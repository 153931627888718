import React, { useEffect, useState } from "react";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { connect } from "react-redux";
import Modal from '@mui/material/Modal';
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import ImageIcon from '@material-ui/icons/Image';
import {
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  withStyles,
} from "@material-ui/core";
// import {
//   getPositiveCallsComments,
//   addPositiveCallComment,
// } from "../../actions/bookingActions";
import "./index.sass";
let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  imageSM: {
    width: "60px",
    height: "60px",
    border: "2px solid #000 ",
    borderRadius: "50%"
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: isMobile ? "400px" : "1200px",
  },
  table: {
    width: "100%",
  },
  tableContainer: {
    marginTop: "2rem",
    position: "sticky",
    top: "200px",
  },
  imgContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap"
},
image: {
  width: "100px",
  height: "auto",
},
imagePaper: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[1],
},
}));
const StyledTableCell:any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow:any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);
function rand() {
  return Math.round(Math.random() * 20) - 10;
}
function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
  };
}

type ModalProps = {
  setOpenRidderComment: Function;
  openRiderComment: boolean;
  riderCommentsJourney: any;
  loading: any;
  riderTravelRecords: any;


};

const CommentsModal: React.FC<ModalProps> = ({
  openRiderComment,
  setOpenRidderComment,
  riderCommentsJourney,
  loading,
  riderTravelRecords

}) => {
  const classes = useStyles();
  const [comment, setComment] = React.useState<string>("");
  const [openImage, setImageOpen] = React.useState(false);
  const [imageUrl, setImageUrl] = useState("")
  const [modalStyle] = React.useState(getModalStyle);
  const handleImageClose = () => {
    setImageOpen(false)

}
  const handleStartModal = (e: any) => {
    setImageUrl(e)
    setImageOpen(true)
  }
  const handleEndModal = (e: any) => {
    setImageUrl(e)
    setImageOpen(true)
  }

  const handleClose = () => {
    setOpenRidderComment(false);
  };

  useEffect(() => {

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const body = (
    <div style={modalStyle} className={classes.imagePaper}>
        <img src={imageUrl} alt="popup" style={{ width: "550px", height: "450px" }} />
        {/* <SimpleModal /> */}
    </div>
);
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openRiderComment}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openRiderComment}>
          <div className={classes.paper}>
            <h6
              style={{
                marginBottom: "10px",
                textAlign: "center",
                borderBottom: "1px solid rgba(0,0,0,0.5)",
                fontWeight: "bold"
              }}
              id="transition-modal-title"
            >
              RIDER JOURNEY
            </h6>
            <div
              id="transition-modal-description"
              className="modal-form-wrapper"
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                marginTop: "20px",
              }}
            >
              <TableContainer className={classes.tableContainer} component={Paper} style={{ maxHeight: "600px" }}>
              <Table stickyHeader aria-label="simple table">
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell align="center">Meter_Start_Reading</StyledTableCell>
                      <StyledTableCell align="center">Meter_End_Eeading</StyledTableCell>
                      <StyledTableCell align="center">Meter_Start_Reading_Image</StyledTableCell>
                      <StyledTableCell align="center">Meter_End_Reading_Image</StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCell align="center">
                        {riderTravelRecords?.meter_start_reading}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {riderTravelRecords?.meter_end_reading}
                      </StyledTableCell>
                      <StyledTableCell style={{cursor:"pointer"}} align="center" onClick={() => handleStartModal(riderTravelRecords?.meter_start_reading_image)}>
                        {riderTravelRecords?.meter_start_reading_image === null ? "NA"
                          : <img key={riderTravelRecords?.meter_start_reading_image} src={riderTravelRecords?.meter_start_reading_image} alt="startimage" className={classes.imageSM} />}
                      </StyledTableCell>
                      <StyledTableCell  style={{cursor:"pointer"}}  align="center" onClick={() => handleEndModal(riderTravelRecords?.meter_end_reading_image)}>
                        {riderTravelRecords?.meter_end_reading_image === null ? "NA"
                          : <img key={riderTravelRecords?.meter_end_reading_image} src={riderTravelRecords?.meter_end_reading_image} alt="startimage" className={classes.imageSM} />}
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
                <Table stickyHeader aria-label="simple table">
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell align="center">Rider</StyledTableCell>
                      <StyledTableCell align="center">Journey Status</StyledTableCell>
                      <StyledTableCell align="center">Booking ID</StyledTableCell>
                      <StyledTableCell align="center">Current Time</StyledTableCell>
                      <StyledTableCell align="center">Route Point</StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {riderCommentsJourney && riderCommentsJourney.results && riderCommentsJourney.results.length > 0 && riderCommentsJourney.results.map((journey: any, index: any) => {
                      return (
                        <StyledTableRow key={index}>
                          <StyledTableCell align="center">
                            {journey?.rider?.username}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {journey?.current_status === "punched" ? "End" : journey?.current_status}
                          </StyledTableCell>
                          <StyledTableCell align="center" style={{ width: "170px !important", display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
                            <span >  {journey?.batch.length > 0 ? journey?.batch.join(",") : ""}</span>

                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {journey?.current_time.substring(0, 10)}<br />
                            {journey?.current_time.substring(11, 19)}{" "}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {journey?.rider_route?.route_point}
                          </StyledTableCell>
                        </StyledTableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </Fade>
      </Modal>
      <Modal
        open={openImage}
        onClose={handleImageClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: any) => ({

});

export default connect(mapStateToProps, {

})(CommentsModal);
