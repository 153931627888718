import React, { useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from '@mui/material/Modal';
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Button, TextField, Grid, Select, MenuItem } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { createCoupon } from '../../actions/productMangerAction';
import { useHistory } from "react-router-dom";
let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: isMobile ? "400px" : "1200px",
    },
    head: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "2rem"
    }
}));

type ModalProps = {
    createCoupon: any;
    open: boolean;
    setOpen: Function;
};

const CommentsModal2: React.FC<ModalProps> = ({
    open,
    setOpen,
    createCoupon,
}) => {
    const history = useHistory()
    const classes = useStyles();

    const [couponCode, setCouponCode] = useState<string | null>(null)
    const [couponName, setCouponName] = useState<string | null>(null)
    const [discountType, setDiscountType] = useState<string>("none")
    const [amount, setAmount] = useState<string | null>(null)
    const [minPrice, setMinPrice] = useState<string | null>(null)
    const [validOnce, setValidOnce] = useState<boolean>(false)
    const [userGroup, setUserGroup] = useState<boolean | any>("none")

    const handleClose = () => {
        setOpen(false);
    };

    const createNewCoupon = async () => {
        const body: any = {
            name: couponName,
            code: couponCode,
            discount: amount,
            minimum_price: minPrice,
            discount_type: discountType,
            valid_once_per_user: validOnce,
            is_active:true,
            usergroup: userGroup.join(',')

        }
        await createCoupon(body)
        history.push("/dashboard/pm/coupons")
        handleClose()
    };
    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <div className={classes.head}>
                            <h4
                                style={{
                                    marginBottom: "1rem",
                                }}
                                id="transition-modal-title"
                            >
                                Create Area
                            </h4>
                            <CloseIcon onClick={() => setOpen(false)} />
                        </div>
                        <Grid container direction="row" alignItems="flex-start" justify="space-between" spacing={3} style={{ margin: "1rem auto" }}>
                            <Grid item xs={12} sm={6} md={4}>
                                <TextField
                                    name="coupon"
                                    type="text"
                                    value={couponName}
                                    className="input"
                                    variant="outlined"
                                    placeholder="Coupon Name"
                                    onChange={(e) => setCouponName(e.target.value as string)}
                                    style={{ width: "100%", minWidth: "200px" }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <TextField
                                    name="coupon"
                                    type="text"
                                    value={couponCode}
                                    className="input"
                                    variant="outlined"
                                    placeholder="Coupon Code"
                                    onChange={(e) => setCouponCode(e.target.value as string)}
                                    style={{ width: "100%", minWidth: "200px" }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <TextField
                                    name="area"
                                    type="text"
                                    value={minPrice}
                                    className="input"
                                    variant="outlined"
                                    placeholder="Min Price"
                                    onChange={(e) => setMinPrice(e.target.value as string)}
                                    style={{ width: "100%", minWidth: "200px" }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Select className="input"
                                    name="validOnce"
                                    variant="outlined"
                                    value={discountType}
                                    style={{ width: "100%", textAlign: "left", minWidth: "200px" }}
                                    onChange={(e: any) => setDiscountType(e.target.value as string)}
                                >
                                    <MenuItem disabled value={"none"}>Discount Type</MenuItem>
                                    <MenuItem value={"amount"}>Amount</MenuItem>
                                    <MenuItem value={"percentage"}>Percentage</MenuItem>
                                </Select>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <TextField
                                    name="city"
                                    type="text"
                                    value={amount}
                                    className="input"
                                    variant="outlined"
                                    placeholder="Discount"
                                    onChange={(e) => setAmount(e.target.value as string)}
                                    style={{ width: "100%", minWidth: "200px" }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Autocomplete
                                    id="city"
                                    onChange={(event, newValue) => {
                                        let sourceIds: any = []
                                        if (newValue) {
                                            let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                            sourceIds = obj.map((item: any) => item.value)
                                        }
                                        setUserGroup(sourceIds)
                                    }}
                                    options={[
                                        {
                                            text: "CustomerLead",
                                            value: "CustomerLead"
                                        },
                                        {
                                            text: "SupportExecutive",
                                            value: "SupportExecutive"
                                        },
                                        {
                                            text: "CustomerExecutive",
                                            value: "CustomerExecutive"
                                        },
                                        {
                                            text: "SampleAccessioning",
                                            value: "SampleAccessioning"
                                        },
                                        {
                                            text: "VerificationExecutive",
                                            value: "VerificationExecutive"
                                        },
                                        {
                                            text: "PhleboAdmin",
                                            value: "PhleboAdmin"
                                        },
                                        {
                                            text: "Finance",
                                            value: "Finance"
                                        },
                                         {
                                            text: "Phlebos",
                                            value: "Phlebos"
                                        },
                                       
                                    ]}
                                    freeSolo
                                    blurOnSelect
                                    aria-required
                                    limitTags={1}
                                    multiple
                                    getOptionLabel={(option: any) => option.text}
                                    getOptionDisabled={(option: any) => {
                                        return userGroup.includes(option.value)
                                    }}
                                    disableClearable
                                    disableCloseOnSelect
                                    renderInput={(params) => (
                                        <TextField className="input"
                                            {...params}
                                            placeholder="User Group"
                                            variant="outlined"
                                            style={{ width: "100%", margin: "0", padding: "0" }}
                                        />
                                    )}
                                />
                            </Grid>
                             <Grid item xs={12} sm={6} md={4}>
                                <Select className="input"
                                                            name="validOnce"
                                                            variant="outlined"
                                                            value={validOnce }
                                                            style={{ width: "100%", textAlign: "left", minWidth: "200px" }}
                                                            onChange={(e: any) => setValidOnce(e.target.value as boolean)}
                                                        >
                                                            <MenuItem disabled value={"none"}>Valid Once</MenuItem>
                                                            <MenuItem value={"true"}>Yes</MenuItem>
                                                            <MenuItem selected value={"false"}>No</MenuItem>
                                                        </Select>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    style={{ height: "50px" }}
                                    onClick={createNewCoupon}
                                >
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
};

const mapStateToProps = (state: any) => ({
    area: state.ProductManagerReducer.area,
    loading: state.ProductManagerReducer.loading
});

export default connect(mapStateToProps, {
    createCoupon,
})(CommentsModal2);
