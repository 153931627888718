import React, { useState, useRef, useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  Grid,
  TablePagination,
  TableRow,
  withStyles,
  TextField,
  Chip,
} from "@material-ui/core";
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import Button from '@mui/material/Button';
import Backdrop from "@material-ui/core/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@material-ui/core/Fade";
import { connect } from "react-redux";
import Loader from "../components/loader";
import { useHistory } from "react-router";
import { getAllBookings, getPhlebos, updateBooking } from "../actions/RouteManagerAction";
import Box from "@mui/material/Box";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Autocomplete from "@material-ui/lab/Autocomplete";
import Typography from '@material-ui/core/Typography';
import Tab from '@material-ui/core/Tab';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
    },
    heading: {
      fontSize: "1.3rem",
      marginTop: "1rem",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      overflow: "scroll",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: "1200px",
      overflow: "scroll",
    },
    table: {
      maxWidth: "100%",
      margin: "auto",
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
  })
);
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
  style: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

interface Props {
  getAllBookings: any;
  bookingList: any;
  loading: any;
  getPhlebos: any;
  phleboList: any;
  updateBooking: any;
}

const BookingsTable: React.FC<Props> = ({
  getAllBookings,
  bookingList,
  loading,
  getPhlebos,
  phleboList,
  updateBooking
}) => {
  const history = useHistory();
  const classes = useStyles();
  const timer = useRef<any>(0);
  const [value, setValue] = React.useState(0);
  const [phleboName, setPhleboName] = useState<any>("");
  const [phlebo, setPhlebo] = useState<any>("");
  const [openAssignPhlebo, setOpenAssignPhlebo] = React.useState(false);
  const [assignBookingId, setAssignBookingId] = useState<any>("");
  const [customerZoneId, setCustomerZoneId] = useState<any>("");
  const [disableAssignBtn, setDisableAssignBtn] = useState<boolean>(false);
  const [disabPhleboBlank, setDisabPhleboBlank] = useState<any>("")
  const [page, setPage] = useState(0);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url =
        bookingList?.links && bookingList?.links?.next?.split("?")[1];
      getAllBookings(url ? `?${url}` : "");
    } else if (newPage < page) {
      let url =
        bookingList?.links &&
        bookingList?.links?.previous?.split("?")[1];
      getAllBookings(url ? `?${url}` : "");
    }
    setPage(newPage as number);
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const handlePhleboAssignmentModel = (item: any) => {
    setOpenAssignPhlebo(true)
    setAssignBookingId(item?.pk)
    setCustomerZoneId(item.customer_areapincode && item.customer_areapincode.zone)

  }

  useEffect(() => {
    if (value === 0) {
      getAllBookings(`delivery_status=pending`)
    }
    else if (value === 1) {
      getAllBookings(`delivery_status=delivered`)
    }


  }, [value])


  const handleCloseAssign = () => {
    setOpenAssignPhlebo(false)
  }

  const SubmitPhleboAssignment = async (e: any) => {
    e.preventDefault();
    const data: any = {
      phlebo: phlebo,
    };
    setDisableAssignBtn(true)
    await updateBooking(data, assignBookingId);
    if (value === 0) {
      getAllBookings(`delivery_status=pending`)
    }
    else if (value === 1) {
      getAllBookings(`delivery_status=delivered`)
    }

    setOpenAssignPhlebo(false)
    history.push('/dashboard/rm/container_delivery_booking')
  }
  useEffect(() => {
    if (openAssignPhlebo === true) {
      getPhlebos(`?phlebo_or_rider=phlebo`)
    }
  }, [openAssignPhlebo])
  return (

    <main className={classes.content}>
      <div className={classes.toolbar} />
      <h2>Container Delivery Booking</h2>
      <AppBar position="static" >
        <Tabs variant="fullWidth" value={value} onChange={handleChange} aria-label="simple tabs example" style={{ justifyContent: "space-around" }}>
          <Tab style={{ background: value === 0 ? "#ffeb3b" : "#fffdfc", color: "black" }} label="PENDING BOOKING" {...a11yProps(0)} />
          <Tab style={{ background: value === 1 ? "#ffeb3b" : "#fffdfc", color: "black" }} label="DELIVERED BOOKING" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <div className="data-table" style={{ width: "100%" }}>
        <TableContainer
          className={classes.tableContainer}
          component={Paper}
          style={{ maxHeight: "580px" }}
        >
          {loading ? (
            <Loader />
          ) : (
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell align="center">Booking Id</StyledTableCell>
                  <StyledTableCell align="center">Name</StyledTableCell>
                  <StyledTableCell align="center">Age</StyledTableCell>
                  <StyledTableCell align="center">Gender</StyledTableCell>
                  <StyledTableCell align="center">Email</StyledTableCell>
                  {value === 0 ? (
                    <StyledTableCell align="center">Assign Phlebo</StyledTableCell>
                  ) : ""}
                  <StyledTableCell align="center">Collection Date</StyledTableCell>
                  <StyledTableCell align="center">Collection Time</StyledTableCell>
                  <StyledTableCell align="center">Booking Amount</StyledTableCell>
                  <StyledTableCell align="center">Amount Due</StyledTableCell>
                  <StyledTableCell align="center">Booking Status</StyledTableCell>
                  <StyledTableCell align="center">Verification Status</StyledTableCell>
                  <StyledTableCell align="center">Booking Source</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {bookingList?.results &&
                  bookingList?.results.length > 0 &&
                  bookingList?.results.map((booking: any, index: any) => {
                    return (
                      <>
                        <StyledTableRow
                          key={index}
                        >
                          <StyledTableCell align="center">{booking?.pk}</StyledTableCell>
                          <StyledTableCell align="center">{`${booking?.designation || ""
                            } ${booking?.customer_name}`}</StyledTableCell>
                          <StyledTableCell align="center">
                            {booking?.customer_age}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {booking?.customer_gender}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {booking?.customer_email}
                          </StyledTableCell>
                          {value === 0 ? (
                            <StyledTableCell align="center">
                              <Chip
                                style={{ backgroundColor: "#f0f4c3" }}
                                onClick={() =>
                                  handlePhleboAssignmentModel(booking)
                                }
                                icon={<DeliveryDiningIcon style={{ color: "#00c853" }} />}
                              ></Chip>
                            </StyledTableCell>
                          ) : ""}

                          <StyledTableCell align="center">
                            {booking?.collection_date}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {booking?.collection_slot?.slot.split("-")[0]}
                            {" - "}
                            {booking?.collection_slot?.slot.split("-")[1]}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {booking?.discounted_price?.final_total_price}

                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {booking?.receivable_amount}

                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {booking?.booking_status}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {booking?.verification_status}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {booking?.booking_source}
                          </StyledTableCell>
                        </StyledTableRow>

                      </>
                    );
                  })}
              </TableBody>
              <TableFooter>
                <StyledTableRow>
                  <TablePagination
                    rowsPerPageOptions={[]}
                    colSpan={3}
                    count={bookingList.count || 0}
                    rowsPerPage={bookingList.page_size}
                    page={page}
                    onPageChange={handleChangePage}
                  />
                </StyledTableRow>
              </TableFooter>
            </Table>
          )}
        </TableContainer>
        {bookingList.results && bookingList.results.length === 0 && (
          <h6 style={{ textAlign: "center" }}>No Data Found</h6>
        )}



      </div>
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={openAssignPhlebo}
          onClose={handleCloseAssign}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openAssignPhlebo}>
            <div className={classes.paper}>

              <div>
                <h3 className={classes.heading} style={{ textAlign: "center" }}>
                  Assign Phlebo
                </h3>
                <Grid
                  container
                  direction="row"
                  alignItems="flex-start"
                  justify="space-between"
                  spacing={3}
                  style={{ margin: "1rem auto" }}
                >
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      id="phlebo"
                      onChange={(event, newValue) => {
                        if (newValue) {
                          let obj = JSON.parse(
                            JSON.stringify(newValue, null, "")
                          );

                          setPhlebo(obj.id as Number);
                        }
                      }}
                      inputValue={phleboName}
                      onInputChange={(event, newInputValue) => {
                        setPhleboName(newInputValue);
                        clearTimeout(timer.current);
                        timer.current = setTimeout(() => {
                          getPhlebos(`?phlebo_or_rider=phlebo&zone=${customerZoneId}&code=${newInputValue}`);
                          setDisabPhleboBlank(newInputValue)
                        }, 1000);
                      }}
                      options={
                        phleboList?.results
                          ? [
                            ...phleboList?.results,
                            { id: 0, user: { username: "NoPhlebo" } },
                          ]
                          : []
                      }
                      freeSolo
                      blurOnSelect
                      getOptionLabel={(option: any) =>
                        option?.user && option?.user?.username
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Phlebo"
                          variant="outlined"
                          style={{ width: "100%", margin: "0", padding: "0", height: "40px" }}
                          className="input"
                          disabled
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="success"
                      disabled={phlebo === "" || disableAssignBtn || disabPhleboBlank === ""}
                      onClick={SubmitPhleboAssignment}
                    >
                      Assign
                    </Button>
                  </Grid>
                </Grid>
              </div>

            </div>
          </Fade>
        </Modal>
      </div>

    </main>

  );
};

const mapStateToProps = (state: any) => ({
  bookingList: state.RouteManagerReducer.bookingList,
  loading: state.RouteManagerReducer.loading,
  phleboList: state.RouteManagerReducer.phleboList,

});

export default connect(mapStateToProps, {
  getAllBookings,
  getPhlebos,
  updateBooking
})(BookingsTable);
