import React, { useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
	Container,
	Grid,
} from '@material-ui/core';
import Box from '@mui/material/Box';
import { useLocation } from "react-router-dom";
import Button from '@mui/material/Button';
import Relevent from "../components/Fill-Info";
import OtherUpdates from "../components/Other-Info";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getLeadDetails } from "../actions/PhleboAdmin";
import Loader from "../components/loader";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			flexGrow: 0,
			//   width: "100%",
			//   marginTop: "20px",
		},
		toolbar: {
			alignItems: 'center',
			justifyContent: 'flex-end',
			padding: theme.spacing(0, 1),
			// necessary for content to be below app bar
			...theme.mixins.toolbar,
		},
		content: {
			width: "80%",
			flexGrow: 21,
			//   padding: theme.spacing(3),
		},
		card: {
			height: "100%",
			padding: theme.spacing(1),
		},
		card_grid: {
			// wordSpacing:3,
			justifyContent: 'space-between'
		}
	})
);

interface Props {
	children: any;
	getLeadDetails: Function;
	lead_details: any;
	loading: boolean;
}

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

const CustomerLeadFillInfoPage: React.FC<Props> = ({
	getLeadDetails,
	lead_details,
	loading,
}) => {
	const classes = useStyles();
	let query = useQuery();
	const [id, setId] = React.useState<number>(0);
	// const [data, setData] = React.useState<object>({});
	useEffect(() => {
		getLeadDetails(query.get("id")!)
		setId(parseInt(query.get("id")!));
		// setData(lead_details);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<>
			<main className={classes.content}>
				<div className={classes.toolbar} />
				<Box>
					<Box style={{
						display: 'flex',
						justifyContent: 'flex-end',
						margin: "10px"
					}}>
						{/* <Link to="/dashboard/pha/booking">
							<Button
								color="primary"
								variant="contained"
								style={{ margin: "5px" }}
							>
								New Booking
							</Button>
						</Link> */}
					</Box>
					<br />
					<Container maxWidth={false}>
						<Grid container spacing={3}>
							{!lead_details ?
								(<Loader />) : (Object.keys(lead_details).length > 0 ?
									(<>
										<Relevent id={id} data={lead_details} />
										<OtherUpdates id={id} data={lead_details} />
									</>) : ("NA"))
							}
						</Grid>
					</Container>
				</Box>
			</main>
		</>
	);
};
const mapStateToProps = (state: any) => ({
	lead_details: state.PhleboAdminReducer.lead_details,
	loading: state.PhleboAdminReducer.loading
});


export default connect(mapStateToProps, { getLeadDetails })(CustomerLeadFillInfoPage);
