import React, { useState, useEffect } from "react";
import { Chip, Grid, Typography, TextField } from "@material-ui/core";
import { connect } from "react-redux";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import BarChartTwoToneIcon from "@mui/icons-material/BarChartTwoTone";
import {
  getBookingOverview,
} from "../../actions/LogisticsPanelAction";
import { getriderActivelogs, getsampleCash, getUserDetails, getSampleCount, getStopCount } from "../../actions/LogisticsPanelAction";
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import PlaylistAddCheckCircleIcon from '@mui/icons-material/PlaylistAddCheckCircle';
import AddModeratorIcon from '@mui/icons-material/AddModerator';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import LoginIcon from '@mui/icons-material/Login';
import CampaignIcon from '@mui/icons-material/Campaign';
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
import DirectionsBikeTwoToneIcon from "@material-ui/icons/DirectionsBikeTwoTone";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import Avatar from "@mui/material/Avatar";
import moment from "moment";
import "./dashboard.css";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      height: "100%",
      padding: "20px",
    },
    cardContent: {
      textAlign: "center",
    },
    center: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    text: { color: "black" },
  })
);

interface Props {
  getBookingOverview: any;
  bookingoverview: any;
  userDetails: any;
  getriderActivelogs: any;
  riderActiveLog: any;
  getsampleCash: any;
  sampleCash: any;
  getUserDetails: any;
  getSampleCount: any;
  sampleCount: any;
  getStopCount: any;
  stopsCount: any;
  loading: any
}

const Dashboard: React.FC<Props> = ({
  getBookingOverview,
  bookingoverview,
  getriderActivelogs,
  riderActiveLog,
  getsampleCash,
  sampleCash,
  getUserDetails,
  userDetails,
  getSampleCount,
  sampleCount,
  getStopCount,
  stopsCount,
  loading,
}) => {
  const classes = useStyles();

  useEffect(() => {
    getUserDetails();
    getBookingOverview("");
    getriderActivelogs();
  }, []);

  useEffect(() => {
    if (userDetails && userDetails?.profile?.username !== undefined) {
      getsampleCash(`?username=${userDetails?.profile && userDetails?.profile?.username}`);
    } if (userDetails && userDetails?.profile?.username !== undefined) {
      getSampleCount(`?username=${userDetails?.profile && userDetails?.profile?.username}`);
    } if (userDetails && userDetails?.profile?.username !== undefined) {
      getStopCount(`?username=${userDetails?.profile && userDetails?.profile?.username}`);
    }
  }, [userDetails]);

  // useEffect(() => {
  //   if (userDetails && userDetails?.profile?.username!==undefined) {
  //     getSampleCount(`?username=${userDetails?.profile && userDetails?.profile?.username}`);
  //   }
  // }, [userDetails]);

  // useEffect(() => {
  //   if (userDetails && userDetails?.profile?.username!==undefined) {
  //     getStopCount(`?username=${userDetails?.profile && userDetails?.profile?.username}`);
  //   }
  // }, [userDetails]);

  return (
    <div style={{ width: "100%", margin: "60px auto" }}>
      <Grid
        container
        spacing={1}
        direction="row"
        style={{ alignItems: "right", display: "flex", justifyContent: "space-between" }}
      >
        <Grid item xs={12} sm={8}>
          <h4 style={{ fontWeight: "bold" }}>LOGISTICS DASHBOARD</h4>
        </Grid>
        <Grid item xs={12} sm={4} style={{ textAlign: "right" }}>
          <TextField
            type="date"
            defaultValue={moment().format("YYYY-MM-DD")}
            onChange={(e) =>
              getBookingOverview(
                `overview_date=${moment(e.target.value).format("YYYY-MM-DD")}`
              )
            }
          />
        </Grid>
      </Grid>
      <div style={{ margin: "10px 0px 0px 0px", marginTop: "2vm" }}>
        <h6>RIDER </h6>
        <div className="cardsAppointment">
          <div style={{ width: "300px" }}>
            <Grid container>
              <Grid item sm={3}>
                <Avatar
                  sx={{ bgcolor: "#ffeff5", marginTop: "8px" }}
                  aria-label="recipe"
                >
                  <TwoWheelerIcon style={{ color: "#fa4d83" }} />
                </Avatar>
              </Grid>
              <Grid item sm={9}>
                <h5 style={{ color: "#ffffff" }}>{riderActiveLog?.rider_count}</h5>
                <span style={{ color: "#ffffff" }}>Total</span>
              </Grid>
            </Grid>
          </div>
          <div style={{ marginLeft: "1%", width: "300px" }}>
            <Grid container>
              <Grid item sm={3}>
                <Avatar
                  sx={{ bgcolor: "#ffeff5", marginTop: "8px" }}
                  aria-label="recipe"
                >
                  <LoginIcon style={{ color: "#fa4d83" }} />
                </Avatar>
              </Grid>
              <Grid item sm={9}>
                <h5 style={{ color: "#ffffff" }}>{riderActiveLog?.login_count}</h5>
                <span style={{ color: "#ffffff" }}>Logged In</span>
              </Grid>
            </Grid>
          </div>
          <div style={{ marginLeft: "1%", width: "300px" }}>
            <Grid container>
              <Grid item sm={3}>
                <Avatar
                  sx={{ bgcolor: "#ffeff5", marginTop: "8px" }}
                  aria-label="recipe"
                >
                  <TwoWheelerIcon style={{ color: "#fa4d83" }} />
                </Avatar>
              </Grid>
              <Grid item sm={9}>
                <h5 style={{ color: "#ffffff" }}>{bookingoverview?.lab_received_batch}</h5>
                <span style={{ color: "#ffffff" }}>Running on Time</span>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      <div style={{ margin: "10px 0px 0px 0px", marginTop: "-8px" }}>
        <h6>SAMPLE </h6>
        <div className="cardsAppointment">
          <div style={{ width: "300px" }}>
            <Grid container>
              <Grid item sm={3}>
                <Avatar
                  sx={{ bgcolor: "#ffeff5", marginTop: "8px" }}
                  aria-label="recipe"
                >
                  <PlaylistAddCheckCircleIcon style={{ color: "#fa4d83" }} />
                </Avatar>
              </Grid>
              <Grid item sm={9}>
                <h5 style={{ color: "#ffffff" }}>{sampleCount?.picked}</h5>
                <span style={{ color: "#ffffff" }}>Picked</span>
              </Grid>
            </Grid>
          </div>
          <div style={{ marginLeft: "1%", width: "300px" }}>
            <Grid container>
              <Grid item sm={3}>
                <Avatar
                  sx={{ bgcolor: "#ffeff5", marginTop: "8px" }}
                  aria-label="recipe"
                >
                  <TransferWithinAStationIcon style={{ color: "#fa4d83" }} />
                </Avatar>
              </Grid>
              <Grid item sm={9}>
                <h5 style={{ color: "#ffffff" }}>{sampleCount?.transferred_to_lab}</h5>
                <span style={{ color: "#ffffff" }}>Transfer to Lab</span>
              </Grid>
            </Grid>
          </div>
          <div style={{ marginLeft: "1%", width: "300px" }}>
            <Grid container>
              <Grid item sm={3}>
                <Avatar
                  sx={{ bgcolor: "#ffeff5", marginTop: "8px" }}
                  aria-label="recipe"
                >
                  <TransferWithinAStationIcon style={{ color: "#fa4d83" }} />
                </Avatar>
              </Grid>
              <Grid item sm={9}>
                <h5 style={{ color: "#ffffff" }}>{sampleCount?.to_be_transferred_to_lab}</h5>
                <span style={{ color: "#ffffff" }}>To be Transferred to Lab</span>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      <div style={{ margin: "10px 0px 0px 0px", marginTop: "-8px" }}>
        <h6>CASH AMOUNT </h6>
        <div className="cardsAppointment">
          <div style={{ width: "290px" }}>
            <Grid container>
              <Grid item sm={3}>
                <Avatar
                  sx={{ bgcolor: "#ffeff5", marginTop: "8px" }}
                  aria-label="recipe"
                >
                  <CurrencyRupeeIcon style={{ color: "#fa4d83" }} />
                </Avatar>
              </Grid>
              <Grid item sm={9}>
                <h5 style={{ color: "#ffffff" }}>{sampleCash?.total_cash}</h5>
                <span style={{ color: "#ffffff" }}>Total</span>
              </Grid>
            </Grid>
          </div>
          <div style={{ marginLeft: "1%", width: "290px" }}>
            <Grid container>
              <Grid item sm={3}>
                <Avatar
                  sx={{ bgcolor: "#ffeff5", marginTop: "8px" }}
                  aria-label="recipe"
                >
                  <CurrencyRupeeIcon style={{ color: "#fa4d83" }} />
                </Avatar>
              </Grid>
              <Grid item sm={9}>
                <h5 style={{ color: "#ffffff" }}>{sampleCash?.received_cash}</h5>
                <span style={{ color: "#ffffff" }}>Cash Received</span>
              </Grid>
            </Grid>
          </div>
          <div style={{ marginLeft: "1%", width: "290px" }}>
            <Grid container>
              <Grid item sm={3}>
                <Avatar
                  sx={{ bgcolor: "#ffeff5", marginTop: "8px" }}
                  aria-label="recipe"
                >
                  <CurrencyRupeeIcon style={{ color: "#fa4d83" }} />
                </Avatar>
              </Grid>
              <Grid item sm={9}>
                <h5 style={{ color: "#ffffff" }}>{sampleCash?.cash_transfer_to_lab}</h5>
                <span style={{ color: "#ffffff" }}>Cash Transfer to Lab</span>
              </Grid>
            </Grid>
          </div>
          <div style={{ marginLeft: "1%", width: "310px" }}>
            <Grid container>
              <Grid item sm={3}>
                <Avatar
                  sx={{ bgcolor: "#ffeff5", marginTop: "8px" }}
                  aria-label="recipe"
                >
                  <CurrencyRupeeIcon style={{ color: "#fa4d83" }} />
                </Avatar>
              </Grid>
              <Grid item sm={9}>
                <h5 style={{ color: "#ffffff" }}>{sampleCash?.cash_transfer_to_be_transfer_to_lab}</h5>
                <span style={{ color: "#ffffff", fontSize: 12 }}>Cash To be transferred to Lab</span>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      <div style={{ margin: "10px 0px 0px 0px", marginTop: "-8px" }}>
        <h6>STOPS </h6>
        <div className="cardsAppointment">
          <div style={{ width: "235px" }}>
            <Grid container>
              <Grid item sm={3}>
                <Avatar
                  sx={{ bgcolor: "#ffeff5", marginTop: "8px" }}
                  aria-label="recipe"
                >
                  <DirectionsBikeTwoToneIcon style={{ color: "#fa4d83" }} />
                </Avatar>
              </Grid>
              <Grid item sm={9}>
                <h5 style={{ color: "#ffffff" }}>{stopsCount?.total_stop}</h5>
                <span style={{ color: "#ffffff" }}>Total</span>
              </Grid>
            </Grid>
          </div>
          <div style={{ marginLeft: "1%", width: "235px" }}>
            <Grid container>
              <Grid item sm={3}>
                <Avatar
                  sx={{ bgcolor: "#ffeff5", marginTop: "8px" }}
                  aria-label="recipe"
                >
                  <DirectionsBikeTwoToneIcon style={{ color: "#fa4d83" }} />
                </Avatar>
              </Grid>
              <Grid item sm={9}>
                <h5 style={{ color: "#ffffff" }}>{stopsCount?.reached_stop}</h5>
                <span style={{ color: "#ffffff" }}>Reached</span>
              </Grid>
            </Grid>
          </div>
          <div style={{ marginLeft: "1%", width: "235px" }}>
            <Grid container>
              <Grid item sm={3}>
                <Avatar
                  sx={{ bgcolor: "#ffeff5", marginTop: "8px" }}
                  aria-label="recipe"
                >
                  <DirectionsBikeTwoToneIcon style={{ color: "#fa4d83" }} />
                </Avatar>
              </Grid>
              <Grid item sm={9}>
                <h5 style={{ color: "#ffffff" }}>{stopsCount?.to_be_reached_stop}</h5>
                <span style={{ color: "#ffffff" }}>To be Reached</span>
              </Grid>
            </Grid>
          </div>
          <div style={{ marginLeft: "1%", width: "235px" }}>
            <Grid container>
              <Grid item sm={3}>
                <Avatar
                  sx={{ bgcolor: "#ffeff5", marginTop: "8px" }}
                  aria-label="recipe"
                >
                  <DirectionsBikeTwoToneIcon style={{ color: "#fa4d83" }} />
                </Avatar>
              </Grid>
              <Grid item sm={9}>
                <h5 style={{ color: "#ffffff" }}>{stopsCount?.delay_reached}</h5>
                <span style={{ color: "#ffffff" }}>Dalay Reach</span>
              </Grid>
            </Grid>
          </div>
          <div style={{ width: "235px", marginLeft: "1%", }}>
            <Grid container>
              <Grid item sm={3}>
                <Avatar
                  sx={{ bgcolor: "#ffeff5", marginTop: "8px" }}
                  aria-label="recipe"
                >
                  <DirectionsBikeTwoToneIcon style={{ color: "#fa4d83" }} />
                </Avatar>
              </Grid>
              <Grid item sm={9}>
                <h5 style={{ color: "#ffffff" }}>{stopsCount?.started_stop}</h5>
                <span style={{ color: "#ffffff" }}>Started Stops</span>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      <div style={{ margin: "10px 0px 0px 0px", marginTop: "-8px" }}>
        <h6>SAMPLE TYPES </h6>
        <div className="cardsAppointment">
          <div style={{ width: "235px" }}>
            <Grid container>
              <Grid item sm={3}>
                <Avatar
                  sx={{ bgcolor: "#ffeff5", marginTop: "8px" }}
                  aria-label="recipe"
                >
                  <MapsHomeWorkIcon style={{ color: "#fa4d83" }} />
                </Avatar>
              </Grid>
              <Grid item sm={9}>
                <h5 style={{ color: "#ffffff" }}>{sampleCash?.b2b}</h5>
                <span style={{ color: "#ffffff" }}>B2B</span>
              </Grid>
            </Grid>
          </div>
          <div style={{ marginLeft: "1%", width: "235px" }}>
            <Grid container>
              <Grid item sm={3}>
                <Avatar
                  sx={{ bgcolor: "#ffeff5", marginTop: "8px" }}
                  aria-label="recipe"
                >
                  <LocalHospitalIcon style={{ color: "#fa4d83" }} />
                </Avatar>
              </Grid>
              <Grid item sm={9}>
                <h5 style={{ color: "#ffffff" }}>{sampleCash?.b2c}</h5>
                <span style={{ color: "#ffffff" }}>B2C</span>
              </Grid>
            </Grid>
          </div>
          <div style={{ marginLeft: "1%", width: "235px" }}>
            <Grid container>
              <Grid item sm={3}>
                <Avatar
                  sx={{ bgcolor: "#ffeff5", marginTop: "8px" }}
                  aria-label="recipe"
                >
                  <BarChartTwoToneIcon style={{ color: "#fa4d83" }} />
                </Avatar>
              </Grid>
              <Grid item sm={9}>
                <h5 style={{ color: "#ffffff" }}>{sampleCash?.cc}</h5>
                <span style={{ color: "#ffffff" }}>CC</span>
              </Grid>
            </Grid>
          </div>
          <div style={{ marginLeft: "1%", width: "235px" }}>
            <Grid container>
              <Grid item sm={3}>
                <Avatar
                  sx={{ bgcolor: "#ffeff5", marginTop: "8px" }}
                  aria-label="recipe"
                >
                  <CampaignIcon style={{ color: "#fa4d83" }} />
                </Avatar>
              </Grid>
              <Grid item sm={9}>
                <h5 style={{ color: "#ffffff" }}>{sampleCash?.dc}</h5>
                <span style={{ color: "#ffffff" }}>DC</span>
              </Grid>
            </Grid>
          </div>
          <div style={{ width: "235px", marginLeft: "1%" }}>
            <Grid container>
              <Grid item sm={3}>
                <Avatar
                  sx={{ bgcolor: "#ffeff5", marginTop: "8px" }}
                  aria-label="recipe"
                >
                  <BarChartTwoToneIcon style={{ color: "#fa4d83" }} />
                </Avatar>
              </Grid>
              <Grid item sm={9}>
                <h5 style={{ color: "#ffffff" }}>{sampleCash?.total}</h5>
                <span style={{ color: "#ffffff" }}>Total</span>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  bookingoverview: state.LogisticsReducer.bookingoverview,
  riderActiveLog: state.LogisticsReducer.riderActiveLog,
  sampleCash: state.LogisticsReducer.sampleCash,
  userDetails: state.LogisticsReducer.userDetails,
  sampleCount: state.LogisticsReducer.sampleCount,
  stopsCount: state.LogisticsReducer.stopsCount,
  loading: state.LogisticsReducer.loading,
});

export default connect(mapStateToProps, {
  getBookingOverview,
  getriderActivelogs,
  getsampleCash,
  getUserDetails,
  getSampleCount,
  getStopCount,
})(Dashboard);
