//Authentication
import React, { useState } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
// import AnalystAgentDash from "../containers/CustomerExecutive-agent";
import Modal from "../components/Modal";
import MainLayout from "../Hoc/MainLayout";
import SnackBar from "../components/SnackBar";
import Login from "../containers/login";
import SecureStorage from "./SecureStorage";
import SecondModal from "../components/SecondModal";
import Dash from "../containers/dash";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { checkUser } from "../actions/loginActions";
import { connect } from "react-redux";
import { messageBox } from "../actions/notificationAction";

// Phlebo Admin
import PhleboAdminDash from "../containers/PhleboAdmin-agent";
import PhleboAdminHomePage from "../PhleboAdmin-Panel/components/Home/home"
import PhleboAdminFilterPage from "../PhleboAdmin-Panel/pages/filter";
import PhleboAdminFilterOnlinePage from "../PhleboAdmin-Panel/pages/filterOnline";
import PhleboAdminViewBooking from "../PhleboAdmin-Panel/components/Bookings/ViewBooking";
import PhleboAdminOnlineViewBooking from "../PhleboAdmin-Panel/components/Bookings/ViewBooking/onlineView";
import PhleboAdminNewBookingsPage from "../PhleboAdmin-Panel/components/Bookings/NewBooking/index";
import PHABookingPage from "../PhleboAdmin-Panel/components/Bookings/BookingPage";
import PHAImagingEditBookingForm from "../PhleboAdmin-Panel/components/Bookings/EditBookingForm/ImagingEditBooking";
import PhleboAdminEditBookingForm from "../PhleboAdmin-Panel/components/Bookings/EditBookingForm/EditBookingForm";
import PhleboAdminExportBooking from "../PhleboAdmin-Panel/pages/exportFilter";
import PHAManageZones from "../PhleboAdmin-Panel/components/ManageZones/index";
import BookingCard from "../PhleboAdmin-Panel/components/BookingCard";
import ViewBookingOnMap from "../PhleboAdmin-Panel/components/BookingCard/ViewBookingOnMap";
import ManageAttendance from "../PhleboAdmin-Panel/components/ManageAttendance";
import PhleboAdminCallRecording from "../PhleboAdmin-Panel/tables/callDetails";
import PaComplaintsBooking from "../PhleboAdmin-Panel/tables/queryTicketsBooking";
import PhleboAdminManagePhlebo from "../PhleboAdmin-Panel/tables/managePhlebos";
import PhaFillInfoPage from "../PhleboAdmin-Panel/pages/fill-info";
import PhleboPinCodePage from "../PhleboAdmin-Panel/components/PinCode";
import CurrentDayBooking from "../PhleboAdmin-Panel/pages/currentDayBooking";
import CityWisePhlebo from "../PhleboAdmin-Panel/pages/cityWisePhlebo";
import PhleboVerifiedBooking from "../PhleboAdmin-Panel/tables/bookingVerificationTable";
import PHACenterInfo from "../PhleboAdmin-Panel/tables/centerTable";
import PHAInbox from "../PhleboAdmin-Panel/components/notificationInbox/index";
import phleboAvailableSlots from "../PhleboAdmin-Panel/pages/availableSlot";
import PhleboAdminTestPackages from "../PhleboAdmin-Panel/pages/testPackages";
// import PHAAdditionalBookingForm from '../PhleboAdmin-Panel/components/Bookings/NewBooking/NewBooking';
import PHAAddAditionalFamily from "../PhleboAdmin-Panel/components/Bookings/NewBooking/AddFamilyMember";
import PHALabDetails from "../PhleboAdmin-Panel/components/LabModal/labDetails";
import PhaPhleboBookingView from "../PhleboAdmin-Panel/components/PhleboBookingPanel/BookingView";
import PhaPleboAdminCommentView from "../PhleboAdmin-Panel/components/PhleboBookingPanel/CommentView";
import PheboAdminTRFDownload from "../PhleboAdmin-Panel/components/Bookings/DownloadBooking/Trf";
import PheboAdminSampleHandover from "../PhleboAdmin-Panel/tables/sampleHandOverTable";
import PheboAdminPhaRiderCashDue from "../PhleboAdmin-Panel/pages/PhleboRiderDue";
import PheboAdminHelpDesk from "../PhleboAdmin-Panel/pages/HelpDesk";
import PheboAdminHIV from "../PhleboAdmin-Panel/components/Bookings/ViewBooking/Hivreport";
import PHABiomarkerScreening from "../PhleboAdmin-Panel/components/Bookings/ViewBooking/BiomarkerScreeningreport";
import PhleboAdminBatchedPayment from "../PhleboAdmin-Panel/pages/BatchedPayment/index";
import PhleboAdminPhleboRiderMap from "../PhleboAdmin-Panel/pages/phleboRiderMapping";
import PhleboAdminRoutePlan from "../PhleboAdmin-Panel/tables/routePlan";
import PhleboAdminHoliday from '../PhleboAdmin-Panel/pages/HolidayCalender/index'
import PHALearningModule from "../PhleboAdmin-Panel/pages/learningModules";
import PHALeadDetails from "../PhleboAdmin-Panel/tables/leads";
// import PushNotification from '../notification/index'

//RouteManager-Panel
import RouteManagerDash from "../containers/RouteManager-Panel";
import RouteManagerHome from "../RouteManager-Panel/pages/filter1"
import RouteManagerFilterPage from "../RouteManager-Panel/pages/filter";
import RouteManagerFilterOnlinePage from "../RouteManager-Panel/pages/filterOnline";
import RouteManagerViewBooking from "../RouteManager-Panel/components/Bookings/ViewBooking";
import RouteManagerOnlineViewBooking from "../RouteManager-Panel/components/Bookings/ViewBooking/onlineView";
import RouteManagerEditBookingForm from "../RouteManager-Panel/components/Bookings/EditBookingForm/EditBookingForm";
import RouteManagerExportBooking from "../RouteManager-Panel/pages/exportFilter";
import RMManageZones from "../RouteManager-Panel/components/ManageZones/index";
import RMBookingCard from "../RouteManager-Panel/components/BookingCard";
import RMViewBookingOnMap from "../RouteManager-Panel/components/BookingCard/ViewBookingOnMap";
import RMManageAttendance from "../RouteManager-Panel/components/ManageAttendance";
import RMCallRecording from "../RouteManager-Panel/tables/callDetails";
import RMComplaintsBooking from "../RouteManager-Panel/tables/queryTicketsBooking";
import RMCenterInfo from "../RouteManager-Panel/tables/centerTable";
import RouteManagerManagePhlebo from "../RouteManager-Panel/tables/managePhlebos";
import RMFillInfoPage from "../RouteManager-Panel/pages/fill-info";
import RouteManagerPinCodePage from "../RouteManager-Panel/components/PinCode";
import RMCurrentDayBooking from "../RouteManager-Panel/pages/currentDayBooking";
import RMCityWisePhlebo from "../RouteManager-Panel/pages/cityWisePhlebo";
import RMInbox from "../RouteManager-Panel/components/notificationInbox/index";
import RouteManagerVerifiedBooking from "../RouteManager-Panel/tables/bookingVerificationTable";
import RoutePhleboBookingView from "../RouteManager-Panel/components/PhleboBookingPanel/BookingView";
import RoutePleboAdminCommentView from "../RouteManager-Panel/components/PhleboBookingPanel/CommentView";
import RouteTestPackages from "../RouteManager-Panel/pages/testPackages";
import RMTRFDownload from "../RouteManager-Panel/components/Bookings/DownloadBooking/Trf";
import RMHelpDesk from "../RouteManager-Panel/pages/HelpDesk";
import RMSampleHandover from "../RouteManager-Panel/tables/sampleHandOverTable";
import RMRiderCashDue from "../RouteManager-Panel/pages/PhleboRiderDue";
import RouteManagerHIV from "../RouteManager-Panel/components/Bookings/ViewBooking/Hivreport";
import RMBiomarkerScreening from "../RouteManager-Panel/components/Bookings/ViewBooking/BiomarkerScreeningreport";
import RouteManagerBatchedPayment from "../RouteManager-Panel/pages/BatchedPayment/index";
import RouteManagerPhleboRiderMap from "../RouteManager-Panel/pages/phleboRiderMapping";
import RouteManagerRoutePlan from "../RouteManager-Panel/tables/routePlan";
import RouteManagerBatchTransferAmount from '../RouteManager-Panel/pages/BatchAmount'
import RouteHoliday from '../RouteManager-Panel/pages/HolidayCalender/index'
import MultipleBookingPaymentUploadTable from "../RouteManager-Panel/pages/MultipleBookingPaymentUpload/MultipleBookingPaymentUploadTable";
import Views from "../RouteManager-Panel/pages/views";
import RMLearningModule from "../RouteManager-Panel/pages/learningModules";
import ContainerDeliveryBooking from "../RouteManager-Panel/tables/containerDeliveryBooking";
// import RMBulkEmailDash from '../containers/BulkEmail-agent';
// import RMComposeBulkEmail from '../BulkEmail-Panel/components/ComposeEmail/index';
// PhleboUser panel`

//Edit Package Master
import EditPackageMasterDash from "../containers/EditPackageMaster-agent";
import EditPackageMasterPackageManagementHome from "../EditPackageMaster-Panel/pages/packageManagementHome";
import EditPackageManagementNameEditPackage from "../EditPackageMaster-Panel/pages/editpackagemanagementEditPackage";
import EditPackageMasterCreatePackageName from "../EditPackageMaster-Panel/pages/createpackagemanagement";


//Bulk Upload Master
import BulkUploadMasterDash from "../containers/BulkUploadMaster-agent";
import BulkUploadMasterBookingUploader from "../BulkUploadMaster-Panel/pages/BookingUploader/cc_bookingUploader ";
import BulkUploadMasterPartnerUploader from "../BulkUploadMaster-Panel/pages/PartnerUploader/crm_partner_uploader";
import BulkUploadMasterPartnerBookingPage from "../BulkUploadMaster-Panel/pages/filter";
import BulkUploadMasterPartnerCCBookingPage from "../BulkUploadMaster-Panel/pages/CC_filter";
import BulkUploadMasterPartnerViewBooking from "../BulkUploadMaster-Panel/Components/Bookings/ViewBooking/index"
import BulkUploadMasterPartnerCCViewBooking from "../BulkUploadMaster-Panel/Components/Bookings/CCViewBooking/index"

//ProductManager Panel
import ProductManagerBookingPage from "../ProductManager-Panel/Components/Bookings/BookingPage";
import ProductManagerHome from "../ProductManager-Panel/Components/Home/home";
import ProductManagerFilterPage from "../ProductManager-Panel/pages/filter";
import ProductManagerViewBooking from "../ProductManager-Panel/Components/Bookings/ViewBooking/index";
import ProductManagerEditBookingForm from "../ProductManager-Panel/Components/Bookings/EditBookingForm/EditBookingForm";
import ProductManagerHomePage from "../ProductManager-Panel/Components/Home";
import ProductManagerCreatePackage from "../ProductManager-Panel/Components/ManagePackages/CreatePackage";
import ProductManagerEditPackage from "../ProductManager-Panel/Components/ManagePackages/EditPackage";
import ProductManagerCreateUser from "../ProductManager-Panel/Components/SubAdmin/CreateUser";
import ProductManagerDash from "../containers/ProductManager-Panel";
import ProductManagerSubAdminPage from "../ProductManager-Panel/Components/SubAdmin";
import ProductManagerPinCodePage from "../ProductManager-Panel/Components/PinCode";
import ProductManagerManageCoupons from "../ProductManager-Panel/Components/ManageCoupons";
import ProductManagerManageZones from "../ProductManager-Panel/Components/ManageZones";
import ProductManagerManagePackages from "../ProductManager-Panel/Components/ManagePackages";
import ProductManagerManageBlogs from "../ProductManager-Panel/Components/ManageBlogs";
import ProductManagerCreateBlog from "../ProductManager-Panel/Components/ManageBlogs/CreateBlog";
import ProductManagerEditBlog from "../ProductManager-Panel/Components/ManageBlogs/EditBlog";
import ProductManagerAdminBookingExport from "../ProductManager-Panel/pages/exportFilter";
import ProductManagerAdminLeadExport from "../ProductManager-Panel/pages/exportLead";
import ProductManagerLabCredentials from "../ProductManager-Panel/Components/labsCredential";
import ProductManagerLeadsPage from "../ProductManager-Panel/pages/LeadsPage";
import ProductManagerDisposition from "../ProductManager-Panel/pages/leadstatus/filter";
import ProductManagerCustomUploader from "../ProductManager-Panel/pages/custom-uploader";
import ProductManagerCLLeadTransfer from "../ProductManager-Panel/pages/lead-transfer/by-comma";
import ProductManagerCLDispositionExport from "../ProductManager-Panel/pages/leadstatus/export";
import ProductManagerCLDispositionBookingExport from "../ProductManager-Panel/pages/bookingstatus/export";
import ProductManagerCLCalls from "../ProductManager-Panel/tables/callsTable";
import ProductManagerWhatsapp from "../ProductManager-Panel/tables/whatsappMessages";
import ProductManagerCallDetails from "../ProductManager-Panel/tables/callDetails";
import ProductManagerCallStatsTable from "../ProductManager-Panel/tables/callStatsTable";
import ProductManagerCLComplaintsBooking from "../ProductManager-Panel/tables/queryTicketsBooking";
import ProductManagersCLComplaintsLeads from "../ProductManager-Panel/tables/queryTicketsLeads";
import ProductManagerIncorrectNumberLeads from "../ProductManager-Panel/tables/incorrectNumberLeads";
import ProductManagerCLSearchResultsMain from "../ProductManager-Panel/Components/search/SearchResult";
import ProductManagerFillInfoPage from "../ProductManager-Panel/pages/fill-info";
import ProductManagerImaginePackageMaster from "../ProductManager-Panel/Components/ImaginePackageMaster/imaginPackage";
// import ProductManagerQVCCallsExport from "../ProductManager-Panel/filters/quality-recording-filters/export";
import ProductManagerBreakTable from "../ProductManager-Panel/tables/breakDetails";
// import ProductManagerDownload from "../SampleAccessioning-Panel/components/Bookings/DownloadBooking/Tablemy";
import ProductManagerAssignPanelUser from "../ProductManager-Panel/Components/AssignPanelUser";
import ProductManagerCallLogs from "../ProductManager-Panel/Components/CallModal/callModal";
import ProductManagerVerifiedBooking from "../ProductManager-Panel/tables/bookingVerificationTable";
import ProductManagerSMSDetails from "../ProductManager-Panel/tables/smsDetails";
import ProductManagerEMAILDetails from "../ProductManager-Panel/tables/emailDetails";
import ProductManagerTestPackages from "../ProductManager-Panel/pages/testPackages";
import ProductManagerCenterInfo from "../ProductManager-Panel/tables/centerTable";
import ProductManagerLabDetails from "../ProductManager-Panel/Components/LabModal/labDetails";
import ProductManagerCreateLabs from "../ProductManager-Panel/Components/LabModal/CreateLabs";

import PMInbox from "../ProductManager-Panel/Components/notificationInbox/index";
import PMAddAditionalFamily from "../ProductManager-Panel/Components/Bookings/NewBooking/AddFamilyMember";
import PMHelpDesk from "../ProductManager-Panel/pages/HelpDesk";
import PMPackageManagement from "../ProductManager-Panel/pages/packagemanagement";
import PMEditPackageManagement from "../ProductManager-Panel/pages/editpackagemanagement";
import ProductManagerCreateTat from "../ProductManager-Panel/pages/createtat";
import PMCreatePackage from "../ProductManager-Panel/pages/createpackagemanagement";





//Logistics Pane
import LogisticsDash from "../containers/Logistics-panel";
import LogisticHome from "../Logistics-panel/components/Home/home"
import LogisticsFilterPage from "../Logistics-panel/pages/filter";
import LogisticsViewBooking from "../Logistics-panel/components/Bookings/ViewBooking/index";
import LogisticsComplaintsBooking from "../Logistics-panel/tables/queryTicketsBooking";
import LogisticSampleHandover from "../Logistics-panel/tables/sampleHandOverTable";
import LogisticsManageRider from "../Logistics-panel/pages/Manage-Rider/index";
import LogisticsManageRiderAttendance from "../Logistics-panel/components/ManageAttendance/index";
import LogisticsPhaRiderCashDue from "../Logistics-panel/pages/PhleboRiderDue";
import LogisticsDownload from "../Logistics-panel/components/Bookings/ViewBooking/DownloadBooking/Trf";
import LogisticsHelpDesk from "../Logistics-panel/pages/HelpDesk";
import LogisticsStops from "../Logistics-panel/pages/Stops/index";
import LogisticsRoutePlan from "../Logistics-panel/tables/routePlan";
import LogisticsRoutePlanAction from "../Logistics-panel/pages/RoutePlan/index";
import LogisticTatReport from "../Logistics-panel/tables/logisticTatReport";
import LogisticStopCreate from "../Logistics-panel/pages/Stops/createStops";
import Logisticstracking from "../Logistics-panel/pages/routeTracking";
import LogisticsPhleboRiderMap from "../Logistics-panel/pages/phleboRiderMapping";
import LogisticsHomeDx from "../Logistics-panel/tables/homeDxSample";
import LogisticsCCSamples from "../Logistics-panel/tables/CCSample";
import LogisticsDCCampSample from "../Logistics-panel/tables/DcCampSample";
import LogisticsManagemanger from "../Logistics-panel/pages/manageManager";
import LogisticsCreateManager from "../Logistics-panel/pages/createNewManager";
import LogisticsRiderMap from "../Logistics-panel/pages/RiderMap/ridermap";
import LogisticRiderCount from "../Logistics-panel/pages/riderCount";
import LogisticDcCampSampleReceivingList from "../Logistics-panel/pages/DcCamp/dcCampSampleReceivingList";

var jwt = require('jsonwebtoken');
function Routes(props) {
  if (window) {
    var path = window.location.pathname;
    var query = window.location.search;
  }
  return (
    <BrowserRouter>
      <MainLayout>
        <SnackBar />
        <Switch>
          <Route path="/" exact component={Login} />
          <Route
            path="/dashboard"
            exact={false}
            component={() => {
              const token = SecureStorage.getItem("token");
              const decoded = jwt.decode(token);
              if (decoded.user_group === "Logistics") {
                return (
                  <Switch>
                    <Route
                      path="/dashboard"
                      exact
                      component={() => <Dash path={path} query={query} />}
                    />
                    <Route
                      path="/dashboard/lp"
                      exact={false}
                      component={() => {
                        return (
                          <Switch>
                            <LogisticsDash>
                              <Route
                                exact
                                path="/dashboard/lp"
                                component={LogisticHome}
                              />
                              <Route
                                exact
                                path="/dashboard/lp/booking_list"
                                component={LogisticsFilterPage}
                              />


                              <Route
                                exact
                                path="/dashboard/lp/booking-view/:id"
                                component={LogisticsViewBooking}
                              />

                              <Route
                                exact
                                path="/dashboard/lp/download/:id/download"
                                component={LogisticsDownload}
                              />
                              <Route
                                exact
                                path="/dashboard/lp/helpdesk"
                                component={LogisticsHelpDesk}
                              />
                              <Route
                                exact
                                path="/dashboard/lp/stops"
                                component={LogisticsStops}
                              />
                              <Route
                                exact
                                path="/dashboard/lp/route_plan"
                                component={LogisticsRoutePlan}
                              />
                              <Route
                                exact
                                path="/dashboard/lp/phleboridermap"
                                component={LogisticsPhleboRiderMap}
                              />
                              <Route
                                exact
                                path="/dashboard/lp/homedxSample"
                                component={LogisticsHomeDx}
                              />
                              <Route
                                exact
                                path="/dashboard/lp/ccSample"
                                component={LogisticsCCSamples}
                              />
                              <Route
                                exact
                                path="/dashboard/lp/dcCampSample"
                                component={LogisticsDCCampSample}
                              />
                              <Route
                                exact
                                path="/dashboard/lp/manageManger"
                                component={LogisticsManagemanger}
                              />
                              <Route
                                exact
                                path="/dashboard/lp/manage_Manager/:id"
                                component={LogisticsCreateManager}
                              />
                              <Route
                                exact
                                path="/dashboard/lp/route_plan_action/:id"
                                component={LogisticsRoutePlanAction}
                              />
                              <Route
                                exact
                                path="/dashboard/lp/booking-tat-report"
                                component={LogisticTatReport}
                              />
                              <Route
                                exact
                                path="/dashboard/lp/stop_plan_action/:id"
                                component={LogisticStopCreate}
                              />
                              <Route
                                exact
                                path="/dashboard/lp/stop_tracking"
                                component={Logisticstracking}
                              />
                              <Route
                                exact
                                path="/dashboard/lp/booking/tickets"
                                component={LogisticsComplaintsBooking}
                              />
                              <Route
                                exact
                                path="/dashboard/lp/sample_receiving_list"
                                component={LogisticSampleHandover}
                              />
                              <Route
                                exact
                                path="/dashboard/lp/manageRider"
                                component={LogisticsManageRider}
                              />
                              <Route
                                exact
                                path="/dashboard/lp/attendance"
                                component={LogisticsManageRiderAttendance}
                              />
                              <Route
                                exact
                                path="/dashboard/lp/cash-due-on-pha-rider"
                                component={LogisticsPhaRiderCashDue}
                              />
                              <Route
                                exact
                                path="/dashboard/lp/rider_on_map"
                                component={LogisticsRiderMap}
                              />
                               <Route
                                exact
                                path="/dashboard/lp/rider_count"
                                component={LogisticRiderCount}
                              />
                               <Route
                                exact
                                path="/dashboard/lp/dc_camp_recivingList"
                                component={LogisticDcCampSampleReceivingList}
                              />
                              
                            </LogisticsDash>
                          </Switch>
                        );
                      }}
                    />
                    <Route
                      path="*"
                    >
                      <Redirect to="/" />
                    </Route>
                  </Switch>)
              }
              else if (decoded.user_group === "PhleboAdmin") {

                return (
                  <Switch>
                    <Route
                      path="/dashboard"
                      exact
                      component={() => <Dash path={path} query={query} />}
                    />
                    <Route
                      path="/dashboard/pha"
                      exact={false}
                      component={() => {
                        return (
                          <Switch>
                            <PhleboAdminDash>

                              <Route
                                exact
                                path="/dashboard/pha"
                                component={PhleboAdminHomePage}
                              />

                              <Route
                                exact
                                path="/dashboard/pha/booking_list"
                                component={PhleboAdminFilterPage}
                              />
                              {/* <Route
                                exact
                                path="/dashboard/pha/cc_booking_list"
                                component={PhleboAdminFilterOnlinePage}
                              /> */}
                              <Route
                                exact
                                path="/dashboard/pha/assign_booking"
                                component={BookingCard}
                              />
                              <Route
                                exact
                                path="/dashboard/pha/view-booking-on-map"
                                component={ViewBookingOnMap}
                              />
                              <Route
                                exact
                                path="/dashboard/pha/cc-booking-view/:id"
                                component={PhleboAdminOnlineViewBooking}
                              />
                              <Route
                                exact
                                path="/dashboard/pha/booking/new"
                                component={PhleboAdminNewBookingsPage}
                              />
                              <Route
                                path="/dashboard/pha/booking"
                                exact
                                component={PHABookingPage}
                              />
                              <Route
                                exact
                                path="/dashboard/pha/phleboridermap"
                                component={PhleboAdminPhleboRiderMap}
                              />
                              <Route
                                exact
                                path="/dashboard/pha/SampleHandOvers"
                                component={PheboAdminSampleHandover}
                              />
                              <Route
                                exact
                                path="/dashboard/pha/cash-due-on-pha-rider"
                                component={PheboAdminPhaRiderCashDue}
                              />
                              <Route
                                exact
                                path="/dashboard/pha/helpdesk"
                                component={PheboAdminHelpDesk}
                              />
                              <Route
                                exact
                                path="/dashboard/pha/pheboadmin-hiv/:id"
                                component={PheboAdminHIV}
                              />
                              <Route
                                exact
                                path="/dashboard/pha/pheboadmin-BiomarkerScreeningreport/:id"
                                component={PHABiomarkerScreening}
                              />
                              <Route
                                path="/dashboard/pha/booking-imaging-edit/:id/edit"
                                exact
                                component={PHAImagingEditBookingForm}
                              />
                              <Route
                                path="/dashboard/pha/booking-additionalFamily/:id/add"
                                exact
                                component={PHAAddAditionalFamily}
                              />
                              <Route
                                exact
                                path="/dashboard/pha/booking-view/:id"
                                component={PhleboAdminViewBooking}
                              />
                              <Route
                                exact
                                path="/dashboard/pha/booking-edit/:id/edit"
                                component={PhleboAdminEditBookingForm}
                              />
                              <Route
                                exact
                                path="/dashboard/pha/bookings/export"
                                component={PhleboAdminExportBooking}
                              />
                              <Route
                                exact
                                path="/dashboard/pha/phleboBookingView"
                                component={PhaPhleboBookingView}
                              />

                              <Route
                                exact
                                path="/dashboard/pha/timeline/:id/view"
                                component={PhaPleboAdminCommentView}
                              />
                              <Route
                                exact
                                path="/dashboard/pha/download/:id/download"
                                component={PheboAdminTRFDownload}
                              />
                              <Route
                                exact
                                path="/dashboard/pha/zones"
                                component={PHAManageZones}
                              />
                              <Route
                                exact
                                path="/dashboard/pha/attendance"
                                component={ManageAttendance}
                              />
                              <Route
                                exact
                                path="/dashboard/pha/booking/tickets"
                                component={PaComplaintsBooking}
                              />
                              <Route
                                exact
                                path="/dashboard/pha/route_plan"
                                component={PhleboAdminRoutePlan}
                              />
                              <Route
                                exact
                                path="/dashboard/pha/holiday"
                                component={PhleboAdminHoliday}
                              />
                              <Route
                                exact
                                path="/dashboard/pha/call_recording"
                                component={PhleboAdminCallRecording}
                              />
                              <Route
                                exact
                                path="/dashboard/pha/users"
                                component={PhleboAdminManagePhlebo}
                              />
                              <Route
                                exact
                                path="/dashboard/pha/fill-info"
                                component={PhaFillInfoPage}
                              />
                              <Route
                                exact
                                path="/dashboard/pha/pincodes"
                                component={PhleboPinCodePage}
                              />
                              <Route
                                exact
                                path="/dashboard/pha/labDetails"
                                component={PHALabDetails}
                              />
                              <Route
                                exact
                                path="/dashboard/pha/currentDayBooking"
                                component={CurrentDayBooking}
                              />
                              <Route
                                exact
                                path="/dashboard/pha/cityWisePhlebos"
                                component={CityWisePhlebo}
                              />
                              <Route
                                exact
                                path="/dashboard/pha/verifiedbooking"
                                component={PhleboVerifiedBooking}
                              />
                              <Route
                                exact
                                path="/dashboard/pha/availableSlot"
                                component={phleboAvailableSlots}
                              />
                              <Route
                                exact
                                path="/dashboard/pha/centerInfo"
                                component={PHACenterInfo}
                              />

                              <Route
                                exact
                                path="/dashboard/pha/inbox"
                                component={PHAInbox}
                              />
                              <Route
                                exact
                                path="/dashboard/pha/testPackages"
                                component={PhleboAdminTestPackages}
                              />
                              <Route
                                path="/dashboard/pha/batched_payment"
                                exact
                                component={PhleboAdminBatchedPayment}
                              />
                              <Route
                                path="/dashboard/pha/learningmodule"
                                exact
                                component={PHALearningModule}
                              />
                               <Route
                                path="/dashboard/pha/Leadtable"
                                exact
                                component={PHALeadDetails}
                              />
                              {/* <PushNotification/> */}
                            </PhleboAdminDash>
                          </Switch>
                        );
                      }}
                    />
                    <Route
                      path="*"
                    >
                      <Redirect to="/" />
                    </Route>
                  </Switch>)
              }
              else if (decoded.user_group === "RouteManager") {

                return (
                  <Switch>
                    <Route
                      path="/dashboard"
                      exact
                      component={() => <Dash path={path} query={query} />}
                    />
                    <Route
                      path="/dashboard/rm"
                      exact={false}
                      component={() => {
                        return (
                          <Switch>
                            <RouteManagerDash>
                              <Route
                                exact
                                path="/dashboard/rm"
                                component={RouteManagerHome}
                              />
                              <Route
                                exact
                                path="/dashboard/rm/booking_list"
                                component={RouteManagerFilterPage}
                              />
                              {/* <Route
                                exact
                                path="/dashboard/rm/cc_booking_list"
                                component={RouteManagerFilterOnlinePage}
                              /> */}

                              <Route
                                exact
                                path="/dashboard/rm/SampleHandOvers"
                                component={RMSampleHandover}
                              />
                              <Route
                                exact
                                path="/dashboard/rm/cash-due-on-pha-rider"
                                component={RMRiderCashDue}
                              />
                              <Route
                                exact
                                path="/dashboard/rm/bookings_assign"
                                component={RMBookingCard}
                              />
                              <Route
                                exact
                                path="/dashboard/rm/view-booking-on-map"
                                component={RMViewBookingOnMap}
                              />
                              <Route
                                exact
                                path="/dashboard/rm/booking-view/:id"
                                component={RouteManagerViewBooking}
                              />
                              <Route
                                exact
                                path="/dashboard/rm/cc-booking-view/:id"
                                component={RouteManagerOnlineViewBooking}
                              />
                              <Route
                                exact
                                path="/dashboard/rm/route_plan"
                                component={RouteManagerRoutePlan}
                              />
                              <Route
                                exact
                                path="/dashboard/rm/batch-amount-transfer"
                                component={RouteManagerBatchTransferAmount}
                              />
                               <Route
                                exact
                                path="/dashboard/rm/multipleBookingPaymentUpload"
                                component={MultipleBookingPaymentUploadTable}
                              />
                              <Route
                                exact
                                path="/dashboard/rm/booking-edit/:id/edit"
                                component={RouteManagerEditBookingForm}
                              />
                              <Route
                                exact
                                path="/dashboard/rm/bookings/export"
                                component={RouteManagerExportBooking}
                              />
                              <Route
                                exact
                                path="/dashboard/rm/zones"
                                component={RMManageZones}
                              />
                              <Route
                                exact
                                path="/dashboard/rm/attendance"
                                component={RMManageAttendance}
                              />
                              <Route
                                exact
                                path="/dashboard/rm/booking/tickets"
                                component={RMComplaintsBooking}
                              />
                              <Route
                                exact
                                path="/dashboard/rm/call_recording"
                                component={RMCallRecording}
                              />
                              <Route
                                exact
                                path="/dashboard/rm/users"
                                component={RouteManagerManagePhlebo}
                              />
                              <Route
                                exact
                                path="/dashboard/rm/fill-info"
                                component={RMFillInfoPage}
                              />
                              <Route
                                exact
                                path="/dashboard/rm/pincodes"
                                component={RouteManagerPinCodePage}
                              />
                              <Route
                                exact
                                path="/dashboard/rm/currentDayBooking"
                                component={RMCurrentDayBooking}
                              />
                              <Route
                                exact
                                path="/dashboard/rm/cityWisePhlebos"
                                component={RMCityWisePhlebo}
                              />
                              <Route
                                exact
                                path="/dashboard/rm/centerInfo"
                                component={RMCenterInfo}
                              />
                              <Route
                                exact
                                path="/dashboard/rm/inbox"
                                component={RMInbox}
                              />
                              <Route
                                exact
                                path="/dashboard/rm/verifiedbooking"
                                component={RouteManagerVerifiedBooking}
                              />
                               <Route
                                exact
                                path="/dashboard/rm/views"
                                component={Views}
                              />
                              <Route
                                exact
                                path="/dashboard/rm/phleboBookingView"
                                component={RoutePhleboBookingView}
                              />
                              <Route
                                exact
                                path="/dashboard/rm/holiday"
                                component={RouteHoliday}
                              />
                             
                              <Route
                                path="/dashboard/rm/batched_payment"
                                exact
                                component={RouteManagerBatchedPayment}
                              />
                              <Route
                                exact
                                path="/dashboard/rm/timeline/:id/view"
                                component={RoutePleboAdminCommentView}
                              />
                              <Route
                                exact
                                path="/dashboard/rm/phleboridermap"
                                component={RouteManagerPhleboRiderMap}
                              />
                              <Route
                                exact
                                path="/dashboard/rm/testPackages"
                                component={RouteTestPackages}
                              />
                              <Route
                                exact
                                path="/dashboard/rm/download/:id/download"
                                component={RMTRFDownload}
                              />
                              <Route
                                exact
                                path="/dashboard/rm/helpdesk"
                                component={RMHelpDesk}
                              />
                              <Route
                                exact
                                path="/dashboard/rm/routemanager-hiv/:id"
                                component={RouteManagerHIV}
                              />
                              <Route
                                exact
                                path="/dashboard/rm/routemanager-BiomarkerScreeningreport/:id"
                                component={RMBiomarkerScreening}
                              />
                              <Route
                                path="/dashboard/rm/learningmodule"
                                exact
                                component={RMLearningModule}
                              />
                              <Route
                                path="/dashboard/rm/container_delivery_booking"
                                exact
                                component={ContainerDeliveryBooking}
                              />
                            </RouteManagerDash>
                          </Switch>
                        );
                      }}
                    />
                    <Route
                      path="*"
                    >
                      <Redirect to="/" />
                    </Route>
                  </Switch>)
              }

              else if (decoded.user_group === "ProductManager") {

                return (
                  <Switch>
                    <Route
                      path="/dashboard"
                      exact
                      component={() => <Dash path={path} query={query} />}
                    />
                    <Route
                      path="/dashboard/pm"
                      exact={false}
                      component={() => {
                        return (
                          <Switch>
                            <ProductManagerDash>
                              <Route
                                exact
                                path="/dashboard/pm"
                                component={ProductManagerHome}
                              />
                              <Route
                                exact
                                path="/dashboard/pm/booking_list"
                                component={ProductManagerFilterPage}
                              />
                              <Route
                                exact
                                path="/dashboard/pm/dash"
                                component={ProductManagerHomePage}
                              />
                              <Route
                                path="/dashboard/pm/booking"
                                exact
                                component={ProductManagerBookingPage}
                              />
                              <Route
                                exact
                                path="/dashboard/pm/pincodes"
                                component={ProductManagerPinCodePage}
                              />
                              <Route
                                exact
                                path="/dashboard/pm/coupons"
                                component={ProductManagerManageCoupons}
                              />
                              <Route
                                exact
                                path="/dashboard/pm/zones"
                                component={ProductManagerManageZones}
                              />
                              <Route
                                exact
                                path="/dashboard/pm/packages"
                                component={ProductManagerManagePackages}
                              />
                              <Route
                                exact
                                path="/dashboard/pm/packages/edit/:slug"
                                component={ProductManagerEditPackage}
                              />
                              <Route
                                exact
                                path="/dashboard/pm/packages/create"
                                component={ProductManagerCreatePackage}
                              />
                              <Route
                                exact
                                path="/dashboard/pm/users"
                                component={ProductManagerSubAdminPage}
                              />
                              <Route
                                exact
                                path="/dashboard/pm/users/create"
                                component={ProductManagerCreateUser}
                              />
                              <Route
                                exact
                                path="/dashboard/pm/blogs"
                                component={ProductManagerManageBlogs}
                              />
                              <Route
                                exact
                                path="/dashboard/pm/blogs/create"
                                component={ProductManagerCreateBlog}
                              />
                              <Route
                                exact
                                path="/dashboard/pm/blog/:slug"
                                component={ProductManagerEditBlog}
                              />
                              <Route
                                exact
                                path="/dashboard/pm/bookings/export"
                                component={
                                  ProductManagerAdminBookingExport
                                }
                              />
                              <Route
                                exact
                                path="/dashboard/pm/leads/export"
                                component={ProductManagerAdminLeadExport}
                              />
                              <Route
                                exact
                                path="/dashboard/pm/labcredential"
                                component={ProductManagerLabCredentials}
                              />
                              <Route
                                exact
                                path="/dashboard/pm/leads"
                                component={ProductManagerLeadsPage}
                              />
                              <Route
                                exact
                                path="/dashboard/pm/dispositions"
                                component={ProductManagerDisposition}
                              />
                              <Route
                                exact
                                path="/dashboard/pm/uploader"
                                component={ProductManagerCustomUploader}
                              />
                              <Route
                                exact
                                path="/dashboard/pm/leads-transfer"
                                component={ProductManagerCLLeadTransfer}
                              />
                              <Route
                                exact
                                path="/dashboard/pm/dispositions/leadexport"
                                component={
                                  ProductManagerCLDispositionExport
                                }
                              />
                              <Route
                                exact
                                path="/dashboard/pm/dispositions/bookingexport"
                                component={
                                  ProductManagerCLDispositionBookingExport
                                }
                              />
                              <Route
                                exact
                                path="/dashboard/pm/lead/smsDetails"
                                component={ProductManagerSMSDetails}
                              />
                              <Route
                                exact
                                path="/dashboard/pm/lead/emailDetails"
                                component={ProductManagerEMAILDetails}
                              />
                              <Route
                                exact
                                path="/dashboard/pm/calls_details"
                                component={ProductManagerCLCalls}
                              />
                              <Route
                                exact
                                path="/dashboard/pm/whatsapp"
                                component={ProductManagerWhatsapp}
                              />
                              <Route
                                exact
                                path="/dashboard/pm/call_Recording"
                                component={ProductManagerCallDetails}
                              />
                              <Route
                                exact
                                path="/dashboard/pm/calls/stats"
                                component={ProductManagerCallStatsTable}
                              />
                              <Route
                                exact
                                path="/dashboard/pm/booking/tickets"
                                component={
                                  ProductManagerCLComplaintsBooking
                                }
                              />
                              <Route
                                exact
                                path="/dashboard/pm/lead/tickets"
                                component={
                                  ProductManagersCLComplaintsLeads
                                }
                              />
                              <Route
                                exact
                                path="/dashboard/pm/lead/incorrectNumberLead"
                                component={
                                  ProductManagerIncorrectNumberLeads
                                }
                              />
                              <Route
                                exact
                                path="/dashboard/pm/booking-view/:id"
                                component={ProductManagerViewBooking}
                              />
                              <Route
                                exact
                                path="/dashboard/pm/booking-edit/:id/edit"
                                component={ProductManagerEditBookingForm}
                              />
                              <Route
                                exact
                                path="/dashboard/pm/lead/search"
                                component={
                                  ProductManagerCLSearchResultsMain
                                }
                              />
                              {/* <Route
                                      exact
                                      path="/dashboard/pm/calls/export"
                                      component={ProductManagerQVCCallsExport}
                                    /> */}
                              <Route
                                exact
                                path="/dashboard/pm/fill-info"
                                component={ProductManagerFillInfoPage}
                              />
                              <Route
                                path="/dashboard/pm/imaginePackageMaster"
                                exact
                                component={
                                  ProductManagerImaginePackageMaster
                                }
                              />
                              <Route
                                exact
                                path="/dashboard/pm/breakData"
                                component={ProductManagerBreakTable}
                              />
                              {/* <Route
                                      exact
                                      path="/dashboard/pm/download/:id/download"
                                      component={ProductManagerDownload}
                                    /> */}
                              <Route
                                exact
                                path="/dashboard/pm/assignPanelUser"
                                component={ProductManagerAssignPanelUser}
                              />
                              <Route
                                exact
                                path="/dashboard/pm/verifiedbooking"
                                component={ProductManagerVerifiedBooking}
                              />
                              <Route
                                exact
                                path="/dashboard/pm/callLog"
                                component={ProductManagerCallLogs}
                              />
                              <Route
                                exact
                                path="/dashboard/pm/testPackages"
                                component={ProductManagerTestPackages}
                              />
                              <Route
                                exact
                                path="/dashboard/pm/centerInfo"
                                component={ProductManagerCenterInfo}
                              />
                              <Route
                                exact
                                path="/dashboard/pm/labDetails"
                                component={ProductManagerLabDetails}
                              />
                              <Route
                                exact
                                path="/dashboard/pm/CreateLabs"
                                component={ProductManagerCreateLabs}
                              />
                              <Route
                                exact
                                path="/dashboard/pm/inbox"
                                component={PMInbox}
                              />
                              <Route
                                exact
                                path="/dashboard/pm/packagemanagement"
                                component={PMPackageManagement}
                              />
                              <Route
                                exact
                                path="/dashboard/pm/bookings-edit/:id/edit"
                                component={PMEditPackageManagement}
                              />
                              <Route
                                exact
                                path="/dashboard/pm/tat/create/:id"
                                component={ProductManagerCreateTat}
                              />
                              <Route
                                exact
                                path="/dashboard/pm/create/package"
                                component={PMCreatePackage}
                              />
                              <Route
                                path="/dashboard/pm/booking-additionalFamily/:id/add"
                                exact
                                component={PMAddAditionalFamily}
                              />
                              <Route
                                exact
                                path="/dashboard/pm/helpdesk"
                                component={PMHelpDesk}
                              />
                            </ProductManagerDash>
                          </Switch>
                        );
                      }}
                    />
                    <Route
                      path="*"
                    >
                      <Redirect to="/" />
                    </Route>
                  </Switch>)
              }
              else if (decoded.user_group === "EditPackageMaster") {

                return (
                  <Switch>
                    <Route
                      path="/dashboard"
                      exact
                      component={() => <Dash path={path} query={query} />}
                    />
                    <Route
                      path="/dashboard/epm/packagemanagement"
                      exact={false}
                      component={() => {
                        return (
                          <Switch>
                            <EditPackageMasterDash>
                              <Route
                                exact
                                path="/dashboard/epm/packagemanagement"
                                component={EditPackageMasterPackageManagementHome}
                              />
                              <Route
                                exact
                                path="/dashboard/epm/packagemanagement/package-edit/:id/edit"
                                component={EditPackageManagementNameEditPackage}
                              />
                              <Route
                                exact
                                path="/dashboard/epm/create/package"
                                component={EditPackageMasterCreatePackageName}
                              />
                            </EditPackageMasterDash>
                          </Switch>
                        );
                      }}
                    />
                    <Route
                      path="*"
                    >
                      <Redirect to="/" />
                    </Route>
                  </Switch>)
              }
              else if (decoded.user_group === "BulkUploader") {

                return (
                  <Switch>
                    <Route
                      path="/dashboard"
                      exact
                      component={() => <Dash path={path} query={query} />}
                    />
                    <Route
                      path="/dashboard/uploader"
                      exact={false}
                      component={() => {
                        return (
                          <Switch>
                            <BulkUploadMasterDash>
                              <Route
                                path="/dashboard/uploader"
                                exact
                                component={BulkUploadMasterPartnerBookingPage}
                              />
                              <Route
                                path="/dashboard/uploader/cc_booking"
                                exact
                                component={BulkUploadMasterPartnerCCBookingPage}
                              />
                              <Route
                                path="/dashboard/uploader/booking_uploader"
                                exact
                                component={BulkUploadMasterBookingUploader}
                              />
                              <Route
                                path="/dashboard/uploader/partner_uploader"
                                exact
                                component={BulkUploadMasterPartnerUploader}
                              />
                              <Route
                                exact
                                path="/dashboard/uploader/booking-view/:id"
                                component={BulkUploadMasterPartnerViewBooking}
                              />
                              <Route
                                exact
                                path="/dashboard/uploader/ccbooking-view/:id"
                                component={BulkUploadMasterPartnerCCViewBooking}
                              />
                            </BulkUploadMasterDash>
                          </Switch>
                        );
                      }}
                    />
                    <Route
                      path="*"
                    >
                      <Redirect to="/" />
                    </Route>
                  </Switch>)
              }

              else {
                return <Redirect to="/" />;
              }
            }}
          />
        </Switch>

      </MainLayout>
    </BrowserRouter>
  );
}
export default connect(null, { checkUser, messageBox })(Routes);