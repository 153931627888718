import React, { useState, useEffect, useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Chip, Grid, TextField, Typography, Checkbox } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  getBookingById,
  updateBooking,
  getCCPackage,
  postAddRemoveTest,
} from "../../../actions/RouteManagerAction";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Loader from "../../../../components/loader";
import moment from "moment";
import { useHistory, useParams } from "react-router-dom";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      marginTop: "2rem",
      padding: "0.5rem 2rem",
      width: "100%",
    },
    table: {
      maxWidth: "100%",
      margin: "auto",
    },
    packageLink: {
      color: "#ffffff",
      textDecoration: "none",
    },
  })
);

interface Props {
  id: any;
  aid: any;
  type: any;
  updateBooking: any;
  ccpackageList: any;
  postAddRemoveTest: any;
  getCCPackage: any;
  getBookingById: any;
  booking: any;
}

const AddCorporateTest: React.FC<Props> = ({
  id,
  ccpackageList,
  aid,
  type,
  getBookingById,
  updateBooking,
  booking,
  postAddRemoveTest,
  getCCPackage,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const [centre, setCentre] = useState<String>("");
  const [packageKey, setPackageKey] = useState<any>(0);
  const [cityId, setCityId] = useState<any>(booking && booking.cityid);

  const [price, setPrice] = useState<any>(
    booking.discounted_price && booking.discounted_price.total_price_package
  );
  const [discount, setDiscount] = useState<any>(
    booking.discounted_price && booking.discounted_price.counpon_discount
  );
  const [totalPrice, setTotalPrice] = useState<any>();
  const [addnPkgs, setAddnPkgs] = useState<any>([]);

  const [isPaneOpen, setPaneOpen] = useState<boolean>(false);
  const [singlePackage, setSinglePackage] = useState<any>({});
  const [extraCharge, setExtraCharge] = useState<boolean>(
    booking.discounted_price && booking.discounted_price.phlebo_cost !== 0
  );
  const [city, setCity] = useState<any>("");

  const [netPrice, setNetPrice] = useState<any>(0);
  const [inputList, setInputList] = useState<any>([
    {
      customerDesignation: booking?.designation,
      designation: booking?.designation,
      customerName: booking?.customer_name,
      customerAge: booking?.customer_age,
      customerGender: booking?.customer_gender,
      customerPackage: [],
      packagePrice: 0,
      dateOfBirth: "",
    },
  ]);
  const timer = useRef<any>(0);
  useEffect(() => {
    getBookingById(`${id}/`);
  }, []);
  useEffect(() => {
    if (booking.center && booking.cityid) {
      getCCPackage(booking?.center?.id, booking.cityid, "");
      setCentre(booking?.center?.id);
      setCity(booking?.cityid);
    }
  }, [booking]);
  useEffect(() => {
    if (booking?.additional_members !== undefined) {
      booking.additional_members &&
        booking.additional_members.length > 0 &&
        booking.additional_members.map((member: any, index: number) => {
          if (Number(member.id) === Number(aid)) {
            setInputList([
              {
                customerDesignation: member?.designation,
                designation: member?.designation,
                customerName: member?.customer_name,
                customerAge: member?.customer_age,
                customerGender: member?.customer_gender,
                customerPackage: [],
                packagePrice: 0,
                dateOfBirth: "",
              },
            ]);
            setAddnPkgs(member.packages);
          }
        });
    } else {
      setInputList([
        {
          customerDesignation: booking?.designation,
          designation: booking?.designation,
          customerName: booking?.customer_name,
          customerAge: booking?.customer_age,
          customerGender: booking?.customer_gender,
          customerPackage: [],
          packagePrice: 0,
          dateOfBirth: "",
        },
      ]);
    }
  }, [booking, aid]);
  const handlePackage = (index: number, customerPackages: any) => {
    const list = [...inputList];
    list[index]["customerPackage"] = customerPackages;
    setInputList(list);
    let accumulatedPrice: any = 0;
    if (customerPackages && customerPackages.length > 0) {
      customerPackages.map((item: any) => {
        accumulatedPrice = accumulatedPrice + item?.package_lab_prices;
      });
    }
    list[index]["packagePrice"] = accumulatedPrice;
    setInputList(list);

    let allPackagePrice = 0;
    inputList &&
      inputList.map((rowItem: any) => {
        allPackagePrice = allPackagePrice + rowItem?.packagePrice;
      });
    setTotalPrice(Math.floor(allPackagePrice));
  };
  useEffect(() => {
    if (totalPrice < 0) {
      setTotalPrice(0);
    }
  }, [totalPrice]);
  useEffect(() => {
    setNetPrice(totalPrice - discount);
  }, [totalPrice, discount]);

  const submitForm = async () => {
    let packagesIds = new Array();
    inputList &&
      inputList[0]?.customerPackage.map((item: any) => {
        packagesIds.push(item?.id);
      });

    const data: any = {
      id: aid === "aid" ? id : Number(aid),
      type: type,
      operation: "add_package",
      packages: String(packagesIds),
    };

    await postAddRemoveTest(data);
    getBookingById(`${id}/`);
    // history.push(`/dashboard/rm/booking-edit/${booking.pk}/edit`)
  };

  return (
    <div style={{ width: "100%" }}>
      <form
        autoComplete="off"
        style={{ width: "100%", padding: "0 20px 20px 20px" }}
      >
        <Grid container item xs={12}>
          <Grid container item xs={12}>
            <Grid item xs={12} style={{ marginTop: 20 }}>
              <Autocomplete
                id="package"
                key={packageKey}
                onChange={(event, newValue) => {
                  if (newValue) {
                    let packs: any = [];
                    newValue.map((val: any) => {
                      let obj = JSON.parse(JSON.stringify(val, null, " "));
                      packs.push(obj);
                    });
                    handlePackage(0, packs);
                  }
                }}
                multiple
                disableClearable
                options={ccpackageList.results || []}
                limitTags={0}
                // disabled={ccpackageList.results === undefined}
                freeSolo
                disableCloseOnSelect
                aria-required
                onInputChange={(event, newInputValue) => {
                  getCCPackage(
                    booking?.center?.id,
                    booking?.cityid,
                    newInputValue
                  );
                }}
                value={inputList[0]?.customerPackage}
                getOptionLabel={(option: any) => {
                  return (
                    option.name +
                    "(" +
                    option.code +
                    ")" +
                    ", Rs. " +
                    option?.package_lab_prices
                  );
                }}
                getOptionDisabled={(option: any) => {
                  const packages = (inputList[0]?.customerPackage).concat(
                    addnPkgs.length === 0 ? booking?.packages : addnPkgs
                  );

                  const packID = packages.map((pack: any) => pack.id);
                  return packID.includes(option.id);
                }}
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.package_city_prices
                      ? option.name.toString() +
                        ", " +
                        option.package_city_prices.tat_time +
                        ", Rs. " +
                        option?.package_city_prices.offer_price +
                        "/-"
                      : option.name.toString() +
                        ", " +
                        option.tat_time +
                        ", Rs." +
                        option?.package_lab_prices +
                        "/-"}
                  </React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    className="input"
                    {...params}
                    placeholder="Package"
                    variant="outlined"
                    label="Test Name"
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <div className="price-details-new-booking">
                <table style={{ width: "100%", margin: "0" }}>
                  <thead>
                    <tr>
                      <td className="cell" align="center">
                        S No.
                      </td>
                      <td className="cell" align="center">
                        Test code
                      </td>

                      <td className="cell" align="center">
                        Test Name
                      </td>

                      <td className="cell" align="center">
                        TAT
                      </td>
                      <td className="cell" align="center">
                        Price
                      </td>
                      {/* <td className="cell" align="center">
                        Camp Discount
                      </td> */}
                    </tr>
                  </thead>
                  <tbody>
                    {inputList[0]?.customerPackage &&
                      inputList[0]?.customerPackage.length > 0 &&
                      inputList[0]?.customerPackage.map(
                        (packageItem: any, index: any) => {
                          return (
                            <tr>
                              <td className="cell" align="center">
                                {index + 1}
                              </td>
                              <td className="cell" align="center">
                                {packageItem?.code}
                              </td>
                              <td className="cell" align="left">
                                <a
                                  onClick={() => {
                                    setSinglePackage(packageItem);
                                    setPaneOpen(true);
                                  }}
                                  className={classes.packageLink}
                                >
                                  {packageItem?.name}
                                  <br />
                                  Fasting : {packageItem?.fasting_time}
                                </a>
                              </td>
                              <td className="cell" align="center">
                                {packageItem?.tat_time}
                              </td>
                              <td className="cell" align="center">
                                ₹ {packageItem?.package_lab_prices}
                              </td>
                              {/* <td className="cell" align="center">
                                    {campCouponData !== ""
                                      ? campCouponData.packages.map(
                                          (val: any) => {
                                            if (packageItem?.id === val.id) {
                                              return (
                                                "₹" +
                                                Math.floor(
                                                  (packageItem?.package_lab_prices *
                                                    campCouponData.discount) /
                                                    100
                                                )
                                              );
                                            } else {
                                              return "";
                                            }
                                          }
                                        )
                                      : ""}
                                  </td> */}
                            </tr>
                          );
                        }
                      )}

                    <tr>
                      <td colSpan={4}>
                        <table style={{ width: "100%" }}>
                          <tr>
                            <td
                              className="cell"
                              align="left"
                              rowSpan={4}
                              colSpan={2}
                              style={{ width: "60%" }}
                              valign="top"
                            >
                              <Grid container spacing={1} direction="row">
                                {/* <Grid item xs={12}>
                                  <p>Enter Coupon</p>
                                </Grid>
                                <Grid item xs={8}>
                                  {campCouponCode !== "" ? (
                                    <TextField
                                      className="input"
                                      placeholder="Enter Coupon"
                                      value={campCouponCode}
                                      variant="outlined"
                                      style={{
                                        width: "100%",
                                        margin: "0",
                                        padding: "0",
                                      }}
                                    />
                                  ) : (
                                    <Autocomplete
                                      id="coupon"
                                      onChange={(event, newValue) => {
                                        if (newValue) {
                                          let obj = JSON.parse(
                                            JSON.stringify(newValue, null, " ")
                                          );
                                          setCoupon(obj.id as Number);
                                          setCouponCode(obj.code as string);
                                          // setCouponType(obj.is_red_cash);
                                        }
                                      }}
                                      inputValue={couponCode}
                                      options={couponList?.results || []}
                                      freeSolo
                                      blurOnSelect
                                      onInputChange={(event, newInputValue) => {
                                        clearTimeout(timer.current);
                                        setCouponCode(newInputValue);
                                        timer.current = setTimeout(() => {
                                          getCoupons(
                                            `${newInputValue}&org_type=homedx`
                                          );
                                        }, 1000);
                                        if (newInputValue === "") {
                                          setCoupon(0);
                                          setDiscount(0);
                                          setTncMessage("");
                                          setCouponMessage("");
                                        }
                                      }}
                                      getOptionLabel={(option: any) =>
                                        option.code
                                      }
                                      getOptionDisabled={(option: any) =>
                                        option.minimum_price > totalPrice
                                      }
                                      renderInput={(params) => (
                                        <TextField
                                          className="input"
                                          {...params}
                                          placeholder="Enter Coupon"
                                          variant="outlined"
                                          disabled={
                                            inputList[0]?.customerPackage
                                              ?.length === 0
                                          }
                                          style={{
                                            width: "100%",
                                            margin: "0",
                                            padding: "0",
                                          }}
                                        />
                                      )}
                                    />
                                  )}
                                </Grid>
                                <Grid item xs={4}>
                                  <Button
                                    variant="contained"
                                    fullWidth
                                    color="primary"
                                    onClick={applyCoupon}
                                    disabled={campCouponCode !== ""}
                                  >
                                    {campCouponCode !== ""
                                      ? "Camp Coupon Applied"
                                      : "Apply"}
                                  </Button>
                                </Grid>
                                <p
                                  style={{
                                    color: "#8e24aa",
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                    alignItems: "center",
                                  }}
                                >
                                  {couponMessage} <br />
                                  {tncMessage}
                                </p> */}
                              </Grid>
                            </td>
                            <td
                              className="cell"
                              align="right"
                              style={{ width: "20%" }}
                            >
                              Gross
                            </td>
                            <td
                              className="cell"
                              align="center"
                              style={{ width: "20%" }}
                            >
                              ₹ {Math.floor(totalPrice)}
                            </td>
                          </tr>
                          {/* <tr>
                            <td
                              className="cell"
                              align="right"
                              style={{ width: "20%" }}
                            >
                              RedCash Discount
                            </td>
                            <td
                              className="cell"
                              align="center"
                              style={{ width: "20%" }}
                            >
                              ₹ {redDisc > 0 ? Math.floor(redDisc) : 0}
                            </td>
                          </tr>
                          <tr>
                            <td className="cell" align="right">
                              Phlebo Cost
                            </td>
                            <td className="cell" align="center">
                              ₹ {extraCharge ? "+ 200" : "0"}
                            </td>
                          </tr> */}
                          <tr>
                            <td
                              className="cell"
                              align="right"
                              style={{ width: "20%" }}
                            >
                              Discount
                            </td>
                            <td
                              className="cell"
                              align="center"
                              style={{ width: "20%" }}
                            >
                              ₹ {Math.floor(discount)}
                            </td>
                          </tr>
                          <tr>
                            <td className="cell" align="right">
                              <h4 className="totalPrice">Net Amount</h4>
                            </td>
                            <td className="cell" align="center">
                              <h4 className="totalPrice">
                                ₹ {Math.floor(netPrice)}
                              </h4>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Grid>

            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Button
                onClick={() => submitForm()}
                color="primary"
                variant="contained"
              >
                Proceed to Add
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  booking: state.RouteManagerReducer.booking,
  packageList: state.RouteManagerReducer.packageList,
  ccpackageList: state.RouteManagerReducer.ccpackageList,
});

export default connect(mapStateToProps, {
  getBookingById,
  getCCPackage,
  postAddRemoveTest,
  updateBooking,
})(AddCorporateTest);
