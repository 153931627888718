import React, { useState } from "react";
import { useRef } from "react";
import {
  makeStyles,
  Theme,
  createStyles,
  withStyles,
} from "@material-ui/core/styles";
import { connect } from "react-redux";
import { Button, Paper, TextField, Grid, Chip } from "@material-ui/core";

import { useEffect } from "react";
import {
  postStops,
  updateStops,
  getStops,
  getCities,
  getBookingOverview,
  getPhlebos,
  getCenterInfo,
} from "../../actions/LogisticsPanelAction";
import { useHistory } from "react-router-dom";
import Loader from "../../components/loader/index";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { TableFooter, TablePagination } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import BarChartTwoToneIcon from "@mui/icons-material/BarChartTwoTone";
import moment from "moment";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import { getLogisticStopsData } from "../../actions/LogisticsPanelAction";
import { generateStopsFilter } from "../../../helpers/generateUrl";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    table: {
      minWidth: 700,
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      marginTop: "2rem",
      padding: "0.5rem 2rem 2rem 2rem",
      width: "100%",
    },
    contentHeader: {
      width: "100%",
      padding: "0px 10px 40px 10px",
      minHeight: "64px",
    },
    leftContentHeader: {
      width: "50%",
      float: "left",
    },
    rightContentHeader: {
      width: "50%",
      float: "right",
      textAlign: "right",
      paddingRight: "20px",
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    head: {
      textAlign: "center",
      fontWeight: "normal",
      marginBottom: "2rem",
    },
    slotContainer: {
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "center",
    },
    slot: {
      width: "150px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      margin: "0.5rem",
    },
    hoveItem: {
      cursor: "pointer",
    },
    selectedSlot: {
      width: "150px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      background: "linear-gradient(60deg, cyan, #00dfff, cyan)",
      cursor: "pointer",
      margin: "0.5rem",
    },
    chips: {
      display: "flex",
    },
    chip: {
      margin: 2,
      background: "linear-gradient(60deg, cyan, #00cfff, cyan)",
      maxWidth: "130px",
    },
    input: {
      padding: "1px",
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
    card: {
      height: "100%",
      padding: "20px",
    },
    cardContent: {
      textAlign: "center",
    },
    center: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    text: { color: "black" },
  })
);

const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);
interface Props {
  getCities: any;
  cities: any;
  postStops: any;
  updateStops: any;
  getStops: any;
  stopsData: any;
  getLogisticStopsData: any,
  logisticstopsdata: any,
  getBookingOverview: any;
  bookingoverview: any;
  getPhlebos: any;
  phleboList: any;
  getCenterInfo: any;
  centerInfo: any;
  loading: any;
}
const StopsPlace: React.FC<Props> = ({
  getCities,
  getLogisticStopsData,
  logisticstopsdata,
  cities,
  postStops,
  updateStops,
  getStops,
  stopsData,
  getBookingOverview,
  bookingoverview,
  getPhlebos,
  phleboList,
  getCenterInfo,
  centerInfo,
  loading,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const timer = useRef<any>(0);
  const [lab, setLab] = React.useState<any>("");
  const [longitude, setLongitude] = React.useState<any>("");
  const [latitude, setLatitude] = React.useState<any>("");
  const [place, setPlace] = useState<any>("");
  const [city, setCity] = useState<any>("");
  const [citys, setCitys] = useState<any>("");
  const [cityName, setCityName] = useState<any>("");
  const [cityNames, setCityNames] = useState<any>("")
  const [isEdit, setIsEdit] = useState<any>(false);
  const [editId, setEditId] = useState<any>("");
  const [page, setPage] = useState(0);
  const [name, setName] = useState<any>("");
  const [phlebo, setPhlebo] = useState<any>([]);
  const [center, setCentre] = useState<any>("");
  const [cityId, setCityId] = useState<any>("");
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url = stopsData.links && stopsData.links.next.split("?")[1];
      getStops(url);
    } else if (newPage < page) {
      let url = stopsData.links && stopsData.links.previous.split("?")[1];
      getStops(url);
    }
    setPage(newPage as number);
  };
  useEffect(() => {
    getCities();
    getStops();
    getBookingOverview("");
    getPhlebos();
    getCenterInfo();
  }, []);
  const handleEdit = (val: any) => {
    setIsEdit(true);
    setEditId(val.id);
    setPlace(val?.name);
    setLatitude(val?.latitued);
    setLongitude(val?.longitude);
    setCity(val?.city);
    setCityName(val?.city_name);
    setCentre(val?.centre_name);
    setPhlebo(val?.phlebos_name);
  };
  const filterStopsData = (e: any) => {
    const body: any = {
      name,
      // city__id: citys,
      panel_users: phlebo,
      cc: center,
      city: cityId,

    };
    const url = generateStopsFilter(body).substring(2);
    getStops(url);
    setPage(0);
  };
  const onKeyDown = (e: any) => {
    if (e.key === "Enter") {
      filterStopsData(e);
    }
  };
  const handleSearch = async (e: any) => {
    e.preventDefault();
    // getStops(`name=${stopsData}`);
  };
  return (
    <Grid container spacing={2} style={{ marginTop: 50 }}>
      <Grid container spacing={3}>
        <Grid item xs={10}>
        <h4 style={{ fontWeight: "bold" }}>STOPS</h4>
        </Grid>
        {/* <Grid item xs={2}>
          <Button
            color="primary"
            variant="contained"
            onClick={() =>
              history.push(`/dashboard/lp/stop_plan_action/create`)
            }
          >
            Create New Stops
          </Button>
        </Grid> */}
        <form style={{ width: "100%", marginLeft: 14 }} onSubmit={handleSearch}>
          {/* <Grid container spacing={3}>
            <Grid item xs={12} lg={3} sm={6}>
              <Card className={classes.card}>
                <Grid container>
                  <Grid item sm={3}>
                    <Avatar
                      sx={{ bgcolor: "#ffeff5", marginTop: "8px" }}
                      aria-label="recipe"
                    >
                      <BarChartTwoToneIcon style={{ color: "#fa4d83" }} />
                    </Avatar>
                  </Grid>
                  <Grid item sm={9}>
                    <h5>{bookingoverview?.total_stops}</h5>
                    <span style={{ color: "grey" }}>Total Stops</span>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12} lg={3} sm={6}>
              <Card className={classes.card}>
                <Grid container>
                  <Grid item sm={3}>
                    <Avatar
                      sx={{ bgcolor: "#ffeff5", marginTop: "8px" }}
                      aria-label="recipe"
                    >
                      <BarChartTwoToneIcon style={{ color: "#fa4d83" }} />
                    </Avatar>
                  </Grid>
                  <Grid item sm={9}>
                    <h5>{bookingoverview.total_active_stops}</h5>
                    <span style={{ color: "grey" }}>Active Stops</span>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12} lg={3} sm={6}>
              <Card className={classes.card}>
                <Grid container>
                  <Grid item sm={3}>
                    <Avatar
                      sx={{ bgcolor: "#ffeff5", marginTop: "8px" }}
                      aria-label="recipe"
                    >
                      <BarChartTwoToneIcon style={{ color: "#fa4d83" }} />
                    </Avatar>
                  </Grid>
                  <Grid item sm={9}>
                    <h5>{bookingoverview.total_inactive_stops}</h5>
                    <span style={{ color: "grey" }}>Inactive Stops</span>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <Button
                color="primary"
                variant="contained"
                fullWidth
                style={{ marginTop: 15, height: "70%" }}
                onClick={() =>
                  history.push(`/dashboard/lp/stop_plan_action/create`)
                }
              >
                Create New Stops
              </Button>
            </Grid>
          </Grid> */}
        </form>
      </Grid>
      <Grid item xs={12}>
        <div style={{ display: "flex", width: "100%", marginTop: 10 }}>
          {/* <hr style={{ width: "100%", marginTop: 10 }} /> */}
        </div>
      </Grid>
      <Grid
        container
        spacing={1}
        direction="row"
      >

        <Grid item xs={12} sm={6} md={3}>
          <TextField
            className="input"
            name="name"
            type="text"
            label="Stop Name"
            value={name}
            variant="outlined"
            onChange={(e) => setName(e.target.value as string)}
            style={{ width: "100%" }}
            onKeyDown={onKeyDown}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
        <Autocomplete
                        id="city"
                        onChange={(event, newValue) => {
                            if (newValue) {
                                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                setCityId(obj.id)
                            }
                        }}
                        options={cities}
                        freeSolo
                        blurOnSelect
                        aria-required
                        getOptionLabel={(option: any) => option.name}
                        onInputChange={(event, newInputValue) => {
                            clearTimeout(timer.current)
                            timer.current = setTimeout(() => {
                                getCities(`${newInputValue}`)
                            }, 1000)
                            if (newInputValue.length === 0) {
                                setCityId("")
                            }
                        }}
                        renderInput={(params) => (
                            <TextField className="input"
                                {...params}
                                placeholder="Select City"
                                variant="outlined"
                                style={{ width: "100%", margin: "0", padding: "0" }}
                                required
                            />
                        )}
                    />
        </Grid>
        {/* <Grid item xs={12} sm={4} md={2}>
          <Autocomplete
            id="center_type"
            onChange={(event, newValue) => {
              if (newValue) {
                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                setCentre(obj.id);
              }
            }}
            freeSolo
            blurOnSelect
            options={centerInfo?.results}
            disableCloseOnSelect
            getOptionLabel={(option: any) =>
              (option?.display_name === null
                ? option?.name
                : option?.display_name) +
              "(" +
              option?.center_code +
              ")"
            }
            onInputChange={(event, newInputValue) => {
              getCenterInfo(`&name_or_code=${newInputValue}`);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Center"
                className="input"
                variant="outlined"
                fullWidth
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Autocomplete
            id="phleboName"
            onChange={(event, newValue) => {
              if (newValue) {
                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                setPhlebo(obj.id);
              }
            }}
            options={
              phleboList.results
                ? [
                  ...phleboList.results,
                  { id: 0, user: { username: "NoPhlebo" } },
                ]
                : []
            }
            freeSolo
            blurOnSelect
            aria-required
            getOptionLabel={(option: any) =>
              option.user && option.user.username
            }
            onInputChange={(event, newInputValue) => {
              clearTimeout(timer.current);
              timer.current = setTimeout(() => {
                getPhlebos(`${newInputValue}`);
              }, 1000);
              if (newInputValue.length === 0) {
                setPhlebo([]);
              }
            }}
            renderInput={(params) => (
              <TextField
                className="input"
                {...params}
                placeholder="Phlebo Name"
                variant="outlined"
                style={{ width: "100%", margin: "0", padding: "0" }}
                required
              />
            )}
          />
        </Grid> */}
        <Grid item xs={12} sm={6} md={3}>
          <Button
            variant="contained"
            color="secondary"
            disabled={loading}
            fullWidth
            onClick={filterStopsData}
          >
            Filter
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Button
            variant="contained"
            color="primary"
          
            fullWidth
            onClick={() => history.push("/dashboard/lp/stops")}
          >
            Reset
          </Button>
        </Grid>
      </Grid>

      <Grid container style={{ marginTop: 10 }}>
        <TableContainer
          className={classes.tableContainer}
          component={Paper}
          style={{ maxHeight: "540px" }}
        >
          {loading ? (
            <Loader />
          ) : (
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell align="center">S. No.</StyledTableCell>
                  <StyledTableCell align="center">Stop Name</StyledTableCell>
                  <StyledTableCell align="center">City</StyledTableCell>
                  {/* <StyledTableCell align="center">Status</StyledTableCell> */}
                  <StyledTableCell align="center">Action</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {stopsData?.results &&
                  stopsData.results?.length > 0 &&
                  stopsData?.results.map((row: any, index: number) => {
                    return (
                      <StyledTableRow key={index}>
                        <StyledTableCell align="center">
                          {index + 1}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row?.name}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row?.city_name}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={() =>
                              history.push(
                                `/dashboard/lp/stop_plan_action/${row?.id}`
                              )
                            }
                          >
                            edit
                          </Button>
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
              </TableBody>
              <TableFooter>
                <TablePagination
                  rowsPerPageOptions={[]}
                  colSpan={3}
                  count={stopsData?.count}
                  rowsPerPage={stopsData.page_size}
                  page={page}
                  onPageChange={handleChangePage}
                />
              </TableFooter>
            </Table>
          )}
        </TableContainer>
      </Grid>
    </Grid>
  );
};
const mapStateToProps = (state: any) => ({
  cities: state.LogisticsReducer.cities,
  stopsData: state.LogisticsReducer.stopsData,
  loading: state.LogisticsReducer.loading,
  bookingoverview: state.LogisticsReducer.bookingoverview,
  centerInfo: state.LogisticsReducer.centerInfo,
  phleboList: state.LogisticsReducer.phleboList,
});
export default connect(mapStateToProps, {
  getStops,
  postStops,
  updateStops,
  getCities,
  getBookingOverview,
  getPhlebos,
  getCenterInfo,
})(StopsPlace);
