import React, { useEffect } from "react";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from '@mui/material/Modal';
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
    getZoneComments,
} from "../../../PhleboAdmin-Panel/actions/PhleboAdmin";
import "./index.sass";
import { resourceLimits } from "worker_threads";
let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: isMobile ? "400px" : "1200px",
    },
}));
type ModalProps = {
    openModal: any;
    setopenModal: any;
    areaComments: any;
    loading: Boolean;
};

const CommentsModal: React.FC<ModalProps> = ({
    loading,
    openModal,
    setopenModal,
    areaComments,
}) => {
    const classes = useStyles();

    const [comment, setComment] = React.useState<string>("");

    const handleClose = () => {
        setopenModal(false);
    };

    // useEffect(() => {
    //     if (zoneId !== 0) {
    //         getZoneComments(zoneId);
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [openModal]);
    return (
        <div>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openModal}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openModal}>
                    <div className={classes.paper}>
                        <h6
                            style={{
                                marginBottom: "10px",
                                textAlign: "center",
                                borderBottom: "1px solid rgba(0,0,0,0.5)",
                            }}
                            id="transition-modal-title"
                        >
                            Comment History
                        </h6>
                        <div
                            id="transition-modal-description"
                            className="modal-form-wrapper"
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "100%",
                            }}
                        >
                            <div className="comment-history">
                                {loading
                                    ? "Please Wait"
                                    : areaComments && areaComments?.results? (
                                        areaComments?.results.map((comment: any) => {
                                            return (
                                                <p>
                                                    {comment.created_at.substring(0, 10)} :{" "}
                                                    {comment.created_at.substring(11, 19)} :{" "}
                                                    {comment.by_user} : {comment.comment}:{" "}
                                                </p>
                                            )
                                        })
                                    ) : ""}
                            </div>
                            {/* {user?.user?.user_group !== "CustomerExecutive" ? ( */}
                            {/* <form className="modal-form chat-form" onSubmit={submitForm}>
                                <TextareaAutosize
                                    aria-label="minimum height"
                                    minRows={3}
                                    placeholder="Add Comment.."
                                    value={comment}
                                    onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                                        setComment(e.target.value as string)
                                    }
                                    style={{ width: "100%", marginRight: "10px" }}
                                />
                                <button style={{ width: "20%" }}>
                                    <p>
                                        {loading ? (
                                            <CircularProgress style={{ color: "#fff" }} size={24} />
                                        ) : (
                                            "Add Comment"
                                        )}
                                    </p>
                                </button>
                            </form>
                            {/* ) : null} */}
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
};

const mapStateToProps = (state: any) => ({
});

export default connect(mapStateToProps, {
})(CommentsModal);
