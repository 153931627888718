import React, { useEffect } from "react";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@mui/material/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import CircularProgress from "@material-ui/core/CircularProgress";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import ZoomInMapIcon from "@mui/icons-material/ZoomInMap";
import Stack from "@mui/material/Stack";
import moment from "moment";
import { addTicketsComment } from "../../../actions/loginActions";
import {
  getTicketComments,
} from "../../actions/RouteManagerAction";
import "./index.sass";

let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: isMobile ? "400px" : "1200px",
  },
}));

type ModalProps = {
  openModal: boolean;
  setopenModal: Function;
  bookingId: any;
  loading: boolean;
  user: any;
  ticketComments: any;
  getTicketComments: any;
  addTicketsComment: any;
  bookingListLoader: any;
};

const CommentsModal: React.FC<ModalProps> = ({
  openModal,
  setopenModal,
  bookingId,
  loading,
  ticketComments,
  getTicketComments,
  addTicketsComment,
  user,
  bookingListLoader,
}) => {
  const classes = useStyles();

  const [comment, setComment] = React.useState<string>("");
  const [maxHeight, setMaxHeight] = React.useState<any>(300);

  const handleClose = () => {
    setopenModal(false);
  };

  useEffect(() => {
    if (bookingId !== "" && openModal) {
      getTicketComments(`${bookingId}/`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal]);

  const submitForm = async (e: any) => {
    e.preventDefault();
    if (comment === "") {
      return;
    } else {
      let body = {
        comments: comment,
        ticket_info: bookingId,
      };
      await addTicketsComment(body);
      setComment("");
      getTicketComments(`${bookingId}/`);
    }
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openModal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <div className={classes.paper}>
            <h6
              style={{
                marginBottom: "10px",
                textAlign: "center",
                borderBottom: "1px solid rgba(0,0,0,0.5)",
              }}
              id="transition-modal-title"
            >
              Comment History
              {Number(maxHeight) === Number(300) ? (
                <Stack
                  direction="row"
                  spacing={2}
                  style={{ display: "block", textAlign: "end" }}
                >
                  <ZoomOutMapIcon
                    fontSize="large"
                    onClick={() =>
                      Number(maxHeight) === Number(600)
                        ? setMaxHeight(Number(600))
                        : setMaxHeight(Number(600))
                    }
                  />
                </Stack>
              ) : (
                <Stack
                  direction="row"
                  spacing={2}
                  style={{ display: "block", textAlign: "end" }}
                >
                  <ZoomInMapIcon
                    fontSize="large"
                    onClick={() =>
                      Number(maxHeight) === Number(300)
                        ? setMaxHeight(Number(300))
                        : setMaxHeight(Number(300))
                    }
                  />
                </Stack>
              )}
            </h6>
            <div
              id="transition-modal-description"
              className="modal-form-wrapper"
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                height: `${maxHeight}px`,
              }}
            >
              <div
                className="comment-history"
                style={{ height: `${maxHeight}px`, minHeight: "150px" }}
              >
                {loading
                  ? "Please Wait"
                  : Object.keys(ticketComments).length > 0
                  ? ticketComments.map((comment: any) => (
                      <p style={{ backgroundColor: comment?.action !== "manual" ? "" : "yellow", paddingLeft: "10px" }}>
                        {moment(comment.created_at).format('MMMM Do YYYY, h:mm:ss a')} <b>-</b> {" "}
                        {comment.comments} <b>{comment.disposition ? ` : ${comment.disposition}` : ""}</b>
                      </p>
                    ))
                  : ""}
              </div>

              <form className="modal-form chat-form" onSubmit={submitForm} style={{ padding: "0px" }}>
                <TextareaAutosize
                  aria-label="minimum height"
                  minRows={3}
                  placeholder="Add Comment.."
                  value={comment}
                  onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                    setComment(e.target.value as string)
                  }
                  style={{ width: "100%" }}
                />
                <button style={{ width: "20%", backgroundColor: comment === "" ? "gray" : "#0a3ab5b8", color: "white", marginTop: "10px", paddingTop: "10px", fontFamily: "revert", fontWeight: "bold" }} disabled={ comment === "" }>
                  <p>
                    {loading ? (
                      <CircularProgress style={{ color: "#fff" }} size={24} />
                    ) : (
                      "Add Comment"
                    )}
                  </p>
                </button>
              </form>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  ticketComments: state.RouteManagerReducer.ticketComments,
  loading: state.RouteManagerReducer.loading,
  user: state.loginReducer.user,
  bookingListLoader: state.loginReducer.bookingListLoader,
});

export default connect(mapStateToProps, {
  getTicketComments,
  addTicketsComment,
})(CommentsModal);
