import {
  ACTIONS,
  PhleboAdminTypes,
} from "../interfaces/actionTypes/LogisticsType";

type InitialState = {
  pin_code: Array<any>;
  cities: Array<any>;
  areaList: Array<any>;
  agentsList: Array<any>;
  phleboList: Array<any>;
  packageList: Array<any>;
  partnerList: Array<any>;
  couponList: Array<any>;
  createBooking: Object;
  booking: Object;
  todays_positive_calls: Object;
  lead_details: Object;
  leadById: Object;
  leads: Array<any>;
  bookingList: Array<any>;
  comment: any;
  data: any;
  users: any;
  bookingsComment: any;
  lead_id: number;
  read_call_details: object;
  all_recordings: object;
  whatsappComments: object;
  whatsappCommentsByLead: object;
  additional_booking: object;
  callStats: object;
  complaint: object;
  complaints: object;
  lead_source: any;
  booking_slots: object;
  search_results: object;
  call_status: object;
  all_lead: object;
  call_transfer: object;
  user_panel: object;
  bookingComplaints: Array<any>;
  agentBYUserGroup: object;
  syncData: Object;
  tube_details: Array<any>;
  bookingReceivedPackage: Array<any>;
  smsReportSend: Array<any>;
  loading: boolean;
  loadingroutes: boolean;
  unmaskedNumber: Array<any>;
  sampleHandOverData: Array<any>;
  phleboBatchTube: Array<any>;
  bookingoverview: Array<any>;
  riderList: Array<any>;
  sampleJourneyComments: Object;
  riderData: Array<any>;
  riderCommentsJourney: Array<any>;
  disposition: Array<any>;
  riderTravelRecords: Array<any>;
  riderRound: Array<any>;
  riderAttendance: Array<any>;
  bookingByBarcode: Array<any>;
  paymentInformation: Array<any>;
  reportDownload: Array<any>;
  reportStatus: Array<any>;
  notificationMessage: Array<any>;
  userDetails: Array<any>;
  labDetails: Array<any>;
  phlebo_rider_due: Array<any>;
  additionMember: Array<any>;
  qrcode: Array<any>;
  signature: Array<any>;
  ticketData: Array<any>;
  ticketCategories: Array<any>;
  ticketSubCategories: Array<any>;
  redtechcomment: Array<any>;
  logistictatdata: Array<any>;
  logisticstopsdata: Array<any>;
  stopsData: Array<any>;
  routesData: Array<any>;
  batchbookingamount: Array<any>;
  centerInfo: Array<any>;
  phleboridermap: Array<any>;
  ticketComments: Array<any>;
  geoFencesMapMyIndia: Array<any>;
  bookingListLoader: boolean;
  mapmyindiaAccessToken: Object;
  geoLoading: boolean;
  geoCheckError: string;
  riderActiveLog: Array<any>;
  teamLeadList: Array<any>;
  language: Array<any>;
  usersList: Array<any>;
  ozontelskilldata: Array<any>;
  dialer: Array<any>;
  orgTypesList: Array<any>;
  chatSkillsList: Array<any>;
  centreData: Object;
  sampleCash: Array<any>;
  stopsCount: Array<any>;
  sampleCount: Array<any>;
  routeListData: Array<any>;
  riderOnMap: Array<any>;
  riderMapMyIndia: Array<any>;
  dcCampList: Array<any>;
  tubeInfoData: Array<any>;
  riderCount: Array<any>;
  labCredential:Array<any>;
};

const initialState: InitialState = {
  stopsData: [],
  routesData: [],
  pin_code: [],
  cities: [],
  batchbookingamount: [],
  areaList: [],
  agentsList: [],
  phleboList: [],
  packageList: [],
  partnerList: [],
  couponList: [],
  phleboBatchTube: [],
  createBooking: {},
  additional_booking: {},
  booking: {},
  lead_details: {},
  leadById: {},
  todays_positive_calls: {},
  leads: [],
  bookingList: [],
  comment: {},
  data: {},
  users: {},
  loading: false,
  loadingroutes: false,
  read_call_details: {},
  all_recordings: {},
  whatsappComments: {},
  whatsappCommentsByLead: {},
  callStats: {},
  complaint: {},
  complaints: {},
  bookingsComment: {},
  lead_id: 0,
  lead_source: {},
  booking_slots: {},
  search_results: {},
  call_status: {},
  all_lead: {},
  call_transfer: {},
  mapmyindiaAccessToken: {},
  agentBYUserGroup: {},
  user_panel: {},
  tube_details: [],
  syncData: [],
  bookingReceivedPackage: [],
  smsReportSend: [],
  unmaskedNumber: [],
  sampleHandOverData: [],
  riderList: [],
  sampleJourneyComments: {},
  riderData: [],
  bookingoverview: [],
  riderCommentsJourney: [],
  riderTravelRecords: [],
  riderRound: [],
  riderAttendance: [],
  bookingByBarcode: [],
  paymentInformation: [],
  reportDownload: [],
  reportStatus: [],
  notificationMessage: [],
  userDetails: [],
  labDetails: [],
  phlebo_rider_due: [],
  additionMember: [],
  qrcode: [],
  signature: [],
  ticketData: [],
  ticketCategories: [],
  ticketSubCategories: [],
  redtechcomment: [],
  logistictatdata: [],
  logisticstopsdata: [],
  centerInfo: [],
  phleboridermap: [],
  disposition: [],
  bookingComplaints: [],
  ticketComments: [],
  geoFencesMapMyIndia: [],
  bookingListLoader: false,
  geoLoading: false,
  geoCheckError: "",
  riderActiveLog: [],
  teamLeadList: [],
  language: [],
  usersList: [],
  ozontelskilldata: [],
  dialer: [],
  orgTypesList: [],
  chatSkillsList: [],
  centreData: {},
  sampleCash: [],
  stopsCount: [],
  sampleCount: [],
  routeListData: [],
  riderOnMap: [],
  riderMapMyIndia: [],
  dcCampList: [],
  tubeInfoData: [],
  riderCount: [],
  labCredential: [],
};

const CustomerLeadReducer = (
  state = initialState,
  action: PhleboAdminTypes
) => {
  switch (action.type) {
    case ACTIONS.GET_PIN_CODE:
      return {
        ...state,
        pin_code: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CENTER:
      return {
        ...state,
        centreData: action.payload,
        loading: false,
      };
    case ACTIONS.GET_RIDER_MAP_MY_INDIA:
      return {
        ...state,
        riderMapMyIndia: action.payload,
        loading: false,
      };
    case ACTIONS.GET_RIDER_ON_MAP:
      return {
        ...state,
        riderOnMap: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ROUTES_LIST_DATA:
      return {
        ...state,
        routeListData: action.payload,
        loading: false,
      };
    case ACTIONS.GET_SAMPLE_COUNT:
      return {
        ...state,
        sampleCount: action.payload,
        loading: false,
      };
    case ACTIONS.GET_STOP_COUNT:
      return {
        ...state,
        stopsCount: action.payload,
        loading: false,
      };
    case ACTIONS.GET_SAMPLE_CASH:
      return {
        ...state,
        sampleCash: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CHAT_SKILLS_LISTS:
      return {
        ...state,
        chatSkillsList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ORG_TYPES:
      return {
        ...state,
        orgTypesList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_DIALER:
      return {
        ...state,
        dialer: action.payload,
        loading: false,
      };
    case ACTIONS.GET_OZONTEL_SKILL_DATA:
      return {
        ...state,
        ozontelskilldata: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ALL_USERS:
      return {
        ...state,
        usersList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LANGUAGE:
      return {
        ...state,
        language: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PANEL_USER_LIST_DATA_TEAM_LEAD:
      return {
        ...state,
        teamLeadList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CENTER_INFORMATION:
      return {
        ...state,
        centerInfo: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PHLEBO_RIDER:
      return {
        ...state,
        phleboridermap: action.payload,
        loading: false,
      };
    case ACTIONS.UPLOAD_FILE:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case ACTIONS.GET_RIDER_ACTIVE_LOGS:
      return {
        ...state,
        riderActiveLog: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PHLEBOS:
      return {
        ...state,
        phleboList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BATCH_BOOKING_AMOUNT:
      return {
        ...state,
        batchbookingamount: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CITIES:
      return {
        ...state,
        cities: action.payload,
        loading: false,
      };
    case ACTIONS.GET_MAMPMYINDIA_ACCESS_TOKEN:
      return {
        ...state,
        mapmyindiaAccessToken: action.payload,
        loading: false,
      };
    case ACTIONS.GET_AREA:
      return {
        ...state,
        areaList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_POSTIVE_CALLS_COMMENTS:
      return {
        ...state,
        todays_positive_calls: action.payload,
        loading: false,
      };
    case ACTIONS.SET_COMMENTS:
      return {
        ...state,
        comment: action.payload,
        loading: false,
      };
    case ACTIONS.ADD_POSTIVE_CALLS_COMMENT:
      return {
        ...state,
        todays_positive_calls: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LEAD_DETAILS:
      return {
        ...state,
        lead_details: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LEADS:
      return {
        ...state,
        leads: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LEAD_BY_ID:
      return {
        ...state,
        leadById: action.payload,
        loading: false,
      };
    case ACTIONS.LEAD_DETAILS_RESET:
      return {
        ...state,
        leadById: {},
        lead_details: {},
        stopsData: [],
        loading: false,
      };
    case ACTIONS.GET_AGENTS:
      return {
        ...state,
        agentsList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_STOPS:
      return {
        ...state,
        stopsData: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ROUTES:
      return {
        ...state,
        routesData: action.payload,
        loadingroutes: false,
      };
    case ACTIONS.GET_AGENTS_BY_USERGROUP:
      return {
        ...state,
        agentBYUserGroup: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PACKAGES:
      return {
        ...state,
        packageList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PARTNERS:
      return {
        ...state,
        partnerList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_COUPONS:
      return {
        ...state,
        couponList: action.payload,
        loading: false,
      };
    case ACTIONS.CREATE_BOOKING:
      return {
        ...state,
        createBooking: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BOOKING_LIST:
      return {
        ...state,
        bookingList: action.payload,
        bookingListLoader: false,
      };
    case ACTIONS.GET_BOOKING_BY_ID:
      return {
        ...state,
        booking: action.payload,
        loading: false,
      };
    case ACTIONS.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case ACTIONS.SET_LOADING_ROUTES:
      return {
        ...state,
        loadingroutes: action.payload,
      };
    case ACTIONS.CREATE_CALL:
      return {
        ...state,
        loading: false,
        lead_id: action.payload,
      };
    case ACTIONS.GET_RECORDINGS:
      return {
        ...state,
        all_recordings: action.payload,
        loading: false,
      };
    case ACTIONS.CREATE_ADDITIONAL_BOOKING:
      return {
        ...state,
        additional_booking: action.payload,
        loading: false,
      };
    case ACTIONS.UPDATE_ADDITIONAL_BOOKING:
      return {
        ...state,
        additional_booking: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LEAD_SOURCE:
      return {
        ...state,
        lead_source: action.payload,
        loading: false,
      };
    case ACTIONS.SEARCH_CALL:
      return {
        ...state,
        search_results: action.payload,
        loading: false,
      };
    case ACTIONS.ADD_COMMENT:
      return {
        ...state,
        comments: action.payload,
        loading: false,
      };
    case ACTIONS.BOOKING_RESET:
      return {
        ...state,
        createBooking: {},
        bookingList: [],
        batchbookingamount: [],
        loading: false,
      };
    case ACTIONS.GET_BOOKINGS_COMMENTS:
      return {
        ...state,
        bookingsComment: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BOOKING_OVERVIEW:
      return {
        ...state,
        bookingoverview: action.payload,
        loading: false,
      };
    case ACTIONS.ADD_BOOKINGS_COMMENT:
      return {
        ...state,
        bookingsComment: action.payload,
        loading: false,
      };
    case ACTIONS.GET_WHATSAPP_COMMENTS:
      return {
        ...state,
        whatsappComments: action.payload,
        loading: false,
      };
    case ACTIONS.GET_WHATSAPP_COMMENTS_BY_LEAD:
      return {
        ...state,
        whatsappCommentsByLead: action.payload,
        loading: false,
      };
    case ACTIONS.SEND_WHATSAPP_COMMENTS:
      return {
        ...state,
        whatsappComments: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LOGISTIC_TAT_DATA:
      return {
        ...state,
        logistictatdata: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LOGISTIC_STOPS_DATA:
      return {
        ...state,
        logisticstopsdata: action.payload,
        loading: false,
      };
    case ACTIONS.GET_RED_TECH_COMMENT:
      return {
        ...state,
        redtechcomment: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CALL_STATS:
      return {
        ...state,
        callStats: action.payload,
        loading: false,
      };
    case ACTIONS.RAISE_COMPLAINT:
      return {
        ...state,
        complaint: action.payload,
        loading: false,
      };
    case ACTIONS.GET_COMPLAINTS:
      return {
        ...state,
        complaints: action.payload,
        loading: false,
      };
    case ACTIONS.ASSIGN_DOCTOR:
      return {
        ...state,
        success: true,
        loading: false,
      };
    case ACTIONS.SEND_FEEDBACK:
      return {
        ...state,
        success: true,
        loading: false,
      };
    case ACTIONS.GET_AVAILABLE_SLOTS:
      return {
        ...state,
        booking_slots: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ALL_LEADS:
      return {
        ...state,
        all_lead: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PANEL_USERS:
      return {
        ...state,
        user_panel: action.payload,
        loading: false,
      };
    case ACTIONS.LEAD_TRANSFER_BY_COMMA:
      return {
        ...state,
        all_lead: action.payload,
        loading: false,
      };
    case ACTIONS.GET_SYNC_DATA:
      return {
        ...state,
        syncData: action.payload,
        loading: false,
      };
    case ACTIONS.GET_TUBE_DETAILS:
      return {
        ...state,
        tube_details: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BOOKING_RECEIVED_PACKAGE:
      return {
        ...state,
        bookingReceivedPackage: action.payload,
        loading: false,
      };
    case ACTIONS.GET_SMS_REPORT_SEND:
      return {
        ...state,
        smsReportSend: action.payload,
        loading: false,
      };
    case ACTIONS.GET_UNMASKED_NUMBER:
      return {
        ...state,
        unmaskedNumber: action.payload,
        loading: false,
      };
    case ACTIONS.GET_SAMPLE_HANDOVER_DATA:
      return {
        ...state,
        sampleHandOverData: action.payload,
        loading: false,
      };
    case ACTIONS.GET_RIDER_LIST:
      return {
        ...state,
        riderList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_SAMPLE_JOURNEY_COMMENTS:
      return {
        ...state,
        sampleJourneyComments: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PHLENO_BATCH_TUBE:
      return {
        ...state,
        phleboBatchTube: action.payload,
        loading: false,
      };
    case ACTIONS.GET_RIDER_DATA:
      return {
        ...state,
        riderData: action.payload,
        loading: false,
      };
    case ACTIONS.GET_RIDER_COUNT:
      return {
        ...state,
        riderCount: action.payload,
        loading: false,
      };
    case ACTIONS.GET_RIDER_JOURNEY_COMMENTS:
      return {
        ...state,
        riderCommentsJourney: action.payload,
        loading: false,
      };
    case ACTIONS.GET_RIDER_TRAVEL_RECORD:
      return {
        ...state,
        riderTravelRecords: action.payload,
        loading: false,
      };
    case ACTIONS.GET_RIDER_ROUND:
      return {
        ...state,
        riderRound: action.payload,
        loading: false,
      };
    case ACTIONS.GET_RIDER_ATTENDANCE:
      return {
        ...state,
        riderAttendance: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BOOKING_BY_BARCODE:
      return {
        ...state,
        bookingByBarcode: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PAYMENT_INFORMATION:
      return {
        ...state,
        paymentInformation: action.payload,
        loading: false,
      };
    case ACTIONS.GET_REPORT_DOWNLOAD:
      return {
        ...state,
        reportDownload: action.payload,
        loading: false,
      };
    case ACTIONS.GET_REPORT_STATUS:
      return {
        ...state,
        reportStatus: action.payload,
        loading: false,
      };
    case ACTIONS.GET_NOTIFICATION_MESSAGE:
      return {
        ...state,
        notificationMessage: action.payload,
        loading: false,
      };
    case ACTIONS.GET_USER_DETAILS:
      return {
        ...state,
        userDetails: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LAB_DETAILS:
      return {
        ...state,
        labDetails: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PHLEBO_RIDER_DUE_AMOUNT:
      return {
        ...state,
        phlebo_rider_due: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ADDITIONAL_BOOKING:
      return {
        ...state,
        additionMember: action.payload,
        loading: false,
      };
    case ACTIONS.GET_QRCODE_DATA:
      return {
        ...state,
        qrcode: action.payload,
        loading: false,
      };
    case ACTIONS.GET_SIGNATURE:
      return {
        ...state,
        signature: action.payload,
        loading: false,
      };
    case ACTIONS.GET_TICKET_DATA:
      return {
        ...state,
        ticketData: action.payload,
        loading: false,
      };
    case ACTIONS.GET_TICKET_CATEGORIES:
      return {
        ...state,
        ticketCategories: action.payload,
        loading: false,
      };

    case ACTIONS.GET_TICEKT_SUB_CATEGORIES:
      return {
        ...state,
        ticketSubCategories: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BOOKING_COMPLAINTS:
      return {
        ...state,
        bookingComplaints: action.payload,
        loading: false,
      };
    case ACTIONS.UPDATE_DISPOSITION:
      return {
        ...state,
        disposition: action.payload,
        loading: false,
      };
    case ACTIONS.GET_TICKET_COMMENTS:
      return {
        ...state,
        ticketComments: action.payload,
        loading: false,
      };
    case ACTIONS.SET_ALL_BOOKING_LOADING:
      return {
        ...state,
        bookingListLoader: action.payload,
      };
    case ACTIONS.CHECK_GEOFENCE_AREA_MAMPMYINDIA:
      return {
        ...state,
        geoFencesMapMyIndia: action.payload,
        // loading: false,
        geoLoading: false,
        geoCheckError: "",
      };
    case ACTIONS.SET_GEO_LOADING:
      return {
        ...state,
        geoLoading: action.payload,
      };
    case ACTIONS.SET_GEO_CHECK_ERROR:
      return {
        ...state,
        geoCheckError: action.payload,
      };
    case ACTIONS.RESET_GET_ROUTES:
      return {
        ...state,
        routeListData: {},
      };
      case ACTIONS.GET_DC_CAMP_SAMPLE_HANDOVER:
        return {
          ...state,
          dcCampList: action.payload,
          loading: false,
        };
      case ACTIONS.GET_BATCH_TUBE_INFO:
        return {
          ...state,
          tubeInfoData: action.payload,
          loading: false,
        };
        case ACTIONS.GET_LAB_CREDENTIAL:
          return {
            ...state,
            labCredential: action.payload,
            loading: false,
          };
         
       

    // reset_get_routes
    default:
      return state;
  }
};

export default CustomerLeadReducer;
