import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
    withStyles,
    Theme,
    createStyles,
    makeStyles,
} from "@material-ui/core/styles";
import { Switch, TextField, Select, MenuItem, Button } from '@material-ui/core'
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { getUserList, getLeadSource, updateUserList,getAgentList } from "../../actions/productMangerAction";
import { TableFooter, TablePagination } from "@material-ui/core";
import Loader from "../loader";

const StyledTableCell:any = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: "#924A91",
            color: theme.palette.common.white,
            fontSize: "14px",
        },
        body: {
            fontSize: 14,
        },
    })
)(TableCell);

const StyledTableRow:any = withStyles((theme: Theme) =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    })
)(TableRow);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        table: {
            minWidth: 700,
        },
        root: {
            flexGrow: 0,
            //   width: "100%",
            //   marginTop: "20px",
        },
        toolbar: {
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
        },
        content: {
            width: "80%",
            flexGrow: 21,
            //   padding: theme.spacing(3),
        },
    })
);

const DefaultSwitch:any = withStyles({
    switchBase: {
        color: "red",
        '&$checked': {
            color: "green",
        },
        '&$checked + $track': {
            backgroundColor: "green",
        },
    },
    checked: {},
    track: {
        backgroundColor: "red"
    },
})(Switch);

interface Props {
    getLeadSource: any;
    lead_source: any;
    updateUserList: any;
    getUserList: any;
    getAgentList:any;
    users: any;
    loading: boolean;
}

const LeadsTable: React.FC<Props> = ({
    getLeadSource,
    lead_source,
    updateUserList,
    getAgentList,
    getUserList,
    users,
    loading,
}) => {
    const classes = useStyles();

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);

    const [mappedNo, setMappedNo] = useState<string | null>("")
    const [mappedNoFb, setMappedNoFb] = useState<string | null>("")
    const [mappedNoGoogle, setMappedNoGoogle] = useState<string | null>("")
    const [callFrom, setCallFrom] = useState<string>("")
    const [isActive, setIsActive] = useState(false)
    const [isGoogleAd, setIsGoogleAd] = useState(false)
     const [isJdAd, setIsJdAd] = useState(false)
    const [isFbAd, setIsFbAd] = useState(false)

    const [editUser, setEditUser] = useState<any>({})

    useEffect(() => {
        getLeadSource()
        getUserList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setIsActive(editUser.status)
        setMappedNo(editUser.mapped_number)
        setMappedNoFb(editUser.mapped_number_fb)
        setMappedNoGoogle(editUser.mapped_number_google)
        setCallFrom(editUser.ctc_from)
        setIsFbAd(editUser.is_fb_ad)
        setIsGoogleAd(editUser.is_google_ad)
        setIsJdAd(editUser.is_jd_ad)
    }, [editUser])

    const handleUser = () => {
        let body: any = {
            ...editUser,
            status: isActive,
            mapped_number: mappedNo,
            mapped_number_fb: mappedNoFb,
            mapped_number_google: mappedNoGoogle,
            is_fb_ad: isFbAd,
            is_google_ad: isGoogleAd,
            ctc_from: callFrom,
            is_jd_ad:isJdAd,
        }
        updateUserList(body)
        setEditUser({})
    }

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        if (newPage > page) {
            let url = users.next;
            getUserList(url.substring(url.indexOf("?")));
        } else if (newPage < page) {
            let url = users.previous;
            getUserList(
                url.indexOf("?") === -1 ? "" : url.substring(url.indexOf("?"))
            );
        }
        setPage(newPage);
    };

    return (
        <>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                    <Link to="/dashboard/pm/users/create" style={{ textDecoration: "none" }}>
                        <Button
                            color="secondary"
                            variant="contained"
                        >
                            Add user
                        </Button>
                    </Link>
                </div>
                <div className="data-table" style={{ width: "100%", marginTop: "2rem" }}>
                    <TableContainer component={Paper}>
                        {loading ? <Loader /> : (
                            <Table className={classes.table} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="center">Panel user</StyledTableCell>
                                        <StyledTableCell align="center">User Role</StyledTableCell>
                                        <StyledTableCell align="center">Status</StyledTableCell>
                                        <StyledTableCell align="center">FB Ad</StyledTableCell>
                                        <StyledTableCell align="center">Google Ad</StyledTableCell>
                                        <StyledTableCell align="center">Call from</StyledTableCell>
                                        <StyledTableCell align="center">JD Ad</StyledTableCell>
                                        <StyledTableCell align="center">Action</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {users && users.userdata && users.userdata.map((user: any) => {
                                        return (
                                            <StyledTableRow key={user.username}>
                                                <StyledTableCell align="center">
                                                    {user.username}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {user.usergroup}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {editUser.username === user.username ? (
                                                        <Switch
                                                            defaultChecked={user.status}
                                                            checked={isActive}
                                                            onChange={(e) => setIsActive(e.target.checked)}
                                                            name="checkedA"
                                                            color="primary"
                                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                        />
                                                    ) : (
                                                        <DefaultSwitch
                                                            checked={user.status}
                                                            name="checkedA"
                                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                            disableRipple
                                                        />
                                                    )}
                                                </StyledTableCell>
                                                {/* <StyledTableCell align="center">
                                                    {editUser.username === user.username ? (
                                                        <TextField
                                                            name="leadId"
                                                            type="text"
                                                            value={mappedNo !== null ? mappedNo : user.mapped_number}
                                                            className="input"
                                                            variant="outlined"
                                                            placeholder="Mapped No"
                                                            onChange={(e) => setMappedNo(e.target.value as string)}
                                                            style={{ width: "100%", padding: "10px", minWidth: "200px" }}
                                                        />
                                                    ) : (
                                                        user.mapped_number
                                                    )}
                                                </StyledTableCell> */}
                                                {/* <StyledTableCell align="center">
                                                    {editUser.username === user.username ? (
                                                        <TextField
                                                            name="leadId"
                                                            type="text"
                                                            value={mappedNoFb !== null ? mappedNoFb : user.mapped_number_fb}
                                                            className="input"
                                                            variant="outlined"
                                                            placeholder="Mapped Number FB"
                                                            onChange={(e) => setMappedNoFb(e.target.value as string)}
                                                            style={{ width: "100%", padding: "10px", minWidth: "200px" }}
                                                        />
                                                    ) : (
                                                        user.mapped_number_fb
                                                    )}
                                                </StyledTableCell> */}
                                                <StyledTableCell align="center">
                                                    {editUser.username === user.username ? (
                                                        <Switch
                                                            defaultChecked={user.is_fb_ad}
                                                            checked={isFbAd}
                                                            onChange={(e) => setIsFbAd(e.target.checked)}
                                                            name="checkedA"
                                                            color="primary"
                                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                        />
                                                    ) : (
                                                        <DefaultSwitch
                                                            checked={user.is_fb_ad}
                                                            name="checkedA"
                                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                            disableRipple
                                                        />
                                                    )}
                                                </StyledTableCell>
                                                {/* <StyledTableCell align="center">
                                                    {editUser.username === user.username ? (
                                                        <TextField
                                                            name="leadId"
                                                            type="text"
                                                            value={mappedNoGoogle !== null ? mappedNoGoogle : user.mapped_number_google}
                                                            className="input"
                                                            variant="outlined"
                                                            placeholder="Mapped Number Google"
                                                            onChange={(e) => setMappedNoGoogle(e.target.value as string)}
                                                            style={{ width: "100%", padding: "10px", minWidth: "200px" }}
                                                        />
                                                    ) : (
                                                        user.mapped_number_google
                                                    )}
                                                </StyledTableCell> */}
                                                <StyledTableCell align="center">
                                                    {editUser.username === user.username ? (
                                                        <Switch
                                                            defaultChecked={user.is_google_ad}
                                                            checked={isGoogleAd}
                                                            onChange={(e) => setIsGoogleAd(e.target.checked)}
                                                            name="checkedA"
                                                            color="primary"
                                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                        />
                                                    ) : (
                                                        <DefaultSwitch
                                                            checked={user.is_google_ad}
                                                            name="checkedA"
                                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                            disableRipple
                                                        />
                                                    )}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {editUser.username === user.username ? (
                                                        <Select className="input"
                                                            name="callFrom"
                                                            variant="outlined"
                                                            defaultValue={user.ctc_from}
                                                            value={callFrom !== "none" ? callFrom : user.ctc_from}
                                                            style={{ width: "100%", minWidth: "200px", textAlign: "left" }}
                                                            onChange={(e) => setCallFrom(e.target.value as string)}
                                                        >
                                                            <MenuItem value={"none"} disabled>Call from</MenuItem>
                                                            <MenuItem value={"none"} disabled>Call from</MenuItem>
                                                            <MenuItem value={"OZONETEL"} >OZONETEL</MenuItem>
                                                            <MenuItem value={"KNO"} >KNOWLARITY</MenuItem>
                                                            <MenuItem value={"AVYUKTA"} >AVYUKTA Gateway</MenuItem>
                                                            <MenuItem value={"TATATELE"} >TATA TELE</MenuItem>
                                                        </Select>
                                                    ) : (
                                                        user.ctc_from
                                                    )}
                                                </StyledTableCell>
                                                 <StyledTableCell align="center">
                                                    {editUser.username === user.username ? (
                                                        <Switch
                                                            defaultChecked={user.is_jd_ad}
                                                            checked={isJdAd}
                                                            onChange={(e) => setIsJdAd(e.target.checked)}
                                                            name="checkedA"
                                                            color="primary"
                                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                        />
                                                    ) : (
                                                        <DefaultSwitch
                                                            checked={user.is_jd_ad}
                                                            name="checkedA"
                                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                            disableRipple
                                                        />
                                                    )}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {editUser.username === user.username ? (
                                                        <Button
                                                            color="primary"
                                                            variant="text"
                                                            onClick={() => handleUser()}
                                                        >
                                                            Submit
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            color="primary"
                                                            variant="text"
                                                            onClick={() => setEditUser(user)}
                                                        >
                                                            Edit
                                                        </Button>
                                                    )}
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        )
                                    })}
                                </TableBody>
                                <TableFooter>
                                    <TablePagination
                                        colSpan={6}
                                        count={(users.userdata && users.userdata.length) || 0}
                                        rowsPerPageOptions={[]}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        onPageChange={handleChangePage}
                                    />
                                </TableFooter>
                            </Table>
                        )}
                    </TableContainer>
                </div>
            </main>
        </>
    );
};

const mapStateToProps = (state: any) => ({
    users: state.ProductManagerReducer.users,
    loading: state.ProductManagerReducer.loading,
    lead_source: state.ProductManagerReducer.lead_source,
    getAgentList: state.ProductManagerReducer.getAgentList,
});

export default connect(mapStateToProps, { getUserList, updateUserList, getLeadSource })(
    LeadsTable
);