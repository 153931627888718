import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import "./view.sass";

interface IProps {
  data: any;
}

const ViewTable: React.FC<IProps> = ({ data }) => {
  const columns = data?.columns;

  return (
    <div>
      <TableContainer className="tableContainer">
        <Table className="table" stickyHeader>
          <TableHead className="tableHead">
            <TableRow className="tableRow">
              {data?.columns?.map((column: string, index: number) => (
                <TableCell className="tableCell" key={index} align="center">
                  {column}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className="tableBody">
            {data?.results?.map((row: any, index: number) => (
              <TableRow className="tableRow" key={index}>
                {data.columns.map((column: string, i: number) => (
                  <TableCell
                    className="tableCell"
                    key={i + column}
                    align="center"
                  >
                    {row[column] || "-"}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
          {/* <TableFooter></TableFooter> */}
        </Table>
      </TableContainer>
    </div>
  );
};

export default ViewTable;
