import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Switch, TextField, Select, MenuItem, Grid } from "@material-ui/core";
import Button from "@mui/material/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { TableFooter, TablePagination } from "@material-ui/core";
import Loader from "../components/loader";
import {
  getPhlebos,
  updatePhlebos,
  getPhleboComments,
  getZone,
  getZone1,
  getCities,
} from "../actions/RouteManagerAction";
import { useHistory } from "react-router-dom";
import { MessageRounded } from "@material-ui/icons";
import BookingCommentsModal from "../components/Comments/phlebosComment";
import EditPhleboLocation from "../components/CreatePhlebo/EditPhleboLocation";
import { genrateManagePhlebos } from "../../helpers/generateUrl";
const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const useStyles: any = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 700,
    },
    root: {
      flexGrow: 0,
      //   width: "100%",
      //   marginTop: "20px",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    contentTable: {
      width: "100%",
      padding: "10px 0px 10px 0px",
    },
    content: {
      width: "80%",
      flexGrow: 21,
      //   padding: theme.spacing(3),
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
    contentHeader: {
      width: "100%",
      padding: "0px 10px 40px 10px",
    },
    leftContentHeader: {
      width: "50%",
      float: "left",
    },
  })
);

const DefaultSwitch: any = withStyles({
  switchBase: {
    color: "red",
    "&$checked": {
      color: "green",
    },
    "&$checked + $track": {
      backgroundColor: "green",
    },
  },
  checked: {},
  track: {
    backgroundColor: "red",
  },
})(Switch);

interface Props {
  children: any;
  phleboList: any;
  getPhlebos: any;
  getPhleboComments: any;
  updatePhlebos: any;
  loading: boolean;
  getZone: any;
  getZone1: any;
  zoneList: any;
  getCities: any;
  cities: any;
  userDetails:any;
}
const LeadsTable: React.FC<Props> = ({
  getPhlebos,
  phleboList,
  updatePhlebos,
  getPhleboComments,
  loading,
  getZone,
  getZone1,
  zoneList,
  getCities,
  userDetails,
  cities,
}) => {
  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [isActive, setIsActive] = useState(false);
  const [editUser, setEditUser] = useState<any>({});
  const [leadId, setLeadId] = useState<number>(0);
  const [phleboUser, setPhleboUser] = useState<any>([]);
  const [phlebo, setPhlebo] = useState<any>("");
  const [page, setPage] = React.useState(0);
  const timer = useRef<any>(0);
  const [openModal, setopenModal] = useState(false);
  const [displayPhlebo, setDisplayPhlebo] = useState<any>("");
  const [zoneName, setZoneName] = useState<any>(0);
  const [base_city, setBase_City] = useState<any>("");
  const history = useHistory();
  const [openEditPhlebo, setOpenEditPhlebo] = useState(false);
  const [openEditPhleboLocation, setOpenEditPhleboLocation] = useState(false);
  const [editPhleboUser, setEditPhleboUser] = useState([]);
  const [filterBtnClicked, setFilterBtnClicked] = useState(false);
  useEffect(() => {
    getPhlebos();
    getCities();
    getZone();
    setRowsPerPage(phleboList.page_size);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsActive(editUser.is_active);
  }, [editUser]);

  const filterSampleHandover = (e: any) => {
    const body: any = {
      phlebo: phlebo,
      zone: zoneName,
      base_city: base_city,
    };
    const url = genrateManagePhlebos(body).substring(2);
    // phlebo !== "" ? getPhlebos(`${url}`) : getZone1(`${url}`);
    getPhlebos(`?${url}`);
    // getZone(`${url}`);
    setPage(0);
  };

  const handleUser = async () => {
    let body: any = {
      is_active: isActive,
    };
    await updatePhlebos(body, editUser.id);
    getPhlebos();
    setEditUser({});
    history.push(`/dashboard/rm/users`);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url = phleboList?.links && phleboList?.links.next.split("?")[1];
      getPhlebos(`?${url}`);
    } else if (newPage < page) {
      let url = phleboList?.links && phleboList?.links?.previous.split("?")[1];
      getPhlebos(`?${url}`);
    }
    setPage(newPage as number);
  };
  const handleClick = (id: number) => {
    setopenModal(true);
    getPhleboComments(id);
  };
  const handleEditPhleboLocation = (user: any) => {
    setOpenEditPhleboLocation(true);
    setEditPhleboUser(user);
  };
  return (
    <>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <div className={classes.contentHeader}>
          <div className={classes.leftContentHeader}>
            <h2 style={{ fontWeight: "bold" }}>MANAGE PHLEBOS</h2>
          </div>
        </div>
        <div className={classes.contentTable}>
          <Grid container spacing={3} direction="row">
            <Grid item xs={12} sm={4} md={2}>
              <Autocomplete
                id="phleboName"
                className="input"
                onChange={(event, newValue) => {
                  if (newValue) {
                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                    setPhlebo(obj.user.name);
                  }
                }}
                options={
                  phleboList.results
                    ? [
                        ...phleboList.results,
                        { id: 0, user: { username: "NoPhlebo" } },
                      ]
                    : []
                }
                freeSolo
                blurOnSelect
                aria-required
                getOptionLabel={(option: any) =>
                  option?.user && option?.user?.username
                }
                inputValue={displayPhlebo}
                onInputChange={(event, newInputValue) => {
                  setDisplayPhlebo(newInputValue);
                  clearTimeout(timer.current);
                  timer.current = setTimeout(() => {
                    getPhlebos(`?search=${newInputValue}`);
                  }, 1000);
                }}
                // disabled={zoneName !== 0 || base_city !== "" ? true : false}

                renderInput={(params) => (
                  <TextField
                    className="input"
                    {...params}
                    variant="outlined"
                    label="Phlebo Name"
                    style={{ width: "100%", margin: "0", padding: "0" }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="zonename"
              onChange={(event, newValue) => {
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  setZoneName(obj?.city?.display_name)
                }
              }}
              options={zoneList?.results || []}
              blurOnSelect
              aria-required
              getOptionLabel={(option: any) => option.city.name}
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current)
                timer.current = setTimeout(() => {
                  getZone(`${newInputValue}`)
                }, 1000)
                // if (newInputValue.length === 0) {
                //   setZoneName(0)
                // }
              }}
              renderInput={(params) => (
                <TextField className="input"
                  {...params}
                  placeholder="Zone/City"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                  required
                  inputProps={{
                    ...params.inputProps,
                    onKeyDown: (e) => {
                      if (e.key === "Enter") {
                        e.stopPropagation();
                      }
                    },
                  }}
                />
              )}
            />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <Autocomplete
                id="base_city"
                onChange={(event, newValue) => {
                  if (newValue) {
                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                    setBase_City(obj?.name);
                  }
                }}
                options={cities || []}
                freeSolo
                blurOnSelect
                aria-required
                getOptionLabel={(option: any) => String(option?.name)}
                onInputChange={(event, newInputValue) => {
                  clearTimeout(timer.current);
                  timer.current = setTimeout(() => {
                    getCities(newInputValue);
                  }, 1000);
                  if (newInputValue?.length === 0) {
                    setBase_City("");
                  }
                }}
                // disabled={phlebo !== "" || zoneName !== 0 ? true : false}
                renderInput={(params) => (
                  <TextField
                    className="input"
                    {...params}
                    label="Base City"
                    variant="outlined"
                    style={{ width: "100%", margin: "0", padding: "0" }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <Button
                variant="contained"
                color="secondary"
                disabled={
                  loading ||
                  (phlebo?.length === 0 && zoneName === 0 && base_city === "")
                }
                fullWidth
                onClick={filterSampleHandover}
              >
                Filter
              </Button>
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => history.push("/dashboard/rm/users")}
              >
                Reset
              </Button>
            </Grid>
          </Grid>
        </div>
        <Grid
          container
          spacing={3}
          direction="row"
          alignItems="center"
          justify="center"
        >
          {/* <Grid item xs={12} sm={6} md={6}>
            <Autocomplete
              id="phlebos"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                // if (newValue) {
                //     let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                //     sourceIds = obj.map((item: any) => item.id)
                // }
                setPhleboUser(sourceIds);
              }}
              options={phleboList.results}
              freeSolo
              blurOnSelect
              aria-required
              limitTags={1}
              // multiple
              getOptionLabel={(option: any) =>
                option && option.user && option.user.username
              }
              getOptionDisabled={(option: any) => {
                return phleboList.results.includes(option.id);
              }}
              disableClearable
              disableCloseOnSelect
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getPhlebos(`?search=${newInputValue}`);
                }, 1000);
                if (newInputValue.length === 0) {
                  setPhleboUser([]);
                }
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  placeholder="Phlebo Search"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid> */}
        </Grid>
        <div className={classes.toolbar} />
        <div
          className="data-table"
          style={{ width: "100%", marginTop: "2rem" }}
        >
          <TableContainer
            className={classes.tableContainer}
            component={Paper}
            style={{ maxHeight: "700px" }}
          >
            {loading ? (
              <Loader />
            ) : (
              <Table stickyHeader aria-label="simple table">
                {phleboList &&
                  phleboList?.results &&
                  phleboList?.results?.length === 0 && (
                    <div style={{ width: "100%", textAlign: "center" }}>
                      <p style={{ fontSize: "20px", fontWeight: "600" }}>
                        No data available!
                      </p>
                    </div>
                  )}
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell align="center">Phlebo Id</StyledTableCell>
                    {userDetails?.profile?.executive_type==="PPMC"?(
                      <StyledTableCell align="center">OTP</StyledTableCell>
                    ):""}
                    
                    <StyledTableCell align="center">Comment</StyledTableCell>
                    {/* <StyledTableCell align="center">
                      Phlebo Name
                    </StyledTableCell> */}
                    <StyledTableCell align="center">Zone</StyledTableCell>
                    <StyledTableCell align="center">
                      Phlebo Username
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Phlebo Number
                    </StyledTableCell>
                    <StyledTableCell align="center">Base City</StyledTableCell>
                    <StyledTableCell align="center">Job Type</StyledTableCell>
                    <StyledTableCell align="center">Status</StyledTableCell>
                    {/* <StyledTableCell align="center">Action</StyledTableCell> */}
                    <StyledTableCell align="center">
                      {" "}
                      Set Home Location{" "}
                    </StyledTableCell>
                    <StyledTableCell align="center">Location</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {phleboList &&
                    phleboList?.results &&
                    phleboList?.results.map((user: any) => {
                      return (
                        <StyledTableRow key={user.id}>
                          <StyledTableCell align="center">
                            {user?.id}
                          </StyledTableCell>
                         
                          {userDetails?.profile?.executive_type==="PPMC"?(
                            <StyledTableCell align="center">{user?.otp}</StyledTableCell>
                          ):""}
                          <TableCell align="center">
                            <div>
                              <MessageRounded
                                onClick={() => handleClick(user?.id)}
                              />
                            </div>
                          </TableCell>
                          {/* <StyledTableCell align="center">
                            {user?.user?.name}
                          </StyledTableCell> */}
                          <StyledTableCell align="center">
                            {user?.zone}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {user?.user?.username}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {user?.user?.phonenumber}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                          {user?.base_city&&user?.base_city?.name}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {user.job_type}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {editUser.user === user.user ? (
                              <Switch
                                defaultChecked={user.is_active}
                                checked={isActive}
                                onChange={(e) => setIsActive(e.target.checked)}
                                name="checkedA"
                                color="primary"
                                inputProps={{
                                  "aria-label": "secondary checkbox",
                                }}
                              />
                            ) : (
                              <DefaultSwitch
                                checked={user.is_active}
                                name="checkedA"
                                inputProps={{
                                  "aria-label": "secondary checkbox",
                                }}
                                disableRipple
                              />
                            )}
                          </StyledTableCell>
                          {/* <StyledTableCell align="center">
                                                    {editUser.user === user.user ? (
                                                        <Button
                                                            color="primary"
                                                            variant="text"
                                                            onClick={() => handleUser()}
                                                        >
                                                            Submit
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            color="primary"
                                                            variant="text"
                                                            onClick={() => setEditUser(user)}
                                                        >
                                                            Edit
                                                        </Button>
                                                    )}
                                                </StyledTableCell> */}
                          <StyledTableCell align="center">
                            {user?.is_active && (
                              <Button
                                color="primary"
                                variant="text"
                                onClick={() => handleEditPhleboLocation(user)}
                              >
                                Set Home Location
                              </Button>
                            )}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {user?.location}
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                </TableBody>
                <TableFooter>
                  <StyledTableRow>
                    <TablePagination
                      rowsPerPageOptions={[]}
                      colSpan={3}
                      count={phleboList?.count || 0}
                      rowsPerPage={phleboList?.page_size || 20}
                      page={page}
                      onPageChange={handleChangePage}
                    />
                  </StyledTableRow>
                </TableFooter>
                <BookingCommentsModal
                  openModal={openModal}
                  setopenModal={setopenModal}
                  leadId={leadId}
                />
                <div className={classes.contentTable}>
                  {openEditPhleboLocation && (
                    <EditPhleboLocation
                      openEditPhlebo={openEditPhleboLocation}
                      setOpenEditPhlebo={setOpenEditPhleboLocation}
                      editPhleboUser={editPhleboUser}
                      getPhlebos={getPhlebos}
                      phlebo={phlebo}
                      zoneName={zoneName}
                      baseCity={base_city}
                    />
                  )}
                </div>
              </Table>
            )}
          </TableContainer>
        </div>
      </main>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  phleboList: state.RouteManagerReducer.phleboList,
  loading: state.RouteManagerReducer.loading,
  zoneList: state.RouteManagerReducer.zoneList,
  cities: state.RouteManagerReducer.cities,
  userDetails: state.RouteManagerReducer.userDetails,
});

export default connect(mapStateToProps, {
  getPhlebos,
  updatePhlebos,
  getPhleboComments,
  getZone,
  getZone1,
  getCities,
})(LeadsTable);
