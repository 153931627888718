import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";
import Button from "@mui/material/Button";
import { Grid, TextField } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import MessageIcon from "@material-ui/icons/Message";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Fade from "@material-ui/core/Fade";
import Modal from "@mui/material/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import { CALL_DISABLE_TIME } from "../../CallTimeDisable";
import moment from "moment";
import {
  clickToCall,
  getAllLeads,
  getCities,
  getLeadStatus,
  getPrescriptionData,
  sendInitialMessage,
  getLtvLead,
  getLeadDispostion,
  leadTransferByComma,
  leadTransferTataTale,
  getPhlebos,
  getLanguage,
} from "../actions/PhleboAdmin";
import { TablePagination, Dialog } from "@material-ui/core";
import Loader from "../components/loader";
import CommentsModal from "../components/Comments/comment";
import CommentsModal2 from "../components/Comments/Comment2";
import { generateLeadsUrl } from "../../helpers/generateUrl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CallIcon from "@material-ui/icons/Call";
import { useRef } from "react";
const StyledTableCellBordered: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
      border: "0.6px solid grey",
    },
    body: {
      fontSize: 14,
      border: "0.6px solid grey",
    },
  })
)(TableCell);
const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      marginTop: "2rem",
      padding: "0.5rem 2rem",
      width: "100%",
    },
    table: {
      maxWidth: "100%",
    },
    contentFilter: {
      width: "100%",
      padding: "0px 10px 10px 0px",
    },
    tableHead: {
      background: "rgb(146, 74, 145)!important",
    },
    tableHeadCell: {
      color: "#fff",
      fontWeight: "bold",
      textAlign: "center",
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  })
);
interface Props {
  getAllLeads: Function;
  all_lead: any;
  loading: boolean;
  clickToCall: Function;
  getCities: any;
  cities: any;
  getLeadStatus: any;
  leadStatus: any;
  getPrescriptionData: any;
  prescription: any;
  sendInitialMessage: any;
  getLtvLead: any;
  ltvLead: any;
  breakResponse: any;
  leadById: any;
  booking: any;
  dispositionList: any;
  getLeadDispostion: any;
  agentsListnewdata: any;
  lead_source: any;
  teamLeadList: any;
  salesLeadList: any;
  leadTransferByComma: any;
  bookingComplaints: any;
  phleboList: any;
  getPhlebos: any;
  leadLoading: any;
  getLanguage: any;
  language: any;
}

const LeadsTable: React.FC<Props> = ({
  getAllLeads,
  all_lead,
  clickToCall,
  getCities,
  cities,
  getLeadStatus,
  leadStatus,
  loading,
  getPrescriptionData,
  prescription,
  sendInitialMessage,
  getLtvLead,
  ltvLead,
  breakResponse,
  leadById,
  booking,
  dispositionList,
  getLeadDispostion,
  agentsListnewdata,
  lead_source,
  teamLeadList,
  salesLeadList,
  leadTransferByComma,
  bookingComplaints,
  getPhlebos,
  phleboList,
  leadLoading,
  getLanguage,
  language,
}) => {
  const classes = useStyles();
  document.querySelectorAll("#dateWidthCustom").forEach((el) => {
    el.addEventListener("keydown", function (e) {
      e.preventDefault();
    });
  });
  const [open2, setOpen2] = useState<boolean>(false);
  const [openWhatsapp, setOpenWhatsapp] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [raiseComplaint, setRaiseComplaint] = useState(false);
  const [leadId, setLeadId] = useState<number>(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20 || all_lead.results.count);
  const [openView, setOpenView] = useState<boolean>(false);
  const [category, setCategory] = useState<any>([]);
  const [id, setId] = useState<string>("");
  const [createdAt, setCreatedAt] = useState<string>("");
  const [followUpDate, setFollowUpDate] = useState<string>("");
  const [leadSource, setleadSource] = useState<any>([]);
  const [priority, setPriority] = useState<any>([]);
  const [start_date, setStart_date] = useState<string>("");
  const [end_date, setEnd_date] = useState<string>("");
  const [prscriptionLead, setPrescriptionLead] = useState<any>("");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openPrescription = Boolean(anchorEl);
  const [pLink, setPlink] = useState<any>("");
  const [openPdf, setOpenPdf] = useState<boolean>(false);
  const [openWhatsappTemp, setOpenWhatsappTemp] = useState<boolean>(false);
  const [leadIdWhatsapp, setLeadIdWhatsapp] = useState<number>(0);
  const [fileType, setFileType] = useState<any>("");
  const history = useHistory();
  const [callButton, setCallButton] = useState<boolean>(true);
  const [categoryList, setCategoryList] = useState<any>("");
  const [showCity, setShowCity] = useState<boolean>(false);
  const [disableFilterBtn, setDisableFilterBtn] = useState<boolean>(false);
  const [subCategoryName, setSubCategoryName] = useState<any>("");
  const [callDate, setCallDate] = useState("");
  const [callTime, setCallTime] = useState("");
  const [followupDateRequired, setFolloupRequired] = useState<boolean>(false);
  const [comment, setComment] = useState<string>("");
  const [phone, setPhone] = useState("");
  const [city, setCity] = useState<any>([]);
  const timer = useRef<any>(0);
  const [agent, setAgent] = useState<any>([]);
  const [teamLead, setTeamLead] = useState<any>([]);
  const [callFrom, setCallFrom] = useState<any>("");
  const [inboundModeFilter, setInbountModeFilter] = useState("none");
  const [leadFor, setLeadFor] = useState<any>("");
  const [selectedLeads, setSelectedLeads] = useState<any>([]);
  const [panel_user, setPanelUser] = React.useState<number | null>(null);
  const [phlebo, setPhlebo] = useState<any>("");
  const [languages, setLanguages] = useState<any>("");
  const [userGroup, setUserGroup] = useState("none");
  const blockCharInAutocompleteField = (
    e: React.KeyboardEvent<HTMLDivElement>
  ) => {
    return (
      "~!@#$%^&*()_+{}|:<>?,./;'[]=-0987654321`".includes(e.key) &&
      e.preventDefault()
    );
  };

  const priorityArr: any = [
    {
      value: "SuperCritical",
    },
    {
      value: "Urgent",
    },
    {
      value: "HopeFull",
    },
    {
      value: "Low",
    },
  ];

  useEffect(() => {
    getAllLeads(`?usergroup=CustomerExecutive`);
    getLeadStatus();
    getLeadDispostion();
    // getCities();
    // getPhlebos();
    // getLanguage();
  }, []);

  useEffect(() => {
    if (disableFilterBtn) {
      setTimeout(() => {
        setDisableFilterBtn(false);
      }, 5000);
    }
  }, [disableFilterBtn]);

  const filterLeads = () => {
    setDisableFilterBtn(true);
    const body: any = {
      id,
      phone: phone,
      city: city.join(","),
      usergroup: userGroup,
      multiple_phlebo: phlebo,
      language: languages,
      followUpDate,
      start_date,
      end_date,
      disposition: categoryList,
      sub_disposition: subCategoryName,
    };
    const url = generateLeadsUrl(body).substring(2);
    getAllLeads(url ? `?${url}` : "");
    setPage(0);
  };

  document.querySelectorAll("#noTyping").forEach((el) => {
    el.addEventListener("keydown", function (e) {
      e.preventDefault();
    });
  });

  useEffect(() => {
    if (open2 === false) {
      setLeadId(0);
    }
  }, [open2]);

  const handleClick = (id: number) => {
    setOpen(true);
    setLeadId(id);
  };

  const handleCallClick = (id: number, type: string) => {
    setLeadId(id);
    clickToCall(id, type);
    setCallButton(false);
    setTimeout(() => {
      setCallButton(true);
    }, CALL_DISABLE_TIME);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url = all_lead && all_lead.next.split("?")[1];
      getAllLeads(url ? `?${url}` : "");
    } else if (newPage < page) {
      let url = all_lead && all_lead.previous.split("?")[1];
      getAllLeads(url ? `?${url}` : "");
    }
    setPage(newPage as number);
  };

  useEffect(() => {
    if (prscriptionLead !== "") {
      getPrescriptionData(`?lead=${prscriptionLead}`);
    }
  }, [prscriptionLead]);

  const handlePrescriptionClick = (
    id: any,
    event: React.MouseEvent<HTMLElement>
  ) => {
    setAnchorEl(event.currentTarget);
    setPrescriptionLead(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFrameView = (data: any) => {
    if (data.base64file !== null && data.image === "") {
      setFileType("b64");
      setPlink(`data:application/pdf;base64,${data.base64file}`);
    } else if (data.base64file === null && data.image !== "") {
      let pieces: any = data.image.split(".").pop();
      const last: any = pieces;
      setFileType(last);
      setPlink(data.image);
    }
    setOpenPdf(true);
  };
  const handlePdfModelClose = () => {
    setOpenPdf(false);
  };

  const handleCloseView = () => {
    setOpenView(false);
  };

  const transferLead = async () => {
    let body = {
      lead_ids: selectedLeads.join(", "),
      panel_user: phlebo,
    };
    await leadTransferByComma(body);
    setSelectedLeads([]);
    getAllLeads("");
  };
  const transferLeadTataTale = async () => {
    let body = {
      leadids: selectedLeads.join(", "),
    };
    await leadTransferTataTale(body);
    setSelectedLeads([]);
    getAllLeads("");
  };

  const checkedLead = (event: any, leadId: number) => {
    if (selectedLeads?.length < 21) {
      !event.target.checked
        ? setSelectedLeads(selectedLeads.filter((lead: any) => lead !== leadId))
        : setSelectedLeads((prev: any) => [...prev, leadId]);
    } else {
      return false;
    }
  };

  const clickToCallHandler = (leadId: any, rowcall: any, rowid: any) => {
    setDisableFilterBtn(true);
    {
      handleCallClick(leadId.toString(), "call");
    }
  };

  const blockInvalidChar = (event: any) => {
    const regex = /^[a-zA-Z0-9_ ]*$/;
    const key = event.key;
    if (!regex.test(key)) {
      event.preventDefault();
    }
  };

  return (
    <>
      <div style={{ width: "95%" }}>
        <div className={classes.toolbar} />
        <Grid item xs={12} sm={6} md={8}>
          <h3 style={{ fontWeight: "bold" }}>
            Total Lead Count : {all_lead.count}
          </h3>
        </Grid>
        <Grid
          container
          spacing={1}
          direction="row"
          style={{ alignItems: "left" }}
        >
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              name="leadId"
              type="number"
              value={id}
              className="input"
              variant="outlined"
              label="Lead Id"
              onChange={(e) => setId(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              name="phone"
              type="number"
              label="Phone"
              value={phone}
              variant="outlined"
              onChange={(e) => setPhone(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="city"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj.map((item: any) => item.id);
                }
                setCity(sourceIds);
              }}
              options={cities}
              freeSolo
              blurOnSelect
              aria-required
              limitTags={1}
              multiple
              getOptionLabel={(option: any) => option.name}
              getOptionDisabled={(option: any) => {
                return city.includes(option.id);
              }}
              disableClearable
              disableCloseOnSelect
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getCities(newInputValue);
                }, 1000);
                if (newInputValue.length === 0) {
                  setCity([]);
                }
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="City"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                  inputProps={{
                    ...params.inputProps,
                    onKeyDown: (e) => {
                      if (e.key === "Enter") {
                        e.stopPropagation();
                      }
                    },
                  }}
                  onKeyDown={(e) => {
                    blockCharInAutocompleteField(e);
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="usergroup_filter"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  setUserGroup(obj.value);
                }
              }}
              options={[
                {
                  text: "CustomerExecutive",
                  value: "CustomerExecutive",
                },
                {
                  text: "Phlebos",
                  value: "Phlebos",
                },
              ]}
              freeSolo
              blurOnSelect
              aria-required
              getOptionLabel={(option: any) => option.text}
              disableClearable
              disableCloseOnSelect
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="User Group"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="phlebofilter"
              // key={key1}
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj.map((item: any) => item.paneluser_id);
                  // setPhlebo(obj.id)
                }
                setPhlebo(sourceIds);
              }}
              freeSolo
              blurOnSelect
              aria-required
              limitTags={1}
              multiple
              options={
                phleboList.results
                  ? [
                      ...phleboList.results,
                      { id: 0, user: { username: "NoPhlebo" } },
                    ]
                  : []
              }
              getOptionDisabled={(option: any) => {
                return phlebo.includes(option.id);
              }}
              onKeyDown={blockInvalidChar}
              disableClearable
              disableCloseOnSelect
              getOptionLabel={(option: any) =>
                option.user && option.user.username
              }
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getPhlebos(`?code=${newInputValue}`);
                }, 1000);
                if (newInputValue.length === 0) {
                  setPhlebo([]);
                }
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Phlebo Name"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                  onKeyDown={blockInvalidChar}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="language"
              onChange={(event, newValue) => {
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  setLanguages(obj.name_en);
                }
              }}
              freeSolo
              blurOnSelect
              options={language?.results || []}
              disableCloseOnSelect
              getOptionLabel={(option: any) => {
                return option?.name_en;
              }}
              onInputChange={(event, newInputValue) => {
                setLanguages(newInputValue);
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getLanguage(newInputValue);
                }, 1000);
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="language"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "1px" }}
                  inputProps={{
                    ...params.inputProps,
                    onKeyDown: (e) => {
                      if (e.key === "Enter") {
                        e.stopPropagation();
                      }
                    },
                  }}
                  onKeyDown={(e) => {
                    blockCharInAutocompleteField(e);
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              id="dateWidthCustom"
              name="Created At"
              type="date"
              label="Follow-Up Date"
              disabled={
                start_date !== "" || end_date !== "" || createdAt !== ""
              }
              value={followUpDate}
              variant="outlined"
              onChange={(e) => setFollowUpDate(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              id="dateWidthCustom"
              name="start_date"
              type="date"
              label="Start Date"
              value={start_date}
              disabled={createdAt !== "" || followUpDate !== ""}
              variant="outlined"
              onChange={(e) => setStart_date(e.target.value as string)}
              style={{ width: "100%" }}
              inputProps={{
                max: moment().format("YYYY-MM-DD"),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              name="end_date"
              id="dateWidthCustom"
              type="date"
              value={end_date}
              className="input"
              disabled={createdAt !== "" || followUpDate !== ""}
              label="End Date"
              variant="outlined"
              onChange={(e) => setEnd_date(e.target.value as string)}
              style={{ width: "100%" }}
              inputProps={{
                max: moment().format("YYYY-MM-DD"),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="disposition"
              onChange={(event, newValue) => {
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  setCategoryList(obj?.id);
                  setSubCategoryName("");
                  setShowCity(false);
                  setCallDate("");
                  setCallTime("");
                  setFolloupRequired(false);
                  setComment("");
                  if (obj.id) {
                    getLeadStatus(`?category=${obj?.id}`);
                  }
                }
              }}
              blurOnSelect
              aria-required
              options={dispositionList}
              getOptionDisabled={(option: any) => {
                return dispositionList?.includes(option?.id);
              }}
              loading={loading}
              disableClearable
              disableCloseOnSelect
              getOptionLabel={(option: any) => option?.name}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Disposition Name"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="sub_disposition"
              key={categoryList}
              onChange={(event, newValue) => {
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  setSubCategoryName(obj.id);
                  setShowCity(obj.show_city);
                  setFolloupRequired(obj.followup_required);
                }
              }}
              blurOnSelect
              aria-required
              loading={loading}
              options={leadStatus}
              getOptionDisabled={(option: any) => {
                return leadStatus.includes(option.id);
              }}
              disableClearable
              disableCloseOnSelect
              getOptionLabel={(option: any) => option.name}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Sub Disposition Name"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              onClick={filterLeads}
              disabled={
                loading ||
                (phlebo?.length === 0 &&
                  id?.length === 0 &&
                  phone?.trim().length === 0 &&
                  city?.length === 0 &&
                  userGroup === "none" &&
                  languages === "" &&
                  subCategoryName === "" &&
                  categoryList === "" &&
                  start_date.trim().length === 0 &&
                  end_date.trim().length === 0 &&
                  followUpDate.trim().length === 0)
              }
            >
              Filter
            </Button>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => history.push("/dashboard/pha/Leadtable")}
            >
              Reset
            </Button>
          </Grid>
        </Grid>
        {selectedLeads.length > 0 && (
          <React.Fragment>
            <h2 style={{ marginTop: "1rem" }}>Transfer To:</h2>
            <Grid container spacing={2} direction="row">
              <Grid item xs={12} sm={3}>
                <Autocomplete
                  id="phleboName"
                  onChange={(event, newValue) => {
                    if (newValue) {
                      let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                      setPhlebo(obj.paneluser_id);
                    }
                  }}
                  options={
                    phleboList?.results
                      ? [
                          ...phleboList?.results,
                          { id: 0, user: { username: "NoPhlebo" } },
                        ]
                      : []
                  }
                  freeSolo
                  blurOnSelect
                  aria-required
                  getOptionLabel={(option: any) =>
                    option?.user && option?.user?.username
                  }
                  onInputChange={(event, newInputValue) => {
                    clearTimeout(timer.current);
                    timer.current = setTimeout(() => {
                      getPhlebos(`?code=${newInputValue}`);
                    }, 1000);
                    if (newInputValue?.length === 0) {
                      setPhlebo("");
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      className="input"
                      {...params}
                      placeholder="Phlebo Name"
                      variant="outlined"
                      style={{ width: "100%", margin: "0", padding: "0" }}
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Button
                  variant="contained"
                  color="success"
                  style={{ height: "40px" }}
                  fullWidth
                  onClick={transferLead}
                  disabled={phlebo === null || phlebo === ""}
                >
                  Transfer
                </Button>
              </Grid>
            </Grid>
          </React.Fragment>
        )}

        <div
          className="data-table"
          style={{ width: "100%", marginTop: "2rem" }}
        >
          <TableContainer
            className={classes.tableContainer}
            component={Paper}
            style={{ maxHeight: "560px" }}
          >
            {leadLoading ? (
              <Loader />
            ) : (
              <Table
                className={classes.table}
                stickyHeader
                aria-label="customized table"
              >
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell>
                      <input
                        type="checkbox"
                        className="input"
                        name="selectall"
                        id="selectall"
                        onChange={(e) =>
                          e.target.checked
                            ? setSelectedLeads(
                                all_lead?.results &&
                                  all_lead?.results.map(
                                    (lead: any, index: number) => {
                                      if (index < 100) {
                                        return lead?.id && lead?.id;
                                      }
                                    }
                                  )
                              )
                            : setSelectedLeads([])
                        }
                      />
                    </StyledTableCell>
                    <StyledTableCell>Lead ID</StyledTableCell>
                    <StyledTableCell align="center">Created At</StyledTableCell>
                    <StyledTableCell align="center">
                      Followup Date
                    </StyledTableCell>
                    <StyledTableCell align="center">Comments</StyledTableCell>
                    <StyledTableCell align="center">
                      Lead Source URL
                    </StyledTableCell>
                    <StyledTableCell align="center">Phlebo</StyledTableCell>
                    <StyledTableCell align="center">Call</StyledTableCell>
                    <StyledTableCell align="center">
                      Contact number
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Patient name
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Calling gender
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Patient age
                    </StyledTableCell>
                    <StyledTableCell align="center">City</StyledTableCell>
                    <StyledTableCell align="center">Locality</StyledTableCell>
                    <StyledTableCell align="center">
                      Patient Area
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Lead Source
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Call status
                    </StyledTableCell>
                    <StyledTableCell align="center">Language</StyledTableCell>
                    <StyledTableCell align="center">
                      Prescription
                    </StyledTableCell>
                    <StyledTableCell align="center">Priority</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {all_lead.results && all_lead.results.length > 0 ? (
                    all_lead.results.map((row: any, index: number) => (
                      <StyledTableRow
                        key={row.id}
                        style={{
                          background:
                            row?.lead_source === "imaging" ||
                            row?.lead_source === "imaging_lp" ||
                            row?.lead_source === "imaging_call" ||
                            row?.lead_source === "imaging_homedx" ||
                            row?.lead_source === "ZOKO_IMAGING" ||
                            row?.lead_source === "imaging_alliance" ||
                            row?.lead_source === "imaging_dsa" ||
                            row?.lead_source === "imaging_web" ||
                            row?.lead_source === "justdial_imaging"
                              ? "#ffeb3b"
                              : "",
                        }}
                      >
                        <StyledTableCell component="th" scope="row">
                          <input
                            type="checkbox"
                            name="selectall"
                            id="selectall"
                            checked={selectedLeads.find(
                              (lead: any) => lead === row.id
                            )}
                            onChange={(e) => checkedLead(e, row.id)}
                          />
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {row.id}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {new Date(row.created_at).toLocaleString()}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.followup_date} {row.followup_time}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <div>
                            <MessageIcon onClick={() => handleClick(row.id)} />
                          </div>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.lead_source_url ? row.lead_source_url : "NA"}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.panel_user ? row.panel_user : "NA"}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <Button
                            disabled={!callButton || disableFilterBtn}
                            className="addDeck"
                            variant="contained"
                            color="primary"
                            onClick={() =>
                              clickToCallHandler(row.id, "call", row.call)
                            }
                            startIcon={<CallIcon />}
                          ></Button>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.content_number ? (
                            <Button
                              disabled={!callButton || disableFilterBtn}
                              className="addDeck"
                              variant="contained"
                              color="primary"
                              onClick={() =>
                                clickToCallHandler(
                                  row?.id,
                                  "contact",
                                  row?.call
                                )
                              }
                              startIcon={<CallIcon />}
                            ></Button>
                          ) : (
                            "NA"
                          )}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.patient_name ? row.patient_name : "NA"}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.calling_gender ? row.calling_gender : "NA"}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.patient_age}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.patient_city}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row?.city_name ? row?.city_name : "NA"}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.patient_area ? row.patient_area : "NA"}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.lead_source}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.call_status}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.language ? row.language : "NA"}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.is_prescription ? (
                            <Button
                              aria-controls="fade-menu"
                              aria-haspopup="true"
                              onClick={(event: any) =>
                                handlePrescriptionClick(row.id, event)
                              }
                            >
                              <NoteAddIcon />
                            </Button>
                          ) : (
                            "NA"
                          )}
                          <Menu
                            id="fade-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={openPrescription}
                            onClose={handleClose}
                            TransitionComponent={Fade}
                          >
                            {prescription &&
                              prescription?.results &&
                              prescription?.results?.length > 0 &&
                              prescription?.results.map(
                                (data: any, index: any) => {
                                  let pieces: any = data.image.split(".").pop();
                                  const last: any = pieces;
                                  return (
                                    <>
                                      {data?.base64file !== null &&
                                      data.image === "" ? (
                                        <MenuItem
                                          onClick={() => handleFrameView(data)}
                                        >
                                          {" "}
                                          Name :{data?.name}, Age:{data?.age},
                                          Created Date :
                                          {new Date(
                                            data.created_at
                                          ).toLocaleString()}
                                        </MenuItem>
                                      ) : data?.base64file === null &&
                                        data.image !== "" ? (
                                        last === "png" ||
                                        last === "jpg" ||
                                        last === "jpeg" ? (
                                          <MenuItem>
                                            <a
                                              href={data?.image}
                                              rel="noreferrer noopener"
                                              target="_blank"
                                            >
                                              Name :{data?.name}, Age:
                                              {data?.age}, Created Date :
                                              {new Date(
                                                data.created_at
                                              ).toLocaleString()}
                                            </a>
                                          </MenuItem>
                                        ) : (
                                          <MenuItem>
                                            <a
                                              href={data?.image}
                                              rel="noreferrer noopener"
                                              target="_blank"
                                            >
                                              Name :{data?.name}, Age:
                                              {data?.age}, Created Date :
                                              {new Date(
                                                data.created_at
                                              ).toLocaleString()}
                                            </a>
                                          </MenuItem>
                                        )
                                      ) : (
                                        ""
                                      )}
                                    </>
                                  );
                                }
                              )}
                          </Menu>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.priority}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))
                  ) : (
                    <StyledTableRow key={1}>
                      <StyledTableCell colSpan={18}>
                        <strong>No Data Found</strong>
                      </StyledTableCell>
                    </StyledTableRow>
                  )}
                </TableBody>
              </Table>
            )}
            <Grid container xs={12}>
              <Grid container item md={6}>
                <TablePagination
                  colSpan={3}
                  count={all_lead?.count || 0}
                  rowsPerPageOptions={[]}
                  rowsPerPage={all_lead?.page_size}
                  page={page}
                  SelectProps={{
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                />
              </Grid>
            </Grid>
          </TableContainer>
          <Dialog
            open={openView}
            onClose={handleCloseView}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="xl"
          >
            <TableContainer style={{ maxHeight: "580px" }}>
              {leadLoading ? (
                <Loader />
              ) : (
                <Table stickyHeader aria-label="simple table">
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCellBordered align="center">
                        Parameters
                      </StyledTableCellBordered>
                      <StyledTableCellBordered align="center">
                        Ref Value
                      </StyledTableCellBordered>
                      <StyledTableCellBordered align="center">
                        Customer<span style={{ display: "none" }}>_</span>Name :
                        {ltvLead &&
                        ltvLead.booking &&
                        ltvLead.booking.length > 0
                          ? ltvLead.booking[0]?.customer_name
                          : ""}{" "}
                        Booking<span style={{ display: "none" }}>_</span>id:
                        {ltvLead &&
                        ltvLead.booking &&
                        ltvLead.booking.length > 0
                          ? ltvLead.booking[0]?.id
                          : ""}{" "}
                        Collection<span style={{ display: "none" }}>_</span>
                        Date:
                        {ltvLead &&
                        ltvLead.booking &&
                        ltvLead.booking.length > 0
                          ? ltvLead.booking[0]?.collection_date
                          : ""}
                      </StyledTableCellBordered>
                      <StyledTableCellBordered align="center">
                        Customer<span style={{ display: "none" }}>_</span>Name :
                        {ltvLead &&
                        ltvLead.booking &&
                        ltvLead.booking.length > 1
                          ? ltvLead.booking[0]?.customer_name
                          : ""}{" "}
                        Booking<span style={{ display: "none" }}>_</span>id:
                        {ltvLead &&
                        ltvLead.booking &&
                        ltvLead.booking.length > 1
                          ? ltvLead.booking[1]?.id
                          : ""}{" "}
                        Collection<span style={{ display: "none" }}>_</span>
                        Date:
                        {ltvLead &&
                        ltvLead.booking &&
                        ltvLead.booking.length > 1
                          ? ltvLead.booking[1]?.collection_date
                          : ""}
                      </StyledTableCellBordered>
                      <StyledTableCellBordered align="center">
                        Customer<span style={{ display: "none" }}>_</span>Name :
                        {ltvLead &&
                        ltvLead.booking &&
                        ltvLead.booking.length > 2
                          ? ltvLead.booking[0]?.customer_name
                          : ""}{" "}
                        Booking<span style={{ display: "none" }}>_</span>id:
                        {ltvLead &&
                        ltvLead.booking &&
                        ltvLead.booking.length > 2
                          ? ltvLead.booking[2]?.id
                          : ""}{" "}
                        Collection<span style={{ display: "none" }}>_</span>
                        Date:
                        {ltvLead &&
                        ltvLead.booking &&
                        ltvLead.booking.length > 2
                          ? ltvLead.booking[2]?.collection_date
                          : ""}
                      </StyledTableCellBordered>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {ltvLead &&
                      ltvLead.test_parameters &&
                      ltvLead.test_parameters.map((row: any) => (
                        <StyledTableRow>
                          <StyledTableCellBordered align="center">
                            {row.test_values__test_parameter__test_name}
                          </StyledTableCellBordered>
                          <StyledTableCellBordered align="center">
                            {ltvLead &&
                            ltvLead.booking &&
                            ltvLead.booking.length > 0
                              ? ltvLead.booking[0]?.customer_gender === "female"
                                ? row?.test_values__test_parameter__other_female
                                : row?.test_values__test_parameter__other_male
                              : ""}
                          </StyledTableCellBordered>
                          <StyledTableCellBordered align="center">
                            {ltvLead &&
                              ltvLead.booking &&
                              ltvLead.booking.length > 0 &&
                              ltvLead.booking[0].test_parameters &&
                              ltvLead.booking[0].test_parameters.length > 0 &&
                              ltvLead.booking[0].test_parameters.map(
                                (val: any) => {
                                  if (
                                    val.test_values__test_parameter ===
                                    row.test_values__test_parameter__id
                                  ) {
                                    return String(val.test_values__value).slice(
                                      0,
                                      25
                                    );
                                  }
                                }
                              )}
                          </StyledTableCellBordered>
                          <StyledTableCellBordered align="center">
                            {ltvLead &&
                              ltvLead.booking &&
                              ltvLead.booking.length > 1 &&
                              ltvLead.booking[1].test_parameters &&
                              ltvLead.booking[1].test_parameters.length > 0 &&
                              ltvLead.booking[1].test_parameters.map(
                                (val: any) => {
                                  if (
                                    val.test_values__test_parameter ===
                                    row.test_values__test_parameter__id
                                  ) {
                                    return String(val.test_values__value).slice(
                                      0,
                                      25
                                    );
                                  }
                                }
                              )}
                          </StyledTableCellBordered>
                          <StyledTableCellBordered align="center">
                            {ltvLead &&
                              ltvLead.booking &&
                              ltvLead.booking.length > 2 &&
                              ltvLead.booking[2].test_parameters &&
                              ltvLead.booking[2].test_parameters.length > 0 &&
                              ltvLead.booking[2].test_parameters.map(
                                (val: any) => {
                                  if (
                                    val.test_values__test_parameter ===
                                    row.test_values__test_parameter__id
                                  ) {
                                    return String(val.test_values__value).slice(
                                      0,
                                      25
                                    );
                                  }
                                }
                              )}
                          </StyledTableCellBordered>
                        </StyledTableRow>
                      ))}
                  </TableBody>
                </Table>
              )}
            </TableContainer>
          </Dialog>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={openPdf}
            onClose={handlePdfModelClose}
            closeAfterTransition
          >
            <Fade in={openPdf}>
              <>
                {fileType === "pdf" ? (
                  <iframe
                    style={{ width: "80%", height: "750px" }}
                    src={pLink}
                    title="title"
                  >
                    Presss me: <a href={pLink}>Download PDF</a>
                  </iframe>
                ) : fileType === "b64" ? (
                  <iframe
                    src={pLink}
                    style={{ width: "80%", height: "750px" }}
                    title="Iframe Example"
                  ></iframe>
                ) : (
                  ""
                )}
              </>
            </Fade>
          </Modal>
        </div>

        <CommentsModal open={open} setOpen={setOpen} leadId={leadId} />
        <CommentsModal2
          open={open2}
          setOpen={setOpen2}
          leadId={leadId}
          getCities={getCities}
          cities={cities}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  all_lead: state.PhleboAdminReducer.all_lead,
  cities: state.PhleboAdminReducer.cities,
  loading: state.PhleboAdminReducer.loading,
  leadStatus: state.PhleboAdminReducer.leadStatus,
  prescription: state.PhleboAdminReducer.prescription,
  ltvLead: state.PhleboAdminReducer.ltvLead,
  breakResponse: state.loginReducer.breakResponse,
  leadById: state.PhleboAdminReducer.leadById,
  dispositionList: state.PhleboAdminReducer.dispositionList,
  bookingComplaints: state.PhleboAdminReducer.bookingComplaints,
  phleboList: state.PhleboAdminReducer.phleboList,
  leadLoading: state.PhleboAdminReducer.leadLoading,
  language: state.PhleboAdminReducer.language,
});

export default connect(mapStateToProps, {
  getAllLeads,
  clickToCall,
  getCities,
  getLeadStatus,
  getPrescriptionData,
  sendInitialMessage,
  getLtvLead,
  getLeadDispostion,
  leadTransferByComma,
  getPhlebos,
  getLanguage,
})(LeadsTable);
