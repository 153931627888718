import React, { useState, useEffect, useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Grid,
  TextField,
  withStyles,
} from "@material-ui/core";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from '@mui/material/Button';
import { connect } from "react-redux";
import { useHistory } from "react-router";
import {
  getSampleHandOver,
  updateRiderToLabStatus,
  postMultipleBookingPayment,
  getRiderList,
  getPhlebos,
  getPaymentInfo,
  getCities,
} from "../../actions/RouteManagerAction";
import { genrateSampleHandoverFilter } from "../../../helpers/generateUrl";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      marginTop: "2rem",
      width: "100%",
    },
    table: {
      maxWidth: "100%",
    },
    tableHead: {
      background: "rgb(146, 74, 145)!important",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    tableHeadCell: {
      color: "#fff",
      fontWeight: "bold",
      textAlign: "center",
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
      width: "98%",
    },
    input: {
      padding: "1px",
    },
    // imageSM: {
    //   width: "60px",
    //   height: "60px",
    //   border: "2px solid #000 ",
    //   borderRadius: "50%"
    // },
    content: {
      width: "80%",
      flexGrow: 21,
      //   padding: theme.spacing(3),
    },
    imagePaper: {
      position: "absolute",
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[1],
    },
    dialog: {
      zIndex: 1,
    },
  })
);
const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

function rand() {
  return Math.round(Math.random() * 20) - 10;
}
function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

interface SampleHandOverProps {
  getSampleHandOver: any;
  sampleHandOverData: any;
  updateRiderToLabStatus: any;
  getRiderList: any;
  riderList: any;
  getPhlebos: any;
  phleboList: any;
  loading: boolean;
  getPaymentInfo: any;
  paymentInformation: any;
  getCities: any;
  cities: any;
  postMultipleBookingPayment: any;
}

const BookingsTable: React.FC<SampleHandOverProps> = ({
  getSampleHandOver,
  sampleHandOverData,
  updateRiderToLabStatus,
  getRiderList,
  riderList,
  getPaymentInfo,
  paymentInformation,
  getPhlebos,
  phleboList,
  getCities,
  cities,
  postMultipleBookingPayment,
  loading,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const timer = useRef<any>(0);
  const [bookingId, setBookingId] = useState<any>("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [phlebo, setPhlebo] = useState<any>();
  const [barcode, setBarcode] = useState<any>("");
  const [batchNumber, setBatchNumber] = useState<any>("");
  const [reciptNumber, setReciptNumber] = useState<any>("");
  const [remarks, setRemarks] = useState<any>("");
  const [imgSrc, setImgSrc] = useState<any>("")
  const [selectedBooking, setSelectedBooking] = useState<any>([]);
  const [selectedBookingCashAmount, setSelectedBookingCashAmount] = useState<any>([]);
  const [file1, setFile1] = React.useState<any>("");
  const [totalAmount, setTotalAmount] = React.useState<any>(0);
  const [caseAmount, setCashAmount] = React.useState<any>(0);
  const [batchImageUrl, setBatchImageUrl] = useState<any>("");
  const [batchImageModalOpen, setBatchImageModalOpen] = useState<any>("");
  const [reducerData, setReducerData] = useState<any>("")
  const [reducerDataForRemoval, setReducerDataForRemoval] = useState<any>("")
  const [recieptAmount, setRecieptAmount] = React.useState<any>(0);
  const [disableBtn, setDisableBtn] = useState<boolean>(false);

  useEffect(() => {
    setTotalAmount(totalAmount + caseAmount)
  }, [reducerData, reducerDataForRemoval])

  useEffect(() => {
    const x = selectedBookingCashAmount.reduce((sum: any, a: any) => sum + a, 0)
    setTotalAmount(x)
  }, [selectedBookingCashAmount])

  useEffect(() => {
    getSampleHandOver(`receipt_uploaded=false`);
    getPhlebos();
  }, []);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url =
        sampleHandOverData.links && sampleHandOverData.links.next.split("?")[1];
      getSampleHandOver(url);
    } else if (newPage < page) {
      let url =
        sampleHandOverData.links &&
        sampleHandOverData.links.previous.split("?")[1];
      getSampleHandOver(url);
    }
    setPage(newPage as number);
  };

  const filterSampleHandover = (e: any) => {
    const body: any = {

      phlebo: phlebo,
      booking: bookingId,
      barcode,
      id: batchNumber,
      // city:city
    };
    const url = genrateSampleHandoverFilter(body).substring(2);
    getSampleHandOver(`receipt_uploaded=false&${url}`);
    setPage(0);
  };



  const submitBooking = async (e: any) => {
    e.preventDefault();
    const body = {
      batches: selectedBooking,
      receipt: file1,
      receipt_number: reciptNumber,
      remark: remarks,
      deposit_amount: recieptAmount

    }
    setDisableBtn(true);
    await postMultipleBookingPayment(body)
    setRecieptAmount("")
    setFile1("")
    setRemarks("")
    setReciptNumber("")
    setTotalAmount("")
    setSelectedBooking([])
    setSelectedBookingCashAmount([])
    history.push("/dashboard/rm/multipleBookingPaymentUpload")
  }
  const fileSelectedHandler1 = async (e: React.ChangeEvent<{ files: any }>) => {
    setImgSrc(URL.createObjectURL(e.target.files[0]))
    const base64 = await convertBase64(e.target.files[0])
    setFile1(base64);
  };
  const convertBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  }
  useEffect(() => {
    if (disableBtn) {
      setTimeout(() => {
        setDisableBtn(false);
      }, 10000)
    }
  }, [disableBtn])

  return (
    <div className={classes.content}>
      <div className={classes.toolbar} />
      <h2>Multiple Booking Payment Upload</h2>
      <Grid container spacing={1} direction="row">

        <Grid item xs={12} sm={4} md={2}>
          <TextField
            className="input"
            name="name"
            type="number"
            label="Booking Id"
            value={bookingId}
            inputProps={{ min: 1, max: 9999999999 }}
            variant="outlined"
            onChange={(e) => setBookingId(e.target.value)}
            style={{ width: "100%" }}
          />
        </Grid>

        <Grid item xs={12} sm={4} md={2}>
          <Autocomplete
            id="phleboName"
            onChange={(event, newValue) => {
              if (newValue) {
                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                setPhlebo(obj.id);
              }
            }}
            options={
              phleboList.results
                ? [
                  ...phleboList.results,
                  { id: 0, user: { username: "NoPhlebo" } },
                ]
                : []
            }
            freeSolo
            blurOnSelect
            aria-required
            getOptionLabel={(option: any) =>
              option.user && option.user.username
            }
            onInputChange={(event, newInputValue) => {
              clearTimeout(timer.current);
              timer.current = setTimeout(() => {
                getPhlebos(`?code=${newInputValue}`);
              }, 1000);
              if (newInputValue.length === 0) {
                setPhlebo([]);
              }
            }}
            renderInput={(params) => (
              <TextField
                className="input"
                {...params}
                placeholder="Phlebo Name"
                variant="outlined"
                style={{ width: "100%", margin: "0", padding: "0" }}
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={filterSampleHandover}
            disabled={loading}
          >
            Filter
          </Button>
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => history.push("/dashboard/rm/multipleBookingPaymentUpload")}
          >
            Reset
          </Button>
        </Grid>
      </Grid>

      {
        selectedBooking && selectedBooking.length > 0 && (
          <>
            <Grid container spacing={2} direction="row" style={{ marginTop: "5px" }}>
              <Grid item xs={12} sm={4} md={2}>
                <TextField
                  className="input"
                  name="totalAmount"
                  type="number"
                  label="Total Amount "
                  value={totalAmount}
                  inputProps={{ min: 1, max: 9999999999 }}
                  variant="outlined"
                  onChange={(e) => setTotalAmount(e.target.value)}
                  disabled
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <TextField
                  className="input"
                  name="recieptAmount"
                  type="text"
                  label="Reciept Amount "
                  value={recieptAmount}
                  inputProps={{ min: 1, max: totalAmount }}
                  variant="outlined"
                  onChange={(e) => setRecieptAmount(e.target.value)}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <TextField
                  className="input"
                  name="reciptNumber"
                  type="text"
                  label="Recipt Number "
                  value={reciptNumber}
                  inputProps={{ min: 1, max: 9999999999 }}
                  variant="outlined"
                  onChange={(e) => setReciptNumber(e.target.value)}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <TextField
                  className="input"
                  name="name"
                  type="text"
                  label="Remarks"
                  value={remarks}
                  variant="outlined"
                  onChange={(e) => setRemarks(e.target.value)}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <sup
                  style={{
                    fontSize: "10px",
                    marginLeft: "15px",
                    marginTop: "10px",
                    background: "#fff",
                    color: "rgba(0, 0, 0, 0.54)",
                    letterSpacing: "0.00938em",
                  }}
                >
                  Upload Bank Receipt
                </sup>
                <input
                  className="input"
                  required
                  style={{
                    width: "100%",
                    marginTop: "-15px",
                    marginBottom: "10px",
                    padding: "18.5px 14px",
                    border: "1px solid rgba(0, 0, 0, 0.23)",
                    borderRadius: "5px",
                  }}
                  type="file"
                  name="file"
                  id="file"
                  multiple
                  onChange={fileSelectedHandler1}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <Button
                  variant="contained"
                  color="success"

                  fullWidth
                  onClick={submitBooking}
                  disabled={file1 === "" || disableBtn}

                >
                  Submit
                </Button>
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <Button
                  variant="contained"
                  color="secondary"

                  fullWidth
                  onClick={() => history.push("/dashboard/rm/multipleBookingPaymentUpload")}

                >
                  Reset
                </Button>
              </Grid>
            </Grid>
          </>
        )
      }

      <div className="data-table">
        <TableContainer
          className={classes.tableContainer}
          component={Paper}
          style={{ maxHeight: "540px" }}
        >
          <Table stickyHeader aria-label="simple table">
            <TableHead className={classes.tableHead}>
              <StyledTableRow>
                <StyledTableCell className={classes.tableHeadCell}>
                  <input
                    type="checkbox"
                    className="input"
                    name="selectall"
                    id="selectall"
                    onChange={(e) =>
                      e.target.checked
                        ? (
                          setSelectedBooking(
                            sampleHandOverData.results &&
                            sampleHandOverData.results.map((data: any) => {
                              return data.id && data.id;
                            })
                          ),
                          setSelectedBookingCashAmount(
                            sampleHandOverData.results &&
                            sampleHandOverData.results.map((data: any) => {
                              return data.cash_amount && data.cash_amount;
                            })
                          ),
                          setReducerDataForRemoval(Math.floor((Math.random() * 100000000000))),
                          setReducerData(Math.floor((Math.random() * 100000000000)))
                        )
                        : (
                            setSelectedBooking([]),
                            setSelectedBookingCashAmount([]),
                            setReducerData(Math.floor((Math.random() * 100000000000))),
                            setReducerDataForRemoval(Math.floor((Math.random() * 100000000000)))
                          )
                    }
                  />
                </StyledTableCell>

                <StyledTableCell className={classes.tableHeadCell}>
                  Booking IDs
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Booking Amt.
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Cash Amt.
                </StyledTableCell>

                <StyledTableCell className={classes.tableHeadCell}>
                  Status
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Sample Handover Time
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  HandOver To
                </StyledTableCell>



              </StyledTableRow>


            </TableHead>
            <TableBody>
              {sampleHandOverData &&
                sampleHandOverData?.results &&
                sampleHandOverData?.results?.map((data: any, index: any) => {
                  return (
                    <StyledTableRow key={index}>
                      <StyledTableCell align="center">
                        <input
                          type="checkbox"
                          name="selectall"
                          id="selectall"
                          checked={selectedBooking.find(
                            (lead: any) => lead === data.id
                          )}
                          onChange={(e) =>
                            !e.target.checked
                              ? (setSelectedBooking(
                                selectedBooking.filter(
                                  (lead: any) => lead !== data?.id,
                                )
                              ),
                                setReducerDataForRemoval(Math.floor((Math.random() * 100000000000))),
                                setCashAmount(-1 * (data?.cash_amount)))
                              : setSelectedBooking((prev: any) => [
                                ...prev,
                                data.id,
                                setReducerData(Math.floor((Math.random() * 100000000000))),
                                setCashAmount(data?.cash_amount)
                              ])
                          }
                        />
                      </StyledTableCell>

                      <StyledTableCell align="center">
                        {String(data?.items)}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.booking_amount}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.cash_amount}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.batch_status}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.sample_handover_time === null
                          ? ""
                          : data?.sample_handover_time.substring(0, 10)}
                        <br />
                        {data?.sample_handover_time === null
                          ? ""
                          : data?.sample_handover_time.substring(11, 20)}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.given_to}
                      </StyledTableCell>



                    </StyledTableRow>
                  );
                })}
            </TableBody>
            <TableFooter>
              <StyledTableRow>
                <TablePagination
                  colSpan={3}
                  count={sampleHandOverData?.count || 0}
                  rowsPerPageOptions={[]}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                />
              </StyledTableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  sampleHandOverData: state.RouteManagerReducer.sampleHandOverData,
  riderList: state.RouteManagerReducer.riderList,
  phleboList: state.RouteManagerReducer.phleboList,
  cities: state.RouteManagerReducer.cities,
  paymentInformation: state.RouteManagerReducer.paymentInformation,
  loading: state.RouteManagerReducer.loading,
});
export default connect(mapStateToProps, {
  getSampleHandOver,
  updateRiderToLabStatus,
  getRiderList,
  getPhlebos,
  getPaymentInfo,
  postMultipleBookingPayment,
  getCities,
})(BookingsTable);
