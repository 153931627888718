import React, { useState, useEffect, useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Grid,
  TextField,
  withStyles,
  MenuItem,
  Select,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import moment from "moment";
import Autocomplete from "@material-ui/lab/Autocomplete";
import VisibilityTwoToneIcon from "@material-ui/icons/VisibilityTwoTone";
import Button from '@mui/material/Button';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Loader from "../../components/loader";
import MessageIcon from "@material-ui/icons/Message";
import { useHistory } from "react-router";
import { } from "../../actions/LogisticsPanelAction";
import {
  getRiderData,
  getPinCode,
  updateRiderAddress,
  getSampleHandOver,
  getRiderList,
  updateRiderRouteOrder,
  DeleteRiderRoute,
  getRiderJourneyComments,
  getRiderRiderTravelRecord,
  getRiderRound,
  TransferRemoveRider,
  getLabDetails,
  getRoutes,
  postAssignRoutes,
  updateAssignRoutes,
} from "../../actions/LogisticsPanelAction";
import Modal from "@mui/material/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import { genrateSampleHandoverFilter } from "../../../helpers/generateUrl";
import Fade from "@material-ui/core/Fade";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import TimeField from "react-simple-timefield";
import RiderComments from "../../components/Comments/RiderComments";
import CommentIcon from "@material-ui/icons/Comment";
import TransferWithinAStationIcon from "@material-ui/icons/TransferWithinAStation";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    table: {
      maxWidth: "100%",
    },
    tableHead: {
      background: "rgb(146, 74, 145)!important",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    tableHeadCell: {
      color: "#fff",
      fontWeight: "bold",
      textAlign: "center",
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
    poppaper: {
      border: "1px solid",
      padding: theme.spacing(1),
      backgroundColor: theme.palette.background.paper,
    },
  })
);
const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);
interface SampleHandOverProps {
  getRiderData: any;
  riderData: any;
  getSampleHandOver: any;
  getPinCode: any;
  getRiderList: any;
  riderList: any;
  pin_code: any;
  updateRiderAddress: any;
  updateRiderRouteOrder: any;
  DeleteRiderRoute: any;
  getRiderJourneyComments: any;
  riderCommentsJourney: any;
  riderTravelRecords: any;
  getRiderRiderTravelRecord: any;
  getRiderRound: any;
  riderRound: any;
  loading: any;
  TransferRemoveRider: any;
  getLabDetails: any;
  labDetails: any;
  getRoutes: any;
  routesData: any;
  postAssignRoutes: any;
  updateAssignRoutes: any;
}

const BookingsTable: React.FC<SampleHandOverProps> = ({
  getRiderData,
  riderData,
  getRiderList,
  riderList,
  getPinCode,
  pin_code,
  updateRiderAddress,
  updateRiderRouteOrder,
  DeleteRiderRoute,
  getRiderJourneyComments,
  riderCommentsJourney,
  getRiderRiderTravelRecord,
  riderTravelRecords,
  getRiderRound,
  riderRound,
  loading,
  TransferRemoveRider,
  getLabDetails,
  labDetails,
  getRoutes,
  routesData,
  postAssignRoutes,
  updateAssignRoutes,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const timer = useRef<any>(0);
  const [open, setOpen] = React.useState(false);
  const [viewRoute, setViewRoute] = useState<boolean>(false);
  const [viewRouteData, setViewRouteData] = useState<any>("");
  const [riderAddress, setRiderAddress] = useState<any>("");
  const [riderVal, setRiderVal] = useState<any>("");
  const [riderValAssigned, setRiderValAssigned] = useState<any>("");
  const [routes, setRoutes] = useState<any>([]);
  const [transterToRider, setTransferToRider] = useState<any>("");
  const [page, setPage] = React.useState(0);
  const [riderIndivisualDetails, setRiderIndevisualDetails] = useState<any>();
  const [editRiderAddress, setEditRiderAddress] = useState<boolean>(false);
  const [editRiderPoint, setEditRiderPoint] = useState<boolean>(false);
  const [indAddress, setIndAddress] = useState<any>("");
  const [indLatidude, setIndLatitude] = useState<any>("");
  const [indLongitude, setIndLongitTude] = useState<any>("");
  const [indNearByAddress, setNearByAddress] = useState<any>("");
  const [indRouteOrder, setIndRouteOrder] = useState<any>("");
  const [pinCode, setIndPinCode] = useState<any>("");
  const [riderRoutePointId, setRiderRoutePointId] = useState<any>("");
  const [riderRouteId, setRiderRouteId] = useState<any>("");
  const [riderId, setRiderId] = useState<any>("");
  const [arrivalTime, setArrivalTime] = useState(moment().format("hh:mm:ss"));
  const [rider, setRider] = useState<any>([]);
  const [addressId, setAddressId] = useState<any>("");
  const [openRiderComment, setOpenRidderComment] = useState<boolean>(false);
  const [modalType, setModalType] = useState<any>("");
  const [transferRiderRound, setTransferRiderRound] = useState<boolean>(false);
  const [transferRiderRoute, setTransferRiderRoute] = useState<boolean>(false);
  const [openAssignRoute, setOpenAssignRoute] = useState<boolean>(false);
  const [oldRiderId, setOldRiderId] = useState<any>("");
  const [labId, setlabId] = useState<any>("");
  const [round, setRound] = useState<any>("");
  const current = new Date();
  var today = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  let yyyy = today.getFullYear();
  let currentDate = yyyy + "-" + mm + "-" + dd;
  useEffect(() => {
    getRiderData();
    getPinCode();
    getLabDetails();
    getRiderList(`?usergroup=Rider`);
    getRoutes();
  }, []);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url = riderData.links && riderData.links.next.split("?")[1];
      getRiderData(url ? `?${url}`: "");
    } else if (newPage < page) {
      let url = riderData.links && riderData.links.previous.split("?")[1];
      getRiderData(url ? `?${url}`: "");
    }
    setPage(newPage as number);
  };
  const filterSampleHandover = () => {
    const body: any = {
      id: rider,
      lab: labId,
    };
    const url = genrateSampleHandoverFilter(body).substring(2);
    getRiderData(`?${url}`);
    setPage(0);
  };
  const handleEditRiderData = (type: any, rider: any, data: any) => {
    setEditRiderAddress(false);
    setEditRiderPoint(false);
    setTransferRiderRoute(false);
    setTransferRiderRound(false);
    setModalType(type);
    setAddressId(rider?.route_point?.id);
    setRiderAddress(
      "Address : " +
      rider?.route_point?.address +
      " " +
      "Route Point : " +
      rider?.route_order
    );
    setIndAddress(rider?.route_point?.address);
    setIndLatitude(rider?.route_point?.latitude);
    setIndLongitTude(rider?.route_point?.longitude);
    setNearByAddress(rider?.route_point?.near_address);
    setIndPinCode(rider?.route_point?.areapincode?.id);
    setIndRouteOrder(rider?.route_order);
    setRiderRoutePointId(rider?.id);
    setArrivalTime(
      rider?.arrive_time !== null ? rider?.arrive_time : "00:00:00"
    );
    setRiderIndevisualDetails(rider);
    setOpen(true);
  };

  const handleRoute = (type: any, data: any) => {
    getRiderRound(data?.id);
    setOldRiderId(data?.id);

    setEditRiderAddress(false);
    setEditRiderPoint(false);
    setTransferRiderRoute(false);
    setTransferRiderRound(false);
    setModalType(type);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleEditRiderAddress = () => {
    setEditRiderAddress(true);
    setEditRiderPoint(false);
  };
  const handleEditRiderPoints = () => {
    setEditRiderPoint(true);
    setEditRiderAddress(false);
  };
  const handletransferRound = () => {
    setTransferRiderRound(true);
    setTransferRiderRoute(false);
    setEditRiderAddress(false);
    setEditRiderPoint(false);
  };
  const handleTransferRoute = () => {
    setTransferRiderRoute(true);
    setTransferRiderRound(false);
    setEditRiderAddress(false);
    setEditRiderPoint(false);
  };
  const handleUpdateRider = async () => {
    const body = {
      address: indAddress,
      near_address: indNearByAddress,
      latitude: indLatidude,
      longitude: indLongitude,
      areapincode: pinCode,
    };
    const body2 = {
      arrive_time: arrivalTime,
      route_order: indRouteOrder,
    };
    if (editRiderAddress) {
      await updateRiderAddress(body, addressId);
      getRiderData();
      handleClose();
    }
    if (editRiderPoint) {
      await updateRiderRouteOrder(body2, riderRoutePointId);
      getRiderData();
      handleClose();
    }
  };
  const transferRound = async () => {
    const body = {
      type: "round",
      round,
      rider: oldRiderId,
      new_rider: transterToRider,
    };
    const body2 = {
      type: "route",
      rider: oldRiderId,
      new_rider: transterToRider,
    };

    if (transferRiderRound) {
      await TransferRemoveRider(body);
      getRiderData();
      handleClose();
    }
    if (transferRiderRoute) {
      await TransferRemoveRider(body2);
      getRiderData();
      handleClose();
    }
  };

  // const handleArrivalTime = (event: any, value: any) => {
  //     const newTime1 = value.replace(/-/g, ':');
  //     const timeSecond1 = newTime1.padEnd(8, arrivalTime.substr(5, 3));

  //     setArrivalTime(timeSecond1)

  // }
  const handleDeleteRiderRoute = (rider: any, data: any) => {
    const body = {
      rider: data?.id,
      rider_route: rider?.id,
    };
    DeleteRiderRoute(body);
    getRiderData();
  };
  const handleRiderComments = (riderId: any) => {
    getRiderJourneyComments(`?created_at=${currentDate}&rider=${riderId}`);
    getRiderRiderTravelRecord(riderId);
    setOpenRidderComment(true);
  };
  const handleOpen = (val: any) => {
    setOpenAssignRoute(true);
    setRiderVal(val);
    setRiderValAssigned(val?.assigned_routes);
    if (
      val?.assigned_routes &&
      val?.assigned_routes.assigned_routes &&
      val?.assigned_routes.assigned_routes.length > 0 &&
      val?.assigned_routes.assigned_routes.map((val: any) => {
        routes.push(val.id);
      })
    ) {
    }
  };
  const handleCloseAssign = () => {
    setOpenAssignRoute(false);
    setRiderVal("");
    setRiderValAssigned("");
    setRoutes([]);
    getRoutes();
  };
  const assignRoute = async () => {
    const data = {
      rider: riderVal.id,
      routes: routes,
    };
    if (riderValAssigned !== null) {
      await updateAssignRoutes(riderValAssigned.id, data);
    } else {
      await postAssignRoutes(data);
    }
    getRiderData();
    handleCloseAssign();
    history.push("/dashboard/lp/manageRider");
  };
  const viewRouteDetails = (val: any) => {
    setViewRouteData(val);
    setViewRoute(true);
  };
  const closeViewRouteDetails = () => {
    setViewRouteData("");
    setViewRoute(false);
  };
  return (
    <div style={{ width: "100%" }}>
      <div className={classes.toolbar} />
      <Grid item xs={12} sm={8}>
        <h4 style={{ fontWeight: "bold" }}>MANAGE RIDERS</h4>
      </Grid>
      <Grid
        container
        spacing={1}
        direction="row"
        style={{ alignItems: "left" }}
      >
        <Grid item xs={12} sm={6} md={3}>
          <Autocomplete
            id="lab"
            onChange={(event, newValue) => {
              if (newValue) {
                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                setlabId(obj.id);
              }
            }}
            freeSolo
            blurOnSelect
            aria-required
            limitTags={1}
            options={labDetails?.results || []}
            disableClearable
            disableCloseOnSelect
            getOptionLabel={(option: any) => String(option?.name)}
            onInputChange={(event, newInputValue) => {
              clearTimeout(timer.current);
              timer.current = setTimeout(() => {
                getLabDetails(`?name=${newInputValue}`);
              }, 1000);
              if (newInputValue.length === 0) {
                setlabId("");
              }
            }}
            renderInput={(params) => (
              <TextField
                className="input"
                {...params}
                placeholder="Lab Name"
                variant="outlined"
                style={{ width: "100%", margin: "0", padding: "0" }}
                inputProps={{
                  ...params.inputProps,
                  onKeyDown: (e) => {
                    if (e.key === 'Enter') {
                      e.stopPropagation();
                    }
                  },
                }}
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Autocomplete
            id="booking_source"
            onChange={(event, newValue) => {
              if (newValue) {
                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                setRider(obj.id);
              }
            }}
            options={riderList?.results || []}
            freeSolo
            blurOnSelect
            aria-required
            getOptionLabel={(option: any) =>
              option.user && option.user.username
            }
            onInputChange={(event, newInputValue) => {
              clearTimeout(timer.current);
              timer.current = setTimeout(() => {
                getRiderList(`?usergroup=Rider&code=${newInputValue}`);
              }, 1000);
              if (newInputValue.length === 0) {
                setRider(0);
              }
            }}
            renderInput={(params) => (
              <TextField
                className="input"
                {...params}
                placeholder="Rider"
                variant="outlined"
                style={{ width: "100%", margin: "0", padding: "0" }}
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Button
            variant="contained"
            color="secondary"
            disabled={loading}
            fullWidth
            onClick={filterSampleHandover}
          >
            Filter
          </Button>
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Button
            variant="contained"
            color="primary"

            fullWidth
            onClick={() => history.push("/dashboard/lp/manageRider")}
          >
            Reset
          </Button>
        </Grid>
      </Grid>
      <TableContainer
        className={classes.tableContainer}
        component={Paper}
        style={{ maxHeight: "550px" }}
      >
        <Table stickyHeader aria-label="simple table">
          <TableHead className={classes.tableHead}>
            <StyledTableRow>
              <StyledTableCell className={classes.tableHeadCell}>
                User Name
              </StyledTableCell>
              <StyledTableCell className={classes.tableHeadCell}>
                Rider journey
              </StyledTableCell>
              <StyledTableCell className={classes.tableHeadCell}>
                Route
                <table style={{ width: "100%" }}>
                  <tr>
                    <td
                      style={{
                        border: "0.7px solid white",
                        width: "30%",
                      }}
                    >
                      Route Name
                    </td>
                    <td
                      style={{
                        border: "0.7px solid white",
                        width: "33%",
                      }}
                    >
                      Source
                    </td>
                    <td
                      style={{
                        border: "0.7px solid white",
                        width: "33%",
                      }}
                    >
                      Destination
                    </td>
                    <td
                      style={{
                        border: "0.7px solid white",
                        width: "4%",
                      }}
                    >
                      View
                    </td>
                  </tr>
                </table>
              </StyledTableCell>
              <StyledTableCell className={classes.tableHeadCell}>
                Transfer
              </StyledTableCell>

              <StyledTableCell className={classes.tableHeadCell}>
                Assign Route
              </StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {riderData &&
              riderData?.results &&
              riderData?.results.length > 0 &&
              riderData?.results.map((data: any, index: any) => {
                return (
                  <StyledTableRow key={index}>
                    <StyledTableCell align="center">
                      {data?.user?.username}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <CommentIcon
                        onClick={() => handleRiderComments(data.id)}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {data?.assigned_routes &&
                        data?.assigned_routes.assigned_routes &&
                        data?.assigned_routes.assigned_routes.length > 0 &&
                        data?.assigned_routes.assigned_routes.map(
                          (rt: any, index: any) => {
                            return (
                              <>
                                <div
                                  style={{
                                    alignItems: "right",
                                    cursor: "pointer",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    border: "0.7px solid grey",
                                  }}
                                >
                                  <table style={{ width: "100%" }}>
                                    <tr>
                                      <td
                                        style={{
                                          border: "0.7px solid grey",
                                          width: "30%",
                                        }}
                                      >
                                        {rt?.name}
                                      </td>
                                      <td
                                        style={{
                                          border: "0.7px solid grey",
                                          width: "33%",
                                        }}
                                      >
                                        {rt?.source}({rt.start_time})
                                      </td>
                                      <td
                                        style={{
                                          border: "0.7px solid grey",
                                          width: "33%",
                                        }}
                                      >
                                        {rt?.destination}({rt.end_time})
                                      </td>
                                      <td
                                        style={{
                                          border: "0.7px solid grey",
                                          width: "4%",
                                        }}
                                      >
                                        <VisibilityTwoToneIcon
                                          color="primary"
                                          onClick={() => viewRouteDetails(rt)}
                                        />
                                      </td>
                                    </tr>
                                  </table>
                                </div>
                              </>
                            );
                          }
                        )}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <TransferWithinAStationIcon
                        onClick={() => handleRoute("transfer", data)}
                      />
                    </StyledTableCell>

                    <StyledTableCell align="center">
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => handleOpen(data)}
                      >
                        Assign Route
                      </Button>
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
          </TableBody>
          <TableFooter>
            <StyledTableRow>
              <TablePagination
                count={riderData?.count || 0}
                rowsPerPageOptions={[]}
                rowsPerPage={riderData?.page_size}
                page={page}
                SelectProps={{
                  native: true,
                }}
                onPageChange={handleChangePage}
              />
            </StyledTableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <Dialog open={openAssignRoute} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Assign Route</DialogTitle>
        <DialogContent>
          <Grid
            container
            spacing={2}
            style={{
              minWidth: "550px",
            }}
          >
            <Grid item xs={12} md={12}>
              <p>Rider</p>
              <TextField
                className="input"
                type="text"
                disabled
                fullWidth
                value={riderVal?.user?.username}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <p>Select Routes</p>

              <Autocomplete
                id="bookings"
                onChange={(event, newValue) => {
                  let sourceIds: any = [];
                  if (newValue) {
                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                    sourceIds = obj.map((item: any) => item?.id);
                  }
                  setRoutes(sourceIds);
                }}
                multiple
                freeSolo
                limitTags={5}
                options={routesData?.results || []}
                defaultValue={riderValAssigned?.assigned_routes || []}
                getOptionDisabled={(option: any) => {
                  return routes.includes(option);
                }}
                loading={loading}
                disableClearable
                disableCloseOnSelect
                getOptionLabel={(option: any) => `${option?.name}`}
                onInputChange={(event, newInputValue) => {
                  clearTimeout(timer.current);
                  timer.current = setTimeout(() => {
                    getRoutes(`?code=${newInputValue}`);
                  }, 1000);

                  if (newInputValue.length === 0) {
                    setRoutes([]);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="input"
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                      onKeyDown: (e) => {
                        if (e.key === 'Enter') {
                          e.stopPropagation();
                        }
                      },
                    }}
                    fullWidth
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => assignRoute()}
            variant="contained"
            color="primary"
          >
            Submit
          </Button>
          <Button
            onClick={handleCloseAssign}
            color="secondary"
            variant="contained"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={viewRoute}
        onClose={() => closeViewRouteDetails()}
        aria-labelledby="form-dialog-title"
      >
        <div style={{ padding: "20px", fontSize: "16px" }}>
          <h5>Route : {viewRouteData?.name}</h5>
          <hr />
          <div style={{ marginBottom: "15px" }}>
            Source : {viewRouteData?.source}({viewRouteData.start_time})
          </div>
          Stops :{" "}
          <ul>
            {viewRouteData &&
              viewRouteData.stops_detail &&
              viewRouteData.stops_detail.length > 0 &&
              viewRouteData.stops_detail.map((val: any) => {
                return (
                  <li>
                    {val?.name}({val.time})
                  </li>
                );
              })}
          </ul>
          <div>
            Destination : {viewRouteData?.destination}(
            {viewRouteData.start_time})
          </div>
        </div>
      </Dialog>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            {modalType === "editRider" ? (
              <Grid
                container
                spacing={3}
                direction="row"
                alignItems="center"
                justify="center"
              >
                <Grid item xs={12} sm={6} md={6}>
                  <TextareaAutosize
                    aria-label="minimum height"
                    minRows={3}
                    placeholder="About Company"
                    value={riderAddress}
                    style={{ width: "100%", marginRight: "10px" }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Button
                    variant="contained"
                    fullWidth
                    style={{ background: "#4caf50", height: "56px" }}
                    onClick={handleEditRiderAddress}
                  >
                    Edit Address
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Button
                    variant="contained"
                    style={{ height: "56px", background: "#ffc107" }}
                    fullWidth
                    // onClick={filterSampleHandover}
                    onClick={handleEditRiderPoints}
                  >
                    Edit Order
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <Grid
                container
                spacing={3}
                direction="row"
                alignItems="center"
                justify="center"
              >
                <Grid item xs={12} sm={6} md={6}>
                  <Button
                    variant="contained"
                    fullWidth
                    style={{ background: "#4caf50", height: "56px" }}
                    onClick={handletransferRound}
                  >
                    Transfer Round
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Button
                    variant="contained"
                    style={{ height: "56px", background: "#ffc107" }}
                    fullWidth
                    onClick={handleTransferRoute}
                  >
                    Transfer Route
                  </Button>
                </Grid>
              </Grid>
            )}
            {transferRiderRound ? (
              <Grid
                container
                spacing={3}
                direction="row"
                alignItems="center"
                justify="center"
              >
                <Grid item xs={12} sm={6} md={6}>
                  <p>Select Round</p>
                  <Select
                    className="input"
                    name="priority"
                    variant="outlined"
                    value={round}
                    style={{ width: "100%" }}
                    onChange={(e) => setRound(e.target.value as string)}
                    required
                  >
                    {riderRound &&
                      riderRound.length > 0 &&
                      riderRound.map((data: any) => {
                        return <MenuItem value={data}>{data}</MenuItem>;
                      })}
                  </Select>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <p>Transfer To</p>
                  <Autocomplete
                    id="booking_source"
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let obj = JSON.parse(
                          JSON.stringify(newValue, null, " ")
                        );
                        setTransferToRider(obj.id);
                      }
                    }}
                    options={riderList?.results || []}
                    freeSolo
                    blurOnSelect
                    aria-required
                    getOptionLabel={(option: any) =>
                      option.user && option.user.username
                    }
                    onInputChange={(event, newInputValue) => {
                      clearTimeout(timer.current);
                      timer.current = setTimeout(() => {
                        getRiderList(`?usergroup=Rider=${newInputValue}`);
                      }, 1000);
                      if (newInputValue.length === 0) {
                        setTransferToRider(0);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        className="input"
                        {...params}
                        placeholder="Rider"
                        variant="outlined"
                        style={{ width: "100%", margin: "0", padding: "0" }}
                        required
                      />
                    )}
                  />
                </Grid>
              </Grid>
            ) : (
              ""
            )}
            {transferRiderRoute ? (
              <Grid
                container
                spacing={3}
                direction="row"
                alignItems="center"
                justify="center"
              >
                <Grid item xs={12} sm={6} md={6}>
                  <p>Transfer To</p>
                  <Autocomplete
                    id="booking_source"
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let obj = JSON.parse(
                          JSON.stringify(newValue, null, " ")
                        );
                        setTransferToRider(obj.id);
                      }
                    }}
                    options={riderList?.results || []}
                    freeSolo
                    blurOnSelect
                    aria-required
                    getOptionLabel={(option: any) =>
                      option.user && option.user.username
                    }
                    onInputChange={(event, newInputValue) => {
                      clearTimeout(timer.current);
                      timer.current = setTimeout(() => {
                        getRiderList(`?usergroup=Rider&code=${newInputValue}`);
                      }, 1000);
                      if (newInputValue.length === 0) {
                        setTransferToRider(0);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        className="input"
                        {...params}
                        placeholder="Rider"
                        variant="outlined"
                        style={{ width: "100%", margin: "0", padding: "0" }}
                        required
                      />
                    )}
                  />
                </Grid>
              </Grid>
            ) : (
              ""
            )}
            {transferRiderRound || transferRiderRoute ? (
              <Grid
                container
                spacing={3}
                direction="row"
                alignItems="center"
                justify="center"
              >
                <Grid item xs={12} sm={6} md={6}>
                  <Button
                    variant="contained"
                    style={{ height: "56px", background: "#2962ff" }}
                    fullWidth
                    // onClick={filterSampleHandover}
                    onClick={transferRound}
                  >
                    Transfer Round
                  </Button>
                </Grid>
              </Grid>
            ) : (
              ""
            )}
            <Grid
              container
              spacing={3}
              direction="row"
              alignItems="center"
              justify="center"
            >
              {editRiderAddress ? (
                <Grid
                  container
                  spacing={3}
                  direction="row"
                  alignItems="center"
                  justify="center"
                >
                  <Grid item xs={12} sm={6} md={6}>
                    <p>Address</p>
                    <TextField
                      name="riderIndivisualDetails"
                      type="text"
                      value={indAddress}
                      className="input"
                      variant="outlined"
                      onChange={(e: any) => setIndAddress(e.target.value)}
                      style={{ width: "100%", margin: "0" }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={6}>
                    <p>Latitude</p>
                    <TextField
                      name="riderIndivisualDetails"
                      type="text"
                      value={indLatidude}
                      onChange={(e: any) => setIndLatitude(e.target.value)}
                      className="input"
                      variant="outlined"
                      style={{ width: "100%", margin: "0" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <p>Longitude</p>
                    <TextField
                      name="riderIndivisualDetails"
                      type="text"
                      value={indLongitude}
                      onChange={(e: any) => setIndLongitTude(e.target.value)}
                      className="input"
                      variant="outlined"
                      style={{ width: "100%", margin: "0" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <p>Near By Address</p>
                    <TextField
                      name="riderIndivisualDetails"
                      type="text"
                      value={indNearByAddress}
                      onChange={(e: any) => setNearByAddress(e.target.value)}
                      className="input"
                      variant="outlined"
                      style={{ width: "100%", margin: "0" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <p>PinCode</p>
                    <Autocomplete
                      id="pincode"
                      onChange={(event, newValue) => {
                        if (newValue) {
                          let obj = JSON.parse(
                            JSON.stringify(newValue, null, " ")
                          );
                          setIndPinCode(obj.id);
                        }
                      }}
                      options={pin_code.results || []}
                      freeSolo
                      blurOnSelect
                      aria-required
                      getOptionLabel={(option: any) =>
                        option?.pincode + "," + option?.area
                      }
                      onInputChange={(event, newInputValue) => {
                        clearTimeout(timer.current);
                        timer.current = setTimeout(() => {
                          getPinCode(newInputValue);
                        }, 1000);
                        if (newInputValue.length === 0) {
                          setIndPinCode(0);
                        }
                      }}
                      defaultValue={
                        riderIndivisualDetails?.route_point?.areapincode
                      }
                      renderInput={(params) => (
                        <TextField
                          className="input"
                          {...params}
                          label="Pin Code"
                          variant="outlined"
                          style={{ width: "100%", margin: "0", padding: "0" }}
                          required
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              ) : (
                ""
              )}
              {editRiderPoint ? (
                <Grid
                  container
                  spacing={3}
                  direction="row"
                  alignItems="center"
                  justify="center"
                >
                  <Grid item xs={12} sm={6} md={6}>
                    <p>Route Order</p>
                    <TextField
                      name="riderIndivisualDetails"
                      type="text"
                      value={indRouteOrder}
                      onChange={(e: any) => setIndRouteOrder(e.target.value)}
                      className="input"
                      variant="outlined"
                      style={{ width: "100%", margin: "0" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <p>Arrival Time</p>
                    {/* <TimeField
                                            showSeconds
                                            value={arrivalTime}
                                            onChange={handleArrivalTime}
                                            style={{ width: 100 }}
                                            input={<TextField label="Arrival Time" value={arrivalTime} variant="outlined" />}
                                        /> */}
                    <TextField
                      id="time"
                      type="time"
                      value={arrivalTime}
                      onChange={(e) => setArrivalTime(e.target.value)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        step: 300, // 5 min
                      }}
                      style={{ width: 150 }}
                    />
                  </Grid>
                </Grid>
              ) : (
                ""
              )}
              {editRiderAddress || editRiderPoint ? (
                <Grid item xs={12} sm={6} md={6}>
                  <Button
                    variant="contained"
                    style={{ height: "56px", background: "#2962ff" }}
                    fullWidth
                    // onClick={filterSampleHandover}
                    disabled={pinCode === 0}
                    onClick={handleUpdateRider}
                  >
                    Update Rider
                  </Button>
                </Grid>
              ) : (
                ""
              )}
            </Grid>
          </div>
        </Fade>
      </Modal>

      <RiderComments
        loading={loading}
        setOpenRidderComment={setOpenRidderComment}
        openRiderComment={openRiderComment}
        riderCommentsJourney={riderCommentsJourney}
        riderTravelRecords={riderTravelRecords}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  riderData: state.LogisticsReducer.riderData,
  riderList: state.LogisticsReducer.riderList,
  pin_code: state.LogisticsReducer.pin_code,
  riderCommentsJourney: state.LogisticsReducer.riderCommentsJourney,
  riderTravelRecords: state.LogisticsReducer.riderTravelRecords,
  riderRound: state.LogisticsReducer.riderRound,
  labDetails: state.LogisticsReducer.labDetails,
  routesData: state.LogisticsReducer.routesData,
  loading: state.LogisticsReducer.loading,
});
export default connect(mapStateToProps, {
  getRiderData,
  getPinCode,
  getSampleHandOver,
  getRiderList,
  updateRiderAddress,
  updateRiderRouteOrder,
  DeleteRiderRoute,
  getRiderJourneyComments,
  getRiderRiderTravelRecord,
  getRiderRound,
  TransferRemoveRider,
  getLabDetails,
  getRoutes,
  postAssignRoutes,
  updateAssignRoutes,
})(BookingsTable);
