import React, { useEffect, useRef, useState } from "react";
import {
  Paper,
  withStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Chip from "@mui/material/Chip";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import DatePicker, { DateObject } from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import { Grid, TextField } from "@material-ui/core";
import {
  getPhleboLeaveList,
  createLeaveList,
  getPhleboListUnderRouteManager,
  updatePhleboLeave,
  resetTableData,
  getZone1,
  getPhleboLeaveLog
} from "../../actions/PhleboAdmin";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@mui/material/Button";
import { MessageRounded } from "@material-ui/icons";
import { genratePhleboLeaveFilter } from "../../../helpers/generateUrl";
import {
  TableFooter,
  TablePagination,
  DialogTitle,
  DialogContent,
  Dialog,
} from "@mui/material";
import PhleboLeaveLog from "../../components/Comments/phleboLeaveLog";
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 0,
      //   width: "100%",
      //   marginTop: "20px",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
      //   padding: theme.spacing(3),
    },
    card: {
      height: "100%",
      padding: theme.spacing(1),
    },
    card_grid: {
      // wordSpacing:3,
      justifyContent: "space-between",
    },
  })
);

const StyledTableCell = withStyles((theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow = withStyles((theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const HolidayCalenderView = ({
  phleboList,
  getPhleboLeaveList,
  phleboLeaveList,
  createLeaveList,
  getPhleboListUnderRouteManager,
  phleboUnderRm,
  updatePhleboLeave,
  resetTableData,
  getZone1,
  phleboLeaveLog,
  getPhleboLeaveLog,
  zoneList,
}) => {
  const ref = useRef();
  const history = useHistory();
  const timer = useRef(0);
  const searchRef = useRef();
  const classes = useStyles();
  const format = "YYYY-MM-dd";
  const [phlebo, setPhlebo] = useState("");
  const [leaveReason, setLeaveReason] = useState("");
  const [dates, setDates] = useState([]);
  const [start_date, setStart_date] = useState("");
  const [end_date, setEnd_date] = useState("");
  const [updateHoliday, setUpdateHoliday] = useState(false);
  const [key, setKey] = useState(false);
  const [key1, setKey1] = useState(false);
  const [page, setPage] = React.useState(0);
  const [zoneName, setZoneName] = useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const[openLog,setOpenLog]= useState(false);
  useEffect(() => {
    getPhleboListUnderRouteManager();
    getZone1();
  }, []);
  var today = new Date();

  const getDaysArray = function (start, end) {
    for (
      var arr = [], dt = new Date(start);
      dt <= new Date(end);
      dt.setDate(dt.getDate() + 1)
    ) {
      arr.push(new Date(dt).toISOString().slice(0, 10));
    }
    return arr;
  };
  const [dayListData, setDayListData] = useState([]);
  const handleFilter = () => {
    const body = {
      start_date,
      end_date,
      phlebo,
      zone_name: zoneName,
    };
    const url = genratePhleboLeaveFilter(body).substring(2);
    getPhleboLeaveList(`?${url}`);
    var daylist = getDaysArray(new Date(start_date), new Date(end_date));
    setDayListData(daylist);
  };

  const [updateDate, setUpdateDate] = useState("");
  const [updatePhleboName, setUpdatePhleboName] = useState("");
  const [updatePhleboId, setUpdatePhleboId] = useState("");
  const [updatePhleboLeaveCat, setUpdatePhleboLeaveCat] = useState("");
  const [updateLeaveData, setUpdateLeaveData] = useState();
  const [updateSelectedLeaveData, setUpdateSelectedLeaveData] = useState("");
  const [updateLeaveId, setUpdateLeaveId] = useState("");
  const handleUpdateLeave = (data, date, phleboName) => {
    setUpdateHoliday(true);
    setUpdatePhleboName(phleboName);
    setUpdatePhleboId(data?.id);
    const filterPrLeave = data?.leave_data.filter(
      (data) => data?.leave_type !== "PR"
    );
    setUpdateLeaveData(filterPrLeave);
  };
  const handleUpdateHoliday = () => {
    setUpdateHoliday(false);
  };
  const updatePhleboLeaveType = async (e) => {
    e.preventDefault();
    const body = {
      phlebo: updatePhleboId,
      date: updateSelectedLeaveData,
      leave_type: updatePhleboLeaveCat,
      is_approved: true,
    };

    await updatePhleboLeave(body, updateLeaveId);
    handleFilter();
    handleUpdateHoliday();
  };
  const applyLeaveForPhlebo = async (e) => {
    e.preventDefault();
    let tempArray = new Array();
    {
      dates.map((date, index) =>
        tempArray.push(date.format().replaceAll("/", "-"))
      );
    }
    const body = {
      phlebo: phlebo,
      leave_type: leaveReason,
      is_approved: true,
      date_list: tempArray,
    };
    await createLeaveList(body);
    setKey(!key);
    setDates([]);
  };
  const handleChangePage = (event, newPage) => {
    if (newPage > page) {
      let url =
        phleboLeaveList.links && phleboLeaveList.links.next.split("?")[1];
      getPhleboLeaveList(url ? `?${url}` : "");
    } else if (newPage < page) {
      let url =
        phleboLeaveList.links && phleboLeaveList.links.previous.split("?")[1];
      getPhleboLeaveList(url ? `?${url}` : "");
    }
    setPage(newPage);
  };
  const resetleave = () => {
    history.push("dashboard/pha/holiday");
  };

  const handleClick = (id) => {
    getPhleboLeaveLog(`?phlebo_id=${id}`);
    setOpenLog(true)
  };
  document.querySelectorAll("#noTyping").forEach((el) => {
    el.addEventListener("keydown", function (e) {
      e.preventDefault();
    });
  });
  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <form onSubmit={applyLeaveForPhlebo}>
        <Grid
          container
          spacing={3}
          direction="row"
          style={{ alignItems: "left", marginTop: "20px", margin: "1rem auto" }}
        >
          <Grid item xs={12} sm={4} md={2}>
            <p>Phlebo Name</p>

            <Autocomplete
              id="phlebo"
              key={key}
              onChange={(event, newValue) => {
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  setPhlebo(obj.id);
                }
              }}
              freeSolo
              blurOnSelect
              aria-required
              limitTags={1}
              options={
                phleboUnderRm?.results
                  ? [
                      ...phleboUnderRm?.results,
                      { id: 0, phlebo_data: { phlebo: "NoPhlebo" } },
                    ]
                  : []
              }
              disableClearable
              disableCloseOnSelect
              getOptionLabel={(option) =>
                option.phlebo_data && option?.phlebo_data?.phlebo
              }
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getPhleboListUnderRouteManager(`?name=${newInputValue}`);
                }, 1000);
                if (newInputValue.length === 0) {
                  setPhlebo("");
                }
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  variant="outlined"
                  placeholder="Search Phlebo"
                  style={{
                    width: "100%",
                    margin: "0",
                    padding: "0",
                    margin: "auto",
                  }}
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <p>Date</p>
            <DatePicker
              value={dates}
              key={key}
              onChange={setDates}
              multiple
              sort
              // format={format}
              minDate={new DateObject().add(1, "days")}
              maxDate={new DateObject().add(37, "days")}
              style={{ height: "40px", width: "224px", margin: "auto" }}
              calendarPosition="bottom-center"
              plugins={[<DatePanel />]}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <p>Leave Type</p>
            <Autocomplete
              id="city"
              key={key}
              onChange={(event, newValue) => {
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  setLeaveReason(obj?.value);
                }
              }}
              options={[
                {
                  text: "Compensatory Off",
                  value: "CO",
                },
                {
                  text: "Week Off",
                  value: "WO",
                },
                {
                  text: "Sick Leave",
                  value: "SL",
                },
                {
                  text: "Casual Leave",
                  value: "CL",
                },
                {
                  text: "Anual Leave",
                  value: "AL",
                },
              ]}
              freeSolo
              blurOnSelect
              aria-required
              limitTags={1}
              getOptionLabel={(option) => option.text}
              disableClearable
              disableCloseOnSelect
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  variant="outlined"
                  label="Leave Type"
                  style={{
                    width: "100%",
                    margin: "0",
                    padding: "0",
                    margin: "auto",
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              type="submit"
              style={{ marginTop: "2.2rem" }}
              disabled={
                leaveReason === "" || dates?.length === 0 || phlebo === ""
              }
            >
              Apply Leave
            </Button>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              style={{ marginTop: "2.2rem" }}
              startIcon={<RestartAltIcon />}
              onClick={() => {
                setLeaveReason("");
                setDates([]);
                setPhlebo("");
                setKey(!key);
                // history.push("/dashboard/pha/holiday")
              }}
            >
              Reset Leave
            </Button>
          </Grid>
        </Grid>
      </form>
      <Grid
        container
        spacing={3}
        direction="row"
        style={{ alignItems: "left", marginTop: "20px" }}
      >
        <Grid item xs={12} sm={4} md={2}>
          <TextField
            className="input"
            name="start_date"
            type="date"
            label="Start Date"
            value={start_date}
            id="noTyping"
            max={`${new Date().getFullYear()}-${
              new Date().getMonth() + 1 < 10
                ? `0${new Date().getMonth() + 1}`
                : new Date().getMonth() + 1
            }-${
              new Date().getDate() < 10
                ? `0${new Date().getDate()}`
                : new Date().getDate()
            }`}
            variant="outlined"
            onChange={(e) => setStart_date(e.target.value)}
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <TextField
            name="end_date"
            type="date"
            value={end_date}
            className="input"
            label="End Date"
            id="noTyping"
            minDate={new Date().setDate(new Date().getDate() + 8)}
            max={`${new Date().getFullYear()}-${
              new Date().getMonth() + 1 < 10
                ? `0${new Date().getMonth() + 1}`
                : new Date().getMonth() + 1
            }-${
              new Date().getDate() < 10
                ? `0${new Date().getDate()}`
                : new Date().getDate()
            }`}
            disabled={start_date === ""}
            variant="outlined"
            onChange={(e) => setEnd_date(e.target.value)}
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Autocomplete
            id="phlebo"
            key={key1}
            onChange={(event, newValue) => {
              if (newValue) {
                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                setPhlebo(obj?.phlebo_data?.phlebo);
              }
            }}
            freeSolo
            blurOnSelect
            aria-required
            limitTags={1}
            options={
              phleboUnderRm?.results
                ? [
                    ...phleboUnderRm?.results,
                    { id: 0, phlebo_data: { phlebo: "NoPhlebo" } },
                  ]
                : []
            }
            disableClearable
            disableCloseOnSelect
            getOptionLabel={(option) =>
              option.phlebo_data && option?.phlebo_data?.phlebo
            }
            onInputChange={(event, newInputValue) => {
              clearTimeout(timer.current);
              timer.current = setTimeout(() => {
                getPhleboListUnderRouteManager(`?name=${newInputValue}`);
              }, 1000);
              if (newInputValue.length === 0) {
                setPhlebo("");
              }
            }}
            renderInput={(params) => (
              <TextField
                className="input"
                {...params}
                variant="outlined"
                placeholder="Search Phlebo"
                style={{
                  width: "100%",
                  margin: "0",
                  padding: "0",
                  margin: "auto",
                }}
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Autocomplete
            id="zonename"
            key={key1}
            onChange={(event, newValue) => {
              if (newValue) {
                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                setZoneName(obj?.city?.display_name);
              }
            }}
            options={zoneList?.results || []}
            freeSolo
            blurOnSelect
            aria-required
            getOptionLabel={(option) => option?.city?.name}
            onInputChange={(event, newInputValue) => {
              clearTimeout(timer.current);
              timer.current = setTimeout(() => {
                getZone1(`city_name=${newInputValue}`);
              }, 1000);
              if (newInputValue.length === 0) {
                setZoneName(0);
              }
            }}
            renderInput={(params) => (
              <TextField
                className="input"
                {...params}
                label="Zone/City"
                variant="outlined"
                style={{ width: "100%", margin: "0", padding: "0" }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            type="submit"
            onClick={handleFilter}
            disabled={start_date === "" || end_date === ""}
          >
            Filter Leave
          </Button>
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            startIcon={<RestartAltIcon />}
            onClick={() => {
              setEnd_date("");
              setStart_date("");
              setZoneName("");
              setPhlebo("");
              resetTableData();
              setKey1(!key1);
            }}
          >
            Reset Leave
          </Button>
        </Grid>
      </Grid>
      <TableContainer
        className={classes.tableContainer}
        component={Paper}
        style={{ maxHeight: "580px", marginTop: "2rem" }}
      >
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <StyledTableRow>
              <StyledTableCell align="center">Phlebo Name</StyledTableCell>
              <StyledTableCell align="center">Phlebo Log</StyledTableCell>

              {dayListData &&
                dayListData?.length > 0 &&
                dayListData?.map((data, index) => {
                  return (
                    <StyledTableCell align="center" key={index}>
                      {data}
                    </StyledTableCell>
                  );
                })}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {phleboLeaveList &&
              phleboLeaveList?.results &&
              phleboLeaveList?.results?.length > 0 &&
              phleboLeaveList?.results?.map((pData, index) => {
                return (
                  <StyledTableRow key={index}>
                    <StyledTableCell align="center">
                      {pData?.phlebo_data?.phlebo}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <div>
                        <MessageRounded
                          onClick={() => handleClick(pData?.id)}
                        />
                      </div>
                    </StyledTableCell>
                    {dayListData &&
                      dayListData.length > 0 &&
                      dayListData.map((data, index1) => {
                        const leave_dates = pData?.leave_data.map(
                          (da, index3) => {
                            return da?.leave_type !== "PR" ? da.date : "";
                          }
                        );
                        return (
                          <StyledTableCell align="center" key={index1}>
                            <Chip
                              disabled={!leave_dates.includes(data)}
                              onClick={() =>
                                handleUpdateLeave(
                                  pData,
                                  data,
                                  pData?.phlebo_data?.phlebo
                                )
                              }
                              label={leave_dates.includes(data) ? "A" : "P"}
                              color={
                                leave_dates.includes(data) ? "error" : "success"
                              }
                            />
                          </StyledTableCell>
                        );
                      })}
                  </StyledTableRow>
                );
              })}
          </TableBody>
          <TableFooter>
            <StyledTableRow>
              <TablePagination
                colSpan={12}
                count={phleboLeaveList?.count || 0}
                rowsPerPageOptions={[]}
                rowsPerPage={phleboLeaveList?.page_size || 5}
                page={page}
                onPageChange={handleChangePage}
              />
            </StyledTableRow>
          </TableFooter>
        </Table>
      </TableContainer>

      <Dialog
        onClose={handleUpdateHoliday}
        aria-labelledby="customized-dialog-title"
        open={updateHoliday}
      >
        <DialogTitle id="customized-dialog-title">Update Holiday</DialogTitle>
        <DialogContent dividers>
          <form onSubmit={updatePhleboLeaveType}>
            <Grid
              container
              spacing={1}
              direction="row"
              style={{ alignItems: "left" }}
            >
              <Grid item xs={6} sm={6} md={6}>
                <TextField
                  className="input"
                  name="receipt"
                  type="text"
                  label="Phlebo Name"
                  value={updatePhleboName}
                  disabled
                  variant="outlined"
                  // onChange={(e) => setReceiptNumber(e.target.value)}
                  style={{ width: "100%", height: "30px" }}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
                <Autocomplete
                  id="holiday"
                  onChange={(event, newValue) => {
                    if (newValue) {
                      let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                      setUpdateLeaveId(obj?.id);
                      setUpdateSelectedLeaveData(obj?.date);
                    }
                  }}
                  options={updateLeaveData || []}
                  freeSolo
                  blurOnSelect
                  aria-required
                  required
                  limitTags={1}
                  getOptionLabel={(option) => option?.date}
                  disableClearable
                  disableCloseOnSelect
                  renderInput={(params) => (
                    <TextField
                      className="input"
                      {...params}
                      variant="outlined"
                      label="Leave Date"
                      style={{ width: "100%", margin: "0", padding: "0" }}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={1}
              direction="row"
              style={{ alignItems: "left", marginTop: "1rem" }}
            >
              <Grid item xs={6} sm={6} md={6}>
                <Autocomplete
                  id="holiday"
                  onChange={(event, newValue) => {
                    if (newValue) {
                      let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                      setUpdatePhleboLeaveCat(obj?.value);
                    }
                  }}
                  options={[
                    {
                      text: "Compensatory Off",
                      value: "CO",
                    },
                    {
                      text: "Present",
                      value: "PR",
                    },
                    {
                      text: "Week Off",
                      value: "WO",
                    },
                    {
                      text: "Sick Leave",
                      value: "SL",
                    },
                    {
                      text: "Casual Leave",
                      value: "CL",
                    },
                    {
                      text: "Anual Leave",
                      value: "AL",
                    },
                  ]}
                  freeSolo
                  blurOnSelect
                  aria-required
                  limitTags={1}
                  required
                  getOptionLabel={(option) => option.text}
                  disableClearable
                  disableCloseOnSelect
                  renderInput={(params) => (
                    <TextField
                      className="input"
                      {...params}
                      variant="outlined"
                      label="Leave Type"
                      style={{ width: "100%", margin: "0", padding: "0" }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  type="submit"
                  disabled={
                    updatePhleboLeaveCat === "" ||
                    updateSelectedLeaveData === ""
                  }
                >
                  Update Leave
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
      <PhleboLeaveLog setOpenLog={setOpenLog} openLog={openLog} phleboLeaveLog={phleboLeaveLog}/>
    </main>
  );
};

const mapStateToProps = (state) => ({
  phleboList: state.PhleboAdminReducer.phleboList,
  phleboLeaveList: state.PhleboAdminReducer.phleboLeaveList,
  phleboUnderRm: state.PhleboAdminReducer.phleboUnderRm,
  zoneList: state.PhleboAdminReducer.zoneList,
  phleboLeaveLog: state.PhleboAdminReducer.phleboLeaveLog,
});

export default connect(mapStateToProps, {
  getPhleboLeaveList,
  createLeaveList,
  getPhleboListUnderRouteManager,
  updatePhleboLeave,
  resetTableData,
  getZone1,
  getPhleboLeaveLog
})(HolidayCalenderView);
