import React, { useState } from "react";
import { useRef } from "react";
import {
    makeStyles,
    Theme,
    createStyles,
    withStyles,
} from "@material-ui/core/styles";
import { connect } from "react-redux";

import { Paper, TextField, Grid, FormControlLabel, Chip } from "@material-ui/core";
import { useEffect } from "react";
import {
    getPhlebos,
    postPhleboRiderMap,
    updatePhleborider,
    getRiderList,
    getPhleboRider,
} from "../actions/RouteManagerAction";
import Button from '@mui/material/Button';
import { useHistory, useParams } from "react-router-dom";
import Checkbox from '@mui/material/Checkbox';
import Loader from "../../components/loader/index";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { TableFooter, TablePagination } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
// import { getLogisticStopsData } from "../../actions/LogisticsPanelAction";
import { generateStopsFilter } from "../../helpers/generateUrl";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        table: {
            minWidth: 700,
        },
        toolbar: {
            alignItems: "center",
            justifyContent: "flex-end",
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        },
        content: {
            width: "80%",
            flexGrow: 21,
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        paper: {
            marginTop: "2rem",
            padding: "0.5rem 2rem 2rem 2rem",
            width: "100%",
        },
        contentHeader: {
            width: "100%",
            padding: "0px 10px 40px 10px",
            minHeight: "64px",
        },
        leftContentHeader: {
            width: "50%",
            float: "left",
        },
        rightContentHeader: {
            width: "50%",
            float: "right",
            textAlign: "right",
            paddingRight: "20px",
        },
        buttonContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        head: {
            textAlign: "center",
            fontWeight: "normal",
            marginBottom: "2rem",
        },
        slotContainer: {
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
        },
        slot: {
            width: "150px",
            height: "100px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            margin: "0.5rem",
        },
        hoveItem: {
            cursor: "pointer",
        },
        selectedSlot: {
            width: "150px",
            height: "100px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "linear-gradient(60deg, cyan, #00dfff, cyan)",
            cursor: "pointer",
            margin: "0.5rem",
        },
        chips: {
            display: "flex",
        },
        chip: {
            margin: 2,
            background: "linear-gradient(60deg, cyan, #00cfff, cyan)",
            maxWidth: "130px",
        },
        input: {
            padding: "1px",
        },
        tableContainer: {
            marginTop: "2rem",
            position: "sticky",
            top: "200px",
        },
        tableHead: {
            background: "rgb(146, 74, 145)!important",
        },
        tableHeadCell: {
            color: "#fff",
            fontWeight: "bold",
            textAlign: "center",
        },
    })
);

const StyledTableCell: any = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: "#924A91",
            color: theme.palette.common.white,
            fontSize: "14px",
        },
        body: {
            fontSize: 14,
        },
    })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    })
)(TableRow);
interface Props {
    getCities: any;
    cities: any;
    postPhleboRiderMap: any;
    getLogisticStopsData: any,
    logisticstopsdata: any,
    phleboList: any,
    getPhlebos: any,
    getRiderList: any,
    updatePhleborider: any,
    riderList: any,
    getPhleboRider: any,
    phleboridermap: any,
    loading: any;
}
const StopsPlace: React.FC<Props> = ({
    postPhleboRiderMap,
    updatePhleborider,
    phleboList,
    getPhlebos,
    riderList,
    getRiderList,
    getPhleboRider,
    phleboridermap,
    loading,
}) => {
    const classes = useStyles();
    const history = useHistory();
    const timer = useRef<any>(0);
    const [isEdit, setIsEdit] = useState<any>(false);
    const [key, setKey] = useState<any>(true);
    const [editId, setEditId] = useState<any>("");
    const [phlebo, setPhlebo] = useState<any>("");
    const [phleboName, setPhleboName] = useState<any>("");
    const [rider, setRider] = useState<any>([]);
    const [riderDef, setRiderDef] = useState<any>([]);
    const [isactive, setIsActive] = useState<boolean>(true);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(20);
    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        if (newPage > page) {
            let url = phleboridermap.links && phleboridermap.links.next.split("?")[1];
            getPhleboRider(url ? `${url}` : "");
        } else if (newPage < page) {
            let url = phleboridermap.links && phleboridermap.links.previous.split("?")[1];
            getPhleboRider(url ? `${url}` : "");
        }
        setPage(newPage as number);
    };
    useEffect(() => {
        getPhlebos();
        getRiderList(`?usergroup=Rider`);
        getPhleboRider();

    }, []);
    const handleReset = () => { };

    const handleSave = async (e: any) => {
        e.preventDefault();
        const data = {
            phlebo,
            rider,
            // is_active: isactive,
        };
        if (isEdit) {
            await updatePhleborider(data, editId);
        } else {
            if (phlebo === "") {
                alert("Please Select Phlebo Name")
                return false;
            } else if (rider.length === 0) {
                alert("Please Select Rider")
                return false;
            } else await postPhleboRiderMap(data);
        }
        handleReset();
        history.push(`/dashboard/rm/phleboridermap`);
    };
    const handleEdit = (val: any) => {
        setIsEdit(true);
        setEditId(val.id);
        setIsActive(val?.is_active);
        setRiderDef(val?.rider[0] || []);
        setRider([val?.rider[0]?.id] || [])
        setPhlebo(val?.phlebo?.id);
        setPhleboName(val?.phlebo?.user?.username)
        setKey(!key);

    };
    return (
        <Grid container spacing={2} style={{ marginTop: 50 }}>
            <h2 style={{ fontWeight: "bold", paddingTop: "20px", paddingBottom: "10px" }}>Phlebo Rider Mapping</h2>
            <form style={{ width: "100%" }} onSubmit={handleSave}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={4} md={4}>
                        <Autocomplete
                            key={key}
                            id="phleboName"
                            onChange={(event, newValue) => {
                                if (newValue) {
                                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                    setPhlebo(obj.id)
                                }
                            }}
                            options={phleboList?.results || []}
                            freeSolo
                            blurOnSelect
                            aria-required
                            defaultValue={{ user: { username: phleboName } }}
                            getOptionLabel={(option: any) => option?.user?.username}
                            onInputChange={(event, newInputValue) => {
                                clearTimeout(timer.current)
                                setPhleboName(newInputValue)
                                timer.current = setTimeout(() => {
                                    getPhlebos(`?code=${newInputValue}`)
                                }, 1000)

                                if (newInputValue.length === 0) {
                                    setPhlebo("")
                                }
                            }}
                            renderInput={(params) => (
                                <TextField className="input"
                                    {...params}
                                    placeholder="Phlebo Name"
                                    variant="outlined"
                                    style={{ width: "100%", margin: "0", padding: "0" }}
                                    inputProps={{
                                        ...params.inputProps,
                                        onKeyDown: (e) => {
                                            if (e.key === 'Enter') {
                                                e.stopPropagation();
                                            }
                                        },
                                    }}
                                    required
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                        <Autocomplete
                            id="rider"
                            key={key}
                            loading={loading}
                            onChange={(event, newValue) => {
                                if (newValue) {
                                    let packs: any = [];
                                    // newValue.map((val: any) => {
                                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                    packs.push(obj.id);
                                    setRider(packs);
                                    // });
                                    // setRider(packs);
                                }
                            }}
                            disableClearable
                            options={riderList.results || []}
                            // options={
                            //     riderList.results
                            //         ? [
                            //             ...riderList.results,
                            //             { id: 0, user: { username: "NoRider" } },
                            //         ]
                            //         : []
                            // }
                            limitTags={0}
                            freeSolo
                            disableCloseOnSelect
                            aria-required
                            defaultValue={riderDef}
                            onInputChange={(event, newInputValue) => {
                                clearTimeout(timer.current);
                                timer.current = setTimeout(() => {
                                    getRiderList(`?usergroup=Rider&code=${newInputValue}`)
                                }, 1000);
                            }}
                            getOptionLabel={(option: any) =>
                                option?.user?.username ? option?.user?.username : option?.user
                            }
                            renderInput={(params) => (
                                <TextField className="input"
                                    {...params}
                                    placeholder="Assign Rider"
                                    label="Select Rider"
                                    variant="outlined"
                                    style={{ width: "100%", margin: "0", padding: "0" }}
                                    required={rider?.length === 0 ? true : false}
                                    inputProps={{
                                        ...params.inputProps,
                                        onKeyDown: (e) => {
                                            if (e.key === 'Enter') {
                                                e.stopPropagation();
                                            }
                                        },
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    {/* <Grid item xs={12} sm={3} md={2}>
                        <FormControlLabel
                            control={<Checkbox />}
                            checked={isactive}
                            label="Is Active"
                            onChange={(e) => setIsActive(!isactive)}
                        />
                    </Grid> */}
                    <Grid item xs={12} sm={6} md={2}>
                        <Button
                            color="primary"
                            variant="contained"
                            fullWidth
                            type="submit"
                            style={{ height: "100%" }}
                        >
                            {isEdit ? "update" : "Create"}
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                        <Button
                            color="secondary"
                            variant="contained"
                            fullWidth
                            onClick={() => history.push("/dashboard/rm/phleboridermap/")}
                            style={{ height: "100%" }}
                        >
                            reset
                        </Button>
                    </Grid>
                </Grid>
                <TableContainer className={classes.tableContainer} component={Paper} style={{ maxHeight: "550px" }}>
                    <Table stickyHeader aria-label="simple table">
                        <TableHead className={classes.tableHead}>
                            <StyledTableRow>
                                <StyledTableCell className={classes.tableHeadCell} >Phlebo Name</StyledTableCell>
                                <StyledTableCell className={classes.tableHeadCell} >Rider Name</StyledTableCell>
                                {/* <StyledTableCell className={classes.tableHeadCell} >Action</StyledTableCell> */}
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {phleboridermap && phleboridermap?.results && phleboridermap?.results.length > 0 && phleboridermap?.results?.map((data: any, index: any) => {
                                return (
                                    <StyledTableRow key={index}>
                                        <StyledTableCell align="center">{data?.phlebo?.user?.name}</StyledTableCell>
                                        <StyledTableCell align="center"> {data.rider &&
                                            data.rider.length > 0 &&
                                            data.rider.map((val: any, index1: any) => {
                                                return (
                                                    <Chip
                                                        label={
                                                            index1 +
                                                            1 +
                                                            ". " +
                                                            val?.user?.name +

                                                            ""
                                                        }
                                                        variant="outlined"
                                                        size="small"
                                                    />
                                                );
                                            })}</StyledTableCell>
                                        {/* <StyledTableCell align="center">
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                onClick={() => handleEdit(data)}
                                            >
                                                edit
                                            </Button>
                                        </StyledTableCell> */}
                                    </StyledTableRow>
                                )
                            })}
                        </TableBody>
                        <TableFooter>
                            <StyledTableRow>
                                <TablePagination
                                    colSpan={6}
                                    count={phleboridermap?.count || 0}
                                    rowsPerPageOptions={[]}
                                    rowsPerPage={phleboridermap?.page_size || 20}
                                    page={page}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    onPageChange={handleChangePage}
                                />
                            </StyledTableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </form>
        </Grid>
    );
};
const mapStateToProps = (state: any) => ({
    phleboList: state.RouteManagerReducer.phleboList,
    riderList: state.RouteManagerReducer.riderList,
    phleboridermap: state.RouteManagerReducer.phleboridermap,
    loading: state.RouteManagerReducer.loading,
});
export default connect(mapStateToProps, {
    getPhlebos,
    getRiderList,
    postPhleboRiderMap,
    updatePhleborider,
    getPhleboRider,

})(StopsPlace);
