import React, { useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Loader from "../loader";
import { connect } from "react-redux";
import { Button, Paper, TextField, Grid, Select, MenuItem, Slider, Chip, withStyles } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
// import { Editor, OriginalTools } from 'react-bootstrap-editor';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { createPackage, getCityPrice, getPackage, getPackageTag, getPackageCatrgory, getPackageDetails } from '../../actions/productMangerAction'
import { useEffect } from "react";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useRef } from "react";
import { useHistory } from "react-router";
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 0,
        },
        toolbar: {
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        },
        content: {
            width: "80%",
            flexGrow: 21,
        },
        paper: {
            padding: "1rem",
            width: "100%",
            borderRadius: "10px"
        },
        head: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "1rem",
            textAlign: "center"
        },
        heading: {
            fontWeight: "normal",
            fontSize: "1.2rem",
            margin: "0"
        },
        table: {
            maxHeight: "400px",
            margin: "auto",
            overflow: "auto"
        },
        image: {
            width: "100px",
            height: "auto",
        },
        imageSM: {
            width: "50px",
            height: "auto",
        },
        imgContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexWrap: "wrap"
        }
    })
);

const PrettoSlider:any = withStyles({
    root: {
        color: 'rgb(146, 74, 145)',
        height: 8,
    },
    thumb: {
        height: 24,
        width: 24,
        backgroundColor: 'violet',
        border: '2px solid purple',
        marginTop: -8,
        marginLeft: -12,
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 4px)',
    },
    track: {
        height: 8,
        borderRadius: 4,
    },
    rail: {
        height: 8,
        borderRadius: 4,
    },
})(Slider);

interface Props {
    createPackage: any;
    cityPriceList: any;
    getCityPrice: any;
    packageList: any;
    getPackage: any;
    getPackageTag: any;
    getPackageCatrgory: any;
    packageCategory: any
    packageTags: any;
    getPackageDetails: any;
    packageDetails: any;
    loading: any;
}

const CreatePackagePage: React.FC<Props> = ({
    createPackage,
    cityPriceList,
    getCityPrice,
    packageList,
    getPackage,
    getPackageTag,
    packageTags,
    getPackageCatrgory,
    packageCategory,
    getPackageDetails,
    packageDetails,
    loading,
}) => {
    const classes = useStyles();
    const history = useHistory();
    const [name, setName] = useState<string>("")
    const [description, setDescription] = useState<string>("")
    const [details, setDetails] = useState<string>("")
    const [faq, setFaq] = useState<string>("")
    const [topFaq, setTopFaq] = useState<string>("")
    const [packagePrice, setPackagePrice] = useState<number>(0)
    const [cityPrice, setCityPrice] = useState<any>([])
    const [testPackage, setTestPackage] = useState<any>([])
    const [relatedTestPackage, setRelatedTestPackage] = useState<any>([])
    const [categoryForWeb, setCategoryForWeb] = useState<any>([])
    const [discount, setDiscount] = useState<number>(0)
    const [offerPrice, setOfferPrice] = useState<number>(0)
    const [addOnPrice, setAddOnPrice] = useState<number>(0)
    const [parameter, setParameter] = useState<string>("")
    const [isAddon, setIsAddon] = useState<boolean | null>(null)
    const [bestPackageApp, setBestPackageApp] = useState<boolean | null>(null)
    const [recommendedAge, setRecommendedAge] = useState<any>([10, 20])
    const [recommendedGender, setRecommendedGender] = useState<string>("any")
    const [testCategory, settestCategory] = useState<string>("any")
    const [homeCollectionCheck, setHomeCollectionCheck] = useState<string>("any")
    const [bestPackageAppCheck, setBestPackageAppCheck] = useState<string>("any")
    const [ptype, setPtype] = useState<string>("any")
    const [isPremium, setIsPremium] = useState<string>("any")
    const [isPopular, setIsPopular] = useState<string>("any")
    const [type, setType] = useState<string>("any")
    const [metaTitle, setMetaTitle] = useState("")
    const [metaKeyword, setMetaKeyword] = useState("")
    const [metaDescription, setMetaDescription] = useState("")
    const [code, setCode] = useState("")
    const [slug, setSlug] = useState("")
    const [lpSlug, setLpSlug] = useState("")
    const [ogTag, setOgTag] = useState("")
    const [tags, setTags] = useState<any>("")
    const [tatTime, setTatTime] = useState("")
    const [twitterCard, setTwitterCard] = useState("")
    const [specimenInstructions, setSpecimenInstructions] = useState("")
    const [sample, setSample] = useState("")
    const [fastingTime, setFastingTime] = useState("")
    const [isActiveCheck, setIsActiveCheck] = useState(false);
    const [isAddonCheck, setIsAddonCheck] = useState(false);
    const [websiteDisplay, setWebsiteDisplay] = useState(false);
    const [isFamilyPackageCheck, setIsFamilyPackageCheck] = useState(false);
    const [timeDuration, setTimeDuration] = useState("hh");
    const [dd, setDD] = useState("");
    const [hh, setHH] = useState("");
    const [mm, setMM] = useState("");
    const [ss, setSS] = useState("");
    const [packageImage, setPackageImage] = useState<any>([])
    const [file, setFile] = React.useState<any>([]);
    const timer = useRef<any>(0)
    const Dday = () => {
        return <Select className="input"
            name="validOnce"
            variant="outlined"
            value={dd}
            style={{ width: "100%" }}
            onChange={(e: any) => setDD(e.target.value as string)}
        >{Array.from(Array(61), (e, i) => {
            return <MenuItem key={i} value={i}>{i}</MenuItem>
        })}</Select>
    }
    const Hhours = () => {
        return <Select className="input"
            name="validOnce"
            variant="outlined"
            value={hh}
            style={{ width: "100%" }}
            onChange={(e: any) => setHH(e.target.value as string)}
        >{Array.from(Array(25), (e, i) => {
            return <MenuItem key={i} value={i < 10 ? "0" + i : i}>{i < 10 ? "0" + i : i}</MenuItem>
        })}</Select>
    }
    const Mminute = () => {
        return <Select className="input"
            name="validOnce"
            variant="outlined"
            value={mm}
            style={{ width: "100%" }}
            onChange={(e: any) => setMM(e.target.value as string)}
        >{Array.from(Array(61), (e, i) => {
            return <MenuItem key={i} value={i < 10 ? "0" + i : i}>{i < 10 ? "0" + i : i}</MenuItem>
        })}</Select>
    }
    const Ssecond = () => {
        return <Select className="input"
            name="validOnce"
            variant="outlined"
            value={ss}
            style={{ width: "100%" }}
            onChange={(e: any) => setSS(e.target.value as string)}
        >{Array.from(Array(61), (e, i) => {
            return <MenuItem key={i} value={i < 10 ? "0" + i : i}>{i < 10 ? "0" + i : i}</MenuItem>
        })}</Select>
    }
    useEffect(() => {
        getCityPrice()
        getPackageTag()
        getPackageCatrgory()
        getPackageDetails()
    }, [])

    const submitHandler = () => {
        const formData = new FormData();
        formData.append("name", name)
        formData.append("code", code)
        formData.append("slug", slug)
        formData.append("lpslug", lpSlug)
        formData.append("recommended_gender", recommendedGender)
        formData.append("tat_time", tatTime)
        formData.append("fasting_time", fastingTime)
        formData.append("discount", discount as any)
        formData.append("description", description)
        formData.append("package_price", packagePrice as any)
        formData.append("sample", sample)
        formData.append("parameter", parameter)
        formData.append("addon_price", addOnPrice as any)
        formData.append("is_premium", isPremium)
        formData.append("tat_time_duration", dd + " " + hh + ":" + mm + ":" + ss)
        formData.append("home_collection", homeCollectionCheck)
        formData.append("best_package_app", bestPackageAppCheck)
        formData.append("recommended_age", recommendedAge.join("-"))
        formData.append("details", details)
        formData.append("meta_title", metaTitle)
        formData.append("meta_description", metaDescription)
        formData.append("is_addon", isAddon as any)
        formData.append("faq", faq)
        // formData.append("city_prices", cityPrice as any)
         if(file.length > 0){
           for (let i = 0; i < file.length; i++) {
            formData.append("package_image", file[i])
          }
       }

        createPackage(formData)
        history.push("/dashboard/pm/packages")
    }
    const fileSelectedHandler = (e: React.ChangeEvent<{ files: any }>) => {
        setFile(e.target.files)
    }
    
    return (
        <>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <Paper elevation={5} className={classes.paper}>
                    <h3 style={{ textAlign: "center", fontWeight: "bold" }}>CREATE PACKAGE</h3>
                    {loading ? <Loader /> : (
                        <Grid container spacing={3} style={{ margin: "1rem auto" }}>
                            <Grid container item xs={12} md={3} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <p>Name</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <TextField className="input"
                                        name="title"
                                        type="text"
                                        placeholder="Enter Name"
                                        value={name}
                                        variant="outlined"
                                        style={{ margin: "0", width: "100%" }}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={3} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <p>Code</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <TextField className="input"
                                        name="title"
                                        type="text"
                                        placeholder="Enter Meta Description"
                                        value={code}
                                        variant="outlined"
                                        style={{ margin: "0", width: "100%" }}
                                        onChange={(e: any) => setCode(e.target.value)}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container item xs={12} md={3} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <p>Slug</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <TextField className="input"
                                        name="title"
                                        type="text"
                                        placeholder="Enter Slug"
                                        value={slug}
                                        variant="outlined"
                                        style={{ margin: "0", width: "100%" }}
                                        onChange={(e: any) => setSlug(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={3} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <p>Lp Slug</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <TextField className="input"
                                        name="title"
                                        type="text"
                                        placeholder="Enter Lp Slug "
                                        value={lpSlug}
                                        variant="outlined"
                                        style={{ margin: "0", width: "100%" }}
                                        onChange={(e: any) => setLpSlug(e.target.value)}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container item xs={12} md={3} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <p>Parameter</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <TextField className="input"
                                        name="title"
                                        type="number"
                                        placeholder="Enter Parameter"
                                        value={parameter}
                                        variant="outlined"
                                        style={{ margin: "0", width: "100%" }}
                                        onChange={(e) => setParameter(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={3} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <p>Recommended Gender</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <Select className="input"
                                        name="validOnce"
                                        variant="outlined"
                                        value={recommendedGender}
                                        style={{ width: "100%" }}
                                        onChange={(e: any) => setRecommendedGender(e.target.value as string)}
                                    >
                                        <MenuItem value={"any"}>Recommended Gender</MenuItem>
                                        <MenuItem value={"male"}>Male</MenuItem>
                                        <MenuItem value={"female"}>Female</MenuItem>
                                    </Select>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={3} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <p>Type</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <Select className="input"
                                        name="validOnce"
                                        variant="outlined"
                                        value={type}
                                        style={{ width: "100%" }}
                                        onChange={(e: any) => setType(e.target.value as string)}
                                    >
                                        <MenuItem value={"any"}>Type</MenuItem>
                                        <MenuItem value={"covid"}>Covid</MenuItem>
                                        <MenuItem value={"blood"}>Blood</MenuItem>
                                        <MenuItem value={"others"}>Others</MenuItem>
                                    </Select>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={3} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <p>Tat Time</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <TextField className="input"
                                        name="title"
                                        type="text"
                                        placeholder="Enter TaT Time"
                                        value={tatTime}
                                        variant="outlined"
                                        style={{ margin: "0", width: "100%" }}
                                        onChange={(e: any) => setTatTime(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={3} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <p>Fasting Time</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <TextField className="input"
                                        name="title"
                                        type="text"
                                        placeholder="Enter Fasting Time"
                                        value={fastingTime}
                                        variant="outlined"
                                        style={{ margin: "0", width: "100%" }}
                                        onChange={(e: any) => setFastingTime(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={3} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <p>Package Price</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <TextField className="input"
                                        name="title"
                                        type="number"
                                        placeholder="Enter Package Price"
                                        value={packagePrice}
                                        variant="outlined"
                                        style={{ margin: "0", width: "100%" }}
                                        onChange={(e: any) => setPackagePrice(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={3} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <p>Offer Price</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <TextField className="input"
                                        name="title"
                                        type="number"
                                        placeholder="Enter Offer Price"
                                        // value={Math.floor(packagePrice - ((packagePrice * discount) / 100))}
                                        value={offerPrice}
                                        variant="outlined"
                                        style={{ margin: "0", width: "100%" }}
                                        onChange={(e: any) => setOfferPrice(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={3} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <p>Discount</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <TextField className="input"
                                        name="title"
                                        type="number"
                                        placeholder="Enter Discount"
                                        value={discount}
                                        variant="outlined"
                                        style={{ margin: "0", width: "100%" }}
                                        onChange={(e: any) => setDiscount(e.target.value)}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container item xs={12} md={3} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <p>AddOn Price</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <TextField className="input"
                                        name="title"
                                        type="number"
                                        placeholder="Enter Addo Price"
                                        value={addOnPrice}
                                        variant="outlined"
                                        style={{ margin: "0", width: "100%" }}
                                        onChange={(e: any) => setAddOnPrice(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={3} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <p>Is premium</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <Select className="input"
                                        name="validOnce"
                                        variant="outlined"
                                        value={isPremium}
                                        style={{ width: "100%" }}
                                        onChange={(e: any) => setIsPremium(e.target.value as string)}
                                    >
                                        <MenuItem value={"any"}>Is premium</MenuItem>
                                        <MenuItem value={"yes"}>Yes</MenuItem>
                                        <MenuItem value={"no"}>No</MenuItem>
                                    </Select>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={3} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <p>Is popular</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <Select className="input"
                                        name="validOnce"
                                        variant="outlined"
                                        value={isPopular}
                                        style={{ width: "100%" }}
                                        onChange={(e: any) => setIsPopular(e.target.value as string)}
                                    >
                                        <MenuItem value={"any"}>Is popular</MenuItem>
                                        <MenuItem value={"yes"}>Yes</MenuItem>
                                        <MenuItem value={"no"}>No</MenuItem>
                                    </Select>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={3} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <p>Package Image</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <input className="input" style={{ width: "100%", margin: "0", padding: "18.5px 14px", border: "1px solid rgba(0, 0, 0, 0.23)", borderRadius: "5px" }} type="file" name="file" id="file" multiple onChange={fileSelectedHandler} />
                                </Grid>
                            </Grid>
                            {/* <Grid container item xs={12} md={3} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <p>Test category</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <Select className="input"
                                        name="validOnce"
                                        variant="outlined"
                                        value={testCategory}
                                        style={{ width: "100%" }}
                                        onChange={(e: any) => settestCategory(e.target.value as string)}
                                    >
                                        <MenuItem value={"any"}>Test category</MenuItem>
                                        <MenuItem value={"rcl75"}>RCL75</MenuItem>
                                        <MenuItem value={"rcl70"}>RCL70</MenuItem>
                                        <MenuItem value={"100amount"}>100 Amount</MenuItem>
                                        <MenuItem value={"lb40"}>LB 40</MenuItem>
                                    </Select>
                                </Grid>
                            </Grid> */}
                            <Grid container item xs={12} md={3} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <p>Home Collection</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <Select className="input"
                                        name="validOnce"
                                        variant="outlined"
                                        value={homeCollectionCheck}
                                        style={{ width: "100%" }}
                                        onChange={(e: any) => setHomeCollectionCheck(e.target.value as string)}
                                    >
                                        <MenuItem value={"any"}>Home Collection</MenuItem>
                                        <MenuItem value={"yes"}>YES</MenuItem>
                                        <MenuItem value={"no"}>NO</MenuItem>
                                    </Select>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={3} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <p>Best package app</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <Select className="input"
                                        name="validOnce"
                                        variant="outlined"
                                        value={bestPackageAppCheck}
                                        style={{ width: "100%" }}
                                        onChange={(e: any) => setBestPackageAppCheck(e.target.value as string)}
                                    >
                                        <MenuItem value={"any"}>Best package app</MenuItem>
                                        <MenuItem value={"yes"}>YES</MenuItem>
                                        <MenuItem value={"no"}>NO</MenuItem>
                                    </Select>
                                </Grid>
                            </Grid>

                            <Grid container item xs={12} md={3} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <p>Time Duration</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <Grid container spacing={3} style={{ margin: "1rem auto" }}>
                                        <Grid item xs={3} style={{ padding: "0" }}>
                                            <p>Day</p>
                                            <Dday />
                                        </Grid>

                                        <Grid item xs={3} style={{ padding: "0" }}>
                                            <p>HH</p>
                                            <Hhours />
                                        </Grid>
                                        <Grid item xs={3} style={{ padding: "0" }}>
                                            <p>MM</p>
                                            <Mminute />
                                        </Grid>
                                        <Grid item xs={3} style={{ padding: "0" }}>
                                            <p>SS</p>
                                            <Ssecond />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={3} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <p>Sample</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <TextField className="input"
                                        name="title"
                                        type="text"
                                        placeholder="Enter Sample"
                                        value={sample}
                                        variant="outlined"
                                        style={{ margin: "0", width: "100%" }}
                                        onChange={(e: any) => setSample(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={3} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <p>Category for web</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <Autocomplete
                                        id="category_for_web"
                                        onChange={(event, newValue) => {
                                            let sourceIds: any = []
                                            if (newValue) {
                                                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                                sourceIds = obj.map((item: any) => item.id)
                                            }
                                            setCategoryForWeb(sourceIds)
                                        }}
                                        freeSolo
                                        blurOnSelect
                                        aria-required
                                        limitTags={1}
                                        multiple
                                        options={packageCategory.results}
                                        getOptionDisabled={(option: any) => {
                                            return categoryForWeb.includes(option.id)
                                        }}
                                        disableClearable
                                        disableCloseOnSelect
                                        getOptionLabel={(option: any) => option?.name}
                                        onInputChange={(event, newInputValue) => {
                                            clearTimeout(timer.current)
                                            timer.current = setTimeout(() => {
                                                getPackageCatrgory(newInputValue)
                                            }, 1000)
                                            if (newInputValue.length === 0) {
                                                setCategoryForWeb([])
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextField className="input"
                                                {...params}
                                                placeholder="Category For Web"
                                                variant="outlined"
                                                style={{ width: "100%", margin: "0", padding: "0" }}
                                                required
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={3} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <p>Package</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <Autocomplete
                                        id="package"
                                        onChange={(event, newValue) => {
                                            let sourceIds: any = []
                                            if (newValue) {
                                                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                                sourceIds = obj.map((item: any) => item.id)
                                            }
                                            setTestPackage(sourceIds)
                                        }}
                                        freeSolo
                                        blurOnSelect
                                        aria-required
                                        limitTags={1}
                                        multiple
                                        options={packageDetails.results}
                                        getOptionDisabled={(option: any) => {
                                            return testPackage.includes(option.id)
                                        }}
                                        disableClearable
                                        disableCloseOnSelect
                                        getOptionLabel={(option: any) => option?.name}
                                        onInputChange={(event, newInputValue) => {
                                            clearTimeout(timer.current)
                                            timer.current = setTimeout(() => {
                                                getPackageDetails(newInputValue)
                                            }, 1000)
                                            if (newInputValue.length === 0) {
                                                setTestPackage([])
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextField className="input"
                                                {...params}
                                                placeholder="Package Details"
                                                variant="outlined"
                                                style={{ width: "100%", margin: "0", padding: "0" }}
                                                required
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={3} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <p>Related Package</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <Autocomplete
                                        id="related_package"
                                        onChange={(event, newValue) => {
                                            let sourceIds: any = []
                                            if (newValue) {
                                                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                                sourceIds = obj.map((item: any) => item.id)
                                            }
                                            setRelatedTestPackage(sourceIds)
                                        }}
                                        freeSolo
                                        blurOnSelect
                                        aria-required
                                        limitTags={1}
                                        multiple
                                        options={packageDetails.results}
                                        getOptionDisabled={(option: any) => {
                                            return relatedTestPackage.includes(option.id)
                                        }}
                                        disableClearable
                                        disableCloseOnSelect
                                        getOptionLabel={(option: any) => option?.name}
                                        onInputChange={(event, newInputValue) => {
                                            clearTimeout(timer.current)
                                            timer.current = setTimeout(() => {
                                                getPackageDetails(newInputValue)
                                            }, 1000)
                                            if (newInputValue.length === 0) {
                                                setRelatedTestPackage([])
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextField className="input"
                                                {...params}
                                                placeholder="Package Details"
                                                variant="outlined"
                                                style={{ width: "100%", margin: "0", padding: "0" }}
                                                required
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={3} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <p>Tags</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <Autocomplete
                                        id="tags"
                                        onChange={(event, newValue) => {
                                            let sourceIds: any = []
                                            if (newValue) {
                                                let tagId: any = []
                                                newValue.map((val: any) => {
                                                    let obj = JSON.parse(JSON.stringify(val, null, " "));
                                                    tagId.push(obj.id)
                                                })
                                                setTags(tagId)
                                            }
                                        }}
                                        onInputChange={(event, newInputValue) => {
                                            clearTimeout(timer.current)
                                            timer.current = setTimeout(() => {
                                                getPackageTag(newInputValue)
                                            }, 1000)
                                        }}
                                        multiple
                                        disableClearable
                                        limitTags={2}
                                        options={packageTags.results}
                                        freeSolo
                                        blurOnSelect
                                        getOptionLabel={(option: any) => option.tags}
                                        renderTags={(value: string[], getTagProps) =>
                                            value.map((option: any, index: number) => (
                                                <Chip
                                                    key={index}
                                                    variant="default"
                                                    color="primary"
                                                    label={option.tags}
                                                    {...getTagProps({ index })}
                                                />
                                            ))
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                placeholder="Tags"
                                                variant="outlined"
                                                style={{ width: "100%", margin: "0", padding: "0" }}
                                                className="input"
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={3} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <p>PType</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <Select className="input"
                                        name="validOnce"
                                        variant="outlined"
                                        value={ptype}
                                        style={{ width: "100%" }}
                                        onChange={(e: any) => setPtype(e.target.value as string)}
                                    >
                                        <MenuItem value={"any"}>Ptype</MenuItem>
                                        <MenuItem value={"sales"}>Sales</MenuItem>
                                        <MenuItem value={"coorporate"}>Coorporate</MenuItem>
                                        <MenuItem value={"all"}>All</MenuItem>
                                    </Select>
                                </Grid>
                            </Grid>

                            <Grid container item xs={12} md={3} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <p>Best Package App</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <Select className="input"
                                        name="validOnce"
                                        variant="outlined"
                                        value={bestPackageApp}
                                        style={{ width: "100%" }}
                                        onChange={(e: any) => setBestPackageApp(e.target.value as boolean)}
                                    >
                                        <MenuItem disabled value=''>Best Package App</MenuItem>
                                        <MenuItem value={"true"}>True</MenuItem>
                                        <MenuItem value={"false"}>False</MenuItem>
                                    </Select>
                                </Grid>
                            </Grid>
                            <Grid container spacing={3} style={{ margin: "1rem auto" }}>
                                <Grid container item xs={12} md={6} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>

                                    <FormControlLabel
                                        control={<Checkbox checked={isActiveCheck} onChange={(e: any) => setIsActiveCheck(e.target.checked)} name="antoine" />}
                                        label="Is active"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox checked={isAddonCheck} onChange={(e: any) => setIsAddonCheck(e.target.checked)} name="antoine" />}
                                        label="Is addon"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox checked={websiteDisplay} onChange={(e: any) => setWebsiteDisplay(e.target.checked)} name="antoine" />}
                                        label="Website Display"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox checked={isFamilyPackageCheck} onChange={(e: any) => setIsFamilyPackageCheck(e.target.checked)} name="antoine" />}
                                        label="Is family package"
                                    />
                                </Grid>
                                <Grid container item xs={6} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                    <Grid item xs={12} style={{ padding: "0" }}>
                                        <p>Recommended Age</p>
                                    </Grid>
                                    <Grid item xs={12} style={{ padding: "0" }}>
                                        <PrettoSlider
                                            value={recommendedAge}
                                            onChange={(event: any, newValue: number | number[]) => setRecommendedAge(newValue)}
                                            valueLabelDisplay="auto"
                                            aria-labelledby="range-slider"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container item xs={6} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={6} style={{ padding: "0" }}>
                                    <p>Meta Title</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <TextareaAutosize
                                        maxRows={3}
                                        style={{ height: "100px", width: "100%" }}
                                        aria-label="maximum height"
                                        placeholder="Meta Title"
                                        value={metaTitle}
                                        onChange={(e: any) => setMetaTitle(e.target.value as string)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={6} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={6} style={{ padding: "0" }}>
                                    <p>Meta Keyword</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <TextareaAutosize
                                        maxRows={3}
                                        style={{ height: "100px", width: "100%" }}
                                        aria-label="maximum height"
                                        placeholder="Meta Keyword"
                                        value={metaKeyword}
                                        onChange={(e: any) => setMetaKeyword(e.target.value as string)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={6} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={6} style={{ padding: "0" }}>
                                    <p>Meta Description</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <TextareaAutosize
                                        maxRows={3}
                                        style={{ height: "100px", width: "100%" }}
                                        aria-label="maximum height"
                                        placeholder="Meta Description"
                                        value={metaDescription}
                                        onChange={(e: any) => setMetaDescription(e.target.value as string)}
                                    />
                                </Grid>
                            </Grid>


                            <Grid container item xs={6} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={6} style={{ padding: "0" }}>
                                    <p>Og Tag</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <TextareaAutosize
                                        maxRows={3}
                                        style={{ height: "100px", width: "100%" }}
                                        aria-label="maximum height"
                                        placeholder="Og Tag"
                                        value={ogTag}
                                        onChange={(e: any) => setOgTag(e.target.value as string)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={6} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={6} style={{ padding: "0" }}>
                                    <p>Twitter Card</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <TextareaAutosize
                                        maxRows={3}
                                        style={{ height: "100px", width: "100%" }}
                                        aria-label="maximum height"
                                        placeholder="Twitter Card"
                                        value={twitterCard}
                                        onChange={(e: any) => setTwitterCard(e.target.value as string)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <p>Description</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    {/* <Editor
                                        tools={OriginalTools}
                                        value={` ${description} `}
                                        onChange={(e: any) => setDescription(e)}
                                    /> */}
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <p>Details</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    {/* <Editor
                                        tools={OriginalTools}
                                        value={` ${details} `}
                                        onChange={(e: any) => setDetails(e)}
                                    /> */}
                                </Grid>
                            </Grid>

                            <Grid container item xs={12} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <p>FAQ</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    {/* <Editor
                                        tools={OriginalTools}
                                        value={` ${faq} `}
                                        onChange={(e: any) => setFaq(e)}
                                    /> */}
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <p>Specimen Instructions</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    {/* <Editor
                                        tools={OriginalTools}
                                        value={` ${specimenInstructions} `}
                                        onChange={(e: any) => setSpecimenInstructions(e)}
                                    /> */}
                                </Grid>
                            </Grid>

                            <Grid container item xs={12} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <p>Top FAQ</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    {/* <Editor
                                        tools={OriginalTools}
                                        value={` ${topFaq} `}
                                        onChange={(e: any) => setTopFaq(e)}
                                    /> */}
                                </Grid>
                            </Grid>



                            <Grid container item xs={12} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <p>Details</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    {/* <Editor
                                        tools={OriginalTools}
                                        value={` ${details} `}
                                        onChange={(e: any) => setDetails(e)}
                                    /> */}
                                </Grid>
                            </Grid>

                            <Grid container item xs={12} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <p>FAQ</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    {/* <Editor
                                        tools={OriginalTools}
                                        value={` ${faq} `}
                                        onChange={(e: any) => setFaq(e)}
                                    /> */}
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <p>Specimen Instructions</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    {/* <Editor
                                        tools={OriginalTools}
                                        value={` ${specimenInstructions} `}
                                        onChange={(e: any) => setSpecimenInstructions(e)}
                                    /> */}
                                </Grid>
                            </Grid>

                            <Grid container item xs={12} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <p>Top FAQ</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    {/* <Editor
                                        tools={OriginalTools}
                                        value={` ${topFaq} `}
                                        onChange={(e: any) => setTopFaq(e)}
                                    /> */}
                                </Grid>
                            </Grid>



                            <Grid container item xs={12} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <Button
                                        color="secondary"
                                        variant="contained"
                                        fullWidth
                                        onClick={submitHandler}
                                    >
                                        Create Package
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </Paper>
            </main>
        </>
    );
};


const mapStateToProps = (state: any) => ({
    cityPriceList: state.ProductManagerReducer.cityPriceList,
    packageList: state.ProductManagerReducer.packageList,
    packageCategory: state.ProductManagerReducer.packageCategory,
    packageTags: state.ProductManagerReducer.packageTags,
    packageDetails: state.ProductManagerReducer.packageDetails,
    loading: state.ProductManagerReducer.loading,
})

export default connect(mapStateToProps, {
    createPackage,
    getCityPrice,
    getPackage,
    getPackageTag,
    getPackageCatrgory,
    getPackageDetails,
})(CreatePackagePage);