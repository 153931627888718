import React from "react";
import LogisticsPanelDashboardLeft from "../../Logistics-panel/dashboard-left/index";
import LogisticsPanelDashboardRight from "../../Logistics-panel/dashboard-right/index";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    container: {
        display: 'flex',
        padding: "20px"
    }
});

interface Props {
    children: any;
}

const SampleAccessioningDash: React.FC<Props> = ({ children }) => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <LogisticsPanelDashboardLeft />
            <LogisticsPanelDashboardRight>{children && children}</LogisticsPanelDashboardRight>
        </div>
    );
};

export default SampleAccessioningDash;

