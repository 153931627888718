import React, { useState, useEffect } from "react";
import { Chip, Grid, Typography, TextField } from "@material-ui/core";
import { connect } from "react-redux";
import Divider from "@material-ui/core/Divider";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Card from "@mui/material/Card";

import BarChartTwoToneIcon from "@mui/icons-material/BarChartTwoTone";
import { getBookingOverview } from "../../actions/PhleboAdmin";
import ContactPhoneOutlinedIcon from "@material-ui/icons/ContactPhoneOutlined";
import DirectionsBikeTwoToneIcon from "@material-ui/icons/DirectionsBikeTwoTone";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import Skeleton from '@mui/material/Skeleton';
import Avatar from "@mui/material/Avatar";
import moment from "moment";
import "./dashboard.css";
const useStyles = makeStyles((theme: Theme) =>
createStyles({
  card: {
    height: "100%",
    padding: "20px",
  },
  cardContent: {
    textAlign: "center",
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  text: { color: "black" },
})
);


interface Props {
  getBookingOverview: any;
  bookingoverview: any;
  userDetails: any;
  loading: any
}

const Dashboard: React.FC<Props> = ({
  getBookingOverview,
  bookingoverview,

  loading,
}) => {
  const classes = useStyles();


  useEffect(() => {
    getBookingOverview("");
  }, []);
  const assignedPhNum =
    bookingoverview?.phlebo_assigned + bookingoverview?.phlebo_unassigned;

  return (
    <div style={{ width: "90%", margin: "60px auto" }}>
      <Grid
          container
          spacing={1}
          direction="row"
          style={{ alignItems: "right", display: "flex" }}
        >
         <Grid item xs={12} sm={8}>
          <h4 style={{ fontWeight: "bold" }}>DASHBOARD</h4>
        </Grid>
        <Grid item xs={12} sm={4} style={{ textAlign: "right" }}>
          <TextField
            type="date"
            defaultValue={moment().format("YYYY-MM-DD")}
            onChange={(e) =>
              getBookingOverview(
                `overview_date=${moment(e.target.value).format("YYYY-MM-DD")}`
              )
            }
          />
        </Grid>
      </Grid>
      <div style={{ margin: "10px 0px 0px 0px" }}>
        <h6>PICKUP</h6>
        <div className="cardsAppointment">
          <div>
            <Grid container>
              <Grid item sm={3}>
                <Avatar
                  sx={{ bgcolor: "#ffeff5", marginTop: "8px" }}
                  aria-label="recipe"
                >
                  <BarChartTwoToneIcon style={{ color: "#fa4d83" }} />
                </Avatar>
              </Grid>
              <Grid item sm={9}>
                <h5 style={{ color: "#ffffff" }}>{bookingoverview?.total_pickup}</h5>
                <span style={{ color: "#ffffff" }}>Total</span>
              </Grid>
            </Grid>
          </div>
          <div style={{ marginLeft: "6%" }}>
            <Grid container>
              <Grid item sm={3}>
                <Avatar
                  sx={{ bgcolor: "#ffeff5", marginTop: "8px" }}
                  aria-label="recipe"
                >
                  <BarChartTwoToneIcon style={{ color: "#fa4d83" }} />
                </Avatar>
              </Grid>
              <Grid item sm={9}>
                <h5 style={{ color: "#ffffff" }}>{bookingoverview?.pickup_done}</h5>
                <span style={{ color: "#ffffff" }}>Confirmed</span>
              </Grid>
            </Grid>
          </div>
          <div style={{ marginLeft: "6%" }}>
            <Grid container>
              <Grid item sm={3}>
                <Avatar
                  sx={{ bgcolor: "#ffeff5", marginTop: "8px" }}
                  aria-label="recipe"
                >
                  <BarChartTwoToneIcon style={{ color: "#fa4d83" }} />
                </Avatar>
              </Grid>
              <Grid item sm={9}>
                <h5 style={{ color: "#ffffff" }}>{bookingoverview?.pickup_pending}</h5>
                <span style={{ color: "#ffffff" }}>Pending</span>
              </Grid>
            </Grid>
          </div>
          <div style={{ marginLeft: "6%" }}>
            <Grid container>
              <Grid item sm={3}>
                <Avatar
                  sx={{ bgcolor: "#ffeff5", marginTop: "8px" }}
                  aria-label="recipe"
                >
                  <BarChartTwoToneIcon style={{ color: "#fa4d83" }} />
                </Avatar>
              </Grid>
              <Grid item sm={9}>
                <h5 style={{ color: "#ffffff" }}>{bookingoverview?.pickup_hold}</h5>
                <span style={{ color: "#ffffff" }}>Hold</span>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="cardsAppointment">
          <div>
            <Grid container>
              <Grid item sm={3}>
                <Avatar
                  sx={{ bgcolor: "#ffeff5", marginTop: "8px" }}
                  aria-label="recipe"
                >
                  <BarChartTwoToneIcon style={{ color: "#fa4d83" }} />
                </Avatar>
              </Grid>
              <Grid item sm={9}>
                <h5 style={{ color: "#ffffff" }}>{bookingoverview?.pickup_cancelled}</h5>
                <span style={{ color: "#ffffff" }}>Cancelled</span>
              </Grid>
            </Grid>
          </div>
          <div style={{ marginLeft: "6%" }}>
            <Grid container>
              <Grid item sm={3}>
                <Avatar
                  sx={{ bgcolor: "#ffeff5", marginTop: "8px" }}
                  aria-label="recipe"
                >
                  <BarChartTwoToneIcon style={{ color: "#fa4d83" }} />
                </Avatar>
              </Grid>
              <Grid item sm={9}>
                <h5 style={{ color: "#ffffff" }}>{bookingoverview?.pickup_reschedule}</h5>
                <span style={{ color: "#ffffff" }}>Rescheduled</span>
              </Grid>
            </Grid>
          </div>
        </div>
      
      <div style={{ margin: "10px 0px 0px 0px" }}>
        <h6>PHLEBO</h6>
        <div className="cardsAppointment">
          <div>
            <Grid container>
              <Grid item sm={3}>
                <Avatar
                  sx={{ bgcolor: "#ffeff5", marginTop: "8px" }}
                  aria-label="recipe"
                >
                  <DirectionsBikeTwoToneIcon style={{ color: "#fa4d83" }} />
                </Avatar>
              </Grid>
              <Grid item sm={9}>
                <h5 style={{ color: "#ffffff" }}>{assignedPhNum}</h5>
                <span style={{ color: "#ffffff" }}>Total</span>
              </Grid>
            </Grid>
          </div>
          <div style={{ marginLeft: "6%" }}>
            <Grid container>
              <Grid item sm={3}>
                <Avatar
                  sx={{ bgcolor: "#ffeff5", marginTop: "8px" }}
                  aria-label="recipe"
                >
                  <DirectionsBikeTwoToneIcon style={{ color: "#fa4d83" }} />
                </Avatar>
              </Grid>
              <Grid item sm={9}>
                <h5 style={{ color: "#ffffff" }}>{bookingoverview?.phlebo_assigned}</h5>
                <span style={{ color: "#ffffff" }}>Assigned</span>
              </Grid>
            </Grid>
          </div>
          <div style={{ marginLeft: "6%" }}>
            <Grid container>
              <Grid item sm={3}>
                <Avatar
                  sx={{ bgcolor: "#ffeff5", marginTop: "8px" }}
                  aria-label="recipe"
                >
                  <DirectionsBikeTwoToneIcon style={{ color: "#fa4d83" }} />
                </Avatar>
              </Grid>
              <Grid item sm={9}>
                <h5 style={{ color: "#ffffff" }}>{bookingoverview?.phlebo_unassigned}</h5>
                <span style={{ color: "#ffffff" }}>Unassigned</span>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  bookingoverview: state.PhleboAdminReducer.bookingoverview,
  loading: state.PhleboAdminReducer.loading,
});

export default connect(mapStateToProps, {
  getBookingOverview,
})(Dashboard);
