import React, { useState } from "react";
import { useRef } from "react";
import {
    makeStyles,
    Theme,
    createStyles,
    withStyles,
} from "@material-ui/core/styles";
import { connect } from "react-redux";
import { Button, Paper, TextField, Grid, FormControlLabel } from "@material-ui/core";

import { useEffect } from "react";
import {
    postStops,
    updateStops,
    getStops,
    getCities,
    getPhlebos,
    getCenterInfo,
    resetLeadDetails,
    checkGeoFenchMapMyIndiaPilot,
    getMapMyIndiaAccessToken
} from "../../actions/LogisticsPanelAction";
import { useHistory, useParams } from "react-router-dom";
import Checkbox from '@mui/material/Checkbox';
import Loader from "../../components/loader/index";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { TableFooter, TablePagination } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { getLogisticStopsData } from "../../actions/LogisticsPanelAction";
import { generateStopsFilter } from "../../../helpers/generateUrl";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        table: {
            minWidth: 700,
        },
        toolbar: {
            alignItems: "center",
            justifyContent: "flex-end",
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        },
        content: {
            width: "80%",
            flexGrow: 21,
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        paper: {
            marginTop: "2rem",
            padding: "0.5rem 2rem 2rem 2rem",
            width: "100%",
        },
        contentHeader: {
            width: "100%",
            padding: "0px 10px 40px 10px",
            minHeight: "64px",
        },
        leftContentHeader: {
            width: "50%",
            float: "left",
        },
        rightContentHeader: {
            width: "50%",
            float: "right",
            textAlign: "right",
            paddingRight: "20px",
        },
        buttonContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        head: {
            textAlign: "center",
            fontWeight: "normal",
            marginBottom: "2rem",
        },
        slotContainer: {
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
        },
        slot: {
            width: "150px",
            height: "100px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            margin: "0.5rem",
        },
        hoveItem: {
            cursor: "pointer",
        },
        selectedSlot: {
            width: "150px",
            height: "100px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "linear-gradient(60deg, cyan, #00dfff, cyan)",
            cursor: "pointer",
            margin: "0.5rem",
        },
        chips: {
            display: "flex",
        },
        chip: {
            margin: 2,
            background: "linear-gradient(60deg, cyan, #00cfff, cyan)",
            maxWidth: "130px",
        },
        input: {
            padding: "1px",
        },
        tableContainer: {
            marginTop: "2rem",
            position: "sticky",
            top: "200px",
        },
    })
);

const StyledTableCell: any = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: "#924A91",
            color: theme.palette.common.white,
            fontSize: "14px",
        },
        body: {
            fontSize: 14,
        },
    })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    })
)(TableRow);
interface ModalProps {
    getCities: any;
    cities: any;
    postStops: any;
    updateStops: any;
    getStops: any;
    stopsData: any;
    resetLeadDetails: any,
    loading: any;
    checkGeoFenchMapMyIndiaPilot: any;
    geoFencesMapMyIndia: any
    mapmyindiaAccessToken: any;
    getMapMyIndiaAccessToken: any
}
const StopsPlace: React.FC<ModalProps> = ({
    getCities,
    cities,
    postStops,
    updateStops,
    getStops,
    stopsData,
    resetLeadDetails,
    checkGeoFenchMapMyIndiaPilot,
    geoFencesMapMyIndia,
    mapmyindiaAccessToken,
    getMapMyIndiaAccessToken,
    loading,
}) => {
    const classes = useStyles();
    const history = useHistory();
    const timer = useRef<any>(0);
    const [lab, setLab] = React.useState<any>("");
    const [longitude, setLongitude] = React.useState<any>("");
    const [latitude, setLatitude] = React.useState<any>("");
    const [place, setPlace] = useState<any>("");
    const [city, setCity] = useState<any>("");
    const [citys, setCitys] = useState<any>("");
    const [cityName, setCityName] = useState<any>("");
    const [cityNames, setCityNames] = useState<any>("")
    const [isEdit, setIsEdit] = useState<any>(false);
    const [editId, setEditId] = useState<any>("");
    const [page, setPage] = useState(0);
    const [name, setName] = useState<any>("");
    const { id } = useParams<{ id: string }>();
    const [phlebo, setPhlebo] = useState<any>([]);
    const [center, setCentre] = useState<any>("");
    const [isactive, setIsActive] = useState<boolean>(true);
    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        if (newPage > page) {
            let url = stopsData.links && stopsData.links.next.split("?")[1];
            getStops(url);
        } else if (newPage < page) {
            let url = stopsData.links && stopsData.links.previous.split("?")[1];
            getStops(url);
        }
        setPage(newPage as number);
    };
    useEffect(() => {
        getCities();
        getStops();
        resetLeadDetails();
        getMapMyIndiaAccessToken()
    }, []);

    useEffect(() => {
        if (id === "create") {
        } else if (Number(id) > 0) {
            getStops(`id=${id}`);
        }
    }, [id]);

    const handleReset = () => { };
    useEffect(() => {
        if (
            id !== "create" &&
            stopsData &&
            stopsData.results &&
            stopsData.results.length > 0
        ) {
            handleEdit(stopsData.results[0]);
        }
    }, [stopsData, id]);

    const handleSave = async (e: any) => {
        e.preventDefault();
        const data = {
            name: place,
            latitued: latitude,
            longitude: longitude,
            city: city,
            cc: center,
            is_active: isactive,
        };
        if (isEdit) {
            await updateStops(data, editId);
        } else {
            await postStops(data);
        }

        handleReset();
        getStops();
        resetLeadDetails();
        history.push(`/dashboard/lp/stops`);

    };
    const handleEdit = (val: any) => {
        setIsEdit(true);
        setEditId(val.id);
        setPlace(val?.name);
        setLatitude(val?.latitued);
        setLongitude(val?.longitude);
        setCity(val?.city || "");
        setCityName(val?.city_name || "");
        setIsActive(val?.is_active);
    };
    const filterStopsData = (e: any) => {
        const body: any = {
            name,
            citys,
        };
        const url = generateStopsFilter(body).substring(2);
        getStops(url);
        setPage(0);
    };
    const onKeyDown = (e: any) => {
        if (e.key === "Enter") {
            filterStopsData(e);
        }
    };

    const [suggestedAddress, setSuggestedAddres] = useState<any>([]);
    const [mapAddressObject, setMapAddressObject] = useState<any>({});
    const [accessToken, setAccessToken] = useState<any>("");

    useEffect(() => {
        if (geoFencesMapMyIndia?.latitude && geoFencesMapMyIndia?.longitude) {
            setLatitude(geoFencesMapMyIndia?.latitude);
            setLongitude(geoFencesMapMyIndia?.longitude);
        }
    }, [geoFencesMapMyIndia]);
    useEffect(() => {
        if (mapmyindiaAccessToken?.accesstoken) {
            setAccessToken(mapmyindiaAccessToken?.accesstoken);
        }
    }, [mapmyindiaAccessToken]);

    useEffect(() => {
        if (mapAddressObject?.eLoc) {

            const displayAddress = `${mapAddressObject?.placeName}, ${mapAddressObject?.placeAddress}`;
            //getPinCode(mapAddressObject?.addressTokens?.pincode);
            setPlace(displayAddress);
            if (mapAddressObject?.addressTokens?.pincode !== "") {
                checkGeoFenchMapMyIndiaPilot(mapAddressObject?.eLoc);
            }
            else {

            }
        }
    }, [mapAddressObject]);

    const locationAutoCompleteSearch = (val: string, e: any) => {
        if (val.length > 2) {
            const urlForMap = `https://atlas.mapmyindia.com/api/places/search/json?query=${val}&access_token=${accessToken}&tokenizeAddress=true`;
            return new Promise(function (resolve, reject) {
                const id = "_" + Math.round(10000 * Math.random());
                const callbackName = "jsonp_callback_" + "data" + id;
                window[callbackName] = (data: any) => {
                    delete window[callbackName];
                    const ele = document.getElementById(id);
                    ele?.parentNode?.removeChild(ele);
                    const suggestedData = data?.suggestedLocations;
                    if (suggestedData.length > 0) {
                        setSuggestedAddres(suggestedData);
                    }
                };
                const src = urlForMap + "&callback=" + callbackName;
                const script = document.createElement("script");
                script.src = src;
                script.id = id;
                script.addEventListener("error", reject);
                (
                    document.getElementsByTagName("head")[0] ||
                    document.body ||
                    document.documentElement
                ).appendChild(script);
            });
        }
    };
    return (
        <Grid container spacing={2} style={{ marginTop: 50 }}>
            <h2 style={{ fontWeight: "bold", paddingTop: "20px", paddingBottom: "10px" }}>Update Stop</h2>
            <form style={{ width: "100%" }} onSubmit={handleSave}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={3}>

                        <Autocomplete
                            id="customer_landmark"
                            key={1}
                            onChange={(event, newValue) => {
                                if (newValue) {
                                    let obj = JSON.parse(
                                        JSON.stringify(newValue, null, " ")
                                    );
                                    setMapAddressObject(obj);
                                }
                            }}
                            options={suggestedAddress ? suggestedAddress : []}
                            freeSolo
                            aria-required
                            getOptionLabel={(option: any) =>
                                `${option?.placeName}, ${option?.placeAddress}`
                            }
                            filterOptions={(option: any) => option}
                            onInputChange={(event, newInputValue) => {
                                clearTimeout(timer.current);
                                timer.current = setTimeout(() => {
                                    locationAutoCompleteSearch(newInputValue, event);
                                }, 500);
                            }}
                            // onKeyDown={(e)=>handleKeyDown(e)}
                            renderInput={(params) => (
                                <TextField
                                    className="input"
                                    {...params}
                                    label="Search Address"
                                    variant="outlined"
                                    style={{ width: "100%", margin: "0", padding: "0" }}
                                    required
                                    inputProps={{
                                        ...params.inputProps,
                                        onKeyDown: (e) => {
                                            if (e.key === 'Enter') {
                                                e.stopPropagation();
                                            }
                                        },
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <TextField
                            label="Latitude"
                            variant="outlined"
                            className="input"
                            fullWidth
                            value={latitude}
                            onChange={(e) => setLatitude(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <TextField
                            label="Longitude"
                            variant="outlined"
                            className="input"
                            fullWidth
                            value={longitude}
                            onChange={(e) => setLongitude(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Autocomplete
                            id="city"
                            onChange={(event, newValue) => {
                                if (newValue) {
                                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                    setCity(obj.id);
                                }
                            }}
                            inputValue={cityName}
                            loading={loading}
                            options={cities || []}
                            onInputChange={(event, newInputValue) => {
                                clearTimeout(timer.current);
                                timer.current = setTimeout(() => {
                                    getCities(newInputValue);
                                }, 1000);

                                setCityName(newInputValue);
                                if (newInputValue === "") {
                                    setCity("");
                                }
                            }}
                            freeSolo
                            blurOnSelect
                            aria-required
                            getOptionLabel={(option: any) => `${option?.name}`}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    className="input"
                                    label="City"
                                    variant="outlined"
                                    inputProps={{
                                        ...params.inputProps,
                                        onKeyDown: (e) => {
                                            if (e.key === 'Enter') {
                                                e.stopPropagation();
                                            }
                                        },
                                    }}
                                    fullWidth
                                    required
                                />
                            )}
                        />
                    </Grid>
                    {/* <Grid item xs={12} sm={4} md={3}>
                        <Autocomplete
                            id="center_type"
                            onChange={(event, newValue) => {
                                if (newValue) {
                                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                    setCentre(obj.id);
                                }
                            }}
                            freeSolo
                            blurOnSelect
                            options={centerInfo?.results}
                            disableCloseOnSelect
                            getOptionLabel={(option: any) =>
                                (option?.display_name === null
                                    ? option?.name
                                    : option?.display_name) +
                                "(" +
                                option?.center_code +
                                ")"
                            }
                            onInputChange={(event, newInputValue) => {
                                getCenterInfo(`&name_or_code=${newInputValue}`);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Center"
                                    className="input"
                                    variant="outlined"
                                    fullWidth
                                    required
                                />
                            )}
                        />
                    </Grid> */}
                    {/* <Grid item xs={12} sm={4} md={2}>
                        <Autocomplete
                            id="phleboName"
                            onChange={(event, newValue) => {
                                if (newValue) {
                                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                    setPhlebo(obj.id);
                                }
                            }}
                            options={
                                phleboList.results
                                    ? [
                                        ...phleboList.results,
                                        { id: 0, user: { username: "NoPhlebo" } },
                                    ]
                                    : []
                            }
                            freeSolo
                            blurOnSelect
                            getOptionLabel={(option: any) =>
                                option.user && option.user.username
                            }
                            onInputChange={(event, newInputValue) => {
                                clearTimeout(timer.current);
                                timer.current = setTimeout(() => {
                                    getPhlebos(`${newInputValue}`);
                                }, 1000);
                                if (newInputValue.length === 0) {
                                    setPhlebo([]);
                                }
                            }}
                            multiple
                            renderInput={(params) => (
                                <TextField
                                    className="input"
                                    {...params}
                                    placeholder="Phlebo Name"
                                    variant="outlined"
                                    style={{ width: "100%", margin: "0", padding: "0" }}
                                />
                            )}
                        />
                    </Grid> */}
                    {/* <Grid item xs={12} sm={4} md={3}>
                        <Autocomplete
                            id="phleboName"
                            loading={loading}
                            onChange={(event, newValue) => {
                                if (newValue) {
                                    let packs: any = [];
                                    newValue.map((val: any) => {
                                        let obj = JSON.parse(JSON.stringify(val, null, " "));
                                        packs.push(obj.id);
                                    });
                                    setPhlebo(packs);
                                }
                            }}
                            multiple
                            disableClearable
                            options={
                                phleboList.results
                                    ? [
                                        ...phleboList.results,
                                        { id: 0, user: { username: "NoPhlebo" } },
                                    ]
                                    : []
                            }
                            limitTags={0}
                            freeSolo
                            disableCloseOnSelect
                            onInputChange={(event, newInputValue) => {
                                clearTimeout(timer.current);
                                timer.current = setTimeout(() => {
                                    getPhlebos(newInputValue);
                                }, 1000);
                            }}
                            getOptionLabel={(option: any) =>
                                option.user && option.user.username
                            }
                            getOptionDisabled={(option: any) => {
                                const packID = phlebo.map((pack: any) => pack.user);
                                return packID.includes(option.user.username);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    className="input"
                                    {...params}
                                    placeholder="Phlebo Name"
                                    variant="outlined"
                                    style={{ width: "100%", margin: "0", padding: "0" }}
                                />
                            )}
                        />
                    </Grid> */}
                    {/* <Grid item xs={12} sm={3} md={2}>
                        <FormControlLabel
                            control={<Checkbox />}
                            checked={isactive}
                            label="Is Active"
                            onChange={(e) => setIsActive(!isactive)}
                        />
                    </Grid> */}
                    <Grid item xs={12} sm={6} md={2}>
                        <Button
                            color="primary"
                            variant="contained"
                            fullWidth
                            type="submit"
                            style={{ height: "100%" }}
                        >
                            {isEdit ? "update" : "Create"}
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                        <Button
                            color="secondary"
                            variant="contained"
                            fullWidth
                            onClick={() => history.push("/dashboard/lp/stop_plan_action/create")}
                            style={{ height: "100%" }}
                        >
                            reset
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Grid>
    );
};
const mapStateToProps = (state: any) => ({
    cities: state.LogisticsReducer.cities,
    stopsData: state.LogisticsReducer.stopsData,
    phleboList: state.LogisticsReducer.phleboList,
    centerInfo: state.LogisticsReducer.centerInfo,
    lead_details: state.LogisticsReducer.lead_details,
    geoFencesMapMyIndia: state.LogisticsReducer.geoFencesMapMyIndia,
    mapmyindiaAccessToken: state.LogisticsReducer.mapmyindiaAccessToken,
    loading: state.LogisticsReducer.loading,
});
export default connect(mapStateToProps, {
    getStops,
    postStops,
    updateStops,
    getCities,
    getPhlebos,
    getCenterInfo,
    resetLeadDetails,
    checkGeoFenchMapMyIndiaPilot,
    getMapMyIndiaAccessToken
})(StopsPlace);
