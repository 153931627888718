import React, { useEffect, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Card,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Typography,
  FormLabel,
  FormControl,
  FormGroup,
  FormControlLabel,
  FormHelperText,
  Checkbox,
} from "@material-ui/core";
import Button from '@mui/material/Button';
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import {
  getBookingById,
  getPinCode,
  resetBookings,
  clickToCall,
  updateBooking,
} from "../../../actions/BulkUploadMasterAction";
import Loader from "../../../../components/loader";
import moment from "moment";
import CallIcon from "@material-ui/icons/Call";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      marginTop: "2rem",
      padding: "0.5rem 2rem",
      width: "100%",
    },
    formControl: {
      margin: theme.spacing(3),
    },
  })
);

interface Props {
  match: any;
  booking: any;
  getBookingById: any;
  getPinCode: any;
  pin_code: any;
  partnerList: any;
  getPartners: any;
  resetBookings: any;
  clickToCall: any;
  updateBooking: any;
  loading: Boolean;
}
const TUBEDISPOSITION = [
  {
    id: 1,
    type: "SERUM SAMPLE QUANTITY NOT SUFFICIENT (LEAKED OUT)",
  },
  {
    id: 2,
    type: "URINE SAMPLE NOT RECEIVED.",
  },
  {
    id: 3,
    type: "FLUORIDE SAMPLE NOT RECEIVED.",
  },
  {
    id: 4,
    type: "FLUORIDE & URINE SAMPLE NOT RECEIVED.",
  },
  {
    id: 5,
    type: "URINE SAMPLE QUANTITY NOT SUFFICIENT (LEAKED OUT)",
  },
  {
    id: 6,
    type: "UNLABELED URINE SAMPLE RECEIVED",
  },
  {
    id: 7,
    type: "SAMPLE QUANTITY NOT SUFFICIENT",
  },
  {
    id: 8,
    type: "STOOL SAMPLE NOT RECEIVED.",
  },
  {
    id: 9,
    type: "WRONG SAMPLE TYPE RECEIVED.",
  },
  {
    id: 10,
    type: "ONLY TRF RECEIVED. SAMPLE NOT RECEIVED.",
  },
  {
    id: 11,
    type: "24 HRS. URINE VOLUME NOT MENTION",
  },
  {
    id: 12,
    type: "EMPTY FLUORIDE TUBE RECEIVED",
  },
  {
    id: 13,
    type: "ALL TUBES UNLABELED RECEIVED",
  },
  {
    id: 14,
    type: "HAEMOLYSED SAMPLE",
  },
  {
    id: 15,
    type: "TURBID SAMPLE",
  },
  {
    id: 16,
    type: "RESULT RE-CHECK",
  },
  {
    id: 17,
    type: "LIPEMIC SAMPLE",
  },
  {
    id: 18,
    type: "PATIENT LABELLING MISMATCH ON TUBE",
  },
];
const tubes: any = [
  {
    tube: 1,
    tubeName: "tube 1",
    disposition: "Select Disposition",
    isChecked: false,
    packageId: 1,
  },
  {
    tube: 2,
    tubeName: "tube 1",
    disposition: "Select Disposition",
    isChecked: false,
    packageId: 1,
  },
];
const ViewBooking: React.FC<Props> = ({
  match,
  booking,
  getBookingById,
  getPinCode,
  pin_code,
  resetBookings,
  clickToCall,
  updateBooking,
  loading,
}) => {
  const classes = useStyles();
  const [resamplingMessage, setResamplinigMessage] = useState<string>("");
  const [openResapling, setResapling] = React.useState(false);
  const [tubeList, setTubeList] = useState<any>([]);
  useEffect(() => {
    resetBookings();
    setTubeList(tubes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const bookingId = match.params.id;
  useEffect(() => {
    getBookingById(bookingId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingId]);

  useEffect(() => {
    getPinCode(
      booking?.customer_areapincode && booking?.customer_areapincode.pincode
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [booking]);

  const handleCallClick = (id: number, type: string) => {
    clickToCall(id, type);
  };

  const history = useHistory();
  const cancelHandler = () => {
    // const data: any = {
    //     "booking_status": "cancelled"
    // }
    // updateBooking(data, bookingId)
    history.push(`/dashboard/ccadmin`);
  };

  const resamplingHandler = () => {
    setResapling(true);
  };
  const closeResampling = () => {
    setResamplinigMessage("");
    setResapling(false);
  };
  const updateMessage = () => {
    let message = "";
    const list = [...tubeList];
    list.map((data: any) => {
      if (data.isChecked && data.disposition !== "Select Disposition") {
        message =
          message +
          "Tube Name: " +
          data.tubeName +
          ", Disposition: " +
          data.disposition +
          "\n";
      }
      setResamplinigMessage(message);
    });
  };
  const handleCheckBox = (event: any, index: number) => {
    const isCheck = event.target.checked;
    const list = [...tubeList];
    list[index]["isChecked"] = isCheck;
    setTubeList(list);
    updateMessage();
  };
  const handleDisposition = (event: any, index: number) => {
    const selectedDisposition = event.target.value;
    const listDispo = [...tubeList];
    listDispo[index]["disposition"] = selectedDisposition;
    setTubeList(listDispo);
    updateMessage();
  };
  const handleDOBForAge = (dob: any, bdate: any) => {
    const a: any = moment(bdate);
    const b: any = moment(dob);
    var years = a.diff(b, "year");
    b.add(years, "years");
    var months = a.diff(b, "months");
    b.add(months, "months");
    var days = a.diff(b, "days");
    if (months !== 0) {
      var age = months + "Months " + days + "Days";
    } else {
      var age = days + "Days";
    }

    return age;
  };


  return (
    <>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Dialog
          open={openResapling}
          onClose={closeResampling}
          aria-labelledby="form-dialog-title"
        >
          <DialogContent style={{ width: "600px", height: "auto" }}>
            <TextareaAutosize
              maxRows={3}
              style={{ height: "150px", width: "100%" }}
              aria-label="maximum height"
              placeholder="Message..."
              value={resamplingMessage}
              onChange={(e) => setResamplinigMessage(e.target.value as string)}
            />
          </DialogContent>
          <DialogContent>
            <FormControl
              component="fieldset"
              className={classes.formControl}
              style={{ width: "90%" }}
            >
              <FormLabel component="legend">TUBE DETAILS</FormLabel>
              {tubeList && tubeList?.length > 0
                ? tubeList.map((data: any, index: any) => {
                    return (
                      <FormGroup key={index}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={data.isChecked}
                              onChange={(e) => handleCheckBox(e, index)}
                              name="gilad"
                            />
                          }
                          label={data?.tubeName}
                        />
                        {data.isChecked === true ? (
                          <Select
                            className="input"
                            name="customer_designation"
                            variant="outlined"
                            value={data.disposition}
                            style={{ width: "100%", margin: "0" }}
                            onChange={(e: any) => handleDisposition(e, index)}
                            required={data.isChecked === true}
                          >
                            <MenuItem disabled value={data.disposition}>
                              {data.disposition}
                            </MenuItem>
                            {TUBEDISPOSITION.map((tube: any, index: any) => {
                              return (
                                <MenuItem value={tube.type}>
                                  {tube.id + ". " + tube.type}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        ) : (
                          ""
                        )}
                      </FormGroup>
                    );
                  })
                : ""}
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeResampling} color="primary">
              Cancel
            </Button>
            <Button
              //   onClick={ProccessResampling}
              color="primary"
              disabled={resamplingMessage === ""}
            >
              Proceed
            </Button>
          </DialogActions>
        </Dialog>
        <Paper className={classes.paper} elevation={15}>
          <h3 style={{ textAlign: "center" }}>View Booking Details</h3>
          {loading ? (
            <Loader />
          ) : (
            <>
              <Grid
                container
                direction="row"
                alignItems="flex-start"
                justify="space-between"
                spacing={3}
                style={{ margin: "1rem auto" }}
              >
                <Grid item xs={6} md={3}>
                  <h6>Collection Date</h6>
                  <p style={{ fontWeight: "normal" }}>
                    {booking?.collection_date}
                  </p>
                </Grid>
                <Grid item xs={6} md={3}>
                  <h6>Pincode</h6>
                  <p style={{ fontWeight: "normal" }}>
                    {booking?.customer_areapincode &&
                      booking?.customer_areapincode?.pincode}
                  </p>
                </Grid>
                <Grid item xs={6} md={3}>
                  <h6>Zone Name</h6>
                  <p style={{ fontWeight: "normal" }}>
                    {booking?.customer_areapincode &&
                      booking?.customer_areapincode?.area}
                  </p>
                </Grid>
                <Grid item xs={6} md={3}>
                  <h6>Booking Id</h6>
                  <p style={{ fontWeight: "normal" }}>{booking?.pk}</p>
                </Grid>
              </Grid>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Customer Name</TableCell>
                      <TableCell align="center">Age</TableCell>
                      <TableCell align="center">Gender</TableCell>
                      <TableCell align="center">Package Name</TableCell>
                      {/* <TableCell align="center">Partner OrderId</TableCell> */}
                      <TableCell align="center">Gross Price</TableCell>
                      {/* <TableCell align="center">Phlebo Cost</TableCell> */}
                      <TableCell align="center">Discount</TableCell>
                      <TableCell align="center">Total Price</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell align="center">{`${
                        booking?.designation || ""
                      } ${booking?.customer_name}`}</TableCell>
                      <TableCell align="center">
                        {booking?.customer_age === 0
                          ? handleDOBForAge(booking?.dob, booking?.booking_date)
                          : booking?.customer_age}
                      </TableCell>
                      <TableCell align="center">
                        {booking?.customer_gender}
                      </TableCell>
                      <TableCell align="center">
                        <ul>
                          {booking?.packages &&
                            booking?.packages.map((pack: any, index: any) => {
                              return (
                                <li
                                  key={index}
                                  style={{
                                    margin: "1rem 0",
                                    textAlign: "left",
                                  }}
                                >
                                  {pack.name}({pack.code}), {pack.tat_time}
                                </li>
                              );
                            })}
                        </ul>
                      </TableCell>
                      {/* <TableCell align="center">
                        {booking?.partner && booking?.partner?.id}
                      </TableCell> */}
                      <TableCell align="center">
                        Rs.
                        {booking?.final_packages_price +
                          booking?.final_discount}
                      </TableCell>
                      {/* <TableCell align="center">
                        {booking?.discounted_price &&
                          booking?.discounted_price.phlebo_cost &&
                          Math.floor(booking?.discounted_price.phlebo_cost)}
                      </TableCell> */}

                      <TableCell align="center">
                        Rs.{booking?.final_discount}
                      </TableCell>
                      <TableCell align="center">
                        Rs. {booking?.final_packages_price}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              {/* <h6
                style={{
                  textAlign: "center",
                  margin: "0",
                  border: "1px solid #ccc",
                  padding: "1rem",
                }}
              >
                Total Amount:{" "}
                {booking?.discounted_price &&
                  booking?.discounted_price.final_total_price &&
                  Math.floor(booking?.discounted_price.final_total_price)}
              </h6> */}
              <Grid
                container
                spacing={4}
                direction="row"
                justify="flex-start"
                alignItems="center"
                style={{ margin: "1rem auto" }}
              >
                <Grid
                  direction="row"
                  justify="space-around"
                  alignItems="center"
                  container
                  item
                  xs={12}
                  md={6}
                  spacing={3}
                >
                  <Grid item xs={12} md={5}>
                    <p>
                      Mobile Number
                      <CallIcon
                        onClick={() => handleCallClick(bookingId, "call")}
                      />
                    </p>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      name="customer_phonenumber"
                      type="text"
                      value={booking?.customer_phonenumber || ""}
                      disabled
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  direction="row"
                  justify="space-around"
                  alignItems="center"
                  container
                  item
                  xs={12}
                  md={6}
                  spacing={3}
                >
                  <Grid item xs={12} md={5}>
                    <p>
                      Alternate Mobile Number
                      <CallIcon
                        onClick={() => handleCallClick(bookingId, "contact")}
                      />
                    </p>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      name="customer_alternatenumber"
                      type="text"
                      value={booking?.customer_altphonenumber || ""}
                      disabled
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  direction="row"
                  justify="space-around"
                  alignItems="center"
                  container
                  item
                  xs={12}
                  md={6}
                  spacing={3}
                >
                  <Grid item xs={12} md={5}>
                    <p>Email</p>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      name="customer_email"
                      type="email"
                      disabled
                      value={booking?.customer_email || ""}
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  direction="row"
                  justify="space-around"
                  alignItems="center"
                  container
                  item
                  xs={12}
                  md={6}
                  spacing={3}
                >
                  <Grid item xs={12} md={5}>
                    <p>
                      Whatsapp Number
                      <CallIcon
                        onClick={() => handleCallClick(bookingId, "whatsapp")}
                      />
                    </p>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      name="customer_whatsapppnumber"
                      type="text"
                      disabled
                      value={booking?.customer_whatsapppnumber || ""}
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  direction="row"
                  justify="space-around"
                  alignItems="center"
                  container
                  item
                  xs={12}
                  md={6}
                  spacing={3}
                >
                  <Grid item xs={12} md={5}>
                    <p>Aadhar Number</p>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      name="customer_aadhar"
                      type="text"
                      disabled
                      value={booking?.customer_aadhar || ""}
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={4}
                direction="row"
                justify="flex-start"
                alignItems="center"
                style={{ margin: "1rem auto" }}
              >
                <Grid item xs={12}>
                  <Divider style={{ backgroundColor: "#009fff" }} />
                  <h3 style={{ textAlign: "center" }}>Address</h3>
                </Grid>
                <Grid
                  direction="row"
                  justify="space-around"
                  alignItems="center"
                  container
                  item
                  xs={12}
                  md={6}
                  spacing={3}
                >
                  <Grid item xs={12} md={5}>
                    <p>House Number</p>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      name="houseNumber"
                      type="text"
                      value={
                        (booking?.customer_address &&
                          booking?.customer_address.split(",")[0]) ||
                        ""
                      }
                      disabled
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  direction="row"
                  justify="space-around"
                  alignItems="center"
                  container
                  item
                  xs={12}
                  md={6}
                  spacing={3}
                >
                  <Grid item xs={12} md={5}>
                    <p>Full Address</p>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      name="customer_address"
                      type="text"
                      value={booking?.customer_address || ""}
                      disabled
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  direction="row"
                  justify="space-around"
                  alignItems="center"
                  container
                  item
                  xs={12}
                  md={6}
                  spacing={3}
                >
                  <Grid item xs={12} md={5}>
                    <p>Landmark</p>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      name="customer_landmark"
                      type="text"
                      value={booking?.customer_landmark || ""}
                      disabled
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  direction="row"
                  justify="space-around"
                  alignItems="center"
                  container
                  item
                  xs={12}
                  md={6}
                  spacing={3}
                >
                  <Grid item xs={12} md={5}>
                    <p>City</p>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      name="city"
                      type="text"
                      value={
                        (pin_code.results &&
                          pin_code.results.length > 0 &&
                          pin_code.results[0].city) ||
                        ""
                      }
                      disabled
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  direction="row"
                  justify="space-around"
                  alignItems="center"
                  container
                  item
                  xs={12}
                  md={6}
                  spacing={3}
                >
                  <Grid item xs={12} md={5}>
                    <p>Area Name</p>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      name="areaName"
                      type="text"
                      disabled
                      value={
                        (booking?.customer_areapincode &&
                          booking?.customer_areapincode?.area) ||
                        ""
                      }
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  direction="row"
                  justify="space-around"
                  alignItems="center"
                  container
                  item
                  xs={12}
                  md={6}
                  spacing={3}
                >
                  <Grid item xs={12} md={5}>
                    <p>Pincode</p>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      name="pincode"
                      type="text"
                      disabled
                      value={
                        (booking?.customer_areapincode &&
                          booking?.customer_areapincode.pincode) ||
                        ""
                      }
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={4}
                direction="row"
                justify="flex-start"
                alignItems="center"
                style={{ margin: "1rem auto" }}
              >
                {booking?.payment_image &&
                  booking?.payment_image.map((image: any) => {
                    return (
                      <Grid
                        key={image.id}
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={3}
                        spacing={3}
                      >
                        <img
                          src={image.image}
                          alt="payment"
                          style={{ width: "100%", aspectRatio: "1/1" }}
                        />
                      </Grid>
                    );
                  })}
              </Grid>
            </>
          )}
        </Paper>
      </main>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  booking: state. BulkUploadMasterReducer.booking,
  pin_code: state. BulkUploadMasterReducer.pin_code,
  loading: state. BulkUploadMasterReducer.loading,
});

export default connect(mapStateToProps, {
  getBookingById,
  getPinCode,
  resetBookings,
  updateBooking,
  clickToCall,
})(ViewBooking);
