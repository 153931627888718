import React, { useState, useEffect } from "react";
import { Chip, Grid, Typography, TextField } from "@material-ui/core";
import { connect } from "react-redux";
import Divider from "@material-ui/core/Divider";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import {getBookingOverview} from "../../actions/productMangerAction";
import BarChartTwoToneIcon from "@mui/icons-material/BarChartTwoTone";
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import RepeatRoundedIcon from '@mui/icons-material/RepeatRounded';
import DataThresholdingRoundedIcon from '@mui/icons-material/DataThresholdingRounded';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import Avatar from "@mui/material/Avatar";
import moment from "moment";
import "./home.css"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      height: "100%",
      padding: "20px",
    },
    cardContent: {
      textAlign: "center",
    },
    center: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    text: { color: "black" },
  })
);

interface Props {
  getBookingOverview:any;
  bookingoverview:any;
}

const Dashboard: React.FC<Props> = ({
  getBookingOverview,
  bookingoverview
}) => {
  const classes = useStyles();
  useEffect(() => {
    getBookingOverview("")
}, [])

  return (
    <div style={{ width: "90%", margin: "60px auto" }}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <h3>Dashboard</h3>
        </Grid>
        <Grid item xs={6} style={{ textAlign: "right" }}>
          <TextField
            type="date"
            defaultValue={moment().format("YYYY-MM-DD")}
            aria-readonly
            onChange={(e) => {
              getBookingOverview(
                `overview_date=${moment(e.target.value).format("YYYY-MM-DD")}`
              );
            }}
          />
        </Grid>
      </Grid>
      <div style={{ margin: "10px 0px 0px 0px" }}>
        <h5>PICKUP</h5>
        <div className="cardsAppointment">
          <div>
            <Grid container>
              <Grid item sm={3}>
                <Avatar
                  sx={{ bgcolor: "#ffeff5", marginTop: "8px" }}
                  aria-label="recipe"
                >
                  <BarChartTwoToneIcon style={{ color: "#fa4d83" }} />
                </Avatar>
              </Grid>
              <Grid item sm={9}>
                <h5 style={{ color: "#ffffff" }}>{bookingoverview?.total_pickup}</h5>
                <span style={{ color: "#ffffff" }}>Total</span>
              </Grid>
            </Grid>
          </div>
          <div style={{ marginLeft: "6%" }}>
            <Grid container>
              <Grid item sm={3}>
                <Avatar
                  sx={{ bgcolor: "#ffeff5", marginTop: "8px" }}
                  aria-label="recipe"
                >
                  <CancelRoundedIcon style={{ color: "#fa4d83" }} />
                </Avatar>
              </Grid>
              <Grid item sm={9}>
                <h5 style={{ color: "#ffffff" }}>{bookingoverview?.pickup_cancelled}</h5>
                <span style={{ color: "#ffffff" }}>Cancelled</span>
              </Grid>
            </Grid>
          </div>
          <div style={{ marginLeft: "6%" }}>
            <Grid container>
              <Grid item sm={3}>
                <Avatar
                  sx={{ bgcolor: "#ffeff5", marginTop: "8px" }}
                  aria-label="recipe"
                >
                  <CheckCircleRoundedIcon style={{ color: "#fa4d83" }} />
                </Avatar>
              </Grid>
              <Grid item sm={9}>
                <h5 style={{ color: "#ffffff" }}>{bookingoverview?.pickup_done}</h5>
                <span style={{ color: "#ffffff" }}>Confirmed</span>
              </Grid>
            </Grid>
          </div>
          <div style={{ marginLeft: "6%" }}>
            <Grid container>
              <Grid item sm={3}>
                <Avatar
                  sx={{ bgcolor: "#ffeff5", marginTop: "8px" }}
                  aria-label="recipe"
                >
                  <DataThresholdingRoundedIcon style={{ color: "#fa4d83" }} />
                </Avatar>
              </Grid>
              <Grid item sm={9}>
                <h5 style={{ color: "#ffffff" }}>{bookingoverview?.pickup_hold}</h5>
                <span style={{ color: "#ffffff" }}>Hold</span>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="cardsAppointment">
          <div>
            <Grid container>
              <Grid item sm={3}>
                <Avatar
                  sx={{ bgcolor: "#ffeff5", marginTop: "8px" }}
                  aria-label="recipe"
                >
                  <MoreHorizRoundedIcon style={{ color: "#fa4d83" }} />
                </Avatar>
              </Grid>
              <Grid item sm={9}>
                <h5 style={{ color: "#ffffff" }}>{bookingoverview?.pickup_pending}</h5>
                <span style={{ color: "#ffffff" }}>Pending</span>
              </Grid>
            </Grid>
          </div>
          <div style={{ marginLeft: "6%" }}>
            <Grid container>
              <Grid item sm={3}>
                <Avatar
                  sx={{ bgcolor: "#ffeff5", marginTop: "8px" }}
                  aria-label="recipe"
                >
                  <RepeatRoundedIcon style={{ color: "#fa4d83" }} />
                </Avatar>
              </Grid>
              <Grid item sm={9}>
                <h5 style={{ color: "#ffffff" }}>{bookingoverview?.pickup_reschedule}</h5>
                <span style={{ color: "#ffffff" }}>Rescheduled</span>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  bookingoverview: state.ProductManagerReducer.bookingoverview,
});

export default connect(mapStateToProps, {
  getBookingOverview,
})(Dashboard);