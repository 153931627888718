import React, { useEffect, useRef, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Grid, TextField, MenuItem, Select } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import Loader from "../../components/loader";
import BatchedPaymentTable from "../../tables/batchedPayment";
import { batchPaymentFilter1 } from "../../../helpers/generateUrl";
import SearchIcon from "@mui/icons-material/Search";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import Button from '@mui/material/Button';
import { 
  getBatchTransferData,
  UpdateBatchAmountReceipt,
  getLabCredential,
  getAgentList,
} from '../../actions/RouteManagerAction'
import AmountTable from '../BatchAmount/table'
let width = window.innerWidth;
let isMobile = width <= 500;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
      //   width: "100%",
      //   marginTop: "20px",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
      //   padding: theme.spacing(3),
    },
    card: {
      height: "100%",
      padding: theme.spacing(1),
    },
    card_grid: {
      // wordSpacing:3,
      justifyContent: "space-between",
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: isMobile ? "400px" : "800px",
    },
  })
);

interface Props {
  loading: boolean;
  getBatchTransferData: any;
  batchTransferData: any;
  UpdateBatchAmountReceipt: any;
  getLabCredential: any;
  getAgentList: any;
  labCredential: any;
  agentsList: any;
}

const BatchedPaymentFilter: React.FC<Props> = ({
  loading,
  getBatchTransferData,
  batchTransferData,
  UpdateBatchAmountReceipt,
  getLabCredential,
  getAgentList,
  labCredential,
  agentsList,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const timer = useRef<any>(0);
  const [bookingId, setBookingId] = useState<any>("");
  const [lab, setLab] = useState<any>("");
  const [panel_user, setPanel_user] = useState<any>("");
  const [batch, setBatch] = useState<any>("");
  const [status, setStatus] = useState<any>("none");
  const [page, setPage] = useState(0);
  const [createdAtGreater, setCreatedAtGreater] = useState("");
  const [createdAtLesser, setCreatedAtLesser] = useState("");
  const [createdAt, setCreatedAt] = useState("");
  useEffect(() => {
    getBatchTransferData();
    getLabCredential();
    getAgentList();
  }, []);

  const filterBatchPayment = () => {
    const body: any = {
      status,
      batch,
      createdAtGreater,
      createdAtLesser,
      createdAt,
      panel_user,
      booking: bookingId,
      lab,
    };
    const url = batchPaymentFilter1(body).substring(2);
    getBatchTransferData(`${url}`)
    setPage(0)
  };
  return (
    <>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <h2>BATCH AMOUNT TRANSFER</h2>
        <Grid
          container
          spacing={1}
          direction="row"
          style={{ alignItems: "left" }}
        >
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              type="number"
              onWheel={(e: any) => e.target.blur()}
              label="Booking Id"
              value={bookingId}
              variant="outlined"
              onChange={(e) => setBookingId(e.target.value)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              type="number"
              onWheel={(e: any) => e.target.blur()}
              label="Batch"
              value={batch}
              variant="outlined"
              onChange={(e) => setBatch(e.target.value)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              onChange={(event, newValue) => {
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  setLab(obj.id);
                }
              }}
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getLabCredential(`?name=${newInputValue}`);
                }, 1000);

                if (newInputValue.length === 0) {
                  setLab("");
                }
              }}
              options={labCredential?.results || []}
              freeSolo
              blurOnSelect
              aria-required
              getOptionLabel={(option: any) => `${option?.name}`}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Lab"
                  variant="outlined"
                  fullWidth
                  className="input"
                  inputProps={{
                    ...params.inputProps,
                    onKeyDown: (e) => {
                          if (e.key === 'Enter') {
                            e.stopPropagation();
                          }
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
          <Autocomplete
            id="panelUser"
            onChange={(event, newValue) => {
              if (newValue) {
                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                setPanel_user(obj.id);
              }
            }}
            options={agentsList.results || []}
            freeSolo
            blurOnSelect
            aria-required
            getOptionLabel={(option: any) => option.user && option.user.name}
            onInputChange={(event, newInputValue) => {
              clearTimeout(timer.current);
              timer.current = setTimeout(() => {
                getAgentList(`${newInputValue.replace(" ", "")}`);
              }, 1000);
              if (newInputValue.length === 0) {
                setPanel_user(0);
              }
            }}
            renderInput={(params) => (
              <TextField
                className="input"
                {...params}
                label="Panel User"
                variant="outlined"
                style={{ width: "100%", margin: "0", padding: "0" }}
                required
              />
            )}
          />
        </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Select
              className="input"
              name="status"
              variant="outlined"
              value={status}
              style={{ width: "100%", height: "40px" }}
              onChange={(e) => setStatus(e.target.value as String)}
            >
              <MenuItem disabled value={"none"}>
                Status
              </MenuItem>
              <MenuItem value={"pending"}>Pending</MenuItem>
              <MenuItem value={"rejected"}>Rejected</MenuItem>
              <MenuItem value={"accepted"}>Accepted</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              name="created_at"
              type="date"
              label="Created At"
              value={
                createdAt
              }
              variant="outlined"
              // disabled={booking_date !== "" || collection_date !== ""}
              // onKeyDown={onKeyDown}
              onChange={(e) => setCreatedAt(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              name="ceatedAtLesser"
              type="date"
              value={
                createdAtLesser
              }
              className="input"
              label="Start Date"
              variant="outlined"
              // disabled={booking_date !== "" || collection_date !== ""}
              onChange={(e) => setCreatedAtLesser(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              name="createdAtGreater"
              type="date"
              value={createdAtGreater}
              className="input"
              label="End Date"
              variant="outlined"
              // disabled={start_date !== "" || end_date !== ""}
              onChange={(e) => setCreatedAtGreater(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              onClick={filterBatchPayment}
              startIcon={<SearchIcon />}
              disabled={loading}
            >
              Filter
            </Button>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              startIcon={<RestartAltIcon />}
              onClick={() => history.push("/dashboard/rm/batch-amount-transfer")}
            >
              Reset
            </Button>
          </Grid>
        </Grid>
        <AmountTable
          loading={loading}
          filterBatchPayment={filterBatchPayment}
          batchTransferData={batchTransferData}
          getBatchTransferData={getBatchTransferData}
          UpdateBatchAmountReceipt={UpdateBatchAmountReceipt} page={page} setPage={setPage}          />
      </main>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    loading: state.RouteManagerReducer.loading,
    batchTransferData: state.RouteManagerReducer.batchTransferData,
    labCredential: state.RouteManagerReducer.labCredential,
    agentsList: state.RouteManagerReducer.agentsList,
  };
};

export default connect(mapStateToProps, {
    getBatchTransferData,
    UpdateBatchAmountReceipt,
    getLabCredential,
    getAgentList,
})(BatchedPaymentFilter);
