import React, { useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Loader from "../loader"
import { connect } from "react-redux";
import { Button, Paper, TextField, Grid,Slider,withStyles } from "@material-ui/core";
import Box from '@mui/material/Box';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useHistory } from "react-router";
import { useEffect } from "react";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useRef } from "react";
import { getCities, createLabs } from '../../actions/productMangerAction'


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 0,
        },
        toolbar: {
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        },
        content: {
            width: "80%",
            flexGrow: 21,
        },
        contentHeader: {
            width: "100%",
            padding: "0px 10px 40px 10px",
            minHeight: "64px",
        },
        leftContentHeader: {
            width: "50%",
            float: "left",
        },
        rightContentHeader: {
            width: "50%",
            float: "right",
            textAlign: "right",
            paddingRight: "20px",
        },
        paper: {
            padding: "1rem",
            width: "100%",
            borderRadius: "10px",
        },
        head: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "1rem",
            textAlign: "center"
        },
        heading: {
            fontWeight: "normal",
            fontSize: "1.2rem",
            margin: "0"
        },
        table: {
            maxHeight: "400px",
            margin: "auto",
            overflow: "auto"
        },
        image: {
            width: "100px",
            height: "auto",
        },
        imageSM: {
            width: "50px",
            height: "auto",
        },
        imgContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexWrap: "wrap"
        }
    })
);


const PrettoSlider:any = withStyles({
    root: {
        color: 'rgb(146, 74, 145)',
        height: 8,
    },

    thumb: {
        height: 24,
        width: 24,
        backgroundColor: 'violet',
        border: '2px solid purple',
        marginTop: -8,
        marginLeft: -12,
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 4px)',
    },
    track: {
        height: 8,
        borderRadius: 4,
    },
    rail: {
        height: 8,
        borderRadius: 4,
    },
})(Slider);

interface Props {
    getCities: any;
    cities: any;
    loading: any;
    createLabs: any;
   
}

const CreatePackagePage: React.FC<Props> = ({
    getCities,
    cities,
    createLabs,
}) => {
    const classes = useStyles();
    const history = useHistory()
    const [name, setName] = useState<string>("")
    const [publicKey, setPublicKey] = useState<string>("")
    const [labaddress, setlabaddress] = useState<string>("")
    const [email, setemail] = useState<string>("")
    const [contactnumber, setcontactnumber] = useState<String>("")
    const [latitude, setlatitude] = useState<string>("")
    const [longitude, setlongitude] = useState<string>("")
    const [city, setCity] = useState<any>([]);
    const timer = useRef<any>(0)
    const [packageImage, setPackageImage] = useState<any>("")
    const [isActiveCheck, setIsActiveCheck] = useState(false);
    const [key, setkey] = useState<string>("")
    const [labid, setlabid] = useState<string>("")
    const [nameError, setError] = useState<string>()


    useEffect(() => {

        getCities()

    }, [])

    const submitHandler = async (e: any) => {
        e.preventDefault()
        const body: any = {
            name: name,
            labid: labid,
            key: key,
            email: email,
            latitude: latitude,
            longitude: longitude,
            city: city,
            lab_address: labaddress,
            contact_number: contactnumber,
            public_key: publicKey,

        }
        await createLabs(body)
        history.push("/dashboard/pm/labDetails")
    }
    const handleSetHeaderImage = (e: React.ChangeEvent<{ files: any }>) => {
        setPackageImage(e.target.files[0])
    }
    
    return (
        <>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <div className={classes.contentHeader}>
                    <div className={classes.leftContentHeader}>
                        <h2>Create Labs Details</h2>
                    </div>
                    <div className={classes.rightContentHeader}></div>
                </div>
                <Paper elevation={5} className={classes.paper}>
                <form onSubmit={submitHandler}>
                        <Grid container spacing={1} style={{ margin: "1rem auto" }}>
                            <Grid container item xs={12} md={3} spacing={1} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <p>Key</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <TextField className="input"
                                        name="title"
                                        type="text"
                                        required
                                        placeholder="Enter Key"
                                        value={key}
                                        variant="outlined"
                                        style={{ margin: "0", width: "100%" }}
                                        onChange={(e) => setkey(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={3} spacing={1} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <p>Lab id</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <TextField className="input"
                                        name="title"
                                        type="text"
                                        required
                                        placeholder="Enter Value"
                                        value={labid}
                                        variant="outlined"
                                        style={{ margin: "0", width: "100%" }}
                                        onChange={(e) => setlabid(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={3} spacing={1} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <p>Lab Name</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <TextField className="input"
                                        name="title"
                                        type="text"
                                        placeholder="Enter Lab Name"
                                        value={nameError}
                                        variant="outlined"
                                        style={{ margin: "0", width: "100%" }}
                                        required
                                        // onChange={(e) => setError(e.target.value)}

                                   />
                                    <span style={{ color: "red", fontSize: "1.2rem" }}>{setError}</span>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={3} spacing={1} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <p>Public Key</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <TextField className="input"
                                        name="title"
                                        type="text"
                                        placeholder="Enter Public Key"
                                        value={publicKey}
                                        variant="outlined"
                                        style={{ margin: "0", width: "100%" }}
                                        onChange={(e) => setPublicKey(e.target.value)}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container item xs={12} md={3} spacing={1} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0", marginTop: "-30px" }}>
                                    <p>Lab Address</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <TextField className="input"
                                        name="title"
                                        type="text"
                                        placeholder="Enter Lab Adress"
                                        value={labaddress}
                                        variant="outlined"
                                        style={{ margin: "0", width: "100%" }}
                                        onChange={(e) => setlabaddress(e.target.value)}
                                        required
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={3} spacing={1} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0", marginTop: "-30px" }}>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <p>City</p>
                                    </Grid>
                                    <Autocomplete
                                        id="city"
                                        onChange={(event, newValue) => {
                                            if (newValue) {
                                                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                                setCity(obj.id)
                                            }
                                        }}
                                        options={cities}
                                        freeSolo
                                        blurOnSelect
                                        aria-required
                                        getOptionLabel={(option: any) => option.display_name}
                                        onInputChange={(event, newInputValue) => {
                                            clearTimeout(timer.current)
                                            timer.current = setTimeout(() => {
                                                getCities(`${newInputValue.replace(" ", "")}`)
                                            }, 1000)
                                            if (newInputValue.length === 0) {
                                                setCity(0)
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextField className="input"
                                                {...params}
                                                placeholder="City Name"
                                                variant="outlined"
                                                type="text"
                                                style={{ width: "100%", margin: "0", padding: "0" }}
                                                required
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={3} spacing={1} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0", marginTop: "-30px" }}>
                                    <p>Email</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <TextField className="input"
                                        name="title"
                                        type="text"
                                        placeholder="Enter Email id"
                                        value={email}
                                        variant="outlined"
                                        style={{ margin: "0", width: "100%" }}
                                        onChange={(e) => setemail(e.target.value)}
                                        required
                                    />
                                </Grid>
                            </Grid>
                           <Grid container item xs={12} md={3} spacing={1} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0", marginTop: "-30px" }}>
                                    <p>Contact Number</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <TextField className="input"
                                        name="title"
                                        type="number"
                                        required
                                        placeholder="Enter Contact Number"
                                        value={contactnumber}
                                        variant="outlined"
                                        style={{ margin: "0", width: "100%" }}
                                        onChange={(e) => setcontactnumber(e.target.value as String)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={3} spacing={1} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0", marginTop: "-30px" }}>
                                    <p>Latitude</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <TextField className="input"
                                        name="title"
                                        type="number"
                                        placeholder="99.99"
                                        value={latitude}
                                        variant="outlined"
                                        style={{ margin: "0", width: "100%" }}
                                        onChange={(e) => setlatitude(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={3} spacing={1} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0", marginTop: "-30px" }}>
                                    <p>Longitude</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <TextField className="input"
                                        name="title"
                                        type="number"
                                        placeholder="99.99"
                                        value={longitude}
                                        variant="outlined"
                                        style={{ margin: "0", width: "100%" }}
                                        onChange={(e) => setlongitude(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={3} spacing={1} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0", marginTop: "-30px" }}>
                                    <p>Image</p>
                                </Grid>

                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <input className="input" style={{ width: "100%", margin: "0", padding: "18.5px 14px", border: "1px solid rgba(0, 0, 0, 0.23)", borderRadius: "5px" }} type="file" name="file" id="file" multiple onChange={handleSetHeaderImage} />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={3} spacing={1} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <FormControlLabel
                                    control={<Checkbox checked={isActiveCheck} onChange={(e: any) => setIsActiveCheck(e.target.checked)} name="antoine" />}
                                    label="Is active"
                                />
                            </Grid>
                            <Grid container item xs={12} md={3} spacing={1} style={{ margin: "1rem 0", width: "100%", padding: "1rem", marginTop: "-50px", }}>
                                <Grid item xs={12} style={{ padding: "0" }}></Grid>
                                <Box m={3} p={2}>
                                    <Button
                                        color="secondary"
                                        variant="contained"
                                        fullWidth
                                        type="submit"
                                        // onClick={submitHandler}                                     
                                    >
                                        Create Lab

                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
           </main>
        </>
    );
};


const mapStateToProps = (state: any) => ({
    cities: state.ProductManagerReducer.cities,
    loading: state.ProductManagerReducer.loading,
    createlab: state.ProductManagerReducer.createlab,
})

export default connect(mapStateToProps, {
    getCities,
    createLabs
})(CreatePackagePage);