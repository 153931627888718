import React, { useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
    Grid,
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TableFooter,
    TablePagination,
} from '@material-ui/core';
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getBlogs, deleteBlog } from '../../actions/productMangerAction'
import { useEffect } from "react";
import Loader from "../loader";
import { DeleteForever, Edit } from "@material-ui/icons";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 0,
        },
        toolbar: {
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        },
        content: {
            width: "80%",
            flexGrow: 21,
        },
        paper: {
            padding: "1rem",
            width: "100%",
            borderRadius: "10px"
        },
        head: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "1rem",
            textAlign: "center"
        },
        heading: {
            fontWeight: "normal",
            fontSize: "1.2rem",
            margin: "0"
        },
        table: {
            margin: "auto",
            overflow: "auto"
        },
        image: {
            width: "100px",
            height: "auto",
        },
        imageSM: {
            width: "50px",
            height: "auto",
        },
        imgContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexWrap: "wrap"
        }
    })
);

interface Props {
    getBlogs: any;
    blogList: any;
    deleteBlog: any;
    loading: any;
}

const BlogsPage: React.FC<Props> = ({
    getBlogs,
    blogList,
    deleteBlog,
    loading,
}) => {
    const classes = useStyles();

    const [page, setPage] = useState(0)

    useEffect(() => {
        getBlogs()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        if (newPage > page) {
            let url = blogList.links && blogList.links.next.split("?")[1];
            getBlogs(url);
        } else if (newPage < page) {
            let url = blogList.links && blogList.links.previous.split("?")[1];
            getBlogs(url);
        }
        setPage(newPage as number);
    };

    return (
        <>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <Paper elevation={5} className={classes.paper}>
                    <Grid container spacing={3} style={{ margin: "1rem auto" }}>
                        <Grid item container direction="row" justify="flex-start" alignItems="center" xs={12} md={4}>
                            <h3 className={classes.heading}>Blogs</h3>
                        </Grid>
                        <Grid container item xs={12} md={8} direction="row" alignItems="center" justify="flex-end">
                            <Link to="/dashboard/pm/blogs/create">
                                <Button variant="contained" color="primary" style={{ marginLeft: "1rem" }}>Add Blog</Button>
                            </Link>
                        </Grid>
                    </Grid>
                    {loading ? <Loader /> : (
                        <TableContainer className={classes.table} component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">Thumbnail</TableCell>
                                        <TableCell align="center">Title</TableCell>
                                        <TableCell align="center">Content</TableCell>
                                        <TableCell align="center">Header Image</TableCell>
                                        <TableCell align="center">Summary</TableCell>
                                        <TableCell align="center">Images</TableCell>
                                        <TableCell align="center">Category</TableCell>
                                        <TableCell align="center">Tags</TableCell>
                                        <TableCell align="center">Edit</TableCell>
                                        <TableCell align="center">Delete</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {blogList.results && blogList.results.length > 0 && blogList.results.map((blog: any) => {
                                        return (
                                            <TableRow key={blog.id}>
                                                <TableCell><img className={classes.image} src={blog.thumbnail} alt="thumbnail" /></TableCell>
                                                <TableCell>{blog.title}</TableCell>
                                                <TableCell>{blog.content}</TableCell>
                                                <TableCell><img className={classes.image} src={blog.header_image} alt="thumbnail" /></TableCell>
                                                <TableCell>{blog.summary}</TableCell>
                                                <TableCell>
                                                    <div className={classes.imgContainer}>
                                                        {blog.images && blog.images.map((image: any, index: any) => {
                                                            return (
                                                                <img key={index} className={classes.imageSM} src={image} alt="thumbnail" />
                                                            )
                                                        })}
                                                    </div>
                                                </TableCell>
                                                <TableCell>
                                                    {blog.category && blog.category.map((cat: any, index: any) => {
                                                        return (
                                                            <p style={{ width: "100%", margin: "0.5rem auto" }} key={cat.id}>{cat.category}</p>
                                                        )
                                                    })}
                                                </TableCell>
                                                <TableCell>
                                                    {blog.tags && blog.tags.map((tag: any, index: any) => {
                                                        return (
                                                            <p style={{ width: "100%", margin: "0.5rem auto" }} key={tag.id}>{tag.tags}</p>
                                                        )
                                                    })}
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Link to={`/dashboard/pm/blog/${blog.slug}`}>
                                                        <Button
                                                            color="primary"
                                                            variant="contained"
                                                        >
                                                            <Edit />
                                                        </Button>
                                                    </Link>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Button
                                                        color="secondary"
                                                        variant="contained"
                                                        onClick={() => {
                                                            deleteBlog(blog.slug)
                                                            getBlogs()
                                                        }}
                                                    >
                                                        <DeleteForever />
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            rowsPerPageOptions={[]}
                                            colSpan={3}
                                            count={(blogList && blogList.count) || 0}
                                            rowsPerPage={blogList && blogList.page_size}
                                            page={page}
                                            onPageChange={handleChangePage}
                                        />
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                    )}
                </Paper>
            </main>
        </>
    );
};


const mapStateToProps = (state: any) => ({
    blogList: state.ProductManagerReducer.blogList,
    loading: state.ProductManagerReducer.loading,
})

export default connect(mapStateToProps, {
    getBlogs,
    deleteBlog,
})(BlogsPage);