import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from '@mui/material/Modal';
import Backdrop from "@material-ui/core/Backdrop";
import Button from '@mui/material/Button';
import Fade from "@material-ui/core/Fade";
import { TableContainer, Table, Chip, TableHead, TableBody, TableRow, TableCell, Paper, TextField, Grid, Select, MenuItem } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import { CreateBarcode } from '../../actions/PhleboAdmin'
let width = window.innerWidth;
let isMobile = width <= 500;


const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        marginTop: "2rem",
        padding: "0.5rem 2rem",
        width: "80%",
    },
    head: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "2rem",
    },
}));
interface Props {
CreateBarcode:any;
}
type ModalProps = {
    setOpenAddTube: Function;
    openAddTube: boolean;
    codeData: any;
    setCodeData: any;
    CreateBarcode:any;
    setOpenTubeModal:any;
    setTubeId:any;
    tubeId:any;
    setUpdateTube:any;
    id: any;
    packId:any;
    bookingIdentifier:any
    memberCheck:any
};

const AddBarcode: React.FC<ModalProps> = ({
    setOpenAddTube,
    openAddTube,
    codeData,
    setCodeData,
    CreateBarcode,
    setOpenTubeModal,
    setTubeId,
    setUpdateTube,
    tubeId,
    packId,
    bookingIdentifier,
    memberCheck,
    id,
}) => {
    const classes = useStyles();
    const timer = useRef<any>(0)
    const [barcode,setBarcode]=useState<any>("")
    const handleClose = () => {
        setOpenAddTube(false);
    };
    
    const submitForm = async (e: any) => {
        e.preventDefault()
        const tubeData =[{
            package: packId,
            tube: id,
            barcode: barcode,
            is_snr: false,
        }]
        const qrcodeArray = {
            code: tubeData,
        }
        await CreateBarcode(bookingIdentifier,qrcodeArray,memberCheck)
        setTubeId(tubeId)
        setUpdateTube(id)
        setOpenAddTube(false)
        setBarcode("")

    }
    const handleSubmitSNR =async(e:any) =>{
        e.preventDefault()
        const tubeData = [{
            package: packId,
            tube:id,
            is_snr: true,
            barcode:""
        }]
        const qrcodeArray = {
            code: tubeData,
        }
        await CreateBarcode(bookingIdentifier,qrcodeArray,memberCheck)
        setTubeId(tubeId)
        setUpdateTube(id)
        setOpenAddTube(false)
        setBarcode("")
    }
    
    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openAddTube}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openAddTube}>
                    <div className={classes.paper}>
                        <Paper elevation={5} className={classes.paper}>
                            <div className={classes.head}>

                                <h4
                                    style={{
                                        marginBottom: "1rem",
                                    }}
                                    id="transition-modal-title"
                                >
                                    TUBE CODE
                                </h4>
                                <CloseIcon onClick={() => setOpenAddTube(false)} />
                            </div>
                            <form onSubmit={submitForm}>
                                <Grid
                                    container
                                    direction="row"
                                    justify="space-between"
                                    alignItems="flex-end"
                                    spacing={3}
                                    style={{ margin: "1rem auto", background: "#eff" }}
                                >
                                    <Grid container item xs={12} md={6} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                        <Grid item xs={12} style={{ padding: "0" }}>
                                            <p>Code</p>
                                        </Grid>
                                        <Grid item xs={12} style={{ padding: "0" }}>
                                            <TextField
                                                name="bill_id"
                                                type="text"
                                                placeholder="Enter Barcode"
                                                value={barcode}
                                                className="input"
                                                variant="outlined"
                                                style={{ margin: "0", width: "100%" }}
                                                onChange={(e) => setBarcode(e.target.value as String)}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={12} md={6} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                        <Grid item xs={12} md={6} sm={6} style={{ padding: "0" }}>
                                            <Button
                                                color="primary"
                                                type="submit"
                                                variant="contained"
                                            >
                                                Add BarCode
                                            </Button>
                                            </Grid>
                                    </Grid>
                                </Grid>
                            </form>
                            <Grid container item xs={12} md={6} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                            <Grid item xs={12} md={6} sm={6} style={{ padding: "0" }}>
                                            <Button
                                                color="primary"
                                                type="submit"
                                                variant="contained"
                                                fullWidth
                                                onClick={(e:any)=>handleSubmitSNR(e)}
                                            >
                                                SNR
                                            </Button>
                                        </Grid>
                                        </Grid>
                        </Paper>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
};

const mapStateToProps = (state: any) => ({

});

export default connect(mapStateToProps, {
    CreateBarcode,
})(AddBarcode);
