import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@mui/material/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Button, MenuItem, Select, TextField, Grid } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import ScoreModal from "../Learning/Score/scoreModal"
import { getAssignmentQuestion, putAssignmentAnswerMaster, postAssignmentAnswerMaster, getAgentList } from "../../actions/loginActions"
import { ACTIONS } from '../../interfaces/actionTypes/loginTypes';
import "./questionModal.css"

let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: isMobile ? "400px" : "1200px",
  },
  head: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "1rem",
  },
}));

type ModalProps = {
  questionModalOpen: boolean;
  setQuestionModalOpen: Function;
  getAssignmentQuestion: any;
  assignmentQuestionMasterData: any;
  putAssignmentAnswerMaster: any;
  assignmentAnswerMasterPutData: any;
  postAssignmentAnswerMaster: any;
  assignmentAnswerMasterPostData: any;
  getAgentList: any;
  agentsList: any;
  moduleId: any;
  moduleQuestion: any;
  minimumScore: any;
  fileUrl: any;
  setTimeInHrModule: any;
  setModuleId: any;
  setTimeInMinModule: any;
  // setOpen: any;
};

const QuestionModal: React.FC<ModalProps> = ({
  questionModalOpen,
  setQuestionModalOpen,
  getAssignmentQuestion,
  assignmentQuestionMasterData,
  putAssignmentAnswerMaster,
  assignmentAnswerMasterPutData,
  postAssignmentAnswerMaster,
  assignmentAnswerMasterPostData,
  getAgentList,
  agentsList,
  moduleId,
  moduleQuestion,
  minimumScore,
  fileUrl,
  setTimeInHrModule,
  setModuleId,
  setTimeInMinModule,
  // setOpen,
}) => {
  const classes = useStyles();

  const history = useHistory();
  let dispatch = useDispatch();

  const [query, setQuery] = useState<string>("");
  const [disposition, setDisposition] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [status, setStatus] = useState<string>("pending");
  const [reciveRemark, setReciveRemark] = React.useState<any>("");
  const [errorMessage, setErrorMessage] = useState<any>("");
  const [checkedWay, setCheckedWay] = useState<any>("");
  const [checkedState, setCheckedState] = useState(
    new Array(4).fill(false)
  );
  const [scoreModalOpen, setScoreModalOpen] = useState(false);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [countOfAnswers, setCountOfAnswers] = useState(0);
  const [answerCheck, setAnswerCheck] = useState<any>([]);
  const [scoreArray, setScoreArray] = useState<any>([]);
  const [questionAttempted, setQuestionAttempted] = useState<any>([]);
  const [ attempted, setAttempted] = useState<any>();
  const [shuffled, setShuffled] = useState<any>([]);

  const handleOnChange = (position: string | number) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );
    setCheckedState(updatedCheckedState);
  };

  const handleClose = () => {
    // setQuestionModalOpen(false);
  }

  useEffect( () => {
    getAgentList(`self=true`);
  }, [])

  useEffect( () => {
    let x = [0, 0, 0, 0];
    if (moduleQuestion[questionIndex]?.assignment_answer_question[3]?.assignment_user % 2 === 1) {
      x[0] = 1;
    }
    if (moduleQuestion[questionIndex]?.assignment_answer_question[2]?.assignment_user % 2 === 1) {
      x[1] = 1;
    }
    if (moduleQuestion[questionIndex]?.assignment_answer_question[1]?.assignment_user % 2 === 1) {
      x[2] = 1;
    }
    if (moduleQuestion[questionIndex]?.assignment_answer_question[0]?.assignment_user % 2 === 1) {
      x[3] = 1;
    }
    const sum = x.reduce((partialSum: any, a: any) => partialSum + a, 0);
    setCountOfAnswers(sum);
  }, [questionIndex])

  useEffect( () => {
    if (moduleQuestion[questionIndex]?.attempted === null) {
      setAttempted(-1);
    } else {
      setAttempted(moduleQuestion[questionIndex]?.attempted);
    }
  }, [questionIndex])

  useEffect( () => {
    if (questionAttempted?.length === questionIndex + 1 && moduleQuestion[questionIndex]?.attempted !== null) {
      setQuestionAttempted([...questionAttempted, moduleQuestion[questionIndex]?.attempted])
    } else if (assignmentAnswerMasterPostData?.length !== 0) {
      setQuestionAttempted([...questionAttempted, assignmentAnswerMasterPostData?.id])
      dispatch({ type: ACTIONS.POST_ASSIGNMENT_ANSWER_MASTER, payload: [] });
    }
  }, [questionIndex, assignmentAnswerMasterPostData])

  let unshuffled = [0, 1, 2, 3];

  useEffect(()=> {
    setShuffled(unshuffled
      .map(value => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value))
  }, [questionIndex])

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={questionModalOpen}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={questionModalOpen}>
          <div className={classes.paper}>
            <div className={classes.head}>
              <h4
                style={{
                  fontSize: "16px",
                }}
                id="transition-modal-title"
              >
                {`Question ${questionIndex + 1} : ${moduleQuestion[questionIndex]?.name}`}
              </h4>
            </div>
            <div 
                style={{
                  fontSize: "16px",
                }}
            >
                {(shuffled[0] === 0) ? 
                  <>
                    { (moduleQuestion[questionIndex]?.assignment_answer_question[0]?.name === "") ? "" :
                      <div>
                          <input
                              type={(countOfAnswers === 0 || countOfAnswers === 1) ? "radio" : "checkbox"}
                              id={`custom-checkbox-${3}`}
                              name={"name"}
                              value={3}
                              checked={checkedState[3]}
                              onChange={() => handleOnChange(3)}
                          />
                          <span style={{ paddingLeft: "20px" }}>{moduleQuestion[questionIndex]?.assignment_answer_question[0]?.name}</span>
                      </div>
                    }
                    { (moduleQuestion[questionIndex]?.assignment_answer_question[3]?.name === "") ? "" :
                      <div>
                        <input
                            type={(countOfAnswers === 0 || countOfAnswers === 1) ? "radio" : "checkbox"}
                            id={`custom-checkbox-${0}`}
                            name={"name"}
                            value={0}
                            checked={checkedState[0]}
                            onChange={() => handleOnChange(0)}
                        />
                        <span style={{ paddingLeft: "20px" }}>{moduleQuestion[questionIndex]?.assignment_answer_question[3]?.name}</span>
                      </div>
                    }
                    { (moduleQuestion[questionIndex]?.assignment_answer_question[1]?.name === "") ? "" :
                      <div>
                          <input
                              type={(countOfAnswers === 0 || countOfAnswers === 1) ? "radio" : "checkbox"}
                              id={`custom-checkbox-${2}`}
                              name={"name"}
                              value={2}
                              checked={checkedState[2]}
                              onChange={() => handleOnChange(2)}
                          />
                          <span style={{ paddingLeft: "20px" }}>{moduleQuestion[questionIndex]?.assignment_answer_question[1]?.name}</span>
                      </div>
                    }
                    { (moduleQuestion[questionIndex]?.assignment_answer_question[2]?.name === "") ? "" :
                      <div>
                          <input
                              type={(countOfAnswers === 0 || countOfAnswers === 1) ? "radio" : "checkbox"}
                              id={`custom-checkbox-${1}`}
                              name={"name"}
                              value={1}
                              checked={checkedState[1]}
                              onChange={() => handleOnChange(1)}
                          />
                          <span style={{ paddingLeft: "20px" }}>{moduleQuestion[questionIndex]?.assignment_answer_question[2]?.name}</span>
                      </div>
                    }
                  </> : (
                    (shuffled[0] === 1) ? 
                  <>
                    { (moduleQuestion[questionIndex]?.assignment_answer_question[1]?.name === "") ? "" :
                      <div>
                          <input
                              type={(countOfAnswers === 0 || countOfAnswers === 1) ? "radio" : "checkbox"}
                              id={`custom-checkbox-${2}`}
                              name={"name"}
                              value={2}
                              checked={checkedState[2]}
                              onChange={() => handleOnChange(2)}
                          />
                          <span style={{ paddingLeft: "20px" }}>{moduleQuestion[questionIndex]?.assignment_answer_question[1]?.name}</span>
                      </div>
                    }
                    { (moduleQuestion[questionIndex]?.assignment_answer_question[3]?.name === "") ? "" :
                      <div>
                        <input
                            type={(countOfAnswers === 0 || countOfAnswers === 1) ? "radio" : "checkbox"}
                            id={`custom-checkbox-${0}`}
                            name={"name"}
                            value={0}
                            checked={checkedState[0]}
                            onChange={() => handleOnChange(0)}
                        />
                        <span style={{ paddingLeft: "20px" }}>{moduleQuestion[questionIndex]?.assignment_answer_question[3]?.name}</span>
                      </div>
                    }
                    { (moduleQuestion[questionIndex]?.assignment_answer_question[0]?.name === "") ? "" :
                      <div>
                          <input
                              type={(countOfAnswers === 0 || countOfAnswers === 1) ? "radio" : "checkbox"}
                              id={`custom-checkbox-${3}`}
                              name={"name"}
                              value={3}
                              checked={checkedState[3]}
                              onChange={() => handleOnChange(3)}
                          />
                          <span style={{ paddingLeft: "20px" }}>{moduleQuestion[questionIndex]?.assignment_answer_question[0]?.name}</span>
                      </div>
                    }
                    { (moduleQuestion[questionIndex]?.assignment_answer_question[2]?.name === "") ? "" :
                      <div>
                          <input
                              type={(countOfAnswers === 0 || countOfAnswers === 1) ? "radio" : "checkbox"}
                              id={`custom-checkbox-${1}`}
                              name={"name"}
                              value={1}
                              checked={checkedState[1]}
                              onChange={() => handleOnChange(1)}
                          />
                          <span style={{ paddingLeft: "20px" }}>{moduleQuestion[questionIndex]?.assignment_answer_question[2]?.name}</span>
                      </div>
                    }
                  </> : (
                    (shuffled[0] === 2) ? 
                  <>
                    { (moduleQuestion[questionIndex]?.assignment_answer_question[2]?.name === "") ? "" :
                      <div>
                          <input
                              type={(countOfAnswers === 0 || countOfAnswers === 1) ? "radio" : "checkbox"}
                              id={`custom-checkbox-${1}`}
                              name={"name"}
                              value={1}
                              checked={checkedState[1]}
                              onChange={() => handleOnChange(1)}
                          />
                          <span style={{ paddingLeft: "20px" }}>{moduleQuestion[questionIndex]?.assignment_answer_question[2]?.name}</span>
                      </div>
                    }
                    { (moduleQuestion[questionIndex]?.assignment_answer_question[1]?.name === "") ? "" :
                      <div>
                          <input
                              type={(countOfAnswers === 0 || countOfAnswers === 1) ? "radio" : "checkbox"}
                              id={`custom-checkbox-${2}`}
                              name={"name"}
                              value={2}
                              checked={checkedState[2]}
                              onChange={() => handleOnChange(2)}
                          />
                          <span style={{ paddingLeft: "20px" }}>{moduleQuestion[questionIndex]?.assignment_answer_question[1]?.name}</span>
                      </div>
                    }
                    { (moduleQuestion[questionIndex]?.assignment_answer_question[3]?.name === "") ? "" :
                      <div>
                        <input
                            type={(countOfAnswers === 0 || countOfAnswers === 1) ? "radio" : "checkbox"}
                            id={`custom-checkbox-${0}`}
                            name={"name"}
                            value={0}
                            checked={checkedState[0]}
                            onChange={() => handleOnChange(0)}
                        />
                        <span style={{ paddingLeft: "20px" }}>{moduleQuestion[questionIndex]?.assignment_answer_question[3]?.name}</span>
                      </div>
                    }
                    { (moduleQuestion[questionIndex]?.assignment_answer_question[0]?.name === "") ? "" :
                      <div>
                          <input
                              type={(countOfAnswers === 0 || countOfAnswers === 1) ? "radio" : "checkbox"}
                              id={`custom-checkbox-${3}`}
                              name={"name"}
                              value={3}
                              checked={checkedState[3]}
                              onChange={() => handleOnChange(3)}
                          />
                          <span style={{ paddingLeft: "20px" }}>{moduleQuestion[questionIndex]?.assignment_answer_question[0]?.name}</span>
                      </div>
                    }
                  </> :
                  <>
                    { (moduleQuestion[questionIndex]?.assignment_answer_question[3]?.name === "") ? "" :
                      <div>
                        <input
                            type={(countOfAnswers === 0 || countOfAnswers === 1) ? "radio" : "checkbox"}
                            id={`custom-checkbox-${0}`}
                            name={"name"}
                            value={0}
                            checked={checkedState[0]}
                            onChange={() => handleOnChange(0)}
                        />
                        <span style={{ paddingLeft: "20px" }}>{moduleQuestion[questionIndex]?.assignment_answer_question[3]?.name}</span>
                      </div>
                    }
                    { (moduleQuestion[questionIndex]?.assignment_answer_question[2]?.name === "") ? "" :
                      <div>
                          <input
                              type={(countOfAnswers === 0 || countOfAnswers === 1) ? "radio" : "checkbox"}
                              id={`custom-checkbox-${1}`}
                              name={"name"}
                              value={1}
                              checked={checkedState[1]}
                              onChange={() => handleOnChange(1)}
                          />
                          <span style={{ paddingLeft: "20px" }}>{moduleQuestion[questionIndex]?.assignment_answer_question[2]?.name}</span>
                      </div>
                    }
                    { (moduleQuestion[questionIndex]?.assignment_answer_question[1]?.name === "") ? "" :
                      <div>
                          <input
                              type={(countOfAnswers === 0 || countOfAnswers === 1) ? "radio" : "checkbox"}
                              id={`custom-checkbox-${2}`}
                              name={"name"}
                              value={2}
                              checked={checkedState[2]}
                              onChange={() => handleOnChange(2)}
                          />
                          <span style={{ paddingLeft: "20px" }}>{moduleQuestion[questionIndex]?.assignment_answer_question[1]?.name}</span>
                      </div>
                    }
                    { (moduleQuestion[questionIndex]?.assignment_answer_question[0]?.name === "") ? "" :
                      <div>
                          <input
                              type={(countOfAnswers === 0 || countOfAnswers === 1) ? "radio" : "checkbox"}
                              id={`custom-checkbox-${3}`}
                              name={"name"}
                              value={3}
                              checked={checkedState[3]}
                              onChange={() => handleOnChange(3)}
                          />
                          <span style={{ paddingLeft: "20px" }}>{moduleQuestion[questionIndex]?.assignment_answer_question[0]?.name}</span>
                      </div>
                    }
                  </>
                  )
                  )}
            </div>
            <div style={{ display: "flex", justifyContent: "space-between", width: "40%", marginLeft: "30%" }}>
                <Button style={{
                    backgroundColor: "white",
                    color: "rgb(12 12 12 / 93%)",
                    borderRadius: "8px",
                    width: "200px",
                    height: "40px",
                    margin: "25px 15px 0 0px",
                    border: "1px solid rgb(12 12 12 / 93%)",
                }}
                size="small"
                disabled={questionIndex === 0}
                onClick={() => {
                  setCheckedState(answerCheck[answerCheck?.length - 1].checkedState);
                  setAnswerCheck((previousArr: string | any[]) => (previousArr.slice(0, -1)));
                  setScoreArray((previousArr: string | any[]) => (previousArr.slice(0, -1)));
                  setQuestionIndex(questionIndex - 1);
                  setCountOfAnswers(0);
                }}
                >
                    <p style={{ margin: "0", fontSize: "15px" }}>Back</p>
                </Button>
                {
                  questionIndex + 1 === moduleQuestion?.length ?
                  <Button style={{
                      backgroundColor: "#c70e0ef7",
                      color: "white",
                      borderRadius: "8px",
                      width: "200px",
                      height: "40px",
                      margin: "25px 15px 0 0px",
                      border: "1px solid",
                  }}
                  size="small"
                  onClick={() => {
                    let scoreValue = 1;
                    let arrayForAnswer: any = [];
                    if (checkedState[0]) {
                      arrayForAnswer.push(moduleQuestion[questionIndex]?.assignment_answer_question[3]?.id)
                      if (moduleQuestion[questionIndex]?.assignment_answer_question[3]?.assignment_user % 2 === 0) {
                        scoreValue = 0;
                      }
                    }
                    if (checkedState[1]) {
                      arrayForAnswer.push(moduleQuestion[questionIndex]?.assignment_answer_question[2]?.id)
                      if (moduleQuestion[questionIndex]?.assignment_answer_question[2]?.assignment_user % 2 === 0) {
                        scoreValue = 0;
                      }
                    }
                    if (checkedState[2]) {
                      arrayForAnswer.push(moduleQuestion[questionIndex]?.assignment_answer_question[1]?.id)
                      if (moduleQuestion[questionIndex]?.assignment_answer_question[1]?.assignment_user % 2 === 0) {
                        scoreValue = 0;
                      }
                    }
                    if (checkedState[3]) {
                      arrayForAnswer.push(moduleQuestion[questionIndex]?.assignment_answer_question[0]?.id)
                      if (moduleQuestion[questionIndex]?.assignment_answer_question[0]?.assignment_user % 2 === 0) {
                        scoreValue = 0;
                      }
                    }
                    if (!checkedState[0]) {
                      if (moduleQuestion[questionIndex]?.assignment_answer_question[3]?.assignment_user % 2 === 1) {
                        scoreValue = 0;
                      }
                    }
                    if (!checkedState[1]) {
                      if (moduleQuestion[questionIndex]?.assignment_answer_question[2]?.assignment_user % 2 === 1) {
                        scoreValue = 0;
                      }
                    }
                    if (!checkedState[2]) {
                      if (moduleQuestion[questionIndex]?.assignment_answer_question[1]?.assignment_user % 2 === 1) {
                        scoreValue = 0;
                      }
                    }
                    if (!checkedState[3]) {
                      if (moduleQuestion[questionIndex]?.assignment_answer_question[0]?.assignment_user % 2 === 1) {
                        scoreValue = 0;
                      }
                    }
                    let data = {
                      answer: arrayForAnswer,
                      question: moduleQuestion[questionIndex]?.id,
                      score: scoreValue * ( 100 / moduleQuestion?.length),
                      user: agentsList?.results[0]?.id,
                    };
                    setScoreArray([...scoreArray, data.score]);
                    setAnswerCheck([...answerCheck, {checkedState}]);
                    setCheckedState(new Array(4).fill(false));
                    let y = [0, 0, 0, 0];
                    if (moduleQuestion[0]?.assignment_answer_question[3]?.assignment_user % 2 === 1) {
                      y[0] = 1;
                    }
                    if (moduleQuestion[0]?.assignment_answer_question[2]?.assignment_user % 2 === 1) {
                      y[1] = 1;
                    }
                    if (moduleQuestion[0]?.assignment_answer_question[1]?.assignment_user % 2 === 1) {
                      y[2] = 1;
                    }
                    if (moduleQuestion[0]?.assignment_answer_question[0]?.assignment_user % 2 === 1) {
                      y[3] = 1;
                    }
                    const sum = y.reduce((partialSum: any, a: any) => partialSum + a, 0);
                    setCountOfAnswers(sum);
                    // setCountOfAnswers(0);
                    // setQuestionIndex(questionIndex + 1);
                    // setQuestionModalOpen(false);
                    setScoreModalOpen(true);
                    // if (assignmentAnswerMasterPostData?.length === 0) {
                    //   postAssignmentAnswerMaster(data);
                    // } else {
                    //   putAssignmentAnswerMaster(data, assignmentAnswerMasterPostData?.id);
                    // }
                    if (questionAttempted?.length >= questionIndex + 1) {
                      putAssignmentAnswerMaster(data, questionAttempted[questionIndex]);
                    } else {
                      postAssignmentAnswerMaster(data);
                    }
                  }}
                  >
                      <p style={{ margin: "0", fontSize: "15px" }}>Submit</p>
                  </Button>
                  :
                  <Button style={{
                      backgroundColor: "#c70e0ef7",
                      color: "white",
                      borderRadius: "8px",
                      width: "200px",
                      height: "40px",
                      margin: "25px 15px 0 0px",
                      border: "1px solid",
                  }}
                  size="small"
                  onClick={() => {
                    // setScoreModalOpen(true);
                    let scoreValue = 1;
                    let arrayForAnswer: any = [];
                    if (checkedState[0]) {
                      arrayForAnswer.push(moduleQuestion[questionIndex]?.assignment_answer_question[3]?.id)
                      if (moduleQuestion[questionIndex]?.assignment_answer_question[3]?.assignment_user % 2 === 0) {
                        scoreValue = 0;
                      }
                    }
                    if (checkedState[1]) {
                      arrayForAnswer.push(moduleQuestion[questionIndex]?.assignment_answer_question[2]?.id)
                      if (moduleQuestion[questionIndex]?.assignment_answer_question[2]?.assignment_user % 2 === 0) {
                        scoreValue = 0;
                      }
                    }
                    if (checkedState[2]) {
                      arrayForAnswer.push(moduleQuestion[questionIndex]?.assignment_answer_question[1]?.id)
                      if (moduleQuestion[questionIndex]?.assignment_answer_question[1]?.assignment_user % 2 === 0) {
                        scoreValue = 0;
                      }
                    }
                    if (checkedState[3]) {
                      arrayForAnswer.push(moduleQuestion[questionIndex]?.assignment_answer_question[0]?.id)
                      if (moduleQuestion[questionIndex]?.assignment_answer_question[0]?.assignment_user % 2 === 0) {
                        scoreValue = 0;
                      }
                    }
                    if (!checkedState[0]) {
                      if (moduleQuestion[questionIndex]?.assignment_answer_question[3]?.assignment_user % 2 === 1) {
                        scoreValue = 0;
                      }
                    }
                    if (!checkedState[1]) {
                      if (moduleQuestion[questionIndex]?.assignment_answer_question[2]?.assignment_user % 2 === 1) {
                        scoreValue = 0;
                      }
                    }
                    if (!checkedState[2]) {
                      if (moduleQuestion[questionIndex]?.assignment_answer_question[1]?.assignment_user % 2 === 1) {
                        scoreValue = 0;
                      }
                    }
                    if (!checkedState[3]) {
                      if (moduleQuestion[questionIndex]?.assignment_answer_question[0]?.assignment_user % 2 === 1) {
                        scoreValue = 0;
                      }
                    }
                    let data = {
                      answer: arrayForAnswer,
                      question: moduleQuestion[questionIndex]?.id,
                      score: scoreValue * ( 100 / moduleQuestion?.length),
                      user: agentsList?.results[0]?.id,
                    };
                    setScoreArray([...scoreArray, data.score]);
                    setAnswerCheck([...answerCheck, {checkedState}]);
                    setCheckedState(new Array(4).fill(false));
                    setQuestionIndex(questionIndex + 1);
                    setCountOfAnswers(0);
                    if (questionAttempted?.length >= questionIndex + 1) {
                      putAssignmentAnswerMaster(data, questionAttempted[questionIndex]);
                    } else {
                      postAssignmentAnswerMaster(data);
                    }
                    // if (assignmentAnswerMasterPostData?.length === 0) {
                    //   postAssignmentAnswerMaster(data);
                    // } else {
                    //   putAssignmentAnswerMaster(data, assignmentAnswerMasterPostData?.id);
                    // }
                  }}
                  >
                      <p style={{ margin: "0", fontSize: "15px" }}>Next</p>
                  </Button>
                }
            </div>
          </div>
        </Fade>
      </Modal>
      { scoreModalOpen && 
        <ScoreModal
            open={scoreModalOpen}
            setOpen={setScoreModalOpen}
            moduleQuestion={moduleQuestion}
            answerCheck={answerCheck}
            scoreArray={scoreArray}
            minimumScore={minimumScore}
            fileUrl={fileUrl}
            setQuestionIndex={setQuestionIndex}
            setScoreArray={setScoreArray}
            setTimeInHrModule={setTimeInHrModule}
            setModuleId={setModuleId}
            setTimeInMinModule={setTimeInMinModule}
        />
      }
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  assignmentQuestionMasterData: state.loginReducer.assignmentQuestionMasterData,
  assignmentAnswerMasterPutData: state.loginReducer.assignmentAnswerMasterPutData,
  assignmentAnswerMasterPostData: state.loginReducer.assignmentAnswerMasterPostData,
  agentsList: state.loginReducer.agentsList,
});

export default connect(mapStateToProps, {
  getAssignmentQuestion,
  putAssignmentAnswerMaster,
  postAssignmentAnswerMaster,
  getAgentList,
})(QuestionModal);