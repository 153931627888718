import React, { useState, useEffect } from "react";
import { Chip, Grid, Typography, TextField } from "@material-ui/core";
import { connect } from "react-redux";
import Divider from "@material-ui/core/Divider";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import { getBookingOverview, getRiderData } from "../actions/LogisticsPanelAction";
import BarChartTwoToneIcon from "@mui/icons-material/BarChartTwoTone";
import Avatar from "@mui/material/Avatar";
import moment from "moment";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        card: {
            height: "100%",
            padding: "20px",
        },
        cardContent: {
            textAlign: "center",
        },
        center: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        text: { color: "black" },
    })
);

interface Props {
    getBookingOverview: any;
    bookingoverview: any;
    getRiderData: any;
    riderData: any;
}
const Dashboard: React.FC<Props> = ({
    getBookingOverview,
    bookingoverview,
    getRiderData,
    riderData,
}) => {
    const classes = useStyles();
    useEffect(() => {
        getBookingOverview("");
        getRiderData();
    }, []);

    return (
        <div style={{ width: "90%", margin: "60px auto" }}>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <h3>Route Tracking</h3>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12} lg={3} sm={6}>
                    <Card className={classes.card}>
                        <Grid container>
                            <Grid item sm={3}>
                                <Avatar
                                    sx={{ bgcolor: "#ffeff5", marginTop: "8px" }}
                                    aria-label="recipe"
                                >
                                    <BarChartTwoToneIcon style={{ color: "#fa4d83" }} />
                                </Avatar>
                            </Grid>
                            <Grid item sm={9}>
                                <h5>{bookingoverview?.total_batch}</h5>
                                <span style={{ color: "grey" }}>Total Rider</span>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                <Grid item xs={12} lg={3} sm={6}>
                    <Card className={classes.card}>
                        <Grid container>
                            <Grid item sm={3}>
                                <Avatar
                                    sx={{ bgcolor: "#ffeff5", marginTop: "8px" }}
                                    aria-label="recipe"
                                >
                                    <BarChartTwoToneIcon style={{ color: "#fa4d83" }} />
                                </Avatar>
                            </Grid>
                            <Grid item sm={9}>
                                <h5>{bookingoverview.pending_batch}</h5>
                                <span style={{ color: "grey" }}>Assign</span>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                <Grid item xs={12} lg={3} sm={6}>
                    <Card className={classes.card}>
                        <Grid container>
                            <Grid item sm={3}>
                                <Avatar
                                    sx={{ bgcolor: "#ffeff5", marginTop: "8px" }}
                                    aria-label="recipe"
                                >
                                    <BarChartTwoToneIcon style={{ color: "#fa4d83" }} />
                                </Avatar>
                            </Grid>
                            <Grid item sm={9}>
                                <h5>{bookingoverview.lab_received_batch}</h5>
                                <span style={{ color: "grey" }}>Unassign</span>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <div style={{ display: "flex", width: "100%", marginTop: 20 }}>
                    <hr style={{ width: "100%", marginTop: 20 }} />
                </div>
            </Grid>
            <Stepper>
                <Step>
                    <StepLabel>Start Point</StepLabel>
                </Step>
                <Step>
                    <StepLabel>Stops</StepLabel>
                </Step>
                <Step>
                    <StepLabel>End Destination</StepLabel>
                </Step>
            </Stepper>
        </div>
    );
};

const mapStateToProps = (state: any) => ({
    bookingoverview: state.LogisticsReducer.bookingoverview,
    riderData: state.LogisticsReducer.riderData
});

export default connect(mapStateToProps, {
    getBookingOverview,
    getRiderData,
})(Dashboard);
