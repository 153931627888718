import React, { useState } from "react";
import { useRef } from "react";
import {
  makeStyles,
  Theme,
  createStyles,
  withStyles,
} from "@material-ui/core/styles";
import Button from '@mui/material/Button';
import { connect } from "react-redux";
import { Paper, TextField, Grid, Chip, Card } from "@material-ui/core";
import { useEffect } from "react";
import { getRoutes, getBookingOverview, getRiderList, getCities, getCenterInfo, resetGetRoutes } from "../actions/LogisticsPanelAction";
import { useHistory } from "react-router-dom";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import Loader from "../../components/loader/index";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Avatar from "@mui/material/Avatar";
import { TableFooter, TablePagination } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import BarChartTwoToneIcon from "@mui/icons-material/BarChartTwoTone";
import { generateStopsFilter } from "../../helpers/generateUrl";
import moment from "moment";
import "./dashboard.css";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    table: {
      minWidth: 700,
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      marginTop: "2rem",
      padding: "0.5rem 2rem 2rem 2rem",
      width: "100%",
    },
    contentHeader: {
      width: "100%",
      padding: "0px 10px 40px 10px",
      minHeight: "64px",
    },
    leftContentHeader: {
      width: "50%",
      float: "left",
    },
    rightContentHeader: {
      width: "50%",
      float: "right",
      textAlign: "right",
      paddingRight: "20px",
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    head: {
      textAlign: "center",
      fontWeight: "normal",
      marginBottom: "2rem",
    },
    slotContainer: {
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "center",
    },
    slot: {
      width: "150px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      margin: "0.5rem",
    },
    hoveItem: {
      cursor: "pointer",
    },
    selectedSlot: {
      width: "150px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      background: "linear-gradient(60deg, cyan, #00dfff, cyan)",
      cursor: "pointer",
      margin: "0.5rem",
    },
    chips: {
      display: "flex",
    },
    chip: {
      margin: 2,
      background: "linear-gradient(60deg, cyan, #00cfff, cyan)",
      maxWidth: "130px",
    },
    input: {
      padding: "1px",
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
    card: {
      height: "100%",
      padding: "20px",
    },
    cardContent: {
      textAlign: "center",
    },
    center: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    text: { color: "black" },
  })
);

const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);
interface Props {
  getRoutes: any;
  routesData: any;
  getBookingOverview: any;
  bookingoverview: any;
  getRiderList: any;
  riderList: any;
  getCities: any;
  cities: any;
  loadingroutes: any;
  loading: any;
  getCenterInfo: any;
  centerInfo: any;
  resetGetRoutes: any;
}
const RoutePlan: React.FC<Props> = ({
  getRoutes,
  routesData,
  getBookingOverview,
  bookingoverview,
  getRiderList,
  riderList,
  getCities,
  cities,
  loading,
  loadingroutes,
  getCenterInfo,
  centerInfo,
  resetGetRoutes,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const timer = useRef<any>(0);
  const [routeName, setRouteName] = useState<any>("");
  const [page, setPage] = useState(0);
  const [rider, setRider] = useState<any>("");
  const [riderDef, setRiderDef] = useState<any>("");
  const [center, setCentre] = useState<any>("");
  const [cityId, setCityId] = useState<any>("");
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url = routesData.links && routesData.links.next.split("?")[1];
      getRoutes(url ? `?${url}` : "");
    } else if (newPage < page) {
      let url = routesData.links && routesData.links.previous.split("?")[1];
      getRoutes(url ? `?${url}` : "");
    }
    setPage(newPage as number);
  };
  useEffect(() => {
    getRoutes();
    getBookingOverview("");
    getRiderList(`?usergroup=Rider`);
    getCities();
    getCenterInfo();
  }, []);
  const filterStopsData = (e: any) => {
    const body: any = {
      rider,
      name: routeName,
      city: cityId,
      cc: center,

    };
    const url = generateStopsFilter(body).substring(2);
    getRoutes(`?${url}`);

    setPage(0);
  };
  const handleSearch = async (e: any) => {
    e.preventDefault();
    // getRoutes(`?name=${routeName}`);
  };

  const editData = (getId: any) => {
    let obj = {
      id: getId,
      action: "editA"
    }
    history.push({
      pathname: `/dashboard/lp/route_plan_action/${getId}`,
      state: obj
    })
  }

  return (
    <div style={{ width: "90%", margin: "60px auto" }}>
      <Grid container spacing={3}>
        <Grid item xs={10}>
          <h4 style={{ fontWeight: "bold" }}>RIDER ROUTES</h4>
        </Grid>
        <Grid item xs={2}>
          <Button
            color="primary"
            variant="contained"
            onClick={() =>
              history.push(`/dashboard/lp/route_plan_action/create`)
            }
          >
            Create Route
          </Button>
        </Grid>
        <form style={{ width: "100%" }} onSubmit={handleSearch}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={3} sm={6}>
              <Card className={classes.card}>
                <Grid container>
                  <Grid item sm={3}>
                    <Avatar
                      sx={{ bgcolor: "#ffeff5", marginTop: "8px" }}
                      aria-label="recipe"
                    >
                      <BarChartTwoToneIcon style={{ color: "#fa4d83" }} />
                    </Avatar>
                  </Grid>
                  <Grid item sm={9}>
                    <h5>{bookingoverview?.total_rider}</h5>
                    <span style={{ color: "grey" }}>Total Rider</span>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12} lg={3} sm={6}>
              <Card className={classes.card}>
                <Grid container>
                  <Grid item sm={3}>
                    <Avatar
                      sx={{ bgcolor: "#ffeff5", marginTop: "8px" }}
                      aria-label="recipe"
                    >
                      <BarChartTwoToneIcon style={{ color: "#fa4d83" }} />
                    </Avatar>
                  </Grid>
                  <Grid item sm={9}>
                    <h5>{bookingoverview.total_assigned_rider}</h5>
                    <span style={{ color: "grey" }}>Assign</span>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12} lg={3} sm={6}>
              <Card className={classes.card}>
                <Grid container>
                  <Grid item sm={3}>
                    <Avatar
                      sx={{ bgcolor: "#ffeff5", marginTop: "8px" }}
                      aria-label="recipe"
                    >
                      <BarChartTwoToneIcon style={{ color: "#fa4d83" }} />
                    </Avatar>
                  </Grid>
                  <Grid item sm={9}>
                    <h5>{bookingoverview.total_unassigned_rider}</h5>
                    <span style={{ color: "grey" }}>Unassigned</span>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12} lg={3} sm={6}>
              <Card className={classes.card}>
                <Grid container>
                  <Grid item sm={3}>
                    <Avatar
                      sx={{ bgcolor: "#ffeff5", marginTop: "8px" }}
                      aria-label="recipe"
                    >
                      <BarChartTwoToneIcon style={{ color: "#fa4d83" }} />
                    </Avatar>
                  </Grid>
                  <Grid item sm={9}>
                    <h5>{bookingoverview?.total_routes}</h5>
                    <span style={{ color: "grey" }}>Total Routes</span>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
          <Grid item xs={12}>
          </Grid>
          <Grid container spacing={3} style={{ marginTop: 10 }}>
            <Grid item xs={12} sm={6} md={2}>
              <TextField
                label="Route Name"
                variant="outlined"
                className="input"
                fullWidth
                value={routeName}
                onChange={(e) => setRouteName(e.target.value)}
              />
            </Grid>
            {/* <Grid item xs={12} sm={6} md={2}>
              <TextField
                label="Route End Point"
                variant="outlined"
                className="input"
                fullWidth
              value={endRouteName}
              onChange={(e) => setendRouteName(e.target.value)}
              />
            </Grid> */}
            <Grid item xs={12} sm={4} md={2}>
              <Autocomplete
                id="city"
                onChange={(event, newValue) => {
                  if (newValue) {
                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                    setCityId(obj.id)
                  }
                }}
                options={cities}
                freeSolo
                blurOnSelect
                aria-required
                getOptionLabel={(option: any) => option.name}
                onInputChange={(event, newInputValue) => {
                  clearTimeout(timer.current)
                  timer.current = setTimeout(() => {
                    getCities(`${newInputValue}`)
                  }, 1000)
                  if (newInputValue.length === 0) {
                    setCityId("")
                  }
                }}
                renderInput={(params) => (
                  <TextField className="input"
                    {...params}
                    label="City"
                    variant="outlined"
                    style={{ width: "100%", margin: "0", padding: "0" }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <Autocomplete
                id="rider"
                key={riderDef}
                onChange={(event, newValue) => {
                  if (newValue) {
                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                    setRider(obj.id)
                  }
                }}
                options={riderList?.results || []}
                freeSolo
                blurOnSelect
                defaultValue={riderDef}
                getOptionLabel={(option: any) => option?.user && option?.user?.name}
                onInputChange={(event, newInputValue) => {
                  clearTimeout(timer.current)
                  timer.current = setTimeout(() => {
                    getRiderList(`?usergroup=Rider&code=${newInputValue}`)
                  }, 1000)
                  if (newInputValue.length === 0) {
                    setRider("")
                  }
                }}
                renderInput={(params) => (
                  <TextField className="input"
                    {...params}
                    placeholder="Assign Rider"
                    label="Rider"
                    variant="outlined"
                    style={{ width: "100%", margin: "0", padding: "0" }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <Autocomplete
                id="center"
                onChange={(event, newValue) => {
                  if (newValue) {
                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                    setCentre(obj.id);
                  }
                }}
                freeSolo
                blurOnSelect
                options={centerInfo?.results || []}
                disableCloseOnSelect
                getOptionLabel={(option: any) =>
                  (option?.display_name === null
                    ? option?.name
                    : option?.display_name) +
                  "(" +
                  option?.center_code +
                  ")"
                }
                loading={loading}
                onInputChange={(event, newInputValue) => {
                  clearTimeout(timer.current);
                  timer.current = setTimeout(() => {
                    getCenterInfo(`?name_or_code=${newInputValue}`);
                  }, 1000);
                }}
                renderInput={(params) => (
                  <TextField
                    className="input"
                    {...params}
                    label="Centre"
                    variant="outlined"
                    style={{ width: "100%", margin: "0", padding: "0" }}
                    inputProps={{
                      ...params.inputProps,
                      onKeyDown: (e) => {
                        if (e.key === 'Enter') {
                          e.stopPropagation();
                        }
                      },
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={4} md={2}>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                onClick={filterStopsData}
                disabled={loadingroutes}
              >
                Filter
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => history.push("/dashboard/lp/route_plan")}
              >
                reset
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
      <Grid container style={{ marginTop: 10 }}>
        <div style={{ width: "100%" }} className="data-table">
          <TableContainer
            className={classes.tableContainer}
            component={Paper}
            style={{ maxHeight: "540px" }}
          >
            {loadingroutes ? (
              <Loader />
            ) : (
              <Table stickyHeader aria-label="simple table">
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell align="center">S. No.</StyledTableCell>
                    <StyledTableCell align="center">Route Name</StyledTableCell>
                    <StyledTableCell align="center">Route Start Point</StyledTableCell>
                    <StyledTableCell align="center">Route End Point</StyledTableCell>
                    <StyledTableCell align="center">Start Time</StyledTableCell>
                    <StyledTableCell align="center">End Time</StyledTableCell>
                    <StyledTableCell align="center">City</StyledTableCell>
                    <StyledTableCell align="center">Colection Center</StyledTableCell>
                    <StyledTableCell align="center">Assigned Rider</StyledTableCell>
                    <StyledTableCell align="center">Assigned phlebos</StyledTableCell>
                    <StyledTableCell align="center">Stops</StyledTableCell>
                    <StyledTableCell align="center">Action</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {routesData &&
                    routesData?.results &&
                    routesData?.results?.length > 0 &&
                    routesData?.results?.map((row: any, index: number) => {
                      return (
                        <StyledTableRow key={index}>
                          <StyledTableCell align="center">
                            {index + 1}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {row?.name}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {row?.source}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {row?.destination}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {row?.start_time}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {row?.end_time}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {row?.city_name}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {row?.stops_detail &&
                              row?.stops_detail?.length > 0 &&
                              row?.stops_detail.map((val: any, index1: any) => {
                                return (
                                  val?.cc ?
                                    <Chip
                                      label={
                                        index1 +
                                        1 +
                                        ". " +
                                        val?.cc?.name
                                      }
                                      variant="outlined"
                                      size="small"
                                    /> : ""
                                );
                              })}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {row?.rider?.name}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {row?.stops_detail && row?.stops_detail.map((data: any) => {
                              return (
                                data?.panel_users && data?.panel_users.map((data2: any, index2: any) => {

                                  return (
                                    <Chip
                                      label={
                                        index2 +
                                        1 +
                                        ". " +
                                        data2?.user
                                      }
                                      variant="outlined"
                                      size="small"
                                    />
                                  )
                                })
                              )
                            })}

                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {row?.stops_detail &&
                              row?.stops_detail.length > 0 &&
                              row?.stops_detail.map((val: any, index1: any) => {
                                return (
                                  <Chip
                                    label={
                                      index1 +
                                      1 +
                                      ". " +
                                      val?.name +
                                      "(" +
                                      val?.time +
                                      ")"
                                    }
                                    variant="outlined"
                                    size="small"
                                  />
                                );
                              })}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <Button
                              color="primary"
                              variant="contained"
                              // onClick={() =>
                              //   history.push(
                              //     `/dashboard/lp/route_plan_action/${row?.id}`
                              //   )
                              // }
                              onClick={() => editData(row?.id)}
                            >
                              edit
                            </Button>
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                </TableBody>
                <TableFooter>
                  <TablePagination
                    rowsPerPageOptions={[]}
                    colSpan={3}
                    count={routesData?.count}
                    rowsPerPage={routesData?.page_size}
                    page={page}
                    onPageChange={handleChangePage}
                  />
                </TableFooter>
              </Table>
            )}
          </TableContainer>
        </div>
      </Grid>
    </div>


  );
};
const mapStateToProps = (state: any) => ({
  routesData: state.LogisticsReducer.routesData,
  loading: state.LogisticsReducer.loading,
  loadingroutes: state.LogisticsReducer.loadingroutes,
  bookingoverview: state.LogisticsReducer.bookingoverview,
  riderList: state.LogisticsReducer.riderList,
  cities: state.LogisticsReducer.cities,
  centerInfo: state.LogisticsReducer.centerInfo,

});
export default connect(mapStateToProps, {
  getRoutes,
  getBookingOverview,
  getRiderList,
  getCities,
  getCenterInfo,
  resetGetRoutes
})(RoutePlan);
