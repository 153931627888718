import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";
import { Grid } from '@material-ui/core';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { getMapMyIndiaAccessToken, verifyLocation, SendLocationRequest, getAddressDetailsLatLong } from '../../actions/RouteManagerAction'
import { useHistory } from 'react-router';
import GamesIcon from '@material-ui/icons/Games';
import { mappls } from "mappls-web-maps";
import { getPreciseDistance } from 'geolib';
// import { updateUnregisterdBooking } from "../../actions/CustomerSupport";

let width = window.innerWidth;
let isMobile = width <= 500;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    mapCSS: {
      height: "300px",
      width: "100%",
    },
    packageLink: {
      color: "#ffffff",
      textDecoration: "none",
    },
    dialog: {
      zIndex: 2,
    },
  })
);
type ModalProps = {
  mapmyindiaAccessToken: any;
  getMapMyIndiaAccessToken: any;
  sendLocationOnWhatsapp: any;
  verifyLocation: any;
  SendLocationRequest: any;
  openModal: any;
  setopenModal: any;
  bookingId: any;
  setCustomerLatitude: any;
  setCustomerLongitude: any;
  customerLatitude: any;
  customerLongitude: any;
  setCustomerLatitude1: any;
  setCustomerLongitude1: any;
  customerLatitude1: any;
  customerLongitude1: any;
  leadId: any;
  getAddressDetailsLatLong: any;
  cityId: any;
  addressLine2: any;
  favoritePlace: any;
  customer_address: any;
  customer_landmark1: any;
  addressDetailsLatLong: any;
  addressId: any;
  setVerifyKey: any;
  heading: any;
};

const BookingCallCount: React.FC<ModalProps> = ({
  mapmyindiaAccessToken,
  getMapMyIndiaAccessToken,
  sendLocationOnWhatsapp,
  verifyLocation,
  SendLocationRequest,
  openModal,
  setopenModal,
  bookingId,
  setCustomerLatitude,
  setCustomerLongitude,
  customerLatitude,
  customerLongitude,
  setCustomerLatitude1,
  setCustomerLongitude1,
  customerLatitude1,
  customerLongitude1,
  leadId,
  getAddressDetailsLatLong,
  cityId,
  addressLine2,
  favoritePlace,
  customer_address,
  customer_landmark1,
  addressDetailsLatLong,
  addressId,
  setVerifyKey,
  heading,
}) => {
  const classes = useStyles();
  const history = useHistory()
  const [accessToken, setAccessToken] = useState<any>("");
  const [distanceBetweenLatLong, setDistanceBetweenLatLong] = useState<any>(0);
  const mapProps = {
    center: [customerLatitude, customerLongitude],
    traffic: false,
    zoom: 8,
    geolocation: false,
    clickableIcons: false,
  };
  let mapplsClassObject = new mappls();

  let mapObject;
  let markerObject;
  let markerObject1;

  const handleClose = () => {
    setopenModal(false);
  };

  useEffect( () => {
    if (openModal && leadId !==0 && leadId !==null && leadId !==undefined) {
      if (addressId === "none") getAddressDetailsLatLong(customerLatitude, customerLongitude, leadId, bookingId);
    }
  }, [leadId, openModal])

  const handleYes = () => {
    if(bookingId !== 0 && bookingId !== undefined && openModal){
      verifyLocation(bookingId)
    }
  };

  const handleNo = () => {
    if (openModal && leadId !== 0 && leadId !== null && leadId !== undefined) {
      let body = {
        lead_id: leadId,
        address_id: addressDetailsLatLong?.id,
        forced: true,
        booking_id: bookingId,
        attempt: "first",
      }
      SendLocationRequest(body);
    }
  };

  useEffect(() => {
    if (openModal && customerLatitude && customerLatitude1 && customerLongitude && customerLongitude1) {
      let dis = getPreciseDistance(
        { latitude: customerLatitude, longitude: customerLongitude },
        { latitude: customerLatitude1, longitude: customerLongitude1 }
      );
      setDistanceBetweenLatLong(dis)
    }
  }, [openModal])

  useEffect(()=>{
    if(bookingId !== 0 && bookingId !== undefined && openModal){
      getMapMyIndiaAccessToken()
    }
  },[openModal])

  useEffect(() => {
    if (mapmyindiaAccessToken?.accesstoken) {
      setAccessToken(mapmyindiaAccessToken?.accesstoken);
    }
    if (openModal) {
      mapplsClassObject.initialize(mapmyindiaAccessToken?.accesstoken, () => {
        mapObject = mapplsClassObject.Map({
          id: "map-container",
          properties: mapProps,
        });
        markerObject = mapplsClassObject.Marker({
          map: mapObject,
          position: { lat: customerLatitude, lng: customerLongitude },
          title: "Location Selected By Sales Officer",
          // popupHtml:  'First Location By Team', // custom html for marker (<div> content </div>)
          // popupOptions: 
          //   { 
          //     openPopup:  true, 
          //     autoClose:  true, 
          //     maxWidth:  500
          //   }
        });
        markerObject.setPosition({ lat: customerLatitude, lng: customerLongitude });
        if (customerLatitude1 !== null && customerLongitude1 !== null) {
            markerObject1 = mapplsClassObject.Marker({
              map: mapObject,
              position: { lat: customerLatitude1, lng: customerLongitude1 },
              title: "Location Shared By Customer",
              // icon: {
              //   // className: 'my-div-icon',
              //   html: "m"
              //   // html: "<img style='position:relative;width:34px;height:48px' src=" + "'https://maps.mapmyindia.com/images/2.png'>" + '<span style="position: absolute;left:1.5em;right: 1em;top:0.9em;bottom:3em; font-size:9px;font-weight:bold; width: 4px; color:black;" class="my-div-span">' + 'M' + '</span>',
              //   // iconSize: [10, 10],
              //   // popupAnchor: [12, -10]
              // },
              // popupHtml:  'Verified Location By Customer', // custom html for marker (<div> content </div>)
              // popupOptions: 
              //   { 
              //     openPopup:  true, 
              //     autoClose:  true, 
              //     maxWidth:  500
              //   }
            });
            markerObject1.setIcon("https://apis.mapmyindia.com/map_v3/2.png");
            markerObject1.setPosition({ lat: customerLatitude1, lng: customerLongitude1 });
          }
          markerObject.setIcon("https://apis.mapmyindia.com/map_v3/1.png");
          markerObject.setDraggable({ lat: customerLatitude, lng: customerLongitude });
          mapObject.addListener("mousemove", function (e: any) { });
      });
    }
  }, [mapmyindiaAccessToken]);

  return (
    <div>
      <Dialog
            open={openModal}
            onClose={() => setopenModal(false)}
            aria-labelledby="form-dialog-title"
            className={classes.dialog}
          >
            <DialogTitle id="form-dialog-title">{heading}</DialogTitle>
            <DialogContent>
              <Grid
                container
                spacing={2}
                style={{
                  margin: "0px 10px 10px 10px",
                  minWidth: "500px",
                  width: "70%",
                }}
              >
                <Grid item xs={12} md={12}>
                    <div
                      key={bookingId}
                      id="map-container"
                      className={classes.mapCSS}
                    ></div>
                </Grid>
              </Grid>
              { (heading === "More than 3 Km - Not Verified") ?
                <p style={{ fontSize: "16px", paddingLeft: "20px" }}>
                  Please call the customer to confirm if the location shared by them is correct. You can check the locations above. 
                  <span>(If the customer says it is the correct location, click on Verified otherwise Not-Verified)</span>
                </p>
               : ""
              }
              { (heading === "Non-Serviceable - Not Verified") ?
                <p style={{ fontSize: "16px", paddingLeft: "20px" }}>
                  The location shared by the Customer is “Out of service area” and more than 3Kms away. Please confirm with the Customer if it is correct. You can check the locations above. 
                  <span>(If the customer says it is the correct location, click on Verified otherwise Not-Verified)</span>
                </p>
               : ""
              }
              {
                (heading === "More than 3 Km - Not Verified" || heading === "Address is more than 3KM" || heading === "Non-Serviceable - Not Verified") ?
                <div style={{ paddingBottom: "20px", paddingLeft: "10px" }}>
                  <Button
                    onClick={handleYes}
                    color="primary"
                  >
                    Verified
                  </Button>
                  <Button
                    onClick={handleNo}
                    color="primary"
                  >
                    Not-Verified
                  </Button>
                  {/* <Button
                    variant="contained"
                    color="secondary"
                    style={{ height: "56px", marginRight: "16px", marginLeft: "10px" }}
                    onClick={() => setopenModal(false)}
                  >
                    YES
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ height: "56px" }}
                    onClick={() => setopenModal(false)}
                  >
                    NO
                  </Button> */}
                </div> : ""
              }
              {(heading === "Non-Serviceable - Not Verified") ?
                <p style={{ fontSize: "16px", paddingLeft: "20px" }}>
                  <span>Click on Not-Verified</span> - If the customer says the location is wrong & Tell the Customer that they must have received a new WhatsApp message, and they should share the updated location through the WhatsApp location feature.
                </p> : ""
              }
              {
                <p style={{ fontSize: "16px", paddingLeft: "20px" }}>Location shared by Sales agent: Lat-{customerLatitude} Long-{customerLongitude}</p>
              }
              {
                (heading === "Location Not Shared") ? "" :
                <p style={{ fontSize: "16px", paddingLeft: "20px" }}>Location shared by Customer: Lat-{customerLatitude1} Long-{customerLongitude1}</p>
              }
              {
                (heading === "Location Not Shared") ? "" :
                <p style={{ fontSize: "16px", paddingLeft: "20px" }}>Distance between the two locations is <span>{distanceBetweenLatLong} meters.</span></p>
              }
              {(heading === "More than 3 Km - Not Verified" || heading === "Address is more than 3KM") ?
                <p style={{ fontSize: "16px", paddingLeft: "20px" }}>
                  Click on Not-Verified - If the customer says the location is wrong & Tell the Customer that they must have received a new WhatsApp message, and they should share the updated location through WhatsApp location or through the link in the message.
                </p> : ""
              }
            </DialogContent>
            {
            // geoLoading ? <Loader /> : 
            (
              <DialogActions>
                {/* <Button
                  // onClick={() => handleAddressModel()}
                  color="primary"
                  // disabled={geoLoading || pincode === "" || geoCheckError !== ""}
                  // disabled={geoLoading || geoCheckError !== ""}
                >
                  Select
                </Button> */}
                <Button
                  onClick={() => setopenModal(false)}
                  color="primary"
                >
                  Cancel
                </Button>
              </DialogActions>
            )}
          </Dialog>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  mapmyindiaAccessToken: state.RouteManagerReducer.mapmyindiaAccessToken,
  sendLocationOnWhatsapp: state.RouteManagerReducer.sendLocationOnWhatsapp,
  addressDetailsLatLong: state.RouteManagerReducer.addressDetailsLatLong,
});

export default connect(mapStateToProps, {
  getMapMyIndiaAccessToken,
  verifyLocation,
  SendLocationRequest,
  getAddressDetailsLatLong,
})(BookingCallCount);
