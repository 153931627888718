import React, { useState } from "react";
import { useRef } from "react";
import {
    makeStyles,
    Theme,
    createStyles,
    withStyles,
} from "@material-ui/core/styles";
import Button from '@mui/material/Button';
import { connect } from "react-redux";
import { Paper, TextField, Grid } from "@material-ui/core";

import { useEffect } from "react";
import {
    getRiderList,

} from "../../Logistics-panel/actions/LogisticsPanelAction";
import { useHistory, useParams } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { TableFooter, TablePagination } from "@material-ui/core";
import { generateCxFilterUrl } from "../../helpers/generateUrl";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        table: {
            minWidth: 700,
        },
        toolbar: {
            alignItems: "center",
            justifyContent: "flex-end",
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        },
        content: {
            width: "80%",
            flexGrow: 21,
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        paper: {
            marginTop: "2rem",
            padding: "0.5rem 2rem 2rem 2rem",
            width: "100%",
        },
        contentHeader: {
            width: "100%",
            padding: "0px 10px 40px 10px",
            minHeight: "64px",
        },
        leftContentHeader: {
            width: "50%",
            float: "left",
        },
        rightContentHeader: {
            width: "50%",
            float: "right",
            textAlign: "right",
            paddingRight: "20px",
        },
        buttonContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        head: {
            textAlign: "center",
            fontWeight: "normal",
            marginBottom: "2rem",
        },
        slotContainer: {
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
        },
        slot: {
            width: "150px",
            height: "100px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            margin: "0.5rem",
        },
        hoveItem: {
            cursor: "pointer",
        },
        selectedSlot: {
            width: "150px",
            height: "100px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "linear-gradient(60deg, cyan, #00dfff, cyan)",
            cursor: "pointer",
            margin: "0.5rem",
        },
        chips: {
            display: "flex",
        },
        chip: {
            margin: 2,
            background: "linear-gradient(60deg, cyan, #00cfff, cyan)",
            maxWidth: "130px",
        },
        input: {
            padding: "1px",
        },
        tableContainer: {
            marginTop: "2rem",
            position: "sticky",
            top: "200px",
        },
        tableHead: {
            background: "rgb(146, 74, 145)!important",
        },
        tableHeadCell: {
            color: "#fff",
            fontWeight: "bold",
            textAlign: "center",
        },
        centerIt: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
    })
);

const StyledTableCell: any = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: "#924A91",
            color: theme.palette.common.white,
            fontSize: "14px",
        },
        body: {
            fontSize: 14,
        },
    })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    })
)(TableRow);
interface Props {
    getRiderList: any,
    riderList: any,
    loading: any;
}
const StopsPlace: React.FC<Props> = ({
    riderList,
    getRiderList,
    loading,
}) => {
    const classes = useStyles();
    const history = useHistory();
    const timer = useRef<any>(0);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(20);
    const [manager, setManager] = useState<any>("");
    const [userId, setUserId] = useState<any>("");
    const [emailId, setEmailId] = useState<any>("");
    const [phone, setPhone] = useState<any>("");
    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        if (newPage > page) {
            let url = riderList.links && riderList.links.next.split("?")[1];
            getRiderList(url ? `?${url}` : "");
        } else if (newPage < page) {
            let url = riderList.links && riderList.links.previous.split("?")[1];
            getRiderList(url ? `?${url}` : "");
        }
        setPage(newPage as number);
    };
    useEffect(() => {
        getRiderList();
    }, []);

    const filterAttendence = async (e: any) => {
        e.preventDefault();
        getRiderList(`?id=${userId}&username=${manager}&email=${emailId}&phonenumber=${phone}`);
    };
    return (
        <Grid container spacing={2} style={{ marginTop: 50 }}>
            <Grid item xs={10}>
                <h4 style={{ fontWeight: "bold" }}>MANAGE MANAGER</h4>
            </Grid>
            <Grid item xs={2}>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={() =>
                        history.push(`/dashboard/lp/manage_Manager/create`)
                    }
                >
                    Create New Manager
                </Button>
            </Grid>
            <form style={{ width: "100%" }}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={4} md={3}>
                        <TextField
                            label="User Id"
                            variant="outlined"
                            className="input"
                            fullWidth
                            type="number"
                            value={userId}
                            onChange={(e) => setUserId(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={3}>
                        <TextField
                            label="Manager Name"
                            variant="outlined"
                            className="input"
                            fullWidth
                            value={manager}
                            onChange={(e) => setManager(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={3}>
                        <TextField
                            label="Email ID"
                            variant="outlined"
                            className="input"
                            fullWidth
                            value={emailId}
                            onChange={(e) => setEmailId(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={3}>
                        <TextField
                            label="Phone No"
                            variant="outlined"
                            className="input"
                            fullWidth
                            type="number"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                        <Button
                            color="primary"
                            variant="contained"
                            fullWidth
                            type="submit"
                            style={{ height: "100%" }}
                            onClick={filterAttendence}
                        >
                            Filter
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                        <Button
                            color="secondary"
                            variant="contained"
                            fullWidth
                            onClick={() => history.push("/dashboard/lp/manageManger")}
                            style={{ height: "100%" }}
                        >
                            reset
                        </Button>
                    </Grid>
                </Grid>
                <TableContainer className={classes.tableContainer} component={Paper} style={{ maxHeight: "550px" }}>
                    <Table stickyHeader aria-label="simple table">
                        <TableHead className={classes.tableHead}>
                            <StyledTableRow>
                                <StyledTableCell className={classes.tableHeadCell}>
                                    User Id
                                </StyledTableCell>
                                <StyledTableCell className={classes.tableHeadCell}>
                                    Employee Id
                                </StyledTableCell>
                                <StyledTableCell className={classes.tableHeadCell}>
                                    Manager Name
                                </StyledTableCell>
                                <StyledTableCell className={classes.tableHeadCell}>
                                    User Group
                                </StyledTableCell>
                                <StyledTableCell className={classes.tableHeadCell}>
                                    User Type
                                </StyledTableCell>
                                <StyledTableCell className={classes.tableHeadCell}>
                                    Email Id
                                </StyledTableCell>
                                <StyledTableCell className={classes.tableHeadCell}>
                                    Phone Nummber
                                </StyledTableCell>
                                <StyledTableCell className={classes.tableHeadCell}>
                                    Reporting Loction
                                </StyledTableCell>
                                <StyledTableCell className={classes.tableHeadCell}>
                                    Action
                                </StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {riderList &&
                                riderList?.results &&
                                riderList.results.map((data: any, index: any) => {
                                    return (
                                        <StyledTableRow key={index}>
                                            <StyledTableCell align="center">
                                                {data?.id}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {data?.empid}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {data?.user?.username}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {data?.user?.usergroup}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {data?.usertype}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {data?.user?.email}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {data?.user?.phonenumber}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {data?.reporting_location}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                <Button
                                                    color="primary"
                                                    variant="contained"
                                                    onClick={() =>
                                                        history.push(
                                                            `/dashboard/lp/manage_Manager/${data?.id}`
                                                        )
                                                    }
                                                >
                                                    edit
                                                </Button>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    );
                                })}
                        </TableBody>
                        <TableFooter>
                            <StyledTableRow>
                                <TablePagination
                                    colSpan={2}
                                    count={riderList?.count || 0}
                                    rowsPerPageOptions={[]}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    onPageChange={handleChangePage}
                                />
                            </StyledTableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </form>
        </Grid>
    );
};
const mapStateToProps = (state: any) => ({
    riderList: state.LogisticsReducer.riderList,
    loading: state.LogisticsReducer.loading,
});
export default connect(mapStateToProps, {
    getRiderList,
})(StopsPlace);
