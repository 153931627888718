import React, { useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  withStyles,
} from "@material-ui/core";
import Button from '@mui/material/Button';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Loader from "../Components/loader";
import moment from "moment";
import BookingCommentsModal from "../Components/Comment/ccbookingComments";
import { MessageRounded } from "@material-ui/icons";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
      textTransform: "capitalize",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      marginTop: "2rem",
      padding: "0.5rem 2rem",
      width: "100%",
    },
    table: {
      maxWidth: "100%",
      margin: "auto",
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
  })
);

const StyledTableCell:any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow:any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

interface Props {
  getCCBookings: any;
  ccbookingList: any;
  page: any;
  setPage: any;
  setopenModal: any;
  openModal: any;
  loading: Boolean;
}

const BookingsTable: React.FC<Props> = ({
  getCCBookings,
  ccbookingList,
  page,
  setPage,
  setopenModal,
  openModal,
  loading,
}) => {
  const classes = useStyles();

  const [bookingId, setBookingId] = useState<number>(0);

  const handleClick = (id: number) => {
    setopenModal(true);
    setBookingId(id);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url = ccbookingList.links && ccbookingList.links.next.split("?")[1];
      getCCBookings(url);
    } else if (newPage < page) {
      let url = ccbookingList.links && ccbookingList.links.previous.split("?")[1];
      getCCBookings(url);
    }
    setPage(newPage as number);
  };
  const handleDOBForAge = (dob: any, bdate: any) => {
    const a: any = moment(bdate);
    const b: any = moment(dob);
    var years = a.diff(b, "year");
    b.add(years, "years");
    var months = a.diff(b, "months");
    b.add(months, "months");
    var days = a.diff(b, "days");
    if (months !== 0) {
      var age = months + "Months " + days + "Days";
    } else {
      var age = days + "Days";
    }

    return age;
  };

  return (
    <div style={{ width: "100%" }} className="data-table">
      <TableContainer
        className={classes.tableContainer}
        component={Paper}
        style={{ maxHeight: "540px" }}
      >
        {loading ? (
          <Loader />
        ) : (
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell align="center">Booking Id</StyledTableCell>
                <StyledTableCell align="center">Action</StyledTableCell>
                <StyledTableCell align="center">Comment</StyledTableCell>
                <StyledTableCell align="center">Booking Date</StyledTableCell>
                <StyledTableCell align="center">Name</StyledTableCell>
                {/* <StyledTableCell align="center">Agent</StyledTableCell> */}
                <StyledTableCell align="center">Age</StyledTableCell>
                <StyledTableCell align="center">Gender</StyledTableCell>
                {/* <StyledTableCell align="center">Email</StyledTableCell>*/}
                {/* <StyledTableCell align="center">
                  Collection Date
                </StyledTableCell> */}
                <StyledTableCell align="center">
                  Expected Report Time
                </StyledTableCell>
                {/* <StyledTableCell align="center">Zone Name</StyledTableCell> */}
                {/* <StyledTableCell align="center">Mobile Number</StyledTableCell> */}
                <StyledTableCell align="center">Centre Name</StyledTableCell>
                <StyledTableCell align="center">Report Status</StyledTableCell>

                <StyledTableCell align="center">Booking Status</StyledTableCell>
                {/* <StyledTableCell align="center">
                  Verification Status
                </StyledTableCell> */}
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {ccbookingList.results &&
                ccbookingList.results.length > 0 &&
                ccbookingList.results.map((booking: any, index: any) => {
                  return (
                    <StyledTableRow key={index}>
                      <StyledTableCell align="center">
                        {booking?.additional_members.length > 0 ? (
                            <div>
                              {booking.id}+
                              <Chip
                                label={`${booking?.additional_members.length}`}
                                style={{ background: "#ffeb3b" }}
                              />
                            </div>
                          ) : (
                            booking.id
                          )}
                        {booking.id}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Link
                          to={`/dashboard/ccadmin/booking-view/${booking?.id}`}
                          style={{ textDecoration: "none" }}
                        >
                          <Button variant="contained" color="secondary">
                            View
                          </Button>
                        </Link>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <div>
                          <MessageRounded
                            onClick={() => handleClick(booking?.id)}
                          />
                        </div>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {moment(booking?.booking_date).format("DD-MM-YYYY")}
                      </StyledTableCell>
                      <StyledTableCell align="center">{`${
                        booking?.designation || ""
                      } ${booking?.customer_name}`}</StyledTableCell>
                      {/* <StyledTableCell align="center">
                          {(booking?.agent && booking?.agent.name) || "NA"}
                        </StyledTableCell> */}
                      <StyledTableCell align="center">
                        {booking?.customer_age === 0
                          ? handleDOBForAge(booking?.dob, booking?.booking_date)
                          : booking?.customer_age}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {booking?.customer_gender}
                      </StyledTableCell>
                      {/*<StyledTableCell align="center">
                           {booking?.customer_email}
                        </StyledTableCell>*/}
                      {/* <StyledTableCell align="center">
                        {booking?.collection_date}
                      </StyledTableCell> */}
                      <StyledTableCell align="center">
                        {moment(booking?.expected_report_release_time).format(
                          "DD-MM-YYYY, hh:MM A"
                        )}
                      </StyledTableCell>
                      {/* <StyledTableCell align="center">
                        {booking?.customer_areapincode?.area}
                      </StyledTableCell> */}
                      {/* <StyledTableCell align="center">
                        {booking?.customer_phonenumber}
                      </StyledTableCell> */}
                      <StyledTableCell align="center">
                        {booking?.centre_address?.name}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {booking?.status}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Chip
                          label={`${booking?.booking_status}`}
                          style={{
                            background:
                              booking?.booking_status === "pending"
                                ? "#ffee58"
                                : booking?.booking_status === "confirmed"
                                ? "#00e676"
                                : booking?.booking_status === "cancelled"
                                ? "#ff3d00"
                                : "#d500f9",
                          }}
                        />
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
            </TableBody>
            <TableFooter>
              <StyledTableRow>
                <TablePagination
                  rowsPerPageOptions={[]}
                  colSpan={3}
                  count={ccbookingList.count || 0}
                  rowsPerPage={ccbookingList.page_size}
                  page={page}
                  onPageChange={handleChangePage}
                />
              </StyledTableRow>
            </TableFooter>
          </Table>
        )}
      </TableContainer>
      {ccbookingList.results && ccbookingList.results.length === 0 && (
        <h6 style={{ textAlign: "center" }}>No Data Found</h6>
      )}
      <BookingCommentsModal
        openModal={openModal}
        setopenModal={setopenModal}
        bookingId={bookingId}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps, {})(BookingsTable);
