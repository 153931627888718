import React, { useState, useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Chip,
  Paper,
  withStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import CachedIcon from '@mui/icons-material/Cached';
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Loader from "../components/loader/index";
import BookingCommentsModal from "../components/Comments/bookingComments";
import { MessageRounded } from "@material-ui/icons";
import AdditonalFamilyModal from "../tables/AdditionalFamily/AdditionFamilyDetails";
import InvertColorsIcon from "@material-ui/icons/InvertColors";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import LocationVerificationModal from "../components/Comments/locationVerificationModal";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      marginTop: "2rem",
      padding: "0.5rem 2rem",
      width: "100%",
    },
    table: {
      maxWidth: "100%",
      margin: "auto",
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
  })
);

const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

interface Props {
  getBookings: any;
  getAllNewBookings: any;
  bookingList: any;
  page: any;
  setPage: any;
  openModal: any;
  setopenModal: any;
  setOpenPaymentModal: any;
  openPaymentModal: any;
  loading: Boolean;
  setOpenTubeModal: any;
  setPaymentId: any;
  paymentId: any;
  setTubeId: any;
  setTotalCount: any;
  setBId: any;
  setMemberCheck: any;
  PostBookingIndexing:any;

}

const BookingsTable: React.FC<Props> = ({
  getBookings,
  getAllNewBookings,
  bookingList,
  page,
  setPage,
  openModal,
  setPaymentId,
  paymentId,
  setopenModal,
  setOpenPaymentModal,
  openPaymentModal,
  setOpenTubeModal,
  setTubeId,
  loading,
  setTotalCount,
  setBId,
  setMemberCheck,
  PostBookingIndexing
}) => {
  const classes = useStyles();

  const [bookingId, setBookingId] = useState<number>(0);
  const [additionalMemebr, setAdditionalMemebr] = useState<any>();
  const [additionalModal, setAdditonalModal] = useState<boolean>(false);
  const [bookingIdLocation, setBookingIdLocation] = useState<number>(0);
  const [leadIdLocation, setLeadIdLocation] = useState<number>(0);
  const [openLocation, setOpenLocation] = useState<boolean>(false);
  const [customerLatitude, setCustomerLatitude] = useState<any>();
  const [customerLongitude, setCustomerLongitude] = useState<any>();
  const [customerLatitude1, setCustomerLatitude1] = useState<any>();
  const [customerLongitude1, setCustomerLongitude1] = useState<any>();
  const [cityId, setCityId] = useState<any>("");
  const [addressLine2, setAddressLine2] = useState<string>();
  const [favoritePlace, setFavoritePlace] = useState<any>();
  const [customer_address, setCustomer_address] = useState<String>("");
  const [customer_landmark1, setCustomer_landmark1] = useState<string>("");
  const [verifyKey, setVerifyKey] = useState<boolean>(false);
  const [heading, setHeading] = useState<string>("");

  const handleClick = (id: number) => {
    setopenModal(true);
    setBookingId(id);
  };

  const handleClickLocation = (id: number) => {
    setOpenLocation(true);
    setBookingIdLocation(id);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url = bookingList.links && bookingList.links.next.split("?")[1];
      // getBookings(url.includes("optimize=true") ? url : `${url}&optimize=true`);
      getAllNewBookings(url.includes("") ? url : `${url}`);
    } else if (newPage < page) {
      let url = bookingList.links && bookingList.links.previous.split("?")[1];
      // getBookings(url.includes("optimize=true") ? url : `${url}&optimize=true`);
      getAllNewBookings(url.includes("") ? url : `${url}`);
    }
    setPage(newPage as number);
  };
  const handleAdditionalView = (member: any) => {
    setAdditionalMemebr(member);
    setAdditonalModal(true);
  };
  const handleTubeModal = (id: any, bId: any, check: any) => {
    setOpenTubeModal(true);
    setTubeId(id);
    setBId(bId);
    setMemberCheck(check);
  };
  const handlePaymentInfo = (id: any) => {
    setPaymentId(id);
    setOpenPaymentModal(true);
    setPaymentId(id);
  };
  const handleIndexing = async (id: any) => {
    let body = {
      booking_id: id
    }
    await PostBookingIndexing(body)
    getAllNewBookings(`booking_id=${id}`)
  }

  return (
    <div className="data-table" style={{ width: "100%" }}>
      <TableContainer
        className={classes.tableContainer}
        component={Paper}
        style={{ maxHeight: "580px" }}
      >
        {loading ? (
          <Loader />
        ) : (
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell align="center">Action</StyledTableCell>
                <StyledTableCell align="center">Booking Id</StyledTableCell>
                <StyledTableCell align="center">Child Id</StyledTableCell>
                <StyledTableCell align="center">Select Tube</StyledTableCell>
                <StyledTableCell align="center">RM Info</StyledTableCell>
                <StyledTableCell align="center">Location Verified</StyledTableCell>
                <StyledTableCell align="center">Phlebo Name</StyledTableCell>
                <StyledTableCell align="center">Phlebo Number</StyledTableCell>
                <StyledTableCell align="center">Comment</StyledTableCell>
                <StyledTableCell align="center">Booking Date</StyledTableCell>
                <StyledTableCell align="center">Name</StyledTableCell>
                <StyledTableCell align="center">Agent</StyledTableCell>
                <StyledTableCell align="center">Age</StyledTableCell>
                <StyledTableCell align="center">Gender</StyledTableCell>
                <StyledTableCell align="center">Email</StyledTableCell>
                <StyledTableCell align="center">
                  Collection Date
                </StyledTableCell>
                <StyledTableCell align="center">
                  Collection Time
                </StyledTableCell>
                <StyledTableCell align="center">Booking Time</StyledTableCell>
                <StyledTableCell align="center">Zone Name</StyledTableCell>
                <StyledTableCell align="center">Mobile Number</StyledTableCell>
                <StyledTableCell align="center">Booking Status</StyledTableCell>
                <StyledTableCell align="center">Report Status</StyledTableCell>
                <StyledTableCell align="center">Pickup Status</StyledTableCell>
                <StyledTableCell align="center">Refresh</StyledTableCell>
                {/* <StyledTableCell align="center">Verification Status</StyledTableCell> */}
                <StyledTableCell align="center">
                  Pickup Recieve Amount
                </StyledTableCell>
                <StyledTableCell align="center">Payment Id</StyledTableCell>
                <StyledTableCell align="center">Payment Mode</StyledTableCell>
                <StyledTableCell align="center">Payment Info</StyledTableCell>
                <StyledTableCell align="center">Pickup Date</StyledTableCell>
                <StyledTableCell align="center">Pickup Time</StyledTableCell>
                <StyledTableCell align="center">
                  UpGrade/DownGrade
                </StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {bookingList.results &&
                bookingList.results.length > 0 &&
                bookingList.results.map((booking: any, index: any) => {
                  const pendingData = booking?.additional_members.filter(
                    (data: any) => data.pickup_status === "pending"
                  );
                  const confirmedData = booking?.additional_members.filter(
                    (data: any) => data.pickup_status === "confirmed"
                  );
                  return (
                    <>
                      <StyledTableRow
                        key={index}
                        style={{
                          backgroundColor:
                            booking.phlebo_verification_remark !==
                              "Hold Due To Payment"
                              ? booking.booking_type === "b2b" &&
                                booking.receivable_amount === 0 &&
                                booking.phlebo_reached === false
                                ? "#ffe082"
                                : booking.booking_type === "b2b" &&
                                  booking.receivable_amount !== 0 &&
                                  booking.phlebo_reached === false
                                  ? "#f48fb1"
                                  : booking.booking_type === "b2b" &&
                                    booking.receivable_amount === 0 &&
                                    booking.phlebo_reached
                                    ? "#ba68c8"
                                    : booking.booking_type === "b2b" &&
                                      booking.receivable_amount !== 0 &&
                                      booking.phlebo_reached
                                      ? "#ba68c8"
                                      : booking.booking_type === "b2c" &&
                                        booking.receivable_amount == 0 &&
                                        booking.phlebo_reached === false
                                        ? "#64ffda"
                                        : booking.booking_type === "b2c" &&
                                          booking.receivable_amount == 0 &&
                                          booking.phlebo_reached
                                          ? "#ba68c8"
                                          : booking?.discounted_price?.total_price_package >= "2000"
                                            ? "#cddc39"
                                            : ""
                              : "#ff9800",
                        }}
                      >
                        <StyledTableCell align="center">
                          <Link
                            to={`/dashboard/rm/booking-view/${booking?.pk}`}
                            style={{ textDecoration: "none" }}
                          >
                            <Button variant="contained" color="secondary">
                              View
                            </Button>
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {booking?.pk}
                        </StyledTableCell>
                        <StyledTableCell align="center"></StyledTableCell>
                        <StyledTableCell align="center">
                          <InvertColorsIcon
                            onClick={() =>
                              handleTubeModal(booking?.pk, booking?.pk, "")
                            }
                          />
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {booking?.rm_info && (
                            <>
                              {booking?.rm_info?.name}
                              <br />
                              {booking?.rm_info?.phonenumber}
                            </>
                          )}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {
                            booking?.customer_out_of_zone === true ?
                              <Button
                                onClick={() => {
                                  setCustomerLatitude(booking?.customer_latitude)
                                  setCustomerLatitude1(booking?.verified_location?.lat)
                                  setCustomerLongitude(booking?.customer_longitude)
                                  setCustomerLongitude1(booking?.verified_location?.long)
                                  setLeadIdLocation(booking?.lead)
                                  setCityId(booking?.customer_areapincode?.city?.id)
                                  setAddressLine2(booking?.address_line2)
                                  setFavoritePlace("")
                                  setCustomer_address(booking?.customer_address)
                                  setCustomer_landmark1(booking?.customer_landmark)
                                  setHeading("Non-Serviceable - Not Verified")
                                  handleClickLocation(booking?.pk)
                                }}
                                variant="outlined"
                                style={{ width: "270px", padding: "0px" }}
                              >Non-Serviceable - Not Verified</Button> : (
                                (booking?.verified_location?.lat === null || booking?.verified_location?.long === null) ?
                                  <Button
                                    onClick={() => {
                                      setCustomerLatitude(booking?.customer_latitude)
                                      setCustomerLatitude1(booking?.verified_location?.lat)
                                      setCustomerLongitude(booking?.customer_longitude)
                                      setCustomerLongitude1(booking?.verified_location?.long)
                                      setLeadIdLocation(booking?.lead)
                                      setCityId(booking?.customer_areapincode?.city?.id)
                                      setAddressLine2(booking?.address_line2)
                                      setFavoritePlace("")
                                      setCustomer_address(booking?.customer_address)
                                      setCustomer_landmark1(booking?.customer_landmark)
                                      setHeading("Location Not Shared")
                                      handleClickLocation(booking?.pk)
                                    }}
                                    variant="outlined"
                                    style={{ width: "270px", padding: "0px" }}
                                  >Location Not Shared</Button> :
                                  ((booking?.address_verified === true && booking?.distance_above_deviation === true) ?
                                    <Button
                                      onClick={() => {
                                        setCustomerLatitude(booking?.customer_latitude)
                                        setCustomerLatitude1(booking?.verified_location?.lat)
                                        setCustomerLongitude(booking?.customer_longitude)
                                        setCustomerLongitude1(booking?.verified_location?.long)
                                        setLeadIdLocation(booking?.lead)
                                        setCityId(booking?.customer_areapincode?.city?.id)
                                        setAddressLine2(booking?.address_line2)
                                        setFavoritePlace("")
                                        setCustomer_address(booking?.customer_address)
                                        setCustomer_landmark1(booking?.customer_landmark)
                                        setHeading("More than 3 Km - Verified")
                                        handleClickLocation(booking?.pk)
                                      }}
                                      variant="outlined"
                                      style={{ width: "270px", padding: "0px" }}
                                    >More than 3 Km - Verified</Button> :
                                    (
                                      (booking?.address_verified === false && booking?.distance_above_deviation === true) ?
                                        <Button
                                          onClick={() => {
                                            setCustomerLatitude(booking?.customer_latitude)
                                            setCustomerLatitude1(booking?.verified_location?.lat)
                                            setCustomerLongitude(booking?.customer_longitude)
                                            setCustomerLongitude1(booking?.verified_location?.long)
                                            setLeadIdLocation(booking?.lead)
                                            setCityId(booking?.customer_areapincode?.city?.id)
                                            setAddressLine2(booking?.address_line2)
                                            setFavoritePlace("")
                                            setCustomer_address(booking?.customer_address)
                                            setCustomer_landmark1(booking?.customer_landmark)
                                            setHeading("More than 3 Km - Not Verified")
                                            handleClickLocation(booking?.pk)
                                          }}
                                          variant="outlined"
                                          style={{ width: "270px", padding: "0px" }}
                                        >More than 3 Km - Not Verified</Button> :
                                        (
                                          (booking?.address_verified === true && booking?.distance_above_deviation === false) ?
                                            <Button
                                              onClick={() => {
                                                setCustomerLatitude(booking?.customer_latitude)
                                                setCustomerLatitude1(booking?.verified_location?.lat)
                                                setCustomerLongitude(booking?.customer_longitude)
                                                setCustomerLongitude1(booking?.verified_location?.long)
                                                setLeadIdLocation(booking?.lead)
                                                setCityId(booking?.customer_areapincode?.city?.id)
                                                setAddressLine2(booking?.address_line2)
                                                setFavoritePlace("")
                                                setCustomer_address(booking?.customer_address)
                                                setCustomer_landmark1(booking?.customer_landmark)
                                                setHeading("Less than 3 Km - Verified")
                                                handleClickLocation(booking?.pk)
                                              }}
                                              variant="outlined"
                                              style={{ width: "270px", padding: "0px" }}
                                            >Less than 3 Km - Verified</Button> :
                                            ((booking?.address_verified === false && booking?.distance_above_deviation === false) ?
                                              <Button
                                                variant="outlined"
                                                style={{ width: "270px", padding: "0px" }}
                                              >Location Shared</Button>
                                              : 
                                              <Button></Button>
                                            )
                                        )
                                    )))
                          }
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {booking?.phlebo && booking?.phlebo?.name}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {booking?.phlebo && booking?.phlebo?.phonenumber}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <div>
                            <MessageRounded
                              onClick={() => handleClick(booking?.pk)}
                            />
                          </div>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {booking?.booking_date}
                        </StyledTableCell>
                        <StyledTableCell align="center">{`${booking?.designation || ""
                          } ${booking?.customer_name}`}</StyledTableCell>
                        <StyledTableCell align="center">
                          {(booking?.agent && booking?.agent?.name) || "NA"}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {booking?.customer_age}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {booking?.customer_gender}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {booking?.customer_email}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {booking?.collection_date}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {booking?.collection_slot?.slot.split("-")[0]}
                          {" - "}
                          {booking?.collection_slot?.slot.split("-")[1]}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {" "}
                          {new Date(booking.created_at).toLocaleString()}
                        </StyledTableCell>
                        {/* <TableCell align="center">{booking?.pickup_amount || "NA"}</TableCell> */}
                        <StyledTableCell align="center">
                          {booking?.customer_areapincode?.area}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {booking?.numbers?.customer_phonenumber}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <Chip
                            label={`${booking?.booking_status}`}
                            style={{
                              background:
                                booking?.booking_status === "pending"
                                  ? "#ffee58"
                                  : booking?.booking_status === "confirmed"
                                    ? "#00e676"
                                    : booking?.booking_status === "cancelled"
                                      ? "#ff3d00"
                                      : "#d500f9",
                            }}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <Chip
                            label={`${booking?.report_status}`}
                            style={{
                              background:
                                booking?.report_status === "pending"
                                  ? "#ffee58"
                                  : booking?.report_status === "consolidate"
                                    ? "#00e676"
                                    : booking?.report_status === "partial"
                                      ? "#d500f9"
                                      : "#ff3d00",
                            }}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <Chip
                            label={`${booking?.pickup_status}`}
                            style={{
                              background:
                                booking?.pickup_status === "pending"
                                  ? "#ffee58"
                                  : booking?.pickup_status === "confirmed"
                                    ? "#00e676"
                                    : booking?.pickup_status === "cancelled"
                                      ? "#ff3d00"
                                      : "#d500f9",
                            }}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <div>
                            <CachedIcon
                              onClick={() =>
                                handleIndexing(booking?.pk)
                              }
                            />
                          </div>
                        </StyledTableCell>
                        {/* <StyledTableCell align="center"><Chip label={`${booking?.verification_status}`} style={{ background: booking?.verification_status === "pending" ? "#ffee58" : booking?.verification_status === "confirmed" ? "#00e676" : booking?.verification_status === "cancelled" ? "#ff3d00" : "#d500f9" }} /></StyledTableCell> */}
                        <StyledTableCell align="center">
                          {booking?.pickup_receive_amount}
                        </StyledTableCell>
                        {booking?.payment_mode === "online" &&
                          booking?.pickup_receive_amount !== null ? (
                          <StyledTableCell align="center">
                            {booking?.plink_id}
                          </StyledTableCell>
                        ) : (
                          <StyledTableCell align="center"></StyledTableCell>
                        )}
                        <StyledTableCell align="center">
                          {booking?.payment_mode || "NA"}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <LocalAtmIcon
                            onClick={() => handlePaymentInfo(booking?.pk)}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {booking?.pickup_date}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {booking?.pickup_time}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {booking?.upgrade}
                        </StyledTableCell>
                      </StyledTableRow>
                      {booking?.additional_members &&
                        booking?.additional_members.length > 0 &&
                        booking?.additional_members.map(
                          (data: any, index2: any) => {
                            return (
                              <StyledTableRow key={index2}>
                                <StyledTableCell align="center">
                                  <Link
                                    to={`/dashboard/rm/booking-view/${booking?.pk}`}
                                    style={{ textDecoration: "none" }}
                                  >
                                    <Button
                                      variant="contained"
                                      style={{ backgroundColor: "#ff9100" }}
                                    >
                                      View
                                    </Button>
                                  </Link>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {booking?.pk}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {data?.id}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  <InvertColorsIcon
                                    onClick={() =>
                                      handleTubeModal(
                                        data?.id,
                                        booking?.pk,
                                        "?is_add=true"
                                      )
                                    }
                                  />
                                </StyledTableCell>
                                <StyledTableCell align="center"></StyledTableCell>
                                <StyledTableCell align="center"></StyledTableCell>
                                <StyledTableCell align="center"></StyledTableCell>
                                <StyledTableCell align="center"></StyledTableCell>
                                <StyledTableCell align="center"></StyledTableCell>
                                <StyledTableCell align="center"></StyledTableCell>
                                {/* <StyledTableCell align="center"></StyledTableCell> */}
                                <StyledTableCell align="center">{`${data?.designation || ""
                                  } ${data?.customer_name}`}</StyledTableCell>
                                <StyledTableCell align="center"></StyledTableCell> 
                                <StyledTableCell align="center">
                                  {data?.customer_age}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {data?.customer_gender}
                                </StyledTableCell>
                                <StyledTableCell align="center"></StyledTableCell>
                                <StyledTableCell align="center"></StyledTableCell>
                                <StyledTableCell align="center"></StyledTableCell>
                                {/* <TableCell align="center">{booking?.pickup_amount || "NA"}</TableCell> */}
                                <StyledTableCell align="center"></StyledTableCell>
                                <StyledTableCell align="center"></StyledTableCell>
                                <StyledTableCell align="center"></StyledTableCell>
                                <StyledTableCell align="center">
                                  <Chip
                                    label={`${data?.booking_status}`}
                                    style={{
                                      background:
                                        data?.booking_status === "pending"
                                          ? "#ffee58"
                                          : data?.booking_status === "confirmed"
                                            ? "#00e676"
                                            : data?.booking_status === "cancelled"
                                              ? "#ff3d00"
                                              : "#d500f9",
                                    }}
                                  />
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  <Chip
                                    label={`${data?.report_status}`}
                                    style={{
                                      background:
                                        data?.report_status === "pending"
                                          ? "#ffee58"
                                          : data?.report_status ===
                                            "consolidate"
                                            ? "#00e676"
                                            : data?.report_status === "partial"
                                              ? "#d500f9"
                                              : "#ff3d00",
                                    }}
                                  />
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  <Chip
                                    label={`${data?.pickup_status}`}
                                    style={{
                                      background:
                                        data?.pickup_status === "pending"
                                          ? "#ffee58"
                                          : data?.pickup_status === "confirmed"
                                            ? "#00e676"
                                            : data?.pickup_status === "cancelled"
                                              ? "#ff3d00"
                                              : "#d500f9",
                                    }}
                                  />
                                </StyledTableCell>
                                <StyledTableCell align="center"></StyledTableCell>
                                <StyledTableCell align="center"></StyledTableCell>

                                <StyledTableCell align="center"></StyledTableCell>
                                <StyledTableCell align="center"></StyledTableCell>
                                <StyledTableCell align="center"></StyledTableCell>
                                <StyledTableCell align="center"></StyledTableCell>
                                <StyledTableCell align="center"></StyledTableCell>
                                {/* <StyledTableCell align="center"></StyledTableCell> */}
                              </StyledTableRow>
                            );
                          }
                        )}
                    </>
                  );
                })}
            </TableBody>
            <TableFooter>
              <StyledTableRow>
                <TablePagination
                  rowsPerPageOptions={[]}
                  colSpan={3}
                  count={bookingList.count || 0}
                  rowsPerPage={bookingList.page_size}
                  page={page}
                  onPageChange={handleChangePage}
                />
              </StyledTableRow>
            </TableFooter>
          </Table>
        )}
      </TableContainer>
      {bookingList.results && bookingList.results.length === 0 && (
        <h6 style={{ textAlign: "center" }}>No Data Found</h6>
      )}
      <BookingCommentsModal
        openModal={openModal}
        setopenModal={setopenModal}
        bookingId={bookingId}
      />
      <AdditonalFamilyModal
        additionalMemebr={additionalMemebr}
        additionalModal={additionalModal}
        setAdditonalModal={setAdditonalModal}
        loading={loading}
      />
      <LocationVerificationModal
        openModal={openLocation}
        setopenModal={setOpenLocation}
        bookingId={bookingIdLocation}
        leadId={leadIdLocation}
        setCustomerLatitude={setCustomerLatitude}
        setCustomerLongitude={setCustomerLongitude}
        customerLatitude={customerLatitude}
        customerLongitude={customerLongitude}
        setCustomerLatitude1={setCustomerLatitude1}
        setCustomerLongitude1={setCustomerLongitude1}
        customerLatitude1={customerLatitude1}
        customerLongitude1={customerLongitude1}
        cityId={cityId}
        addressLine2={addressLine2}
        favoritePlace={favoritePlace}
        customer_address={customer_address}
        customer_landmark1={customer_landmark1}
        addressId={"none"}
        heading={heading}
        setVerifyKey={setVerifyKey}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps, {})(BookingsTable);