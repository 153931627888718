import {
  ACTIONS,
  LoginActionTypes,
} from "../interfaces/actionTypes/loginTypes";
import SecureStorage from "../config/SecureStorage";

type LoginInitialState = {
  user: object;
  status: boolean;
  success: boolean;
  otp: any;
  userDetails: Array<any>;
  locationDataFromPusher: Array<any>;
  passwordStatus: boolean;
  loading: boolean;
  whatsappAddressIdDetail: Array<any>;
  createModuleData: Array<any>;
  postCreateModuleData: Array<any>;
  postUpdateModuleData: Array<any>;
  deleteCreateModuleData: Array<any>;
  performanceTableData: Array<any>;
  assignmentQuestionMasterData: Array<any>;
  assignmentAnswerMasterPutData: Array<any>;
  assignmentAnswerMasterPostData: Array<any>;
  agentsList: Array<any>;
  ticketsCommentAdded: Array<any>;
  bookingListLoader: boolean;
  containerSlots: Array<any>;
};

const initialState: LoginInitialState = {
  user: {
    username: "",
    email: "",
    age: 0,
  },
  status: false,
  success: false,
  otp: false,
  userDetails: [],
  passwordStatus: true,
  loading: false,
  createModuleData: [],
  postCreateModuleData: [],
  postUpdateModuleData: [],
  deleteCreateModuleData: [],
  whatsappAddressIdDetail: [],
  performanceTableData: [],
  assignmentQuestionMasterData: [],
  assignmentAnswerMasterPutData: [],
  locationDataFromPusher: [],
  assignmentAnswerMasterPostData: [],
  agentsList: [],
  ticketsCommentAdded: [],
  bookingListLoader: false,
  containerSlots: [],
};

const userReducer = (state = initialState, action: LoginActionTypes) => {
  switch (action.type) {
    case ACTIONS.SET_USER:
      return {
        ...state,
        user: action.payload,
        status: true,
      };
    case ACTIONS.GET_USER:
      return {
        ...state,
        passwordStatus: action.payload,
        status: true,
      };
    case ACTIONS.RESET_PASSWORD:
      return {
        ...state,
        success: action.payload,
      };
    case ACTIONS.SEND_OTP:
      return {
        ...state,
        otp: action.payload,
      };
    case ACTIONS.LOGOUT:
      SecureStorage.removeItem("token");
      window.location.href = "/";
      return {
        ...state,
        ...initialState,
      };
    case ACTIONS.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case ACTIONS.GET_USER_DETAILS:
      return {
        ...state,
        userDetails: action.payload,
        loading: false,
      };
    case ACTIONS.CREATE_MODULE:
      return {
        ...state,
        createModuleData: action.payload,
      };
    case ACTIONS.POST_CREATE_MODULE:
      return {
        ...state,
        postCreateModuleData: action.payload,
      };
    case ACTIONS.POST_UPDATE_MODULE:
      return {
        ...state,
        postUpdateModuleData: action.payload,
      };
    case ACTIONS.DELETE_CREATE_MODULE:
      return {
        ...state,
        deleteCreateModuleData: action.payload,
      };
    case ACTIONS.PERFORMANCE_TABLE:
      return {
        ...state,
        performanceTableData: action.payload,
      };
    case ACTIONS.ASSIGNMENT_QUESTION_MASTER:
      return {
        ...state,
        assignmentQuestionMasterData: action.payload,
      };
    case ACTIONS.PUT_ASSIGNMENT_ANSWER_MASTER:
      return {
        ...state,
        assignmentAnswerMasterPutData: action.payload,
      };
    case ACTIONS.POST_ASSIGNMENT_ANSWER_MASTER:
      return {
        ...state,
        assignmentAnswerMasterPostData: action.payload,
      };
    case ACTIONS.GET_AGENTS:
      return {
        ...state,
        agentsList: action.payload,
      };
      case ACTIONS.LOCATION_DATA_FROM_PUSHER:
        return {
          ...state,
          locationDataFromPusher: action.payload,
        };
        case ACTIONS.WHATSAPP_ADDRESS_ID_DETAIL:
      return {
        ...state,
        whatsappAddressIdDetail: action.payload,
      };
    case ACTIONS.SET_ALL_BOOKING_LOADING:
      return {
        ...state,
        bookingListLoader: action.payload,
      };
    case ACTIONS.ADD_TICKETS_COMMENT:
      return {
        ...state,
        ticketsCommentAdded: action.payload,
        bookingListLoader: false,
      };
    case ACTIONS.GET_CONTAINER_SLOTS:
      return {
        ...state,
        containerSlots: action.payload,
        bookingListLoader: false,
      };
    default:
      return state;
  }
};

export default userReducer;
