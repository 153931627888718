import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@mui/material/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import {
    Button,
    MenuItem,
    Select,
    TextField,
    Grid,
    Checkbox,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {
    updateQueryTicket,
    updateActionDisposition,

} from "../../actions/LogisticsPanelAction";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";

let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "scroll",
        position: "sticky",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: isMobile ? "400px" : "1200px",
        overflow: "scroll",
        position: "sticky",
    },
    head: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "2rem",
        overflow: "scroll",
        position: "sticky",
    },
}));

type ModalProps = {
    open: boolean;
    bookingId: number;
    updateQueryTicket: any;
    complaint: any;
    queryType: any;
    queryDesc: any;
    queryStatus: any;
    leadId: number;
    ticketId: number;
    bookingList: any;
    ticketDetails: any;
    setOpen: Function;
    packageList: any;
    getPackage: any;
    getAvailableSlots: any;
    booking_slots: any;
    collectionDate: any;
    setCollectionDate: any;
    getQueryTickets: any;
    activeTab: any;
    zoneId: any;
};

const CommentsModal2: React.FC<ModalProps> = ({
    open,
    bookingId,
    leadId,
    updateQueryTicket,
    complaint,
    queryType,
    queryDesc,
    bookingList,
    queryStatus,
    ticketDetails,
    ticketId,
    setOpen,
    packageList,
    getPackage,
    getAvailableSlots,
    booking_slots,
    collectionDate,
    setCollectionDate,
    getQueryTickets,
    activeTab,
    zoneId,
}) => {
    const classes = useStyles();
    const history = useHistory();
    const [query, setQuery] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [status, setStatus] = useState<string>("pending");
    const [reciveRemark, setReciveRemark] = React.useState<any>("");
    const [errorMessage, setErrorMessage] = useState<any>("");
    const [bookingStatus, setBookingStatus] = useState<string>("none");
    const [dispositions, setDisposition] = useState<any>("");
    const [slotKey, setSlotKey] = useState<any>(0);
    const [slot, setSlot] = useState<any>("");
    const [packageId, setPackageId] = useState<any>([]);
    const [packageKey, setPackageKey] = useState<any>(0);
    const timer = useRef<any>(0);

    const handleClose = () => {
        setOpen(false);
        // history.push("/dashboard/lp/lead/tickets")
    };

    useEffect(() => {
        if (reciveRemark !== "") {
            setErrorMessage("");
        }
    }, [reciveRemark]);

    useEffect(() => {
        setQuery(queryType);
        setDescription(queryDesc);
        setStatus(queryStatus);
        setBookingStatus(bookingStatus);
    }, [queryType, queryDesc, queryStatus, bookingStatus]);

    const raiseComplaint = () => {
        const type = bookingId !== 0 ? "booking_ticket" : leadId !== 0 ? "lead_ticket" : "";
        const body: any = {
            status,
            querytype: query,
            query: description,
            bookingStatus,
            receicer_remarks: reciveRemark,
            action_disposition: dispositions,
        };
        const resamplingBody: any = {
            status,
            querytype: query,
            query: description,
            bookingStatus,
            receicer_remarks: reciveRemark,
            packages: packageId,
            collection_slot: slot,
            collection_date: collectionDate,
        };
        if (reciveRemark === "") {
            setErrorMessage("Please Enter Remarks");
        } else {
            if (
                (queryStatus === "resampling" && status === "confirmed") ||
                (queryStatus === "sample not received" && status === "confirmed")
            ) {
                if (collectionDate === "") {
                    alert("Select Collection Date");
                } else if (packageId.length == 0) {
                    alert("Select package");
                } else if (slot === "") {
                    alert("Select Slot");
                } else if (body.query == "" || body.query == null) {
                    alert("Add description.");
                }
                else {
                    updateQueryTicket(type, resamplingBody, ticketId);
                    getQueryTickets(activeTab);
                    handleClose();
                    history.push("/dashboard/lp/booking/tickets")
                }
            }
            // else  if  (queryType==body.querytype){
            //  
            //   
            //   alert(`Choose another QueryType. ${queryType} cannot assign to ${queryType}.`)
            // } 
            else if (body.query == "" || body.query == null) {
                alert("Add description.");
            } else {
                updateQueryTicket(type, body, ticketId);
                getQueryTickets(activeTab);
                setReciveRemark("");
                handleClose();
                history.push("/dashboard/lp/booking/tickets")
            }
        }
    };

    const handleCollectionDate = (e: any) => {
        setCollectionDate(e.target.value);
        setSlotKey(e.target.value.substring(8, 10));
    };

    useEffect(() => {
        if (complaint) {
            handleClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [complaint]);



    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <div className={classes.head}>
                            <h4
                                style={{
                                    marginBottom: "1rem",
                                }}
                                id="transition-modal-title"
                            >
                                Action
                            </h4>
                            <CloseIcon onClick={() => setOpen(false)} />
                        </div>
                        <Grid
                            container
                            direction="row"
                            alignItems="flex-start"
                            justify="space-between"
                            spacing={3}
                            style={{ margin: "1rem auto" }}
                        >
                            <Grid item xs={12} md={6}>
                                <p>Recive Remarks</p>
                                <TextField
                                    className="input"
                                    name="reciveRemark"
                                    type="text"
                                    value={reciveRemark}
                                    disabled={queryStatus === "confirmed"}
                                    variant="outlined"
                                    onChange={(e) => setReciveRemark(e.target.value as string)}
                                    style={{ width: "100%" }}
                                />
                                <p style={{ color: "red" }}>{errorMessage}</p>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <p>Query Type</p>
                                <Select
                                    className="input"
                                    name="action"
                                    variant="outlined"
                                    value={query}
                                    style={{ width: "100%", height: "40px" }}
                                    onChange={(e) => setQuery(e.target.value as string)}
                                >
                                    <MenuItem value={"SupportExecutive"}>
                                        SupportExecutive
                                    </MenuItem>
                                    <MenuItem value={"SampleAccessioning"}>
                                        SampleAccessioning
                                    </MenuItem>
                                    <MenuItem value={"CustomerSupport"}>CustomerSupport</MenuItem>
                                    <MenuItem value={"PhleboAdmin"}>PhleboAdmin</MenuItem>
                                    {/* <MenuItem value={"Doctor"}>Doctor</MenuItem> */}
                                </Select>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <p>Select Status</p>
                                <Select
                                    className="input"
                                    name="action"
                                    variant="outlined"
                                    value={status}
                                    style={{ width: "100%", height: "40px" }}
                                    disabled={dispositions != "resampling_fixed"}
                                    onChange={(e) => setStatus(e.target.value as string)}
                                >
                                    <MenuItem disabled value={"none"}>
                                        Select Status
                                    </MenuItem>
                                    <MenuItem value={"confirmed"}>Confirmed</MenuItem>
                                    <MenuItem value={"pending"}>Pending</MenuItem>
                                    <MenuItem value={"cancelled"}>Cancelled</MenuItem>
                                </Select>
                            </Grid>

                            {dispositions === "resampling_fixed" && status === "confirmed" ? (
                                <>
                                    <Grid item xs={12} md={6}>
                                        <p>Collection Date</p>
                                        <TextField
                                            name="collectionDate"
                                            type="date"
                                            value={collectionDate}
                                            className="input"
                                            label="Collection Date*"
                                            variant="outlined"
                                            disabled={queryStatus === "confirmed"}
                                            inputProps={{
                                                min: `${new Date().getFullYear()}-${new Date().getMonth() + 1 < 10
                                                        ? `0${new Date().getMonth() + 1}`
                                                        : new Date().getMonth() + 1
                                                    }-${new Date().getDate() < 10
                                                        ? `0${new Date().getDate()}`
                                                        : new Date().getDate()
                                                    }`,
                                            }}
                                            onChange={(e: any) => handleCollectionDate(e)}
                                            style={{ width: "100%", height: "40px" }}
                                        />
                                    </Grid>
                                    {/* //packages from the object */}
                                    <Grid item xs={12} md={6}>
                                        <Autocomplete
                                            id="slot"
                                            key={slotKey}
                                            onChange={(event, newValue) => {
                                                let sourceIds: any = "";
                                                if (newValue) {
                                                    let obj = JSON.parse(
                                                        JSON.stringify(newValue, null, " ")
                                                    );
                                                    setSlot(obj.id);
                                                }
                                            }}
                                            options={booking_slots || []}
                                            freeSolo
                                            blurOnSelect
                                            aria-required
                                            limitTags={1}
                                            disabled={
                                                collectionDate === "" || queryStatus === "confirmed"
                                            }
                                            getOptionLabel={(option: any) =>
                                                option.available_slots > 0
                                                    ? option.start_time +
                                                    "-" +
                                                    option.end_time +
                                                    "Available Slot :" +
                                                    option.available_slots
                                                    : ""
                                            }
                                            disableClearable
                                            disableCloseOnSelect
                                            // onInputChange={(event, newInputValue) => {
                                            //   clearTimeout(timer.current);
                                            //   timer.current = setTimeout(() => {
                                            //     getAvailableSlots(newInputValue);
                                            //   }, 1000);
                                            //   if (newInputValue.length === 0) {
                                            //     setSlot("");
                                            //   }
                                            // }}
                                            renderInput={(params) => (
                                                <TextField
                                                    className="input"
                                                    {...params}
                                                    label="Collection Slot"
                                                    variant="outlined"
                                                    style={{ width: "100%", margin: "0", padding: "0" }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        {
                                            ticketDetails.status === "resampling" && ticketDetails.resampling_packages.length === 0 ?
                                                <Autocomplete
                                                    id="package"
                                                    key={packageKey}
                                                    onChange={(event, newValue) => {
                                                        if (newValue) {
                                                            let packs: any = [];
                                                            newValue.map((val: any) => {
                                                                let obj = JSON.parse(
                                                                    JSON.stringify(val, null, " ")
                                                                );
                                                                packs.push(obj.id);
                                                            });
                                                            setPackageId(packs);
                                                        }
                                                    }}
                                                    multiple
                                                    disableClearable
                                                    options={packageList?.results || []}
                                                    limitTags={0}
                                                    freeSolo
                                                    disableCloseOnSelect
                                                    disabled={queryStatus === "confirmed"}
                                                    aria-required
                                                    onInputChange={(event, newInputValue) => {
                                                        clearTimeout(timer.current);
                                                        timer.current = setTimeout(() => {
                                                            getPackage(
                                                                `city_id=${ticketDetails?.booking?.cityId}&code=${newInputValue}&org_type=homedx&booking=${ticketDetails?.booking?.pk}`
                                                            );
                                                        }, 1000);
                                                    }}
                                                    getOptionLabel={(option: any) =>
                                                        option?.name && option?.package_city_prices
                                                            ? option?.name?.toString() +
                                                            ", " +
                                                            option?.package_city_prices?.tat_time
                                                            : option?.name + ", " + option?.tat_time
                                                    }
                                                    renderOption={(option, { selected }) => (
                                                        <React.Fragment>
                                                            <Checkbox
                                                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                                style={{ marginRight: 8 }}
                                                                checked={selected}
                                                            />
                                                            {option?.package_city_prices
                                                                ? option?.name?.toString() +
                                                                ", " +
                                                                option?.package_city_prices?.tat_time
                                                                : option?.name?.toString() +
                                                                ", " +
                                                                option?.tat_time}
                                                        </React.Fragment>
                                                    )}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            className="input"
                                                            {...params}
                                                            placeholder="Package"
                                                            variant="outlined"
                                                            style={{ width: "100%", margin: "0", padding: "0" }}
                                                        />
                                                    )}
                                                />
                                                : <Autocomplete
                                                    id="package"
                                                    key={packageKey}
                                                    onChange={(event, newValue) => {
                                                        if (newValue) {
                                                            let packs: any = [];
                                                            newValue.map((val: any) => {
                                                                let obj = JSON.parse(
                                                                    JSON.stringify(val, null, " ")
                                                                );
                                                                packs.push(obj.id);
                                                            });
                                                            setPackageId(packs);
                                                        }
                                                    }}
                                                    multiple
                                                    disableClearable
                                                    options={ticketDetails?.resampling_packages || []}
                                                    limitTags={0}
                                                    freeSolo
                                                    disableCloseOnSelect
                                                    disabled={queryStatus === "confirmed"}
                                                    aria-required

                                                    getOptionLabel={(option: any) =>
                                                        option?.name +
                                                        ", (" +
                                                        option?.code + ")"
                                                    }
                                                    renderOption={(option, { selected }) => (
                                                        <React.Fragment>
                                                            <Checkbox
                                                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                                style={{ marginRight: 8 }}
                                                                checked={selected}
                                                            />
                                                            {option?.name +
                                                                ", (" +
                                                                option?.code + ")"}
                                                        </React.Fragment>
                                                    )}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            className="input"
                                                            {...params}
                                                            placeholder="Package"
                                                            variant="outlined"
                                                            style={{ width: "100%", margin: "0", padding: "0" }}
                                                        />
                                                    )}
                                                />
                                        }

                                    </Grid>
                                </>
                            ) : (
                                ""
                            )}

                            <Grid item xs={12} md={6}>
                                <p>Disposition</p>
                                <Select
                                    className="input"
                                    name="action"
                                    variant="outlined"
                                    value={dispositions}
                                    style={{ width: "100%", height: "40px" }}
                                    onChange={(e) => setDisposition(e.target.value as string)}
                                >
                                    <MenuItem value={"customer_not_responding"}>
                                        customer_not_responding
                                    </MenuItem>
                                    <MenuItem value={"refund_request"}>refund_request</MenuItem>
                                    <MenuItem value={"customer_not_contactable"}>
                                        customer_not_contactable
                                    </MenuItem>
                                    <MenuItem value={"call_later"}>call_later</MenuItem>
                                    <MenuItem value={"resampling_fixed"}>
                                        resampling_fixed
                                    </MenuItem>
                                    <MenuItem value={"completed"}>completed</MenuItem>
                                </Select>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name="Comment"
                                    type="text"
                                    multiline
                                    // disabled
                                    rows={3}
                                    value={description}
                                    className="input"
                                    variant="outlined"
                                    placeholder="Description"
                                    onChange={(e) => setDescription(e.target.value as string)}
                                    style={{ width: "100%" }}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    style={{ height: "50px" }}
                                    onClick={raiseComplaint}
                                >
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
};

const mapStateToProps = (state: any) => ({
    complaint: state.LogisticsReducer.complaint,
    disposition: state.LogisticsReducer.disposition,
});

export default connect(mapStateToProps, {
    updateQueryTicket,
    updateActionDisposition,
})(CommentsModal2);