import { ACTIONS } from "../interfaces/actionTypes/RouteManagerInterface";
import AXIOS from "../../config/Axios";
import Prefix from "../../config/ApiPrefix";
import PrefixBeta from "../../config/ApiPrefixBeta";
import snackBarUpdate from "../../actions/snackBarActions";
import SecureStorage from "../../config/SecureStorage";

export const changePassword = (body: any) => async (dispatch: Function) => {
  try {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    await AXIOS.post(
      `${Prefix.api}/auth/password_reset/reset_password/`,
      body,
      {
        headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
      }
    );
    dispatch({ type: ACTIONS.CHANGE_PASSWORD, payload: null });
    snackBarUpdate({
      payload: {
        message: "Password Updated",
        status: true,
        type: "success",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = JSON.stringify(err.response.data?.errors).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const clickToCall =
  (id: number, contact_type: string) => async (dispatch: Function) => {
    try {
      await AXIOS.get(`${Prefix.api}/lead/clicktocall/${id}/${contact_type}/`, {
        headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
      });
      dispatch({ type: ACTIONS.CLICK_TO_CALL, payload: null });
      snackBarUpdate({
        payload: {
          message: "Call Succeded",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

export const createAdditionalBooking =
  (id: any, bookingData: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api}/booking/additional_booking/${id}/`,
        bookingData,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.CREATE_ADDITIONAL_BOOKING, payload: data });
      snackBarUpdate({
        payload: {
          message: "Family member added",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

export const updateAdditionalBooking =
  (id: any, bookingData: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.put(
        `${Prefix.api}/booking/ret_up_additional_booking/${id}/`,
        bookingData,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.CREATE_ADDITIONAL_BOOKING, payload: data });
      snackBarUpdate({
        payload: {
          message: "Family member updated",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

export const getAssignedUnassignedBooking =
  (url: any, reset: any = false) =>
  async (dispatch: Function) => {
    if (reset) {
      dispatch({ type: ACTIONS.GET_ASSIGNED_UNASSIGNED_BOOKING, payload: {} });
      return;
    }
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/booking/list/${url ? `?${url}` : ""}`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: ACTIONS.GET_ASSIGNED_UNASSIGNED_BOOKING,
        payload: data,
      });
    } catch (err: any) {
      let title = "";
      if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.detail) {
        title = JSON.stringify(err.response.data.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else{
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

export const getPinCode = (url: string) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/phlebo/pincode/${url ? `?code=${url}` : ""}`,

      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_PIN_CODE, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const getPhlebos = (url: string) => async (dispatch: Function) => {
  // dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    if (url === "?undefined") {
      url = "?page=1";
    }

    const { data } = await AXIOS.get(
      `${Prefix.api}/phlebo/data/${url ? `${url}` : ""}`,

      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_PHLEBOS, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    // dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const updatePhlebos =
  (body: any, url: string) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.put(
        `${Prefix.api}/phlebo/edit/${url ? `${url}/` : ""}`,
        body,

        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.UPDATE_PHLEBOS, payload: data });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

export const getCities = (url: string) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/core/cities/${url ? `?q=${url}` : ""}`,
      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_CITIES, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const getArea = (url: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/core/area/${url ? `?${url}` : ""}`,
      {
        headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
      }
    );
    dispatch({
      type: ACTIONS.GET_AREA,
      payload: data,
    });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const getCollectionSlot =
  (url: string) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/phlebo/timeslots/${url ? `?q=${url}` : ""}`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_COLLECTION_SLOT, payload: data });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

export const getAreaName = (url: string) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/core/city-area/${url ? `?id=${url}` : ""}`,
      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_AREA, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const getAgentList =
  (url: string, usergroup: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/panel/data/${url ? `?code=${url}` : ""}${
          usergroup ? `${url ? "&" : "?"}usergroup=${usergroup}` : ""
        }`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      if (usergroup) {
        dispatch({ type: ACTIONS.GET_AGENTS_BY_USERGROUP, payload: data });
      } else {
        dispatch({ type: ACTIONS.GET_AGENTS, payload: data });
      }
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

export const getVerifiedbyList =
  (url: string) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/panel/data/${url ? url : ""}`,

        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_VERIFIED_BY_LIST, payload: data });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

export const getPackage = (url: string) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${PrefixBeta.api}/core/packages/crmdata${url ? `?${url}` : ""}`,
      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_PACKAGES, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const getPartners = (url: string) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/partner/data/${url ? `?code=${url}` : ""}`,
      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_PARTNERS, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const getPhleboListUnderRouteManager =
  (url: string) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api2}/list-phlebo-under/${url ? `${url}` : ""}`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: ACTIONS.GET_PHLEBO_LIST_UNDER_ROUTE_MANAGER,
        payload: data,
      });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
export const getPhleboLeaveList =
  (url: string) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api2}/list-phlebo-under-leave/${url ? `${url}` : ""}`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_PHLEBO_LEAVE_LIST, payload: data });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
export const createLeaveList = (body: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.post(`${Prefix.api2}/create-leave/`, body, {
      headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
    });
    dispatch({
      type: ACTIONS.CREATE_PHLEBO_LEAVE,
      payload: data,
    });
    snackBarUpdate({
      payload: {
        message: "Leave Apply Successfully",
        status: true,
        type: "success",
      },
    })(dispatch);
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const updatePhleboLeave =
  (body: any, id: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.put(
        `${Prefix.api2}/update-leave/${id}/`,
        body,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: ACTIONS.UPDATE_PHLEBO_LEAVE,
        payload: data,
      });
      snackBarUpdate({
        payload: {
          message: "Leave Updated Successfully",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

export const getCoupons = (url: string) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/coupon/data/${url ? `?code=${url}` : ""}`,
      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_COUPONS, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const createNewBooking =
  (bookingData: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api}/booking/data/`,
        bookingData,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.CREATE_BOOKING, payload: data });
      snackBarUpdate({
        payload: {
          message: "Booked",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
export const getAllBookings = (url: string) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_ALL_BOOKING_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/booking/data/${url ? `?${url}` : ""}`,
      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_BOOKING_LIST, payload: data });
  } catch (err: any) {
    let title = "";
    if (err?.response?.data?.detail) {
      title = err.response.data.detail;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.GET_BOOKING_LIST, payload: [] });
    dispatch({ type: ACTIONS.SET_ALL_BOOKING_LOADING, payload: false });
    throw err;
  }
};

export const getAllNewBookings = (url: string) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_ALL_BOOKING_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${PrefixBeta.api}/core/bookings/${url ? `?${url}` : ""}`,
      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_BOOKING_LIST, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.detail;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong!",
        status: true,
        type:
          err.response.data.detail ===
            "An Email has been sent to your registered mail address"
            ? "success"
            : "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.GET_BOOKING_LIST, payload: [] });
    throw err;
  }
  dispatch({ type: ACTIONS.SET_ALL_BOOKING_LOADING, payload: false });
};

export const getAllBookings1 = (url: string) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.UNASSIGNED_BOOKING_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/booking/data/?rm=true${url ? `&${url}` : ""}`,
      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_BOOKING_LIST1, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.detail;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong!",
        status: true,
        type:
          err.response.data.detail ===
          "An Email has been sent to your registered mail address"
            ? "success"
            : "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.GET_BOOKING_LIST1, payload: [] });
    dispatch({ type: ACTIONS.UNASSIGNED_BOOKING_LOADING, payload: false });
    throw err;
  }
};
export const getAllBookings2 = (url: string) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.PHLEBO_HOLD_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/booking/data/?rm=true${url ? `&${url}` : ""}`,
      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_BOOKING_LIST2, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.detail;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong!",
        status: true,
        type:
          err.response.data.detail ===
          "An Email has been sent to your registered mail address"
            ? "success"
            : "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.GET_BOOKING_LIST2, payload: [] });
    dispatch({ type: ACTIONS.PHLEBO_HOLD_LOADING, payload: false });
    throw err;
  }
};
export const getAllBookings3 = (url: string) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.PHLEBO_LATE_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/booking/data/?rm=true${url ? `&${url}` : ""}`,
      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_BOOKING_LIST3, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.detail;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong!",
        status: true,
        type:
          err.response.data.detail ===
          "An Email has been sent to your registered mail address"
            ? "success"
            : "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.GET_BOOKING_LIST3, payload: [] });
    dispatch({ type: ACTIONS.PHLEBO_LATE_LOADING, payload: false });
    throw err;
  }
};
export const getAllBookings4 = (url: string) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.PHLEBO_VERY_LATE_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/booking/data/?rm=true${url ? `&${url}` : ""}`,
      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_BOOKING_LIST4, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.detail;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong!",
        status: true,
        type:
          err.response.data.detail ===
          "An Email has been sent to your registered mail address"
            ? "success"
            : "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.GET_BOOKING_LIST4, payload: [] });
    dispatch({ type: ACTIONS.PHLEBO_VERY_LATE_LOADING, payload: false });
    throw err;
  }
};
export const getAllBookings5 = (url: string) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.PHLEBO_NOT_STARTED_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/booking/data/?rm=true${url ? `&${url}` : ""}`,
      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_BOOKING_LIST5, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.detail;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong!",
        status: true,
        type:
          err.response.data.detail ===
          "An Email has been sent to your registered mail address"
            ? "success"
            : "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.GET_BOOKING_LIST4, payload: [] });
    dispatch({ type: ACTIONS.PHLEBO_NOT_STARTED_LOADING, payload: false });
    throw err;
  }
};
export const getAllOnlineBookings =
  (url: string) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/booking/b2c_bookings/${url ? `?${url}` : ""}`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_ONLINE_BOOKING_LIST, payload: data });
    } catch (err: any) {
      let title = "";
      if (err.response?.data?.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.GET_ONLINE_BOOKING_LIST, payload: [] });
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
export const getBookings = (url: string) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_BOOKING_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/booking/data/${url ? `search?id=${url}` : ""}`,
      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_BOOKING_LIST, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.GET_BOOKING_LIST, payload: [] });
    // dispatch({ type: ACTIONS.SET_BOOKING_LOADING, payload: false });
    throw err;
  }
};

export const getBookingById = (id: string) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/booking/data/${id ? `${id}` : ""}`,
      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_BOOKING_BY_ID, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const updateBooking =
  (bookingData: any, id: any) => async (dispatch: Function) => {
    // dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.put(
        `${Prefix.api}/booking/data/${id}/`,
        bookingData,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.CREATE_BOOKING, payload: data });
      snackBarUpdate({
        payload: {
          message: "Booking Updated",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.message) {
        title = JSON.stringify(err.response.data.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      // dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
export const updateArea =
  (id: any, body: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.put(`${Prefix.api}/core/area/${id}/`, body, {
        headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
      });
      dispatch({
        type: ACTIONS.UPDATE_AREA,
        payload: data,
      });
      snackBarUpdate({
        payload: {
          message: "Area Updated",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
export const createArea = (body: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.post(`${Prefix.api}/core/area/create/`, body, {
      headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
    });
    dispatch({
      type: ACTIONS.CREATE_AREA,
      payload: data,
    });
    snackBarUpdate({
      payload: {
        message: "Area Created",
        status: true,
        type: "success",
      },
    })(dispatch);
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const getLeadDetails = (id: string) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(`${Prefix.api}/lead/lead-update/${id}/`, {
      headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
    });
    dispatch({ type: ACTIONS.GET_LEAD_DETAILS, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = JSON.stringify(err.response.data?.errors).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const updateLeadDetailsF_2 =
  (id: string, body: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    const { followup_date, followup_time, language } = body;
    try {
      const { data } = await AXIOS.patch(
        `${Prefix.api}/lead/lead-update/${id}/`,
        { followup_date, followup_time, language },
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_LEAD_DETAILS, payload: data });
      snackBarUpdate({
        payload: {
          message: "Success",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

export const updateLeadDetailsF_1 =
  (id: string, body: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    const {
      patient_name,
      patient_age,
      calling_gender,
      priority,
      content_number,
      whatsapp_contact,
      address,
      patient_city,
      patient_area,
    } = body;
    try {
      const { data } = await AXIOS.patch(
        `${Prefix.api}/lead/lead-update/${id}/`,
        {
          patient_name,
          patient_age,
          calling_gender,
          priority,
          content_number,
          whatsapp_contact,
          address,
          patient_city,
          patient_area,
        },
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_LEAD_DETAILS, payload: data });
      snackBarUpdate({
        payload: {
          message: "Success",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

export const getLeads = (body: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.post(`${Prefix.api}/panel/search/`, body, {
      headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
    });
    dispatch({ type: ACTIONS.GET_LEADS, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const resetLeadDetails = () => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.LEAD_DETAILS_RESET });
};

export const getLeadById = () => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(`${Prefix.api}/lead/dashboard/`, {
      headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
    });
    dispatch({ type: ACTIONS.GET_LEAD_BY_ID, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const getPositiveCallsComments =
  (id: number) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/lead/lead-related-comments/${id}/`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: ACTIONS.GET_POSTIVE_CALLS_COMMENTS,
        payload: data,
      });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

export const addPositiveCallComment =
  (body: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    const { comment, callId } = body;
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api}/lead/lead-details/${callId}/`,
        {
          comment,
        },
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      snackBarUpdate({
        payload: {
          message: "Created!",
          status: true,
          type: "success",
        },
      })(dispatch);

      dispatch({
        type: ACTIONS.ADD_POSTIVE_CALLS_COMMENT,
        payload: data,
      });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

export const setCFreshComments = (body: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  const { category, sub_category, lead_comment, comment } = body;
  try {
    const { data } = await AXIOS.post(
      `${Prefix.api}/lead/status-allot/`,
      {
        category,
        sub_category,
        lead_comment,
        comment,
      },
      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.SET_COMMENTS, payload: data });
    snackBarUpdate({
      payload: {
        message: "Success!",
        status: true,
        type: "success",
      },
    })(dispatch);
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const coustomFileUpload =
  (file: File, usertype: String, source: String) =>
  async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const formData = new FormData();
      formData.append("file", file);
      const { data } = await AXIOS.post(
        `${Prefix.api}/upload/custom-upload/?usertype=${usertype}&source=${source}`,
        formData,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.UPLOAD_FILE, payload: data });
      snackBarUpdate({
        payload: {
          message: "Success!",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

export const clickToCallBooking =
  (id: number, contact_type: string) => async (dispatch: Function) => {
    try {
      await AXIOS.get(
        `${Prefix.api}/booking/clicktocall/${id}/${contact_type}/`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.CLICK_TO_CALL, payload: null });
      snackBarUpdate({
        payload: {
          message: "Call Succeded",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

export const resetBookings = () => async (dispatch: Function) => {
  dispatch({
    type: ACTIONS.BOOKING_RESET,
  });
};

export const addComment = (body: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  const { comment, callId } = body;
  try {
    const { data } = await AXIOS.post(
      `${Prefix.api}/lead/lead-details/${callId}/`,
      {
        comment,
      },
      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    snackBarUpdate({
      payload: {
        message: "Created!",
        status: true,
        type: "success",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.ADD_COMMENT, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = JSON.stringify(err.response.data?.errors).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const createCall = (body: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  const { name, phone_no, source } = body;

  try {
    const { data } = await AXIOS.post(
      `${Prefix.api}/lead/generate-lead-by-user/`,
      {
        name,
        phone_no,
        source,
      },
      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.CREATE_CALL, payload: data.lead });
    snackBarUpdate({
      payload: {
        message: "Success!",
        status: true,
        type: "success",
      },
    })(dispatch);
    // window.location.assign("/agent/dashboard/patient/create-appointment");
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const getLeadSource = () => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });

  try {
    const { data } = await AXIOS.get(`${Prefix.api}/lead/lead-source/`, {
      headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
    });
    dispatch({ type: ACTIONS.GET_LEAD_SOURCE, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = JSON.stringify(err.response.data?.errors).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const getAllRecordings = (url: string) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/communication/call_status/${url ? url : ""}`,
      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_RECORDINGS, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = JSON.stringify(err.response.data?.errors).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const getCurrentDayBooking =
  (url: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/analytics/booking_analysis/${url ? `?${url}` : ""}`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_CURRENT_DAY_BOOKING, payload: data });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
export const getCityWisePhlebos = (url: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/analytics/citywise_phlebo/${url ? `?${url}` : ""}`,
      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_CITY_WISE_PHLEBOS, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const getBookingsComments =
  (id: number) => async (dispatch: Function) => {
    // dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/lead/booking-related-comments/${id}/`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: ACTIONS.GET_BOOKINGS_COMMENTS,
        payload: data,
      });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      // dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
export const getPhleboComments = (id: number) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/phlebo/booking-related-comments/${id}/`,
      {
        headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
      }
    );

    dispatch({
      type: ACTIONS.GET_PHLEBO_COMMENTS,
      payload: data,
    });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const getZoneComments = (id: number) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/phlebo/zone_comments/${id}/`,
      {
        headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
      }
    );

    dispatch({
      type: ACTIONS.GET_ZONE_COMMENTS,
      payload: data,
    });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const addBookingsComment = (body: any) => async (dispatch: Function) => {
  const { comment, bookingId } = body;
  try {
    const { data } = await AXIOS.post(
      `${Prefix.api}/lead/booking-details/${bookingId}/`,
      {
        comment,
      },
      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    snackBarUpdate({
      payload: {
        message: "Created!",
        status: true,
        type: "success",
      },
    })(dispatch);

    dispatch({
      type: ACTIONS.ADD_BOOKINGS_COMMENT,
      payload: data,
    });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    throw err;
  }
};

export const getWhatsappComments = (url: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/communication/whatsapp_message_comment/${
        url ? `?${url}` : ""
      }`,
      {
        headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
      }
    );
    dispatch({
      type: ACTIONS.GET_WHATSAPP_COMMENTS,
      payload: data,
    });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const getWhatsappCommentsByLead =
  (url: string) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/communication/whatsapp_message_comment/?${url}`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: ACTIONS.GET_WHATSAPP_COMMENTS_BY_LEAD,
        payload: data,
      });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

export const sendWhatsappComments =
  (leadId: any, message: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api}/communication/send_whatsapp_message/${leadId}/`,
        { message },
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: ACTIONS.SEND_WHATSAPP_COMMENTS,
        payload: data,
      });
      snackBarUpdate({
        payload: {
          message: "Message Created",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

export const getCallStats = () => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/analytics/panaluser_calls/`,
      {
        headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
      }
    );
    dispatch({
      type: ACTIONS.GET_CALL_STATS,
      payload: data,
    });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const raiseNewComplaint =
  (type: any, body: any, id: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api}/booking/${type}/create/${id}/`,
        body,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: ACTIONS.RAISE_COMPLAINT,
        payload: data,
      });
      snackBarUpdate({
        payload: {
          message: "Ticket Raised",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

export const getQueryTickets =
  (type: any, id: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/booking/${type}/data/${id ? `?${id}` : ""}`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: ACTIONS.GET_COMPLAINTS,
        payload: data,
      });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

export const updateQueryTicket =
  (type: any, body: any, id: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.put(
        `${Prefix.api}/booking/${type}/${id}/`,
        body,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: ACTIONS.RAISE_COMPLAINT,
        payload: data,
      });
      snackBarUpdate({
        payload: {
          message: "Ticket Updated",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

export const complaintReset = () => async (dispatch: Function) => {
  dispatch({
    type: ACTIONS.RAISE_COMPLAINT,
    payload: {},
  });
};

export const assignDoctor = (body: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.post(
      `${Prefix.api}/booking/doctor_cunsultation/`,
      body,
      {
        headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
      }
    );
    dispatch({
      type: ACTIONS.ASSIGN_DOCTOR,
      payload: data,
    });
    snackBarUpdate({
      payload: {
        message: "Doctor Assigned",
        status: true,
        type: "success",
      },
    })(dispatch);
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const getDoctorBookings = (url: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/booking/doctor_cunsultation/${url ? `?${url}` : ""}`,
      {
        headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
      }
    );
    dispatch({
      type: ACTIONS.GET_BOOKING_LIST,
      payload: data,
    });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const getDoctorBookingById = (id: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/booking/doctor_cunsultation/${id}/`,
      {
        headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
      }
    );
    dispatch({
      type: ACTIONS.GET_BOOKING_BY_ID,
      payload: data,
    });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const getAvailableSlots =
  (collection_date: any, customer_zone: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/booking/booking_slot_collection_date/?collection_date=${
          collection_date ? collection_date : ""
        }&customer_zone=${customer_zone ? customer_zone : ""}`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_AVAILABLE_SLOTS, payload: data });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

export const getAllLeads = (query: string) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });

  try {
    const { data } = await AXIOS.get(`${Prefix.api}/lead/lead/${query}`, {
      headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
    });
    dispatch({ type: ACTIONS.GET_ALL_LEADS, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const leadTransferByComma =
  (body: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });

    const { lead_ids, value, panel_user, status_call, count, priority } = body;

    try {
      const { data } = await AXIOS.post(
        `${Prefix.api}/panel/lead-transfer/`,
        { lead_ids, value, panel_user, status_call, count, priority },
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.LEAD_TRANSFER_BY_COMMA, payload: data });
      snackBarUpdate({
        payload: {
          message: "Transferred!",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

export const leadTransfer = () => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });

  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/panel/lead-transfer/`,

      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_PANEL_USERS, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const searchCall = (body: any) => async (dispatch: Function) => {
  const { search_by, value } = body;
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.post(
      `${Prefix.api}/panel/search/`,
      {
        search_by,
        value,
      },
      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.SEARCH_CALL, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const getZone = (url: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/phlebo/zone/data/${url ? `?city_name=${url}` : ""}`,
      {
        headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
      }
    );
    dispatch({
      type: ACTIONS.GET_ZONE,
      payload: data,
    });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const createZone = (body: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.post(
      `${Prefix.api}/phlebo/zone/create/`,
      body,
      {
        headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
      }
    );
    dispatch({
      type: ACTIONS.CREATE_ZONE,
      payload: data,
    });
    snackBarUpdate({
      payload: {
        message: "Zone Created",
        status: true,
        type: "success",
      },
    })(dispatch);
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const updateZone =
  (id: any, body: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.put(
        `${Prefix.api}/phlebo/zone/edit/${id}/`,
        body,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: ACTIONS.UPDATE_ZONE,
        payload: data,
      });
      snackBarUpdate({
        payload: {
          message: "Zone Updated",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

export const getTimeslots = () => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(`${Prefix.api}/phlebo/timeslots/`, {
      headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
    });
    dispatch({
      type: ACTIONS.GET_TIMESLOTS,
      payload: data,
    });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const getAttendence = () => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(`${Prefix.api}/phlebo/attendance/`, {
      headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
    });
    dispatch({
      type: ACTIONS.GET_ATTENDENCE,
      payload: data,
    });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const getAttenList = (url: string) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/phlebo/attendance/${url ? `search?id=${url}` : ""}`,
      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_ATTENDENCE, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.GET_ATTENDENCE, payload: [] });
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const getAllAttendence = (url: string) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/phlebo/attendance/${url ? `?${url}` : ""}`,
      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_ATTENDENCE, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.GET_ATTENDENCE, payload: [] });
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const updateUserList =
  (body: any, id: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.put(
        `${Prefix.api}/phlebo/edit/${id}/`,
        body,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: ACTIONS.UPDATE_USERS_LIST,
        payload: data,
      });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
export const addRemarkOnCall =
  (leadId: any, body: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.put(
        `${Prefix.api}/communication/call_status/${leadId}/`,
        body,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.ADD_REMARK_ON_CALL, payload: data });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
export const getPaymentSendLink = (url: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/payment/send_link/${url ? `${url}/` : ""}`,

      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_PAYMENT_SEND_LINK, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.detail;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const getCenterInfo = (url: string) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/center/data/${url ? url : ""}`,
      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_CENTER_INFORMATION, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.GET_CENTER_INFORMATION, payload: [] });
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const getNotificationMessage =
  (url: string) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/notification/send/${url ? url : ""}`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_NOTIFICATION_MESSAGE, payload: data });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
export const getVerificationBookingDetails =
  (url: string) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/communication/verification_booking_call/${
          url ? `?${url}` : ""
        }`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: ACTIONS.GET_VERIFICATION_BOOKING_DETAILS,
        payload: data,
      });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
export const getUnmaskedNumber =
  (id: any, source: any, type: any) => async (dispatch: Function) => {
    // dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/panel/unmasked/${id ? `${id}` : ""}/${
          source ? `${source}` : ""
        }/${type ? `${type}` : ""}/`,

        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_UNMASKED_NUMBER, payload: data });
    }catch (err: any) {
      let title = "";
      if (err.response) {
        title = JSON.stringify(err.response.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      }
      else if (err?.response?.data?.errors) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.msg) {
        title = JSON.stringify(err.response.data?.msg).replace(
          /"|{|}|[|]/g,
          ""
        );
      }
       else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      // dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
export const getQRCodeData = (url: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/booking/received_packages/${url}`,

      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_QRCODE_DATA, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const updateTubeStatus =
  (id: any, tubeData: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.put(
        `${Prefix.api}/booking/received_packages/${id ? `${id}/` : ""}`,
        tubeData,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_PACKAGE_TUBE_DETAILS, payload: data });
      snackBarUpdate({
        payload: {
          message: "Tube Status updated",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
export const updatePhleboLocation =
  (phleboData: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api}/phlebo/edit-phlebo-location/`,
        phleboData,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_PHLEBO_BY_PHONE, payload: data });
      snackBarUpdate({
        payload: {
          message: "Phlebo Home Location Updated.",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
export const bookingAutoAssign =
  (zoneData: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api}/phlebo/booking-auto-assign/`,
        zoneData,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.AUTO_ASSIGN_BOOKING, payload: data });
      snackBarUpdate({
        payload: {
          message: "Booking has been allotted to the selected zone.",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
export const getBookingJourney = (id: string) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/booking/journey-details/${id ? `${id}/` : ""}`,
      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_BOOKING_JOURNEY_DETAILS, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const getPaymentInfo = (url: string) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/payment/info/${url ? url : ""}`,
      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );

    dispatch({ type: ACTIONS.GET_PAYMENT_INFORMATION, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const updatePhleboReachedStatus =
  (phleboStatus: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api}/booking/phlebo_loc/`,
        phleboStatus,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.UPDATE_PHLEBO_STATUS, payload: data });
      snackBarUpdate({
        payload: {
          message: "Phlebo Status Updated ",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
export const getAdditionalBookingData =
  (url: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/booking/ret_up_additional_booking/${
          url ? `${url}/` : ""
        }`,

        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_ADDITIONAL_BOOKING, payload: data });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
export const getSignature = (url: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/booking/signature/${url ? `${url}/` : ""}`,

      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_SIGNATURE, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const getUnscannedTubeTube =
  (id: any, type: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/booking/unscanned-tube/${id ? `${id}/${type}` : ""}`,

        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_PACKAGE_ALL_TUBE, payload: data });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

export const CreateBarcode =
  (id: any, barcodeData: any, url: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api}/booking/qrcode1/${id}/${url ? url : ""}`,
        barcodeData,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.CREATE_BARCODE, payload: data });
      snackBarUpdate({
        payload: {
          message: "Tube Update Successfully",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.detail;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
export const postAddRemoveTest =
  (paymentData: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api}/booking/add_remove/`,
        paymentData,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.POST_ADD_REMOVE_TEST, payload: data });
      snackBarUpdate({
        payload: {
          message: "Data successfully saved!",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = String(err.response.data.errors);
      } else {
        title = "Data not saved!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
export const getCCPackage =
  (centre: string, city: string, name: string) =>
  async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/package/cc-package/${
          centre ? `?center=${centre}&cityid=${city}&name=${name}` : ""
        }`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_CC_PACKAGES, payload: data });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = String(err.response.data.detail);
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

export const getUserDetails = (url: string) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(`${Prefix.api}/auth/shortprofile/`, {
      headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
    });
    dispatch({ type: ACTIONS.GET_USER_DETAILS, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.GET_USER_DETAILS, payload: [] });
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const getMapMyIndiaAccessToken = () => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.GET_MAMPMYINDIA_ACCESS_TOKEN, payload: [] });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/panel/mapmyindia-auth-token/`,
      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_MAMPMYINDIA_ACCESS_TOKEN, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    throw err;
  }
};

export const getMapBookings = (url: string) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/booking/data/${url ? `?data_type=map&${url}` : ""}`,
      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_MAP_BOOKING_LIST, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.GET_BOOKING_LIST, payload: [] });
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const getPhleboCurrentLocation =
  (url: number) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/phlebo/phlebo-current-location/${url}`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );

      dispatch({
        type: ACTIONS.GET_PHLEBO_CURRENT_LOCATION,
        payload: data,
      });
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.detail) {
        title = JSON.stringify(err.response.data.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
export const getAreaComments = (id: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/core/comments/${id ? `${id}/` : ""}`,

      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_AREA_COMMENTS, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.detail;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const getSampleHandOver = (url: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/phlebo/sample_handover/${url ? `?${url}` : ""}`,

      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_SAMPLE_HANDOVER_DATA, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const updateRiderToLabStatus =
  (body: any, id: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.put(
        `${Prefix.api}/phlebo/sample_handover/${id}/`,
        body,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: ACTIONS.UPDATE_RIDER_TO_LAB_STATUS,
        payload: data,
      });
      snackBarUpdate({
        payload: {
          message: "Lab Status Updated",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
export const getRiderList =
  (url: string, usergroup: any) => async (dispatch: Function) => {
    // dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/panel/data/${url ? url : ""}`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_RIDER_LIST, payload: data });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      // dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
export const getPhleboRiderDueAmountData =
  (url: string) => async (dispatch: Function) => {
    // dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/phlebo/sample-handover-due/${url ? url : ""}`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_PHLEBO_RIDER_DUE_AMOUNT, payload: data });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      // dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
export const getSampleJourneyComments =
  (id: number) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(`${Prefix.api}/phlebo/sample/${id}/`, {
        headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
      });
      dispatch({
        type: ACTIONS.GET_SAMPLE_JOURNEY_COMMENTS,
        payload: data,
      });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
export const getTicketData = (url: string) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });

  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/redtech/tasks/${url ? `${url}` : ""}`,

      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_TICKET_DATA, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const getTicketCategories = (url: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });

  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/redtech/categories/${url ? `${url}/` : ""}`,

      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_TICKET_CATEGORIES, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const getTicketSubCategories =
  (url: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });

    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/redtech/sub-categories/${url ? `${url}` : ""}`,

        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_TICEKT_SUB_CATEGORIES, payload: data });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
export const CreateTaskTicket = (body: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.post(`${Prefix.api}/redtech/tasks/`, body, {
      headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
    });
    dispatch({ type: ACTIONS.CREATE_TASK_TICKET, payload: data });
    snackBarUpdate({
      payload: {
        message: "Task Created Successfully",
        status: true,
        type: "success",
      },
    })(dispatch);
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const getRedTechComment = (id: number) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/redtech/comments/?ticket=${id}&ordering=-created_at`,

      {
        headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
      }
    );
    dispatch({
      type: ACTIONS.GET_RED_TECH_COMMENT,
      payload: data,
    });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err?.response?.data?.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const postRedTechComment = (body: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.post(`${Prefix.api}/redtech/comments/`, body, {
      headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
    });
    dispatch({
      type: ACTIONS.POST_RED_TECH_COMMENT,
      payload: data,
    });
    snackBarUpdate({
      payload: {
        message: "Message Created",
        status: true,
        type: "success",
      },
    })(dispatch);
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.message;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const getBatchBookingAmount =
  (url: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/phlebo/batch_booking_amount/${url}`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: ACTIONS.GET_BATCH_BOOKING_AMOUNT,
        payload: data,
      });
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.detail) {
        title = JSON.stringify(err.response.data.detail);
        dispatch({
          type: ACTIONS.GET_BATCH_BOOKING_AMOUNT,
          payload: title,
        });
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

export const getHivPackage =
  (url: string, id: any) => async (dispatch: Function) => {
    // dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/booking/hiv-booking/${url}`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_HIV_PACKAGES, payload: data });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.detail;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      // dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

export const getBiomarkerScreening =
  (id: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/booking/biomarker_screening/${id ? `?${id}` : ""}`,

        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_BIOMARKER_SCREENING, payload: data });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.detail;
      } else {
        title = "Not found";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

export const getBiomarkerUltasound =
  (id: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}booking/ultasound_details/${
          id ? `?booking_id=${id}` : ""
        }`,

        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_BIOMARKER_ULTASOUND, payload: data });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.detail;
      } else {
        title = "Not found";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

export const getAdditionalMember = (id: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/booking/additional_booking/${id}/`,
      {
        headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
      }
    );
    dispatch({ type: ACTIONS.GET_ADDITIONAL_MEMBER, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const checkGeoFenceArea =
  (latitude: any, longitude: string, zone_id: string) =>
  async (dispatch: Function) => {
    //dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    dispatch({ type: ACTIONS.CHECK_GEOFENCE_AREA, payload: false });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/booking/checking-geofence-area/?latitude=${latitude}&longitude=${longitude}&zone_id=${zone_id}`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.CHECK_GEOFENCE_AREA, payload: true });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      //dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

export const checkGeoFenchMapMyIndia =
  (eloc: string, pincode: string) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.CHECK_GEOFENCE_AREA_MAMPMYINDIA, payload: [] });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/booking/checking-geofence-area-mapmyindia/?eloc=${eloc}&pincode=${pincode}`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: ACTIONS.CHECK_GEOFENCE_AREA_MAMPMYINDIA,
        payload: data,
      });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      //dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

export const getBookingOverview =
  (url: string) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/booking/overview/${url ? `?${url}` : ""}`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_BOOKING_OVERVIEW, payload: data });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response?.data?.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

export const getBatchPayment = (url: string) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/panel/batch-amount-transfers/${url ? `${url}` : ""}`,

      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_BATCH_PAYMENT, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = JSON.stringify(err.response.data.errors).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const initiateRefund = (body: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.post(
      `${Prefix.api}/payment/refund-payment/`,
      body,
      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.INITIATE_REFUND, payload: data });
    snackBarUpdate({
      payload: {
        message: "Refund Initiate successfully!",
        status: true,
        type: "success",
      },
    })(dispatch);
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const updateInitiateRefund =
  (id: any, body: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.put(
        `${Prefix.api}/payment/refund-approve/sl/${id}/`,
        body,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.UPDATE_INITIATED_REFUND, payload: data });
      snackBarUpdate({
        payload: {
          message: "Refund Initiate Updated successfully!",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

export const getLabCredential = () => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(`${Prefix.api}/sample/lab_cre/`, {
      headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
    });
    dispatch({
      type: ACTIONS.GET_LAB_CREDENTIAL,
      payload: data,
    });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const updateBatchPayment =
  (id: any, body: any) => async (dispatch: Function) => {
    // dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.patch(
        `${Prefix.api}/panel/batch-amount-transfer/approve/${id}/`,
        body,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: ACTIONS.UPDATE_BATCH_PAYMENT,
        payload: data,
      });
      snackBarUpdate({
        payload: {
          message: "Your request has been successfully saved!",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response?.data?.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      // dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

export const postPhleboRiderMap =
  (bookingData: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api}/phlebo/phlebo-rider-maaping/`,
        bookingData,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.POST_PHLEBO_RIDER_MAP, payload: data });
      snackBarUpdate({
        payload: {
          message: "Created",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response.data.errors) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
export const updatePhleborider =
  (body: any, id: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.put(
        `${Prefix.api}/phlebo/edit-phlebo-rider-maaping/${id}/`,
        body,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: ACTIONS.UPDATE_PHLEBO_RIDER,
        payload: data,
      });
      snackBarUpdate({
        payload: {
          message: "Updated",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
export const getPhleboRider = (url: string) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/phlebo/phlebo-rider-maaping/${url ? `?${url}` : ""}`,
      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_PHLEBO_RIDER, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const getRoutes = (url: string) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/phlebo/rider/routes/${url ? `?${url}` : ""}`,
      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_ROUTES, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const getBookingQueryTickets =
  (id: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/redtech/csticket/${id ? `${id}` : ""}`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: ACTIONS.GET_BOOKING_COMPLAINTS,
        payload: data,
      });
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.detail) {
        title = JSON.stringify(err?.response?.data?.detail);
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
export const updateTicketStatus =
  (body: any, id: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.put(
        `${Prefix.api}/redtech/csticket/${id}/`,
        body,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: ACTIONS.UPDATE_TICKET_STATUS,
        payload: data,
      });
      snackBarUpdate({
        payload: {
          message: "Ticket Updated Successfully",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = String(err.response.data.errors);
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
export const getTicketComments = (id: number) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/redtech/csticket/comments/${id}`,

      {
        headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
      }
    );
    dispatch({
      type: ACTIONS.GET_TICKET_COMMENTS,
      payload: data,
    });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong!",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const getBatchTransferData =
  (url: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/panel/batch-amount-transfers/${url ? `?${url}` : ""}`,

        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: ACTIONS.GET_BATCH_TRANSFER_DATA,
        payload: data,
      });
    } catch (err: any) {
      let title = "";
      if (err.response?.data?.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong!",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
export const UpdateBatchAmountReceipt =
  (id: any, body: any) => async (dispatch: Function) => {
    // dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.patch(
        `${Prefix.api}/panel/batch-amount-transfer/${id}/`,
        body,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: ACTIONS.BATCH_AMOUT_RECEIPT_UPDATE,
        payload: data,
      });
      snackBarUpdate({
        payload: {
          message: "Feedback Sent",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response?.data?.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      // dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

export const getZone1 = (url: any) => async (dispatch: Function) => {
  // dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/phlebo/zone/data/${url ? `?${url}` : ""}`,
      {
        headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
      }
    );
    dispatch({
      type: ACTIONS.GET_ZONE,
      payload: data,
    });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong!",
        status: true,
        type: "error",
      },
    })(dispatch);
    // dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const verifyLocation = (id: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.put(
      `${Prefix.api}/booking/verify-location/${id}`,
      {},
      {
        headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
      }
    );
    dispatch({
      type: ACTIONS.LOCATION_VERIFIED,
      payload: data,
    });
    snackBarUpdate({
      payload: {
        message: "Location Verified Successfully",
        status: true,
        type: "success",
      },
    })(dispatch);
  } catch (err: any) {
    let title = "";
    if (err.response.data?.detail) {
      title = JSON.stringify(err.response.data?.detail).replace(
        /"|{|}|[|]/g,
        ""
      );
    }
    if (err.response.data?.errors) {
      title = JSON.stringify(err.response.data?.errors).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
  dispatch({ type: ACTIONS.SET_LOADING, payload: false });
};

export const SendLocationRequest =
  (body: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api}/communication/send-location-request/`,
        body,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.SEND_LOCATION_REQUEST, payload: data });
      snackBarUpdate({
        payload: {
          message: "Message Sent Successfully",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response.data?.detail) {
        title = JSON.stringify(err.response.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      throw err;
    }
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
  };

export const getAddressDetailsLatLong =
  (lat: any, long: any, lead: any, bookingId: any) =>
  async (dispatch: Function) => {
    dispatch({ type: ACTIONS.GET_ADDRESS_DETAILS_LAT_LONG, payload: [] });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/auth/get-address-details-lat-long/?latitude=${lat}&longitude=${long}&lead_id=${lead}&booking_id=${bookingId}`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_ADDRESS_DETAILS_LAT_LONG, payload: data });
    } catch (err: any) {
      let title = "";
      if (err.response.data?.detail) {
        title = JSON.stringify(err.response.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data?.errors) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      throw err;
    }
  };
export const updateActionDisposition =
  (type: any, body: any, id: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.put(
        `${Prefix.api}/booking/booking_ticket/${id}/`,
        body,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: ACTIONS.UPDATE_DISPOSITION,
        payload: data,
      });
      snackBarUpdate({
        payload: {
          message: "Ticket Updated",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
export const resetTableData = () => async (dispatch: Function) => {
  dispatch({
    type: ACTIONS.TABLE_RESET,
  });
};
export const resetBiomarker = () => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.BIOMARKER_RESET });
};
export const updateAttendanceCompliance =
  (id: any, compliance: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    try {
      const { data } = await AXIOS.patch(
        `${Prefix.api}/phlebo/update-compliance/${id}/`,
        { compliance },
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.UPDATE_ATTENDANCE, payload: data });
      snackBarUpdate({
        payload: {
          message: "Attendance updated",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
 export const getNewPackage = (url: string) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${PrefixBeta.api}/core/packages/crmdata${url ? `?${url}` : ""}`,
        { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
      );
      dispatch({ type: ACTIONS.GET_PACKAGES, payload: data });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
    dispatch({ type: ACTIONS.SET_ALL_BOOKING_LOADING, payload: false });
  };

 

  export const syncBookingtoMapMyIndia = () => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/phlebo/booking-auto-assign/`,
        { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
      );
      dispatch({ type: ACTIONS.AUTO_ASSIGN_BOOKING, payload: data });
      snackBarUpdate({
        payload: {
          message: "Booking has been sync from map my india.",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

  export const getPhleboAutoAssign = (url: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/phlebo/auto-assignment-phlebo-view/${url ? `?${url}` : ""}`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: ACTIONS.GET_PHLEBO_AUTO_ASSIGN,
        payload: data,
      });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
  export const getAssignedUnassignedBookingPilotV2 =
  (url: any, reset: any = false) =>
  async (dispatch: Function) => {
    if (reset) {
      dispatch({ type: ACTIONS.GET_ASSIGNED_UNASSIGNED_BOOKING, payload: {} });
      return;
    }
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/booking/list-v2/${url ? `?${url}` : ""}`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: ACTIONS.GET_ASSIGNED_UNASSIGNED_BOOKING_PILOT_V2,
        payload: data,
      });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
  export const postMultipleBookingPayment =
  (phleboData: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api}/panel/batch-amount-transfers/`,
        phleboData,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.POST_MULTIPLE_BOOKING_PAYMENT, payload: data });
      snackBarUpdate({
        payload: {
          message: "Phlebo Home Location Updated.",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

  export const getLeadSubscriptionsData = () => async (dispatch: Function) => {
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/auth/subscriptions/`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: ACTIONS.GET_LEAD_SUBSCRIPTIONS_DATA,
        payload: data,
      });
    } catch (err: any) {
      let title = "";
      if (err.response.data?.detail) {
        title = JSON.stringify(err.response.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data?.errors) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      throw err;
    }
  };

  export const getViews = (id: string) => async (dispatch: Function) => {
    try {
      const url = id ? `/?view_id=${id}` : "/list/";
      const { data } = await AXIOS.get(
        `${Prefix.apiv2}/snowflake/default${url}`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      if(url === "/list/") {
        dispatch({
          type: ACTIONS.GET_SF_VIEWS_DROPDOWN,
          payload: data,
        });
      } else {
        dispatch({
          type: ACTIONS.GET_SF_VIEWS,
          payload: data,
        });
      }
    } catch (err: any) {
      let title = "";
      if (err.response.data?.detail) {
        title = JSON.stringify(err.response.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data?.errors) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      throw err;
    }
  };
  // getting zone details through lat and long
export const getZoneDetails = (lat: string, long: string) => async (dispatch: Function) => {
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/booking/checking-geofence-area-lat-long/?latitude=${lat}&longitude=${long}`,
      {
        headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
      }
    );
    dispatch({
      type: ACTIONS.GET_ZONE_DETAILS,
      payload: data,
    });
  } catch (err: any) {
    let title = "";
    if (err.response.data?.detail) {
      title = JSON.stringify(err.response.data?.detail).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err.response.data?.errors) {
      title = JSON.stringify(err.response.data?.errors).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    throw err;
  }
};
export const getNewLeadDetails =
(id: string, shownumber: string = "false") =>
  async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      if (shownumber === "true") {
        var { data } = await AXIOS.get(
          `${Prefix.api}/lead/lead-update/${id}`,
          {
            headers: {
              Authorization: `Token ${SecureStorage.getItem("token")}`,
            },
          }
        );
      } else {
        var { data } = await AXIOS.get(
          `${Prefix.api}/lead/lead-update/${id}`,
          {
            headers: {
              Authorization: `Token ${SecureStorage.getItem("token")}`,
            },
          }
        );
      }
      dispatch({ type: ACTIONS.GET_NEW_LEAD_DETAILS, payload: data });
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.detail) {
        title = JSON.stringify(err?.response?.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.errors) {
        title = JSON.stringify(err?.response?.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong!",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
  export const getRejectedBatchForPhlebo = (url:any) => async (dispatch: Function) => {
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/phlebo/rejected-batch/${url ? url :" "}`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: ACTIONS.GET_REJECTED_BATCH_FOR_PHLEBO,
        payload: data,
      });
    } catch (err: any) {
      let title = "";
      if (err.response.data?.detail) {
        title = JSON.stringify(err.response.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data?.errors) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      throw err;
    }
  };
  export const getRejectedBatchForRider = (url:any) => async (dispatch: Function) => {
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/phlebo/rejected-batch/${url ? url :" "}`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: ACTIONS.GET_REJECTED_BATCH_FOR_RIDER,
        payload: data,
      });
    } catch (err: any) {
      let title = "";
      if (err.response.data?.detail) {
        title = JSON.stringify(err.response.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data?.errors) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      throw err;
    }
  };

  export const getRedCoing = (url: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/coupon/available-coins/?phonenumber=${url}`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: ACTIONS.GET_RED_COIN,
        payload: data,
      });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

  export const getAllSpecialPackageBookings = (url: string) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SPECIAL_PACKAGE_BOOKINGS_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/booking/data/?rm=true${url ? `&${url}` : ""}`,
        { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
      );
      dispatch({ type: ACTIONS.GET_SPECIAL_PACKAGE_BOOKINGS, payload: data });
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.detail) {
        title = JSON.stringify(err?.response?.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.errors) {
        title = JSON.stringify(err?.response?.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong!",
          status: true,
          type:
            err.response.data.detail ===
            "An Email has been sent to your registered mail address"
              ? "success"
              : "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.GET_SPECIAL_PACKAGE_BOOKINGS, payload: [] });
      dispatch({ type: ACTIONS.SPECIAL_PACKAGE_BOOKINGS_LOADING, payload: false });
      throw err;
    }
  };