import React, { useEffect, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Grid,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  TablePagination,
  TableFooter,
  withStyles,
  Switch,
} from "@material-ui/core";
import Button from '@mui/material/Button';
import "./index.sass";
import { MessageRounded } from "@material-ui/icons";
import { connect } from "react-redux";
import {
  getCities,
  getArea,
  updateArea,
  getAreaComments,
} from "../../actions/RouteManagerAction";

import Loader from "../loader";
import CreatePincodeModal from "./createPincodeModal";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useRef } from "react";
import { useHistory } from "react-router";
import { generatePincodeFilterUrl } from "../../../helpers/generateUrl";
//  import AreaComments from '../comments/areaComments'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
    },
    paper: {
      padding: "1rem",
      width: "100%",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    table: {
      maxWidth: "100%",
      margin: "auto",
    },
    heading: {
      fontWeight: "normal",
      fontSize: "1.2rem",
    },
    actionButton: {
      margin: "0 0.5rem",
      cursor: "pointer",
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
  })
);

const DefaultSwitch: any = withStyles({
  switchBase: {
    color: "red",
    "&$checked": {
      color: "green",
    },
    "&$checked + $track": {
      backgroundColor: "green",
    },
  },
  checked: {},
  track: {
    backgroundColor: "red",
  },
})(Switch);
const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);
interface Props {
  getArea: any;
  areaList: any;
  updateArea: any;
  getCities: any;
  cities: any;
  getAreaComments: any;
  areaComments: any;
  loading: boolean;
}

const PinCodePage: React.FC<Props> = ({
  getArea,
  areaList,
  updateArea,
  getCities,
  cities,
  getAreaComments,
  areaComments,
  loading,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [editPincode, setEditPincode] = useState("");
  const [pincode, setPincode] = useState<string | null>(null);
  const [zone, setZone] = useState<string | null>(null);
  const [city, setCity] = useState<any>([]);
  const [cityName, setCityName] = useState("");
  const timer = useRef<any>(0);
  const [pin, setPin] = useState("");
  const [page, setPage] = useState(0);
  const [open, setOpen] = useState(false);
  const [openModal, setopenModal] = useState(false);

  useEffect(() => {
    getArea("show=true");
    getCities();
  }, [getArea, getCities]);

  useEffect(() => {
    open === false && getArea("show=true");
  }, [open]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url = areaList.links && areaList.links.next.split("?")[1];
      getArea(`${url}&show=true`);
    } else if (newPage < page) {
      let url = areaList.links && areaList.links.previous.split("?")[1];
      getArea(`${url}&show=true`);
    }
    setPage(newPage as number);
  };

  const handleEditArea = (code: any) => {
    setEditPincode(code.id);
    setCityName(code.city_name);
    setCity(code.city);
    setPincode(code.pincode);
    setZone(code.area);
  };

  const handleSubmit = async (code: any) => {
    setEditPincode("");
    const body: any = {
      city,
      city_name: cityName,
      area: zone,
      pincode,
    };
    await updateArea(code.id, body);
    getArea("show=true");
  };

  const disablePincode = async (pack: any) => {
    const body: any = {
      is_active: !pack.is_active,
    };
    await updateArea(pack.id, body);
    getArea("show=true");
  };

  const filterBookings = () => {
    const body: any = {
      city: city.join(","),
      pincode: pin,
    };
    const url = generatePincodeFilterUrl(body).substring(2);
    getArea(`${url}&show=true`);
    // setPage(0)
  };

  const handleClick = (id: number) => {
    setopenModal(true);
    getAreaComments(id);
  };

  return (
    <>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid container spacing={1} direction="row" style={{ margin: "0" }}>
          <Grid item xs={12} sm={6} md={9}>
            <h2>Pin Codes</h2>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            {/* <Button
              variant="contained"
              color="primary"
              style={{ marginLeft: "1rem" }}
              onClick={() => setOpen(true)}
            >
              Add Pincode
            </Button> */}
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="city"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj.map((item: any) => item.id);
                }
                setCity(sourceIds);
              }}
              options={cities || []}
              freeSolo
              blurOnSelect
              aria-required
              limitTags={1}
              multiple
              getOptionLabel={(option: any) => option.name}
              getOptionDisabled={(option: any) => {
                return city.includes(option.id);
              }}
              disableClearable
              disableCloseOnSelect
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getCities(newInputValue);
                }, 1000);
                if (newInputValue.length === 0) {
                  setCity([]);
                }
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  placeholder="City"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              name="pincode"
              type="number"
              label="Pin Code"
              value={pin}
              variant="outlined"
              onChange={(e) => setPin(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              onClick={filterBookings}
              disabled={loading}
            >
              Filter
            </Button>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => history.push("/dashboard/rm/pincodes")}
            >
              Reset
            </Button>
          </Grid>
        </Grid>
        {/* <Paper elevation={15} className={classes.paper}> */}
        {loading ? (
          <Loader />
        ) : (
          <div className="data-table">
            <TableContainer
              className={classes.tableContainer}
              component={Paper}
              style={{ maxHeight: "560px" }}
            >
              <Table stickyHeader aria-label="simple table">
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell align="center">Pincode</StyledTableCell>
                    {/* <StyledTableCell align="center">
                      Comments
                    </StyledTableCell> */}
                    <StyledTableCell align="center">Area</StyledTableCell>
                    <StyledTableCell align="center">City</StyledTableCell>
                    <StyledTableCell align="center">Active</StyledTableCell>
                    {/* <TableCell align="center">Edit</TableCell> */}
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {areaList &&
                    areaList.results &&
                    areaList.results.length > 0 &&
                    areaList.results.map((code: any, index: any) => {
                      return (
                        <StyledTableRow key={index}>
                          <StyledTableCell align="center">
                            {editPincode === code.id ? (
                              <TextField
                                name="pincode"
                                type="text"
                                value={pincode !== null ? pincode : code.pincode}
                                className="input"
                                variant="outlined"
                                placeholder="Pincode"
                                disabled
                                onChange={(e) =>
                                  setPincode(e.target.value as string)
                                }
                                style={{
                                  width: "100%",
                                  padding: "10px",
                                  minWidth: "200px",
                                }}
                              />
                            ) : (
                              code.pincode
                            )}
                          </StyledTableCell>
                          {/* <StyledTableCell align="center">
                            <div>
                              <MessageRounded
                                onClick={() => handleClick(code.id)}
                              />
                            </div>
                          </StyledTableCell> */}
                          <StyledTableCell align="center">
                            {editPincode === code.id ? (
                              <TextField
                                name="area"
                                type="text"
                                value={zone !== null ? zone : code.area}
                                className="input"
                                variant="outlined"
                                disabled
                                placeholder="Area"
                                onChange={(e) =>
                                  setZone(e.target.value as string)
                                }
                                style={{
                                  width: "100%",
                                  padding: "10px",
                                  minWidth: "200px",
                                }}
                              />
                            ) : (
                              code.area
                            )}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {editPincode === code.id ? (
                              <Autocomplete
                                id="city"
                                onChange={(event, newValue) => {
                                  if (newValue) {
                                    let obj = JSON.parse(
                                      JSON.stringify(newValue, null, " ")
                                    );
                                    setCity(obj.id);
                                    setCityName(obj.name);
                                  }
                                }}
                                options={cities}
                                freeSolo
                                blurOnSelect
                                aria-required
                                disabled
                                getOptionLabel={(option: any) => option.name}
                                inputValue={cityName}
                                onInputChange={(event, newInputValue) => {
                                  clearTimeout(timer.current);
                                  setCityName(newInputValue);
                                  timer.current = setTimeout(() => {
                                    getCities(newInputValue);
                                  }, 1000);
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    className="input"
                                    {...params}
                                    placeholder="City"
                                    variant="outlined"
                                    style={{
                                      width: "100%",
                                      padding: "10px",
                                      minWidth: "200px",
                                    }}
                                  />
                                )}
                              />
                            ) : (
                              code.city_name
                            )}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <DefaultSwitch
                              checked={code.is_active}
                              name="checkedA"
                              inputProps={{
                                "aria-label": "secondary checkbox",
                              }}
                              disableRipple
                              onClick={() => disablePincode(code)}
                            />
                          </StyledTableCell>
                          {/* <TableCell align="center">
                                                      {editPincode === code.id ? (
                                                          <Button
                                                              color="primary"
                                                              variant="contained"
                                                              onClick={() => handleSubmit(code)}
                                                          >
                                                              <CheckCircleOutlineRounded />
                                                          </Button>
                                                      ) : (
                                                          <Button
                                                              color="primary"
                                                              variant="contained"
                                                              onClick={() => handleEditArea(code)}
                                                          >
                                                              <Edit />
                                                          </Button>
                                                      )}
                                                  </TableCell> */}
                        </StyledTableRow>
                      );
                    })}
                </TableBody>
                <TableFooter>
                  <StyledTableRow>
                    <TablePagination
                      rowsPerPageOptions={[]}
                       colSpan={3}
                      count={(areaList && areaList.count) || 0}
                      rowsPerPage={areaList && areaList.page_size}
                      page={page}
                      onPageChange={handleChangePage}
                    />
                  </StyledTableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </div>
        )}
        {areaList && areaList.length === 0 && (
          <h3 className={classes.heading} style={{ textAlign: "center" }}>
            No Data Found
          </h3>
        )}
        {/* </Paper> */}
        {/* <AreaComments
          openModal={openModal}
          setopenModal={setopenModal}
          areaComments={areaComments}
          loading={loading}
        /> */}
        {/* <AreaComments openModal={openModal} setopenModal={setopenModal} areaComments={areaComments} loading={loading} /> */}
        {/* <CreatePincodeModal open={open} setOpen={setOpen} /> */}
      </main>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  areaList: state.RouteManagerReducer.areaList,
  cities: state.RouteManagerReducer.cities,
  areaComments: state.RouteManagerReducer.areaComments,
  loading: state.RouteManagerReducer.loading,
});

export default connect(mapStateToProps, {
  getArea,
  getCities,
  updateArea,
  getAreaComments,
})(PinCodePage);
