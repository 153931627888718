import React, { useEffect, useRef, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Container, Grid, TextField } from "@material-ui/core";
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  MenuItem,
  Select,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import { generateBookingFilterUrl } from "../../helpers/generateUrl";
import { connect } from "react-redux";
import {
  getAgentList,
  getLeadSource,
  getPartners,
  getAllBookings,
  getCities,
  getPhlebos,
} from "../actions/productMangerAction";
import "./filter.sass";
import { useHistory } from "react-router";
import BookingsTable from "../tables/bookingsTable";
import Loader from "../Components/loader/index";
import { getPaymentInfo } from "../actions/productMangerAction";
import PaymentModal from "../Components/comments/paymentforModal";
import SyncModal from "../Components/comments/syncModal";
import AdditionalSyncModal from "../Components/comments/additionMemberSyncModal";
import BookingCommentModal from "../Components/comments/bookingComments";
import { BOOKING_STATUS } from "../../booking_status";
import QuizRoundedIcon from "@mui/icons-material/QuizRounded";
import CloseIcon from "@material-ui/icons/Close";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
      //   width: "100%",
      //   marginTop: "20px",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
      //   padding: theme.spacing(3),
    },
    card: {
      height: "100%",
      padding: theme.spacing(1),
    },
    card_grid: {
      // wordSpacing:3,
      justifyContent: "space-between",
    },
  })
);

interface Props {
  children: any;
  agentsList: any;
  bookingList: any;
  partnerList: any;
  getAgentList: any;
  getPartners: any;
  getAllBookings: any;
  getCities: any;
  cities: any;
  getPhlebos: any;
  phleboList: any;
  getLeadSource: any;
  lead_source: any;
  loading: boolean;
  getPaymentInfo: any;
  paymentInformation: any;
  bookingListLoader: boolean;
}

const CustomerLeadFilterPage: React.FC<Props> = ({
  agentsList,
  partnerList,
  getAgentList,
  getPartners,
  getAllBookings,
  bookingList,
  getCities,
  cities,
  getPhlebos,
  phleboList,
  getLeadSource,
  lead_source,
  getPaymentInfo,
  paymentInformation,
  loading,
  bookingListLoader,
}) => {
  const classes = useStyles();

  const history = useHistory();

  const [openModal, setopenModal] = useState<boolean>(false);

  const [status, setStatus] = useState<any>([]);
  const [source, setSource] = useState<any>([]);
  const [partner, setPartner] = useState<any>([]);
  const [agent, setAgent] = useState<any>([]);
  const [city, setCity] = useState<any>([]);
  const [start_date, setStart_date] = useState("");
  const [end_date, setEnd_date] = useState("");
  const [booking_date, setBooking_date] = useState("");
  const [collection_date, setCollection_date] = useState("");
  const [verificationStatus, setVerificationStatus] = useState<any>([]);
  const [pickup_status, setPickup_status] = useState<any>([]);
  const [name, setName] = useState("");
  const [phlebo, setPhlebo] = useState<any>([]);
  const [phone, setPhone] = useState("");
  const [bookingId, setBookingId] = React.useState<any>("");
  const [userGroup, setUserGroup] = useState("none");
  const [page, setPage] = useState(0);
  const [sample_registered, setSampleRegisterd] = useState<any>("none");
  const [report_status, setReportStatus] = useState<any>("none");
  const [paymentId, setPaymentId] = useState<any>(0);
  const [openPaymentModal, setOpenPaymentModal] = useState<any>(false);
  const [syncId, setSyncId] = useState<any>("");
  const [openSyncModal, setSyncOpenModal] = useState<boolean>(false);
  const [bookingData, setBookingData] = useState({});
  const [openAdditionalSyncModal, setSyncAdditionalOpenModal] =
    useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<any>("");
  const [syncButton, setSyncButton] = useState<boolean>(false);
  const [syncAdditionalId, setAdditionalSyncId] = useState<any>("");
  const [bookingAdditionalData, setAdditonalBookingData] = useState({});
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const timer = useRef<any>(0);

  useEffect(() => {
    getAgentList();
    getPartners();
    getAllBookings();
    getCities();
    getPhlebos();
    getLeadSource();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterBookings = () => {
    const body: any = {
      status: status.join(","),
      verificationStatus: verificationStatus.join(","),
      source: source.join(","),
      agent: agent.join(","),
      city: city.join(","),
      start_date,
      end_date,
      booking_date,
      bookingId,
      collection_date,
      pickup_status: pickup_status.join(","),
      name,
      phlebo: phlebo.join(","),
      partner: partner.join(","),
      user_group: userGroup,
      phone,
      sample_registered,
      report_status,
    };
    const url = generateBookingFilterUrl(body).substring(2);
    getAllBookings(url ? `${url}&page=1` : "page=1");
    setPage(0);
  };
  useEffect(() => {
    if (paymentId != 0) {
      getPaymentInfo(`?booking=${paymentId}`);
    }
  }, [paymentId]);
  const handleCross = () => {
    setOpen(false);
  };

  return (
    <>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid
          container
          spacing={2}
          direction="row"
          style={{ alignItems: "right" }}
        >
          <Grid item xs={12} sm={8}>
            <h4 style={{ fontWeight: "bold" }}>
              BOOKING LIST{" "}
              <QuizRoundedIcon color="primary" onClick={handleOpen} />
            </h4>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={1}
          direction="row"
          style={{ alignItems: "left" }}
        >
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              name="booking"
              type="number"
              label="Booking Id"
              onWheel={(e: any) => e.target.blur()}
              value={bookingId}
              variant="outlined"
              onChange={(e) => setBookingId(e.target.value)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              name="name"
              type="text"
              label="Name"
              value={name}
              variant="outlined"
              onChange={(e) => setName(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              name="phone"
              type="text"
              label="Phone"
              value={phone}
              variant="outlined"
              onChange={(e) => setPhone(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="city"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj.map((item: any) => item.id);
                }
                setCity(sourceIds);
              }}
              options={cities || []}
              freeSolo
              blurOnSelect
              aria-required
              limitTags={1}
              multiple
              getOptionLabel={(option: any) => option.name}
              getOptionDisabled={(option: any) => {
                return city.includes(option.id);
              }}
              disableClearable
              disableCloseOnSelect
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getCities(newInputValue);
                }, 1000);
                if (newInputValue.length === 0) {
                  setCity([]);
                }
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="City"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="status"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj.map((item: any) => item.value);
                }
                setStatus(sourceIds);
              }}
              options={BOOKING_STATUS || []}
              blurOnSelect
              aria-required
              limitTags={1}
              multiple
              getOptionLabel={(option: any) => option.text}
              getOptionDisabled={(option: any) => {
                return status.includes(option.value);
              }}
              disableClearable
              disableCloseOnSelect
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Booking Status"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="partner"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj.map((item: any) => item.id);
                }
                setPartner(sourceIds);
              }}
              freeSolo
              blurOnSelect
              limitTags={1}
              multiple
              options={partnerList.results || []}
              getOptionDisabled={(option: any) => {
                return partner.includes(option.id);
              }}
              disableClearable
              disableCloseOnSelect
              getOptionLabel={(option: any) => option.user && option.user.name}
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getPartners(newInputValue);
                }, 1000);
                if (newInputValue.length === 0) {
                  setPartner([]);
                }
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Partner"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj.map((item: any) => item.id);
                }
                setSource(sourceIds);
              }}
              freeSolo
              multiple
              id="checkboxes-tags-demo"
              options={lead_source || []}
              disableCloseOnSelect
              limitTags={1}
              disableClearable
              getOptionLabel={(option: any) => option?.name}
              getOptionDisabled={(option: any) => {
                return source.includes(option.id);
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Source"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="agent"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj.map((item: any) => item.id);
                }
                setAgent(sourceIds);
              }}
              freeSolo
              blurOnSelect
              aria-required
              limitTags={1}
              multiple
              options={agentsList.results || []}
              getOptionDisabled={(option: any) => {
                return agent.includes(option.id);
              }}
              disableClearable
              disableCloseOnSelect
              getOptionLabel={(option: any) =>
                option.user && option.user.username
              }
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getAgentList(`${newInputValue.replace(" ", "")}`);
                }, 1000);
                if (newInputValue.length === 0) {
                  setPartner([]);
                }
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Agent"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="phlebo"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj.map((item: any) => item.id);
                }
                setPhlebo(sourceIds);
              }}
              freeSolo
              blurOnSelect
              aria-required
              limitTags={1}
              multiple
              options={
                phleboList.results
                  ? [
                      ...phleboList.results,
                      { id: 0, user: { username: "NoPhlebo" } },
                    ]
                  : []
              }
              getOptionDisabled={(option: any) => {
                return phlebo.includes(option.id);
              }}
              disableClearable
              disableCloseOnSelect
              getOptionLabel={(option: any) =>
                option.user && option.user.username
              }
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getPhlebos(newInputValue);
                }, 1000);
                if (newInputValue.length === 0) {
                  setPhlebo([]);
                }
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Phlebo Name"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="city"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj.map((item: any) => item.value);
                }
                setVerificationStatus(sourceIds);
              }}
              options={[
                {
                  text: "Pending",
                  value: "pending",
                },
                {
                  text: "Confirmed",
                  value: "confirmed",
                },
                {
                  text: "Cancelled",
                  value: "cancelled",
                },
              ]}
              freeSolo
              blurOnSelect
              aria-required
              limitTags={1}
              multiple
              getOptionLabel={(option: any) => option.text}
              getOptionDisabled={(option: any) => {
                return verificationStatus.includes(option.value);
              }}
              disableClearable
              disableCloseOnSelect
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Verification Status"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="city"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj.map((item: any) => item.value);
                }
                setPickup_status(sourceIds);
              }}
              options={[
                {
                  text: "Pending",
                  value: "pending",
                },
                {
                  text: "Confirmed",
                  value: "confirmed",
                },
                {
                  text: "Cancelled",
                  value: "cancelled",
                },
                {
                  text: "Hold",
                  value: "hold",
                },
              ]}
              freeSolo
              blurOnSelect
              aria-required
              limitTags={1}
              multiple
              getOptionLabel={(option: any) => option.text}
              getOptionDisabled={(option: any) => {
                return pickup_status.includes(option.value);
              }}
              disableClearable
              disableCloseOnSelect
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Pickup Status"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Select
              className="input"
              name="usergroup"
              variant="outlined"
              value={userGroup}
              style={{ width: "100%", height: "40px" }}
              onChange={(e) => setUserGroup(e.target.value as string)}
            >
              <MenuItem disabled value={"none"}>
                User Type
              </MenuItem>
              <MenuItem value={"SupportExecutive"}>SupportExecutive</MenuItem>
              <MenuItem value={"SampleAccessioning"}>
                SampleAccessioning
              </MenuItem>
              <MenuItem value={"CustomerExecutive"}>CustomerExecutive</MenuItem>
              <MenuItem value={"PhleboAdmin"}>PhleboAdmin</MenuItem>
              <MenuItem value={"Phlebos"}>Phlebos</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              name="start_date"
              type="date"
              label="Start Date"
              value={
                booking_date === "" || collection_date === "" ? start_date : ""
              }
              variant="outlined"
              disabled={booking_date !== "" || collection_date !== ""}
              onChange={(e) => setStart_date(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              name="end_date"
              type="date"
              value={
                booking_date === "" || collection_date === "" ? end_date : ""
              }
              className="input"
              label="End Date"
              variant="outlined"
              disabled={booking_date !== "" || collection_date !== ""}
              onChange={(e) => setEnd_date(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              name="booking_date"
              type="date"
              value={start_date === "" || end_date === "" ? booking_date : ""}
              className="input"
              label="Booking Date"
              variant="outlined"
              disabled={start_date !== "" || end_date !== ""}
              onChange={(e) => setBooking_date(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              name="collection_date"
              type="date"
              value={
                start_date === "" || end_date === "" ? collection_date : ""
              }
              className="input"
              label="Collection Date"
              variant="outlined"
              disabled={start_date !== "" || end_date !== ""}
              onChange={(e) => setCollection_date(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Select
              className="input"
              name="sampleregisterd"
              variant="outlined"
              value={sample_registered}
              style={{ width: "100%", height: "40px" }}
              onChange={(e) => setSampleRegisterd(e.target.value as String)}
            >
              <MenuItem disabled value={"none"}>
                Sample Type
              </MenuItem>
              <MenuItem value={"true"}>Registerd</MenuItem>
              <MenuItem value={"false"}>Not Registerd</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Select
              className="input"
              name="sampleregisterd"
              variant="outlined"
              value={report_status}
              style={{ width: "100%", height: "40px" }}
              onChange={(e) => setReportStatus(e.target.value as String)}
            >
              <MenuItem disabled value={"none"}>
                Report Status
              </MenuItem>
              <MenuItem value={"na"}>None</MenuItem>
              <MenuItem value={"pending"}>Pending</MenuItem>
              <MenuItem value={"partial"}>Partial</MenuItem>
              <MenuItem value={"consolidate"}>Consolidate</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              onClick={filterBookings}
              disabled={loading}
            >
              Filter
            </Button>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => history.push("/dashboard/pm/booking_list")}
            >
              Reset
            </Button>
          </Grid>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={open}>
              <div
                style={{
                  position: "absolute",
                  left: "10%",
                  right: "15%",
                  top: "25%",
                  minHeight: "400",
                  minWidth: "500",
                  backgroundColor: "white",
                }}
              >
                <Box style={{}}>
                  <Typography
                    id="transition-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    <div
                      style={{
                        margin: "0rem 2rem",
                        paddingTop: "1rem",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <h4>Note:</h4>
                      <CloseIcon onClick={handleCross} />
                    </div>
                  </Typography>
                  <Typography id="transition-modal-description" sx={{ mt: 1 }}>
                    <div style={{ margin: "1rem" }}>
                      <div
                        style={{
                          alignItems: "left",
                          display: "inline-block",
                          margin: "1rem",
                        }}
                      >
                        <small>
                          <ul style={{ paddingLeft: "15px" }}>
                            <li
                              style={{ fontWeight: "bold", color: "default" }}
                            >
                              You can filter by booking date or start and end
                              date.
                            </li>
                            <li
                              style={{ fontWeight: "bold", color: "default" }}
                            >
                              T: Total Additional Booking count
                            </li>
                            <li
                              style={{ fontWeight: "bold", color: "default" }}
                            >
                              P: Pending count for additional member
                            </li>
                            <li
                              style={{ fontWeight: "bold", color: "default" }}
                            >
                              C: Confirmed count for additional member
                            </li>
                            <li
                              style={{ fontWeight: "bold", color: "default" }}
                            >
                              For B2B booking first select Booking Type b2b then
                              apply any filter option
                            </li>
                            <li
                              style={{ fontWeight: "bold", color: "default" }}
                            >
                              For Sync Covide Report SRF and Barcode is Required
                            </li>
                            <li
                              style={{ fontWeight: "bold", color: "default" }}
                            >
                              You Can Sync other sample without filling details{" "}
                            </li>
                          </ul>
                        </small>
                      </div>
                      <div
                        style={{
                          alignItems: "left",
                          display: "inline-block",
                          margin: "1rem",
                        }}
                      >
                        <small>
                          <div className="r-cl" style={{ display: "flex" }}>
                            <div
                              className="boxStyle"
                              style={{
                                border: "1px solid",
                                width: "17px",
                                height: "17px",
                                background: "#ffe082",
                              }}
                            ></div>
                            <div style={{ marginLeft: "5px" }}>
                              B2B booking and Payable Amount is Zero
                            </div>
                          </div>
                          <div className="r-cl" style={{ display: "flex" }}>
                            <div
                              className="boxStyle"
                              style={{
                                border: "1px solid",
                                width: "17px",
                                height: "17px",
                                background: "#f48fb1",
                              }}
                            ></div>
                            <div style={{ marginLeft: "5px" }}>
                              B2B booking and Payable Amount is not Zero
                            </div>
                          </div>
                          <div className="r-cl" style={{ display: "flex" }}>
                            <div
                              className="boxStyle"
                              style={{
                                border: "1px solid",
                                width: "17px",
                                height: "17px",
                                background: "#64ffda",
                              }}
                            ></div>
                            <div style={{ marginLeft: "5px" }}>
                              B2C booking and Payable Amount is Zero
                            </div>
                          </div>
                          <div className="r-cl" style={{ display: "flex" }}>
                            <div
                              className="boxStyle"
                              style={{
                                border: "1px solid",
                                width: "17px",
                                height: "17px",
                                background: "default",
                              }}
                            ></div>
                            <div style={{ marginLeft: "5px" }}>
                              B2C booking and Payable Amount is not Zero
                            </div>
                          </div>
                        </small>
                      </div>
                    </div>
                  </Typography>
                </Box>
              </div>
            </Fade>
          </Modal>
        </Grid>
        <PaymentModal
          openModalP={openPaymentModal}
          setOpenModalP={setOpenPaymentModal}
          paymentInformation={paymentInformation}
        />

        <br />
        {bookingListLoader ? (
          <Loader />
        ) : (
          bookingList.results &&
          bookingList.results.length > 0 && (
            <Box>
              <Container maxWidth={false}>
                <Grid container spacing={3}>
                  <BookingsTable
                    setopenModal={setopenModal}
                    openModal={openModal}
                    page={page}
                    setPage={setPage}
                    loading={loading}
                    getBookings={getAllBookings}
                    bookingList={bookingList}
                    paymentId={paymentId}
                    setPaymentId={setPaymentId}
                    openPaymentModal={openPaymentModal}
                    setOpenPaymentModal={setOpenPaymentModal}
                    syncId={syncId}
                    setSyncId={setSyncId}
                    openSyncModal={openSyncModal}
                    setSyncOpenModal={setSyncOpenModal}
                    setBookingData={setBookingData}
                    setPageNumber={setPageNumber}
                    pageNumber={pageNumber}
                    setAdditionalSyncId={setAdditionalSyncId}
                    openAdditionalSyncModal={openAdditionalSyncModal}
                    setSyncAdditionalOpenModal={setSyncAdditionalOpenModal}
                    setAdditonalBookingData={setAdditonalBookingData}
                    setBookingId={setBookingId}
                    bookingListLoader={bookingListLoader}
                  />
                </Grid>
              </Container>
              <SyncModal
                openSyncModal={openSyncModal}
                setSyncOpenModal={setSyncOpenModal}
                syncId={syncId}
                bookingData={bookingData}
                setSyncButton={setSyncButton}
                getAllBookings={getAllBookings}
                pageNumber={pageNumber}
              />
              <AdditionalSyncModal
                openAdditionalSyncModal={openAdditionalSyncModal}
                setSyncAdditionalOpenModal={setSyncAdditionalOpenModal}
                syncId={syncAdditionalId}
                bookingData={bookingAdditionalData}
                setSyncButton={setSyncButton}
                getBookings={getAllBookings}
                pageNumber={pageNumber}
              />
            </Box>
          )
        )}
        {bookingList.results && bookingList.results.length === 0 && (
          <h6 style={{ textAlign: "center" }}>No Data Found</h6>
        )}
      </main>
      <BookingCommentModal
        openModal={openModal}
        setopenModal={setopenModal}
        bookingId={bookingId}
      />
    </>
  );
};

const mapStateToProps = (state: any) => ({
  agentsList: state.ProductManagerReducer.agentsList,
  partnerList: state.ProductManagerReducer.partnerList,
  bookingList: state.ProductManagerReducer.bookingList,
  cities: state.ProductManagerReducer.cities,
  phleboList: state.ProductManagerReducer.phleboList,
  paymentInformation: state.ProductManagerReducer.paymentInformation,
  loading: state.ProductManagerReducer.loading,
  lead_source: state.ProductManagerReducer.lead_source,
  bookingListLoader: state.ProductManagerReducer.bookingListLoader,

});

export default connect(mapStateToProps, {
  getAgentList,
  getPartners,
  getCities,
  getPhlebos,
  getLeadSource,
  getAllBookings,
  getPaymentInfo,
})(CustomerLeadFilterPage);
