import React, { useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import { connect } from "react-redux";
import { updateLeadDetailsF_2 } from "../../actions/RouteManagerAction";
import "./index.sass";
import { CircularProgress, Grid, Paper } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      marginTop: "2rem",
      padding: "0.5rem 2rem 2rem 2rem",
      width: "100%",
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    slotContainer: {
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "center",
    },
    slot: {
      width: "100px",
      height: "60px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      margin: "0.5rem",
    },
    selectedSlot: {
      width: "100px",
      height: "60px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background: "linear-gradient(60deg, cyan, white, cyan)",
      cursor: "pointer",
      margin: "0.5rem",
    },
    chips: {
      display: "flex",
    },
    chip: {
      margin: 2,
      background: "linear-gradient(60deg, cyan, #00cfff, cyan)",
      maxWidth: "130px",
    },
  })
);
interface Props {
  id: any;
  data: any;
  updateLeadDetailsF_2: Function;
  loading: boolean;
}

const OtherUpdates: React.FC<Props> = ({
  id,
  data,
  updateLeadDetailsF_2,
  loading,
}) => {
  const classes = useStyles();
  const [followup_date, setFollowupDate] = React.useState<string>("");
  const [followup_time, setFollowupTime] = React.useState<string>("");
  const [language, setLanguage] = React.useState<string>("");

  useEffect(() => {
    setFollowupDate(data.followup_date);
    setFollowupTime(data.followup_time);
    setLanguage(data.language);
  }, [data]);

  const submitForm = async () => {
    let body = {
      followup_date,
      followup_time,
      language,
    };
    updateLeadDetailsF_2(id, body);
  };
  return (
    <Paper className={classes.paper} elevation={15}>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="flex-end"
        spacing={3}
        style={{ margin: "1rem auto" }}
      >
        <Grid item xs>
          <p>Follow Up Date</p>
          <TextField
            name="follow_up"
            type="date"
            placeholder="Select Date"
            value={followup_date}
            className={classes.textField}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            style={{ width: "100%", margin: "0" }}
            onChange={(e) => setFollowupDate(e.target.value as any)}
          />
        </Grid>
        <Grid item xs>
          <p>Follow Up Time</p>
          <TextField
            name="follow_up_time"
            type="time"
            placeholder="Select Time"
            value={followup_time}
            className={classes.textField}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            style={{ width: "100%", margin: "0" }}
            onChange={(e) => setFollowupTime(e.target.value as any)}
          />
        </Grid>
        <Grid item xs>
          <p>Language</p>
          <Select
            name="customer_gender"
            variant="outlined"
            defaultValue="male"
            value={language}
            style={{ width: "100%", margin: "0", padding: "0" }}
            onChange={(e) => setLanguage(e.target.value as any)}
          >
            <MenuItem value={""}>Select</MenuItem>
            <MenuItem value={"hi"}>Hindi</MenuItem>
            <MenuItem value={"en"}>English</MenuItem>
            <MenuItem value={"ot"}>Other</MenuItem>
          </Select>
        </Grid>
      </Grid>

      <CardActions style={{ paddingLeft: "16px" }}>
        <Button
          style={{
            background: "#924A91",
            color: "#fff",
            paddingTop: "0",
            paddingBottom: "0",
            width: "100%",
          }}
          size="large"
          onClick={submitForm}
          disabled={loading}
        >
          {/* <b>Submit</b> */}
          {/* <p> */}
          {loading ? (
            <CircularProgress style={{ color: "#fff" }} size={24} />
          ) : (
            "Submit"
          )}

          {/* </p> */}
        </Button>
      </CardActions>
    </Paper>
  );
};

const mapStateToProps = (state: any) => ({
  loading: state.RouteManagerReducer.loading,
});

export default connect(mapStateToProps, { updateLeadDetailsF_2 })(OtherUpdates);
