import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";
import { Grid, TextField, Select, MenuItem } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import {
  getPackage,
} from "../actions/EditPackageMasterAction";
import {
  AppBar,
  Card,
  Chip,
  Dialog,
  Divider,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { TableFooter, TablePagination } from "@material-ui/core";
import Button from '@mui/material/Button';
import { generateProductManagementFilter } from "../../helpers/generateUrl";
import { Link } from "react-router-dom";
import Autocomplete from "@material-ui/lab/Autocomplete";

const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 700,
    },
    root: {
      flexGrow: 0,
      //   width: "100%",
      //   marginTop: "20px",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
      //   padding: theme.spacing(3),
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
  })
);

interface Props {
  getPackage: any;
  packageList: any;
  match: any;
  loading: any;
}

const LeadsTable: React.FC<Props> = ({
  getPackage,
  packageList,
  match,
  loading,
}) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const timer = useRef<any>(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [id, setId] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [code, setCode] = useState<String>("");
  const [packagePrice, setPackagePrice] = useState<string>("");
  const [offerPrice, setOfferPrice] = useState<string>("");
  const [addonPrice, setAddonPrice] = useState<string>("");
  const [parameter, setParameter] = useState<string>("");
  const [fasting, setFasting] = useState<string>("");
  const [status, setStatus] = useState<string>("");
  const [age, setAge] = useState<string>("");
  const [tatModel, setTatModal] = useState<boolean>(false);
  const [tat, setTat] = useState<string>("");
  const [category, setCategory] = useState<string>("");
  const [mrp, setMrp] = useState<string>("");
  const [gender, setGender] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [modalData, setModalData] = useState<any>("");
  const [editUser, setEditUser] = useState<any>({});
  const [categories, setCategories] = useState<any>("");
  const [packageDetails, setPackageDetails] = useState<any>([]);
  // const bookingId = match.params.id;

  const history = useHistory();

  const filterLeads = () => {
    const body: any = {
      package_id: id,
      name: name,
      code: code as String,
      parameter: parameter,
      // category: category,
      recommended_gender: gender,
      package_price: mrp,
      fasting_time: fasting,
      recommended_age: age,
      addon_price: addonPrice,
      offer_price: offerPrice,
      status,
      tat_time: tat,

      category: categories,
    };
    const url = generateProductManagementFilter(body).substring(2);

    getPackage(`${url}`);
  };

  useEffect(() => {
    getPackage();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    // if (newPage > page) {
    //   let url = packageList.links.next;

    //   getPackage(`${url}`.substring(url.indexOf("?") + 1));
    // } else if (newPage < page) {
    //   let url = packageList.links.previous;
    //   getPackage(
    //     url.indexOf("?") === -1 ? "" : `${url}`.substring(url.indexOf("?") + 1)
    //   );
    // }
    if (newPage > page) {
      let url = packageList?.links && packageList?.links.next.split("?")[1];
      getPackage(url);
    } else if (newPage < page) {
      let url = packageList?.links && packageList?.links.previous.split("?")[1];
      getPackage(url);
    }
    setPage(newPage);
  };

  const handleUser = () => {
    let body: any = {
      ...editUser,
    };
    // updateUserList(body)
    setEditUser({});
    setTimeout(() => {
      // getUserList()
    }, 1000);
  };
  const handleOpenModel = (data: any) => {
    setOpen(true);
    setModalData(data);
    // setTatModal(id);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  return (
    <>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid
          container
          spacing={3}
          direction="row"
          alignItems="center"
          justify="center"
          style={{ margin: "0" }}
        >

          <h2 style={{ textAlign: "left" }}>Package Management</h2>
          <Grid container spacing={1} direction="row" style={{ marginTop: 20 }}>
            <Grid item xs={12} sm={4} md={2}>
              <Autocomplete
                freeSolo
                id="test-name-filter"
                options={packageList.results}
                disableClearable
                getOptionLabel={(option: any) => option.name}
                onInputChange={(event, newInputValue) => {
                  clearTimeout(timer.current);
                  timer.current = setTimeout(() => {
                    getPackage(`code=${newInputValue}`);
                  }, 200);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="input"
                    variant="outlined"
                    label="Search by Test name"
                    InputProps={{ ...params.InputProps, type: "search" }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <TextField
                className="input"
                name="Package Code"
                type="text"
                label="Package Code"
                value={code}
                variant="outlined"
                onChange={(e) => setCode(e.target.value)}
                style={{ width: "100%" }}
              />
            </Grid>

            <Grid item xs={12} sm={4} md={2}>
              <Button
                variant="contained"
                color="secondary"
                style={{ height: "56px" }}
                fullWidth
                onClick={filterLeads}
                disabled={loading}
              >
                Filter
              </Button>
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <Button
                variant="contained"
                color="primary"
                style={{ height: "56px" }}
                fullWidth
                onClick={() => history.push("/dashboard/epm/packagemanagement")}
              >
                Reset
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <div
          className="data-table"
          style={{ width: "100%", marginTop: "2rem" }}
        >
          <TableContainer
            className={classes.tableContainer}
            component={Paper}
            style={{ maxHeight: "560px" }}
          >
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell align="center">Package ID</StyledTableCell>
                  <StyledTableCell align="center">Package Code</StyledTableCell>
                  <StyledTableCell align="center">Package Name</StyledTableCell>
                  <StyledTableCell align="center">
                    Package Price
                  </StyledTableCell>
                  <StyledTableCell align="center">Offer Price</StyledTableCell>
                  <StyledTableCell align="center">Addon Price</StyledTableCell>
                  <StyledTableCell align="center">Parameter</StyledTableCell>
                  <StyledTableCell align="center">Fasting</StyledTableCell>
                  <StyledTableCell align="center">Age</StyledTableCell>
                  <StyledTableCell align="center">Gender</StyledTableCell>
                  <StyledTableCell align="center">Edit</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {packageList && packageList?.results ? (
                  packageList?.results.map((item: any) => {
                    return (
                      <StyledTableRow key={item.id}>
                        <StyledTableCell align="center">
                          {item.id ? item.id : "NA"}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {item.code ? item.code : "NA"}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {item.name ? item.name : "NA"}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {item.package_price ? item.package_price : "NA"}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {item.offer_price ? item.offer_price : "NA"}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {item.addon_price ? item.addon_price : "NA"}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {item.parameter ? item.parameter : "NA"}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {item.fasting_time ? item.fasting_time : "NA"}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {item.recommended_age ? item.recommended_age : "NA"}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {item.recommended_gender
                            ? item.recommended_gender
                            : "NA"}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <Button
                            color="primary"
                            variant="text"
                            onClick={() => {
                              history.push(`/dashboard/epm/packagemanagement/package-edit/${item.id}/edit`);
                            }}
                          >
                            Edit
                          </Button>
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })
                ) : (
                  <p style={{ textAlign: "center" }}>No data found</p>
                )}
              </TableBody>
              {packageList && packageList.count > 0 && (
                <TableFooter>
                  <TablePagination
                    colSpan={6}
                    count={packageList.count || 0}
                    rowsPerPageOptions={[]}
                    rowsPerPage={rowsPerPage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    page={page}
                    SelectProps={{
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                  />
                </TableFooter>
              )}
            </Table>
          </TableContainer>
        </div>
      </main>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  packageList: state.EditPackageMasterReducer.packageList,
});

export default connect(mapStateToProps, {
  getPackage,
})(LeadsTable);
