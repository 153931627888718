import { combineReducers } from "redux";

import modalReducer from "./modalReducer";
import secondModalReducer from "./secondModalReducer";
import snackBarReducer from "./snackBarReducer";
import loginReducer from "./loginReducer";
import PhleboAdminReducer from "../PhleboAdmin-Panel/reducers/PhleboAdminReducer";
import RouteManagerReducer from "../RouteManager-Panel/reducers/RouteManagerReducer";
import LogisticsReducer from "../Logistics-panel/reducers/LogisticsReducer";
import ProductManagerReducer from "../ProductManager-Panel/reducers/ProductManagerReducer";
import EditPackageMasterReducer from "../EditPackageMaster-Panel/reducers/EditPackageMasterReducer"
import BulkUploadMasterReducer from "../BulkUploadMaster-Panel/reducers/BulkUploadMasterReducer"
// import notificationReducer from './notificationReducer';

const rootReducer = combineReducers({
  modalReducer,
  secondModalReducer,
  snackBarReducer,
  loginReducer,
  PhleboAdminReducer,
  RouteManagerReducer,
  LogisticsReducer,
  EditPackageMasterReducer,
  ProductManagerReducer,
  BulkUploadMasterReducer
});

export default rootReducer;
