import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from '@mui/material/Modal';
import Backdrop from "@material-ui/core/Backdrop";
import Button from '@mui/material/Button';
import Fade from "@material-ui/core/Fade";
import { TableContainer, Table, Chip, TableHead, TableBody, TableRow, TableCell, Paper, TextField, Grid, Select, MenuItem } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
let width = window.innerWidth;
let isMobile = width <= 500;


const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        marginTop: "2rem",
        padding: "0.5rem 2rem",
        width: "100%",
    },
    table: {
        maxWidth: "100%",
        margin: "auto"
    },
    head: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "2rem",
    },
}));

type ModalProps = {
    additionalMemebr: any;
    additionalModal: any;
    setAdditonalModal: any;
    loading: any;
};

const CreatePhlebo: React.FC<ModalProps> = ({
    additionalMemebr,
    additionalModal,
    setAdditonalModal,
    loading,
}) => {
    const classes = useStyles();
    const timer = useRef<any>(0)

    const [phleboName, setPhleboName] = useState<string>("");
    const [phleboNumber, setPhleboNumber] = useState<string>("");
    const [employeId, setEmployeId] = useState<string>("");
    const [id, setId] = useState<any>(0);
    const [phleboSource, setPhleboSource] = useState<string>("none");
    const [zoneName, setZoneName] = useState<string>("none");
    const [bookingId, setBookingId] = useState<any>("")
    const [name, setName] = useState<any>("")
    const [memberData, setMemberData] = useState<any>()
    const handleClose = () => {
        setAdditonalModal(false);
        setId(id + 1);
    };
    useEffect(() => {
        setMemberData(additionalMemebr)
    }, [additionalMemebr])

    const filterBookings = () => {
        const allData = [...additionalMemebr]
        const filterData = allData && allData.filter((data: any) => {
            if (bookingId !== "") {
                return data.id == bookingId
            }
            else {
                return (data.customer_name).includes(name)
            }
        })
        if (bookingId === "" && name === "") {
            setMemberData(allData)
        }
        else {
            setMemberData(filterData)
        }

    }
    const handleReset = ()=>{
        const allData = [...additionalMemebr]
        setMemberData(allData)
        setBookingId("")
        setName("")
    }
    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={additionalModal}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                key={id}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={additionalModal}>
                    <div className={classes.paper}>
                        <Paper elevation={15} className={classes.paper}>
                            <div className={classes.head}>

                                <h4
                                    style={{
                                        marginBottom: "1rem",
                                    }}
                                    id="transition-modal-title"
                                >
                                    ADDITIONAL FAMILY MEMBER
                                </h4>
                                <CloseIcon onClick={() => setAdditonalModal(false)} />
                            </div>
                            <Grid container spacing={3} direction="row" style={{ alignItems: "left" }}>
                                <Grid item xs={12} sm={4} md={2}>
                                    <TextField
                                        className="input"
                                        name="name"
                                        type="number"
                                        label="Booking Id"
                                        value={bookingId}
                                        variant="outlined"
                                        onChange={(e) => {
                                            setBookingId(e.target.value)
                                            setName("")
                                        }}
                                        style={{ width: "100%" }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4} md={2}>
                                    <TextField
                                        className="input"
                                        name="name"
                                        type="text"
                                        label="Name"
                                        value={name}
                                        variant="outlined"
                                        onChange={(e) => {
                                            setName(e.target.value as string)
                                            setBookingId("")
                                        }
                                        }
                                        style={{ width: "100%" }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4} md={2}>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        style={{ height: "56px" }}
                                        fullWidth
                                        onClick={filterBookings}
                                        disabled={loading}
                                    >
                                        Filter
                                    </Button>
                                </Grid>
                                <Grid item xs={12} sm={4} md={2}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        style={{ height: "56px" }}
                                        fullWidth
                                        onClick={handleReset}
                                    >
                                        Reset
                                    </Button>
                                </Grid>
                            </Grid>
                            <TableContainer className={classes.table} component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">Additional Booking ID</TableCell>
                                            <TableCell align="center">Bill ID</TableCell>
                                            <TableCell align="center">NAME</TableCell>
                                            <TableCell align="center">GENDER</TableCell>
                                            <TableCell align="center">AGE</TableCell>
                                            <TableCell align="center">Created At</TableCell>
                                            <TableCell align="center">BOOKING STATUS</TableCell>
                                            <TableCell align="center">REPORT STATUS</TableCell>
                                            <TableCell align="center">PICKUP STATUS</TableCell>
                                            <TableCell align="center">REPORT VERIFICATION STATUS</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {memberData && memberData.map((data: any, index: any) => {

                                            return (
                                                <TableRow key={index}>
                                                    <TableCell align="center">{data?.id || "N/A"}</TableCell>
                                                    <TableCell align="center">{data?.bill_id || "N/A"}</TableCell>
                                                    <TableCell align="center">{data?.customer_name || "N/A"}</TableCell>
                                                    <TableCell align="center">{data?.customer_gender || "N/A"}</TableCell>
                                                    <TableCell align="center">{data?.customer_age || "N/A"}</TableCell>
                                                    <TableCell align="center">
                                                        {new Date(data?.created_at).toLocaleString()}
                                                    </TableCell>
                                                    <TableCell align="center">{data?.booking_status || "N/A"}</TableCell>
                                                    <TableCell align="center">{data?.report_status || "N/A"}</TableCell>
                                                    <TableCell align="center">{data?.pickup_status || "N/A"}</TableCell>
                                                    <TableCell align="center">{data?.report_verification_status || "N/A"}</TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
};

const mapStateToProps = (state: any) => ({

});

export default connect(mapStateToProps, {
})(CreatePhlebo);
