import React, { useState, useEffect, useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Card,
  Grid,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@material-ui/core";
import {
  getPinCode,
  getPackage,
  updateBooking,
  getBookingById,
  getCoupons,
  getPartners,
  getAvailableSlots,
  getMapMyIndiaAccessToken,
  checkGeoFenceArea,
  checkGeoFenchMapMyIndia,
} from "../../../actions/productMangerAction";
import Button from "@material-ui/core/Button";
import "./index.sass";
import { connect } from "react-redux";
import moment from "moment";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useHistory } from "react-router";
import { Chip } from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import { GoogleMapsAPI } from "../../../../helpers/client-config";
import { GENDER_CHOICE } from "../../constant";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
    },
    contentHeader: {
      width: "100%",
      padding: "0px 10px 10px 10px",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      padding: "20px 20px 20px 20px",
      marginTop: "20px",
      width: "100%",
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    head: {
      textAlign: "center",
      fontWeight: "normal",
      marginBottom: "2rem",
    },
    slotContainer: {
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "center",
    },
    slot: {
      width: "150px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      margin: "0.5rem",
    },
    selectedSlot: {
      width: "150px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      background: "linear-gradient(60deg, cyan, #00dfff, cyan)",
      cursor: "pointer",
      margin: "0.5rem",
    },
    chips: {
      display: "flex",
    },
    chip: {
      margin: 2,
      background: "linear-gradient(60deg, cyan, #00cfff, cyan)",
      maxWidth: "130px",
    },
  })
);

interface Props {
  getPinCode: any;
  pin_code: any;
  getPackage: any;
  packageList: any;
  couponList: any;
  getPartners: any;
  getCoupons: any;
  partnerList: any;
  updateBooking: any;
  match: any;
  booking: any;
  getBookingById: any;
  booking_slots: any;
  getAvailableSlots: any;
  loading: boolean;
  checkGeoFenceArea: any;
  checkGeoFenceStatus: boolean;
  checkGeoFenchMapMyIndia: any;
  geoFencesMapMyIndia: any;
  getMapMyIndiaAccessToken: any;
  mapmyindiaAccessToken: any;
}

const EditBookingForm: React.FC<Props> = ({
  getPinCode,
  pin_code,
  packageList,
  getPackage,
  couponList,
  getCoupons,
  partnerList,
  updateBooking,
  match,
  booking,
  getBookingById,
  booking_slots,
  getAvailableSlots,
  loading,
  getPartners,
  checkGeoFenceArea,
  checkGeoFenceStatus,
  checkGeoFenchMapMyIndia,
  geoFencesMapMyIndia,
  getMapMyIndiaAccessToken,
  mapmyindiaAccessToken,
}) => {
  const classes = useStyles();

  const history = useHistory();

  const [collection_date, setCollection_date] = useState<String>(
    booking.collection_date
  );
  const [booking_date, setBooking_date] = useState<string>(
    booking.booking_date
  );
  const [pincode, setPinCode] = useState<String>(
    booking.customer_areapincode && booking.customer_areapincode.id
  );
  const [zoneName, setZoneName] = useState<String>(
    booking.customer_areapincode && booking.customer_areapincode.area
  );
  const [timeSlots, setTimeSlots] = useState<Array<Object>>([]);
  const [selectedSlot, setSelectedSlot] = useState<Number>(
    booking.collection_slot && booking.collection_slot.id
  );

  const [customer_name, setCustomer_name] = useState<String>(
    booking.customer_name
  );
  const [customer_designation, setCustomer_designation] = useState<string>();
  const [designation, setDesignation] = useState<String>();

  const [customer_age, setCustomer_age] = useState<String>(
    booking.customer_age
  );
  const [customer_gender, setCustomer_gender] = useState<string>();
  const [customer_phonenumber, setCustomer_phonenumber] = useState<String>(
    booking.customer_phonenumber
  );
  const [customer_alternatenumber, setCustomer_alternatenumber] =
    useState<String>(booking.customer_altphonenumber);
  const [customer_whatsapppnumber, setCustomer_whatsapppnumber] =
    useState<String>(booking.customer_whatsapppnumber);
  const [customer_email, setCustomer_email] = useState<String>(
    booking.customer_email
  );
  const [customer_landmark, setCustomer_landmark] = useState<String>(
    booking?.customer_landmark
  );
  const [sublocality, setSubLocality] = useState<any>(booking?.landmark)
  const [landmark, setLandmark] = useState<any>(null);
  const [geoLocation, setGeoLocation] = useState<any>([]);
  const [customer_longitude, setCustomerLongitude] = useState<any>("");
  const [customer_latitude, setCustomerLatitude] = useState<any>("");
  const [customer_areapincode, setCustomer_areapincode] = useState<Number>(0);
  const [customer_areaname, setCustomer_areaname] = useState<String>(
    booking.customer_areapincode && booking.customer_areapincode.area
  );
  const [customer_address, setCustomer_address] = useState<String>(
    booking.customer_address && booking.customer_address
  );
  const [addressLine2, setAddressLine2] = useState<string>(booking?.address_line2 &&
    booking?.address_line2);
  const [customer_areapincodeDef, setCustomer_areapincodeDef] = useState<Number>(booking.customer_areapincode)
  const [favoritePlace, setFavoritePlace] = useState<any>(booking?.landmark &&
    booking?.landmark);
  const [customer_city, setCustomer_city] = useState<String>("");
  const [customer_aadhar, setCustomer_aadhar] = useState<String>(
    booking.customer_aadhar
  );
  const [passport_number, setPassportNumber] = useState<String>(
    booking.passport_number
  );
  const [adharPassport, setAdharPassport] = useState(
    booking.customer_aadhar !== "" ? "customer_aadhar" : "passport_number"
  );
  // const [partner, setPartner] = useState<String>(
  //   booking?.partner && booking?.partner?.id
  // );
  const [partner, setPartner] = useState<any>(0);
  const [packageDetails, setPackageDetails] = useState<any>(booking.packages);
  const [packageKey, setPackageKey] = useState<any>(0);
  const [packages, setPackages] = useState<Number[]>(
    booking.packages &&
    booking.packages.map((pack: any) => {
      return pack.id;
    })
  );
  const [isPriceHide, setIsPriceHide] = useState<boolean>(false);

  const [coupon, setCoupon] = useState<Number>(0);
  const [couponCode, setCouponCode] = useState<string>("");
  const [price, setPrice] = useState<any>(
    booking.discounted_price && booking.discounted_price.total_price_package
  );
  const [discount, setDiscount] = useState<any>(
    booking.discounted_price && booking.discounted_price.counpon_discount
  );
  const [totalPrice, setTotalPrice] = useState<any>(
    booking.discounted_price && booking.discounted_price.final_total_price
  );
  const [extraCharge, setExtraCharge] = useState<boolean>(
    booking.discounted_price && booking.discounted_price.phlebo_cost !== 0
  );
  const [cityId, setCityId] = useState<any>(booking && booking.cityid);

  const [packKey, setPackKey] = useState<any>(0);
  const [zoneId, setZoneId] = useState<any>("");
  const [couponKey, setCouponKey] = useState<any>(0);
  const [is_pincode, setIsPincode] = useState<boolean>(true);
  const [mobileValidation, setMobileValidation] = useState({
    customer_alternatenumber: true,
    customer_phonenumber: true,
    customer_whatsapppnumber: true,
  });
  const bookingId = match.params.id;
  const timer = useRef<any>(0);

  useEffect(() => {
    if (booking?.coupon_applied) {
      setCoupon(booking?.coupon_applied.id);
      setCouponCode(booking?.coupon_applied?.name);
    }
    if (booking?.designation && booking?.customer_gender) {
      setCustomer_designation(booking?.designation);
      const findGender = GENDER_CHOICE.filter(
        (el: any) => el.db_name == booking?.designation
      );
      if (
        booking?.designation == "Master" &&
        booking?.customer_gender == "male"
      ) {
        setDesignation("Baby Boy");
      } else if (
        booking?.designation == "Master" &&
        booking?.customer_gender == "female"
      ) {
        setDesignation("Baby Girl");
      } else {
        findGender.length > 0 && setDesignation(findGender[0].display_name);
      }
      setCustomer_gender(booking?.customer_gender);
    }
  }, []);

  useEffect(() => {
    getPackage(`city_id=${cityId}`);
    getCoupons("?org_type=homedx");
    getPartners();
    getMapMyIndiaAccessToken();
  }, []);

  useEffect(() => {
    getBookingById(`${bookingId}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingId]);

  useEffect(() => {
    getArea(
      collection_date,
      booking.customer_areapincode && booking.customer_areapincode.pincode
    );
    setCustomer_areapincode(booking?.customer_areapincode.id)
  }, [booking]);

  useEffect(() => {
    const slots = booking_slots && booking_slots.results;
    setTimeSlots(slots && slots[0].zone_data && slots[0].zone_data.time_slots);
  }, [booking_slots]);

  const handlePinCode = (val: String) => {
    setPinCode(val as string);
    // getArea(val as String)
    getArea(collection_date, val as String);
    const { id, area, city, cityid } = pin_code.results.find(
      (code: any) => code.pincode === val
    );
    setCustomer_areapincode(id as Number);
    getPackage(`city_id=${cityid}`);
    setCustomer_areaname(area as String);
    setCustomer_city(city as String);
    setCityId(cityid);
    setIsPincode(false);
    setPackageDetails([]);
    setPackages([]);
    setPackageKey(cityid);
    setCoupon(0);
    setCouponKey(cityid);
  };
  const getArea = (collection_date: String, pincode: String) => {
    const zone =
      pin_code.results &&
      pin_code.results.find((code: any) => code.pincode === pincode);
    setZoneName(zone && (zone.area as String));
    if (zone && zone.zone_data && zone.zone_data.id) {
      getAvailableSlots(
        collection_date,
        zone && zone.zone_data && zone.zone_data.id
      );
    }

    setCustomer_areapincode(zone && (zone.id as Number));
    setCustomer_city(zone && (zone.city as String));
    setZoneId(zone && zone.zone_data && zone.zone_data.id);
  };

  const checkNormalPackage = (packages: any) => {
    let isNormalPackage = false;
    packages &&
      packages.length > 0 &&
      packages.map((item: any) => {
        if (!item.is_addon) {
          isNormalPackage = true;
        }
      });
    return isNormalPackage;
  };

  const getNoofPackageBuyOneGetOne = () => {
    let noOfBuyOneGetOne = 0;
    booking.additional_members &&
      booking.additional_members.map((item: any) => {
        if (item?.packages && item?.packages.length > 0) {
          item?.packages.map((packageItem: any) => {
            if (packageItem?.code == "PL50") {
              noOfBuyOneGetOne = noOfBuyOneGetOne + 1;
            }
          });
        }
      });
    return noOfBuyOneGetOne;
  };

  const handlePackages = (customerPackage: any) => {
    const min_phlebo_cost = packageList && packageList?.results && packageList?.results.length > 0 && packageList?.results[0].min_booking_amount_for_phlebo
    setPackageDetails(customerPackage);
    let accumulatedPrice: any = 0;
    let packIds: any = [];

    if (customerPackage && customerPackage.length > 0) {
      let atLeastOneNormalPackage = checkNormalPackage(customerPackage);
      let pl50Count =
        packageDetails?.length > 0 ? getNoofPackageBuyOneGetOne() : 0;
      let priceApplicable = pl50Count > 0 ? pl50Count % 2 : 0;
      customerPackage.map((item: any) => {
        packIds.push(item.id);
        if (pl50Count > 0 && priceApplicable == 1 && item?.code == "PL50") {
          accumulatedPrice = accumulatedPrice + 0;
        } else {
          if (
            atLeastOneNormalPackage &&
            item.is_addon &&
            item.addon_price &&
            item.addon_price > 0
          ) {
            accumulatedPrice = accumulatedPrice + item.addon_price;
          } else {
            if (item.package_city_prices === null) {
              accumulatedPrice = accumulatedPrice + item.offer_price;
            } else {
              accumulatedPrice =
                accumulatedPrice + item.package_city_prices.offer_price;
            }
          }
        }
        if (accumulatedPrice < min_phlebo_cost) {
          setExtraCharge(true);
        } else {
          setExtraCharge(false);
        }
      });
    }
    // Edit Family Members
    if (
      booking?.additional_members &&
      booking?.additional_members?.length > 0
    ) {
      booking.additional_members.map((item: any) => {
        if (item.packages) {
          let atLeastOneNormalPackage = checkNormalPackage(item?.packages);
          item.packages.map((pack: any) => {
            if (
              atLeastOneNormalPackage &&
              pack.is_addon &&
              pack.addon_price &&
              pack.addon_price > 0
            ) {
              accumulatedPrice = accumulatedPrice + pack.addon_price;
            } else {
              if (pack.package_city_prices === null) {
                accumulatedPrice = accumulatedPrice + pack.offer_price;
              } else {
                accumulatedPrice =
                  accumulatedPrice + pack.package_city_prices.offer_price;
              }
              if (accumulatedPrice < min_phlebo_cost) {
                setExtraCharge(true);
              } else {
                setExtraCharge(false);
              }
            }
          });
        }
      });
    }
    setPackages(packIds);
    setPrice(Math.floor(accumulatedPrice));
  };

  useEffect(() => {
    if (extraCharge) {
      setTotalPrice(Math.floor(price - discount + 200));
    } else {
      setTotalPrice(Math.floor(price - discount));
    }
  }, [price, discount, extraCharge]);

  useEffect(() => {
    if (totalPrice < 0) {
      setTotalPrice(0);
    }
  }, [totalPrice]);

  const applyCoupon = () => {
    if (couponCode === "") {
      setCouponCode("");
      setCoupon(0);
      setDiscount(0);
      return;
    }
    const appliedCoupon =
      couponList.results.find((coup: any) => coup.id === coupon) ||
      couponList.results.find((coup: any) => coup.code === couponCode);
    if (appliedCoupon && appliedCoupon.minimum_price < totalPrice) {
      setCoupon(appliedCoupon.id);
      if (appliedCoupon.discount_type.toLowerCase() === "percentage") {
        if (appliedCoupon.is_red_cash) {
          setDiscount(0);
        }
        else {
          setDiscount(Math.floor((price * appliedCoupon.discount) / 100));
        }

      }
      if (appliedCoupon.discount_type.toLowerCase() === "amount") {
        if (appliedCoupon.is_red_cash) {
          setDiscount(0);
        }
        else {
          setDiscount(Math.floor(appliedCoupon.discount));
        }
      }
    } else {
      setCouponCode("");
      setCoupon(0);
      setDiscount(0);
    }
  };
  const verifyMobile = (type: any) => {
    if (type === "mobile") {
      if (parseInt(customer_phonenumber && customer_phonenumber[0]) < 6 || customer_phonenumber && customer_phonenumber.length !== 10) {
        setMobileValidation((prev: any) => ({
          ...prev,
          customer_phonenumber: false,
        }));
      }
    }
    if (type === "alternate") {
      if (parseInt(customer_alternatenumber && customer_alternatenumber[0]) < 6 || customer_alternatenumber && customer_alternatenumber.length !== 10) {
        setMobileValidation((prev: any) => ({
          ...prev,
          customer_alternatenumber: false,
        }));
      }
    }
    if (type === "whatsapp") {
      if (parseInt(customer_whatsapppnumber && customer_whatsapppnumber[0]) < 6 || customer_whatsapppnumber && customer_whatsapppnumber.length !== 10) {
        setMobileValidation((prev: any) => ({
          ...prev,
          customer_whatsapppnumber: false,
        }));
      }
    }
  };
  const submitForm = async (e: any) => {
    e.preventDefault();
    const data: any = {
      booking_date: moment(new Date()).format("YYYY-MM-DD"),
      collection_date: collection_date,
      collection_slot: selectedSlot,
      customer_name: customer_name,
      customer_age: customer_age,
      customer_gender: customer_gender,
      customer_email: customer_email,
      customer_phonenumber: customer_phonenumber,
      customer_altphonenumber: customer_alternatenumber,
      customer_whatsapppnumber: customer_whatsapppnumber,
      customer_address: customer_address,
      customer_landmark: customer_landmark,
      customer_longitude: customer_longitude,
      customer_latitude: customer_latitude,
      customer_areapincode: customer_areapincode,
      customer_city: customer_city,
      customer_aadhar: customer_aadhar,
      passport_number: passport_number === null ? "" : passport_number,
      packages: packages,
      coupon_applied: coupon,
      // "partner": booking_source,
      designation: customer_designation,
      address_line2: addressLine2,
      landmark: favoritePlace,
    };

    if (data["coupon_applied"] === 0 || couponCode === "") {
      delete data["coupon_applied"];
    }

    // if (removeCoupn && !data['coupon_applied']) {
    //     data['remove_coupon'] = "true"
    // }
    await updateBooking(data, bookingId);
    history.push(`/dashboard/pm/booking-view/${bookingId}`);
  };
  const changeCollectionDate = (e: any) => {
    setSelectedSlot(0)
    setCollection_date(e.target.value as string);
    if (zoneId) {
      getAvailableSlots(e.target.value, zoneId);
      getPackage(`city_id=${cityId}`);
    }
  };

  useEffect(() => {
    setCustomer_landmark(landmark ? landmark.label : booking.customer_landmark);
    if (landmark?.label) {
      geocodeByAddress(landmark?.label)
        .then((results) => getLatLng(results[0]))
        .then(({ lat, lng }) => {

          lat && setCustomerLatitude(lat);
          lng && setCustomerLongitude(lng);
        });
    }
  }, [landmark]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAdharPassport((event.target as HTMLInputElement).value);
  };

  const titleSelection = (title: string) => {
    const findGender = GENDER_CHOICE.filter(
      (el: any) => el.display_name == title
    );
    setDesignation(title);
    setCustomer_designation(findGender[0].db_name);
    setCustomer_gender(findGender[0].gender);
  };
  //mapmyindia
  const [suggestedAddress, setSuggestedAddres] = useState<any>([]);
  const [mapAddressObject, setMapAddressObject] = useState<any>({});
  const [accessToken, setAccessToken] = useState<any>("");
  useEffect(() => {
    if (geoFencesMapMyIndia?.latitude && geoFencesMapMyIndia?.longitude) {
      setCustomerLatitude(geoFencesMapMyIndia?.latitude);
      setCustomerLongitude(geoFencesMapMyIndia?.longitude);
    }
  }, [geoFencesMapMyIndia]);

  useEffect(() => {
    if (mapmyindiaAccessToken?.accesstoken) {
      setAccessToken(mapmyindiaAccessToken?.accesstoken);
    }
  }, [mapmyindiaAccessToken]);

  useEffect(() => {
    if (mapAddressObject?.eLoc) {
      getPinCode(mapAddressObject?.addressTokens?.pincode);
      setCustomer_landmark(mapAddressObject?.placeAddress);
      checkGeoFenchMapMyIndia(mapAddressObject?.eLoc, "NA");
    }
  }, [mapAddressObject]);

  useEffect(() => {
    if (pin_code?.results && pin_code?.results.length > 0) {
      setCityId(pin_code?.results[0].cityid);
      setPinCode(pin_code?.results[0].pincode)
    }
  }, [pin_code]);

  const locationAutoCompleteSearch = (val: string) => {
    if (val.length > 2) {
      setSuggestedAddres([]);
      const urlForMap = `https://atlas.mapmyindia.com/api/places/search/json?query=${val}&access_token=${accessToken}&tokenizeAddress=true`;
      return new Promise(function (resolve, reject) {
        const id = "_" + Math.round(10000 * Math.random());
        const callbackName = "jsonp_callback_" + "data" + id;
        window[callbackName] = (data: any) => {
          delete window[callbackName];
          const ele = document.getElementById(id);
          ele?.parentNode?.removeChild(ele);
          resolve(data?.suggestedLocations);
          setSuggestedAddres(data?.suggestedLocations);
        };
        const src = urlForMap + "&callback=" + callbackName;
        const script = document.createElement("script");
        script.src = src;
        script.id = id;
        script.addEventListener("error", reject);
        (
          document.getElementsByTagName("head")[0] ||
          document.body ||
          document.documentElement
        ).appendChild(script);
      });
    }
  };

  //mapmyindia
  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <div className={classes.contentHeader}>
        <h2>Edit Boooking</h2>
      </div>
      <Paper className={classes.paper} elevation={15}>
        <form onSubmit={submitForm}>
          <Grid container spacing={3} style={{ margin: "auto" }}>
            <Grid item xs={12} md={6}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <p>Booking date</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <input
                    className="input"
                    name="collection_date"
                    type="date"
                    value={booking_date || ""}
                    style={{
                      width: "100%",
                      margin: "0",
                      padding: "18.5px 14px",
                      height: "40px"
                    }}
                    required
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <p>Sample Collection date</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <input
                    className="input"
                    name="collection_date"
                    type="date"
                    value={
                      (collection_date && collection_date.toString()) || ""
                    }
                    // onChange={(e) => setCollection_date(e.target.value as string)}
                    onChange={(e) => changeCollectionDate(e)}
                    style={{
                      width: "100%",
                      margin: "0",
                      padding: "18.5px 14px",
                      height: "40px",
                    }}
                    // min={`${new Date().getFullYear()}-${(new Date().getMonth() + 1) < 10 ? `0${(new Date().getMonth() + 1)}` : (new Date().getMonth() + 1)}-${new Date().getDate() < 10 ? `0${new Date().getDate()}` : new Date().getDate()}`}
                    required
                  />
                </Grid>
              </Grid>
              {/* <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <p>Pin Code</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    id="pincode"
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let obj = JSON.parse(
                          JSON.stringify(newValue, null, " ")
                        );
                        handlePinCode(obj.pincode as String);
                      }
                    }}
                    defaultValue={customer_areapincodeDef || { id: "", pincode: "", area: "" }}
                    options={pin_code.results}
                    freeSolo
                    blurOnSelect
                    onInputChange={(event, newInputValue) => {
                      clearTimeout(timer.current);
                      timer.current = setTimeout(() => {
                        getPinCode(newInputValue.split(",")[0]);
                      }, 1000);
                    }}
                    getOptionLabel={(option: any) =>
                      `${option.pincode}, ${option.area}`
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Pin Code"
                        variant="outlined"
                        style={{ width: "100%", margin: "0", padding: "0" }}
                        className="input"
                        disabled={booking.phlebo !== null}
                        required
                      />
                    )}

                  />
                </Grid>
              </Grid> */}
            </Grid>
            <Grid item xs={12} md={6}>
              <Card
                className="sampleCollectionTime"
                style={{ height: "100%", overflow: "auto" }}
              >
                <p style={{ marginBottom: "1rem" }}>Sample Collection Time</p>
                <div className={classes.slotContainer}>
                  {timeSlots &&
                    timeSlots.length > 0 &&
                    timeSlots.map((slot: any) => {
                      return (
                        slot.available_slots > 0 && (
                          <Card
                            key={slot.id}
                            className={
                              slot.id === selectedSlot
                                ? classes.selectedSlot
                                : classes.slot
                            }
                            onClick={() => setSelectedSlot(slot.id as Number)}
                          >
                            <p
                              style={{
                                textAlign: "center",
                                color: "black",
                                fontSize: "14px",
                              }}
                            >
                              {slot.start_time1hr}-{slot.end_time1hr}
                            </p>
                            {/* <p style={{ textAlign: "center", color: "black" }}>{moment(`${slot.start_time}`, ["HH.mm.ss"]).format("hh:mm A")} - {moment(`${slot.end_time}`, ["HH.mm.ss"]).format("hh:mm A")}</p> */}
                            <p style={{ textAlign: "center", color: "green" }}>
                              {" "}
                              Availabe slots: {slot.available_slots}
                            </p>
                          </Card>
                        )
                      );
                    })}
                </div>
              </Card>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="flex-end"
            spacing={3}
            style={{ margin: "1rem auto", background: "#eff" }}
          >
            <Grid item xs>
              <p>Title</p>
              <Select
                className="input"
                name="customer_designation"
                variant="outlined"
                value={designation || ""}
                style={{ width: "100%", height: "40px" }}
                onChange={(e) => titleSelection(e.target.value as string)}
                required
              >
                <MenuItem value="Mr">Mr</MenuItem>
                  <MenuItem value="Ms">Ms (Mrs/Miss)</MenuItem>
                  {/* <MenuItem value="other">Others</MenuItem> */}
              </Select>
            </Grid>
            <Grid item xs>
              <p>Customer Name</p>
              <TextField
                name="customer_name"
                type="text"
                placeholder="Enter Name"
                value={customer_name}
                className="input"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ width: "100%", margin: "0" }}
                onChange={(e) => setCustomer_name(e.target.value as String)}
                required
              />
            </Grid>
            <Grid item xs>
              <p>Age</p>
              <TextField
                name="customer_age"
                type="number"
                placeholder="Enter Age"
                value={customer_age}
                className="input"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ width: "100%", margin: "0" }}
                onChange={(e) => setCustomer_age(e.target.value as String)}
                required
              />
            </Grid>
            <Grid item xs>
              <p>Gender</p>
              <Select
                className="input"
                name="customer_gender"
                variant="outlined"
                value={customer_gender || ""}
                style={{ width: "100%", height: "40px", padding: "0" }}
                onChange={(e) => setCustomer_gender(e.target.value as string)}
                required
                disabled
              >
                <MenuItem selected hidden value={""}>
                  Please Select
                </MenuItem>
                <MenuItem value={"male"}>Male</MenuItem>
                <MenuItem value={"female"}>Female</MenuItem>
               
              </Select>
            </Grid>
            <Grid item xs>
              <p>Package Name</p>
              <Autocomplete
                id="package"
                key={packageKey}
                onChange={(event, newValue) => {
                  if (newValue) {
                    let packs: any = [];
                    newValue.map((val: any) => {
                      let obj = JSON.parse(JSON.stringify(val, null, " "));
                      packs.push(obj);
                    });
                    handlePackages(packs);
                    setPackKey(1);
                  }
                }}
                multiple
                options={packageList.results}
                value={packageDetails}
                disableClearable
                limitTags={0}
                getOptionLabel={(option: any) =>
                  option.name && option.package_city_prices
                    ? option.name.toString() +
                    ", " +
                    option.package_city_prices.tat_time +
                    ", Rs. " +
                    option?.package_city_prices.offer_price + "/-"
                    : option.name + ", " + option?.tat_time + ", Rs. " + option?.offer_price + "/-"
                }
                getOptionDisabled={(option: any) => {
                  const packID = packageDetails.map((pack: any) => pack.id);
                  return packID.includes(option.id);
                }}
                onInputChange={(event, newInputValue) => {
                  clearTimeout(timer.current);
                  timer.current = setTimeout(() => {
                    getPackage(`city_id=${cityId}&code=${newInputValue}`);
                  }, 1000);
                }}
                freeSolo
                disableCloseOnSelect
                renderTags={(value: string[], getTagProps) =>
                  value.map((option: any, index: number) => (
                    <Chip
                      variant="default"
                      color="primary"
                      label={
                        option.package_city_prices
                          ? option.name.toString() +
                          ", " +
                          option.package_city_prices.tat_time +
                          ", Rs. " +
                          option?.package_city_prices.offer_price + "/-"
                          : option.name.toString() + ", " + option.tat_time + ", Rs." + option?.offer_price + "/-"
                      }
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Package"
                    variant="outlined"
                    style={{ width: "100%", margin: "0", padding: "0" }}
                    className="input"
                  />
                )}
              />
            </Grid>
            {/* <Grid item xs>
              <p>Partner</p>
              <TextField
                name="partner"
                type="text"
                value={partner}
                placeholder="Enter Partner "
                className="input"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => setPartner(e.target.value as String)}
                style={{ width: "100%", margin: "0" }}
              />
            </Grid> */}
            <Grid item xs>
              {/* <Grid item xs={12} md={6}> */}
              <p>Partner</p>
              {/* </Grid> */}
              {/* <Grid item xs={12} md={6}> */}
              <Autocomplete
                id="partnername"
                onChange={(event, newValue) => {
                  if (newValue) {
                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                    setPartner(obj.id)
                    setIsPriceHide(obj.is_price_hide)
                  }
                }}
                options={partnerList.results}
                freeSolo
                blurOnSelect
                aria-required
                disabled={addressLine2 === "" || customer_address === "" || favoritePlace === ""}
                getOptionLabel={(option: any) => option.user && option.user.name}
                onInputChange={(event, newInputValue) => {
                  clearTimeout(timer.current)
                  timer.current = setTimeout(() => {
                    getPartners(`${newInputValue}`)
                  }, 1000)
                  if (newInputValue.length === 0) {
                    setPartner(0)
                  }
                }}
                renderInput={(params) => (
                  <TextField className="input"
                    {...params}
                    placeholder="Partner"
                    variant="outlined"
                    style={{ width: "100%", margin: "0", padding: "0" }}

                  />
                )}
              />
            </Grid>
          </Grid>
          {/* </Grid> */}
          <Grid
            container
            spacing={3}
            direction="row"
            justify="center"
            alignItems="center"
            style={{ margin: "1rem auto" }}
          >
            <Grid item xs={12} md={6}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <p>Mobile Number</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    name="customer_phonenumber"
                    type="text"
                    value={customer_phonenumber}
                    placeholder="Enter Mobile Number"
                    className="input"
                    variant="outlined"
                    disabled
                    style={{ margin: "0", width: "100%" }}
                    onChange={(e) =>
                      setCustomer_phonenumber(e.target.value as String)
                    }
                    onBlur={() => verifyMobile("mobile")}
                    onFocus={() => {
                      setMobileValidation((prev: any) => ({
                        ...prev,
                        customer_phonenumber: true,
                      }));
                    }}
                    helperText={
                      !mobileValidation.customer_phonenumber &&
                      "Incorrect Phone Number"
                    }
                    required
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <p>Alternate Mobile Number</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    name="customer_alternatenumber"
                    type="text"
                    value={customer_alternatenumber}
                    placeholder="Enter Alternate Mobile Number"
                    className="input"
                    variant="outlined"
                    style={{ margin: "0", width: "100%" }}
                    onChange={(e) =>
                      setCustomer_alternatenumber(e.target.value as String)
                    }
                    required
                    onBlur={() => verifyMobile("alternate")}
                    onFocus={() => {
                      setMobileValidation((prev: any) => ({
                        ...prev,
                        customer_alternatenumber: true,
                      }));
                    }}
                    helperText={
                      !mobileValidation.customer_alternatenumber &&
                      "Incorrect alternatenumber Number"
                    }
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <p>Email</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    name="customer_email"
                    type="email"
                    placeholder="Enter Email"
                    value={customer_email}
                    className="input"
                    variant="outlined"
                    style={{ margin: "0", width: "100%" }}
                    onChange={(e) =>
                      setCustomer_email(e.target.value as String)
                    }
                    required
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <p>Whatsapp Number</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    name="customer_whatsapppnumber"
                    type="text"
                    value={customer_whatsapppnumber}
                    placeholder="Enter Alternate Mobile Number"
                    className="input"
                    variant="outlined"
                    style={{ margin: "0", width: "100%" }}
                    onChange={(e) =>
                      setCustomer_whatsapppnumber(e.target.value as String)
                    }
                    required
                    onBlur={() => verifyMobile("whatsapp")}
                    onFocus={() => {
                      setMobileValidation((prev: any) => ({
                        ...prev,
                        customer_whatsapppnumber: true,
                      }));
                    }}
                    helperText={
                      !mobileValidation.customer_whatsapppnumber &&
                      "Incorrect Whatsapp Number"
                    }
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Aadhaar/Passport</FormLabel>
                    <RadioGroup
                      aria-label="Choose"
                      name="adharpassport"
                      value={adharPassport}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="customer_aadhar"
                        control={<Radio />}
                        label="Aadhaar Number"
                      />
                      <FormControlLabel
                        value="passport_number"
                        control={<Radio />}
                        label="Passport Number"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    className="input"
                    name="customer_aadhar"
                    type="text"
                    placeholder={
                      adharPassport === "customer_aadhar"
                        ? "Enter Adhar Number"
                        : "Enter Passport Number"
                    }
                    value={
                      adharPassport === "customer_aadhar"
                        ? customer_aadhar
                        : passport_number
                    }
                    variant="outlined"
                    style={{ margin: "0", width: "100%" }}
                    onChange={(e) =>
                      adharPassport === "customer_aadhar"
                        ? setCustomer_aadhar(e.target.value as String)
                        : setPassportNumber(e.target.value as String)
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} style={{ padding: "1rem" }}>
              <div className="priceDetails" style={{ padding: "1rem" }}>
                <table style={{ width: "100%", margin: "0" }}>
                  <thead>
                    <tr>
                      <td className="cell">Customer Name</td>
                      <td className="cell">Packages</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="cell">{customer_name}</td>
                      <td className="cell">
                        <ul>
                          {packageDetails.map((pack: any, index: any) => {
                            return <li key={index}>{pack.name}
                              <br />DESCRIPTION : {pack.description}
                              <br />
                              Report time: {
                                pack.package_city_prices
                                  ? pack.package_city_prices.tat_time
                                  : pack.tat_time
                              }
                              <br />
                              Price: {
                                pack.package_city_prices
                                  ? pack.package_city_prices.offer_price
                                  : pack.offer_price
                              }
                            </li>;
                          })}
                        </ul>
                      </td>
                    </tr>
                    {booking.additional_members &&
                      booking.additional_members.map(
                        (member: any, index: any) => {
                          return (
                            <tr key={index}>
                              <td className="cell">{member.customer_name}</td>
                              <td className="cell">
                                <ul>
                                  {member.packages &&
                                    member.packages.map(
                                      (pack: any, index: any) => {
                                        return <li key={index}>{pack.name}
                                          <br />DESCRIPTION : {pack.description}
                                          <br />
                                          Report time: {
                                            pack.package_city_prices
                                              ? pack.package_city_prices.tat_time
                                              : pack.tat_time
                                          }
                                          <br />
                                          Price: {
                                            pack.package_city_prices
                                              ? pack.package_city_prices.offer_price
                                              : pack.offer_price
                                          }
                                        </li>;
                                      }
                                    )}
                                </ul>
                              </td>
                            </tr>
                          );
                        }
                      )}
                  </tbody>
                </table>
              </div>
              <div
                className="priceDetails"
                style={{ padding: "1rem 1rem 0 1rem" }}
              >
                <h6 className="totalPrice">
                  Price: ₹ {Math.floor(price)} {extraCharge ? "+ 200" : ""}
                </h6>
                <h6 className="totalPrice">
                  RedCash Discount: ₹ {Math.floor(booking?.redcash_discounted_amount)}
                </h6>
                <h6 className="totalPrice">Discount: ₹ {discount}</h6>
                <h6 className="totalPrice">Total Amount: ₹ {totalPrice - Math.floor(booking?.redcash_discounted_amount)}</h6>
                <Grid
                  container
                  spacing={1}
                  direction="row"
                  justify="center"
                  alignItems="center"
                  style={{ margin: "1rem auto" }}
                >
                  <Grid item xs={2}>
                    <p>Enter Coupon</p>
                  </Grid>
                  <Grid item xs={3}>
                    <Autocomplete
                      key={couponKey}
                      id="coupon"
                      onChange={(event, newValue) => {
                        if (newValue) {
                          let obj = JSON.parse(
                            JSON.stringify(newValue, null, " ")
                          );
                          setCoupon(obj.id as Number);
                        }
                      }}
                      defaultValue={
                        couponList.results &&
                        couponList.results.find((code: any) => {
                          return code.id === coupon;
                        })
                      }
                      inputValue={couponCode}
                      disabled={Math.floor(booking?.redcash_discounted_amount) > 0}
                      onInputChange={(event, newInputValue) => {
                        clearTimeout(timer.current);
                        setCouponCode(newInputValue);
                        timer.current = setTimeout(() => {
                          getCoupons(`?code=${newInputValue}&org_type=homedx`);
                        }, 1000);
                        if (newInputValue === "") {
                          setCoupon(0);
                          setDiscount(0);
                          // setremoveCoupn("true")
                        }
                      }}
                      options={couponList.results}
                      freeSolo
                      blurOnSelect
                      getOptionLabel={(option: any) => option.name}
                      getOptionDisabled={(option: any) =>
                        option.minimum_price > totalPrice
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Coupon"
                          variant="outlined"
                          style={{ width: "100%", margin: "0", padding: "0" }}
                          className="input"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Button
                      variant="contained"
                      fullWidth
                      color="primary"
                      disabled={Math.floor(booking?.redcash_discounted_amount) > 0}
                      onClick={applyCoupon}
                    >
                      Apply
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
            direction="row"
            justify="center"
            alignItems="center"
            style={{ margin: "10px auto" }}
          >
            <Grid item xs={12}>
              <Grid item xs={12}>
                <h3
                  style={{
                    borderTop: "dotted 1px #cccc",
                    paddingTop: "10px",
                    fontSize: "24px",
                    fontWeight: "bold",
                  }}
                >
                  Address
                </h3>
              </Grid>
              <Grid container spacing={3} style={{ margin: "1rem auto" }}>

                <Grid
                  direction="row"
                  justify="space-around"
                  alignItems="center"
                  container
                  item
                  xs={12}
                  md={6}
                  spacing={3}
                >
                  <Grid item xs={12} md={5}>
                    <p>Landmark</p>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    {/* <TextField
                      name="customer_landmark"
                      type="text"
                      placeholder="Enter landmark"
                      value={customer_landmark}
                      className="input"
                      variant="outlined"
                      // disabled
                      style={{ margin: "0", width: "100%" }}
                      onChange={(e) =>
                        setCustomer_landmark(e.target.value as String)
                      }
                      required
                    />
                    <GooglePlacesAutocomplete
                      apiKey={GoogleMapsAPI}
                      selectProps={{
                        landmark,
                        onChange: setLandmark,
                      }}
                    /> */}
                    <Autocomplete
                      id="colony-area-sector"
                      onChange={(event, newValue) => {
                        if (newValue) {
                          let obj = JSON.parse(
                            JSON.stringify(newValue, null, " ")
                          );
                          setMapAddressObject(obj);
                        }
                      }}
                      options={suggestedAddress ? suggestedAddress : []}
                      freeSolo
                      blurOnSelect
                      aria-required
                      getOptionLabel={(option: any) => `${option?.placeAddress}`}
                      filterOptions={(option: any) => option}
                      onInputChange={(event, newInputValue) => {
                        clearTimeout(timer.current);
                        timer.current = setTimeout(() => {
                          locationAutoCompleteSearch(newInputValue);
                        }, 500);
                      }}
                      disabled
                      renderInput={(params) => (
                        <TextField
                          className="input"
                          {...params}
                          placeholder="Search Address"
                          variant="outlined"
                          style={{ width: "100%", margin: "0", padding: "0" }}
                          required
                        />
                      )}
                      defaultValue={{ "placeAddress": customer_landmark }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  direction="row"
                  justify="space-around"
                  alignItems="center"
                  container
                  item
                  xs={12}
                  md={6}
                  spacing={3}
                >
                  <Grid item xs={12} md={5}>
                    <p>House No./Plot No./Flat No./Door No./Shop/ c/o</p>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      name="customer_address"
                      type="text"
                      value={customer_address}
                      onChange={(e) => setCustomer_address(e.target.value as string)}
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  direction="row"
                  justify="space-around"
                  alignItems="center"
                  container
                  item
                  xs={12}
                  md={6}
                  spacing={3}
                >
                  <Grid item xs={12} md={5}>
                    <p>Apartment/Building /Colony/ Block/Sector/ Street/Gali/Road/Chawl</p>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      name="customer_address"
                      type="text"
                      value={addressLine2}
                      onChange={(e) =>
                        setAddressLine2(e.target.value as string)
                      }
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  direction="row"
                  justify="space-around"
                  alignItems="center"
                  container
                  item
                  xs={12}
                  md={6}
                  spacing={3}
                >
                  <Grid item xs={12} md={5}>
                    <p>Landmark/Sublocality</p>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      name="customer_address"
                      type="text"
                      value={favoritePlace}
                      onChange={(e) => setFavoritePlace(e.target.value as string)}
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
                {/* <Grid
                  direction="row"
                  justify="space-around"
                  alignItems="center"
                  container
                  item
                  xs={12}
                  md={6}
                  spacing={3}
                >
                  <Grid item xs={12} md={5}>
                    <p>Pincode</p>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      name="pincode"
                      type="text"
                      disabled
                      value={
                        (booking.customer_areapincode &&
                          booking.customer_areapincode.pincode) ||
                        ""
                      }
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid> */}
              </Grid>
            </Grid>
          </Grid>
          <div className={classes.buttonContainer}>
            <Button
              color="primary"
              type="submit"
              variant="contained"
              disabled={loading || !selectedSlot || packages.length === 0}
            >
              Update booking
            </Button>
          </div>
        </form>
      </Paper>
    </main>
  );
};
const mapStateToProps = (state: any) => ({
  pin_code: state.ProductManagerReducer.pin_code,
  packageList: state.ProductManagerReducer.packageList,
  couponList: state.ProductManagerReducer.couponList,
  booking: state.ProductManagerReducer.booking,
  booking_slots: state.ProductManagerReducer.booking_slots,
  loading: state.ProductManagerReducer.loading,
  partnerList: state.ProductManagerReducer.partnerList,
  checkGeoFenceStatus: state.ProductManagerReducer.checkGeoFenceStatus,
  geoFencesMapMyIndia: state.ProductManagerReducer.geoFencesMapMyIndia,
  mapmyindiaAccessToken: state.ProductManagerReducer.mapmyindiaAccessToken,
});

export default connect(mapStateToProps, {
  getPinCode,
  getPackage,
  updateBooking,
  getCoupons,
  getBookingById,
  getPartners,
  getAvailableSlots,
  checkGeoFenceArea,
  checkGeoFenchMapMyIndia,
  getMapMyIndiaAccessToken,
})(EditBookingForm);
