import React, { useEffect, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Link, useHistory } from 'react-router-dom'
import {
    Grid,
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TextField,
    TablePagination,
    TableFooter,
    Switch,
    withStyles
} from '@material-ui/core';
import Button from "@material-ui/core/Button";
import './index.sass'
import { Edit } from "@material-ui/icons";
import { connect } from "react-redux";
import Loader from "../loader";
import { MessageRounded } from '@material-ui/icons';
import { updatePackage, getPackage, getPackageComment } from "../../actions/productMangerAction";
import PackageComments from '../comments/packageComments'
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 0,
            //   width: "100%",
            //   marginTop: "20px",
        },
        toolbar: {
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        },
        content: {
            width: "80%",
            flexGrow: 21,
        },
        paper: {
            padding: "1rem",
            width: "100%"
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        table: {
            maxWidth: "100%",
            margin: "auto"
        },
        heading: {
            fontWeight: "normal",
            fontSize: "1.2rem"
        },
        actionButton: {
            margin: "0 0.5rem",
            cursor: "pointer"
        },
        tableContainer: {
            marginTop: "2rem",
            position: "sticky",
            top: "200px",
        },
    })
);
const StyledTableCell:any = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: "#924A91",
            color: theme.palette.common.white,
            fontSize: "14px",
        },
        body: {
            fontSize: 14,
        },
    })
)(TableCell);

const StyledTableRow:any = withStyles((theme: Theme) =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    })
)(TableRow);

const DefaultSwitch:any = withStyles({
    switchBase: {
        color: "red",
        '&$checked': {
            color: "green",
        },
        '&$checked + $track': {
            backgroundColor: "green",
        },
    },
    checked: {},
    track: {
        backgroundColor: "red"
    },
})(Switch);

interface Props {
    getPackage: any;
    packageList: any;
    updatePackage: any;
    getPackageComment: any;
    packageComments: any;
    loading: Boolean;
}

const PinCodePage: React.FC<Props> = ({
    getPackage,
    packageList,
    updatePackage,
    getPackageComment,
    packageComments,
    loading
}) => {
    const classes = useStyles();
    const [search, setSearch] = useState<String>("")
    const [page, setPage] = useState(0)
    const [openModal, setopenModal] = useState<boolean>(false)
    const [packageId, setPackageId] = useState<any>("")
    const handleSearch = (e: React.ChangeEvent<{ value: unknown }>) => {
        setSearch(e.target.value as string)
        getPackage(`code=${e.target.value}`)
    }

    useEffect(() => {
        getPackage()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        if (newPage > page) {
            let url = packageList.links && packageList.links.next.split("?")[1];
            getPackage(url);
        } else if (newPage < page) {
            let url = packageList.links && packageList.links.previous.split("?")[1];
            getPackage(url);
        }
        setPage(newPage as number);
    };

    const history = useHistory()
    const handleEditPackage = (pack: any) => {
        history.push(`/dashboard/pm/packages/edit/${pack.slug}`)
    }
    const disablePackage = async (pack: any) => {
        const body: any = {
            is_active: !pack.is_active
        }
        await updatePackage(pack.slug, body)
        getPackage()
    }
    useEffect(() => {
        if (packageId !== "") {
            getPackageComment(packageId)
        }

    },[openModal])
    const handleClick = (id: any) => {
        setPackageId(id)
        setopenModal(true)
    }
    return (
        <>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <Paper elevation={15} className={classes.paper}>
                    <Grid container spacing={3} style={{ margin: "1rem auto" }}>
                        <Grid item xs={12} md={4}>
                        <h4 style={{ fontWeight: "bold" }}>PACKAGES</h4>
                            {/* <h3 className={classes.heading}>Packages</h3> */}
                        </Grid>
                        <Grid container item xs={12} md={8} direction="row" alignItems="center" justify="flex-end">
                            <TextField
                                id="search"
                                type="search"
                                value={search}
                                className="input"
                                variant="outlined"
                                placeholder="Search"
                                onChange={(e) => handleSearch(e)}
                                style={{ margin: "0" }}
                            />
                            <Link to="/dashboard/pm/packages/create">
                                <Button variant="contained" color="primary" style={{ marginLeft: "1rem" }}>Add Package</Button>
                            </Link>
                        </Grid>
                    </Grid>
                    {loading ? <Loader /> : (
                        <TableContainer className={classes.tableContainer} component={Paper} style={{ maxHeight: "560px" }}>
                            <Table stickyHeader aria-label="simple table">
                                <TableHead>
                                    <StyledTableRow>
                                        <StyledTableCell align="center">Name</StyledTableCell>
                                        <StyledTableCell align="center">Comments</StyledTableCell>
                                        <StyledTableCell align="center">Package Price</StyledTableCell>
                                        <StyledTableCell align="center">Discount</StyledTableCell>
                                        <StyledTableCell align="center">Offer Price</StyledTableCell>
                                        <StyledTableCell align="center">Parameter</StyledTableCell>
                                        <StyledTableCell align="center">Recommended Gender</StyledTableCell>
                                        <StyledTableCell align="center">Recommended Age</StyledTableCell>
                                        <StyledTableCell align="center">Addon</StyledTableCell>
                                        <StyledTableCell align="center">Active</StyledTableCell>
                                        <StyledTableCell align="center">Edit</StyledTableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {packageList.results && packageList.results.length > 0 && packageList.results.map((code: any, index: any) => {
                                        return (
                                            <StyledTableRow key={index}>
                                                <StyledTableCell align="center">
                                                    {code.name}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <div>
                                                        <MessageRounded onClick={() => handleClick(code.id)} />
                                                    </div>
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {code.package_price}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {code.discount}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {code.offer_price}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {code.parameter}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {code.recommended_gender}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {code.recommended_age}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {code.is_addon ? "Yes" : "No"}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <DefaultSwitch
                                                        checked={code.is_active}
                                                        name="checkedA"
                                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                        disableRipple
                                                        onClick={() => disablePackage(code)}
                                                    />
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <Button
                                                        color="primary"
                                                        variant="contained"
                                                        onClick={() => handleEditPackage(code)}
                                                    >
                                                        <Edit />
                                                    </Button>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        )
                                    })}
                                </TableBody>
                                <TableFooter>
                                    <StyledTableRow>
                                        <TablePagination
                                            rowsPerPageOptions={[]}
                                            colSpan={3}
                                            count={packageList.count || 0}
                                            rowsPerPage={packageList.page_size}
                                            page={0}
                                            onPageChange={handleChangePage}
                                        />
                                    </StyledTableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                    )}
                    {packageList && packageList.length === 0 && (
                        <h3 className={classes.heading} style={{ textAlign: "center" }}>No Data Found</h3>
                    )}
                </Paper>
                <PackageComments setopenModal={setopenModal} openModal={openModal} packageComments={packageComments} />
            </main>
        </>
    );
};

const mapStateToProps = (state: any) => ({
    packageList: state.ProductManagerReducer.packageList,
    packageComments: state.ProductManagerReducer.packageComments,
    loading: state.ProductManagerReducer.loading,
})

export default connect(mapStateToProps, {
    getPackage,
    updatePackage,
    getPackageComment,
})(PinCodePage);