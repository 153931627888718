import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from '@mui/material/Modal';
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Button, MenuItem, Select, TextField, Grid } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { raiseNewComplaint } from '../../actions/LogisticsPanelAction';

let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: isMobile ? "400px" : "1200px",
    },
    head: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "2rem"
    }
}));

type ModalProps = {
    open: boolean;
    bookingId: number;
    raiseNewComplaint: any;
    complaint: any;
    leadId: number;
    setOpen: Function;
};

const CommentsModal2: React.FC<ModalProps> = ({
    open,
    bookingId,
    leadId,
    raiseNewComplaint,
    complaint,
    setOpen,
}) => {
    const classes = useStyles();

    const [query, setQuery] = useState("none")
    const [description, setDescription] = useState("")
    const [status, setStatus] = useState("none")
    const [disposition, setDisposition] = useState<string>("")


    const handleClose = () => {
        setOpen(false);
    };

    const raiseComplaint = () => {
        const type = bookingId !== 0 ? "booking_ticket" : leadId !== 0 ? "lead_ticket" : ""
        const id = bookingId !== 0 ? bookingId : leadId !== 0 ? leadId : 0
        const body: any = {
            status: "pending",
            querytype: query,
            query: description,
            disposition
        }
        if (disposition === "") {
            delete body.disposition
        }
        setDisposition("")
        raiseNewComplaint(type, body, id)
    };

    useEffect(() => {
        if (complaint) {
            handleClose()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [complaint])

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <div className={classes.head}>
                            <h4
                                style={{
                                    marginBottom: "1rem",
                                }}
                                id="transition-modal-title"
                            >
                                Raise a query ticket
                            </h4>
                            <CloseIcon onClick={() => setOpen(false)} />
                        </div>
                        <Grid container direction="row" alignItems="flex-start" justify="space-between" spacing={3} style={{ margin: "1rem auto" }}>
                            
                            
                                <Grid item xs={12} md={6}>
                                    <p>Disposition</p>
                                    <Select className="input"
                                        name="action"
                                        variant="outlined"
                                        value={disposition}
                                        style={{ width: "100%" }}
                                        onChange={(e) => setDisposition(e.target.value as string)}

                                    >
                                        <MenuItem value={"phlebo_delay"}>Phlebo Delay</MenuItem>
                                        <MenuItem value={"phlebo_behavior"}>Phlebo Behavior</MenuItem>
                                        <MenuItem value={"phlebo_markhold"}>Phlebo Markhold</MenuItem>
                                        <MenuItem value={"resampling"}>Resampling</MenuItem>
                                        <MenuItem value={"report_related_query"}>Report Tat Breached</MenuItem>
                                        <MenuItem value={"report_not_delivered"}>Report Not Delivered</MenuItem>
                                        <MenuItem value={"fault_report"}>Fault Report</MenuItem>
                                        <MenuItem value={"refund_query"}>Refund Query</MenuItem>
                                        <MenuItem value={"doctor_consultation"}>Doctor Consultations</MenuItem>
                                        <MenuItem value={"social_media_ticket"}>Social Media Ticket</MenuItem>
                                        <MenuItem value={"others"}>Others</MenuItem>
                                    </Select>
                                </Grid>
                          
                            {/* <Grid item xs={12} md={6}>
                                <Select className="input"
                                    name="action"
                                    variant="outlined"
                                    value={status}
                                    style={{ width: "100%" }}
                                    onChange={(e) => setStatus(e.target.value as string)}
                                >
                                    <MenuItem disabled value={"none"}>Selecct Status</MenuItem>
                                    <MenuItem value={"pending"}>Pending</MenuItem>
                                    <MenuItem value={"confirmed"}>Confirmed</MenuItem>
                                    <MenuItem value={"cancelled"}>Cancelled</MenuItem>
                                </Select>
                            </Grid> */}
                            <Grid item xs={12}>
                                <TextField
                                    name="comment"
                                    type="text"
                                    multiline
                                    rows={3}
                                    value={description}
                                    className="input"
                                    variant="outlined"
                                    placeholder="Description"
                                    onChange={(e) => setDescription(e.target.value as string)}
                                    style={{ width: "100%" }}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    style={{ height: "50px" }}
                                    onClick={raiseComplaint}
                                    disabled={description === ""}
                                >
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
};

const mapStateToProps = (state: any) => ({
    complaint: state.LogisticsReducer.complaint
});

export default connect(mapStateToProps, {
    raiseNewComplaint
})(CommentsModal2);
