import React, { useState } from 'react'
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Chip, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@material-ui/core';
import Button from "@material-ui/core/Button";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getBookings } from '../../../actions/productMangerAction'
import Loader from '../../../../components/loader';
import moment from 'moment';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        paper: {
            marginTop: "2rem",
            padding: "0.5rem 2rem",
            width: "100%",
        },
        table: {
            maxWidth: "100%",
            margin: "auto"
        },
    })
);


interface Props {
    getBookings: any;
    bookingList: any;
    loading: boolean;
}

const EditBooking: React.FC<Props> = ({
    getBookings,
    bookingList,
    loading
}) => {
    const classes = useStyles();

    const [search, setSearch] = useState<String>("")

    const handleSearch = (e: React.ChangeEvent<{ value: unknown }>) => {
        setSearch(e.target.value as string)
    }

    const searchBooking = async () => {
        await getBookings(search)
    }

    return (
        <div style={{ width: "100%" }}>
            <Paper elevation={15} className={classes.paper}>
                <Grid container spacing={3} style={{ margin: "1rem auto" }}>
                    <Grid item xs={12} md={4}>
                        <h3>Booking</h3>
                    </Grid>
                    <Grid container item xs={12} md={8} direction="row" alignItems="center" justify="flex-end">
                        <Grid container item xs={12} md={8} direction="row" alignItems="center" justify="flex-end">
                            <Grid item xs={12} md={8}>
                                <TextField
                                    className="input"
                                    id="search"
                                    type="search"
                                    value={search}
                                    variant="outlined"
                                    placeholder="Search"
                                    onChange={(e) => handleSearch(e)}
                                    style={{ margin: "0", padding: "0", width: "100%", marginBottom: "1rem" }}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Button variant="contained" fullWidth disabled={search.length === 0} color="primary" style={{ height: "56px", marginBottom: "1rem" }} onClick={searchBooking}>Search</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <TableContainer className={classes.table} component={Paper}>
                    {loading ? <Loader /> : (
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Collection Date</TableCell>
                                    <TableCell align="center">Collection Time</TableCell>
                                    <TableCell align="center">Zone Name</TableCell>
                                    <TableCell align="center">Mobile Number</TableCell>
                                    <TableCell align="center">Booking Number</TableCell>
                                    <TableCell align="center">Booking Status</TableCell>
                                    <TableCell align="center">Verification Status</TableCell>
                                    <TableCell align="center">Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {bookingList.results && bookingList.results.length > 0 && bookingList.results.map((booking: any, index: any) => {
                                    return (
                                        <TableRow key={index}>
                                            <TableCell align="center">{booking.collection_date}</TableCell>
                                            <TableCell align="center">
                                                {booking.collection_slot && moment(`${booking.collection_slot.slot.split("-")[0]}`, ["HH.mm.ss"]).format("hh:mm A")}
                                                {" - "}
                                                {booking.collection_slot && moment(`${booking.collection_slot.slot.split("-")[1]}`, ["HH.mm.ss"]).format("hh:mm A")}
                                            </TableCell>
                                            <TableCell align="center">{booking.customer_areapincode.area}</TableCell>
                                            <TableCell align="center">{booking.customer_phonenumber}</TableCell>
                                              <TableCell align="center">{booking.additional_members.length>0?(
                          <div>
                         { booking.pk}+
                          <Chip
                           label={`${booking.additional_members.length}`}
                           style={{background:"#ffeb3b"}}
                           />
                           </div>

                        ):booking.pk}</TableCell>
                                            <TableCell align="center"><Chip label={`${booking.booking_status}`} style={{background:booking.booking_status==="pending"?"#ffee58":booking.booking_status==="confirmed"?"#00e676":booking.booking_status==="cancelled"?"#ff3d00":"#d500f9"}} /></TableCell>
                                             <TableCell align="center"><Chip label={`${booking.verification_status}`} style={{background:booking.verification_status==="pending"?"#ffee58":booking.verification_status==="confirmed"?"#00e676":booking.verification_status==="cancelled"?"#ff3d00":"#d500f9"}}/></TableCell>
                                            <TableCell align="center">
                                                <Link to={`/dashboard/pm/booking-view/${booking.pk}`} style={{ textDecoration: "none" }}>
                                                    <Button variant="contained" color="secondary">View</Button>
                                                </Link>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    )}
                </TableContainer>
                {bookingList && bookingList.length === 0 && (
                    <h6 style={{ textAlign: "center" }}>No Data Found</h6>
                )}
            </Paper>
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    bookingList: state.ProductManagerReducer.bookingList,
    loading: state.ProductManagerReducer.loading,
})

export default connect(mapStateToProps, {
    getBookings
})(EditBooking);

