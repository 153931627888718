import React, { useState, useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Grid,
  TextField,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { useRef } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Loader from "../Components/loader";
import moment from "moment";
import { MessageRounded } from "@material-ui/icons";
import { getIncorrectNumberLead } from "../actions/productMangerAction";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useHistory } from "react-router";
// import { generateEmailFilterUrl } from "../../helpers/generateUrl";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      marginTop: "2rem",
      padding: "0.5rem 2rem",
      width: "100%",
    },
    table: {
      maxWidth: "100%",
      margin: "auto",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    listStyle: {
      marginLeft: "20px",
    },
  })
);

interface Props {
  getIncorrectNumberLead: any;
  incorrectNumberLeads: any;
  loading: Boolean;
}

const BookingsTable: React.FC<Props> = ({
  getIncorrectNumberLead,
  incorrectNumberLeads,
  loading,
}) => {
  const classes = useStyles();
  useEffect(() => {
    getIncorrectNumberLead();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const history = useHistory();
  const [bookingId, setBookingId] = useState<any>("");
  const [email, setEmail] = useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url = incorrectNumberLeads.links && incorrectNumberLeads.links.next;
      getIncorrectNumberLead(url);
    } else if (newPage < page) {
      let url =
        incorrectNumberLeads.links && incorrectNumberLeads.links.previous;
      getIncorrectNumberLead(url);
    }
    setPage(newPage as number);
  };

  // const filterBookings = () => {
  //     const body: any = {
  //         booking:bookingId,
  //         email,
  //     }
  //     const url = generateEmailFilterUrl(body).substring(2)
  //     getIncorrectNumberLead(`${url}`)
  //     // setPage(0)
  // }

  return (
    <div style={{ width: "100%" }}>
      <div className={classes.toolbar} />
      <Paper elevation={15} className={classes.paper}>
        <TableContainer className={classes.table} component={Paper}>
          <h2>Incorrect Number Lead</h2>
          {loading ? (
            <Loader />
          ) : (
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  {/* <TableCell align="center">Full Name</TableCell> */}
                  <TableCell align="center">Phone Number</TableCell>
                  <TableCell align="center">lead_source</TableCell>
                  <TableCell align="center">Created At</TableCell>
                  <TableCell align="center">Updated at</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {incorrectNumberLeads?.results &&
                  incorrectNumberLeads?.results?.length > 0 &&
                  incorrectNumberLeads?.results?.map(
                    (data: any, index: any) => {
                      return (
                        <TableRow key={index}>
                          {/* <TableCell style={{ height: "50px" }} align="center">{data.data.full_name}</TableCell> */}
                          <TableCell style={{ height: "50px" }} align="center">
                            {data?.phone_number}
                          </TableCell>
                          <TableCell style={{ height: "50px" }} align="center">
                            {data?.lead_source || "NA"}
                          </TableCell>
                          <TableCell style={{ height: "50px" }} align="center">
                            {new Date(data?.created_at).toLocaleString()}
                          </TableCell>
                          <TableCell style={{ height: "50px" }} align="center">
                            {new Date(data?.updated_at).toLocaleString()}
                          </TableCell>
                        </TableRow>
                      );
                    }
                  )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[]}
                    colSpan={5}
                    count={incorrectNumberLeads.count || 0}
                    rowsPerPage={incorrectNumberLeads.page_size}
                    page={page}
                    onPageChange={handleChangePage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          )}
        </TableContainer>
      </Paper>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  incorrectNumberLeads: state.ProductManagerReducer.incorrectNumberLeads,
});

export default connect(mapStateToProps, {
  getIncorrectNumberLead,
})(BookingsTable);
