import React, { useEffect, useRef, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
    Container,
    Grid,
    TextField,
    Select,
    MenuItem,
    Checkbox,
} from '@material-ui/core';
import Box from '@mui/material/Box';
import { CheckBoxOutlineBlank, CheckBoxRounded } from "@material-ui/icons";
import Button from '@mui/material/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { generateBookingFilterUrl } from "../../helpers/generateUrl";
import { connect } from "react-redux";
import { getAgentList, getLeadSource, getPartners, getAllBookings, getCities, getPhlebos } from '../actions/BulkUploadMasterAction'
import './filter.sass'
import { useHistory } from "react-router";
import BookingsTable from "../tables/bookingsTable";
import BookingCommentsModal from '../Components/Comment/bookingComments'
import Loader from "../Components/loader/index";
import { BOOKING_STATUS } from '../../booking_status'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 0,
           
        },
        toolbar: {
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
          
            ...theme.mixins.toolbar,
        },
        content: {
            width: "80%",
            flexGrow: 21,
           
        },
        card: {
            height: "100%",
            padding: theme.spacing(1),
        },
        card_grid: {
           
            justifyContent: 'space-between'
        },
    })
);

interface Props {
    children: any;
    agentsList: any;
    bookingList: any;
    partnerList: any;
    getAgentList: any;
    getPartners: any;
    getAllBookings: any;
    getCities: any;
    cities: any;
    getPhlebos: any;
    phleboList: any;
    getLeadSource: any;
    lead_source: any;
    loading: boolean;
}

const SampleAccessioningFilterPage: React.FC<Props> = ({
    agentsList,
    partnerList,
    getAgentList,
    getPartners,
    getAllBookings,
    bookingList,
    getCities,
    cities,
    getPhlebos,
    phleboList,
    getLeadSource,
    lead_source,
    loading
}) => {
    const classes = useStyles();

    const history = useHistory()

    const [openModal, setopenModal] = useState<boolean>(false);
    const [bookingId, setBookingId] = useState<any>("")
    const [status, setStatus] = useState<String>("none")
    const [source, setSource] = useState<any>([])
    const [partner, setPartner] = useState<number>(0)
    const [agent, setAgent] = useState<number>(0)
    const [city, setCity] = useState<number>(0)
    const [start_date, setStart_date] = useState("")
    const [end_date, setEnd_date] = useState("")
    const [booking_date, setBooking_date] = useState("")
    const [collection_date, setCollection_date] = useState("")
    const [verificationStatus, setVerificationStatus] = useState("none")
    const [pickup_status, setPickup_status] = useState("none")
    const [name, setName] = useState("")
    const [phlebo, setPhlebo] = useState<number>(0)
    const [phone, setPhone] = useState("")
    const [sample_registered, setSampleRegisterd] = useState<any>("none")
    const [report_status, setReportStatus] = useState<any>("none")
    const [barcode, setBarcode] = useState<any>("")
    const [barcodeFilter, setBarcodeFilter] = useState<boolean>(false)
    const [page, setPage] = useState(0)
    const timer = useRef<any>(0)

    useEffect(() => {
        getAgentList()
        getPartners()
        getAllBookings()
        getCities()
        getPhlebos()
        getLeadSource()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const filterBookings = (e: any) => {
        const body: any = {
            status,
            verificationStatus,
            source: source.join(","),
            agent,
            city,
            start_date,
            end_date,
            booking_date,
            collection_date,
            pickup_status,
            name,
            phlebo,
            partner,
            phone,
            sample_registered,
            report_status,
            bookingId,
            barcode
        }
        const url = generateBookingFilterUrl(body).substring(2)
        localStorage.setItem("barcode", barcode);
        if (url.includes("barcode")) {
            setBarcodeFilter(true)
        }
        else {
            setBarcodeFilter(false)
        }
        getAllBookings(url)
        setPage(0)
    }
    useEffect(() => {
        if (barcodeFilter) {
            if (bookingList && bookingList?.results && bookingList?.results.length === 1) {
                history.push(`/dashboard/lp/booking-view/${bookingList?.results[0]?.pk}`)
            }
        }
    }, [bookingList])
    const onKeyDown = (e: any) => {
        if (e.key === "Enter") {
            filterBookings(e);
        }
    };

    return (
        <>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <h2>Booking Dashboard</h2>
                <Grid container spacing={1} direction="row" style={{ alignItems: "left" }}>

                    <Grid item xs={12} sm={4} md={2}>
                        <TextField
                            className="input"
                            name="name"
                            type="number"
                            label="Booking Id"
                            value={bookingId}
                            variant="outlined"
                            onChange={(e) => setBookingId(e.target.value)}
                            style={{ width: "100%" }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={4} md={2}>
                        <TextField
                            className="input"
                            name="name"
                            type="text"
                            label="Name"
                            value={name}
                            variant="outlined"
                            onChange={(e) => setName(e.target.value as string)}
                            style={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Button
                            variant="contained"
                            color="secondary"
                            disabled={loading}
                            fullWidth
                            onClick={filterBookings}
                        >
                            Filter
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Button
                            variant="contained"
                            color="primary"

                            fullWidth
                            onClick={() => history.push("/dashboard/lp/booking_list")}
                        >
                            Reset
                        </Button>
                    </Grid>
                </Grid>
                <br />
                {loading ? <Loader /> : bookingList.results && bookingList.results.length > 0 && (
                    <Box>
                        <Container maxWidth={false}>
                            <Grid container spacing={3}>
                                <BookingsTable setopenModal={setopenModal} openModal={openModal} page={page} setPage={setPage} loading={loading} getBookings={getAllBookings} bookingList={bookingList} bookingId={bookingId} setBookingId={setBookingId} />
                            </Grid>
                        </Container>
                    </Box>
                )}
                {bookingList.results && bookingList.results.length === 0 && (
                    <h6 style={{ textAlign: "center" }}>No Data Found</h6>
                )}
                <BookingCommentsModal openModal={openModal} setopenModal={setopenModal} bookingId={bookingId} />
            </main>
        </>
    );
};

const mapStateToProps = (state: any) => ({
    agentsList: state.BulkUploadMasterReducer.agentsList,
    partnerList: state.BulkUploadMasterReducer.partnerList,
    bookingList: state.BulkUploadMasterReducer.bookingList,
    cities: state.BulkUploadMasterReducer.cities,
    phleboList: state.BulkUploadMasterReducer.phleboList,
    loading: state.BulkUploadMasterReducer.loading,
    lead_source: state.BulkUploadMasterReducer.lead_source,
})

export default connect(mapStateToProps, {
    getAgentList,
    getPartners,
    getCities,
    getPhlebos,
    getLeadSource,
    getAllBookings
})(SampleAccessioningFilterPage);
