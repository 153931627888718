import React, { useState, useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Chip,
  Paper,
  withStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Loader from "../components/loader/index";
import BookingCommentsModal from "../components/Comments/bookingComments";
import { MessageRounded } from "@material-ui/icons";
import AdditonalFamilyModal from "./AdditionalFamily/AdditionFamilyDetails";
import InvertColorsIcon from "@material-ui/icons/InvertColors";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      marginTop: "2rem",
      padding: "0.5rem 2rem",
      width: "100%",
    },
    table: {
      maxWidth: "100%",
      margin: "auto",
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
  })
);

const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

interface Props {

  rejectedbatchForPhlebo: any;
 
  loading:any;
}

const BookingsTable: React.FC<Props> = ({
  rejectedbatchForPhlebo,
  loading,
  
  
}) => {
  const classes = useStyles();  

  return (
    <div className="data-table" style={{ width: "100%" }}>
      <TableContainer
        className={classes.tableContainer}
        component={Paper}
        style={{ maxHeight: "580px" }}
      >
        {loading ? (
          <Loader />
        ) : (
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <StyledTableRow>
              <StyledTableCell align="center">Action</StyledTableCell>
                <StyledTableCell align="center">Booking Id</StyledTableCell>
                <StyledTableCell align="center">Customer Name</StyledTableCell>
                <StyledTableCell align="center">Customer Id</StyledTableCell>
                <StyledTableCell align="center">Customer Zone Name</StyledTableCell>
                <StyledTableCell align="center">Customer Zone Rm Username</StyledTableCell>
                <StyledTableCell align="center">Phlebo Name</StyledTableCell>
                <StyledTableCell align="center">Batch status</StyledTableCell>
               
                
               
                
               
              </StyledTableRow>
            </TableHead>
             <TableBody>
              {rejectedbatchForPhlebo&&
                rejectedbatchForPhlebo?.length > 0 &&
                rejectedbatchForPhlebo?.map((batchData: any, index: any) => {
                  return (
                    <>
                      <StyledTableRow
                        key={index}
                        
                      >
                        <StyledTableCell align="center">
                          <Link
                            to={`/dashboard/rm/booking-view/${batchData?.items__id}`}
                            style={{ textDecoration: "none" }}
                          >
                            <Button variant="contained" color="secondary">
                              View
                            </Button>
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {batchData?.items__id}
                        </StyledTableCell>
                       
                        <StyledTableCell align="center">
                          {batchData?.items__customer_name}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {batchData?.id}
                        </StyledTableCell>
                       
                        <StyledTableCell align="center">
                          {batchData?.items__customer_zone__name}
                        </StyledTableCell>
                        <StyledTableCell align="center">{`${
                          batchData?.items__customer_zone__rm__user__username 
                        } `}</StyledTableCell>
                        <StyledTableCell align="center">
                          {(batchData?.phlebo__user__username) || "NA"}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {batchData?.batch_status}
                        </StyledTableCell>
                       
                 
                       
                      </StyledTableRow>
                      
                    </>
                  );
                })}
            </TableBody> 
           
            {rejectedbatchForPhlebo && rejectedbatchForPhlebo?.length === 0 && (
              <span style={{ paddingLeft: "10px", textAlign: "left", fontWeight: "bolder", display: "flex", fontSize:"20px", width:"10rem" }}>No Data Found</span>
            )}
          </Table>
        )}
      </TableContainer>
     
      
    </div>
  );
};

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps, {})(BookingsTable);
