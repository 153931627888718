import React, { useEffect, useRef, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Container,
  Grid,
  TextField,
  Select,
  MenuItem,
  Checkbox,
} from "@material-ui/core";
import Box from "@mui/material/Box";
import { CheckBoxOutlineBlank, CheckBoxRounded } from "@material-ui/icons";
import Button from '@mui/material/Button';
import Autocomplete from "@material-ui/lab/Autocomplete";
import { generateBookingFilterUrl } from "../../helpers/generateUrl";
import { connect } from "react-redux";
import {
  getAgentList,
  getPartners,
  getCCBookings,
  getCities,
  getPhlebos,
  getCenterInfo,
  getLeadSource
} from "../actions/BulkUploadMasterAction";
import "./filter.sass";
import { useHistory } from "react-router";
import BookingsTable from "../tables/CC_bookingsTable";
import Loader from "../Components/loader";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
      //   width: "100%",
      //   marginTop: "20px",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
      //   padding: theme.spacing(3),
    },
    card: {
      height: "100%",
      padding: theme.spacing(1),
    },
    card_grid: {
      // wordSpacing:3,
      justifyContent: "space-between",
    },
    input: {
      padding: "1px",
    },
  })
);

interface Props {
  children: any;
  agentsList: any;
  ccbookingList: any;
  partnerList: any;
  getAgentList: any;
  getPartners: any;
  getCCBookings: any;
  getCities: any;
  cities: any;
  getPhlebos: any;
  phleboList: any;
  getLeadSource: any;
  lead_source: any;
  loading: boolean;
  getCenterInfo: any;
  centerInfo: any;
}

const CustomerLeadFilterPage: React.FC<Props> = ({
  agentsList,
  partnerList,
  getAgentList,
  getPartners,
  getCCBookings,
  ccbookingList,
  getCities,
  cities,
  getPhlebos,
  phleboList,
  getLeadSource,
  lead_source,
  loading,
  getCenterInfo,
  centerInfo,
}) => {
  const classes = useStyles();

  const history = useHistory();

  const [openModal, setopenModal] = useState<boolean>(false);
  const [bookingId, setBookingId] = useState<any>("");
  const [status, setStatus] = useState<String>("none");
  const [source, setSource] = useState<any>([]);
  const [partner, setPartner] = useState<number>(0);
  const [agent, setAgent] = useState<number>(0);
  const [city, setCity] = useState<number>(0);
  const [start_date, setStart_date] = useState("");
  const [end_date, setEnd_date] = useState("");
  const [booking_date, setBooking_date] = useState("");
  const [collection_date, setCollection_date] = useState("");
  const [verificationStatus, setVerificationStatus] = useState("none");
  const [pickup_status, setPickup_status] = useState("none");
  const [name, setName] = useState("");
  const [qrcode, setqrCode] = useState<any>("");
  const [phlebo, setPhlebo] = useState<number>(0);
  const [phone, setPhone] = useState("");
  const [center, setCentre] = useState<any>("");
  const [page, setPage] = useState(0);

  const timer = useRef<any>(0);

  useEffect(() => {
    getAgentList();
    getPartners();
    getCCBookings();
    getCities();
    getPhlebos();
    getLeadSource();
    getCenterInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterBookings = () => {
    const body: any = {
      bookingId,
      center,
      status,
      verificationStatus,
      source: source.join(","),
      agent,
      city,
      start_date,
      end_date,
      booking_date,
      collection_date,
      name,
      phlebo,
      partner,
      phone,
      qrcode,
    };
    const url = generateBookingFilterUrl(body).substring(2);
    getCCBookings(url);
    setPage(0);
  };

  return (
    <>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid
          container
          spacing={3}
          direction="row"
          style={{ alignItems: "left" }}
        >
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              name="name"
              type="text"
              label="Booking Id"
              value={bookingId}
              variant="outlined"
              onChange={(e) => setBookingId(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              name="name"
              type="text"
              label="Name"
              value={name}
              variant="outlined"
              onChange={(e) => setName(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              name="phone"
              type="number"
              label="Phone"
              value={phone}
              variant="outlined"
              onChange={(e) => setPhone(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              name="Barcode"
              type="text"
              label="Barcode"
              value={qrcode}
              variant="outlined"
              onChange={(e) => setqrCode(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              name="start_date"
              type="date"
              label="Start Date"
              value={
                booking_date === "" || collection_date === "" ? start_date : ""
              }
              variant="outlined"
              disabled={booking_date !== "" || collection_date !== ""}
              onChange={(e) => setStart_date(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              name="end_date"
              type="date"
              value={
                booking_date === "" || collection_date === "" ? end_date : ""
              }
              className="input"
              label="End Date"
              variant="outlined"
              disabled={booking_date !== "" || collection_date !== ""}
              onChange={(e) => setEnd_date(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="center"
              onChange={(event, newValue) => {
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  setCentre(obj.id);
                }
              }}
              freeSolo
              blurOnSelect
              options={centerInfo?.results||[]}
              disableCloseOnSelect
              getOptionLabel={(option: any) =>
                (option?.display_name === null
                  ? option?.name
                  : option?.display_name) +
                "(" +
                option?.center_code +
                ")"
              }
              loading={loading}
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getCenterInfo(`?name_or_code=${newInputValue}`);
                }, 1000);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className="input"
                  label="Centre"
                  variant="outlined"
                  fullWidth
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Button
              variant="contained"
              color="secondary"
              disabled={loading}
              fullWidth
              onClick={filterBookings}
            >
              Filter
            </Button>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Button
              variant="contained"
              color="primary"
             
              fullWidth
              onClick={() => history.push("/dashboard/lap")}
            >
              Reset
            </Button>
          </Grid>
        </Grid>
        <br />
        {loading ? (
          <Loader />
        ) : (
          ccbookingList.results &&
          ccbookingList.results.length > 0 && (
            <Box>
              <Container maxWidth={false}>
                <Grid container spacing={3}>
                  <BookingsTable
                    setopenModal={setopenModal}
                    openModal={openModal}
                    page={page}
                    setPage={setPage}
                    loading={loading}
                    getCCBookings={getCCBookings}
                    ccbookingList={ccbookingList}
                  />
                </Grid>
              </Container>
            </Box>
          )
        )}
        {ccbookingList.results && ccbookingList.results.length === 0 && (
          <h6 style={{ textAlign: "center" }}>No Data Found</h6>
        )}
      </main>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  centerInfo: state. BulkUploadMasterReducer.centerInfo,
  agentsList: state. BulkUploadMasterReducer.agentsList,
  partnerList: state. BulkUploadMasterReducer.partnerList,
  ccbookingList: state. BulkUploadMasterReducer.ccbookingList,
  cities: state. BulkUploadMasterReducer.cities,
  phleboList: state. BulkUploadMasterReducer.phleboList,
  loading: state. BulkUploadMasterReducer.loading,
  lead_source: state. BulkUploadMasterReducer.lead_source,
});

export default connect(mapStateToProps, {
  getCenterInfo,

  getAgentList,
  getPartners,
  getCities,
  getPhlebos,
  getLeadSource,
  getCCBookings,
})(CustomerLeadFilterPage);
