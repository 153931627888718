import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
// import SearchBar from "../../../search-bar";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import { coustomFileUpload } from "../actions/productMangerAction";
// import "../index.sass";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
      marginTop: "20px",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
      //   padding: theme.spacing(3),
    },
    card: {
      height: "100%",
      padding: theme.spacing(1),
    },
    card_grid: {
      // wordSpacing:3,
      justifyContent: "space-between",
    },
  })
);

interface Props {
  loading: boolean;
  coustomFileUpload: Function;
}

const CustomUploader: React.FC<Props> = ({ loading, coustomFileUpload }) => {
  const classes = useStyles();
  const [file, setFile] = React.useState<File>();
  const [file_name, setFileName] = React.useState<string>("");
  const [usertype, setUserType] = React.useState<string>("none");
  const [source, setSource] = React.useState<string>("none");
  const [error, setError] = React.useState<string>("");

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!!event.currentTarget) {
      if (event.currentTarget!.files) {
        setFile(event.currentTarget.files[0]);
        setFileName(event.currentTarget.files[0].name);
      }
    }
  };
  const handleUserTypeChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setUserType(event.target.value as string);
  };
  const handleSourceChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSource(event.target.value as string);
  };
  const submitFile = async () => {
    if (usertype === "none" || source === "none") {
      setError("Select Fields!");
    } else {
      await coustomFileUpload(file, usertype, source);
      setFileName("");
    }
  };
  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      {/* <h4 style={{ fontWeight: "bold" }}>UPLODER</h4> */}
      <div className="main-right">
        <div className="main-file-upload">
          <div className="file-upload-wrapper">
            <div className="form-elements" style={{ display: "flex" }}>
              <div>
                <FormControl variant="outlined" required>
                  <Select
                    id="demo-simple-select-required"
                    value={source}
                    style={{
                      width: "90%",
                      minWidth: "100px",
                      marginRight: "60px",
                    }}
                    inputProps={{ "aria-label": "Without label" }}
                    onChange={handleSourceChange}
                  >
                    <MenuItem value={"none"}>Source</MenuItem>
                    <MenuItem value={"Social-Media"}>Social Media</MenuItem>
                  </Select>
                </FormControl>
              </div>
              {/* <div>
                <FormControl variant="outlined" required>
                  <Select
                    id="demo-simple-select-required"
                    value={usertype}
                    style={{ width: "90%", minWidth: "100px", marginLeft: "100px" }}
                    inputProps={{ "aria-label": "Without label" }}
                    onChange={handleUserTypeChange}
                  >
                    <MenuItem value={"none"}>UserType</MenuItem>
                    <MenuItem value={"CustomerExecutive"}>Customer Executive</MenuItem>
                  </Select>
                </FormControl>
              </div> */}
            </div>
            <br />
            <p style={{ color: "red" }}>{error}</p>
            <br />
            <div className="file-uploader">
              <input
                type="text"
                style={{
                  height: "100%",
                  padding: "6px 16px",
                  marginRight: "5px",
                }}
                value={file_name}
                disabled
              />
              <Button variant="contained" component="label" color="secondary">
                Select File
                <input type="file" hidden onChange={handleFileChange} />
              </Button>
              <Button
                variant="contained"
                component="label"
                color="primary"
                style={{
                  color: "#fff",
                  marginLeft: "5px",
                }}
              >
                <a href={`${process.env.PUBLIC_URL}/Sample.csv`} download>
                  Sample File
                </a>
              </Button>
            </div>
            <Button
              variant="contained"
              component="label"
              style={{
                backgroundColor: "#924A91",
                color: "#fff",
                width: "100%",
                marginTop: "20px",
              }}
              onClick={submitFile}
            >
              Upload
            </Button>
            {loading && (
              <div className={classes.root}>
                <LinearProgress />
              </div>
            )}
          </div>
        </div>
      </div>
    </main>
  );
};

const mapStateToProps = (state: any) => ({
  loading: state.ProductManagerReducer.loading,
});

export default connect(mapStateToProps, { coustomFileUpload })(CustomUploader);
