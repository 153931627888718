import React from "react";
import BulkUploadMasterDashboardLeft from "../../BulkUploadMaster-Panel/dashboard-left/index";
import BulkUploadMasterDashboardRight from "../../BulkUploadMaster-Panel/dashboard-right/index";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    container: {
        display: 'flex',
        padding: "20px"
    }
});

interface Props {
    children: any;
}

const AdminDash: React.FC<Props> = ({ children }) => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <BulkUploadMasterDashboardLeft />
            <BulkUploadMasterDashboardRight>{children && children}</BulkUploadMasterDashboardRight>
        </div>
    );
};

export default AdminDash;
