import React, { useEffect, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
    Grid,
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TextField,
    TablePagination,
    TableFooter,
    withStyles,
    Switch,
} from '@material-ui/core';
import Button from "@material-ui/core/Button";
import './index.sass'
import { CheckCircleOutlineRounded, Edit } from "@material-ui/icons";
import { connect } from "react-redux";
import { getArea, updateArea } from '../../actions/productMangerAction'
import { getCities } from '../../actions/productMangerAction'
import Loader from "../loader";
import CreatePincodeModal from "./createPincodeModal";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useRef } from "react";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 0,
            //   width: "100%",
            //   marginTop: "20px",
        },
        toolbar: {
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        },
        content: {
            width: "80%",
            flexGrow: 21,
        },
        paper: {
            padding: "1rem",
            width: "100%"
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        table: {
            maxWidth: "100%",
            margin: "auto"
        },
        heading: {
            fontWeight: "normal",
            fontSize: "1.2rem"
        },
        actionButton: {
            margin: "0 0.5rem",
            cursor: "pointer"
        },
        tableContainer: {
            marginTop: "2rem",
            position: "sticky",
            top: "200px",
        },
    })
);

const DefaultSwitch:any = withStyles({
    switchBase: {
        color: "red",
        '&$checked': {
            color: "green",
        },
        '&$checked + $track': {
            backgroundColor: "green",
        },
    },
    checked: {},
    track: {
        backgroundColor: "red"
    },
})(Switch);
const StyledTableCell:any = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: "#924A91",
            color: theme.palette.common.white,
            fontSize: "14px",
        },
        body: {
            fontSize: 14,
        },
    })
)(TableCell);

const StyledTableRow:any = withStyles((theme: Theme) =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    })
)(TableRow);

interface Props {
    getArea: any;
    areaList: any;
    updateArea: any;
    getCities: any;
    cities: any;
    loading: Boolean;
}

const PinCodePage: React.FC<Props> = ({
    getArea,
    areaList,
    updateArea,
    getCities,
    cities,
    loading
}) => {
    const classes = useStyles();

    const [search, setSearch] = useState<String>("")

    const [editPincode, setEditPincode] = useState("")

    const [pincode, setPincode] = useState<string | null>(null)
    const [zone, setZone] = useState<string | null>(null)
    const [city, setCity] = useState<number | null>(0)
    const [cityName, setCityName] = useState("")

    const timer = useRef<any>(0)

    const [page, setPage] = useState(0)

    const [open, setOpen] = useState(false)

    const handleSearch = (e: React.ChangeEvent<{ value: unknown }>) => {
        setSearch(e.target.value as string)
        getArea(e.target.value)
    }

    useEffect(() => {
        getArea()
        getCities()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        open === false && getArea()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        if (newPage > page) {
            let url = areaList.links && areaList.links.next.split("?")[1];
            getArea(url);
        } else if (newPage < page) {
            let url = areaList.links && areaList.links.previous.split("?")[1];
            getArea(url);
        }
        setPage(newPage as number);
    };

    const handleEditArea = (code: any) => {
        setEditPincode(code.id)
        setCityName(code.city_name)
        setCity(code.city)
        setPincode(code.pincode)
        setZone(code.area)
    }

    const handleSubmit = async (code: any) => {
        setEditPincode("")
        const body: any = {
            city,
            city_name: cityName,
            area: zone,
            pincode,
        }
        await updateArea(code.id, body)
        getArea()
    }

    const disablePincode = async (pack: any) => {
        const body: any = {
            is_active: !pack.is_active
        }
        await updateArea(pack.id, body)
        getArea()
    }

    return (
        <>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <Paper elevation={15} className={classes.paper}>
                    <Grid container spacing={3} style={{ margin: "1rem auto" }}>
                        <Grid item xs={12} md={4}>
                            <h3 className={classes.heading}>Pincodes</h3>
                        </Grid>
                        <Grid container item xs={12} md={8} direction="row" alignItems="center" justify="flex-end">
                            <TextField
                                id="search"
                                type="search"
                                value={search}
                                className="input"
                                variant="outlined"
                                placeholder="Search"
                                onChange={(e) => handleSearch(e)}
                                style={{ margin: "0" }}
                            />
                            <Button variant="contained" color="primary" style={{ marginLeft: "1rem" }} onClick={() => setOpen(true)}>Add Pincode</Button>
                        </Grid>
                    </Grid>
                    {loading ? <Loader /> : (
                        <TableContainer className={classes.tableContainer} component={Paper} style={{ maxHeight: "700px" }}>
                            <Table stickyHeader aria-label="simple table">
                                <TableHead>
                                    <StyledTableRow>
                                        <StyledTableCell align="center">Pincode</StyledTableCell>
                                        <StyledTableCell align="center">Area</StyledTableCell>
                                        <StyledTableCell align="center">City</StyledTableCell>
                                        <StyledTableCell align="center">Active</StyledTableCell>
                                        <StyledTableCell align="center">Edit</StyledTableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {areaList && areaList.results && areaList.results.length > 0 && areaList.results.map((code: any, index: any) => {
                                        return (
                                            <StyledTableRow key={index}>
                                                <StyledTableCell align="center">
                                                    {editPincode === code.id ? (
                                                        <TextField
                                                            name="pincode"
                                                            type="text"
                                                            value={pincode !== null ? pincode : code.pincode}
                                                            className="input"
                                                            variant="outlined"
                                                            placeholder="Pincode"
                                                            onChange={(e) => setPincode(e.target.value as string)}
                                                            style={{ width: "100%", padding: "10px", minWidth: "200px" }}
                                                        />
                                                    ) : (
                                                        code.pincode
                                                    )}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {editPincode === code.id ? (
                                                        <TextField
                                                            name="area"
                                                            type="text"
                                                            value={zone !== null ? zone : code.area}
                                                            className="input"
                                                            variant="outlined"
                                                            placeholder="Area"
                                                            onChange={(e) => setZone(e.target.value as string)}
                                                            style={{ width: "100%", padding: "10px", minWidth: "200px" }}
                                                        />
                                                    ) : (
                                                        code.area
                                                    )}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {editPincode === code.id ? (
                                                        <Autocomplete
                                                        id="city"
                                                        onChange={(event, newValue) => {
                                                            if (newValue) {
                                                                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                                                setCity(obj.id)
                                                                setCityName(obj.name)
                                                            }
                                                        }}
                                                        options={cities||[]}
                                                        freeSolo
                                                        blurOnSelect
                                                        aria-required
                                                        getOptionLabel={(option: any) =>  option.display_name}
                                                        onInputChange={(event, newInputValue) => {
                                                            clearTimeout(timer.current)
                                                            setCityName(newInputValue)
                                                            timer.current = setTimeout(() => {
                                                                getCities(newInputValue)
                                                            }, 1000)
                                                        }}
                                                        defaultValue={code?.city_name}
                                                        renderInput={(params) => (
                                                            <TextField className="input"
                                                                {...params}
                                                                label="City"
                                                                variant="outlined"
                                                                style={{ width: "100%", margin: "0", padding: "0" }}
                                                                required
                                                            />
                                                        )}
                                                    />
                                                    ) : (
                                                        code.city_name
                                                    )}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <DefaultSwitch
                                                        checked={code.is_active}
                                                        name="checkedA"
                                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                        disableRipple
                                                        onClick={() => disablePincode(code)}
                                                    />
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {editPincode === code.id ? (
                                                        <Button
                                                            color="primary"
                                                            variant="contained"
                                                            onClick={() => handleSubmit(code)}
                                                        >
                                                            <CheckCircleOutlineRounded />
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            color="primary"
                                                            variant="contained"
                                                            onClick={() => handleEditArea(code)}
                                                        >
                                                            <Edit />
                                                        </Button>
                                                    )}
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        )
                                    })}
                                </TableBody>
                                <TableFooter>
                                    <StyledTableRow>
                                        <TablePagination
                                            rowsPerPageOptions={[]}
                                            colSpan={3}
                                            count={(areaList && areaList.count) || 0}
                                            rowsPerPage={areaList && areaList.page_size}
                                            page={page}
                                            onPageChange={handleChangePage}
                                        />
                                    </StyledTableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                    )}
                    {areaList && areaList.length === 0 && (
                        <h3 className={classes.heading} style={{ textAlign: "center" }}>No Data Found</h3>
                    )}
                </Paper>
                <CreatePincodeModal open={open} setOpen={setOpen} />
            </main>
        </>
    );
};

const mapStateToProps = (state: any) => ({
    areaList: state.ProductManagerReducer.areaList,
    cities: state.BookingReducer.cities,
    loading: state.ProductManagerReducer.loading,
})

export default connect(mapStateToProps, {
    getArea,
    getCities,
    updateArea,
})(PinCodePage);