import React, { useEffect, useRef, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Container,
  Grid,
  TextField,
  Select,
  MenuItem,
} from "@material-ui/core";
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import Autocomplete from "@material-ui/lab/Autocomplete";
import { generateBookingFilterUrl } from "../../helpers/generateUrl";
import { connect } from "react-redux";
import {
  getPaymentInfo,
  getAgentList,
  getLeadSource,
  getPartners,
  getAllOnlineBookings,
  getCities,
  getPhlebos,
  getCollectionSlot,
  getQRCodeData,
  getVerifiedbyList,
  getUnscannedTubeTube,
} from "../actions/PhleboAdmin";
import "./filter.sass";
import { useHistory } from "react-router";
import OnlineBookingsTable from "../tables/bookingsTableOnline";
// import Loader from "../components/loader";
import PaymentModal from "../components/Comments/paymentforModal";
import TubeModal from "../components/Comments/tubeModal";
import { BOOKING_STATUS } from "../../booking_status";
import QuizRoundedIcon from "@mui/icons-material/QuizRounded";
import CloseIcon from "@material-ui/icons/Close";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
      //   width: "100%",
      //   marginTop: "20px",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
      //   padding: theme.spacing(3),
    },
    card: {
      height: "100%",
      padding: theme.spacing(1),
    },
    card_grid: {
      // wordSpacing:3,
      justifyContent: "space-between",
    },
  })
);

interface Props {
  children: any;
  agentsList: any;
  onlineBookingList: any;
  partnerList: any;
  getAgentList: any;
  getCollectionSlot: any;
  getPartners: any;
  getAllOnlineBookings: any;
  getCities: any;
  collectionSlot: any;
  cities: any;
  getPhlebos: any;
  phleboList: any;
  getLeadSource: any;
  lead_source: any;
  getPaymentInfo: any;
  paymentInformation: any;
  getQRCodeData: any;
  qrcode: any;
  loading: boolean;
  getVerifiedbyList: any;
  verifiedbyList: any;
  getUnscannedTubeTube: any;
  unScannedTube: any;
}

const CustomerLeadFilterPage: React.FC<Props> = ({
  agentsList,
  partnerList,
  getAgentList,
  getPartners,
  getAllOnlineBookings,
  onlineBookingList,
  getCollectionSlot,
  getPaymentInfo,
  paymentInformation,
  collectionSlot,
  getCities,
  cities,
  getPhlebos,
  phleboList,
  getLeadSource,
  lead_source,
  getQRCodeData,
  qrcode,
  loading,
  getVerifiedbyList,
  verifiedbyList,
  getUnscannedTubeTube,
  unScannedTube,
}) => {
  const classes = useStyles();

  const history = useHistory();
  const timer = useRef<any>(0);
  const [openModal, setopenModal] = useState<boolean>(false);
  const [status, setStatus] = useState<any>([]);
  const [source, setSource] = useState<any>([]);
  const [partner, setPartner] = useState<any>([]);
  const [agent, setAgent] = useState<any>([]);
  const [city, setCity] = useState<any>([]);
  const [VerifiedbyList, setVerifiedbyList] = useState<any>([]);
  const [slot, setSlot] = useState<any>([]);
  const [start_date, setStart_date] = useState("");
  const [end_date, setEnd_date] = useState("");
  const [booking_date, setBooking_date] = useState("");
  const [collection_date, setCollection_date] = useState("");
  const [verificationStatus, setVerificationStatus] = useState<any>([]);
  const [pickup_status, setPickup_status] = useState<any>([]);
  const [name, setName] = useState("");
  const [phlebo, setPhlebo] = useState<any>([]);
  const [phone, setPhone] = useState("");
  const [bookingId, setBookingId] = useState<any>("");
  const [report_status, setReportStatus] = useState<any>("none");
  const [pickup_received_amount, setPickupReceivedAmount] =
    useState<string>("none");
  const [page, setPage] = useState(0);
  const [sample_registered, setSampleRegisterd] = useState<any>("none");
  const [paymentId, setPaymentId] = useState<any>(0);
  const [openPaymentModal, setOpenPaymentModal] = useState<any>(false);
  const [heights, setheights] = useState(window.innerHeight);
  const [notificationData, setnotificationData] = useState("");
  const [defaultStatus, setDefaultStatus] = useState<any>([]);
  const [openTubeModal, setOpenTubeModal] = useState<any>(false);
  const [tubeId, setTubeId] = useState<any>("");
  const [updateTube, setUpdateTube] = useState<any>("");
  const [phleboJourney, setPhleboJourney] = useState<any>("none");
  const [totalCount, setTotalCount] = useState<any>("");
  const [bId, setBId] = useState<any>("");
  const [bookingIdentifier, setBookingIdentifier] = useState<any>("");
  const [memberCheck, setMemberCheck] = useState<any>("");
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const blockCharInPhoneField = (e: React.KeyboardEvent<HTMLDivElement>) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();


  useEffect(() => {
    if (history.action === "POP") {
      let ceBookingFilter: any;
      ceBookingFilter = localStorage.getItem("CEBookingFilter");
      if (ceBookingFilter) {
        ceBookingFilter = JSON.parse(ceBookingFilter);

        const statusD = ceBookingFilter?.status?.split(",");
        let statusTemp = new Array();
        BOOKING_STATUS.map((item: any) => {
          statusD.map((itm: any) => {
            if (item.value == itm) {
              statusTemp.push(item);
            }
          });
        });
        setDefaultStatus(statusTemp);
        ceBookingFilter?.status &&
          setStatus(ceBookingFilter?.status?.split(","));
        ceBookingFilter?.source &&
          setSource(ceBookingFilter?.source?.split(","));
        ceBookingFilter?.partner &&
          setPartner(ceBookingFilter?.partner?.split(","));
        ceBookingFilter?.city && setCity(ceBookingFilter?.city?.split(","));
        ceBookingFilter?.start_date &&
          setStart_date(ceBookingFilter?.start_date);
        setCollection_date(ceBookingFilter?.collection_date);
        ceBookingFilter?.verificationStatus &&
          setVerificationStatus(
            ceBookingFilter?.verificationStatus?.split(",")
          );
        ceBookingFilter?.pickup_status &&
          setPickup_status(ceBookingFilter?.pickup_status?.split(","));
        setName(ceBookingFilter?.name);
        setPhlebo(ceBookingFilter?.phlebo);
        setPhone(ceBookingFilter?.phone);
        setReportStatus(ceBookingFilter?.report_status);
        setSampleRegisterd(ceBookingFilter?.sample_registered);
        setPickupReceivedAmount(ceBookingFilter?.pickup_received_amount);
        setBookingId(ceBookingFilter?.bookingId);
        setPhleboJourney(ceBookingFilter?.journey);

        const url = generateBookingFilterUrl(ceBookingFilter).substring(2);
        const pageNo = page ? page : 1;
        getAllOnlineBookings(`${url}&page=${pageNo}`);
      }
    } else {
      // getAgentList()
      // getVerifiedbyList("?usergroup=VerificationExecutive")
      // getPartners()
      // getCities()
      getCollectionSlot();
      // getPhlebos()
      getLeadSource();
      getAllOnlineBookings();
      localStorage.removeItem("CEBookingFilter");
      setDefaultStatus([]);
    }
  }, [setDefaultStatus]);

  useEffect(() => {
    if (paymentId != 0) {
      getPaymentInfo(`?booking=${paymentId}`);
    }
  }, [paymentId]);

  // useEffect(() => {
  //     if (tubeId !== '') {
  //         getQRCodeData(tubeId)
  //         setOpenTubeModal(true)
  //     }

  // }, [tubeId, updateTube])
  useEffect(() => {
    if (tubeId !== "" && bId !== "") {
      if (bId === tubeId) {
        getQRCodeData(`?booking_id=${tubeId}`);
        getUnscannedTubeTube(bId, "?add_booking=false");
        setOpenTubeModal(true);
        setBookingIdentifier(bId);
      } else {
        getQRCodeData(`?add_booking_id=${tubeId}`);
        getUnscannedTubeTube(tubeId, "?add_booking=true");
        setOpenTubeModal(true);
        setBookingIdentifier(tubeId);
      }
    }
  }, [tubeId, updateTube]);

  const filterBookings = (e: any) => {
    const body: any = {
      status: status ? status.join(",") : "",
      verificationStatus: verificationStatus
        ? verificationStatus.join(",")
        : "",
      source: source ? source.join(",") : "",
      agent: agent ? agent.join(",") : "",
      city: city ? city.join(",") : "",
      slot: slot.join(","),
      start_date,
      end_date,
      booking_date,
      collection_date,
     
      pickup_status: pickup_status ? pickup_status.join(",") : "",
      name,
      phlebo: phlebo ? phlebo.join(",") : "",
      partner: partner ? partner.join(",") : "",
      phone,
      report_status,
      sample_registered,
      is_pickup_receive_amount: pickup_received_amount,
      bookingIdCC:bookingId,
      journey: phleboJourney,
    };
    const url = generateBookingFilterUrl(body).substring(2);

    localStorage.setItem("CEBookingFilter", JSON.stringify(body));

    getAllOnlineBookings(`${url}`);
    setPage(0);
  };
  const onKeyDown = (e: any) => {
    if (e.key === "Enter") {
      filterBookings(e);
    }
  };
  const handleCross = () => {
    setOpen(false);
  };
  useEffect(() => {
    setTotalCount(onlineBookingList.count);
  }, [onlineBookingList]);
  document.querySelectorAll("#noTyping").forEach(el => {
    el.addEventListener("keydown", function (e) {
      e.preventDefault()
    })
  })

  return (
    <>
      <main className={classes.content}>
        {/* {notificationData !== "" ? <MyNotify /> : null} */}
        <div className={classes.toolbar} />
        <Grid
          container
          spacing={1}
          direction="row"
          style={{ alignItems: "right" }}
        >
          <Grid item xs={12} sm={8}>
            <h4 style={{ fontWeight: "bold" }}>
             CC BOOKINGS{" "}
              {/* <QuizRoundedIcon color="primary" onClick={handleOpen} /> */}
            </h4>
          </Grid>
          <Grid item xs={12} sm={4}>
            <h4 style={{ fontWeight: "bold" }}>BOOKING COUNT : {totalCount}</h4>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={1}
          direction="row"
          style={{ alignItems: "left" }}
        >
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              name="booking"
              type="number"
              label="Booking Id"
              onWheel={(e: any) => e.target.blur()}
              value={bookingId}
              variant="outlined"
              inputProps={{ min: 0 }}
              onKeyDown={(e) => {
                blockCharInPhoneField(e)
                onKeyDown(e)
              }}
              onChange={(e) => setBookingId(e.target.value)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              name="name"
              type="text"
              label="Name"
              value={name}
              variant="outlined"
              onKeyDown={onKeyDown}
              onChange={(e) => setName(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              name="phone"
              type="number"
              label="Phone"
              value={phone}
              variant="outlined"
              onKeyDown={onKeyDown}
              onChange={(e) => setPhone(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="city"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj.map((item: any) => item.id);
                }
                setCity(sourceIds);
              }}
              options={cities || []}
              freeSolo
              blurOnSelect
              aria-required
              limitTags={1}
              multiple
              onKeyDown={onKeyDown}
              getOptionLabel={(option: any) => option.name}
              getOptionDisabled={(option: any) => {
                return city.includes(option.id);
              }}
              disableClearable
              disableCloseOnSelect
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getCities(newInputValue);
                }, 1000);
                if (newInputValue.length === 0) {
                  setCity([]);
                }
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="City"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="status"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj.map((item: any) => item.value);
                }
                setStatus(sourceIds);
              }}
              options={BOOKING_STATUS || []}
              blurOnSelect
              aria-required
              onKeyDown={onKeyDown}
              limitTags={1}
              multiple
              getOptionLabel={(option: any) => option.text}
              getOptionDisabled={(option: any) => {
                return status.includes(option.value);
              }}
              disableClearable
              disableCloseOnSelect
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Booking Status"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
              defaultValue={defaultStatus}
            />
          </Grid>
         
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="city"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj.map((item: any) => item.value);
                }
                setVerificationStatus(sourceIds);
              }}
              options={[
                {
                  text: "Pending",
                  value: "pending",
                },
                {
                  text: "Confirmed",
                  value: "confirmed",
                },
                {
                  text: "Cancelled",
                  value: "cancelled",
                },
              ]}
              freeSolo
              blurOnSelect
              aria-required
              limitTags={1}
              onKeyDown={onKeyDown}
              multiple
              getOptionLabel={(option: any) => option.text}
              getOptionDisabled={(option: any) => {
                return verificationStatus.includes(option.value);
              }}
              disableClearable
              disableCloseOnSelect
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Verification Status"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>
      
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              name="start_date"
              type="date"
              label="Start Date"
              value={
                booking_date === "" || collection_date === "" ? start_date : ""
              }
              id="noTyping"
              variant="outlined"
              disabled={booking_date !== "" || collection_date !== ""}
              onKeyDown={e => e.preventDefault()} 
              onChange={(e) => setStart_date(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              name="end_date"
              type="date"
              value={
                booking_date === "" || collection_date === "" ? end_date : ""
              }
              className="input"
              id="noTyping"
              label="End Date"
              variant="outlined"
              onKeyDown={e => e.preventDefault()} 
              disabled={booking_date !== "" || collection_date !== ""}
              onChange={(e) => setEnd_date(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
     
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              name="collection_date"
              type="date"
              value={
                start_date === "" || end_date === "" ? collection_date : ""
              }
              className="input"
              id="noTyping"
              label="Collection Date"
              variant="outlined"
              onKeyDown={e => e.preventDefault()} 
              disabled={start_date !== "" || end_date !== ""}
              onChange={(e) => setCollection_date(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
         
          <Grid item xs={12} sm={4} md={2}>
            <Select
              className="input"
              name="sampleregisterd"
              variant="outlined"
              value={report_status}
              style={{ width: "100%", height: "40px" }}
              onChange={(e) => setReportStatus(e.target.value as String)}
            >
              <MenuItem disabled value={"none"}>
                Report Status
              </MenuItem>
              <MenuItem value={"na"}>None</MenuItem>
              <MenuItem value={"pending"}>Pending</MenuItem>
              <MenuItem value={"partial"}>Partial</MenuItem>
              <MenuItem value={"consolidate"}>Consolidate</MenuItem>
            </Select>
          </Grid>
      
          {/* End */}
          <Grid item xs={12} sm={4} md={2}>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              onClick={filterBookings}
            >
              Filter
            </Button>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => history.push("/dashboard/pha/cc_booking_list")}
            >
              Reset
            </Button>
          </Grid>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={open}>
              <div
                style={{
                  position: "absolute",
                  left: "10%",
                  right: "15%",
                  top: "25%",
                  minHeight: "400",
                  minWidth: "500",
                  backgroundColor: "white",
                }}
              >
                <Box style={{}}>
                  <Typography
                    id="transition-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    <div
                      style={{
                        margin: "0rem 2rem",
                        paddingTop: "1rem",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <h4>Note:</h4>
                      <CloseIcon onClick={handleCross} />
                    </div>
                  </Typography>
                  <Typography id="transition-modal-description" sx={{ mt: 1 }}>
                    <div style={{ margin: "1rem" }}>
                      <div
                        style={{
                          alignItems: "left",
                          display: "inline-block",
                          margin: "1rem",
                        }}
                      >
                        <small>
                          <ul style={{ paddingLeft: "15px" }}>
                            <li>
                              You can filter by booking date or start and end
                              date.
                            </li>
                            <li>T: Total Additional Booking count</li>
                            <li>P: Pending count for additional member</li>
                            <li>C: Confirmed count for additional member</li>
                            <li>
                              For B2B booking first select Booking Type b2b then
                              apply any filter option
                            </li>
                          </ul>
                        </small>
                      </div>
                      <div
                        style={{
                          alignItems: "left",
                          display: "inline-block",
                          margin: "1rem",
                        }}
                      >
                        <small>
                          <div className="r-cl" style={{ display: "flex" }}>
                            <div
                              className="boxStyle"
                              style={{
                                border: "1px solid",
                                width: "17px",
                                height: "17px",
                                background: "#ffe082",
                              }}
                            ></div>
                            <div style={{ marginLeft: "5px" }}>
                              B2B booking and Payable Amount is Zero and Phlebo
                              Not Reached
                            </div>
                          </div>
                          <div className="r-cl" style={{ display: "flex" }}>
                            <div
                              className="boxStyle"
                              style={{
                                border: "1px solid",
                                width: "17px",
                                height: "17px",
                                background: "#f48fb1",
                              }}
                            ></div>
                            <div style={{ marginLeft: "5px" }}>
                              B2B booking and Payable Amount is not Zero and
                              Phlebo Not Reached
                            </div>
                          </div>
                          <div className="r-cl" style={{ display: "flex" }}>
                            <div
                              className="boxStyle"
                              style={{
                                border: "1px solid",
                                width: "17px",
                                height: "17px",
                                background: "#64ffda",
                              }}
                            ></div>
                            <div style={{ marginLeft: "5px" }}>
                              B2C booking and Payable Amount is Zero and Phlebo
                              Not Reached
                            </div>
                          </div>
                          <div className="r-cl" style={{ display: "flex" }}>
                            <div
                              className="boxStyle"
                              style={{
                                border: "1px solid",
                                width: "17px",
                                height: "17px",
                                background: "default",
                              }}
                            ></div>
                            <div style={{ marginLeft: "5px" }}>
                              B2C booking and Payable Amount is not Zero and
                              Phlebo Not Reached
                            </div>
                          </div>
                          <div className="r-cl" style={{ display: "flex" }}>
                            <div
                              className="boxStyle"
                              style={{
                                border: "1px solid",
                                width: "17px",
                                height: "17px",
                                background: "#ba68c8",
                              }}
                            ></div>
                            <div style={{ marginLeft: "5px" }}>
                              Phlebo Reached
                            </div>
                          </div>
                          <div className="r-cl" style={{ display: "flex" }}>
                            <div
                              className="boxStyle"
                              style={{
                                border: "1px solid",
                                width: "17px",
                                height: "17px",
                                background: "#ff9800",
                              }}
                            ></div>
                            <div style={{ marginLeft: "5px" }}>
                              Hold Due To Payment
                            </div>
                          </div>
                        </small>
                      </div>
                    </div>
                  </Typography>
                </Box>
              </div>
            </Fade>
          </Modal>
        </Grid>
        <PaymentModal
          openModalP={openPaymentModal}
          setOpenModalP={setOpenPaymentModal}
          paymentInformation={paymentInformation}
        />
        <br />
        {onlineBookingList?.results && onlineBookingList?.results.length > 0 && (
          <Box>
            <Container maxWidth={false}>
              <Grid container spacing={3}>
                <OnlineBookingsTable
                  setOpenTubeModal={setOpenTubeModal}
                  setTubeId={setTubeId}
                  setopenModal={setopenModal}
                  openModal={openModal}
                  page={page}
                  setPage={setPage}
                  loading={loading}
                  getBookings={getAllOnlineBookings}
                  onlineBookingList={onlineBookingList}
                  paymentId={paymentId}
                  setPaymentId={setPaymentId}
                  openPaymentModal={openPaymentModal}
                  setOpenPaymentModal={setOpenPaymentModal}
                  setTotalCount={setTotalCount}
                  setBId={setBId}
                  setMemberCheck={setMemberCheck}
                />
              </Grid>
            </Container>
          </Box>
        )}
        {onlineBookingList?.results && onlineBookingList?.results.length === 0 && (
          <h6 style={{ textAlign: "center" }}>No Data Found</h6>
        )}
        <TubeModal
          setOpenTubeModal={setOpenTubeModal}
          openTubeModal={openTubeModal}
          qrcode={qrcode}
          getQRCodeData={getQRCodeData}
          tubeId={tubeId}
          setTubeId={setTubeId}
          setUpdateTube={setUpdateTube}
          unScannedTube={unScannedTube}
          bookingIdentifier={bookingIdentifier}
          memberCheck={memberCheck}
        />
      </main>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  agentsList: state.PhleboAdminReducer.agentsList,
  partnerList: state.PhleboAdminReducer.partnerList,
  onlineBookingList: state.PhleboAdminReducer.onlineBookingList,
  cities: state.PhleboAdminReducer.cities,
  phleboList: state.PhleboAdminReducer.phleboList,
  collectionSlot: state.PhleboAdminReducer.collectionSlot,
  paymentInformation: state.PhleboAdminReducer.paymentInformation,
  loading: state.PhleboAdminReducer.loading,
  qrcode: state.PhleboAdminReducer.qrcode,
  lead_source: state.PhleboAdminReducer.lead_source,
  verifiedbyList: state.PhleboAdminReducer.verifiedbyList,
  unScannedTube: state.PhleboAdminReducer.unScannedTube,
});

export default connect(mapStateToProps, {
  getAgentList,
  getPartners,
  getCities,
  getPhlebos,
  getLeadSource,
  getCollectionSlot,
  getPaymentInfo,
  getAllOnlineBookings,
  getQRCodeData,
  getVerifiedbyList,
  getUnscannedTubeTube,
})(CustomerLeadFilterPage);
