import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";
import { Grid, TextField } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import EditIcon from "@material-ui/icons/Edit";
import MessageIcon from "@material-ui/icons/Message";
import CallIcon from "@material-ui/icons/Call";
import { clickToCall } from "../actions/productMangerAction";
import {
  getLeadSource,
  getAgentList,
  getCities,
  getAllLeads,
  leadTransferByComma,
} from "../actions/productMangerAction";
import { TableFooter, TablePagination } from "@material-ui/core";
import Loader from "../Components/loader";
import CommentsModal from "../Components/comments/comment";
import CommentsModal2 from "../Components/comments/Comment2";
import RaiseComplaintModal from "../Components/comments/raiseTicketModal";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Button } from "@material-ui/core";
import { generateLeadsUrl } from "../../helpers/generateUrl";
import { useRef } from "react";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import Pagination from "@material-ui/lab/Pagination";

const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

interface Props {
  getAllLeads: Function;
  all_lead: any;
  lead_source: any;
  loading: boolean;
  // resendSms: Function;
  clickToCall: Function;
  getLeadSource: any;
  user_panel: any;
  leadTransferByComma: any;
  getAgentList: any;
  agentsList: any;
  agentBYUserGroup: any;
  getCities: any;
  cities: any;
}

const LeadsTable: React.FC<Props> = ({
  getAllLeads,
  all_lead,
  clickToCall,
  getLeadSource,
  lead_source,
  user_panel,
  leadTransferByComma,
  getAgentList,
  agentsList,
  agentBYUserGroup,
  getCities,
  cities,
  loading,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  const [open2, setOpen2] = useState<boolean>(false);
  const [raiseComplaint, setRaiseComplaint] = useState(false);

  const [leadId, setLeadId] = useState<number>(0);
  const [partner, setPartner] = useState<any>([]);
  const [page, setPage] = useState(0);
  const [page1, setPage1] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50 || all_lead.results.count);
  const [category, setCategory] = useState<any>([]);
  const [id, setId] = useState<string>("");
  const [start_date, setStart_date] = useState<string>("");
  const [end_date, setEnd_date] = useState<string>("");
  const [followUpDate, setFollowUpDate] = useState<string>("");
  const [leadSource, setleadSource] = useState<any>([]);
  const [priority, setPriority] = useState<any>([]);
  const [city, setCity] = useState<any>([]);
  const [agent, setAgent] = useState<any>([]);
  const [selectedLeads, setSelectedLeads] = useState<any>([]);
  const [panel_user, setPanelUser] = React.useState<number | null>(null);
  const timer = useRef<any>(0);
  const history = useHistory();
  const [pageNum, setPageNum] = useState(1);
  const CATEGORY: any = [
    {
      id: 1,
      text: "Interested/Follow up",
      city: true,
      leadId: false,
    },
    {
      id: 2,
      text: "Not Interested",
      city: true,
      query: false,
    },
    {
      id: 3,
      text: "Order Booked",
      city: true,
      query: false,
    },
    {
      id: 4,
      text: "Ringing",
      city: false,
      query: false,
    },
    {
      id: 5,
      text: "Number Doesn't Exist",
      city: false,
      query: false,
    },
    {
      id: 6,
      text: "By Mistake & Wrong Number",
      city: false,
      query: false,
    },
    {
      id: 7,
      text: "Call Back",
      city: true,
      query: false,
    },
    {
      id: 8,
      text: "Business Realated Call",
      city: true,
      query: false,
    },
    {
      id: 9,
      text: "Spam Call",
      city: false,
      query: false,
    },
    {
      id: 10,
      text: "Enquiry Call",
      city: true,
      query: false,
    },
    {
      id: 11,
      text: "CFresh",
      city: false,
      query: false,
    },
    {
      id: 12,
      text: "Test Not Available",
      city: true,
      query: false,
    },
    {
      id: 13,
      text: "Subscribed to Competitor",
      city: true,
      query: false,
    },
    {
      id: 14,
      text: "Slot Issue",
      city: true,
      query: false,
    },
    {
      id: 15,
      text: "Same Day Slot",
      city: true,
      query: false,
    },
    {
      id: 16,
      text: "Already Booked",
      city: false,
      query: false,
    },
    {
      id: 17,
      text: "Not Contactable",
      city: false,
      query: false,
    },
    {
      id: 18,
      text: "Out of Station",
      city: true,
      query: false,
    },
    {
      id: 19,
      text: "Out of Service Area",
      city: true,
      query: false,
    },
    {
      id: 20,
      text: "Need Time to Think",
      city: true,
      query: false,
    },
    {
      id: 21,
      text: "Email/Whatsapp Package",
      city: true,
      leadId: false,
    },
    {
      id: 22,
      text: "Discount/Price Enquiry",
      city: true,
      leadId: false,
    },
    {
      id: 23,
      text: "CS issue",
      city: false,
      leadId: true,
    },
    {
      id: 24,
      text: "Call Not Reachable",
      city: false,
      leadId: false,
    },
    {
      id: 25,
      text: "Call Later",
      city: true,
      leadId: false,
    },
    {
      id: 26,
      text: "Call drop",
      city: true,
      leadId: false,
    },
    {
      id: 27,
      text: "Call Disconnected",
      city: true,
      leadId: false,
    },
    {
      id: 28,
      text: "Below <5 year patient",
      city: true,
      leadId: false,
    },
    {
      id: 29,
      text: "DND",
      city: false,
      leadId: false,
    },
    {
      id: 30,
      text: "Phlebo's Issue",
      city: false,
      leadId: true,
    },
    {
      id: 31,
      text: "Reports Regarding Issue",
      city: false,
      leadId: true,
    },
    {
      id: 32,
      text: "Health Advice",
      city: false,
      leadId: true,
    },
    {
      id: 33,
      text: "Health Consultation",
      city: false,
      leadId: true,
    },
    {
      id: 34,
      text: "Health Consultation",
      city: false,
      leadId: false,
    },
    {
      id: 35,
      text: "Language Barrier",
      city: false,
      leadId: false,
    },
  ];

  const priorityArr: any = [
    {
      value: "SuperCritical",
    },
    {
      value: "Urgent",
    },
    {
      value: "HopeFull",
    },
    {
      value: "Low",
    },
  ];

  useEffect(() => {
    let url = ``;
    getAllLeads(url);
    getLeadSource();
    getCities();
    getAgentList();
    getAgentList("", "CustomerExecutive");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterLeads = () => {
    const body: any = {
      lead_call_status_id: category.join(","),
      leadSource: leadSource.join(","),
      priority: priority.join(","),
      followUpDate,
      city: city.join(","),
      start_date,
      end_date,
      agent: agent.join(","),
      id,
    };
    const url = generateLeadsUrl(body).substring(2);
    getAllLeads(`?${url}`);
    setPage(0);
  };

  useEffect(() => {
    if (open2 === false) {
      setLeadId(0);
    }
  }, [open2]);

  const handleClick = (id: number) => {
    setOpen(true);
    setLeadId(id);
  };

  const handleModal = (id: number) => {
    setOpen2(true);
    setLeadId(id);
  };

  const handleRaiseTicket = (id: number) => {
    setRaiseComplaint(true);
    setLeadId(id);
  };

  const handleCallClick = (id: number, type: string) => {
    setLeadId(id);
    clickToCall(id, type);
  };
  // const handleResendClick = (id: number) => {
  //   let body = {
  //     appt_id: id,
  //     template_id: "1010101"
  //   };
  //   resendSms(body);
  // };
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url = all_lead.next;

      getAllLeads(url.substring(url.indexOf("?")));
    } else if (newPage < page) {
      let url = all_lead.previous;

      getAllLeads(
        url.indexOf("?") === -1 ? "" : url.substring(url.indexOf("?"))
      );
    }
    setPage(newPage);
  };

  const transferLead = async () => {
    let body = {
      lead_ids: selectedLeads.join(", "),
      panel_user,
    };
    await leadTransferByComma(body);
    getAllLeads("");
    setSelectedLeads([]);
    getAllLeads("");
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    getAllLeads(`?page_size=${event.target.value}`);
    setPage(0);
  };
  const handlePage = (event: any, value: any) => {
    if (value > page) {
      let url = all_lead.next;
      getAllLeads(url.substring(url.indexOf("?")));
    } else if (value < page) {
      let url = all_lead.previous;

      getAllLeads(
        url.indexOf("?") === -1 ? "" : url.substring(url.indexOf("?"))
      );
    }
    setPage(value);
  };

  return (
    <>
      <Grid
        container
        spacing={3}
        direction="row"
        alignItems="center"
        justify="center"
      >
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            name="leadId"
            type="number"
            value={id}
            className="input"
            variant="outlined"
            placeholder="Lead Id"
            onChange={(e) => setId(e.target.value as string)}
            style={{ width: "100%" }}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Autocomplete
            id="city"
            onChange={(event, newValue) => {
              let sourceIds: any = [];
              if (newValue) {
                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                sourceIds = obj.map((item: any) => item.id);
              }
              setCity(sourceIds);
            }}
            options={cities}
            freeSolo
            blurOnSelect
            aria-required
            limitTags={1}
            multiple
            getOptionLabel={(option: any) => option.name}
            getOptionDisabled={(option: any) => {
              return city.includes(option.id);
            }}
            disableClearable
            disableCloseOnSelect
            onInputChange={(event, newInputValue) => {
              clearTimeout(timer.current);
              timer.current = setTimeout(() => {
                getCities(newInputValue);
              }, 1000);
              if (newInputValue.length === 0) {
                setCity([]);
              }
            }}
            renderInput={(params) => (
              <TextField
                className="input"
                {...params}
                placeholder="City"
                variant="outlined"
                style={{ width: "100%", margin: "0", padding: "0" }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Autocomplete
            id="agent"
            onChange={(event, newValue) => {
              let sourceIds: any = [];
              if (newValue) {
                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                sourceIds = obj.map((item: any) => item.id);
              }
              setAgent(sourceIds);
            }}
            freeSolo
            blurOnSelect
            aria-required
            limitTags={1}
            multiple
            options={agentsList.results || []}
            getOptionDisabled={(option: any) => {
              return agent.includes(option.id);
            }}
            disableClearable
            disableCloseOnSelect
            getOptionLabel={(option: any) =>
              option.user && option.user.username
            }
            onInputChange={(event, newInputValue) => {
              clearTimeout(timer.current);
              timer.current = setTimeout(() => {
                getAgentList(`?code=${newInputValue.replace(" ", "")}`);
              }, 1000);
              if (newInputValue.length === 0) {
                setPartner([]);
              }
            }}
            renderInput={(params) => (
              <TextField
                className="input"
                {...params}
                placeholder="Agent"
                variant="outlined"
                style={{ width: "100%", margin: "0", padding: "0" }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            className="input"
            name="start_date"
            type="date"
            label="Start Date"
            value={start_date}
            variant="outlined"
            onChange={(e) => setStart_date(e.target.value as string)}
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            name="end_date"
            type="date"
            value={end_date}
            className="input"
            label="End Date"
            variant="outlined"
            onChange={(e) => setEnd_date(e.target.value as string)}
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            className="input"
            name="Created At"
            type="date"
            label="Follow-Up Date"
            value={followUpDate}
            variant="outlined"
            onChange={(e) => setFollowUpDate(e.target.value as string)}
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Autocomplete
            onChange={(event, newValue) => {
              let sourceIds: any = [];
              if (newValue) {
                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                sourceIds = obj.map((item: any) => item.id);
              }
              setCategory(sourceIds);
            }}
            multiple
            id="checkboxes-tags-demo"
            options={CATEGORY}
            disableCloseOnSelect
            limitTags={1}
            getOptionLabel={(option: any) => option.text}
            getOptionDisabled={(option: any) => {
              return category.includes(option.id);
            }}
            disableClearable
            renderInput={(params) => (
              <TextField
                className="input"
                {...params}
                placeholder="Status"
                variant="outlined"
                style={{ width: "100%", margin: "0", padding: "0" }}
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Autocomplete
            onChange={(event, newValue) => {
              let sourceIds: any = [];
              if (newValue) {
                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                sourceIds = obj.map((item: any) => item.id);
              }
              setleadSource(sourceIds);
            }}
            multiple
            id="checkboxes-tags-demo"
            options={lead_source}
            disableCloseOnSelect
            limitTags={1}
            getOptionLabel={(option: any) => option.name}
            getOptionDisabled={(option: any) => {
              return leadSource.includes(option.id);
            }}
            disableClearable
            renderInput={(params) => (
              <TextField
                className="input"
                {...params}
                placeholder="Lead Source"
                variant="outlined"
                style={{ width: "100%", margin: "0", padding: "0" }}
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Autocomplete
            onChange={(event, newValue) => {
              let sourceIds: any = [];
              if (newValue) {
                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                sourceIds = obj.map((item: any) => item.value);
              }
              setPriority(sourceIds);
            }}
            multiple
            id="checkboxes-tags-demo"
            options={priorityArr}
            disableCloseOnSelect
            limitTags={1}
            getOptionLabel={(option: any) => option.value}
            getOptionDisabled={(option: any) => {
              return priority.includes(option.value);
            }}
            disableClearable
            renderInput={(params) => (
              <TextField
                className="input"
                {...params}
                placeholder="Priority"
                variant="outlined"
                style={{ width: "100%", margin: "0", padding: "0" }}
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Button
            variant="contained"
            color="secondary"
            style={{ height: "56px" }}
            fullWidth
            onClick={filterLeads}
            disabled={loading}
          >
            Filter
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Button
            variant="contained"
            color="primary"
            style={{ height: "56px" }}
            fullWidth
            onClick={() => history.push("/dashboard/pm/leads")}
          >
            Reset
          </Button>
        </Grid>
      </Grid>
      {selectedLeads.length > 0 && (
        <React.Fragment>
          <h2 style={{ textAlign: "center", marginTop: "2rem" }}>
            Transfer To:
          </h2>
          <Grid
            container
            spacing={3}
            direction="row"
            alignItems="center"
            justify="center"
          >
            <Grid item xs={12} sm={6}>
              <Autocomplete
                id="agentname"
                onChange={(event, newValue) => {
                  if (newValue) {
                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                    setPanelUser(obj.id);
                  }
                }}
                options={agentBYUserGroup.results}
                freeSolo
                blurOnSelect
                aria-required
                getOptionLabel={(option: any) =>
                  option.user && option.user.username
                }
                onInputChange={(event, newInputValue) => {
                  clearTimeout(timer.current);
                  timer.current = setTimeout(() => {
                    getAgentList(
                      `?code=${newInputValue.replace(" ", "")}`,
                      "CustomerExecutive"
                    );
                  }, 1000);
                  if (newInputValue.length === 0) {
                    setPanelUser(0);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    className="input"
                    {...params}
                    placeholder="Agent username"
                    variant="outlined"
                    style={{ width: "100%", margin: "0", padding: "0" }}
                    required
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                variant="contained"
                color="default"
                style={{ height: "56px" }}
                fullWidth
                onClick={transferLead}
                disabled={
                  panel_user === null ||
                  panel_user === 0 
                 
                }
              >
                Transfer
              </Button>
            </Grid>
          </Grid>
        </React.Fragment>
      )}
      <div className="data-table" style={{ width: "100%", marginTop: "2rem" }}>
        <TableContainer component={Paper}>
          {loading ? (
            <Loader />
          ) : (
            <Table className={classes.table} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>
                    <input
                      type="checkbox"
                      className="input"
                      name="selectall"
                      id="selectall"
                      onChange={(e) =>
                        e.target.checked
                          ? setSelectedLeads(
                              all_lead.results &&
                                all_lead.results.map((lead: any) => {
                                  return lead.id && lead.id;
                                })
                            )
                          : setSelectedLeads([])
                      }
                    />
                  </StyledTableCell>
                  <StyledTableCell>Lead ID</StyledTableCell>
                  <StyledTableCell align="center">Count</StyledTableCell>
                  <StyledTableCell align="center">Created At</StyledTableCell>
                  <StyledTableCell align="center">Comments</StyledTableCell>
                  <StyledTableCell align="center">Action</StyledTableCell>
                  <StyledTableCell align="center">
                    Lead Source URL
                  </StyledTableCell>
                  <StyledTableCell align="center">Agent</StyledTableCell>
                  <StyledTableCell align="center">Call</StyledTableCell>
                  <StyledTableCell align="center">
                    Contact number
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    Whatsapp contact
                  </StyledTableCell>
                  <StyledTableCell align="center">Patient name</StyledTableCell>
                  <StyledTableCell align="center">
                    Calling gender
                  </StyledTableCell>
                  <StyledTableCell align="center">Patient age</StyledTableCell>
                  <StyledTableCell align="center">City</StyledTableCell>
                  <StyledTableCell align="center">City Name</StyledTableCell>
                  <StyledTableCell align="center">Patient Area</StyledTableCell>
                  <StyledTableCell align="center">Lead source</StyledTableCell>
                  <StyledTableCell align="center">Call status</StyledTableCell>
                  <StyledTableCell align="center">Language</StyledTableCell>
                  <StyledTableCell align="center">Edit</StyledTableCell>
                  <StyledTableCell align="center">Raise Ticket</StyledTableCell>
                  <StyledTableCell align="center">Priority</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {all_lead.results && all_lead.results.length > 0
                  ? all_lead.results.map((row: any) => (
                      <StyledTableRow
                        key={row.id}
                        // style={{
                        //   background: setColor(row.appt_status),
                        // }}
                      >
                        <StyledTableCell component="th" scope="row">
                          <input
                            type="checkbox"
                            name="selectall"
                            id="selectall"
                            checked={selectedLeads.find(
                              (lead: any) => lead === row.id
                            )}
                            onChange={(e) =>
                              !e.target.checked
                                ? setSelectedLeads(
                                    selectedLeads.filter(
                                      (lead: any) => lead !== row.id
                                    )
                                  )
                                : setSelectedLeads((prev: any) => [
                                    ...prev,
                                    row.id,
                                  ])
                            }
                          />
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {row?.id}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row?.count}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {new Date(row?.created_at).toLocaleString()}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <div>
                            <MessageIcon onClick={() => handleClick(row?.id)} />
                          </div>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <div>
                            <MessageIcon onClick={() => handleModal(row?.id)} />
                          </div>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row?.lead_source_url ? row?.lead_source_url : "NA"}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row?.panel_user ? row?.panel_user : "NA"}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <CallIcon
                            onClick={() => handleCallClick(row?.id, "call")}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row?.content_number ? (
                            <CallIcon
                              onClick={() =>
                                handleCallClick(row?.id, "contact")
                              }
                            />
                          ) : null}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.whatsapp_contact !== row?.content_number ? (
                            <CallIcon
                              onClick={() =>
                                handleCallClick(row?.id, "whatsapp")
                              }
                            />
                          ) : (
                            "NA"
                          )}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row?.patient_name ? row?.patient_name : "NA"}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row?.calling_gender ? row?.calling_gender : "NA"}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row?.patient_age}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row?.patient_city}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row?.city_name}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row?.patient_area ? row?.patient_area : "NA"}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row?.lead_source}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row?.call_status}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row?.language ? row?.language : "NA"}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <Link to={`/dashboard/pm/fill-info?id=${row?.id}`}>
                            <EditIcon />
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <Button
                            variant={!raiseComplaint ? "contained" : "outlined"}
                            color="secondary"
                            onClick={() => handleRaiseTicket(row?.id)}
                          >
                            <ConfirmationNumberIcon />
                          </Button>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row?.priority}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))
                  : "NA"}
              </TableBody>
            </Table>
          )}
          <Grid container xs={12}>
            <Grid container item md={6}>
              <TablePagination
                colSpan={6}
                count={all_lead.count || 0}
                rowsPerPageOptions={[10, 20, 30, 40, 50]}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                page={page}
                SelectProps={{
                  native: true,
                }}
                onPageChange={handleChangePage}
              />
            </Grid>
            <Grid container item md={6}>
              <Pagination
                style={{ paddingTop: 10 }}
                count={6}
                page={page}
                variant="outlined"
                color="primary"
                onChange={handlePage}
              />
            </Grid>
          </Grid>
        </TableContainer>
      </div>
      <CommentsModal open={open} setOpen={setOpen} leadId={leadId} />
      <CommentsModal2
        open={open2}
        setOpen={setOpen2}
        leadId={leadId}
        getCities={getCities}
        cities={cities}
      />
      <RaiseComplaintModal
        open={raiseComplaint}
        setOpen={setRaiseComplaint}
        leadId={Number(leadId)}
        bookingId={0}
      />
    </>
  );
};

const mapStateToProps = (state: any) => ({
  all_lead: state.ProductManagerReducer.all_lead,
  lead_source: state.ProductManagerReducer.lead_source,
  user_panel: state.ProductManagerReducer.user_panel,
  agentsList: state.ProductManagerReducer.agentsList,
  agentBYUserGroup: state.ProductManagerReducer.agentBYUserGroup,
  cities: state.ProductManagerReducer.cities,
  loading: state.ProductManagerReducer.loading,
});

export default connect(mapStateToProps, {
  getAllLeads,
  clickToCall,
  getLeadSource,
  getCities,
  leadTransferByComma,
  getAgentList,
})(LeadsTable);
