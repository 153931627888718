import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Button from '@mui/material/Button';
import { MenuItem, Select } from "@material-ui/core";
// import "./Tableform.css";
import image from "../../../../images/logo.png";
import { getBookingById, getAdditionalBookingData, getQRCodeData, getSignature, getHivPackage } from '../../../actions/PhleboAdmin';
import { connect } from 'react-redux';
import { savePDF } from '@progress/kendo-react-pdf';
import ReactToPrint from "react-to-print";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { useRef } from 'react';
const useStyles = makeStyles({
    table: {
        height: "40px"
    },
    left: {
        margin: 600,
        width: "50%"
    },
    fontsize13: {
        fontSize: "13px",
    },
    rightContentHeader: {
        width: "88%",
        float: "left",
        textAlign: "left",
        paddingRight: "20px",
    },
    style: {
        background: "#AD70A6",
        color: "#fff",
        padding: 10,
        marginLeft: 5,
    }

});
interface Props {
    match: any;
    booking: any;
    getBookingById: any;
    getAdditionalBookingData: any;
    additionMember: any;
    getQRCodeData: any;
    qrcode: any;
    getSignature: any;
    signature: any;
    loading: Boolean;
    getHivPackage: any;
    hivpackageList: any;
}
const PhleboAdminViewBooking: React.FC<Props> = ({
    match,
    booking,
    getHivPackage,
    hivpackageList,
    getBookingById,
    getAdditionalBookingData,
    additionMember,
    getSignature,
    signature,
    loading
}) => {

    const bookingId = match.params.id
    const [id, setId] = useState<any>(0)


    useEffect(() => {
        if (bookingId !== 0||bookingId !== undefined) {
            if (Number(bookingId)) getBookingById(`${bookingId}/`)
            if (Number(bookingId)) getHivPackage(`?booking=${bookingId}`)
            if (Number(bookingId)) getQRCodeData(bookingId)
            if (Number(bookingId)) getSignature(bookingId)

        }
    }, [bookingId])

    useEffect(() => {
        if (bookingId !== 0||bookingId !== undefined) {
            if (Number(bookingId)) getHivPackage(`?booking=${bookingId}`)}
    }, [booking])

    const classes = useStyles();
    const contentArea = useRef<any>(0)
    const componentRef = useRef<any>(null);
    //   const pid = booking && booking?.customer_name + "-" + booking && booking?.pk;
    const [personName, setPersonName] = useState<any>("main")
    // const handleExportWithFunction = () => {
    //     savePDF(contentArea.current, { paperSize: "A4"});
    //     //  window.print

    // }

    const reactToPrintContent = React.useCallback(() => {
        return componentRef.current;
    }, [componentRef.current]);
    const reactToPrintTrigger = React.useCallback(() => {
        return <button style={{ marginLeft: "10px" }}>Print Receipt</button>;
    }, []);

  
useEffect(()=>{
    if(personName==="main"){
        if (bookingId !== 0||bookingId !== undefined) {
            if (Number(bookingId)) getHivPackage(`?booking=${bookingId}`)}
    }else{
        getHivPackage(`?add_member_booking=${personName}`)
    }
   
},[personName])

    // useEffect(() => {
    //     setSelf(hivpackageList && hivpackageList?.length > 0 && hivpackageList[0]?.report_can_be_seen_by[0])
    //     setRelative(hivpackageList && hivpackageList?.length > 0 && hivpackageList[0]?.report_can_be_seen_by[0])
    //     setRefDoc(hivpackageList && hivpackageList?.length > 0 && hivpackageList[0]?.report_can_be_seen_by[0])
    //     setRefAge(hivpackageList && hivpackageList?.length > 0 && hivpackageList[0]?.report_can_be_seen_by[0])

    // }, [hivpackageList])
   

    return (
        <>
            <div>
                <div style={{ height: "20px", marginTop: "35%" }}>
                    <h2></h2>
                    <div className={classes.rightContentHeader}>
                        {/* <Select className="input"
                            name="priority"
                            variant="outlined"
                            style={{ width: "50%", marginLeft: "1rem", marginBottom: "1rem", alignItems: "flex-start" }}
                           value={personName}
                          onChange={handleChange}
                        >
                            <MenuItem disabled selected value={'none'}>Customer Name</MenuItem>
                            <MenuItem ></MenuItem>
                            {booking.additional_members &&
                booking.additional_members.length > 0 &&
                booking.additional_members.map((data: any) => {
                  return (
                    <MenuItem value={data?.id}>{data?.customer_name}</MenuItem>

                  )
                })}
                        </Select> */}
                        { }
                        <Select className="input"
                            name="priority"
                            variant="outlined"
                            style={{ width: "100%", marginLeft: "1rem", marginBottom: "1rem", alignItems: "flex-start" }}
                            value={personName}

                            onChange={(e)=>setPersonName(e.target.value)}
                        >
                            {/* <MenuItem disabled selected value={'none'}>Customer Name</MenuItem> */}
                            <MenuItem value={"main"}>{booking?.customer_name}</MenuItem>
                            {booking.additional_members &&
                                booking.additional_members.length > 0 &&
                                booking.additional_members.map((data: any) => {
                                    return (
                                        <MenuItem value={data?.id}>{data?.customer_name}</MenuItem>

                                    )
                                })}
                        </Select>
                        <ReactToPrint
                            content={reactToPrintContent}
                            trigger={reactToPrintTrigger}
                        />


                    </div>

                </div>
            </div>

            <div

                style={{
                    width: 842,
                    background: "#f1f1f1",
                    margin: "20px auto",
                    padding: 20
                }}
            >
               
                            <div ref={componentRef}>
                                <div style={{ width: "100%", display: "flex", background: "#AD70A6", marginTop: "52px" }}>
                                    <div style={{ width: "50%", textAlign: "left", padding: 15 }}>
                                        <h3><img src={image} alt="logo" style={{ width: "150px" }} /></h3>
                                    </div>
                                    <div style={{ width: "50%", textAlign: "right", padding: 15 }}>
                                        <div style={{ color: "white", fontWeight: "bold" }}>HIV CONSENT FORM</div>
                                    </div>
                                </div>
                                <div style={{ width: "100%", display: "flex", margin: "10px 0px" }}>

                                    <div style={{ width: "20%" }}>Name of the Patient:</div>
                                    <input style={{ width: "30%", marginLeft: 5 }} value={hivpackageList && hivpackageList?.length > 0 && hivpackageList[0]?.patient_name} />

                                    <div style={{ width: "20%", marginLeft: 8 }}>Contact No:</div>

                                    <input style={{ width: "30%", marginLeft: 3 }} value={hivpackageList && hivpackageList?.length > 0 && hivpackageList[0]?.contact_number} />
                                </div>

                                <div style={{ width: "100%", display: "flex", margin: "10px 0px" }}>

                                    <div style={{ width: "20%" }}>Address:</div>
                                    <input style={{ width: "30%", marginLeft: 5 }} value={hivpackageList && hivpackageList?.length > 0 && hivpackageList[0]?.address} />

                                    <div style={{ width: "20%", marginLeft: 8 }}>Date of Counselling:</div>

                                    <input style={{ width: "30%", marginLeft: 3 }} value={hivpackageList && hivpackageList?.length > 0 && hivpackageList[0]?.counselling_date} />
                                </div>

                                <div style={{ width: "100%", display: "flex", margin: "10px 0px" }}>

                                    <div style={{ width: "20%" }}>Age:</div>
                                    <input style={{ width: "30%", marginLeft: 5 }} value={hivpackageList && hivpackageList?.length > 0 && hivpackageList[0]?.age} />

                                    <div style={{ width: "20%", marginLeft: 8 }}>Sex:</div>

                                    <input style={{ width: "30%", marginLeft: 3 }} value={hivpackageList && hivpackageList?.length > 0 && hivpackageList[0]?.sex} />
                                </div>

                                <div style={{ width: "100%", display: "flex", margin: "10px 0px" }}>

                                    <div style={{ width: "20%" }}>Ref Doctor Name:</div>
                                    <input style={{ width: "30%", marginLeft: 5 }} value={hivpackageList && hivpackageList?.length > 0 && hivpackageList[0]?.ref_doctor_name} />

                                    <div style={{ width: "20%", marginLeft: 8 }}>Ref Doctor Mobile:</div>

                                    <input style={{ width: "30%", marginLeft: 3 }} value={hivpackageList && hivpackageList?.length > 0 && hivpackageList[0]?.ref_doctor_phone} />
                                </div>

                                <div style={{ width: "100%", display: "flex", margin: "10px 0px" }}>

                                    <div style={{ width: "20%" }}>Name Of Counsellor:</div>
                                    <input style={{ width: "30%", marginLeft: 5 }} value={hivpackageList && hivpackageList?.length > 0 && hivpackageList[0]?.counsellor_name} />

                                    <div style={{ width: "20%", marginLeft: 8 }}>Aadhaar Number:</div>

                                    <input style={{ width: "30%", marginLeft: 3 }} value={hivpackageList && hivpackageList?.length > 0 && hivpackageList[0]?.adhaar_number} />
                                </div>
                                <div style={{ width: "100%", display: "flex", margin: "10px 0px" }}>
                                    <div style={{ width: "20%" }}>
                                        Name of Collection Center/Lab:
                                    </div>
                                    <input style={{ width: "80%", marginLeft: 5 }} value={hivpackageList && hivpackageList?.length > 0 && hivpackageList[0]?.center_lab_name} />
                                </div>
                                <div style={{ width: "100%", display: "flex", margin: "10px 0px" }}>
                                    <div style={{ width: "20%" }}>
                                        Aadhaar Image:
                                    </div>
                                    <img style={{ width: "150px", height: "auto" }} src={hivpackageList && hivpackageList?.length > 0 && hivpackageList[0]?.adhaar_card} />
                                </div>

                                <div>
                                    <div style={{ fontWeight: "bold", color: "blue" }}>PRE TEST COUNSELLING:</div>
                                    <div style={{ padding: 10, width: "100%" }}>
                                        <p>I  <span style={{ fontWeight: "bold" }}> {hivpackageList && hivpackageList?.length > 0 && hivpackageList[0]?.patient_name} </span>
                                            the undersigned agreed to get my blood tested for HIV antibodies.
                                            My counselor has answered any questions I have about HIV/AIDS. I have been provided information with the following
                                            details about HIV testing; HIV is the virus that causes AIDS and can be transmitted through unprotected sex (vaginal,
                                            anal, or oral sex) with someone who has HIV; contact with blood as in sharing needles (piercing, tattooing, drug
                                            equipment including needles), by HIV-infected pregnant women to their infants during pregnancy or delivery, or while
                                            breast feeding.</p>


                                        <p>There are treatments for HIV/AIDS that can help an individual stay healthy. Individuals with HIV/AIDS can adopt safe
                                            practices to protect uninfected and infected people in their lives from becoming infected
                                            or being infected themselves with different strains of HIV.</p>

                                        <p>The results of my HIV testing and other related information will be kept confidential.</p>

                                        <p> A person may carry the HIV virus for a span of 10 years before progression to full blown HIV. During this period person
                                            shows no signs and symptoms of underlying disease but is capable of causing disease.</p>
                                    </div>
                                </div>

                                <div>
                                    <div style={{ fontWeight: "bold", color: "blue" }}>POST TEST COUNSELLING:</div>
                                    <div style={{ padding: 10, width: "100%" }}>
                                        <p>
                                            This is to certify that after receiving my HIV testing results/report Post test counseling will be given by my treating
                                            doctor</p>
                                    </div>
                                </div>

                                <div >
                                    <div style={{ fontWeight: "bold", color: "blue" }}>RESULT:</div>
                                    <div style={{ padding: 10, width: "100%" }}>
                                        <p>

                                            I understand that my result will be kept confidential and authorize the following person/agency to collect my reports</p>
                                    </div>
                                </div>

                                <div style={{ width: "100%", display: "flex", margin: "10px 0px" }}>

                                    <FormControlLabel
                                        value="Self"
                                        control={
                                            <Checkbox color="primary"
                                                checked={hivpackageList && hivpackageList?.length > 0 && hivpackageList[0]?.report_can_be_seen_by[0] === "self"}
                                            />}
                                        label="Self"
                                        labelPlacement="start"

                                    />


                                    <FormControlLabel
                                        value="Ref Doctor"
                                        control={
                                            <Checkbox color="primary"
                                                checked={hivpackageList && hivpackageList?.length > 0 && hivpackageList[0]?.report_can_be_seen_by[0] === "refdoctor"}
                                            />
                                        }
                                        label="Ref Doctor"
                                        labelPlacement="start"
                                    />

                                    <FormControlLabel
                                        value="Ref Agency"
                                        control={<Checkbox color="primary" />}
                                        checked={hivpackageList && hivpackageList?.length > 0 && hivpackageList[0]?.report_can_be_seen_by[0] === "refagency"}

                                        label="Ref Agency"
                                        labelPlacement="start"
                                    />

                                    <FormControlLabel
                                        value="Relative"
                                        control={<Checkbox color="primary" />}
                                        checked={hivpackageList && hivpackageList?.length > 0 && hivpackageList[0]?.report_can_be_seen_by[0] === "relative"}
                                        label="Relative"
                                        labelPlacement="start"
                                    />

                                </div>

                                <div style={{ width: "100%", display: "flex", margin: "30px 0px" }}>

                                    <div className='signAture' style={{ textAlign: "left" }}>
                                        <p>Dated Sign of Patient:</p>
                                        <img style={{ width: "100px", height: "80px" }} src={hivpackageList && hivpackageList?.length > 0 && hivpackageList[0]?.patient_signature} />
                                    </div>

                                    <div className='signAture'>
                                        <p>Dated Sign of Doctor:</p>
                                        <img style={{ width: "100px", height: "80px" }} src={hivpackageList && hivpackageList?.length > 0 && hivpackageList[0]?.doctor_signature} />
                                    </div>


                                </div>



                                <div
                                    style={{
                                        width: "100%",
                                        background: "#AD70A6",
                                        color: "#fff",
                                        textAlign: "center",
                                        padding: 10
                                    }}
                                >
                                    <h3>Redcliffe Life Tech Pvt.Ltd.</h3>
                                    <h6>
                                        H-55, Sector-63, Electronic City Noida, Noida-201301 (U.P) INDIA,
                                        Phone: 898 898 1777
                                        <br /> www.redcliffelabs.com
                                    </h6>
                                </div>
                            </div> 
                          

            </div>
        </>
    );
}
const mapStateToProps = (state: any) => ({
    booking: state.PhleboAdminReducer.booking,
    additionMember: state.PhleboAdminReducer.additionMember,
    signature: state.PhleboAdminReducer.signature,
    loading: state.PhleboAdminReducer.loading,
    hivpackageList: state.PhleboAdminReducer.hivpackageList,

})

export default connect(mapStateToProps, {
    getBookingById,
    getHivPackage,
    getAdditionalBookingData,
    getSignature,
})(PhleboAdminViewBooking);