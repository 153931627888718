import React, { useState, useEffect, useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Card,
  Grid,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@material-ui/core";
import {
  getPackage,
  updateBooking,
  getBookingById,
  getCoupons,
  getAvailableSlots,
  getCities,
  getTimeslots,
  getSubCenterInfo,
} from "../../../actions/PhleboAdmin";
import Button from "@mui/material/Button";
import "./index.sass";
import { connect } from "react-redux";
import moment from "moment";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useHistory } from "react-router";
import { Chip } from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { GENDER_CHOICE } from "../../constant";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
    },
    contentHeader: {
      width: "100%",
      padding: "0px 10px 10px 10px",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      padding: "20px 20px 20px 20px",
      marginTop: "20px",
      width: "100%",
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    head: {
      textAlign: "center",
      fontWeight: "normal",
      marginBottom: "2rem",
    },
    slotContainer: {
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "center",
    },
    slot: {
      width: "150px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      margin: "0.5rem",
    },
    selectedSlot: {
      width: "150px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      background: "linear-gradient(60deg, cyan, #00dfff, cyan)",
      cursor: "pointer",
      margin: "0.5rem",
    },
    chips: {
      display: "flex",
    },
    chip: {
      margin: 2,
      background: "linear-gradient(60deg, cyan, #00cfff, cyan)",
      maxWidth: "130px",
    },
  })
);

interface Props {
  getPackage: any;
  packageList: any;
  couponList: any;
  getCoupons: any;
  updateBooking: any;
  match: any;
  booking: any;
  getBookingById: any;
  booking_slots: any;
  getAvailableSlots: any;
  loading: boolean;
  getCities: any;
  cities: any;
  getTimeslots: any;
  timeslots: any;
  timeslotsList: any;
  getSubCenterInfo: any;
  subCenter: any;
}

const EditBookingForm: React.FC<Props> = ({
  packageList,
  getPackage,
  couponList,
  getCoupons,
  updateBooking,
  match,
  booking,
  getBookingById,
  booking_slots,
  getAvailableSlots,
  getCities,
  cities,
  getTimeslots,
  timeslots,
  timeslotsList,
  subCenter,
  loading,
  getSubCenterInfo,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [collection_date, setCollection_date] = useState<String>(
    booking.collection_date
  );
  const [booking_date, setBooking_date] = useState<string>(
    booking.booking_date
  );
  const [timeSlots, setTimeSlots] = useState<Array<Object>>([]);
  const [selectedSlot, setSelectedSlot] = useState<Number>(
    booking.collection_slot && booking.collection_slot.id
  );
  const [customer_name, setCustomer_name] = useState<String>(
    booking.customer_name
  );
  const [customer_designation, setCustomer_designation] = useState<string>();
  const [designation, setDesignation] = useState<String>();

  const [customer_age, setCustomer_age] = useState<String>(
    booking.customer_age
  );
  const [customer_gender, setCustomer_gender] = useState<string>();
  const [customer_phonenumber, setCustomer_phonenumber] = useState<String>(
    booking.customer_phonenumber
  );
  const [customer_alternatenumber, setCustomer_alternatenumber] =
    useState<String>(booking.customer_altphonenumber);
  const [customer_whatsapppnumber, setCustomer_whatsapppnumber] =
    useState<String>(booking.customer_whatsapppnumber);
  const [customer_email, setCustomer_email] = useState<String>(
    booking.customer_email
  );
  const [customer_landmark, setCustomer_landmark] = useState<String>(
    booking.customer_address
  );
  const [landmark, setLandmark] = useState<any>(null);
  const [customer_longitude, setCustomerLongitude] = useState<any>(
    booking?.customer_longitude
  );
  const [customer_latitude, setCustomerLatitude] = useState<any>(
    booking?.customer_latitude
  );
  const [customer_areapincode, setCustomer_areapincode] = useState<Number>(
    booking?.customer_areapincode?.id
  );
  const [customer_address, setCustomer_address] = useState<String>(
    booking?.customer_address && booking?.customer_address
  );
  const [customer_city, setCustomer_city] = useState<String>("");
  const [customer_aadhar, setCustomer_aadhar] = useState<String>(
    booking?.customer_aadhar
  );
  const [passport_number, setPassportNumber] = useState<String>(
    booking?.passport_number
  );
  const [adharPassport, setAdharPassport] = useState(
    booking?.customer_aadhar !== "" ? "customer_aadhar" : "passport_number"
  );
  const [packageDetails, setPackageDetails] = useState<any>(booking.packages);
  const [packageKey, setPackageKey] = useState<any>(0);
  const [packages, setPackages] = useState<Number[]>(
    booking?.packages &&
      booking?.packages.map((pack: any) => {
        return pack?.id;
      })
  );

  const [coupon, setCoupon] = useState<Number>(0);
  const [couponCode, setCouponCode] = useState<string>("");
  const [price, setPrice] = useState<any>(
    booking?.discounted_price && booking?.discounted_price?.total_price_package
  );
  const [discount, setDiscount] = useState<any>(
    booking?.discounted_price && booking?.discounted_price?.counpon_discount
  );
  const [totalPrice, setTotalPrice] = useState<any>(
    booking?.discounted_price && booking?.discounted_price?.final_total_price
  );
  const [extraCharge, setExtraCharge] = useState<boolean>(
    booking?.discounted_price && booking?.discounted_price?.phlebo_cost !== 0
  );
  const [cityName, setCityName] = useState<String>(
    booking?.city && booking?.city
  );
  const [cityId, setCityId] = useState<any>(booking && booking?.cityid);
  const [centerName, setCentername] = useState<String>(booking?.center?.name);
  const [centerId, setCenterId] = useState<any>(booking?.center?.id);
  const [centerKey, setCenterKey] = useState<any>(0);
  const [packKey, setPackKey] = useState<any>(0);
  const [couponKey, setCouponKey] = useState<any>(0);
  const [is_pincode, setIsPincode] = useState<boolean>(true);
  const [mobileValidation, setMobileValidation] = useState({
    customer_alternatenumber: true,
    customer_phonenumber: true,
    customer_whatsapppnumber: true,
  });

  const bookingId = match.params.id;
  const [subCenterId, setSubCenterId] = useState<any>(
    booking?.center_address?.id
  );
  const [defaultSubCenter, setDefaultSubCenter] = useState<any>();
  const timer = useRef<any>(0);

  useEffect(() => {
    if (booking?.coupon_applied) {
      setCoupon(booking?.coupon_applied?.id);
      setCouponCode(booking?.coupon_applied?.name);
    }
    if (booking?.designation && booking?.customer_gender) {
      setCustomer_designation(booking?.designation);
      const findGender = GENDER_CHOICE.filter(
        (el: any) => el.db_name == booking?.designation
      );
      if (
        booking?.designation == "Master" &&
        booking?.customer_gender == "male"
      ) {
        setDesignation("Baby Boy");
      } else if (
        booking?.designation == "Master" &&
        booking?.customer_gender == "female"
      ) {
        setDesignation("Baby Girl");
      } else {
        findGender.length > 0 && setDesignation(findGender[0]?.display_name);
      }
      setCustomer_gender(booking?.customer_gender);
    }
  }, [booking]);

  useEffect(() => {
    if (cityId !== "") {
      getSubCenterInfo(`?imaging=true`);
    }
  }, [cityId]);

  useEffect(() => {
    getPackage(`center_address=${subCenterId}&org_type=imaging`);
    getCoupons("?org_type=imaging");
    getCities("", "?is_imaging=true");
    getTimeslots();
  }, []);

  useEffect(() => {
    getBookingById(`${bookingId}/`);
  }, [bookingId]);

  useEffect(() => {
    if (timeslotsList && timeslotsList?.count > 0) {
      setTimeSlots(timeslotsList?.results)
    }
  }, [timeslotsList]);

  useEffect(() => {
    if (booking?.pk > 0 && collection_date !== booking?.collection_date) {
      setCollection_date(booking?.collection_date);
      setBooking_date(booking?.booking_date);
      setCustomerLatitude(booking?.customer_latitude);
      setCustomerLongitude(booking?.customer_longitude);
      setPackageKey(!packageKey)
      setSelectedSlot(booking.collection_slot && booking.collection_slot.id);
      setCustomer_name(booking?.customer_name);
      setCustomer_age(booking.customer_age);
      setCustomer_phonenumber(booking.customer_phonenumber);
      setCustomer_alternatenumber(booking.customer_altphonenumber);
      setCustomer_whatsapppnumber(booking.customer_whatsapppnumber);
      setCustomer_email(booking.customer_email);
      setCustomer_landmark(booking?.customer_landmark);
      setCustomer_address(booking.customer_address && booking.customer_address);
      setCustomer_aadhar(booking.customer_aadhar);
      setAdharPassport(
        booking.customer_aadhar !== "" ? "customer_aadhar" : "passport_number"
      );
      setCityName(booking && booking?.city);
      setPackageDetails(booking.packages || []);
      setPackages(
        booking.packages &&
        booking.packages.map((pack: any) => {
          return pack.id;
        })
      );
      setCustomer_areapincode(booking?.customer_areapincode?.id);
      setCustomer_city((booking?.customer_areapincode?.city?.city as String));
      setPrice(
        booking?.discounted_price?.total_price_package
      );
      setDiscount(
        booking.discounted_price && booking.discounted_price.counpon_discount
      );
      setExtraCharge(
        booking.discounted_price && booking.discounted_price.phlebo_cost !== 0
      );
      setCityId(booking && booking.cityid);
      setSelectedSlot(booking?.collection_slot && booking?.collection_slot?.id);
    }
  }, [booking]);

  const handleCities = (val: any) => {
    setCityId(val.id);
    setCenterKey(val.id);
    setPackageKey(val.id);
  };
  const checkNormalPackage = (packages: any) => {
    let isNormalPackage = false;
    packages &&
      packages?.length > 0 &&
      packages?.map((item: any) => {
        if (!item.is_addon) {
          isNormalPackage = true;
        }
      });
    return isNormalPackage;
  };

  const getNoofPackageBuyOneGetOne = () => {
    let noOfBuyOneGetOne = 0;
    booking?.additional_members &&
      booking?.additional_members?.map((item: any) => {
        if (item?.packages && item?.packages?.length > 0) {
          item?.packages?.map((packageItem: any) => {
            if (packageItem?.code == "PL50") {
              noOfBuyOneGetOne = noOfBuyOneGetOne + 1;
            }
          });
        }
      });
    return noOfBuyOneGetOne;
  };

  const handlePackages = (customerPackage: any) => {
    const min_phlebo_cost =
      packageList &&
      packageList?.results &&
      packageList?.results?.length > 0 &&
      packageList?.results[0].min_booking_amount_for_phlebo;
    setPackageDetails(customerPackage);
    let accumulatedPrice: any = 0;
    let packIds: any = [];

    if (customerPackage && customerPackage.length > 0) {
      let atLeastOneNormalPackage = checkNormalPackage(customerPackage);
      let pl50Count =
        packageDetails?.length > 0 ? getNoofPackageBuyOneGetOne() : 0;
      let priceApplicable = pl50Count > 0 ? pl50Count % 2 : 0;
      customerPackage.map((item: any) => {
        packIds.push(item.id);
        if (pl50Count > 0 && priceApplicable == 1 && item?.code == "PL50") {
          accumulatedPrice = accumulatedPrice + 0;
        } else {
          if (
            atLeastOneNormalPackage &&
            item?.is_addon &&
            item.addon_price &&
            item.addon_price > 0
          ) {
            accumulatedPrice = accumulatedPrice + item.addon_price;
          } else {
            if (item.package_center_address_prices === null) {
              accumulatedPrice = accumulatedPrice + item.offer_price;
            } else {
              accumulatedPrice =
                accumulatedPrice +
                item.package_center_address_prices.offer_price;
            }
          }
        }
        if (accumulatedPrice < min_phlebo_cost) {
          setExtraCharge(true);
        } else {
          setExtraCharge(false);
        }
      });
    }
    // Edit Family Members
    if (
      booking?.additional_members &&
      booking?.additional_members?.length > 0
    ) {
      booking?.additional_members.map((item: any) => {
        if (item.packages) {
          let atLeastOneNormalPackage = checkNormalPackage(item?.packages);
          item.packages.map((pack: any) => {
            if (
              atLeastOneNormalPackage &&
              pack.is_addon &&
              pack.addon_price &&
              pack.addon_price > 0
            ) {
              accumulatedPrice = accumulatedPrice + pack.addon_price;
            } else {
              accumulatedPrice = accumulatedPrice + pack.offer_price;
              if (accumulatedPrice < min_phlebo_cost) {
                setExtraCharge(true);
              } else {
                setExtraCharge(false);
              }
            }
          });
        }
      });
    }
    setPackages(packIds);
    setPrice(Math.floor(accumulatedPrice));
  };

  useEffect(() => {
    if (extraCharge) {
      setTotalPrice(Math.floor(price - discount + 200));
    } else {
      setTotalPrice(Math.floor(price - discount));
    }
  }, [price, discount, extraCharge]);

  useEffect(() => {
    if (totalPrice < 0) {
      setTotalPrice(0);
    }
  }, [totalPrice]);

  const applyCoupon = () => {
    if (couponCode === "") {
      setCouponCode("");
      setCoupon(0);
      setDiscount(0);
      return;
    }
    const appliedCoupon =
      couponList.results.find((coup: any) => coup.id === coupon) ||
      couponList.results.find((coup: any) => coup.code === couponCode);
    if (appliedCoupon && appliedCoupon.minimum_price < totalPrice) {
      setCoupon(appliedCoupon.id);
      if (appliedCoupon.discount_type.toLowerCase() === "percentage") {
        if (appliedCoupon.is_red_cash) {
          setDiscount(Math.floor(price));
        } else {
          setDiscount(Math.floor((price * appliedCoupon.discount) / 100));
        }
      }
      if (appliedCoupon.discount_type.toLowerCase() === "amount") {
        if (appliedCoupon.is_red_cash) {
          setDiscount(0);
        } else {
          setDiscount(Math.floor(appliedCoupon.discount));
        }
      }
    } else {
      setCouponCode("");
      setCoupon(0);
      setDiscount(0);
    }
  };
  const verifyMobile = (type: any) => {
    if (type === "mobile") {
      if (
        parseInt(customer_phonenumber && customer_phonenumber[0]) < 6 ||
        (customer_phonenumber && customer_phonenumber.length < 10)
      ) {
        setMobileValidation((prev: any) => ({
          ...prev,
          customer_phonenumber: false,
        }));
      }
    }
    if (type === "alternate") {
      if (
        parseInt(customer_alternatenumber && customer_alternatenumber[0]) < 6 ||
        (customer_alternatenumber && customer_alternatenumber.length < 10)
      ) {
        setMobileValidation((prev: any) => ({
          ...prev,
          customer_alternatenumber: false,
        }));
      }
    }
    if (type === "whatsapp") {
      if (
        parseInt(customer_whatsapppnumber && customer_whatsapppnumber[0]) < 6 ||
        (customer_whatsapppnumber && customer_whatsapppnumber.length < 10)
      ) {
        setMobileValidation((prev: any) => ({
          ...prev,
          customer_whatsapppnumber: false,
        }));
      }
    }
  };
  const submitForm = async (e: any) => {
    e.preventDefault();
    const data: any = {
      booking_date: moment(new Date()).format("YYYY-MM-DD"),
      collection_date: collection_date,
      collection_slot: selectedSlot,
      customer_name: customer_name,
      customer_age: customer_age,
      customer_gender: customer_gender,
      customer_email: customer_email,
      customer_phonenumber: customer_phonenumber,
      customer_altphonenumber: customer_alternatenumber,
      customer_whatsapppnumber: customer_whatsapppnumber,
      customer_address: customer_address,
      customer_landmark: customer_address,
      customer_longitude: customer_longitude,
      customer_latitude: customer_latitude,
      customer_areapincode: customer_areapincode,
      customer_aadhar: customer_aadhar,
      passport_number: passport_number === null ? "" : passport_number,
      packages: packages,
      coupon_applied: coupon,
      designation: customer_designation,
      center: centerId,
      center_address: subCenterId,
    };

    if (data["coupon_applied"] === 0 || couponCode === "") {
      delete data["coupon_applied"];
    }

    // if (removeCoupn && !data['coupon_applied']) {
    //     data['remove_coupon'] = "true"
    // }
    await updateBooking(data, bookingId);
    history.push(`/dashboard/pha/booking-view/${bookingId}`);
  };
  const changeCollectionDate = (e: any) => {
    setSelectedSlot(0);
    setCollection_date(e.target.value as string);
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAdharPassport((event.target as HTMLInputElement).value);
  };

  const titleSelection = (title: string) => {
    const findGender = GENDER_CHOICE.filter(
      (el: any) => el.display_name == title
    );
    setDesignation(title);
    setCustomer_designation(findGender[0].db_name);
    setCustomer_gender(findGender[0].gender);
  };
  const handleSubCenter = (val: any) => {
    setCenterId(val.center.id);
    setSubCenterId(val.id);
    setCustomerLatitude(val.latitude);
    setCustomerLongitude(val.logitude);
    setTimeSlots(timeslotsList.results);
    setCustomer_address(val.address);
    setCustomer_areapincode(parseInt(val.location.id));
    setCustomer_city(val.location.city);
    setPackageKey(val.id);
  };

  useEffect(() => {
    if (subCenter && subCenter.results) {
      const defaultSub =
        subCenter &&
        subCenter.results &&
        subCenter.results.find((code: any) => {
          return code.id == booking.center_address.id;
        });
      setDefaultSubCenter(defaultSub);
    }
  }, [subCenter && subCenter?.results]);

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <div className={classes.contentHeader}>
        <h2>EDIT IMAGING BOOKING</h2>
      </div>
      <Paper className={classes.paper} elevation={15}>
        <form onSubmit={submitForm}>
          <Grid container spacing={3} style={{ margin: "auto" }}>
            <Grid item xs={12} md={6}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <p>Booking date</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <input
                    className="input"
                    name="collection_date"
                    type="date"
                    value={booking_date || ""}
                    style={{
                      width: "100%",
                      margin: "0",
                      padding: "18.5px 14px",
                    }}
                    required
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <p>Sample Collection date</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <input
                    className="input"
                    name="collection_date"
                    type="date"
                    value={
                      (collection_date && collection_date.toString()) || ""
                    }
                    // onChange={(e) => setCollection_date(e.target.value as string)}
                    onChange={(e) => changeCollectionDate(e)}
                    style={{
                      width: "100%",
                      margin: "0",
                      padding: "18.5px 14px",
                    }}
                    // min={`${new Date().getFullYear()}-${(new Date().getMonth() + 1) < 10 ? `0${(new Date().getMonth() + 1)}` : (new Date().getMonth() + 1)}-${new Date().getDate() < 10 ? `0${new Date().getDate()}` : new Date().getDate()}`}
                    required
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <p>Cities</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    id="cities"
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let obj = JSON.parse(
                          JSON.stringify(newValue, null, " ")
                        );
                        handleCities(obj as String);
                      }
                    }}
                    defaultValue={
                      cities &&
                      cities.find((code: any) => {
                        return code.name === cityName;
                      })
                    }
                    options={cities || []}
                    freeSolo
                    blurOnSelect
                    onInputChange={(event, newInputValue) => {
                      clearTimeout(timer.current);
                      timer.current = setTimeout(() => {
                        getCities("", `?q=${newInputValue}&is_imaging=true`);
                      }, 1000);
                    }}
                    getOptionLabel={(option: any) => `${option.name}`}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select Cities"
                        variant="outlined"
                        style={{ width: "100%", margin: "0", padding: "0" }}
                        className="input"
                        required
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <p>Sub Center</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    id="subcenter"
                    key={centerKey}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let obj = JSON.parse(
                          JSON.stringify(newValue, null, " ")
                        );
                        handleSubCenter(obj as String);
                      }
                    }}
                    defaultValue={
                      subCenter &&
                      subCenter.results &&
                      subCenter.results.find((code: any) => {
                        return code.id == booking.center_address.id;
                      })
                    }
                    options={subCenter.results || []}
                    freeSolo
                    blurOnSelect
                    onInputChange={(event, newInputValue) => {
                      clearTimeout(timer.current);
                      timer.current = setTimeout(() => {
                        getSubCenterInfo(`?imaging=true&name=${newInputValue}`);
                      }, 1000);
                    }}
                    getOptionLabel={(option: any) =>
                      `${option?.address}${
                        option.distance
                          ? `,Distance : ${Math.trunc(option?.distance)} KM`
                          : ""
                      }`
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select Sub Center"
                        variant="outlined"
                        style={{ width: "100%", margin: "0", padding: "0" }}
                        className="input"
                        required
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card
                className="sampleCollectionTime"
                style={{ height: "375px!important", overflow: "auto" }}
              >
                <p style={{ marginBottom: "1rem" }}>Sample Collection Time</p>
                <div className={classes.slotContainer}>
                  {timeSlots &&
                    timeSlots.length > 0 &&
                    timeSlots.map((slot: any) => {
                      return (
                        timeSlots.length > 0 && (
                          <Card
                            key={slot.id}
                            className={
                              slot.id === selectedSlot
                                ? classes.selectedSlot
                                : classes.slot
                            }
                            onClick={() => setSelectedSlot(slot.id as Number)}
                          >
                            <p
                              style={{
                                textAlign: "center",
                                color: "black",
                                fontSize: "14px",
                              }}
                            >
                              {slot.start_time} onwards
                            </p>
                            <p style={{ textAlign: "center", color: "green" }}>
                              {" "}
                              {/* Availabe slots: {slot.available_slots} */}
                            </p>
                          </Card>
                        )
                      );
                    })}
                </div>
              </Card>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="flex-end"
            spacing={3}
            style={{ margin: "1rem auto", background: "#eff" }}
          >
            <Grid item xs>
              <p>Title</p>
              <Select
                className="input"
                name="customer_designation"
                variant="outlined"
                value={designation || ""}
                style={{ width: "100%", margin: "0" }}
                onChange={(e) => titleSelection(e.target.value as string)}
                required
              >
                <MenuItem value="Mr">Mr</MenuItem>
                <MenuItem value="Ms">Ms</MenuItem>
                <MenuItem value="Baby Boy">Baby Boy</MenuItem>
                <MenuItem value="Baby Girl">Baby Girl</MenuItem>
              </Select>
            </Grid>
            <Grid item xs>
              <p>Customer Name</p>
              <TextField
                name="customer_name"
                type="text"
                placeholder="Enter Name"
                value={customer_name}
                className="input"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ width: "100%", margin: "0" }}
                onChange={(e) => setCustomer_name(e.target.value as String)}
                required
              />
            </Grid>
            <Grid item xs>
              <p>Age</p>
              <TextField
                name="customer_age"
                type="number"
                placeholder="Enter Age"
                value={customer_age}
                className="input"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ width: "100%", margin: "0" }}
                onChange={(e) => setCustomer_age(e.target.value as String)}
                required
              />
            </Grid>
            <Grid item xs>
              <p>Gender</p>
              <Select
                className="input"
                name="customer_gender"
                variant="outlined"
                value={customer_gender || ""}
                style={{ width: "100%", margin: "0", padding: "0" }}
                onChange={(e) => setCustomer_gender(e.target.value as string)}
                required
                disabled
              >
                <MenuItem selected hidden value={""}>
                  Please Select
                </MenuItem>
                <MenuItem value={"male"}>Male</MenuItem>
                <MenuItem value={"female"}>Female</MenuItem>
             
              </Select>
            </Grid>
            <Grid item xs>
              <p>Package Name</p>
              <Autocomplete
                id="package"
                key={packageKey}
                onChange={(event, newValue) => {
                  if (newValue) {
                    let packs: any = [];
                    newValue.map((val: any) => {
                      let obj = JSON.parse(JSON.stringify(val, null, " "));
                      packs.push(obj);
                    });
                    handlePackages(packs);
                    setPackKey(1);
                  }
                }}
                multiple
                options={packageList.results}
                value={packageDetails}
                disableClearable
                limitTags={0}
                getOptionLabel={(option: any) =>
                  option.name && option.package_center_address_prices
                    ? option.name.toString() +
                      ", " +
                      option.package_center_address_prices.tat_time
                    : option.name + ", " + option.tat_time
                }
                getOptionDisabled={(option: any) => {
                  const packID = packageDetails.map((pack: any) => pack.id);
                  return packID.includes(option.id);
                }}
                onInputChange={(event, newInputValue) => {
                  clearTimeout(timer.current);
                  timer.current = setTimeout(() => {
                    getPackage(
                      `center_address=${subCenterId}&org_type=imaging&search=${newInputValue}`
                    );
                  }, 1000);
                }}
                freeSolo
                disableCloseOnSelect
                renderTags={(value: string[], getTagProps) =>
                  value.map((option: any, index: number) => (
                    <Chip
                      variant="default"
                      color="primary"
                      label={
                        option.name && option.package_center_address_prices
                          ? option.name.toString() +
                            ", " +
                            option.package_center_address_prices.tat_time
                          : option.name + ", " + option.tat_time
                      }
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Package"
                    variant="outlined"
                    style={{ width: "100%", margin: "0", padding: "0" }}
                    className="input"
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
            direction="row"
            justify="center"
            alignItems="center"
            style={{ margin: "1rem auto" }}
          >
            <Grid item xs={12} md={6}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <p>Mobile Number</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    name="customer_phonenumber"
                    type="text"
                    value={customer_phonenumber}
                    placeholder="Enter Mobile Number"
                    className="input"
                    variant="outlined"
                    disabled
                    style={{ margin: "0", width: "100%" }}
                    onChange={(e) =>
                      setCustomer_phonenumber(e.target.value as String)
                    }
                    onBlur={() => verifyMobile("mobile")}
                    onFocus={() => {
                      setMobileValidation((prev: any) => ({
                        ...prev,
                        customer_phonenumber: true,
                      }));
                    }}
                    helperText={
                      !mobileValidation.customer_phonenumber &&
                      "Incorrect Phone Number"
                    }
                    required
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <p>Alternate Mobile Number</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    name="customer_alternatenumber"
                    type="text"
                    value={customer_alternatenumber}
                    placeholder="Enter Alternate Mobile Number"
                    className="input"
                    variant="outlined"
                    style={{ margin: "0", width: "100%" }}
                    onChange={(e) =>
                      setCustomer_alternatenumber(e.target.value as String)
                    }
                    required
                    onBlur={() => verifyMobile("whatsapp")}
                    onFocus={() => {
                      setMobileValidation((prev: any) => ({
                        ...prev,
                        customer_whatsapppnumber: true,
                      }));
                    }}
                    helperText={
                      !mobileValidation.customer_whatsapppnumber &&
                      "Incorrect Whatsapp Number"
                    }
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <p>Email</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    name="customer_email"
                    type="email"
                    placeholder="Enter Email"
                    value={customer_email}
                    className="input"
                    variant="outlined"
                    style={{ margin: "0", width: "100%" }}
                    onChange={(e) =>
                      setCustomer_email(e.target.value as String)
                    }
                    required
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <p>Whatsapp Number</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    name="customer_whatsapppnumber"
                    type="text"
                    placeholder="Enter Whatsapp Number"
                    value={customer_whatsapppnumber}
                    className="input"
                    variant="outlined"
                    style={{ margin: "0", width: "100%" }}
                    onChange={(e) =>
                      setCustomer_whatsapppnumber(e.target.value as String)
                    }
                    onBlur={() => verifyMobile("mobile")}
                    onFocus={() => {
                      setMobileValidation((prev: any) => ({
                        ...prev,
                        customer_phonenumber: true,
                      }));
                    }}
                    helperText={
                      !mobileValidation.customer_phonenumber &&
                      "Incorrect Phone Number"
                    }
                    required
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Aadhaar/Passport</FormLabel>
                    <RadioGroup
                      aria-label="Choose"
                      name="adharpassport"
                      value={adharPassport}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="customer_aadhar"
                        control={<Radio />}
                        label="Aadhaar Number"
                      />
                      <FormControlLabel
                        value="passport_number"
                        control={<Radio />}
                        label="Passport Number"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    className="input"
                    name="customer_aadhar"
                    type="text"
                    placeholder={
                      adharPassport === "customer_aadhar"
                        ? "Enter Aadhaar Number"
                        : "Enter Passport Number"
                    }
                    value={
                      adharPassport === "customer_aadhar"
                        ? customer_aadhar
                        : passport_number
                    }
                    variant="outlined"
                    style={{ margin: "0", width: "100%" }}
                    onChange={(e) =>
                      adharPassport === "customer_aadhar"
                        ? setCustomer_aadhar(e.target.value as String)
                        : setPassportNumber(e.target.value as String)
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} style={{ padding: "1rem" }}>
              <div className="priceDetails" style={{ padding: "1rem" }}>
                <table style={{ width: "100%", margin: "0" }}>
                  <thead>
                    <tr>
                      <td className="cell">Customer Name</td>
                      <td className="cell">Packages</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="cell">{customer_name}</td>
                      <td className="cell">
                        <ul>
                          {packageDetails && packageDetails?.length > 0 && packageDetails?.map((pack: any, index: any) => {
                            return (
                              <li key={index}>
                                {pack.name}
                                <br />
                                DESCRIPTION : {pack.description}
                                <br />
                                Report time:{" "}
                                {pack.package_center_address_prices
                                  ? pack.package_center_address_prices.tat_time
                                  : pack.tat_time}
                                <br />
                                Price:{" "}
                                {pack.package_center_address_prices
                                  ? pack.package_center_address_prices
                                      .offer_price
                                  : pack.offer_price}
                              </li>
                            );
                          })}
                        </ul>
                      </td>
                    </tr>
                    {booking.additional_members &&
                      booking.additional_members.map(
                        (member: any, index: any) => {
                          return (
                            <tr key={index}>
                              <td className="cell">{member.customer_name}</td>
                              <td className="cell">
                                <ul>
                                  {member.packages &&
                                    member.packages.map(
                                      (pack: any, index: any) => {
                                        return (
                                          <li key={index}>
                                            {pack.name}
                                            <br />
                                            DESCRIPTION : {pack.description}
                                            <br />
                                            Report time:{" "}
                                            {pack.package_center_address_prices
                                              ? pack
                                                  .package_center_address_prices
                                                  .tat_time
                                              : pack.tat_time}
                                            <br />
                                            Price:{" "}
                                            {pack.package_center_address_prices
                                              ? pack
                                                  .package_center_address_prices
                                                  .offer_price
                                              : pack.offer_price}
                                          </li>
                                        );
                                      }
                                    )}
                                </ul>
                              </td>
                            </tr>
                          );
                        }
                      )}
                  </tbody>
                </table>
              </div>
              <div
                className="priceDetails"
                style={{ padding: "1rem 1rem 0 1rem" }}
              >
                <h6 className="totalPrice">
                  Price: ₹ {Math.floor(price)} {extraCharge ? "+ 200" : ""}
                </h6>
                <h6 className="totalPrice">
                  RedCash Discount: ₹{" "}
                  {Math.floor(booking?.redcash_discounted_amount)}
                </h6>
                <h6 className="totalPrice">Discount: ₹ {discount}</h6>
                <h6 className="totalPrice">
                  Total Amount: ₹{" "}
                  {totalPrice - Math.floor(booking?.redcash_discounted_amount)}
                </h6>
                <Grid
                  container
                  spacing={1}
                  direction="row"
                  justify="center"
                  alignItems="center"
                  style={{ margin: "1rem auto" }}
                >
                  <Grid item xs={2}>
                    <p>Enter Coupon</p>
                  </Grid>
                  <Grid item xs={3}>
                    <Autocomplete
                      key={couponKey}
                      id="coupon"
                      onChange={(event, newValue) => {
                        if (newValue) {
                          let obj = JSON.parse(
                            JSON.stringify(newValue, null, " ")
                          );
                          setCoupon(obj.id as Number);
                        }
                      }}
                      defaultValue={
                        couponList.results &&
                        couponList.results.find((code: any) => {
                          return code.id === coupon;
                        })
                      }
                      inputValue={couponCode}
                      onInputChange={(event, newInputValue) => {
                        clearTimeout(timer.current);
                        setCouponCode(newInputValue);
                        timer.current = setTimeout(() => {
                          getCoupons(`?code=${newInputValue}&org_type=imaging`);
                        }, 1000);
                        if (newInputValue === "") {
                          setCoupon(0);
                          setDiscount(0);
                          // setremoveCoupn("true")
                        }
                      }}
                      options={couponList.results}
                      freeSolo
                      blurOnSelect
                      getOptionLabel={(option: any) => option.name}
                      getOptionDisabled={(option: any) =>
                        option.minimum_price > totalPrice
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Coupon"
                          variant="outlined"
                          style={{ width: "100%", margin: "0", padding: "0" }}
                          className="input"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Button
                      variant="contained"
                      fullWidth
                      color="primary"
                      onClick={applyCoupon}
                    >
                      Apply
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
            direction="row"
            justify="center"
            alignItems="center"
            style={{ margin: "1rem auto" }}
          >
            <Grid item xs={12}>
              <Grid item xs={12}>
                <h3
                  style={{
                    borderTop: "dotted 1px #cccc",
                    paddingTop: "10px",
                    fontSize: "24px",
                    fontWeight: "bold",
                  }}
                >
                  CENTER ADDRESS
                </h3>
              </Grid>
              <Grid container spacing={3} style={{ margin: "1rem auto" }}>
                <Grid item xs={12} md={3}>
                  <Grid item xs={12} style={{ padding: "0" }}>
                    <p>Center Address</p>
                  </Grid>
                  <Grid item xs={12} style={{ padding: "0" }}>
                    <TextField
                      className="input"
                      name="customer_address"
                      type="text"
                      placeholder="Enter address"
                      value={customer_address}
                      disabled
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Grid item xs={12} style={{ padding: "0" }}>
                    <p>Center Latitude</p>
                  </Grid>
                  <Grid item xs={12} style={{ padding: "0" }}>
                    <TextField
                      className="input"
                      name="customer_longitude"
                      type="text"
                      placeholder="Enter Longitude"
                      value={customer_longitude}
                      disabled
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Grid item xs={12} style={{ padding: "0" }}>
                    <p>Center Longitude</p>
                  </Grid>
                  <Grid item xs={12} style={{ padding: "0" }}>
                    <TextField
                      className="input"
                      name="customer_latitude"
                      type="text"
                      placeholder="Enter Longitude"
                      value={customer_latitude}
                      disabled
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <div className={classes.buttonContainer}>
            <Button
              color="primary"
              type="submit"
              variant="contained"
              disabled={loading || !selectedSlot || packages.length === 0}
            >
              Update booking
            </Button>
          </div>
        </form>
      </Paper>
    </main>
  );
};
const mapStateToProps = (state: any) => ({
  packageList: state.PhleboAdminReducer.packageList,
  couponList: state.PhleboAdminReducer.couponList,
  booking: state.PhleboAdminReducer.booking,
  booking_slots: state.PhleboAdminReducer.booking_slots,
  loading: state.PhleboAdminReducer.loading,
  cities: state.PhleboAdminReducer.cities,
  timeslots: state.PhleboAdminReducer.timeslots,
  timeslotsList: state.PhleboAdminReducer.timeslotsList,
  subCenter: state.PhleboAdminReducer.subCenter,
});

export default connect(mapStateToProps, {
  getPackage,
  updateBooking,
  getCoupons,
  getBookingById,
  getAvailableSlots,
  getCities,
  getTimeslots,
  getSubCenterInfo,
})(EditBookingForm);
