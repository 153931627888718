import React, { useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Loader from "../loader";
import { connect } from "react-redux";
import { Button, Paper, TextField, Grid, Switch, Select, MenuItem } from "@material-ui/core";
import { useEffect } from "react";
import { getLeadSource } from "../../actions/productMangerAction";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 0,
        },
        toolbar: {
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        },
        content: {
            width: "80%",
            flexGrow: 21,
        },
        paper: {
            padding: "1rem",
            width: "100%",
            borderRadius: "10px"
        },
        head: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "1rem",
            textAlign: "center"
        },
        heading: {
            fontWeight: "normal",
            fontSize: "1.2rem",
            margin: "0"
        },
        table: {
            maxHeight: "400px",
            margin: "auto",
            overflow: "auto"
        },
        image: {
            width: "100px",
            height: "auto",
        },
        imageSM: {
            width: "50px",
            height: "auto",
        },
        imgContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexWrap: "wrap"
        }
    })
);

interface Props {
    loading: any;
    getLeadSource: any;
    lead_source: any;
}

const BlogsPage: React.FC<Props> = ({
    loading,
    getLeadSource,
    lead_source,
}) => {
    const classes = useStyles();

    const [username, setUsername] = useState("")
    const [userRole, setUserRole] = useState("")
    const [mappedNo, setMappedNo] = useState("")
    const [mappedNoFb, setMappedNoFb] = useState("")
    const [mappedNoGoogle, setMappedNoGoogle] = useState("")
    const [isFbAd, setIsFbAd] = useState(false)
    const [isGoogleAd, setIsGoogleAd] = useState(false)
     const [isJdAd, setIsJdAd] = useState(false)
    const [callfrom, setcallfrom] = useState("none")

    useEffect(() => {
        getLeadSource()
    }, [])

    const submitHandler = () => {

    }

    return (
        <>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <Paper elevation={5} className={classes.paper}>
                    <h3 style={{ textAlign: "center" }}>Create User</h3>
                    {loading ? <Loader /> : (
                        <Grid container spacing={3} style={{ margin: "1rem auto" }}>
                            <Grid container item xs={12} md={6} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <p>Username</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <TextField className="input"
                                        name="title"
                                        type="text"
                                        placeholder="Enter Username"
                                        value={username}
                                        variant="outlined"
                                        style={{ margin: "0", width: "100%" }}
                                        onChange={(e) => setUsername(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={6} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <p>User Role</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <TextField className="input"
                                        name="title"
                                        type="text"
                                        placeholder="Enter User Role"
                                        value={userRole}
                                        variant="outlined"
                                        style={{ margin: "0", width: "100%" }}
                                        onChange={(e) => setUserRole(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={6} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <p>Mapped No</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <TextField className="input"
                                        name="title"
                                        type="text"
                                        placeholder="Enter Mapped No"
                                        value={mappedNo}
                                        variant="outlined"
                                        style={{ margin: "0", width: "100%" }}
                                        onChange={(e) => setMappedNo(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={6} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <p>Call From</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <Select className="input"
                                        name="callFrom"
                                        variant="outlined"
                                        value={callfrom}
                                        style={{ margin: "0", width: "100%" }}
                                        onChange={(e) => setcallfrom(e.target.value as string)}
                                    >
                                        <MenuItem value={"none"} disabled>Call from</MenuItem>
                                        {lead_source && lead_source.length > 0 && lead_source.map((item: any) => {
                                            return (
                                                <MenuItem key={item.id} value={item.name}>{item.name}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={6} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <p>Is Fb Ad</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <Switch
                                        checked={isFbAd}
                                        onChange={(e) => setIsFbAd(e.target.checked)}
                                        name="checkedA"
                                        color="secondary"
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={6} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <p>Is Google Ad</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <Switch
                                        checked={isGoogleAd}
                                        onChange={(e) => setIsGoogleAd(e.target.checked)}
                                        name="checkedA"
                                        color="secondary"
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={6} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <p>Mapped No Fb</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <TextField className="input"
                                        name="title"
                                        type="text"
                                        placeholder="Enter Mapped No Fb"
                                        value={mappedNoFb}
                                        variant="outlined"
                                        style={{ margin: "0", width: "100%" }}
                                        onChange={(e) => setMappedNoFb(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={6} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <p>Mapped No Google</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <TextField className="input"
                                        name="title"
                                        type="text"
                                        placeholder="Enter Mapped No Google"
                                        value={mappedNoGoogle}
                                        variant="outlined"
                                        style={{ margin: "0", width: "100%" }}
                                        onChange={(e) => setMappedNoGoogle(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                              <Grid container item xs={12} md={6} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <p>Is Jd Ad</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <Switch
                                        checked={isJdAd}
                                        onChange={(e) => setIsJdAd(e.target.checked)}
                                        name="checkedA"
                                        color="secondary"
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <Button
                                        color="secondary"
                                        variant="contained"
                                        fullWidth
                                        onClick={submitHandler}
                                    >
                                        Create User
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </Paper>
            </main>
        </>
    );
};


const mapStateToProps = (state: any) => ({
    loading: state.ProductManagerReducer.loading,
    lead_source: state.ProductManagerReducer.lead_source,
})

export default connect(mapStateToProps, {
    getLeadSource
})(BlogsPage);