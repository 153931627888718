import React, { useState } from "react";
import { useRef } from "react";
import {
  makeStyles,
  Theme,
  createStyles,
  withStyles,
} from "@material-ui/core/styles";
import { connect } from "react-redux";
import Button from "@mui/material/Button";
import {
  Paper,
  TextField,
  Grid,
  Dialog,
  DialogContent,
  Checkbox,
} from "@material-ui/core";

import { useEffect } from "react";
import {
  postRoutes,
  updateRoutes,
  // getRoutesListData,
  getStops,
  getCities,
  getRiderList,
  getPhlebos,
  getCenterInfo,
  getMapMyIndiaAccessToken,
  getRoutesListData,
  resetBookings,
  getRoutes
} from "../../actions/LogisticsPanelAction";
import { useHistory, useLocation, useParams } from "react-router-dom";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import Loader from "../../components/loader/index";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import StopCreateModal from "../Stops/createStopsModal";

import { TableFooter, TablePagination } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    table: {
      minWidth: 700,
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "100%",
      flexGrow: 21,
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      marginTop: "2rem",
      padding: "0.5rem 2rem 2rem 2rem",
      width: "100%",
    },
    contentHeader: {
      width: "100%",
      padding: "0px 10px 40px 10px",
      minHeight: "64px",
    },
    leftContentHeader: {
      width: "50%",
      float: "left",
    },
    rightContentHeader: {
      width: "50%",
      float: "right",
      textAlign: "right",
      paddingRight: "20px",
    },
    mapCSS: {
      height: "700px",
      width: "90%",
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    head: {
      textAlign: "center",
      fontWeight: "normal",
      marginBottom: "2rem",
    },
    slotContainer: {
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "center",
    },
    slot: {
      width: "150px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      margin: "0.5rem",
    },
    hoveItem: {
      cursor: "pointer",
    },
    dialog: {
      zIndex: 2,
    },
    selectedSlot: {
      width: "150px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      background: "linear-gradient(60deg, cyan, #00dfff, cyan)",
      cursor: "pointer",
      margin: "0.5rem",
    },
    chips: {
      display: "flex",
    },
    chip: {
      margin: 2,
      background: "linear-gradient(60deg, cyan, #00cfff, cyan)",
      maxWidth: "130px",
    },
    input: {
      padding: "1px",
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
  })
);

const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);
interface Props {
  getCities: any;
  cities: any;
  postRoutes: any;
  updateRoutes: any;
  // getRoutesListData: any;
  // routeListData: any;
  getStops: any;
  stopsData: any;
  getRiderList: any;
  riderList: any;
  getPhlebos: any;
  phleboList: any;
  getCenterInfo: any;
  centerInfo: any;
  loading: any;
  mapmyindiaAccessToken: any;
  getMapMyIndiaAccessToken: any;
  getRoutesListData: any;
  routeListData: any;
  resetBookings: any;
  getRoutes: any;
  routesData: any;
}
const RoutePlanAction: React.FC<Props> = ({
  getCities,
  cities,
  postRoutes,
  updateRoutes,
  // getRoutesListData,
  // routeListData,
  getStops,
  stopsData,
  getRiderList,
  riderList,
  getPhlebos,
  phleboList,
  getCenterInfo,
  centerInfo,
  mapmyindiaAccessToken,
  getMapMyIndiaAccessToken,
  getRoutesListData,
  routeListData,
  resetBookings,
  loading,
  getRoutes,
  routesData,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const [rider, setRider] = useState<any>("");
  const [riderName, setRiderName] = useState<any>("");
  const timer = useRef<any>(0);
  const [routeName, setRouteName] = React.useState<any>("");
  const [sourceName, setSourceName] = React.useState<any>("");
  const [sourceLatLng, setSourceLatLng] = React.useState<any>({
    lat: "",
    lng: "",
  });
  const [destinationName, setDestinationName] = useState<any>("");
  const [destinationLatLng, setDestinationLatLng] = useState<any>({
    lat: "",
    lng: "",
  });
  const [startTime, setStartTime] = React.useState<any>("08:00");
  const [endTime, setEndTime] = React.useState<any>("");
  const [city, setCity] = useState<any>("");
  const [cityName, setCityName] = useState<any>("");
  const [isEdit, setIsEdit] = useState<any>(false);
  const [editId, setEditId] = useState<any>("");
  const [center, setCentre] = useState<any>([]);
  const [filterStopData, setFilterStopData] = useState<any>([]);
  const [openLocationModal, setOpenLocationModal] = useState<boolean>(false);
  const [pts, setPts] = useState<any>([]);
  const [routeList, setRouteList] = useState<any>("");
  let mapData: any;

  const [stops, setStops] = useState<any>([
    {
      stop: "",
      // stopName: "",
      stop_start_time: "",
      stop_end_time: "",
      on_call: false,
      // lat: "",
      // lng: "",
      cc: "",
    },
  ]);


  const [tempPts, setTempPts] = useState<any>([]);
  const newPts = [sourceLatLng, destinationLatLng, ...tempPts];
  const [displayLatLand, setDisplayLatLng] = useState<any>([]);
  function addMinutesToTime(time: any, minsAdd: any) {
    function z(n: any) { return (n < 10 ? '0' : '') + n; };
    var bits = time.split(':');
    var mins = bits[0] * 60 + +bits[1] + +minsAdd;
    return z(mins % (24 * 60) / 60 | 0) + ':' + z(mins % 60);
  }
  useEffect(() => {
    const sortedData = newPts.filter(
      (data: any) => data.lat !== "" || data.lng !== ""
    );
    setDisplayLatLng(sortedData);
  }, [stops, sourceLatLng, destinationLatLng]);

  // const [tempStopModalAddress,setTempStopModalAddress]=useState<any>(
  //   []
  // )
  const [page, setPage] = useState(0);

  useEffect(() => {
    // resetBookings();
    getCities();
    getStops();
    getRiderList(`?usergroup=Rider`);
    getPhlebos();
    getCenterInfo();
    getMapMyIndiaAccessToken();
    getRoutes();
    // getRoutesListData();
    // getRoutesListData();
  }, []);

  useEffect(() => {
    if (location?.state?.action !== "editA" && routeList) {
      getRoutesListData(`${routeList}`);
      // resetBookings();
      // resetBookings();
    } else if (Number(location?.state?.id) > 0 && location?.state?.action === "editA") {
      getRoutesListData(`${location?.state?.id}`);
    } else {
      // resetBookings();
    }
  }, [location?.state?.id, routeList]);

  console.log('routeListData', routeListData)

  useEffect(() => {
    if (location?.state?.action === "editA" && routeListData?.id === location?.state?.id) {
      handleEdit(routeListData);
    }
  }, [routeListData]);

  useEffect(() => {
    if (routeListData?.id === routeList) {
      handleCreate(routeListData);
    }
  }, [routeList, routeListData]);

  const handleSave = async (e: any) => {
    e.preventDefault();
    let newStops = stops;
    newStops?.length > 0 &&
      newStops.map((val: any, index: any) => {
        if (val?.on_call) {
          newStops[index]["on_call"] = "on_call";
        }
        if (val?.on_call === false) {
          newStops[index]["on_call"] = "route_decided";
        }
        if (val?.cc?.id) {
          newStops[index]["cc"] = val.cc.id;
        }
      });
    const data = {
      city: city,
      name: routeName,
      rider: rider,
      source: sourceName,
      destination: destinationName,
      start_time: startTime.length === 5 ? startTime + ":00" : startTime,
      end_time: endTime.length === 5 ? endTime + ":00" : endTime,
      stops: newStops,
    };
    if (rider === "") {
      delete data.rider;
    }
    if (isEdit) {
      await updateRoutes(data, editId);
    } else {
      await postRoutes(data);
    }
    history.push(`/dashboard/lp/route_plan`);
  };

  const handleCreate = (val: any) => {
    const tempEditvalue = new Array();
    val?.stops_detail?.map((data: any) => {
      return tempEditvalue.push({
        id: data?.id,
        route: data?.route,
        stop: data?.stop,
        stopName: data?.name,
        stop_start_time: data?.time ? data?.time : "",
        stop_end_time: data?.end_time ? data?.end_time : "",
        on_call: data?.oncall === "route_decided" ? false : true,
        cc: data.cc,
      });
    });
    setEditId(val.id);
    setCity(val?.city);
    setCityName(val?.city_name);
    setRouteName(val?.name);
    setSourceName(val?.source);
    setDestinationName(val?.destination);
    setStartTime(val?.start_time);
    setEndTime(val?.end_time);
    setStops(tempEditvalue || []);
    setRiderName(val?.rider?.name);
    // setPhlebo(val?.stops_detail[0]?.panel_users[0]?.user);
    setCentre(val?.stops_detail?.cc?.name)
    setIsEdit(false);
    setRider(val?.rider?.id);
  };
  const handleEdit = (val: any) => {
    const tempEditvalue = new Array();
    val?.stops_detail?.map((data: any) => {
      return tempEditvalue.push({
        id: data?.id,
        route: data?.route,
        stop: data?.stop,
        stopName: data?.name,
        stop_start_time: data?.time ? data?.time : "",
        stop_end_time: data?.end_time ? data?.end_time : "",
        on_call: data?.oncall === "route_decided" ? false : true,
        cc: data?.cc,

      });
    });
    setEditId(val.id);
    setCity(val?.city);
    setCityName(val?.city_name);
    setRouteName(val?.name);
    setSourceName(val?.source);
    setDestinationName(val?.destination);
    setStartTime(val?.start_time);
    setEndTime(val?.end_time);
    setRiderName(val?.rider?.name);
    // setPhlebo(val?.stops_detail[0]?.panel_users[0]?.user);
    setCentre(val?.stops_detail?.cc?.name)
    setIsEdit(true);
    setStops(tempEditvalue);
    setRider(val?.rider?.id);
  };
  const handleRemoveClick = (index: any) => {
    const list = [...stops];
    list.splice(index, 1);
    setStops(list);
  };
  const handleAddClick = () => {
    setStops([
      ...stops,
      {
        stop: "",
        stopName: "",
        stop_start_time: "",
        stop_end_time: "",
        on_call: false,
        cc: "",
      },
    ]);
  };
  const handleStops = (index: any, val: any, key: any) => {
    let list = [...stops];
    if (key === "on_call") {
      if (val) {
        list[index]["on_call"] = true;
      } else {
        list[index]["on_call"] = false;
      }
    }
    if (key === "stop") {
      const localTemp = [...tempPts];
      localTemp.push({ lat: val.latitued, lng: val.longitude });
      setTempPts(localTemp);

      list[index][key] = val?.id;
    } else if (key === "stop_start_time") {
      list[index][key] = val;
      // console.log( moment(val).add(5,"m")," ---")
    } else if (key === "stop_end_time") {
      list[index][key] = val;
    } else if (key === "cc" && val !== undefined) {
      list[index]["cc"] = val;
    } else if (key === "cc" && val === undefined) {
      delete list[index]["cc"];
    } else if (key === "panel_users") {
      list[index]["panel_users"] = val;
    }
    setStops(list);
  };

  const asin = Math.asin;
  const cos = Math.cos;
  const sin = Math.sin;
  const PI_180 = Math.PI / 180;
  function hav(x: any) {
    const s = sin(x / 2);
    return s * s;
  }
  function relativeHaversineDistance(
    lat1: any,
    lon1: any,
    lat2: any,
    lon2: any
  ) {
    const aLatRad = lat1 * PI_180;
    const bLatRad = lat2 * PI_180;
    const aLngRad = lon1 * PI_180;
    const bLngRad = lon2 * PI_180;

    const ht =
      hav(bLatRad - aLatRad) +
      cos(aLatRad) * cos(bLatRad) * hav(bLngRad - aLngRad);
    // since we're only interested in relative differences,
    // there is no need to multiply by earth radius or to sqrt the squared differences
    return asin(ht);
  }
  const [distanceTo, setDistanceTo] = useState<any>({
    lng: 77.3801,
    lat: 28.6301,
  });

  const mapMarketDisplay = () => {
    const sortedRoot = displayLatLand.sort(
      (a: any, b: any) =>
        relativeHaversineDistance(
          a.lat,
          a.lng,
          distanceTo.lat,
          distanceTo.lng
        ) -
        relativeHaversineDistance(b.lat, b.lng, distanceTo.lat, distanceTo.lng)
    );
    window["MapmyIndia"].Polyline({
      map: mapData,
      // editable:JSON.parse(edit),
      paths: eval(sortedRoot && sortedRoot.length > 0 && sortedRoot),
      strokeColor: "#3f51b5",
      strokeOpacity: 1.0,
      zoomControl: true,
      location: true,
      strokeWeight: 6,
      fitbounds: true,
      lineGap: 0,
      fitboundOptions: { padding: 120, duration: 1000 },
      popupHtml: "route 1",
      popupOptions: { offset: { bottom: [0, -20] } },
    });
  };

  useEffect(() => {
    if (stopsData && stopsData.results && stopsData.results.length > 0) {
      const filterNullLat = stopsData.results.filter((data: any) => {
        return data.latitued !== null && data.longitude !== null;
      });
      setFilterStopData(filterNullLat);
    }
  }, [stopsData]);


  return (
    <Grid container style={{ marginTop: 50, width: "100%" }}>
      <Grid item xs={12}>
        <Grid container style={{ marginTop: 6 }}>
          <Grid item xs={12} sm={6} md={5}>
            <h2>Route Plan</h2>
          </Grid>
          <Grid item xs={12} sm={6} md={5}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => setOpenLocationModal(true)}
            >
              Create Stop
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <Autocomplete
              disabled={location?.state?.action === "editA"}
              id="city"
              onChange={(event, newValue) => {
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  setRouteList(obj?.id)
                }
              }}
              options={routesData?.results || []}
              freeSolo
              blurOnSelect
              aria-required
              getOptionLabel={(option: any) => option.name}
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current)
                timer.current = setTimeout(() => {
                  getRoutes(`?name=${newInputValue}`)
                }, 1000)
                if (newInputValue.length === 0) {
                  setRouteList("")
                }
              }}
              renderInput={(params) => (
                <TextField className="input"
                  {...params}
                  label="Route List Name"
                  placeholder="Select Route List Name"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>
        </Grid>
        <form style={{ width: "100%", marginTop: 6 }} onSubmit={handleSave}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                label="Route Name"
                variant="outlined"
                className="input"
                fullWidth
                required
                value={routeName}
                onChange={(e) => setRouteName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Autocomplete
                id="source"
                onChange={(event, newValue) => {
                  if (newValue) {
                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                    setSourceName(obj?.name);
                    setSourceLatLng({
                      lat: obj?.latitued,
                      lng: obj?.longitude,
                    });
                    setDistanceTo({
                      lat: obj?.latitued,
                      lng: obj?.longitude,
                    });
                  }
                }}
                blurOnSelect
                aria-required
                inputValue={String(sourceName)}
                options={filterStopData ? filterStopData : []}
                disableClearable
                disableCloseOnSelect
                getOptionLabel={(option: any) => `${String(option?.name)}`}
                onInputChange={(event, newInputValue) => {
                  setSourceName(newInputValue);
                  clearTimeout(timer.current);
                  timer.current = setTimeout(() => {
                    getStops(`search=${newInputValue}`);
                  }, 1000);
                  if (newInputValue.length === 0) {
                    setSourceName("");
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="input"
                    size="small"
                    variant="outlined"
                    label="Route Start Point"
                    fullWidth
                    inputProps={{
                      ...params.inputProps,
                      onKeyDown: (e) => {
                        if (e.key === "Enter") {
                          e.stopPropagation();
                        }
                      },
                    }}
                    required={filterStopData.length === 0 ? true : false}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                label="Start Time"
                variant="outlined"
                className="input"
                type="time"
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                required
                value={startTime}
                onChange={(e) => setStartTime(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Autocomplete
                id="sourceendname"
                onChange={(event, newValue) => {
                  if (newValue) {
                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                    setDestinationName(obj?.name);
                    setDestinationLatLng({
                      lat: obj?.latitued,
                      lng: obj?.longitude,
                    });
                  }
                }}
                blurOnSelect
                aria-required
                inputValue={String(destinationName)}
                options={filterStopData ? filterStopData : []}
                disableClearable
                disableCloseOnSelect
                getOptionLabel={(option: any) => `${String(option?.name)}`}
                onInputChange={(event, newInputValue) => {
                  setDestinationName(newInputValue);
                  clearTimeout(timer.current);
                  timer.current = setTimeout(() => {
                    getStops(`search=${newInputValue}`);
                  }, 1000);
                  if (newInputValue.length === 0) {
                    setDestinationName("");
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="input"
                    size="small"
                    label="Route End Point"
                    variant="outlined"
                    fullWidth
                    inputProps={{
                      ...params.inputProps,
                      onKeyDown: (e) => {
                        if (e.key === "Enter") {
                          e.stopPropagation();
                        }
                      },
                    }}
                    required
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                label="End Time"
                variant="outlined"
                className="input"
                type="time"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{ min: startTime }}
                fullWidth
                required
                value={endTime}
                onChange={(e) => setEndTime(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Autocomplete
                id="city"
                onChange={(event, newValue) => {
                  if (newValue) {
                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                    setCity(obj.id);
                  }
                }}
                inputValue={cityName}
                loading={loading}
                options={cities || []}
                onInputChange={(event, newInputValue) => {
                  clearTimeout(timer.current);
                  timer.current = setTimeout(() => {
                    getCities(newInputValue);
                  }, 1000);

                  setCityName(newInputValue);
                  // if (newInputValue === "") {
                  //   setCity("");
                  // }
                }}
                blurOnSelect
                aria-required
                getOptionLabel={(option: any) => `${String(option?.name)}`}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="input"
                    label="City"
                    variant="outlined"
                    fullWidth
                    inputProps={{
                      ...params.inputProps,
                      onKeyDown: (e) => {
                        if (e.key === "Enter") {
                          e.stopPropagation();
                        }
                      },
                    }}
                    required
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Autocomplete
                id="rider"
                onChange={(event, newValue) => {
                  if (newValue) {
                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                    setRider(obj.id);
                  }
                }}
                inputValue={riderName || ""}
                options={riderList?.results || []}
                blurOnSelect
                freeSolo
                getOptionLabel={(option: any) =>
                  option?.user && option?.user?.name
                }
                onInputChange={(event, newInputValue, reason) => {
                  setRiderName(newInputValue);
                  clearTimeout(timer.current);
                  timer.current = setTimeout(() => {
                    getRiderList(
                      `?usergroup=Rider&code=${newInputValue}`
                    );
                  }, 1000);
                  if (reason === 'clear') {
                    setRider("")
                  }

                }}
                renderInput={(params) => (
                  <TextField
                    className="input"
                    {...params}
                    placeholder="Assign Rider"
                    label="Select Rider"
                    variant="outlined"
                    style={{ width: "100%", margin: "0", padding: "0" }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <div style={{ width: "100%" }} className="data-table">
                <TableContainer
                  component={Paper}
                  style={{ maxHeight: "540px" }}
                >
                  <Table stickyHeader aria-label="simple table">
                    <TableHead>
                      <StyledTableRow>
                        <StyledTableCell align="center">
                          Add Stops
                        </StyledTableCell>
                        <StyledTableCell align="center">S. No.</StyledTableCell>
                        <StyledTableCell align="center">
                          Stop Point Address{" "}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Stop Start Time
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Stop End Time
                        </StyledTableCell>
                        <StyledTableCell align="center">Centre</StyledTableCell>
                        <StyledTableCell align="center">
                          On Call
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableHead>
                    <TableBody>
                      {stops.map((row: any, index: number) => {
                        return (
                          <StyledTableRow key={index}>
                            <StyledTableCell align="center">
                              {stops.length !== 1 && (
                                <HighlightOffIcon
                                  style={{
                                    height: "30px",
                                    width: "30px",
                                    borderRadius: "50%",
                                  }}
                                  onClick={() => handleRemoveClick(index)}
                                />
                              )}
                              {stops.length - 1 === index && (
                                <AddCircleIcon
                                  style={{
                                    height: "30px",
                                    width: "30px",
                                    borderRadius: "50%",
                                  }}
                                  onClick={handleAddClick}
                                />
                              )}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {index + 1}
                            </StyledTableCell>

                            <StyledTableCell align="center">
                              <Autocomplete
                                id="center"
                                key={String(isEdit) + String(row?.stopName)}
                                onChange={(event, newValue) => {
                                  if (newValue) {
                                    let obj = JSON.parse(
                                      JSON.stringify(newValue, null, " ")
                                    );
                                    setStops(obj.name);
                                    handleStops(index, obj, "stop");
                                    const tempPts = [...pts];
                                    tempPts.push({
                                      lat: obj?.latitued,
                                      lng: obj?.longitude,
                                    });
                                  }
                                }}
                                blurOnSelect
                                options={stopsData?.results || []}
                                disableClearable
                                disableCloseOnSelect
                                getOptionLabel={(option: any) =>
                                  (option?.name)
                                }
                                onInputChange={(event, newInputValue) => {
                                  setTimeout(
                                    getStops(
                                      `search=${newInputValue}`
                                    ),
                                    1000
                                  );
                                  if (newInputValue.length === 0) {
                                    handleStops(index, undefined, "stops");
                                  }
                                }}
                                defaultValue={
                                  row?.stopName
                                    ? { name: String(row?.stopName) }
                                    : { name: "" }
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    className="input"
                                    label="Stop Name"
                                    variant="outlined"
                                    required
                                    inputProps={{
                                      ...params.inputProps,
                                      onKeyDown: (e) => {
                                        if (e.key === "Enter") {
                                          e.stopPropagation();
                                        }
                                      },
                                    }}
                                    fullWidth
                                  />
                                )}
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <TextField
                                label="Start Time"
                                variant="outlined"
                                className="input"
                                type="time"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                inputProps={{
                                  min: startTime + ":00",
                                  max: endTime + ":00",
                                }}
                                fullWidth
                                required
                                value={row?.stop_start_time}
                                onChange={(e) => {
                                  handleStops(
                                    index,
                                    e.target.value + ":00",
                                    "stop_start_time"
                                  )

                                  handleStops(
                                    index,
                                    addMinutesToTime(e.target.value, 5) + ":00",
                                    "stop_end_time"
                                  )
                                }
                                }
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <TextField
                                variant="outlined"
                                className="input"
                                type="time"
                                size="small"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                inputProps={{
                                  min: startTime + ":00",
                                  max: endTime + ":00",
                                }}
                                fullWidth
                                value={row?.stop_end_time}
                                onChange={(e) =>
                                  handleStops(
                                    index,
                                    e.target.value + ":00",
                                    "stop_end_time"
                                  )
                                }
                                required
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Autocomplete
                                id="center"
                                key={String(isEdit) + String(row?.cc?.name)}
                                onChange={(event, newValue) => {
                                  if (newValue) {
                                    let obj = JSON.parse(
                                      JSON.stringify(newValue, null, " ")
                                    );
                                    setCentre(obj.id);
                                    handleStops(
                                      index,
                                      { name: obj.name, id: obj.id },
                                      "cc"
                                    );
                                  }
                                }}
                                blurOnSelect
                                freeSolo
                                options={centerInfo?.results || []}
                                disableClearable
                                disableCloseOnSelect
                                getOptionLabel={(option: any) =>
                                  (option?.display_name
                                    ? String(option?.display_name)
                                    : String(option?.name)) +
                                  (option?.center_code
                                    ? "(" + String(option?.center_code) + ")"
                                    : "")
                                }
                                onInputChange={(event, newInputValue) => {
                                  setTimeout(
                                    getCenterInfo(
                                      `?name_or_code=${newInputValue}`
                                    ),
                                    1000
                                  );
                                  if (newInputValue.length === 0) {
                                    handleStops(index, undefined, "cc");
                                  }
                                }}
                                defaultValue={
                                  row?.cc?.name
                                    ? { display_name: String(row?.cc?.name) }
                                    : { name: "" }
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    className="input"
                                    label="Select Centre"
                                    variant="outlined"
                                    inputProps={{
                                      ...params.inputProps,
                                      onKeyDown: (e) => {
                                        if (e.key === "Enter") {
                                          e.stopPropagation();
                                        }
                                      },
                                    }}
                                    fullWidth
                                  />
                                )}
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Checkbox
                                checked={row.on_call}
                                onChange={(event: any) =>
                                  handleStops(
                                    index,
                                    event.target.checked,
                                    "on_call"
                                  )
                                }
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <Button
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
              >
                {isEdit ? "update" : "Create"}
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <Button
                color="secondary"
                variant="contained"
                fullWidth
                onClick={() =>
                  history.push(`/dashboard/lp/route_plan_action/create`)
                }
              >
                reset
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
      {/* <Grid item xs={12} sm={6} md={4}>
        <div>
          <div id="tempMap" className={classes.mapCSS}></div>
        </div>
      </Grid> */}
      <Dialog
        open={openLocationModal}
        onClose={() => setOpenLocationModal(false)}
        aria-labelledby="form-dialog-title"
        className={classes.dialog}
      >
        <DialogContent>
          <StopCreateModal setOpenLocationModal={setOpenLocationModal} />
        </DialogContent>
      </Dialog>
    </Grid>
  );
};
const mapStateToProps = (state: any) => ({
  cities: state.LogisticsReducer.cities,
  // routeListData: state.LogisticsReducer.routeListData,
  stopsData: state.LogisticsReducer.stopsData,
  riderList: state.LogisticsReducer.riderList,
  phleboList: state.LogisticsReducer.phleboList,
  centerInfo: state.LogisticsReducer.centerInfo,
  mapmyindiaAccessToken: state.LogisticsReducer.mapmyindiaAccessToken,
  routeListData: state.LogisticsReducer.routeListData,
  routesData: state.LogisticsReducer.routesData,
  loading: state.LogisticsReducer.loading,
});
export default connect(mapStateToProps, {
  getRoutesListData,
  postRoutes,
  updateRoutes,
  getCities,
  getStops,
  getRiderList,
  getPhlebos,
  getCenterInfo,
  getMapMyIndiaAccessToken,
  // getRoutesListData,
  resetBookings,
  getRoutes
})(RoutePlanAction);
