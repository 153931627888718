export const arr = [
  {
    role: "PhleboAdmin",
    url: "pha",
  },
  {
    role: "RouteManager",
    url: "rm",
  },
  {
    role: "ProductManager",
    url: "pm",
  },
  {
    role: "Logistics",
    url: "lp",
  },
  {
    role: "EditPackageMaster",
    url: "epm/packagemanagement",
  },
  {
    role: "BulkUploader",
    url: "uploader",
  }]