import React, { useState, useEffect, useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Grid, TextField, MenuItem, Select } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  createPackageManagement,
  getTagsList,
  getPackageManagement,
  getPackageCategory,
  getPackageTag,
  getTestsCrm
} from "../actions/productMangerAction";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router";
import { connect, useDispatch } from "react-redux";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
    },
    contentHeader: {
      width: "100%",
      padding: "0px 10px 40px 10px",
    },
    leftContentHeader: {
      width: "50%",
      float: "left",
    },
    rightContentHeader: {
      width: "50%",
      float: "right",
      textAlign: "right",
      paddingRight: "20px",
    },
    contentTable: {
      width: "100%",
      padding: "20px 10px 10px 10px",
      background: "#ffffff",
      margin: "10px",
      boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
    },
    paper: {
      marginTop: "2rem",
      width: "100%",
    },
    input: {
      padding: "1px",
    },
  })
);

interface Props {
  match: any;
  createPackageManagement: any;
  packagecreate: any;
  getTagsList: any;
  tagslist: any;
  getPackageManagement: any;
  packageManagement: any;
  getPackageCategory: any;
  packagecategory: any;
  getPackageTag: any;
  packageTags: any;
  getTestsCrm:any;
  testsCrm:any;

}

const PMCreatePackage: React.FC<Props> = ({
  createPackageManagement,
  packagecreate,
  match,
  getTagsList,
  tagslist,
  getPackageManagement,
  packageManagement,
  getPackageCategory,
  packagecategory,
  getPackageTag,
  packageTags,
  getTestsCrm,
  testsCrm
}) => {
  const classes = useStyles();
  const history = useHistory();
  const timer = useRef<any>(0);
  const [packagePrice, setPackagePrice] = useState<string>("");
  const [offerPrice, setOfferPrice] = useState<any>("");
  const [addonPrice, setAddonPrice] = useState<string>("");
  const [isaddon, setIsAddon] = useState<boolean>(true);
  const [name, setName] = useState<string>("");
  const [code, setCode] = useState<any>("");
  const [discount, setDiscount] = useState<any>(0);
  const [parameters, setParameters] = useState<string>("");
  const [fasting, setFasting] = useState<any>("");
  const [age, setAge] = useState<any>("");
  const [patients, setPatients] = useState<string>("");
  const [platforms, setPlatforms] = useState<any>("");
  const [plateformType, setPlateform] = useState<string>("");
  const [orgType, setOrgType] = useState<string>("");
  const [tags, setTags] = useState<any>([]);
  const [citywiseTat, setCitywiseTat] = useState<string>("");
  const [categories, setCategories] = useState<any>("");
  const [gender, setGender] = useState<string>("");
  const [tests, setTests] = useState<any>("");
  const [numberofpatients, setNumberOfPatients] = useState<any>("");
  const [testType, setTestType] = useState<any>("");
  const [testsDef, setTestsDef] = useState<any>([]);
  const packageId = match.params.id;
  // const timer = useRef<any>(0);

  useEffect(() => {
    getTagsList();
    getPackageCategory();
    getPackageTag();
    getTestsCrm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onKeyDown = (e: any) => {
    // if (e.key === "Enter") {
    //     filterSampleHandover(e);
    // }
  };

  const submitForm = async (e: any) => {
    e.preventDefault();
    const body: any = {
      name: name,
      discount: discount,
      code: code,
      package_price: String(packagePrice),
      offer_price: String(offerPrice),
      addon_price: addonPrice,
      parameter: Number(parameters),
      fasting_time: fasting,
      age: age,
      patient: patients,
      platforms: platforms,
      tat_time: citywiseTat,
      category_for_web: categories,
      org_type: orgType,
      recommended_gender: gender,
      no_patients: numberofpatients,
      tags: tags,
      ptype: plateformType,
      is_addon: isaddon,
      package_or_test: testType,
      test: tests,
      // website_display: true,
    };
    await createPackageManagement(body);
    getPackageManagement();
    history.push(`/dashboard/pm/packagemanagement`);
    // handleClose()
  };
  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <div className={classes.contentHeader}>
        <div className={classes.leftContentHeader}>
          <h2>Create Package</h2>
        </div>
        <div className={classes.rightContentHeader}></div>
      </div>
      <div className={classes.contentTable}>
        <form onSubmit={submitForm}>
          <Grid container spacing={3} style={{ margin: "1rem auto" }}>
            <Grid container item xs={12} md={12} spacing={2}>
            <Grid item xs={12} sm={5} md={3}>
            <h6>Type</h6>
              <Select
               className="input"
                // fullWidth
                placeholder="select type"
                required
                variant="outlined"
                value={testType}
                style={{width: "100%", height: "40px"}}
                onChange={(e) => setTestType(e.target.value)}
              >
                <MenuItem value="package">Package</MenuItem>
                <MenuItem value="test">Test</MenuItem>
              </Select>
            </Grid>
            {testType === "package" ? (
              <Grid item xs={12} sm={5} md={3}>
                <h6>Tests</h6>
                <Autocomplete
                  id="tests"
                  className="input"
                  key={packageId}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      let packs: any = [];
                      newValue.map((val: any) => {
                        let obj = JSON.parse(JSON.stringify(val, null, " "));
                        packs.push(obj.id);
                      });
                      setTests(packs);
                    }
                  }}
                  freeSolo
                  multiple
                  limitTags={1}
                  
                  options={testsCrm?.results || []}
                  onInputChange={(event, newInputValue) => {
                    clearTimeout(timer.current);
                    timer.current = setTimeout(() => {
                      getTestsCrm(`code=${newInputValue}`);
                    }, 1000);
                  }}
                  getOptionDisabled={(option: any) => {
                    return tests.includes(option.id);
                  }}
                  defaultValue={testsDef}
                  getOptionLabel={(option: any) => `${option?.name}`}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      fullWidth
                      required={tests.length === 0 ? true : false}
                    />
                  )}
                />
              </Grid>
            ) : (
              ""
            )}
              <Grid item xs={12} sm={5} md={3}>
                <h6>Package Name</h6>
                <TextField
                  className="input"
                  name="name"
                  type="text"
                  value={name}
                  variant="outlined"
                  onChange={(e) => setName(e.target.value as string)}
                  style={{ width: "100%" }}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={5} md={3}>
                <h6>Package Code</h6>
                <TextField
                  className="input"
                  name="name"
                  type="text"
                  value={code}
                  variant="outlined"
                  onChange={(e) => setCode(e.target.value as string)}
                  style={{ width: "100%" }}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={5} md={3}>
                <h6>Package Price</h6>
                <TextField
                  className="input"
                  name="name"
                  type="number"
                  value={packagePrice}
                  variant="outlined"
                  onChange={(e) => setPackagePrice(e.target.value)}
                  style={{ width: "100%" }}
                  required
                />
              </Grid>
              {/* <Grid item xs={12} sm={5} md={3}>
              <h6>Package Price</h6>
              <TextField
                className="input"
                name="name"
                type="number"
                value={packagePrice}
                variant="outlined"
                onChange={(e) =>  setPackagePrice(e.target.value)}
                style={{ width: "100%" }}
              />
            </Grid> */}
              <Grid item xs={12} sm={5} md={3}>
                <h6>Offer Price</h6>
                <TextField
                  className="input"
                  name="name"
                  type="number"
                  value={offerPrice}
                  variant="outlined"
                  onChange={(e) => setOfferPrice(e.target.value)}
                  style={{ width: "100%" }}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={5} md={3}>
                <h6>Addon Price</h6>
                <TextField
                  className="input"
                  name="name"
                  type="number"
                  value={addonPrice}
                  variant="outlined"
                  onChange={(e) => setAddonPrice(e.target.value)}
                  style={{ width: "100%" }}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={5} md={3}>
                <h6>Parameter</h6>
                <TextField
                  className="input"
                  name="name"
                  type="text"
                  value={parameters}
                  variant="outlined"
                  onChange={(e) => setParameters(e.target.value)}
                  style={{ width: "100%" }}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={5} md={3}>
                <h6>Fasting Requirement</h6>
                <TextField
                  className="input"
                  name="name"
                  type="text"
                  value={fasting}
                  variant="outlined"
                  onChange={(e) => setFasting(e.target.value as string)}
                  style={{ width: "100%" }}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={5} md={3}>
                <h6>No. of Patients Required</h6>
                <TextField
                  className="input"
                  name="name"
                  type="number"
                  value={numberofpatients}
                  variant="outlined"
                  onChange={(e) => setNumberOfPatients(e.target.value as string)}
                  style={{ width: "100%" }}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={5} md={3}>
                <h6>Age Requirement</h6>
                <TextField
                  className="input"
                  name="name"
                  type="text"
                  value={age}
                  variant="outlined"
                  onChange={(e) => setAge(e.target.value as string)}
                  style={{ width: "100%" }}
                  required
                />
              </Grid>
              {/* <Grid item xs={12} sm={5} md={3}>
                <h6>No of Patients Required</h6>
                <TextField
                  className="input"
                  name="name"
                  type="text"
                  value={patients}
                  variant="outlined"
                  onChange={(e) => setPatients(e.target.value as string)}
                  style={{ width: "100%" }}
                  required

                />
              </Grid> */}
              {/* <Grid item xs={12} sm={5} md={3}>
                <h6>Platforms to be made live</h6>
                <TextField
                  className="input"
                  name="name"
                  type="text"
                  value={platforms}
                  variant="outlined"
                  onChange={(e) => setPlatforms(e.target.value as string)}
                  style={{ width: "100%" }}
                  required

                />
              </Grid> */}
              <Grid item xs={12} sm={5} md={3}>
                <h6>Org Type</h6>
                <Select
                  className="input"
                  name="name"
                  type="text"
                  value={orgType}
                  variant="outlined"
                  onChange={(e) => setOrgType(e.target.value as string)}
                  style={{ width: "100%", height: "40px" }}
                  required
                >
                  <MenuItem value={"none"} disabled>
                    Org Type
                  </MenuItem>
                  <MenuItem value={"homedx"}>Homedx</MenuItem>
                  <MenuItem value={"imaging"}>Imaging</MenuItem>
                  <MenuItem value={"genomics"}>Genomics</MenuItem>
                  <MenuItem value={"collection_center"}>
                    Collection Center
                  </MenuItem>
                  <MenuItem value={"REDO"}>REDO</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} sm={5} md={3}>
                <h6>Citywise TAT</h6>
                <TextField
                  className="input"
                  name="name"
                  type="text"
                  value={citywiseTat}
                  variant="outlined"
                  onChange={(e) => setCitywiseTat(e.target.value as string)}
                  style={{ width: "100%" }}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={5} md={3}>
                <h6>Categories</h6>
                <Autocomplete
                  id="categories"
                  onChange={(event, newValue) => {
                    let sourceIds: any = [];
                    if (newValue) {
                      let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                      sourceIds = obj.map((item: any) => item.id);
                    }
                    setCategories(sourceIds);
                  }}
                  options={packagecategory?.results || []}
                  freeSolo
                  blurOnSelect
                  aria-required
                  limitTags={1}
                  multiple
                  getOptionLabel={(option: any) => option?.name}
                  getOptionDisabled={(option: any) => {
                    return categories.includes(option);
                  }}
                  disableClearable
                  disableCloseOnSelect
                  onInputChange={(event, newInputValue) => {
                    clearTimeout(timer.current);
                    timer.current = setTimeout(() => {
                      getPackageCategory(`?name=${newInputValue}`);
                    }, 1000);
                    if (newInputValue.length === 0) {
                      setCategories([]);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      className="input"
                      {...params}
                      // required
                      variant="outlined"
                      style={{ width: "100%", margin: "0", padding: "0" }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={5} md={3}>
                <h6>Gender</h6>
                <Select
                  className="input"
                  name="status"
                  variant="outlined"
                  required
                  value={gender}
                  style={{ width: "100%",height: "40px" }}
                  onChange={(e) => setGender(e.target.value as string)}
                >
                  <MenuItem disabled value={"none"}>
                    Gender
                  </MenuItem>
                  <MenuItem value={"male"}>Male</MenuItem>
                  <MenuItem value={"female"}>Female</MenuItem>
                  <MenuItem value={"both"}>Both</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} sm={5} md={3}>
                <h6>Tags</h6>
                <Autocomplete
                  id="tags"
                  onChange={(event, newValue) => {
                    let sourceIds: any = [];
                    if (newValue) {
                      let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                      sourceIds.push(obj.id);
                    }
                    setTags(sourceIds);
                  }}
                  options={tagslist?.results || []}
                  freeSolo
                  blurOnSelect
                  aria-required
                  limitTags={1}
                  getOptionLabel={(option: any) => option?.tags}
                  getOptionDisabled={(option: any) => {
                    return tags?.includes(option?.id);
                  }}
                  disableClearable
                  disableCloseOnSelect
                  onInputChange={(event, newInputValue) => {
                    clearTimeout(timer?.current);
                    timer.current = setTimeout(() => {
                      getTagsList(`${newInputValue}`);
                    }, 1000);
                    if (newInputValue?.length === 0) {
                      setTags("");
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      className="input"
                      {...params}
                      required
                      variant="outlined"
                      style={{ width: "100%", margin: "0", padding: "0" }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={5} md={3}>
                <h6>Platform Type</h6>
                <Select
                  className="input"
                  name="status"
                  variant="outlined"
                  required
                  value={plateformType}
                  style={{ width: "100%",height: "40px" }}
                  onChange={(e) => setPlateform(e.target.value as string)}
                >
                  <MenuItem value={"sales"}>Sales</MenuItem>
                  <MenuItem value={"coorporate"}>Coorporate</MenuItem>
                  <MenuItem value={"camp"}>Camp</MenuItem>
                  <MenuItem value={"all"}>All</MenuItem>
                </Select>
              </Grid>
            </Grid>
          </Grid>
          <div className={classes.buttonContainer}>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              style={{ marginLeft: "1rem", marginBottom: "20px" }}
            >
              Submit
            </Button>
          </div>
        </form>
      </div>
      {/* <div className={classes.contentTable}> */}
      {/* <FamilyPackageBookingList
          familyPackageBookingList={props.familyPackageBookingList}
          getFamilyPackageBooking={props.getFamilyPackageBooking}
          loading={props.loading}
        /> */}
      {/* </div> */}
    </main>
  );
};

const mapStateToProps = (state: any) => ({
  packagecreate: state.ProductManagerReducer.packagecreate,
  tagslist: state.ProductManagerReducer.tagslist,
  packageManagement: state.ProductManagerReducer.packageManagement,
  packagecategory: state.ProductManagerReducer.packagecategory,
  packageTags: state.ProductManagerReducer.packageTags,
  testsCrm: state.ProductManagerReducer.testsCrm,
});

export default connect(mapStateToProps, {
  createPackageManagement,
  getTagsList,
  getPackageManagement,
  getPackageCategory,
  getPackageTag,
  getTestsCrm
})(PMCreatePackage);
