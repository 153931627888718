import React, { useState, useEffect, useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Grid,
  TextField,
  withStyles,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import Modal from "@mui/material/Modal";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from '@mui/material/Button';
import { connect } from "react-redux";
import MessageIcon from "@material-ui/icons/Message";
import EditIcon from "@material-ui/icons/EditRounded";
import { useHistory } from "react-router";
import SampleJourneyComment from "../components/Comments/sampleJourney";
import SystemUpdateAltIcon from "@material-ui/icons/SystemUpdateAlt";
import BatchIdModel from "../components/Comments/BatchIdModel";
import {
  getSampleHandOver,
  updateRiderToLabStatus,
  getRiderList,
  getPhlebos,
  getPaymentInfo,
  getCities,
} from "../actions/RouteManagerAction";
import PaymentModal from '../components/Comments/batchPaymentModal';
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import { genrateSampleHandoverFilter } from "../../helpers/generateUrl";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      marginTop: "2rem",
      width: "100%",
    },
    table: {
      maxWidth: "100%",
    },
    tableHead: {
      background: "rgb(146, 74, 145)!important",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    tableHeadCell: {
      color: "#fff",
      fontWeight: "bold",
      textAlign: "center",
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
      width: "98%",
    },
    input: {
      padding: "1px",
    },
    // imageSM: {
    //   width: "60px",
    //   height: "60px",
    //   border: "2px solid #000 ",
    //   borderRadius: "50%"
    // },
    content: {
      width: "80%",
      flexGrow: 21,
      //   padding: theme.spacing(3),
    },
    imagePaper: {
      position: "absolute",
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[1],
    },
    dialog: {
      zIndex: 1,
    },
  })
);
const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

function rand() {
  return Math.round(Math.random() * 20) - 10;
}
function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

interface SampleHandOverProps {
  getSampleHandOver: any;
  sampleHandOverData: any;
  updateRiderToLabStatus: any;
  getRiderList: any;
  riderList: any;
  getPhlebos: any;
  phleboList: any;
  loading: boolean;
  getPaymentInfo: any;
  paymentInformation: any;
  getCities: any;
  cities: any;
}

const BookingsTable: React.FC<SampleHandOverProps> = ({
  getSampleHandOver,
  sampleHandOverData,
  updateRiderToLabStatus,
  getRiderList,
  riderList,
  getPaymentInfo,
  paymentInformation,
  getPhlebos,
  phleboList,
  getCities,
  cities,
  loading,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const timer = useRef<any>(0);
  const [bookingId, setBookingId] = useState<any>("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [open, setOpen] = useState<boolean>(false);
  const [sampleId, setSampleId] = useState<number>(0);
  const [start_date, setStart_date] = useState("");
  const [end_date, setEnd_date] = useState("");
  const [createdAt, setCreatedAt] = useState<string>("");
  const [rider, setRider] = useState<any>([]);
  const [phlebo, setPhlebo] = useState<any>([]);
  const [barcode, setBarcode] = useState<any>("");
  const [batchStatus, setBatchStatus] = useState<any>("");
  const [openLocationModal, setOpenLocationModal] = useState<boolean>(false);
  const [acceptFrom, setAcceptFrom] = useState<string>("");
  const [acceptAmount, setAcceptAmount] = useState<string>("");
  const [city, setCity] = useState<any>([]);
  const [openBatch, setOpenBatch] = useState<boolean>(false);
  const [batchId, setBatchId] = useState<number>(0);
  const [sampleHandoverID, setSampleHandoverID] = useState<string>("");
  const [phleboAmount, setPhleboAmount] = useState<string>("");
  const [riderAmount, setRiderAmount] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [status, setStatus] = useState<string>("");
  const [modalStyle] = React.useState(getModalStyle);
  const [openPaymentModal, setOpenPaymentModal] = useState<any>(false)
  const [batchNumber, setBatchNumber] = useState<any>("");

  useEffect(() => {
    getSampleHandOver();
    getPhlebos();
    getRiderList(`?usergroup=Rider`);
  }, []);

  useEffect(() => {
    if (acceptFrom === "Rider") {
      setAcceptAmount(riderAmount || "0");
    } else if (acceptFrom === "Phlebo") {
      setAcceptAmount(phleboAmount || "0");
    } else {
      setAcceptAmount("");
    }
  }, [acceptFrom]);

  const openAcceptPaymentModel = (
    id: string,
    phleboAmount: string,
    riderAmount: string,
    non_lab_city: string
  ) => {
    setSampleHandoverID(id);
    setPhleboAmount(phleboAmount);
    setRiderAmount(riderAmount);
    setMessage((non_lab_city && "Non Lab City Booking") || "");
    setOpenLocationModal(true);
  };
  useEffect(() => {
    if (batchId != 0) {
      getPaymentInfo(`?batch=${batchId}`);
    }
  }, [batchId])
  const handlePaymentInfo = (id: any) => {
    setBatchId(id)
    setOpenPaymentModal(true);
    setBatchId(id);
  };

  const submitHandOver = async () => {
    let error = 0;
    if (acceptFrom == "") {
      alert("Please select sample accept from.");
      error = 1;
    }
    if (acceptFrom == "Rider" && acceptAmount != riderAmount) {
      alert("Please enter amount.");
      error = 1;
    }
    if (acceptFrom == "Phlebo" && acceptAmount != phleboAmount) {
      alert("Please enter amount.");
      error = 1;
    }
    if (status == "") {
      alert("Please select status.");
      error = 1;
    }
    if (error == 0) {
      const body = {
        status: status,
        hand_over_amount: acceptAmount,
        handover_by: acceptFrom,
      };
      await updateRiderToLabStatus(body, sampleHandoverID);
      history.push("/dashboard/rm/SampleHandOvers");
    }
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url =
        sampleHandOverData.links && sampleHandOverData.links.next.split("?")[1];
      getSampleHandOver(url);
    } else if (newPage < page) {
      let url =
        sampleHandOverData.links &&
        sampleHandOverData.links.previous.split("?")[1];
      getSampleHandOver(url);
    }
    setPage(newPage as number);
  };

  const handleClick = (id: number) => {
    setOpen(true);
    setSampleId(id);
  };
  const handleClickToBactch = (id: number) => {
    setOpenBatch(true);
    setBatchId(id);
  };

  const handleRiderToLabUpdate = async (id: any) => {
    const body = {
      lab_received: "confirmed",
    };
    await updateRiderToLabStatus(body, id);
    history.push("/dashboard/rm/SampleHandOvers");
  };
  const onKeyDown = (e: any) => {
    if (e.key === "Enter") {
      filterSampleHandover(e);
    }
  };

  const filterSampleHandover = (e: any) => {
    const body: any = {
      start_date,
      end_date,
      created_date: createdAt,
      phlebo: phlebo,
      rider,
      booking: bookingId,
      barcode,
      batchStatus,
      id: batchNumber,
      // city:city
    };

    const url = genrateSampleHandoverFilter(body).substring(2);
    getSampleHandOver(`${url}`);
    setPage(0);
  };
  //batch image start
  const [batchImageUrl, setBatchImageUrl] = useState<any>("");
  const [batchImageModalOpen, setBatchImageModalOpen] = useState<any>("");

  const body = (
    <div style={modalStyle} className={classes.imagePaper}>
      <img
        src={batchImageUrl}
        alt="popup"
        style={{ width: "1000px", height: "600px", alignItems: "center" }}
      />
    </div>
  );
  const handleBatchImage = (e: any) => {
    setBatchImageUrl(e);
    setBatchImageModalOpen(true);
  };
  const handleBatchImageClose = () => {
    setBatchImageModalOpen(false);
  };
  //batch image End
  return (
    <div className={classes.content}>
      <div className={classes.toolbar} />
      <h2>Sample Handover</h2>
      <Grid container spacing={1} direction="row">
        {/* <Grid item xs={12} sm={4} md={2}>
          <TextField
            className="input"
            name="barcode"
            type="text"
            label="Barcode"
            value={barcode}
            variant="outlined"
            onChange={(e) => setBarcode(e.target.value)}
            style={{ width: "100%" }}
            onKeyDown={onKeyDown}
          />
        </Grid> */}
        <Grid item xs={12} sm={4} md={2}>
          <TextField
            className="input"
            name="batchNumber"
            type="number"
            label="Batch Number"
            value={batchNumber}
            variant="outlined"
            onChange={(e) => setBatchNumber(e.target.value)}
            style={{ width: "100%" }}
            onKeyDown={onKeyDown}
          />
        </Grid>

        <Grid item xs={12} sm={4} md={2}>
          <TextField
            className="input"
            name="name"
            type="number"
            label="Booking Id"
            value={bookingId}
            variant="outlined"
            onChange={(e) => setBookingId(e.target.value)}
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Autocomplete
            id="booking_source"
            onChange={(event, newValue) => {
              if (newValue) {
                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                setRider(obj.id);
              }
            }}
            options={riderList.results}
            freeSolo
            blurOnSelect
            aria-required
            getOptionLabel={(option: any) => option.user && option.user.name}
            onInputChange={(event, newInputValue) => {
              clearTimeout(timer.current);
              timer.current = setTimeout(() => {
                getRiderList(`?usergroup=Rider&code=${newInputValue}`);
              }, 1000);
              if (newInputValue.length === 0) {
                setRider(0);
              }
            }}
            renderInput={(params) => (
              <TextField
                className="input"
                {...params}
                placeholder="Rider"
                variant="outlined"
                style={{ width: "100%", margin: "0", padding: "0" }}
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Autocomplete
            id="phleboName"
            onChange={(event, newValue) => {
              if (newValue) {
                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                setPhlebo(obj.id);
              }
            }}
            options={
              phleboList.results
                ? [
                  ...phleboList.results,
                  { id: 0, user: { username: "NoPhlebo" } },
                ]
                : []
            }
            freeSolo
            blurOnSelect
            aria-required
            getOptionLabel={(option: any) =>
              option.user && option.user.username
            }
            onInputChange={(event, newInputValue) => {
              clearTimeout(timer.current);
              timer.current = setTimeout(() => {
                getPhlebos(`?code=${newInputValue}`);
              }, 1000);
              if (newInputValue.length === 0) {
                setPhlebo([]);
              }
            }}
            renderInput={(params) => (
              <TextField
                className="input"
                {...params}
                placeholder="Phlebo Name"
                variant="outlined"
                style={{ width: "100%", margin: "0", padding: "0" }}
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <TextField
            className="input"
            name="start_date"
            type="date"
            label="Start Date"
            value={start_date}
            variant="outlined"
            onChange={(e) => setStart_date(e.target.value as string)}
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <TextField
            name="end_date"
            type="date"
            value={end_date}
            className="input"
            label="End Date"
            variant="outlined"
            onChange={(e) => setEnd_date(e.target.value as string)}
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <TextField
            className="input"
            name="Created At"
            type="date"
            label="Created Date"
            value={createdAt}
            variant="outlined"
            onChange={(e) => setCreatedAt(e.target.value as string)}
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <FormControl style={{ width: "100%" }} className="input">
            <InputLabel>Batch Status</InputLabel>
            <Select
              className="input"
              name="status"
              variant="outlined"
              value={batchStatus}
              style={{ width: "100%", height: "40px" }}
              onChange={(e) => setBatchStatus(e.target.value as string)}
            >
              <MenuItem value={"batch_generated"}>Batch Generated</MenuItem>
              <MenuItem value={"handover_to_rider"}>Handover To Rider</MenuItem>
              <MenuItem value={"handover_to_lab"}>Handover To Lab</MenuItem>
             
              <MenuItem value={"sample_received_from_courier"}>
                Sample Received From Courier
              </MenuItem>
             
              <MenuItem value={"amount_received_from_bank_deposit"}>
                Amount Received From Bank Deposit
              </MenuItem>
              <MenuItem value={"completed"}>Completed</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {/* <Grid item xs={12} sm={6} md={2}>
                    <Autocomplete
                        id="city"
                        onChange={(event, newValue) => {
                            let sourceIds: any = [];
                            if (newValue) {
                                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                sourceIds = obj.map((item: any) => item.id);
                            }
                            setCity(sourceIds);
                        }}
                        options={cities || []}
                        freeSolo
                        blurOnSelect
                        aria-required
                        limitTags={1}
                        multiple
                        onKeyDown={onKeyDown}
                        getOptionLabel={(option: any) => option.name}
                        getOptionDisabled={(option: any) => {
                            return city.includes(option.id);
                        }}
                        disableClearable
                        disableCloseOnSelect
                        onInputChange={(event, newInputValue) => {
                            clearTimeout(timer.current);
                            timer.current = setTimeout(() => {
                                getCities(newInputValue);
                            }, 1000);
                            if (newInputValue.length === 0) {
                                setCity([]);
                            }
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="City"
                                variant="outlined"
                                style={{ width: "100%", margin: "0", padding: "0" }}
                            />
                        )}
                    />
                </Grid> */}
        <Grid item xs={12} sm={4} md={2}>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={filterSampleHandover}
            disabled={loading}
          >
            Filter
          </Button>
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => history.push("/dashboard/rm/SampleHandOvers")}
          >
            Reset
          </Button>
        </Grid>
      </Grid>
      <div className="data-table">
        <TableContainer
          className={classes.tableContainer}
          component={Paper}
          style={{ maxHeight: "540px" }}
        >
          <Table stickyHeader aria-label="simple table">
            <TableHead className={classes.tableHead}>
              <StyledTableRow>
                {/* <StyledTableCell className={classes.tableHeadCell}>
                  Edit
                </StyledTableCell> */}
                <StyledTableCell className={classes.tableHeadCell}>
                  Sample Journey
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Batch Number
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Phlebo
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Booking IDs
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Booking Amt.
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Cash Amt.
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Amt. Due on Phlebo
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Amt Received by Rider
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Amt Received by Lab
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Sample Handover
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Rider Name (HandOver)
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Rider Status
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Lab Name
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Lab Status
                </StyledTableCell>
                {/* <StyledTableCell className={classes.tableHeadCell}>
                  Lab Received
                </StyledTableCell> */}
                <StyledTableCell className={classes.tableHeadCell}>
                  Remarks
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Batch Image
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Courier Number
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Courier Receipt
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Deposit Receipt
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                Payment Mode
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>Payment Info</StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>Transfer Type </StyledTableCell>
              </StyledTableRow>


            </TableHead>
            <TableBody>
              {sampleHandOverData &&
                sampleHandOverData?.results &&
                sampleHandOverData?.results?.map((data: any, index: any) => {
                  return (
                    <StyledTableRow key={index}>
                      {/* <StyledTableCell align="center">
                        {data?.batch_status != "completed" && (
                          <EditIcon
                            onClick={() =>
                              openAcceptPaymentModel(
                                data?.id,
                                data?.amount,
                                data?.amount_received_by_rider,
                                data?.awb_number
                              )
                            }
                          />
                        )}
                      </StyledTableCell> */}
                      <StyledTableCell align="center">
                        <MessageIcon onClick={() => handleClick(data?.id)} />
                      </StyledTableCell>
                      {/* <StyledTableCell align="center">{data?.id}</StyledTableCell> */}
                      <StyledTableCell
                        style={{ cursor: "pointer", color: "blue" }}
                        key={index}
                        align="center"
                        onClick={() => handleClickToBactch(data.id)}
                      >
                        {data?.id}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.phlebo?.user?.name}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {String(data?.items)}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.booking_amount}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.cash_amount}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.amount}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.amount_received_by_rider}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.amount_received_by_lab}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.sample_handover_time === null
                          ? ""
                          : data?.sample_handover_time.substring(0, 10)}
                        <br />
                        {data?.sample_handover_time === null
                          ? ""
                          : data?.sample_handover_time.substring(11, 20)}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.given_to}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.rider_status}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.lab_name}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.batch_status}
                      </StyledTableCell>
                      {/* <StyledTableCell align="center">
                        {data?.lab_received}{" "}
                        {data?.lab_received === "pending" ? (
                          <SystemUpdateAltIcon
                            onClick={() => handleRiderToLabUpdate(data?.id)}
                          />
                        ) : (
                          ""
                        )}
                      </StyledTableCell> */}
                      <StyledTableCell align="center">
                        {data?.remarks}
                      </StyledTableCell>
                      {data?.batch_image !== null ? (
                        <StyledTableCell
                          align="center"
                          onClick={() => handleBatchImage(data?.batch_image)}
                        >
                          <img
                            key={data?.batch_image}
                            src={data?.batch_image}
                            alt="startimage"
                            style={{ width: "150px" }}
                          />
                        </StyledTableCell>
                      ) : (
                        <StyledTableCell align="center">NA</StyledTableCell>
                      )}
                      <StyledTableCell align="center">
                        {data?.awb_number}
                      </StyledTableCell>
                      {data?.courier_receipt !== null ? (
                        <StyledTableCell
                          align="center"
                          onClick={() => handleBatchImage(data?.courier_receipt)}
                        >
                          <img
                            key={data?.courier_receipt}
                            src={data?.courier_receipt}
                            alt="startimage"
                            style={{ width: "150px" }}
                          />
                        </StyledTableCell>
                      ) : (
                        <StyledTableCell align="center">NA</StyledTableCell>
                      )}
                      
                      {data?.bank_receipt !== null ? (
                        <StyledTableCell
                          align="center"
                          onClick={() => handleBatchImage(data?.bank_receipt)}
                        >
                          <img
                            key={data?.bank_receipt}
                            src={data?.bank_receipt}
                            alt="startimage"
                            style={{ width: "150px" }}
                          />
                        </StyledTableCell>
                      ) : (
                        <StyledTableCell align="center">NA</StyledTableCell>
                      )}
                       <StyledTableCell align="center">{data?.payment_mode}</StyledTableCell>
                      <StyledTableCell align="center">
                        <LocalAtmIcon
                          onClick={() => handlePaymentInfo(data?.id)}
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.transfer_type}
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
            </TableBody>
            <TableFooter>
              <StyledTableRow>
                <TablePagination
                  rowsPerPageOptions={[]}
                  colSpan={3}
                  count={sampleHandOverData.count || 0}
                  rowsPerPage={sampleHandOverData.page_size}
                  page={page}
                  onPageChange={handleChangePage}
                  SelectProps={{
                    native: true,
                  }}
                />
              </StyledTableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
      <SampleJourneyComment open={open} setOpen={setOpen} sampleId={sampleId} />
      <BatchIdModel
        openBatch={openBatch}
        setOpenBatch={setOpenBatch}
        batchId={batchId}
      />
      {/* Location Map Modal  */}
      <Dialog
        open={openLocationModal}
        onClose={() => setOpenLocationModal(false)}
        aria-labelledby="form-dialog-title"
        className={classes.dialog}
      >
        <DialogTitle id="form-dialog-title">Accept Sample</DialogTitle>
        <DialogContent>
          <Grid
            container
            spacing={2}
            style={{
              margin: "0px 10px 10px 10px",
              minWidth: "500px",
              width: "70%",
            }}
          >
            {message && message && (
              <Grid item xs={12} md={12}>
                <p>
                  <strong>{message}</strong>
                </p>
              </Grid>
            )}
            <Grid item xs={12} md={12}>
              <p>Accept From</p>
              <Select
                className="input"
                name="status"
                variant="outlined"
                value={acceptFrom}
                style={{ width: "100%" }}
                onChange={(e) => setAcceptFrom(e.target.value as string)}
              >
                <MenuItem value={"Rider"}>Rider</MenuItem>
                <MenuItem value={"Phlebo"}>Phlebo</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} md={12}>
              <p>Accept From</p>
              <Select
                className="input"
                name="status"
                variant="outlined"
                value={status}
                style={{ width: "100%" }}
                onChange={(e) => setStatus(e.target.value as string)}
              >
                <MenuItem value={"completed"}>
                  Sample and Amount Received
                </MenuItem>
                <MenuItem value={"sample_received_from_courier"}>
                  Sample Received From Courier
                </MenuItem>
                <MenuItem value={"amount_received_from_bank_deposit"}>
                  Amount Received From Bank Deposit
                </MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} md={12}>
              <p>Amount</p>
              <TextField
                className="input"
                name="zone"
                type="text"
                value={acceptAmount || ""}
                variant="outlined"
                style={{ width: "100%", margin: "0" }}
                onChange={(e) => setAcceptAmount(e.target.value as string)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => submitHandOver()} color="primary">
            Submit
          </Button>
          <Button onClick={() => setOpenLocationModal(false)} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Modal
        open={batchImageModalOpen}
        onClose={handleBatchImageClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
      <PaymentModal
        openModalP={openPaymentModal}
        setOpenModalP={setOpenPaymentModal}
        paymentInformation={paymentInformation}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  sampleHandOverData: state.RouteManagerReducer.sampleHandOverData,
  riderList: state.RouteManagerReducer.riderList,
  phleboList: state.RouteManagerReducer.phleboList,
  cities: state.RouteManagerReducer.cities,
  paymentInformation: state.RouteManagerReducer.paymentInformation,
  loading: state.RouteManagerReducer.loading,
});
export default connect(mapStateToProps, {
  getSampleHandOver,
  updateRiderToLabStatus,
  getRiderList,
  getPhlebos,
  getPaymentInfo,
  getCities,
})(BookingsTable);
