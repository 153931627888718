import React, { useState, useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Chip,
  Paper,
  withStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Loader from "../components/loader/index";
import BookingCommentsModal from "../components/Comments/bookingComments";
import { MessageRounded } from "@material-ui/icons";
import AdditonalFamilyModal from "./AdditionalFamily/AdditionFamilyDetails";
import InvertColorsIcon from "@material-ui/icons/InvertColors";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      marginTop: "2rem",
      padding: "0.5rem 2rem",
      width: "100%",
    },
    table: {
      maxWidth: "100%",
      margin: "auto",
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
  })
);

const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

interface Props {
  getAllBookings2: any;
  bookingList2: any;
  page: any;
  setPage: any;
  openModal: any;
  setopenModal: any;
  setOpenPaymentModal: any;
  openPaymentModal: any;
  loading: Boolean;
  setOpenTubeModal: any;
  setPaymentId: any;
  paymentId: any;
  setTubeId: any;
  setTotalCount: any;
  setBId: any;
  setMemberCheck: any;
}

const BookingsTable: React.FC<Props> = ({
  getAllBookings2,
  bookingList2,
  page,
  setPage,
  openModal,
  setPaymentId,
  paymentId,
  setopenModal,
  setOpenPaymentModal,
  openPaymentModal,
  setOpenTubeModal,
  setTubeId,
  loading,
  setTotalCount,
  setBId,
  setMemberCheck,
}) => {
  const classes = useStyles();

  const [bookingId, setBookingId] = useState<number>(0);
  const [additionalMemebr, setAdditionalMemebr] = useState<any>();
  const [additionalModal, setAdditonalModal] = useState<boolean>(false);
  const handleClick = (id: number) => {
    setopenModal(true);
    setBookingId(id);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url = bookingList2.links && bookingList2.links.next.split("?")[1];
      getAllBookings2(url);
    } else if (newPage < page) {
      let url = bookingList2.links && bookingList2.links.previous.split("?")[1];
      getAllBookings2(url);
    }
    setPage(newPage as number);
  };
  const handleAdditionalView = (member: any) => {
    setAdditionalMemebr(member);
    setAdditonalModal(true);
  };
  const handleTubeModal = (id: any, bId: any, check: any) => {
    setOpenTubeModal(true);
    setTubeId(id);
    setBId(bId);
    setMemberCheck(check);
  };
  const handlePaymentInfo = (id: any) => {
    setPaymentId(id);
    setOpenPaymentModal(true);
    setPaymentId(id);
  };
  useEffect(() => {
    setTotalCount(bookingList2.count);
  }, [bookingList2]);
  return (
    <div className="data-table" style={{ width: "100%" }}>
      <TableContainer
        className={classes.tableContainer}
        component={Paper}
        style={{ maxHeight: "580px" }}
      >
        {loading ? (
          <Loader />
        ) : (
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell align="center">Action</StyledTableCell>
                <StyledTableCell align="center">Booking Id</StyledTableCell>
                <StyledTableCell align="center">Child Id</StyledTableCell>
                <StyledTableCell align="center">Phlebo Name</StyledTableCell>
                <StyledTableCell align="center">Phlebo Number</StyledTableCell>
                <StyledTableCell align="center">Comment</StyledTableCell>
                <StyledTableCell align="center">Booking Date</StyledTableCell>
                <StyledTableCell align="center">Name</StyledTableCell>
                <StyledTableCell align="center">Agent</StyledTableCell>
                <StyledTableCell align="center">Age</StyledTableCell>
                <StyledTableCell align="center">Gender</StyledTableCell>
                <StyledTableCell align="center">Email</StyledTableCell>
                <StyledTableCell align="center">
                  Collection Date
                </StyledTableCell>
                <StyledTableCell align="center">
                  Collection Time
                </StyledTableCell>
                <StyledTableCell align="center">Booking Time</StyledTableCell>
                <StyledTableCell align="center">Zone Name</StyledTableCell>
                <StyledTableCell align="center">Booking Status</StyledTableCell>
                <StyledTableCell align="center">Report Status</StyledTableCell>
                <StyledTableCell align="center">Pickup Status</StyledTableCell>
                {/* <StyledTableCell align="center">Verification Status</StyledTableCell> */}
                <StyledTableCell align="center">
                  Pickup Recieve Amount
                </StyledTableCell>
                <StyledTableCell align="center">Payment Info</StyledTableCell>
                <StyledTableCell align="center">Pickup Date</StyledTableCell>
                <StyledTableCell align="center">Pickup Time</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {bookingList2?.results &&
                bookingList2?.results?.length > 0 &&
                bookingList2?.results.map((booking: any, index: any) => {
                  const pendingData = booking?.additional_members.filter(
                    (data: any) => data.pickup_status === "pending"
                  );
                  const confirmedData = booking?.additional_members.filter(
                    (data: any) => data.pickup_status === "confirmed"
                  );
                  return (
                    <>
                      <StyledTableRow
                        key={index}
                        style={{
                          backgroundColor:
                            booking.phlebo_verification_remark !==
                            "Hold Due To Payment"
                              ? booking.booking_type === "b2b" &&
                                booking.receivable_amount === 0 &&
                                booking.phlebo_reached === false
                                ? "#ffe082"
                                : booking.booking_type === "b2b" &&
                                  booking.receivable_amount !== 0 &&
                                  booking.phlebo_reached === false
                                ? "#f48fb1"
                                : booking.booking_type === "b2b" &&
                                  booking.receivable_amount === 0 &&
                                  booking.phlebo_reached
                                ? "#ba68c8"
                                : booking.booking_type === "b2b" &&
                                  booking.receivable_amount !== 0 &&
                                  booking.phlebo_reached
                                ? "#ba68c8"
                                : booking.booking_type === "b2c" &&
                                  booking.receivable_amount == 0 &&
                                  booking.phlebo_reached === false
                                ? "#64ffda"
                                : booking.booking_type === "b2c" &&
                                  booking.receivable_amount == 0 &&
                                  booking.phlebo_reached
                                ? "#ba68c8"
                                : ""
                              : "#ff9800",
                        }}
                      >
                        <StyledTableCell align="center">
                          <Link
                            to={`/dashboard/rm/booking-view/${booking?.pk}`}
                            style={{ textDecoration: "none" }}
                          >
                            <Button variant="contained" color="secondary">
                              View
                            </Button>
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {booking?.pk}
                        </StyledTableCell>
                        <StyledTableCell align="center"></StyledTableCell>
                        <StyledTableCell align="center">
                          {booking?.phlebo && booking?.phlebo?.name}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {booking?.phlebo && booking?.phlebo?.phonenumber}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <div>
                            <MessageRounded
                              onClick={() => handleClick(booking?.pk)}
                            />
                          </div>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {booking?.booking_date}
                        </StyledTableCell>
                        <StyledTableCell align="center">{`${
                          booking?.designation || ""
                        } ${booking?.customer_name}`}</StyledTableCell>
                        <StyledTableCell align="center">
                          {(booking?.agent && booking?.agent?.name) || "NA"}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {booking?.customer_age}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {booking?.customer_gender}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {booking?.customer_email}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {booking?.collection_date}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {booking?.collection_slot?.slot.split("-")[0]}
                          {" - "}
                          {booking?.collection_slot?.slot.split("-")[1]}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {" "}
                          {new Date(booking.created_at).toLocaleString()}
                        </StyledTableCell>
                        {/* <TableCell align="center">{booking?.pickup_amount || "NA"}</TableCell> */}
                        <StyledTableCell align="center">
                          {booking?.customer_areapincode.area}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <Chip
                            label={`${booking?.booking_status}`}
                            style={{
                              background:
                                booking?.booking_status === "pending"
                                  ? "#ffee58"
                                  : booking?.booking_status === "confirmed"
                                  ? "#00e676"
                                  : booking?.booking_status === "cancelled"
                                  ? "#ff3d00"
                                  : "#d500f9",
                            }}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <Chip
                            label={`${booking?.report_status}`}
                            style={{
                              background:
                                booking?.report_status === "pending"
                                  ? "#ffee58"
                                  : booking?.report_status === "consolidate"
                                  ? "#00e676"
                                  : booking?.report_status === "partial"
                                  ? "#d500f9"
                                  : "#ff3d00",
                            }}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <Chip
                            label={`${booking?.pickup_status}`}
                            style={{
                              background:
                                booking?.pickup_status === "pending"
                                  ? "#ffee58"
                                  : booking?.pickup_status === "confirmed"
                                  ? "#00e676"
                                  : booking?.pickup_status === "cancelled"
                                  ? "#ff3d00"
                                  : "#d500f9",
                            }}
                          />
                        </StyledTableCell>
                        {/* <StyledTableCell align="center"><Chip label={`${booking?.verification_status}`} style={{ background: booking?.verification_status === "pending" ? "#ffee58" : booking?.verification_status === "confirmed" ? "#00e676" : booking?.verification_status === "cancelled" ? "#ff3d00" : "#d500f9" }} /></StyledTableCell> */}
                        <StyledTableCell align="center">
                          {booking?.pickup_receive_amount}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <LocalAtmIcon
                            onClick={() => handlePaymentInfo(booking?.pk)}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {booking?.pickup_date}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {booking?.pickup_time}
                        </StyledTableCell>
                      </StyledTableRow>
                      {booking?.additional_members &&
                        booking?.additional_members.length > 0 &&
                        booking?.additional_members.map(
                          (data: any, index2: any) => {
                            return (
                              <StyledTableRow key={index2}>
                                <StyledTableCell align="center">
                                  <Link
                                    to={`/dashboard/rm/booking-view/${booking?.pk}`}
                                    style={{ textDecoration: "none" }}
                                  >
                                    <Button
                                      variant="contained"
                                      style={{ backgroundColor: "#ff9100" }}
                                    >
                                      View
                                    </Button>
                                  </Link>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {booking?.pk}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {data?.id}
                                </StyledTableCell>
                                <StyledTableCell align="center"></StyledTableCell>
                                <StyledTableCell align="center"></StyledTableCell>
                                <StyledTableCell align="center"></StyledTableCell>
                                <StyledTableCell align="center"></StyledTableCell>
                                <StyledTableCell align="center">{`${
                                  data?.designation || ""
                                } ${data?.customer_name}`}</StyledTableCell>
                                <StyledTableCell align="center"></StyledTableCell>
                                <StyledTableCell align="center">
                                  {data?.customer_age}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {data?.customer_gender}
                                </StyledTableCell>
                                <StyledTableCell align="center"></StyledTableCell>
                                <StyledTableCell align="center"></StyledTableCell>
                                <StyledTableCell align="center"></StyledTableCell>
                                {/* <TableCell align="center">{booking?.pickup_amount || "NA"}</TableCell> */}
                                <StyledTableCell align="center"></StyledTableCell>
                                <StyledTableCell align="center"></StyledTableCell>
                                <StyledTableCell align="center">
                                  <Chip
                                    label={`${data?.booking_status}`}
                                    style={{
                                      background:
                                        data?.booking_status === "pending"
                                          ? "#ffee58"
                                          : data?.booking_status === "confirmed"
                                          ? "#00e676"
                                          : data?.booking_status === "cancelled"
                                          ? "#ff3d00"
                                          : "#d500f9",
                                    }}
                                  />
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  <Chip
                                    label={`${data?.report_status}`}
                                    style={{
                                      background:
                                        data?.report_status === "pending"
                                          ? "#ffee58"
                                          : data?.report_status ===
                                            "consolidate"
                                          ? "#00e676"
                                          : data?.report_status === "partial"
                                          ? "#d500f9"
                                          : "#ff3d00",
                                    }}
                                  />
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  <Chip
                                    label={`${data?.pickup_status}`}
                                    style={{
                                      background:
                                        data?.pickup_status === "pending"
                                          ? "#ffee58"
                                          : data?.pickup_status === "confirmed"
                                          ? "#00e676"
                                          : data?.pickup_status === "cancelled"
                                          ? "#ff3d00"
                                          : "#d500f9",
                                    }}
                                  />
                                </StyledTableCell>
                                <StyledTableCell align="center"></StyledTableCell>
                                <StyledTableCell align="center"></StyledTableCell>
                                <StyledTableCell align="center"></StyledTableCell>
                                <StyledTableCell align="center"></StyledTableCell>
                                {/* <StyledTableCell align="center"></StyledTableCell> */}
                              </StyledTableRow>
                            );
                          }
                        )}
                    </>
                  );
                })}
            </TableBody>
            <TableFooter>
              <StyledTableRow>
                <TablePagination
                  rowsPerPageOptions={[]}
                  colSpan={4}
                  count={bookingList2?.count || 0}
                  rowsPerPage={bookingList2?.page_size}
                  page={page}
                  onPageChange={handleChangePage}
                />
              </StyledTableRow>
            </TableFooter>
            {bookingList2?.results && bookingList2?.results?.length === 0 && (
              <span style={{ paddingLeft: "10px", textAlign: "left", fontWeight: "bolder", display: "flex", fontSize:"20px", width:"10rem" }}>No Data Found</span>
            )}
          </Table>
        )}
      </TableContainer>
      <BookingCommentsModal
        openModal={openModal}
        setopenModal={setopenModal}
        bookingId={bookingId}
      />
      <AdditonalFamilyModal
        additionalMemebr={additionalMemebr}
        additionalModal={additionalModal}
        setAdditonalModal={setAdditonalModal}
        loading={loading}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps, {})(BookingsTable);
