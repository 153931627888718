import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from '@mui/material/Modal';
import Backdrop from "@material-ui/core/Backdrop";
import Button from '@mui/material/Button';
import Fade from "@material-ui/core/Fade";
import { TableContainer, Table, Chip, TableHead, TableBody, TableRow, TableCell, Paper, TextField, Grid, Select, MenuItem } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import { updateTubeStatus } from '../../actions/PhleboAdmin'
let width = window.innerWidth;
let isMobile = width <= 500;


const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        marginTop: "2rem",
        padding: "0.5rem 2rem",
        width: "70%",
    },
    head: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "2rem",
    },
}));
interface Props {
updateTubeStatus:any;
}
type ModalProps = {
    setOpen: Function;
    open: boolean;
    codeData: any;
    setCodeData: any;
    updateTubeStatus:any;
    setOpenTubeModal:any;
    setTubeId:any;
    tubeId:any;
    setUpdateTube:any;
    id: any;
};

const CreatePhlebo: React.FC<ModalProps> = ({
    setOpen,
    open,
    codeData,
    setCodeData,
    updateTubeStatus,
    setOpenTubeModal,
    setTubeId,
    setUpdateTube,
    tubeId,
    id,
}) => {
    const classes = useStyles();
    const timer = useRef<any>(0)
    const handleClose = () => {
        setOpen(false);
    };
    
    const submitForm = async (e: any) => {
        e.preventDefault()
        const tubeData = {
            code: codeData,
            is_snr: false
        }
        await updateTubeStatus(id,tubeData)
        setTubeId(tubeId)
        setUpdateTube(id)
        setOpen(false)

    }
    
    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <Paper elevation={5} className={classes.paper}>
                            <div className={classes.head}>

                                <h4
                                    style={{
                                        marginBottom: "1rem",
                                    }}
                                    id="transition-modal-title"
                                >
                                    EDIT TUBE CODE
                                </h4>
                                <CloseIcon onClick={() => setOpen(false)} />
                            </div>
                            <form onSubmit={submitForm}>
                                <Grid
                                    container
                                    direction="row"
                                    justify="space-between"
                                    alignItems="flex-end"
                                    spacing={3}
                                    style={{ margin: "1rem auto", background: "#eff" }}
                                >
                                    <Grid container item xs={12} md={6} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                        <Grid item xs={12} style={{ padding: "0" }}>
                                            <p>Code</p>
                                        </Grid>
                                        <Grid item xs={12} style={{ padding: "0" }}>
                                            <TextField
                                                name="bill_id"
                                                type="text"
                                                placeholder="Bill Id"
                                                value={codeData}
                                                className="input"
                                                variant="outlined"
                                                style={{ margin: "0", width: "100%" }}
                                                onChange={(e) => setCodeData(e.target.value as String)}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={12} md={6} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                        <Grid item xs={12} style={{ padding: "0" }}>
                                            <Button
                                                color="primary"
                                                type="submit"
                                                variant="contained"
                                            >
                                                Update Code
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </form>

                        </Paper>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
};

const mapStateToProps = (state: any) => ({

});

export default connect(mapStateToProps, {
    updateTubeStatus,
})(CreatePhlebo);
