import React, { useState, useEffect, useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Grid,
  TextField,
  withStyles,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from '@mui/material/Button';
import { connect } from "react-redux";
import MessageIcon from "@material-ui/icons/Message";
import { useHistory } from "react-router";
import SampleJourneyComment from "../components/Comments/sampleJourney";
import BatchIdModel from "../components/Comments/BatchIdModel";
import PaymentModal from '../components/Comments/batchPaymentModal';
import CallIcon from "@material-ui/icons/Call";
import {
  getSampleHandOver,
  updateRiderToLabStatus,
  getRiderList,
  getPhlebos,
  getPaymentInfo,
  updateRiderSampleHandover,
  clickToCallBooking,
} from "../actions/LogisticsPanelAction";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import Loader from "../components/loader";
import { genrateSampleHandoverFilter } from "../../helpers/generateUrl";
import { CALL_DISABLE_TIME } from "../../CallTimeDisable";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      marginTop: "2rem",
      width: "100%",
    },
    table: {
      maxWidth: "100%",
    },
    tableHead: {
      background: "rgb(146, 74, 145)!important",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    tableHeadCell: {
      color: "#fff",
      fontWeight: "bold",
      textAlign: "center",
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
      width: "98%",
    },
    input: {
      padding: "1px",
    },
  })
);
const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

interface SampleHandOverProps {
  getSampleHandOver: any;
  sampleHandOverData: any;
  updateRiderToLabStatus: any;
  getRiderList: any;
  riderList: any;
  getPhlebos: any;
  phleboList: any;
  loading: boolean;
  getPaymentInfo: any;
  paymentInformation: any;
  updateRiderSampleHandover: any;
  clickToCallBooking: Function;
}

const BookingsTable: React.FC<SampleHandOverProps> = ({
  getSampleHandOver,
  sampleHandOverData,
  updateRiderToLabStatus,
  getRiderList,
  riderList,
  getPhlebos,
  phleboList,
  getPaymentInfo,
  paymentInformation,
  updateRiderSampleHandover,
  loading,
  clickToCallBooking,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const timer = useRef<any>(0);
  const [bookingId, setBookingId] = useState<any>("");
  const [page, setPage] = React.useState(0);
  // const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [open, setOpen] = useState<boolean>(false);
  const [sampleId, setSampleId] = useState<number>(0);
  const [start_date, setStart_date] = useState("");
  const [end_date, setEnd_date] = useState("");
  const [createdAt, setCreatedAt] = useState<string>("");
  const [rider, setRider] = useState<any>([]);
  const [editedRider, setEditedRider] = useState<any>([]);
  const [phlebo, setPhlebo] = useState<any>([]);
  const [barcode, setBarcode] = useState<any>("");
  const [batchStatus, setBatchStatus] = useState<any>("none");
  const [editType, setEditType] = useState<boolean>(false);
  const [userDetails, setUserDetails] = useState<any>({});
  const [filterUrl, setFilterUrl] = useState<any>("");
  const [batchNumber, setBatchNumber] = useState<any>("");
  const [openBatch, setOpenBatch] = useState<boolean>(false);
  const [batchId, setBatchId] = useState<number>(0);
  const [openPaymentModal, setOpenPaymentModal] = useState<any>(false)
  const [callButton, setCallButton] = useState<boolean>(true);

  useEffect(() => {
    getSampleHandOver();
    getPhlebos();
    getRiderList(`?usergroup=Rider`);
  }, []);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url =
        sampleHandOverData.links && sampleHandOverData.links.next.split("?")[1];
      getSampleHandOver(url);
    } else if (newPage < page) {
      let url =
        sampleHandOverData.links &&
        sampleHandOverData.links.previous.split("?")[1];
      getSampleHandOver(url);
    }
    setPage(newPage as number);
  };
  const handleClick = (id: number) => {
    setOpen(true);
    setSampleId(id);
  };
  const handleClickToBactch = (id: number) => {
    setOpenBatch(true);
    setBatchId(id);
  };
  const handleRiderToLabUpdate = async (id: any) => {
    const body = {
      lab_received: "confirmed",
    };
    await updateRiderToLabStatus(body, id);
    history.push("/dashboard/lp/SampleHandOvers");
  };
  const onKeyDown = (e: any) => {
    if (e.key === "Enter") {
      filterSampleHandover(e);
    }
  };

  const filterSampleHandover = (e: any) => {
    const body: any = {
      start_date,
      end_date,
      created_date: createdAt,
      phlebo: phlebo,
      rider,
      booking: bookingId,
      barcode,
      batchStatus,
      id: batchNumber,
    };
    const url = genrateSampleHandoverFilter(body).substring(2);
    setFilterUrl(url);
    getSampleHandOver(`${url}`);
    setPage(0);
  };
  const handleEditRiderChange = (data: any) => {
    setUserDetails(data);
    // setEditType(true)
  };
  const handleSubmitRiderChange = (id: any) => {
    const body = {
      given_to: editedRider,
    };
    updateRiderSampleHandover(body, id);
    setTimeout(() => {
      getSampleHandOver(`${filterUrl}`);
    }, 1000);

    setEditType(false);
    setUserDetails({});
  };
  useEffect(() => {
    if (batchId != 0) {
      getPaymentInfo(`?batch=${batchId}`);
    }
  }, [batchId])

  const handlePaymentInfo = (id: any) => {
    setBatchId(id)
    setOpenPaymentModal(true);
    setBatchId(id);
  };

  const handleCallClick = (item: number, type: string) => {
    setCallButton(false);
    clickToCallBooking(item, type);
    setTimeout(() => {
      setCallButton(true);
    }, CALL_DISABLE_TIME);
  };
  return (
    <div style={{ width: "95%" }}>
      <div className={classes.toolbar} />
      <h3 style={{ fontWeight: "bold", padding: "10px 0" }}>
        Sample Receiving List
      </h3>
      <Grid container spacing={1} direction="row">
        <Grid item xs={12} sm={4} md={2}>
          <TextField
            className="input"
            name="batchNumber"
            type="number"
            label="Batch Number"
            value={batchNumber}
            variant="outlined"
            // onChange={(e) => setBatchNumber(e.target.value)}
            style={{ width: "100%" }}
            inputProps={{ min: 0 }}
            onKeyDown={onKeyDown}
            onChange={(e) => {
              const inputVal = e.target.value;
              setBatchNumber(inputVal.replace(/[^0-9 ]/g, ''))
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <TextField
            className="input"
            name="barcode"
            type="text"
            label="Barcode"
            value={barcode}
            variant="outlined"
            onChange={(e) => setBarcode(e.target.value)}
            style={{ width: "100%" }}
            onKeyDown={onKeyDown}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <TextField
            className="input"
            name="name"
            type="number"
            label="Booking Id"
            value={bookingId}
            variant="outlined"
            onChange={(e) => {
              const inputVal = e.target.value;
              setBookingId(inputVal.replace(/[^0-9 ]/g, ''))
            }}
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Autocomplete
            id="booking_source"
            onChange={(event, newValue) => {
              if (newValue) {
                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                setRider(obj.id);
              }
            }}
            options={riderList.results}
            freeSolo
            blurOnSelect
            aria-required
            getOptionLabel={(option: any) =>
              option.user && option.user.username
            }
            onInputChange={(event, newInputValue) => {
              clearTimeout(timer.current);
              timer.current = setTimeout(() => {
                getRiderList(`?usergroup=Rider&code=${newInputValue}`);
              }, 1000);
              if (newInputValue.length === 0) {
                setRider(0);
              }
            }}
            renderInput={(params) => (
              <TextField
                className="input"
                {...params}
                placeholder="Rider"
                variant="outlined"
                style={{ width: "100%", margin: "0", padding: "0" }}
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Autocomplete
            id="phleboName"
            onChange={(event, newValue) => {
              if (newValue) {
                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                setPhlebo(obj.id);
              }
            }}
            options={
              phleboList.results
                ? [
                  ...phleboList.results,
                  { id: 0, user: { username: "NoPhlebo" } },
                ]
                : []
            }
            freeSolo
            blurOnSelect
            aria-required
            getOptionLabel={(option: any) =>
              option.user && option.user.username
            }
            onInputChange={(event, newInputValue) => {
              clearTimeout(timer.current);
              timer.current = setTimeout(() => {
                getPhlebos(`${newInputValue}`);
              }, 1000);
              if (newInputValue.length === 0) {
                setPhlebo([]);
              }
            }}
            renderInput={(params) => (
              <TextField
                className="input"
                {...params}
                placeholder="Phlebo Name"
                variant="outlined"
                style={{ width: "100%", margin: "0", padding: "0" }}
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <TextField
            className="input"
            name="start_date"
            type="date"
            label="Start Date"
            value={start_date}
            variant="outlined"
            onChange={(e) => String(e.target.value).length <= 10 ? setStart_date(e.target.value as string) : ""}
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <TextField
            name="end_date"
            type="date"
            value={end_date}
            className="input"
            label="End Date"
            variant="outlined"
            onChange={(e) => String(e.target.value).length <= 10 ? setEnd_date(e.target.value as string) : ""}
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <TextField
            className="input"
            name="Created At"
            type="date"
            label="Created Date"
            value={createdAt}
            variant="outlined"
            onChange={(e) => String(e.target.value).length <= 10 ? setCreatedAt(e.target.value as string) : ""}
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Select
            className="input"
            label="Batch Status"
            name="status"
            variant="outlined"
            value={batchStatus}
            style={{ width: "100%", height: "40px" }}
            onChange={(e) => setBatchStatus(e.target.value as string)}
          >
            <MenuItem disabled value={"none"}>Batch Status</MenuItem>
            <MenuItem value={"batch_generated"}>Batch Generated</MenuItem>
            <MenuItem value={"batch_cancelled"}>Batch Cancelled</MenuItem>
            <MenuItem value={"handover_to_rider"}>Handover To Rider</MenuItem>
            <MenuItem value={"handover_to_lab"}>Handover To Lab</MenuItem>
            <MenuItem value={"completed"}>Completed</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Button
            variant="contained"
            color="secondary"
            disabled={loading}
            fullWidth
            onClick={filterSampleHandover}
          >
            Filter
          </Button>
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Button
            variant="contained"
            color="primary"

            fullWidth
            onClick={() => history.push("/dashboard/lp/sample_receiving_list")}
          >
            Reset
          </Button>
        </Grid>
      </Grid>
      <div style={{ width: "100%" }} className="data-table">
        <TableContainer
          className={classes.tableContainer}
          component={Paper}
          style={{ maxHeight: "550px" }}
        >
          <Table stickyHeader aria-label="simple table">
            <TableHead className={classes.tableHead}>
              <StyledTableRow>
              <StyledTableCell className={classes.tableHeadCell}>
                  Call
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Edit Batch
                </StyledTableCell>
                {editType ? (
                  <StyledTableCell
                    className={classes.tableHeadCell}
                  ></StyledTableCell>
                ) : (
                  ""
                )}

                <StyledTableCell className={classes.tableHeadCell}>
                  Sample Journey
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Batch Number
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Batch Created At
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Phlebo
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Booking IDs
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Booking Amt.
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Cash Amt.
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Amt Due on Phlebo
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Amt Received by Rider
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Amt Received by Lab
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Status
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Sample Receive Time
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  HandOver
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Remarks
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>Payment Info</StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>Transfer Type </StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {sampleHandOverData?.results === undefined ? (
                <Loader />
              ) : (
                sampleHandOverData &&
                sampleHandOverData?.results &&
                sampleHandOverData.results.map((data: any, index: any) => {
                  return (
                    <StyledTableRow key={index}>
                        <StyledTableCell align="center">
                        <Button
                          disabled={!callButton}
                          className="addDeck"
                          variant="contained"
                          color="primary"
                          onClick={() =>
                            handleCallClick(data?.items, "call")
                          }
                          startIcon={<CallIcon />}
                        ></Button>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <div>
                          {userDetails.id === data?.id ? (
                            <span>
                              <div style={{ width: "220PX" }}>
                                <Autocomplete
                                  id="booking_source"
                                  onChange={(event, newValue) => {
                                    if (newValue) {
                                      let obj = JSON.parse(
                                        JSON.stringify(newValue, null, " ")
                                      );
                                      setEditedRider(obj.id);
                                    }
                                  }}
                                  options={riderList?.results || []}
                                  freeSolo
                                  blurOnSelect
                                  aria-required
                                  getOptionLabel={(option: any) =>
                                    option.user && option.user.username
                                  }
                                  onInputChange={(event, newInputValue) => {
                                    clearTimeout(timer.current);
                                    timer.current = setTimeout(() => {
                                      getRiderList(
                                        `?usergroup=Rider&code=${newInputValue}`
                                      );
                                    }, 1000);
                                    if (newInputValue.length === 0) {
                                      setEditedRider(0);
                                    }
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      className="input"
                                      {...params}
                                      placeholder="Rider"
                                      variant="outlined"
                                      style={{
                                        width: "100%",
                                        margin: "0",
                                        padding: "0",
                                      }}
                                      required
                                    />
                                  )}
                                />
                              </div>
                            </span>
                          ) : (
                            <EditIcon
                              onClick={() => handleEditRiderChange(data)}
                            />
                          )}
                        </div>
                      </StyledTableCell>
                      {editType || userDetails?.id === data?.id ? (
                        <StyledTableCell align="center">
                          <Button disabled={riderList?.results == 0}>
                            <CheckCircleIcon
                              onClick={() => handleSubmitRiderChange(data.id)}
                            />
                          </Button>

                        </StyledTableCell>
                      ) : (
                        ""
                      )}

                      <StyledTableCell align="center">
                        <MessageIcon onClick={() => handleClick(data.id)} />
                      </StyledTableCell>
                      {/* <StyledTableCell align="center">
                          {data?.id}
                        </StyledTableCell> */}
                      <StyledTableCell
                        style={{ cursor: "pointer", color: "blue" }}
                        key={index}
                        align="center"
                        onClick={() => handleClickToBactch(data.id)}
                      >
                        {data?.id}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.created_at.substring(0, 10)}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.phlebo?.user?.name}
                      </StyledTableCell>

                      <StyledTableCell align="center">
                        {String(data?.items)}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.booking_amount}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.cash_amount}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.amount}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.amount_received_by_rider}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.amount_received_by_lab}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.batch_status}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.sample_handover_time === null
                          ? ""
                          : data?.sample_handover_time.substring(0, 10)}
                        <br />
                        {data?.sample_handover_time === null
                          ? ""
                          : data?.sample_handover_time.substring(11, 20)}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.given_to}
                      </StyledTableCell>

                      {/* <StyledTableCell align="center">
                          {data?.lab_received}{" "}
                          {data?.lab_received === "pending" ? (
                            <SystemUpdateAltIcon
                              onClick={() => handleRiderToLabUpdate(data?.id)}
                            />
                          ) : (
                            ""
                          )}
                        </StyledTableCell> */}

                      {/* <StyledTableCell align="center">
                          {data?.tube_received_by_rider}
                        </StyledTableCell> */}
                      <StyledTableCell align="center">
                        {data?.remarks}
                      </StyledTableCell>
                      {/* <StyledTableCell align="center">
                          {data?.cash_received_by_rider}
                        </StyledTableCell> */}
                      <StyledTableCell align="center">
                        <LocalAtmIcon
                          onClick={() => handlePaymentInfo(data?.id)}
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.transfer_type}
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })
              )}
            </TableBody>
            <TableFooter>
              <StyledTableRow>
                <TablePagination
                  colSpan={3}
                  count={sampleHandOverData?.count || 0}
                  rowsPerPageOptions={[]}
                  rowsPerPage={sampleHandOverData?.page_size}
                  page={page}
                  SelectProps={{
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                />
              </StyledTableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
      <SampleJourneyComment open={open} setOpen={setOpen} sampleId={sampleId} />
      <BatchIdModel
        openBatch={openBatch}
        setOpenBatch={setOpenBatch}
        batchId={batchId}
      />
      <PaymentModal
        openModalP={openPaymentModal}
        setOpenModalP={setOpenPaymentModal}
        paymentInformation={paymentInformation}
      />

      {/* {bookingList.results && bookingList.results.length === 0 && (
        <h6 style={{ textAlign: "center" }}>No Data Found</h6>
      )} */}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  sampleHandOverData: state.LogisticsReducer.sampleHandOverData,
  riderList: state.LogisticsReducer.riderList,
  phleboList: state.LogisticsReducer.phleboList,
  paymentInformation: state.LogisticsReducer.paymentInformation,
  loading: state.LogisticsReducer.loading,
});
export default connect(mapStateToProps, {
  getSampleHandOver,
  updateRiderToLabStatus,
  getRiderList,
  getPhlebos,
  updateRiderSampleHandover,
  getPaymentInfo,
  clickToCallBooking,
})(BookingsTable);
