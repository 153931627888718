import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from '@mui/material/Modal';
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Button, TextField, Grid, Select, MenuItem } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  createNewPhlebo,
  updatePhlebo,
} from "../../actions/PhleboAdmin";
import { useHistory } from "react-router-dom";
// import Loader from '../loader'
import Loader from "../loader/index";
let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: isMobile ? "400px" : "1200px",
  },
  head: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "2rem",
  },
}));

type ModalProps = {
  openEditPhlebo: boolean;
  editPhleboUser: any;
  updatePhlebo: any;
  setOpenEditPhlebo: Function;
  zoneList: any;
  loading: Boolean;
  setZoneName: any;
  zoneName: any;
  getAutoCompleteZone: any;
  getPhlebos: any;
};

const CreatePhlebo: React.FC<ModalProps> = ({
  openEditPhlebo,
  setOpenEditPhlebo,
  editPhleboUser,
  updatePhlebo,
  zoneList,
  setZoneName,
  zoneName,
  loading,
  getAutoCompleteZone,
  getPhlebos,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const timer = useRef<any>(0);
  const [phoneNumber, setPhoneNumber] = useState<any>(
    editPhleboUser && editPhleboUser.user && editPhleboUser.user.phonenumber
  );
  const [phleboName, setPhleboName] = useState<any>(
    editPhleboUser && editPhleboUser.user && editPhleboUser.user.name
  );
  const [phleboUserName, setPhleboUserName] = useState<any>(
    editPhleboUser && editPhleboUser.user && editPhleboUser.user.username
  );
  const [phleboSource, setPhleboSource] = useState<string>(
    editPhleboUser?.u_type
  );
  const [employeId, setEmployeId] = useState<string>(editPhleboUser?.empid);
  const [baseCity, setBaseCity] = useState<string>(editPhleboUser?.base_city);
  const [mobileValidation, setMobileValidation] = useState({
    phoneNumber: true,
  })
  const [jobType, setJobType] = useState<string>(editPhleboUser.job_type);


  const handleClose = () => {
    setOpenEditPhlebo(false);
    getPhlebos();
  };
  const verifyMobile = (type: any) => {
    if (type === "mobile") {
      if (parseInt(phoneNumber && phoneNumber[0]) < 6 || phoneNumber && phoneNumber.length < 10) {
        setMobileValidation((prev: any) => ({
          ...prev,
          phoneNumber: false,
        }));
      }
    }

  };





  const CreateNewPhlebo = async () => {
    const body: any = {
      empid: employeId,
      fullname: phleboName,
      phonenumber: phoneNumber,
      username: phleboUserName,
      u_type: phleboSource,
      zone: zoneName || "",
      job_type: jobType
    };
    await updatePhlebo(
      body,
      editPhleboUser && editPhleboUser.user && editPhleboUser.user.phonenumber
    );
    setOpenEditPhlebo(false);
    getPhlebos();
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openEditPhlebo}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        {loading ? (
          <Loader />
        ) : (
          <Fade in={openEditPhlebo}>
            <div className={classes.paper}>
              <div className={classes.head}>
                <h4
                  style={{
                    marginBottom: "1rem",
                  }}
                  id="transition-modal-title"
                >
                  Edit Phlebo
                </h4>
                <CloseIcon onClick={() => handleClose()} />
              </div>

              <Grid
                container
                direction="row"
                alignItems="flex-start"
                justify="space-between"
                spacing={3}
                style={{ margin: "1rem auto" }}
              >
                <Grid item xs={12} sm={4} md={4}>
                  <h6>Employe Id</h6>
                  <TextField
                    name="empId"
                    type="text"
                    defaultValue={editPhleboUser?.empid}
                    value={employeId}
                    className="input"
                    variant="outlined"
                    placeholder="Enter Employe Id"
                    onChange={(e) => setEmployeId(e.target.value as string)}
                    style={{ width: "100%", minWidth: "200px" }}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <h6>Name</h6>
                  <TextField
                    name="name"
                    type="text"
                    defaultValue={
                      editPhleboUser &&
                      editPhleboUser.user &&
                      editPhleboUser.user.name
                    }
                    value={phleboName}
                    className="input"
                    variant="outlined"
                    placeholder="Phlebo Name"
                    onChange={(e) => setPhleboName(e.target.value as string)}
                    style={{ width: "100%", minWidth: "200px" }}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <h6>User Name</h6>
                  <TextField
                    name="username"
                    type="text"
                    defaultValue={
                      editPhleboUser &&
                      editPhleboUser.user &&
                      editPhleboUser.user.username
                    }
                    value={phleboUserName}
                    className="input"
                    variant="outlined"
                    placeholder="Phlebo Name"
                    onChange={(e) =>
                      setPhleboUserName(e.target.value as string)
                    }
                    style={{ width: "100%", minWidth: "200px" }}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={4}>

                  <h6>Phone Number</h6>
                  <TextField
                    name="number"
                    type="tel"
                    defaultValue={editPhleboUser && editPhleboUser.user && editPhleboUser.user.phonenumber}
                    value={phoneNumber}
                    className="input"
                    variant="outlined"
                    placeholder="Phone Number"
                    onChange={(e) => setPhoneNumber(e.target.value as string)}
                    style={{ width: "100%", minWidth: "200px" }}
                    onBlur={() => verifyMobile("mobile")}
                    onFocus={() => {
                      setMobileValidation((prev: any) => ({
                        ...prev,
                        phoneNumber: true
                      }))
                    }}
                    helperText={!mobileValidation.phoneNumber && "Incorrect Phone Number"}
                    required
                  />

                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <h6>Phlebo Source</h6>
                  <Select
                    className="input"
                    name="booking_status"
                    variant="outlined"
                    value={phleboSource}
                    style={{ width: "100%", margin: "0", padding: "0", height: "40px" }}
                    onChange={(e) => setPhleboSource(e.target.value as string)}
                    required
                  >
                    <MenuItem disabled value={"none"}>
                      Select Source
                    </MenuItem>
                    <MenuItem value={"rcl"}>RCL</MenuItem>
                    <MenuItem value={"kartvya"}>Kartvya</MenuItem>
                    <MenuItem value={"cc"}>CC</MenuItem>
                    <MenuItem value={"others"}>Others</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <h6>Job Type</h6>
                  <Select
                    className="input"
                    name="phlebo_sorce"
                    variant="outlined"
                    value={jobType}
                    style={{ width: "100%", margin: "0", padding: "0", height: "40px" }}
                    onChange={(e) => setJobType(e.target.value as string)}
                    required
                  >
                    <MenuItem disabled value={"none"}>
                      Job Type
                    </MenuItem>
                    <MenuItem value={"part_time"}>Part Time</MenuItem>
                    <MenuItem value={"full_time"}>Full Time</MenuItem>

                  </Select>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <h6>Select Zone</h6>
                  <Autocomplete
                    id="zone"
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let obj = JSON.parse(
                          JSON.stringify(newValue, null, " ")
                        );
                        setZoneName(obj.id);
                      }
                    }}
                    // defaultValue={
                    //   zoneList?.results &&
                    //   zoneList?.results.find((code: any) => {
                    //     return code.id === baseCity;
                    //   })
                    // }
                    value={
                      zoneList && zoneList?.results && zoneList?.results?.length > 0 &&
                      zoneList?.results.find((code: any) => {
                        if (code?.city?.name === baseCity) return code?.city?.name;
                      })
                    }
                    options={zoneList?.results || []}
                    freeSolo
                    blurOnSelect
                    getOptionLabel={(option: any) =>
                      `${option.id}, ${option?.city?.name}`
                    }
                    onInputChange={(event, newInputValue) => {
                      clearTimeout(timer.current);
                      timer.current = setTimeout(() => {
                        getAutoCompleteZone(newInputValue.split(",")[0]);
                      }, 1000);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Zone"
                        variant="outlined"
                        style={{ width: "100%", margin: "0", padding: "0" }}
                        className="input"
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    style={{ height: "50px" }}
                    onClick={CreateNewPhlebo}
                    disabled={!mobileValidation?.phoneNumber}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Fade>
        )}
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  phleboPhone: state.PhleboAdminReducer.phleboPhone,
  loading: state.PhleboAdminReducer.loading,
});

export default connect(mapStateToProps, {
  createNewPhlebo,
  updatePhlebo,
})(CreatePhlebo);
