export const ACTIONS = {
  CHANGE_PASSWORD: "change_password",
  SET_USER: 'login/set_user',
  LOGOUT: 'login/logout',
  SET_PROFILE: "login/set_profile",
  RESET_PASSWORD: "login/reset_password",
  SEND_OTP: "login/send_otp",
  SET_LOADING: 'login/set_loading',
  PANELUSER_LOGOUT:'login/paneluser_logout',
  GET_FIREBASE_NOTIFICATION:'login/get_leads',
  GET_USER_DETAILS:"login/get_user_details",
  GET_USER:"login/get_user_data",
  CREATE_MODULE : "login/create_module",
  POST_CREATE_MODULE : "login/post_create_module",
  POST_UPDATE_MODULE : "login/post_update_module",
  DELETE_CREATE_MODULE : "login/delete_create_module",
  PERFORMANCE_TABLE : "login/performance_table",
  ASSIGNMENT_QUESTION_MASTER : "login/assignment_question_master",
  PUT_ASSIGNMENT_ANSWER_MASTER : "login/put_assignment_answer_master",
  POST_ASSIGNMENT_ANSWER_MASTER : "login/post_assignment_answer_master",
  GET_AGENTS: "login/get_agents",
  LOCATION_DATA_FROM_PUSHER: "login/location_data_from_pusher",
  WHATSAPP_ADDRESS_ID_DETAIL: "login/whatsapp_address_id_detail",
  POST_BOOKING_INDEXING:"login/booking/booking-indexing/",
  ADD_TICKETS_COMMENT: "login/add_tickets_comment",
  SET_ALL_BOOKING_LOADING: "login/set_all_booking_loading",
  GET_CONTAINER_SLOTS: "login/get_container_slots",
};

interface SetUser {
  type: typeof ACTIONS.SET_USER
  payload: Array<string | number>
}
interface GetUserDetails {
    type: typeof ACTIONS.GET_USER_DETAILS
    payload: Array<any>
}
interface GetUserDeta {
    type: typeof ACTIONS.GET_USER
    payload: Array<any>
}
interface Logout {
  type: typeof ACTIONS.LOGOUT
  payload: any
}
interface PanelUserLogout {
  type: typeof ACTIONS.PANELUSER_LOGOUT
  payload: any
}
interface SetProfile {
  type: typeof ACTIONS.SET_PROFILE;
  payload: object;
}
interface LocationDataFromPusher {
  type: typeof ACTIONS.LOCATION_DATA_FROM_PUSHER
  payload: Array<any>
}
interface ResetPassword {
  type: typeof ACTIONS.RESET_PASSWORD;
  payload: object;
}
interface FireBaseToken {
  type: typeof ACTIONS.GET_FIREBASE_NOTIFICATION;
  payload: object;
}
interface SendOTP {
  type: typeof ACTIONS.SEND_OTP;
  payload: object;
}

interface SetLoadng {
  type: typeof ACTIONS.SET_LOADING
  payload: boolean
}
interface CreateModule {
  type: typeof ACTIONS.CREATE_MODULE
  payload: boolean
}
interface PostCreateModule {
  type: typeof ACTIONS.POST_CREATE_MODULE
  payload: boolean
}
interface PostUpdateModule {
  type: typeof ACTIONS.POST_UPDATE_MODULE
  payload: boolean
}
interface DeleteCreateModule {
  type: typeof ACTIONS.DELETE_CREATE_MODULE
  payload: boolean
}
interface PerformanceTable {
  type: typeof ACTIONS.PERFORMANCE_TABLE
  payload: boolean
}
interface AssignmentQuestionMaster {
  type: typeof ACTIONS.ASSIGNMENT_QUESTION_MASTER
  payload: boolean
}
interface PutAssignmentAnswerMaster {
  type: typeof ACTIONS.PUT_ASSIGNMENT_ANSWER_MASTER
  payload: boolean
}
interface PostAssignmentAnswerMaster {
  type: typeof ACTIONS.POST_ASSIGNMENT_ANSWER_MASTER
  payload: boolean
}
interface GetAgents {
  type: typeof ACTIONS.GET_AGENTS;
  payload: Array<any>;
}
interface WhatsappAddressIdDetail {
  type: typeof ACTIONS.WHATSAPP_ADDRESS_ID_DETAIL
  payload: Array<any>
}
interface AddTicketsComment {
  type: typeof ACTIONS.ADD_TICKETS_COMMENT;
  payload: Array<any>;
}

interface GetBookingsLoader {
  type: typeof ACTIONS.SET_ALL_BOOKING_LOADING;
  payload: boolean;
}
interface PostBookingIndexing {
  type: typeof ACTIONS.POST_BOOKING_INDEXING;
  payload: Array<any>;
}

interface GetContainerSlots {
  type: typeof ACTIONS.GET_CONTAINER_SLOTS;
  payload: Array<any>;
}

export type LoginActionTypes = SetUser | Logout | SetProfile | SetLoadng | ResetPassword | PanelUserLogout | FireBaseToken | GetUserDetails | GetUserDeta | CreateModule | PostCreateModule | DeleteCreateModule | PerformanceTable | AssignmentQuestionMaster | PutAssignmentAnswerMaster | PostAssignmentAnswerMaster | PostUpdateModule | GetAgents|LocationDataFromPusher|WhatsappAddressIdDetail | AddTicketsComment | GetBookingsLoader|PostBookingIndexing | GetContainerSlots
