import React, { useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Chip,
  Paper,
  Table,
  withStyles,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import Button from '@mui/material/Button';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Loader from "../Components/loader";
import moment from "moment";
import {
  getSyncData,
  getSMSLink,
  getSmsReportSend,
  getUnSyncBooking,
  clickToCallBooking,
  getReportDownload,
} from "../actions/productMangerAction";
import { MessageRounded } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import CallIcon from "@material-ui/icons/Call";
import SendIcon from "@material-ui/icons/Send";
import GetAppIcon from "@material-ui/icons/GetApp";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import SyncModal from "../Components/comments/syncModal";
import AdditionalSyncModal from "../Components/comments/additionMemberSyncModal";
import "./index.sass"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      marginTop: "2rem",
      padding: "0.5rem 2rem",
      width: "100%",
    },
    table: {
      maxWidth: "100%",
      margin: "auto",
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
  })
);

const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

interface Props {
  getBookings: any;
  bookingList: any;
  page: any;
  setPage: any;
  getSyncData: any;
  bookingCall: any;
  setopenModal: any;
  reportDownload: any;
  getUnSyncBooking: any;

  clickToCallBooking: any;
  getReportDownload: any;
  getSmsReportSend: any;
  setPaymentId: any;
  paymentId: any;
  openPaymentModal: any;
  setOpenPaymentModal: any;
  getSMSLink: any;
  openModal: any;
  loading: Boolean;
  setSyncId: any;
  openSyncModal: any;
  setSyncOpenModal: any;
  setBookingData: any;
  syncId: any;
  pageNumber: any;
  setPageNumber: any;
  setAdditionalSyncId: any;
  openAdditionalSyncModal: any;
  setSyncAdditionalOpenModal: any;
  setAdditonalBookingData: any;
  setBookingId: any;
  bookingListLoader: boolean;
}

const BookingsTable: React.FC<Props> = ({
  getBookings,
  bookingList,
  getSyncData,
  page,
  setPage,
  bookingCall,
  getSmsReportSend,
  getReportDownload,
  clickToCallBooking,
  reportDownload,
  getSMSLink,
  setopenModal,
  setPaymentId,
  setOpenPaymentModal,
  openPaymentModal,
  paymentId,
  openModal,
  getUnSyncBooking,
  setSyncId,
  syncId,
  openSyncModal,
  setSyncOpenModal,
  setBookingData,
  pageNumber,
  setPageNumber,
  setAdditionalSyncId,
  openAdditionalSyncModal,
  setSyncAdditionalOpenModal,
  setAdditonalBookingData,
  loading,
  setBookingId,
  bookingListLoader,
}) => {
  const classes = useStyles();
  const [reportSendStatus, setReportSendStatus] = useState(false);
  const [additionalMemebr, setAdditionalMemebr] = useState<any>();
  const [additionalModal, setAdditonalModal] = useState<boolean>(false);
  const history = useHistory();
  const handleClick = (id: number) => {
    setopenModal(true);
    setBookingId(id);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url = bookingList.links && bookingList.links.next.split("?")[1];
      getBookings(url);
      setPageNumber(url);
    } else if (newPage < page) {
      let url = bookingList.links && bookingList.links.previous.split("?")[1];
      getBookings(url);
      setPageNumber(url);
    }
    setPage(newPage as number);
  };
  const handleSync = async (id: any, data: any) => {
    setSyncId(id);
    setSyncOpenModal(true);
    setBookingData(data);
  };
  const handleUnSyncBooking = (id: any) => {
    getUnSyncBooking(`${id}/`);
    setTimeout(() => {
      getBookings(pageNumber);
    }, 1000);
  };
  const handleAdditionalUnSyncBooking = (id: any) => {
    getUnSyncBooking(`${id}/?is_add=true`);
    setTimeout(() => {
      getBookings(pageNumber);
    }, 1000);
  };
  const handleAdditionalSync = async (id: any, data: any) => {
    setAdditionalSyncId(id);
    setSyncAdditionalOpenModal(true);
    setAdditonalBookingData(data);
  };
  const handleSMSSend = (id: any) => {
    getSMSLink(id);
    alert("SMS Sent Successfully");
  };
  const handleSendReportSms = (id: any) => {
    getSmsReportSend(`${id}/booking`);
    setReportSendStatus(true);
    alert("Report SMS Send Successfully");
  };
  const handleCallClick = (id: number, type: string) => {
    clickToCallBooking(id, type);
  };
  const handleDownloadReport = (report: any, id: any) => {
    getReportDownload(`${id}/`);

    var b64 = report.allReportDetails.reportDetails;
    var bin = window.atob(b64);
    var link = document.createElement("a");
    link.download = `${report.allReportDetails.Patient_Name}-${report.allReportDetails.Age}.pdf`;
    link.href = "data:application/octet-stream;base64," + b64;
    link.click();
  };
  const handlePaymentInfo = (id: any) => {
    setPaymentId(id);
    setOpenPaymentModal(true);
    setPaymentId(id);
  };
  const handleAdditionalView = (member: any) => {
    setAdditionalMemebr(member);
    setAdditonalModal(true);
  };
  return (
    <div className="data-table" style={{ width: "100%" }}>
      <TableContainer
        className={classes.tableContainer}
        component={Paper}
        style={{ maxHeight: "590px" }}
      >
        {bookingListLoader ? (
          <Loader />
        ) : (
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell align="center">Action</StyledTableCell>
                <StyledTableCell align="center">Booking Id</StyledTableCell>
                <StyledTableCell align="center">Child Id</StyledTableCell>
                <StyledTableCell align="center">Lims Id</StyledTableCell>
                <StyledTableCell align="center">Sync Booking</StyledTableCell>
                <StyledTableCell align="center">UnSync</StyledTableCell>
                <StyledTableCell align="center">Booking Date</StyledTableCell>
                <StyledTableCell align="center">Name</StyledTableCell>
                <StyledTableCell align="center">Age</StyledTableCell>
                <StyledTableCell align="center">Gender</StyledTableCell>
                <StyledTableCell align="center">Email</StyledTableCell>
                <StyledTableCell align="center">Agent</StyledTableCell>
                <StyledTableCell align="center">
                  Collection Date
                </StyledTableCell>
                <StyledTableCell align="center">
                  Collection Time
                </StyledTableCell>
                <StyledTableCell align="center">Booking Time</StyledTableCell>
                <StyledTableCell align="center">Zone Name</StyledTableCell>
                <StyledTableCell align="center">Mobile Number</StyledTableCell>
                <StyledTableCell align="center">
                  Pickup Receive Amount
                </StyledTableCell>
                <StyledTableCell align="center">Booking Status</StyledTableCell>
                <StyledTableCell align="center">Report Status</StyledTableCell>
                {/* <StyledTableCell align="center">Verification Status</StyledTableCell> */}
                <StyledTableCell align="center">Pickup Status</StyledTableCell>
                <StyledTableCell align="center">Review SMS</StyledTableCell>
                <StyledTableCell align="center">Call</StyledTableCell>
                <StyledTableCell align="center">Comment</StyledTableCell>
                <StyledTableCell align="center">Payment Info</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {bookingList?.results &&
                bookingList?.results.length > 0 &&
                bookingList?.results?.map((booking: any, index: any) => {
                  const pendingData = booking?.additional_members?.filter(
                    (data: any) => data.pickup_status === "pending"
                  );
                  const confirmedData = booking.additional_members.filter(
                    (data: any) => data.pickup_status === "confirmed"
                  );
                  return (
                    <>
                      <StyledTableRow
                        style={{
                          backgroundColor:
                            booking?.booking_type === "b2b" &&
                            booking?.receivable_amount === 0
                              ? "#ffe082"
                              : booking?.booking_type === "b2b" &&
                                booking?.receivable_amount !== 0
                              ? "#f48fb1"
                              : booking?.booking_type === "b2c" &&
                                booking?.receivable_amount == 0
                              ? "#64ffda"
                              : "",
                        }}
                        key={index}
                      >
                        <StyledTableCell align="center">
                          <Link
                            to={`/dashboard/pm/booking-view/${booking?.pk}`}
                            style={{ textDecoration: "none" }}
                          >
                            <Button variant="contained" color="secondary">
                              View
                            </Button>
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {booking?.pk}
                        </StyledTableCell>
                        <StyledTableCell align="center"></StyledTableCell>
                        <StyledTableCell align="center">
                          {booking?.bill_id || "N/A"}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {booking?.bill_id === null &&
                          booking?.pickup_status === "confirmed" ? (
                            <Button
                              disabled={booking?.is_sync}
                              variant="contained"
                              color="inherit"
                              onClick={() => handleSync(booking?.pk, booking)}
                            >
                              Sync
                            </Button>
                          ) : (
                            "NA"
                          )}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {booking?.is_sync && booking?.bill_id !== null ? (
                            <Button
                              variant="contained"
                              color="inherit"
                              onClick={() => handleUnSyncBooking(booking?.pk)}
                            >
                              UnSync
                            </Button>
                          ) : (
                            "NA"
                          )}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {booking?.booking_date}
                        </StyledTableCell>
                        <StyledTableCell align="center">{`${
                          booking?.designation || ""
                        } ${booking?.customer_name}`}</StyledTableCell>
                        <StyledTableCell align="center">
                          {booking?.customer_age}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {booking?.customer_gender}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {booking?.customer_email}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {(booking?.agent && booking?.agent?.name) || "NA"}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {booking?.collection_date}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {booking?.collection_slot &&
                            moment(
                              `${
                                booking?.collection_slot?.slot?.split("-")[0]
                              }`,
                              ["HH.mm.ss"]
                            ).format("hh:mm A")}
                          {" - "}
                          {booking?.collection_slot &&
                            moment(
                              `${
                                booking?.collection_slot?.slot?.split("-")[1]
                              }`,
                              ["HH.mm.ss"]
                            ).format("hh:mm A")}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {" "}
                          {new Date(booking?.created_at).toLocaleString()}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {booking?.customer_areapincode.area}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {booking?.customer_phonenumber}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {booking?.pickup_receive_amount || "NA"}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <Chip
                            label={`${booking?.booking_status}`}
                            style={{
                              background:
                                booking?.booking_status === "pending"
                                  ? "#ffee58"
                                  : booking?.booking_status === "confirmed"
                                  ? "#00e676"
                                  : booking?.booking_status === "cancelled"
                                  ? "#ff3d00"
                                  : "#d500f9",
                            }}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <Chip
                            label={`${booking?.report_status}`}
                            style={{
                              background:
                                booking?.report_status === "pending"
                                  ? "#ffee58"
                                  : booking?.report_status === "consolidate"
                                  ? "#00e676"
                                  : booking?.report_status === "partial"
                                  ? "#d500f9"
                                  : "#ff3d00",
                            }}
                          />
                        </StyledTableCell>
                        {/* <StyledTableCell align="center"><Chip label={`${booking?.verification_status}`} style={{ background: booking?.verification_status === "pending" ? "#ffee58" : booking?.verification_status === "confirmed" ? "#00e676" : booking?.verification_status === "cancelled" ? "#ff3d00" : "#d500f9" }} /></StyledTableCell> */}
                        <StyledTableCell align="center">
                          <Chip
                            label={`${booking?.pickup_status}`}
                            style={{
                              background:
                                booking?.pickup_status === "pending"
                                  ? "#ffee58"
                                  : booking?.pickup_status === "confirmed"
                                  ? "#00e676"
                                  : booking?.pickup_status === "cancelled"
                                  ? "#ff3d00"
                                  : "#d500f9",
                            }}
                          />
                        </StyledTableCell>
                        {/* <TableCell align="center">
                                                    <BookingCountModal bookingCall={bookingCall} bookingId={booking.pk} />
                                                </TableCell> */}
                        <StyledTableCell align="center">
                          <SendIcon
                            onClick={() => handleSMSSend(booking?.pk)}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <CallIcon
                            onClick={() => handleCallClick(booking?.pk, "call")}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <div>
                            <MessageRounded
                              onClick={() => handleClick(booking?.pk)}
                            />
                          </div>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <LocalAtmIcon
                            onClick={() => handlePaymentInfo(booking?.pk)}
                          />
                        </StyledTableCell>
                      </StyledTableRow>
                      {booking?.additional_members &&
                        booking?.additional_members?.length > 0 &&
                        booking?.additional_members.map(
                          (data: any, index2: any) => {
                            return (
                              <StyledTableRow key={index}>
                                <StyledTableCell align="center">
                                  <Link
                                    to={`/dashboard/pm/booking-view/${booking?.pk}`}
                                    style={{ textDecoration: "none" }}
                                  >
                                    <Button
                                      variant="contained"
                                      style={{ backgroundColor: "#ff9100" }}
                                    >
                                      View
                                    </Button>
                                  </Link>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {booking?.pk}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {data?.id}
                                </StyledTableCell>
                                <StyledTableCell align="center"></StyledTableCell>
                                <StyledTableCell align="center">
                                  {data.bill_id === null &&
                                  data?.pickup_status === "confirmed" ? (
                                    <Button
                                      disabled={data.is_sync}
                                      variant="contained"
                                      color="inherit"
                                      onClick={() =>
                                        handleAdditionalSync(data.id, data)
                                      }
                                    >
                                      Sync
                                    </Button>
                                  ) : (
                                    "NA"
                                  )}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {" "}
                                  {data?.is_sync && data?.bill_id !== null ? (
                                    <Button
                                      variant="contained"
                                      color="inherit"
                                      onClick={() =>
                                        handleAdditionalUnSyncBooking(data.id)
                                      }
                                    >
                                      UnSync
                                    </Button>
                                  ) : (
                                    "NA"
                                  )}
                                </StyledTableCell>
                                <StyledTableCell align="center"></StyledTableCell>
                                <StyledTableCell align="center">{`${
                                  data?.designation || ""
                                } ${data?.customer_name}`}</StyledTableCell>
                                <StyledTableCell align="center">
                                  {data?.customer_age}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {data?.customer_gender}
                                </StyledTableCell>
                                <StyledTableCell align="center"></StyledTableCell>
                                <StyledTableCell align="center"></StyledTableCell>
                                <StyledTableCell align="center"></StyledTableCell>
                                <StyledTableCell align="center"></StyledTableCell>
                                <StyledTableCell align="center"></StyledTableCell>
                                <StyledTableCell align="center"></StyledTableCell>
                                <StyledTableCell align="center"></StyledTableCell>
                                <StyledTableCell align="center"></StyledTableCell>
                                <StyledTableCell align="center">
                                  <Chip
                                    label={`${data?.booking_status}`}
                                    style={{
                                      background:
                                        data?.booking_status === "pending"
                                          ? "#ffee58"
                                          : data.booking_status === "confirmed"
                                          ? "#00e676"
                                          : data.booking_status === "cancelled"
                                          ? "#ff3d00"
                                          : "#d500f9",
                                    }}
                                  />
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  <Chip
                                    label={`${data?.report_status}`}
                                    style={{
                                      background:
                                        data?.report_status === "pending"
                                          ? "#ffee58"
                                          : data.report_status === "consolidate"
                                          ? "#00e676"
                                          : data.report_status === "partial"
                                          ? "#d500f9"
                                          : "#ff3d00",
                                    }}
                                  />
                                </StyledTableCell>
                                {/* <StyledTableCell align="center">
                                  <Chip
                                    label={`${data?.report_verification_status}`}
                                    style={{
                                      background:
                                        data?.report_verification_status ===
                                        "pending"
                                          ? "#ffee58"
                                          : data.report_verification_status ===
                                            "confirmed"
                                          ? "#00e676"
                                          : data.report_verification_status ===
                                            "cancelled"
                                          ? "#ff3d00"
                                          : "#d500f9",
                                    }}
                                  />
                                </StyledTableCell> */}
                                <StyledTableCell align="center">
                                  <Chip
                                    label={`${data?.pickup_status}`}
                                    style={{
                                      background:
                                        data?.pickup_status === "pending"
                                          ? "#ffee58"
                                          : data.pickup_status === "confirmed"
                                          ? "#00e676"
                                          : data.pickup_status === "cancelled"
                                          ? "#ff3d00"
                                          : "#d500f9",
                                    }}
                                  />
                                </StyledTableCell>
                                <StyledTableCell align="center"></StyledTableCell>
                                <StyledTableCell align="center"></StyledTableCell>
                                <StyledTableCell align="center"></StyledTableCell>
                                {/* <StyledTableCell align="center"></StyledTableCell> */}
                                {/* <StyledTableCell align="center"></StyledTableCell> */}
                              </StyledTableRow>
                            );
                          }
                        )}
                    </>
                  );
                })}
            </TableBody>
            <TableFooter>
              <StyledTableRow>
                <TablePagination
                  rowsPerPageOptions={[]}
                   colSpan={3}
                  count={bookingList.count || 0}
                  rowsPerPage={bookingList.page_size}
                  page={page}
                  onPageChange={handleChangePage}
                />
              </StyledTableRow>
            </TableFooter>
          </Table>
        )}
      </TableContainer>
      {bookingList.results && bookingList.results.length === 0 && (
        <h6 style={{ textAlign: "center" }}>No Data Found</h6>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  bookingCall: state.ProductManagerReducer.bookingCall,
  reportDownload: state.ProductManagerReducer.reportDownload,
});

export default connect(mapStateToProps, {
  getSyncData,
  getSMSLink,
  getSmsReportSend,
  clickToCallBooking,
  getReportDownload,
  getUnSyncBooking,
})(BookingsTable);
