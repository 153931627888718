import React, { useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  withStyles,
} from "@material-ui/core";
import Button from '@mui/material/Button';
import "./index.sass"
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Loader from "../Components/loader/index";
import { MessageRounded } from "@material-ui/icons";
import {
  getQueryTickets,
  searchCall,
  getAllBookings,
  getPackage,
  getCollectionSlot,
} from "../actions/productMangerAction";
import { useEffect } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { generateQueryTicketLeadFilterUrl } from "../../helpers/generateUrl";
import RaiseComplaintModal from "../Components/comments/updateLeadModal";
import { Container, Grid, TextField } from "@material-ui/core";
import Box from "@mui/material/Box";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
      //   width: "100%",
      //   marginTop: "20px",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
      //   padding: theme.spacing(3),
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      marginTop: "2rem",
      padding: "0.5rem 2rem",
      width: "100%",
    },
    table: {
      maxWidth: "100%",
      margin: "auto",
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
  })
);
const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

interface Props {
  getQueryTickets: any;
  complaints: any;
  searchCall: any;
  bookingList: any;
  getAllBookings: any;
  loading: any;
  getPackage: any;
  packageList: any;
  getCollectionSlot: any;
  collectionSlot: any;
}

const BookingsTable: React.FC<Props> = ({
  getQueryTickets,
  complaints,
  getAllBookings,
  searchCall,
  bookingList,
  loading,
  getPackage,
  packageList,
  getCollectionSlot,
  collectionSlot,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [raiseComplaint, setRaiseComplaint] = useState(false);
  //  const [leadId, setLeadId] = useState(0)
  const [type, setType] = useState("");
  const [query, setQuery] = useState("");
  const [status, setStatus] = useState("");
  const [activeTab, setActiveTab] = React.useState<string>("");
  const [ticketId, setTicketId] = useState(0);
  const [lead, setLeadId] = useState<any>("");
  const [booking_date, setBooking_date] = useState("");
  const [collection_date, setCollection_date] = useState("");
  const [start_date, setStart_date] = useState("");
  const [ticket_Status, setTicketStatus] = useState<any>("");
  const [end_date, setEnd_date] = useState("");
  const [recivedBy, setRecivedBy] = useState<any>([]);
  const [bookingStatus, setBookingStatus] = useState<string>("");
  const [reciveRemark, setReciveRemark] = React.useState<any>("");

  const [ticketDetails, setTicketDetails] = useState<any>("");
  const handleOpenModal = (ticket: any) => {
    setLeadId(ticket.lead.id);
    setType(ticket.querytype);
    setQuery(ticket.query);
    setStatus(ticket.status);
    setBookingStatus(ticket.bookingStatus);
    setReciveRemark(ticket.reciveRemark);
    setRaiseComplaint(true);
    setTicketId(ticket.id);
    setTicketDetails(ticket);
    getAllBookings(`lead=${ticket.lead.id}`);
  };

  useEffect(() => {
    getQueryTickets("lead_ticket", activeTab);
    getPackage();
  }, [activeTab]);

  useEffect(() => {
    if (raiseComplaint === false) {
      getQueryTickets("lead_ticket", activeTab);
    }
  }, [raiseComplaint]);

  const [page, setPage] = useState(0);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url = complaints.links && complaints.links.next.split("?")[1];
      getQueryTickets(
        "lead_ticket",
        activeTab === "" ? url : `${activeTab}&${url}`
      );
    } else if (newPage < page) {
      let url = complaints.links && complaints.links.previous.split("?")[1];
      getQueryTickets(
        "lead_ticket",
        activeTab === "" ? url : `${activeTab}&${url}`
      );
    }
    setPage(newPage as number);
  };

  const viewLeadHandler = (id: any) => {
    let body = {
      search_by: "call_id",
      value: id || 0,
    };
    searchCall(body);
    history.push("/dashboard/pm/lead/search");
  };
  const handleReceivedTicket = (type: any) => {
    setActiveTab(`type=${type}`);
  };
  const handleAllTicket = () => {
    setActiveTab("");
  };
  const handleSendTicket = (type: any) => {
    setActiveTab(`type=${type}`);
  };

  const filterBookings = () => {
    const body: any = {
      id: lead,
      ticket_Status,
      start_date,
      end_date,
    };
    const url = generateQueryTicketLeadFilterUrl(body).substring(2);
    getQueryTickets("lead_ticket", `${url}&page=1`);
    setPage(0);
  };

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <h4 style={{ fontWeight: "bold" }}>LEAD TICKETS</h4>
      <Grid container spacing={1} direction="row">
        <Grid item xs={12} sm={4} md={2}>
          <TextField
            className="input"
            name="lead"
            type="number"
            placeholder="Lead Id"
            value={lead}
            variant="outlined"
            onChange={(e) => setLeadId(e.target.value)}
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Autocomplete
            id="ticket_status"
            onChange={(event, newValue) => {
              let sourceIds: any = [];
              if (newValue) {
                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                sourceIds = obj.map((item: any) => item.value);
              }
              setTicketStatus(sourceIds);
            }}
            options={[
              {
                text: "Pending",
                value: "pending",
              },
              {
                text: "Confirmed",
                value: "confirmed",
              },
              {
                text: "Cancelled",
                value: "cancelled",
              },
              {
                text: "Resampling",
                value: "resampling",
              },
              {
                text: "Hold",
                value: "hold",
              },
              {
                text: "Sample Not Received",
                value: "sample not received",
              },
            ]}
            freeSolo
            blurOnSelect
            aria-required
            limitTags={1}
            multiple
            getOptionLabel={(option: any) => option.text}
            getOptionDisabled={(option: any) => {
              return ticket_Status.includes(option.value);
            }}
            disableClearable
            disableCloseOnSelect
            renderInput={(params) => (
              <TextField
                className="input"
                {...params}
                placeholder="Ticket Status"
                variant="outlined"
                style={{ width: "100%", margin: "0", padding: "0" }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={4} md={2}>
          <TextField
            className="input"
            name="start_date"
            type="date"
            label="Start Date"
            value={start_date}
            variant="outlined"
            // disabled={created_date !== ""}
            onChange={(e) => setStart_date(e.target.value as string)}
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <TextField
            name="end_date"
            type="date"
            value={end_date}
            className="input"
            label="End Date"
            variant="outlined"
            // disabled={created_date !== ""}
            onChange={(e) => setEnd_date(e.target.value as string)}
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={filterBookings}
             disabled={loading}
          >
            Filter
          </Button>
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => history.push("/dashboard/pm/lead/tickets")}
          >
            Reset
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={1} direction="row" style={{ margin: "2rem" }}>
        <Grid item xs={12} sm={4} md={2}>
          <Button
            variant={activeTab == "" ? "contained" : "outlined"}
            color="primary"
            fullWidth
            onClick={() => handleAllTicket()}
          >
            All
          </Button>
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Button
            variant={activeTab == "type=received" ? "contained" : "outlined"}
            color="primary"
            fullWidth
            onClick={() => handleReceivedTicket("received")}
          >
            Received Ticket
          </Button>
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Button
            variant={activeTab == "type=send" ? "contained" : "outlined"}
            color="primary"
            fullWidth
            onClick={() => handleSendTicket("send")}
          >
            Send Ticket
          </Button>
        </Grid>
      </Grid>
      <div className="data-table">
        <TableContainer
          className={classes.tableContainer}
          component={Paper}
          style={{ maxHeight: "540px" }}
        >
          {loading ? (
            <Loader />
          ) : (
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell align="center">Lead Id</StyledTableCell>
                  <StyledTableCell align="center">Agent</StyledTableCell>
                  <StyledTableCell align="center">Comment</StyledTableCell>
                  <StyledTableCell align="center">Created At</StyledTableCell>
                  <StyledTableCell align="center">Query Type</StyledTableCell>
                  <StyledTableCell align="center">
                    Query Description
                  </StyledTableCell>
                  <StyledTableCell align="center">Status</StyledTableCell>
                  <StyledTableCell align="center">View</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {complaints?.results &&
                  complaints?.results?.length > 0 &&
                  complaints?.results?.map((ticket: any, index: any) => {
                    return (
                      <StyledTableRow key={index}>
                        <StyledTableCell align="center">
                          {ticket?.lead && ticket?.lead.id}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {ticket?.panel_user && ticket?.panel_user?.name}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <Button color="primary" variant="text">
                            <MessageRounded
                              onClick={() => handleOpenModal(ticket)}
                            />
                          </Button>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {new Date(ticket?.created_at).toLocaleString()}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {ticket?.querytype}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {ticket?.query}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {ticket?.status}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <Button
                            color="secondary"
                            variant="contained"
                            style={{ height: "40px" }}
                            onClick={() => viewLeadHandler(ticket?.lead?.id)}
                          >
                            View
                          </Button>
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
              </TableBody>
              <TableFooter>
                <StyledTableRow>
                  <TablePagination
                    rowsPerPageOptions={[]}
                     colSpan={3}
                    count={complaints.count || 0}
                    rowsPerPage={complaints.page_size}
                    page={page}
                    onPageChange={handleChangePage}
                  />
                </StyledTableRow>
              </TableFooter>
            </Table>
          )}
        </TableContainer>
      </div>
      <RaiseComplaintModal
        open={raiseComplaint}
        setOpen={setRaiseComplaint}
        ticketId={ticketId}
        queryType={type}
        queryDesc={query}
        queryStatus={status}
        bookingId={0}
        leadId={Number(lead)}
        ticketDetails={ticketDetails}
        bookingList={bookingList}
        packageList={packageList}
        getPackage={getPackage}
        getCollectionSlot={getCollectionSlot}
        collectionSlot={collectionSlot}
      />
    </main>
  );
};

const mapStateToProps = (state: any) => ({
  loading: state.ProductManagerReducer.loading,
  bookingList: state.ProductManagerReducer.bookingList,
  complaints: state.ProductManagerReducer.complaints,
  packageList: state.ProductManagerReducer.packageList,
  collectionSlot: state.ProductManagerReducer.collectionSlot,
});

export default connect(mapStateToProps, {
  getQueryTickets,
  searchCall,
  getAllBookings,
  getCollectionSlot,
  getPackage,
})(BookingsTable);
