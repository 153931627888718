export const GENDER_CHOICE = [
  {
    display_name: "Mrs",
    db_name: "Mrs",
    gender: "female",
  },
  {
    display_name: "Miss",
    db_name: "Miss",
    gender: "female",
  },
  {
    display_name: "Ms",
    db_name: "Ms",
    gender: "female",
  },
  {
    display_name: "Smt",
    db_name: "Smt",
    gender: "female",
  },
  {
    display_name: "Mr",
    db_name: "Mr",
    gender: "male",
  },
  {
    display_name: "Baby Boy",
    db_name: "Master",
    gender: "male",
  },
  {
    display_name: "Baby Girl",
    db_name: "Master",
    gender: "female",
  },
];

export const EXECUTIVE_TYPES = [
  {
    text: "Consultation",
    value: "Consultation",
  },
  {
    text: "Escalation",
    value: "Escalation",
  },
  {
    text: "Inbound",
    value: "Inbound",
  },
  {
    text: "LTV",
    value: "LTV",
  },
  {
    text: "Outbound",
    value: "Outbound",
  },
  {
    text: "RCA",
    value: "RCA",
  },
  {
    text: "Resampling",
    value: "Resampling",
  },
  {
    text: "Report",
    value: "Report",
  },
  {
    text: "Ticketing",
    value: "Ticketing",
  },
  {
    text: "CoCal",
    value: "CoCal",
  },
]

export const ADDUSER_EXECUTIVE_TYPES = [
  {
    text: "AreaManager",
    value: "AreaManager",
  },
  {
    text: "Manager",
    value: "Manager",
  },
]