import React, { useState, useEffect } from "react";
import clsx from "clsx";
import Snackbar from "@material-ui/core/Snackbar";
import { Link } from "react-router-dom";
import {
  createStyles,
  makeStyles,
  useTheme,
  Theme,
  fade,
} from "@material-ui/core/styles";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import SpeakerGroupIcon from '@mui/icons-material/SpeakerGroup';
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import MergeIcon from '@mui/icons-material/Merge';
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import AccountTreeTwoToneIcon from "@material-ui/icons/AccountTreeTwoTone";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import LaptopMacTwoToneIcon from "@material-ui/icons/LaptopMacTwoTone";
import TimeToLeaveIcon from '@mui/icons-material/TimeToLeave';
import {
  logout,
  panelUserLogout,
  getFirebaseNotification,
  getCreateModule,
} from "../../actions/loginActions";
import { useDispatch } from "react-redux";
import HomeIcon from "@material-ui/icons/Home";
import {
  Book,
  CloudUploadRounded,
  SettingsApplicationsRounded,
} from "@material-ui/icons";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Tooltip,
  InputAdornment
} from "@material-ui/core";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Button } from "@material-ui/core";
import {
  changePassword,
  getUserDetails,
  getNotificationMessage,
} from "../actions/RouteManagerAction";
import { ConfirmationNumberRounded } from "@material-ui/icons";
import HistoryIcon from "@material-ui/icons/History";
import AssignmentLateIcon from "@material-ui/icons/AssignmentLate";
import PreviewIcon from '@mui/icons-material/Preview';
import DeckIcon from "@material-ui/icons/Deck";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import WorkIcon from "@material-ui/icons/Work";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import CollectionsBookmarkIcon from "@material-ui/icons/CollectionsBookmark";
import FilterCenterFocusIcon from "@material-ui/icons/FilterCenterFocus";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import Popover from "@material-ui/core/Popover";
import AllInboxIcon from "@material-ui/icons/AllInbox";
import { useHistory } from "react-router";
import MoveDownIcon from '@mui/icons-material/MoveDown';
import Fade from "@material-ui/core/Fade";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { connect } from "react-redux";
import InvertColorsIcon from "@material-ui/icons/InvertColors";
import { getToken, onMessageListener } from "../../firebase";
import NewUpdateModal from "../components/Comments/newUpdate";
import QuizIcon from "@mui/icons-material/Quiz";
import Collections from "@material-ui/icons/Collections";
import DeveloperModeIcon from "@material-ui/icons/DeveloperMode";
import PaymentIcon from "@material-ui/icons/Payment";
import SecureStorage from '../../config/SecureStorage';
import AutoStoriesRoundedIcon from '@mui/icons-material/AutoStoriesRounded';

// Attempt Learning Component
import AttemptLearningComponent from "../../components/Learning/attemptLearningModule";

const drawerWidth = 240;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    title: {
      flexGrow: 1,
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "block",
      },
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: "none",
    },
    drawer: {
      color: "#fff",
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9) + 1,
      },
    },
    toolbar: {
      display: "flex",
      alignItems: "left",
      justifyContent: "flex-start",
      backgroundColor: "#924A91",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    dashbord: {
      color: "#fff",
      fontSize: "18px",
      fontWeight: "normal",
      padding: "20px 0px 10px 10px",
    },
    content: {
      width: "80%",
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    search: {
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      "&:hover": {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginLeft: 0,
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "auto",
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    inputRoot: {
      color: "inherit",
    },
    popover: {
      pointerEvents: "none",
    },
    paper: {
      padding: theme.spacing(1),
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: "12ch",
        "&:focus": {
          width: "20ch",
        },
      },
    },
  })
);

interface Props {
  getUserDetails: any;
  userDetails: any;
  getNotificationMessage: any;
  notificationMessage: any;
  passwordStatus: boolean;
  getCreateModule: any;
  createModuleData: any;
}
const PhleboAdmin: React.FC<Props> = ({
  getUserDetails,
  getNotificationMessage,
  userDetails,
  notificationMessage,
  passwordStatus,
  getCreateModule,
  createModuleData,
}) => {
  let dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [changePasswordModal, setchangePasswordModal] = React.useState(false);
  const [oldPassword, setOldPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [heights, setheights] = useState(window.innerHeight);
  const openPopover = Boolean(anchorEl);
  const [openRightMenu, setOpenRightMenu] = React.useState<boolean>(false);
  const audio = new Audio(
    "https://static.redcliffelabs.com/media/mixkit-modern-classic-door-bell-sound-113.mp3"
  );
  const [isTokenFound, setTokenFound] = useState(false);
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });
  const [openSnack, setOpenSnack] = useState<boolean>(false);
  const [anchorElUpdate, setAnchorElUpdate] = React.useState(null);
  const [openAttemptLearningComponent, setOpenAttemptLearningComponent] = React.useState(false);
  const [showOldPassword, setShowOldPassword] = React.useState(false);
  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const [showNewPasswordRepeat, setShowNewPasswordRepeat] = React.useState(false);
  const id = open ? "transitions-popper" : undefined;
  useEffect(() => {
    let token;

    async function tokenFunc() {
      token = await getToken(setTokenFound);
      if (token) {
        const body = {
          token,
        };
        dispatch(getFirebaseNotification(body));
      }
      return token;
    }

    tokenFunc();
  }, [setTokenFound]);

  onMessageListener()
    .then((payload) => {
      setShow(true);
      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
      });
    })
    .catch((err) => console.log("failed: ", err));

  const handleClick = (event: any) => {
    setOpenRightMenu(true);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setOpenRightMenu(false);
  };

  useEffect( () => {
    if (createModuleData && createModuleData?.count > 0 && createModuleData?.results[createModuleData?.count - 1]?.score_by_user === "Fail") {
      if (localStorage.getItem("tokeninlocal") === SecureStorage.getItem("token")) setOpenAttemptLearningComponent(true);
    }
    if ((createModuleData && createModuleData?.count > 0 && createModuleData?.results[createModuleData?.count - 1]?.score_by_user === "Pass") || (createModuleData && createModuleData?.count === 0)) {
      localStorage.setItem("tokeninlocal", "delete");
    }
  }, [createModuleData])



  useEffect(() => {
    getUserDetails();
    if (createModuleData?.length === 0 && localStorage.getItem("tokeninlocal") !== "delete") {
      getCreateModule();
    }
  }, []);

  useEffect(() => {
    if (!passwordStatus) {
      setchangePasswordModal(true);
    }
  }, [passwordStatus]);

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
    getNotificationMessage(`?is_read=false`);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const updatePassword = async () => {
    const body: any = {
      old_password: oldPassword,
      new_password: newPassword,
      confirm_password: confirmPassword,
    };
    await dispatch(changePassword(body));
    setchangePasswordModal(false);
    dispatch(logout());
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleNotification = () => {
    history.push("/dashboard/rm/inbox");
  };
  const handleLogout = () => {
    dispatch(panelUserLogout());
    dispatch(logout());
  };
  useEffect(() => {
    if (notification.body !== "") {
      setOpenSnack(true);
      audio.play();
    }
  }, [notification]);
  const handleSnackClose = () => {
    setOpenSnack(false);
  };
  const handleUpdateDetails = (event: any) => {
    setAnchorElUpdate(anchorElUpdate ? null : event.currentTarget);
  };
  return (
    <div className={classes.root}>
      {  openAttemptLearningComponent && 
         <AttemptLearningComponent 
          open={openAttemptLearningComponent}
          setOpen={setOpenAttemptLearningComponent}
        />
      }
      <Snackbar
        // anchorOrigin={{ vertical, horizontal }}
        open={openSnack}
        onClose={handleSnackClose}
        message={notification.body}
      />
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar style={{ backgroundColor: "#924A91" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography className={classes.title} variant="h6" noWrap>
            Redcliffelabs
          </Typography>
          <div>
            <Button
              variant="text"
              onClick={(e) => handleClick(e)}
              size="medium"
              endIcon={<KeyboardArrowDownIcon />}
              style={{ color: "#fff" }}
            >
              {userDetails &&
                userDetails.profile &&
                userDetails.profile.username}
            </Button>
            <Menu
              id="fade-menu"
              open={openRightMenu}
              onClose={handleClose}
              TransitionComponent={Fade}
              anchorEl={anchorEl}
            >
              <MenuItem onClick={() => setchangePasswordModal(true)}>
                Change Password
              </MenuItem>
              <MenuItem onClick={() => handleLogout()}>Logout</MenuItem>
            </Menu>
          </div>
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-owns={openPopover ? "mouse-over-popover" : undefined}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            onClick={handleNotification}
            color="inherit"
          >
            <NotificationsActiveIcon />
          </IconButton>
          <IconButton>
            <DeveloperModeIcon
              aria-describedby={id}
              onClick={handleUpdateDetails}
            />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <Dialog
          open={changePasswordModal}
          onClose={() => setchangePasswordModal(false)}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Change Password</DialogTitle>
          <DialogContent>
            <TextField
              id="name"
              placeholder="Old Password"
              type={showOldPassword ? "text" : "password"}
              fullWidth
              style={{
                width: "100%",
                margin: "0.3rem auto",
                padding: "14px !important",
              }}
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <div onClick={() => setShowOldPassword((previousState: any) => !previousState)}>
                    <InputAdornment position="end">
                        {showOldPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </InputAdornment>
                  </div>
                )
              }}
            />
            <TextField
              id="name"
              placeholder="New Password"
              type={showNewPassword ? "text" : "password"}
              fullWidth
              style={{
                width: "100%",
                margin: "0.3rem auto",
                padding: "14px !important",
              }}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <div onClick={() => setShowNewPassword((previousState: any) => !previousState)}>
                    <InputAdornment position="end">
                        {showNewPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </InputAdornment>
                  </div>
                )
              }}
            />
            <TextField
              id="name"
              placeholder="Confirm Password"
              type={showNewPasswordRepeat ? "text" : "password"}
              fullWidth
              style={{
                width: "100%",
                margin: "0.3rem auto",
                padding: "14px !important",
              }}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <div onClick={() => setShowNewPasswordRepeat((previousState: any) => !previousState)}>
                    <InputAdornment position="end">
                        {showNewPasswordRepeat ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </InputAdornment>
                  </div>
                )
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => updatePassword()} color="primary">
              Update
            </Button>
            <Button
              onClick={() => setchangePasswordModal(false)}
              color="primary"
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <div className={classes.toolbar}>
          <span className={classes.dashbord}>Dashboard</span>
          <IconButton onClick={handleDrawerClose} style={{ color: "#fff" }}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          <Link to="/dashboard/rm">
            <ListItem button>
              <Tooltip title="Home" arrow>
                <ListItemIcon>
                  <HomeIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Home" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/rm/bookings_assign">
            <ListItem button>
              <Tooltip title="Assign Bookings" arrow>
                <ListItemIcon>
                  <AssignmentTurnedInIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Assign Bookings" />
            </ListItem>
          </Link>
        </List>
         <List>
          <Link to="/dashboard/rm/attendance">
            <ListItem button>
              <Tooltip title="Attendance" arrow>
                <ListItemIcon>
                  <MenuBookIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Attendance" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/rm/batch-amount-transfer">
            <ListItem button>
              <Tooltip title="Batch Amount Transfer" arrow>
                <ListItemIcon>
                  <MoveDownIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Batch Amount Transfer" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/rm/batched_payment">
            <ListItem button>
              <Tooltip title="Batched Payment" arrow>
                <ListItemIcon>
                  <PaymentIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Batched Payment" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/rm/booking_list">
            <ListItem button>
              <Tooltip title="Booking List" arrow>
                <ListItemIcon>
                  <Book style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Booking List" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/rm/booking/tickets">
            <ListItem button>
              <Tooltip title="Booking Tickets" arrow>
                <ListItemIcon>
                  <ConfirmationNumberRounded style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Booking Tickets" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/rm/call_recording">
            <ListItem button>
              <Tooltip title="Call Recording" arrow>
                <ListItemIcon>
                  <HistoryIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Call Recording" />
            </ListItem>
          </Link>
        </List>
        {/* <List>
          <Link to="/dashboard/rm/cc_booking_list">
            <ListItem button>
              <Tooltip title="CC Booking List" arrow>
                <ListItemIcon>
                  <LaptopMacTwoToneIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="CC Booking List" />
            </ListItem>
          </Link>
        </List> */}
        <List>
          <Link to="/dashboard/rm/centerInfo">
            <ListItem button>
              <Tooltip title="Center Information" arrow>
                <ListItemIcon>
                  <FilterCenterFocusIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Center Information" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/rm/cityWisePhlebos">
            <ListItem button>
              <Tooltip title="City Wise Phlebos" arrow>
                <ListItemIcon>
                  <LocationCityIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="City Wise Phlebos" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/rm/container_delivery_booking">
            <ListItem button>
              <Tooltip title="Container Delivery Booking" arrow>
                <ListItemIcon>
                  <LocalShippingIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Container Delivery Booking" />
            </ListItem>
          </Link>
        </List>
       
       
        <List>
          <Link to="/dashboard/rm/currentDayBooking">
            <ListItem button>
              <Tooltip title="Current Day Bookings" arrow>
                <ListItemIcon>
                  <AssignmentLateIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Current Day Booking" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/rm/bookings/export">
            <ListItem button>
              <Tooltip title="Export Booking" arrow>
                <ListItemIcon>
                  <CloudUploadRounded style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Export Booking" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/rm/helpdesk">
            <ListItem button>
              <Tooltip title="Help Desk" arrow>
                <ListItemIcon>
                  <QuizIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary=" Help Desk" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/rm/holiday">
            <ListItem button>
              <Tooltip title="Holiday Calender" arrow>
                <ListItemIcon>
                  <TimeToLeaveIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Holiday Calender" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/rm/inbox">
            <ListItem button>
              <Tooltip title="Inbox" arrow>
                <ListItemIcon>
                  <AllInboxIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Inbox" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/rm/users">
            <ListItem button>
              <Tooltip title="Manage Phlebos" arrow>
                <ListItemIcon>
                  <SettingsApplicationsRounded style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Manage Phlebos" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/rm/pincodes">
            <ListItem button>
              <Tooltip title="Manage Pincodes" arrow>
                <ListItemIcon>
                  <WorkIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Manage Pincodes" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/rm/zones">
            <ListItem button>
              <Tooltip title="Manage Zones" arrow>
                <ListItemIcon>
                  <DeckIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Manage Zones" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/rm/multipleBookingPaymentUpload">
            <ListItem button>
              <Tooltip title="Multiple Booking Payment Upload" arrow>
                <ListItemIcon>
                  <SpeakerGroupIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Multiple Booking Payment Upload" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/rm/cash-due-on-pha-rider">
            <ListItem button>
              <Tooltip title="Phlebo and Rider Due" arrow>
                <ListItemIcon>
                  <MoneyOffIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Phlebo and Rider Due" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/rm/phleboridermap">
            <ListItem button>
              <Tooltip title="Phlebo Rider Map" arrow>
                <ListItemIcon>
                  <MergeIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="PhleboRiderMap" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/rm/route_plan">
            <ListItem button>
              <Tooltip title="Route Plan" arrow>
                <ListItemIcon>
                  <AccountTreeTwoToneIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Route Plan" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/rm/SampleHandOvers">
            <ListItem button>
              <Tooltip title="Sample HandOver" arrow>
                <ListItemIcon>
                  <Collections style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Sample HandOver" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/rm/testPackages">
            <ListItem button>
              <Tooltip title="Test Packages" arrow>
                <ListItemIcon>
                  <InvertColorsIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Test Packages" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/rm/learningmodule">
            <ListItem button>
              <Tooltip title="Learning Component" arrow>
                <ListItemIcon>
                  <AutoStoriesRoundedIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Learning Module" />
            </ListItem>
          </Link>
        </List>
        {/* <List>
          <Link to="/dashboard/rm/phleboBookingView">
            <ListItem button>
              <Tooltip title="Phelbo Booking View" arrow>
                <ListItemIcon>
                  <TransferWithinAStationIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Phelbo Booking View" />
            </ListItem>
          </Link>
        </List> */}
        <List>
          <Link to="/dashboard/rm/verifiedbooking">
            <ListItem button>
              <Tooltip title="Verified Booking" arrow>
                <ListItemIcon>
                  <CollectionsBookmarkIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Verified Booking" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/rm/views">
            <ListItem button>
              <Tooltip title="Views" arrow>
                <ListItemIcon>
                  <PreviewIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Views" />
            </ListItem>
          </Link>
        </List> 
        <Divider />
        <List>
          <ListItem button onClick={() => handleLogout()}>
            <Tooltip title="Logout" arrow>
              <ListItemIcon>
                <ExitToAppIcon style={{ color: "#924A91" }} />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </Drawer>
      {notificationMessage &&
        notificationMessage?.results &&
        notificationMessage?.results?.length > 0
        ? notificationMessage?.results.map((data: any, index: any) => {
          return (
            <Popover
              id="mouse-over-popover"
              className={classes.popover}
              classes={{
                paper: classes.paper,
              }}
              open={openPopover}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              <Typography>{index + 1 + "." + data?.notification}</Typography>
            </Popover>
          );
        })
        : null}
      <NewUpdateModal
        anchorElUpdate={anchorElUpdate}
        setAnchorElUpdate={setAnchorElUpdate}
        id={id}
      />
    </div>
  );
};
const mapStateToProps = (state: any) => ({
  userDetails: state.RouteManagerReducer.userDetails,
  notificationMessage: state.RouteManagerReducer.notificationMessage,
  passwordStatus: state.loginReducer.passwordStatus,
  createModuleData: state.loginReducer.createModuleData,
});

export default connect(mapStateToProps, {
  getUserDetails,
  getNotificationMessage,
  getCreateModule,
})(PhleboAdmin);
