import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from '@mui/material/Modal';
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Button, TextField, Grid } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { getCities, createArea } from '../../actions/productMangerAction';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useRef } from "react";

let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: isMobile ? "400px" : "1200px",
    },
    head: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "2rem"
    }
}));

type ModalProps = {
    createArea: any;
    area: any;
    getCities: any;
    cities: any;
    open: boolean;
    setOpen: Function;
};

const CommentsModal2: React.FC<ModalProps> = ({
    open,
    setOpen,
    createArea,
    area,
    getCities,
    cities,
}) => {
    const classes = useStyles();

    const [pincode, setPincode] = useState("")
    const [areaName, setAreaName] = useState("")
    const [city, setCity] = useState(0)

    const timer = useRef<any>(0)

    const handleClose = () => {
        setOpen(false);
    };

    const createNewArea = async () => {
        const body: any = {
            pincode,
            area: areaName,
            city
        }
        await createArea(body)
        handleClose()
    };

    useEffect(() => {
        getCities()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <div className={classes.head}>
                            <h4
                                style={{
                                    marginBottom: "1rem",
                                }}
                                id="transition-modal-title"
                            >
                                Create Area
                            </h4>
                            <CloseIcon onClick={() => setOpen(false)} />
                        </div>
                        <Grid container direction="row" alignItems="flex-start" justify="space-between" spacing={3} style={{ margin: "1rem auto" }}>
                            <Grid item xs={12} sm={6} md={4}>
                                <TextField
                                    name="pincode"
                                    type="text"
                                    value={pincode}
                                    className="input"
                                    variant="outlined"
                                    placeholder="Pincode"
                                    onChange={(e) => setPincode(e.target.value as string)}
                                    style={{ width: "100%" }}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <TextField
                                    name="area"
                                    type="text"
                                    value={areaName}
                                    className="input"
                                    variant="outlined"
                                    placeholder="Area"
                                    onChange={(e) => setAreaName(e.target.value as string)}
                                    style={{ width: "100%" }}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Autocomplete
                                    id="city"
                                    onChange={(event, newValue) => {
                                        if (newValue) {
                                            let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                            setCity(obj.id)
                                        }
                                    }}
                                    options={cities}
                                    freeSolo
                                    blurOnSelect
                                    aria-required
                                    getOptionLabel={(option: any) => option.name}
                                    onInputChange={(event, newInputValue) => {
                                        clearTimeout(timer.current)
                                        timer.current = setTimeout(() => {
                                            getCities(newInputValue)
                                        }, 1000)
                                        if (newInputValue.length === 0) {
                                            setCity(0)
                                        }
                                    }}
                                    renderInput={(params) => (
                                        <TextField className="input"
                                            {...params}
                                            placeholder="City"
                                            variant="outlined"
                                            style={{ width: "100%", margin: "0", padding: "0" }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    style={{ height: "50px" }}
                                    onClick={createNewArea}
                                >
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
};

const mapStateToProps = (state: any) => ({
    area: state.ProductManagerReducer.area,
    cities: state.ProductManagerReducer.cities,
    loading: state.ProductManagerReducer.loading
});

export default connect(mapStateToProps, {
    createArea,
    getCities
})(CommentsModal2);
