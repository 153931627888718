import React, { useState, useRef, useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Button from '@mui/material/Button';
import { connect } from "react-redux";
import Checkbox from "@material-ui/core/Checkbox";
import { Grid, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {
  partnerBulkUploadBooking,
  getPartners,
  getPinCode,
} from "../../actions/BulkUploadMasterAction";
import './filter.sass'
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
      marginTop: "20px",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be bel
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
      padding: theme.spacing(3),
    },
    card: {
      height: "100%",
      padding: theme.spacing(1),
    },
    card_grid: {
      wordSpacing: 3,
      justifyContent: "space-between",
    },
  })
);

interface Props {
  loading: boolean;
  partnerBulkUploadBooking: any;
  getPartners: any;
  packageList: any;
  getPinCode: any;
  pin_code: any;
  partnerList: any;
}

const CustomUploader: React.FC<Props> = ({
  loading,
  getPartners,
  partnerList,
  getPinCode,
  pin_code,
  partnerBulkUploadBooking,
}) => {
  const timer = useRef<any>(0);
  const classes = useStyles();
  const [file, setFile] = React.useState<any>([]);
  const [file_name, setFileName] = React.useState<string>("");
  const [buttonState, setButtonState] = useState<boolean>(false);
  const [collection_date, setCollection_date] = useState("");
  const [booking_date, setBooking_date] = useState("");
  const [partner, setPartner] = useState<any>([]);
  const [pincode, setPinCode] = useState<any>("");
  const [cityId, setCityId] = useState<any>("");

  const handleFileChange = (e: React.ChangeEvent<{ files: any }>) => {
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);

  };
  const submitFile = async () => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("collection_date", collection_date);
    formData.append("booking_date", booking_date);
    formData.append("partner", partner);
    formData.append("customer_areapincode", pincode);

    await partnerBulkUploadBooking(formData);
    setButtonState(true);
    setTimeout(() => {
      setButtonState(false);
    }, 1000 * 60 * 10);
  };
 
  useEffect(() => {
    getPartners("");
    getPinCode();
  }, []);

  const handlePinCode = (val: String) => {
    setPinCode(val as string);
    const { cityid } = pin_code.results.find(
      (code: any) => code.pincode === val
    );
    setCityId(cityid);
  };
  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <Grid xs={12} sm={8} md={6} lg={6} spacing={3}>
        <Grid item xs={12} md={8}>
          <h4 style={{ fontWeight: "bold" }}>PARTNER UPLOADER</h4>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        direction="row"
        style={{ alignItems: "left", marginTop: "20px" }}
      >
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            name="collection_date"
            type="date"
            value={collection_date}
            className="input"
            label="Collection Date"

            variant="outlined"
            onChange={(e) => setCollection_date(e.target.value as string)}
            style={{ width: "100%", height: "56px", }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            name="booking_date"
            type="date"
            value={booking_date}
            className="input"
            label="Booking Date"
            variant="outlined"
            onChange={(e: any) => setBooking_date(e.target.value as string)}
            style={{ width: "100%" }}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Autocomplete
            id="pincode"
            onChange={(event, newValue) => {
              if (newValue) {
                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                handlePinCode(obj.id as String);
              }
            }}
            options={pin_code.results}
            freeSolo
            blurOnSelect
            aria-required
            getOptionLabel={(option: any) =>
              `${option.pincode}, ${option.area}`
            }
            onInputChange={(event, newInputValue) => {
              clearTimeout(timer.current);
              timer.current = setTimeout(() => {
                getPinCode(newInputValue.split(",")[0]);
              }, 1000);
            }}
            renderInput={(params) => (
              <TextField
                className="input"
                {...params}
                placeholder="Pin Code"
                variant="outlined"
                style={{ width: "100%", margin: "0", padding: "0", height: "56px", }}
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Autocomplete
            id="partner"
            onChange={(event, newValue) => {
              let sourceIds: any = [];
              if (newValue) {
                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                sourceIds = obj.map((item: any) => item.id);
              }
              setPartner(sourceIds);
            }}
            freeSolo
            blurOnSelect
            limitTags={1}
            multiple
            options={partnerList?.results || []}

            getOptionDisabled={(option: any) => {
              return partner.includes(option.id);
            }}
            disableClearable
            disableCloseOnSelect
            getOptionLabel={(option: any) => option.user && option.user.name}
            onInputChange={(event, newInputValue) => {
              clearTimeout(timer.current);
              timer.current = setTimeout(() => {
                getPartners(newInputValue);
              }, 1000);
              if (newInputValue.length === 0) {
                setPartner([]);
              }
            }}
            renderInput={(params) => (
              <TextField
                className="input"
                {...params}
                label="Partner"
                variant="outlined"
                style={{ width: "100%", margin: "0", padding: "0", }}
              />
            )}
          />

        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        direction="row"
        style={{ alignItems: "left", marginTop: "20px" }}
      >
        <Grid item xs={12} sm={6} md={4}>
          <input
            type="text/csv"
            style={{
              width: "100%",
              padding: "6px 16px",
              marginRight: "5px",
            }}
            value={file_name}
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Button
            fullWidth

            variant="contained"
            component="label"
            color="secondary"
          >
            Select File
            <input
              type="file"
              name="file"
              id="file"
              hidden
              onChange={handleFileChange}
            />
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <a
            href="https://staticredcliffelabs.s3.amazonaws.com/media/gallary-file/None/7d9fdbc3-56ed-4603-a7bc-ac559ea80000.csv"
            target="_black"
          >
            <Button
              fullWidth
              style={{ background: "#4caf50" }}
              variant="contained"
              component="label"
            >
              Download File
            </Button>
          </a>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={1}
     
      >
        <Grid item xs={12} sm={4} md={2}>
          <Button
            variant="contained"
            fullWidth
            component="label"
            style={{
              backgroundColor: "#924A91",
              color: "#fff",
              width: "100%",
              marginTop: "20px",

            }}
            onClick={submitFile}
            disabled={buttonState}
          >
            Upload
          </Button>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
      >
        <Grid item xs={12} md={8}>
          <small>
            <ul style={{ paddingLeft: "15px", marginTop: "20px" }}>
              <li>
                Note : Booking date and Collection date format Should be
                YYYY:MM:DD{" "}
              </li>
            </ul>
          </small>
        </Grid>
      </Grid>
    </main>
  );
};

const mapStateToProps = (state: any) => ({
  loading: state.BulkUploadMasterReducer.loading,
  partnerList: state.BulkUploadMasterReducer.partnerList,
  pin_code: state.BulkUploadMasterReducer.pin_code,
});

export default connect(mapStateToProps, {
  partnerBulkUploadBooking,
  getPartners,
  getPinCode,
})(CustomUploader);