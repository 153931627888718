import React, { useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Chip,
  Paper,
  withStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Loader from "../components/loader/index";
import moment from "moment";
import { MessageRounded } from "@material-ui/icons";
import { getSyncData } from "../actions/LogisticsPanelAction";
import AdditonalFamilyModal from "../tables/AdditionalFamily/AdditionFamilyDetails";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      marginTop: "2rem",
      // padding: "0.5rem 1rem",
      width: "100%",
    },
    table: {
      maxWidth: "100%",
      margin: "auto",
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
  })
);
const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

interface Props {
  getBookings: any;
  getAllNewBookings: any;
  bookingList: any;
  getSyncData: any;
  page: any;
  setPage: any;
  setopenModal: any;
  openModal: any;
  loading: Boolean;
  bookingId: any;
  setBookingId: any;
  bookingListLoader: boolean;
}

const BookingsTable: React.FC<Props> = ({
  getBookings,
  getAllNewBookings,
  bookingList,
  getSyncData,
  page,
  setPage,
  setopenModal,
  openModal,
  bookingId,
  setBookingId,
  loading,
  bookingListLoader,
}) => {
  const classes = useStyles();

  const [additionalMemebr, setAdditionalMemebr] = useState<any>();
  const [additionalModal, setAdditonalModal] = useState<boolean>(false);
  const handleClick = (id: number) => {
    setopenModal(true);
    setBookingId(id);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url = bookingList.links && bookingList.links.next.split("?")[1];
      // getBookings(url);
      getAllNewBookings(url);
    } else if (newPage < page) {
      let url = bookingList.links && bookingList.links.previous.split("?")[1];
      // getBookings(url);
      getAllNewBookings(url);
    }
    setPage(newPage as number);
  };
  const handleSync = (id: any) => {
    getSyncData(id);
    alert("Confirmed");
    setTimeout(() => {
      getBookings();
    }, 1000);
  };
  const handleAdditionalView = (member: any) => {
    setAdditionalMemebr(member);
    setAdditonalModal(true);
  };
  return (
    <div style={{ width: "100%" }} className="data-table">
      <TableContainer
        className={classes.tableContainer}
        component={Paper}
        style={{ maxHeight: "560px" }}
      >
        {bookingListLoader ? (
          <Loader />
        ) : (
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell align="center">Action</StyledTableCell>
                <StyledTableCell align="center">Booking Id</StyledTableCell>
                <StyledTableCell align="center">Lims Id</StyledTableCell>
                <StyledTableCell align="center">Comment</StyledTableCell>
                <StyledTableCell align="center">Created At</StyledTableCell>
                <StyledTableCell align="center">Booking Date</StyledTableCell>
                <StyledTableCell align="center">Name</StyledTableCell>
                <StyledTableCell align="center">Agent</StyledTableCell>
                <StyledTableCell align="center">Age</StyledTableCell>
                <StyledTableCell align="center">Gender</StyledTableCell>
                <StyledTableCell align="center">Email</StyledTableCell>
                <StyledTableCell align="center">
                  Collection Date
                </StyledTableCell>
                <StyledTableCell align="center">
                  Collection Time
                </StyledTableCell>
                <StyledTableCell align="center">Booking Time</StyledTableCell>
                <StyledTableCell align="center">Zone Name</StyledTableCell>
                <StyledTableCell align="center">Mobile Number</StyledTableCell>
                <StyledTableCell align="center">Booking Status</StyledTableCell>
                <StyledTableCell align="center">Report Status</StyledTableCell>
                {/* <StyledTableCell align="center">Verification Status</StyledTableCell> */}
                <StyledTableCell align="center">Pickup Status</StyledTableCell>
                <StyledTableCell align="center">
                  Pickup Receive Amount
                </StyledTableCell>
                {/* <StyledTableCell align="center">Sync</StyledTableCell> */}
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {bookingList.results &&
                bookingList?.results.length > 0 &&
                bookingList?.results.map((booking: any, index: any) => {
                  const pendingData = booking?.additional_members.filter(
                    (data: any) => data?.pickup_status === "pending"
                  );
                  const confirmedData = booking?.additional_members.filter(
                    (data: any) => data?.pickup_status === "confirmed"
                  );
                  return (
                    <StyledTableRow
                      style={{
                        background:
                          booking?.red_booking_color === true ? "red" : "",
                      }}
                      key={index}
                    >
                      <StyledTableCell align="center">
                        <Link
                          to={`/dashboard/lp/booking-view/${booking?.pk}`}
                          style={{ textDecoration: "none" }}
                        >
                          <Button variant="contained" color="secondary">
                            View
                          </Button>
                        </Link>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {booking?.additional_members?.length > 0 ? (
                          <div>
                            {booking?.pk}+
                            <Chip
                              label={
                                "T" +
                                booking?.additional_members?.length +
                                `${confirmedData.length > 0
                                  ? "C" + confirmedData.length
                                  : ""
                                }` +
                                `${pendingData.length > 0
                                  ? "P" + pendingData.length
                                  : ""
                                }`
                              }
                              style={{
                                background:
                                  pendingData.length > 0
                                    ? "#e91e63"
                                    : "#1b5e20",
                              }}
                              onClick={() =>
                                handleAdditionalView(
                                  booking?.additional_members
                                )
                              }
                            />
                          </div>
                        ) : (
                          booking.pk
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {booking?.bill_id || "N/A"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <div>
                          <MessageRounded
                            onClick={() => handleClick(booking?.pk)}
                          />
                        </div>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {new Date(booking?.created_at).toLocaleString()}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {booking?.booking_date}
                      </StyledTableCell>
                      <StyledTableCell align="center">{`${booking?.designation || ""
                        } ${booking?.customer_name}`}</StyledTableCell>
                      <StyledTableCell align="center">
                        {(booking?.agent && booking?.agent.name) || "NA"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {booking?.customer_age}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {booking?.customer_gender}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {booking?.customer_email}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {booking?.collection_date}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {booking?.collection_slot &&
                          moment(
                            `${booking?.collection_slot?.slot.split("-")[0]}`,
                            ["HH.mm.ss"]
                          ).format("hh:mm A")}
                        {" - "}
                        {booking?.collection_slot &&
                          moment(
                            `${booking?.collection_slot?.slot.split("-")[1]}`,
                            ["HH.mm.ss"]
                          ).format("hh:mm A")}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {" "}
                        {new Date(booking?.created_at).toLocaleString()}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {booking?.customer_areapincode?.area}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {booking?.numbers?.customer_phonenumber}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Chip
                          label={`${booking?.booking_status}`}
                          style={{
                            background:
                              booking.booking_status === "pending"
                                ? "#ffee58"
                                : booking.booking_status === "confirmed"
                                  ? "#00e676"
                                  : booking.booking_status === "cancelled"
                                    ? "#ff3d00"
                                    : "#d500f9",
                          }}
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Chip
                          label={`${booking?.report_status}`}
                          style={{
                            background:
                              booking.report_status === "pending"
                                ? "#ffee58"
                                : booking.report_status === "consolidate"
                                  ? "#00e676"
                                  : booking.report_status === "partial"
                                    ? "#d500f9"
                                    : "#ff3d00",
                          }}
                        />
                      </StyledTableCell>
                      {/* <StyledTableCell align="center"><Chip label={`${booking?.verification_status}`} style={{ background: booking.verification_status === "pending" ? "#ffee58" : booking.verification_status === "confirmed" ? "#00e676" : booking.verification_status === "cancelled" ? "#ff3d00" : "#d500f9" }} /></StyledTableCell> */}
                      <StyledTableCell align="center">
                        <Chip
                          label={`${booking?.pickup_status}`}
                          style={{
                            background:
                              booking.pickup_status === "pending"
                                ? "#ffee58"
                                : booking.pickup_status === "confirmed"
                                  ? "#00e676"
                                  : booking.pickup_status === "cancelled"
                                    ? "#ff3d00"
                                    : "#d500f9",
                          }}
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {booking?.pickup_receive_amount || "NA"}
                      </StyledTableCell>
                      {/* <StyledTableCell align="center">
                                                      {booking.bill_id===null && (
                                                          <Button disabled={booking.is_sync}  variant="contained" color="inherit" onClick={()=>(booking.pk)}>Sync</Button>
                                                    
                                                )}                                                
                                                </StyledTableCell> */}
                    </StyledTableRow>
                  );
                })}
            </TableBody>
            <TableFooter>
              <StyledTableRow>
                <TablePagination
                  rowsPerPageOptions={[]}
                  colSpan={4}
                  count={bookingList?.count || 0}
                  rowsPerPage={bookingList?.page_size}
                  page={page}
                  onPageChange={handleChangePage}
                />
              </StyledTableRow>
            </TableFooter>
          </Table>
        )}
      </TableContainer>
      {bookingList?.results && bookingList?.results.length === 0 && (
        <h6 style={{ textAlign: "center" }}>No Data Found</h6>
      )}
      <AdditonalFamilyModal
        additionalMemebr={additionalMemebr}
        additionalModal={additionalModal}
        setAdditonalModal={setAdditonalModal}
        loading={loading}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps, {
  getSyncData,
})(BookingsTable);
