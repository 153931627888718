import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@mui/material/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import {
  Button,
  MenuItem,
  TextField,
  Grid,
  Checkbox
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useEffect } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useHistory } from "react-router-dom";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { updateTicketStatus } from '../../actions/LogisticsPanelAction'
let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "scroll",
    position: "sticky",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: isMobile ? "400px" : "1200px",
    overflow: "scroll",
    position: "sticky",
  },
  head: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "2rem",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
},
}));

type ModalProps = {
  openTicketModal: any
  setOpenTicketModal: any
  bookingComplaints: any;
  ticketIndividualDetails: any
  getAvailableSlots: any;
  updateTicketStatus: any;
  collectionDate: any;
  packageList: any;
  ticketDetails: any;
  getPackage: any;
  setCollectionDate: any;
  zoneId: any;
  getBookingQueryTickets: any;
  booking_slots: any;

};

const CommentsModal2: React.FC<ModalProps> = ({
  openTicketModal,
  setOpenTicketModal,
  ticketIndividualDetails,
  updateTicketStatus,
  bookingComplaints,
  packageList,
  getPackage,
  ticketDetails,
  collectionDate,
  setCollectionDate,
  getAvailableSlots,
  getBookingQueryTickets,
  booking_slots,
  zoneId,

}) => {
  const classes = useStyles();
  const history = useHistory();
  const [disposition, setDisposition] = useState<any>("")
  const [subDisposition, setSubDisposition] = useState<any>("")
  const [subDispositionData, setSubDispostionData] = useState<any>([])
  const [query, setQuery] = useState<any>("")
  const [packageId, setPackageId] = useState<any>([]);
  const [slotKey, setSlotKey] = useState<any>(0);
  const [packageKey, setPackageKey] = useState<any>(0);
  const [slot, setSlot] = useState<any>("");
  const [calldate, setCallDate] = useState<any>("")
  const [callTime, setCallTime] = useState<any>("")
  const timer = useRef<any>(0);


  const handleClose = () => {
    setOpenTicketModal(false);
    getBookingQueryTickets()
    //  history.push("/dashboard/lp/booking/tickets")

  };
  useEffect(() => {
    if (disposition !== "") {
      const selecterDisposiotion = bookingComplaints.suggested_disposition.filter((data: any) => data.disposition === disposition)
      setSubDispostionData(selecterDisposiotion[0]?.sub_disposition)

    }

  }, [disposition])
  const submitComplaint = () => {
    const body = {
      query: query,
      action_disposition: disposition,
      action_sub_disposition: subDisposition,
      packages: packageId,
      collection_date: collectionDate === "" ? null : collectionDate,
      collection_slot: slot,
      // call_later_time: calldate + "" + callTime ,
    }
    updateTicketStatus(body, ticketIndividualDetails?.id)
    getBookingQueryTickets()
    setDisposition("")
    setSubDisposition("")
    setSubDispostionData([])
    setQuery("")
    setOpenTicketModal(false);
  }

  const handleCollectionDate = (e: any) => {
    setCollectionDate(e.target.value);
    setSlotKey(e.target.value.substring(8, 10));
  };
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openTicketModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openTicketModal}>
          <div className={classes.paper}>
            <div className={classes.head}>
              <h4
                style={{
                  marginBottom: "1rem",
                }}
                id="transition-modal-title"
              >
                Action
              </h4>
              <CloseIcon onClick={() => handleClose()} />
            </div>
            <Grid
              container
              direction="row"
              alignItems="flex-start"
              justify="space-between"
              spacing={3}
              style={{ margin: "1rem auto" }}
            >
              <Grid item xs={12} md={6}>
                <TextField
                  className="input"
                  name="action"
                  select
                  label="Disposition"
                  variant="outlined"
                  value={disposition}
                  style={{ width: "100%" }}
                  onChange={(e) => setDisposition(e.target.value as string)}
                >
                  {bookingComplaints?.suggested_disposition && bookingComplaints?.suggested_disposition.length > 0 && bookingComplaints?.suggested_disposition.map((data: any) => {
                    return (
                      <MenuItem value={data?.disposition}>
                        {data?.disposition}
                      </MenuItem>
                    )
                  })}
                </TextField>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  className="input"
                  name="action"
                  select
                  label="Sub Disposition"
                  variant="outlined"
                  disabled={disposition === ""}
                  value={subDisposition}
                  style={{ width: "100%", height: "40px" }}
                  onChange={(e) => setSubDisposition(e.target.value as string)}
                >
                  {subDispositionData && subDispositionData !== undefined && subDispositionData.map((data: any) => {
                    return (
                      <MenuItem value={data}>
                        {data}
                      </MenuItem>
                    )
                  })}
                </TextField>
              </Grid>
              {disposition === "Resampling Booking Created" && subDisposition === "Resampling Booking Created" ? (
                <>
                  <Grid item xs={12} md={6}>
                    <TextField
                      name="collectionDate"
                      type="date"
                      value={collectionDate}
                      className="input"
                      label="Collection Date*"
                      variant="outlined"
                      //    disabled={queryStatus === "confirmed"}
                      inputProps={{
                        min: `${new Date().getFullYear()}-${new Date().getMonth() + 1 < 10
                          ? `0${new Date().getMonth() + 1}`
                          : new Date().getMonth() + 1
                          }-${new Date().getDate() < 10
                            ? `0${new Date().getDate()}`
                            : new Date().getDate()
                          }`,
                      }}
                      onChange={(e: any) => handleCollectionDate(e)}
                      style={{ width: "100%" }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      id="slot"
                      key={slotKey}
                      onChange={(event, newValue) => {
                        let sourceIds: any = "";
                        if (newValue) {
                          let obj = JSON.parse(
                            JSON.stringify(newValue, null, " ")
                          );
                          setSlot(obj.id);
                        }
                      }}
                      options={booking_slots || []}
                      freeSolo
                      blurOnSelect
                      aria-required
                      limitTags={1}
                      //    disabled={
                      //      collectionDate === "" || queryStatus === "confirmed"
                      //    }
                      getOptionLabel={(option: any) =>
                        option?.available_slots > 0
                          ? option?.start_time +
                          "-" +
                          option?.end_time +
                          "Available Slot :" +
                          option?.available_slots
                          : ""
                      }
                      disableClearable
                      disableCloseOnSelect
                      // onInputChange={(event, newInputValue) => {
                      //   clearTimeout(timer.current);
                      //   timer.current = setTimeout(() => {
                      //     getAvailableSlots(newInputValue);
                      //   }, 1000);
                      //   if (newInputValue.length === 0) {
                      //     setSlot("");
                      //   }
                      // }}
                      renderInput={(params) => (
                        <TextField
                          className="input"
                          {...params}
                          label="Collection Slot"
                          variant="outlined"
                          style={{ width: "100%", margin: "0", padding: "0" }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    {
                      ticketDetails?.status === "resampling" && ticketDetails?.resampling_packages?.length === 0 ?
                        <Autocomplete
                          id="package"
                          key={packageKey}
                          onChange={(event, newValue) => {
                            if (newValue) {
                              let packs: any = [];
                              newValue.map((val: any) => {
                                let obj = JSON.parse(
                                  JSON.stringify(val, null, " ")
                                );
                                packs.push(obj.id);
                              });
                              setPackageId(packs);
                            }
                          }}
                          multiple
                          disableClearable
                          options={bookingComplaints?.resampling_packages || []}
                          limitTags={0}
                          freeSolo
                          disableCloseOnSelect
                          //    disabled={queryStatus === "confirmed"}
                          aria-required

                          getOptionLabel={(option: any) =>
                            option?.name +
                            ", " +
                            option?.code
                          }

                          renderInput={(params) => (
                            <TextField
                              className="input"
                              {...params}
                              placeholder="Package"
                              variant="outlined"
                              style={{ width: "100%", margin: "0", padding: "0" }}
                            />
                          )}
                        />
                        : <Autocomplete
                          id="package"
                          key={packageKey}
                          onChange={(event, newValue) => {
                            if (newValue) {
                              let packs: any = [];
                              newValue.map((val: any) => {
                                let obj = JSON.parse(
                                  JSON.stringify(val, null, " ")
                                );
                                packs.push(obj?.id);
                              });
                              setPackageId(packs);
                            }
                          }}
                          multiple
                          disableClearable
                          options={bookingComplaints?.resampling_packages || []}
                          limitTags={0}
                          freeSolo
                          disableCloseOnSelect
                          //    disabled={queryStatus === "confirmed"}
                          aria-required

                          getOptionLabel={(option: any) =>
                            option?.name +
                            ", (" +
                            option?.code + ")"
                          }
                          renderInput={(params) => (
                            <TextField
                              className="input"
                              {...params}
                              placeholder="Package"
                              variant="outlined"
                              style={{ width: "100%", margin: "0", padding: "0" }}
                            />
                          )}
                        />
                    }

                  </Grid>
                </>
              ) : ""}
             {/* {disposition === "Booking to Be rescheduled" && subDisposition === "Booking to Be rescheduled" ? (
                <Grid container spacing={3} direction="row" alignItems="center" justify="center" style={{ margin: "0.5rem" }}>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      className="input"
                      name="call_date"
                      type="date"
                      label="Date"
                      value={calldate}
                      variant="outlined"
                      onChange={(e) => setCallDate(e.target.value as string)}
                      style={{ width: "100%" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      id="time"
                      label="Time"
                      type="time"
                      value={callTime}
                      defaultValue="07:30"
                      className={classes.textField}
                      onChange={(e) => setCallTime(e.target.value as string)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        step: 300, // 5 min
                      }}
                    />
                  </Grid>
                </Grid>
              ) : ""} */}
              <Grid item xs={12} sm={6} md={12}>
                <textarea
                  maxLength={6}
                  aria-label="maximum height"
                  placeholder="Please write query....."
                  value={query}
                  onChange={(e) => setQuery(e.target.value as string)}
                  style={{ width: 1100, height: 80 }}              />
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  style={{ height: "50px" }}
                  disabled={disposition === "" || subDisposition === "" || query === ""}
                  onClick={submitComplaint}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </div>
        </Fade>
      </Modal>
    </div>

  );
};

const mapStateToProps = (state: any) => ({

});

export default connect(mapStateToProps, {
  updateTicketStatus
})(CommentsModal2);
