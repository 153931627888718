export default {
    count: 34,
    next: "http://localhost:3100/api/snowflake/lab/homedx/?start_date=2022-12-01&end_date=2023-01-03&page=2&page_size=20",
    page_size: 20,
    previous:
      "http://localhost:3100/api/snowflake/lab/homedx/?start_date=2022-12-01&end_date=2023-01-03",
    results: [
      {
        COURIERED_BOOKINGS: 28,
        "Total Cash Collected at Lab": 155009,
        TOTAL_SAMPLE_RECEIVED_AT_LAB: 546,
        DATE: "2022-12-16",
      },
      {
        TOTAL_SAMPLE_RECEIVED_AT_LAB: 525,
        COURIERED_BOOKINGS: 288,
        DATE: "2022-12-15",
        "Total Cash Collected at Lab": 147047,
      },
    ],
    columns: [
      "TOTAL_SAMPLE_RECEIVED_AT_LAB",
      "Total Cash Collected at Lab",
      "DATE",
      "COURIERED_BOOKINGS",
    ],
    filters: [
      {
        name: "package",
        type: "dropdown",
        values: ["Smart Full Body", "CBC Total"],
      },
      {
        name: "source_url",
        type: "text",
      },
      {
        name: "booking_id",
        type: "number",
      },
    ],
  };
  