export const ACTIONS = {
  CHANGE_PASSWORD: "change_password",
  GET_PIN_CODE: "pha/get_pin_code",
  GET_CITIES: "pha/get_cities",
  UPDATE_USERS_LIST: "pha/update_user_list",
  GET_COLLECTION_SLOT: "pha/get_collection_slot",
  GET_AREA: "pha/get_area",
  GET_AGENTS: "pha/get_agents",
  GET_AGENTS_BY_USERGROUP: "pha/get_agents_by_usergroup",
  GET_PACKAGES: "pha/get_packages",
  GET_PARTNERS: "pha/get_partners",
  GET_COUPONS: "pha/get_coupons",
  CREATE_BOOKING: "pha/create_booking",
  CREATE_ADDITIONAL_BOOKING: "pha/create_additional_booking",
  UPDATE_ADDITIONAL_BOOKING: "pha/update_additional_booking",
  UPDATE_BOOKING: "pha/update_booking",
  GET_BOOKING_LIST: "pha/get_booking_list",
  GET_LEAD_DETAILS: "pha/get_lead_details",
  GET_LEAD_BY_ID: "pha/get_lead_by_id",
  LEAD_DETAILS_RESET: "pha/lead_details_reset",
  GET_LEADS: "pha/get_leads",
  GET_BOOKING_BY_ID: "pha/get_booking_by_id",
  CLICK_TO_CALL: "pha/click_to_call",
  GET_POSTIVE_CALLS_COMMENTS: "pha/get_postive_calls_commnets",
  ADD_POSTIVE_CALLS_COMMENT: "pha/add_comments",
  SET_COMMENTS: "pha/set_commnets",
  UPLOAD_FILE: "pha/upload",
  SET_LOADING: "pha/set_loading",
  SEARCH_CALL: "pha/serach",
  BOOKING_RESET: "pha/booking_reset",
  ADD_COMMENT: "positive_calls/add_comment",
  CREATE_CALL: "pha/createcall",
  GET_LEAD_SOURCE: "pha/getleadsource",
  GET_RECORDINGS: "pha/get_recordings",
  GET_BOOKINGS_COMMENTS: "pha/get_bookings_commnets",
  ADD_BOOKINGS_COMMENT: "pha/add_bookings_comments",
  GET_TICKET_COMMENTS: "pha/ticket_comments",
  GET_WHATSAPP_COMMENTS: "pha/get_whatsapp_comments",
  GET_WHATSAPP_COMMENTS_BY_LEAD: "pha/get_whatsapp_comments_by_lead",
  SEND_WHATSAPP_COMMENTS: "pha/send",
  GET_CALL_STATS: "pha/get_call_stats",
  RAISE_COMPLAINT: "pha/raise_complaint",
  GET_COMPLAINTS: "pha/get_complaints",
  ASSIGN_DOCTOR: "pha/assign_doctor",
  UPDATE_BOOKING_ZONE: "pha/update_booking_zone",
  SEND_FEEDBACK: "pha/send_feedback",
  GET_AVAILABLE_SLOTS: "pha/get_available_slots",
  GET_ALL_LEADS: "pha/get_all_leads",
  LEAD_TRANSFER_BY_COMMA: "pha/set_comments",
  GET_PANEL_USERS: "pha/get_panel_user",
  GET_ASSIGNED_UNASSIGNED_BOOKING: "pha/get_assigned_unassigned_bookings",
  GET_ZONE: "pha/get_zone",
  UPDATE_ZONE: "pha/update_zone",
  CREATE_ZONE: "pha/create_zone",
  GET_TIMESLOTS: "pha/get_timeslots",
  GET_ATTENDENCE: "pha/get_attendence",
  ADD_REMARK_ON_CALL: "pha/add_remark_on_call",
  UPDATE_PHLEBOS: "pha/update_phlebos",
  GET_PHLEBO_COMMENTS: "pha/get_phlebo_commnets",
  GET_ZONE_COMMENTS: "pha/get_zone_comments",
  GET_CURRENT_DAY_BOOKING: "pha/get_current_day_booking",
  UPDATE_AREA: "pha/update_area",
  CREATE_AREA: "admin/create_area",
  GET_CITY_WISE_PHLEBOS: "pha/get_city_wise_phlebos",
  CREATE_NEW_PHLEBO: "pha/create_phlebo",
  GET_PHLEBO_BY_PHONE: "pha/get_phlebo_by_phone",
  GET_VERIFICATION_BOOKING_DETAILS: "pha/get_verification_booking_details",
  GET_PAYMENT_SEND_LINK: "pha/get_payment_send_link",
  GET_PAYMENT_RESEND_LINK: "pha/get_payment_resend_link",
  GET_PAYMENT_CANCEL_LINK: "pha/get_payment_cancel_link",
  GET_CENTER_INFORMATION: "pha/get_center_information",
  GET_REPORT_DOWNLOAD: "pha/get_report_download",
  GET_PAYMENT_INFORMATION: "pha/get_payment_information",
  GET_FIREBASE_NOTIFICATION: "pha/get_firebase_notification",
  GET_NOTIFICATION_MESSAGE: "pha/get_notification_message",
  GET_REPORT_STATUS: "pha/get_report_status",
  GET_SMS_REPORT_SEND: "pha/get_sms_report_send",
  GET_USER_DETAILS: "pha/get_user_details",
  GET_AREA_COMMENTS: "pha/get_area_comments",
  SAME_NUMBER_BOOKING: "pha/same_number_booking",
  GET_UNMASKED_NUMBER: "pha/get_unmasked_number",
  CHECK_GEOFENCE_AREA: "pha/checking-geofence-area",
  AUTO_ASSIGN_BOOKING: "pha/auto_assign_booking",
  GET_LAB_DETAILS: "pha/get_lab_details",
  GET_QRCODE_DATA: "pha/get_qrcode_data",
  GET_PACKAGE_TUBE_DETAILS: "pha/get_package_tube_details",
  GET_BOOKING_JOURNEY_DETAILS: "admin/booking_journey_details",
  GET_VERIFIED_BY_LIST: "pha/get_verified_by_list",
  UPDATE_PHLEBO_STATUS: "pha/update_phlebo_status",
  GET_ADDITIONAL_BOOKING: "pha/get_additional_booking",
  GET_SIGNATURE: "pha/get_signature",
  GET_RED_COIN: "pha/get_red_coins",
  GET_MAMPMYINDIA_ACCESS_TOKEN: "pha/get-mapmyindia-access-token",
  GET_SUB_CENTER_INFORMATION: "pha/get_sub_center_information",
  GET_PHLEBOS_LIST: "pha/get_phlebo_list",
  GET_MAP_BOOKING_LIST: "pha/get_map_booking",
  GET_PHLEBO_CURRENT_LOCATION: "pha/phlebo_current_location",
  GET_IMAGING_TIMESLOTS: "pha/get_imaging_timeslots",
  CREATE_IMAGING_BOOKING: "pha/CREATE_IMAGING_BOOKING",
  GET_MULTIPLE_PACKAGES: "pha/get_multiple_package",
  GET_DISPLAY_CITIES: "pha/get_display_cities",
  CHECK_GEOFENCE_AREA_MAMPMYINDIA: "pha/checking-geofence-area-mapmyindia",
  GET_ADDRESS_TYPE_SEATING: "pha/get_address_type_seating",
  GET_PACKAGE_ALL_TUBE: "pha/get_package_all_tube",
  CREATE_BARCODE: "pha/create_barcode",
  GET_CC_PACKAGES: "pha/get_cc_packages",
  POST_ADD_REMOVE_TEST: "pha/post_add_remove_test",
  GET_SAMPLE_JOURNEY_COMMENTS: "pha/get_sample_journey_comments",
  GET_PHLENO_BATCH_TUBE: "pha/get_phlebo_batch_tube",
  UPDATE_RIDER_TO_LAB_STATUS: "pha/update_rider_to_lab_status",
  GET_SAMPLE_HANDOVER_DATA: "pha/getsample_handover_data",
  GET_RIDER_LIST: "pha/get_rider_list",
  GET_PHLEBO_RIDER_DUE_AMOUNT: "pha/get_phlebo_rider_due_amount",
  GET_TICKET_DATA: "pha/get_ticket_data",
  GET_TICKET_CATEGORIES: "pha/get_ticket_category",
  GET_TICEKT_SUB_CATEGORIES: "pha/get_ticket_categories",
  CREATE_TASK_TICKET: "pha/create_task_ticket",
  GET_RED_TECH_COMMENT: "pha/redtech_comments/",
  POST_RED_TECH_COMMENT: "pha/postredtech_comments/",
  GET_BATCH_BOOKING_AMOUNT: "pha/get_batch_amount",
  GET_BOOKING_OVERVIEW: "pha/booking_overview",
  GET_HIV_PACKAGES: "pha/get_hiv_package",
  GET_BIOMARKER_SCREENING: "pha/biomarker_screening",
  GET_BIOMARKER_ULTASOUND: "pha/biomarker_ultasound",
  GET_ADDITIONAL_MEMBER: "pha/additional_member",
  GET_BATCH_PAYMENT: "pha/get_batch_payment",
  INITIATE_REFUND: "pha/initiate_refund",
  UPDATE_BATCH_PAYMENT: "pha/update_batch_payment",
  UPDATE_INITIATED_REFUND: "pha/update_initiate_refund",
  GET_LAB_CREDENTIAL: "pha/get_lab_cred",
  GET_CE_CENTER_FOR_CC: "pha/get_ce_center_for_cc",
  GET_BREAK_RESPONSE: "pha/get_break_response",
  CREATE_PILOT_BOOKING: "pha/create_pilot_booking",
  GET_ONLINE_BOOKING_LIST: "pha/get_online_booking_list",
  SET_GEO_LOADING: "pha/set_geo_loading",
  SET_GEO_CHECK_ERROR: "pha/set_geo_check_error",
  POST_PHLEBO_RIDER_MAP: "pha/post_phlebo_rider_map",
  UPDATE_PHLEBO_RIDER: "pha/update_phlebo_rider",
  GET_PHLEBO_RIDER: "pha/get_phlebo_rider",
  GET_ROUTES: "pha/get_routes",
  GET_BOOKING_COMPLAINTS: "pha/get_booking_complaints",
  RAISE_NEW_RAISE_TICEKT_DISPOSITION: "pha/cstech_create-ticekt",
  UPDATE_DISPOSITION: "pha/update_disposition",
  UPDATE_TICKET_STATUS: "pha/update_ticekt_status",
  BIOMARKER_RESET: "pha/biomarker_reset",
  SET_ALL_BOOKING_LOADING: "pha/set_all_booking_loading",
  GET_PHLEBO_LEAVE_LIST: "pha/get_phlebo_leave_list",
  CREATE_PHLEBO_LEAVE: "pha/create_phlebo_leave",
  GET_PHLEBO_LIST_UNDER_ROUTE_MANAGER:
    "pha/get_phlebo_list_under_route_manager",
  GET_ASSIGNED_UNASSIGNED_BOOKING_PILOT_V2: "pha/get_booking_List",
  UPDATE_PHLEBO_LEAVE: "pha/update_phlebo_leave",
  TABLE_RESET: "pha/table_reset",
  LOCATION_VERIFIED: "pha/location_verified",
  SEND_LOCATION_REQUEST: "pha/communication/send-location-request/",
  GET_ADDRESS_DETAILS_LAT_LONG: "pha/get-address-details-lat-long",
  UPDATE_ATTENDANCE: "pha/update_attendance",
  GET_PHLEBO_AUTO_ASSIGN: "pha/get_phlebo_auto_assign",
  CHECK_GEOFENCE_AREA_MAMPMYINDIA_FOR_CUSTOMER_ADDRESS:
    "pha/checking-geofence-area-mapmyindia_for_customer",
  SET_GEO_LOADING_FOR_CUSTOMER: "pha/set_geo_loading_for_customer",
  SET_GEO_ERROR_FOR_CUSTOMER: "pha/set_geo_check_error_for_customer",
  GET_SEND_LOCATION_REQUEST: "pha/communication/on-whatsapp",
  GET_CRM_LEAD_ADDRESS: "pha/get_auth/address-crm/",
  SET_LEAD_ADDRESS_LOADER: "pha/set_lead_address_loader",
  POST_CRM_LEAD_ADDRESS: "pha/post_auth/address-crm/",
  GET_WHATSAPP_MESSAGE_STATUS: "pha/get_whatsapp_message_status",
  GET_REFERRAL_CURRENT_BALANCE: "pha/get_referral_current_balance/",
  GET_COUPON_REFERRAL_VALIDATION: "pha/get_coupon_referral_validation/",
  GET_LEAD_SUBSCRIPTIONS_DATA: "pha/get-lead-subscriptions-data",
  SET_BOOKING_ID_LOADER: "pha/set_booking_id_loader",
  GET_ROUTE_MANAGERS: "pha/get_route_managers",
  GET_ZONE_DETAILS: "pha/get_zone_details",
  ADDRESS_JSON_POST: "pha/address_json",
  GET_NEW_LEAD_DETAILS: "pha/lead_details",
  LATEST_LEAD_ADDRESS: "pha/latest_lead_address",
  CUSTOMER_SUMMARY_USING_UHID: "pha/customer_summary_using_uhid",
  CUSTOMER_DETAILS_USING_UHID: "pha/customer_details_using_uhid",
  SET_LOADING_SUMMARY: "pha/set_loading_summary",
  SET_LOADING_DETAILS: "pha/set_loading_details",
  GET_LEAD_DISPOSITION_LIST: "pha/plead/status-category/",
  GET_BOOKING_SUB_DISPOSITION: "pha/ead/lead-status/",
  GET_LEAD_STATUS: "pha/get_lead_status",
  POST_DISPOSITION_DATA: "pha/post_disposition_data",
  CREATE_WHATSAPP_TEMPLATE: "pha/create_whatsapp_template",
  GET_WHATSAPP_TEMPLATE: "pha/get_whatsapp_template",
  GET_PANEL_USER_LIST_DATA_SALES_LEAD:
    "pha/get_panel_user_list_data_sales_lead",
  GET_AGENTS_NEW_DATA: "pha/get_agents_new_data",
  LEAD_TRANSFER_TO_TATATALE: "pha/lead_transfer_to_tatatale",
  GET_PANEL_USER_LIST_DATA_TEAM_LEAD: "pha/get_panel_user_list_data_team_lead",
  GET_LTV_LEAD: "pha/get_ltv_lead",
  SEND_INITIAL_WHATSAPP_MESSAGE: "pha/send_initial_whatsapp_message",
  GET_PRESCRIPTION_DATA: "pha/get_prescription_data",
  SET_LEAD_LOADING: "pha/set_lead_loading",
  GET_LANGUAGE: "pha/get_language",
  GET_PHLEBO_LEAVE_LOG: "pha/phlebo/v2/phlebo-leave-logs",
};

interface UpdateAttendance {
  type: typeof ACTIONS.UPDATE_ATTENDANCE;
  payload: any;
}

interface GetPinCode {
  type: typeof ACTIONS.GET_PIN_CODE;
  payload: Array<any>;
}
interface LocationVerified {
  type: typeof ACTIONS.LOCATION_VERIFIED;
  payload: boolean;
}
interface SendLocationRequest {
  type: typeof ACTIONS.SEND_LOCATION_REQUEST;
  payload: Object;
}
interface GetPhleboAutoAssign {
  type: typeof ACTIONS.GET_PHLEBO_AUTO_ASSIGN;
  payload: Array<any>;
}
interface GetAddressDetailsLatLong {
  type: typeof ACTIONS.GET_ADDRESS_DETAILS_LAT_LONG;
  payload: Array<any>;
}
interface UpdateBookingZone {
  type: typeof ACTIONS.UPDATE_BOOKING_ZONE;
  payload: Array<any>;
}
interface GetBatchBookingAmount {
  type: typeof ACTIONS.GET_BATCH_BOOKING_AMOUNT;
  payload: boolean;
}
interface GetPhleboRiderDueAmount {
  type: typeof ACTIONS.GET_PHLEBO_RIDER_DUE_AMOUNT;
  payload: Array<any>;
}
interface GetPackageAllTube {
  type: typeof ACTIONS.GET_PACKAGE_ALL_TUBE;
  payload: Array<any>;
}
interface CreatePilotBooking {
  type: typeof ACTIONS.CREATE_PILOT_BOOKING;
  payload: boolean;
}
interface CreateBarcode {
  type: typeof ACTIONS.CREATE_BARCODE;
  payload: Array<any>;
}
interface GetPhleboCurrentLocation {
  type: typeof ACTIONS.GET_PHLEBO_CURRENT_LOCATION;
  payload: Array<any>;
}
interface GetPhleboList {
  type: typeof ACTIONS.GET_PHLEBOS_LIST;
  payload: Array<any>;
}
interface GetCeCenterForCc {
  type: typeof ACTIONS.GET_CE_CENTER_FOR_CC;
  payload: Array<any>;
}
interface GetMapBooking {
  type: typeof ACTIONS.GET_MAP_BOOKING_LIST;
  payload: Array<any>;
}
interface GetAdditionalBooking {
  type: typeof ACTIONS.GET_ADDITIONAL_BOOKING;
  payload: Array<any>;
}
interface GetSampleJourneyComments {
  type: typeof ACTIONS.GET_SAMPLE_JOURNEY_COMMENTS;
  payload: Object;
}
interface GetSignature {
  type: typeof ACTIONS.GET_SIGNATURE;
  payload: Array<any>;
}
interface GetLabDetails {
  type: typeof ACTIONS.GET_LAB_DETAILS;
  payload: Array<any>;
}
interface UpdatePhleboStatus {
  type: typeof ACTIONS.UPDATE_PHLEBO_STATUS;
  payload: Array<any>;
}
interface CheckGeoFenceArea {
  type: typeof ACTIONS.CHECK_GEOFENCE_AREA;
  payload: Array<any>;
}
interface AutoAssignBooking {
  type: typeof ACTIONS.AUTO_ASSIGN_BOOKING;
  payload: Array<any>;
}
interface GetUnmaskedNumber {
  type: typeof ACTIONS.GET_UNMASKED_NUMBER;
  payload: Array<any>;
}
interface SameNumberBooking {
  type: typeof ACTIONS.SAME_NUMBER_BOOKING;
  payload: Object;
}
interface GetRiderList {
  type: typeof ACTIONS.GET_RIDER_LIST;
  payload: Array<any>;
}
interface UpdateRiderToLabStatus {
  type: typeof ACTIONS.UPDATE_RIDER_TO_LAB_STATUS;
  payload: Array<any>;
}
interface GetAreaComments {
  type: typeof ACTIONS.GET_AREA_COMMENTS;
  payload: Array<any>;
}
interface GetBreakResponse {
  type: typeof ACTIONS.GET_BREAK_RESPONSE;
  payload: Object;
}
interface GetBookingOverview {
  type: typeof ACTIONS.GET_BOOKING_OVERVIEW;
  payload: Array<any>;
}
interface GetUserDetails {
  type: typeof ACTIONS.GET_USER_DETAILS;
  payload: Array<any>;
}
interface GetSmsReportSend {
  type: typeof ACTIONS.GET_SMS_REPORT_SEND;
  payload: Array<any>;
}
interface GetAllRecording {
  type: typeof ACTIONS.GET_RECORDINGS;
  payload: Array<any>;
}
interface GetPhleboByPhone {
  type: typeof ACTIONS.GET_PHLEBO_BY_PHONE;
  payload: Object;
}
interface AddRemarkOnCall {
  type: typeof ACTIONS.ADD_REMARK_ON_CALL;
  payload: Object;
}
interface GetBookingJourney {
  type: typeof ACTIONS.GET_BOOKING_JOURNEY_DETAILS;
  payload: Array<any>;
}
interface GetUserList {
  type: typeof ACTIONS.UPDATE_USERS_LIST;
  payload: Object;
}
interface ChangePassword {
  type: typeof ACTIONS.CHANGE_PASSWORD;
  payload: Object;
}
interface PostAddRemoveTest {
  type: typeof ACTIONS.POST_ADD_REMOVE_TEST;
  payload: Array<any>;
}

interface UploadFile {
  type: typeof ACTIONS.UPLOAD_FILE;
  payload: Object;
}

interface GetCities {
  type: typeof ACTIONS.GET_CITIES;
  payload: Array<any>;
}

interface GetArea {
  type: typeof ACTIONS.GET_AREA;
  payload: Array<any>;
}
interface GetCollectionSlot {
  type: typeof ACTIONS.GET_COLLECTION_SLOT;
  payload: Array<any>;
}

interface GetAgents {
  type: typeof ACTIONS.GET_AGENTS;
  payload: Array<any>;
}
interface GetAssignedUnassignedBooking {
  type: typeof ACTIONS.GET_ASSIGNED_UNASSIGNED_BOOKING;
  payload: Array<any>;
}
interface GetPhleboBatchTube {
  type: typeof ACTIONS.GET_PHLENO_BATCH_TUBE;
  payload: Array<any>;
}

interface SetComments {
  type: typeof ACTIONS.SET_COMMENTS;
  payload: Object;
}
interface getCCPackage {
  type: typeof ACTIONS.GET_CC_PACKAGES;
  payload: Array<any>;
}

interface GetPositiveCallsComments {
  type: typeof ACTIONS.GET_POSTIVE_CALLS_COMMENTS;
  payload: Object;
}

interface AddPositiveCallsComments {
  type: typeof ACTIONS.ADD_POSTIVE_CALLS_COMMENT;
  payload: Object;
}

interface ClickToCall {
  type: typeof ACTIONS.CLICK_TO_CALL;
  payload: Array<any>;
}

interface GetPackages {
  type: typeof ACTIONS.GET_PACKAGES;
  payload: Array<any>;
}
interface getAssignedUnassignedBookingPilotV2 {
  type: typeof ACTIONS.GET_ASSIGNED_UNASSIGNED_BOOKING_PILOT_V2;
  payload: Array<any>;
}

interface GetPartners {
  type: typeof ACTIONS.GET_PARTNERS;
  payload: Array<any>;
}
interface GetSampleHandOver {
  type: typeof ACTIONS.GET_SAMPLE_HANDOVER_DATA;
  payload: Array<any>;
}
interface CreateWhatsappTemplate {
  type: typeof ACTIONS.CREATE_WHATSAPP_TEMPLATE;
  payload: boolean;
}

interface GetCoupons {
  type: typeof ACTIONS.GET_COUPONS;
  payload: Array<any>;
}

interface SetLoading {
  type: typeof ACTIONS.SET_LOADING;
  payload: boolean;
}

interface CreateBooking {
  type: typeof ACTIONS.CREATE_BOOKING;
  payload: boolean;
}
interface CreatePhlebo {
  type: typeof ACTIONS.CREATE_NEW_PHLEBO;
  payload: boolean;
}

interface UpdateBooking {
  type: typeof ACTIONS.UPDATE_BOOKING;
  payload: boolean;
}

interface GetBookings {
  type: typeof ACTIONS.GET_BOOKING_LIST;
  payload: boolean;
}

interface GetBookingById {
  type: typeof ACTIONS.GET_BOOKING_BY_ID;
  payload: boolean;
}

interface GetLeads {
  type: typeof ACTIONS.GET_LEADS;
  payload: boolean;
}

interface GetLeadDetails {
  type: typeof ACTIONS.GET_LEAD_DETAILS;
  payload: boolean;
}

interface GetLeadById {
  type: typeof ACTIONS.GET_LEAD_BY_ID;
  payload: boolean;
}

interface ResetLeadDetails {
  type: typeof ACTIONS.LEAD_DETAILS_RESET;
  payload: boolean;
}

interface GetPinCode {
  type: typeof ACTIONS.GET_PIN_CODE;
  payload: Array<any>;
}
interface ChangePassword {
  type: typeof ACTIONS.CHANGE_PASSWORD;
  payload: Object;
}
interface GetCities {
  type: typeof ACTIONS.GET_CITIES;
  payload: Array<any>;
}
interface GetCollectionSlot {
  type: typeof ACTIONS.GET_COLLECTION_SLOT;
  payload: Array<any>;
}

interface GetArea {
  type: typeof ACTIONS.GET_AREA;
  payload: Array<any>;
}

interface GetAgents {
  type: typeof ACTIONS.GET_AGENTS;
  payload: Array<any>;
}

interface SetComments {
  type: typeof ACTIONS.SET_COMMENTS;
  payload: Object;
}

interface GetPositiveCallsComments {
  type: typeof ACTIONS.GET_POSTIVE_CALLS_COMMENTS;
  payload: Object;
}

interface AddPositiveCallsComments {
  type: typeof ACTIONS.ADD_POSTIVE_CALLS_COMMENT;
  payload: Object;
}
interface ClickToCall {
  type: typeof ACTIONS.CLICK_TO_CALL;
  payload: Array<any>;
}

interface GetPackages {
  type: typeof ACTIONS.GET_PACKAGES;
  payload: Array<any>;
}

interface GetPartners {
  type: typeof ACTIONS.GET_PARTNERS;
  payload: Array<any>;
}
interface GetRedCoins {
  type: typeof ACTIONS.GET_RED_COIN;
  payload: Array<any>;
}

interface GetCoupons {
  type: typeof ACTIONS.GET_COUPONS;
  payload: Array<any>;
}
interface GetAddressTypeSeating {
  type: typeof ACTIONS.GET_ADDRESS_TYPE_SEATING;
  payload: Object;
}

interface SetLoading {
  type: typeof ACTIONS.SET_LOADING;
  payload: boolean;
}

interface CreateBooking {
  type: typeof ACTIONS.CREATE_BOOKING;
  payload: boolean;
}

interface UpdateBooking {
  type: typeof ACTIONS.UPDATE_BOOKING;
  payload: boolean;
}

interface GetBookings {
  type: typeof ACTIONS.GET_BOOKING_LIST;
  payload: boolean;
}

interface GetBookingById {
  type: typeof ACTIONS.GET_BOOKING_BY_ID;
  payload: boolean;
}

interface GetLeads {
  type: typeof ACTIONS.GET_LEADS;
  payload: boolean;
}

interface GetLeadDetails {
  type: typeof ACTIONS.GET_LEAD_DETAILS;
  payload: boolean;
}

interface GetLeadById {
  type: typeof ACTIONS.GET_LEAD_BY_ID;
  payload: boolean;
}
interface SetLeadAddressLoading {
  type: typeof ACTIONS.SET_LEAD_ADDRESS_LOADER;
  payload: boolean;
}

interface ResetLeadDetails {
  type: typeof ACTIONS.LEAD_DETAILS_RESET;
  payload: boolean;
}
interface SearchCall {
  type: typeof ACTIONS.SEARCH_CALL;
  payload: Object;
}
interface SearchCall {
  type: typeof ACTIONS.SEARCH_CALL;
  payload: Object;
}
interface GetAgentByUserGroup {
  type: typeof ACTIONS.GET_AGENTS_BY_USERGROUP;
  payload: Object;
}

interface CreateAdditionalBooking {
  type: typeof ACTIONS.CREATE_ADDITIONAL_BOOKING;
  payload: Object;
}
interface UpdateAdditionalBooking {
  type: typeof ACTIONS.UPDATE_ADDITIONAL_BOOKING;
  payload: Object;
}
interface GetZone {
  type: typeof ACTIONS.GET_ZONE;
  payload: Object;
}
interface CreateZone {
  type: typeof ACTIONS.CREATE_ZONE;
  payload: Object;
}
interface UpdateZone {
  type: typeof ACTIONS.UPDATE_ZONE;
  payload: Object;
}
interface GetTimeslots {
  type: typeof ACTIONS.GET_TIMESLOTS;
  payload: Object;
}

interface GetAttendence {
  type: typeof ACTIONS.GET_ATTENDENCE;
  payload: Object;
}
interface UpdatePhlebos {
  type: typeof ACTIONS.UPDATE_PHLEBOS;
  payload: Object;
}
interface GetPhleboComment {
  type: typeof ACTIONS.GET_PHLEBO_COMMENTS;
  payload: Object;
}
interface GetZoneComment {
  type: typeof ACTIONS.GET_ZONE_COMMENTS;
  payload: Object;
}
interface GetCurrentDayBooking {
  type: typeof ACTIONS.GET_CURRENT_DAY_BOOKING;
  payload: Object;
}
interface GetCityWisePhlebos {
  type: typeof ACTIONS.GET_CITY_WISE_PHLEBOS;
  payload: Object;
}
interface PostCrmLeadAddress {
  type: typeof ACTIONS.POST_CRM_LEAD_ADDRESS;
  payload: Object;
}
interface UpdateArea {
  type: typeof ACTIONS.UPDATE_AREA;
  payload: Object;
}
interface CreateArea {
  type: typeof ACTIONS.CREATE_AREA;
  payload: Object;
}
interface GetPaymentSendLink {
  type: typeof ACTIONS.GET_PAYMENT_SEND_LINK;
  payload: Object;
}
interface GetPaymentReSendLink {
  type: typeof ACTIONS.GET_PAYMENT_RESEND_LINK;
  payload: Object;
}
interface GetPaymentCancelLink {
  type: typeof ACTIONS.GET_PAYMENT_CANCEL_LINK;
  payload: Object;
}
interface GetVerificationBookingDetails {
  type: typeof ACTIONS.GET_VERIFICATION_BOOKING_DETAILS;
  payload: Array<any>;
}
interface GetCenterInfo {
  type: typeof ACTIONS.GET_CENTER_INFORMATION;
  payload: Array<any>;
}
interface GetReportDownload {
  type: typeof ACTIONS.GET_REPORT_DOWNLOAD;
  payload: Object;
}
interface GetPaymentInfro {
  type: typeof ACTIONS.GET_PAYMENT_INFORMATION;
  payload: Array<any>;
}
interface GetFirebaseNotification {
  type: typeof ACTIONS.GET_FIREBASE_NOTIFICATION;
  payload: Array<any>;
}
interface GetNotificationMessage {
  type: typeof ACTIONS.GET_NOTIFICATION_MESSAGE;
  payload: Array<any>;
}
interface GetReportStatus {
  type: typeof ACTIONS.GET_REPORT_STATUS;
  payload: Array<any>;
}
interface GetQRCodeData {
  type: typeof ACTIONS.GET_QRCODE_DATA;
  payload: Array<any>;
}
interface GetPackageTubeDetails {
  type: typeof ACTIONS.GET_PACKAGE_TUBE_DETAILS;
  payload: Array<any>;
}
interface GetVerifiedByList {
  type: typeof ACTIONS.GET_VERIFIED_BY_LIST;
  payload: Object;
}
interface GetMapMyIndiaAccessToken {
  type: typeof ACTIONS.GET_MAMPMYINDIA_ACCESS_TOKEN;
  payload: Array<any>;
}

interface GetSubCenterInformation {
  type: typeof ACTIONS.GET_SUB_CENTER_INFORMATION;
  payload: Array<any>;
}
interface GetImagingTimeSlots {
  type: typeof ACTIONS.GET_IMAGING_TIMESLOTS;
  payload: Array<any>;
}
interface CreateImagingBooking {
  type: typeof ACTIONS.CREATE_IMAGING_BOOKING;
  payload: boolean;
}
interface GetPhleboAutoAssign {
  type: typeof ACTIONS.GET_PHLEBO_AUTO_ASSIGN;
  payload: Array<any>;
}
interface GetMultiplepackage {
  type: typeof ACTIONS.GET_MULTIPLE_PACKAGES;
  payload: Array<any>;
}
interface GetDisplayCities {
  type: typeof ACTIONS.GET_DISPLAY_CITIES;
  payload: Array<any>;
}
interface CheckGeoFenceAreaMapMyIndia {
  type: typeof ACTIONS.CHECK_GEOFENCE_AREA_MAMPMYINDIA;
  payload: Array<any>;
}

interface GetRedTechComment {
  type: typeof ACTIONS.GET_RED_TECH_COMMENT;
  payload: Array<any>;
}
interface GetTicketData {
  type: typeof ACTIONS.GET_TICKET_DATA;
  payload: Object;
}
interface GetTicketCategory {
  type: typeof ACTIONS.GET_TICKET_CATEGORIES;
  payload: Object;
}
interface GetTicketSubCategories {
  type: typeof ACTIONS.GET_TICEKT_SUB_CATEGORIES;
  payload: Object;
}
interface PostRedTechComment {
  type: typeof ACTIONS.POST_RED_TECH_COMMENT;
  payload: Object;
}
interface CreateTaskTicket {
  type: typeof ACTIONS.CREATE_TASK_TICKET;
  payload: Array<any>;
}

interface GetHivPackage {
  type: typeof ACTIONS.GET_HIV_PACKAGES;
  payload: Object;
}
interface GetBiomarkerScreening {
  type: typeof ACTIONS.GET_BIOMARKER_SCREENING;
  payload: Object;
}
interface GetBiomarkerUltasound {
  type: typeof ACTIONS.GET_BIOMARKER_ULTASOUND;
  payload: Object;
}
interface GetAdditionalMember {
  type: typeof ACTIONS.GET_ADDITIONAL_MEMBER;
  payload: Array<any>;
}
interface GetBatchPayment {
  type: typeof ACTIONS.GET_BATCH_PAYMENT;
  payload: Array<any>;
}
interface InitiateRefund {
  type: typeof ACTIONS.INITIATE_REFUND;
  payload: Array<any>;
}
interface UpdateBatchPayment {
  type: typeof ACTIONS.UPDATE_BATCH_PAYMENT;
  payload: Array<any>;
}
interface UpdateInitiateRefund {
  type: typeof ACTIONS.UPDATE_INITIATED_REFUND;
  payload: Array<any>;
}
interface GetLabCredential {
  type: typeof ACTIONS.GET_LAB_CREDENTIAL;
  payload: Array<any>;
}
interface SetBookingIdLoader {
  type: typeof ACTIONS.SET_BOOKING_ID_LOADER;
  payload: boolean;
}
interface GetLeadSubscriptionsData {
  type: typeof ACTIONS.GET_LEAD_SUBSCRIPTIONS_DATA;
  payload: Array<any>;
}
interface GetOnlineBookingList {
  type: typeof ACTIONS.GET_ONLINE_BOOKING_LIST;
  payload: boolean;
}
interface GeoLoading {
  type: typeof ACTIONS.SET_GEO_LOADING;
  payload: boolean;
}
interface CheckGeoCheckError {
  type: typeof ACTIONS.SET_GEO_CHECK_ERROR;
  payload: boolean;
}
interface PostPhleboRiderMapping {
  type: typeof ACTIONS.POST_PHLEBO_RIDER_MAP;
  payload: Array<any>;
}
interface UpdatePhleboRider {
  type: typeof ACTIONS.UPDATE_PHLEBO_RIDER;
  payload: Array<any>;
}
interface GetPhleboRider {
  type: typeof ACTIONS.GET_PHLEBO_RIDER;
  payload: Array<any>;
}

interface GetRoutes {
  type: typeof ACTIONS.GET_ROUTES;
  payload: Array<any>;
}
interface GetBookingQueryTickets {
  type: typeof ACTIONS.GET_BOOKING_COMPLAINTS;
  payload: boolean;
}
interface GetTicketComments {
  type: typeof ACTIONS.GET_TICKET_COMMENTS;
  payload: Array<any>;
}
interface RaiseNewTicektDisposition {
  type: typeof ACTIONS.RAISE_NEW_RAISE_TICEKT_DISPOSITION;
  payload: Array<any>;
}
interface UpdateActionDisposition {
  type: typeof ACTIONS.UPDATE_DISPOSITION;
  payload: Array<any>;
}
interface BiomarkerReset {
  type: typeof ACTIONS.BIOMARKER_RESET;
  payload: boolean;
}
interface UpdateTicketStatus {
  type: typeof ACTIONS.UPDATE_TICKET_STATUS;
  payload: Array<any>;
}
interface GetBookingsLoader {
  type: typeof ACTIONS.SET_ALL_BOOKING_LOADING;
  payload: boolean;
}
interface GetPhleboLeaveList {
  type: typeof ACTIONS.GET_PHLEBO_LEAVE_LIST;
  payload: Object;
}
interface CreateLeaveList {
  type: typeof ACTIONS.CREATE_PHLEBO_LEAVE;
  payload: Array<any>;
}
interface GetPhleboUnderRm {
  type: typeof ACTIONS.GET_PHLEBO_LIST_UNDER_ROUTE_MANAGER;
  payload: Array<any>;
}
interface UpdatePhleboLeave {
  type: typeof ACTIONS.UPDATE_PHLEBO_LEAVE;
  payload: Array<any>;
}
interface ResetTable {
  type: typeof ACTIONS.TABLE_RESET;
  payload: boolean;
}
interface CheckGeoFenceAreaMapMyIndiaForCustomer {
  type: typeof ACTIONS.CHECK_GEOFENCE_AREA_MAMPMYINDIA_FOR_CUSTOMER_ADDRESS;
  payload: Array<any>;
}
interface GeoLoadingForCustomer {
  type: typeof ACTIONS.SET_GEO_LOADING_FOR_CUSTOMER;
  payload: boolean;
}
interface CheckGeoCheckErrorForCustomer {
  type: typeof ACTIONS.SET_GEO_ERROR_FOR_CUSTOMER;
  payload: boolean;
}
interface GetCommunicationOnWhatsapp {
  type: typeof ACTIONS.GET_SEND_LOCATION_REQUEST;
  payload: Array<any>;
}
interface getCrmLeadAddress {
  type: typeof ACTIONS.GET_CRM_LEAD_ADDRESS;
  payload: Array<any>;
}
interface GetWhatsappMessageStatus {
  type: typeof ACTIONS.GET_WHATSAPP_MESSAGE_STATUS;
  payload: Array<any>;
}
interface GetReferralCurrentBalance {
  type: typeof ACTIONS.GET_REFERRAL_CURRENT_BALANCE;
  payload: Array<any>;
}
interface GetCouponReferralValidation {
  type: typeof ACTIONS.GET_COUPON_REFERRAL_VALIDATION;
  payload: Array<any>;
}

interface GetRouteManagers {
  type: typeof ACTIONS.GET_ROUTE_MANAGERS;
  payload: Array<any>;
}

interface GetZoneDetails {
  type: typeof ACTIONS.GET_ZONE_DETAILS;
  payload: any;
}
interface AddressJsonPost {
  type: typeof ACTIONS.ADDRESS_JSON_POST;
  payload: any;
}
interface GetNewLeadDetails {
  type: typeof ACTIONS.GET_NEW_LEAD_DETAILS;
  payload: any;
}
interface GetLatestLeadAddress {
  type: typeof ACTIONS.LATEST_LEAD_ADDRESS;
  payload: Array<any>;
}
interface GetCustomerSummaryUsingUHID {
  type: typeof ACTIONS.CUSTOMER_SUMMARY_USING_UHID;
  payload: Array<any>;
}
interface GetCustomerDetailsUsingUHID {
  type: typeof ACTIONS.CUSTOMER_DETAILS_USING_UHID;
  payload: Array<any>;
}
interface SetLoadingSummary {
  type: typeof ACTIONS.SET_LOADING_SUMMARY;
  payload: boolean;
}
interface SetLeadLoading {
  type: typeof ACTIONS.SET_LEAD_LOADING;
  payload: boolean;
}
interface SetLoadingDetails {
  type: typeof ACTIONS.SET_LOADING_DETAILS;
  payload: boolean;
}
interface GetLeadDispostion {
  type: typeof ACTIONS.GET_LEAD_DISPOSITION_LIST;
  payload: Object;
}
interface GetBookingSubDisposition {
  type: typeof ACTIONS.GET_BOOKING_SUB_DISPOSITION;
  payload: boolean;
}
interface GetLeadStatus {
  type: typeof ACTIONS.GET_LEAD_STATUS;
  payload: Object;
}
interface PostDispositionData {
  type: typeof ACTIONS.POST_DISPOSITION_DATA;
  payload: Array<any>;
}
interface GetWhatsappTemplate {
  type: typeof ACTIONS.GET_WHATSAPP_TEMPLATE;
  payload: Array<any>;
}
interface GetPanelUserDataSalesLead {
  type: typeof ACTIONS.GET_PANEL_USER_LIST_DATA_SALES_LEAD;
  payload: Array<any>;
}
interface GetAgentNewData {
  type: typeof ACTIONS.GET_AGENTS_NEW_DATA;
  payload: Array<any>;
}
interface GetTataTalyLead {
  type: typeof ACTIONS.LEAD_TRANSFER_TO_TATATALE;
  payload: Object;
}
interface GetPanelUserDataTeamLead {
  type: typeof ACTIONS.GET_PANEL_USER_LIST_DATA_TEAM_LEAD;
  payload: Array<any>;
}
interface GetLtvLead {
  type: typeof ACTIONS.GET_LTV_LEAD;
  payload: boolean;
}
interface SendInititalWhatsapp {
  type: typeof ACTIONS.SEND_INITIAL_WHATSAPP_MESSAGE;
  payload: Array<any>;
}
interface GetPrescriptionData {
  type: typeof ACTIONS.GET_PRESCRIPTION_DATA;
  payload: Array<any>;
}
interface GetLanguage {
  type: typeof ACTIONS.GET_LANGUAGE;
  payload: boolean;
}
interface GetPhleboLeaveLog {
  type: typeof ACTIONS.GET_PHLEBO_LEAVE_LOG;
  payload: boolean;
}
export type PhleboAdminTypes =
  | UpdateAttendance
  | SearchCall
  | GetAssignedUnassignedBooking
  | CreateAdditionalBooking
  | UpdateAdditionalBooking
  | GetPinCode
  | ChangePassword
  | GetCities
  | GetArea
  | GetAgents
  | GetAgentByUserGroup
  | GetPackages
  | CreateBooking
  | UpdateBooking
  | GetPartners
  | GetCoupons
  | GetBookings
  | GetBookingById
  | SetLoading
  | GetLeadDetails
  | GetLeads
  | GetLeadById
  | ResetLeadDetails
  | ClickToCall
  | GetPositiveCallsComments
  | AddPositiveCallsComments
  | SetComments
  | UploadFile
  | GetPinCode
  | ChangePassword
  | GetCities
  | GetArea
  | GetAgents
  | GetPackages
  | CreateBooking
  | UpdateBooking
  | GetPartners
  | GetCoupons
  | GetBookings
  | GetBookingById
  | SetLoading
  | GetLeadDetails
  | GetLeads
  | GetLeadById
  | ResetLeadDetails
  | ClickToCall
  | GetPositiveCallsComments
  | AddPositiveCallsComments
  | SetComments
  | SearchCall
  | GetZone
  | CreateZone
  | UpdateZone
  | GetTimeslots
  | GetAttendence
  | GetCollectionSlot
  | GetUserList
  | AddRemarkOnCall
  | UpdatePhlebos
  | GetPhleboComment
  | GetZoneComment
  | GetCurrentDayBooking
  | UpdateArea
  | CreateArea
  | GetCeCenterForCc
  | GetCityWisePhlebos
  | CreatePhlebo
  | GetPhleboByPhone
  | GetPaymentSendLink
  | GetPaymentReSendLink
  | GetPaymentCancelLink
  | GetVerificationBookingDetails
  | GetCenterInfo
  | GetReportDownload
  | GetPaymentInfro
  | GetFirebaseNotification
  | GetNotificationMessage
  | GetReportStatus
  | GetSmsReportSend
  | GetUserDetails
  | GetAllRecording
  | GetAreaComments
  | SameNumberBooking
  | GetUnmaskedNumber
  | GetSampleHandOver
  | UpdateRiderToLabStatus
  | CheckGeoFenceArea
  | GetRiderList
  | AutoAssignBooking
  | GetLabDetails
  | GetQRCodeData
  | GetPackageTubeDetails
  | GetBookingJourney
  | GetVerifiedByList
  | UpdatePhleboStatus
  | GetAdditionalBooking
  | GetSignature
  | GetRedCoins
  | GetMapMyIndiaAccessToken
  | GetRedCoins
  | GetSubCenterInformation
  | GetPhleboList
  | GetAddressTypeSeating
  | GetMapBooking
  | GetPhleboCurrentLocation
  | GetImagingTimeSlots
  | CreateImagingBooking
  | GetMultiplepackage
  | GetDisplayCities
  | CheckGeoFenceAreaMapMyIndia
  | GetPackageAllTube
  | GetBreakResponse
  | CreateBarcode
  | getCCPackage
  | PostAddRemoveTest
  | GetSampleJourneyComments
  | GetPhleboBatchTube
  | GetPhleboRiderDueAmount
  | GetTicketData
  | GetTicketCategory
  | GetTicketSubCategories
  | CreateTaskTicket
  | GetRedTechComment
  | PostRedTechComment
  | GetBatchBookingAmount
  | GetHivPackage
  | GetBiomarkerScreening
  | GetBiomarkerUltasound
  | GetAdditionalMember
  | GetBookingOverview
  | InitiateRefund
  | GetBatchPayment
  | UpdateBatchPayment
  | CreatePilotBooking
  | UpdateInitiateRefund
  | UpdateBookingZone
  | GetOnlineBookingList
  | GetLabCredential
  | GeoLoading
  | CheckGeoCheckError
  | PostPhleboRiderMapping
  | UpdatePhleboRider
  | GetPhleboRider
  | GetRoutes
  | GetBookingQueryTickets
  | GetTicketComments
  | RaiseNewTicektDisposition
  | UpdateActionDisposition
  | UpdateTicketStatus
  | GetPhleboLeaveList
  | CreateLeaveList
  | GetPhleboUnderRm
  | UpdatePhleboLeave
  | ResetTable
  | getAssignedUnassignedBookingPilotV2
  | GetBookingsLoader
  | SendLocationRequest
  | GetPhleboAutoAssign
  | LocationVerified
  | CheckGeoFenceAreaMapMyIndiaForCustomer
  | CheckGeoCheckErrorForCustomer
  | GeoLoadingForCustomer
  | GetCommunicationOnWhatsapp
  | getCrmLeadAddress
  | SetLeadAddressLoading
  | PostCrmLeadAddress
  | GetWhatsappMessageStatus
  | GetReferralCurrentBalance
  | GetCouponReferralValidation
  | GetLeadSubscriptionsData
  | SetBookingIdLoader
  | GetPhleboAutoAssign
  | GetAddressDetailsLatLong
  | GetRouteManagers
  | AddressJsonPost
  | GetLatestLeadAddress
  | GetCustomerSummaryUsingUHID
  | GetCustomerDetailsUsingUHID
  | SetLoadingSummary
  | SetLoadingDetails
  | GetLeadDispostion
  | GetBookingSubDisposition
  | GetLeadStatus
  | PostDispositionData
  | GetWhatsappTemplate
  | CreateWhatsappTemplate
  | GetZoneDetails
  | GetPanelUserDataSalesLead
  | GetAgentNewData
  | GetTataTalyLead
  | GetPanelUserDataTeamLead
  | GetLtvLead
  | SendInititalWhatsapp
  | GetPrescriptionData
  | SetLeadLoading
  |GetPhleboLeaveLog
  | GetLanguage;
