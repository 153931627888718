import React, { useEffect } from "react";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { connect } from "react-redux";
import Modal from "@mui/material/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import CircularProgress from "@material-ui/core/CircularProgress";
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import CloseIcon from '@material-ui/icons/Close';
import {
  Grid,
  MenuItem,
  Select,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  TablePagination,
  Container,
  Box,
  TextField,
  withStyles,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {
  getWhatsappTemplate,
  createWhatsappTemplate,
} from "../../actions/PhleboAdmin";
import "./index.sass";
import { NearMe } from "@material-ui/icons";
import { display } from "@mui/system";
let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow:"scroll",
  },
  imageSM: {
    width: "60px",
    height: "60px",
    border: "2px solid #000 ",
    borderRadius: "50%",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: isMobile ? "400px" : "1200px",
  },
  imagePaper: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[1],
  },
  table: {
    maxWidth: "100%",
  },
  tableHead: {
    background: "rgb(146, 74, 145)!important",
  },
  tableHeadCell: {
    color: "#fff",
    fontWeight: "bold",
    textAlign: "center",
  },
  tableContainer: {
    position: "sticky",
    top: "200px",
    overflow:"scroll",
  },
}));

const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

function rand() {
  return Math.round(Math.random() * 20) - 10;
}
function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

type ModalProps = {
  open: boolean;
  setOpen: Function;
  leadId: number;
  getWhatsappTemplate: Function;
  createWhatsappTemplate: Function;
  whatsappTemplate: any;
  loading: boolean;
  user: any;
  messageSource: any;
  singleChat?: any;
  getChats?: any;
};

const WhatsAppModal: React.FC<ModalProps> = ({
  open,
  setOpen,
  leadId,
  getWhatsappTemplate,
  createWhatsappTemplate,
  whatsappTemplate,
  loading,
  user,
  messageSource,
  singleChat,
  getChats
}) => {
  const classes = useStyles();

  const [comment, setComment] = React.useState<string>("");
  const [maxHeight, setMaxHeight] = React.useState<any>(350)
  const [name, setName] = React.useState<string>("");
  const [image, setImage] = React.useState<string>("");
  const [imageShow, setImageShow] = React.useState<any>(false);
  const [openImage, setImageOpen] = React.useState(false);
  const [imageUrl, setImageUrl] = React.useState("");
  const [filterData, setFilterData] = React.useState<any>([]);
  const [modalStyle] = React.useState(getModalStyle);
  const [hover, setHover] = React.useState(false);
  const [selectedLeads, setSelectedLeads] = React.useState<any>([]);
  const onHover = () => {
    setHover(!hover)
  }

  const handleClose = () => {
    setOpen(false);
    setName("")
    setImage("")
    setImageShow(false)
    setImageOpen(false)
    setImageUrl("")
    setSelectedLeads([])
  };

  useEffect(() => {
    if (open === true) {
      getWhatsappTemplate();
    }
  }, [open]);

  const [checkedOne, setCheckedOne] = React.useState(false);

  const handleChangeOne = () => {
    setCheckedOne(!checkedOne);
  };

  // let filterData: string | any[] = [];

  useEffect(() => {
    setFilterData(whatsappTemplate?.filter( (data: { templateid: string; }) =>  {
      return data.templateid === name;
    }))
  }, [name]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const body = {
      templateid: name,
      image_id: image,
      lead: leadId,
    }
    if (messageSource === "ameyo") {
      await createWhatsappTemplate(`templateid=${name}&image_id=${image}&lead=${leadId}&provider=${messageSource}&from_sales=${true}`, body);
    } else {
      await createWhatsappTemplate(`templateid=${name}&image_id=${image}&lead=${leadId}&from_sales=${true}&provider=ameyo`, body);
    }
    setOpen(false);
    setName("")
    setImage("")
    setImageShow(false)
    setImageOpen(false)
    setImageUrl("")
    setSelectedLeads([])

    if(singleChat) {
      getChats(`lead=${leadId}`);
    }
  };
  const handleOpenModal = (e: any) => {
    setImageUrl(e);
    setImageOpen(true);
  };
  const handleImageClose = () => {
    setImageOpen(false);
  };
  const body = (
    <div style={modalStyle} className={classes.imagePaper}>
      <img
        src={imageUrl}
        alt="popup"
        style={{ width: "550px", height: "450px" }}
      />
      {/* <SimpleModal /> */}
    </div>
  );
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        // onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h6
              style={{
                marginBottom: "10px",
                paddingBottom: "15px",
                textAlign: "center",
                borderBottom: "1px solid rgba(0,0,0,0.5)",
                fontSize: "25px",
                display: "flex",
                justifyContent: "space-between"
              }}
              id="transition-modal-title"
            >
              {`Select ${imageShow ? "Image" : "Name"} For Whatsapp Template`}
              {/* {Number(maxHeight) === Number(300) ? (
                <Stack direction="row" spacing={2} style={{ display: "block", textAlign: "end" }} >
                <ZoomOutMapIcon fontSize="large" onClick={() => Number(maxHeight) === Number(600) ? setMaxHeight(Number(600)) : setMaxHeight(Number(600))} />
                </Stack>
                ) : (
                <Stack direction="row" spacing={2} style={{ display: "block", textAlign: "end" }} >
                <ZoomInMapIcon fontSize="large" onClick={() => Number(maxHeight) === Number(300) ? setMaxHeight(Number(300)) : setMaxHeight(Number(300))} />
                </Stack>
                )} */}
                <div><CloseIcon onClick={handleClose} /></div>
            </h6>
            <div
              id="transition-modal-description"
              className="modal-form-wrapper"
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                marginTop: "20px",
                height: `${maxHeight}px`

              }}
            >
              {imageShow === false ?
                <div style={{ display: "flex" }}>
                  <Grid item xs={12} md={6} style={{ padding: "0" }}>
                    <FormControl>
                      {name !== "" ? "" : <InputLabel style={{ paddingLeft: "20px" }}>Select Name</InputLabel>}
                      <Select
                        className="input"
                        name="templatename"
                        variant="outlined"
                        required
                        value={name}
                        style={{ width: "400px", margin: "0", padding: "0" }}
                        onChange={(e) =>
                          setName(e.target.value as string)
                        }
                      >
                        <MenuItem disabled value={"none"}>
                          {"Select Name"}
                        </MenuItem>
                        {whatsappTemplate?.map((data: any) => {
                          return <MenuItem value={data.templateid}>{data.template_name}</MenuItem>;
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                </div>
                :
                <div style={{ display: "flex", width: "600px" }}>
                  <TableContainer className={classes.tableContainer}
                    // component={Paper}
                    style={{ maxHeight: "300px" }}
                  >
                    <Table stickyHeader aria-label="simple table">
                      <TableHead className={classes.tableHead}>
                      <StyledTableCell align="center" className={classes.tableHeadCell}>
                        Package Name
                      </StyledTableCell>
                      <StyledTableCell align="center" className={classes.tableHeadCell}>
                        Package Image
                      </StyledTableCell>
                      <StyledTableCell align="center" className={classes.tableHeadCell}>
                        Select Image
                      </StyledTableCell>
                      </TableHead>
                      <TableBody>
                        {  filterData[0]?.images?.map((data: any, index: any) => {
                            return (
                              <StyledTableRow
                                key={index}
                              >
                                <StyledTableCell align="center">
                                  {data?.name}
                                </StyledTableCell>
                                <StyledTableCell
                                    align="center"
                                    onClick={() => {
                                        handleOpenModal(data?.image)
                                        // setImage(data?.id)
                                      }
                                    }
                                  >
                                    <img
                                      key={data?.id}
                                      src={data?.image}
                                      alt="startimage"
                                      className={classes.imageSM}
                                      style={{ cursor:"pointer" }}
                                    />
                                    <div style={{ color: "green", paddingTop: "10px" }}>{ image === data?.id ? `Image Selected` : ""}</div>
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                  <input
                                    type="checkbox"
                                    name="selectall"
                                    id="selectall"
                                    checked={selectedLeads.find(
                                      (id: any) => id === data?.id
                                    )}
                                    onChange={(e) => {
                                      !e.target.checked
                                        ? setSelectedLeads(
                                          selectedLeads?.filter(
                                            (id: any) => id !== data?.id
                                          )
                                        )
                                        : setSelectedLeads((prev: any) => [
                                          data?.id,
                                        ]);
                                        e.target.checked === false ? setImage("") : setImage(data?.id);
                                        
                                        // selectedLeads?.length === 0 ? setImage("") : setImage(data?.id);
                                      }
                                    }
                                  />
                                  </StyledTableCell>
                              </StyledTableRow>
                            );
                          })}
                      </TableBody>
                      <TableFooter>
                        <StyledTableRow>
                        </StyledTableRow>
                      </TableFooter>
                    </Table>
                  </TableContainer>
                </div>
              }
              {imageShow === false ?
              <Button 
                style={{ marginTop: "260px", marginLeft: "950px" }}
                disabled={name===""}
                variant="contained"
                color="primary"
                onClick={() => {
                  setImageShow(true);
                }}
              >
                Next
              </Button>
              :
              <Button
                style={{ marginTop: "0px", marginLeft: "950px" }}
                variant="contained"
                color="secondary"
                disabled={image===""}
                onClick={handleSubmit}
              >
                Submit
              </Button>
              }
              {/* <form className="modal-form chat-form" onSubmit={submitForm}>
              </form> */}
            </div>
          </div>
        </Fade>
      </Modal>
      <Modal
          open={openImage}
          onClose={handleImageClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {body}
        </Modal>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  whatsappTemplate: state.PhleboAdminReducer.whatsappTemplate,
  loading: state.PhleboAdminReducer.loading,
  user: state.loginReducer.user,
});

export default connect(mapStateToProps, {
  getWhatsappTemplate,
  createWhatsappTemplate,
})(WhatsAppModal);