import React, { useEffect, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Card,
  Chip,
  Dialog,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { Autocomplete } from "@material-ui/lab";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import {
  getBookingById,
  getPinCode,
  clickToCallBooking,
  updateBooking,
  getAvailableSlots,
  getPackage,
  updateAdditionalBooking,
  getPaymentSendLink,
  getUnmaskedNumber,
  updatePhleboReachedStatus,
  getHivPackage,
  getBiomarkerScreening,
} from "../../../actions/PhleboAdmin";
import Loader from "../../../../components/loader";
import moment from "moment";
import CallIcon from "@material-ui/icons/Call";
import { GridCloseIcon } from "@material-ui/data-grid";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Popover from "@material-ui/core/Popover";
import RaiseComplaintModal from "../../Comments/raiseTicketModal";
import { useRef } from "react";
import RemoveTest from "./removePackage";
import AddTest from "./addPackage";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      marginTop: "2rem",
      padding: "0.5rem 2rem",
      width: "100%",
    },
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    typography: {
      padding: theme.spacing(2),
    },
    slotContainer: {
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "center",
    },
    slot: {
      width: "150px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      margin: "0.5rem",
    },
    selectedSlot: {
      width: "150px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      background: "linear-gradient(60deg, cyan, #00dfff, cyan)",
      cursor: "pointer",
      margin: "0.5rem",
    },
    tableHead: {
      fontWeight: "bold",
      textTransform: "capitalize",
      textAlign: "center",
    },
  })
);

interface Props {
  match: any;
  booking: any;
  getBookingById: any;
  getPinCode: any;
  pin_code: any;
  partnerList: any;
  getPartners: any;
  clickToCallBooking: any;
  updateBooking: any;
  getAvailableSlots: any;
  getPaymentSendLink: any;
  booking_slots: any;
  complaints: any;
  getPackage: any;
  packageList: any;
  updateAdditionalBooking: any;
  additional_booking: any;
  getUnmaskedNumber: any;
  unmaskedNumber: any;
  updatePhleboReachedStatus: any;
  loading: Boolean;
  getHivPackage: any;
  hivpackageList: any;
  getBiomarkerScreening: any;
  biomarkerScreening: any;
}

const PhleboAdminViewBooking: React.FC<Props> = ({
  match,
  booking,
  getBookingById,
  getPinCode,
  pin_code,
  clickToCallBooking,
  updateBooking,
  getAvailableSlots,
  booking_slots,
  getPackage,
  packageList,
  complaints,
  updateAdditionalBooking,
  getPaymentSendLink,
  additional_booking,
  getUnmaskedNumber,
  unmaskedNumber,
  updatePhleboReachedStatus,
  loading,
  getHivPackage,
  hivpackageList,
  getBiomarkerScreening,
  biomarkerScreening,
}) => {
  const classes = useStyles();
  const timer = useRef<any>(0);
  const [timeSlots, setTimeSlots] = useState<Array<Object>>([]);
  const [selectedSlot, setSelectedSlot] = useState<Number>(
    booking.collection_slot && booking.collection_slot.id
  );
  const [customer_designation, setCustomerDesignation] = useState<String>(
    booking.designation || ""
  );
  const [customer_name, setCustomer_name] = useState<any>("");
  const [customer_age, setCustomer_age] = useState<any>("");
  const [customer_gender, setCustomer_gender] = useState<any>("male");
  const [customer_aadhar, setCustomer_aadhar] = useState<any>("");
  const [packageDetails, setPackageDetails] = useState<any>([]);
  const [id, setId] = useState(0);
  const [booking_status, setBooking_status] = useState<string>("pending");
  const [pickup_status, setPickup_status] = useState("pending");
  const [phlebo_verification_remark, setPhlebo_verification_remark] =
    useState<string>("");
  const [phlebo_incentive, setPhlebo_incentive] = useState("");
  const [phlebo_convenience, setPhlebo_convenience] = useState("");
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [open, setOpen] = React.useState(false);
  const [totalPrice, setTotalPrice] = useState<any>(
    booking.discounted_price && booking.discounted_price.final_total_price
  );
  const [edit, setEdit] = useState(false);
  const openAnchor = Boolean(anchorEl);
  const [is_price_hide, setIsPriceHode] = useState<boolean>(
    booking?.partner?.is_price_hide
  );
  const [price, setPrice] = useState<any>(0);
  const [extraCharge, setExtraCharge] = useState<boolean>(
    booking.discounted_price && booking.discounted_price.phlebo_cost !== 0
  );
  const [openAddModal, setOpenAddModal] = useState<any>(false);
  const [openRemoveModal, setOpenRemoveModal] = useState<any>(false);
  const [bId, setBId] = useState<any>("");
  const [aId, setAId] = useState<any>("");
  const [type, setType] = useState<any>("");
  const [personId, setPersoneId] = React.useState("none");
  const [raiseComplaint, setRaiseComplaint] = useState(false);
  const [openModal, setopenModal] = useState<boolean>(false);
  const [callButton, setCallButton] = useState<boolean>(true);
  const popid = open ? "simple-popover" : undefined;
  const handleClose = () => {
    setOpen(false);
  };

  const handleAnchorClose = () => {
    setAnchorEl(null);
  };
  const handleOpen = () => {
    setOpen(true);
    getSlots();
  };
  const getArea = (pincode: String, colDate: any) => {
    const zone =
      pin_code.results &&
      pin_code.results.find((code: any) => code.pincode === pincode);
    getAvailableSlots(colDate, zone && zone.zone_data && zone.zone_data.id);
  };

  const getSlots = () => {
    getArea(
      booking.customer_areapincode && booking.customer_areapincode.pincode,
      booking.collection_date && booking.collection_date
    );
  };

  useEffect(() => {
    const slots = booking_slots && booking_slots.results;
    setTimeSlots(slots && slots[0].zone_data && slots[0].zone_data.time_slots);
  }, [booking_slots]);

  const bookingId = match.params.id;
  useEffect(() => {
    getBookingById(`${bookingId}/`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingId, additional_booking]);

  useEffect(() => {
    getPinCode(
      booking.customer_areapincode &&
        `${booking.customer_areapincode.pincode}&show_code=true`
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [booking]);

  const handleCallClick = (id: number, type: string) => {
    clickToCallBooking(id, type);
    setCallButton(false);
  };
  const handleAddTest = (bid: any, aid: any, type: any) => {
    setOpenAddModal(true);
    setBId(bid);
    setType(type);
    setAId(aid);
  };
  const handleRemoveTest = (bid: any, aid: any, type: any) => {
    setOpenRemoveModal(true);
    setBId(bid);
    setType(type);
    setAId(aid);
  };
  const handleCloseAddModal = () => {
    // history.push(`/dashboard/coradmin/booking-view/${booking?.pk}`);
    setOpenRemoveModal(false);
    setOpenAddModal(false);
    getBookingById(`${bookingId}/`);
  };

  const history = useHistory();
  const cancelHandler = () => {
    // const data: any = {
    //     "booking_status": "cancelled"
    // }
    // updateBooking(data, bookingId)
    history.push(`/dashboard/rm`);
  };

  const handleEditMember = (member: any) => {
    setCustomer_aadhar(member.customer_aadhar);
    setCustomer_age(member.customer_age);
    setCustomer_gender(member.customer_gender);
    setCustomer_name(member.customer_name);
    setPackageDetails(member.packages);
    setSelectedSlot(member.collection_slot.id);
    setBooking_status(member.booking_status);
    setPickup_status(member.pickup_status);
    setPhlebo_convenience(member.phlebo_convenience);
    setPhlebo_incentive(member.phlebo_incentive);
    setPhlebo_verification_remark(member.phlebo_verification_remark);
    setId(member.id);
    handleOpen();
  };

  const updateFamilyMemberHandler = async () => {
    const body: any = {
      pickup_status,
      phlebo_convenience,
      phlebo_incentive,
      phlebo_verification_remark,
      packages: packageDetails.map((pack: any) => {
        return pack.id;
      }),
    };
    await updateAdditionalBooking(id, body);
    setOpen(false);
    setCustomer_aadhar("");
    setCustomer_age("");
    setCustomer_gender("male");
    setCustomer_name("");
    setPackageDetails([]);
    setBooking_status("pending");
    setPickup_status("pending");
    setPhlebo_convenience("");
    setPhlebo_incentive("");
    setPhlebo_verification_remark("");
    setId(0);
  };
  const handleRefreshPaymentSend = () => {
    getPaymentSendLink(booking.pk);
    alert("Fresh Payment Link Send Successfully");
    setTimeout(() => {
      setCallButton(true);
      getBookingById(`${bookingId}/`);
    }, 2000);
  };

  const handleUnmaskedNumber = (e: any, id: any, source: any, type: any) => {
    setAnchorEl(e.currentTarget);
    getUnmaskedNumber(id, source, type);
  };
  const handlePhleboReached = () => {
    // setopenModal(true);
  };
  // const handlePhleboReached = () => {
  //   const body = {
  //     booking: booking.pk,
  //     current_status: "punched",
  //   };
  //   updatePhleboReachedStatus(body);
  //   history.push(`/dashboard/pha/booking-view/${booking.pk}`);
  // };

  useEffect(() => {
    if (bookingId !== 0||bookingId !== undefined) {
      if (Number(bookingId)) getHivPackage(`?booking=${bookingId}`);}
  }, [booking]);

  useEffect(() => {
    if (Number(bookingId)) getBiomarkerScreening(`booking_id=${bookingId}`);
  }, [bookingId]);

  useEffect(() => {
    if (personId !== "none" && String(personId) === String(bookingId)) {
      if (Number(personId)) getBiomarkerScreening(`booking_id=${personId}`);
    } else if (personId !== "none" && String(personId) !== String(bookingId)) {
      if (Number(personId)) getBiomarkerScreening(`add_member_booking=${personId}`);
    }
  }, [personId]);

  return (
    <>
      <main className={classes.content}>
        <div className={classes.toolbar} />
    

        <Paper className={classes.paper} elevation={15}>
          <Grid
            container
            direction="row"
            alignItems="flex-start"
            justify="space-between"
            spacing={3}
            style={{ margin: "1rem auto" }}
          >
            <Grid item xs={6} sm={6} md={6}>
              <h3
                style={{
                  paddingTop: "10px",
                  fontSize: "24px",
                  fontWeight: "bold",
                }}
              >
           CC BOOKING
              </h3>
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <h3
                style={{
                  paddingTop: "10px",
                  fontSize: "24px",
                  fontWeight: "bold",
                  textAlign: "right",
                }}
              >
                BOOKING (ID: {bookingId})
              </h3>
            </Grid>
          </Grid>
          {loading ? (
            <Loader />
          ) : (
            <>
              <Grid
                container
                direction="row"
                alignItems="flex-start"
                justify="space-between"
                spacing={3}
                style={{ margin: "1rem auto" }}
              >
                <Grid item xs={6} md={3}>
                  <h6>Collection Date</h6>
                  <p style={{ fontWeight: "normal" }}>
                    {booking.collection_date}
                  </p>
                </Grid>
                {/* <Grid item xs={6} md={3}>
                  <h6>Pincode</h6>
                  <p style={{ fontWeight: "normal" }}>
                    {booking.customer_areapincode &&
                      booking.customer_areapincode.pincode}
                  </p>
                </Grid> */}
                <Grid item xs={6} md={3}>
                  <h6>Collection Time</h6>
                  <p style={{ fontWeight: "normal" }}>
                    {booking.collection_slot &&
                      moment(`${booking.collection_slot.slot.split("-")[0]}`, [
                        "HH.mm.ss",
                      ]).format("hh:mm A")}
                    {" - "}
                    {booking.collection_slot &&
                      moment(`${booking.collection_slot.slot.split("-")[1]}`, [
                        "HH.mm.ss",
                      ]).format("hh:mm A")}
                  </p>
                </Grid>
              </Grid>

              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableHead}>S No.</TableCell>
                      <TableCell className={classes.tableHead}>
                        Customer Name
                      </TableCell>
                      <TableCell className={classes.tableHead}>Age</TableCell>
                      <TableCell className={classes.tableHead}>
                        Gender
                      </TableCell>
                      <TableCell className={classes.tableHead}>
                        Package Name
                      </TableCell>
                      <TableCell className={classes.tableHead}>
                        Partner OrderId
                      </TableCell>
                      <TableCell className={classes.tableHead}>
                        Aadhaar No
                      </TableCell>
                     
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow key={1}>
                      <TableCell align="center">1.</TableCell>
                      <TableCell align="center">{`${
                        booking.designation || ""
                      } ${booking.customer_name}`}</TableCell>
                      <TableCell align="center">
                        {booking.customer_age}
                      </TableCell>
                      <TableCell align="center">
                        {booking.customer_gender}
                      </TableCell>
                      <TableCell align="center">
                        <ul>
                          {booking.packages &&
                            booking.packages.map((pack: any, index: any) => {
                              return (
                                <li
                                  key={index}
                                  style={{
                                    margin: "1rem 0",
                                    textAlign: "left",
                                  }}
                                >
                                  {/* {pack.name}, {pack.tat_time} */}
                                  {pack.name && pack.package_city_prices
                                    ? pack.name.toString() +
                                      ", " +
                                      pack.package_city_prices.tat_time
                                    : pack.name + ", " + pack.tat_time}
                                </li>
                              );
                            })}
                        </ul>
                      </TableCell>
                      <TableCell align="center">
                        {booking.partner && booking.partner.id}
                      </TableCell>
                      <TableCell align="center">
                        {booking.customer_aadhar || ""}
                      </TableCell>
                     
                    </TableRow>
                    {booking.additional_members &&
                      booking.additional_members.length > 0 &&
                      booking.additional_members.map(
                        (member: any, index: number) => {
                          return (
                            <TableRow key={index + 1}>
                              <TableCell align="center">{index + 2}.</TableCell>
                              <TableCell align="center">
                                {`${member.designation || ""} ${
                                  member.customer_name
                                }`}
                              </TableCell>
                              <TableCell align="center">
                                {member.customer_age}
                              </TableCell>
                              <TableCell align="center">
                                {member.customer_gender}
                              </TableCell>
                              <TableCell align="center">
                                <ul>
                                  {member.packages &&
                                    member.packages.map(
                                      (pack: any, index: any) => {
                                        return (
                                          <li
                                            key={index}
                                            style={{
                                              margin: "0 0 1rem 0",
                                              textAlign: "left",
                                            }}
                                          >
                                            {/* {pack.name}, {pack.price} */}
                                            {pack.name &&
                                            pack.package_city_prices
                                              ? pack.name.toString() +
                                                ", " +
                                                pack.package_city_prices
                                                  .tat_time
                                              : pack.name +
                                                ", " +
                                                pack.tat_time}
                                          </li>
                                        );
                                      }
                                    )}
                                </ul>
                              </TableCell>
                              <TableCell align="center"></TableCell>
                              <TableCell align="center">
                                {member.customer_aadhar}
                              </TableCell>
                             
                            </TableRow>
                          );
                        }
                      )}
                    <TableRow></TableRow>
                    <TableRow>
                      <TableCell align="right" colSpan={6}>
                        Package Price
                      </TableCell>
                      <TableCell align="center">
                        {booking.discounted_price &&
                          booking.discounted_price.total_price_package &&
                          Math.floor(
                            booking.discounted_price.total_price_package
                          )}
                        /-
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="right" colSpan={6}>
                        Phlebo Cost
                      </TableCell>
                      <TableCell align="center">
                        {booking.discounted_price &&
                          booking.discounted_price.phlebo_cost &&
                          Math.floor(booking.discounted_price.phlebo_cost)}
                        /-
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="right" colSpan={6}>
                        Discount
                      </TableCell>
                      <TableCell align="center">
                        {booking.discounted_price &&
                          booking.discounted_price.counpon_discount &&
                          Math.floor(booking.discounted_price.counpon_discount)}
                        /-
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="right" colSpan={6}>
                        RedCash Discount
                      </TableCell>
                      <TableCell align="center">
                        {booking?.redcash_discounted_amount}/-
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell colSpan={6} align="right">
                        <h6
                          style={{
                            padding: "5px",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          Total Amount
                        </h6>
                      </TableCell>
                      <TableCell align="center">
                        <h6
                          style={{
                            padding: "5px",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          {booking.discounted_price &&
                            booking.discounted_price.final_total_price &&
                            Math.floor(
                              booking.discounted_price.final_total_price
                            ) - Math.floor(booking.redcash_discounted_amount)}
                          /-
                        </h6>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>

              <Grid
                  container
                  spacing={4}
                  direction="row"
                  justify="flex-start"
                  alignItems="center"
                  style={{ margin: "1rem auto" }}
                >
                  <Grid
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                    container
                    item
                    xs={12}
                    md={6}
                    spacing={3}
                  >
                    <Grid item xs={12} md={5}>
                      <p>AgentName</p>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <TextField
                        name="agentName"
                        type="text"
                        value={(booking.agent && booking.agent.name) || ""}
                            disabled
                            className="input"
                            variant="outlined"
                            style={{ margin: "0", width: "100%" }}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        direction="row"
                        justify="space-around"
                        alignItems="center"
                        container
                        item
                        xs={12}
                        md={6}
                        spacing={3}
                      >
                        <Grid item xs={12} md={5}>
                          <p>
                            Phlebo
                            {booking.phlebo && booking.phlebo.name ? (
                              <Button
                              disabled={!callButton}
                              className="addDeck"
                              variant="contained"
                              color="primary"
                              onClick={() => handleCallClick(bookingId, "phlebo")}
                              startIcon={<CallIcon />}
    
    
                            ></Button>
                            ) : (
                              ""
                            )}
                          </p>
                        </Grid>
                        <Grid item xs={12} md={7}>
                          <TextField
                        name="phlebo"
                        type="text"
                        value={(booking.phlebo && booking.phlebo.name) || ""}
                            disabled
                            className="input"
                            variant="outlined"
                            style={{ margin: "0", width: "100%" }}
                          />
                        </Grid>
                      </Grid>
                  <Grid
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                    container
                    item
                    xs={12}
                    md={6}
                    spacing={3}
                  >
                    <Grid item xs={12} md={5}>
                      <p>
                        Mobile Number

                        <VisibilityIcon
                          onClick={(e: any) =>
                            handleUnmaskedNumber(
                              e,
                              bookingId,
                              "booking",
                              "contact"
                            )
                          }
                          style={{ marginLeft: "2rem" }}
                        />
                        <Button
                          disabled={!callButton}
                          className="addDeck"
                          variant="contained"
                          color="primary"
                          onClick={() => handleCallClick(bookingId, "call")}
                          startIcon={<CallIcon />}
                        ></Button>
                      </p>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <TextField
                        name="customer_phonenumber"
                        type="text"
                        value={booking?.customer_phonenumber || ""}
                        disabled
                        className="input"
                        variant="outlined"
                        style={{ margin: "0", width: "100%" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                    container
                    item
                    xs={12}
                    md={6}
                    spacing={3}
                  >
                    <Grid item xs={12} md={5}>
                      <p>
                        Alternate Mobile Number
                        <VisibilityIcon
                          onClick={(e: any) =>
                            handleUnmaskedNumber(
                              e,
                              bookingId,
                              "booking",
                              "altphonenumber"
                            )
                          }
                          style={{ marginLeft: "2rem" }}
                        />
                        <Button
                          disabled={!callButton}
                          className="addDeck"
                          variant="contained"
                          color="primary"
                          onClick={() => handleCallClick(bookingId, "contact")}
                          startIcon={<CallIcon />}


                        ></Button>

                      </p>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <TextField
                        name="customer_alternatenumber"
                        type="text"
                        value={booking?.customer_altphonenumber || ""}
                        disabled
                        className="input"
                        variant="outlined"
                        style={{ margin: "0", width: "100%" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                    container
                    item
                    xs={12}
                    md={6}
                    spacing={3}
                  >
                    <Grid item xs={12} md={5}>
                      <p>Email</p>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <TextField
                        name="customer_email"
                        type="email"
                        disabled
                        value={booking?.customer_email || ""}
                        className="input"
                        variant="outlined"
                        style={{ margin: "0", width: "100%" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                    container
                    item
                    xs={12}
                    md={6}
                    spacing={3}
                  >
                    <Grid item xs={12} md={5}>
                      <p>
                        Whatsapp Number
                        <VisibilityIcon
                          onClick={(e: any) =>
                            handleUnmaskedNumber(
                              e,
                              bookingId,
                              "booking",
                              "whatsapp"
                            )
                          }
                          style={{ marginLeft: "2rem" }}
                        />
                        <Button
                          disabled={!callButton}
                          className="addDeck"
                          variant="contained"
                          color="primary"
                          onClick={() => handleCallClick(bookingId, "whatsapp")}
                          startIcon={<CallIcon />}
                        ></Button>
                      </p>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <TextField
                        name="customer_whatsapppnumber"
                        type="text"
                        disabled
                        value={booking?.customer_whatsapppnumber || ""}
                        className="input"
                        variant="outlined"
                        style={{ margin: "0", width: "100%" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                    container
                    item
                    xs={12}
                    md={6}
                    spacing={3}
                  >
                   <Grid item xs={12} md={5}>
                    <p>Aadhaar Number</p>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      name="customer_aadhar"
                      type="text"
                      disabled
                      value={booking.customer_aadhar || ""}
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                    </Grid>
                  </Grid>
                  <Grid
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                    container
                    item
                    xs={12}
                    md={6}
                    spacing={3}
                  >
                   <Grid item xs={12} md={5}>
                    <p>Passport Number</p>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      name="Passport"
                      type="text"
                      disabled
                      value={booking.passport_number || ""}
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
                 {/* <Grid
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                    container
                    item
                    xs={12}
                    md={6}
                    spacing={3}
                  >
                   <Grid item xs={12} md={5}>
                                        <p>Booking Source</p>
                                    </Grid>
                                    <Grid item xs={12} md={7}>
                                        <TextField
                                            name="agentName"
                                            type="text"
                                            value={(booking.partner && booking.partner.partner_name) || ""}
                                            disabled
                                            className="input"
                                            variant="outlined"
                                            style={{ margin: "0", width: "100%" }}
                                        />
                                    </Grid>
                                </Grid> */}
                  <Grid
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                    container
                    item
                    xs={12}
                    md={6}
                    spacing={3}
                  >
                    <Grid item xs={12} md={5}>
                    <p>Finance Amount</p>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      name="financeamount"
                      type="text"
                      value={booking.finance_amount || ""}
                      disabled
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                  </Grid>
                  <Grid
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                    container
                    item
                    xs={12}
                    md={6}
                    spacing={3}
                  >
                    <Grid item xs={12} md={5}>
                    <p>Finance Status</p>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      name="financestatus"
                      type="text"
                      value={booking.finance_status || ""}
                      disabled
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                  </Grid>
                  <Grid
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                    container
                    item
                    xs={12}
                    md={6}
                    spacing={3}
                  >
                   <Grid item xs={12} md={5}>
                    <p>Finance Remarks</p>
                  </Grid>
                    <Grid item xs={12} md={7}>
                    <TextField
                      name="financeremark"
                      type="text"
                      value={booking.finance_remarks || ""}
                      disabled
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                    </Grid>
                  </Grid>
                </Grid>
              <Grid
                container
                spacing={4}
                direction="row"
                justify="flex-start"
                alignItems="center"
                style={{ margin: "1rem auto" }}
              >
                <Grid item xs={12}>
                  <h3
                    style={{
                      borderTop: "dotted 1px #cccc",
                      paddingTop: "10px",
                      fontSize: "24px",
                      fontWeight: "bold",
                    }}
                  >
                    Address
                  </h3>
                </Grid>
                <Grid
                  direction="row"
                  justify="space-around"
                  alignItems="center"
                  container
                  item
                  xs={12}
                  md={6}
                  spacing={3}
                >
                  <Grid item xs={12} md={5}>
                    <p>City</p>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      name="city"
                      type="text"
                      value={booking?.display_name || ""}
                      disabled
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  direction="row"
                  justify="space-around"
                  alignItems="center"
                  container
                  item
                  xs={12}
                  md={6}
                  spacing={3}
                >
                  <Grid item xs={12} md={5}>
                   <p>locality</p>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      name="customer_landmark"
                      type="text"
                      value={booking.customer_landmark || ""}
                      disabled
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  direction="row"
                  justify="space-around"
                  alignItems="center"
                  container
                  item
                  xs={12}
                  md={6}
                  spacing={3}
                >
                  <Grid item xs={12} md={5}>
                    <p>House No./Plot No./Flat No./Door No./Shop/ c/o</p>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      name="customer_address"
                      type="text"
                      value={
                        (booking.customer_address &&
                          booking.customer_address) ||
                        ""
                      }
                      disabled
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  direction="row"
                  justify="space-around"
                  alignItems="center"
                  container
                  item
                  xs={12}
                  md={6}
                  spacing={3}
                >
                  <Grid item xs={12} md={5}>
                    <p>
                      Apartment/Building /Colony/ Block/Sector/
                      Street/Gali/Road/Chawl
                    </p>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      name="customer_address"
                      type="text"
                      value={
                        (booking?.address_line2 && booking?.address_line2) || ""
                      }
                      disabled
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  direction="row"
                  justify="space-around"
                  alignItems="center"
                  container
                  item
                  xs={12}
                  md={6}
                  spacing={3}
                >
                  <Grid item xs={12} md={5}>
                    <p>Landmark/Sublocality</p>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      name="customer_address"
                      type="text"
                      value={(booking?.landmark && booking?.landmark) || ""}
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  direction="row"
                  justify="space-around"
                  alignItems="center"
                  container
                  item
                  xs={12}
                  md={6}
                  spacing={3}
                >
                  <Grid item xs={12} md={5}>
                    <p>Pincode</p>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      name="pincode"
                      type="text"
                      disabled
                      value={
                        (booking.customer_areapincode &&
                          booking.customer_areapincode.pincode) ||
                        ""
                      }
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                container
                spacing={4}
                direction="row"
                justify="flex-start"
                alignItems="center"
                style={{ margin: "1rem auto" }}
              >
                {booking.payment_image &&
                  booking.payment_image.map((image: any) => {
                    return (
                      <div>
                        <h5>Payment Image</h5>
                        <Grid
                          key={image.id}
                          item
                          xs={12}
                          sm={6}
                          md={4}
                          lg={3}
                          spacing={3}
                        >
                          <img
                            src={image.image}
                            alt="payment"
                            style={{ width: "100%", aspectRatio: "1/1" }}
                          />
                        </Grid>
                      </div>
                    );
                  })}
              </Grid>
            </>
          )}
        </Paper>
        <Popover
          id={popid}
          open={openAnchor}
          anchorEl={anchorEl}
          onClose={handleAnchorClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Typography className={classes.typography}>
            {unmaskedNumber.number}
          </Typography>
        </Popover>
        <RaiseComplaintModal
          open={raiseComplaint}
          setOpen={setRaiseComplaint}
          bookingId={Number(bookingId)}
          complaints={complaints}
          leadId={0} booking={undefined}   
               />
        
      </main>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  booking: state.PhleboAdminReducer.booking,
  pin_code: state.PhleboAdminReducer.pin_code,
  booking_slots: state.PhleboAdminReducer.booking_slots,
  packageList: state.PhleboAdminReducer.packageList,
  additional_booking: state.PhleboAdminReducer.additional_booking,
  unmaskedNumber: state.PhleboAdminReducer.unmaskedNumber,
  complaints: state.PhleboAdminReducer.complaints,
  loading: state.PhleboAdminReducer.loading,
  hivpackageList: state.PhleboAdminReducer.hivpackageList,
  biomarkerScreening: state.PhleboAdminReducer.biomarkerScreening,
});

export default connect(mapStateToProps, {
  getBookingById,
  getPinCode,
  clickToCallBooking,
  updateBooking,
  getAvailableSlots,
  getPaymentSendLink,
  getPackage,
  updateAdditionalBooking,
  getUnmaskedNumber,
  updatePhleboReachedStatus,
  getHivPackage,
  getBiomarkerScreening,
})(PhleboAdminViewBooking);
