import React, { useState, useEffect, useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Grid,
  TextField,
  withStyles,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from '@mui/material/Button';
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { getLabDetails, getCities } from "../../actions/PhleboAdmin";
import { genrateLabDetailsFilter } from "../../../helpers/generateUrl";
import LabDetailsModel from "./labDetailsModal"
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      marginTop: "2rem",
      width: "100%",
    },
    table: {
      maxWidth: "100%",
    },
    tableHead: {
      background: "rgb(146, 74, 145)!important",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    tableHeadCell: {
      color: "#fff",
      fontWeight: "bold",
      textAlign: "center",
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
      width: "98%",
    },
    input: {
      padding: "1px",
    },
  })
);
const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "20px",
    },
    body: {
      fontSize: 20, fontWeight: 700
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

interface SampleHandOverProps {
  getLabDetails: any;
  labDetails: any;
  getCities: any;
  cities: any;
  loading: Boolean;

}

const BookingsTable: React.FC<SampleHandOverProps> = ({
  getLabDetails,
  getCities,
  cities,
  labDetails,
  loading,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const timer = useRef<any>(0);
  const [bookingId, setBookingId] = useState<any>("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [disableFilterBtn, setDisableFilterBtn] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<any>(false)
  const [city, setCity] = useState<any>([]);
  const [labData, setLabData] = React.useState([]);
  useEffect(() => {
    getLabDetails();
    getCities()
  }, []);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url =
        labDetails.links && labDetails.links.next.split("?")[1];
      getLabDetails(url);
    } else if (newPage < page) {
      let url =
        labDetails.links &&
        labDetails.links.previous.split("?")[1];
      getLabDetails(url);
    }
    setPage(newPage as number);
  };

  useEffect(() => {
    if (disableFilterBtn) {
      setTimeout(() => {
        setDisableFilterBtn(false);
      }, 5000)
    }
  }, [disableFilterBtn])


  const filterTatTableData = (e: any) => {
    setDisableFilterBtn(true);
    const body: any = {
      zone: city,

    };

    const url = genrateLabDetailsFilter(body).substring(2);
    getLabDetails(`?${url}`);
    setPage(0);
  };
  const onKeyDown = (e: any) => {
    if (e.key === "Enter") {
      filterTatTableData(e);
    }
  };

  const handleLabModel = (data: any) => {

    const labD = labDetails.results.find(
      (labdata: any) => labdata.lab_credentials && labdata.lab_credentials.name === data
    );
    setLabData(labD);
    setOpenModal(true)

  }
  return (
    <div style={{ width: "95%" }}>
      <div className={classes.toolbar} />
      <h3 style={{ fontWeight: "bold" }}>LAB DETAILS</h3>
      <Grid
        container
        spacing={1}
        direction="row"
      >

        <Grid item xs={12} sm={4} md={4}>
          <Autocomplete
            id="city"
            onChange={(event, newValue) => {
              let sourceIds: any = [];
              if (newValue) {
                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                sourceIds = obj.map((item: any) => item?.display_name);
              }
              setCity(sourceIds);
            }}
            options={cities || []}
            freeSolo
            blurOnSelect
            aria-required
            limitTags={1}
            multiple
            onKeyDown={onKeyDown}
            getOptionLabel={(option: any) => option?.name}
            getOptionDisabled={(option: any) => {
              return city.includes(option.id);
            }}
            disableClearable
            disableCloseOnSelect
            onInputChange={(event, newInputValue) => {
              clearTimeout(timer.current);
              timer.current = setTimeout(() => {
                getCities(`${newInputValue}`);
              }, 1000);
              if (newInputValue.length === 0) {
                setCity([]);
              }
            }}
            renderInput={(params) => (
              <TextField
                className="input"
                {...params}
                label="City"
                variant="outlined"
                style={{ width: "100%", margin: "0", padding: "0" }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={filterTatTableData}
            disabled={disableFilterBtn}
          >
            Filter
          </Button>
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => history.push("/dashboard/pha/labDetails")}
          >
            Reset
          </Button>
        </Grid>
      </Grid>
      <div className="data-table" style={{ width: "100%", marginTop: "2rem" }}>
        <TableContainer
          className={classes.tableContainer}
          component={Paper}
          style={{ maxHeight: "550px" }}
        >
          <Table stickyHeader aria-label="simple table">
            <TableHead className={classes.tableHead}>
              <StyledTableRow>
                <StyledTableCell className={classes.tableHeadCell}>
                  LAB NAME
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Address
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Certification
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  ACTION
                </StyledTableCell>


              </StyledTableRow>
            </TableHead>
            <TableBody>
              {labDetails &&
                labDetails?.results &&
                labDetails?.results?.map((data: any, index: any) => {
                  return (
                    <StyledTableRow key={index}>

                      <StyledTableCell align="center">
                        {data?.lab_credentials?.name}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.lab_credentials?.address}
                      </StyledTableCell>


                      <StyledTableCell align="center">
                        {data?.lab_credentials?.lab_certification === "NABL Certified" ? (
                          <div>
                            <p style={{ marginBottom: 0 }}>NABL Certified</p> <br />
                            {data?.lab_credentials?.nabl_certification_number && <p style={{ marginTop: '5px', fontSize: '15px' }}>{`(${data?.lab_credentials?.nabl_certification_number})`}</p>}
                            <p style={{ marginBottom: 0 }}>ISO Certified</p>
                          </div>
                        ) : "Follow NABL Guidelines"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <StyledTableCell align="center">

                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                              handleLabModel(data?.lab_credentials?.name);
                            }}
                          >
                            Lab Details
                          </Button>

                        </StyledTableCell>
                      </StyledTableCell>




                    </StyledTableRow>
                  );
                })}
            </TableBody>
            <TableFooter>
              <StyledTableRow>
                <TablePagination
                  colSpan={3}
                  count={labDetails?.count || 0}
                  rowsPerPageOptions={[]}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                />
              </StyledTableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
      {labDetails?.results && labDetails?.results?.length === 0 && (
        <h6 style={{ textAlign: "center" }}>No Data Found</h6>
      )}
      <LabDetailsModel setOpenModal={setOpenModal} openModal={openModal} labDetails={labData} />
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  labDetails: state.PhleboAdminReducer.labDetails,
  loading: state.PhleboAdminReducer.loading,
  cities: state.PhleboAdminReducer.cities,
});
export default connect(mapStateToProps, {
  getCities,
  getLabDetails,
})(BookingsTable);
