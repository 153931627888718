import "./index.sass";
import React, { useState } from "react";
import {
  createStyles,
  Grid,
  makeStyles,
  withStyles,
  Theme,
} from "@material-ui/core";
import Button from '@mui/material/Button';
import { TextField } from "@material-ui/core";
import { connect } from "react-redux";
import {
  getAssignedUnassignedBooking,
  getZone,
  getMapMyIndiaAccessToken,
  getCities,
  getMapBookings,
  getPhleboCurrentLocation,
  getBookingById,
  getCollectionSlot
} from "../../actions/RouteManagerAction";
import { useEffect } from "react";
import { useRef } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useHistory } from "react-router-dom";
import axios from 'axios';
import { generateBookingFilterUrl } from "../../../helpers/generateUrl";
import QuizRoundedIcon from '@mui/icons-material/QuizRounded';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';

const position = [51.505, -0.09];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
    },
    tableContainer: {
      marginTop: "2rem",
      top: "200px",
    },
    mapCSS: {
      height: "700px",
      width: "90%"
    }
  })
);

interface ViewBookingOnMapProps {
  getAssignedUnassignedBooking: any;
  allBookings: any;
  getZone: any;
  zoneList: any;
  loading: any;
  getMapMyIndiaAccessToken: any;
  mapmyindiaAccessToken: any;
  getCities: any;
  cities: any;
  getMapBookings: any;
  mapBooking: any;
  getPhleboCurrentLocation: any;
  getCollectionSlot: any;
  phleboCurrentLocation: any;
  getBookingById: any;
  booking: any;
  collectionSlot: any;
}

const ViewBookingOnMap: React.FC<ViewBookingOnMapProps> = ({
  getAssignedUnassignedBooking,
  allBookings,
  getZone,
  zoneList,
  loading,
  getMapMyIndiaAccessToken,
  mapmyindiaAccessToken,
  getCities,
  cities,
  getMapBookings,
  mapBooking,
  getCollectionSlot,
  getPhleboCurrentLocation,
  phleboCurrentLocation,
  getBookingById,
  collectionSlot,
  booking
}) => {
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    getCities();
    getZone();
    getCollectionSlot()
    getMapMyIndiaAccessToken();
    // const todayDate = new Date().toISOString().slice(0, 10)
    // getAssignedUnassignedBooking(`multiple_city=1&collection_date=${todayDate}`);
    return () => {
      getAssignedUnassignedBooking("", true);
    };
  }, []);

  const [city, setCity] = useState(0);
  const [collection_date, setCollection_date] = useState("");

  const [bookings, setBookings] = useState<any>([]);
  const [phlebos, setPhlebos] = useState<any>([]);
  const [zoneName, setZoneName] = useState<string>("none");
  const [phleboIds, setPhleboIds] = useState<any>([])
  const [accessToken, setAccessToken] = useState<any>("");
  const [phleboUserId, setPhleboUserId] = useState<any>("")
  const [mapMyIndiaphleboUserId, setMapMyIndiaPhleboUserId] = useState<any>("")
  const [mapMyIndiaLiveResponse, setMapMyIndiaLiveResponse] = useState<any>([])
  const [bookingId, setBookingId] = useState<any>("")
  const timer = useRef<any>(0);
  const [open, setOpen] = React.useState(false);
  const [mapLoading, setMapLoading] = useState<boolean>(false)
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [slot, setSlot] = useState<any>("")
  let mapData: any

  useEffect(() => {
    let tempBookings: any = [];
    let tempPhlebos: any = [];
    Object.keys(allBookings).map((el: any) => {
      if (el !== "phlebos") {
        tempBookings.push(allBookings[el]);
      } else {
        tempPhlebos.push(...allBookings[el]);
      }
    });
    setBookings(tempBookings.filter((item: any) => item !== undefined));
    setPhlebos(tempPhlebos.filter((item: any) => item !== undefined));
  }, [allBookings]);

  useEffect(() => {
    if (mapmyindiaAccessToken?.accesstoken) {
      setAccessToken(mapmyindiaAccessToken?.accesstoken)
    }
  }, [mapmyindiaAccessToken]);

  const filterBookings = () => {
    const body: any = {
      city,
      collection_date,
      phlebo: phleboUserId,
      bookingId: bookingId,
      slot: slot,

    };
    const url = generateBookingFilterUrl(body).substring(2);
    getAssignedUnassignedBooking(url);
    getMapBookings(`${url}&get_all=true`)

  };

  useEffect(() => {
    if (city !== 0 && city !== undefined && city !== null) {
      getPhleboCurrentLocation(`?city=${city}`)
    }
  }, [city])

  const getAutoCompleteZone = (val: string) => {
    getZone(val);
  };

  const loadExternalScript = (token: any, call: any) => {
    var mapurl =
      `https://apis.mapmyindia.com/advancedmaps/api/` +
      token +
      `/map_sdk?layer=vector&v=2.0`;
    const scriptElement = document.createElement("script");
    document.head.appendChild(scriptElement);
    scriptElement.onload = () => {
      return call(true);
    };
    scriptElement.id = "mapScript";
    scriptElement.src = mapurl;
    scriptElement.async = true;
    scriptElement.defer = true;
  };


  const getPhleboLiveLocation = async () => {

    let config = {
      headers: {
        'Authorization': `Bearer ${accessToken}`,

      },
    }
    if (phleboUserId === "") {
      setMapLoading(true)
      axios.get(`https://intouch.mapmyindia.com/iot/api/device/?id=${phleboIds.join(",")}`, config)
        .then(function (response: any) {
          setMapMyIndiaLiveResponse(response)
          setMapLoading(false)
        })
        .catch(function (error: any) {
          console.log("live loc err", error);
        })
    }
    else {
      axios.get(`https://intouch.mapmyindia.com/iot/api/device/?id=${mapMyIndiaphleboUserId}`, config)
        .then(function (response: any) {
          setMapMyIndiaLiveResponse(response)
          setMapLoading(false)

        })
        .catch(function (error: any) {
          console.log("live loc err", error);
        })
    }


  }
  useEffect(() => {
    if (phleboIds.length !== 0) {
      getPhleboLiveLocation();
    }
  }, [phleboIds, phleboUserId, bookingId])

  useEffect(() => {
    const phleboId: any = []
    phleboCurrentLocation && phleboCurrentLocation?.phlebos && phleboCurrentLocation?.phlebos.length > 0 && phleboCurrentLocation?.phlebos.map((data: any) => {
      if (data.mapmyindiaid !== null) {
        phleboId.push(data.mapmyindiaid)
      }
    })
    setPhleboIds(phleboId)
  }, [phleboCurrentLocation])


  useEffect(() => {
    if (mapmyindiaAccessToken?.accesstoken !== undefined) {
      loadExternalScript(mapmyindiaAccessToken?.accesstoken, (data: any) => {
        if (data) {
          if (window.hasOwnProperty("MapmyIndia")) {
            mapData = window['MapmyIndia'].Map("tempMap", {
              center: [28.627941, 77.37493],
              zoom: 4,
              zoomControl: true,
            });
            mapData.on("load", mapMarketDisplay)
          }
        }
      });

    }
  }, [mapmyindiaAccessToken?.accesstoken, mapMyIndiaLiveResponse, mapBooking]);
  const mapMarketDisplay = () => {
    window['MapmyIndia'].Polyline({
      map: mapData,
      // editable:JSON.parse(edit),
      path: eval(phleboCurrentLocation && phleboCurrentLocation?.geofancearea && phleboCurrentLocation?.geofancearea.length > 0 && phleboCurrentLocation.geofancearea),
      strokeColor: "#9c27b0",
      strokeOpacity: 1.0,
      strokeWeight: 9,
      fitbounds: true,
      lineGap: 0,
      fitboundOptions: { padding: 120, duration: 1000 },
      popupHtml: "route 1",
      popupOptions: { offset: { 'bottom': [0, -20] } }
    })
    {
      mapBooking && mapBooking.results && mapBooking.results.length > 0 && mapBooking.results.map((data: any) => (
        window['MapmyIndia'].addMarker({
          map: mapData,
          position: { "lat": Number(data.customer_latitude), "lng": Number(data.customer_longitude) },
          fitbounds: false,
          fitboundOptions: { padding: 120, duration: 1000, maxZoom: 12 },
          width: "35px",
          height: "45px",
          icon: data?.pickup_status === "confirmed" ? "https://staticredcliffelabs.s3.amazonaws.com/media/gallary-file/None/c0353a79-fe6f-4a4a-9b68-682f1dc003ef.png" : data?.pickup_status === "pending" ? "https://staticredcliffelabs.s3.amazonaws.com/media/gallary-file/None/24e0a1f6-b5d5-4053-bf9b-c4eb4ea488b2.png" : data?.pickup_status === "cancelled" ? "https://staticredcliffelabs.s3.amazonaws.com/media/gallary-file/None/fdde892b-287b-474a-896f-ee35d33fcc22.png" : data?.pickup_status === "hold" ? "https://staticredcliffelabs.s3.amazonaws.com/media/gallary-file/None/75a33219-859c-40c4-ad8a-9629a2a8a171.png" : "",
          popupOptions: { "openPopup": false },
          popupHtml: `<div className="cards">
          <div className="card_child">
            <span className="spans">Booking Id :${data?.pk} </span>
          </div>
          <div className="card_child">
              <span className="spans">Customer Name :${data?.customer_name && data?.customer_name} </span>
          </div>
           <div className="card_child">
            <span className="spans">Amount : &#8377;${data?.receivable_amount}</span> 
           </div>
           <div className="card_child">
           <span className="spans">Phlebo Name : ${data?.phlebo && data?.phlebo?.name}</span> 
           </div>
             <div className="card_child">
              <span className="spans">Address : ${data?.booking_full_address}</span>
             </div>
             <div className="card_child">
              <span className="spans">Collection Date : ${data?.collection_date}</span>
             </div>
             <div className="card_child">
              <span className="spans">Collection Time : ${data?.collection_slot?.slot}</span>
             </div>
             <div className="card_child">
              <span className="spans">Pickup Status : ${data?.pickup_status}</span>
             </div>
            
          </div>`,
          draggable: false
        })

      ))
    }

    {
      mapMyIndiaLiveResponse && mapMyIndiaLiveResponse?.data && mapMyIndiaLiveResponse?.data?.data && mapMyIndiaLiveResponse?.data?.data.length > 0 && mapMyIndiaLiveResponse?.data?.data.map((loc: any) => (
        window['MapmyIndia'].addMarker({
          map: mapData,
          position: { "lat": Number(loc?.location?.latitude), "lng": Number(loc?.location?.longitude) },
          fitbounds: false,
          fitboundOptions: { padding: 120, duration: 1000, maxZoom: 12 },
          width: "35px",
          height: "45px",
          icon: "https://cdnjs.cloudflare.com/ajax/libs/emojione/2.2.7/assets/svg/1f3cd.svg",
          popupOptions: { "openPopup": false },
          popupHtml: `<div className="cards">
          <div className="card_child">
              <span className="spans">Phlebo Name :${loc?.deviceDetails?.name && loc?.deviceDetails?.name} </span>
          </div>  
          </div>`,
          draggable: false
        })
      ))
    }
  }


  const handleBookingFilter = () => {
    if (bookingId !== "") {
      getBookingById(`${bookingId}/`)
    }
  }
  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <h2> <QuizRoundedIcon color="primary" onClick={handleOpen} /></h2>
      <Grid
        container
        spacing={1}
        direction="row"
        style={{
          marginTop: "20px",
          padding: "10px",
          background: "#f3f3f3",
          borderRadius: 8,
        }}
      >
        <Grid item xs={6} sm={12} md={12}>
          <strong>Filter</strong>
        </Grid>
        <Grid item xs={6} sm={4} md={2}>
          <Autocomplete
            id="city"
            onChange={(event, newValue) => {
              if (newValue) {
                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                setCity(obj.id);
              }
            }}
            options={cities || []}
            freeSolo
            blurOnSelect
            aria-required
            getOptionLabel={(option: any) => option.name}
            onInputChange={(event, newInputValue) => {
              clearTimeout(timer.current);
              timer.current = setTimeout(() => {
                getCities(newInputValue);
              }, 1000);
              if (newInputValue.length === 0) {
                setCity(0);
              }
            }}
            renderInput={(params) => (
              <TextField
                className="input"
                {...params}
                label="City *"
                variant="outlined"
                style={{ width: "100%", margin: "0", padding: "0" }}
                inputProps={{
                  ...params.inputProps,
                  onKeyDown: (e) => {
                        if (e.key === 'Enter') {
                          e.stopPropagation();
                        }
                  },
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={2}>
          <TextField
            name="collection_date"
            type="date"
            value={collection_date}
            className="input"
            label="Collection Date *"
            variant="outlined"
            onChange={(e) => setCollection_date(e.target.value as string)}
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={2}>
          <TextField
            className="input"
            name="booking"
            type="number"
            label="Booking Id"
            value={bookingId}
            variant="outlined"
            disabled={collection_date === "" || city === 0}
            onWheel={(e: any) => e.target.blur()}
            onChange={(e) => setBookingId(e.target.value)}
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={2}>
          <Autocomplete
            id="phlebo"
            onChange={(event, newValue) => {
              if (newValue) {
                let obj = JSON.parse(JSON.stringify(newValue, null, " "));

                setPhleboUserId(obj.id);
                setMapMyIndiaPhleboUserId(obj.mapmyindiaid);
              }
            }}
            options={phleboCurrentLocation?.phlebos || []}
            freeSolo
            blurOnSelect
            aria-required
            disabled={collection_date === "" || city === 0}
            getOptionLabel={(option: any) => option.user?.username}
            onInputChange={(event, newInputValue) => {
              clearTimeout(timer.current);
              if (newInputValue.length === 0) {
                setPhleboUserId("");
              }

            }}
            renderInput={(params) => (
              <TextField
                className="input"
                {...params}
                label="Phlebo"
                variant="outlined"
                style={{ width: "100%", margin: "0", padding: "0" }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Autocomplete
            id="slot"
            onChange={(event, newValue) => {
              let sourceIds: any = []
              if (newValue) {
                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                setSlot(obj.id)
              }

            }}
            options={collectionSlot.results || []}
            freeSolo
            blurOnSelect
            aria-required
            disabled={collection_date === "" || city === 0}
            getOptionLabel={(option: any) => option.start_time + "-" + option.end_time}
            disableClearable
            disableCloseOnSelect
            onInputChange={(event, newInputValue) => {
              clearTimeout(timer.current)
              timer.current = setTimeout(() => {
                getCollectionSlot(newInputValue)
              }, 1000)
              if (newInputValue.length === 0) {
                setSlot("")
              }
            }}
            renderInput={(params) => (
              <TextField className="input"
                {...params}
                label="Collection Slot"
                variant="outlined"
                style={{ width: "100%", margin: "0", padding: "0" }}
                inputProps={{
                  ...params.inputProps,
                  onKeyDown: (e) => {
                        if (e.key === 'Enter') {
                          e.stopPropagation();
                        }
                  },
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={2}></Grid>
        <Grid item xs={6} sm={4} md={2}>
          <Button
            variant="contained"
            color="secondary"
            // disabled={loading}
            fullWidth
            onClick={filterBookings}
            disabled={collection_date === "" || city === 0}
          >
            Filter All Booking
          </Button>
        </Grid>
        <Grid item xs={6} sm={4} md={2}>
          <Button
            variant="contained"
            color="secondary"

            fullWidth
            onClick={() => history.push("/dashboard/rm/bookings_assign")}
          >
            Back
          </Button>
        </Grid>
        <Grid item style={{color:"red"}}>
         <p>Note: <br/>Collection date and zone is mandatory for filter 
         </p> 
          
          </Grid>
      </Grid>
      <div className={classes.tableContainer}>
        <div>
          <div id="tempMap" className={classes.mapCSS}></div>
        </div>
      </div>
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>Pickup Status Details</DialogTitle>
        <List sx={{ pt: 0 }}>
          <ListItem button>
            <ListItemAvatar>
              <img src="https://staticredcliffelabs.s3.amazonaws.com/media/gallary-file/None/c0353a79-fe6f-4a4a-9b68-682f1dc003ef.png" style={{ width: "60px", height: "60px", borderRadius: "50%" }} />
            </ListItemAvatar>
            <ListItemText primary="Confirm" />
          </ListItem>
          <ListItem button>
            <ListItemAvatar>
              <img src="https://staticredcliffelabs.s3.amazonaws.com/media/gallary-file/None/24e0a1f6-b5d5-4053-bf9b-c4eb4ea488b2.png" style={{ width: "60px", height: "60px", borderRadius: "50%" }} />
            </ListItemAvatar>
            <ListItemText primary="Pending" />
          </ListItem>
          <ListItem button>
            <ListItemAvatar>
              <img src="https://staticredcliffelabs.s3.amazonaws.com/media/gallary-file/None/fdde892b-287b-474a-896f-ee35d33fcc22.png" style={{ width: "60px", height: "60px", borderRadius: "50%" }} />
            </ListItemAvatar>
            <ListItemText primary="Cancelled" />
          </ListItem>
          <ListItem button>
            <ListItemAvatar>
              <img src="https://staticredcliffelabs.s3.amazonaws.com/media/gallary-file/None/75a33219-859c-40c4-ad8a-9629a2a8a171.png" style={{ width: "60px", height: "60px", borderRadius: "50%" }} />
            </ListItemAvatar>
            <ListItemText primary="Hold" />
          </ListItem>
        </List>
      </Dialog>
    </main>
  );
};

const mapStateToProps = (state: any) => ({
  allBookings: state.RouteManagerReducer.allBookings,
  loading: state.RouteManagerReducer.loading,
  zoneList: state.RouteManagerReducer.zoneList,
  cities: state.RouteManagerReducer.cities,
  mapmyindiaAccessToken: state.RouteManagerReducer.mapmyindiaAccessToken,
  mapBooking: state.RouteManagerReducer.mapBooking,
  phleboCurrentLocation: state.RouteManagerReducer.phleboCurrentLocation,
  booking: state.RouteManagerReducer.booking,
  collectionSlot: state.RouteManagerReducer.collectionSlot,
});

export default connect(mapStateToProps, {
  getAssignedUnassignedBooking,
  getZone,
  getMapMyIndiaAccessToken,
  getCities,
  getMapBookings,
  getPhleboCurrentLocation,
  getBookingById,
  getCollectionSlot
})(ViewBookingOnMap);
