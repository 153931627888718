import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import {
    withStyles,
    Theme,
    createStyles,
    makeStyles,
} from "@material-ui/core/styles";
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import {
    Grid,
    TextField,
    Paper,
    Table,
    TableCell,
    TableRow,
    TableBody,
    TableContainer,
    TableHead,
    Typography,
    IconButton,
    Collapse
} from "@material-ui/core";
import Box from '@mui/material/Box';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DehazeIcon from '@material-ui/icons/Dehaze';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import DoneIcon from '@material-ui/icons/Done';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import ClearIcon from '@material-ui/icons/Clear';
import PauseIcon from '@material-ui/icons/Pause';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DonutLargeIcon from '@material-ui/icons/DonutLarge';
import PropTypes from 'prop-types';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import TodayIcon from '@material-ui/icons/Today';
import { TableFooter, TablePagination, MenuItem, Select } from "@material-ui/core";
import GetAppIcon from '@material-ui/icons/GetApp';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import { useHistory } from "react-router";
import {
    getBookingJourney,
    getAllBookings,
    getAvailableSlots,
    getPhlebos,
    getPackage,
    getPartners,
    getLeads
} from "../../actions/PhleboAdmin";
import { generateBookingFilterUrl } from "../../../helpers/generateUrl";
// import { generateBookingFilterUrl } from "../../helpers/generateUrl";
import TimeLineComments from './CommentView'
const StyledTableCell:any = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: "#924A91",
            color: theme.palette.common.white,
            fontSize: "14px",
        },
        body: {
            fontSize: 14,
        },
        buttonContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            flexWrap: "wrap"
        },
    })
)(TableCell);

const StyledTableRow:any = withStyles((theme: Theme) =>
    createStyles({
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    })
)(TableRow);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        table: {
            minWidth: 700,
        },
        root: {
            flexGrow: 0,
            width: "100%",
            marginTop: "40px",
        },
        toolbar: {
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        },
        content: {
            width: "90%",
            flexGrow: 21,
            padding: theme.spacing(3),
        },
        paper: {
            // width: "19%",
            display: "flex",
            marginTop: "1rem",
            marginRight: "13px",
            padding: "2rem",
            justifyContent: "space-around",
        },
        input: {
            display: "none"
        },
        contentHeader: {
            width: "100%",
            padding: "0px 10px 40px 10px",
            minHeight: "64px",
        },
        leftContentHeader: {
            width: "50%",
            float: "left",
        },
        rightContentHeader: {
            width: "50%",
            float: "right",
            textAlign: "right",
            paddingRight: "20px",
        },
        paperwork: {
            display: "flex",
            flexWrap: "wrap",
            boxsSizing: "border-box"
        },

        upload: {
            display: "none"
        }
        ,


        buttonContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            flexWrap: "wrap"
        },
        button: {
            margin: theme.spacing(1),

        },
        span1: {
            color: "#651fff"
        },
        span2: {
            color: "#fb8c00"
        },
        span3: {
            color: "#651fff"
        },
        span4: {
            color: "#2e7d32"
        },
        span5: {
            color: "#f44336"
        },
        span6: {
            color: "#4dd0e1"
        },
        span7: {
            color: "#616161"
        },
        span8: {
            color: "#fb8c00"
        },
        tableContainer1: {
            marginTop: "40px"
        },
        tableContainer: {
            marginTop: "2rem",
            position: "sticky",
            top: "200px",
          },


    })
);

interface Props {
    getBookingJourney: any;
    bookingJourney: any;
    getAllBookings: any;
    bookingList: any;
    partnerList: any;
    getAvailableSlots: any;
    getPackage: any;
    getLeads: any;
    getPartners: any;
    agentsList: any;
    lead_source: any;
    cities: any;
    loading: boolean;
    phleboList: any;
}

function createData(name: any, address: any, timeslot: any, amount: any, status: any, action: any) {
    return {
        name,
        address,
        timeslot,
        amount,
        status,
    };
}

function Row(props: any) {
    const { row, bookingJourney } = props;
    const [open, setOpen] = React.useState(false);
    const [openModal, setOpenModal] = React.useState<boolean>(false);
    const classes = useStyles();
    const handleBookingDetails = (id: any) => {
        props.setBooking_Id(id)
    }

    const handleOpenModal = () => {
        setOpenModal(true)
    }
    return (
        <>
            <TableRow className={classes.root}>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon onClick={() => handleBookingDetails(row.pk)} />}
                    </IconButton>
                </TableCell>
                {/* <TableCell style={{ float: "left" }}>{row?.customer_name}</TableCell>  */}
                <TableCell align="center" >{row?.pk}</TableCell>
                <TableCell align="center" >{row?.customer_name}</TableCell>
                <TableCell align="center">{row?.customer_address}</TableCell>
                <TableCell align="center">{row?.collection_slot?.slot}</TableCell>
                <TableCell align="center">{row?.discounted_price?.total_price_package}</TableCell>
                <TableCell align="center">{row?.phlebo === null ? "Booking Unassigned" : "Phlebo Assigned"}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Typography variant="h6" gutterBottom component="div">
                                TimeLine
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Timeline</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow >
                                        <TableCell component="th" scope="row">
                                            <Timeline align="left" style={{ flexDirection: "initial" }}>
                                                <TimelineItem>
                                                    <TimelineSeparator style={{ transform: "rotate(90deg)", marginLeft: "4rem" }} >
                                                        <TimelineDot style={{ backgroundColor: bookingJourney?.booking_creation_time !== null ? "#388e3c" : "#dd2c00" }} />
                                                        <TimelineConnector style={{ backgroundColor: bookingJourney?.booking_creation_time !== null ? "#388e3c" : "#dd2c00", height: "120px" }} />
                                                    </TimelineSeparator>
                                                    <Typography style={{ textAlign: "center" }}>
                                                        Booking_Created
                                                        <br />
                                                        {bookingJourney && bookingJourney?.booking_creation_time ? bookingJourney.booking_creation_time.substring(11, 19) : "Not Found"}{" "}
                                                    </Typography>
                                                </TimelineItem>
                                                <TimelineItem>
                                                    <TimelineSeparator style={{ transform: "rotate(90deg)" }} >
                                                        <TimelineDot
                                                            style={{
                                                                backgroundColor: bookingJourney?.booking_status === "pending" ? "#dd2c00"
                                                                    : bookingJourney?.booking_status === "confirmed" ? "#00c853"
                                                                        : bookingJourney?.booking_status === "cancelled" ? "#dd2c00"
                                                                            : bookingJourney?.booking_status === "rescheduled" ? "#0288d1"
                                                                                : bookingJourney?.booking_status === "resampling" ? "#f57c00"
                                                                                    : bookingJourney?.booking_status === "Sample Not Received Due To Payment Information" ? "#5d4037"
                                                                                        : bookingJourney?.booking_status === "sample received" ? "#64dd17"
                                                                                            : bookingJourney?.booking_status === "partial received" ? "#64dd17"
                                                                                                : "#dd2c00"
                                                            }} />
                                                        <TimelineConnector style={{ backgroundColor: bookingJourney?.booking_status !== null ? "#388e3c" : "#dd2c00", height: "120px" }} />
                                                    </TimelineSeparator>
                                                    <Typography style={{ textAlign: "center" }}>
                                                        Booking_Status
                                                        <br />
                                                        {bookingJourney?.booking_status !== null ? bookingJourney?.booking_status : "Not Found"}{" "}
                                                    </Typography>
                                                </TimelineItem>
                                                <TimelineItem>
                                                    <TimelineSeparator style={{ transform: "rotate(90deg)" }} >
                                                        <TimelineDot
                                                            style={{
                                                                backgroundColor: bookingJourney?.verification_status === "pending" ? "#dd2c00"
                                                                    : bookingJourney?.verification_status === "confirmed" ? "#00c853"
                                                                        : bookingJourney?.verification_status === "cancelled" ? "#dd2c00"
                                                                            : "#dd2c00"
                                                            }} />
                                                        <TimelineConnector style={{ backgroundColor: bookingJourney?.verification_status !== null ? "#388e3c" : "#dd2c00", height: "120px" }} />
                                                    </TimelineSeparator>
                                                    <Typography style={{ textAlign: "center" }}>
                                                        Verificaton_Status
                                                        <br />
                                                        {bookingJourney?.verification_status !== null ? bookingJourney?.verification_status : "Not Found"}{" "}
                                                    </Typography>
                                                </TimelineItem>
                                                <TimelineItem>
                                                    <TimelineSeparator style={{ transform: "rotate(90deg)" }} >
                                                        <TimelineDot style={{ backgroundColor: bookingJourney?.verification_time !== null ? "#388e3c" : "#dd2c00" }} />
                                                        <TimelineConnector style={{ backgroundColor: bookingJourney?.verification_time !== null ? "#388e3c" : "#dd2c00", height: "120px" }} />
                                                    </TimelineSeparator>
                                                    <Typography style={{ textAlign: "center" }}>
                                                        Verification_Time
                                                        <br />
                                                        {bookingJourney && bookingJourney?.verification_time ? bookingJourney.verification_time.substring(11, 19) : "Not Found"}{" "}
                                                    </Typography>
                                                </TimelineItem>
                                                <TimelineItem>
                                                    <TimelineSeparator style={{ transform: "rotate(90deg)" }} >
                                                        <TimelineDot style={{ backgroundColor: bookingJourney?.phlebo_assign_time !== null ? "#388e3c" : "#dd2c00" }} />
                                                        <TimelineConnector style={{ backgroundColor: bookingJourney?.phlebo_assign_time !== null ? "#388e3c" : "#dd2c00", height: "120px" }} />
                                                    </TimelineSeparator>
                                                    <Typography style={{ textAlign: "center" }}>
                                                        Phlebo_Assign_time
                                                        <br />
                                                        {bookingJourney && bookingJourney?.phlebo_assign_time ? bookingJourney.phlebo_assign_time.substring(11, 19) : "Not Found"}{" "}
                                                        <br />
                                                        {bookingJourney && bookingJourney?.phlebo_assign_time ? "Assign" : ""}
                                                    </Typography>
                                                </TimelineItem>
                                                <TimelineItem>
                                                    <TimelineSeparator style={{ transform: "rotate(90deg)" }} >
                                                        <TimelineDot
                                                            style={{
                                                                backgroundColor: bookingJourney?.pickup_status === "pending" ? "#dd2c00"
                                                                    : bookingJourney?.pickup_status === "confirmed" ? "#00c853"
                                                                        : bookingJourney?.pickup_status === "cancelled" ? "#dd2c00"
                                                                            : "#dd2c00"
                                                            }} />
                                                        <TimelineConnector style={{ backgroundColor: bookingJourney?.pickup_status !== null ? "#388e3c" : "#dd2c00", height: "120px" }} />
                                                    </TimelineSeparator>
                                                    <Typography style={{ textAlign: "center" }}>
                                                        Pickup_Status
                                                        <br />
                                                        {bookingJourney?.pickup_status !== null ? bookingJourney?.pickup_status : "Not Picked"}{" "}
                                                    </Typography>
                                                </TimelineItem>
                                                <TimelineItem>
                                                    <TimelineSeparator style={{ transform: "rotate(90deg)" }} >
                                                        <TimelineDot style={{ backgroundColor: bookingJourney?.pickup_time !== null ? "#388e3c" : "#dd2c00" }} />
                                                        <TimelineConnector style={{ backgroundColor: bookingJourney?.pickup_time !== null ? "#388e3c" : "#dd2c00", height: "120px" }} />
                                                    </TimelineSeparator>
                                                    <Typography style={{ textAlign: "center" }}>
                                                        pickup_Time
                                                        <br />
                                                        {bookingJourney && bookingJourney?.pickup_time ? bookingJourney.pickup_time.substring(11, 19) : "Not Picked"}{" "}
                                                    </Typography>
                                                </TimelineItem>
                                            </Timeline>
                                        </TableCell>
                                    </TableRow>
                                    <Grid container spacing={3} direction="row" alignItems="center" justify="center">
                                        <Grid item xs={12} sm={6} md={3}>
                                            <Button onClick={() => handleOpenModal()}>View Timeline</Button>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={3}>
                                            <Link to={`/dashboard/pha/booking-view/${row.pk}`} style={{ fontSize: "20px" }}>
                                                <Button>View Booking</Button>
                                            </Link>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={3}>
                                            <Link to={`/dashboard/pha/booking-edit/${row.pk}/edit`} style={{ fontSize: "20px" }}>
                                                <Button>Edit Booking</Button>
                                            </Link>
                                        </Grid>
                                    </Grid>
                                </TableBody>

                            </Table>

                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
            <TimeLineComments openModal={openModal} setOpenModal={setOpenModal} bookingJourney={bookingJourney} booking={row} />
        </>
    );
}

const PinCodePage: React.FC<Props> = ({
    getBookingJourney,
    bookingJourney,
    getAllBookings,
    bookingList,
    agentsList,
    getAvailableSlots,
    getLeads,
    partnerList,
    getPartners,
    cities,
    lead_source,
    phleboList,
    getPackage,
    loading
}) => {
    const classes = useStyles();
    const history = useHistory()
    const [booking_Id, setBooking_Id] = useState<any>(0)
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [search, setSearch] = useState<String>("")

    const [status, setStatus] = useState<any>([])
    const [source, setSource] = useState<any>([])
    const [partner, setPartner] = useState<any>([])
    const [agent, setAgent] = useState<any>([])
    const [city, setCity] = useState<any>([])
    const [start_date, setStart_date] = useState("")
    const [end_date, setEnd_date] = useState("")
    const [booking_date, setBooking_date] = useState("")
    const [collection_date, setCollection_date] = useState("")
    const [verificationStatus, setVerificationStatus] = useState<any>([])
    const [pickup_status, setPickup_status] = useState<any>([])
    const [name, setName] = useState("")
    const [phlebo, setPhlebo] = useState<any>([])
    const [phone, setPhone] = useState("")
    const [bookingId, setBookingid] = React.useState<any>("")
    const [userGroup, setUserGroup] = useState("none")
    // const [page, setPage] = useState(0)
    const [sample_registered, setSampleRegisterd] = useState<any>("none")
    const [report_status, setReportStatus] = useState<any>("none")
    const timer = useRef<any>(0)

    useEffect(() => {
        getAllBookings()
    }, [])
    const handleSearch = (e: React.ChangeEvent<{ value: unknown }>) => {
        setSearch(e.target.value as string)
    }
    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        if (newPage > page) {
            let url = bookingList.links && bookingList.links.next.split("?")[1];
            getAllBookings(url);
        } else if (newPage < page) {
            let url = bookingList.links && bookingList.links.previous.split("?")[1];
            getAllBookings(url);
        }
        setPage(newPage as number);
    };
    useEffect(() => {
        if (booking_Id !== 0) {
            getBookingJourney(booking_Id)
        }
    }, [booking_Id])
    // useEffect(() => {
    //     // getAgentList()
    //     // getPartners()
    //     getAllBookings()
    //     // getCities()
    //     // getPhlebos()
    //     // getLeadSource()
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [])

    const filterBookings = () => {
        const body: any = {
            status: status.join(","),
            verificationStatus: verificationStatus.join(","),
            source: source.join(","),
            agent: agent.join(","),
            city: city.join(","),
            start_date,
            end_date,
            booking_date,
            bookingId,
            collection_date,
            pickup_status: pickup_status.join(","),
            name,
            phlebo: phlebo.join(","),
            partner: partner.join(","),
            user_group: userGroup,
            phone,
            sample_registered,
            report_status,
        }
       
        const url = generateBookingFilterUrl(body).substring(2)
        getAllBookings(url ? `${url}&page=1` : "page=1");
        setPage(0)
    }


    const searchLead = () => {
        let body = {
            search_by: "call_id",
            value: search,
        };
        getLeads(body, "number=true")
    }
    
    return (
        <>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <div className={classes.leftContentHeader}>
                </div>
                {/* <Link to={`/dashboard/pha/aditional-booking`} >
                    <Button
                        variant="contained"
                        color="primary">
                        + ADD NEW BOOKING
                    </Button>
                </Link> */}
                {/* <input
                    accept="image/*,.pdf"
                    className={classes.input}
                    id="contained-button-file"
                    multiple
                    type="file"
                />
                <label htmlFor="contained-button-file">
                    <Button variant="contained" color="primary" component="span" style={{ marginLeft: "10px" }}>
                        UPLOAD FILE
                    </Button>
                </label> */}


                <span style={{
                    color: "blue", position: "absolute", right: "0",
                    fontSize: "1rem", marginBottom: "1rem",
                    marginRight: "2rem",
                }} >
                </span>
                <div className={classes.paperwork}>
                    <Grid container spacing={3} direction="row" alignItems="center" justify="center">
                        <Grid item xs={12} sm={6} md={3}>
                            <TextField
                                className="input"
                                name="booking"
                                type="number"
                                placeholder="Booking Id"
                                value={bookingId}
                                variant="outlined"
                                onChange={(e) => setBookingid(e.target.value)}
                                style={{ width: "100%" }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <TextField
                                className="input"
                                name="name"
                                type="text"
                                label="Name"
                                value={name}
                                variant="outlined"
                                onChange={(e) => setName(e.target.value as string)}
                                style={{ width: "100%" }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <TextField
                                className="input"
                                name="phone"
                                type="number"
                                label="Phone"
                                value={phone}
                                variant="outlined"
                                onChange={(e) => setPhone(e.target.value as string)}
                                style={{ width: "100%" }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Autocomplete
                                id="city"
                                onChange={(event, newValue) => {
                                    let sourceIds: any = []
                                    if (newValue) {
                                        let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                        sourceIds = obj.map((item: any) => item.id)
                                    }
                                    setCity(sourceIds)
                                }}
                                options={cities || []}
                                freeSolo
                                blurOnSelect
                                aria-required
                                limitTags={1}
                                multiple
                                getOptionLabel={(option: any) => option.name}
                                getOptionDisabled={(option: any) => {
                                    return city.includes(option.id)
                                }}
                                disableClearable
                                disableCloseOnSelect
                                // onInputChange={(event, newInputValue) => {
                                //     clearTimeout(timer.current)
                                //     timer.current = setTimeout(() => {
                                //         getCities(newInputValue)
                                //     }, 1000)
                                //     if (newInputValue.length === 0) {
                                //         setCity([])
                                //     }
                                // }}
                                renderInput={(params) => (
                                    <TextField className="input"
                                        {...params}
                                        placeholder="City"
                                        variant="outlined"
                                        style={{ width: "100%", margin: "0", padding: "0" }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Autocomplete
                                id="city"
                                onChange={(event, newValue) => {
                                    let sourceIds: any = []
                                    if (newValue) {
                                        let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                        sourceIds = obj.map((item: any) => item.value)
                                    }
                                    setStatus(sourceIds)
                                }}
                                options={[
                                    {
                                        text: "Pending",
                                        value: "pending"
                                    },
                                    {
                                        text: "Confirmed",
                                        value: "confirmed"
                                    },
                                    {
                                        text: "Cancelled",
                                        value: "cancelled"
                                    },
                                    {
                                        text: "Rescheduled",
                                        value: "rescheduled"
                                    },
                                    {
                                        text: "Resampling",
                                        value: "resampling"
                                    },
                                    {
                                        text: "Sample Not Received Due To Payment Information",
                                        value: "Sample Not Received Due To Payment Information"
                                    },
                                    {
                                        text: "Sample Received",
                                        value: "sample received"
                                    },
                                    {
                                        text: "Partial Received",
                                        value: "partial received"
                                    },
                                ]}
                                freeSolo
                                blurOnSelect
                                aria-required
                                limitTags={1}
                                multiple
                                getOptionLabel={(option: any) => option.text}
                                getOptionDisabled={(option: any) => {
                                    return status.includes(option.value)
                                }}
                                disableClearable
                                disableCloseOnSelect
                                renderInput={(params) => (
                                    <TextField className="input"
                                        {...params}
                                        placeholder="Booking Status"
                                        variant="outlined"
                                        style={{ width: "100%", margin: "0", padding: "0" }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Autocomplete
                                id="partner"
                                onChange={(event, newValue) => {
                                    let sourceIds: any = []
                                    if (newValue) {
                                        let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                        sourceIds = obj.map((item: any) => item.id)
                                    }
                                    setPartner(sourceIds)
                                }}
                                freeSolo
                                blurOnSelect
                                limitTags={1}
                                multiple
                                // options={r}
                                options={partnerList.results || []}
                                getOptionDisabled={(option: any) => {
                                    return partner.includes(option.id)
                                }}
                                disableClearable
                                disableCloseOnSelect
                                getOptionLabel={(option: any) => option.user && option.user.name}
                                onInputChange={(event, newInputValue) => {
                                    clearTimeout(timer.current)
                                    timer.current = setTimeout(() => {
                                        getPartners(newInputValue)
                                    }, 1000)
                                    if (newInputValue.length === 0) {
                                        setPartner([])
                                    }
                                }}
                                renderInput={(params) => (
                                    <TextField className="input"
                                        {...params}
                                        placeholder="Partner"
                                        variant="outlined"
                                        style={{ width: "100%", margin: "0", padding: "0" }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Autocomplete
                                onChange={(event, newValue) => {
                                    let sourceIds: any = []
                                    if (newValue) {
                                        let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                        sourceIds = obj.map((item: any) => item.id)
                                    }
                                    setSource(sourceIds)
                                }}
                                freeSolo
                                multiple
                                id="checkboxes-tags-demo"
                                options={lead_source || []}
                                disableCloseOnSelect
                                limitTags={1}
                                disableClearable
                                getOptionLabel={(option: any) => option.name}
                                getOptionDisabled={(option: any) => {
                                    return source.includes(option.id)
                                }}
                                renderInput={(params) => (
                                    <TextField className="input"
                                        {...params}
                                        placeholder="Source"
                                        variant="outlined"
                                        style={{ width: "100%", margin: "0", padding: "0" }}
                                        required
                                    />
                                )}
                            />

                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Autocomplete
                                id="agent"
                                onChange={(event, newValue) => {
                                    let sourceIds: any = []
                                    if (newValue) {
                                        let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                        sourceIds = obj.map((item: any) => item.id)
                                    }
                                    setAgent(sourceIds)
                                }}
                                freeSolo
                                blurOnSelect
                                aria-required
                                limitTags={1}
                                multiple
                                options={agentsList.results || []}
                                getOptionDisabled={(option: any) => {
                                    return agent.includes(option.id)
                                }}
                                disableClearable
                                disableCloseOnSelect
                                getOptionLabel={(option: any) => option.user && option.user.username}
                                // onInputChange={(event, newInputValue) => {
                                //     clearTimeout(timer.current)
                                //     timer.current = setTimeout(() => {
                                //         getAgentList(`?code=${newInputValue.replace(" ", "")}`)
                                //     }, 1000)
                                //     if (newInputValue.length === 0) {
                                //         setPartner([])
                                //     }
                                // }}
                                renderInput={(params) => (
                                    <TextField className="input"
                                        {...params}
                                        placeholder="Agent"
                                        variant="outlined"
                                        style={{ width: "100%", margin: "0", padding: "0" }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Autocomplete
                                id="phlebo"
                                onChange={(event, newValue) => {
                                    let sourceIds: any = []
                                    if (newValue) {
                                        let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                        sourceIds = obj.map((item: any) => item.id)
                                    }
                                    setPhlebo(sourceIds)
                                }}
                                freeSolo
                                blurOnSelect
                                aria-required
                                limitTags={1}
                                multiple
                                options={phleboList.results ? [...phleboList.results, { id: 0, user: { username: "NoPhlebo" } }] : []}
                                getOptionDisabled={(option: any) => {
                                    return phlebo.includes(option.id)
                                }}
                                disableClearable
                                disableCloseOnSelect
                                getOptionLabel={(option: any) => option.user && option.user.username}
                                // onInputChange={(event, newInputValue) => {
                                //     clearTimeout(timer.current)
                                //     timer.current = setTimeout(() => {
                                //         getPhlebos(newInputValue)
                                //     }, 1000)
                                //     if (newInputValue.length === 0) {
                                //         setPhlebo([])
                                //     }
                                // }}
                                renderInput={(params) => (
                                    <TextField className="input"
                                        {...params}
                                        placeholder="Phlebo Name"
                                        variant="outlined"
                                        style={{ width: "100%", margin: "0", padding: "0" }}
                                        required
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Autocomplete
                                id="city"
                                onChange={(event, newValue) => {
                                    let sourceIds: any = []
                                    if (newValue) {
                                        let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                        sourceIds = obj.map((item: any) => item.value)
                                    }
                                    setVerificationStatus(sourceIds)
                                }}
                                options={[
                                    {
                                        text: "Pending",
                                        value: "pending"
                                    },
                                    {
                                        text: "Confirmed",
                                        value: "confirmed"
                                    },
                                    {
                                        text: "Cancelled",
                                        value: "cancelled"
                                    }
                                ]}
                                freeSolo
                                blurOnSelect
                                aria-required
                                limitTags={1}
                                multiple
                                getOptionLabel={(option: any) => option.text}
                                getOptionDisabled={(option: any) => {
                                    return verificationStatus.includes(option.value)
                                }}
                                disableClearable
                                disableCloseOnSelect
                                renderInput={(params) => (
                                    <TextField className="input"
                                        {...params}
                                        placeholder="Verification Status"
                                        variant="outlined"
                                        style={{ width: "100%", margin: "0", padding: "0" }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Autocomplete
                                id="city"
                                onChange={(event, newValue) => {
                                    let sourceIds: any = []
                                    if (newValue) {
                                        let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                        sourceIds = obj.map((item: any) => item.value)
                                    }
                                    setPickup_status(sourceIds)
                                }}
                                options={[
                                    {
                                        text: "Pending",
                                        value: "pending"
                                    },
                                    {
                                        text: "Confirmed",
                                        value: "confirmed"
                                    },
                                    {
                                        text: "Cancelled",
                                        value: "cancelled"
                                    },
                                    {
                                        text: "Hold",
                                        value: "hold"
                                    }
                                ]}
                                freeSolo
                                blurOnSelect
                                aria-required
                                limitTags={1}
                                multiple
                                getOptionLabel={(option: any) => option.text}
                                getOptionDisabled={(option: any) => {
                                    return pickup_status.includes(option.value)
                                }}
                                disableClearable
                                disableCloseOnSelect
                                renderInput={(params) => (
                                    <TextField className="input"
                                        {...params}
                                        placeholder="Pickup Status"
                                        variant="outlined"
                                        style={{ width: "100%", margin: "0", padding: "0" }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Select className="input"
                                name="usergroup"
                                variant="outlined"
                                value={userGroup}

                                style={{ width: "100%" }}
                                onChange={(e) => setUserGroup(e.target.value as string)}
                            >
                                <MenuItem disabled value={"none"}>User Type</MenuItem>
                                <MenuItem value={"SupportExecutive"}>SupportExecutive</MenuItem>
                                <MenuItem value={"SampleAccessioning"}>SampleAccessioning</MenuItem>
                                <MenuItem value={"CustomerExecutive"}>CustomerExecutive</MenuItem>
                                <MenuItem value={"PhleboAdmin"}>PhleboAdmin</MenuItem>
                                <MenuItem value={"Phlebos"}>Phlebos</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <TextField
                                className="input"
                                name="start_date"
                                type="date"
                                label="Start Date"
                                value={booking_date === "" || collection_date === "" ? start_date : ""}
                                variant="outlined"
                                disabled={booking_date !== "" || collection_date !== ""}
                                onChange={(e) => setStart_date(e.target.value as string)}
                                style={{ width: "100%" }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <TextField
                                name="end_date"
                                type="date"
                                value={booking_date === "" || collection_date === "" ? end_date : ""}
                                className="input"
                                label="End Date"
                                variant="outlined"
                                disabled={booking_date !== "" || collection_date !== ""}
                                onChange={(e) => setEnd_date(e.target.value as string)}
                                style={{ width: "100%" }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <TextField
                                name="booking_date"
                                type="date"
                                value={start_date === "" || end_date === "" ? booking_date : ""}
                                className="input"
                                label="Booking Date"
                                variant="outlined"
                                disabled={start_date !== "" || end_date !== ""}
                                onChange={(e) => setBooking_date(e.target.value as string)}
                                style={{ width: "100%" }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <TextField
                                name="collection_date"
                                type="date"
                                value={start_date === "" || end_date === "" ? collection_date : ""}
                                className="input"
                                label="Collection Date"
                                variant="outlined"
                                disabled={start_date !== "" || end_date !== ""}
                                onChange={(e) => setCollection_date(e.target.value as string)}
                                style={{ width: "100%" }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>

                            <Select className="input"
                                name="sampleregisterd"
                                variant="outlined"
                                value={sample_registered}
                                style={{ width: "100%", margin: "0" }}

                                onChange={(e) => setSampleRegisterd(e.target.value as String)}

                            >
                                <MenuItem disabled value={"none"}>Sample Type</MenuItem>
                                <MenuItem value={"true"}>Registerd</MenuItem>
                                <MenuItem value={"false"}>Not Registerd</MenuItem>

                            </Select>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>

                            <Select className="input"
                                name="sampleregisterd"
                                variant="outlined"
                                value={report_status}
                                style={{ width: "100%", margin: "0" }}

                                onChange={(e) => setReportStatus(e.target.value as String)}

                            >
                                <MenuItem disabled value={"none"}>Report Status</MenuItem>
                                <MenuItem value={"na"}>None</MenuItem>
                                <MenuItem value={"pending"}>Pending</MenuItem>
                                <MenuItem value={"partial"}>Partial</MenuItem>
                                <MenuItem value={"consolidate"}>Consolidate</MenuItem>

                            </Select>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Button
                                variant="contained"
                                color="secondary"
                                disabled={loading}
                                fullWidth
                                onClick={filterBookings}
                            >
                                Filter
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Button
                                variant="contained"
                                color="primary"
                              
                                fullWidth
                                onClick={() => history.push("/dashboard/pha/phleboBookingView")}
                            >
                                Reset
                            </Button>
                        </Grid>
                    </Grid>

                </div>
                <div className={classes.tableContainer1}>
                        <Grid container spacing={3} style={{ margin: "1rem auto" }}>
                            <Grid item xs={12} md={12} style={{ display: "flex" }}>
                                <div className="heading">
                                    <h3 ></h3>
                                    <p></p>
                                </div>
                                {/* <div className="buttondiv" style={{ marginLeft: "auto" }}>
                                    <Button variant="contained" style={{ height: "56px", marginBottom: "10px" }} color="default" startIcon={<GetAppIcon />} >DOWNLOAD</Button>

                                    <TextField className="input"
                                        id="search"
                                        type="search"
                                        value={search}
                                        variant="outlined"
                                        placeholder="Search"
                                        onChange={(e) => handleSearch(e)}
                                        style={{ margin: "0", padding: "0 9px" }}
                                    />
                                    <Button variant="contained" style={{ height: "56px" }} disabled={search.length === 0} color="primary" onClick={searchLead}>Search</Button>
                                </div> */}

                            </Grid>
                        </Grid>
                <TableContainer className={classes.tableContainer} component={Paper} style={{ maxHeight: "700px" }}>
                        <Table  stickyHeader aria-label="simple table">
                            <TableHead style={{ backgroundColor: "#924A91" }}>
                                <StyledTableRow >
                                    <StyledTableCell />
                                    <StyledTableCell align="center">BOOKING ID</StyledTableCell>
                                    <StyledTableCell align="center">NAME</StyledTableCell>
                                    <StyledTableCell align="center">ADDRESS</StyledTableCell>
                                    <StyledTableCell align="center">TIME SLOT</StyledTableCell>
                                    <StyledTableCell align="center">AMOUNT</StyledTableCell>
                                    <StyledTableCell align="center">STATUS</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {bookingList && bookingList.results && bookingList.results.length > 0 && bookingList.results.map((row: any) => (
                                    <Row key={row.name} row={row} setBooking_Id={setBooking_Id} bookingJourney={bookingJourney} />
                                ))}
                            </TableBody>
                            <TableFooter>
                                <TablePagination
                                    colSpan={3}
                                    count={bookingList.count || 0}
                                    rowsPerPageOptions={[]}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    onPageChange={handleChangePage}
                                />
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </div>

            </main>
        </>
    );
};
const mapStateToProps = (state: any) => ({
    agentsList: state.PhleboAdminReducer.agentsList,
    bookingJourney: state.PhleboAdminReducer.bookingJourney,
    bookingList: state.PhleboAdminReducer.bookingList,
    phleboList: state.PhleboAdminReducer.phleboList,
    partnerList: state.PhleboAdminReducer.partnerList,
    cities: state.PhleboAdminReducer.cities,
    lead_source: state.PhleboAdminReducer.lead_source,
    loading: state.PhleboAdminReducer.loading,
});
export default connect(mapStateToProps, {
    getBookingJourney,
    getAllBookings,
    getLeads
})(PinCodePage);
