import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
    Grid,
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from '@material-ui/core';
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 0,
        },
        toolbar: {
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        },
        content: {
            width: "80%",
            flexGrow: 21,
        },
        paper: {
            padding: "1rem",
            width: "100%"
        },
        head: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "1rem"
        },
        heading: {
            fontWeight: "normal",
            fontSize: "1.2rem"
        },
        table: {
            maxHeight: "400px",
            margin: "auto",
            overflow: "auto"
        },
    })
);

interface Props {
    children: any;
}

const SalesLeadHomePage: React.FC<Props> = ({ children }) => {
    const classes = useStyles();

    return (
        <>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <Paper elevation={12} className={classes.paper}>
                            <div className={classes.head}>
                                <h3 className={classes.heading}>Summary Booking Date Wise</h3>
                                <Button color="primary" variant="contained">Export</Button>
                            </div>
                            <TableContainer className={classes.table} component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">Booking Date</TableCell>
                                            <TableCell align="center">Total Booking</TableCell>
                                            <TableCell align="center">Count</TableCell>
                                            <TableCell align="center">Revenue</TableCell>
                                            <TableCell align="center">ASP</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center">May 27, 2021</TableCell>
                                            <TableCell align="center">9</TableCell>
                                            <TableCell align="center">9</TableCell>
                                            <TableCell align="center">₹6497.00</TableCell>
                                            <TableCell align="center">₹928.14</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="center">May 27, 2021</TableCell>
                                            <TableCell align="center">9</TableCell>
                                            <TableCell align="center">9</TableCell>
                                            <TableCell align="center">₹6497.00</TableCell>
                                            <TableCell align="center">₹928.14</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="center">May 27, 2021</TableCell>
                                            <TableCell align="center">9</TableCell>
                                            <TableCell align="center">9</TableCell>
                                            <TableCell align="center">₹6497.00</TableCell>
                                            <TableCell align="center">₹928.14</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Paper elevation={12} className={classes.paper}>
                            <div className={classes.head}>
                                <h3 className={classes.heading}>Summary Collection Date Wise</h3>
                                <Button color="primary" variant="contained">Export</Button>
                            </div>
                            <TableContainer className={classes.table} component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">Booking Date</TableCell>
                                            <TableCell align="center">Total Booking</TableCell>
                                            <TableCell align="center">Count</TableCell>
                                            <TableCell align="center">Revenue</TableCell>
                                            <TableCell align="center">ASP</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center">May 27, 2021</TableCell>
                                            <TableCell align="center">9</TableCell>
                                            <TableCell align="center">9</TableCell>
                                            <TableCell align="center">₹6497.00</TableCell>
                                            <TableCell align="center">₹928.14</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="center">May 27, 2021</TableCell>
                                            <TableCell align="center">9</TableCell>
                                            <TableCell align="center">9</TableCell>
                                            <TableCell align="center">₹6497.00</TableCell>
                                            <TableCell align="center">₹928.14</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="center">May 27, 2021</TableCell>
                                            <TableCell align="center">9</TableCell>
                                            <TableCell align="center">9</TableCell>
                                            <TableCell align="center">₹6497.00</TableCell>
                                            <TableCell align="center">₹928.14</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Paper elevation={12} className={classes.paper}>
                            <div className={classes.head}>
                                <h3 className={classes.heading}>Agent Summary</h3>
                                <Button color="primary" variant="contained">Export</Button>
                            </div>
                            <TableContainer className={classes.table} component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">Agent Name</TableCell>
                                            <TableCell align="center">Total Booking</TableCell>
                                            <TableCell align="center">Count</TableCell>
                                            <TableCell align="center">Revenue</TableCell>
                                            <TableCell align="center">ASP</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center">Monika Mohan</TableCell>
                                            <TableCell align="center">9</TableCell>
                                            <TableCell align="center">9</TableCell>
                                            <TableCell align="center">₹6497.00</TableCell>
                                            <TableCell align="center">₹928.14</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="center">Monika Mohan</TableCell>
                                            <TableCell align="center">9</TableCell>
                                            <TableCell align="center">9</TableCell>
                                            <TableCell align="center">₹6497.00</TableCell>
                                            <TableCell align="center">₹928.14</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="center">Monika Mohan</TableCell>
                                            <TableCell align="center">9</TableCell>
                                            <TableCell align="center">9</TableCell>
                                            <TableCell align="center">₹6497.00</TableCell>
                                            <TableCell align="center">₹928.14</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Paper elevation={12} className={classes.paper}>
                            <div className={classes.head}>
                                <h3 className={classes.heading}>Zone Summary</h3>
                                <Button color="primary" variant="contained">Export</Button>
                            </div>
                            <TableContainer className={classes.table} component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">Zone Name</TableCell>
                                            <TableCell align="center">Collection Count</TableCell>
                                            <TableCell align="center">Count</TableCell>
                                            <TableCell align="center">Revenue</TableCell>
                                            <TableCell align="center">ASP</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center">Ghaziabad</TableCell>
                                            <TableCell align="center">9</TableCell>
                                            <TableCell align="center">9</TableCell>
                                            <TableCell align="center">₹6497.00</TableCell>
                                            <TableCell align="center">₹928.14</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="center">Ghaziabad</TableCell>
                                            <TableCell align="center">9</TableCell>
                                            <TableCell align="center">9</TableCell>
                                            <TableCell align="center">₹6497.00</TableCell>
                                            <TableCell align="center">₹928.14</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="center">Ghaziabad</TableCell>
                                            <TableCell align="center">9</TableCell>
                                            <TableCell align="center">9</TableCell>
                                            <TableCell align="center">₹6497.00</TableCell>
                                            <TableCell align="center">₹928.14</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </Grid>
                </Grid>
            </main>
        </>
    );
};


export default SalesLeadHomePage;
