import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { getCallStats, getAgentList } from "../actions/productMangerAction";
import {
  Grid,
  TableFooter,
  TablePagination,
  TextField,
  Container,
} from "@material-ui/core";
import Button from '@mui/material/Button';
import "./index.sass"
import Box from "@mui/material/Box";
import Loader from "../Components/loader";
import moment from "moment";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useHistory } from "react-router-dom";
import { generateLeadsUrl } from "../../helpers/generateUrl";
import Export from "../Components/Export/callStats";
// import CallsStats from "../../CustomerExecutive-Panel/components/CallsStats";
const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 700,
    },
    root: {
      flexGrow: 0,
      //   width: "100%",
      //   marginTop: "20px",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
      //   padding: theme.spacing(3),
    },
  })
);

interface Props {
  getCallStats: any;
  callStats: any;
  getAgentList: any;
  agentsList: any;
  loading: boolean;
}

const LeadsTable: React.FC<Props> = ({
  getCallStats,
  callStats,
  getAgentList,
  agentsList,
  loading,
}) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [filtered, setFiltered] = useState(false);
  const [createdAt, setCreatedAt] = useState("");
  const [startDate, setStartDate] = useState("");
  const [agent, setAgent] = useState<any>([]);

  const timer = useRef<any>(0);

  useEffect(() => {
    getCallStats();
    getAgentList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url = callStats.next;
      getCallStats(url.substring(url.indexOf("?")));
    } else if (newPage < page) {
      let url = callStats.previous;
      getCallStats(
        url.indexOf("?") === -1 ? "" : url.substring(url.indexOf("?"))
      );
    }
    setPage(newPage);
  };

  const history = useHistory();

  const filterLeads = () => {
    const body: any = {
      start_date: startDate,
      created_date: createdAt,
      agent: agent.join(","),
    };
    const url = generateLeadsUrl(body).substring(2);
    getCallStats(`?${url}`);
    setFiltered(true);
    setPage(0);
  };

  {
    /*Eport Booking Start */
  }
  //  const columns= [
  //         { field: 'username', headerName: 'Call Agent', width: 200 },
  //         { field: 'outgoing_total_call', headerName: 'Outgoing Total Call', width: 200 },
  //         { field: 'outgoing_unique_call', headerName: 'Outgoing Unique Call', width: 200 },
  //         { field: 'outgoing_total_talktime', headerName: 'Collection Slot', width: 200 },
  //         { field: 'incoming_total_call', headerName: 'Incoming Total Call', width: 200 },
  //         { field: 'incoming_unique_call', headerName: 'Incoming Unique Call', width: 200 },
  //         { field: 'incoming_total_talktime', headerName: 'Incoming Total TalkTime', width: 200 },
  //         { field: 'total_talktime', headerName: 'Total TalkTime<', width: 200 },
  //     ];
  //          if(callStats.data){
  //           const rows=callStats.data.length > 0 && callStats.data.map()

  //       }
  {
    /*Eport Booking End */
  }

  return (
    <>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <h4 style={{ fontWeight: "bold" }}>CALL STATS</h4>
        <Grid
          container
          spacing={1}
          direction="row"
          alignItems="center"
          justify="center"
        >
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              name="Created At"
              type="date"
              label="Start Date"
              value={startDate}
              variant="outlined"
              onChange={(e) => setStartDate(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              name="Created At"
              type="date"
              label="Recorded Date/End Date"
              value={createdAt}
              variant="outlined"
              onChange={(e) => setCreatedAt(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Autocomplete
              id="agent"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj.map((item: any) => item.id);
                }
                setAgent(sourceIds);
              }}
              options={agentsList.results || []}
              freeSolo
              blurOnSelect
              aria-required
              limitTags={1}
              multiple
              getOptionLabel={(option: any) => option.user && option.user.name}
              getOptionDisabled={(option: any) => {
                return agent.includes(option.id);
              }}
              disableClearable
              disableCloseOnSelect
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getAgentList(`?code=${newInputValue.replace(" ", "")}`);
                }, 1000);
                if (newInputValue.length === 0) {
                  setAgent(0);
                }
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  placeholder="Agent"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={4} md={2}>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              onClick={filterLeads}
              disabled={loading}
            >
              Filter
            </Button>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => history.push("/dashboard/pm/calls/stats")}
            >
              Reset
            </Button>
          </Grid>
        </Grid>
        {/* <div className="data-table" style={{ width: "100%", marginTop: "2rem" }}>
                    <TableContainer component={Paper}>
                        {loading ? <Loader /> : (
                            <Table className={classes.table} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="center">Call Agent</StyledTableCell>
                                        <StyledTableCell align="center">Outgoing Total Call</StyledTableCell>
                                        <StyledTableCell align="center">Outgoing Unique Call</StyledTableCell>
                                        <StyledTableCell align="center">Outgoing Total TalkTime</StyledTableCell>
                                        <StyledTableCell align="center">Incoming Total Call</StyledTableCell>
                                        <StyledTableCell align="center">Incoming Unique Call</StyledTableCell>
                                        <StyledTableCell align="center">Incoming Total TalkTime</StyledTableCell>
                                        <StyledTableCell align="center">Total TalkTime</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {callStats && callStats.data && callStats.data.map((call: any) => {
                                        return (
                                            <StyledTableRow key={call.id}>
                                                <StyledTableCell align="center">
                                                    {call.username}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {call.outgoing_call_missed + call.outgoing_call_picked}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {call.outgoing_unique_call_missed + call.outgoing_unique_call_picked}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {moment.utc(call.outgoing_talktime_picked * 1000).format('HH:mm:ss')}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {call.incomming_call_missed + call.incomming_call_picked}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {call.incomming_unique_call_missed + call.incomming_unique_call_picked}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {moment.utc(call.incomming_talktime_picked * 1000).format('HH:mm:ss')}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {moment.utc((call.incomming_talktime_picked + call.outgoing_talktime_picked) * 1000).format('HH:mm:ss')}
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        )
                                    })}
                                </TableBody>
                                <TableFooter>
                                    <TablePagination
                                         colSpan={3}
                                        count={callStats.count || 0}
                                        rowsPerPageOptions={[]}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        onPageChange={handleChangePage}
                                    />
                                </TableFooter>
                            </Table>
                        )}
                    </TableContainer>
                </div> */}
        <br />
        {loading ? (
          <Loader />
        ) : (
          filtered &&
          callStats.data &&
          callStats.data.length > 0 && (
            <Box>
              <Container maxWidth={false}>
                <Grid container spacing={3}>
                  <Export loading={loading} callStats={callStats} />
                </Grid>
              </Container>
            </Box>
          )
        )}
        {callStats.data && callStats.data.length === 0 && (
          <h6 style={{ textAlign: "center" }}>No Data Found</h6>
        )}
      </main>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  loading: state.ProductManagerReducer.loading,
  callStats: state.ProductManagerReducer.callStats,
  agentsList: state.ProductManagerReducer.agentsList,
});

export default connect(mapStateToProps, { getCallStats, getAgentList })(
  LeadsTable
);
