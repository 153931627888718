export const ACTIONS = {
  CHANGE_PASSWORD: "change_password",
  GET_PIN_CODE: "lp/get_pin_code",
  GET_CITIES: "lp/get_cities",
  GET_AREA: "lp/get_area",
  GET_AGENTS: "lp/get_agents",
  GET_AGENTS_BY_USERGROUP: "lp/get_agents_by_usergroup",
  GET_PACKAGES: "lp/get_packages",
  GET_PHLEBOS: "lp/get_phlebos",
  GET_PARTNERS: "lp/get_partners",
  GET_COUPONS: "lp/get_coupons",
  CREATE_BOOKING: "lp/create_booking",
  UPDATE_BOOKING: "lp/update_booking",
  GET_BOOKING_LIST: "lp/get_booking_list",
  CREATE_ADDITIONAL_BOOKING: "lp/create_additional_booking",
  UPDATE_ADDITIONAL_BOOKING: "lp/update_additional_booking",
  GET_LEAD_DETAILS: "lp/get_lead_details",
  GET_LEAD_BY_ID: "lp/get_lead_by_id",
  LEAD_DETAILS_RESET: "lp/lead_details_reset",
  GET_LEADS: "lp/get_leads",
  GET_BOOKING_BY_ID: "lp/get_booking_by_id",
  CLICK_TO_CALL: "lp/click_to_call",
  GET_POSTIVE_CALLS_COMMENTS: "lp/get_postive_calls_commnets",
  ADD_POSTIVE_CALLS_COMMENT: "lp/add_comments",
  SET_COMMENTS: "lp/set_commnets",
  UPLOAD_FILE: "lp/upload",
  SET_LOADING: "lp/set_loading",
  SET_LOADING_ROUTES: "lp/set_loading_routes",
  SEARCH_CALL: "lp/serach",
  BOOKING_RESET: "lp/booking_reset",
  ADD_COMMENT: "positive_calls/add_comment",
  CREATE_CALL: "lp/createcall",
  GET_LEAD_SOURCE: "lp/getleadsource",
  GET_RECORDINGS: "lp/get_recordings",
  GET_BOOKINGS_COMMENTS: "lp/get_bookings_commnets",
  ADD_BOOKINGS_COMMENT: "lp/add_bookings_comments",
  GET_WHATSAPP_COMMENTS: "lp/get_whatsapp_comments",
  GET_WHATSAPP_COMMENTS_BY_LEAD: "lp/get_whatsapp_comments_by_lead",
  SEND_WHATSAPP_COMMENTS: "lp/send",
  GET_CALL_STATS: "lp/get_call_stats",
  RAISE_COMPLAINT: "lp/raise_complaint",
  GET_COMPLAINTS: "lp/get_complaints",
  ASSIGN_DOCTOR: "lp/assign_doctor",
  SEND_FEEDBACK: "lp/send_feedback",
  GET_AVAILABLE_SLOTS: "lp/get_available_slots",
  GET_ALL_LEADS: "lp/get_all_leads",
  LEAD_TRANSFER_BY_COMMA: "lp/set_comments",
  GET_PANEL_USERS: "lp/get_panel_user",
  RESAMPLE_BOOKING: "lp/resample_booking",
  GET_SYNC_DATA: "lp/get_sync_data",
  GET_TUBE_DETAILS: "lp/get_tub_details",
  GET_BOOKING_RECEIVED_PACKAGE: "lp/get_booking_received_package",
  GET_SMS_REPORT_SEND: "lp/get_sms_report_send",
  GET_UNMASKED_NUMBER: "lp/get_unmasked_number",
  GET_SAMPLE_HANDOVER_DATA: "lp/getsample_handover_data",
  GET_RIDER_LIST: "lp/get_rider_list",
  UPDATE_RIDER_TO_LAB_STATUS: "lp/update_rider_to_lab_status",
  GET_SAMPLE_JOURNEY_COMMENTS: "lp/get_sample_journey_comments",
  GET_PHLENO_BATCH_TUBE: "lp/get_phlebo_batch_tube",
  GET_RIDER_DATA: "lp/get_rider_data",
  GET_RIDER_COUNT: "lp/get_rider_count",
  UPDATERIDER_ADDRESS: "lp/update_rider_address",
  UPDATERIDER_ROUTE_ORDER: "lp/update_rider_route_order",
  DELETE_RIDER_ROUTE: "lp/delet_rider_route",
  GET_RIDER_JOURNEY_COMMENTS: "lp/get_rider_journey_comments",
  GET_RIDER_TRAVEL_RECORD: "lp/get_rider_travel_records",
  GET_RIDER_ROUND: "lp/get_rider_round",
  TRANSFER_REMOVE_RIDER_ROUND: "lp/transfer_remove_rider_round",
  GET_RIDER_ATTENDANCE: "lp/get_rider_attendance",
  GET_BOOKING_BY_BARCODE: "lp/get_booking_by_barcode",
  GET_PAYMENT_INFORMATION: "lp/get_payment_information",
  GET_REPORT_DOWNLOAD: "lp/get_report_download",
  GET_REPORT_STATUS: "lp/get_report_status",
  GET_USER_DETAILS: "lp/get_user_details",
  GET_NOTIFICATION_MESSAGE: "lp/get_notification_message",
  GET_LAB_DETAILS: "lp/get_lab_details",
  GET_PHLEBO_RIDER_DUE_AMOUNT: "lp/get_phlebo_rider_due_amount",
  GET_ADDITIONAL_BOOKING: "lp/get_additional_booking",
  GET_QRCODE_DATA: "lp/get_qrcode_data",
  GET_SIGNATURE: "lp/get_signature",
  GET_STOPS: "lp/get_stops",
  POST_STOPS: "lp/post_stops",
  POST_METER: "lp/post_meter",
  POST_ASSIGNED_ROUTES: "lp/post_ar",
  UPDATE_ASSIGNED_ROUTES: "lp/post_ar",
  UPDATE_STOPS: "lp/post_stops",
  UPDATE_METER: "lp/post_meter",
  GET_ROUTES: "lp/get_routes",
  POST_ROUTES: "lp/post_routes",
  UPDATE_ROUTES: "lp/post_routes",
  GET_TICKET_DATA: "lp/get_ticket_data",
  GET_TICKET_CATEGORIES: "lp/get_ticket_category",
  GET_TICEKT_SUB_CATEGORIES: "lp/get_ticket_categories",
  CREATE_TASK_TICKET: "lp/create_task_ticket",
  GET_RED_TECH_COMMENT: "lp/redtech_comments",
  POST_RED_TECH_COMMENT: "lp/postredtech_comments",
  GET_LOGISTIC_TAT_DATA: "lp/booking/logistic_tat",
  GET_LOGISTIC_STOPS_DATA: "lp/booking/logistic_stops",
  UPDATE_RIDER_SAMPLE_HANDOVER: "lp/update_rider_sample_handover",
  GET_BATCH_BOOKING_AMOUNT: "lp/get_batch_amount",
  GET_BOOKING_OVERVIEW: "lp/booking_overview",
  GET_CENTER_INFORMATION: "lp/get_center_information",
  POST_PHLEBO_RIDER_MAP: "lp/post_phlebo_rider_map",
  UPDATE_PHLEBO_RIDER: "lp/update_phlebo_rider",
  GET_PHLEBO_RIDER: "lp/get_phlebo_rider",
  GET_BOOKING_COMPLAINTS: "lp/get_booking_complaints",
  UPDATE_TICKET_STATUS: "lp/update_ticekt_status",
  UPDATE_DISPOSITION: "lp/update_disposition",
  GET_TICKET_COMMENTS: "lp/ticket_comments",
  SET_ALL_BOOKING_LOADING: "lp/set_all_booking_loading",
  GET_MAMPMYINDIA_ACCESS_TOKEN: "lp/get-mapmyindia-access-token",
  CHECK_GEOFENCE_AREA_MAMPMYINDIA: "lp/checking-geofence-area-mapmyindia",
  SET_GEO_LOADING: "lp/set_geo_loading",
  SET_GEO_CHECK_ERROR: "lp/set_geo_check_error",
  GET_RIDER_ACTIVE_LOGS: "lp/get_rider_active_logs",
  GET_CENTER: "lp/get_center",
  GET_CHAT_SKILLS_LISTS: "lp/get_chat_skills_lists",
  GET_ORG_TYPES: "lp/get_org_types",
  GET_DIALER: "lp/get_dialer",
  GET_OZONTEL_SKILL_DATA: "lp/get_ozontel_skill_data",
  GET_ALL_USERS: "lp/get_all_users",
  GET_LANGUAGE: "lp/get_language",
  GET_PANEL_USER_LIST_DATA_TEAM_LEAD: "lp/get_panel_user_list_data_team_lead",
  CREATE_PANEL_USER: "lp/create_panel_user",
  UPDATED_PANEL_USER: "lp/updated_panel_user",
  GET_SAMPLE_CASH:"lp/get_sample_cash",
  GET_STOP_COUNT:"lp/get_stop_count",
  GET_SAMPLE_COUNT:"lp/get_sample_count",
  GET_ROUTES_LIST_DATA:"lp/get_routes_list_data",
  RESET_GET_ROUTES: "lp/reset_get_routes",
  GET_RIDER_ON_MAP:"lp/get_rider_on_map",
  GET_RIDER_MAP_MY_INDIA:"lp/get_rider_map_my_india",
  CLICK_TO_CALL_RIDER_COUNT: "lp/click_to_call_rider_count",
  GET_DC_CAMP_SAMPLE_HANDOVER: "lp/get_dc_camp_sample_handover",
  GET_BATCH_TUBE_INFO: "lp/get_batch_tube_info",
  GET_LAB_CREDENTIAL: "lp/get_lab_cred",
};

interface GetPinCode {
  type: typeof ACTIONS.GET_PIN_CODE;
  payload: Array<any>;
}
interface GetRidermapMyIndia{
  type: typeof ACTIONS.GET_RIDER_MAP_MY_INDIA;
  payload: Object;
}
interface GetRidermap{
  type: typeof ACTIONS.GET_RIDER_ON_MAP;
  payload: Object;
}
interface GetRouteListData{
  type: typeof ACTIONS.GET_ROUTES_LIST_DATA;
  payload: Object;
}
interface GetSampleCount{
  type: typeof ACTIONS.GET_SAMPLE_COUNT;
  payload: Object;
}
interface GetStopCount{
  type: typeof ACTIONS.GET_STOP_COUNT;
  payload: Object;
}
interface GetSampleCash {
  type: typeof ACTIONS.GET_SAMPLE_CASH;
  payload: Object;
}
interface UpdatedPanelUser {
  type: typeof ACTIONS.UPDATED_PANEL_USER;
  payload: Object;
}
interface CreatePanelUser {
  type: typeof ACTIONS.CREATE_PANEL_USER;
  payload: Object;
}

interface GetPanelUserDataTeamLead {
  type: typeof ACTIONS.GET_PANEL_USER_LIST_DATA_TEAM_LEAD;
  payload: Array<any>;
}
interface GetAllUsers {
  type: typeof ACTIONS.GET_ALL_USERS;
  payload: boolean;
}
interface getOzontelSkillData {
  type: typeof ACTIONS.GET_OZONTEL_SKILL_DATA;
  payload: Object;
}
interface GetDialer {
  type: typeof ACTIONS.GET_DIALER;
  payload: boolean;
}
interface GetOrgTypes {
  type: typeof ACTIONS.GET_ORG_TYPES;
  payload: boolean;
}
interface GetChatSkillsList {
  type: typeof ACTIONS.GET_CHAT_SKILLS_LISTS;
  payload: boolean;
}
interface GetCentre {
  type: typeof ACTIONS.GET_CENTER;
  payload: Array<any>;
}
interface GetActiveRider {
  type: typeof ACTIONS.GET_RIDER_ACTIVE_LOGS;
  payload: Array<any>;
}
interface CheckGeoFenceAreaMapMyIndia {
  type: typeof ACTIONS.CHECK_GEOFENCE_AREA_MAMPMYINDIA;
  payload: Array<any>;
}

interface GetMapMyIndiaAccessToken {
  type: typeof ACTIONS.GET_MAMPMYINDIA_ACCESS_TOKEN;
  payload: Array<any>;
}
interface GetCenterInfo {
  type: typeof ACTIONS.GET_CENTER_INFORMATION;
  payload: Array<any>;
}
interface GetPhleboRider {
  type: typeof ACTIONS.GET_PHLEBO_RIDER;
  payload: Array<any>;
}
interface PostPhleboRiderMapping {
  type: typeof ACTIONS.POST_PHLEBO_RIDER_MAP;
  payload: Array<any>;
}
interface UpdatePhleboRider {
  type: typeof ACTIONS.UPDATE_PHLEBO_RIDER;
  payload: Array<any>;
}
interface GetBatchBookingAmount {
  type: typeof ACTIONS.GET_BATCH_BOOKING_AMOUNT;
  payload: boolean;
}
interface PostAssignedRoutes {
  type: typeof ACTIONS.POST_ASSIGNED_ROUTES;
  payload: Array<any>;
}
interface UpdateAssignedRoutes {
  type: typeof ACTIONS.UPDATE_ASSIGNED_ROUTES;
  payload: Array<any>;
}
interface GetStops {
  type: typeof ACTIONS.GET_STOPS;
  payload: Array<any>;
}
interface PostStops {
  type: typeof ACTIONS.POST_STOPS;
  payload: Array<any>;
}
interface PostMeter {
  type: typeof ACTIONS.POST_METER;
  payload: Array<any>;
}
interface UpdateStops {
  type: typeof ACTIONS.UPDATE_STOPS;
  payload: Array<any>;
}
interface UpdateMeter {
  type: typeof ACTIONS.UPDATE_METER;
  payload: Array<any>;
}
interface GetRoutes {
  type: typeof ACTIONS.GET_ROUTES;
  payload: Array<any>;
}
interface PostRoutes {
  type: typeof ACTIONS.POST_ROUTES;
  payload: Array<any>;
}
interface UpdateRoutes {
  type: typeof ACTIONS.UPDATE_ROUTES;
  payload: Array<any>;
}
interface GetPhleboRiderDueAmount {
  type: typeof ACTIONS.GET_PHLEBO_RIDER_DUE_AMOUNT;
  payload: Array<any>;
}
interface GetLabDetails {
  type: typeof ACTIONS.GET_LAB_DETAILS;
  payload: Array<any>;
}
interface UpdateRiderSampleHandover {
  type: typeof ACTIONS.UPDATE_RIDER_SAMPLE_HANDOVER;
  payload: Array<any>;
}

interface GetReportDownload {
  type: typeof ACTIONS.GET_REPORT_DOWNLOAD;
  payload: Object;
}
interface GetReportStatus {
  type: typeof ACTIONS.GET_REPORT_STATUS;
  payload: Array<any>;
}
interface GetPaymentInformation {
  type: typeof ACTIONS.GET_PAYMENT_INFORMATION;
  payload: Array<any>;
}
interface GetBookingByBarcode {
  type: typeof ACTIONS.GET_BOOKING_BY_BARCODE;
  payload: Array<any>;
}
interface GetRiderAttendance {
  type: typeof ACTIONS.GET_RIDER_ATTENDANCE;
  payload: Array<any>;
}
interface GetRiderRound {
  type: typeof ACTIONS.GET_RIDER_ROUND;
  payload: Array<any>;
}
interface TransferRomoveRider {
  type: typeof ACTIONS.TRANSFER_REMOVE_RIDER_ROUND;
  payload: Array<any>;
}
interface GetRiderTracelRecords {
  type: typeof ACTIONS.GET_RIDER_TRAVEL_RECORD;
  payload: Array<any>;
}
interface GetRiderJourneyComments {
  type: typeof ACTIONS.GET_RIDER_JOURNEY_COMMENTS;
  payload: Array<any>;
}
interface DeleteRiderRoute {
  type: typeof ACTIONS.DELETE_RIDER_ROUTE;
  payload: Array<any>;
}
interface UpdateRiderUpdateRoute {
  type: typeof ACTIONS.UPDATERIDER_ROUTE_ORDER;
  payload: Array<any>;
}
interface UpdatedRiderAddress {
  type: typeof ACTIONS.UPDATERIDER_ADDRESS;
  payload: Array<any>;
}
interface GetRiderData {
  type: typeof ACTIONS.GET_RIDER_DATA;
  payload: Array<any>;
}
interface GetRiderCount {
  type: typeof ACTIONS.GET_RIDER_COUNT;
  payload: Array<any>;
}
interface GetUnmaskedNumber {
  type: typeof ACTIONS.GET_UNMASKED_NUMBER;
  payload: Array<any>;
}
interface GetPhleboBatchTube {
  type: typeof ACTIONS.GET_PHLENO_BATCH_TUBE;
  payload: Array<any>;
}
interface ChangePassword {
  type: typeof ACTIONS.CHANGE_PASSWORD;
  payload: Object;
}

interface UploadFile {
  type: typeof ACTIONS.UPLOAD_FILE;
  payload: Object;
}

interface GetCities {
  type: typeof ACTIONS.GET_CITIES;
  payload: Array<any>;
}

interface GetArea {
  type: typeof ACTIONS.GET_AREA;
  payload: Array<any>;
}

interface GetAgents {
  type: typeof ACTIONS.GET_AGENTS;
  payload: Array<any>;
}

interface GetPhlebo {
  type: typeof ACTIONS.GET_PHLEBOS;
  payload: Array<any>;
}

interface SetComments {
  type: typeof ACTIONS.SET_COMMENTS;
  payload: Object;
}

interface GetPositiveCallsComments {
  type: typeof ACTIONS.GET_POSTIVE_CALLS_COMMENTS;
  payload: Object;
}

interface AddPositiveCallsComments {
  type: typeof ACTIONS.ADD_POSTIVE_CALLS_COMMENT;
  payload: Object;
}

interface ResampleBooking {
  type: typeof ACTIONS.RESAMPLE_BOOKING;
  payload: Object;
}

interface ClickToCall {
  type: typeof ACTIONS.CLICK_TO_CALL;
  payload: Array<any>;
}

interface GetPackages {
  type: typeof ACTIONS.GET_PACKAGES;
  payload: Array<any>;
}

interface GetPartners {
  type: typeof ACTIONS.GET_PARTNERS;
  payload: Array<any>;
}

interface GetCoupons {
  type: typeof ACTIONS.GET_COUPONS;
  payload: Array<any>;
}

interface SetLoading {
  type: typeof ACTIONS.SET_LOADING;
  payload: boolean;
}
interface SetLoadingRoutes {
  type: typeof ACTIONS.SET_LOADING_ROUTES;
  payload: boolean;
}
interface GetBookingOverview {
  type: typeof ACTIONS.GET_BOOKING_OVERVIEW;
  payload: Array<any>;
}

interface CreateBooking {
  type: typeof ACTIONS.CREATE_BOOKING;
  payload: boolean;
}

interface UpdateBooking {
  type: typeof ACTIONS.UPDATE_BOOKING;
  payload: boolean;
}

interface GetBookings {
  type: typeof ACTIONS.GET_BOOKING_LIST;
  payload: boolean;
}

interface GetBookingById {
  type: typeof ACTIONS.GET_BOOKING_BY_ID;
  payload: boolean;
}

interface GetLeads {
  type: typeof ACTIONS.GET_LEADS;
  payload: boolean;
}

interface GetLeadDetails {
  type: typeof ACTIONS.GET_LEAD_DETAILS;
  payload: boolean;
}

interface GetLeadById {
  type: typeof ACTIONS.GET_LEAD_BY_ID;
  payload: boolean;
}

interface ResetLeadDetails {
  type: typeof ACTIONS.LEAD_DETAILS_RESET;
  payload: boolean;
}

interface GetPinCode {
  type: typeof ACTIONS.GET_PIN_CODE;
  payload: Array<any>;
}
interface ChangePassword {
  type: typeof ACTIONS.CHANGE_PASSWORD;
  payload: Object;
}
interface GetCities {
  type: typeof ACTIONS.GET_CITIES;
  payload: Array<any>;
}

interface GetArea {
  type: typeof ACTIONS.GET_AREA;
  payload: Array<any>;
}
interface GetPhlebo {
  type: typeof ACTIONS.GET_PHLEBOS;
  payload: Array<any>;
}

interface GetAgents {
  type: typeof ACTIONS.GET_AGENTS;
  payload: Array<any>;
}

interface SetComments {
  type: typeof ACTIONS.SET_COMMENTS;
  payload: Object;
}

interface GetPositiveCallsComments {
  type: typeof ACTIONS.GET_POSTIVE_CALLS_COMMENTS;
  payload: Object;
}

interface AddPositiveCallsComments {
  type: typeof ACTIONS.ADD_POSTIVE_CALLS_COMMENT;
  payload: Object;
}
interface ClickToCall {
  type: typeof ACTIONS.CLICK_TO_CALL;
  payload: Array<any>;
}

interface GetPackages {
  type: typeof ACTIONS.GET_PACKAGES;
  payload: Array<any>;
}

interface GetPartners {
  type: typeof ACTIONS.GET_PARTNERS;
  payload: Array<any>;
}

interface GetCoupons {
  type: typeof ACTIONS.GET_COUPONS;
  payload: Array<any>;
}

interface SetLoading {
  type: typeof ACTIONS.SET_LOADING;
  payload: boolean;
}

interface CreateBooking {
  type: typeof ACTIONS.CREATE_BOOKING;
  payload: boolean;
}

interface UpdateBooking {
  type: typeof ACTIONS.UPDATE_BOOKING;
  payload: boolean;
}

interface GetBookings {
  type: typeof ACTIONS.GET_BOOKING_LIST;
  payload: boolean;
}

interface GetBookingById {
  type: typeof ACTIONS.GET_BOOKING_BY_ID;
  payload: boolean;
}

interface GetLeads {
  type: typeof ACTIONS.GET_LEADS;
  payload: boolean;
}

interface GetLeadDetails {
  type: typeof ACTIONS.GET_LEAD_DETAILS;
  payload: boolean;
}

interface GetLeadById {
  type: typeof ACTIONS.GET_LEAD_BY_ID;
  payload: boolean;
}

interface ResetLeadDetails {
  type: typeof ACTIONS.LEAD_DETAILS_RESET;
  payload: boolean;
}
interface SearchCall {
  type: typeof ACTIONS.SEARCH_CALL;
  payload: Object;
}
interface SearchCall {
  type: typeof ACTIONS.SEARCH_CALL;
  payload: Object;
}
interface GetAgentByUserGroup {
  type: typeof ACTIONS.GET_AGENTS_BY_USERGROUP;
  payload: Object;
}

interface CreateAdditionalBooking {
  type: typeof ACTIONS.CREATE_ADDITIONAL_BOOKING;
  payload: Object;
}
interface UpdateAdditionalBooking {
  type: typeof ACTIONS.UPDATE_ADDITIONAL_BOOKING;
  payload: Object;
}
interface GetSyncData {
  type: typeof ACTIONS.GET_SYNC_DATA;
  payload: Object;
}
interface GetTubeDetails {
  type: typeof ACTIONS.GET_TUBE_DETAILS;
  payload: Array<any>;
}
interface GetBookingReceivedPackage {
  type: typeof ACTIONS.GET_BOOKING_RECEIVED_PACKAGE;
  payload: Array<any>;
}
interface GetSmsReportSent {
  type: typeof ACTIONS.GET_SMS_REPORT_SEND;
  payload: Object;
}
interface GetSampleHandOver {
  type: typeof ACTIONS.GET_SAMPLE_HANDOVER_DATA;
  payload: Array<any>;
}
interface GetRiderList {
  type: typeof ACTIONS.GET_RIDER_LIST;
  payload: Array<any>;
}
interface UpdateRiderToLabStatus {
  type: typeof ACTIONS.UPDATE_RIDER_TO_LAB_STATUS;
  payload: Array<any>;
}
interface GetSampleJourneyComments {
  type: typeof ACTIONS.GET_SAMPLE_JOURNEY_COMMENTS;
  payload: Object;
}
interface GetUserDetails {
  type: typeof ACTIONS.GET_USER_DETAILS;
  payload: Array<any>;
}
interface GetNotificationMessage {
  type: typeof ACTIONS.GET_NOTIFICATION_MESSAGE;
  payload: Array<any>;
}
interface GetAdditionalBooking {
  type: typeof ACTIONS.GET_ADDITIONAL_BOOKING;
  payload: Array<any>;
}

interface GetQRCodeData {
  type: typeof ACTIONS.GET_QRCODE_DATA;
  payload: Array<any>;
}
interface GetSignature {
  type: typeof ACTIONS.GET_SIGNATURE;
  payload: Array<any>;
}
interface GetRedTechComment {
  type: typeof ACTIONS.GET_RED_TECH_COMMENT;
  payload: Array<any>;
}
interface GetTicketData {
  type: typeof ACTIONS.GET_TICKET_DATA;
  payload: Object;
}
interface GetTicketCategory {
  type: typeof ACTIONS.GET_TICKET_CATEGORIES;
  payload: Object;
}
interface GetTicketSubCategories {
  type: typeof ACTIONS.GET_TICEKT_SUB_CATEGORIES;
  payload: Object;
}
interface PostRedTechComment {
  type: typeof ACTIONS.POST_RED_TECH_COMMENT;
  payload: Object;
}
interface CreateTaskTicket {
  type: typeof ACTIONS.CREATE_TASK_TICKET;
  payload: Array<any>;
}
interface GetLogisticTatData {
  type: typeof ACTIONS.GET_LOGISTIC_TAT_DATA;
  payload: Array<any>;
}
interface GetLogisticStopsData {
  type: typeof ACTIONS.GET_LOGISTIC_STOPS_DATA;
  payload: Array<any>;
}
interface GetBookingQueryTickets {
  type: typeof ACTIONS.GET_BOOKING_COMPLAINTS;
  payload: boolean;
}
interface UpdateTicketStatus {
  type: typeof ACTIONS.UPDATE_TICKET_STATUS;
  payload: Array<any>;
}
interface UpdateActionDisposition {
  type: typeof ACTIONS.UPDATE_DISPOSITION;
  payload: Array<any>;
}
interface GetTicketComments {
  type: typeof ACTIONS.GET_TICKET_COMMENTS;
  payload: Array<any>;
}
interface GetBookingsLoader {
  type: typeof ACTIONS.SET_ALL_BOOKING_LOADING;
  payload: boolean;
}
interface GeoLoading {
  type: typeof ACTIONS.SET_GEO_LOADING;
  payload: boolean;
}
interface CheckGeoCheckError {
  type: typeof ACTIONS.SET_GEO_CHECK_ERROR;
  payload: boolean;
}
interface GetLanguage {
  type: typeof ACTIONS.GET_LANGUAGE;
  payload: boolean;
}

interface ResetGetRoutes {
  type: typeof ACTIONS.RESET_GET_ROUTES;
  payload: boolean;
}
interface ClickToCallRiderCount {
  type: typeof ACTIONS.CLICK_TO_CALL_RIDER_COUNT;
  payload: Array<any>;
}
interface GetDcCampSampleHandover {
  type: typeof ACTIONS.GET_DC_CAMP_SAMPLE_HANDOVER;
  payload: Array<any>;
}
interface GetBatchTubeInfo {
  type: typeof ACTIONS.GET_BATCH_TUBE_INFO;
  payload: Array<any>;
}
interface GetRiderCount {
  type: typeof ACTIONS.GET_RIDER_COUNT;
  payload: Array<any>;
}
interface GetLabCredential {
  type: typeof ACTIONS.GET_LAB_CREDENTIAL;
  payload: Array<any>;
}


export type PhleboAdminTypes =
  | SearchCall
  | GetPinCode
  | ChangePassword
  | GetCities
  | GetArea
  | GetAgents
  | GetPhlebo
  | GetAgentByUserGroup
  | CreateAdditionalBooking
  | UpdateAdditionalBooking
  | GetPackages
  | CreateBooking
  | UpdateBooking
  | GetPartners
  | GetCoupons
  | GetBookings
  | GetBookingById
  | SetLoading
  | GetLeadDetails
  | GetLeads
  | GetLeadById
  | ResetLeadDetails
  | ClickToCall
  | GetPositiveCallsComments
  | AddPositiveCallsComments
  | SetComments
  | GetStops
  | PostStops
  | PostMeter
  | UpdateStops
  | UpdateMeter
  | GetRoutes
  | PostRoutes
  | UpdateRoutes
  | UploadFile
  | GetPinCode
  | ChangePassword
  | GetCities
  | GetArea
  | GetAgents
  | GetPhlebo
  | GetPackages
  | GetPartners
  | GetCoupons
  | GetBookings
  | GetBookingById
  | SetLoading
  | GetLeadDetails
  | GetLeads
  | GetLeadById
  | ResetLeadDetails
  | ClickToCall
  | GetPositiveCallsComments
  | AddPositiveCallsComments
  | SetComments
  | SearchCall
  | ResampleBooking
  | GetSyncData
  | GetTubeDetails
  | GetBookingReceivedPackage
  | GetSmsReportSent
  | GetUnmaskedNumber
  | GetSampleHandOver
  | GetRiderList
  | UpdateRiderToLabStatus
  | GetSampleJourneyComments
  | GetPhleboBatchTube
  | GetRiderData
  | UpdatedRiderAddress
  | UpdateRiderUpdateRoute
  | DeleteRiderRoute
  | GetRiderJourneyComments
  | GetRiderTracelRecords
  | GetRiderRound
  | TransferRomoveRider
  | GetRiderAttendance
  | GetBookingByBarcode
  | GetPaymentInformation
  | GetReportDownload
  | GetReportStatus
  | GetUserDetails
  | UpdateAssignedRoutes
  | GetNotificationMessage
  | GetLabDetails
  | GetPhleboRiderDueAmount
  | GetAdditionalBooking
  | GetQRCodeData
  | GetSignature
  | GetTicketData
  | GetTicketCategory
  | GetTicketSubCategories
  | CreateTaskTicket
  | GetRedTechComment
  | PostRedTechComment
  | PostAssignedRoutes
  | GetLogisticTatData
  | GetLogisticStopsData
  | UpdateRiderSampleHandover
  | GetBatchBookingAmount
  | GetBookingOverview
  | GetCenterInfo
  | PostPhleboRiderMapping
  | UpdatePhleboRider
  | GetPhleboRider
  | GetBookingQueryTickets
  | UpdateTicketStatus
  | UpdateActionDisposition
  | GetTicketComments
  | GetBookingsLoader
  | GetMapMyIndiaAccessToken
  | CheckGeoFenceAreaMapMyIndia
  | GeoLoading
  | CheckGeoCheckError
  | SetLoadingRoutes
  | GetActiveRider
  | GetCentre
  | GetChatSkillsList
  | GetOrgTypes
  | GetDialer
  | getOzontelSkillData
  | GetAllUsers
  | GetLanguage
  | GetPanelUserDataTeamLead
  | CreatePanelUser
  | UpdatedPanelUser
  | GetSampleCash
  | GetStopCount
  | GetSampleCount
  | GetRouteListData
  | ResetGetRoutes
  | GetRidermap
  | GetRidermapMyIndia
  | GetRiderCount
  | GetDcCampSampleHandover
  | GetBatchTubeInfo
  | GetRiderCount
  | ClickToCallRiderCount
  | GetLabCredential;