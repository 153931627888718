import React, { useState, useEffect, useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Card,
  Grid,
  MenuItem,
  Paper,
  Select,
  Checkbox,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import DialogContentText from '@mui/material/DialogContentText';
import {
  getPackage,
  getNewPackage,
  updateBooking,
  getBookingById,
  getCoupons,
  getAvailableSlots,
  getMapMyIndiaAccessToken,
  // checkGeoFenceArea,
  checkGeoFenchMapMyIndia,
  getTimeslots,
  SendLocationRequest,
  getNewLeadDetails,
  getPhlebos,
  getLeadSubscriptionsData,
  getRedCoing,
} from "../../../actions/PhleboAdmin";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Button from "@mui/material/Button";
import "./index.sass";
import { connect } from "react-redux";
import moment from "moment";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CloseIcon from "@material-ui/icons/Close";
import { useHistory } from "react-router";
import { Chip } from "@material-ui/core";
import Loader from "../../Loader2";
import { GENDER_CHOICE } from "../../constant";
import { setTimeout } from "timers";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { getContainerSlots } from "../../../../actions/loginActions";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
    },
    contentHeader: {
      width: "100%",
      padding: "0px 10px 10px 10px",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      padding: "20px 20px 20px 20px",
      marginTop: "20px",
      width: "100%",
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    head: {
      textAlign: "center",
      fontWeight: "normal",
      marginBottom: "2rem",
    },
    slotContainer: {
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "center",
    },
    slot: {
      width: "150px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      margin: "0.5rem",
    },
    selectedSlot: {
      width: "150px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      background: "linear-gradient(60deg, cyan, #00dfff, cyan)",
      cursor: "pointer",
      margin: "0.5rem",
    },
    chips: {
      display: "flex",
    },
    chip: {
      margin: 2,
      background: "linear-gradient(60deg, cyan, #00cfff, cyan)",
      maxWidth: "130px",
    },
    dialog: {
      zIndex: 2,
    },
  })
);

interface Props {
  getPackage: any;
  packageList: any;
  couponList: any;
  getCoupons: any;
  updateBooking: any;
  match: any;
  booking: any;
  getBookingById: any;
  booking_slots: any;
  getAvailableSlots: any;
  loading: boolean;
  checkGeoFenceArea: any;
  checkGeoFenceStatus: boolean;
  checkGeoFenchMapMyIndia: any;
  geoFencesMapMyIndia: any;
  getMapMyIndiaAccessToken: any;
  mapmyindiaAccessToken: any;
  getTimeslots: any;
  timeslots: any;
  userDetails: any;
  getNewPackage: any;
  SendLocationRequest: any;
  getNewLeadDetails: any;
  newLeadDetails: any;
  getPhlebos: any;
  phleboList: any;
  getLeadSubscriptionsData: any;
  leadSubscriptionsData: any;
  getRedCoing: any;
  redCoin: any;
  getContainerSlots: any;
  containerSlots: any;
  bookingListLoader: any;
}

const EditBookingForm: React.FC<Props> = ({
  packageList,
  getPackage,
  couponList,
  getCoupons,
  updateBooking,
  match,
  booking,
  getBookingById,
  booking_slots,
  getAvailableSlots,
  loading,
  // checkGeoFenceArea,
  // checkGeoFenceStatus,
  checkGeoFenchMapMyIndia,
  geoFencesMapMyIndia,
  getMapMyIndiaAccessToken,
  mapmyindiaAccessToken,
  getTimeslots,
  timeslots,
  userDetails,
  getNewPackage,
  SendLocationRequest,
  getNewLeadDetails,
  newLeadDetails,
  getPhlebos,
  phleboList,
  getLeadSubscriptionsData,
  leadSubscriptionsData,
  getRedCoing,
  redCoin,
  getContainerSlots,
  containerSlots,
  bookingListLoader,
}) => {
  const classes = useStyles();

  const history = useHistory();
  const [cType, setCType] = useState<any>("");
  const [cValue, setCValue] = useState<any>("");
  const [couponPercentage, setCouponPercentage] = useState<any>(0);
  const [referralCouponDiscount, setReferralCouponDiscount] = useState<any>(0)
  const [collection_date, setCollection_date] = useState<String>(
    booking.collection_date
  );
  const [booking_date, setBooking_date] = useState<string>(
    booking.booking_date
  );
  const [timeSlots, setTimeSlots] = useState<Array<{"available_slots": number}>>([]);
  const [selectedSlot, setSelectedSlot] = useState<Number>(
    booking.collection_slot && booking.collection_slot.id
  );
  const [customer_name, setCustomer_name] = useState<String>(
    booking.customer_name
  );
  const [customer_designation, setCustomer_designation] = useState<string>();
  const [designation, setDesignation] = useState<String>();
  const [customer_age, setCustomer_age] = useState<String>(
    booking.customer_age
  );
  const [customer_gender, setCustomer_gender] = useState<string>();
  const [customer_phonenumber, setCustomer_phonenumber] = useState<String>(
    booking.customer_phonenumber
  );
  const [customer_alternatenumber, setCustomer_alternatenumber] = useState<String>(booking.customer_altphonenumber);
  const [customer_whatsapppnumber, setCustomer_whatsapppnumber] = useState<String>(booking.customer_whatsapppnumber);
  const [customer_email, setCustomer_email] = useState<String>(
    booking.customer_email
  );
  const [customer_landmark, setCustomer_landmark] = useState<String>(
    booking?.customer_landmark
  );
  const [landmark, setLandmark] = useState<any>(null);
  const [customer_longitude, setCustomerLongitude] = useState<any>("");
  const [customer_latitude, setCustomerLatitude] = useState<any>("");
  const [customer_areapincode, setCustomer_areapincode] = useState<Number>(0);
  const [customer_areaname, setCustomer_areaname] = useState<String>(
    booking.customer_areapincode && booking.customer_areapincode.area
  );
  const [customer_address, setCustomer_address] = useState<String>(
    booking.customer_address && booking.customer_address
  );
  const [addressLine2, setAddressLine2] = useState<string>(
    booking?.address_line2 && booking?.address_line2
  );
  const [customer_areapincodeDef, setCustomer_areapincodeDef] =
    useState<Number>(booking.customer_areapincode);
  const [favoritePlace, setFavoritePlace] = useState<any>(
    booking?.landmark && booking?.landmark
  );
  const [customer_city, setCustomer_city] = useState<String>("");
  const [customer_aadhar, setCustomer_aadhar] = useState<String>(
    booking.customer_aadhar
  );
  const [rm_comment, setRMComment] = useState<any>();
  const [adharPassport, setAdharPassport] = useState(
    booking.customer_aadhar !== "" ? "customer_aadhar" : "passport_number"
  );
  const [partner, setPartner] = useState<String>(
    booking.partner && booking.partner.id
  );
  const [packageDetails, setPackageDetails] = useState<any>(booking.packages);
  const [packageDetailsOld, setPackageDetailsOld] = useState<any>(booking.packages);
  const [packageDetailsAdditionalMembers, setPackageDetailsAdditionalMembers] = useState<any>(booking?.additional_members?.filter(
    (mem: any) => (mem?.pickup_status !== "cancelled" && mem?.booking_status !== "cancelled")
  ).map((p: any) => {
    return p.packages;
  }));
  const [packageDetailsAdditionalMembersNewArray, setPackageDetailsAdditionalMembersNewArray] = useState<any>([]);
  const [packageKey, setPackageKey] = useState<any>(false);
  const [packages, setPackages] = useState<Number[]>(booking.packages && booking.packages.map((pack: any) => {
      return pack.id;
    })
  );
  const [packagesCode, setPackagesCode] = useState<any>(booking.packages && booking.packages.map((pack: any) => {
      return pack?.code;
    })
  );
  const [phleboInitial, setPhleboInitial] = useState<Number>(
    booking.phlebo && booking.phlebo.id
  );
  const [coupon, setCoupon] = useState<Number>(0);
  const [couponCode, setCouponCode] = useState<string>("");
  const [couponApplyFlag, setCouponApplyFlag] = useState<boolean>(false);
  const [price, setPrice] = useState<any>(
    booking?.discounted_price?.total_price_package
  );
  const [priceInitial, setPriceInitial] = useState<any>(
    booking?.discounted_price?.total_price_package
  );
  const [discount, setDiscount] = useState<any>(
    booking.discounted_price && booking.discounted_price.counpon_discount
  );
  const [totalPrice, setTotalPrice] = useState<any>();
  const [extraCharge, setExtraCharge] = useState<boolean>(
    booking.discounted_price && booking.discounted_price.phlebo_cost !== 0
  );
  const [cityId, setCityId] = useState<any>(booking && booking.cityid);
  const [findSelectedSlot, setFindSelectedSlot] = useState<any>();
  const [phleboCost, setPhleboCost] = useState<any>(0);
  const [phleboCostOld, setPhleboCostOld] = useState<any>(0);
  const [minAmountPhleboCost, setMinAmountPhleboCost] = useState<any>(0);
  const [packKey, setPackKey] = useState<any>(0);
  const [zoneId, setZoneId] = useState<any>("");
  const [couponKey, setCouponKey] = useState<any>(0);
  const [is_pincode, setIsPincode] = useState<boolean>(true);
  const [disableBtn, setDisableBtn] = useState<boolean>(false);
  const [passport, setPassport] = useState<any>(
    booking?.passport_number === null ? "" : booking?.passport_number
  );
  const [aadhar, setAadhar] = useState<any>(booking?.customer_aadhar);
  const [bookingFor, setBookingFor] = useState<any>(booking?.book_for);
  const [isPrescribed, setIsPrescribed] = useState<any>(
    booking?.is_prescription
  );
  const [phleboRemarks, setPhleboRemarks] = useState<any>(
    booking?.phlebo_remarks
  );
  const [mobileValidation, setMobileValidation] = useState({
    customer_alternatenumber: true,
    customer_phonenumber: true,
    customer_whatsapppnumber: true,
  });
  const [pickup_time, setPickup_time] = useState(booking?.pickup_time);
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false)
  const [apiHitCount, setApiHitCount] = useState<number>(0)
  const [redcaseCheck, setRedCashCheck] = useState<any>(booking?.redcash_discounted_amount)
  const [referralPointsDiscount, setReferralPointsDiscount] = useState<any>(0);
  const [referralPointsApplied, setReferralPointsApplied] = useState<any>(false);
  const [redProMembershipDiscountPercentage, setRedProMembershipDiscountPercentage] = useState<any>(0);
  const [redProMembershipDiscount, setRedProMembershipDiscount] = useState<any>(0);
  const [alreadyARedPro, setAlreadyARedPro] = useState<any>(false);
  const [refresh, setRefresh] = useState<any>(false);
  const [verification_remark, setVerification_remark] = useState<string>(
    booking?.verification_remark
  );
  const [booking_status, setBooking_status] = useState<string>(
    booking?.booking_status
  );
  const [phleboName, setPhleboName] = useState<any>("");
  const [pickup_amount, setPickup_amount] = useState(0);
  const [remarks, setRemarks] = useState<string>(booking?.remarks);
  const [phlebo_incentive, setPhlebo_incentive] = useState(
    booking?.phlebo_incentive
  );
  const [pickup_status, setPickup_status] = useState(booking?.pickup_status);
  const [verification_status, setVerification_status] = useState<string>(
    booking?.verification_status
  );
  const [phlebo_convenience, setPhlebo_convenience] = useState(
    booking?.phlebo_convenience
  );
  const [payment_mode, setPaymentMode] = useState<String>(
    booking?.payment_mode
  );
  const [pickup_date, setPickup_date] = useState(booking?.pickup_date);
  const [phlebo_verification_remark, setPhlebo_verification_remark] =
    useState<string>(booking?.phlebo_verification_remark);
  const [rca_comment, setRCAComment] = useState<any>();
  const [phlebo, setPhlebo] = useState<Number>(
    booking?.phlebo && booking?.phlebo?.id
  );
  const [openLoyaltyModal, setOpenLoyaltyModal] = useState<boolean>(false);
  const [checkedWay, setCheckedWay] = useState<any>("");
  const [proMembershipPrice, setProMembershipPrice] = useState<any>(0);
  const [requestedForHardCopy, setRequestedForhHardCopy] = useState<any>(false);
  const [removePhleboCharge, setRemovePhleboCharges] = useState<any>(false);
  const [bookingType, setBookingType] = useState<any>(booking?.booking_type)
  const [hardCopyPrice, setHardCopyPrice] = useState<any>(0);
  const [redCoinMessage, setRedCoinMessage] = useState<any>("");
  const [redCashButton, setRedCashButton] = useState<boolean>(false);
  const [totalRedCoin, setTotalRedCoin] = useState<any>("");
  const [redDisc, setRedDisc] = useState<any>(0);
  const [redCashDiscountPercentage, setRedCashDiscountPercentage] = useState<any>(0);
  const [containerDate, setContainerDate] = useState<String>("");
  const [containerSlot, setContainerSlot] = useState<String>("");
  const [containerObject, setContainerObject] = useState<any>({
    start_time: "",
    end_time: ""
  });
  const [checkedContainerDeliverySlot, setCheckedContainerDeliverySlot] = useState<any>("");
  const [additionalMembersStatus, setAdditionalMembersStatus] = useState<any>();
  const [deleteUrineData, setDeleteUrineData] = useState<boolean>(booking?.delivery_date !== null ? false : true);
  const [dateValidation, setDateValidation] = useState<any>(0);
  const [overallDiscountLimit, setOverallDiscountLimit] = useState<any>(userDetails?.profile?.overall_discount_limit);
  const bookingId = match.params.id;

  const timer = useRef<any>(0);

  useEffect(() => {
    getBookingById(`${bookingId}/`);
  }, [bookingId]);

  useEffect(() => {
    setOverallDiscountLimit(userDetails?.profile?.overall_discount_limit);
  }, [userDetails])

  const SendLocationMessage = async () => {
    let body = {
      lead_id: booking?.lead,
      address_id: booking?.customer_booking_address,
      // customer_whatsapp_phonenumber: customer_whatsapppnumber, No need
      attempt: "first",
    }
    if (booking?.verified_location?.customer_verified_latitude === null || booking?.verified_location?.customer_verified_longitude === null ||
      booking?.verified_location?.customer_verified_latitude === "" || booking?.verified_location?.customer_verified_longitude === "" ||
      booking?.verified_location?.customer_verified_latitude === 0 || booking?.verified_location?.customer_verified_longitude === 0 ||
      booking?.verified_location?.customer_verified_latitude === undefined || booking?.verified_location?.customer_verified_longitude === undefined
    ) {
      await SendLocationRequest(body);
    } else {
      alert("We have already customer verified address.")
    }
  }

  useEffect(() => {
    if (booking?.pk > 0 && collection_date !== booking?.collection_date) {
      setCollection_date(booking?.collection_date);
      setBooking_date(booking?.booking_date);
      setCustomerLatitude(booking?.customer_latitude);
      setCustomerLongitude(booking?.customer_longitude);
      setPackageKey(!packageKey)
      setBookingType(booking?.booking_type)
      setSelectedSlot(booking.collection_slot && booking.collection_slot.id);
      setCustomer_name(booking?.customer_name);
      setCustomer_age(booking.customer_age);
      setRemovePhleboCharges(booking?.remove_phlebo_charges)
      setCustomer_phonenumber(booking.customer_phonenumber);
      setCustomer_alternatenumber(booking.customer_altphonenumber);
      setCustomer_whatsapppnumber(booking.customer_whatsapppnumber);
      setCustomer_email(booking.customer_email);
      setCustomer_landmark(booking?.customer_landmark);
      setCustomer_areaname(
        booking.customer_areapincode && booking.customer_areapincode.area
      );
      setRequestedForhHardCopy(booking?.requested_for_hard_copy)
      setPhleboInitial(booking?.phlebo && booking?.phlebo?.id);
      // setCValue(booking?.discounted_price?.counpon_discount);
      if (booking?.coupon_applied?.is_red_cash === true) {
        setCouponPercentage(0);
      } else {
        setCouponPercentage(booking?.coupon_applied?.discount);
      }
      setRemovePhleboCharges(booking?.remove_phlebo_charges)
      setPhlebo_convenience(booking?.phlebo_convenience)
      setBooking_status(booking?.booking_status)
      setCustomer_address(booking.customer_address && booking.customer_address);
      setAddressLine2(booking?.address_line2 && booking?.address_line2);
      setCustomer_areapincodeDef(booking.customer_areapincode);
      setFavoritePlace(booking?.landmark && booking?.landmark);
      setRedCashCheck(booking?.redcash_discounted_amount);
      setRedDisc(booking?.redcash_discounted_amount);
      setCustomer_aadhar(booking.customer_aadhar);
      setRedCashButton(booking?.redcash_discounted_amount === 0 ? false : true);
      setAdharPassport(
        booking.customer_aadhar !== "" ? "customer_aadhar" : "passport_number"
      );
      setPaymentMode(booking?.payment_mode)
      setPhlebo_verification_remark(booking?.phlebo_verification_remark)
      setPickup_status(booking?.pickup_status)
      setVerification_remark(booking?.verification_remark)
      setVerification_status(booking?.verification_status)
      setCType(booking?.discounted_price?.coupon_type)
      setPartner(booking.partner && booking.partner.id);
      setPackageDetails(booking.packages || []);
      setPackageDetailsOld(booking.packages);
      setPhlebo_incentive(booking?.phlebo_incentive)
      setPackages(booking.packages && booking.packages.map((pack: any) => {
          return pack.id;
        })
      );
      setPackagesCode(booking.packages && booking.packages.map((pack: any) => {
          return pack?.code;
        })
      );
      setCustomer_areapincode(booking?.customer_areapincode?.id);
      setCustomer_city((booking?.customer_areapincode?.city?.city as String));
      setZoneId(booking?.customer_zone);
      setPackageDetailsAdditionalMembers(booking?.additional_members?.filter(
        (mem: any) => (mem?.pickup_status !== "cancelled" && mem?.booking_status !== "cancelled")
      ).map((p: any) => {
        return p.packages;
      }))
      setPrice(
        booking?.discounted_price?.total_price_package
      );
      setPriceInitial(booking?.discounted_price?.total_price_package);
      setDiscount(
        booking.discounted_price && booking.discounted_price.counpon_discount
      );
      setExtraCharge(
        booking.discounted_price && booking.discounted_price.phlebo_cost !== 0
      );
      setCityId(booking && booking.cityid);
      setPassport(
        booking?.passport_number === null ? "" : booking?.passport_number
      );
      setAadhar(booking?.customer_aadhar);
      setBookingFor(booking?.book_for);
      setIsPrescribed(booking?.is_prescription);
      setPhleboRemarks(booking?.phlebo_remarks);
      setSelectedSlot(booking?.collection_slot && booking?.collection_slot?.id);
      setAdditionalMembersStatus(booking?.additional_members?.filter(
        (mem: any) => (mem?.pickup_status === "cancelled" || mem?.booking_status === "cancelled")
      ));
      setContainerDate(booking?.delivery_date);
      if (booking?.delivery_date !== null) {
        setContainerSlot(booking?.delivery_collection_slot[0]?.id);
        setContainerObject({
          id: booking?.delivery_collection_slot[0]?.id,
          start_time: booking?.delivery_collection_slot[0]?.slot?.slice(0,8),
          end_time: booking?.delivery_collection_slot[0]?.slot?.slice(9,17)
        });
      }
      setCheckedContainerDeliverySlot(booking?.delivery_date !== null ? "1" : "");
      let date1 = moment(`${booking?.booking_date}`);
      let date2 = moment().format("YYYY-MM-DD");
      setDateValidation(-1 * (date1.diff(date2, 'days')));
      setDeleteUrineData(booking?.delivery_date !== null ? false : true);
      getNewLeadDetails(`${booking?.lead}/?booking_id=${bookingId}`, "true");
    }
  }, [booking]);

  useEffect(() => {
    if (requestedForHardCopy === "true" || requestedForHardCopy === true) {
      setHardCopyPrice(150)
    }
    else {
      setHardCopyPrice(0)
    }
  }, [requestedForHardCopy])

  useEffect(() => {
    if (packageDetailsAdditionalMembers?.length === 1) {
      let list;
      list = packageDetailsAdditionalMembers[0];
      setPackageDetailsAdditionalMembersNewArray(list);
    } else if (packageDetailsAdditionalMembers?.length === 2) {
      let list;
      list = packageDetailsAdditionalMembers[0]?.concat(packageDetailsAdditionalMembers[1]);
      setPackageDetailsAdditionalMembersNewArray(list);
    } else if (packageDetailsAdditionalMembers?.length === 3) {
      let list;
      list = packageDetailsAdditionalMembers[0]?.concat(packageDetailsAdditionalMembers[1]?.concat(packageDetailsAdditionalMembers[2]))
      setPackageDetailsAdditionalMembersNewArray(list);
    } else if (packageDetailsAdditionalMembers?.length === 4) {
      let list;
      list = packageDetailsAdditionalMembers[0]?.concat(packageDetailsAdditionalMembers[1]?.concat(packageDetailsAdditionalMembers[2]?.concat(packageDetailsAdditionalMembers[3])));
      setPackageDetailsAdditionalMembersNewArray(list);
    }
  }, [packageDetailsAdditionalMembers])

  useEffect(() => {
    setRedProMembershipDiscount(Math.floor((totalPrice / 10)));
  }, [totalPrice])

  useEffect(() => {
    if (newLeadDetails && newLeadDetails?.subscriptions?.length > 0 && newLeadDetails?.subscriptions[0]?.discount > 0) {
      setAlreadyARedPro(true)
      if (newLeadDetails?.booking_count > 10) {
        setRedProMembershipDiscount(0);
      } else {
        setRedProMembershipDiscount(Math.floor((totalPrice / 100) * newLeadDetails?.subscriptions[0]?.discount));
      }
    } else {
      setAlreadyARedPro(false)
      setRedProMembershipDiscount(0);
    }
  }, [newLeadDetails, price])

  useEffect(() => {
    if (checkedWay === "") {
      setRedProMembershipDiscount(0);
    } else {
      // setReferralPointsDiscount(0);
      // setReferralPointsApplied(false);
      // setDiscount(0);
      // setCoupon(0);
      // setCouponCode("");
      // setRedDisc(0);
      setRedProMembershipDiscount(Math.floor((totalPrice / 100) * redProMembershipDiscountPercentage));
    }
  }, [checkedWay])

  useEffect(() => {
    if (booking?.coupon_applied) {
      setCoupon(booking?.coupon_applied.id);
      setCouponCode(booking?.coupon_applied?.name);
    }
    if (booking?.designation && booking?.customer_gender) {
      setCustomer_designation(booking?.designation);
      const findGender = GENDER_CHOICE.filter(
        (el: any) => el.db_name == booking?.designation
      );
      if (
        booking?.designation == "Master" &&
        booking?.customer_gender == "male"
      ) {
        setDesignation("Baby Boy");
      } else if (
        booking?.designation == "Master" &&
        booking?.customer_gender == "female"
      ) {
        setDesignation("Baby Girl");
      } else {
        findGender.length > 0 && setDesignation(findGender[0].display_name);
      }
      setCustomer_gender(booking?.customer_gender);
    }
  }, [booking]);

  // useEffect(() => {
  //   if (booking?.customer_gender !== undefined)
  //     getPackage(
  //       `city_id=${booking?.cityid}&gender=${booking?.customer_gender}`
  //     );
  //   // {
  //   //   userDetails?.profile?.pilot_user ? (
  //   //     getNewPackage(`city_id=${booking?.cityid}&gender=${booking?.customer_gender}`)
  //   //     ) :   getPackage(`city_id=${booking?.cityid}&gender=${booking?.customer_gender}`)
  //   // }
  //   // getCoupons("?org_type=homedx");
  //   // getMapMyIndiaAccessToken();
  // }, []);

  const [isHitCouponAPI, setIsHitCouponAPI] = useState(false);

  useEffect(() => {
    if (isHitCouponAPI === true) {
      getCoupons("?org_type=homedx");
    }
  }, [isHitCouponAPI]);

  const [isHitPackageAPI, setIsHitPackageAPI] = useState(false);

  useEffect(() => {
    if (isHitPackageAPI === true) {
      if (booking?.customer_gender !== undefined) {
        getPackage(`city_id=${booking?.cityid}&gender=${booking?.customer_gender}`);
      }
    }
  }, [isHitPackageAPI]);

  const handleAddLoyalty = () => {
    setOpenLoyaltyModal(false)
  };

  const handleCanel = () => {
    setCheckedWay("");
    setRedProMembershipDiscountPercentage(0);
    setProMembershipPrice(0);
    setOpenLoyaltyModal(false);
  };

  const [isHitLeadSubscriptionsDataAPI, setIsHitLeadSubscriptionsDataAPI] = useState(false);

  useEffect(() => {
    if (isHitLeadSubscriptionsDataAPI === true) {
      getLeadSubscriptionsData();
    }
  }, [isHitLeadSubscriptionsDataAPI]);

  useEffect(() => {
    const slots = booking_slots && booking_slots.results;
    setTimeSlots(slots && slots[0]?.zone_data && slots[0]?.zone_data?.time_slots?.filter((item: {
      available_slots: number; start_time: any; id: number;
    }) => {
      var dateNow = moment(new Date()).format("DD/MM/YYYY HH:mm:ss");
      var dateThen = moment(new Date()).format(`DD/MM/YYYY ${item?.start_time}`);
      var timeDiff = moment(dateThen, "DD/MM/YYYY HH:mm:ss").diff(moment(dateNow, "DD/MM/YYYY HH:mm:ss"));
      if (item?.id === booking?.collection_slot?.id) return item;
      else if (collection_date === moment().format("YYYY-MM-DD")) return timeDiff > 5400000 && item?.available_slots > 0;
      else return item?.available_slots > 0;
    }));
  }, [booking_slots]);

  useEffect(() => {
    if (Number(selectedSlot) > 0 && timeSlots !== undefined) {
      const findingSelectedSlot = timeSlots.filter(
        (x: any) => x.id === Number(selectedSlot)
      );
      setFindSelectedSlot(findingSelectedSlot)
    }
  }, [selectedSlot, timeSlots])

  useEffect(() => {
    if (removePhleboCharge === true) {
      setPhleboCost(0)
      setPhleboCostOld(0)
    } else {
      if (findSelectedSlot && findSelectedSlot?.length > 0 && findSelectedSlot[0]?.id > -1) {
        setPhleboCost(findSelectedSlot[0]?.phlebo_cost)
        setPhleboCostOld(findSelectedSlot[0]?.phlebo_cost)
        setMinAmountPhleboCost(findSelectedSlot[0]?.minimum_amount_phlebo_cost)
      }
    }

  }, [findSelectedSlot])

  useEffect(() => {
    if (zoneId > 0 && collection_date?.length > 0) {
      if (booking?.centre_address === null) {
        getAvailableSlots(collection_date, zoneId);
      } else {
        getTimeslots();
      }
    }
  }, [zoneId])

  // const checkNormalPackage = (packages: any) => {
  //   let isNormalPackage = false;
  //   packages &&
  //     packages.length > 0 &&
  //     packages.map((item: any) => {
  //       if (!item?.is_addon) {
  //         isNormalPackage = true;
  //       }
  //     });
  //   return isNormalPackage;
  // };

  const getNoofPackageBuyOneGetOne = () => {
    let noOfBuyOneGetOne = 0;
    booking.additional_members &&
      booking.additional_members.map((item: any) => {
        if (item?.packages && item?.packages.length > 0) {
          item?.packages.map((packageItem: any) => {
            if (packageItem?.code == "PL50") {
              noOfBuyOneGetOne = noOfBuyOneGetOne + 1;
            }
          });
        }
      });
    return noOfBuyOneGetOne;
  };

  const SAMPLE_20M_INTERVAL_3TIMES = ["BC052A"];
  const SAMPLE_1H_INTERVAL_3TIMES = "BC031";
  const SAMPLE_1H_INTERVAL_2TIMES = "BC29";
  const SAMPLE_2H_INTERVAL_2TIMES = "BC032A";
  const URINE_CONTAINER_WITHIN_24H = [
    'BC326', 'BC285', 'BC308', 'BC351', 'BC346', 'BC437', 'BC370', 'BC397', 'BC195', 'BC199',
    'BC410', 'BC413', 'BC472', 'BC288', 'BC018', 'BC289', 'BC290', 'BC291', 'BC292', 'BC293',
    'BC294', 'BC296', 'BC297', 'BC54', 'SE15', 'BC299', 'BC303', 'BC314', 'BC320', 'BC321',
    'BC322', 'BC323', 'BC324', 'BC325', 'BC115', 'BC124', 'BC274', 'BC185', 'BC246', 'BC416',
    'BC239', 'BC226', 'BC209', 'BC404', 'BC476'
  ];

  const [SAMPLE_1H_INTERVAL_3TIMES_SLOTS, setSAMPLE_1H_INTERVAL_3TIMES_SLOTS] = useState<Array<{"available_slots": number}>>([]);
  const [SAMPLE_1H_INTERVAL_2TIMES_SLOTS, setSAMPLE_1H_INTERVAL_2TIMES_SLOTS] = useState<Array<{"available_slots": number}>>([]);
  const [SAMPLE_2H_INTERVAL_2TIMES_SLOTS, setSAMPLE_2H_INTERVAL_2TIMES_SLOTS] = useState<Array<{"available_slots": number}>>([]);

  useEffect(() => {

    if (timeSlots?.length > 0) {
      setSAMPLE_1H_INTERVAL_3TIMES_SLOTS(timeSlots?.filter((single, index) => {
        if (index < timeSlots?.length - 2) {
          if (timeSlots[index]?.available_slots > 0 && timeSlots[index + 1]?.available_slots > 0 && timeSlots[index + 2]?.available_slots > 0) {
            return single;
          }
        }
      }))
      setSAMPLE_1H_INTERVAL_2TIMES_SLOTS(timeSlots?.filter((single, index) => {
        if (index < timeSlots?.length - 1) {
          if (timeSlots[index]?.available_slots > 0 && timeSlots[index + 1]?.available_slots > 0) {
            return single;
          }
        }
      }))
      setSAMPLE_2H_INTERVAL_2TIMES_SLOTS(timeSlots?.filter((single, index) => {
        if (index < timeSlots?.length - 3) {
          if (timeSlots[index]?.available_slots > 0 && timeSlots[index + 3]?.available_slots > 0) {
            return single;
          }
        }
      }))
    }

  }, [timeSlots])

  const handlePackages = (customerPackage: any) => {
    const min_phlebo_cost =
      customerPackage &&
      customerPackage?.length > 0 &&
      customerPackage?.[customerPackage?.length - 1]?.min_booking_amount_for_phlebo;
    const phlebo_cost =
      customerPackage &&
      customerPackage?.length > 0 &&
      customerPackage?.[customerPackage?.length - 1]?.phlebo_cost;

    let urinePackageList: { code: string; }[] = [];
    let normalPackageList: { code: string; }[] = [];
    customerPackage && customerPackage?.length > 0 && customerPackage.filter((item: { code: string; }) => {
      URINE_CONTAINER_WITHIN_24H.forEach(obj => {
          if ( item?.code === obj ) {
            urinePackageList.push(item);
            return item;
          }
      });
    });

    if (urinePackageList?.length === 0) {
      normalPackageList = customerPackage;
    } else {
      normalPackageList.push(customerPackage[customerPackage?.length - 1]);
    }

    if (additionalMembersStatus?.length !== booking?.additional_members?.length) {
      if (booking?.delivery_date === null) {
        if (urinePackageList?.length > 0) {
          let copyPackage = customerPackage.slice(0, customerPackage.length - 1);
          customerPackage = copyPackage;
          setPackageDetails(customerPackage);
          alert("For adding URINE PACKAGE create another booking.")
        } else {
          setPackageDetails(customerPackage);
        }
      } else {
        if (urinePackageList?.length > 0) {
          customerPackage = [urinePackageList[urinePackageList?.length - 1]];
          setPackageDetails(customerPackage);
          alert("You can only add URINE CONTAINER PACKAGE in this booking.")
        } else {
          alert("You can only select/update URINE CONTAINER package in URINE CONTAINER BOOKING. For other packages, do another booking.");
        }
      }
    } else {
      if (booking?.delivery_date === null) {
        if (urinePackageList?.length > 0) {
          customerPackage = [urinePackageList[urinePackageList?.length - 1]];
          setPackageDetails(customerPackage);
          setCheckedContainerDeliverySlot("1");
          alert("Please select the Container Delivery Date and Slot. You can select only one package in URINE CONTAINER BOOKING for 1 member.")
        } else {
          setCheckedContainerDeliverySlot("");
          setPackageDetails(customerPackage);
        }
      } else {
        if (urinePackageList?.length > 0 && urinePackageList?.length === customerPackage?.length) {
          customerPackage = [urinePackageList[urinePackageList?.length - 1]];
          setPackageDetails(customerPackage);
          setCheckedContainerDeliverySlot("1");
          alert("You can select only one package in URINE CONTAINER BOOKING for 1 member.")
        } else if (urinePackageList?.length === 1) {
          customerPackage = [];
          setPackageDetails([]);
          setCheckedContainerDeliverySlot("");
          alert("'Need a Container For Booking' checkbox is unchecked now as you are not doing the URINE CONTAINER BOOKING.");
        } else {
          setPackageDetails(customerPackage);
          setCheckedContainerDeliverySlot("");
        }
      }
    }

    let countUrinePack: { code: string; }[] = [];
    customerPackage && customerPackage?.length > 0 && customerPackage.filter((item: { code: string; }) => {
      URINE_CONTAINER_WITHIN_24H.forEach(obj => {
          if ( item?.code === obj ) {
            countUrinePack.push(item);
            return item;
          }
      });
    });

    if (countUrinePack?.length > 0) {
      setDeleteUrineData(false);
    } else {
      setDeleteUrineData(true);
    }

    let accumulatedPrice: any = 0;
    let packIds: any = [];
    let packCodes: any = [];
    setRefresh(!refresh);
    if (customerPackage && customerPackage.length > 0) {
      // let atLeastOneNormalPackage = checkNormalPackage(customerPackage);
      let pl50Count =
        packageDetails?.length > 0 ? getNoofPackageBuyOneGetOne() : 0;
      let priceApplicable = pl50Count > 0 ? pl50Count % 2 : 0;
      customerPackage.map((item: any) => {
        packIds.push(item?.id);
        packCodes.push(item?.code);
        if (pl50Count > 0 && priceApplicable == 1 && item?.code == "PL50") {
          accumulatedPrice = accumulatedPrice + 0;
        } else {
          // if (
          //   atLeastOneNormalPackage &&
          //   item?.is_addon &&
          //   item?.addon_price &&
          //   item?.addon_price > 0
          // ) {
          //   accumulatedPrice = accumulatedPrice + item?.addon_price;
          // } else {
          if (item?.package_city_prices === null) {
            accumulatedPrice = accumulatedPrice + item?.offer_price;
          } else {
            accumulatedPrice =
              accumulatedPrice + item?.package_city_prices?.offer_price;
          }
          // }
        }
      });
    }

    setPrice(Math.floor(accumulatedPrice));
    // Edit Family Members
    if (
      booking?.additional_members &&
      booking?.additional_members?.length > 0
    ) {
      booking.additional_members.map((item: any) => {
        if (item?.packages) {
          // let atLeastOneNormalPackage = checkNormalPackage(item?.packages);
          item?.packages.map((pack: any) => {
            // if (
            //   atLeastOneNormalPackage &&
            //   pack.is_addon &&
            //   pack.addon_price &&
            //   pack.addon_price > 0
            // ) {
            //   accumulatedPrice = accumulatedPrice + pack.addon_price;
            // } else {
            if (pack.package_city_prices === null) {
              accumulatedPrice = accumulatedPrice + pack.offer_price;
            } else {
              accumulatedPrice =
                accumulatedPrice + pack.package_city_prices?.offer_price;
            }
            if (accumulatedPrice < min_phlebo_cost) {
              setPhleboCost(phlebo_cost)
              setExtraCharge(true);
            } else {
              setExtraCharge(false);
            }
            // }
          });
          setTotalPrice(Math.floor(accumulatedPrice));
        }
      });
    }
    setDiscount(0);
    setReferralPointsDiscount(0);
    setReferralCouponDiscount(0);
    // setSelectedSlot(booking.collection_slot && booking.collection_slot.id);
    // setPhleboCost(0);
    // setMinAmountPhleboCost(0);
    setReferralPointsApplied(false);
    setPackages(packIds);
    setPackagesCode(packCodes);

    // if (phlebo_cost !== undefined && extraCharge === true) {
    //   setPrice(Math.floor(accumulatedPrice) + phlebo_cost);
    // } else {
    //   setPrice(Math.floor(accumulatedPrice));
    // }
  };
  useEffect(() => {
    if (cityId !== undefined) {
      getPhlebos(`?city_id=${cityId}`)
    }
  }, [cityId])

  useEffect(() => {
    if (booking.receivable_amount === 0) {
      setPaymentMode(booking.payment_mode);
    }
    if (booking.phlebo) {
      setPhleboName(booking?.phlebo?.name);
    }
  }, [booking]);

  useEffect(() => {
    if (!alreadyARedPro) {
      if (totalPrice < minAmountPhleboCost && minAmountPhleboCost > 0) {
        setExtraCharge(true);
        setPhleboCost(phleboCostOld);
        // setPrice(totalPrice + phleboCostOld);
      } else {
        setPhleboCost(0);
        setExtraCharge(false);
      }
    } else {
      setPhleboCost(0);  // Doing 0 as per Pradeep Sir
      setExtraCharge(false);
    }
  }, [refresh])

  const hanbdlePaymentMode = (e: any) => {
    if (e.target.value === "cash") {
      setPickup_amount(booking.receivable_amount);
      setPaymentMode(e.target.value as string);
    } else {
      setPickup_amount(0);
      setPaymentMode(e.target.value as string);
    }
  };
  useEffect(() => {
    if (pickup_status === "confirmed" && booking?.receivable_amount !== 0) {
      setPickup_amount(booking?.receivable_amount);
    } else if (
      pickup_status === "confirmed" &&
      booking?.receivable_amount === 0
    ) {
      setPaymentMode(booking?.payment_mode);
    } else {
      setPickup_amount(0);
      setPaymentMode("none");
    }
  }, [pickup_status]);
  const totalfixedPrice =
    totalPrice - Math.floor(booking?.redcash_discounted_amount);
  useEffect(() => {
    if (booking.payment_mode === "credit") {
      if (totalfixedPrice > booking?.discounted_price?.final_total_price) {
        setPaymentMode("none");
      } else {
        setPaymentMode(booking.payment_mode);
      }
    }
  }, [totalPrice]);

  const checkAdditionalPickupStatusAndBookingStatus = booking?.additional_members && booking?.additional_members?.length > 0 &&
    booking?.additional_members?.filter((data: any) => data.booking_status !== "cancelled" && data?.pickup_status !== "cancelled");

  useEffect(() => {
    if (extraCharge) {
      setTotalPrice(Math.floor(price - discount + phleboCost));
    } else {
      setTotalPrice(Math.floor(price - discount));
    }
  }, [discount, extraCharge]);

  useEffect(() => {
    if (totalPrice < 0) {
      setTotalPrice(0);
    }
  }, [totalPrice]);

  useEffect(() => {
    if (price >= 0 && booking?.discounted_price?.coupon_type === "referral" && booking?.discounted_price?.referral_coupon_discount > 0) {
      const discountPercentage = booking?.coupon_applied?.discount;
      setReferralCouponDiscount(Math.floor(((price) / 100) * discountPercentage))
    }
  }, [price]);

  useEffect(() => {
    if (booking?.discounted_price?.phlebo_cost > 0) {
      setExtraCharge(true)
      setPhleboCost(booking?.discounted_price?.phlebo_cost)
    }
  }, [booking])

  // useEffect(() => {
  //   if (phleboCost > 0)  setExtraCharge(true)
  // }, [phleboCost])

  // useEffect(() => {
  //   if (extraCharge) {
  //     let priceWithPhleboCost = price + phleboCost;
  //     setPrice(priceWithPhleboCost)
  //   }
  // }, [extraCharge])

  useEffect(() => {
    if (booking?.discounted_price?.coupon_type === "referral") {
      setCType("referral");
      setReferralCouponDiscount(booking?.discounted_price?.referral_coupon_discount)
    }
  }, [booking]);

  const applyCoupon = () => {
    if (couponCode === "") {
      setCouponCode("");
      setCoupon(0);
      setDiscount(0);
      setCouponApplyFlag(false);
      return;
    }
    const appliedCoupon =
      couponList?.results?.find((coup: any) => coup?.id === coupon) ||
      couponList?.results?.find((coup: any) => coup?.code === couponCode);
    if (appliedCoupon && appliedCoupon?.minimum_price < totalPrice) {
      setCoupon(appliedCoupon.id);
      if (appliedCoupon?.discount_type?.toLowerCase() === "percentage") {
        if (appliedCoupon?.is_red_cash) {
          setDiscount(0);
        } else {
          setCouponApplyFlag(true);
          let totalDiscountOnBooking = 0;
          if (appliedCoupon?.upper_limit_percentage === null || appliedCoupon?.upper_limit_percentage === 0) {
            totalDiscountOnBooking = Math.floor(((price) * overallDiscountLimit) / 100);
          } else {
            totalDiscountOnBooking = Math.floor(((price) * appliedCoupon?.upper_limit_percentage) / 100);
          }

          let couponDiscount = Math.floor(((price - redProMembershipDiscount) * appliedCoupon?.discount) / 100);

          if (couponDiscount + redProMembershipDiscount <= totalDiscountOnBooking) {
            setDiscount(couponDiscount);
          } else {
            (totalDiscountOnBooking - redProMembershipDiscount > 0) ? setDiscount(totalDiscountOnBooking - redProMembershipDiscount) : setDiscount(0)
          }
        }
      }
      if (appliedCoupon.discount_type?.toLowerCase() === "amount") {
        if (appliedCoupon.is_red_cash) {
          setDiscount(0);
        } else {
          setCouponApplyFlag(true);
          setDiscount(Math.floor(appliedCoupon?.discount));
        }
      }
    } else {
      setCouponCode("");
      setCoupon(0);
      setDiscount(0);
    }
  };
  const applyCouponPerc = () => {
    if (cValue === "") {
      setCouponCode("");
      setCoupon(0);
      setDiscount(0);
      setCouponApplyFlag(false);
      return;
    }
    const appliedCoupon = { cType: cType, cValue: cValue };
    if (appliedCoupon.cValue < totalPrice) {
      if (appliedCoupon.cType === "percentage") {
        if (appliedCoupon.cValue > 100) {
          alert("Please Select Valid Number");
        }
        setDiscount(Math.floor(((price - redProMembershipDiscount) * appliedCoupon.cValue) / 100));
        setCouponApplyFlag(true)
      }
      if (appliedCoupon.cType === "amount") {
        setDiscount(Math.floor(appliedCoupon.cValue));
        setCouponApplyFlag(true)
      }
    } else {
      setCouponCode("");
      setCoupon(0);
      setDiscount(0);
      setCouponApplyFlag(false)
    }
  };
  const verifyMobile = (type: any) => {
    if (type === "mobile") {
      if (
        parseInt(customer_phonenumber && customer_phonenumber[0]) < 6 ||
        (customer_phonenumber && customer_phonenumber.length !== 10)
      ) {
        setMobileValidation((prev: any) => ({
          ...prev,
          customer_phonenumber: false,
        }));
      }
    }
    if (type === "alternate") {
      if (
        parseInt(customer_alternatenumber && customer_alternatenumber[0]) < 6 ||
        (customer_alternatenumber && customer_alternatenumber.length !== 10)
      ) {
        setMobileValidation((prev: any) => ({
          ...prev,
          customer_alternatenumber: false,
        }));
      }
    }
    if (type === "whatsapp") {
      if (
        parseInt(customer_whatsapppnumber && customer_whatsapppnumber[0]) < 6 ||
        (customer_whatsapppnumber && customer_whatsapppnumber.length !== 10)
      ) {
        setMobileValidation((prev: any) => ({
          ...prev,
          customer_whatsapppnumber: false,
        }));
      }
    }
  };

  const submitForm = async (e: any) => {

    e.preventDefault();

    let packagesOld = packageDetailsOld.map((pack: any) => {
      return pack?.package_city_prices ? pack?.package_city_prices?.offer_price : pack?.offer_price;
    });
    let packagesOldPrice = packagesOld.reduce((packSum: any, a: any) => packSum + a, 0);

    let packagesNew = packageDetails.map((pack: any) => {
      return pack?.package_city_prices ? pack?.package_city_prices?.offer_price : pack?.offer_price;
    });
    let packagesNewPrice = packagesNew.reduce((packSum: any, a: any) => packSum + a, 0);

    let packagesAdditionalMembers = packageDetailsAdditionalMembersNewArray.map((pack: any) => {
      return pack?.package_city_prices ? pack?.package_city_prices?.offer_price : pack?.offer_price;
    });
    let packagesAdditionalMembersPrice = packagesAdditionalMembers.reduce((packSum: any, a: any) => packSum + a, 0);

    packagesOldPrice = packagesOldPrice + packagesAdditionalMembersPrice;
    packagesNewPrice = packagesNewPrice + packagesAdditionalMembersPrice;

    const data: any = {
      booking_date: moment(new Date()).format("YYYY-MM-DD"),
      collection_date: collection_date,
      collection_slot: selectedSlot,
      customer_name: customer_name,
      customer_age: customer_age,
      customer_gender: customer_gender,
      customer_email: customer_email,
      customer_phonenumber: customer_phonenumber,
      customer_altphonenumber: customer_alternatenumber,
      customer_whatsapppnumber: customer_whatsapppnumber,
      customer_address: customer_address,
      customer_landmark: customer_landmark,
      customer_longitude: customer_longitude,
      customer_latitude: customer_latitude,
      customer_areapincode: customer_areapincode,
      customer_city: customer_city,
      customer_aadhar: aadhar,
      passport_number: passport === null ? "" : passport,
      packages: packages,
      coupon_applied: coupon,
      coupon_amount: Number(cValue),
      coupon_type: cType,
      // "partner": booking_source,
      designation: customer_designation,
      address_line2: addressLine2,
      is_prescription: isPrescribed,
      landmark: favoritePlace,
      phlebo_remarks: phleboRemarks,
      is_referral_applied: "false",
      verification_remark: verification_remark,
      verification_status: verification_status,
      booking_status: booking_status,
      pickup_status: pickup_status,
      pickup_receive_amount: pickup_amount,
      phlebo_incentive: phlebo_incentive,
      phlebo_convenience: phlebo_convenience,
      phlebo_verification_remark: phlebo_verification_remark,
      rm_comment: rm_comment,
      rca_comment: rca_comment,
      remarks: remarks,
      phlebo: phlebo,
      manual_asigned: phleboInitial == phlebo ? false : true,
      subscription: checkedWay,
      payment_mode,
      remove_coupon: "true",
      requested_for_hard_copy: requestedForHardCopy,
      remove_phlebo_charges: removePhleboCharge,
      is_redcash_applied: redCashButton ? "true" : "false",
      delivery_date: booking?.delivery_date !== null ? containerDate : moment(new Date().setDate(new Date().getDate() + 1)).format('YYYY-MM-DD'),
      delivery_collection_slot: containerSlot === "" ? 11 : containerSlot,
    };

    if (deleteUrineData) {
      delete data.delivery_date;
      delete data.delivery_collection_slot;
    }

    if (booking?.coupon_applied?.discount_type === "percentage") {
      if (packagesOldPrice <= packagesNewPrice) {
        delete data["remove_coupon"];
      } else {
        if ((packagesOldPrice < 1000 && packagesNewPrice < 1000) || (packagesOldPrice > 4999 && packagesNewPrice > 4999) || ((packagesOldPrice > 999 && packagesNewPrice > 999) && (packagesOldPrice < 2000 && packagesNewPrice < 2000)) || ((packagesOldPrice > 1999 && packagesNewPrice > 1999) && (packagesOldPrice < 5000 && packagesNewPrice < 5000))) {
          delete data["remove_coupon"];
        }
      }
    } else {
      delete data["remove_coupon"];
    }

    if (booking.booking_type === "b2b" || booking.booking_type === "DSA") {
      delete data["requested_for_hard_copy"];

    }

    if (booking?.booking_type === "b2c" && redCashButton) {
      delete data.coupon_amount;
      delete data.coupon_type;
      delete data.coupon_applied;
      data["remove_coupon"] = "true";
    }

    if (booking.booking_type === "b2b") {
      delete data["remove_coupon"];
      delete data["coupon_amount"];
      delete data["coupon_applied"];
      delete data["coupon_type"];
    }
    if (removePhleboCharge === false) {
      delete data["remove_phlebo_charges"]
    }

    if (((cValue === "" || cValue === 0) && (cType === "percentage")) || ((cType === "code") && data["coupon_applied"] === 0)) {
      delete data.coupon_amount;
      delete data.coupon_type;
      delete data.coupon_applied;
    }

    if (data["subscription"] === "") {
      delete data["subscription"];
    }
    if (data["payment_mode"] === "" || data["payment_mode"] === "none") {
      delete data["payment_mode"];
    }
    if (data["manual_asigned"] === false) {
      delete data["manual_asigned"];
    }
    if (data["remarks"] === null || remarks === "" || remarks === null) {
      delete data["remarks"];
    }
    if (data["customer_areapincode"] === 0) {
      delete data["customer_areapincode"];
    }
    if (data["rca_comment"] === null || rca_comment === null) {
      delete data["rca_comment"];
    }

    // if (JSON.stringify(packagesNew) == JSON.stringify(packagesOld)) {
    //   delete data.remove_coupon;
    // }
    if (
      data["phlebo_verification_remark"] === null ||
      phlebo_verification_remark === "" ||
      phlebo_verification_remark === null
    ) {
      delete data["phlebo_verification_remark"];
    }
    if (
      data["phlebo_incentive"] === 0 ||
      phlebo_incentive === "" ||
      phlebo_incentive === null
    ) {
      delete data["phlebo_incentive"];
    }
    if (data["rm_comment"] === null || rm_comment === null) {
      delete data["rm_comment"];
    }
    if (
      data["phlebo_convenience"] === 0 ||
      phlebo_convenience === "" ||
      phlebo_convenience === null
    ) {
      delete data["phlebo_convenience"];
    }
    // if (cType === "" || cType === "none" || couponApplyFlag === false) {
    //   delete data.coupon_amount;
    //   delete data.coupon_type;
    //   delete data.coupon_applied;
    // }
    if (data["coupon_applied"] === 0 || couponCode === "") {
      delete data["coupon_applied"];
    }
    if (booking?.discounted_price?.coupon_type === "redeem points" && booking?.discounted_price?.referral_coupon_discount >= 0) {
      data["is_referral_applied"] = "true";
    }
    if (data["customer_areapincode"] === 0) {
      delete data["customer_areapincode"];
    }
    if (
      data["verification_remark"] === null ||
      verification_remark === "" ||
      verification_remark === null
    ) {
      delete data["verification_remark"];
    }

    if (cType === "referral") {
      delete data.coupon_amount;
    }
    if (data["payment_mode"] === "" || data["payment_mode"] === "none") {
      delete data["payment_mode"];
    }

    // if (removeCoupn && !data['coupon_applied']) {
    //     data['remove_coupon'] = "true"
    // }
    if (data["pickup_receive_amount"] === 0 || pickup_amount === null) {
      delete data["pickup_receive_amount"];
    }
    if (pickup_status === "confirmed" && payment_mode === "none") {
      alert("Payment Mode Can't be None");
    } else {
      setDisableBtn(true)
      await updateBooking(data, bookingId);
      history.push(`/dashboard/pha/booking-view/${bookingId}`);
    }
  };
  const changeCollectionDate = (e: any) => {
    setSelectedSlot(0);
    setPhleboCost(0);
    setMinAmountPhleboCost(0);
    setCollection_date(e.target.value as string);
    if (zoneId) {
      getPackage(`city_id=${cityId}&gender=${customer_gender}`);
      // {
      //   userDetails?.profile?.pilot_user ? (
      //     getNewPackage(`city_id=${cityId}&gender=${customer_gender}`)
      //     ) :   getPackage(`city_id=${cityId}&gender=${customer_gender}`)
      // }
      if (booking?.centre_address === null) {
        getAvailableSlots(e.target.value, zoneId);
      } else {
        getTimeslots();
      }
    }
  };

  useEffect(() => {
    setCustomer_landmark(landmark ? landmark.label : booking.customer_landmark);
    // if (landmark?.label) {
    //   geocodeByAddress(landmark?.label)
    //     .then((results) => getLatLng(results[0]))
    //     .then(({ lat, lng }) => {
    //       lat && setCustomerLatitude(lat);
    //       lng && setCustomerLongitude(lng);
    //     });
    // }
  }, [landmark]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAdharPassport((event.target as HTMLInputElement).value);
  };

  const titleSelection = (title: string) => {
    const findGender = GENDER_CHOICE.filter(
      (el: any) => el.display_name == title
    );
    setDesignation(title);
    getPackage(
      `city_id=${booking?.cityid}&org_type=homedx&gender=${customer_gender}`
    );
    // {
    //   userDetails?.profile?.pilot_user ? (
    //     getNewPackage(`city_id=${booking?.cityid}&org_type=homedx&gender=${customer_gender}`)
    //     ) :   getPackage(`city_id=${booking?.cityid}&org_type=homedx&gender=${customer_gender}`)
    // }
    setCustomer_designation(findGender[0].db_name);
    setCustomer_gender(findGender[0].gender);
  };
  //mapmyindia
  const [suggestedAddress, setSuggestedAddres] = useState<any>([]);
  const [mapAddressObject, setMapAddressObject] = useState<any>({});
  const [accessToken, setAccessToken] = useState<any>("");
  // useEffect(() => {
  //   if (geoFencesMapMyIndia?.latitude && geoFencesMapMyIndia?.longitude) {
  //     setCustomerLatitude(geoFencesMapMyIndia?.latitude);
  //     setCustomerLongitude(geoFencesMapMyIndia?.longitude);
  //   }
  // }, [geoFencesMapMyIndia]);

  // useEffect(() => {
  //   if (mapmyindiaAccessToken?.accesstoken) {
  //     setAccessToken(mapmyindiaAccessToken?.accesstoken);
  //   }
  // }, [mapmyindiaAccessToken]);

  // useEffect(() => {
  //   if (mapAddressObject?.eLoc) {
  //     getPinCode(mapAddressObject?.addressTokens?.pincode);
  //     setCustomer_landmark(mapAddressObject?.placeAddress);
  //     checkGeoFenchMapMyIndia(mapAddressObject?.eLoc, "NA");
  //   }
  // }, [mapAddressObject]);

  const locationAutoCompleteSearch = (val: string) => {
    if (val.length > 2) {
      setSuggestedAddres([]);
      const urlForMap = `https://atlas.mapmyindia.com/api/places/search/json?query=${val}&access_token=${accessToken}&tokenizeAddress=true`;
      return new Promise(function (resolve, reject) {
        const id = "_" + Math.round(10000 * Math.random());
        const callbackName = "jsonp_callback_" + "data" + id;
        window[callbackName] = (data: any) => {
          delete window[callbackName];
          const ele = document.getElementById(id);
          ele?.parentNode?.removeChild(ele);
          resolve(data?.suggestedLocations);
          setSuggestedAddres(data?.suggestedLocations);
        };
        const src = urlForMap + "&callback=" + callbackName;
        const script = document.createElement("script");
        script.src = src;
        script.id = id;
        script.addEventListener("error", reject);
        (
          document.getElementsByTagName("head")[0] ||
          document.body ||
          document.documentElement
        ).appendChild(script);
      });
    }
  };
  document.querySelectorAll("#noTyping").forEach((el) => {
    el.addEventListener("keydown", function (e) {
      e.preventDefault();
    });
  });

  const RM_COMMENTS: any = [
    {
      id: 1,
      name: "Customer not intersted",
    },
    {
      id: 2,
      name: "Customer will call back",
    },
    {
      id: 3,
      name: "Call not picked",
    },
    {
      id: 4,
      name: "Wrong Number",
    },
    {
      id: 5,
      name: "Out of service area",
    },
    {
      id: 6,
      name: "Customer irritated",
    },
    {
      id: 7,
      name: "Out of Station",
    },
    {
      id: 8,
      name: "Patient Hospitalise",
    },
    {
      id: 9,
      name: "Urgent report ",
    },
    {
      id: 10,
      name: "Dupliate booking",
    },
    {
      id: 11,
      name: "Wrong Address",
    },
    {
      id: 12,
      name: "Sample already collected",
    },
  ];
  const RCA_COMMENT: any = [
    {
      id: 1,
      name: "Customer not intersted",
    },
    {
      id: 2,
      name: "Customer will call back",
    },
    {
      id: 3,
      name: "Call not picked",
    },
    {
      id: 4,
      name: "Wrong Number",
    },
    {
      id: 5,
      name: "Out of service area",
    },
    {
      id: 6,
      name: "Customer irritated",
    },
    {
      id: 7,
      name: "Out of Station",
    },
    {
      id: 8,
      name: "Patient Hospitalise",
    },
    {
      id: 9,
      name: "Urgent report ",
    },
    {
      id: 10,
      name: "Dupliate booking",
    },
    {
      id: 11,
      name: "Wrong Address",
    },
    {
      id: 12,
      name: "Sample already collected",
    },
    {
      id: 13,
      name: "Phlebo delay",
    },
    {
      id: 14,
      name: "Wrong booking allocated",
    },
    {
      id: 15,
      name: "Rescheduled",
    },
    {
      id: 16,
      name: "Done from Other Lab",
    },
    {
      id: 18,
      name: "No Busy",
    },
  ];


  useEffect(() => {
    if (disableBtn) {
      setTimeout(() => {
        setDisableBtn(false);
      }, 15000)
    }
  }, [disableBtn])

  useEffect(() => {
    if (referralPointsDiscount === 0 && redCoin?.coins >= 0 && !isNaN(redProMembershipDiscount) && !isNaN(price)) {
      const maxRedDiscount = (((price - redProMembershipDiscount)) / 100) * redCashDiscountPercentage;
      const redCashDeduct = maxRedDiscount - redCoin?.coins >= 0 ? redCoin?.coins : maxRedDiscount;
      if (redCashButton) {
        setRedDisc(redCashDeduct);
      } else {
        setRedDisc(0);
      }
    }
    // else if (referralPointsDiscount > 0 && totalPrice > 0) {
    //   const maxReferralPointsDiscount = (totalPrice);
    //   if (totalPrice > 0) {
    //     // const leftAmount = maxReferralPointsDiscount - referralPointsDiscount;
    //     const maxRedDiscount = (((totalPrice - redProMembershipDiscount)) / 100) * redCashDiscountPercentage;
    //     const redCashDeduct = maxRedDiscount - redCoin?.coins >= 0 ? redCoin?.coins : maxRedDiscount;
    //     if (redCashButton) {
    //       if (totalPrice - referralPointsDiscount >= redCashDeduct) {
    //         setRedDisc(redCashDeduct);
    //       } else {
    //         setRedDisc(totalPrice - referralPointsDiscount)
    //       }
    //     } else {
    //       setRedDisc(0);
    //     }
    //   }
    // }
  }, [redCashButton]);

  useEffect(() => {
    if (newLeadDetails?.mobile?.length > 9) {
      getRedCoing(newLeadDetails?.mobile);
    }
  }, [newLeadDetails]);

  useEffect(() => {
    setRedCashDiscountPercentage(redCoin?.redcash_percentage)
  }, [redCoin]);

  useEffect(() => {
    if (redCoin) {
      if (redCoin?.coins == 0) {
        setRedCoinMessage(`You have ${redCoin.coins} Red Cash`);
      } else {
        setRedCoinMessage(`${redCoin.coins} Red Cash Available`);
      }
      setTotalRedCoin(redCoin.coins);
    }
  }, [redCoin.coins]);

  const handleRedCheck = (event: any) => {
    setRedCashButton(event.target.checked);
    setDiscount(0);
    setCouponKey(couponKey + 1);
  };

  // const [containerSlotsArray, setContainerSlotsArray] = useState<Array<{"start_time": string, "end_time": string}>>([]);
  // const [dateKey, setDateKey] = useState<any>(false);

  // useEffect(() => {
  //   const slots = containerSlots && containerSlots?.results;
  //   setContainerSlotsArray(slots && slots[0]?.zone_data && slots[0]?.zone_data?.time_slots?.filter((item: {
  //     available_slots: number; start_time: any;
  //   }) => {
  //     var dateNow = moment(new Date()).format("DD/MM/YYYY HH:mm:ss");
  //     var dateThen = moment(new Date()).format(`DD/MM/YYYY ${item?.start_time}`);
  //     var timeDiff = moment(dateThen, "DD/MM/YYYY HH:mm:ss").diff(moment(dateNow, "DD/MM/YYYY HH:mm:ss"));
  //     if (containerDate === moment().format("YYYY-MM-DD")) return timeDiff > 5400000 && item?.available_slots > 0;
  //     else return item?.available_slots > 0;
  //   }));
  // }, [containerSlots]);

  // useEffect(() => {
  //   if (containerDate && zoneId !== "") {
  //     setDateKey(!dateKey);
  //     getContainerSlots(containerDate, zoneId);
  //   }
  // }, [containerDate])

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <div className={classes.contentHeader}>
        <Grid
          container
          direction="row"
          alignItems="flex-start"
          justify="space-between"
          spacing={3}
        // style={{ margin: "1rem auto" }}
        >
          <Grid item xs={5} sm={5} md={5}>
            <h3
              style={{
                paddingTop: "10px",
                fontSize: "24px",
                fontWeight: "bold",
              }}
            >
              EDIT BOOKING
            </h3>
          </Grid>
          <Grid item xs={5} sm={5} md={5}>
            <h3
              style={{
                paddingTop: "10px",
                fontSize: "24px",
                fontWeight: "bold",
              }}
            >
              {booking?.centre_address !== null
                ? "CENTRE : " +
                String(booking?.centre_address?.name).toUpperCase()
                : "HOME-DX BOOKING"}
            </h3>
          </Grid>
          <Grid item xs={2} sm={2} md={2}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              style={{ fontSize: "10px", marginLeft: "5px" }}
              onClick={() => {
                setTimeout(() => setButtonDisabled(false), 300000);
                setButtonDisabled(true)
                setApiHitCount(1)
                SendLocationMessage()
              }}
              disabled={buttonDisabled || booking?.lead === null}
            >
              Send location Message
            </Button>
            {(apiHitCount > 0) ?
              <p style={{ color: "green", alignItems: "center", paddingLeft: "20px" }}>
                Message Sent
              </p> : ""
            }
          </Grid>
        </Grid>
      </div>
      {Object.keys(booking).length === 0 ? (
        <Loader />
      ) : (
        <Paper className={classes.paper} elevation={15}>
          <form onSubmit={submitForm}>
            <Grid container spacing={3} style={{ margin: "auto" }}>
              <Grid item xs={12} md={6}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <p>Booking date</p>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <input
                      className="input"
                      name="collection_date"
                      type="date"
                      value={booking_date || ""}
                      style={{
                        width: "100%",
                        margin: "0",
                        padding: "18.5px 14px",
                        height: "40px",
                      }}
                      required
                      disabled
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <p>Sample Collection date</p>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <input
                      className="input"
                      name="collection_date"
                      type="date"
                      value={
                        (collection_date && collection_date.toString()) || ""
                      }

                      // onChange={(e) => setCollection_date(e.target.value as string)}
                      onChange={(e) => changeCollectionDate(e)}
                      style={{
                        width: "100%",
                        margin: "0",
                        padding: "18.5px 14px",
                        height: "40px",
                      }}
                      id="noTyping"
                      min= {deleteUrineData === false ? (dateValidation < 3 ? moment(new Date().setDate(new Date().getDate() + 3 - dateValidation)).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD")) : booking?.booking_date}
                      max= {moment(new Date().setDate(new Date().getDate() + 7)).format("YYYY-MM-DD")}
                      required
                    />
                  </Grid>
                </Grid>
                {bookingType !== "b2b" || bookingType !== "DSA" ? (
                  <>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <p>Requested For Hard Copy</p>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Select
                          className="input"
                          name="requested_for_hard_copy"
                          variant="outlined"
                          value={requestedForHardCopy}
                          disabled={requestedForHardCopy === true}
                          style={{ width: "100%", margin: "0", height: "40px" }}
                          onChange={(e) => setRequestedForhHardCopy(e.target.value)}
                        >
                          <MenuItem value="true">Yes</MenuItem>
                          <MenuItem value="false">No</MenuItem>
                        </Select>
                      </Grid>
                    </Grid>
                  </>
                ) : ""}

                <Grid container spacing={3}>

                  <Grid item xs={12} md={6}>
                    <p>Remove Phlebo Charge</p>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Select
                      className="input"
                      name="requested_for_hard_copy"
                      variant="outlined"
                      value={removePhleboCharge}
                      disabled={removePhleboCharge === true}
                      style={{ width: "100%", margin: "0", height: "40px" }}
                      onChange={(e) => setRemovePhleboCharges(e.target.value)}
                    >
                      <MenuItem value="true">Yes</MenuItem>
                      <MenuItem value="false">No</MenuItem>
                    </Select>
                  </Grid>
                </Grid>
                {/* <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <p>Pin Code</p>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      id="pincode"
                      onChange={(event, newValue) => {
                        if (newValue) {
                          let obj = JSON.parse(
                            JSON.stringify(newValue, null, " ")
                          );
                          handlePinCode(obj.pincode as String);
                        }
                      }}
                      defaultValue={
                        customer_areapincodeDef || {
                          id: "",
                          pincode: "",
                          area: "",
                        }
                      }
                      options={pin_code.results}
                      freeSolo
                      blurOnSelect
                      onInputChange={(event, newInputValue) => {
                        clearTimeout(timer.current);
                        timer.current = setTimeout(() => {
                          getPinCode(newInputValue.split(",")[0]);
                        }, 1000);
                      }}
                      getOptionLabel={(option: any) => `${option?.pincode}`}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Pin Code"
                          variant="outlined"
                          style={{ width: "100%", margin: "0", padding: "0" }}
                          className="input"
                          disabled={booking.phlebo !== null}
                          required
                        />
                      )}
                      disabled
                    />
                  </Grid>
                </Grid> */}
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid
                  container
                  spacing={0}
                  style={{
                    padding: "10px",
                    background: "#e1f5fe",
                    marginLeft: "-10px",
                  }}
                >
                  <Grid item xs={12} md={6}>
                    <p>Locality </p>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <p>
                      : <strong>{customer_landmark}</strong>
                      &nbsp;{" "}
                    </p>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <p>House / Flat / Shop Number /-</p>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <p>
                      : <strong>{customer_address}</strong>
                    </p>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <p>
                      Apartment / Building / Street Number
                    </p>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <p>
                      : <strong>{addressLine2}</strong>
                    </p>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <p>Landmark</p>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <p>
                      : <strong>{favoritePlace}</strong>
                    </p>
                  </Grid>
                  {/* <Grid item xs={12} md={6}>
                    <p>Area Name </p>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <p>
                      : <strong>{zoneName}</strong>
                    </p>
                  </Grid> */}
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="flex-end"
              spacing={3}
              style={{ margin: "1rem auto", background: "#eff" }}
            >
              <Grid item md={3}>
                <p>Mobile Number</p>
                <TextField
                  name="customer_phonenumber"
                  type="text"
                  value={customer_phonenumber}
                  placeholder="Enter Mobile Number"
                  className="input"
                  variant="outlined"
                  disabled
                  style={{ margin: "0", width: "100%" }}
                  onChange={(e) =>
                    setCustomer_phonenumber(e.target.value as String)
                  }
                  onBlur={() => verifyMobile("mobile")}
                  onFocus={() => {
                    setMobileValidation((prev: any) => ({
                      ...prev,
                      customer_phonenumber: true,
                    }));
                  }}
                  helperText={
                    !mobileValidation.customer_phonenumber &&
                    "Incorrect Phone Number"
                  }
                  required
                />
              </Grid>
              <Grid item md={3}>
                <p>Alternate Mobile Number</p>
                <TextField
                  name="customer_alternatenumber"
                  type="text"
                  value={customer_alternatenumber}
                  placeholder="Enter Alternate Mobile Number"
                  className="input"
                  variant="outlined"
                  style={{ margin: "0", width: "100%" }}
                  onChange={(e) =>
                    setCustomer_alternatenumber(e.target.value as String)
                  }
                  required
                  onBlur={() => verifyMobile("alternate")}
                  onFocus={() => {
                    setMobileValidation((prev: any) => ({
                      ...prev,
                      customer_alternatenumber: true,
                    }));
                  }}
                  helperText={
                    !mobileValidation.customer_alternatenumber &&
                    "Incorrect alternate Number"
                  }
                />
              </Grid>
              <Grid item md={3}>
                <p>Whatsapp Number</p>
                <TextField
                  name="customer_whatsapppnumber"
                  type="text"
                  value={customer_whatsapppnumber}
                  placeholder="Enter Whatsapp Mobile Number"
                  className="input"
                  variant="outlined"
                  style={{ margin: "0", width: "100%" }}
                  onChange={(e) =>
                    setCustomer_whatsapppnumber(e.target.value as String)
                  }
                  required
                  onBlur={() => verifyMobile("whatsapp")}
                  onFocus={() => {
                    setMobileValidation((prev: any) => ({
                      ...prev,
                      customer_whatsapppnumber: true,
                    }));
                  }}
                  helperText={
                    !mobileValidation.customer_whatsapppnumber &&
                    "Incorrect Whatsapp Number"
                  }
                />
              </Grid>
              <Grid item md={3}>
                <p>Email</p>
                <TextField
                  name="customer_email"
                  type="email"
                  placeholder="Enter Email"
                  value={customer_email}
                  className="input"
                  variant="outlined"
                  style={{ margin: "0", width: "100%" }}
                  onChange={(e) => setCustomer_email(e.target.value as String)}
                  required
                />
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              spacing={3}
              style={{ margin: "1rem auto", background: "#e1f5fe" }}
            >
              <Grid item md={2}>
                <p>Title</p>
                <Select
                  className="input"
                  name="customer_designation"
                  variant="outlined"
                  value={designation || ""}
                  style={{ width: "100%", margin: "0", height: "40px" }}
                  onChange={(e) => titleSelection(e.target.value as string)}
                  required
                >
                  <MenuItem value="Mr">Mr</MenuItem>
                  <MenuItem value="Ms">Ms</MenuItem>

                  {/* <MenuItem value="Baby Boy">Baby Boy</MenuItem>
                  <MenuItem value="Baby Girl">Baby Girl</MenuItem> */}
                </Select>
              </Grid>
              <Grid item md={2}>
                <p>Customer Name</p>
                <TextField
                  name="customer_name"
                  type="text"
                  placeholder="Enter Name"
                  value={customer_name}
                  className="input"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{ width: "100%", margin: "0" }}
                  onChange={(e) =>
                    setCustomer_name(
                      e.target.value.replace(/[^A-Za-z- ']/, "") as String
                    )
                  }
                  required
                />
              </Grid>
              <Grid item md={2}>
                <p>Age</p>
                <TextField
                  name="customer_age"
                  type="number"
                  placeholder="Enter Age"
                  value={customer_age}
                  className="input"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{ width: "100%", margin: "0" }}
                  inputProps={{ min: 0, max: 150 }}
                  onChange={(e) => {
                    let ageValue = parseInt(e.target.value);
                    if (ageValue > 150) ageValue = 150;
                    if (ageValue < 0) ageValue = 0;
                    e.target.value = ageValue.toString();
                    setCustomer_age(e.target.value as any);
                  }}
                  required
                />
              </Grid>
              <Grid item md={2}>
                <p>Gender</p>
                <TextField
                  className="input"
                  name="customer_gender"
                  variant="outlined"
                  value={
                    customer_gender === "male" || customer_gender === "Male"
                      ? "Male"
                      : customer_gender === "female" || customer_gender === "Female"
                        ? "Female"
                        : customer_gender === "other" ? "Male" : ""
                  }
                  style={{
                    width: "100%",
                    margin: "0",
                    padding: "0",
                    height: "40px",
                  }}
                  onChange={(e) => setCustomer_gender(e.target.value as string)}
                  required
                  disabled
                />
              </Grid>
              <Grid item md={2} onMouseEnter={() => setIsHitPackageAPI(true)}>
                <p>Package Name</p>
                <Autocomplete
                  id="package"
                  key={packageKey}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      let packs: any = [];
                      newValue.map((val: any) => {
                        let obj = JSON.parse(JSON.stringify(val, null, " "));
                        packs.push(obj);
                      });
                      handlePackages(packs);
                      setPackKey(1);
                      setCValue("");
                      setCoupon(0);
                      setCouponApplyFlag(false);
                    }
                  }}
                  multiple
                  filterOptions={(options, state) => options}
                  options={packageList && packageList?.results && packageList?.results?.length > 0 ? packageList?.results : []}
                  value={packageDetails}
                  disableClearable
                  limitTags={0}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      <div style={{ display: 'flex' }}>
                        {option?.package_city_prices
                          ? ((option?.highlight?.name && option?.highlight?.name.length > 0) ? <div dangerouslySetInnerHTML={{ __html: `${String(option?.highlight?.name)}, ${option?.package_city_prices?.tat_time}, Rs. ${option?.package_city_prices?.offer_price}/- <br /> ${option?.package_or_test === "test" ? option?.also_known_as ? `Also known as: ${option?.also_known_as}` : option?.alias?.length > 0 ? `Also known as: ${option?.alias[0].name}` : "" : option?.highlight?.['test.name'] ? `Includes: ${option?.highlight?.['test.name'][0]}` : ""}` }}></div> : <div dangerouslySetInnerHTML={{ __html: `${option?.name.toString()}, ${option?.package_city_prices?.tat_time}, Rs. ${option?.package_city_prices?.offer_price} <br /> ${option?.package_or_test === "test" ? option?.also_known_as ? `Also known as: ${option?.also_known_as}` : option?.alias?.length > 0 ? `Also known as: ${option?.alias[0].name}` : "" : option?.highlight?.['test.name'] ? `Includes: ${option?.highlight?.['test.name'][0]}` : ""}` }} />) : (option?.highlight?.name && option?.highlight?.name.length > 0) ? <div dangerouslySetInnerHTML={{ __html: `${String(option?.highlight?.name)}, ${option?.tat_time ? `${option?.tat_time},` : ""} ${"Rs. " + option?.offer_price + "/-" || ""} <br /> ${option?.package_or_test === "test" ? option?.also_known_as ? `Also known as: ${option?.also_known_as}` : option?.alias?.length > 0 ? `Also known as: ${option?.alias[0].name}` : "" : option?.highlight?.['test.name'] ? `Includes: ${option?.highlight?.['test.name'][0]}` : ""}` }}></div> : option?.name.toString() +
                            ", " +
                            option?.tat_time +
                            ", Rs." +
                            option?.offer_price +
                            "/-"}

                        {/* {option?.package_city_prices && !option?.highlight?.name
                        ? 
                        option?.package_city_prices?.tat_time +
                        ", Rs. " +
                        option?.package_city_prices?.offer_price +
                        "/-"
                        :  ""} */}
                      </div>
                    </React.Fragment>
                  )}
                  getOptionDisabled={(option: any) => {
                    const packID = packageDetails.map((pack: any) => pack.id);
                    return packID.includes(option.id);
                  }}
                  onInputChange={(event, newInputValue) => {
                    clearTimeout(timer.current);
                    timer.current = setTimeout(() => {
                      getPackage(`city_id=${cityId}&search=${newInputValue}`);
                      // {
                      //   userDetails?.profile?.pilot_user ? (
                      //     getNewPackage(`city_id=${cityId}&code=${newInputValue}`)
                      //     ) :   getPackage(`city_id=${cityId}&search=${newInputValue}`)
                      // }
                    }, 1000);
                  }}
                  freeSolo
                  disableCloseOnSelect
                  renderTags={(value: string[], getTagProps) =>
                    value.map((option: any, index: number) => (
                      <Chip
                        variant="default"
                        color="primary"
                        label={
                          option.package_city_prices
                            ? option.name.toString() +
                            ", " +
                            option.package_city_prices.tat_time +
                            ", Rs. " +
                            option?.package_city_prices.offer_price +
                            "/-"
                            : option.name.toString() +
                            ", " +
                            option.tat_time +
                            ", Rs." +
                            option?.offer_price +
                            "/-"
                        }
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Package"
                      variant="outlined"
                      style={{ width: "100%", margin: "0", padding: "0" }}
                      className="input"
                      inputProps={{
                        ...params.inputProps,
                        onKeyDown: (e) => {
                          if (e.key === "Enter") {
                            e.stopPropagation();
                          }
                        },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item md={2}>
                <p>Partner</p>
                <TextField
                  name="partner"
                  type="text"
                  value={partner}
                  placeholder="Enter Order Id"
                  className="input"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => setPartner(e.target.value as String)}
                  style={{ width: "100%", margin: "0" }}
                />
              </Grid>
              <Grid item md={2}>
                <p>Passport Number</p>
                <TextField
                  className="input"
                  name="passport"
                  type="text"
                  placeholder="Enter Passport Number"
                  value={passport}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{ width: "100%", margin: "0" }}
                  onChange={(e: any) => setPassport(e.target.value)}
                />
              </Grid>
              <Grid item md={2}>
                <p>Aadhar Number</p>
                <TextField
                  className="input"
                  name="aadhar"
                  type="number"
                  onWheel={(e: any) => e.target.blur()}
                  onInput={(e: any) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 12);
                  }}
                  placeholder="Enter Aadhar Number"
                  value={aadhar}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{ width: "100%", margin: "0" }}
                  onChange={(e: any) => setAadhar(e.target.value)}
                />
              </Grid>
              <Grid item md={3}>
                <p>Booking For</p>
                <Select
                  className="input"
                  name="bookingFor"
                  variant="outlined"
                  value={bookingFor ? bookingFor : ""}
                  style={{ width: "100%", margin: "0", height: "40px" }}
                  required
                  onChange={(e: any) => setBookingFor(e.target.value)}
                >
                  <MenuItem value="self">Self</MenuItem>
                  <MenuItem value="father_in_law">
                    Father/Father in law
                  </MenuItem>
                  <MenuItem value="mother_in_law">
                    Mother/Mother in law
                  </MenuItem>
                  <MenuItem value="son_in_law">Son/Son in law</MenuItem>
                  <MenuItem value="daughter_in_law">
                    Daughter/Daughter in law
                  </MenuItem>
                  <MenuItem value="spouse">Spouse</MenuItem>
                  <MenuItem value="family">Family</MenuItem>
                  <MenuItem value="friend">Friend</MenuItem>
                  <MenuItem value="other">Other</MenuItem>
                </Select>
              </Grid>
              {+customer_age < 5 && customer_age && <p style={{ textAlign: 'center', width: '100%', color: 'red' }}>Age cannot be less than 5 years.</p>}
            </Grid>
            <Grid
              container
              direction="row"
              spacing={3}
              style={{ margin: "1rem auto" }}
            >
              <Grid item md={2}>
                <p style={{ fontWeight: "bold" }}>Phlebo Remarks</p>
                <textarea
                  className="input"
                  name="phlebo_remarks"
                  placeholder="Enter Phlebo Remakrs"
                  value={phleboRemarks}
                  style={{ width: "100%", margin: "0" }}
                  onChange={(e: any) => setPhleboRemarks(e.target.value)}
                />
              </Grid>
              <Grid item md={4}>
                <p style={{ fontWeight: "bold" }}>Phlebos</p>


                <Autocomplete
                  id="booking_source"
                  onChange={(event, newValue) => {
                    if (newValue) {
                      let obj = JSON.parse(
                        JSON.stringify(newValue, null, "")
                      );
                      setPhlebo(obj.id as Number);
                    }
                  }}
                  inputValue={phleboName}
                  onInputChange={(event, newInputValue) => {
                    setPhleboName(newInputValue);
                    clearTimeout(timer.current);
                    timer.current = setTimeout(() => {
                      getPhlebos(`?city_id=${cityId}&code=${newInputValue}`);
                    }, 1000);
                  }}
                  options={
                    phleboList?.results
                      ? [
                        ...phleboList?.results,
                        { id: 0, user: { username: "NoPhlebo" } },
                      ]
                      : []
                  }
                  freeSolo
                  blurOnSelect
                  getOptionLabel={(option: any) =>
                    option?.user && option?.user?.username
                  }
                  disabled={booking?.delivery_status==="delivered"}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Phlebo"
                      variant="outlined"
                      style={{ width: "100%", margin: "0", padding: "0", height: "40px" }}
                      className="input"
                      disabled
                    />
                  )}
                />
              </Grid>
            </Grid>

            { Number(checkedContainerDeliverySlot) === 1 ?
              <Grid style={{ display: "flex" }}>
                <Grid item xs={12} md={3}>
                  <p>Need a Container For Booking</p>
                </Grid>
                <Grid item xs={12} md={3}>
                  <div className="checkList" style={{ display: "flex" }}>
                      {
                          [{id: 1, name: "Yes"}].map((row: any) => (
                            <div style={{ paddingLeft: "20px" }}>
                              <input value={row?.id} type="checkbox" disabled style={{color: (checkedContainerDeliverySlot == row?.id ? "blue" : "gray")}} checked={checkedContainerDeliverySlot == row?.id ? true : false} onChange={(e) => {
                                if (!e.target.checked) {
                                  setCheckedContainerDeliverySlot("");
                                } else {
                                  setCheckedContainerDeliverySlot(e.target.value);
                                }
                              }} />
                              <span style={{ paddingLeft: "10px" }}>
                                <span style={{ color: (checkedContainerDeliverySlot == row?.id ? "blue" : "gray") }}>{row?.name}</span>
                              </span>
                            </div>
                          ))
                      }
                  </div>
                </Grid>
              </Grid>
              : ""
            }

            {/* { Number(checkedContainerDeliverySlot) === 1 ?
              <Grid style={{ display: "flex" }}>
                <Grid item xs={12} md={3}>
                  <p>Container Delivery Date</p>
                </Grid>
                <Grid item xs={12} md={3} style={{ paddingRight: "40px" }}>
                  <TextField
                    name="containerDate"
                    type="date"
                    id="noTyping"
                    value={containerDate}
                    className="input"
                    label="Container Date*"
                    variant="outlined"
                    inputProps={{
                      min: (booking?.delivery_date !== null ? booking?.delivery_date : moment().format("YYYY-MM-DD")),
                      max: moment(new Date().setDate(new Date().getDate() + 2)).format("YYYY-MM-DD"),
                    }}
                    onChange={(e: any) => {
                      setCollection_date("");
                      setContainerSlot("");
                      setContainerObject({
                        start_time: "",
                        end_time: ""
                      })
                      setDateKey(!dateKey);
                      setContainerDate(e.target.value);
                    }}
                    style={{ width: "100%" }}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <p>Container Delivery Slot</p>
                </Grid>
                <Grid item xs={12} md={3} style={{ paddingRight: "40px" }}>
                <Autocomplete
                    id="slot"
                    key={dateKey}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let obj = JSON.parse(
                          JSON.stringify(newValue, null, " ")
                        );
                        setContainerSlot(obj.id);
                        setContainerObject(obj);
                      }
                    }}
                    options={containerSlotsArray || []}
                    freeSolo
                    blurOnSelect
                    aria-required
                    limitTags={1}
                    value={containerObject}
                    disabled={containerDate === "" || bookingListLoader}
                    getOptionLabel={(option: any) =>
                      option?.start_time + (option?.end_time ? "-" : "") + option?.end_time
                    }
                    disableClearable
                    disableCloseOnSelect
                    renderInput={(params) => (
                      <TextField
                        className="input"
                        {...params}
                        label="Collection Slot"
                        variant="outlined"
                        style={{ width: "100%", margin: "0", padding: "0" }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              : ""
            } */}

            <Grid
              container
              spacing={3}
              direction="row"
              justify="center"
              alignItems="center"
              style={{ margin: "1rem auto" }}
            >
              <Grid item xs={12} md={6}>
                <Card
                  className="sampleCollectionTime"
                  style={{ minHeight: "470px", overflow: "auto" }}
                >
                  <p style={{ marginBottom: "1rem" }}>Sample Collection Time</p>
                  <div className={classes.slotContainer}>
                    {booking?.centre_address === null
                      ? (
                        (packagesCode?.length > 0 && packagesCode[0]) === SAMPLE_1H_INTERVAL_3TIMES ?
                        SAMPLE_1H_INTERVAL_3TIMES_SLOTS && SAMPLE_1H_INTERVAL_3TIMES_SLOTS.length > 0 && SAMPLE_1H_INTERVAL_3TIMES_SLOTS.map((slot: any) => {
                          return slot.available_slots > 0 ? (
                            <Card
                              key={slot.id}
                              className={
                                slot.id === selectedSlot
                                  ? classes.selectedSlot
                                  : classes.slot
                              }
                              onClick={() => setSelectedSlot(slot.id as Number)}
                            >
                              <p
                                style={{
                                  textAlign: "center",
                                  color: "black",
                                  fontSize: "14px",
                                }}
                              >
                                {slot.start_time1hr}-{slot.end_time1hr}
                              </p>
                              <p
                                style={{ textAlign: "center", color: "green" }}
                              >
                                {" "}
                                Available slots: {slot.available_slots}
                              </p>
                            </Card>
                          ) : (
                            <Card
                              key={slot.id}
                              className={
                                slot.id === selectedSlot
                                  ? classes.selectedSlot
                                  : classes.slot
                              }
                              onClick={() =>
                                alert("No Available Slot for this Area")
                              }
                            >
                              <p
                                style={{
                                  textAlign: "center",
                                  color: "black",
                                  fontSize: "14px",
                                }}
                              >
                                {slot.start_time1hr}-{slot.end_time1hr}
                              </p>
                              <p
                                style={{ textAlign: "center", color: "green" }}
                              >
                                {" "}
                                Available slots: {slot.available_slots}
                              </p>
                            </Card>
                          );
                        }) : (
                          (packagesCode?.length > 0 && packagesCode[0]) === SAMPLE_1H_INTERVAL_2TIMES ?
                          SAMPLE_1H_INTERVAL_2TIMES_SLOTS && SAMPLE_1H_INTERVAL_2TIMES_SLOTS.length > 0 && SAMPLE_1H_INTERVAL_2TIMES_SLOTS.map((slot: any) => {
                            return slot.available_slots > 0 ? (
                              <Card
                                key={slot.id}
                                className={
                                  slot.id === selectedSlot
                                    ? classes.selectedSlot
                                    : classes.slot
                                }
                                onClick={() => setSelectedSlot(slot.id as Number)}
                              >
                                <p
                                  style={{
                                    textAlign: "center",
                                    color: "black",
                                    fontSize: "14px",
                                  }}
                                >
                                  {slot.start_time1hr}-{slot.end_time1hr}
                                </p>
                                <p
                                  style={{ textAlign: "center", color: "green" }}
                                >
                                  {" "}
                                  Available slots: {slot.available_slots}
                                </p>
                              </Card>
                            ) : (
                              <Card
                                key={slot.id}
                                className={
                                  slot.id === selectedSlot
                                    ? classes.selectedSlot
                                    : classes.slot
                                }
                                onClick={() =>
                                  alert("No Available Slot for this Area")
                                }
                              >
                                <p
                                  style={{
                                    textAlign: "center",
                                    color: "black",
                                    fontSize: "14px",
                                  }}
                                >
                                  {slot.start_time1hr}-{slot.end_time1hr}
                                </p>
                                <p
                                  style={{ textAlign: "center", color: "green" }}
                                >
                                  {" "}
                                  Available slots: {slot.available_slots}
                                </p>
                              </Card>
                            );
                          }) : (
                            (packagesCode?.length > 0 && packagesCode[0]) === SAMPLE_2H_INTERVAL_2TIMES ?
                            SAMPLE_2H_INTERVAL_2TIMES_SLOTS && SAMPLE_2H_INTERVAL_2TIMES_SLOTS.length > 0 && SAMPLE_2H_INTERVAL_2TIMES_SLOTS.map((slot: any) => {
                              return slot.available_slots > 0 ? (
                                <Card
                                  key={slot.id}
                                  className={
                                    slot.id === selectedSlot
                                      ? classes.selectedSlot
                                      : classes.slot
                                  }
                                  onClick={() => setSelectedSlot(slot.id as Number)}
                                >
                                  <p
                                    style={{
                                      textAlign: "center",
                                      color: "black",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {slot.start_time1hr}-{slot.end_time1hr}
                                  </p>
                                  <p
                                    style={{ textAlign: "center", color: "green" }}
                                  >
                                    {" "}
                                    Available slots: {slot.available_slots}
                                  </p>
                                </Card>
                              ) : (
                                <Card
                                  key={slot.id}
                                  className={
                                    slot.id === selectedSlot
                                      ? classes.selectedSlot
                                      : classes.slot
                                  }
                                  onClick={() =>
                                    alert("No Available Slot for this Area")
                                  }
                                >
                                  <p
                                    style={{
                                      textAlign: "center",
                                      color: "black",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {slot.start_time1hr}-{slot.end_time1hr}
                                  </p>
                                  <p
                                    style={{ textAlign: "center", color: "green" }}
                                  >
                                    {" "}
                                    Available slots: {slot.available_slots}
                                  </p>
                                </Card>
                              );
                            }) : (
                              timeSlots && timeSlots.length > 0 && timeSlots.map((slot: any) => {
                                return slot.available_slots > 0 ? (
                                  <Card
                                    key={slot.id}
                                    className={
                                      slot.id === selectedSlot
                                        ? classes.selectedSlot
                                        : classes.slot
                                    }
                                    onClick={() => setSelectedSlot(slot.id as Number)}
                                  >
                                    <p
                                      style={{
                                        textAlign: "center",
                                        color: "black",
                                        fontSize: "14px",
                                      }}
                                    >
                                      {slot.start_time1hr}-{slot.end_time1hr}
                                    </p>
                                    <p
                                      style={{ textAlign: "center", color: "green" }}
                                    >
                                      {" "}
                                      Available slots: {slot.available_slots}
                                    </p>
                                  </Card>
                                ) : (
                                  <Card
                                    key={slot.id}
                                    className={
                                      slot.id === selectedSlot
                                        ? classes.selectedSlot
                                        : classes.slot
                                    }
                                    onClick={() =>
                                      alert("No Available Slot for this Area")
                                    }
                                  >
                                    <p
                                      style={{
                                        textAlign: "center",
                                        color: "black",
                                        fontSize: "14px",
                                      }}
                                    >
                                      {slot.start_time1hr}-{slot.end_time1hr}
                                    </p>
                                    <p
                                      style={{ textAlign: "center", color: "green" }}
                                    >
                                      {" "}
                                      Available slots: {slot.available_slots}
                                    </p>
                                  </Card>
                                );
                              })
                            )
                          )
                        )
                      )
                      : timeslots &&
                      timeslots?.results &&
                      timeslots.results.length > 0 &&
                      timeslots.results.map((slot: any) => {
                        return (
                          <Card
                            key={slot.id}
                            className={
                              slot.id === selectedSlot
                                ? classes.selectedSlot
                                : classes.slot
                            }
                            onClick={() => setSelectedSlot(slot.id as Number)}
                          >
                            <p
                              style={{
                                textAlign: "center",
                                color: "black",
                                fontSize: "14px",
                              }}
                            >
                              {slot.start_time}-{slot.end_time}
                            </p>
                            {/* <p style={{ textAlign: "center", color: "black" }}>{moment(`${slot.start_time}`, ["HH.mm.ss"]).format("hh:mm A")} - {moment(`${slot.end_time}`, ["HH.mm.ss"]).format("hh:mm A")}</p> */}
                          </Card>
                        );
                      })}
                  </div>
                </Card>
              </Grid>
              <Grid item xs={12} md={6} style={{ padding: "1rem" }}>
                <div className="priceDetails" style={{ padding: "1rem" }}>
                  <table style={{ width: "100%", margin: "0" }}>
                    <thead>
                      <tr>
                        <td className="cell">Customer Name</td>
                        <td className="cell">Packages</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="cell">{customer_name}</td>
                        <td className="cell">
                          <ul>
                            {packageDetails &&
                              packageDetails?.length &&
                              packageDetails.map((pack: any, index: any) => {
                                return (
                                  <li key={index}>
                                    {pack.name}
                                    <br />
                                    DESCRIPTION : {pack.description}
                                    <br />
                                    Report time:{" "}
                                    {pack.package_city_prices
                                      ? pack.package_city_prices?.tat_time
                                      : pack.tat_time}
                                    <br />
                                    Smart Report Available:{" "}{pack?.smart_report_available === false ? "No" : "Yes"}
                                    <br />
                                    Price:{" "}
                                    {pack.package_city_prices
                                      ? pack.package_city_prices?.offer_price
                                      : pack.offer_price}
                                  </li>
                                );
                              })}
                          </ul>
                        </td>
                      </tr>
                      {booking?.additional_members &&
                        booking?.additional_members.map(
                          (member: any, index: any) => {
                            return (
                              <tr key={index}>
                                <td className="cell">{member.customer_name}</td>
                                <td className="cell">
                                  <ul>
                                    {member.packages &&
                                      member.packages.map(
                                        (pack: any, index: any) => {
                                          return (
                                            <li key={index}>
                                              {pack.name}
                                              <br />
                                              DESCRIPTION : {pack.description}
                                              <br />
                                              Report time:{" "}
                                              {pack.package_city_prices
                                                ? pack.package_city_prices
                                                  .tat_time
                                                : pack.tat_time}
                                              <br />
                                              Smart Report Available:{" "}{pack?.smart_report_available === false ? "No" : "Yes"}
                                              <br />
                                              Price:{" "}
                                              {pack.package_city_prices
                                                ? pack.package_city_prices
                                                  .offer_price
                                                : pack.offer_price}
                                            </li>
                                          );
                                        }
                                      )}
                                  </ul>
                                </td>
                              </tr>
                            );
                          }
                        )}
                    </tbody>
                  </table>
                </div>
                <div
                  className="priceDetails"
                  style={{ padding: "1rem 1rem 0 1rem" }}
                >
                  <h6 className="totalPrice">
                    Price: ₹ {Math.floor(price)}
                  </h6>
                  <h6 className="totalPrice">
                    RedCash Discount: ₹ {redDisc > 0 ? Math.floor(redDisc) : 0}
                  </h6>
                  {booking?.discounted_price?.coupon_type === "referral" ?
                    <h6 className="totalPrice">
                      Referral Coupon Discount: ₹{" "}{Math.floor(referralCouponDiscount)}
                    </h6> : ""
                  }
                  {booking?.discounted_price?.coupon_type === "redeem points" ?
                    <h6 className="totalPrice">
                      Referral Points Discount: ₹{" "}{booking?.discounted_price?.referral_coupon_discount}
                    </h6> : ""
                  }
                  <h6 className="totalPrice">Discount: ₹ {discount}</h6>
                  <h6 className="totalPrice">VIP Membership Discount: ₹ {(alreadyARedPro && booking?.discounted_price?.subscription_discount_amount !== 0 && redProMembershipDiscount > 0) ? redProMembershipDiscount : 0}</h6>
                  {alreadyARedPro ?
                    <h6 className="totalPrice">VIP Membership Price: ₹ {Math.floor(booking?.discounted_price?.subscription_amount)}</h6> :
                    (checkedWay === "" ? "" : <h6 className="totalPrice">VIP Membership Price: ₹ {Math.floor(proMembershipPrice)}</h6>)
                  }
                  {requestedForHardCopy === "true" || requestedForHardCopy === true ? (
                    <h6 className="totalPrice">Hard Copy Cost: ₹ {hardCopyPrice}</h6>
                  ) : ""}
                  <h6 className="totalPrice">
                    Total Amount: ₹{" "}
                    {booking?.discounted_price?.coupon_type === "redeem points" ?
                      (totalPrice - Math.floor(booking?.discounted_price?.referral_coupon_discount) - Math.floor(redProMembershipDiscount) + proMembershipPrice - Math.floor(redDisc) > 0 ? totalPrice - Math.floor(booking?.discounted_price?.referral_coupon_discount) - Math.floor((alreadyARedPro && booking?.discounted_price?.subscription_discount_amount !== 0 && redProMembershipDiscount > 0) ? redProMembershipDiscount : 0) + proMembershipPrice + hardCopyPrice - Math.floor(redDisc) : 0) :
                      (totalPrice - Math.floor(referralCouponDiscount) - Math.floor(redProMembershipDiscount) + proMembershipPrice - Math.floor(redDisc) > 0 ? totalPrice - Math.floor(referralCouponDiscount) - Math.floor((alreadyARedPro && booking?.discounted_price?.subscription_discount_amount !== 0 && redProMembershipDiscount > 0) ? redProMembershipDiscount : 0) + Math.floor(booking.discounted_price?.subscription_amount) + proMembershipPrice + hardCopyPrice - Math.floor(redDisc) : 0)
                    }
                  </h6>
                  {/* {redcaseCheck <= 0 && booking?.discounted_price?.coupon_type !== "redeem points" ? (
                    <> */}
                  <Grid
                    container
                    spacing={1}
                    direction="row"
                    justify="center"
                    alignItems="center"
                    style={{ margin: "1rem auto" }}
                  >
                    {(alreadyARedPro && booking?.discounted_price?.subscription_amount === 0) ?
                      <div style={{ display: "flex", justifyContent: "center", marginBottom: "16px" }} onMouseEnter={() => setIsHitLeadSubscriptionsDataAPI(true)}>
                        <Button
                          variant="contained"
                          color="secondary"
                          size="small"
                          style={{ fontSize: "16px", backgroundColor: "green", color: "white" }}
                          onClick={() =>
                            setOpenLoyaltyModal(true)
                          }
                          disabled
                        >
                          Already a VIP Member
                        </Button>
                      </div>
                      :
                      <div style={{ display: "flex", justifyContent: "center", marginBottom: "16px" }} onMouseEnter={() => setIsHitLeadSubscriptionsDataAPI(true)}>
                        {checkedWay === "" ?
                          <Button
                            variant="contained"
                            color="secondary"
                            size="small"
                            style={{ fontSize: "16px" }}
                            onClick={() =>
                              setOpenLoyaltyModal(true)
                            }
                            disabled={(Object.keys(newLeadDetails).length === 0 || newLeadDetails?.id === "undefined" || newLeadDetails?.id === undefined) ? true : false}
                          >
                            Add VIP Membership
                          </Button>
                          :
                          <Button
                            variant="contained"
                            color="secondary"
                            size="small"
                            style={{ fontSize: "16px" }}
                            onClick={() =>
                              setOpenLoyaltyModal(true)
                            }
                            disabled={(Object.keys(newLeadDetails).length === 0 || newLeadDetails?.id === "undefined" || newLeadDetails?.id === undefined) ? true : false}
                          >
                            Cancel VIP Membership
                          </Button>
                        }
                      </div>
                    }
                    <p style={{ fontWeight: "bold", color: "white", margin: "0", display: "flex", justifyContent: "center", alignItems: "center" }}>
                      {redCoin?.coins ? redCoinMessage : ""}
                      {redCoin && redCoin?.coins && redCoin?.coins >= 0 &&
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={redCashButton}
                              onChange={handleRedCheck}
                              name="Redeem Redcash"
                              style={{ margin: "0", marginLeft: "10px" }}
                              disabled={redCoin?.coins <= 0}
                            />
                          }
                          label=""
                        />
                      }
                    </p>
                    {(booking?.discounted_price?.coupon_type === "referral" && booking?.discounted_price?.referral_coupon_discount > 0) ?
                      <p>You have already applied Referral Coupon Code.</p> :
                      <>
                        {!redCashButton && booking?.discounted_price?.coupon_type !== "redeem points" ? (
                          <>
                            <Grid container spacing={1} direction="row">
                              <Grid item xs={12}>
                                <p>Manage Coupon</p>
                              </Grid>
                              <Grid item xs={12}>
                                <Select
                                  className="input"
                                  name="cType"
                                  variant="outlined"
                                  value={cType}
                                  style={{ width: "100%", margin: "0" }}
                                  onChange={(e) => {
                                    setCouponApplyFlag(false);
                                    setCValue("");
                                    setCType(e.target.value);
                                  }}
                                >
                                  <MenuItem disabled value={"none"}>
                                    Discount Type
                                  </MenuItem>
                                  <MenuItem value={"code"}>Code</MenuItem>
                                  <MenuItem value={"percentage"}>Percentage</MenuItem>
                                </Select>
                              </Grid>
                              {cType == "percentage" ? (<>

                                <Grid item xs={6}>
                                  <TextField
                                    name="area"
                                    value={cValue}
                                    className="input"
                                    variant="outlined"
                                    label="Percentage"
                                    onChange={(e) => setCValue(e.target.value)}
                                    fullWidth
                                    size="small"
                                    select
                                    disabled={price < 1000}
                                  >
                                    <MenuItem value={""}>none</MenuItem>
                                    {Array.from(
                                      Array(
                                        Number(price) < 2000
                                          ? 5
                                          : Number(price) < 5000
                                            ? 10
                                            : 15
                                      ).keys()
                                    ).map((val: any) => {
                                      return (
                                        <MenuItem value={val + 1}>{val + 1}</MenuItem>
                                      );
                                    })}
                                  </TextField>
                                </Grid>
                                <Grid item xs={4}>
                                  <Button
                                    variant="contained"
                                    fullWidth
                                    color="primary"
                                    onClick={() => applyCouponPerc()}
                                  >
                                    Apply
                                  </Button>
                                </Grid>
                              </>)

                                :
                                <>
                                  <Grid container spacing={1} direction="row" onMouseEnter={() => setIsHitCouponAPI(true)}>
                                    <Grid item xs={12}>
                                      <p>Enter Coupon</p>
                                    </Grid>
                                    <Grid item xs={8}>
                                      <Autocomplete
                                        id="coupon"
                                        key={couponKey}
                                        onChange={(event, newValue) => {
                                          if (newValue) {
                                            let obj = JSON.parse(
                                              JSON.stringify(newValue, null, " ")
                                            );
                                            setCoupon(obj?.id as Number);
                                            setCouponCode(obj?.code as string);
                                            // setCouponType(obj.is_red_cash);
                                          }
                                        }}
                                        inputValue={couponCode}
                                        options={couponList?.results || []}
                                        freeSolo
                                        blurOnSelect
                                        onInputChange={(event, newInputValue) => {
                                          clearTimeout(timer.current);
                                          setCouponCode(newInputValue);
                                          timer.current = setTimeout(() => {
                                            if (event !== null) {
                                              getCoupons(`?code=${newInputValue}&org_type=homedx`);
                                            }
                                          }, 1000);
                                          if (newInputValue === "" && event !== null) {
                                            setCoupon(0);
                                            setDiscount(0);
                                          }
                                        }}
                                        getOptionLabel={(option: any) => option?.code}
                                        getOptionDisabled={(option: any) =>
                                          option?.minimum_price > totalPrice
                                        }
                                        renderInput={(params) => (
                                          <TextField
                                            className="input"
                                            {...params}
                                            placeholder="Enter Coupon"
                                            variant="outlined"
                                            inputProps={{
                                              ...params.inputProps,
                                              onKeyDown: (e) => {
                                                if (e.key === 'Enter') {
                                                  e.stopPropagation();
                                                }
                                              },
                                            }}
                                            style={{
                                              width: "100%",
                                              margin: "0",
                                              padding: "0",
                                            }}
                                          />
                                        )}
                                      />
                                    </Grid>
                                    <Grid item xs={4}>
                                      <Button
                                        variant="contained"
                                        fullWidth
                                        color="primary"
                                        onClick={applyCoupon}
                                      >
                                        Apply
                                      </Button>
                                    </Grid>
                                  </Grid>
                                </>}

                            </Grid>
                          </>
                        ) : <>
                          <Grid
                            container
                            spacing={1}
                            direction="row"
                            justify="center"
                            alignItems="center"
                            style={{ margin: "1rem auto" }}
                          >
                            <Grid container spacing={1} direction="row">
                              <p className="totalPrice" style={{ color: "#ff4569", fontWeight: "15px", alignItems: "center", }}>You Have Already Apply Red Cash/Referral Point/Coupon. So You Can't Apply Other Coupons.</p>
                            </Grid>
                          </Grid>
                        </>
                        }
                      </>
                    }
                  </Grid>

                </div>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={3}
              direction="row"
              justify="center"
              alignItems="center"
              style={{ margin: "10px auto" }}
            >
              <Grid item xs={12}>
                {/* <Grid item xs={12}>
                  <h3
                    style={{
                      borderTop: "dotted 1px #cccc",
                      paddingTop: "10px",
                      fontSize: "24px",
                      fontWeight: "bold",
                    }}
                  >
                    Address
                  </h3>
                </Grid> */}
                <Grid container spacing={3} style={{ margin: "1rem auto" }}>
                  {/* <Grid
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                    container
                    item
                    xs={12}
                    md={6}
                    spacing={3}
                  >
                    <Grid item xs={12} md={5}>
                      <p>{CITY}</p>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <TextField
                        name="city"
                        type="text"
                        value={
                          (booking.customer_areapincode && 
                            booking.customer_areapincode?.city?.display_name) ||
                          ""
                        }
                        disabled
                        className="input"
                        variant="outlined"
                        style={{ margin: "0", width: "100%" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                    container
                    item
                    xs={12}
                    md={6}
                    spacing={3}
                  >
                    <Grid item xs={12} md={5}>
                      <p>{LOCALITY}</p>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <Autocomplete
                        id="colony-area-sector"
                        onChange={(event, newValue) => {
                          if (newValue) {
                            let obj = JSON.parse(
                              JSON.stringify(newValue, null, " ")
                            );
                            setMapAddressObject(obj);
                          }
                        }}
                        options={suggestedAddress ? suggestedAddress : []}
                        freeSolo
                        blurOnSelect
                        aria-required
                        getOptionLabel={(option: any) => `${option?.placeAddress}`}
                        filterOptions={(option: any) => option}
                        onInputChange={(event, newInputValue) => {
                          clearTimeout(timer.current);
                          timer.current = setTimeout(() => {
                            locationAutoCompleteSearch(newInputValue);
                          }, 500);
                        }}
                        disabled
                        renderInput={(params) => (
                          <TextField
                            className="input"
                            {...params}
                            placeholder="Search Address"
                            variant="outlined"
                            style={{ width: "100%", margin: "0", padding: "0" }}
                            required
                          />
                        )}
                        defaultValue={{ "placeAddress": customer_landmark }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                    container
                    item
                    xs={12}
                    md={6}
                    spacing={3}
                  >
                    <Grid item xs={12} md={5}>
                      <p>{ADDRESS_LINE1}</p>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <TextField
                        name="customer_address"
                        type="text"
                        value={customer_address}
                        onChange={(e) => setCustomer_address(e.target.value as string)}
                        className="input"
                        variant="outlined"
                        style={{ margin: "0", width: "100%" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                    container
                    item
                    xs={12}
                    md={6}
                    spacing={3}
                  >
                    <Grid item xs={12} md={5}>
                      <p>{ADDRESS_LINE2}</p>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <TextField
                        name="customer_address"
                        type="text"
                        value={addressLine2}
                        onChange={(e) =>
                          setAddressLine2(e.target.value as string)
                        }
                        className="input"
                        variant="outlined"
                        style={{ margin: "0", width: "100%" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                    container
                    item
                    xs={12}
                    md={6}
                    spacing={3}
                  >
                    <Grid item xs={12} md={5}>
                      <p>{LANDMARK}</p>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <TextField
                        name="customer_address"
                        type="text"
                        value={favoritePlace}
                        onChange={(e) => setFavoritePlace(e.target.value as string)}
                        className="input"
                        variant="outlined"
                        style={{ margin: "0", width: "100%" }}
                      />
                    </Grid>
                  </Grid>
                  
                  
                  {/* <Grid
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                    container
                    item
                    xs={12}
                    md={6}
                    spacing={3}
                  >
                    <Grid item xs={12} md={5}>
                      <p>Area</p>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <TextField
                        name="areaName"
                        type="text"
                        disabled
                        value={
                          (pin_code.results &&
                            pin_code.results.length > 0 &&
                            pin_code.results[0].city) ||
                          ""
                        }
                        className="input"
                        variant="outlined"
                        style={{ margin: "0", width: "100%" }}
                      />
                    </Grid>
                  </Grid> */}
                  {/* <Grid
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                    container
                    item
                    xs={12}
                    md={6}
                    spacing={3}
                  >
                    <Grid item xs={12} md={5}>
                      <p>{PINCODE}</p>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <TextField
                        name="pincode"
                        type="text"
                        disabled
                        value={
                          (booking.customer_areapincode &&
                            booking.customer_areapincode.pincode) ||
                          ""
                        }
                        className="input"
                        variant="outlined"
                        style={{ margin: "0", width: "100%" }}
                      />
                    </Grid>
                  </Grid>  */}
                  <Grid
                    container
                    item
                    xs={12}
                    md={3}
                    spacing={3}
                    style={{ margin: "10px 0", width: "100%", padding: "10px" }}
                  >
                    <Grid item xs={12} style={{ padding: "0" }}>
                      <p>Verification Remark</p>
                    </Grid>
                    <Grid item xs={12} style={{ padding: "0" }}>
                      <TextField
                        name="verification_remark"
                        placeholder="Enter Remark"
                        type="text"
                        value={verification_remark}
                        className="input"
                        variant="outlined"
                        style={{ margin: "0", width: "100%" }}
                        onChange={(e) =>
                          setVerification_remark(e.target.value as string)
                        }
                        // required
                        disabled={booking?.verification_status !== "pending"}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    md={3}
                    spacing={3}
                    style={{ margin: "10px 0", width: "100%", padding: "10px" }}
                  >
                    <Grid item xs={12} style={{ padding: "0" }}>
                      <p>Verification Status</p>
                    </Grid>
                    <Grid item xs={12} style={{ padding: "0" }}>
                      <Select
                        className="input"
                        name="verification_status"
                        variant="outlined"
                        value={verification_status}
                        style={{ width: "100%", margin: "0", padding: "0", height: '40px' }}
                        onChange={(e) =>
                          setVerification_status(e.target.value as string)
                        }
                        // required
                        disabled={booking?.verification_status !== "pending"}
                      >
                        {/* <MenuItem value={"pending"}>Pending</MenuItem> */}
                        <MenuItem value={"confirmed"}>Confirmed</MenuItem>
                        {/* <MenuItem value={"cancelled"}>Cancelled</MenuItem> */}
                      </Select>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    md={3}
                    spacing={3}
                    style={{ margin: "10px 0", width: "100%", padding: "10px" }}
                  >
                    <Grid item xs={12} style={{ padding: "0" }}>
                      <p>Booking Status</p>
                    </Grid>
                    <Grid item xs={12} style={{ padding: "0" }}>
                      <Select
                        className="input"
                        name="verification_status"
                        variant="outlined"
                        value={booking_status || "pending"}
                        style={{ width: "100%", margin: "0", padding: "0", height: "40px" }}
                        onChange={(e) =>
                          setBooking_status(e.target.value as string)
                        }
                      // disabled={booking.booking_status === "rescheduled" || booking.booking_status === "resampling"} ////manisha 28-01-2022
                      >
                        <MenuItem disabled value={booking_status}>
                          {booking_status}
                        </MenuItem>
                      </Select>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    item
                    xs={12}
                    md={3}
                    spacing={3}
                    style={{ margin: "10px 0", width: "100%", padding: "10px" }}
                  >
                    <Grid item xs={12} style={{ padding: "0" }}>
                      <p>Pickup Status</p>
                    </Grid>
                    <Grid item xs={12} style={{ padding: "0" }}>
                      <Select
                        className="input"
                        name="verification_status"
                        variant="outlined"
                        value={pickup_status || "pending"}
                        style={{ width: "100%", margin: "0", padding: "0", height: "40px" }}
                        onChange={(e) =>
                          setPickup_status(e.target.value as string)
                        }
                        required
                      >
                        <MenuItem value={"pending"}>Pending</MenuItem>
                        <MenuItem value={"confirmed"}>Confirmed</MenuItem>
                        {checkAdditionalPickupStatusAndBookingStatus?.length > 0 &&
                          <MenuItem value={"cancelled"}>Cancelled</MenuItem>
                        }
                        <MenuItem value={"hold"}>Hold</MenuItem>
                      </Select>
                      {checkAdditionalPickupStatusAndBookingStatus?.length === 0 &&
                        <p style={{ color: "#ab003c" }}>
                          You Can't Cancel The Main Member Booking
                        </p>
                      }
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    md={3}
                    spacing={3}
                    style={{ margin: "10px 0", width: "100%", padding: "10px" }}
                  >
                    <Grid item xs={12} style={{ padding: "0" }}>
                      <p>Pickup Date</p>
                    </Grid>
                    <Grid item xs={12} style={{ padding: "0" }}>
                      <input
                        className="input"
                        name="collection_date"
                        type="date"
                        value={pickup_date || ""}
                        style={{
                          width: "100%",
                          margin: "0",
                          padding: "18.5px 14px",
                          height: "40px"
                        }}
                        onChange={(e) => setPickup_date(e.target.value)}
                        disabled
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    md={3}
                    spacing={3}
                    style={{ margin: "10px 0", width: "100%", padding: "10px" }}
                  >
                    <Grid item xs={12} style={{ padding: "0" }}>
                      <p>Pickup Time</p>
                    </Grid>
                    <Grid item xs={12} style={{ padding: "0" }}>
                      <input
                        className="input"
                        name="collection_date"
                        type="time"
                        value={pickup_time || ""}
                        style={{
                          width: "100%",
                          margin: "0",
                          padding: "18.5px 14px",
                          height: "40px"
                        }}
                        onChange={(e) => setPickup_time(e.target.value)}
                        disabled
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    md={3}
                    spacing={3}
                    style={{ margin: "10px 0", width: "100%", padding: "10px" }}
                  >
                    <Grid item xs={12} style={{ padding: "0" }}>
                      <p>Pickup Amount</p>
                    </Grid>
                    <Grid item xs={12} style={{ padding: "0" }}>
                      <TextField
                        name="pickup_amount"
                        placeholder="Pickup Amount"
                        type="number"
                        value={pickup_amount}
                        className="input"
                        variant="outlined"
                        disabled
                        style={{ margin: "0", width: "100%" }}
                        required={pickup_status === "confirmed"}
                      // onChange={(e) => setPickup_amount(e.target.value as any)}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    md={3}
                    spacing={3}
                    style={{ margin: "10px 0", width: "100%", padding: "10px" }}
                  >
                    <Grid item xs={12} style={{ padding: "0" }}>
                      <p>Payment Mode</p>
                    </Grid>
                    <Grid item xs={12} style={{ padding: "0" }}>
                      <Select
                        className="input"
                        name="payment_mode"
                        variant="outlined"
                        value={payment_mode || ""}
                        style={{ width: "100%", margin: "0", padding: "0", height: "40px" }}
                        disabled={pickup_status !== "confirmed"}
                        onChange={(e: any) => hanbdlePaymentMode(e)}
                        required={pickup_status === "confirmed"}
                      >
                        <MenuItem disabled value={"none"}>
                          None
                        </MenuItem>
                        {/* <MenuItem disabled value={booking?.payment_mode}>{booking?.payment_mode}</MenuItem> */}
                        <MenuItem value={"cash"}>Cash</MenuItem>
                        {/* <MenuItem value={"online"}>Online Payment</MenuItem> */}
                      </Select>
                    </Grid>
                  </Grid>
                  {/* <Grid container item xs={12} md={3} spacing={3} style={{ margin: "10px 0", width: "100%", padding: "10px" }}>
                                        <Grid item xs={12} style={{ padding: "0" }}>
                                            <p>Payment Source</p>
                                        </Grid>
                                        <Grid item xs={12} style={{ padding: "0" }}>
                                            <TextField
                                                name="payment_source"
                                                placeholder="Payment source"
                                                type="text"
                                                value={payment_source}
                                                className="input"
                                                variant="outlined"
                                                style={{ margin: "0", width: "100%" }}
                                                onChange={(e) => setPayment_source(e.target.value as string)}
                                                required={pickup_status === "confirmed"}
                                            />
                                        </Grid>
                                    </Grid> */}
                  <Grid
                    container
                    item
                    xs={12}
                    md={3}
                    spacing={3}
                    style={{ margin: "10px 0", width: "100%", padding: "10px" }}
                  >
                    <Grid item xs={12} style={{ padding: "0" }}>
                      <p>Phlebo Incentive</p>
                    </Grid>
                    <Grid item xs={12} style={{ padding: "0" }}>
                      <TextField
                        name="phlebo_verification_remark"
                        placeholder="Enter phlebo Incentive"
                        type="number"
                        value={phlebo_incentive}
                        className="input"
                        variant="outlined"
                        style={{ margin: "0", width: "100%" }}
                        onChange={(e) =>
                          setPhlebo_incentive(e.target.value as string)
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    md={3}
                    spacing={3}
                    style={{ margin: "10px 0", width: "100%", padding: "10px" }}
                  >
                    <Grid item xs={12} style={{ padding: "0" }}>
                      <p>Phlebo Convenience</p>
                    </Grid>
                    <Grid item xs={12} style={{ padding: "0" }}>
                      <TextField
                        name="phlebo_verification_remark"
                        placeholder="Enter phlebo Convenience"
                        type="number"
                        value={phlebo_convenience}
                        className="input"
                        variant="outlined"
                        style={{ margin: "0", width: "100%" }}
                        onChange={(e) =>
                          setPhlebo_convenience(e.target.value as string)
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    md={3}
                    spacing={3}
                    style={{ margin: "10px 0", width: "100%", padding: "10px" }}
                  >
                    <Grid item xs={12} style={{ padding: "0" }}>
                      <p>Phlebo Verification Remarks</p>
                    </Grid>
                    <Grid item xs={12} style={{ padding: "0" }}>
                      <Select
                        className="input"
                        name="phlebo_verification_remarks"
                        variant="outlined"
                        value={phlebo_verification_remark || ""}
                        style={{ width: "100%", margin: "0", padding: "0", height: "40px" }}
                        onChange={(e) =>
                          setPhlebo_verification_remark(
                            e.target.value as string
                          )
                        }
                      >
                        <MenuItem value={phlebo_verification_remark}>
                          {phlebo_verification_remark}
                        </MenuItem>
                        <MenuItem value={"Customer out of station"}>
                          Customer Out Of Station
                        </MenuItem>
                        <MenuItem
                          value={"Booking not confirm so customer not ready"}
                        >
                          Booking not Confirm so Customer not ready
                        </MenuItem>
                        <MenuItem value={"Customer not in fasting"}>
                          Customer not in fasting
                        </MenuItem>
                        <MenuItem value={"Phlebo late"}>Phlebo late</MenuItem>
                        <MenuItem value={"Hold Due To Payment"}>
                          Hold Due To Payment
                        </MenuItem>
                        <MenuItem
                          value={"Customer wants to reschedule but not sure"}
                        >
                          Customer Wants to reschedule but not sure
                        </MenuItem>
                        <MenuItem value={"Customer wants to cancel"}>
                          Customer Wants to Cancel
                        </MenuItem>
                        <MenuItem value={"Address not found"}>
                          Address not Found
                        </MenuItem>
                        <MenuItem value={"Customer not responding to call"}>
                          Customer not responding to call
                        </MenuItem>
                      </Select>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    md={3}
                    spacing={3}
                    style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}
                  >
                    <Grid item xs={12} style={{ padding: "0" }}>
                      <p>Route Manager Comments</p>
                    </Grid>
                    <Grid item xs={12} style={{ padding: "0" }}>
                      <Select
                        className="input"
                        name="routeManager"
                        variant="outlined"
                        value={rm_comment}
                        defaultValue={booking?.rm_comment}
                        style={{ width: "100%", margin: "0", padding: "0", height: "40px" }}
                        onChange={(e) => setRMComment(e.target.value as number)}
                      >
                        {RM_COMMENTS.map((item: any) => {
                          return (
                            <MenuItem key={item?.id} value={`${item?.id}`}>
                              {item?.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    md={3}
                    spacing={3}
                    style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}
                  >
                    <Grid item xs={12} style={{ padding: "0" }}>
                      <p>RCA Comments</p>
                    </Grid>
                    <Grid item xs={12} style={{ padding: "0" }}>
                      <Select
                        className="input"
                        name="rca_comment"
                        variant="outlined"
                        value={rca_comment}
                        defaultValue={booking?.rca_comment}
                        style={{ width: "100%", margin: "0", padding: "0", height: "40px" }}
                        onChange={(e) =>
                          setRCAComment(e.target.value as number)
                        }
                      >
                        {RCA_COMMENT?.map((item: any) => {
                          return (
                            <MenuItem key={item?.id} value={`${item?.id}`}>
                              {item?.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    md={3}
                    spacing={3}
                    style={{ margin: "10px 0", width: "100%", padding: "10px" }}
                  >
                    <Grid item xs={12} style={{ padding: "0" }}>
                      <p>Remark</p>
                    </Grid>
                    <Grid item xs={12} style={{ padding: "0" }}>
                      <TextField
                        name="remark"
                        placeholder="Enter Remark"
                        type="text"
                        value={remarks}
                        className="input"
                        variant="outlined"
                        style={{ margin: "0", width: "100%" }}
                        onChange={(e) => setRemarks(e.target.value as string)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <div className={classes.buttonContainer}>
              <Button
                color="primary"
                type="submit"
                variant="contained"
                disabled={
                  loading ||
                  !selectedSlot ||
                  packages.length === 0 ||
                  !mobileValidation.customer_alternatenumber ||
                  !mobileValidation.customer_phonenumber ||
                  !mobileValidation.customer_whatsapppnumber ||
                  +customer_age < 5 ||
                  disableBtn
                }
              >
                Update booking
              </Button>
            </div>
          </form>
        </Paper>
      )}
      <Dialog
        open={openLoyaltyModal}
        // onClose={() => setOpenLoyaltyModal(false)}
        aria-labelledby="form-dialog-title"
        className={classes.dialog}
      >
        <DialogTitle id="form-dialog-title">
          <div
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div>Add VIP Membership</div>
            <div>
              <CloseIcon onClick={() => {
                setCheckedWay("");
                setRedProMembershipDiscountPercentage(0);
                setProMembershipPrice(0);
                setOpenLoyaltyModal(false);
              }} />
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div className="checkList" style={{ display: "flex" }}>
                <div style={{}}>
                  {
                    leadSubscriptionsData?.count > 0 ? (
                      leadSubscriptionsData?.results.map((row: any) => (
                        <div style={{ paddingLeft: "20px" }}>
                          <input value={row?.id} type="checkbox" checked={checkedWay == row?.id ? true : false} onChange={(e) => {
                            if (!e.target.checked) {
                              setCheckedWay("");
                              setRedProMembershipDiscountPercentage(0);
                              setProMembershipPrice(0);
                            } else {
                              setCheckedWay(e.target.value);
                              setRedProMembershipDiscountPercentage(10);
                              setProMembershipPrice(row?.subscription_amount)
                            }
                          }} />
                          <span style={{ paddingLeft: "10px" }}>
                            <span style={{ textDecoration: "line-through", fontSize: "20px" }}>{row?.name?.slice(0, 6)}</span>
                            <span style={{ color: "gray" }}>{row?.name?.slice(6, 17)}</span>
                            <span style={{ fontSize: "20px" }}>{row?.name?.slice(17, 35)}</span>
                          </span>
                        </div>
                      ))
                    ) : (
                      <p style={{ fontSize: "16px", fontWeight: "700", padding: "10px" }}>Oops! As of now no subscriptions available.</p>
                    )
                  }
                </div>
              </div>
            </Grid>
            <DialogContentText id="alert-dialog-slide-description">
              <div style={{ margin: "1rem" }}>
                <div style={{ alignItems: "left", display: "inline-block", marginLeft: "1rem", marginRight: "1rem", marginBottom: "1rem" }}>
                  <span>Benefits:</span>
                  <small>
                    <ul style={{ paddingLeft: "15px" }}>
                      <li>Users will get an additional 10% off on all bookings
                        .</li>
                      <li>No convenience fee for Premium slots.</li>
                      <li>Free report consultation from our doctors on Priority.</li>
                      <li>100% cash back as Red cash on 1st booking and 25% cashback on all subsequent bookings. (Which can be used on all future bookings)</li>
                      <li>24/7 Priority customer support.</li>
                      {/* <li>Free personalised Diet chart - over WhatsApp</li> */}
                    </ul>
                  </small>
                </div>
              </div>
            </DialogContentText>
          </Grid>
        </DialogContent>
        <DialogActions style={{ margin: "0px 10px 10px 0px" }}>
          <Button
            variant="contained"
            style={{
              marginTop: "20px",
            }}
            color="primary"
            onClick={handleAddLoyalty}
            disabled={checkedWay === ""}
          >
            ADD Membership
          </Button>
          <Button
            variant="contained"
            style={{
              marginTop: "20px",
              marginLeft: "10px",
            }}
            color="secondary"
            onClick={handleCanel}
            disabled={checkedWay === ""}
          >
            Cancel Membership
          </Button>
        </DialogActions>
      </Dialog>
    </main>
  );
};

const mapStateToProps = (state: any) => ({
  packageList: state.PhleboAdminReducer.packageList,
  couponList: state.PhleboAdminReducer.couponList,
  booking: state.PhleboAdminReducer.booking,
  booking_slots: state.PhleboAdminReducer.booking_slots,
  loading: state.PhleboAdminReducer.loading,
  // checkGeoFenceStatus: state.PhleboAdminReducer.checkGeoFenceStatus,
  geoFencesMapMyIndia: state.PhleboAdminReducer.geoFencesMapMyIndia,
  mapmyindiaAccessToken: state.PhleboAdminReducer.mapmyindiaAccessToken,
  timeslots: state.PhleboAdminReducer.timeslots,
  userDetails: state.loginReducer.userDetails,
  newLeadDetails: state.PhleboAdminReducer.newLeadDetails,
  phleboList: state.PhleboAdminReducer.phleboList,
  leadSubscriptionsData: state.PhleboAdminReducer.leadSubscriptionsData,
  redCoin: state.PhleboAdminReducer.redCoin,
  containerSlots: state.loginReducer.containerSlots,
  bookingListLoader: state.loginReducer.bookingListLoader,
});

export default connect(mapStateToProps, {
  getPackage,
  getNewPackage,
  updateBooking,
  getCoupons,
  getBookingById,
  getAvailableSlots,
  // checkGeoFenceArea,
  checkGeoFenchMapMyIndia,
  getMapMyIndiaAccessToken,
  getTimeslots,
  SendLocationRequest,
  getNewLeadDetails,
  getPhlebos,
  getLeadSubscriptionsData,
  getRedCoing,
  getContainerSlots,
})(EditBookingForm);