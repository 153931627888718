import React, { useEffect, useState, useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import {
    Paper,
    TextField,
    Grid,
    Switch,
    Select,
    MenuItem,
    FormControlLabel,
    Chip,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import { useHistory, useParams } from "react-router";
import Checkbox from "@mui/material/Checkbox";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import {
    getLeadSource,
    createPanelUser,
    getPanelUserListTeamLead,
    getLanguage,
    getAllUsers,
    getOzontelSkillsData,
    getDialer,
    getOrgTypes,
    getChatSkillsList,
    getCentre,
    updatePanelUser,
    getRiderList,
} from "../actions/LogisticsPanelAction";
import { ADDUSER_EXECUTIVE_TYPES } from "../components/constant";
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 0,
        },
        toolbar: {
            alignItems: "center",
            justifyContent: "flex-end",
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        },
        content: {
            width: "80%",
            flexGrow: 21,
        },
        contentTable: {
            width: "100%",
            padding: "10px 0px 10px 0px",
        },
        paper: {
            padding: "1rem",
            width: "100%",
            borderRadius: "10px",
        },
        head: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "1rem",
            textAlign: "center",
        },
        heading: {
            fontWeight: "normal",
            fontSize: "1.2rem",
            margin: "0",
        },
        table: {
            maxHeight: "400px",
            margin: "auto",
            overflow: "auto",
        },
        image: {
            width: "100px",
            height: "auto",
        },
        imageSM: {
            width: "50px",
            height: "auto",
        },
        imgContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexWrap: "wrap",
        },
    })
);
interface Props {
    loading: any;
    getLeadSource: any;
    lead_source: any;
    createPanelUser: any;
    teamLeadList: any;
    chatSkillsList: any;
    usersList: any;
    language: any;
    orgTypesList: any;
    dialer: any;
    getPanelUserListTeamLead: any;
    getLanguage: any;
    getOzontelSkillsData: any;
    getDialer: any;
    getAllUsers: any;
    getOrgTypes: any;
    getChatSkillsList: any;
    ozontelskilldata: any;
    centreData: any;
    getCentre: any;
    updatePanelUser: any;
    getRiderList: any;
    riderList: any;
}

const BlogsPage: React.FC<Props> = ({
    loading,
    getLeadSource,
    lead_source,
    createPanelUser,
    teamLeadList,
    chatSkillsList,
    usersList,
    language,
    orgTypesList,
    dialer,
    getPanelUserListTeamLead,
    getLanguage,
    getOzontelSkillsData,
    ozontelskilldata,
    getDialer,
    getAllUsers,
    getOrgTypes,
    getChatSkillsList,
    centreData,
    getCentre,
    updatePanelUser,
    getRiderList,
    riderList,
}) => {
    const classes = useStyles();
    const history = useHistory();
    const [name, setName] = useState("");
    const [username, setUserName] = useState("");
    const [employeeId, setEmployeeId] = useState<String>("");
    const [password, setPassword] = useState("");
    const [dialers, setDialers] = useState("");
    const [phoneNumber, setPhoneNumber] = useState<String>("");
    const [email, setEmail] = useState<String>("");
    const [userGroup, setUserGroup] = useState<any>("");
    const [orgType, setOrgType] = useState<any>([]);
    const [reportingLocation, setReportingLocation] = useState<any>("");
    const [userType, setUserType] = useState<any>("");
    const [centre, setCentre] = useState<any>("");
    const [clicktoCall, setClickToCall] = useState<any>("OZONETEL");
    const [centreDef, setCentreDef] = useState<any>("");
    const [ameyoUsername, setAmeyoUsername] = useState<any>("");
    const [ameyoPassword, setAmeyoPassword] = useState<any>();
    const [isactive, setIsActive] = useState<boolean>(false);
    const [teamLead, setTeamLead] = useState<any>([]);
    const [chatskills, setChatSkills] = useState<any>("");
    const [executiveType, setExecutiveType] = useState("");
    const [languages, setLanguages] = useState<any>([]);
    const [defOrgType, setDefOrgType] = useState<any>([]);
    const [ozontelId, setOzontelId] = useState<any>([]);
    const [isEdit, setIsEdit] = useState<any>(false);
    const [assignDef, setAssignDef] = useState<any>([])
    const timer = useRef<any>(0);
    const { id } = useParams<{ id: string }>();
    const [editId, setEditId] = useState<any>("");
    const [key, setKey] = useState<any>(false);
    const blockCharInAutocompleteField = (
        e: React.KeyboardEvent<HTMLDivElement>
    ) => {
        return (
            "~!@#$%^&*()_+{}|:<>?,./;'[]=-0987654321`".includes(e.key) &&
            e.preventDefault()
        );
    };

    const inputAlphaNumeric = (e: any) => {
        const value = (e.target.value) ? e.target.value.replace(/[^ a-zA-Z_ ]+/ig, "") : '';
        if (e.target.value !== value) {
            e.target.value = value;
        }
    }

    const [mobileValidation, setMobileValidation] = useState({
        phoneNumber: true,
    });

    const verifyMobile = (type: any) => {
        if (type === "mobile") {
            if (
                parseInt(phoneNumber && phoneNumber[0]) < 6 ||
                (phoneNumber && phoneNumber.length < 10)
            ) {
                setMobileValidation((prev: any) => ({
                    ...prev,
                    phoneNumber: false,
                }));
            }
        }
    };

    useEffect(() => {
        getLeadSource();
        getPanelUserListTeamLead(`?usergroup=CustomerLead`);
        getLanguage();
        getOzontelSkillsData();
        getDialer();
        getAllUsers(`?page_size=1000`);
        getOrgTypes();
        getChatSkillsList();
        getCentre();
    }, []);

    const handleCreateuser = async (e: any) => {
        e.preventDefault();
        const data: any = {
            username: username,
            email,
            fullname: name,
            phonenumber: phoneNumber,
            password: password,
            usergroup: userGroup,
            ctc_from: clicktoCall,
            dailer: dialers,
            asigned: teamLead,
            usertype: userType,
            org_types: orgType,
            // languages: languages,
            reporting_location: reportingLocation,
            executive_type: executiveType ? executiveType : null,
            ozontel_skills: ozontelId,
            chat_skills: chatskills,
            is_active: isactive,
            empid: employeeId,
            ameyo_user: ameyoUsername,
            // ameyo_password: ameyoPassword,
            center: centre,

        };
        if (password === "" || password === "none") {
            delete data.password;
        }
        if (clicktoCall === "" || clicktoCall === "none") {
            delete data.ctc_from;
        }
        if (dialers === "" || teamLead === "none") {
            delete data.dailer;
        }
        if (teamLead === "" || teamLead === "none") {
            delete data.asigned;
        }
        if (languages === "" || languages === "none") {
            delete data.languages;
        }
        if (executiveType === "" || executiveType === "none") {
            delete data.executive_type;
        }
        if (chatskills === "" || chatskills === "none") {
            delete data.chat_skills;
        }
        if (ozontelId?.length === 0) {
            delete data.ozontel_skills;
        }
        if (ameyoUsername === "" || ameyoUsername === "none") {
            delete data.ameyo_user;
        }
        if (ameyoPassword === "" || ameyoPassword === "none") {
            delete data.ameyo_password;
        }
        if (isEdit) {
            await updatePanelUser(data, username);
        } else {
            await createPanelUser(data);
        }
        history.push("/dashboard/lp/manageManger");
        getRiderList();

    };
    useEffect(() => {
        if (id && id !== 'create') {
            getRiderList(`?id=${id}`)
        }
    }, [])
    useEffect(() => {
        if (riderList?.results && riderList?.results?.length === 1) {
            riderList?.results?.map((list: any) => {
                setEditId(list.id);
                setUserName(list && list?.user?.username);
                setOrgType(list && list?.org_type?.map((val: any) => val?.id));
                setDefOrgType(list?.org_type);
                setUserType(list?.usertype);
                setEmail(list?.user?.email);
                setName(list?.user?.fullname);
                setPhoneNumber(list?.user?.phonenumber);
                setEmployeeId(list?.empid);
                setExecutiveType(list?.type);
                setReportingLocation(list && list?.reporting_location);
                setTeamLead(list?.asigned?.map((list: any) => list?.id));
                setAssignDef(list?.asigned);
                setIsActive(list?.is_active);
                setUserGroup(list?.user?.usergroup);
                setCentre(list?.center?.id || "");
                setCentreDef(list?.center?.name || "");
                setIsEdit(true);

            })
        }
    }, [riderList])
    return (
        <>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <Grid
                    container
                    spacing={1}
                    direction="row"
                    style={{ alignItems: "right" }}
                >
                </Grid>
                <br />
                <Paper elevation={5} className={classes.paper}>
                    <div className={classes.contentTable}>
                        <form onSubmit={handleCreateuser}>
                            <Grid container spacing={4} style={{ margin: "1rem auto" }}>
                                <h4> Login Information -</h4>
                                <Grid container spacing={2} style={{ margin: "1rem auto" }}>
                                    <Grid item xs={12} md={3}>
                                        <h6>
                                            Username <sup>*</sup>{" "}
                                        </h6>
                                        <TextField
                                            fullWidth
                                            className="input"
                                            name="title"
                                            type="text"
                                            placeholder="Enter Username"
                                            value={username}
                                            variant="outlined"
                                            style={{ margin: "0", width: "100%", padding: "1px" }}
                                            onChange={(e) => setUserName(e.target.value)}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <h6>
                                            Email <sup>*</sup>{" "}
                                        </h6>
                                        <TextField
                                            fullWidth
                                            className="input"
                                            name="customer_email"
                                            type="email"
                                            placeholder="Enter Email"
                                            value={email}
                                            variant="outlined"
                                            style={{ margin: "0", width: "100%", padding: "1px" }}
                                            onChange={(e) => setEmail(e.target.value as String)}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <h6>
                                            User Group<sup>*</sup>{" "}
                                        </h6>
                                        <Autocomplete
                                            id="users_list"
                                            onChange={(event, newValue) => {
                                                if (newValue) {
                                                    let obj = JSON.parse(
                                                        JSON.stringify(newValue, null, " ")
                                                    );
                                                    setUserGroup(obj.name);
                                                }
                                            }}
                                            options={usersList?.results || []}
                                            freeSolo
                                            blurOnSelect
                                            aria-required
                                            inputValue={userGroup || ""}
                                            getOptionLabel={(option: any) =>
                                                option?.id && option?.name
                                            }
                                            onInputChange={(event, newInputValue) => {
                                                setUserGroup(newInputValue);
                                                clearTimeout(timer.current);
                                                timer.current = setTimeout(() => {
                                                    getAllUsers(`?name=${newInputValue}`);
                                                }, 1000);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    className="input"
                                                    {...params}
                                                    placeholder="Enter User Group"
                                                    variant="outlined"
                                                    style={{ width: "100%", margin: "0", padding: "1px" }}
                                                    required
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <h6>
                                            Password <sup>*</sup>{" "}
                                        </h6>
                                        <TextField
                                            className="input"
                                            name="title"
                                            type="text"
                                            placeholder="Enter Password"
                                            value={password}
                                            variant="outlined"
                                            style={{ margin: "0", width: "100%", padding: "1px" }}
                                            onChange={(e) => setPassword(e.target.value)}
                                            required={isEdit === false}
                                        />
                                    </Grid>
                                </Grid>
                                <h4>Employee Information -</h4>
                                <Grid container spacing={2} style={{ margin: "1rem auto" }}>
                                    <Grid item xs={12} md={3}>
                                        <h6>
                                            Full Name <sup>*</sup>{" "}
                                        </h6>
                                        <TextField
                                            className="input"
                                            name="title"
                                            type="text"
                                            placeholder="Enter Full Name"
                                            value={name}
                                            variant="outlined"
                                            style={{ margin: "0", width: "100%", padding: "1px" }}
                                            onChange={(e) => setName(e.target.value)}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <h6>Phone Number</h6>
                                        <TextField
                                            className="input"
                                            name="customer_phonenumber"
                                            type="number"
                                            value={phoneNumber}
                                            onInput={(e: any) => {
                                                e.target.value = Math.max(0, parseInt(e.target.value))
                                                    .toString()
                                                    .slice(0, 10);
                                            }}
                                            required
                                            placeholder="Enter Mobile Number"
                                            variant="outlined"
                                            style={{ margin: "0", width: "100%", padding: "1px" }}
                                            onChange={(e) => setPhoneNumber(e.target.value as String)}
                                            disabled={isEdit === true}
                                            onBlur={() => verifyMobile("mobile")}
                                            onFocus={() => {
                                                setMobileValidation((prev: any) => ({
                                                    ...prev,
                                                    phoneNumber: true,
                                                }));
                                            }}
                                            helperText={
                                                !mobileValidation.phoneNumber &&
                                                "Incorrect Phone Number"
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <h6>
                                            Employee Id <sup>*</sup>{" "}
                                        </h6>
                                        <TextField
                                            className="input"
                                            name="employee_id"
                                            type="text"
                                            placeholder="Enter Employee Id"
                                            value={employeeId}
                                            variant="outlined"
                                            style={{ margin: "0", width: "100%", padding: "1px" }}
                                            onChange={(e) => setEmployeeId(e.target.value)}
                                            required
                                        />
                                    </Grid>
                                </Grid>
                                <h4>Panel User Information -</h4>
                                <Grid container spacing={3} style={{ margin: "1rem auto" }}>
                                    <Grid item xs={12} md={3}>
                                        <h6>
                                            User Type <sup>*</sup>{" "}
                                        </h6>
                                        <Select
                                            className="input"
                                            name="usertype"
                                            variant="outlined"
                                            value={userType}
                                            style={{
                                                width: "100%",
                                                margin: "0",
                                                height: "40px",
                                                padding: "1px",
                                            }}
                                            onChange={(e) => setUserType(e.target.value as String)}
                                            required
                                        >
                                            <MenuItem value={"b2c"}>B2C</MenuItem>
                                            <MenuItem value={"b2b"}>B2B</MenuItem>
                                            <MenuItem value={"both"}>BOTH</MenuItem>
                                        </Select>
                                    </Grid>
                                    {isEdit === true ?
                                        <Grid item xs={12} md={3}>
                                            <h6>Org Type<sup>*</sup>{" "}</h6>
                                            <Autocomplete
                                                id="Org_Type"
                                                key={key}
                                                multiple
                                                limitTags={1}
                                                onChange={(event, newValue) => {
                                                    if (newValue) {
                                                        let sourceIds: any = [];
                                                        newValue.map((val: any) => {
                                                            let obj = JSON.parse(JSON.stringify(val, null, " "));
                                                            sourceIds.push(obj.id);
                                                        });
                                                        setOrgType(sourceIds);
                                                    }
                                                }}
                                                defaultValue={defOrgType}
                                                options={orgTypesList?.results || []}
                                                getOptionLabel={(option: any) => {
                                                    return option?.name;
                                                }}
                                                onInputChange={(event, newInputValue) => {
                                                    clearTimeout(timer.current);
                                                    timer.current = setTimeout(() => {
                                                        getOrgTypes(newInputValue);
                                                    }, 1000);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        className="input"
                                                        {...params}
                                                        placeholder="Org Types"
                                                        variant="outlined"
                                                        style={{ width: "100%", margin: "0", padding: "1px" }}
                                                        required={orgType.length > 0 ? false : true}
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            onKeyDown: (e) => {
                                                                if (e.key === "Enter") {
                                                                    e.stopPropagation();
                                                                }
                                                            },
                                                        }}
                                                        onKeyDown={(e) => {
                                                            blockCharInAutocompleteField(e);
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        :
                                        <Grid item xs={12} md={3}>
                                            <h6>
                                                Org Type<sup>*</sup>{" "}
                                            </h6>
                                            <Autocomplete
                                                id="Org_Type"
                                                onChange={(event, newValue) => {
                                                    if (newValue) {
                                                        let obj = JSON.parse(
                                                            JSON.stringify(newValue, null, " ")
                                                        );
                                                        let storeData: any = [];
                                                        obj?.map((list: any) => {
                                                            storeData.push(list?.id);
                                                        });
                                                        setOrgType(storeData);
                                                    }
                                                }}
                                                options={orgTypesList?.results || []}
                                                freeSolo
                                                blurOnSelect
                                                aria-required
                                                limitTags={1}
                                                multiple
                                                getOptionLabel={(option: any) => {
                                                    return option?.name;
                                                }}
                                                getOptionDisabled={(option: any) => {
                                                    return orgType.includes(option?.id);
                                                }}
                                                disableClearable
                                                disableCloseOnSelect
                                                onInputChange={(event, newInputValue) => {
                                                    clearTimeout(timer.current);
                                                    timer.current = setTimeout(() => {
                                                        getOrgTypes(newInputValue);
                                                    }, 1000);
                                                    setDefOrgType(newInputValue);
                                                    if (newInputValue?.length === 0) {
                                                        setOrgType([]);
                                                    }
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        className="input"
                                                        {...params}
                                                        placeholder="Org Types"
                                                        variant="outlined"
                                                        style={{ width: "100%", margin: "0", padding: "1px" }}
                                                        required={orgType.length > 0 ? false : true}
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            onKeyDown: (e) => {
                                                                if (e.key === "Enter") {
                                                                    e.stopPropagation();
                                                                }
                                                            },
                                                        }}
                                                        onKeyDown={(e) => {
                                                            blockCharInAutocompleteField(e);
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    }
                                    {isEdit === true ?
                                        <Grid item xs={12} md={3}>
                                            <h6>Assigned</h6>
                                            <Autocomplete
                                                id="team lead"
                                                key={key}
                                                multiple
                                                limitTags={1}
                                                onChange={(event, newValue) => {
                                                    if (newValue) {
                                                        let sourceIds: any = [];
                                                        newValue.map((val: any) => {
                                                            let obj = JSON.parse(JSON.stringify(val, null, " "));
                                                            sourceIds.push(obj.id);
                                                        });
                                                        setTeamLead(sourceIds);
                                                    }
                                                }}
                                                defaultValue={assignDef}
                                                options={teamLeadList?.results || []}
                                                getOptionLabel={(option: any) =>
                                                    option?.user?.username
                                                }
                                                onInputChange={(event, newInputValue) => {
                                                    clearTimeout(timer.current);
                                                    timer.current = setTimeout(() => {
                                                        getPanelUserListTeamLead(
                                                            `?code=${newInputValue}`
                                                        );
                                                    }, 1000);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        className="input"
                                                        {...params}
                                                        placeholder="Team Lead"
                                                        variant="outlined"
                                                        style={{ width: "100%", margin: "0", padding: "1px" }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        :
                                        <Grid item xs={12} md={3}>
                                            <h6>Assigned</h6>
                                            <Autocomplete
                                                id="team lead"
                                                multiple
                                                limitTags={1}
                                                onChange={(event, newValue) => {
                                                    if (newValue) {
                                                        let sourceIds: any = [];
                                                        newValue.map((val: any) => {
                                                            let obj = JSON.parse(JSON.stringify(val, null, " "));
                                                            sourceIds.push(obj.id);
                                                        });
                                                        setTeamLead(sourceIds);
                                                    }
                                                }}
                                                options={teamLeadList?.results || []}
                                                getOptionLabel={(option: any) =>
                                                    option?.user?.username
                                                }
                                                onInputChange={(event, newInputValue) => {
                                                    clearTimeout(timer.current);
                                                    timer.current = setTimeout(() => {
                                                        getPanelUserListTeamLead(
                                                            `?code=${newInputValue}`
                                                        );
                                                    }, 1000);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        className="input"
                                                        {...params}
                                                        placeholder="Team Lead"
                                                        variant="outlined"
                                                        style={{ width: "100%", margin: "0", padding: "1px" }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    }
                                    <Grid item xs={12} md={3}>
                                        <h6>
                                            Center
                                        </h6>
                                        <Autocomplete
                                            id="center"
                                            onChange={(event, newValue) => {
                                                if (newValue) {
                                                    let obj = JSON.parse(
                                                        JSON.stringify(newValue, null, " ")
                                                    );
                                                    setCentre(obj.id);
                                                }
                                            }}
                                            freeSolo
                                            blurOnSelect
                                            options={centreData?.results || []}
                                            inputValue={centreDef || ""}
                                            disableCloseOnSelect
                                            getOptionLabel={(option: any) =>
                                                (option?.display_name === null
                                                    ? option?.name
                                                    : option?.display_name) +
                                                (option?.center_code
                                                    ? "(" + option?.center_code + ")"
                                                    : "")
                                            }
                                            onInputChange={(event, newInputValue) => {
                                                setCentreDef(newInputValue);
                                                clearTimeout(timer.current);
                                                timer.current = setTimeout(() => {
                                                    getCentre(
                                                        `?optimize=true&center_type=collection_center&name_or_code=${newInputValue}`
                                                    );
                                                }, 1000);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    className="input"
                                                    {...params}
                                                    placeholder="Center"
                                                    variant="outlined"
                                                    style={{ width: "100%", margin: "0", padding: "0" }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <h6>
                                            Reporting Location<sup>*</sup>{" "}
                                        </h6>
                                        <Select
                                            className="input"
                                            name="ReportingLocation"
                                            variant="outlined"
                                            value={reportingLocation}
                                            style={{
                                                width: "100%",
                                                margin: "0",
                                                height: "40px",
                                                padding: "1px",
                                            }}
                                            required
                                            onChange={(e) =>
                                                setReportingLocation(e.target.value as String)
                                            }
                                        >
                                            <MenuItem value={"Gurugram"}>Gurugram</MenuItem>
                                            <MenuItem value={"Noida"}>Noida</MenuItem>
                                            <MenuItem value={"GGN2"}>GGN2</MenuItem>
                                        </Select>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <h6>Executive Type</h6>
                                        <Select
                                            id="ExecutiveTypeFilter"
                                            className="ExecutiveTypes"
                                            variant="outlined"
                                            value={executiveType}
                                            style={{
                                                width: "100%",
                                                minWidth: "150px",
                                                textAlign: "left",
                                                height: "40px",
                                                padding: "1px",
                                            }}
                                            onChange={(e) =>
                                                setExecutiveType(e.target.value as string)
                                            }
                                        >
                                            {ADDUSER_EXECUTIVE_TYPES &&
                                                ADDUSER_EXECUTIVE_TYPES.map((exe_type: any) => {
                                                    return (
                                                        <MenuItem value={exe_type.text}>
                                                            {exe_type.text}
                                                        </MenuItem>
                                                    );
                                                })}
                                        </Select>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <h6>
                                            Click To Call<sup>*</sup>{" "}
                                        </h6>
                                        <Select
                                            className="input"
                                            name="sampleregisterd"
                                            variant="outlined"
                                            value={clicktoCall}
                                            style={{
                                                width: "100%",
                                                margin: "0",
                                                height: "40px",
                                                padding: "1px",
                                            }}
                                            onChange={(e) => setClickToCall(e.target.value as String)}
                                            disabled
                                        >
                                            <MenuItem value={"TATATELE"}>TATA TELE</MenuItem>
                                            <MenuItem value={"TATATELEOFFLINE"}>
                                                TATATELE OFFLINE
                                            </MenuItem>
                                            <MenuItem value={"KNO"}>KNOWLARITY</MenuItem>
                                            <MenuItem value={"OZONETEL"}>OZONETEL</MenuItem>
                                            <MenuItem value={"CZENTRIX"}>C-Zentrix</MenuItem>
                                            <MenuItem value={"AMEYOCALL"}>AMEYOCALL</MenuItem>
                                        </Select>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <h6>Is Active</h6>
                                        <FormControlLabel
                                            control={<Checkbox />}
                                            checked={isactive}
                                            label="IsActive"
                                            onChange={(e) => setIsActive(!isactive)}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "20px", textAlign: "center" }}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        type="submit"
                                        style={{ width: 300 }}

                                    >
                                        {isEdit ? "update" : "Create"}
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                    {/* )} */}
                </Paper>
            </main>
        </>
    );
};

const mapStateToProps = (state: any) => ({
    loading: state.LogisticsReducer.loading,
    lead_source: state.LogisticsReducer.lead_source,
    teamLeadList: state.LogisticsReducer.teamLeadList,
    chatSkillsList: state.LogisticsReducer.chatSkillsList,
    usersList: state.LogisticsReducer.usersList,
    language: state.LogisticsReducer.language,
    orgTypesList: state.LogisticsReducer.orgTypesList,
    dialer: state.LogisticsReducer.dialer,
    ozontelskilldata: state.LogisticsReducer.ozontelskilldata,
    centreData: state.LogisticsReducer.centreData,
    riderList: state.LogisticsReducer.riderList,
});

export default connect(mapStateToProps, {
    getLeadSource,
    createPanelUser,
    getPanelUserListTeamLead,
    getLanguage,
    getOzontelSkillsData,
    getDialer,
    getAllUsers,
    getOrgTypes,
    getChatSkillsList,
    getCentre,
    updatePanelUser,
    getRiderList,
})(BlogsPage);
