import React, { useCallback, useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  Switch,
  Select,
  MenuItem,
  Grid,
  Table,
} from "@material-ui/core";
import Button from '@mui/material/Button';
// import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  DatePicker,
  TimePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import "./index.sass";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { TableFooter, TablePagination } from "@material-ui/core";
import TimeField from "react-simple-timefield";
import Loader from "../components/loader";
import moment from "moment";
import {
  getPhlebos,
  updatePhlebos,
  getPhleboComments,
  getZone,
  getZone1,
  getCities,
} from "../actions/PhleboAdmin";
import { useHistory } from "react-router-dom";
import { MessageRounded } from "@material-ui/icons";
import BookingCommentsModal from "../components/Comments/phlebosComment";
import CreatePhlebo from "../components/CreatePhlebo/createPhlebos";
import EditPhlebo from "../components/CreatePhlebo/editPhlebo";
import EditPhleboLocation from "../components/CreatePhlebo/EditPhleboLocation";
import { genrateManagePhlebos } from '../../helpers/generateUrl'
import { userInfo } from "os";

const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
      textTransform: "uppercase",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 700,
    },
    root: {
      flexGrow: 0,
      //   width: "100%",
      //   marginTop: "20px",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
      //   padding: theme.spacing(3),
    },
    contentHeader: {
      width: "100%",
      padding: "0px 10px 40px 10px",
    },
    leftContentHeader: {
      width: "50%",
      float: "left",
    },
    rightContentHeader: {
      width: "50%",
      float: "right",
      textAlign: "right",
      paddingRight: "20px",
    },
    contentTable: {
      width: "100%",
      padding: "10px 0px 10px 0px",
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },

  })
);

const DefaultSwitch: any = withStyles({
  switchBase: {
    color: "red",
    "&$checked": {
      color: "green",
    },
    "&$checked + $track": {
      backgroundColor: "green",
    },
  },
  checked: {},
  track: {
    backgroundColor: "red",
  },
})(Switch);

interface Props {
  children: any;
  phleboList: any;
  getPhlebos: any;
  getPhleboComments: any;
  updatePhlebos: any;
  loading: boolean;
  getZone: any;
  getZone1: any;
  zoneList: any;
  getCities:any;
  cities:any;
}
const LeadsTable: React.FC<Props> = ({
  getPhlebos,
  phleboList,
  updatePhlebos,
  getPhleboComments,
  getZone,
  getZone1,
  zoneList,
  loading,
  getCities,
  cities
}) => {
  const classes = useStyles();
  const [isActive, setIsActive] = useState(false);
  const [openPhlebo, setOpenPhlebo] = useState(false);
  const [openEditPhlebo, setOpenEditPhlebo] = useState(false);
  const [openEditPhleboLocation, setOpenEditPhleboLocation] = useState(false);
  const [editUser, setEditUser] = useState<any>({});
  const [leadId, setLeadId] = useState<number>(0);
  const [phleboUser, setPhleboUser] = useState<any>([]);
  const [base_city, setBase_City] = useState<any>("");
  const [phlebo, setPhlebo] = useState<any>("");
  const [displayPhlebo, setDisplayPhlebo] = useState<any>("");
  const [page, setPage] = useState(0);
  const timer = useRef<any>(0);
  const [openModal, setopenModal] = useState(false);
  const [editPhleboUser, setEditPhleboUser] = useState([]);
  const [phleboOrRider, setPhleboOrRider] = useState<string>("none");
  const [zoneName, setZoneName] = useState<any>(0);
  const [startTime, setStartTime] = useState(moment().format("hh:mm:ss"));
  const [endTime, setEndTime] = useState<any>(moment().format("hh:mm:ss"));
  const history = useHistory();
  useEffect(() => {
    getPhlebos();
    getZone();
    getCities();
  }, []);

  useEffect(() => {
    setIsActive(editUser.is_active);
  }, [editUser]);

  const handleUser = async () => {
    let body: any = {
      is_active: isActive,
      phlebo_or_rider: phleboOrRider,
      start_time: startTime,
      end_time: endTime,
    };
    await updatePhlebos(body, editUser.id);
    if(phlebo || zoneName !== 0 || base_city !== "") {
      const body: any = {
        phlebo: phlebo,
        zone: zoneName,
        base_city: base_city,
  
      }
      const url = genrateManagePhlebos(body).substring(2);
      // phlebo !== "" ? getPhlebos(`${url}`) : getZone1(`${url}`);
      getPhlebos(`?${url}`)
    } else {
      getPhlebos()
    }

    setEditUser({});
  };

  const getAutoCompleteZone = (val: string) => {
    if (val === undefined) getZone(val);
  };
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url = phleboList?.links && phleboList?.links?.next.split("?")[1];
      getPhlebos(`?${url}`);
    } else if (newPage < page) {
      let url = phleboList?.links && phleboList?.links?.previous.split("?")[1];
      getPhlebos(`?${url}`);
    }
    setPage(newPage as number);
  };

  const filterSampleHandover = (e: any) => {
    const body: any = {
      phlebo: phlebo,
      zone: zoneName,
      base_city: base_city,

    }
    const url = genrateManagePhlebos(body).substring(2);
    // phlebo !== "" ? getPhlebos(`${url}`) : getZone1(`${url}`);
    getPhlebos(`?${url}`)
    // getZone(`${url}`);
    // setPage(0);
  }

  const handleClick = (id: number) => {
    setopenModal(true);
    getPhleboComments(id);
  };
  const handleEditUser = (user: any) => {
    setStartTime(user.start_time)
    setEndTime(user.end_time);
    setEditUser(user);
  };
  const handleEditPhlebo = (user: any) => {
    setOpenEditPhlebo(true);
    setEditPhleboUser(user);
  };

  const handleEditPhleboLocation = (user: any) => {
    setOpenEditPhleboLocation(true);
    setEditPhleboUser(user);
  };

  const handleEndTime = (event: any, value: any) => {
    const newTime2 = value.replace(/-/g, ":");
    const timeSecond2 = newTime2.padEnd(8, endTime.substr(5, 3));
    setEndTime(timeSecond2);
  };
  return (
    <>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <div className={classes.contentHeader}>
          <div className={classes.leftContentHeader}>
            <h2 style={{ fontWeight: "bold" }}>MANAGE PHLEBOS</h2>
          </div>
        </div>
        <div className={classes.contentTable}>
          <Grid container spacing={1} direction="row">
            <Grid item xs={12} sm={4} md={2}>
              <Autocomplete
                id="phleboName"
                className="input"
                onChange={(event, newValue) => {
                  if (newValue) {
                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                    setPhlebo(obj.user.name)
                  }
                }}
                options={phleboList.results ? [...phleboList.results, { id: 0, user: { username: "NoPhlebo" } }] : []}
                freeSolo
                blurOnSelect
                aria-required
                getOptionLabel={(option: any) => option?.user && option?.user?.username}
                inputValue={displayPhlebo}
                onInputChange={(event, newInputValue) => {
                  setDisplayPhlebo(newInputValue)
                  clearTimeout(timer.current)
                  timer.current = setTimeout(() => {
                      getPhlebos(`?search=${newInputValue}`)
                  }, 1000)
              }}
                // disabled={(zoneName !== 0 || base_city !== "") ? true : false}
                renderInput={(params) => (
                  <TextField
                    className="input"
                    {...params}
                    variant="outlined"
                    label="Phlebo Name"
                    style={{ width: "100%", margin: "0", padding: "0" }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={4} md={2}>
              <Autocomplete
                id="zonename"
                onChange={(event, newValue) => {
                  if (newValue) {
                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                    setZoneName(obj?.id);
                  }
                }}
                options={zoneList?.results||[]}
                freeSolo
                blurOnSelect
                aria-required
                getOptionLabel={(option: any) => option?.city?.name}
                onInputChange={(event, newInputValue) => {
                  clearTimeout(timer.current);
                  timer.current = setTimeout(() => {
                    getZone(`${newInputValue}`);
                  }, 1000);
                  if (newInputValue.length === 0) {
                    setZoneName(0);
                  }
                }}
                // disabled={(phlebo !== "" || base_city !== "") ? true : false}
                renderInput={(params) => (
                  <TextField
                    className="input"
                    {...params}
                    label="Zone/City"
                    variant="outlined"
                    style={{ width: "100%", margin: "0", padding: "0" }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <Autocomplete
                id="base_city"
                onChange={(event, newValue) => {
                  if (newValue) {
                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                    setBase_City(obj?.name);
                  }
                }}
                options={cities || []}
                freeSolo
                blurOnSelect
                aria-required
                getOptionLabel={(option: any) => String(option?.name)
                }
                onInputChange={(event, newInputValue) => {
                  // clearTimeout(timer.current);
                  // timer.current = setTimeout(() => {
                  //   getPhlebos(`?base_city=${newInputValue}`);
                  // }, 1000);
                  if (newInputValue?.length === 0) {
                    setBase_City("");
                  }
                }}
                // disabled={(phlebo !== "" || zoneName !== 0) ? true : false}
                renderInput={(params) => (
                  <TextField
                    className="input"
                    {...params}
                    label="Base City"
                    variant="outlined"
                    style={{ width: "100%", margin: "0", padding: "0" }}
                    inputProps={{
                      ...params.inputProps,
                      onKeyDown: (e) => {
                            if (e.key === 'Enter') {
                              e.stopPropagation();
                            }
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                onClick={filterSampleHandover}
                disabled={
                  loading ||
                  (phlebo?.length === 0 && zoneName === 0 && base_city === "")
                }
              >
                Filter
              </Button>
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <Button
                variant="contained"
                color="primary"

                fullWidth
                onClick={() => history.push("/dashboard/pha/users")}
              >
                Reset
              </Button>
            </Grid>

          </Grid>
        </div>
        <div className={classes.contentTable}>
          <CreatePhlebo
            openPhlebo={openPhlebo}
            setOpenPhlebo={setOpenPhlebo}
            getAutoCompleteZone={getAutoCompleteZone}
            zoneList={zoneList}
            getPhlebos={getPhlebos}
          />
          {openEditPhlebo && (
            <EditPhlebo
              openEditPhlebo={openEditPhlebo}
              setOpenEditPhlebo={setOpenEditPhlebo}
              editPhleboUser={editPhleboUser}
              zoneList={zoneList}
              zoneName={zoneName}
              setZoneName={setZoneName}
              getAutoCompleteZone={getAutoCompleteZone}
              getPhlebos={getPhlebos}
            />
          )}
          {openEditPhleboLocation && (
            <EditPhleboLocation
              openEditPhlebo={openEditPhleboLocation}
              setOpenEditPhlebo={setOpenEditPhleboLocation}
              editPhleboUser={editPhleboUser}
              getPhlebos={getPhlebos}
              phlebo={phlebo}
              zoneName={zoneName}
              baseCity={base_city}
            />
          )}
        </div>

        <div
          className="data-table"
          style={{ width: "100%", marginTop: "2rem" }}
        >
          <TableContainer
            className={classes.tableContainer}
            component={Paper}
            style={{ maxHeight: "560px" }}
          >
            {loading ? (
              <Loader />
            ) : (
              <Table stickyHeader aria-label="simple table">
                {phleboList &&
                  phleboList?.results &&
                  phleboList?.results?.length === 0 && (
                    <div style={{ width: "100%", textAlign: "center" }}>
                      <p style={{ fontSize: "20px", fontWeight: "600" }}>
                        No data available!
                      </p>
                    </div>
                  )}
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell align="center">Phlebo_Id</StyledTableCell>
                     <StyledTableCell align="center">OTP</StyledTableCell> 
                    <StyledTableCell align="center">Comment</StyledTableCell>
                    <StyledTableCell align="center">Zone</StyledTableCell>
                    {/* <StyledTableCell align="center">
                      Phlebo_Name
                    </StyledTableCell> */}
                    <StyledTableCell align="center">
                      Phlebo_Username
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Phlebo_Number
                    </StyledTableCell>
                    <StyledTableCell align="center">Start_Time</StyledTableCell>
                    <StyledTableCell align="center">End_Time</StyledTableCell>
                    
                    {/* <StyledTableCell align="center">Source</StyledTableCell> */}
                    <StyledTableCell align="center">Base City</StyledTableCell>
                    <StyledTableCell align="center">Job Type</StyledTableCell>
                    {/* <StyledTableCell align="center">
                      Phlebo_Or_Rider
                    </StyledTableCell> */}
                    <StyledTableCell align="center">Status</StyledTableCell>
                    <StyledTableCell align="center">Action</StyledTableCell>
                    <StyledTableCell align="center">
                      Edit_Phlebo
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Set_Home_Location
                    </StyledTableCell>
                    <StyledTableCell align="center">Location</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {phleboList &&
                    phleboList?.results &&
                    phleboList?.results?.map((user: any) => {
                      return (
                        <StyledTableRow key={user.id}>
                          <StyledTableCell align="center">
                            {user?.id}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {user?.otp}
                          </StyledTableCell> 
                          <TableCell align="center">
                            <div>
                              <MessageRounded
                                onClick={() => handleClick(user.id)}
                              />
                            </div>
                          </TableCell>
                          <StyledTableCell align="center">
                            {user?.zone}
                          </StyledTableCell>
                          {/* <StyledTableCell align="center">
                            {user?.user?.name}
                          </StyledTableCell> */}
                          <StyledTableCell align="center">
                            {user?.user?.username}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {user?.user?.phonenumber}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {editUser.user === user.user ? (
                              <TextField
                                id="time"
                                type="time"
                                value={startTime}
                                onChange={(e) => setStartTime(e.target.value)}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                inputProps={{
                                  step: 300, // 5 min
                                }}
                                sx={{ width: 150 }}
                              />
                            ) : (
                              user?.start_time
                            )}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {editUser.user === user.user ? (
                              <TextField
                                id="time"
                                type="time"
                                value={endTime}
                                onChange={(e) => setEndTime(e.target.value)}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                inputProps={{
                                  step: 300, // 5 min
                                }}
                                sx={{ width: 150 }}
                              />
                            ) : (
                              user?.end_time
                            )}
                          </StyledTableCell>
                          {/* <StyledTableCell align="center">
                            {user?.u_type}
                          </StyledTableCell> */}
                          <StyledTableCell align="center">
                            {user?.base_city&&user?.base_city?.name}
                          </StyledTableCell> 
                          <StyledTableCell align="center">
                            {user.job_type}
                          </StyledTableCell>
                          {/* <StyledTableCell align="center">
                            {editUser.user === user.user ? (
                              <Select
                                className="input"
                                name="booking_status"
                                variant="outlined"
                                value={phleboOrRider}
                                style={{
                                  width: "100%",
                                  margin: "0",
                                  padding: "0",
                                }}
                                onChange={(e) =>
                                  setPhleboOrRider(e.target.value as string)
                                }
                                required
                              >
                                <MenuItem disabled value={"none"}>
                                  Please Select
                                </MenuItem>
                                <MenuItem value={"phlebo"}>Phlebo</MenuItem>
                                <MenuItem value={"rider"}>Rider</MenuItem>
                              </Select>
                            ) : (
                              user.phlebo_or_rider
                            )}
                          </StyledTableCell> */}
                          <StyledTableCell align="center">
                            {editUser.user === user.user ? (
                              <Switch
                                defaultChecked={user?.is_active}
                                checked={isActive}
                                onChange={(e) => setIsActive(e.target.checked)}
                                name="checkedA"
                                color="primary"
                                inputProps={{
                                  "aria-label": "secondary checkbox",
                                }}
                              />
                            ) : (
                              <DefaultSwitch
                                checked={user?.is_active}
                                name="checkedA"
                                inputProps={{
                                  "aria-label": "secondary checkbox",
                                }}
                                disableRipple
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {editUser.user === user.user ? (
                              <Button
                                color="primary"
                                variant="text"
                                onClick={() => {
                                  handleUser()
                                }}
                              >
                                Submit
                              </Button>
                            ) : (
                              <Button
                                color="primary"
                                variant="text"
                                // onClick={() => setEditUser(user)}
                                onClick={() => handleEditUser(user)}
                              >
                                Edit Time
                              </Button>
                            )}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <Button
                              color="primary"
                              variant="text"
                              onClick={() => handleEditPhlebo(user)}
                            >
                              Edit Phlebo
                            </Button>
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {user?.is_active && (
                              <Button
                                color="primary"
                                variant="text"
                                onClick={() => handleEditPhleboLocation(user)}
                              >
                                Set Home Location
                              </Button>
                            )}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {user?.location}
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                </TableBody>
                <TableFooter>
                  <StyledTableRow>
                    <TablePagination
                      rowsPerPageOptions={[]}
                      colSpan={3}
                      count={phleboList?.count || 0}
                      rowsPerPage={phleboList?.page_size}
                      page={page}
                      onPageChange={handleChangePage}
                    />
                  </StyledTableRow>
                </TableFooter>
                <BookingCommentsModal
                  openModal={openModal}
                  setopenModal={setopenModal}
                  leadId={leadId}
                />
              </Table>
            )}
          </TableContainer>
        </div>
      </main>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  phleboList: state.PhleboAdminReducer.phleboList,
  loading: state.PhleboAdminReducer.loading,
  zoneList: state.PhleboAdminReducer.zoneList,
  cities: state.PhleboAdminReducer.cities,
});

export default connect(mapStateToProps, {
  getPhlebos,
  updatePhlebos,
  getPhleboComments,
  getZone,
  getZone1,
  getCities,
})(LeadsTable);