import React, { useState, useEffect, useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Grid,
  TextField,
  withStyles,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@mui/material/Button";
import { connect } from "react-redux";
import MessageIcon from "@material-ui/icons/Message";
import { useHistory } from "react-router";

import { getLogisticTatData } from "../actions/LogisticsPanelAction";
import { genrateTatTableFilter } from "../../helpers/generateUrl";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      marginTop: "2rem",
      width: "100%",
    },
    table: {
      maxWidth: "100%",
    },
    tableHead: {
      background: "rgb(146, 74, 145)!important",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    tableHeadCell: {
      color: "#fff",
      fontWeight: "bold",
      textAlign: "center",
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
      width: "98%",
    },
    input: {
      padding: "1px",
    },
  })
);
const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

interface SampleHandOverProps {
  getLogisticTatData: any;
  logistictatdata: any;
  loading: boolean;
}

const BookingsTable: React.FC<SampleHandOverProps> = ({
  getLogisticTatData,
  logistictatdata,
  loading,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const timer = useRef<any>(0);
  const [bookingId, setBookingId] = useState<any>("");
  const [batchId, setBatchId] = useState<any>("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [customer_name, setCustomerName] = useState<any>("");
  const [lead, setLead] = useState<any>("");
  const [customer_mobile, setCustomerMobile] = useState<any>("");
  const [collection_date_start, setCollection_Date_Start] = useState<any>("");
  const [collection_date_end, setCollection_Date_End] = useState<any>("");
  const [collection_date, setCollectionDate] = useState<any>("");
  const [barcode, setBarcode] = useState<any>("");
  useEffect(() => {
    getLogisticTatData();
  }, []);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url =
        logistictatdata.links && logistictatdata.links.next.split("?")[1];
      getLogisticTatData(url);
    } else if (newPage < page) {
      let url =
        logistictatdata.links && logistictatdata.links.previous.split("?")[1];
      getLogisticTatData(url);
    }
    setPage(newPage as number);
  };

  const filterTatTableData = (e: any) => {
    const body: any = {
      id: bookingId,
      batch_id: batchId,
      mobile_number: customer_mobile,
      customer_name: customer_name,
      collection_date_start: collection_date_start,
      collection_date_end: collection_date_end,
      collection_date: collection_date,
      barcode,
    };
    const url = genrateTatTableFilter(body).substring(2);
    getLogisticTatData(url);
    setPage(0);
  };
  const onKeyDown = (e: any) => {
    if (e.key === "Enter") {
      filterTatTableData(e);
    }
  };
  return (
    <div style={{ width: "99%" }}>
      <div className={classes.toolbar} />
      <h3 style={{ fontWeight: "bold" }}>Booking Tat Report</h3>
      <Grid container spacing={1} direction="row">
        <Grid item xs={12} sm={4} md={2}>
          <TextField
            className="input"
            name="bookingId"
            type="number"
            label="Booking Id"
            value={bookingId}
            variant="outlined"
            onChange={(e) => setBookingId(e.target.value)}
            style={{ width: "100%" }}
            onKeyDown={onKeyDown}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <TextField
            className="input"
            name="batchId"
            type="number"
            label="Batch Id"
            value={batchId}
            variant="outlined"
            onChange={(e) => setBatchId(e.target.value)}
            style={{ width: "100%" }}
            onKeyDown={onKeyDown}
          />
        </Grid>

        <Grid item xs={12} sm={4} md={2}>
          <TextField
            className="input"
            name="barcode"
            type="text"
            label="Barcode"
            value={barcode}
            variant="outlined"
            onChange={(e) => setBarcode(e.target.value)}
            style={{ width: "100%" }}
            onKeyDown={onKeyDown}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <TextField
            className="input"
            name="customer_name"
            type="text"
            label="Patient Name "
            value={customer_name}
            variant="outlined"
            onChange={(e) => setCustomerName(e.target.value)}
            style={{ width: "100%" }}
            onKeyDown={onKeyDown}
          />
        </Grid>
        {/* <Grid item xs={12} sm={4} md={2}>
          <TextField
            className="input"
            name="customer_mobile"
            type="email"
            label="Customer Mobile"
            value={customer_mobile}
            variant="outlined"
            onChange={(e) => setCustomerMobile(e.target.value)}
            style={{ width: "100%" }}
            onKeyDown={onKeyDown}
          />
        </Grid> */}

        <Grid item xs={12} sm={4} md={2}>
          <TextField
            className="input"
            name="collection_date"
            type="date"
            label="Collection  Date"
            value={collection_date}
            variant="outlined"
            onChange={(e) => setCollectionDate(e.target.value as string)}
            style={{ width: "100%" }}
            onKeyDown={onKeyDown}
          />
        </Grid>

        <Grid item xs={12} sm={4} md={2}>
          <TextField
            className="input"
            name="collection_date_start"
            type="date"
            label="Collection Start Date"
            value={collection_date_start}
            variant="outlined"
            onChange={(e) => setCollection_Date_Start(e.target.value as string)}
            style={{ width: "100%" }}
            onKeyDown={onKeyDown}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <TextField
            className="input"
            name="collection_date_end"
            type="date"
            label="Collection End Date"
            value={collection_date_end}
            variant="outlined"
            onChange={(e) => setCollection_Date_End(e.target.value as string)}
            style={{ width: "100%" }}
            onKeyDown={onKeyDown}
          />
        </Grid>

        <Grid item xs={12} sm={4} md={2}>
          <Button
            variant="contained"
            color="secondary"
            disabled={loading}
            fullWidth
            onClick={filterTatTableData}
          >
            Filter
          </Button>
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => history.push("/dashboard/lp/booking-tat-report")}
          >
            Reset
          </Button>
        </Grid>
      </Grid>
      <div style={{ width: "100%" }} className="data-table">
        <TableContainer
          className={classes.tableContainer}
          component={Paper}
          style={{ maxHeight: "550px" }}
        >
          <Table stickyHeader aria-label="simple table">
            <TableHead className={classes.tableHead}>
              <StyledTableRow>
                <StyledTableCell className={classes.tableHeadCell}>
                  Booking Center
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Processing Center
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Patient Name
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Age
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Batch Id
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Booking Id's
                </StyledTableCell>
                {/* <StyledTableCell className={classes.tableHeadCell}>
                Barcode
              </StyledTableCell> */}
                <StyledTableCell className={classes.tableHeadCell}>
                  City
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  State
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Collection Date
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Batch Created At
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Logistic Received At
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Sample Handover At
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Logistic Tat
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  SRA Receive Date Time
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Overall Tat
                </StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {logistictatdata &&
                logistictatdata?.results &&
                logistictatdata.results.map((data: any, index: any) => {
                  return (
                    <StyledTableRow key={index}>
                      <StyledTableCell align="center">
                        {data?.booking_center ? data?.booking_center : "NA"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.processing_center
                          ? data?.processing_center
                          : "NA"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.patient_name}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {Math.trunc(data?.age)}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.batch_id ? data?.batch_id : "NA"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.booking_id}
                      </StyledTableCell>
                      {/* <StyledTableCell align="center">
                      {data?.barcode?data?.barcode:"NA"}
                    </StyledTableCell> */}
                      <StyledTableCell align="center">
                        {data?.city ? data?.city : "NA"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.state ? data?.state : "NA"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.sample_collection_date !== null
                          ? new Date(
                              data?.sample_collection_date
                            ).toLocaleString()
                          : "NA"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.batch_create_date !== null
                          ? new Date(data?.batch_create_date).toLocaleString()
                          : "NA"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.logistic_recieve_date !== null
                          ? new Date(
                              data?.logistic_recieve_date
                            ).toLocaleString()
                          : "NA"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.sample_handover_date !== null
                          ? new Date(
                              data?.sample_handover_date
                            ).toLocaleString()
                          : "NA"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.logistic_tat ? data?.logistic_tat : "NA"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.sra_recieve_date !== null
                          ? new Date(data?.sra_recieve_date).toLocaleString()
                          : "NA"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.tat ? data?.tat : "NA"}
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
            </TableBody>
            <TableFooter>
              <StyledTableRow>
                <TablePagination
                  rowsPerPageOptions={[]}
                  colSpan={3}
                  count={logistictatdata.count || 0}
                  rowsPerPage={logistictatdata.page_size}
                  page={page}
                  onPageChange={handleChangePage}
                  SelectProps={{
                    native: true,
                  }}
                />
              </StyledTableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
      {logistictatdata?.results && logistictatdata?.results?.length === 0 && (
        <h6 style={{ textAlign: "center" }}>No Data Found</h6>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  logistictatdata: state.LogisticsReducer.logistictatdata,
  loading: state.LogisticsReducer.loading,
});
export default connect(mapStateToProps, {
  getLogisticTatData,
})(BookingsTable);
