import React, { useState, useEffect, useRef } from 'react'
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { AppBar, Card, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Toolbar } from "@material-ui/core";
import { connect } from "react-redux";
import { Paper, Grid, Slider, withStyles } from "@material-ui/core";
import { GridCloseIcon } from "@material-ui/data-grid";
import { useHistory } from "react-router";
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Button from "@material-ui/core/Button";
import NotificationsIcon from '@material-ui/icons/Notifications';
import Avatar from '@material-ui/core/Avatar';
import InputBase from '@material-ui/core/InputBase';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import SendIcon from '@material-ui/icons/Send';
import AirportShuttleIcon from '@material-ui/icons/AirportShuttle';
import LaptopMacIcon from '@material-ui/icons/LaptopMac';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import RestoreIcon from '@material-ui/icons/Restore';
import AddBoxIcon from '@material-ui/icons/AddBox';
import EditIcon from '@material-ui/icons/Edit';
import { getBookingJourney, getBookingById } from  "../../actions/PhleboAdmin"
// import Loader from '../../Components/loader/index'
import Loader from '../../components/loader/index'
// import "./index.sass";
let width = window.innerWidth;
let isMobile = width <= 500;
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 0,
        },
        toolbar: {
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        },
        content: {
            width: "80%",
            flexGrow: 21,
        },
        paper1: {
            marginTop: "-84px",
            marginLeft: "670px"

        },
        paper: {
            padding: "1rem",
            width: "100%",
            borderRadius: "10px",
        },
        rightScrollChat: {
            width: "100 %",
            padding: "1rem",
            height: "80vh",
            borderRadius: "10px",
            border: "1px solid",
            overflow: "scroll",
            flexDirection: "column",
            display: "flex",
            justifyContent: "space-between"
        },
        timeline: {
            align: "left",
            flex: "auto"
        },
        head: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "1rem",
            textAlign: "center"
        },
        heading: {
            fontWeight: "normal",
            fontSize: "1.2rem",
            margin: "0"
        },
        table: {
            maxHeight: "400px",
            margin: "auto",
            overflow: "auto"
        },
        appBar: {
            position: "relative",
        },
        image: {
            width: "100px",
            height: "auto",
        },
        imageSM: {
            width: "50px",
            height: "auto",
        },
        title: {
            marginLeft: theme.spacing(2),
            flex: 1,
        },
        imgContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexWrap: "wrap"
        }
    })
);

type ModalProps = {
    openModal: boolean;
    setOpenModal: Function;
    bookingJourney: any;
    booking: any;
};

const TimeLineCommentModal: React.FC<ModalProps> = ({
    openModal,
    setOpenModal,
    bookingJourney,
    booking,

}) => {
    const classes = useStyles();
    const [comment, setComment] = React.useState<string>("");
    const [packageImage, setPackageImage] = useState<any>("")
    const [message, setMessage] = useState<any>("")
    const handleClose = () => {
        setOpenModal(false);
    };
   
    return (
        <div>
            {openModal?(
                <Dialog fullScreen open={openModal} onClose={handleClose}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <GridCloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            TIMELINE VIEW
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Grid container className={classes.root} spacing={2} style={{ marginTop: "5rem" }}>
                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            <Grid container >
                                <Grid item xs={10}>
                                    <Typography variant="h6">Connected</Typography>
                                    <Typography>Redcliffe Life Diagnostics</Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <IconButton aria-label="NotificationsIcon">
                                        <NotificationsIcon />
                                    </IconButton>
                                </Grid>
                                <Grid item xs={1}>
                                    <Avatar>H</Avatar>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={8}>
                        <Paper className={classes.paper}>
                            <Grid container>
                                <Grid item xs={4}>
                                    <Typography variant="h5">{booking?.customer_name}</Typography>
                                    <Typography>Age:{booking?.customer_age} </Typography>
                                    <Typography>Gender: {booking?.customer_gender}</Typography>
                                    <Typography>Contact: {booking?.customer_phonenumber}</Typography>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography>Address:</Typography>
                                    <Typography> {booking?.customer_address}</Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant="h6">Current status</Typography>
                                    <Typography>{booking?.phlebo === null ? "Phlebo Not Assign" : `Phlebo: ${booking?.phlebo?.name} Assign`}</Typography>
                                    <Typography>Time Slot</Typography>
                                    <Typography>
                                        {booking?.collection_slot?.slot.split("-")[0]}
                                        {" - "}
                                        {booking?.collection_slot?.slot.split("-")[1]}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={4}>
                        <Paper className={classes.paper}>
                            Payment Information
                            <Grid container>
                                <Grid item xs={10}>
                                    <Typography variant="h4">{booking?.final_packages_price}</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography>Finance Status:</Typography>
                                    <Typography>{booking?.finance_status}</Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={8}>
                        <Paper className={classes.rightScrollChat} >
                            <h5>Timeline</h5>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Timeline align="alternate">
                                        {bookingJourney&&bookingJourney.loc_data&&bookingJourney.loc_data.length>0&&bookingJourney.loc_data.map((data:any,index:any)=>{
                                            return(
                                                 <TimelineItem>
                                            <TimelineOppositeContent >
                                                <Typography >
                                                Created At
                                                <br/>
                                                {data.created_at.substring(11, 19)}{" "}
                                                </Typography>
                                            </TimelineOppositeContent>
                                            <TimelineSeparator>
                                                <TimelineDot>
                                                    <AirportShuttleIcon />
                                                </TimelineDot>
                                                <TimelineConnector />
                                            </TimelineSeparator>
                                            <TimelineContent>
                                                <Paper elevation={3} className={classes.paper}>
                                                    <Typography variant="h6" component="h1">
                                                        <span>
                                                        Current Status:{data?.current_status}
                                                        </span>
                                                    </Typography>
                                                    {/* <Typography>Booking Accepted by Outsource (Noida)</Typography> */}
                                                </Paper>
                                            </TimelineContent>
                                        </TimelineItem>
                                            )
                                        })}
                                    </Timeline>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={4}>
                        <Paper className={classes.paper}>
                            <h3>Test Details</h3>
                            <Grid container>
                                <Grid item xs={10}>
                                    Test Name
                                </Grid>
                                <Grid item xs={2}>
                                    Price
                                </Grid>
                            </Grid>
                            {booking && booking?.packages && booking.packages.length > 0 && booking?.packages.map((pack:any,index:any)=>{
                                return(
                                     <Grid container key={index}>
                                <Grid item xs={10}>
                                    {pack.name}
                                </Grid>
                                <Grid item xs={2}>
                                    {pack.package_price}
                                </Grid>
                            </Grid>
                                )
                            })}
                        </Paper>
                    </Grid>
                    <Grid item xs={8}>
                        <Paper className={classes.paper}>
                            <Grid item xs={12} spacing={3} style={{ height: "260px" }}>
                                <Paper className={classes.paper}>Raise A Query</Paper>
                            </Grid>
                            <Grid item xs={11} spacing={3}>
                                <InputBase
                                    className={classes.paper}
                                    placeholder="Type Message...."
                                    value={message}
                                    inputProps={{ 'aria-label': 'search google maps' }}
                                    onChange={(e: any) => setMessage(e.target.value as any)}
                                />
                                <IconButton color="primary" className={classes.paper1} aria-label="directions">

                                    <SendIcon onClick={() => ""} />
                                </IconButton>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Dialog>
            ):""}
            
        </div>
    );
};

const mapStateToProps = (state: any) => ({

});

export default connect(mapStateToProps, {

})(TimeLineCommentModal);
