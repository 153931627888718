import React, { useState, useEffect, useRef } from "react";
import {
  makeStyles,
  Theme,
  createStyles,
  withStyles,
} from "@material-ui/core/styles";
import {
  Card,
  Grid,
  MenuItem,
  Paper,
  Switch,
  Select,
  TextField,
  FormControlLabel,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Loader from "../Components/loader/index"
import {
  updatePackage,
  getPackageManagement,
} from "../actions/EditPackageMasterAction";
import { connect } from "react-redux";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import moment from "moment";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useHistory } from "react-router";
import { Chip } from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { Description } from "@material-ui/icons";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
    },
    contentHeader: {
      width: "100%",
      padding: "0px 10px 10px 10px",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      padding: "20px 20px 20px 20px",
      marginTop: "20px",
      width: "100%",
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    head: {
      textAlign: "center",
      fontWeight: "normal",
      marginBottom: "2rem",
    },
    slotContainer: {
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "center",
    },
    slot: {
      width: "150px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      margin: "0.5rem",
    },
    selectedSlot: {
      width: "150px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      background: "linear-gradient(60deg, cyan, #00dfff, cyan)",
      cursor: "pointer",
      margin: "0.5rem",
    },
    chips: {
      display: "flex",
    },
    chip: {
      margin: 2,
      background: "linear-gradient(60deg, cyan, #00cfff, cyan)",
      maxWidth: "130px",
    },
  })
);

const DefaultSwitch: any = withStyles({
  switchBase: {
    color: "red",
    "&$checked": {
      color: "green",
    },
    "&$checked + $track": {
      backgroundColor: "green",
    },
  },
  checked: {},
  track: {
    backgroundColor: "red",
  },
})(Switch);
interface Props {
  match: any;
  booking: any;
  updatePackage: any;
  loading: any;
  getPackageManagement: any;
  packageManagement: any;

}

const PMEditPackageManagement: React.FC<Props> = ({
  match,
  booking,
  updatePackage,
  loading,
  getPackageManagement,
  packageManagement,

}) => {
  const classes = useStyles();
  const history = useHistory();
  const [name, setName] = useState<string>("");
  const [slug, setSlug] = useState<string>("");
  const [code, setCode] = useState<any>("");
  const [packagePrice, setPackagePrice] = useState<string>("");
  const [details, setDetails] = useState<string>("");
  const [faq, setFaq] = useState<string>("");
  const [metatitle, setMetatitle] = useState<string>("");
  const [metakeyword, setMetakeyword] = useState<string>("");
  const [metadescription, setMetadescription] = useState<string>("");
  const [twittercard, setTwittercard] = useState<string>("");
  const [ogtag, setOgtag] = useState<string>("");
  const [textcriteria, setTextcriteria] = useState<string>("");
  const [scratchcode, setScratchcode] = useState<string>("");
  const [qna, setQna] = useState<string>("");
  const [content, setContent] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [offerPrice, setOfferPrice] = useState<string>("");
  const [addonPrice, setAddonPrice] = useState<string>("");
  const [parameter, setParameter] = useState<string>("");
  const [fasting, setFasting] = useState<string>("");
  const [age, setAge] = useState<string>("");
  const [tags, setTags] = useState<string>("");
  const [orgType, setOrgType] = useState<string>("");
  const [gender, setGender] = useState<string>("");
  const [plateformType, setPlateform] = useState<string>("");
  const [isaddon, setIsAddon] = useState<boolean>(true);
  const [isactive, setIsActive] = useState<boolean>(false);
  const [websitedisplay, setWebsiteDisplay] = useState<boolean>(false);
  const bookingId = match.params.id;
  const timer = useRef<any>(0);

  useEffect(() => {
    if (packageManagement?.results) {
      packageManagement?.results?.length > 0 &&
        packageManagement?.results?.map((list: any) => {
          setName(list?.name);
          setSlug(list?.slug);
          setCode(list?.code);
          setDescription(list?.description)
          setPackagePrice(list?.package_price);
          setDetails(list?.details);
          setFaq(list?.faq);
          setMetatitle(list?.meta_title);
          setMetakeyword(list?.meta_keyword);
          setMetadescription(list?.meta_description);
          setScratchcode(list?.scratch_code);
          setQna(list?.qna);
          setContent(list?.content);
          setTwittercard(list?.twitter_card);
          setOgtag(list?.og_tag);
          setTextcriteria(list?.text_criteria);
          setOfferPrice(list?.offer_price);
          setAddonPrice(list?.addon_price);
          setParameter(list?.parameter);
          setFasting(list?.fasting_time);
          setOrgType(list?.org_type);
          setGender(list?.recommended_gender);
          setTags(list?.tags);
          setAge(list?.recommended_age);
          setPlateform(list?.ptype);
          setIsAddon(list?.is_addon);
          setIsActive(list?.is_active);
          setWebsiteDisplay(list?.website_display);

        });
    }
  }, [packageManagement]);

  const submitForm = async (e: any) => {
    e.preventDefault();
    const data: any = {
      name: name,
      code: code,
      package_price: packagePrice,
      details: details,
      faq: faq,
      metatitle: metatitle,
      metadescription: metadescription,
      metakeyword: metakeyword,
      scratchcode: scratchcode,
      qna: qna,
      content: content,
      twittercard: twittercard,
      ogtag: ogtag,
      textcriteria: textcriteria,
      slug: slug,
      description: description,
      offer_price: offerPrice,
      addon_price: addonPrice,
      parameter: parameter,
      fasting_time: fasting,
      website_display: websitedisplay,
      ptype: plateformType,
      recommended_gender: gender,
      org_type: orgType,
      is_addon: isaddon,
      is_active: isactive,
      tags: tags,
      age: age,
    };

    await updatePackage(slug, data);
    getPackageManagement();
    history.push(`/dashboard/epm/packagemanagement`);
  };



  useEffect(() => {
    getPackageManagement(`?package_id=${bookingId}`);
  }, [bookingId]);

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <div className={classes.contentHeader}>
        <h2>Edit Package Management</h2>
      </div>
      <Paper className={classes.paper} elevation={15}>
      {loading ? (
              <Loader />
            ) : (
        <form onSubmit={submitForm}>
          <Grid
            container
            spacing={2}
            direction="row"
            justify="center"
            alignItems="center"
            style={{ margin: "10px auto" }}
          >
            <Grid container spacing={2} style={{ margin: "1rem auto" }}>
              <Grid item xs={12} sm={5} md={3}>
                <TextField
                  className="input"
                  label="Package Name"
                  name="name"
                  type="text"
                  value={name}
                  variant="outlined"
                  onChange={(e) => setName(e.target.value as string)}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} sm={5} md={3}>
                <TextField
                  className="input"
                  label="Slug"
                  name="slug"
                  type="text"
                  value={slug}
                  variant="outlined"
                  onChange={(e) => setSlug(e.target.value as string)}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} sm={5} md={3}>
                <TextField
                  className="input"
                  name="name"
                  type="text"
                  label="Package Code"
                  value={code}
                  variant="outlined"
                  onChange={(e) => setCode(e.target.value as string)}
                  style={{ width: "100%" }}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={5} md={3}>
                <TextField
                  className="input"
                  name="name"
                  type="text"
                  label="Parameters"
                  value={parameter}
                  variant="outlined"
                  onChange={(e) => setParameter(e.target.value as string)}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} sm={5} md={3}>
                <TextField
                  className="input"
                  name="name"
                  label="Fasting Time"
                  type="text"
                  value={fasting}
                  variant="outlined"
                  onChange={(e) => setFasting(e.target.value as string)}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} sm={5} md={3}>
                <TextField
                  className="input"
                  name="name"
                  type="text"
                  label="Age Requirement"
                  value={age}
                  variant="outlined"
                  onChange={(e) => setAge(e.target.value as string)}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} sm={5} md={3}>
                <TextField
                  className="input"
                  name="status"
                  select
                  variant="outlined"
                  label="Recommended Gender"
                  value={gender}
                  style={{ width: "100%" }}
                  onChange={(e) => setGender(e.target.value as string)}
                >
                  <MenuItem value={"male"}>Male</MenuItem>
                  <MenuItem value={"female"}>Female</MenuItem>
                  <MenuItem value={"both"}>Both</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} sm={5} md={3}>
                <TextField
                  className="input"
                  name="scratchcode"
                  label="Scratch Code"
                  type="text"
                  value={scratchcode}
                  variant="outlined"
                  onChange={(e) => setScratchcode(e.target.value as string)}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} sm={5} md={3}>
                <label>Description: </label>
                <textarea
                  style={{ marginTop: "15px", width: "100%" }}
                  name="description"
                  placeholder="description "
                  rows={4}
                  cols={50}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={5} md={3}>
                <label>Details: </label>
                <textarea
                  style={{ marginTop: "15px", width: "100%" }}
                  name="details"
                  placeholder="Details "
                  rows={4}
                  cols={50}
                  value={details}
                  onChange={(e) => setDetails(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={5} md={3}>
                <label>Faq : </label>
                <textarea
                  style={{ marginTop: "15px", width: "100%" }}
                  name="faq"
                  placeholder="faq "
                  rows={4}
                  cols={50}
                  value={faq}
                  onChange={(e) => setFaq(e.target.value)}
                />
              </Grid>

              <Grid item xs={12} sm={5} md={3}>
                <label>Meta Title : </label>
                <textarea
                  style={{ marginTop: "15px", width: "100%" }}
                  name="metatitle"
                  placeholder="Meta Title"
                  rows={4}
                  cols={50}
                  value={metatitle}
                  onChange={(e) => setMetatitle(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={5} md={3}>
                <label>Meta Keyword : </label>
                <textarea
                  style={{ marginTop: "15px", width: "100%" }}
                  name="metakeyword"
                  placeholder="Meta Keyword"
                  rows={4}
                  cols={50}

                  value={metakeyword}
                  onChange={(e) => setMetakeyword(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={5} md={3}>
                <label>Meta Description : </label>
                <textarea
                  style={{ marginTop: "15px", width: "100%" }}
                  name="metadescription"
                  placeholder="Meta Description"
                  rows={4}
                  cols={50}
                  value={metadescription}
                  onChange={(e) => setMetadescription(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={5} md={3}>
                <label>Twittercar : </label>
                <textarea
                  style={{ marginTop: "15px", width: "100%" }}
                  name="twittercard"
                  placeholder="twittercard"
                  rows={4}
                  cols={50}
                  value={twittercard}
                  onChange={(e) => setTwittercard(e.target.value)}
                />
              </Grid>

              <Grid item xs={12} sm={5} md={3}>
                <label>Og tag : </label>
                <textarea
                  style={{ marginTop: "15px", width: "100%" }}
                  name="ogtag"
                  placeholder="ogtag"
                  rows={4}
                  cols={50}
                  value={ogtag}
                  onChange={(e) => setOgtag(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={5} md={3}>
                <label>Text Criteria : </label>
                <textarea
                  style={{ marginTop: "15px", width: "100%" }}
                  name="textcriteria"
                  placeholder="Text Criteria"
                  rows={4}
                  cols={50}
                  value={textcriteria}
                  onChange={(e) => setTextcriteria(e.target.value)}
                />
              </Grid>

              <Grid item xs={12} sm={5} md={3}>

                <label>Qna: </label>
                <textarea
                  style={{ marginTop: "15px", width: "100%" }}
                  name="qna"
                  placeholder="QnA"
                  rows={4}
                  cols={50}
                  value={qna}
                  onChange={(e) => setQna(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={5} md={3}>
                <label>Content: </label>
                <textarea
                  style={{ marginTop: "15px", width: "100%" }}
                  name="content"
                  placeholder="Content"
                  rows={4}
                  cols={50}
                  value={content}
                  onChange={(e) => setContent(e.target.value)}
                />

              </Grid>
            </Grid>
          </Grid>
          <div className={classes.buttonContainer}>
            <Button color="primary" type="submit" variant="contained">
              Update Package
            </Button>
          </div>
        </form>
         )}
      </Paper>
      
    </main>
  );
};
const mapStateToProps = (state: any) => ({
  packageManagement: state.EditPackageMasterReducer.packageManagement,
  updatePackage: state.EditPackageMasterReducer.updatePackage,
  booking: state.EditPackageMasterReducer.booking,
  loading: state.EditPackageMasterReducer.loading,
});

export default connect(mapStateToProps, {
  getPackageManagement,
  updatePackage,

})(PMEditPackageManagement);
