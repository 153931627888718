import {
  ACTIONS,
  PhleboAdminTypes,
} from "../interfaces/actionTypes/RouteManagerInterface";

type InitialState = {
  pin_code: Array<any>;
  cities: Array<any>;
  collectionSlot: Array<any>;
  areaList: Array<any>;
  agentsList: Array<any>;
  phleboList: Array<any>;
  mapBooking: Array<any>;
  geoFencesMapMyIndia: Array<any>;
  packageList: Array<any>;
  areaComments: Array<any>;
  partnerList: Array<any>;
  couponList: Array<any>;
  createBooking: Object;
  additional_booking: Object;
  booking: Object;
  todays_positive_calls: Object;
  lead_details: Object;
  phleboCurrentLocation: Array<any>;
  leadById: Object;
  leads: Array<any>;
  bookingList: Array<any>;
  bookingList1: Array<any>;
  bookingList2: Array<any>;
  bookingList3: Array<any>;
  bookingList4: Array<any>;
  bookingList5: Array<any>;
  comment: any;
  userDetails: Array<any>;
  data: any;
  users: any;
  bookingsComment: any;
  phleboComment: any;
  zoneComment: any;
  phlebosComment: any;
  lead_id: number;
  read_call_details: object;
  all_recordings: object;
  whatsappComments: object;
  mapmyindiaAccessToken: Object;
  whatsappCommentsByLead: object;
  callStats: object;
  complaint: object;
  complaints: object;
  lead_source: any;
  booking_slots: object;
  search_results: object;
  call_status: object;
  all_lead: object;
  call_transfer: object;
  user_panel: object;
  ticketComments: Array<any>
  agentBYUserGroup: object;
  allBookings: object;
  loading: boolean;
  unassignedLoading: boolean;
  pickupHoldLoading: boolean;
  phleboLateLoading: boolean;
  phleboVeryLateLoading: boolean;
  phleboNotStartedLoading: boolean;
  checkGeoFenceStatus: boolean;
  zoneList: Object;
  zone: Object;
  timeslotsList: Object;
  attendenceList: Object;
  currentBooking: Object;
  cityWisePhlebos: Object;
  area: Object;
  paymentSendLink: Array<any>;
  paymentInformation: Array<any>;
  centerInfo: Array<any>;
  notificationMessage: Array<any>;
  verificationBookingDetails: Array<any>;
  unmaskedNumber: Array<any>;
  qrcode: Array<any>;
  phleboPhone: Object;
  bookingJourney: Array<any>;
  autoAssignStatus: boolean;
  verifiedbylist: Array<any>;
  additionMember: Array<any>;
  bookingoverview: Array<any>;
  signature: Array<any>;
  packageALlTube: Array<any>;
  unScannedTube: Array<any>;
  ccpackageList: Array<any>;
  addRemove: Object;
  ticketData: Array<any>;
  ticketCategories: Array<any>;
  ticketSubCategories: Array<any>;
  redtechcomment: Array<any>;
  sampleHandOverData: Array<any>;
  riderList: Array<any>;
  phlebo_rider_due: Array<any>;
  routesData: Array<any>;
  sampleJourneyComments: Object;
  batchbookingamount: Array<any>;
  batchPayment: Object;
  hivpackageList: Array<any>;
  biomarkerScreening: Array<any>;
  biomarkerUltasound: Array<any>;
  markerAdditionMember: Array<any>;
  labCredential: Object;
  onlineBookingList: Object;
  disposition: Array<any>;
  bookingComplaints: Array<any>
  phleboridermap: Array<any>;
  batchTransferData: Array<any>;
  phleboLeaveList: Array<any>;
  bookingListLoader: boolean;
  bookingtableloading: boolean;
  phleboUnderRm: Array<any>;
  locationVerified: Array<any>;
  sendLocationOnWhatsapp: Array<any>;
  addressDetailsLatLong: Array<any>;
  phleboAutoAssign: Array<any>;
  phleboAssignUnassignv2List: Object;
  leadSubscriptionsData: Array<any>;
  views: Array<any>;
  zoneDetails: Array<any>;
  newLeadDetails: Array<any>;
  viewsDropdown: Array<any>;
  rejectedbatchForPhlebo: Array<any>;
  rejectedbatchForRider: Array<any>;
  redCoin: Array<any>;
  specialPackageLoading: boolean;
  allSpecialPackageBookings: Array<any>;
};

const initialState: InitialState = {
  pin_code: [],
  cities: [],
  batchbookingamount: [],
  collectionSlot: [],
  areaList: [],
  agentsList: [],
  phleboList: [],
  packageList: [],
  partnerList: [],
  couponList: [],
  createBooking: {},
  additional_booking: {},
  booking: {},
  lead_details: {},
  leadById: {},
  todays_positive_calls: {},
  geoFencesMapMyIndia: [],
  leads: [],
  bookingList: [],
  bookingList1: [],
  bookingList2: [],
  bookingList3: [],
  bookingList4: [],
  bookingList5: [],
  comment: {},
  data: {},
  users: {},
  loading: false,
  unassignedLoading: false,
  pickupHoldLoading: false,
  phleboLateLoading: false,
  phleboVeryLateLoading: false,
  phleboNotStartedLoading: false,
  read_call_details: {},
  all_recordings: {},
  whatsappComments: {},
  whatsappCommentsByLead: {},
  callStats: {},
  mapBooking: [],
  area: {},
  complaint: {},
  complaints: {},
  bookingJourney: [],
  bookingsComment: {},
  phleboComment: {},
  phlebosComment: {},
  phleboCurrentLocation: [],
  zoneComment: {},
  lead_id: 0,
  lead_source: {},
  booking_slots: {},
  search_results: {},
  call_status: {},
  all_lead: {},
  call_transfer: {},
  agentBYUserGroup: {},
  allBookings: {},
  user_panel: {},
  routesData: [],
  zoneList: {},
  zone: {},
  timeslotsList: {},
  attendenceList: {},
  currentBooking: {},
  cityWisePhlebos: {},
  paymentSendLink: [],
  notificationMessage: [],
  centerInfo: [],
  verificationBookingDetails: [],
  unmaskedNumber: [],
  qrcode: [],
  paymentInformation: [],
  phleboPhone: {},
  autoAssignStatus: false,
  checkGeoFenceStatus: false,
  verifiedbylist: [],
  additionMember: [],
  mapmyindiaAccessToken: {},
  signature: [],
  packageALlTube: [],
  areaComments: [],
  unScannedTube: [],
  ccpackageList: [],
  addRemove: {},
  userDetails: [],
  bookingoverview: [],
  ticketData: [],
  ticketCategories: [],
  ticketSubCategories: [],
  redtechcomment: [],
  sampleHandOverData: [],
  riderList: [],
  phlebo_rider_due: [],
  sampleJourneyComments: {},
  hivpackageList: [],
  biomarkerScreening: [],
  biomarkerUltasound: [],
  markerAdditionMember: [],
  batchPayment: [],
  labCredential: {},
  onlineBookingList: {},
  phleboridermap: [],
  phleboAutoAssign: [],
  bookingComplaints: [],
  ticketComments: [],
  disposition: [],
  batchTransferData: [],
  phleboLeaveList:[],
  phleboUnderRm:[],
  bookingListLoader: false,
  locationVerified: [],
  sendLocationOnWhatsapp: [],
  addressDetailsLatLong: [],
  phleboAssignUnassignv2List: {},
  bookingtableloading: false,
  leadSubscriptionsData: [],
  newLeadDetails:[],
  views: [],
  zoneDetails: [],
  viewsDropdown: [],
  rejectedbatchForPhlebo: [],
  rejectedbatchForRider: [],
  redCoin: [],
  specialPackageLoading: false,
  allSpecialPackageBookings: [],
};

const RouteManagerReducer = (
  state = initialState,
  action: PhleboAdminTypes
) => {
  switch (action.type) {
    case ACTIONS.GET_PIN_CODE:
      return {
        ...state,
        pin_code: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BATCH_BOOKING_AMOUNT:
      return {
        ...state,
        batchbookingamount: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ASSIGNED_UNASSIGNED_BOOKING:
      return {
        ...state,
        allBookings: action.payload,
        loading: false,
      };
    case ACTIONS.CREATE_AREA:
      return {
        ...state,
        area: action.payload,
        loading: false,
      };
    case ACTIONS.UPLOAD_FILE:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case ACTIONS.UPDATE_AREA:
      return {
        ...state,
        area: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PHLEBOS:
      return {
        ...state,
        phleboList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CITIES:
      return {
        ...state,
        cities: action.payload,
        loading: false,
      };
    case ACTIONS.GET_COLLECTION_SLOT:
      return {
        ...state,
        collectionSlot: action.payload,
        loading: false,
      };
    case ACTIONS.GET_AREA:
      return {
        ...state,
        areaList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_POSTIVE_CALLS_COMMENTS:
      return {
        ...state,
        todays_positive_calls: action.payload,
        loading: false,
      };
    case ACTIONS.GET_TICKET_COMMENTS:
      return {
        ...state,
        ticketComments: action.payload,
        loading: false,
      };
    case ACTIONS.SET_COMMENTS:
      return {
        ...state,
        comment: action.payload,
        loading: false,
      };
    case ACTIONS.GET_RED_TECH_COMMENT:
      return {
        ...state,
        redtechcomment: action.payload,
        loading: false,
      };

    case ACTIONS.ADD_POSTIVE_CALLS_COMMENT:
      return {
        ...state,
        todays_positive_calls: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LEAD_DETAILS:
      return {
        ...state,
        lead_details: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LEADS:
      return {
        ...state,
        leads: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PAYMENT_INFORMATION:
      return {
        ...state,
        paymentInformation: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LEAD_BY_ID:
      return {
        ...state,
        leadById: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BOOKING_COMPLAINTS:
      return {
        ...state,
        bookingComplaints: action.payload,
        loading: false,
      };
    case ACTIONS.LEAD_DETAILS_RESET:
      return {
        ...state,
        leadById: {},
        lead_details: {},
        loading: false,
      };
    case ACTIONS.GET_AGENTS:
      return {
        ...state,
        agentsList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_AGENTS_BY_USERGROUP:
      return {
        ...state,
        agentBYUserGroup: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PACKAGES:
      return {
        ...state,
        packageList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PARTNERS:
      return {
        ...state,
        partnerList: action.payload,
        loading: false,
      };
    case ACTIONS.CHECK_GEOFENCE_AREA_MAMPMYINDIA:
      return {
        ...state,
        geoFencesMapMyIndia: action.payload,
        loading: false,
      };
    case ACTIONS.GET_COUPONS:
      return {
        ...state,
        couponList: action.payload,
        loading: false,
      };
    case ACTIONS.CHECK_GEOFENCE_AREA:
      return {
        ...state,
        checkGeoFenceStatus: action.payload,
        loading: false,
      };
    case ACTIONS.CREATE_BOOKING:
      return {
        ...state,
        createBooking: action.payload,
        loading: false,
      };
      case ACTIONS.GET_BOOKING_LIST:
        return {
          ...state,
          bookingList: action.payload,
          bookingtableloading: false,
        };
    case ACTIONS.GET_BOOKING_LIST1:
      return {
        ...state,
        bookingList: action.payload,
        bookingListLoader: false,
        bookingList1: action.payload,
        unassignedLoading: false,
      };
    case ACTIONS.GET_BOOKING_LIST2:
      return {
        ...state,
        bookingList2: action.payload,
        pickupHoldLoading: false,
      };
    case ACTIONS.GET_BOOKING_LIST3:
      return {
        ...state,
        bookingList3: action.payload,
        phleboLateLoading: false,
      };
    case ACTIONS.GET_BOOKING_LIST4:
      return {
        ...state,
        bookingList4: action.payload,
        phleboVeryLateLoading: false,
      };
    case ACTIONS.GET_BOOKING_LIST5:
      return {
        ...state,
        bookingList5: action.payload,
        phleboNotStartedLoading: false,
      };
    case ACTIONS.GET_BOOKING_BY_ID:
      return {
        ...state,
        booking: action.payload,
        loading: false,
      };
    case ACTIONS.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case ACTIONS.UNASSIGNED_BOOKING_LOADING:
      return {
        ...state,
        unassignedLoading: action.payload,
      };
    case ACTIONS.PHLEBO_HOLD_LOADING:
      return {
        ...state,
        pickupHoldLoading: action.payload,
      };
    case ACTIONS.PHLEBO_LATE_LOADING:
      return {
        ...state,
        phleboLateLoading: action.payload,
      };
    case ACTIONS.PHLEBO_VERY_LATE_LOADING:
      return {
        ...state,
        phleboVeryLateLoading: action.payload,
      };
    case ACTIONS.PHLEBO_NOT_STARTED_LOADING:
      return {
        ...state,
        phleboNotStartedLoading: action.payload,
      };
    case ACTIONS.CREATE_CALL:
      return {
        ...state,
        loading: false,
        lead_id: action.payload,
      };
    case ACTIONS.GET_RECORDINGS:
      return {
        ...state,
        all_recordings: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BOOKING_OVERVIEW:
      return {
        ...state,
        bookingoverview: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LEAD_SOURCE:
      return {
        ...state,
        lead_source: action.payload,
        loading: false,
      };
    case ACTIONS.SEARCH_CALL:
      return {
        ...state,
        search_results: action.payload,
        loading: false,
      };
    case ACTIONS.ADD_COMMENT:
      return {
        ...state,
        comments: action.payload,
        loading: false,
      };
    case ACTIONS.BOOKING_RESET:
      return {
        ...state,
        createBooking: {},
        bookingList: [],
        batchbookingamount: [],
        loading: false,
      };
    case ACTIONS.GET_BOOKINGS_COMMENTS:
      return {
        ...state,
        bookingsComment: action.payload,
        loading: false,
      };
    case ACTIONS.ADD_BOOKINGS_COMMENT:
      return {
        ...state,
        bookingsComment: action.payload,
        loading: false,
      };
    case ACTIONS.GET_WHATSAPP_COMMENTS:
      return {
        ...state,
        whatsappComments: action.payload,
        loading: false,
      };
    case ACTIONS.GET_USER_DETAILS:
      return {
        ...state,
        userDetails: action.payload,
        loading: false,
      };
    case ACTIONS.GET_WHATSAPP_COMMENTS_BY_LEAD:
      return {
        ...state,
        whatsappCommentsByLead: action.payload,
        loading: false,
      };
    case ACTIONS.GET_MAMPMYINDIA_ACCESS_TOKEN:
      return {
        ...state,
        mapmyindiaAccessToken: action.payload,
        loading: false,
      };
    case ACTIONS.GET_AREA_COMMENTS:
      return {
        ...state,
        areaComments: action.payload,

        loading: false,
      };
    case ACTIONS.SEND_WHATSAPP_COMMENTS:
      return {
        ...state,
        whatsappComments: action.payload,
        loading: false,
      };
    case ACTIONS.GET_MAP_BOOKING_LIST:
      return {
        ...state,
        mapBooking: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CALL_STATS:
      return {
        ...state,
        callStats: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PHLEBO_CURRENT_LOCATION:
      return {
        ...state,
        phleboCurrentLocation: action.payload,
        loading: false,
      };
    case ACTIONS.RAISE_COMPLAINT:
      return {
        ...state,
        complaint: action.payload,
        loading: false,
      };
    case ACTIONS.GET_COMPLAINTS:
      return {
        ...state,
        complaints: action.payload,
        loading: false,
      };
    case ACTIONS.ASSIGN_DOCTOR:
      return {
        ...state,
        success: true,
        loading: false,
      };
    case ACTIONS.SEND_FEEDBACK:
      return {
        ...state,
        success: true,
        loading: false,
      };
    case ACTIONS.GET_AVAILABLE_SLOTS:
      return {
        ...state,
        booking_slots: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ALL_LEADS:
      return {
        ...state,
        all_lead: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PANEL_USERS:
      return {
        ...state,
        user_panel: action.payload,
        loading: false,
      };
    case ACTIONS.LEAD_TRANSFER_BY_COMMA:
      return {
        ...state,
        all_lead: action.payload,
        loading: false,
      };
    case ACTIONS.CREATE_ADDITIONAL_BOOKING:
      return {
        ...state,
        additional_booking: action.payload,
        loading: false,
      };
    case ACTIONS.UPDATE_ADDITIONAL_BOOKING:
      return {
        ...state,
        additional_booking: action.payload,
        loading: false,
      };
    case ACTIONS.CREATE_ZONE:
      return {
        ...state,
        zone: action.payload,
        loading: false,
      };
    case ACTIONS.UPDATE_ZONE:
      return {
        ...state,
        zone: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ZONE:
      return {
        ...state,
        zoneList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_TIMESLOTS:
      return {
        ...state,
        timeslotsList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ATTENDENCE:
      return {
        ...state,
        attendenceList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PHLEBO_COMMENTS:
      return {
        ...state,
        phleboComment: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ZONE_COMMENTS:
      return {
        ...state,
        zoneComment: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CURRENT_DAY_BOOKING:
      return {
        ...state,
        currentBooking: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CITY_WISE_PHLEBOS:
      return {
        ...state,
        cityWisePhlebos: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PAYMENT_SEND_LINK:
      return {
        ...state,
        paymentSendLink: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CENTER_INFORMATION:
      return {
        ...state,
        centerInfo: action.payload,
        loading: false,
      };
    case ACTIONS.GET_NOTIFICATION_MESSAGE:
      return {
        ...state,
        notificationMessage: action.payload,
        loading: false,
      };
    case ACTIONS.GET_VERIFICATION_BOOKING_DETAILS:
      return {
        ...state,
        verificationBookingDetails: action.payload,
        loading: false,
      };
    case ACTIONS.GET_UNMASKED_NUMBER:
      return {
        ...state,
        unmaskedNumber: action.payload,
        loading: false,
      };
    case ACTIONS.GET_QRCODE_DATA:
      return {
        ...state,
        qrcode: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PHLEBO_BY_PHONE:
      return {
        ...state,
        phleboPhone: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BOOKING_JOURNEY_DETAILS:
      return {
        ...state,
        bookingJourney: action.payload,
        loading: false,
      };
    case ACTIONS.AUTO_ASSIGN_BOOKING:
      return {
        ...state,
        autoAssignStatus: action.payload,
        loading: false,
      };
    case ACTIONS.GET_VERIFIED_BY_LIST:
      return {
        ...state,
        verifiedbyList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_SIGNATURE:
      return {
        ...state,
        signature: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ADDITIONAL_BOOKING:
      return {
        ...state,
        additionMember: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PACKAGE_ALL_TUBE:
      return {
        ...state,
        unScannedTube: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CC_PACKAGES:
      return {
        ...state,
        ccpackageList: action.payload,
        loading: false,
      };
    case ACTIONS.POST_ADD_REMOVE_TEST:
      return {
        ...state,
        addRemove: action.payload,
        loading: false,
      };
    case ACTIONS.GET_TICKET_DATA:
      return {
        ...state,
        ticketData: action.payload,
        loading: false,
      };
    case ACTIONS.GET_TICKET_CATEGORIES:
      return {
        ...state,
        ticketCategories: action.payload,
        loading: false,
      };

    case ACTIONS.GET_TICEKT_SUB_CATEGORIES:
      return {
        ...state,
        ticketSubCategories: action.payload,
        loading: false,
      };
    case ACTIONS.GET_SAMPLE_HANDOVER_DATA:
      return {
        ...state,
        sampleHandOverData: action.payload,
        loading: false,
      };
    case ACTIONS.GET_RIDER_LIST:
      return {
        ...state,
        riderList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_SAMPLE_JOURNEY_COMMENTS:
      return {
        ...state,
        sampleJourneyComments: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PHLEBO_RIDER_DUE_AMOUNT:
      return {
        ...state,
        phlebo_rider_due: action.payload,
        loading: false,
      };
    case ACTIONS.GET_HIV_PACKAGES:
      return {
        ...state,
        hivpackageList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BIOMARKER_SCREENING:
      return {
        ...state,
        biomarkerScreening: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BIOMARKER_ULTASOUND:
      return {
        ...state,
        biomarkerUltasound: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ADDITIONAL_MEMBER:
      return {
        ...state,
        markerAdditionMember: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BATCH_PAYMENT:
      return {
        ...state,
        batchPayment: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ONLINE_BOOKING_LIST:
      return {
        ...state,
        onlineBookingList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LAB_CREDENTIAL:
      return {
        ...state,
        labCredential: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PHLEBO_RIDER:
      return {
        ...state,
        phleboridermap: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ROUTES:
      return {
        ...state,
        routesData: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BATCH_TRANSFER_DATA:
      return {
        ...state,
        batchTransferData: action.payload,
        loading: false,
      };
      case ACTIONS.UPDATE_DISPOSITION:
        return {
            ...state,
            disposition: action.payload,
            loading: false,
        };
        case ACTIONS.SET_ALL_BOOKING_LOADING:
          return {
            ...state,
            bookingListLoader: action.payload,
          };
        case ACTIONS.GET_PHLEBO_LEAVE_LIST:
          return {
            ...state,
            phleboLeaveList: action.payload,
          };
        case ACTIONS.GET_PHLEBO_LIST_UNDER_ROUTE_MANAGER:
          return {
            ...state,
            phleboUnderRm: action.payload,
          };
          case ACTIONS.TABLE_RESET:
            return {
              ...state,
              phleboLeaveList: [],
              loading: false,
            };
    case ACTIONS.GET_ADDRESS_DETAILS_LAT_LONG:
      return {
        ...state,
        addressDetailsLatLong: action.payload,
        loading: false,
      };
    case ACTIONS.SEND_LOCATION_REQUEST:
      return {
        ...state,
        sendLocationOnWhatsapp: action.payload,
        loading: false,
      };
    case ACTIONS.LOCATION_VERIFIED:
      return {
        ...state,
        locationVerified: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ASSIGNED_UNASSIGNED_BOOKING_PILOT_V2:
      return {
        ...state,
        phleboAssignUnassignv2List: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PHLEBO_AUTO_ASSIGN:
      return {
        ...state,
        phleboAutoAssign: action.payload,
        loading: false,
      };
    case ACTIONS.SET_BOOKING_LOADING:
      return {
        ...state,

        bookingtableloading: false,
      };
    case ACTIONS.BIOMARKER_RESET:
      return {
        ...state,
        biomarkerScreening: {},
      };
    case ACTIONS.GET_LEAD_SUBSCRIPTIONS_DATA:
      return {
        ...state,
        leadSubscriptionsData: action.payload,
        loading: false,
      };
    case ACTIONS.GET_SF_VIEWS:
      return {
        ...state,
        views: action.payload,
      }
    case ACTIONS.GET_ZONE_DETAILS:
      return {
        ...state,
        zoneDetails: action.payload,
        loading: false,
      };
      case ACTIONS.GET_NEW_LEAD_DETAILS:
        return {
          ...state,
          newLeadDetails: action.payload,
          loading: false,
        };
    case ACTIONS.GET_SF_VIEWS_DROPDOWN:
      return {
        ...state,
        viewsDropdown: action.payload,
        loading: false,
      };
    case ACTIONS.GET_REJECTED_BATCH_FOR_PHLEBO:
      return {
        ...state,
        rejectedbatchForPhlebo: action.payload,
        loading: false,
      };
    case ACTIONS.GET_REJECTED_BATCH_FOR_RIDER:
      return {
        ...state,
        rejectedbatchForRider: action.payload,
        loading: false,
      };
    case ACTIONS.GET_RED_COIN:
      return {
        ...state,
        redCoin: action.payload,
        loading: false,
      };
    case ACTIONS.SPECIAL_PACKAGE_BOOKINGS_LOADING:
      return {
        ...state,
        specialPackageLoading: action.payload,
      };
    case ACTIONS.GET_SPECIAL_PACKAGE_BOOKINGS:
      return {
        ...state,
        allSpecialPackageBookings: action.payload,
        specialPackageLoading: false,
      };
    default:
      return state;
  }
};

export default RouteManagerReducer;
