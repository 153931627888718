export const ACTIONS = {
  CHANGE_PASSWORD: "change_password",
  GET_USERS_LIST: "pm/get_user_list",
  UPDATE_USERS_LIST: "pm/update_user_list",
  GET_AREA: "pm/get_area",
  CREATE_AREA: "pm/create_area",
  UPDATE_AREA: "pm/update_area",
  GET_ZONE: "pm/get_zone",
  UPDATE_ZONE: "pm/update_zone",
  CREATE_ZONE: "pm/create_zone",
  GET_TIMESLOTS: "pm/get_timeslots",
  GET_TESTS_CRM: "pm/get_tests_crm",
  GET_BLOG_TAGS: "pm/get_tags",
  GET_BLOG_CATEGORY: "pm/get_blog_categgory",
  GET_BLOG_IMAGES: "pm/get_blog_images",
  GET_BLOGS: "pm/get_blogs",
  GET_BLOG_BY_SLUG: "pm/get_blog_by_slug",
  UPDATE_BLOGS: "pm/update_blogs",
  CREATE_BLOGS: "pm/create_blogs",
  DELETE_BLOG: "pm/delete_blog",
  CREATE_COUPON: "pm/create_coupon",
  UPDATE_COUPON: "pm/update_coupon",
  GET_PACKAGE: "pm/get_package",
  CREATE_PACKAGE: "pm/create_package",
  UPDATE_PACKAGE: "pm/update_package",
  GET_CITY_PRICES: "pm/get_city_prices",
  SET_LOADING: "pm/set_loading",
  GET_PIN_CODE: "pm/get_pin_code",
  GET_CITIES: "pm/get_cities",
  GET_AGENTS: "pm/get_agents",
  GET_AGENTS_BY_USERGROUP: "pm/get_agents_by_usergroup",
  GET_PACKAGES: "pm/get_packages",
  GET_PHLEBOS: "pm/get_phlebos",
  GET_PARTNERS: "pm/get_partners",
  GET_COUPONS: "pm/get_coupons",
  CREATE_BOOKING: "pm/create_booking",
  UPDATE_BOOKING: "pm/update_booking",
  GET_BOOKING_LIST: "pm/get_booking_list",
  GET_LEAD_DETAILS: "pm/get_lead_details",
  GET_LEAD_BY_ID: "pm/get_lead_by_id",
  LEAD_DETAILS_RESET: "pm/lead_details_reset",
  GET_LEADS: "pm/get_leads",
  GET_BOOKING_BY_ID: "pm/get_booking_by_id",
  CLICK_TO_CALL: "pm/click_to_call",
  GET_POSTIVE_CALLS_COMMENTS: "pm/get_postive_calls_commnets",
  ADD_POSTIVE_CALLS_COMMENT: "pm/add_comments",
  SET_COMMENTS: "pm/set_commnets",
  UPLOAD_FILE: "pm/upload",
  SEARCH_CALL: "pm/serach",
  BOOKING_RESET: "pm/booking_reset",
  ADD_COMMENT: "positive_calls/add_comment",
  CREATE_CALL: "pm/createcall",
  GET_LEAD_SOURCE: "pm/getleadsource",
  GET_RECORDINGS: "pm/get_recordings",
  GET_BOOKINGS_COMMENTS: "pm/get_bookings_commnets",
  ADD_BOOKINGS_COMMENT: "pm/add_bookings_comments",
  GET_WHATSAPP_COMMENTS: "pm/get_whatsapp_comments",
  GET_WHATSAPP_COMMENTS_BY_LEAD: "pm/get_whatsapp_comments_by_lead",
  SEND_WHATSAPP_COMMENTS: "pm/send",
  GET_CALL_STATS: "pm/get_call_stats",
  RAISE_COMPLAINT: "pm/raise_complaint",
  GET_COMPLAINTS: "pm/get_complaints",
  ASSIGN_DOCTOR: "pm/assign_doctor",
  SEND_FEEDBACK: "pm/send_feedback",
  GET_AVAILABLE_SLOTS: "pm/get_available_slots",
  GET_ALL_LEADS: "pm/get_all_leads",
  LEAD_TRANSFER_BY_COMMA: "pm/set_comments",
  GET_PANEL_USERS: "pm/get_panel_user",
  GET_LAB_CREDENTIAL_DETAILS: "pm/get_lab_credential_details",
  UPDATE_LAB_CREDENTIALS: "pm/update_lab_credentials",
  GET_ZONE_COMMENTS: "pm/get_zone_comments",
  GET_DISPOSITIONS: "pm/get_dispositions",
  GET_DISPOSITION_DATA: "pm/get_disposition_data",
  ADD_REMARK_ON_CALL: "pm/add_remark_on_call",
  GET_INCORRECT_NUMBER_LEAD: "pm/get_incorrect_number_lead",
  SAME_NUMBER_BOOKING: "pm/same_number_booking",
  GET_PAYMENT_SEND_LINK: "pm/get_payment_send_link",
  GET_PAYMENT_RESEND_LINK: "pm/get_payment_resend_link",
  GET_PAYMENT_CANCEL_LINK: "pm/get_payment_cancel_link",
  GET_SMS_REPORT_SEND: "pm/get_sms_report_send",
  GET_BREAK_DATA: "pm/get_break_data",
  CREATE_ADDITIONAL_BOOKING: "pm/create_additional_booking",
  UPDATE_ADDITIONAL_BOOKING: "pm/update_additional_booking",
  GET_ASSIGNED_USERGROUP: "pm/get_assigned_usergroup",
  GET_TODAYS_CALL_DETAILS: "pm/get_todays_call_details",
  GET_TOP_RECORDS: "pm/get_top_records",
  GET_VERIFICATION_BOOKING_DETAILS: "pm/get_verification_booking_details",
  GET_SMS_DETAILS: "pm/get_sms_details",
  GET_EMAIL_DETAILS: "pm/get_email_details",
  GET_REPORT_STATUS: "pm/get_report_status",
  GET_CENTER_INFORMATION: "pm/get_center_information",
  GET_LAB_DETAILS: "pm/get_lab_details",
  GET_SYNC_DATA: "pm/get_sync_data",
  GET_SMS_LINK: "pm/send_sms_link",
  GET_REPORT_DOWNLOAD: "pm/get_report_download",
  GET_PAYMENT_INFORMATION: "pm/get_payment_information",
  GET_NOTIFICATION_MESSAGE: "pm/get_notification_message",
  GET_UNMASKED_NUMBER: "pm/get_unmasked_number",
  CHECK_GEOFENCE_AREA: "pm/checking-geofence-area",
  GET_PACKAGE_CATEGORY: "pm/get_package_category",
  GET_PACKAGE_TAGS: "pm/get_package_tag",
  GET_PACKAGE_DETAILS: "pm/get_package_details",
  CREATE_LAB: "pm/create_lab",
  GET_PACKAGE_COMMENTS: "pm/get_package_comments",
  GET_PACKAGE_BY_NAME: "pm/get_package_bt_name",
  GET_COLLECTION_SLOT: "pm/get_collection_slot",
  GET_QRCODE_DATA: "pm/get_qrcode_data",
  GET_MAMPMYINDIA_ACCESS_TOKEN: "pm/get-mapmyindia-access-token",
  CHECK_GEOFENCE_AREA_MAMPMYINDIA: "pm/checking-geofence-area-mapmyindia",
  GET_USER_DETAILS: "pm/get_user_details",
  GET_UNSYNC_BOOKING: "pm/get_unsync_booking",
  GET_TICKET_DATA: "pm/get_ticket_data",
  GET_TICKET_CATEGORIES: "pm/get_ticket_category",
  GET_TICEKT_SUB_CATEGORIES: "pm/get_ticket_categories",
  CREATE_TASK_TICKET: "pm/create_task_ticket",
  GET_RED_TECH_COMMENT: "pm/redtech_comments/",
  POST_RED_TECH_COMMENT: "pm/postredtech_comments/",
  GET_PACKAGE_MANAGEMENT: "pm/get_package_management",
  CREATE_PACKAGE_MANAGEMENT: "pm/create_package_management",
  UPDATE_PACKAGE_MANAGEMENT: "pm/update_package_management",
  GET_CITY_WISE_PACKAGE: "pm/get_city_wise_package",
  UPDATE_TAT_PACKAGE: "pm/update_tat_package",
  CREATE_TAT: "pm/create_tat",
  GET_TAGS_LIST: "pm/get_tags_list",
  GET_CATEGORY: "pm/get_category",
  GET_BOOKING_OVERVIEW: "pm/booking_overview",
  UPDATE_TICKET_STATUS: "pm/update_ticekt_status",
  GET_TICKET_COMMENTS: "pm/ticket_comments",
  GET_BOOKING_COMPLAINTS: "pm/get_booking_complaints",
  UPDATE_DISPOSITION: "pm/update_disposition",
  SET_ALL_BOOKING_LOADING: "pm/set_all_booking_loading",

};
interface GetCategoryList {
  type: typeof ACTIONS.GET_CATEGORY;
  payload: Object;
}
interface GetTestCrm {
  type: typeof ACTIONS.GET_TESTS_CRM;
  payload: boolean;
}
interface GetUserList {
  type: typeof ACTIONS.GET_USERS_LIST;
  payload: Object;
}
interface GetQRCodeData {
  type: typeof ACTIONS.GET_QRCODE_DATA;
  payload: Array<any>;
}
interface CheckGeoFenceArea {
  type: typeof ACTIONS.CHECK_GEOFENCE_AREA;
  payload: Array<any>;
}
interface GetPackageByName {
  type: typeof ACTIONS.GET_PACKAGE_BY_NAME;
  payload: Array<any>;
}
interface CreateLab {
  type: typeof ACTIONS.CREATE_LAB;
  payload: Array<any>;
}
interface GetPackageComments {
  type: typeof ACTIONS.GET_PACKAGE_COMMENTS;
  payload: Array<any>;
}
interface GetPackageDetails {
  type: typeof ACTIONS.GET_PACKAGE_DETAILS;
  payload: Array<any>;
}
interface GetPackageCategory {
  type: typeof ACTIONS.GET_PACKAGE_CATEGORY;
  payload: Array<any>;
}
interface GetPackageTag {
  type: typeof ACTIONS.GET_PACKAGE_TAGS;
  payload: Array<any>;
}
interface GetUnmaskedNumber {
  type: typeof ACTIONS.GET_UNMASKED_NUMBER;
  payload: Array<any>;
}
interface GetAssignedUsergroup {
  type: typeof ACTIONS.GET_ASSIGNED_USERGROUP;
  payload: Object;
}
interface SetUserList {
  type: typeof ACTIONS.UPDATE_USERS_LIST;
  payload: Object;
}
interface GetArea {
  type: typeof ACTIONS.GET_AREA;
  payload: Object;
}
interface CreateArea {
  type: typeof ACTIONS.CREATE_AREA;
  payload: Object;
}
interface UpdateArea {
  type: typeof ACTIONS.UPDATE_AREA;
  payload: Object;
}
interface GetZone {
  type: typeof ACTIONS.GET_ZONE;
  payload: Object;
}
interface CreateZone {
  type: typeof ACTIONS.CREATE_ZONE;
  payload: Object;
}
interface UpdateZone {
  type: typeof ACTIONS.UPDATE_ZONE;
  payload: Object;
}
interface GetTimeslots {
  type: typeof ACTIONS.GET_TIMESLOTS;
  payload: Object;
}
interface GetBlogTags {
  type: typeof ACTIONS.GET_BLOG_TAGS;
  payload: Object;
}
interface GetCollectionSlot {
  type: typeof ACTIONS.GET_COLLECTION_SLOT;
  payload: Array<any>;
}
interface GetBlogCategory {
  type: typeof ACTIONS.GET_BLOG_CATEGORY;
  payload: Object;
}
interface GetBlogImages {
  type: typeof ACTIONS.GET_BLOG_IMAGES;
  payload: Object;
}
interface GetBookingOverview {
  type: typeof ACTIONS.GET_BOOKING_OVERVIEW;
  payload: Array<any>;
}
interface GetBlogs {
  type: typeof ACTIONS.GET_BLOGS;
  payload: Object;
}
interface GetBlogBySlug {
  type: typeof ACTIONS.GET_BLOG_BY_SLUG;
  payload: Object;
}
interface CreateBlogs {
  type: typeof ACTIONS.CREATE_BLOGS;
  payload: Object;
}
interface UpdateBlogs {
  type: typeof ACTIONS.UPDATE_BLOGS;
  payload: Object;
}
interface DeleteBlog {
  type: typeof ACTIONS.DELETE_BLOG;
  payload: Object;
}
interface CreateCoupon {
  type: typeof ACTIONS.CREATE_COUPON;
  payload: Object;
}
interface UpdateCoupon {
  type: typeof ACTIONS.UPDATE_COUPON;
  payload: Object;
}
interface GetPackageBySLug {
  type: typeof ACTIONS.GET_PACKAGE;
  payload: Object;
}
interface CreatePackage {
  type: typeof ACTIONS.CREATE_PACKAGE;
  payload: Object;
}
interface UpdatePackage {
  type: typeof ACTIONS.UPDATE_PACKAGE;
  payload: Object;
}
interface GetCityPrices {
  type: typeof ACTIONS.GET_CITY_PRICES;
  payload: Object;
}
interface SetLoading {
  type: typeof ACTIONS.SET_LOADING;
  payload: boolean;
}

interface GetPinCode {
  type: typeof ACTIONS.GET_PIN_CODE;
  payload: Array<any>;
}
interface ChangePassword {
  type: typeof ACTIONS.CHANGE_PASSWORD;
  payload: Object;
}

interface UploadFile {
  type: typeof ACTIONS.UPLOAD_FILE;
  payload: Object;
}

interface GetCities {
  type: typeof ACTIONS.GET_CITIES;
  payload: Array<any>;
}

interface GetAgents {
  type: typeof ACTIONS.GET_AGENTS;
  payload: Array<any>;
}

interface GetPhlebo {
  type: typeof ACTIONS.GET_PHLEBOS;
  payload: Array<any>;
}

interface SetComments {
  type: typeof ACTIONS.SET_COMMENTS;
  payload: Object;
}

interface GetPositiveCallsComments {
  type: typeof ACTIONS.GET_POSTIVE_CALLS_COMMENTS;
  payload: Object;
}

interface AddPositiveCallsComments {
  type: typeof ACTIONS.ADD_POSTIVE_CALLS_COMMENT;
  payload: Object;
}

interface ClickToCall {
  type: typeof ACTIONS.CLICK_TO_CALL;
  payload: Array<any>;
}

interface GetPackages {
  type: typeof ACTIONS.GET_PACKAGES;
  payload: Array<any>;
}
interface GetUserDetails {
  type: typeof ACTIONS.GET_USER_DETAILS;
  payload: Array<any>;
}

interface GetPartners {
  type: typeof ACTIONS.GET_PARTNERS;
  payload: Array<any>;
}

interface GetCoupons {
  type: typeof ACTIONS.GET_COUPONS;
  payload: Array<any>;
}

interface SetLoading {
  type: typeof ACTIONS.SET_LOADING;
  payload: boolean;
}

interface CreateBooking {
  type: typeof ACTIONS.CREATE_BOOKING;
  payload: boolean;
}

interface UpdateBooking {
  type: typeof ACTIONS.UPDATE_BOOKING;
  payload: boolean;
}

interface GetBookings {
  type: typeof ACTIONS.GET_BOOKING_LIST;
  payload: boolean;
}

interface GetBookingById {
  type: typeof ACTIONS.GET_BOOKING_BY_ID;
  payload: boolean;
}

interface GetLeads {
  type: typeof ACTIONS.GET_LEADS;
  payload: boolean;
}

interface GetLeadDetails {
  type: typeof ACTIONS.GET_LEAD_DETAILS;
  payload: boolean;
}

interface GetLeadById {
  type: typeof ACTIONS.GET_LEAD_BY_ID;
  payload: boolean;
}

interface GetMapMyIndiaAccessToken {
  type: typeof ACTIONS.GET_MAMPMYINDIA_ACCESS_TOKEN;
  payload: Array<any>;
}
interface ResetLeadDetails {
  type: typeof ACTIONS.LEAD_DETAILS_RESET;
  payload: boolean;
}

interface GetPinCode {
  type: typeof ACTIONS.GET_PIN_CODE;
  payload: Array<any>;
}
interface ChangePassword {
  type: typeof ACTIONS.CHANGE_PASSWORD;
  payload: Object;
}
interface GetCities {
  type: typeof ACTIONS.GET_CITIES;
  payload: Array<any>;
}

interface GetPhlebo {
  type: typeof ACTIONS.GET_PHLEBOS;
  payload: Array<any>;
}

interface GetAgents {
  type: typeof ACTIONS.GET_AGENTS;
  payload: Array<any>;
}

interface SetComments {
  type: typeof ACTIONS.SET_COMMENTS;
  payload: Object;
}

interface GetPositiveCallsComments {
  type: typeof ACTIONS.GET_POSTIVE_CALLS_COMMENTS;
  payload: Object;
}

interface AddPositiveCallsComments {
  type: typeof ACTIONS.ADD_POSTIVE_CALLS_COMMENT;
  payload: Object;
}
interface ClickToCall {
  type: typeof ACTIONS.CLICK_TO_CALL;
  payload: Array<any>;
}

interface GetPackages {
  type: typeof ACTIONS.GET_PACKAGES;
  payload: Array<any>;
}

interface GetPartners {
  type: typeof ACTIONS.GET_PARTNERS;
  payload: Array<any>;
}

interface GetCoupons {
  type: typeof ACTIONS.GET_COUPONS;
  payload: Array<any>;
}

interface SetLoading {
  type: typeof ACTIONS.SET_LOADING;
  payload: boolean;
}

interface CreateBooking {
  type: typeof ACTIONS.CREATE_BOOKING;
  payload: boolean;
}

interface UpdateBooking {
  type: typeof ACTIONS.UPDATE_BOOKING;
  payload: boolean;
}

interface GetBookings {
  type: typeof ACTIONS.GET_BOOKING_LIST;
  payload: boolean;
}

interface GetBookingById {
  type: typeof ACTIONS.GET_BOOKING_BY_ID;
  payload: boolean;
}

interface GetLeads {
  type: typeof ACTIONS.GET_LEADS;
  payload: boolean;
}

interface GetLeadDetails {
  type: typeof ACTIONS.GET_LEAD_DETAILS;
  payload: boolean;
}

interface GetLeadById {
  type: typeof ACTIONS.GET_LEAD_BY_ID;
  payload: boolean;
}

interface ResetLeadDetails {
  type: typeof ACTIONS.LEAD_DETAILS_RESET;
  payload: boolean;
}
interface SearchCall {
  type: typeof ACTIONS.SEARCH_CALL;
  payload: Object;
}
interface SearchCall {
  type: typeof ACTIONS.SEARCH_CALL;
  payload: Object;
}
interface GetAgentByUserGroup {
  type: typeof ACTIONS.GET_AGENTS_BY_USERGROUP;
  payload: Object;
}
interface GetLabCredentialDetails {
  type: typeof ACTIONS.GET_LAB_CREDENTIAL_DETAILS;
  payload: Object;
}
interface UpdatelabCredential {
  type: typeof ACTIONS.UPDATE_LAB_CREDENTIALS;
  payload: Object;
}
interface GetZoneComment {
  type: typeof ACTIONS.GET_ZONE_COMMENTS;
  payload: Object;
}
interface GetDispositions {
  type: typeof ACTIONS.GET_DISPOSITIONS;
  payload: Array<any>;
}
interface GetDispositionData {
  type: typeof ACTIONS.GET_DISPOSITION_DATA;
  payload: Object;
}
interface AddRemarkOnCall {
  type: typeof ACTIONS.ADD_REMARK_ON_CALL;
  payload: Object;
}
interface GetIncorrectNumberLead {
  type: typeof ACTIONS.GET_INCORRECT_NUMBER_LEAD;
  payload: Object;
}
interface SameNumberBooking {
  type: typeof ACTIONS.SAME_NUMBER_BOOKING;
  payload: Object;
}
interface GetPaymentSendLink {
  type: typeof ACTIONS.GET_PAYMENT_SEND_LINK;
  payload: Object;
}
interface GetPaymentReSendLink {
  type: typeof ACTIONS.GET_PAYMENT_RESEND_LINK;
  payload: Object;
}
interface GetPaymentCancelLink {
  type: typeof ACTIONS.GET_PAYMENT_CANCEL_LINK;
  payload: Object;
}
interface GetSmsReportSent {
  type: typeof ACTIONS.GET_SMS_REPORT_SEND;
  payload: Object;
}
interface GetBreakData {
  type: typeof ACTIONS.GET_BREAK_DATA;
  payload: Object;
}
interface CheckGeoFenceAreaMapMyIndia {
  type: typeof ACTIONS.CHECK_GEOFENCE_AREA_MAMPMYINDIA;
  payload: Array<any>;
}
interface CreateAdditionalBooking {
  type: typeof ACTIONS.CREATE_ADDITIONAL_BOOKING;
  payload: Object;
}
interface UpdateAdditionalBooking {
  type: typeof ACTIONS.UPDATE_ADDITIONAL_BOOKING;
  payload: Object;
}
interface GetTodayCallDetails {
  type: typeof ACTIONS.GET_TODAYS_CALL_DETAILS;
  payload: Array<any>;
}
interface GetTopRecords {
  type: typeof ACTIONS.GET_TOP_RECORDS;
  payload: Array<any>;
}
interface GetVerificationBookingDetails {
  type: typeof ACTIONS.GET_VERIFICATION_BOOKING_DETAILS;
  payload: Array<any>;
}
interface GetSmsDetails {
  type: typeof ACTIONS.GET_SMS_DETAILS;
  payload: Array<any>;
}
interface GetEmailDetails {
  type: typeof ACTIONS.GET_EMAIL_DETAILS;
  payload: Array<any>;
}
interface GetReportStatus {
  type: typeof ACTIONS.GET_REPORT_STATUS;
  payload: Array<any>;
}
interface GetCenterInfo {
  type: typeof ACTIONS.GET_CENTER_INFORMATION;
  payload: Array<any>;
}
interface GetLabDetails {
  type: typeof ACTIONS.GET_LAB_DETAILS;
  payload: Array<any>;
}
interface GetSyncData {
  type: typeof ACTIONS.GET_SYNC_DATA;
  payload: Object;
}
interface SendSmsLink {
  type: typeof ACTIONS.GET_SMS_LINK;
  payload: Object;
}
interface GetReportDownload {
  type: typeof ACTIONS.GET_REPORT_DOWNLOAD;
  payload: Object;
}
interface GetPaymentInfro {
  type: typeof ACTIONS.GET_PAYMENT_INFORMATION;
  payload: Array<any>;
}
interface GetNotificationMessage {
  type: typeof ACTIONS.GET_NOTIFICATION_MESSAGE;
  payload: Array<any>;
}
interface GetRedTechComment {
  type: typeof ACTIONS.GET_RED_TECH_COMMENT;
  payload: Array<any>;
}
interface GetTicketData {
  type: typeof ACTIONS.GET_TICKET_DATA;
  payload: Object;
}
interface GetTicketCategory {
  type: typeof ACTIONS.GET_TICKET_CATEGORIES;
  payload: Object;
}
interface GetTicketSubCategories {
  type: typeof ACTIONS.GET_TICEKT_SUB_CATEGORIES;
  payload: Object;
}
interface PostRedTechComment {
  type: typeof ACTIONS.POST_RED_TECH_COMMENT;
  payload: Object;
}
interface CreateTaskTicket {
  type: typeof ACTIONS.CREATE_TASK_TICKET;
  payload: Array<any>;
}
interface GetPackageManagement {
  type: typeof ACTIONS.GET_PACKAGE_MANAGEMENT;
  payload: Array<any>;
}
interface CreatePackageManagement {
  type: typeof ACTIONS.CREATE_PACKAGE_MANAGEMENT;
  payload: Array<any>;
}
interface UpdatePackageManagement {
  type: typeof ACTIONS.UPDATE_PACKAGE_MANAGEMENT;
  payload: Array<any>;
}
interface GetCityWisePackage {
  type: typeof ACTIONS.GET_CITY_WISE_PACKAGE;
  payload: Array<any>;
}
interface UpdateTatPackage {
  type: typeof ACTIONS.UPDATE_TAT_PACKAGE;
  payload: Array<any>;
}

interface CreateTat {
  type: typeof ACTIONS.CREATE_TAT;
  payload: Object;
}
interface GetTagsList {
  type: typeof ACTIONS.GET_TAGS_LIST;
  payload: Array<any>;
}
interface UpdateTicketStatus {
  type: typeof ACTIONS.UPDATE_TICKET_STATUS;
  payload: Array<any>;
}
interface GetTicketComments {
  type: typeof ACTIONS.GET_TICKET_COMMENTS;
  payload: Array<any>;
}
interface GetBookingQueryTickets {
  type: typeof ACTIONS.GET_BOOKING_COMPLAINTS;
  payload: boolean;
}
interface UpdateActionDisposition {
  type: typeof ACTIONS.UPDATE_DISPOSITION;
  payload: Array<any>;
}
interface GetBookingsLoader {
  type: typeof ACTIONS.SET_ALL_BOOKING_LOADING;
  payload: boolean;
}
export type BookingTypes =
  | GetUserList
  | SetUserList
  | GetArea
  | CreateArea
  | UpdateArea
  | GetZone
  | CreateZone
  | UpdateZone
  | GetBlogs
  | GetBlogBySlug
  | CreateBlogs
  | UpdateBlogs
  | GetTimeslots
  | GetBlogTags
  | GetBlogCategory
  | GetBlogImages
  | CreateCoupon
  | UpdateCoupon
  | DeleteBlog
  | GetPackageBySLug
  | CreatePackage
  | UpdatePackage
  | GetCityPrices
  | SetLoading
  | SearchCall
  | GetPinCode
  | ChangePassword
  | GetCities
  | GetAgents
  | GetPhlebo
  | GetAgentByUserGroup
  | GetPackages
  | CreateBooking
  | UpdateBooking
  | GetPartners
  | GetCoupons
  | GetBookings
  | GetBookingById
  | SetLoading
  | GetLeadDetails
  | GetLeads
  | GetLeadById
  | ResetLeadDetails
  | ClickToCall
  | GetPositiveCallsComments
  | AddPositiveCallsComments
  | CreateAdditionalBooking
  | UpdateAdditionalBooking
  | SetComments
  | UploadFile
  | GetPinCode
  | ChangePassword
  | GetCities
  | GetAgents
  | GetPhlebo
  | GetPackages
  | CreateBooking
  | UpdateBooking
  | GetPartners
  | GetCoupons
  | GetBookings
  | GetBookingById
  | SetLoading
  | GetLeadDetails
  | GetLeads
  | GetLeadById
  | ResetLeadDetails
  | ClickToCall
  | GetPositiveCallsComments
  | AddPositiveCallsComments
  | SetComments
  | SearchCall
  | GetLabCredentialDetails
  | UpdatelabCredential
  | GetZoneComment
  | GetDispositions
  | GetDispositionData
  | AddRemarkOnCall
  | GetIncorrectNumberLead
  | SameNumberBooking
  | GetPaymentSendLink
  | GetPaymentReSendLink
  | GetPaymentCancelLink
  | GetSmsReportSent
  | GetBreakData
  | GetCollectionSlot
  | GetAssignedUsergroup
  | GetTodayCallDetails
  | GetTopRecords
  | GetVerificationBookingDetails
  | GetSmsDetails
  | GetEmailDetails
  | GetReportStatus
  | GetCenterInfo
  | GetLabDetails
  | SendSmsLink
  | GetReportDownload
  | GetSyncData
  | GetPaymentInfro
  | GetNotificationMessage
  | GetUnmaskedNumber
  | CheckGeoFenceArea
  | GetPackageCategory
  | GetPackageTag
  | GetPackageDetails
  | CreateLab
  | GetPackageComments
  | GetPackageByName
  | GetQRCodeData
  | GetMapMyIndiaAccessToken
  | CheckGeoFenceAreaMapMyIndia
  | GetUserDetails
  | GetTicketData
  | GetTicketCategory
  | GetTicketSubCategories
  | CreateTaskTicket
  | GetRedTechComment
  | PostRedTechComment
  | GetPackageManagement
  | CreatePackageManagement
  | UpdatePackageManagement
  | GetCityWisePackage
  | UpdateTatPackage
  | CreateTat
  | GetTagsList
  | GetCategoryList
  | GetTestCrm
  | UpdateTicketStatus
  | GetBookingQueryTickets
  | GetTicketComments
  | UpdateActionDisposition
  | GetBookingOverview
  | GetBookingsLoader;
