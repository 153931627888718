export const ACTIONS = {
  CHANGE_PASSWORD: "change_password",
  GET_PIN_CODE: "rm/get_pin_code",
  GET_CITIES: "rm/get_cities",
  UPDATE_USERS_LIST: "rm/update_user_list",
  GET_COLLECTION_SLOT: "rm/get_collection_slot",
  GET_AREA: "rm/get_area",
  GET_AGENTS: "rm/get_agents",
  GET_AGENTS_BY_USERGROUP: "rm/get_agents_by_usergroup",
  GET_PACKAGES: "rm/get_packages",
  GET_PHLEBOS: "rm/get_phlebos",
  GET_PARTNERS: "rm/get_partners",
  GET_COUPONS: "rm/get_coupons",
  CREATE_BOOKING: "rm/create_booking",
  CREATE_ADDITIONAL_BOOKING: "rm/create_additional_booking",
  UPDATE_ADDITIONAL_BOOKING: "rm/update_additional_booking",
  GET_ONLINE_BOOKING_LIST: "rm/get_online_booking_list",
  UPDATE_BOOKING: "rm/update_booking",
  GET_BOOKING_LIST: "rm/get_booking_list",
  GET_BOOKING_LIST1: "rm/get_booking_list1",
  GET_BOOKING_LIST2: "rm/get_booking_list2",
  GET_BOOKING_LIST3: "rm/get_booking_list3",
  GET_BOOKING_LIST4: "rm/get_booking_list4",
  GET_BOOKING_LIST5: "rm/get_booking_list5",
  GET_LEAD_DETAILS: "rm/get_lead_details",
  GET_LEAD_BY_ID: "rm/get_lead_by_id",
  LEAD_DETAILS_RESET: "rm/lead_details_reset",
  GET_LEADS: "rm/get_leads",
  GET_BOOKING_BY_ID: "rm/get_booking_by_id",
  CLICK_TO_CALL: "rm/click_to_call",
  GET_POSTIVE_CALLS_COMMENTS: "rm/get_postive_calls_commnets",
  ADD_POSTIVE_CALLS_COMMENT: "rm/add_comments",
  SET_COMMENTS: "rm/set_commnets",
  UPLOAD_FILE: "rm/upload",
  SET_LOADING: "rm/set_loading",
  UNASSIGNED_BOOKING_LOADING: "rm/unassigned_booking_loading",
  PHLEBO_HOLD_LOADING: "rm/phlebo_hold_loading",
  PHLEBO_LATE_LOADING: "rm/phlebo_late_loading",
  PHLEBO_VERY_LATE_LOADING: "rm/phlebo_very_late_loading",
  PHLEBO_NOT_STARTED_LOADING: "rm/phlebo_not_started_loading",
  SEARCH_CALL: "rm/serach",
  BOOKING_RESET: "rm/booking_reset",
  ADD_COMMENT: "positive_calls/add_comment",
  CREATE_CALL: "rm/createcall",
  GET_LEAD_SOURCE: "rm/getleadsource",
  GET_RECORDINGS: "rm/get_recordings",
  GET_BOOKINGS_COMMENTS: "rm/get_bookings_commnets",
  ADD_BOOKINGS_COMMENT: "rm/add_bookings_comments",
  GET_WHATSAPP_COMMENTS: "rm/get_whatsapp_comments",
  GET_WHATSAPP_COMMENTS_BY_LEAD: "rm/get_whatsapp_comments_by_lead",
  SEND_WHATSAPP_COMMENTS: "rm/send",
  GET_CALL_STATS: "rm/get_call_stats",
  RAISE_COMPLAINT: "rm/raise_complaint",
  GET_COMPLAINTS: "rm/get_complaints",
  ASSIGN_DOCTOR: "rm/assign_doctor",
  SEND_FEEDBACK: "rm/send_feedback",
  GET_AVAILABLE_SLOTS: "rm/get_available_slots",
  GET_ALL_LEADS: "rm/get_all_leads",
  LEAD_TRANSFER_BY_COMMA: "rm/set_comments",
  GET_PANEL_USERS: "rm/get_panel_user",
  GET_ASSIGNED_UNASSIGNED_BOOKING: "rm/get_assigned_unassigned_bookings",
  GET_ZONE: "rm/get_zone",
  UPDATE_ZONE: "rm/update_zone",
  CREATE_ZONE: "rm/create_zone",
  GET_TIMESLOTS: "rm/get_timeslots",
  GET_ATTENDENCE: "rm/get_attendence",
  ADD_REMARK_ON_CALL: "rm/add_remark_on_call",
  UPDATE_PHLEBOS: "rm/update_phlebos",
  GET_PHLEBO_COMMENTS: "rm/get_phlebo_commnets",
  GET_ZONE_COMMENTS: "rm/get_zone_comments",
  GET_CURRENT_DAY_BOOKING: "rm/get_current_day_booking",
  UPDATE_AREA: "rm/update_area",
  CREATE_AREA: "admin/create_area",
  GET_CITY_WISE_PHLEBOS: "rm/get_city_wise_phlebos",
  GET_PAYMENT_SEND_LINK: "rm/get_payment_send_link",
  GET_CENTER_INFORMATION: "rm/get_center_information",
  GET_NOTIFICATION_MESSAGE: "rm/get_notification_message",
  GET_VERIFICATION_BOOKING_DETAILS: "rm/get_verification_booking_details",
  GET_UNMASKED_NUMBER: "rm/get_unmasked_number",
  CHECK_GEOFENCE_AREA_MAMPMYINDIA: "rm/checking-geofence-area-mapmyindia",
  GET_QRCODE_DATA: "rm/get_qrcode_data",
  GET_PACKAGE_TUBE_DETAILS: "rm/get_package_tube_details",
  GET_PHLEBO_BY_PHONE: "rm/get_phlebo_by_phone",
  AUTO_ASSIGN_BOOKING: "rm/auto_assign_booking",
  GET_BOOKING_JOURNEY_DETAILS: "rm/booking_journey_details",
  GET_PAYMENT_INFORMATION: "rm/get_payment_information",
  GET_VERIFIED_BY_LIST: "rm/get_verified_by_list",
  UPDATE_PHLEBO_STATUS: "rm/update_phlebo_status",
  GET_ADDITIONAL_BOOKING: "rm/get_additional_booking",
  GET_SIGNATURE: "rm/get_signature",
  GET_PACKAGE_ALL_TUBE: "rm/get_package_all_tube",
  CREATE_BARCODE: "rm/create_barcode",
  POST_ADD_REMOVE_TEST: "rm/post_add_remove_test",
  GET_CC_PACKAGES: "rm/get_cc_packages",
  GET_USER_DETAILS: "rm/get_user_details",
  GET_MAMPMYINDIA_ACCESS_TOKEN: "rm/get-mapmyindia-access-token",
  GET_MAP_BOOKING_LIST: "rm/get_map_booking",
  GET_PHLEBO_CURRENT_LOCATION: "rm/phlebo_current_location",
  GET_AREA_COMMENTS: "rm/get_area_comments",
  GET_TICKET_DATA: "rm/get_ticket_data",
  GET_TICKET_CATEGORIES: "rm/get_ticket_category",
  GET_TICEKT_SUB_CATEGORIES: "rm/get_ticket_categories",
  CREATE_TASK_TICKET: "rm/create_task_ticket",
  GET_RED_TECH_COMMENT: "rm/redtech_comments/",
  POST_RED_TECH_COMMENT: "rm/postredtech_comments/",
  GET_SAMPLE_JOURNEY_COMMENTS: "rm/get_sample_journey_comments",
  GET_PHLENO_BATCH_TUBE: "rm/get_phlebo_batch_tube",
  UPDATE_RIDER_TO_LAB_STATUS: "rm/update_rider_to_lab_status",
  GET_SAMPLE_HANDOVER_DATA: "rm/getsample_handover_data",
  GET_RIDER_LIST: "rm/get_rider_list",
  GET_PHLEBO_RIDER_DUE_AMOUNT: "rm/get_phlebo_rider_due_amount",
  GET_BATCH_BOOKING_AMOUNT: "rm/get_batch_amount",
  GET_HIV_PACKAGES: "rm/get_hiv_package",
  GET_BIOMARKER_SCREENING: "rm/biomarker_screening",
  GET_BIOMARKER_ULTASOUND: "rm/biomarker_ultasound",
  GET_ADDITIONAL_MEMBER: "rm/additional_member",
  GET_BOOKING_OVERVIEW: "rm/booking_overview",
  CHECK_GEOFENCE_AREA: "rm/checking-geofence-area",
  GET_BATCH_PAYMENT: "rm/get_batch_payment",
  INITIATE_REFUND: "rm/initiate_refund",
  UPDATE_BATCH_PAYMENT: "rm/update_batch_payment",
  UPDATE_INITIATED_REFUND: "rm/update_initiate_refund",
  GET_LAB_CREDENTIAL: "rm/get_lab_cred",
  POST_PHLEBO_RIDER_MAP: "rm/post_phlebo_rider_map",
  UPDATE_PHLEBO_RIDER: "rm/update_phlebo_rider",
  GET_PHLEBO_RIDER: "rm/get_phlebo_rider",
  GET_ROUTES: "rm/get_routes",
  GET_BOOKING_COMPLAINTS: "rm/get_booking_complaints",
  UPDATE_TICKET_STATUS: "rm/update_ticekt_status",
  GET_TICKET_COMMENTS: "rm/ticket_comments",
  GET_BATCH_TRANSFER_DATA: "rm/get_batch_transfer_data",
  BATCH_AMOUT_RECEIPT_UPDATE: "rm/batch_amount_receipt_update",
  UPDATE_DISPOSITION: "rm/update_disposition",
  SET_ALL_BOOKING_LOADING: "rm/set_all_booking_loading",
  GET_PHLEBO_LEAVE_LIST: "rm/get_phlebo_leave_list",
  CREATE_PHLEBO_LEAVE: "rm/create_phlebo_leave",
  GET_PHLEBO_LIST_UNDER_ROUTE_MANAGER: "rm/get_phlebo_list_under_route_manager",
  UPDATE_PHLEBO_LEAVE: "rm/update_phlebo_leave",
  TABLE_RESET: "rm/table_reset",
  LOCATION_VERIFIED: "rm/location_verified",
  SEND_LOCATION_REQUEST: "rm/communication/send-location-request/",
  GET_ADDRESS_DETAILS_LAT_LONG: "rm/get-address-details-lat-long",
  SET_BOOKING_LOADING: "rm/table_loading",
  BIOMARKER_RESET: "rm/biomarker_reset",
  UPDATE_ATTENDANCE: "rm/update_attendance",
  GET_PHLEBO_AUTO_ASSIGN: "rm/get_phlebo_auto_assign",
  GET_ASSIGNED_UNASSIGNED_BOOKING_PILOT_V2: "rm/get_booking_List",
  POST_MULTIPLE_BOOKING_PAYMENT: "rm/panel/batch-amount-transfers/",
  GET_LEAD_SUBSCRIPTIONS_DATA: "rm/get-lead-subscriptions-data",
  GET_NEW_LEAD_DETAILS:"rm/lead_details",
  GET_SF_VIEWS: 'rm/get_views',
  GET_ZONE_DETAILS: 'pha/get_zone_details',
  GET_SF_VIEWS_DROPDOWN: 'rm/get_sf_views_dropdown',
  GET_REJECTED_BATCH_FOR_PHLEBO: 'rm/phlebo/rejected-batch/phlebo',
  GET_REJECTED_BATCH_FOR_RIDER: 'rm/phlebo/rejected-batch/rider',
  GET_RED_COIN: "rm/get_red_coins",
  GET_SPECIAL_PACKAGE_BOOKINGS: "rm/get_special_package_bookings",
  SPECIAL_PACKAGE_BOOKINGS_LOADING: "rm/special_package_bookings_loading",
};
interface UpdateAttendance {
  type: typeof ACTIONS.UPDATE_ATTENDANCE;
  payload: any;
}
interface getAssignedUnassignedBookingPilotV2 {
  type: typeof ACTIONS.GET_ASSIGNED_UNASSIGNED_BOOKING_PILOT_V2;
  payload: Array<any>;
}
interface GetPhleboAutoAssign {
  type: typeof ACTIONS.GET_PHLEBO_AUTO_ASSIGN;
  payload: Array<any>;
}
interface GetPinCode {
  type: typeof ACTIONS.GET_PIN_CODE;
  payload: Array<any>;
}
interface LocationVerified {
  type: typeof ACTIONS.LOCATION_VERIFIED;
  payload: boolean;
}
interface SendLocationRequest {
  type: typeof ACTIONS.SEND_LOCATION_REQUEST;
  payload: Object;
}
interface GetAddressDetailsLatLong {
  type: typeof ACTIONS.GET_ADDRESS_DETAILS_LAT_LONG;
  payload: Array<any>;
}
interface UpdatePhleboLeave {
  type: typeof ACTIONS.UPDATE_PHLEBO_LEAVE;
  payload: Array<any>;
}
interface GetPhleboUnderRm {
  type: typeof ACTIONS.GET_PHLEBO_LIST_UNDER_ROUTE_MANAGER;
  payload: Array<any>;
}
interface CreateLeaveList {
  type: typeof ACTIONS.CREATE_PHLEBO_LEAVE;
  payload: Array<any>;
}
interface getAssignedUnassignedBookingPilotV2 {
  type: typeof ACTIONS.GET_ASSIGNED_UNASSIGNED_BOOKING_PILOT_V2;
  payload: Array<any>;
}
interface UpdateBatchReceipt {
  type: typeof ACTIONS.BATCH_AMOUT_RECEIPT_UPDATE;
  payload: Array<any>;
}
interface GetBatchBookingAmount {
  type: typeof ACTIONS.GET_BATCH_BOOKING_AMOUNT;
  payload: boolean;
}
interface GetSampleJourneyComments {
  type: typeof ACTIONS.GET_SAMPLE_JOURNEY_COMMENTS;
  payload: Object;
}
interface GetPhleboLeaveList {
  type: typeof ACTIONS.GET_PHLEBO_LEAVE_LIST;
  payload: Object;
}
interface GetOnlineBookingList {
  type: typeof ACTIONS.GET_ONLINE_BOOKING_LIST;
  payload: boolean;
}
interface GetPhleboBatchTube {
  type: typeof ACTIONS.GET_PHLENO_BATCH_TUBE;
  payload: Array<any>;
}
interface UpdateRiderToLabStatus {
  type: typeof ACTIONS.UPDATE_RIDER_TO_LAB_STATUS;
  payload: Array<any>;
}
interface GetBookingOverview {
  type: typeof ACTIONS.GET_BOOKING_OVERVIEW;
  payload: Array<any>;
}
interface GetRiderList {
  type: typeof ACTIONS.GET_RIDER_LIST;
  payload: Array<any>;
}
interface GetSampleHandOver {
  type: typeof ACTIONS.GET_SAMPLE_HANDOVER_DATA;
  payload: Array<any>;
}
interface GetPhleboRiderDueAmount {
  type: typeof ACTIONS.GET_PHLEBO_RIDER_DUE_AMOUNT;
  payload: Array<any>;
}
interface GetBookingQueryTickets {
  type: typeof ACTIONS.GET_BOOKING_COMPLAINTS;
  payload: boolean;
}
interface GetPackageAllTube {
  type: typeof ACTIONS.GET_PACKAGE_ALL_TUBE;
  payload: Array<any>;
}
interface CreateBarcode {
  type: typeof ACTIONS.CREATE_BARCODE;
  payload: Array<any>;
}
interface GetAdditionalBooking {
  type: typeof ACTIONS.GET_ADDITIONAL_BOOKING;
  payload: Array<any>;
}
interface GetSignature {
  type: typeof ACTIONS.GET_SIGNATURE;
  payload: Array<any>;
}
interface UpdatePhleboStatus {
  type: typeof ACTIONS.UPDATE_PHLEBO_STATUS;
  payload: Array<any>;
}
interface AutoAssignBooking {
  type: typeof ACTIONS.AUTO_ASSIGN_BOOKING;
  payload: Array<any>;
}
interface GetUnmaskedNumber {
  type: typeof ACTIONS.GET_UNMASKED_NUMBER;
  payload: Array<any>;
}
interface CheckGeoFenceArea {
  type: typeof ACTIONS.CHECK_GEOFENCE_AREA;
  payload: Array<any>;
}
interface GetAreaComments {
  type: typeof ACTIONS.GET_AREA_COMMENTS;
  payload: Array<any>;
}
interface GetUserDetails {
  type: typeof ACTIONS.GET_USER_DETAILS;
  payload: Array<any>;
}
interface AddRemarkOnCall {
  type: typeof ACTIONS.ADD_REMARK_ON_CALL;
  payload: Object;
}
interface GetUserList {
  type: typeof ACTIONS.UPDATE_USERS_LIST;
  payload: Object;
}
interface ChangePassword {
  type: typeof ACTIONS.CHANGE_PASSWORD;
  payload: Object;
}
interface GetPaymentInfro {
  type: typeof ACTIONS.GET_PAYMENT_INFORMATION;
  payload: Array<any>;
}
interface CheckGeoFenceAreaMapMyIndia {
  type: typeof ACTIONS.CHECK_GEOFENCE_AREA_MAMPMYINDIA;
  payload: Array<any>;
}
interface UploadFile {
  type: typeof ACTIONS.UPLOAD_FILE;
  payload: Object;
}

interface GetCities {
  type: typeof ACTIONS.GET_CITIES;
  payload: Array<any>;
}

interface GetArea {
  type: typeof ACTIONS.GET_AREA;
  payload: Array<any>;
}
interface GetCollectionSlot {
  type: typeof ACTIONS.GET_COLLECTION_SLOT;
  payload: Array<any>;
}

interface GetAgents {
  type: typeof ACTIONS.GET_AGENTS;
  payload: Array<any>;
}

interface GetPhlebo {
  type: typeof ACTIONS.GET_PHLEBOS;
  payload: Array<any>;
}
interface GetAssignedUnassignedBooking {
  type: typeof ACTIONS.GET_ASSIGNED_UNASSIGNED_BOOKING;
  payload: Array<any>;
}

interface SetComments {
  type: typeof ACTIONS.SET_COMMENTS;
  payload: Object;
}
interface UpdateTicketStatus {
  type: typeof ACTIONS.UPDATE_TICKET_STATUS;
  payload: Array<any>;
}
interface GetMapBooking {
  type: typeof ACTIONS.GET_MAP_BOOKING_LIST;
  payload: Array<any>;
}

interface GetPositiveCallsComments {
  type: typeof ACTIONS.GET_POSTIVE_CALLS_COMMENTS;
  payload: Object;
}

interface AddPositiveCallsComments {
  type: typeof ACTIONS.ADD_POSTIVE_CALLS_COMMENT;
  payload: Object;
}

interface ClickToCall {
  type: typeof ACTIONS.CLICK_TO_CALL;
  payload: Array<any>;
}

interface GetPackages {
  type: typeof ACTIONS.GET_PACKAGES;
  payload: Array<any>;
}

interface GetPartners {
  type: typeof ACTIONS.GET_PARTNERS;
  payload: Array<any>;
}
interface GetPhleboAutoAssign {
  type: typeof ACTIONS.GET_PHLEBO_AUTO_ASSIGN;
  payload: Array<any>;
}
interface GetCoupons {
  type: typeof ACTIONS.GET_COUPONS;
  payload: Array<any>;
}

interface SetLoading {
  type: typeof ACTIONS.SET_LOADING;
  payload: boolean;
}
interface SetUnassignedBookingLoading {
  type: typeof ACTIONS.UNASSIGNED_BOOKING_LOADING;
  payload: boolean;
}
interface SetPhleboHoldBookingLoading {
  type: typeof ACTIONS.PHLEBO_HOLD_LOADING;
  payload: boolean;
}
interface SetPhleboLateBookingLoading {
  type: typeof ACTIONS.PHLEBO_LATE_LOADING;
  payload: boolean;
}
interface SetPhleboVeryLateBookingLoading {
  type: typeof ACTIONS.PHLEBO_VERY_LATE_LOADING;
  payload: boolean;
}
interface SetPhleboNotStartedBookingLoading {
  type: typeof ACTIONS.PHLEBO_NOT_STARTED_LOADING;
  payload: boolean;
}

interface CreateBooking {
  type: typeof ACTIONS.CREATE_BOOKING;
  payload: boolean;
}

interface UpdateBooking {
  type: typeof ACTIONS.UPDATE_BOOKING;
  payload: boolean;
}

interface GetBookings {
  type: typeof ACTIONS.GET_BOOKING_LIST;
  payload: boolean;
}
interface GetBookings1 {
  type: typeof ACTIONS.GET_BOOKING_LIST1;
  payload: boolean;
}
interface GetBookings2 {
  type: typeof ACTIONS.GET_BOOKING_LIST2;
  payload: boolean;
}
interface GetBookings3 {
  type: typeof ACTIONS.GET_BOOKING_LIST3;
  payload: boolean;
}
interface GetBookings4 {
  type: typeof ACTIONS.GET_BOOKING_LIST4;
  payload: boolean;
}
interface GetBookings5 {
  type: typeof ACTIONS.GET_BOOKING_LIST5;
  payload: boolean;
}

interface GetBookingById {
  type: typeof ACTIONS.GET_BOOKING_BY_ID;
  payload: boolean;
}

interface GetLeads {
  type: typeof ACTIONS.GET_LEADS;
  payload: boolean;
}
interface GetPhleboCurrentLocation {
  type: typeof ACTIONS.GET_PHLEBO_CURRENT_LOCATION;
  payload: Array<any>;
}
interface PostMultipleBookingPayment {
  type: typeof ACTIONS.POST_MULTIPLE_BOOKING_PAYMENT;
  payload: Array<any>;
}

interface GetLeadDetails {
  type: typeof ACTIONS.GET_LEAD_DETAILS;
  payload: boolean;
}

interface GetLeadById {
  type: typeof ACTIONS.GET_LEAD_BY_ID;
  payload: boolean;
}

interface ResetLeadDetails {
  type: typeof ACTIONS.LEAD_DETAILS_RESET;
  payload: boolean;
}

interface GetPinCode {
  type: typeof ACTIONS.GET_PIN_CODE;
  payload: Array<any>;
}
interface ChangePassword {
  type: typeof ACTIONS.CHANGE_PASSWORD;
  payload: Object;
}
interface GetCities {
  type: typeof ACTIONS.GET_CITIES;
  payload: Array<any>;
}
interface GetCollectionSlot {
  type: typeof ACTIONS.GET_COLLECTION_SLOT;
  payload: Array<any>;
}

interface GetArea {
  type: typeof ACTIONS.GET_AREA;
  payload: Array<any>;
}
interface GetPhlebo {
  type: typeof ACTIONS.GET_PHLEBOS;
  payload: Array<any>;
}

interface GetAgents {
  type: typeof ACTIONS.GET_AGENTS;
  payload: Array<any>;
}

interface SetComments {
  type: typeof ACTIONS.SET_COMMENTS;
  payload: Object;
}

interface GetPositiveCallsComments {
  type: typeof ACTIONS.GET_POSTIVE_CALLS_COMMENTS;
  payload: Object;
}

interface AddPositiveCallsComments {
  type: typeof ACTIONS.ADD_POSTIVE_CALLS_COMMENT;
  payload: Object;
}
interface ClickToCall {
  type: typeof ACTIONS.CLICK_TO_CALL;
  payload: Array<any>;
}

interface GetPackages {
  type: typeof ACTIONS.GET_PACKAGES;
  payload: Array<any>;
}

interface GetPartners {
  type: typeof ACTIONS.GET_PARTNERS;
  payload: Array<any>;
}

interface GetCoupons {
  type: typeof ACTIONS.GET_COUPONS;
  payload: Array<any>;
}

interface SetLoading {
  type: typeof ACTIONS.SET_LOADING;
  payload: boolean;
}
interface GetMapMyIndiaAccessToken {
  type: typeof ACTIONS.GET_MAMPMYINDIA_ACCESS_TOKEN;
  payload: Array<any>;
}

interface CreateBooking {
  type: typeof ACTIONS.CREATE_BOOKING;
  payload: boolean;
}

interface UpdateBooking {
  type: typeof ACTIONS.UPDATE_BOOKING;
  payload: boolean;
}

interface GetBookings {
  type: typeof ACTIONS.GET_BOOKING_LIST;
  payload: boolean;
}

interface GetBookingById {
  type: typeof ACTIONS.GET_BOOKING_BY_ID;
  payload: boolean;
}

interface GetLeads {
  type: typeof ACTIONS.GET_LEADS;
  payload: boolean;
}

interface GetLeadDetails {
  type: typeof ACTIONS.GET_LEAD_DETAILS;
  payload: boolean;
}

interface GetLeadById {
  type: typeof ACTIONS.GET_LEAD_BY_ID;
  payload: boolean;
}

interface ResetLeadDetails {
  type: typeof ACTIONS.LEAD_DETAILS_RESET;
  payload: boolean;
}
interface SearchCall {
  type: typeof ACTIONS.SEARCH_CALL;
  payload: Object;
}
interface SearchCall {
  type: typeof ACTIONS.SEARCH_CALL;
  payload: Object;
}
interface GetAgentByUserGroup {
  type: typeof ACTIONS.GET_AGENTS_BY_USERGROUP;
  payload: Object;
}

interface CreateAdditionalBooking {
  type: typeof ACTIONS.CREATE_ADDITIONAL_BOOKING;
  payload: Object;
}
interface UpdateAdditionalBooking {
  type: typeof ACTIONS.UPDATE_ADDITIONAL_BOOKING;
  payload: Object;
}
interface GetZone {
  type: typeof ACTIONS.GET_ZONE;
  payload: Object;
}
interface CreateZone {
  type: typeof ACTIONS.CREATE_ZONE;
  payload: Object;
}
interface UpdateZone {
  type: typeof ACTIONS.UPDATE_ZONE;
  payload: Object;
}
interface GetTimeslots {
  type: typeof ACTIONS.GET_TIMESLOTS;
  payload: Object;
}

interface GetAttendence {
  type: typeof ACTIONS.GET_ATTENDENCE;
  payload: Object;
}
interface UpdatePhlebos {
  type: typeof ACTIONS.UPDATE_PHLEBOS;
  payload: Object;
}
interface GetPhleboComment {
  type: typeof ACTIONS.GET_PHLEBO_COMMENTS;
  payload: Object;
}
interface GetZoneComment {
  type: typeof ACTIONS.GET_ZONE_COMMENTS;
  payload: Object;
}
interface GetCurrentDayBooking {
  type: typeof ACTIONS.GET_CURRENT_DAY_BOOKING;
  payload: Object;
}
interface GetCityWisePhlebos {
  type: typeof ACTIONS.GET_CITY_WISE_PHLEBOS;
  payload: Object;
}
interface UpdateArea {
  type: typeof ACTIONS.UPDATE_AREA;
  payload: Object;
}
interface CreateArea {
  type: typeof ACTIONS.CREATE_AREA;
  payload: Object;
}
interface GetComplaints {
  type: typeof ACTIONS.GET_COMPLAINTS;
  payload: Object;
}
interface GetPaymentSendLink {
  type: typeof ACTIONS.GET_PAYMENT_SEND_LINK;
  payload: Object;
}
interface GetCenterInfo {
  type: typeof ACTIONS.GET_CENTER_INFORMATION;
  payload: Array<any>;
}
interface GetNotificationMessage {
  type: typeof ACTIONS.GET_NOTIFICATION_MESSAGE;
  payload: Array<any>;
}
interface GetVerificationBookingDetails {
  type: typeof ACTIONS.GET_VERIFICATION_BOOKING_DETAILS;
  payload: Array<any>;
}
interface GetQRCodeData {
  type: typeof ACTIONS.GET_QRCODE_DATA;
  payload: Array<any>;
}
interface GetBookingJourney {
  type: typeof ACTIONS.GET_BOOKING_JOURNEY_DETAILS;
  payload: Array<any>;
}
interface GetPackageTubeDetails {
  type: typeof ACTIONS.GET_PACKAGE_TUBE_DETAILS;
  payload: Array<any>;
}
interface GetPhleboByPhone {
  type: typeof ACTIONS.GET_PHLEBO_BY_PHONE;
  payload: Object;
}
interface GetVerifiedByList {
  type: typeof ACTIONS.GET_VERIFIED_BY_LIST;
  payload: Object;
}
interface PostAddRemoveTest {
  type: typeof ACTIONS.POST_ADD_REMOVE_TEST;
  payload: Array<any>;
}
interface getCCPackage {
  type: typeof ACTIONS.GET_CC_PACKAGES;
  payload: Array<any>;
}

interface UpdateActionDisposition {
  type: typeof ACTIONS.UPDATE_DISPOSITION;
  payload: Array<any>;
}
interface GetRedTechComment {
  type: typeof ACTIONS.GET_RED_TECH_COMMENT;
  payload: Array<any>;
}
interface GetTicketData {
  type: typeof ACTIONS.GET_TICKET_DATA;
  payload: Object;
}
interface GetTicketCategory {
  type: typeof ACTIONS.GET_TICKET_CATEGORIES;
  payload: Object;
}
interface GetTicketSubCategories {
  type: typeof ACTIONS.GET_TICEKT_SUB_CATEGORIES;
  payload: Object;
}
interface PostRedTechComment {
  type: typeof ACTIONS.POST_RED_TECH_COMMENT;
  payload: Object;
}
interface CreateTaskTicket {
  type: typeof ACTIONS.CREATE_TASK_TICKET;
  payload: Array<any>;
}
interface GetHivPackage {
  type: typeof ACTIONS.GET_HIV_PACKAGES;
  payload: Object;
}
interface GetBiomarkerScreening {
  type: typeof ACTIONS.GET_BIOMARKER_SCREENING;
  payload: Object;
}
interface GetBiomarkerUltasound {
  type: typeof ACTIONS.GET_BIOMARKER_ULTASOUND;
  payload: Object;
}
interface GetAdditionalMember {
  type: typeof ACTIONS.GET_ADDITIONAL_MEMBER;
  payload: Array<any>;
}
interface GetBatchPayment {
  type: typeof ACTIONS.GET_BATCH_PAYMENT;
  payload: Array<any>;
}
interface InitiateRefund {
  type: typeof ACTIONS.INITIATE_REFUND;
  payload: Array<any>;
}
interface UpdateBatchPayment {
  type: typeof ACTIONS.UPDATE_BATCH_PAYMENT;
  payload: Array<any>;
}
interface UpdateInitiateRefund {
  type: typeof ACTIONS.UPDATE_INITIATED_REFUND;
  payload: Array<any>;
}
interface GetLabCredential {
  type: typeof ACTIONS.GET_LAB_CREDENTIAL;
  payload: Array<any>;
}
interface PostPhleboRiderMapping {
  type: typeof ACTIONS.POST_PHLEBO_RIDER_MAP;
  payload: Array<any>;
}
interface UpdatePhleboRider {
  type: typeof ACTIONS.UPDATE_PHLEBO_RIDER;
  payload: Array<any>;
}
interface GetPhleboRider {
  type: typeof ACTIONS.GET_PHLEBO_RIDER;
  payload: Array<any>;
}
interface GetRoutes {
  type: typeof ACTIONS.GET_ROUTES;
  payload: Array<any>;
}
interface GetTicketComments {
  type: typeof ACTIONS.GET_TICKET_COMMENTS;
  payload: Array<any>;
}
interface GetBatchTransferData {
  type: typeof ACTIONS.GET_BATCH_TRANSFER_DATA;
  payload: Array<any>;
}
interface GetBookingsLoader {
  type: typeof ACTIONS.SET_ALL_BOOKING_LOADING;
  payload: boolean;
}

interface ResetTable {
  type: typeof ACTIONS.TABLE_RESET;
  payload: boolean;
}
interface SetLadingTable {
  type: typeof ACTIONS.SET_BOOKING_LOADING;
  payload: boolean;
}
interface BiomarkerReset {
  type: typeof ACTIONS.BIOMARKER_RESET;
  payload: boolean;
}
interface PostMultipleBookingPayment {
  type: typeof ACTIONS.POST_MULTIPLE_BOOKING_PAYMENT;
  payload: Array<any>;
}
interface GetLeadSubscriptionsData {
  type: typeof ACTIONS.GET_LEAD_SUBSCRIPTIONS_DATA;
  payload: Array<any>;
}

interface GetViews {
  type: typeof ACTIONS.GET_SF_VIEWS;
  payload: Array<any>;
}
interface GetZoneDetails {
  type: typeof ACTIONS.GET_ZONE_DETAILS;
  payload: any;
}
interface GetNewLeadDetails {
  type: typeof ACTIONS.GET_NEW_LEAD_DETAILS;
  payload: any;
}

interface GetSfViewsDropdown {
  type: typeof ACTIONS.GET_SF_VIEWS_DROPDOWN;
  payload: any;
}
interface GetRejectedBatch {
  type: typeof ACTIONS.GET_REJECTED_BATCH_FOR_PHLEBO;
  payload: any;
}
interface GetRejectedBatchForRider {
  type: typeof ACTIONS.GET_REJECTED_BATCH_FOR_RIDER;
  payload: any;
}

interface GetRedCoins {
  type: typeof ACTIONS.GET_RED_COIN;
  payload: Array<any>;
}
interface GetAllSpecialPackageBookings {
  type: typeof ACTIONS.GET_SPECIAL_PACKAGE_BOOKINGS;
  payload: Array<any>;
}
interface SetSpecialPackageLoading {
  type: typeof ACTIONS.SPECIAL_PACKAGE_BOOKINGS_LOADING;
  payload: boolean;
}

export type PhleboAdminTypes =
  | UpdateAttendance
  | SearchCall
  | GetAssignedUnassignedBooking
  | CreateAdditionalBooking
  | UpdateAdditionalBooking
  | GetPinCode
  | ChangePassword
  | GetCities
  | GetArea
  | GetAgents
  | GetPhlebo
  | GetAgentByUserGroup
  | GetPackages
  | CreateBooking
  | UpdateBooking
  | GetPartners
  | GetCoupons
  | GetBookings
  | GetBookings1
  | GetBookings2
  | GetBookings3
  | GetBookings4
  | GetBookings5
  | GetBookingById
  | SetLoading
  | SetUnassignedBookingLoading
  | SetPhleboHoldBookingLoading
  | SetPhleboLateBookingLoading
  | SetPhleboVeryLateBookingLoading
  | SetPhleboNotStartedBookingLoading
  | GetLeadDetails
  | GetLeads
  | GetLeadById
  | ResetLeadDetails
  | ClickToCall
  | GetPositiveCallsComments
  | AddPositiveCallsComments
  | SetComments
  | UploadFile
  | GetPinCode
  | ChangePassword
  | GetCities
  | GetArea
  | GetAgents
  | GetPhlebo
  | GetPackages
  | CreateBooking
  | UpdateBooking
  | GetPartners
  | GetCoupons
  | GetBookings
  | GetBookingById
  | GetPhleboCurrentLocation
  | SetLoading
  | GetLeadDetails
  | GetLeads
  | GetLeadById
  | ResetLeadDetails
  | ClickToCall
  | GetPositiveCallsComments
  | AddPositiveCallsComments
  | SetComments
  | SearchCall
  | GetZone
  | CreateZone
  | UpdateZone
  | GetTimeslots
  | GetAttendence
  | GetCollectionSlot
  | GetUserList
  | AddRemarkOnCall
  | UpdatePhlebos
  | GetPhleboComment
  | GetZoneComment
  | GetCurrentDayBooking
  | UpdateArea
  | CreateArea
  | GetCityWisePhlebos
  | GetComplaints
  | GetPaymentSendLink
  | GetCenterInfo
  | GetNotificationMessage
  | GetVerificationBookingDetails
  | GetUnmaskedNumber
  | GetQRCodeData
  | GetPackageTubeDetails
  | GetPhleboByPhone
  | AutoAssignBooking
  | GetBookingJourney
  | GetPaymentInfro
  | GetVerifiedByList
  | UpdatePhleboStatus
  | GetAdditionalBooking
  | GetSignature
  | GetPackageAllTube
  | CreateBarcode
  | PostAddRemoveTest
  | getCCPackage
  | GetUserDetails
  | GetMapMyIndiaAccessToken
  | GetMapBooking
  | GetAreaComments
  | GetTicketData
  | GetTicketCategory
  | GetTicketSubCategories
  | CreateTaskTicket
  | GetRedTechComment
  | PostRedTechComment
  | GetSampleJourneyComments
  | GetPhleboBatchTube
  | GetPhleboRiderDueAmount
  | UpdateRiderToLabStatus
  | GetRiderList
  | GetBatchBookingAmount
  | GetHivPackage
  | GetBiomarkerScreening
  | GetBiomarkerUltasound
  | GetAdditionalMember
  | CheckGeoFenceArea
  | GetBookingOverview
  | CheckGeoFenceAreaMapMyIndia
  | GetBookingOverview
  | InitiateRefund
  | GetBatchPayment
  | UpdateBatchPayment
  | UpdateInitiateRefund
  | GetOnlineBookingList
  | PostPhleboRiderMapping
  | UpdatePhleboRider
  | GetPhleboRider
  | GetRoutes
  | GetBookingQueryTickets
  | UpdateTicketStatus
  | GetTicketComments
  | GetLabCredential
  | GetBatchTransferData
  | UpdateBatchReceipt
  | UpdateActionDisposition
  | GetBookingsLoader
  | GetPhleboLeaveList
  | CreateLeaveList
  | GetPhleboUnderRm
  | UpdatePhleboLeave
  | ResetTable
  | SendLocationRequest
  | LocationVerified
  | GetAddressDetailsLatLong
  | getAssignedUnassignedBookingPilotV2
  | GetPhleboAutoAssign
  | BiomarkerReset
  | SetLadingTable
  |PostMultipleBookingPayment
  | GetAddressDetailsLatLong
  | GetLeadSubscriptionsData
  |GetNewLeadDetails
  | GetZoneDetails
  | GetViews
  | GetZoneDetails
  |GetSfViewsDropdown
  |GetRejectedBatch
  |GetRejectedBatchForRider
  | GetRedCoins
  | GetAllSpecialPackageBookings
  | SetSpecialPackageLoading