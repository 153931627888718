import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";
import Modal from "@mui/material/Modal";
import { useHistory } from "react-router-dom";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import {
  TableContainer,
  Table,
  Chip,
  TableHead,
  TableBody,
  Switch,
  TableRow,
  TableCell,
  Button,
  Paper,
  TextField,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import EditUser from "../SubAdmin/CreateUser";
import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TableFooter, TablePagination } from "@material-ui/core";
import {
  getCityWisePackage,
  getCities,
  updateTatPackage,
} from "../../actions/productMangerAction";
let width = window.innerWidth;
let isMobile = width <= 500;

const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    marginTop: "2rem",
    padding: "0.5rem 2rem",
    width: "90%",
  },
  head: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "2rem",
  },
  tableContainer: {
    marginTop: "2rem",
    position: "sticky",
    top: "200px",
  },
}));

const DefaultSwitch: any = withStyles({
  switchBase: {
    color: "red",
    "&$checked": {
      color: "green",
    },
    "&$checked + $track": {
      backgroundColor: "green",
    },
  },
  checked: {},
  track: {
    backgroundColor: "red",
  },
})(Switch);

interface Props {
  getCities: any;
  cities: any;
  citywisepackage: any;
  getCityWisePackage: any;
  setOpen: any;
  open: any;
  data: any;
  updateTatPackage: any;
  tatpackageupdated: any;
}

const ProductManagementModal: React.FC<Props> = ({
  getCities,
  cities,
  getCityWisePackage,
  citywisepackage,
  updateTatPackage,
  tatpackageupdated,
  setOpen,
  open,
  data,

}) => {
  const classes = useStyles();
  const timer = useRef<any>(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [editUser, setEditUser] = useState<any>({});
  const [homechecked, setHomeChecked] = useState<boolean>(true);
  const [isActive, setIsActive] = useState(false);
  const [packagePrice, setPackagePrice] = useState<string>("");
  const [offerPrice, setOfferPrice] = useState<string>("");
  const [addonPrice, setAddonPrice] = useState<string>("");
  const [tatTime, setTatTime] = useState<string>("");
  const [tat, setTat] = useState<string>("");
  const [city, setCity] = useState<any>([]);
  const history = useHistory();

  const handleHomeChange = (event: any) => {
    setHomeChecked(event.target.checked);
  };

  const handleClose = () => {
    setOpen(false);
  };


  useEffect(() => {
    if(editUser){
      setTatTime(editUser.tat_time);
      setPackagePrice(editUser.package_price);
      setOfferPrice(editUser.offer_price);
      setAddonPrice(editUser.addon_price);
      setIsActive(editUser.is_active);
    }
  }, [editUser])
  

  const handleUser = async () => {
    let body: any = {
      is_active: isActive,
      offer_price: offerPrice,
      addon_price: addonPrice,
      package_price: packagePrice,
      tat_time: tatTime,
    };
    await updateTatPackage(editUser?.id,body);
     setEditUser({});
  };

  useEffect(() => {
    setIsActive(editUser?.is_active);
  }, [editUser]);

  const submitForm = async (e: any) => {
    e.preventDefault();
    const body = {};
    // setSyncSubmit(true);
    // await getSyncData(`${syncId}/`, body);
    setTimeout(() => {
      // getAllBookings(pageNumber);
      // setSyncSubmit(false);
    }, 1000);

    setOpen(false);
  };

  useEffect(() => {
    getCities();
  }, []);

  useEffect(() => {
    if (data.id) {
      getCityWisePackage(data.id);
    }
  }, [data]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url = citywisepackage.links.next;

      getCityWisePackage(url.substring(url.indexOf("?") + 1));
    } else if (newPage < page) {
      let url = citywisepackage.links.previous;
      getCityWisePackage(
        url.indexOf("?") === -1 ? "" : url.substring(url.indexOf("?") + 1)
      );
    }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Paper elevation={5} className={classes.paper}>
              <form onSubmit={submitForm}>
                <div className={classes.head}>
                  <h4
                    style={{
                      marginBottom: "1rem",
                    }}
                  >
                    TAT TABLE
                  </h4>
                  <CloseIcon onClick={() => setOpen(false)} />
                </div>
                <div>
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={() => {
                      history.push(`/dashboard/pm/tat/create/${data.id}`);
                    }}
                    >
                  Create Tat
                </Button>
              </div>
              <div
                className="data-table"
                style={{ width: "100%", marginTop: "2rem" }}
              >
                <TableContainer
                  className={classes.tableContainer}
                  component={Paper}
                  style={{ maxHeight: "580px" }}
                >
                  <Table stickyHeader aria-label="simple table">
                    <TableHead>
                      <StyledTableRow>
                        <StyledTableCell align="center">
                          City
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          TAT
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Package Price
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Offer Price
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Addon Price
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Is Active
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Edit
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableHead>
                    <TableBody>
                      {citywisepackage && citywisepackage?.results ? (
                        citywisepackage?.results.map((item: any) => {
                          return (
                            <StyledTableRow key={item.id}>
                              <StyledTableCell align="center">
                                {item.city.display_name
                                  ? item.city.display_name
                                  : "NA"}
                              </StyledTableCell>
                               <StyledTableCell align="center">
                              {editUser.id === item?.id ? (
                              <TextField
                                className="input"
                                name="tat time"
                                variant="outlined"
                                value={tatTime}
                                style={{
                                  width: "100%",
                                  margin: "0",
                                  padding: "0",
                                }}
                                onChange={(e) =>
                                  setTatTime(e.target.value as string)
                                }
                                required
                              >
                              </TextField>
                            ) : (
                              item.tat_time
                            )}
                          </StyledTableCell>
                                <StyledTableCell align="center">
                              {editUser.id === item?.id ? (
                              <TextField
                                className="input"
                                name="package price"
                                type="number"
                                variant="outlined"
                                value={packagePrice}
                                style={{
                                  width: "100%",
                                  margin: "0",
                                  padding: "0",
                                }}
                                onChange={(e) =>
                                  setPackagePrice(e.target.value as string)
                                }
                                required
                              >
                              </TextField>
                            ) : (
                              item.package_price
                            )}
                          </StyledTableCell>
                               <StyledTableCell align="center">
                              {editUser.id === item?.id ? (
                              <TextField
                                className="input"
                                name="offer price"
                                type="number"
                                variant="outlined"
                                value={offerPrice}
                                style={{
                                  width: "100%",
                                  margin: "0",
                                  padding: "0",
                                }}
                                onChange={(e) =>
                                  setOfferPrice(e.target.value as string)
                                }
                                required
                              >
                              </TextField>
                            ) : (
                              item.offer_price
                            )}
                          </StyledTableCell>
                              <StyledTableCell align="center">
                              {editUser.id === item?.id ? (
                              <TextField
                                className="input"
                                name="addon price"
                                type="number"
                                variant="outlined"
                                value={addonPrice}
                                style={{
                                  width: "100%",
                                  margin: "0",
                                  padding: "0",
                                }}
                                onChange={(e) =>
                                  setAddonPrice(e.target.value as string)
                                }
                                required
                              >
                              </TextField>
                            ) : (
                              item.addon_price
                            )}
                          </StyledTableCell>
                              <StyledTableCell align="center">
                                {editUser.id === item.id ? (
                                  <Switch
                                    defaultChecked={item.is_active}
                                    checked={isActive}
                                    onChange={(e) =>
                                      setIsActive(e.target.checked)
                                    }
                                    name="checkedA"
                                    color="primary"
                                    inputProps={{
                                      "aria-label": "secondary checkbox",
                                    }}
                                  />
                                ) : (
                                  <DefaultSwitch
                                    checked={item.is_active}
                                    name="checkedA"
                                    inputProps={{
                                      "aria-label": "secondary checkbox",
                                    }}
                                    disableRipple
                                  />
                                )}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {editUser?.id === item?.id ? (
                                  <Button
                                    color="primary"
                                    variant="text"
                                    onClick={() => handleUser()}
                                  >
                                    Submit
                                  </Button>
                                ) : (
                                  <Button
                                    color="primary"
                                    variant="text"
                                    onClick={() => setEditUser(item)}
                                  >
                                    Edit
                                  </Button>
                                )}
                              </StyledTableCell>
                            </StyledTableRow>
                          );
                        })
                      ) : (
                        <p style={{ textAlign: "center" }}>No data found</p>
                      )}
                    </TableBody>
                    {citywisepackage && citywisepackage.count > 0 && (
                      <TableFooter>
                        <TablePagination
                          colSpan={6}
                          count={citywisepackage.count || 0}
                          rowsPerPageOptions={[]}
                          rowsPerPage={rowsPerPage}
                          onChangeRowsPerPage={handleChangeRowsPerPage}
                          page={page}
                          SelectProps={{
                            native: true,
                          }}
                          onPageChange={handleChangePage}
                        />
                      </TableFooter>
                    )}
                  </Table>
                </TableContainer>
              </div>
            </form>
          </Paper>
        </div>
      </Fade>
    </Modal>
    </div >
  );
};

const mapStateToProps = (state: any) => ({
  citywisepackage: state.ProductManagerReducer.citywisepackage,
  cities: state.ProductManagerReducer.cities,
  tatpackageupdated: state.ProductManagerReducer.tatpackageupdated,

});

export default connect(mapStateToProps, {
  getCityWisePackage,
  getCities,
  updateTatPackage,
})(ProductManagementModal);
