import React, { useState, useEffect, useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Grid,
  TextField,
  withStyles,
  Select,
  MenuItem,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@mui/material/Button";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import {
  getRiderCount,
  getRiderList,
  clickToCallRiderCount,
  getLabCredential
} from "../actions/LogisticsPanelAction";
import { CALL_DISABLE_TIME } from "../../CallTimeDisable";
import CallIcon from "@material-ui/icons/Call";
import { genrateRiderCountFilter } from "../../helpers/generateUrl";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    table: {
      maxWidth: "100%",
    },
    tableHead: {
      background: "rgb(146, 74, 145)!important",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    tableHeadCell: {
      color: "#fff",
      fontWeight: "bold",
      textAlign: "center",
    },
    tableCollSpan: {
      color: "#fff",
      fontWeight: "bold",
      textAlign: "center",
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
    poppaper: {
      border: "1px solid",
      padding: theme.spacing(1),
      backgroundColor: theme.palette.background.paper,
    },
  })
);
const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);
interface SampleHandOverProps {
  getRiderCount: any;
  riderCount: any;
  getRiderList: any;
  riderList: any;
  loading: any;
  clickToCallRiderCount: any;
  getLabCredential: any;
  labCredential: any;
}

const BookingsTable: React.FC<SampleHandOverProps> = ({
  getRiderCount,
  riderCount,
  getRiderList,
  riderList,
  loading,
  clickToCallRiderCount,
  getLabCredential,
  labCredential
}) => {
  const classes = useStyles();
  const history = useHistory();
  const timer = useRef<any>(0);
  const [page, setPage] = React.useState(0);
  const [riderName, setRiderName] = useState<any>("");
  const [start_date, setStart_date] = useState("");
  const [end_date, setEnd_date] = useState("");
  const [isStatus, setIsStatus] = useState("none");
  const [labDetail, setLabDetails] = React.useState<any>("");
  const [labDetailName, setLabDetailsName] = React.useState<any>("");
  const current = new Date();
  var today = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  let yyyy = today.getFullYear();
  let currentDate = yyyy + "-" + mm + "-" + dd;
  const [callButton, setCallButton] = useState<boolean>(true);
  useEffect(() => {
    getRiderCount();
    getRiderList(`?usergroup=Rider`);
  }, []);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url = riderCount.links && riderCount.links.next.split("?")[1];
      getRiderCount(url ? `?${url}` : "");
    } else if (newPage < page) {
      let url = riderCount.links && riderCount.links.previous.split("?")[1];
      getRiderCount(url ? `?${url}` : "");
    }
    setPage(newPage as number);
  };
  const filterSampleHandover = () => {
    const body: any = {
      rider: riderName,
      start_date,
      end_date,
      rider_action: isStatus,
      lab: labDetail,
    };
    const url = genrateRiderCountFilter(body).substring(2);
    getRiderCount(`?${url}`);
    setPage(0);
  };

  const handleCallClick = (lead: number, type: string) => {
    setCallButton(false);
    clickToCallRiderCount(`${lead}/rider`, type);
    setTimeout(() => {
      setCallButton(true);
    }, CALL_DISABLE_TIME);
  };

  console.log(labCredential,"labCredential")

  return (
    <div style={{ width: "100%" }}>
      <div className={classes.toolbar} />
      <Grid item xs={12} sm={8}>
        <h2>Rider Level Count</h2>
      </Grid>
      <Grid
        container
        spacing={1}
        direction="row"
        style={{ alignItems: "left" }}
      >
        <Grid item xs={12} sm={6} md={3}>
          <Autocomplete
            id="booking_source"
            onChange={(event, newValue) => {
              if (newValue) {
                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                setRiderName(obj?.user?.username);
              }
            }}
            options={riderList?.results || []}
            freeSolo
            blurOnSelect
            aria-required
            getOptionLabel={(option: any) =>
              option?.user && option?.user?.username
            }
            onInputChange={(event, newInputValue) => {
              clearTimeout(timer.current);
              timer.current = setTimeout(() => {
                getRiderList(`?usergroup=Rider&rider=${newInputValue}`);

              }, 1000);
              if (newInputValue.length === 0) {
                setRiderName("");
              }
            }}
            renderInput={(params) => (
              <TextField
                className="input"
                {...params}
                label="Rider"
                variant="outlined"
                style={{ width: "100%", margin: "0", padding: "0" }}
                inputProps={{
                  ...params.inputProps,
                  onKeyDown: (e) => {
                    if (e.key === "Enter") {
                      e.stopPropagation();
                    }
                  },
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="lab"
              onChange={(event, newValue) => {
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  setLabDetails(obj.id);
                }
              }}
              inputValue={labDetailName}
              loading={loading}
              options={labCredential.results || []}
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getLabCredential(`?name=${newInputValue}`);
                }, 1000);

                setLabDetailsName(newInputValue);
                if (newInputValue === "") {
                  setLabDetails("");
                }
              }}
              freeSolo
              blurOnSelect
              aria-required
              getOptionLabel={(option: any) => `${option?.name}`}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Lab"
                  variant="outlined"
                  fullWidth
                  required
                  inputProps={{
                    ...params.inputProps,
                    onKeyDown: (e) => {
                      if (e.key === "Enter") {
                        e.stopPropagation();
                      }
                    },
                  }}
                />
              )}
            />
          </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Select
            className="input"
            name="Status_Rider"
            variant="outlined"
            value={isStatus}
            style={{ width: "100%", height: "40px" }}
            onChange={(e) => setIsStatus(e.target.value as any)}
          >
            <MenuItem disabled value={"none"}>
              Rider Action
            </MenuItem>
            <MenuItem value={"accept"}>Accept</MenuItem>
            <MenuItem value={"reject"}>Reject</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <TextField
            className="input"
            name="start_date"
            id="noTyping"
            type="date"
            label="Start Date"
            value={start_date}
            variant="outlined"
            onChange={(e) => setStart_date(e.target.value as string)}
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <TextField
            name="end_date"
            id="noTyping"
            type="date"
            value={end_date}
            className="input"
            label="End Date"
            variant="outlined"
            onChange={(e) => setEnd_date(e.target.value as string)}
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Button
            variant="contained"
            color="secondary"
            disabled={loading}
            fullWidth
            onClick={filterSampleHandover}
          >
            Filter
          </Button>
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => history.push("/dashboard/lp/rider_count")}
          >
            Reset
          </Button>
        </Grid>
      </Grid>
      <TableContainer
        className={classes.tableContainer}
        component={Paper}
        style={{ maxHeight: "550px" }}
      >
        <Table stickyHeader aria-label="simple table">
          {riderCount?.results && riderCount?.results?.length === 0 && (
            <h6 style={{ textAlign: "center" }}>No Data Found</h6>
          )}
          <TableHead className={classes.tableHead}>
            <StyledTableRow>
              <StyledTableCell className={classes.tableHeadCell}>
                Rider
              </StyledTableCell>
              <StyledTableCell className={classes.tableHeadCell}>
                 Lab
              </StyledTableCell>
              <StyledTableCell className={classes.tableCollSpan}>
                <h5>PHLEBO</h5>
                <div style={{ border: "2px solid white" }}>
                  <StyledTableCell className={classes.tableHeadCell}>
                    Total Booking (Approx)
                  </StyledTableCell>
                  <StyledTableCell className={classes.tableHeadCell}>
                    Awaiting Rider Action
                  </StyledTableCell>
                  <StyledTableCell className={classes.tableHeadCell}>
                    Picked
                  </StyledTableCell>
                  <StyledTableCell className={classes.tableHeadCell}>
                    Transferred to Lab
                  </StyledTableCell>
                  <StyledTableCell className={classes.tableHeadCell}>
                    Cash Pending with Rider
                  </StyledTableCell>
                  <StyledTableCell className={classes.tableHeadCell}>
                    Cash Transferred
                  </StyledTableCell>
                </div>
              </StyledTableCell>
              <StyledTableCell className={classes.tableCollSpan}>
                <h5>CC</h5>
                <div style={{ border: "2px solid white" }}>
                  <StyledTableCell className={classes.tableHeadCell}>
                    Total bookings
                  </StyledTableCell>
                  <StyledTableCell className={classes.tableHeadCell}>
                    Picked bookings
                  </StyledTableCell>
                  <StyledTableCell className={classes.tableHeadCell}>
                    <br />
                    Transferred to Lab
                    <br />.
                  </StyledTableCell>
                </div>
              </StyledTableCell>
              <StyledTableCell className={classes.tableCollSpan}>
                <h5>DC</h5>
                <div style={{ border: "2px solid white" }}>
                  <StyledTableCell className={classes.tableHeadCell}>
                    Picked Bookings
                  </StyledTableCell>
                  <StyledTableCell className={classes.tableHeadCell}>
                    Transferred to Lab
                  </StyledTableCell>
                  <StyledTableCell className={classes.tableHeadCell}>
                    Cash Pending with Rider
                  </StyledTableCell>
                  <StyledTableCell className={classes.tableHeadCell}>
                    Cash Transferred to Lab
                  </StyledTableCell>
                </div>
              </StyledTableCell>
              <StyledTableCell className={classes.tableHeadCell}>
                Tag box Temperature
              </StyledTableCell>
              <StyledTableCell className={classes.tableHeadCell}>
                Call
              </StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {riderCount &&
              riderCount?.results &&
              riderCount?.results.length > 0 &&
              riderCount?.results.map((data: any, index: any) => {
                return (
                  <StyledTableRow key={index}>
                    <StyledTableCell align="center">
                     {data?.rider.replace(/_RIDER$/, '')}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {data?.lab?.name}
                    </StyledTableCell>
                    <StyledTableCell>
                      <div
                        style={{
                          backgroundColor: "#e1f7ea",
                          width: "100%",
                          display: "flex",
                        }}
                      >
                        <div
                          style={{
                            border: "1px solid #95a5a6",
                            padding: "15px",
                            width: "20%",
                          }}
                        >
                          {data?.total_bookings}
                        </div>
                        <div
                          style={{
                            border: "1px solid #95a5a6",
                            padding: "15px",
                            width: "20%",
                          }}
                        >
                          {data?.awaiting_rider_action}
                        </div>
                        <div
                          style={{
                            border: "1px solid #95a5a6",
                            padding: "15px",
                            width: "20%",
                          }}
                        >
                          {data?.picked ? data?.picked : "0"}
                        </div>
                        <div
                          style={{
                            border: "1px solid #95a5a6",
                            padding: "15px",
                            width: "20%",
                          }}
                        >
                          {data?.transfered_to_lab}
                        </div>
                        <div
                          style={{
                            border: "1px solid #95a5a6",
                            padding: "15px",
                            width: "20%",
                          }}
                        >
                          {data?.cash_pending_with_rider}
                        </div>
                        <div
                          style={{
                            border: "1px solid #95a5a6",
                            padding: "15px",
                            width: "20%",
                          }}
                        >
                          {data?.cash_transfered_to_lab}
                        </div>
                      </div>
                    </StyledTableCell>
                    <StyledTableCell>
                      <div
                        style={{
                          backgroundColor: "#e1f6f7",
                          width: "100%",
                          display: "flex",
                        }}
                      >
                        <div
                          style={{
                            border: "1px solid #95a5a6",
                            padding: "15px",
                            width: "33%",
                          }}
                        >
                          {data?.cc_total_booking}
                        </div>
                        <div
                          style={{
                            border: "1px solid #95a5a6",
                            padding: "15px",
                            width: "33%",
                          }}
                        >
                          {data?.cc_picked_booking}
                        </div>
                        <div
                          style={{
                            border: "1px solid #95a5a6",
                            padding: "15px",
                            width: "33%",
                          }}
                        >
                          {data?.cc_transfer_to_lab}
                        </div>
                      </div>
                    </StyledTableCell>
                    <StyledTableCell>
                      <div
                        style={{
                          backgroundColor: "#f5f4da",
                          width: "100%",
                          display: "flex",
                        }}
                      >
                        <div
                          style={{
                            border: "1px solid #95a5a6",
                            padding: "15px",
                            width: "25%",
                          }}
                        >
                          {data?.dc_total_booking}
                        </div>
                        <div
                          style={{
                            border: "1px solid #95a5a6",
                            padding: "15px",
                            width: "25%",
                          }}
                        >
                          {data?.dc_transfered_to_lab}
                        </div>
                        <div
                          style={{
                            border: "1px solid #95a5a6",
                            padding: "15px",
                            width: "25%",
                          }}
                        >
                          {data?.dc_cash_pending_with_rider}
                        </div>
                        <div
                          style={{
                            border: "1px solid #95a5a6",
                            padding: "15px",
                            width: "25%",
                          }}
                        >
                          {data?.dc_cash_pending_with_rider}
                        </div>
                      </div>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {data?.temperature}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Button
                        disabled={!callButton}
                        className="addDeck"
                        variant="contained"
                        color="primary"
                        onClick={() => handleCallClick(data?.user.id, "call")}
                        startIcon={<CallIcon />}
                      ></Button>
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
          </TableBody>
          <TableFooter>
            <StyledTableRow>
              <TablePagination
                count={riderCount?.count || 0}
                rowsPerPageOptions={[]}
                colSpan={1}
                rowsPerPage={riderCount?.page_size}
                page={page}
                SelectProps={{
                  native: true,
                }}
                onPageChange={handleChangePage}
              />
            </StyledTableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  riderCount: state.LogisticsReducer.riderCount,
  riderList: state.LogisticsReducer.riderList,
  loading: state.LogisticsReducer.loading,
  labCredential: state.LogisticsReducer.labCredential,
});
export default connect(mapStateToProps, {
  getRiderCount,
  getRiderList,
  clickToCallRiderCount,
  getLabCredential
})(BookingsTable);
