import React, { useEffect, useRef, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Container,
  Grid,
} from "@material-ui/core";
import Box from "@mui/material/Box";
import { generateBookingFilterUrl } from "../../helpers/generateUrl";
import { connect } from "react-redux";
import {
  getPaymentInfo,
  getAgentList,
  getLeadSource,
  getPartners,
  getAllBookings,
  getAllBookings1,
  getAllBookings2,
  getAllBookings3,
  getAllBookings4,
  getAllBookings5,
  getCities,
  getPhlebos,
  getCollectionSlot,
  getQRCodeData,
  getVerifiedbyList,
  getUnscannedTubeTube,
  getRejectedBatchForPhlebo,
  getRejectedBatchForRider,
  getAllSpecialPackageBookings,
} from "../actions/RouteManagerAction";
import "./filter.sass";
import { useHistory } from "react-router";
import BookingsTable from "../tables/bookingsTable1";
import BookingsTableHold from "../tables/bookingsTable4";
import BookingsTableFor0To60 from "../tables/bookingsTable2";
import BookingsTableForEndSlotTimeHasFinished from "../tables/bookingsTable3";
import SampleRejctedForPhlbo from "../tables/sampleRejctedForPhlbo";
import SampleRejctedForRider from "../tables/sampleRejctedForRider";
import BookingsTableFor15 from "../tables/bookingsTable5";
import BookingsTableForSpecialPackages from "../tables/bookingsTableForSpecialPackages";
import BookingsTableForUrinePackages from "../tables/bookingsTableForUrinePackages";
import PaymentModal from "../components/Comments/paymentforModal";
import TubeModal from "../components/Comments/tubeModal";
import QuizRoundedIcon from "@mui/icons-material/QuizRounded";
import CloseIcon from "@material-ui/icons/Close";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import {  Paper } from "@material-ui/core";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
      //   width: "100%",
      //   marginTop: "20px",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
      //   padding: theme.spacing(3),
    },
    card: {
      height: "100%",
      padding: theme.spacing(1),
    },
    card_grid: {
      // wordSpacing:3,
      justifyContent: "space-between",
    },
  })
);

interface Props {
  children: any;
  agentsList: any;
  bookingList: any;
  bookingList1: any;
  bookingList2: any;
  bookingList3: any;
  bookingList4: any;
  bookingList5: any;
  partnerList: any;
  getAgentList: any;
  getCollectionSlot: any;
  getPartners: any;
  getAllBookings: any;
  getAllBookings1: any;
  getAllBookings2: any;
  getAllBookings3: any;
  getAllBookings4: any;
  getRejectedBatchForPhlebo:any;
  rejectedbatchForPhlebo:any;
  getRejectedBatchForRider:any;
  rejectedbatchForRider:any;
  getAllBookings5: any;
  getCities: any;
  collectionSlot: any;
  cities: any;
  getPhlebos: any;
  phleboList: any;
  getLeadSource: any;
  lead_source: any;
  getPaymentInfo: any;
  paymentInformation: any;
  getQRCodeData: any;
  qrcode: any;
  loading: boolean;
  unassignedLoading: boolean;
  pickupHoldLoading: boolean;
  phleboLateLoading: boolean;
  phleboVeryLateLoading: boolean;
  phleboNotStartedLoading: boolean;
  getVerifiedbyList: any;
  verifiedbyList: any;
  getUnscannedTubeTube: any;
  unScannedTube: any;
  getAllSpecialPackageBookings: any;
  allSpecialPackageBookings: any;
  specialPackageLoading: any;
}

const CustomerLeadFilterPage: React.FC<Props> = ({
  agentsList,
  partnerList,
  getAgentList,
  getPartners,
  getAllBookings,
  getAllBookings1,
  getAllBookings2,
  getAllBookings3,
  getAllBookings4,
  getRejectedBatchForPhlebo,
  rejectedbatchForPhlebo,
  getRejectedBatchForRider,
  rejectedbatchForRider,
  getAllBookings5,
  bookingList,
  bookingList1,
  bookingList2,
  bookingList3,
  bookingList4,
  bookingList5,
  getCollectionSlot,
  getPaymentInfo,
  paymentInformation,
  collectionSlot,
  getCities,
  cities,
  getPhlebos,
  phleboList,
  getLeadSource,
  lead_source,
  getQRCodeData,
  qrcode,
  loading,
  unassignedLoading,
  pickupHoldLoading,
  phleboLateLoading,
  phleboVeryLateLoading,
  phleboNotStartedLoading,
  getVerifiedbyList,
  verifiedbyList,
  getUnscannedTubeTube,
  unScannedTube,
  getAllSpecialPackageBookings,
  allSpecialPackageBookings,
  specialPackageLoading,
}) => {
  const classes = useStyles();

  const history = useHistory();
  const timer = useRef<any>(0);
  const [openModal, setopenModal] = useState<boolean>(false);
  const [status, setStatus] = useState<any>([]);
  const [source, setSource] = useState<any>([]);
  const [partner, setPartner] = useState<any>([]);
  const [agent, setAgent] = useState<any>([]);
  const [city, setCity] = useState<any>([]);
  const [VerifiedbyList, setVerifiedbyList] = useState<any>([]);
  const [slot, setSlot] = useState<any>([]);
  const [start_date, setStart_date] = useState("");
  const [end_date, setEnd_date] = useState("");
  const [booking_date, setBooking_date] = useState("");
  const [collection_date, setCollection_date] = useState("");
  const [verificationStatus, setVerificationStatus] = useState<any>([]);
  const [pickup_status, setPickup_status] = useState<any>([]);
  const [noPhlebo, setNoPhlebo] = useState<any>({});
  const [pickupStatusHold, setPickupStatusHold] = useState<any>({});
  const [phleboNotStarted, setPhleboNotStarted] = useState<any>([]);
  const [name, setName] = useState("");
  const [phlebo, setPhlebo] = useState<any>([]);
  const [phone, setPhone] = useState("");
  const [bookingId, setBookingId] = useState<any>("");
  const [report_status, setReportStatus] = useState<any>("none");
  const [pickup_received_amount, setPickupReceivedAmount] =
    useState<string>("none");
  const [page, setPage] = useState(0);
  const [page1, setPage1] = useState(0);
  const [sample_registered, setSampleRegisterd] = useState<any>("none");
  const [paymentId, setPaymentId] = useState<any>(0);
  const [openPaymentModal, setOpenPaymentModal] = useState<any>(false);
  const [heights, setheights] = useState(window.innerHeight);
  const [notificationData, setnotificationData] = useState("");
  const [defaultStatus, setDefaultStatus] = useState<any>([]);
  const [openTubeModal, setOpenTubeModal] = useState<any>(false);
  const [tubeId, setTubeId] = useState<any>("");
  const [updateTube, setUpdateTube] = useState<any>("");
  const [phleboJourney, setPhleboJourney] = useState<any>("none");
  const [totalCountPickupStatusHold, setTotalCountPickupStatusHold] = useState<any>(0);
  const [totalCountUnassigned, setTotalCountUnassigned] = useState<any>(0);
  const [totalCount, setTotalCount] = useState<any>(0);
  const [totalCountPhleboNotStarted, setTotalCountPhleboNotStarted] = useState<any>(0);
  const [totalCountPhleboNotStarted15, setTotalCountPhleboNotStarted15] = useState<any>(0);
  const [totalCountSpecialPackageBookings, setTotalCountSpecialPackageBookings] = useState<any>(0);
  const [totalCountUrinePackageBookings, setTotalCountUrinePackageBookings] = useState<any>(0);
  const [bId, setBId] = useState<any>("");
  const [bookingIdentifier, setBookingIdentifier] = useState<any>("");
  const [memberCheck, setMemberCheck] = useState<any>("");
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const today = new Date()
  const currentData = today.toISOString().slice(0,10)

  useEffect(() => {
    getCollectionSlot();
    // getPhlebos()
    getLeadSource();
  }, []);

  useEffect(() => {
      getAllBookings1(`multiple_phlebo=0&collection_date=${currentData}`)
      getAllBookings2(`multiple_pickup_status=hold&collection_date=${currentData}`)
      getAllBookings3(`journey=not-end&collection_date=${currentData}`)
      getAllBookings4(`journey=not-end&collection_date=${currentData}`)
      getAllBookings5(`multiple_booking_status=phlebo assigned&collection_date=${currentData}`)
      getRejectedBatchForPhlebo('?batch_status=rejected_for_phlebo')
      getRejectedBatchForRider('?batch_status=rejected_for_rider')
      getAllSpecialPackageBookings(`special_package=true&collection_date=${currentData}`)
      getAllBookings(`${"delivery_status=pending"}`);
  }, []);

  useEffect(() => {
    if (paymentId != 0) {
      getPaymentInfo(`?booking=${paymentId}`);
    }
  }, [paymentId]);

  useEffect(() => {
    if (tubeId !== "" && bId !== "") {
      if (bId === tubeId) {
        getQRCodeData(`?booking_id=${tubeId}`);
        getUnscannedTubeTube(bId, "?add_booking=false");
        setOpenTubeModal(true);
        setBookingIdentifier(bId);
      } else {
        getQRCodeData(`?add_booking_id=${tubeId}`);
        getUnscannedTubeTube(tubeId, "?add_booking=true");
        setOpenTubeModal(true);
        setBookingIdentifier(tubeId);
      }
    }
  }, [tubeId, updateTube]);

  const handleCross = () => {
    setOpen(false);
  };

  return (
    <>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid
          container
          spacing={1}
          direction="row"
          style={{ alignItems: "right" }}
        >
          <Grid item xs={12} sm={8}>
            <h4 style={{ fontWeight: "bold" }}>
              ACTIONABLE BOOKINGS{" "}
              <QuizRoundedIcon color="primary" onClick={handleOpen} />
            </h4>
          </Grid>
          {/* <Grid item xs={12} sm={4}>
            <h4 style={{ fontWeight: "bold" }}>BOOKING COUNT : {totalCount}</h4>
          </Grid> */}
        </Grid>
        <Grid
          container
          spacing={1}
          direction="row"
          style={{ alignItems: "left" }}
        >
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={open}>
              <div
                style={{
                  position: "absolute",
                  left: "10%",
                  right: "15%",
                  top: "25%",
                  minHeight: "400",
                  minWidth: "500",
                  backgroundColor: "white",
                }}
              >
                <Box style={{}}>
                  <Typography
                    id="transition-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    <div
                      style={{
                        margin: "0rem 2rem",
                        paddingTop: "1rem",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <h4>Note:</h4>
                      <CloseIcon onClick={handleCross} />
                    </div>
                  </Typography>
                  <Typography id="transition-modal-description" sx={{ mt: 1 }}>
                    <div style={{ margin: "1rem" }}>
                      <div
                        style={{
                          alignItems: "left",
                          display: "inline-block",
                          margin: "1rem",
                        }}
                      >
                        <small>
                          <ul style={{ paddingLeft: "15px" }}>
                            <li>
                              You can filter by booking date or start and end
                              date.
                            </li>
                            <li>T: Total Additional Booking count</li>
                            <li>P: Pending count for additional member</li>
                            <li>C: Confirmed count for additional member</li>
                            <li>
                              For B2B booking first select Booking Type b2b then
                              apply any filter obtion
                            </li>
                          </ul>
                        </small>
                      </div>
                      <div
                        style={{
                          alignItems: "left",
                          display: "inline-block",
                          margin: "1rem",
                        }}
                      >
                        <small>
                          <div className="r-cl" style={{ display: "flex" }}>
                            <div
                              className="boxStyle"
                              style={{
                                border: "1px solid",
                                width: "17px",
                                height: "17px",
                                background: "#ffe082",
                              }}
                            ></div>
                            <div style={{ marginLeft: "5px" }}>
                              B2B booking and Payable Amount is Zero and Phlebo
                              Not Reached
                            </div>
                          </div>
                          <div className="r-cl" style={{ display: "flex" }}>
                            <div
                              className="boxStyle"
                              style={{
                                border: "1px solid",
                                width: "17px",
                                height: "17px",
                                background: "#f48fb1",
                              }}
                            ></div>
                            <div style={{ marginLeft: "5px" }}>
                              B2B booking and Payable Amount is not Zero and
                              Phlebo Not Reached
                            </div>
                          </div>
                          <div className="r-cl" style={{ display: "flex" }}>
                            <div
                              className="boxStyle"
                              style={{
                                border: "1px solid",
                                width: "17px",
                                height: "17px",
                                background: "#64ffda",
                              }}
                            ></div>
                            <div style={{ marginLeft: "5px" }}>
                              B2C booking and Payable Amount is Zero and Phlebo
                              Not Reached
                            </div>
                          </div>
                          <div className="r-cl" style={{ display: "flex" }}>
                            <div
                              className="boxStyle"
                              style={{
                                border: "1px solid",
                                width: "17px",
                                height: "17px",
                                background: "default",
                              }}
                            ></div>
                            <div style={{ marginLeft: "5px" }}>
                              B2C booking and Payable Amount is not Zero and
                              Phlebo Not Reached
                            </div>
                          </div>
                          <div className="r-cl" style={{ display: "flex" }}>
                            <div
                              className="boxStyle"
                              style={{
                                border: "1px solid",
                                width: "17px",
                                height: "17px",
                                background: "#ba68c8",
                              }}
                            ></div>
                            <div style={{ marginLeft: "5px" }}>
                              Phlebo Reached
                            </div>
                          </div>
                          <div className="r-cl" style={{ display: "flex" }}>
                            <div
                              className="boxStyle"
                              style={{
                                border: "1px solid",
                                width: "17px",
                                height: "17px",
                                background: "#ff9800",
                              }}
                            ></div>
                            <div style={{ marginLeft: "5px" }}>
                              Hold Due To Payment
                            </div>
                          </div>
                        </small>
                      </div>
                    </div>
                  </Typography>
                </Box>
              </div>
            </Fade>
          </Modal>
        </Grid>
        <PaymentModal
          openModalP={openPaymentModal}
          setOpenModalP={setOpenPaymentModal}
          paymentInformation={paymentInformation}
        />
        <br />
        <div style={{ display: "flex", paddingTop: "25px" }}>
          <Paper
            elevation={5}
            style={{ marginBottom: "3rem", padding: "1rem", width: "50%" }}
          >
            <div style={{ width: "100%" }}>
              <h4 style={{ fontWeight: "bold", paddingLeft: "20px" }}>
                Total Unassigned Bookings : {totalCountUnassigned}
              </h4>
              {
              // bookingList1?.results && bookingList1?.results?.length > 0 && (
                <Box>
                  <Container maxWidth={false}>
                    <Grid container spacing={3}>
                      <BookingsTable
                        setOpenTubeModal={setOpenTubeModal}
                        setTubeId={setTubeId}
                        setopenModal={setopenModal}
                        openModal={openModal}
                        page={page}
                        setPage={setPage}
                        loading={unassignedLoading}
                        getAllBookings1={getAllBookings1}
                        bookingList1={bookingList1}
                        paymentId={paymentId}
                        setPaymentId={setPaymentId}
                        openPaymentModal={openPaymentModal}
                        setOpenPaymentModal={setOpenPaymentModal}
                        setTotalCount={setTotalCountUnassigned}
                        setBId={setBId}
                        setMemberCheck={setMemberCheck}
                      />
                    </Grid>
                  </Container>
                </Box>
              // )
              }
              {/* {bookingList1?.results && bookingList1?.results?.length === 0 && (
                <h6 style={{ textAlign: "center" }}>No Data Found</h6>
              )} */}
            </div>
          </Paper>
          <Paper
            elevation={5}
            style={{ marginBottom: "3rem", padding: "1rem", width: "50%" }}
          >
            <div style={{ width: "100%" }}>
              <h4 style={{ fontWeight: "bold", paddingLeft: "20px" }}>
                Total Pickup Status Hold Bookings : {totalCountPickupStatusHold}
              </h4>
              {
              // bookingList2?.results && bookingList2?.results?.length > 0 && (
                <Box>
                  <Container maxWidth={false}>
                    <Grid container spacing={3}>
                    <BookingsTableHold
                        setOpenTubeModal={setOpenTubeModal}
                        setTubeId={setTubeId}
                        setopenModal={setopenModal}
                        openModal={openModal}
                        page={page1}
                        setPage={setPage1}
                        loading={pickupHoldLoading}
                        getAllBookings2={getAllBookings2}
                        bookingList2={bookingList2}
                        paymentId={paymentId}
                        setPaymentId={setPaymentId}
                        openPaymentModal={openPaymentModal}
                        setOpenPaymentModal={setOpenPaymentModal}
                        setTotalCount={setTotalCountPickupStatusHold}
                        setBId={setBId}
                        setMemberCheck={setMemberCheck}
                      />
                    </Grid>
                  </Container>
                </Box>
              // )
              }
              {/* {bookingList2?.results && bookingList2?.results?.length === 0 && (
                <h6 style={{ textAlign: "center" }}>No Data Found</h6>
              )} */}
            </div>
          </Paper>
        </div>
        <div style={{ display: "flex", paddingTop: "25px" }}>
         
         <Paper
           elevation={5}
           style={{ marginBottom: "3rem", padding: "1rem", width: "50%" }}
         >
           <div style={{ width: "100%" }}>
             <h4 style={{ fontWeight: "bold", paddingLeft: "20px" }}>
             Sample Rejected: Rider Submission
             </h4>
             {
             // bookingList3?.results && bookingList3?.results?.length > 0 && (
               <Box>
                 <Container maxWidth={false}>
                   <Grid container spacing={3}>
                   <SampleRejctedForRider
                       rejectedbatchForRider={rejectedbatchForRider}
                       loading={loading}
                     />
                   </Grid>
                 </Container>
               </Box>
             // )
             }
             {/* {bookingList3?.results && bookingList3?.results?.length === 0 && (
               <h6 style={{ textAlign: "center" }}>No Data Found</h6>
             )} */}
           </div>
         </Paper>
         <Paper
           elevation={5}
           style={{ marginBottom: "3rem", padding: "1rem", width: "50%" }}
         >
           <div style={{ width: "100%" }}>
             <h4 style={{ fontWeight: "bold", paddingLeft: "20px" }}>
             Sample Rejected: Phlebo Submission
             </h4>
             {
             // bookingList3?.results && bookingList3?.results?.length > 0 && (
               <Box>
                 <Container maxWidth={false}>
                   <Grid container spacing={3}>
                   <SampleRejctedForPhlbo
                       rejectedbatchForPhlebo={rejectedbatchForPhlebo}
                       loading={loading}
                     />
                   </Grid>
                 </Container>
               </Box>
             // )
             }
             {/* {bookingList3?.results && bookingList3?.results?.length === 0 && (
               <h6 style={{ textAlign: "center" }}>No Data Found</h6>
             )} */}
           </div>
         </Paper>
       </div>
        <div style={{ display: "flex", paddingTop: "25px" }}>
          <Paper
            elevation={5}
            style={{ marginBottom: "3rem", padding: "1rem", width: "50%" }}
          >
            <div style={{ width: "100%" }}>
              <h4 style={{ fontWeight: "bold", paddingLeft: "20px" }}>
                Phlebo has not reached the Cx location till Slot Start Time: {totalCountPhleboNotStarted}
              </h4>
              {
              // bookingList3?.results && bookingList3?.results?.length > 0 && (
                <Box>
                  <Container maxWidth={false}>
                    <Grid container spacing={3}>
                    <BookingsTableFor0To60
                        setOpenTubeModal={setOpenTubeModal}
                        setTubeId={setTubeId}
                        setopenModal={setopenModal}
                        openModal={openModal}
                        page={page}
                        setPage={setPage}
                        loading={phleboLateLoading}
                        getAllBookings3={getAllBookings3}
                        bookingList3={bookingList3}
                        paymentId={paymentId}
                        setPaymentId={setPaymentId}
                        openPaymentModal={openPaymentModal}
                        setOpenPaymentModal={setOpenPaymentModal}
                        setTotalCount={setTotalCountPhleboNotStarted}
                        setBId={setBId}
                        setMemberCheck={setMemberCheck}
                      />
                    </Grid>
                  </Container>
                </Box>
              // )
              }
              {/* {bookingList3?.results && bookingList3?.results?.length === 0 && (
                <h6 style={{ textAlign: "center" }}>No Data Found</h6>
              )} */}
            </div>
          </Paper>
          <Paper
            elevation={5}
            style={{ marginBottom: "3rem", padding: "1rem", width: "50%" }}
          >
            <div style={{ width: "100%" }}>
              <h4 style={{ fontWeight: "bold", paddingLeft: "20px" }}>
                Phlebo has not reached Cx location till Slot End time: {totalCount}
              </h4>
              {
                <Box>
                  <Container maxWidth={false}>
                    <Grid container spacing={3}>
                      <BookingsTableForEndSlotTimeHasFinished
                        setOpenTubeModal={setOpenTubeModal}
                        setTubeId={setTubeId}
                        setopenModal={setopenModal}
                        openModal={openModal}
                        page={page}
                        setPage={setPage}
                        loading={phleboVeryLateLoading}
                        getAllBookings4={getAllBookings4}
                        bookingList4={bookingList4}
                        paymentId={paymentId}
                        setPaymentId={setPaymentId}
                        openPaymentModal={openPaymentModal}
                        setOpenPaymentModal={setOpenPaymentModal}
                        setTotalCount={setTotalCount}
                        setBId={setBId}
                        setMemberCheck={setMemberCheck}
                      />
                    </Grid>
                  </Container>
                </Box>
              }
              {/* {bookingList?.results && bookingList?.results.length === 0 && (
                <h6 style={{ textAlign: "center" }}>No Data Found</h6>
              )} */}
            </div>
          </Paper>
        </div>
       
        <div style={{ display: "flex", paddingTop: "25px" }}>
         
          <Paper
            elevation={5}
            style={{ marginBottom: "3rem", padding: "1rem", width: "50%" }}
          >
            <div style={{ width: "100%" }}>
              <h4 style={{ fontWeight: "bold", paddingLeft: "20px" }}>
              Phlebo has not started the journey 15 mins before slot start time: {totalCountPhleboNotStarted15}
              </h4>
              {
              // bookingList3?.results && bookingList3?.results?.length > 0 && (
                <Box>
                  <Container maxWidth={false}>
                    <Grid container spacing={3}>
                    <BookingsTableFor15
                        setOpenTubeModal={setOpenTubeModal}
                        setTubeId={setTubeId}
                        setopenModal={setopenModal}
                        openModal={openModal}
                        page={page}
                        setPage={setPage}
                        loading={phleboNotStartedLoading}
                        getAllBookings5={getAllBookings5}
                        bookingList5={bookingList5}
                        paymentId={paymentId}
                        setPaymentId={setPaymentId}
                        openPaymentModal={openPaymentModal}
                        setOpenPaymentModal={setOpenPaymentModal}
                        setTotalCount={setTotalCountPhleboNotStarted15}
                        setBId={setBId}
                        setMemberCheck={setMemberCheck}
                      />
                    </Grid>
                  </Container>
                </Box>
              // )
              }
              {/* {bookingList3?.results && bookingList3?.results?.length === 0 && (
                <h6 style={{ textAlign: "center" }}>No Data Found</h6>
              )} */}
            </div>
          </Paper>

          <Paper
            elevation={5}
            style={{ marginBottom: "3rem", padding: "1rem", width: "50%" }}
          >
            <div style={{ width: "100%" }}>
              <h4 style={{ fontWeight: "bold", paddingLeft: "20px" }}>
              Container Delivery Pending Bookings: {totalCountSpecialPackageBookings}
              </h4>
              {
                <Box>
                  <Container maxWidth={false}>
                    <Grid container spacing={3}>
                    <BookingsTableForSpecialPackages
                        setOpenTubeModal={setOpenTubeModal}
                        setTubeId={setTubeId}
                        setopenModal={setopenModal}
                        openModal={openModal}
                        page={page}
                        setPage={setPage}
                        loading={specialPackageLoading}
                        getAllSpecialPackageBookings={getAllSpecialPackageBookings}
                        allSpecialPackageBookings={allSpecialPackageBookings}
                        paymentId={paymentId}
                        setPaymentId={setPaymentId}
                        openPaymentModal={openPaymentModal}
                        setOpenPaymentModal={setOpenPaymentModal}
                        setTotalCount={setTotalCountSpecialPackageBookings}
                        setBId={setBId}
                        setMemberCheck={setMemberCheck}
                      />
                    </Grid>
                  </Container>
                </Box>
              }
            </div>
          </Paper>

        </div>

        <div style={{ display: "flex", paddingTop: "25px" }}>

          <Paper
            elevation={5}
            style={{ marginBottom: "3rem", padding: "1rem", width: "50%" }}
          >
            <div style={{ width: "100%" }}>
              <h4 style={{ fontWeight: "bold", paddingLeft: "20px" }}>
              Total Urine Package Bookings: {totalCountUrinePackageBookings}
              </h4>
              {
                <Box>
                  <Container maxWidth={false}>
                    <Grid container spacing={3}>
                    <BookingsTableForUrinePackages
                        setOpenTubeModal={setOpenTubeModal}
                        setTubeId={setTubeId}
                        setopenModal={setopenModal}
                        openModal={openModal}
                        page={page}
                        setPage={setPage}
                        loading={specialPackageLoading}
                        getAllBookings={getAllBookings}
                        bookingList={bookingList}
                        paymentId={paymentId}
                        setPaymentId={setPaymentId}
                        openPaymentModal={openPaymentModal}
                        setOpenPaymentModal={setOpenPaymentModal}
                        setTotalCount={setTotalCountUrinePackageBookings}
                        setBId={setBId}
                        setMemberCheck={setMemberCheck}
                      />
                    </Grid>
                  </Container>
                </Box>
              }
            </div>
          </Paper>

        </div>

        <TubeModal
          setOpenTubeModal={setOpenTubeModal}
          openTubeModal={openTubeModal}
          qrcode={qrcode}
          getQRCodeData={getQRCodeData}
          tubeId={tubeId}
          setTubeId={setTubeId}
          setUpdateTube={setUpdateTube}
          unScannedTube={unScannedTube}
          bookingIdentifier={bookingIdentifier}
          memberCheck={memberCheck}
        />
      </main>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  agentsList: state.RouteManagerReducer.agentsList,
  partnerList: state.RouteManagerReducer.partnerList,
  bookingList: state.RouteManagerReducer.bookingList,
  bookingList1: state.RouteManagerReducer.bookingList1,
  bookingList2: state.RouteManagerReducer.bookingList2,
  bookingList3: state.RouteManagerReducer.bookingList3,
  bookingList4: state.RouteManagerReducer.bookingList4,
  bookingList5: state.RouteManagerReducer.bookingList5,
  cities: state.RouteManagerReducer.cities,
  phleboList: state.RouteManagerReducer.phleboList,
  collectionSlot: state.RouteManagerReducer.collectionSlot,
  paymentInformation: state.RouteManagerReducer.paymentInformation,
  loading: state.RouteManagerReducer.loading,
  unassignedLoading: state.RouteManagerReducer.unassignedLoading,
  pickupHoldLoading: state.RouteManagerReducer.pickupHoldLoading,
  phleboLateLoading: state.RouteManagerReducer.phleboLateLoading,
  phleboVeryLateLoading: state.RouteManagerReducer.phleboVeryLateLoading,
  phleboNotStartedLoading: state.RouteManagerReducer.phleboNotStartedLoading,
  qrcode: state.RouteManagerReducer.qrcode,
  lead_source: state.RouteManagerReducer.lead_source,
  verifiedbyList: state.RouteManagerReducer.verifiedbyList,
  unScannedTube: state.RouteManagerReducer.unScannedTube,
  rejectedbatchForPhlebo: state.RouteManagerReducer.rejectedbatchForPhlebo,
  rejectedbatchForRider: state.RouteManagerReducer.rejectedbatchForRider,
  allSpecialPackageBookings: state.RouteManagerReducer.allSpecialPackageBookings,
  specialPackageLoading: state.RouteManagerReducer.specialPackageLoading,
});

export default connect(mapStateToProps, {
  getAgentList,
  getPartners,
  getCities,
  getPhlebos,
  getLeadSource,
  getCollectionSlot,
  getPaymentInfo,
  getAllBookings,
  getAllBookings1,
  getAllBookings2,
  getAllBookings3,
  getAllBookings4,
  getRejectedBatchForPhlebo,
  getAllBookings5,
  getQRCodeData,
  getVerifiedbyList,
  getUnscannedTubeTube,
  getRejectedBatchForRider,
  getAllSpecialPackageBookings,
})(CustomerLeadFilterPage);
