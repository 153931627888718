import React, { useState, useEffect, useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Grid, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { getCities, createTat } from "../actions/productMangerAction";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router";
import { connect, useDispatch } from "react-redux";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
    },
    contentHeader: {
      width: "100%",
      padding: "0px 10px 40px 10px",
    },
    leftContentHeader: {
      width: "50%",
      float: "left",
    },
    rightContentHeader: {
      width: "50%",
      float: "right",
      textAlign: "right",
      paddingRight: "20px",
    },
    contentTable: {
      width: "100%",
      padding: "20px 10px 10px 10px",
      background: "#ffffff",
      margin: "10px",
      boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
    },
    paper: {
      marginTop: "2rem",
      width: "100%",
    },
  })
);

interface Props {
  match: any;
  getCities: any;
  cities: any;
  createTat: any;
  tat: any;
}

const PMEditPackageManagement: React.FC<Props> = ({
  getCities,
  cities,
  createTat,
  tat,
  match,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const timer = useRef<any>(0);
  // const [package, setPackage] = useState<string>("");
  const [tats, setTat] = useState<string>("");
  const [cityId, setCityId] = useState<any>("");
  const [packagePrice, setPackagePrice] = useState<string>("");
  const [offerPrice, setOfferPrice] = useState<string>("");
  const [addonPrice, setAddonPrice] = useState<string>("");
  const packageId = match.params.id;
  // const timer = useRef<any>(0);

  useEffect(() => {
    getCities();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onKeyDown = (e: any) => {
    // if (e.key === "Enter") {
    //     filterSampleHandover(e);
    // }
  };

  const createNewTat = async () => {
    const body: any = {
      package: packageId,
      city: cityId,
      tat_time: tats,
      package_price: packagePrice,
      offer_price: offerPrice,
      addon_price: addonPrice,
    };
    await createTat(body);
    history.push(`/dashboard/pm/packagemanagement`);
    // handleClose()
  };

  // const submitForm = async (e: any) => {
  //   e.preventDefault();
  //   const data: any = {
  // customer_name: customerName,
  // customer_email: customerEmail,
  // customer_mobile: customerMobile,
  // no_of_member: noOfMember,
  // family_package: packages && packages?.id,
  // };
  // await props.postFamilyPackageBooking(data);
  // setCustomerMobile("");
  // setCustomerName("");
  // setCustomerEmail("");
  // setNoOfMember("");
  // setPackage("");
  // };

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <div className={classes.contentHeader}>
        <div className={classes.leftContentHeader}>
          <h2>Create TAT</h2>
        </div>
        <div className={classes.rightContentHeader}></div>
      </div>
      <div className={classes.contentTable}>
        {/* <form onSubmit={submitForm}> */}
        <Grid container spacing={3} style={{ margin: "1rem auto" }}>
          <Grid container item xs={12} md={12} spacing={3}>
            <Grid item xs={12} md={4}>
              <h5>City</h5>
              <Autocomplete
                id="city"
                onChange={(event, newValue) => {
                  if (newValue) {
                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                    setCityId(obj.id);
                  }
                }}
                options={cities || []}
                freeSolo
                blurOnSelect
                aria-required
                getOptionLabel={(option: any) => option.name}
                onInputChange={(event, newInputValue) => {
                  clearTimeout(timer.current);
                  timer.current = setTimeout(() => {
                    getCities(`${newInputValue}`);
                  }, 1000);
                  if (newInputValue.length === 0) {
                    setCityId("");
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="input"
                    placeholder="Select City"
                    variant="outlined"
                    style={{ width: "100%", margin: "0", padding: "0" }}
                    required
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <h5>Tat</h5>
              <TextField
                className="input"
                name="name"
                type="text"
                value={tats}
                variant="outlined"
                onChange={(e) => setTat(e.target.value as string)}
                style={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <h5>Package Price</h5>
              <TextField
                className="input"
                name="name"
                type="number"
                value={packagePrice}
                variant="outlined"
                onChange={(e) => setPackagePrice(e.target.value as string)}
                style={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <h5>Offer Price</h5>
              <TextField
                className="input"
                name="name"
                type="number"
                value={offerPrice}
                variant="outlined"
                onChange={(e) => setOfferPrice(e.target.value as string)}
                style={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <h5>Addon Price</h5>
              <TextField
                className="input"
                name="name"
                type="number"
                value={addonPrice}
                variant="outlined"
                onChange={(e) => setAddonPrice(e.target.value as string)}
                style={{ width: "100%" }}
              />
            </Grid>
          </Grid>
        </Grid>

        <div className={classes.buttonContainer}>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            style={{ marginLeft: "1rem", marginBottom: "20px" }}
            onClick={() => createNewTat()}
          >
            Submit
          </Button>
        </div>
        {/* </form> */}
      </div>
      {/* <div className={classes.contentTable}> */}
      {/* <FamilyPackageBookingList
          familyPackageBookingList={props.familyPackageBookingList}
          getFamilyPackageBooking={props.getFamilyPackageBooking}
          loading={props.loading}
        /> */}
      {/* </div> */}
    </main>
  );
};

const mapStateToProps = (state: any) => ({
  cities: state.ProductManagerReducer.cities,
  tat: state.ProductManagerReducer.tat,
});

export default connect(mapStateToProps, {
  getCities,
  createTat,
})(PMEditPackageManagement);
