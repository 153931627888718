import React, { useState, useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Grid,
  TextField,
  withStyles,
} from "@material-ui/core";
import Button from '@mui/material/Button';
import { useRef } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Loader from "../components/loader/index";
import moment from "moment";
import { MessageRounded } from "@material-ui/icons";
import { getCityWisePhlebos, getCities } from "../actions/RouteManagerAction";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useHistory } from "react-router";
import { generateCityWisePhlebosFilterUrl } from "../../helpers/generateUrl";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      marginTop: "2rem",
      padding: "0.5rem 2rem",
      width: "100%",
    },
    table: {
      maxWidth: "100%",
      margin: "auto",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    listStyle: {
      marginLeft: "20px",
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
  })
);

const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

interface Props {
  getCityWisePhlebos: any;
  cityWisePhlebos: any;
  page: any;
  getCities: any;
  openModal: any;
  cities: any;
  setopenModal: any;
  loading: boolean;
}

const BookingsTable: React.FC<Props> = ({
  getCityWisePhlebos,
  cityWisePhlebos,
  getCities,
  cities,
  openModal,
  setopenModal,
  loading,
}) => {
  const classes = useStyles();
  useEffect(() => {
    getCityWisePhlebos();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // const [page, setPage] = React.useState(0);
  const [city, setCity] = useState<any>([]);
  const timer = useRef<any>(0);
  const history = useHistory();
  // const handleChangePage = (
  //     event: React.MouseEvent<HTMLButtonElement> | null,
  //     newPage: number
  // ) => {
  //     if (newPage > page) {
  //         let url = currentBooking.links && currentBooking.links.next.split("?")[1];
  //         getCurrentDayBooking(url);
  //     } else if (newPage < page) {
  //         let url = currentBooking.links && currentBooking.links.previous.split("?")[1];
  //         getCurrentDayBooking(url);
  //     }
  //     setPage(newPage as number);
  // };
  const filterBookings = () => {
    const body: any = {
      city: city.join(","),
    };
    const url = generateCityWisePhlebosFilterUrl(body).substring(2);
    getCityWisePhlebos(`${url}`);
    // setPage(0)
  };

  return (
    <div style={{ width: "100%" }}>
      <div className={classes.toolbar} />
      <h2>City Wise phlebo </h2>
      <Grid container spacing={1} direction="row">
        <Grid item xs={12} sm={4} md={2}>
          <Autocomplete
            id="city"
            onChange={(event, newValue) => {
              let sourceIds: any = [];
              if (newValue) {
                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                sourceIds = obj.map((item: any) => item.id);
              }
              setCity(sourceIds);
            }}
            options={cities}
            freeSolo
            blurOnSelect
            aria-required
            limitTags={1}
            multiple
            getOptionLabel={(option: any) => option.name}
            getOptionDisabled={(option: any) => {
              return city.includes(option.id);
            }}
            disableClearable
            disableCloseOnSelect
            onInputChange={(event, newInputValue) => {
              clearTimeout(timer.current);
              timer.current = setTimeout(() => {
                getCities(newInputValue);
              }, 1000);
              if (newInputValue.length === 0) {
                setCity([]);
              }
            }}
            renderInput={(params) => (
              <TextField
                className="input"
                {...params}
                placeholder="City"
                variant="outlined"
                style={{ width: "100%", margin: "0", padding: "0" }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={filterBookings}
            disabled={loading}
          >
            Filter
          </Button>
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => history.push("/dashboard/rm/cityWisePhlebos")}
          >
            Reset
          </Button>
        </Grid>
      </Grid>
      <div className={classes.toolbar} />
      <div className="data-table">
        <TableContainer
          className={classes.tableContainer}
          component={Paper}
          style={{ maxHeight: "560px" }}
        >
          {loading ? (
            <Loader />
          ) : (
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell align="center">City</StyledTableCell>
                  <StyledTableCell align="center">Phlebos List</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {cityWisePhlebos &&
                  cityWisePhlebos?.length > 0 &&
                  cityWisePhlebos.map((phlebos: any, index: any) => {
                    return (
                      <StyledTableRow key={index}>
                        <StyledTableCell
                          style={{ height: "50px" }}
                          align="center"
                        >
                          {phlebos?.city}
                        </StyledTableCell>
                        <StyledTableCell
                          style={{ height: "50px" }}
                          align="center"
                        >
                          {phlebos?.phlebo.join(", ")}
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
              </TableBody>
              <TableFooter>
                {/* <TableRow>
                                      <TablePagination
                                          rowsPerPageOptions={[]}
                                          colSpan={5}
                                          count={currentBooking.length || 0}
                                          rowsPerPage={currentBooking.length||0}
                                          page={page}
                                          onPageChange={handleChangePage}
                                      />
                                  </TableRow> */}
              </TableFooter>
            </Table>
          )}
        </TableContainer>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  getCityWisePhlebos: state.RouteManagerReducer.getCityWisePhlebos,
  cityWisePhlebos: state.RouteManagerReducer.cityWisePhlebos,
  cities: state.RouteManagerReducer.cities,
});

export default connect(mapStateToProps, {
  getCityWisePhlebos,
  getCities,
})(BookingsTable);
