import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from '@mui/material/Modal';
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Button from '@mui/material/Button';
import { TextField, Grid } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { setCFreshComments, getLeadById, getLeadDispostion, getLeadStatus ,postDispositionData} from "../../actions/PhleboAdmin";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useRef } from "react";
import { useHistory } from "react-router-dom";
let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: isMobile ? "400px" : "1200px",
    },
    head: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "2rem"
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));

type ModalProps = {
    open: boolean;
    leadId: number;
    setOpen: Function;
    setCFreshComments: any
    postDispositionData: any
    getLeadById: any;
    getLeadDispostion: any;
    dispositionList: any;
    leadStatus: any;
    getLeadStatus: any;
    leadById: any;
    getCities: any
    cities: any
    loading: boolean;
    userDetails: any;
};

const CommentsModal2: React.FC<ModalProps> = ({
    open,
    leadId,
    setOpen,
    setCFreshComments,
    postDispositionData,
    getLeadById,
    getLeadDispostion,
    leadStatus,
    getLeadStatus,
    dispositionList,
    leadById,
    loading,
    getCities = () => { },
    cities = [],
    userDetails,
}) => {
    const classes = useStyles();

    const [comment, setComment] = useState<string>("");
    const [city, setCity] = React.useState<number>(0);
    const [cityName, setCityName] = React.useState<string>("");
    const [status, setStatus] = useState("none")
    const [testName, setTestname] = useState("")
    const [pincode, setPincode] = useState<number>()
    const [callDate, setCallDate] = useState("")
    const [callTime, setCallTime] = useState("")
    const history = useHistory()
    const timer = useRef<any>(0)
    //
    const [categoryList, setCategoryList] = useState<any>("");
    const [followupDateRequired, setFolloupRequired] = useState<boolean>(false);
    const [showCity, setShowCity] = useState<boolean>(false);
    const [subCategoryName, setSubCategoryName] = useState<any>("");
    const [subDispostion, setSubDisposition] = useState<any>("");


    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (open === true) {
            getLeadDispostion("")
        }
    }, [open])

    const submitForm = (e: any) => {
        e.preventDefault()

        let body: any = {
            category: categoryList,
            sub_category: subCategoryName,
            lead_comment: leadId,
            comment,
            pincode,
            test: testName,
            city: city,
            status: status,

            followup_date: callDate,
            followup_time: callTime,
        };

        if (body['city'] === 0) {
            delete body['city'];
        }
        if (body['followup_date'] === "") {
            delete body['followup_date'];
        }
        if (body['followup_time'] === "") {
            delete body['followup_time'];
        }
        if (body['pincode'] === 0) {
            delete body['pincode'];
        }
        if (body['test'] === 0) {
            delete body['test'];
        }
        if (body['status'] === "none") {
            delete body['status'];
        }

        // console.log(subDispostion,"---------")
        // if (userDetails.profile?.ctc_from === "AMEYOCALL") {
        //     console.log((window as any)?.ameyo?.integration?.disposeCallByDisposition(
        //         subDispostion
        //     ), "LeadCrm")
        //     console.log(subDispostion,"---------")
        //     const res = (window as any)?.ameyo?.integration?.disposeCallByDisposition(
        //         subDispostion
        //     );
        // }
        postDispositionData(body);
        setComment("")
        setCategoryList("")
        setSubCategoryName("")
        setCallDate("")
        setCallTime("")
        setCity(0)
        handleClose()
        setTimeout(() => {
            if (leadById.length > 0)
                history.push("/dashboard/pha")
            getLeadById("")
        }, 2000)
    };
    const [isHitCityAPI, setIsHitCityAPI] = useState(false);

    useEffect(() => {
      if (isHitCityAPI === true) {
        getCities();
      }
    }, [isHitCityAPI]);

    let maxDate = new Date().toISOString().slice(0, 10);
    let monthValue = maxDate.slice(5, 7);
    let monthValueInt = parseInt(monthValue) + 1;
    let monthValueIntoString = monthValueInt === 13 ? "01" : monthValueInt < 10 ? `0${monthValueInt}` : `${monthValueInt}`;
    let yearValue = monthValueInt === 13 ? parseInt(maxDate.slice(0, 4)) + 1 : parseInt(maxDate.slice(0, 4));

    let maxFinal = `${yearValue}-${monthValueIntoString}-${maxDate.slice(8, 10)}`
  
    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <div className={classes.head}>
                            <p
                                style={{
                                    marginBottom: "1rem",
                                }}
                                id="transition-modal-title"
                            >
                                Select an options
                            </p>
                            <CloseIcon onClick={() => setOpen(false)} />
                        </div>
                        <form onSubmit={submitForm}>
                            <Grid
                                container
                                direction="row"
                                alignItems="flex-start"
                                justify="space-between"
                                spacing={3}
                                style={{ margin: "1rem auto" }}
                            >
                                <Grid item xs={12} md={6}>
                                    <Autocomplete
                                        id="disposition"

                                        onChange={(event, newValue) => {
                                            if (newValue) {
                                                let obj = JSON.parse(
                                                    JSON.stringify(
                                                        newValue,
                                                        null,
                                                        " "
                                                    )
                                                );
                                                setCategoryList(obj?.id);
                                                setSubCategoryName("")
                                                setShowCity(false)
                                                setCallDate("")
                                                setCallTime("")
                                                setFolloupRequired(false)
                                                setComment("")

                                                if (obj.id) {
                                                    getLeadStatus(`?category=${obj?.id}`)
                                                }
                                            }
                                        }}

                                        blurOnSelect
                                        aria-required


                                        options={dispositionList}
                                        getOptionDisabled={(option: any) => {
                                            return dispositionList.includes(option.id);
                                        }}
                                        loading={loading}
                                        disableClearable
                                        disableCloseOnSelect

                                        getOptionLabel={(option: any) =>
                                            option.name
                                        }

                                        renderInput={(params) => (
                                            <TextField
                                                className="input"
                                                {...params}
                                                label="Disposition Name"
                                                variant="outlined"
                                                style={{ width: "100%", margin: "0", padding: "0" }}
                                                required
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Autocomplete
                                        id="sub_disposition"
                                        key={categoryList}
                                        onChange={(event, newValue) => {
                                            if (newValue) {
                                                let obj = JSON.parse(
                                                    JSON.stringify(newValue, null, " ")
                                                );
                                                setSubCategoryName(obj.id)
                                                // setSubDisposition(obj.name)
                                                //  setCategoryList(obj?.category_id);
                                                setShowCity(obj.show_city)
                                                setFolloupRequired(obj.followup_required)
                                            }
                                        }}

                                        blurOnSelect
                                        aria-required

                                        loading={loading}
                                        options={leadStatus}
                                        getOptionDisabled={(option: any) => {
                                            return leadStatus.includes(option.id);
                                        }}
                                        disableClearable
                                        disableCloseOnSelect

                                        getOptionLabel={(option: any) =>
                                            option.name
                                        }

                                        renderInput={(params) => (
                                            <TextField
                                                className="input"
                                                {...params}
                                                label="Sub Disposition Name"
                                                variant="outlined"
                                                style={{ width: "100%", margin: "0", padding: "0" }}
                                                required
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} onMouseEnter={() => setIsHitCityAPI(true)}>
                                    {showCity && categoryList && (
                                        <Autocomplete
                                            id="city"
                                            onChange={(event, newValue) => {
                                                if (newValue) {
                                                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                                    setCity(obj.id)
                                                }
                                            }}
                                            options={cities}
                                            
                                            blurOnSelect
                                            aria-required
                                            getOptionLabel={(option: any) => option.name}
                                            inputValue={cityName}
                                            onInputChange={(event, newInputValue) => {
                                                clearTimeout(timer.current)
                                                setCityName(String(newInputValue).trim())
                                                timer.current = setTimeout(() => {
                                                    getCities(newInputValue)
                                                }, 1000)
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    className="input"
                                                    label="City"
                                                    variant="outlined"
                                                    style={{ width: "100%", marginBottom: "1rem" }}
                                                    required
                                                />
                                            )}
                                        />
                                    )}

                                </Grid>
                                {followupDateRequired && categoryList && (
                                    <>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                className="input"
                                                name="call_date"
                                                type="date"
                                                label={" Date"}
                                                value={callDate}
                                                required={callDate?.length === 0 ? true : false}
                                                variant="outlined"
                                                inputProps={{
                                                    min: new Date().toISOString().slice(0, 10),
                                                    max: maxFinal
                                                }}
                                                onChange={(e) => setCallDate(e.target.value as string)}
                                                style={{ width: "100%" }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                id="time"
                                                label={" Time"}
                                                type="time"
                                                value={callTime}
                                                defaultValue="07:30"
                                                className={classes.textField}
                                                required={callTime?.length === 0 ? true : false}

                                                onChange={(e) => setCallTime(e.target.value as string)}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                style={{ width: "100%" }}
                                                inputProps={{
                                                    step: 300, // 5 min
                                                }}
                                            />
                                        </Grid>
                                    </>
                                )}



                            </Grid>

                            {/* <>
                                {bodyData[0] && bodyData[0].testName && (
                                    <TextField
                                        id="test"
                                        style={{ width: "100%", marginBottom: "1rem" }}
                                        label="Test name"
                                        variant="outlined"
                                        onChange={(e) => setTestname(e.target.value as any)}
                                        className="input" />
                                )}
                            </> */}
                            {/* <>
                                {bodyData[0] && bodyData[0].pincode && (
                                    <TextField
                                        id="pincode"
                                        style={{ width: "100%", marginBottom: "1rem" }}
                                        label="Pincode"
                                        type="number"
                                        variant="outlined"
                                        onChange={(e) => setPincode(e.target.value as any)}
                                        inputProps={{ min: 99999, max: 999999 }}
                                        className="input" />
                                )}
                            </> */}



                            <TextField
                                name="comment"
                                type="text"
                                multiline
                                rows={4}
                                value={comment}
                                className="input"
                                label="Comment"
                                variant="outlined"
                                placeholder="Comment"
                                onChange={(e) => setComment(String(e.target.value).trimStart())}
                                style={{ width: "100%", marginBottom: "1rem", marginTop: "1rem" }}
                                required
                            />

                            <Button
                                fullWidth
                                variant="contained"
                                color="secondary"
                                style={{ height: "50px" }}
                                type="submit"
                                disabled={categoryList === "" || subCategoryName === ""}

                            >
                                Submit
                            </Button>
                        </form>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
};

const mapStateToProps = (state: any) => ({
    leadById: state.PhleboAdminReducer.leadById,
    dispositionList: state.PhleboAdminReducer.dispositionList,
    leadStatus: state.PhleboAdminReducer.leadStatus,
    loading: state.PhleboAdminReducer.loading,
    userDetails: state.loginReducer.userDetails,
});

export default connect(mapStateToProps, {
    setCFreshComments,
    postDispositionData,
    getLeadById,
    getLeadDispostion,
    getLeadStatus,
})(CommentsModal2);
