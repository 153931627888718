import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Modal from '@mui/material/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { Button, Paper } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles, } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import Loader from '../loader';
import { useHistory } from 'react-router-dom';
let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    paper: {
        marginTop: "2rem",
        padding: "0.5rem 2rem",
        width: "100%",
    },
    table: {
        maxWidth: "100%",
        margin: "auto"
    },
    toolbar: {
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
    },
    listStyle: {
        marginLeft: "20px",
    },
    tableContainer: {
        marginTop: "2rem",
        position: "sticky",
        top: "200px",
    },
}));

const StyledTableCell: any = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: "#924A91",
            color: theme.palette.common.white,
            fontSize: "14px",
        },
        body: {
            fontSize: 14,
        },
    })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    })
)(TableRow);
interface Props {

}
type ModalProps = {
    loading: Boolean;
    openEmailSMSModal: boolean;
    emailDetails: any;
    smsDetails: any;
    setOpenEmailSMSModal: Function;
    setOpen: Function;
    open: boolean;
};

const CreatePhlebo: React.FC<ModalProps> = ({
    openEmailSMSModal,
    setOpenEmailSMSModal,
    emailDetails,
    smsDetails,
    loading,
    open,
    setOpen,
}) => {

    const history = useHistory();
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [newTicket, setNewTicket] = useState<boolean>(false)
    const handleClose = () => {
        setOpenEmailSMSModal(false);
    };
    const handleCross = () => {
        setOpen(false)
        setNewTicket(false)
    }
    // const submitForm = async (e: any) => {
    //     e.preventDefault()
    //     const data: any = {
    //         "verification_status": verification_status,
    //         "booking_status": booking_status,
    //         "verification_remark": verification_remark,
    //         "disposition": disposition,
    //     }
    //       if (data['disposition'] === 0 || disposition === "") {
    //         delete data['disposition'];
    //     }
    //       await updateBooking(data, bookingData.pk)
    //     history.push(`/dashboard/pm`)
    // };
    const handleEMAILPage = () => {
        setPage(0)
    }
    const handleSMSPage = () => {
        setPage(1)
    }
    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openEmailSMSModal}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500
                }}
            >
                <Fade in={openEmailSMSModal}>
                    <div className={classes.paper}>
                        <Paper elevation={15} className={classes.paper}>
                            <Button onClick={handleEMAILPage} variant="contained" style={{ marginLeft: "1rem", marginBottom: "1rem", backgroundColor: "#00ffbf" }}>Email Details</Button>
                            <Button onClick={handleSMSPage} variant="contained" style={{ marginLeft: "1rem", marginBottom: "1rem", backgroundColor: "#bf00ff" }}>SMS Details</Button>
                            <CloseIcon onClick={handleClose} style={{ marginLeft: "449px", marginBottom: "1rem" }} />
                            {page === 0 ? (
                                <div className="data-table">
                                    <TableContainer className={classes.tableContainer} component={Paper} style={{ maxHeight: "500px" }}>
                                    <h2>Email Details</h2>
                                    {loading ? <Loader /> : (
                                        <Table stickyHeader aria-label="simple table">

                                            <TableHead>
                                                <StyledTableRow>
                                                    <StyledTableCell align="center">Booking Id</StyledTableCell>
                                                    <StyledTableCell align="center">Created At</StyledTableCell>
                                                    <StyledTableCell align="center">Email Id</StyledTableCell>
                                                    <StyledTableCell align="center">Message</StyledTableCell>
                                                    {/* <StyledTableCell align="center">SMS Status</StyledTableCell> */}
                                                </StyledTableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    emailDetails.results && emailDetails.results.length > 0 &&!newTicket && emailDetails.results.map((data: any, index: any) => {
                                                        return (
                                                            <TableRow key={index}>

                                                                <TableCell style={{ height: "50px" }} align="center">{data.booking.pk}</TableCell>
                                                                <TableCell style={{ height: "50px" }} align="center">{new Date(data.created_at).toLocaleString()}</TableCell>
                                                                <TableCell style={{ height: "50px" }} align="center">{data.email}</TableCell>
                                                                <TableCell style={{ height: "50px" }} align="center">{data.message}</TableCell>
                                                                {/* <TableCell style={{height:"50px"}} align="center">{data.sms_status}</TableCell> */}

                                                            </TableRow>
                                                        )
                                                    })}
                                            </TableBody>
                                        </Table>
                                    )}
                                </TableContainer>
                                </div>
                            ) : (
                                <div className="data-table">
                                    <TableContainer className={classes.tableContainer} component={Paper} style={{ maxHeight: "500px" }}>
                                    <h2>SMS Details</h2>
                                    {loading ? <Loader /> : (
                                        <Table stickyHeader aria-label="simple table">

                                            <TableHead>
                                                <StyledTableRow>
                                                    <StyledTableCell align="center">Booking Id</StyledTableCell>
                                                    <StyledTableCell align="center">Created At</StyledTableCell>
                                                    <StyledTableCell align="center">Phone Number</StyledTableCell>
                                                    <StyledTableCell align="center">Message</StyledTableCell>
                                                    <StyledTableCell align="center">SMS Status</StyledTableCell>
                                                </StyledTableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    smsDetails.results && smsDetails.results.length > 0 && smsDetails.results.map((data: any, index: any) => {
                                                        return (
                                                            <TableRow key={index}>
                                                                <TableCell style={{ height: "50px" }} align="center">{data.booking.pk}</TableCell>
                                                                <TableCell style={{ height: "50px" }} align="center">{new Date(data.created_at).toLocaleString()}</TableCell>
                                                                <TableCell style={{ height: "50px" }} align="center">{data.phonenumber}</TableCell>
                                                                <TableCell style={{ height: "50px" }} align="center">{data.message}</TableCell>
                                                                <TableCell style={{ height: "50px" }} align="center">{data.sms_status}</TableCell>

                                                            </TableRow>
                                                        )
                                                    })}
                                            </TableBody>
                                        </Table>
                                    )}
                                </TableContainer>
                                </div>
                            )}

                        </Paper>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
};

const mapStateToProps = (state: any) => ({
    // area: state.SupportExecutiveReducer.area,
});

export default connect(mapStateToProps, {
})(CreatePhlebo);

