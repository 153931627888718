import React, { useEffect, useRef, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Container,
  Grid,
  TextField,
  Select,
  MenuItem,
} from "@material-ui/core";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { generateBookingFilterUrl } from "../../helpers/generateUrl";
import { connect } from "react-redux";
import {
  getAgentList,
  getZone,
  getLeadSource,
  getPartners,
  getAllBookings,
  getAllNewBookings,
  getCities,
  getPhlebos,
  getCollectionSlot,
  getFirebaseNotification,
  getQRCodeData,
  getVerifiedbyList,
  getUnscannedTubeTube,
  getRouteManagers
} from "../actions/PhleboAdmin";
import "./filter.sass";
import { useHistory } from "react-router";
import BookingsTable from "../tables/bookingsTable";
import Loader from "../components/loader";
import { getPaymentInfo } from "../actions/PhleboAdmin";
import PaymentModal from "../components/Comments/paymentforModal";
import Chip from "@material-ui/core/Chip";
import TubeModal from "../components/Comments/tubeModal";
import { BOOKING_STATUS } from "../../booking_status";
import QuizRoundedIcon from "@mui/icons-material/QuizRounded";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@mui/material/Typography";
import {PostBookingIndexing} from "../../actions/loginActions"
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
      //   width: "100%",
      //   marginTop: "20px",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
      //   padding: theme.spacing(3),
    },
    card: {
      height: "100%",
      padding: theme.spacing(1),
    },
    card_grid: {
      // wordSpacing:3,
      justifyContent: "space-between",
    },
  })
);

interface Props {
  children: any;
  agentsList: any;
  bookingList: any;
  partnerList: any;
  getAgentList: any;
  getCollectionSlot: any;
  getPartners: any;
  getAllBookings: any;
  getAllNewBookings: any;
  getCities: any;
  collectionSlot: any;
  cities: any;
  getPhlebos: any;
  phleboList: any;
  getLeadSource: any;
  lead_source: any;
  getPaymentInfo: any;
  paymentInformation: any;
  getFirebaseNotification: any;
  firebaseNotification: any;
  getQRCodeData: any;
  qrcode: any;
  loading: Boolean;
  getVerifiedbyList: any;
  verifiedbyList: any;
  getUnscannedTubeTube: any;
  unScannedTube: any;
  getZone: any;
  zoneList: any;
  bookingListLoader: boolean;
  getRouteManagers: any;
  routeManagers: any;
  PostBookingIndexing:any;
}

const CustomerLeadFilterPage: React.FC<Props> = ({
  agentsList,
  partnerList,
  getAgentList,
  getPartners,
  getAllBookings,
  getAllNewBookings,
  bookingList,
  getCollectionSlot,
  getPaymentInfo,
  paymentInformation,
  collectionSlot,
  getCities,
  cities,
  getPhlebos,
  phleboList,
  getLeadSource,
  getFirebaseNotification,
  firebaseNotification,
  lead_source,
  getQRCodeData,
  qrcode,
  loading,
  getVerifiedbyList,
  verifiedbyList,
  getUnscannedTubeTube,
  unScannedTube,
  getZone,
  zoneList,
  bookingListLoader,
  getRouteManagers,
  routeManagers,
  PostBookingIndexing
}) => {
  const classes = useStyles();

  const history = useHistory();
  const timer = useRef<any>(0);
  const [openModal, setopenModal] = useState<boolean>(false);

  const [status, setStatus] = useState<any>([]);
  const [source, setSource] = useState<any>([]);
  const [partner, setPartner] = useState<any>([]);
  const [agent, setAgent] = useState<any>([]);
  const [city, setCity] = useState<any>([]);
  const [slot, setSlot] = useState<any>([]);
  const [VerifiedbyList, setVerifiedbyList] = useState<any>("");
  const [start_date, setStart_date] = useState("");
  const [end_date, setEnd_date] = useState("");
  const [booking_date, setBooking_date] = useState("");
  const [collection_date, setCollection_date] = useState("");
  const [verificationStatus, setVerificationStatus] = useState<any>([]);
  const [pickup_status, setPickup_status] = useState<any>([]);
  const [name, setName] = useState("");
  const [phlebo, setPhlebo] = useState<any>([]);
  const [phone, setPhone] = useState("");
  const [bookingId, setBookingId] = useState<any>("");
  const [report_status, setReportStatus] = useState<any>("none");
  const [pickup_received_amount, setPickupReceivedAmount] =
    useState<string>("none");
  const [page, setPage] = useState(0);
  const [sample_registered, setSampleRegisterd] = useState<any>("none");
  const [paymentId, setPaymentId] = useState<any>(0);
  const [openPaymentModal, setOpenPaymentModal] = useState<any>(false);
  const [heights, setheights] = useState(window.innerHeight);
  const [notificationData, setnotificationData] = useState("");
  const [defaultStatus, setDefaultStatus] = useState<any>([]);
  const [openTubeModal, setOpenTubeModal] = useState<any>(false);
  const [tubeId, setTubeId] = useState<any>("");
  const [updateTube, setUpdateTube] = useState<any>("");
  const [booking_type, setbooking_type] = useState<String>("none");
  const [totalCount, setTotalCount] = useState<any>("");
  const [phleboJourney, setPhleboJourney] = useState<any>("none");
  const [specialPackage, setSpecialPackage] = useState<any>("none");
  const [bId, setBId] = useState<any>("");
  const [bookingIdentifier, setBookingIdentifier] = useState<any>("");
  const [memberCheck, setMemberCheck] = useState<any>("");
  const [open, setOpen] = React.useState(false);
  const [locationVerified, setLocationVerified] = useState<any>("none");
  const [RM, setRM] = useState<any>([]);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const blockCharInPhoneField = (e: React.KeyboardEvent<HTMLDivElement>) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();

  useEffect(() => {
    getRouteManagers("?usergroup=RouteManager");
  }, []);



  useEffect(() => {
    if (history.action === "POP") {
      let ceBookingFilter: any;
      ceBookingFilter = localStorage.getItem("CEBookingFilter");
      if (ceBookingFilter) {
        ceBookingFilter = JSON.parse(ceBookingFilter);

        const statusD = ceBookingFilter?.status?.split(",");
        let statusTemp = new Array();
        BOOKING_STATUS.map((item: any) => {
          statusD.map((itm: any) => {
            if (item.value == itm) {
              statusTemp.push(item);
            }
          });
        });
        setDefaultStatus(statusTemp);
        ceBookingFilter?.status &&
          setStatus(ceBookingFilter?.status?.split(","));
        ceBookingFilter?.source &&
          setSource(ceBookingFilter?.source?.split(","));
        ceBookingFilter?.partner &&
          setPartner(ceBookingFilter?.partner?.split(","));
        ceBookingFilter?.city && setCity(ceBookingFilter?.city?.split(","));
        ceBookingFilter?.start_date &&
          setStart_date(ceBookingFilter?.start_date);
        setCollection_date(ceBookingFilter?.collection_date);
        ceBookingFilter?.verificationStatus &&
          setVerificationStatus(
            ceBookingFilter?.verificationStatus?.split(",")
          );
        ceBookingFilter?.pickup_status &&
          setPickup_status(ceBookingFilter?.pickup_status?.split(","));
        setName(ceBookingFilter?.name);
        setPhlebo(ceBookingFilter?.phlebo);
        setPhone(ceBookingFilter?.phone);
        setReportStatus(ceBookingFilter?.report_status);
        setSampleRegisterd(ceBookingFilter?.sample_registered);
        setPickupReceivedAmount(ceBookingFilter?.pickup_received_amount);
        setBookingId(ceBookingFilter?.bookingId);
        setPhleboJourney(ceBookingFilter?.journey);
        const url = generateBookingFilterUrl(ceBookingFilter).substring(2);
        const pageNo = page ? page : 1;
        // getAllBookings(`${url}&page=${pageNo}&optimize=true`);
        getAllNewBookings(`${url}&page=${pageNo}`);
      }
    } else {
      // getAgentList()
      // getPartners()
      // getCities()
      getCollectionSlot();
      getPhlebos();
      getLeadSource();
      localStorage.removeItem("CEBookingFilter");
      getVerifiedbyList("?usergroup=VerificationExecutive");
      setDefaultStatus([]);
    }
  }, [setDefaultStatus]);

  useEffect(() => {
    if (paymentId != 0) {
      getPaymentInfo(`?booking=${paymentId}`);
    }
  }, [paymentId]);

  useEffect(() => {
    getAllNewBookings("");
  }, [])
  useEffect(() => {
    if (tubeId !== "" && bId !== "") {
      if (bId === tubeId) {
        getQRCodeData(`?booking_id=${tubeId}`);
        getUnscannedTubeTube(bId, "?add_booking=false");
        setOpenTubeModal(true);
        setBookingIdentifier(bId);
      } else {
        getQRCodeData(`?add_booking_id=${tubeId}`);
        getUnscannedTubeTube(tubeId, "?add_booking=true");
        setOpenTubeModal(true);
        setBookingIdentifier(tubeId);
      }
    }
  }, [tubeId, updateTube]);
  const filterBookings = (e: any) => {
    const body: any = {
      status: status ? status.join(",") : "",
      verificationStatus: verificationStatus
        ? verificationStatus.join(",")
        : "",
      source: source ? source.join(",") : "",
      agent: agent ? agent.join(",") : "",
      city: city ? city.join(",") : "",
      slot: slot.join(","),
      start_date,
      end_date,
      booking_date,
      collection_date,
      pickup_status: pickup_status ? pickup_status.join(",") : "",
      name,
      verified_by: VerifiedbyList ? VerifiedbyList : "",
      phlebo: phlebo ? phlebo.join(",") : "",
      partner: partner ? partner.join(",") : "",
      phone,
      report_status,
      sample_registered,
      is_pickup_receive_amount: pickup_received_amount,
      booking_type: booking_type,
      bookingId,
      journey: phleboJourney,
      rm_fullname: RM.join(','),
      sample_pickup_interval: specialPackage,
    };
    let url = generateBookingFilterUrl(body).substring(2);
    if (locationVerified === "a") {
      if (url === "") url = "address_verified=true&distance_above_deviation=true"
      else url = url + "&address_verified=true&distance_above_deviation=true"
    }
    if (locationVerified === "b") {
      if (url === "") url = "address_verified=false&distance_above_deviation=true"
      else url = url + "&address_verified=false&distance_above_deviation=true"
    }
    if (locationVerified === "c") {
      if (url === "") url = "address_verified=true&distance_above_deviation=false"
      else url = url + "&address_verified=true&distance_above_deviation=false"
    }
    if (locationVerified === "d") {
      if (url === "") url = "location_not_shared=true"
      else url = url + "&location_not_shared=true"
    }
    if (locationVerified === "e") {
      if (url === "") url = "customer_out_of_zone=true";
      else url = url + "&customer_out_of_zone=true";
    }
    localStorage.setItem("CEBookingFilter", JSON.stringify(body));
    // getAllBookings(url ? `${url}&page=1&optimize=true` : "page=1&optimize=true");
    getAllNewBookings(url ? `${url}&page=1` : "page=1");
    setPage(0);
  };
  const onKeyDown = (e: any) => {
    if (e.key === "Enter") {
      filterBookings(e);
    }
  };

  const handleReset = () => {
    history.push("/dashboard/pha/booking_list");

    // getAllBookings("optimize=true");
    getAllNewBookings("");
  };
  const handleCross = () => {
    setOpen(false);
  };
  document.querySelectorAll("#noTyping").forEach(el => {
    el.addEventListener("keydown", function (e) {
      e.preventDefault()
    })
  })



  return (
    <>
      <main className={classes.content}>
        {/* {notificationData !== "" ? <MyNotify /> : null} */}
        <div className={classes.toolbar} />
        <Grid
          container
          spacing={2}
          direction="row"
          style={{ alignItems: "right" }}
        >
          <Grid item xs={12} sm={8}>
            <h4 style={{ fontWeight: "bold" }}>
              BOOKING LIST{" "}
              <QuizRoundedIcon color="primary" onClick={handleOpen} />
            </h4>
          </Grid>
          <Grid item xs={12} sm={4}>
            <h4 style={{ fontWeight: "bold" }}>BOOKING COUNT : {totalCount}</h4>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={1}
          direction="row"
          style={{ alignItems: "left" }}
        >
          <Grid item xs={12} sm={4} md={2}>
             <TextField
                className="input"
                name="booking"
                type="number"
                label="Booking Id"
                value={bookingId}
                variant="outlined"
                onWheel={(e: any) => e.target.blur()}
                inputProps={{ min: 0 }}
                onKeyDown={(e) => {
                  blockCharInPhoneField(e)
                  onKeyDown(e)
                }}
                onChange={(e) => setBookingId(e.target.value)}
                style={{ width: "100%" }}
              />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              name="name"
              type="text"
              label="Name"
              value={name}
              variant="outlined"
              onKeyDown={onKeyDown}
              onWheel={(e: any) => e.target.blur()}
              onChange={(e) => setName(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              name="phone"
              type="number"
              label="Phone"
              value={phone}
              variant="outlined"
              onKeyDown={onKeyDown}
              onChange={(e) => setPhone(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="city"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj.map((item: any) => item.id);
                }
                setCity(sourceIds);
              }}
              options={cities || []}
              freeSolo
              blurOnSelect
              aria-required
              limitTags={1}
              multiple
              onKeyDown={onKeyDown}
              getOptionLabel={(option: any) => option.name}
              getOptionDisabled={(option: any) => {
                return city.includes(option.id);
              }}
              disableClearable
              disableCloseOnSelect
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getCities(newInputValue);
                }, 1000);
                if (newInputValue.length === 0) {
                  setCity([]);
                }
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="City"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="status"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj.map((item: any) => item.value);
                }
                setStatus(sourceIds);
              }}
              options={BOOKING_STATUS || []}
              blurOnSelect
              aria-required
              onKeyDown={onKeyDown}
              limitTags={1}
              multiple
              getOptionLabel={(option: any) => option.text}
              getOptionDisabled={(option: any) => {
                return status.includes(option.value);
              }}
              disableClearable
              disableCloseOnSelect
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Booking Status"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
              defaultValue={defaultStatus}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="partner"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj.map((item: any) => item.id);
                }
                setPartner(sourceIds);
              }}
              freeSolo
              blurOnSelect
              limitTags={1}
              multiple
              options={partnerList?.results || []}
              onKeyDown={onKeyDown}
              getOptionDisabled={(option: any) => {
                return partner.includes(option.id);
              }}
              disableClearable
              disableCloseOnSelect
              getOptionLabel={(option: any) => option.user && option.user.name}
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getPartners(newInputValue);
                }, 1000);
                if (newInputValue.length === 0) {
                  setPartner([]);
                }
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Partner"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj.map((item: any) => item.id);
                }
                setSource(sourceIds);
              }}
              freeSolo
              multiple
              id="checkboxes-tags-demo"
              options={lead_source || []}
              disableCloseOnSelect
              limitTags={1}
              disableClearable
              onKeyDown={onKeyDown}
              getOptionLabel={(option: any) => option.name}
              getOptionDisabled={(option: any) => {
                return source.includes(option.id);
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Source"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="agent"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj.map((item: any) => item.id);
                }
                setAgent(sourceIds);
              }}
              freeSolo
              blurOnSelect
              aria-required
              limitTags={1}
              multiple
              options={agentsList?.results || []}
              getOptionDisabled={(option: any) => {
                return agent.includes(option.id);
              }}
              disableClearable
              disableCloseOnSelect
              getOptionLabel={(option: any) =>
                option.user && option.user.username
              }
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getAgentList(`${newInputValue.replace(" ", "")}`);
                }, 1000);
                if (newInputValue.length === 0) {
                  setPartner([]);
                }
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Agent"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
          <Autocomplete
              id="routemanger"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj.map((item: any) => item.username);
                }
                setRM(sourceIds);
              }}
              freeSolo
              blurOnSelect
              aria-required
              limitTags={1}
              multiple
              options={routeManagers?.results || []}
              getOptionDisabled={(option: any) => {
                return RM.includes(option.username);
              }}
              disableClearable
              disableCloseOnSelect
              getOptionLabel={(option: any) =>
                 option.username
              }
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getRouteManagers(`?usergroup=RouteManager&code=${newInputValue}`);
                }, 1000);
                if (newInputValue.length === 0) {
                  setRM([]);
                }
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Route Manger"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="verifiedby"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj.map((item: any) => item.id);
                }
                setVerifiedbyList(sourceIds);
              }}
              freeSolo
              blurOnSelect
              aria-required
              limitTags={1}
              multiple
              options={verifiedbyList?.results || []}
              getOptionDisabled={(option: any) => {
                return VerifiedbyList.includes(option.id);
              }}
              disableClearable
              disableCloseOnSelect
              getOptionLabel={(option: any) =>
                option.user && option.user.username
              }
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getVerifiedbyList(
                    `?code=${newInputValue}&usergroup=VerificationExecutive`
                  );
                }, 1000);
                if (newInputValue.length === 0) {
                  setVerifiedbyList([]);
                }
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Verified by"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="phlebo"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj.map((item: any) => item.id);
                }
                setPhlebo(sourceIds);
              }}
              freeSolo
              blurOnSelect
              aria-required
              limitTags={1}
              multiple
              options={
                phleboList.results
                  ? [
                      ...phleboList.results,
                      { id: 0, user: { username: "NoPhlebo" } },
                    ]
                  : []
              }
              getOptionDisabled={(option: any) => {
                return phlebo.includes(option.id);
              }}
              disableClearable
              disableCloseOnSelect
              onKeyDown={onKeyDown}
              getOptionLabel={(option: any) =>
                option.user && option.user.username
              }
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getPhlebos(`?code=${newInputValue}`);
                }, 1000);
                if (newInputValue.length === 0) {
                  setPhlebo([]);
                }
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Phlebo Name"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="city"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj.map((item: any) => item.value);
                }
                setVerificationStatus(sourceIds);
              }}
              options={[
                {
                  text: "Pending",
                  value: "pending",
                },
                {
                  text: "Confirmed",
                  value: "confirmed",
                },
                {
                  text: "Confirmed Cancelled",
                  value: "confirmed_cancelled",
                },
                {
                  text: "Not Verified Cancelled",
                  value: "not_verified_cancelled",
                },
              ]}
              freeSolo
              blurOnSelect
              aria-required
              limitTags={1}
              onKeyDown={onKeyDown}
              multiple
              getOptionLabel={(option: any) => option.text}
              getOptionDisabled={(option: any) => {
                return verificationStatus.includes(option.value);
              }}
              disableClearable
              disableCloseOnSelect
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Verification Status"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="city"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj.map((item: any) => item.value);
                }
                setPickup_status(sourceIds);
              }}
              options={[
                {
                  text: "Pending",
                  value: "pending",
                },
                {
                  text: "Confirmed",
                  value: "confirmed",
                },
                {
                  text: "Cancelled",
                  value: "cancelled",
                },
                {
                  text: "Hold",
                  value: "hold",
                },
              ]}
              freeSolo
              blurOnSelect
              aria-required
              limitTags={1}
              multiple
              getOptionLabel={(option: any) => option.text}
              onKeyDown={onKeyDown}
              getOptionDisabled={(option: any) => {
                return pickup_status.includes(option.value);
              }}
              disableClearable
              disableCloseOnSelect
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Pickup Status"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              name="start_date"
              type="date"
              label="Start Date"
              id="noTyping"
              value={
                booking_date === "" || collection_date === "" ? start_date : ""
              }
              variant="outlined"
              disabled={booking_date !== "" || collection_date !== ""}
              onKeyDown={e => e.preventDefault()} 
              onChange={(e) => setStart_date(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              name="end_date"
              type="date"
              value={
                booking_date === "" || collection_date === "" ? end_date : ""
              }
              className="input"
              id="noTyping"
              label="End Date"
              variant="outlined"
              onKeyDown={e => e.preventDefault()} 
              disabled={booking_date !== "" || collection_date !== ""}
              onChange={(e) => setEnd_date(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              name="booking_date"
              type="date"
              value={start_date === "" || end_date === "" ? booking_date : ""}
              className="input"
              label="Booking Date"
              id="noTyping"
              variant="outlined"
              onKeyDown={e => e.preventDefault()} 
              disabled={start_date !== "" || end_date !== ""}
              onChange={(e) => setBooking_date(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              name="collection_date"
              type="date"
              value={
                start_date === "" || end_date === "" ? collection_date : ""
              }
              className="input"
              id="noTyping"
              label="Collection Date"
              variant="outlined"
              onKeyDown={e => e.preventDefault()} 
              disabled={start_date !== "" || end_date !== ""}
              onChange={(e) => setCollection_date(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          {/* Start */}

          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="slot"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj.map((item: any) => item.id);
                }
                setSlot(sourceIds);
              }}
              options={collectionSlot.results || []}
              freeSolo
              blurOnSelect
              aria-required
              limitTags={1}
              multiple
              getOptionLabel={(option: any) =>
                option.start_time + "-" + option.end_time
              }
              getOptionDisabled={(option: any) => {
                return slot.includes(option.id);
              }}
              disableClearable
              disableCloseOnSelect
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getCollectionSlot(newInputValue);
                }, 1000);
                if (newInputValue.length === 0) {
                  setSlot([]);
                }
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Collection Slot"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Select
              className="input"
              name="booking_status"
              variant="outlined"
              value={pickup_received_amount || "pending"}
              style={{
                width: "100%",
                margin: "0",
                padding: "0",
                height: "40px",
              }}
              onChange={(e) =>
                setPickupReceivedAmount(e.target.value as string)
              }
              required
            >
              <MenuItem disabled value={"none"}>
                Pickup Recieved Amount
              </MenuItem>
              <MenuItem value={"true"}>Received</MenuItem>
              <MenuItem value={"false"}>Not Received</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Select
              className="input"
              name="sampleregisterd"
              variant="outlined"
              value={sample_registered}
              style={{
                width: "100%",
                height: "40px",
              }}
              onChange={(e) => setSampleRegisterd(e.target.value as String)}
            >
              <MenuItem disabled value="none">
                Sample Type
              </MenuItem>
              <MenuItem value={"true"}>Registerd</MenuItem>
              <MenuItem value={"false"}>Not Registerd</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Select
              className="input"
              name="sampleregisterd"
              variant="outlined"
              value={report_status}
              style={{ width: "100%", margin: "0", height: "40px" }}
              onChange={(e) => setReportStatus(e.target.value as String)}
            >
              <MenuItem disabled value={"none"}>
                Report Status
              </MenuItem>
              <MenuItem value={"na"}>None</MenuItem>
              <MenuItem value={"pending"}>Pending</MenuItem>
              <MenuItem value={"partial"}>Partial</MenuItem>
              <MenuItem value={"consolidate"}>Consolidate</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Select
              className="input"
              name="finance_status"
              variant="outlined"
              value={booking_type}
              style={{ width: "100%", margin: "0", height: "40px" }}
              onChange={(e) => setbooking_type(e.target.value as String)}
            >
              <MenuItem disabled value="none">
                Booking Type
              </MenuItem>
              <MenuItem value={"b2b"}>B2B</MenuItem>
              <MenuItem value={"b2c"}>B2C</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Select
              className="input"
              name="sampleregisterd"
              variant="outlined"
              value={phleboJourney}
              style={{ width: "100%", margin: "0", height: "40px" }}
              onChange={(e) => setPhleboJourney(e.target.value as String)}
            >
              <MenuItem disabled value={"none"}>
                Phlebo Journey
              </MenuItem>
              <MenuItem value={"started"}>Started</MenuItem>
              <MenuItem value={"not-started"}>Not Started</MenuItem>
              <MenuItem value={"end"}>end</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="location_verified"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  setLocationVerified(obj.value);
                }

              }}
              options={[
                {
                  text: "More than 3 Km - Verified",
                  value: "a",
                },
                {
                  text: "More than 3 Km - Not Verified",
                  value: "b",
                },
                {
                  text: "Less than 3 Km - Verified",
                  value: "c",
                },
                {
                  text: "Location not shared",
                  value: "d",
                },
                {
                  text: "Non-Serviceable - Not Verified",
                  value: "e",
                },
              ]}
              freeSolo
              blurOnSelect
              aria-required
              getOptionLabel={(option: any) => option.text}
              disableClearable
              disableCloseOnSelect
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Location/Verified"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Select
              className="input"
              name="specialPackage"
              variant="outlined"
              value={specialPackage}
              style={{ width: "100%", height: "40px" }}
              onChange={(e) => setSpecialPackage(e.target.value as String)}
            >
              <MenuItem disabled value={"none"}>Select Special Package</MenuItem>
              <MenuItem value={"SAMPLE_20M_INTERVAL_3TIMES"}>SAMPLE 20M INTERVAL 3TIMES</MenuItem>
              <MenuItem value={"SAMPLE_1H_INTERVAL_3TIMES"}>SAMPLE 1H INTERVAL 3TIMES</MenuItem>
              <MenuItem value={"SAMPLE_1H_INTERVAL_2TIMES"}>SAMPLE 1H INTERVAL 2TIMES</MenuItem>
              <MenuItem value={"SAMPLE_2H_INTERVAL_2TIMES"}>SAMPLE 2H INTERVAL 2TIMES</MenuItem>
              <MenuItem value={"URINE_CONTAINER_WITHIN_24H"}>URINE CONTAINER WITHIN 24H</MenuItem>
            </Select>
          </Grid>
          {/* End */}
          <Grid item xs={12} sm={4} md={2}>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              onClick={filterBookings}
            >
              Filter
            </Button>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => handleReset()}
            >
              Reset
            </Button>
          </Grid>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={open}>
              <div
                style={{
                  position: "absolute",
                  left: "10%",
                  right: "15%",
                  top: "25%",
                  minHeight: "400",
                  minWidth: "500",
                  backgroundColor: "white",
                }}
              >
                <Box style={{}}>
                  <Typography
                    id="transition-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    <div
                      style={{
                        margin: "0rem 2rem",
                        paddingTop: "1rem",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <h4>Note:</h4>
                      <CloseIcon onClick={handleCross} />
                    </div>
                  </Typography>
                  <Typography id="transition-modal-description" sx={{ mt: 1 }}>
                    <div style={{ margin: "1rem" }}>
                      <div
                        style={{
                          alignItems: "left",
                          display: "inline-block",
                          margin: "1rem",
                        }}
                      >
                        <small>
                          <ul style={{ paddingLeft: "15px" }}>
                            <li>
                              You can filter by booking date or start and end
                              date.
                            </li>
                            <li>T: Total Additional Booking count</li>
                            <li>P: Pending count for additional member</li>
                            <li>C: Confirmed count for additional member</li>
                            <li>
                              For B2B booking first select Booking Type b2b then
                              apply any filter option
                            </li>
                          </ul>
                        </small>
                      </div>
                      <div
                        style={{
                          alignItems: "left",
                          display: "inline-block",
                          margin: "1rem",
                        }}
                      >
                        <small>
                          <div className="r-cl" style={{ display: "flex" }}>
                            <div
                              className="boxStyle"
                              style={{
                                border: "1px solid",
                                width: "17px",
                                height: "17px",
                                background: "#ffe082",
                              }}
                            ></div>
                            <div style={{ marginLeft: "5px" }}>
                              B2B booking and Payable Amount is Zero and Phlebo
                              Not Reached
                            </div>
                          </div>
                          <div className="r-cl" style={{ display: "flex" }}>
                            <div
                              className="boxStyle"
                              style={{
                                border: "1px solid",
                                width: "17px",
                                height: "17px",
                                background: "#f48fb1",
                              }}
                            ></div>
                            <div style={{ marginLeft: "5px" }}>
                              B2B booking and Payable Amount is not Zero and
                              Phlebo Not Reached
                            </div>
                          </div>
                          <div className="r-cl" style={{ display: "flex" }}>
                            <div
                              className="boxStyle"
                              style={{
                                border: "1px solid",
                                width: "17px",
                                height: "17px",
                                background: "#64ffda",
                              }}
                            ></div>
                            <div style={{ marginLeft: "5px" }}>
                              B2C booking and Payable Amount is Zero and Phlebo
                              Not Reached
                            </div>
                          </div>
                          <div className="r-cl" style={{ display: "flex" }}>
                            <div
                              className="boxStyle"
                              style={{
                                border: "1px solid",
                                width: "17px",
                                height: "17px",
                                background: "default",
                              }}
                            ></div>
                            <div style={{ marginLeft: "5px" }}>
                              B2C booking and Payable Amount is not Zero and
                              Phlebo Not Reached
                            </div>
                          </div>
                          <div className="r-cl" style={{ display: "flex" }}>
                            <div
                              className="boxStyle"
                              style={{
                                border: "1px solid",
                                width: "17px",
                                height: "17px",
                                background: "#ba68c8",
                              }}
                            ></div>
                            <div style={{ marginLeft: "5px" }}>
                              Phlebo Reached
                            </div>
                          </div>
                          <div className="r-cl" style={{ display: "flex" }}>
                            <div
                              className="boxStyle"
                              style={{
                                border: "1px solid",
                                width: "17px",
                                height: "17px",
                                background: "#ff9800",
                              }}
                            ></div>
                            <div style={{ marginLeft: "5px" }}>
                              Hold Due To Payment
                            </div>
                          </div>
                          <div className="r-cl" style={{ display: "flex" }}>
                            <div
                              className="boxStyle"
                              style={{
                                border: "1px solid",
                                width: "17px",
                                height: "17px",
                                background: "#cddc39",
                              }}
                            ></div>
                            <div style={{ marginLeft: "5px" }}>
                              Booking Amount is more than 2000 Rupees
                            </div>
                          </div>
                        </small>
                      </div>
                    </div>
                  </Typography>
                </Box>
              </div>
            </Fade>
          </Modal>
        </Grid>
        <PaymentModal
          openModalP={openPaymentModal}
          setOpenModalP={setOpenPaymentModal}
          paymentInformation={paymentInformation}
        />
        <br />
        {bookingListLoader ? (
          <Loader />
        ) : (
          bookingList.results &&
          bookingList.results.length > 0 && (
            <Box>
              <Container maxWidth={false}>
                <Grid container spacing={3}>
                  <BookingsTable
                      setOpenTubeModal={setOpenTubeModal}
                      setTubeId={setTubeId}
                      setopenModal={setopenModal}
                      openModal={openModal}
                      page={page}
                      setPage={setPage}
                      loading={loading}
                      getBookings={getAllBookings}
                      bookingList={bookingList}
                      paymentId={paymentId}
                      setPaymentId={setPaymentId}
                      openPaymentModal={openPaymentModal}
                      setOpenPaymentModal={setOpenPaymentModal}
                      setTotalCount={setTotalCount}
                      setBId={setBId}
                      setMemberCheck={setMemberCheck}
                      getZone={getZone}
                      zoneList={zoneList}
                      bookingListLoader={bookingListLoader} 
                      getAllNewBookings={getAllNewBookings}
                      PostBookingIndexing={PostBookingIndexing}
                      />
                </Grid>
              </Container>
            </Box>
          )
        )}
        {bookingList.results && bookingList.results.length === 0 && (
          <h6 style={{ textAlign: "center" }}>No Data Found</h6>
        )}
        <TubeModal
          setOpenTubeModal={setOpenTubeModal}
          openTubeModal={openTubeModal}
          qrcode={qrcode}
          getQRCodeData={getQRCodeData}
          tubeId={tubeId}
          setTubeId={setTubeId}
          setUpdateTube={setUpdateTube}
          unScannedTube={unScannedTube}
          bookingIdentifier={bookingIdentifier}
          memberCheck={memberCheck}
        />
      </main>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  agentsList: state.PhleboAdminReducer.agentsList,
  partnerList: state.PhleboAdminReducer.partnerList,
  bookingList: state.PhleboAdminReducer.bookingList,
  cities: state.PhleboAdminReducer.cities,
  phleboList: state.PhleboAdminReducer.phleboList,
  collectionSlot: state.PhleboAdminReducer.collectionSlot,
  paymentInformation: state.PhleboAdminReducer.paymentInformation,
  loading: state.PhleboAdminReducer.loading,
  firebaseNotification: state.PhleboAdminReducer.firebaseNotification,
  qrcode: state.PhleboAdminReducer.qrcode,
  lead_source: state.PhleboAdminReducer.lead_source,
  verifiedbyList: state.PhleboAdminReducer.verifiedbyList,
  unScannedTube: state.PhleboAdminReducer.unScannedTube,
  zoneList: state.PhleboAdminReducer.zoneList,
  bookingListLoader: state.PhleboAdminReducer.bookingListLoader,
  routeManagers: state.PhleboAdminReducer.routeManagers,
});

export default connect(mapStateToProps, {
  getAgentList,
  getPartners,
  getCities,
  getPhlebos,
  getLeadSource,
  getCollectionSlot,
  getPaymentInfo,
  getFirebaseNotification,
  getAllBookings,
  getAllNewBookings,
  getQRCodeData,
  getVerifiedbyList,
  getUnscannedTubeTube,
  getZone,
  getRouteManagers,
  PostBookingIndexing
})(CustomerLeadFilterPage);
