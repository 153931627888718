import React, { useEffect, useRef, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Grid, TextField, MenuItem } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  getBatchPayment,
  initiateRefund,
  updateInitiateRefund,
  getPhlebos,
  getLabCredential,
} from "../../actions/RouteManagerAction";
import Loader from "../../components/loader";
import BatchedPaymentTable from "../../tables/batchedPayment";
import { batchPaymentFilter } from "../../../helpers/generateUrl";
import SearchIcon from "@mui/icons-material/Search";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import Button from '@mui/material/Button';
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import CircularProgress from "@material-ui/core/CircularProgress";
import Modal from "@mui/material/Modal";
import moment from "moment";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
let width = window.innerWidth;
let isMobile = width <= 500;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
      //   width: "100%",
      //   marginTop: "20px",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
      //   padding: theme.spacing(3),
    },
    card: {
      height: "100%",
      padding: theme.spacing(1),
    },
    card_grid: {
      // wordSpacing:3,
      justifyContent: "space-between",
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: isMobile ? "400px" : "800px",
    },
  })
);

interface Props {
  getBatchPayment: any;
  batchPayment: any;
  initiateRefund: any;
  updateInitiateRefund: any;
  getPhlebos: any;
  phleboList: any;
  getLabCredential: any;
  labCredential: any;
  loading: boolean;
}

const BatchedPaymentFilter: React.FC<Props> = ({
  loading,
  getBatchPayment,
  batchPayment,
  initiateRefund,
  updateInitiateRefund,
  getPhlebos,
  phleboList,
  getLabCredential,
  labCredential,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const timer = useRef<any>(0);
  const [bookingId, setBookingId] = useState<any>("");
  const [amount, setAmount] = useState<any>("");
  const [accountNumber, setAccountNumber] = useState<any>("");
  const [accountHolderName, setAccountHolderName] = useState<any>("");
  const [bankName, setBankName] = useState<any>("");
  const [status, setStatus] = useState<any>("");
  const [paymentMode, setPaymentMode] = useState<any>("");
  const [openRefundModal, setOpenRefundModal] = useState<boolean>(false);
  const [createBookingId, setCreateBookingId] = useState<any>("");
  const [createAmount, setCreateAmount] = useState<any>("");
  const [createPaymentMode, setCreatePaymentMode] = useState<any>("");
  const [createRemarks, setCreateRemarks] = useState<any>("");
  const [refundId, setRefundId] = useState<any>("");
  const [buttonType, setButtoneType] = useState<any>("");
  const [apiType, setApiType] = useState<any>("");
  const [transferDate, setTransferDate] = useState<any>("");
  const [transectionId, setTransectionId] = useState<any>("");
  const [file1, setFile1] = React.useState<any>([]);
  const [updateStatus, setUpdateStatus] = useState<any>("");
  const [batchNumber, setBatchNumber] = useState<any>("");
  const [createdStartDate, setCreatedStartDate] = useState<any>("");
  const [createdEndDate, setCreatedEndDate] = useState<any>("");
  const [phlebo, setPhlebo] = useState<any>("");
  const [lab, setLab] = useState<any>("");
  const [paymentStatusType, setPaymentStatusType] = useState<any>("false");

  useEffect(() => {
    getBatchPayment();
    getPhlebos();
    getLabCredential();
  }, []);

  const filterBatchPayment = () => {
    const body: any = {
      bookingId,
      batchNumber,
      lab,
      phlebo,
      created_start_date:createdStartDate,
      created_end_date:createdEndDate,
    };
    const url = batchPaymentFilter(body).substring(2);
    getBatchPayment(url ? `?${url}` : "");
    // setPage(0)
  };

  return (
    <>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <h2>Batched Payment</h2>
        <Grid
          container
          spacing={1}
          direction="row"
          style={{ alignItems: "left" }}
        >
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              type="number"
              onWheel={(e: any) => e.target.blur()}
              label="Booking Id"
              value={bookingId}
              variant="outlined"
              onChange={(e) => setBookingId(e.target.value)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              type="number"
              onWheel={(e: any) => e.target.blur()}
              label="Batch Number"
              value={batchNumber}
              variant="outlined"
              onChange={(e) => setBatchNumber(e.target.value)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              onChange={(event, newValue) => {
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  setLab(obj.id);
                }
              }}
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getLabCredential(`?name=${newInputValue}`);
                }, 1000);

                if (newInputValue.length === 0) {
                  setLab("");
                }
              }}
              options={labCredential?.results || []}
              freeSolo
              blurOnSelect
              aria-required
              getOptionLabel={(option: any) => `${option?.name}`}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Lab"
                  variant="outlined"
                  fullWidth
                  className="input"
                  inputProps={{
                    ...params.inputProps,
                    onKeyDown: (e) => {
                          if (e.key === 'Enter') {
                            e.stopPropagation();
                          }
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              name="name"
              type="date"
              label="Created Start Date"
              value={createdStartDate}
              variant="outlined"
              onChange={(e) => setCreatedStartDate(e.target.value)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              name="name"
              type="date"
              label="Created End Date"
              value={createdEndDate}
              variant="outlined"
              onChange={(e) => setCreatedEndDate(e.target.value)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="phleboName"
              onChange={(event, newValue) => {
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  setPhlebo(obj.paneluser_id);
                }
              }}
              options={
                phleboList.results
                  ? [
                    ...phleboList.results,
                    { id: 0, user: { username: "NoPhlebo" } },
                  ]
                  : []
              }
              freeSolo
              blurOnSelect
              aria-required
              getOptionLabel={(option: any) =>
                option.user && option.user.username
              }
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getPhlebos(`?code=${newInputValue}`);
                }, 1000);
                if (newInputValue.length === 0) {
                  setPhlebo("");
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Phlebo Name"
                  variant="outlined"
                  fullWidth
                  className="input"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              onClick={filterBatchPayment}
              startIcon={<SearchIcon />}
              disabled={loading}
            >
              Filter
            </Button>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              startIcon={<RestartAltIcon />}
              onClick={() => history.push("/dashboard/rm/batched_payment")}
            >
              Reset
            </Button>
          </Grid>
        </Grid>
        <BatchedPaymentTable
          loading={loading}
          batchPayment={batchPayment}
          getBatchPayment={getBatchPayment}
        />
      </main>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    loading: state.RouteManagerReducer.loading,
    batchPayment: state.RouteManagerReducer.batchPayment,
    phleboList: state.RouteManagerReducer.phleboList,
    labCredential: state.RouteManagerReducer.labCredential,
  };
};

export default connect(mapStateToProps, {
  getBatchPayment,
  initiateRefund,
  updateInitiateRefund,
  getPhlebos,
  getLabCredential,
})(BatchedPaymentFilter);
