import React, { useEffect, useRef, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Paper,
  Grid,
  TextField,
  Button,
  MenuItem,
  Select,
  Container,
} from "@material-ui/core";
import Box from "@mui/material/Box";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { connect } from "react-redux";
import {
  getDispositions,
  getAgentList,
  getCities,
  getBookings,
  getDispositionData,
} from "../../actions/productMangerAction";
import "./filter.sass";
import { useHistory } from "react-router";
import Loader from "../../Components/loader";
import { generateBookingDispositionUrl } from "../../../helpers/generateUrl";
import {
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridToolbarExport,
} from "@material-ui/data-grid";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
      //   width: "100%",
      //   marginTop: "20px",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
      //   padding: theme.spacing(3),
    },
    card: {
      height: "100%",
      padding: theme.spacing(1),
    },
    card_grid: {
      // wordSpacing:3,
      justifyContent: "space-between",
    },
    paper: {
      marginTop: "2rem",
      padding: "0.5rem 2rem",
      width: "100%",
    },
  })
);

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

interface Props {
  // getDispositions: any
  getBookings: any;
  // dispositions: any
  bookingList: any;
  getAgentList: any;
  getCities: any;
  agentsList: any;
  dispositionData: any;
  getDispositionData: any;
  cities: any;
  loading: boolean;
}

const CustomerLeadStateFilterPage: React.FC<Props> = ({
  loading,
  // getDispositions,
  getBookings,
  // dispositions,
  bookingList,
  getAgentList,
  getDispositionData,
  dispositionData,
  getCities,
  agentsList,
  cities,
}) => {
  const classes = useStyles();

  const history = useHistory();

  const BOOKINGDISPOSITION: any = [
    // {
    //     id: 1,
    //     text: "Call Back From Sales"
    // },
    // {
    //     id: 2,
    //     text: "Phlebo Didn't Reach on time"
    // },
    // {
    //     id: 3,
    //     text: "Wrong Report Received"
    // },
    // {
    //     id: 4,
    //     text: "Edit Report"
    // },
    // {
    //     id: 5,
    //     text: "Report not received"
    // },
    // {
    //     id: 6,
    //     text: "Partial report received"
    // },
    // {
    //     id: 7,
    //     text: "Rescheduled to next Date"
    // },
    // {
    //     id: 8,
    //     text: "Time Slot Changed"
    // },
    // {
    //     id: 9,
    //     text: "Cancel- Out of Station"
    // },
    // {
    //     id: 10,
    //     text: "Cancel- Travel Plan Changed"
    // },
    // {
    //     id: 11,
    //     text: "Cancel- Duplicate Booking"
    // },
    // {
    //     id: 12,
    //     text: "Cancel"
    // },
    // {
    //     id: 13,
    //     text: "Subscribed to Competitor"
    // },
    // {
    //     id: 14,
    //     text: "Call Later"
    // },
    // {
    //     id: 15,
    //     text: "Wrong Number"
    // },
    // {
    //     id: 16,
    //     text: "Health Consultation"
    // },
    // {
    //     id: 17,
    //     text: "Phlebo Complaint"
    // },
    // {
    //     id: 18,
    //     text: "Wrong Commitment By Sales"
    // },
    // {
    //     id: 19,
    //     text: "Wrong Commitment By Phlebo"
    // },
    // {
    //     id: 20,
    //     text: "Booking Done"
    // },
    //  {
    //     id: 34,
    //     text: "Resampling"
    // }
    {
      id: 8,
      text: "Time Slot Changed",
    },
    {
      id: 9,
      text: "Cancel-Out Of Station",
    },
    {
      id: 10,
      text: "Cancel-Travel Plan Changed",
    },
    {
      id: 11,
      text: "Cancel-Duplicate Booking",
    },
    {
      id: 12,
      text: "Cancel",
    },
    {
      id: 21,
      text: "Booked From Different Lab",
    },
    {
      id: 15,
      text: "Wrong Number",
    },
    {
      id: 22,
      text: "Cancel-Out Of Service Area ",
    },
    {
      id: 23,
      text: "Confirmed ",
    },
    {
      id: 24,
      text: "Rescheduled  ",
    },
    {
      id: 14,
      text: "Call Later ",
    },
  ];

  const [filtered, setFiltered] = useState(false);

  const [city, setCity] = useState<any>([]);
  const [agent, setAgent] = useState<any>([]);
  const [category, setCategory] = useState<any>([]);
  const [partner, setPartner] = useState<any>([]);
  const [start_date, setStart_date] = useState("");
  const [end_date, setEnd_date] = useState("");
  const [created_date, setCreated_date] = useState("");
  const [userGroup, setUserGroup] = useState("none");

  const timer = useRef<any>(0);

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 200 },
    { field: "booking", headerName: "Booking", width: 200 },
    { field: "by_user", headerName: "Panel User", width: 200 },
    { field: "city", headerName: "City", width: 200 },
    { field: "disposition", headerName: "Disposition", width: 200 },
    { field: "details", headerName: "Details", width: 200 },
    { field: "created_at", headerName: "Created At", width: 200 },
  ];
  let rows: any = [];

  if (dispositionData.results) {
    rows =
      dispositionData.results &&
      dispositionData.results.length > 0 &&
      dispositionData.results.map((data: any) => {
        return {
          ...data,
          id: data.booking.pk,
          by_user: data.by_user,
          city: data.booking.city,
          disposition: data.disposition,
          details: data.details,
          created_at: new Date(data.created_at).toLocaleString(),
        };
      });
  }

  useEffect(() => {
    getAgentList(`?usergroup=SupportExecutive`);
    // getBookings()
    getCities();
    // getDispositionData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterDisposition = () => {
    const body: any = {
      lead_call_status_id: category.join(","),
      city: city.join(","),
      agent: agent.join(","),
      start_date,
      end_date,
      created_date,
      userGroup,
    };
    const url = generateBookingDispositionUrl(body).substring(2);
    getDispositionData(`${url}&download=true`);
    setFiltered(true);
  };
  return (
    <>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid
          container
          spacing={3}
          direction="row"
          alignItems="center"
          justify="center"
          style={{ margin: "0" }}
        >
          <Grid item xs={12} sm={6} md={3}>
            <Autocomplete
              id="city"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj.map((item: any) => item.id);
                }
                setCity(sourceIds);
              }}
              options={cities}
              freeSolo
              blurOnSelect
              aria-required
              limitTags={1}
              multiple
              getOptionLabel={(option: any) => option.name}
              getOptionDisabled={(option: any) => {
                return city.includes(option.id);
              }}
              disableClearable
              disableCloseOnSelect
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getCities(newInputValue);
                }, 1000);
                if (newInputValue.length === 0) {
                  setCity([]);
                }
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  placeholder="City"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Autocomplete
              id="agent"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj.map((item: any) => item.id);
                }
                setAgent(sourceIds);
              }}
              freeSolo
              blurOnSelect
              aria-required
              limitTags={1}
              multiple
              options={agentsList.results || []}
              getOptionDisabled={(option: any) => {
                return agent.includes(option.id);
              }}
              disableClearable
              disableCloseOnSelect
              getOptionLabel={(option: any) =>
                option.user && option.user.username
              }
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getAgentList(`${newInputValue.replace(" ", "")}`);
                }, 1000);
                if (newInputValue.length === 0) {
                  setPartner([]);
                }
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  placeholder="Agent"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>
          {/* <Grid item xs={12} sm={6} md={4}>
                        <Autocomplete
                            onChange={(event, newValue) => {
                                let sourceIds: any = []
                                if (newValue) {
                                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                    sourceIds = obj.map((item: any) => item.id)
                                }
                                setCategory(sourceIds)
                            }}
                            multiple
                            id="checkboxes-tags-demo"
                            options={BOOKINGDISPOSITION}
                            disableCloseOnSelect
                            limitTags={1}
                            getOptionLabel={(option: any) => option.text}
                            getOptionDisabled={(option: any) => {
                                return category.includes(option.id)
                            }}
                            disableClearable
                            renderInput={(params) => (
                                <TextField className="input"
                                    {...params}
                                    placeholder="Booking Disposition"
                                    variant="outlined"
                                    style={{ width: "100%", margin: "0", padding: "0" }}
                                    required
                                />
                            )}
                        />
                    </Grid> */}
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              className="input"
              name="start_date"
              type="date"
              label="Start Date"
              value={start_date}
              disabled={created_date !== ""}
              variant="outlined"
              onChange={(e) => setStart_date(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              className="input"
              name="end_date"
              type="date"
              label="End Date"
              value={end_date}
              disabled={created_date !== ""}
              variant="outlined"
              onChange={(e) => setEnd_date(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              className="input"
              name="created_date"
              type="date"
              label="Created Date"
              disabled={start_date !== "" || end_date !== ""}
              value={created_date}
              variant="outlined"
              onChange={(e) => setCreated_date(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Select
              className="input"
              name="usergroup"
              variant="outlined"
              value={userGroup}
              style={{ width: "100%" }}
              onChange={(e) => setUserGroup(e.target.value as string)}
            >
              <MenuItem disabled value={"none"}>
                User Type
              </MenuItem>
              <MenuItem value={"SupportExecutive"}>Support Executive</MenuItem>
              <MenuItem value={"VerificationExecutive"}>
                Verification Executive
              </MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Button
              variant="contained"
              color="secondary"
              style={{ height: "56px" }}
              fullWidth
              onClick={filterDisposition}
              disabled={loading}
            >
              Filter
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Button
              variant="contained"
              color="primary"
              style={{ height: "56px" }}
              fullWidth
              onClick={() => {
                setFiltered(false);
                history.push("/dashboard/pm/dispositions/bookingexport");
              }}
            >
              Reset
            </Button>
          </Grid>
        </Grid>
        <br />
        {loading ? (
          <Loader />
        ) : (
          filtered &&
          dispositionData.results &&
          dispositionData.results.length > 0 && (
            <Box>
              <Container maxWidth={false}>
                <Grid container spacing={3}>
                  <Paper elevation={15} className={classes.paper}>
                    <div style={{ height: 700 }}>
                      <DataGrid
                        rowsPerPageOptions={[]}
                        rows={dispositionData && dispositionData.results}
                        columns={columns}
                        components={{
                          Toolbar: CustomToolbar,
                        }}
                        pageSize={50}
                        rowCount={dispositionData.count || 0}
                        loading={loading}
                      />
                    </div>
                  </Paper>
                </Grid>
              </Container>
            </Box>
          )
        )}
      </main>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  loading: state.ProductManagerReducer.loading,
  // dispositions: state.ProductManagerReducer.dispositions,
  dispositionData: state.ProductManagerReducer.dispositionData,
  bookingList: state.ProductManagerReducer.bookingList,
  agentsList: state.ProductManagerReducer.agentsList,
  cities: state.ProductManagerReducer.cities,
});

export default connect(mapStateToProps, {
  // getDispositions,
  getBookings,
  getAgentList,
  getCities,
  getDispositionData,
})(CustomerLeadStateFilterPage);
