import "./index.sass";
import React, { useState } from "react";
import {
    createStyles,
    Grid,
    makeStyles,
    withStyles,
    Theme,
} from "@material-ui/core";
import Button from '@mui/material/Button';
import { TextField } from "@material-ui/core";
import { connect } from "react-redux";
import {
    getMapMyIndiaAccessToken,
    getRiderList,
    getRoutes,
    getCities,
    getRiderLiveMap,
    getRiderMapMyIndia,
} from "../../actions/LogisticsPanelAction";
import { useEffect } from "react";
import { useRef } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useHistory } from "react-router-dom";
import axios from 'axios';
import { generateBookingFilterUrl } from "../../../helpers/generateUrl";
import QuizRoundedIcon from '@mui/icons-material/QuizRounded';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DelayPhleboIcon from "../../../images/Group21386.svg";

const position = [51.505, -0.09];

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 0,
        },
        toolbar: {
            alignItems: "center",
            justifyContent: "flex-end",
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        },
        content: {
            width: "80%",
            flexGrow: 21,
        },
        tableContainer: {
            marginTop: "2rem",
            top: "200px",
        },
        mapCSS: {
            height: "700px",
            width: "90%"
        }
    })
);

interface ViewBookingOnMapProps {
    loading: any;
    getMapMyIndiaAccessToken: any;
    mapmyindiaAccessToken: any;
    getRiderList: any;
    riderList: any;
    getRoutes: any;
    routesData: any;
    getCities: any;
    cities: any;
    getRiderLiveMap: any;
    riderOnMap: any;
    getRiderMapMyIndia: any;
    riderMapMyIndia: any;
}

const ViewBookingOnMap: React.FC<ViewBookingOnMapProps> = ({
    loading,
    getMapMyIndiaAccessToken,
    mapmyindiaAccessToken,
    getRiderList,
    riderList,
    getRoutes,
    routesData,
    getCities,
    cities,
    getRiderLiveMap,
    riderOnMap,
    getRiderMapMyIndia,
    riderMapMyIndia,
}) => {
    const classes = useStyles();
    const history = useHistory();
    const [accessToken, setAccessToken] = useState<any>("");
    const timer = useRef<any>(0);
    const [rider, setRider] = useState<any>("");
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [cityId, setCityId] = useState<any>("");
    const [refreshIt, setRefreshIt] = useState<number>(1);
    useEffect(() => {
        getMapMyIndiaAccessToken();
        // getRiderMapMyIndia();
    }, []);

    const filterBookings = () => {
        const body: any = {
            cityId: cityId,
            rider: rider,

        };
        const url = generateBookingFilterUrl(body).substring(2);
        getRiderMapMyIndia(`?${url}`)
        getRiderLiveMap();
        setRefreshIt(refreshIt === 1 ? 2 : 1);
    };

    const handleReset = () => {
        history.go("/dashboard/lp/rider_on_map");
        getRiderLiveMap(); 
        getRiderMapMyIndia() ;
        setRider("");
        setCityId("");
        setRefreshIt(refreshIt === 1 ? 2 : 1);
      };
    useEffect(() => {
        if (mapmyindiaAccessToken?.accesstoken) {
            setAccessToken(mapmyindiaAccessToken?.accesstoken)
        }
    }, [mapmyindiaAccessToken]);

    const loadExternalScript = (token: any, call: any) => {
        var mapurl =
            `https://apis.mapmyindia.com/advancedmaps/api/` +
            token +
            `/map_sdk?layer=vector&v=2.0`;
        const scriptElement = document.createElement("script");
        document.head.appendChild(scriptElement);
        scriptElement.onload = () => {
            return call(true);
        };
        scriptElement.id = "mapScript";
        scriptElement.src = mapurl;
        scriptElement.async = true;
        scriptElement.defer = true;
    };

    // const getPhleboLiveLocation = async () => {

    //     let config = {
    //         headers: {
    //             'Authorization': `Bearer ${accessToken}`,

    //         },
    //     }
    //     if (riderId === "") {
    //         setMapLoading(true)
    //         axios.get(`https://intouch.mapmyindia.com/iot/api/device/?id=${riderId.join(",")}`, config)
    //             .then(function (response: any) {
    //                 setMapMyIndiaLiveResponse(response)
    //                 setMapLoading(false)
    //             })
    //             .catch(function (error: any) {
    //                 console.log("live loc err", error);
    //             })
    //     }
    //     else {
    //         axios.get(`https://intouch.mapmyindia.com/iot/api/device/?id=${mapMyIndiaphleboUserId}`, config)
    //             .then(function (response: any) {
    //                 setMapMyIndiaLiveResponse(response)
    //                 setMapLoading(false)

    //             })
    //             .catch(function (error: any) {
    //                 console.log("live loc err", error);
    //             })
    //     }


    // }
    let mapData: any
    useEffect(() => {
        if (mapmyindiaAccessToken?.accesstoken !== undefined && riderMapMyIndia?.geofancearea && riderMapMyIndia?.geofancearea.length > 0) {
            loadExternalScript(mapmyindiaAccessToken?.accesstoken, (data: any) => {
                if (data) {
                    if (window.hasOwnProperty("MapmyIndia")) {
                        mapData = window['MapmyIndia'].Map("tempMap", {
                            center: [28.627941, 77.37493],
                            zoom: 4,
                            zoomControl: true,
                        });
                        mapData?.on("load", mapMarketDisplay)
                    }
                }
            });

        } else if (mapmyindiaAccessToken?.accesstoken !== undefined && riderOnMap && riderOnMap.length > 0) {
            loadExternalScript(mapmyindiaAccessToken?.accesstoken, (data: any) => {
                if (data) {
                    if (window.hasOwnProperty("MapmyIndia")) {
                        mapData = window['MapmyIndia'].Map("tempMap", {
                            center: [28.627941, 77.37493],
                            zoom: 4,
                            zoomControl: true,
                        });
                        mapData?.on("load", mapMarketDisplayAll)
                    }
                }
            });
        }
    }, [mapmyindiaAccessToken?.accesstoken, riderMapMyIndia, riderOnMap]);

    //polygon map
    const mapMarketDisplay = () => {
        window['MapmyIndia'].Polyline({
            map: mapData,
            path: eval(riderMapMyIndia && riderMapMyIndia?.geofancearea && riderMapMyIndia?.geofancearea.length > 0 && riderMapMyIndia.geofancearea),
            strokeColor: "#3f51b5",
            strokeOpacity: 1.0,
            strokeWeight: 4,
            fitbounds: true,
            lineGap: 0,
            fitboundOptions: { padding: 100, duration: 1000 },
            popupHtml: "route 1",
            popupOptions: { offset: { 'bottom': [0, -20] } }
        })
        //rider map with filter
        riderMapMyIndia && riderMapMyIndia?.riders && riderMapMyIndia?.riders?.length > 0 && riderMapMyIndia?.riders?.map((loc: any) => (

            window['MapmyIndia'].addMarker({
                map: mapData,
                position: { "lat": parseFloat(loc?.user[0]?.latitude), "lng": parseFloat(loc?.user[0]?.longitude) },
                fitbounds: false,
                fitboundOptions: { padding: 120, duration: 1000, maxZoom: 12 },
                width: "35px",
                height: "45px",
                icon: loc?.stops_details?.delay === 0 ? "https://cdnjs.cloudflare.com/ajax/libs/emojione/2.2.7/assets/svg/1f3cd.svg" : "https://staticredcliffelabs.s3.amazonaws.com/media/gallary-file/None/282e91c0-79a0-44a3-83ca-dfe536e732e9.svg",
                popupOptions: { "openPopup": false },
                popupHtml: `<div className="cards">
              <div className="card_child">
                  <span className="spans">Rider Name :${loc?.user[0]?.username || ""} </span><br/>
                  <span className="spans">Route Name :${loc?.user[0]?.route || ""} </span><br/>
                  <span className="spans">Current Stop :${loc?.user[1]?.stop || ""} </span><br/>
                  <span className="spans">Delay Time :${loc?.user[1]?.delay || ""}min </span>
              </div>  
              </div>`,

                draggable: false
            })
        ))
    }
    //rider map whiout filter
    const mapMarketDisplayAll = () => {
        riderOnMap && riderOnMap?.length > 0 && riderOnMap?.map((loc: any) => (
            window['MapmyIndia'].addMarker({
                map: mapData,
                position: { "lat": Number(loc?.latitude), "lng": Number(loc?.longitude) },
                fitbounds: false,
                fitboundOptions: { padding: 120, duration: 1000, maxZoom: 12 },
                width: "35px",
                height: "45px",
                icon: "https://cdnjs.cloudflare.com/ajax/libs/emojione/2.2.7/assets/svg/1f3cd.svg",
                popupOptions: { "openPopup": false },
                popupHtml: `<div className="cards">
              <div className="card_child">
                  <span className="spans">Rider Name :${loc?.rider?.username || ""} </span>
              </div>  
              </div>`,
                draggable: false
            })
        ))
    }
    useEffect(() => {
        getRiderLiveMap();
    }, [])

    return (
        <main className={classes.content}>
            <div className={classes.toolbar} />
            <Grid item xs={12}>
                <h2> <QuizRoundedIcon color="primary" onClick={handleOpen} /></h2>
                <h4 style={{ fontWeight: "bold" }}>RIDER ON MAP</h4>
            </Grid>
            <Grid
                container
                spacing={1}
                direction="row"
                style={{
                    marginTop: "20px",
                    padding: "10px",
                    background: "#f3f3f3",
                    borderRadius: 8,
                }}
            >
                <Grid item xs={12} sm={4} md={2}>
                    <Autocomplete
                        id="rider"
                        onChange={(event, newValue) => {
                            if (newValue) {
                                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                setRider(obj.id)
                            }
                        }}
                        options={riderList?.results || []}
                        freeSolo
                        blurOnSelect
                        getOptionLabel={(option: any) => option?.user && option?.user?.name}
                        onInputChange={(event, newInputValue) => {
                            clearTimeout(timer.current)
                            timer.current = setTimeout(() => {
                                getRiderList(`?usergroup=Rider&code=${newInputValue}`)
                            }, 1000)
                            if (newInputValue.length === 0) {
                                setRider("")
                            }
                        }}
                        renderInput={(params) => (
                            <TextField className="input"
                                {...params}
                                placeholder="Select Rider"
                                label="Rider"
                                variant="outlined"
                                style={{ width: "100%", margin: "0", padding: "0" }}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={2}>
                    <Autocomplete
                        id="city"
                        onChange={(event, newValue) => {
                            if (newValue) {
                                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                setCityId(obj?.id)
                            }
                        }}
                        options={cities || ""}
                        freeSolo
                        blurOnSelect
                        aria-required
                        getOptionLabel={(option: any) => option.name}
                        onInputChange={(event, newInputValue) => {
                            clearTimeout(timer.current)
                            timer.current = setTimeout(() => {
                                getCities(`${newInputValue}`)
                            }, 1000)
                            if (newInputValue.length === 0) {
                                setCityId("")
                            }
                        }}
                        renderInput={(params) => (
                            <TextField className="input"
                                {...params}
                                label="Select City"
                                variant="outlined"
                                style={{ width: "100%", margin: "0", padding: "0" }}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6} sm={4} md={2}>
                    <Button
                        key={refreshIt}
                        variant="contained"
                        color="secondary"
                        onClick={filterBookings}
                        fullWidth
                    >
                        Filter
                    </Button>
                </Grid>
                <Grid item xs={6} sm={4} md={2}>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={handleReset}                   
                    >
                        Reset
                    </Button>
                </Grid>
            </Grid>
            <div className={classes.tableContainer}>
                <div>
                    <div id="tempMap" className={classes.mapCSS}></div>
                </div>
            </div>
            {/* <Dialog onClose={handleClose} open={open}>
                <DialogTitle>Pickup Status Details</DialogTitle>
                <List sx={{ pt: 0 }}>
                    <ListItem button>
                        <ListItemAvatar>
                            <img src="https://staticredcliffelabs.s3.amazonaws.com/media/gallary-file/None/c0353a79-fe6f-4a4a-9b68-682f1dc003ef.png" style={{ width: "60px", height: "60px", borderRadius: "50%" }} />
                        </ListItemAvatar>
                        <ListItemText primary="Confirm" />
                    </ListItem>
                    <ListItem button>
                        <ListItemAvatar>
                            <img src="https://staticredcliffelabs.s3.amazonaws.com/media/gallary-file/None/24e0a1f6-b5d5-4053-bf9b-c4eb4ea488b2.png" style={{ width: "60px", height: "60px", borderRadius: "50%" }} />
                        </ListItemAvatar>
                        <ListItemText primary="Pending" />
                    </ListItem>
                    <ListItem button>
                        <ListItemAvatar>
                            <img src="https://staticredcliffelabs.s3.amazonaws.com/media/gallary-file/None/fdde892b-287b-474a-896f-ee35d33fcc22.png" style={{ width: "60px", height: "60px", borderRadius: "50%" }} />
                        </ListItemAvatar>
                        <ListItemText primary="Cancelled" />
                    </ListItem>
                    <ListItem button>
                        <ListItemAvatar>
                            <img src="https://staticredcliffelabs.s3.amazonaws.com/media/gallary-file/None/75a33219-859c-40c4-ad8a-9629a2a8a171.png" style={{ width: "60px", height: "60px", borderRadius: "50%" }} />
                        </ListItemAvatar>
                        <ListItemText primary="Hold" />
                    </ListItem>
                </List>
            </Dialog> */}
        </main>
    );
};

const mapStateToProps = (state: any) => ({
    loading: state.LogisticsReducer.loading,
    mapmyindiaAccessToken: state.LogisticsReducer.mapmyindiaAccessToken,
    mapBooking: state.LogisticsReducer.mapBooking,
    riderList: state.LogisticsReducer.riderList,
    routesData: state.LogisticsReducer.routesData,
    cities: state.LogisticsReducer.cities,
    riderOnMap: state.LogisticsReducer.riderOnMap,
    riderMapMyIndia: state.LogisticsReducer.riderMapMyIndia,
});

export default connect(mapStateToProps, {
    getMapMyIndiaAccessToken,
    getRiderList,
    getRoutes,
    getCities,
    getRiderLiveMap,
    getRiderMapMyIndia,
})(ViewBookingOnMap);
