import React, { useState, useEffect, useRef } from "react";
import {
  makeStyles,
  Theme,
  createStyles,
  withStyles,
} from "@material-ui/core/styles";
import {
  Grid,
  MenuItem,
  Paper,
  Switch,
  TextField,
  FormControlLabel,
  Select,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {
  updatePackageManagement,
  getPackageManagement,
  getTagsList,
  getPackageCategory,
  getTestsCrm
} from "../actions/productMangerAction";
import { connect } from "react-redux";
import Checkbox from "@mui/material/Checkbox";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
    },
    contentHeader: {
      width: "100%",
      padding: "0px 10px 10px 10px",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      padding: "20px 20px 20px 20px",
      marginTop: "20px",
      width: "100%",
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    head: {
      textAlign: "center",
      fontWeight: "normal",
      marginBottom: "2rem",
    },
    slotContainer: {
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "center",
    },
    slot: {
      width: "150px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      margin: "0.5rem",
    },
    selectedSlot: {
      width: "150px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      background: "linear-gradient(60deg, cyan, #00dfff, cyan)",
      cursor: "pointer",
      margin: "0.5rem",
    },
    chips: {
      display: "flex",
    },
    chip: {
      margin: 2,
      background: "linear-gradient(60deg, cyan, #00cfff, cyan)",
      maxWidth: "130px",
    },
  })
);

const DefaultSwitch: any = withStyles({
  switchBase: {
    color: "red",
    "&$checked": {
      color: "green",
    },
    "&$checked + $track": {
      backgroundColor: "green",
    },
  },
  checked: {},
  track: {
    backgroundColor: "red",
  },
})(Switch);
interface Props {
  match: any;
  booking: any;
  updatePackageManagement: any;
  getPackageManagement: any;
  packageManagement: any;
  getTagsList: any;
  tagslist: any;
  getPackageCategory: any;
  packagecategory: any;
  getTestsCrm: any;
  testsCrm: any;
}

const PMEditPackageManagement: React.FC<Props> = ({
  match,
  booking,
  updatePackageManagement,
  getPackageManagement,
  packageManagement,
  getTagsList,
  tagslist,
  getPackageCategory,
  packagecategory,
  getTestsCrm,
  testsCrm
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [name, setName] = useState<string>("");
  const [code, setCode] = useState<any>("");
  const [packagePrice, setPackagePrice] = useState<string>("");
  const [offerPrice, setOfferPrice] = useState<string>("");
  const [addonPrice, setAddonPrice] = useState<string>("");
  const [parameter, setParameter] = useState<string>("");
  const [fasting, setFasting] = useState<string>("");
  const [age, setAge] = useState<string>("");
  const [categories, setCategories] = useState<any>("");
  const [tat, setTat] = useState<string>("");
  const [platform, setPlatform] = useState<string>("");
  const [timeDuration, setTimeDuration] = useState<string>("");
  const [category, setCategory] = useState<string>("");
  const [tags, setTags] = useState<string>("");
  const [orgType, setOrgType] = useState<string>("");
  const [passSlug, setPassSlug] = useState<any>("");
  const [gender, setGender] = useState<string>("");
  const [plateformType, setPlateform] = useState<string>("");
  const [isaddon, setIsAddon] = useState<boolean>(true);
  const [isactive, setIsActive] = useState<boolean>(false);
  const [websitedisplay, setWebsiteDisplay] = useState<boolean>(false);
  const [numberofpatients, setNumberOfPatients] = useState<any>("");
  const [tests, setTests] = useState<any>("");
  const [testType, setTestType] = useState<any>("");
  const [testsDef, setTestsDef] = useState<any>([]);
  const bookingId = match.params.id;
  const packageId = match.params.id;
  const timer = useRef<any>(0);

  useEffect(() => {
    if (packageManagement?.results) {
      packageManagement?.results?.length > 0 &&
        packageManagement?.results?.map((list: any) => {
          setName(list?.name);
          setCode(list?.code);
          setPackagePrice(list?.package_price);
          setOfferPrice(list?.offer_price);
          setAddonPrice(list?.addon_price);
          setParameter(list?.parameter);
          setFasting(list?.fasting_time);
          setNumberOfPatients(list?.no_patients);
          setTat(list?.tat_time);
          setTimeDuration(list?.tat_time);
          setOrgType(list?.org_type);
          setPassSlug(list?.slug);
          setGender(list?.recommended_gender);
          setTags(list?.tags);
          setAge(list?.recommended_age);
          setPlateform(list?.ptype);
          setIsAddon(list?.is_addon);
          setIsActive(list?.is_active);
          setWebsiteDisplay(list?.website_display);
          getPackageCategory(list?.packagecategory);
          setTests(list?.package_or_test);
        });
    }
  }, [packageManagement]);

  const submitForm = async (e: any) => {
    e.preventDefault();
    const data: any = {
      name: name,
      code: code,
      package_price: packagePrice,
      offer_price: offerPrice,
      addon_price: addonPrice,
      parameter: parameter,
      fasting_time: fasting,
      no_patients: numberofpatients,
      tat_time: tat,
      website_display: websitedisplay,
      ptype: plateformType,
      recommended_gender: gender,
      org_type: orgType,
      is_addon: isaddon,
      is_active: isactive,
      tat_time_duration: timeDuration,
      tags: tags,
      age: age,
      package_or_test: tests,
    };

    await updatePackageManagement(data, passSlug);
    getPackageManagement();
    history.push(`/dashboard/pm/packagemanagement`);
  };


  useEffect(() => {
    getPackageManagement(`?package_id=${bookingId}`);
    getTagsList();
    getTestsCrm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingId]);

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <div className={classes.contentHeader}>
        <h2>Edit Package Management</h2>
      </div>
      <Paper className={classes.paper} elevation={15}>
        <form onSubmit={submitForm}>
          <Grid
            container
            spacing={2}
            direction="row"
            justify="center"
            alignItems="center"
            style={{ margin: "10px auto" }}
          >
            <Grid container spacing={2} style={{ margin: "1rem auto" }}>
              <Grid item xs={12} sm={5} md={3}>
                <Select
                  className="input"
                  fullWidth
                  label="select type"
                  required
                  variant="outlined"
                  value={testType}
                  style={{ width: "100%",height: "40px"}}
                  onChange={(e) => setTestType(e.target.value)}
                >
                  <MenuItem value="package">Package</MenuItem>
                  <MenuItem value="test">Test</MenuItem>
                </Select>
              </Grid>
              {testType === "package" ? (
                <Grid item xs={12} sm={5} md={3}>
                  <Autocomplete
                    id="tests"
                    key={packageId}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let packs: any = [];
                        newValue.map((val: any) => {
                          let obj = JSON.parse(JSON.stringify(val, null, " "));
                          packs.push(obj.id);
                        });
                        setTests(packs);
                      }
                    }}
                    freeSolo
                    multiple
                    limitTags={1}
                    options={testsCrm?.results || []}
                    onInputChange={(event, newInputValue) => {
                      clearTimeout(timer.current);
                      timer.current = setTimeout(() => {
                        getTestsCrm(`code=${newInputValue}`);
                      }, 1000);
                    }}
                    getOptionDisabled={(option: any) => {
                      return tests?.includes(option.id);
                    }}
                    defaultValue={testsDef}
                    getOptionLabel={(option: any) => `${option?.name}`}
                    renderInput={(params) => (
                      <TextField
                        className="input"
                        {...params}
                        variant="outlined"
                        style={{ width: "100%", margin: "0", padding: "0" }}
                        label="Tests"
                        fullWidth
                        required={tests?.length === 0 ? true : false}
                      />
                    )}
                  />
                </Grid>
              ) : (
                ""
              )}
              <Grid item xs={12} sm={5} md={3}>
                <TextField
                  className="input"
                  label="Package Name"
                  name="name"
                  type="text"
                  value={name}
                  variant="outlined"
                  onChange={(e) => setName(e.target.value as string)}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} sm={5} md={3}>
                <TextField
                  className="input"
                  name="name"
                  type="text"
                  label="Package Code"
                  value={code}
                  variant="outlined"
                  onChange={(e) => setCode(e.target.value as string)}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} sm={5} md={3}>
                <TextField
                  className="input"
                  name="name"
                  label="Package Price"
                  type="number"
                  value={packagePrice}
                  variant="outlined"
                  onChange={(e) => setPackagePrice(e.target.value as string)}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} sm={5} md={3}>
                <TextField
                  className="input"
                  name="name"
                  type="number"
                  label="Offer Price"
                  value={offerPrice}
                  variant="outlined"
                  onChange={(e) => setOfferPrice(e.target.value as string)}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} sm={5} md={3}>
                <TextField
                  className="input"
                  name="name"
                  type="number"
                  label="Addon Price"
                  value={addonPrice}
                  variant="outlined"
                  onChange={(e) => setAddonPrice(e.target.value as string)}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} sm={5} md={3}>
                <TextField
                  className="input"
                  name="name"
                  type="text"
                  label="Parameters"
                  value={parameter}
                  variant="outlined"
                  onChange={(e) => setParameter(e.target.value as string)}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} sm={5} md={3}>
                <TextField
                  className="input"
                  name="name"
                  label="Fasting Requirement"
                  type="text"
                  value={fasting}
                  variant="outlined"
                  onChange={(e) => setFasting(e.target.value as string)}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} sm={5} md={3}>
                <TextField
                  className="input"
                  name="name"
                  label="No. of Patients Required"
                  type="number"
                  value={numberofpatients}
                  variant="outlined"
                  onChange={(e) => setNumberOfPatients(e.target.value as string)}
                  style={{ width: "100%" }}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={5} md={3}>
                <TextField
                  className="input"
                  name="name"
                  type="text"
                  label="Age Requirement"
                  value={age}
                  variant="outlined"
                  onChange={(e) => setAge(e.target.value as string)}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} sm={5} md={3}>
                <Select
                  className="input"
                  name="name"
                  label="Org Type"
                  type="text"
                  value={orgType}
                  variant="outlined"
                  onChange={(e) => setOrgType(e.target.value as string)}
                  style={{ width: "100%", height: "40px" }}
                >
                  <MenuItem value={"none"} disabled>
                    Org Type
                  </MenuItem>
                  <MenuItem value={"homedx"}>Homedx</MenuItem>
                  <MenuItem value={"imaging"}>Imaging</MenuItem>
                  <MenuItem value={"genomics"}>Genomics</MenuItem>
                  <MenuItem value={"collection_center"}>
                    Collection Center
                  </MenuItem>
                  <MenuItem value={"REDO"}>REDO</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} sm={5} md={3}>
                <TextField
                  className="input"
                  name="name"
                  label="TAT Time"
                  type="text"
                  value={tat}
                  variant="outlined"
                  onChange={(e) => setTat(e.target.value as string)}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} sm={5} md={3}>
                <Autocomplete
                  id="categories"
                  onChange={(event, newValue) => {
                    let sourceIds: any = [];
                    if (newValue) {
                      let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                      sourceIds = obj.map((item: any) => item.id);
                    }
                    setCategories(sourceIds);
                  }}
                  options={packagecategory?.results || []}
                  freeSolo
                  blurOnSelect
                  aria-required
                  limitTags={1}
                  multiple
                  getOptionLabel={(option: any) => option?.name}
                  getOptionDisabled={(option: any) => {
                    return categories.includes(option);
                  }}
                  disableClearable
                  disableCloseOnSelect
                  onInputChange={(event, newInputValue) => {
                    clearTimeout(timer.current);
                    timer.current = setTimeout(() => {
                      getPackageCategory(newInputValue);
                    }, 1000);
                    if (newInputValue.length === 0) {
                      setCategories([]);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      className="input"
                      {...params}
                      // required
                      variant="outlined"
                      label="Categories"
                      style={{ width: "100%", margin: "0", padding: "0" }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={5} md={3}>
                <Select
                  className="input"
                  name="status"
                  variant="outlined"
                  label="Gender"
                  value={gender}
                  style={{ width: "100%", height: "40px" }}
                  onChange={(e) => setGender(e.target.value as string)}
                >
                  <MenuItem value={"male"}>Male</MenuItem>
                  <MenuItem value={"female"}>Female</MenuItem>
                  <MenuItem value={"both"}>Both</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} sm={5} md={3}>
                <Autocomplete
                  id="tags"
                  onChange={(event, newValue) => {
                    let sourceIds: any = [];
                    if (newValue) {
                      let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                      sourceIds = obj.map((item: any) => item.id);
                    }
                    setTags(sourceIds);
                  }}
                  options={tagslist?.results || []}
                  freeSolo
                  blurOnSelect
                  aria-required
                  limitTags={1}
                  getOptionLabel={(option: any) => option?.tags}
                  getOptionDisabled={(option: any) => {
                    return tags?.includes(option?.id);
                  }}
                  disableClearable
                  disableCloseOnSelect
                  onInputChange={(event, newInputValue) => {
                    clearTimeout(timer?.current);
                    timer.current = setTimeout(() => {
                      getTagsList(`${newInputValue}`);
                    }, 1000);
                    if (newInputValue?.length === 0) {
                      setTags("");
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      className="input"
                      {...params}
                      label="Tags"
                      variant="outlined"
                      style={{ width: "100%", margin: "0", padding: "0" }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={5} md={3}>
                <Select
                  className="input"
                  name="status"
                  variant="outlined"
                  label="Plateform Type"
                  value={plateformType}
                  style={{ width: "100%", height: "40px" }}
                  onChange={(e) => setPlateform(e.target.value as string)}
                >
                  <MenuItem value={"sales"}>Sales</MenuItem>
                  <MenuItem value={"coorporate"}>Coorporate</MenuItem>
                  <MenuItem value={"camp"}>Camp</MenuItem>
                  <MenuItem value={"all"}>All</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} sm={5} md={3}>
                <FormControlLabel
                  control={<Checkbox />}
                  checked={isaddon}
                  label="Isaddon"
                  onChange={(e) => setIsAddon(!isaddon)}
                />
              </Grid>
              <Grid item xs={12} sm={5} md={3}>
                <FormControlLabel
                  control={<Checkbox />}
                  checked={websitedisplay}
                  label="Website Display"
                  onChange={(e) => setWebsiteDisplay(!websitedisplay)}
                />
              </Grid>
              <Grid item xs={12} sm={5} md={3}>
                <FormControlLabel
                  control={<Checkbox />}
                  checked={isactive}
                  label="Is Active"
                  onChange={(e) => setIsActive(!isactive)}
                />
              </Grid>
            </Grid>
          </Grid>
          <div className={classes.buttonContainer}>
            <Button color="primary" type="submit" variant="contained">
              Update Package
            </Button>
          </div>
        </form>
      </Paper>
    </main>
  );
};
const mapStateToProps = (state: any) => ({
  packageManagement: state.ProductManagerReducer.packageManagement,
  updatePackageManagement: state.ProductManagerReducer.updatePackageManagement,
  booking: state.ProductManagerReducer.booking,
  tagslist: state.ProductManagerReducer.tagslist,
  packagecategory: state.ProductManagerReducer.packagecategory,
  testsCrm: state.ProductManagerReducer.testsCrm,
});

export default connect(mapStateToProps, {
  getPackageManagement,
  updatePackageManagement,
  getTagsList,
  getPackageCategory,
  getTestsCrm,
})(PMEditPackageManagement);
