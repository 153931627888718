import React, { useState, useEffect, useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import "react-sliding-pane/dist/react-sliding-pane.css";
import "../react-sliding-pane.css";
import {
  Card,
  Paper,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import Geocode from "react-geocode";
import { useDispatch } from "react-redux";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  getPackage,
  createNewBooking,
  getCoupons,
  getLeads,
  getLeadDetails,
  resetLeadDetails,
  getAvailableSlots,
  getCities,
  getCenterInfo,
  getImagingTimeslots,
  createImagingBooking,
  getRedCoing,
  getSubCenterInfo,
  getPinCode,
  getMultiplePackage,
  getDisplayCities,
  getMapMyIndiaAccessToken,
  checkGeoFenceArea,
  checkGeoFenchMapMyIndia,
} from "../../../actions/PhleboAdmin";
import Button from "@mui/material/Button";
import "./index.sass";
import { connect } from "react-redux";
import moment from "moment";
import { useHistory } from "react-router-dom";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { GENDER_CHOICE } from "../../constant";
import { ACTIONS } from "../../../interfaces/actionTypes/PhleboAdmin";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
Geocode.setApiKey("AIzaSyBPjDlsHYhWtjt3FxgRPnTMXN-qJZ9hz0s");
Geocode.enableDebug();

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },

    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    slotContainer: {
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "center",
      minHeight: "300px",
    },
    slot: {
      width: "150px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      margin: "0.5rem",
    },
    selectedSlot: {
      width: "150px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      background: "linear-gradient(60deg, cyan, #00cfff, cyan)",
      cursor: "pointer",
      margin: "0.5rem",
    },
    chips: {
      display: "flex",
    },
    chip: {
      margin: 2,
      background: "linear-gradient(60deg, cyan, #00cfff, cyan)",
      maxWidth: "130px",
    },
    table: {
      maxWidth: "100%",
      margin: "auto",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "100%",
      flexGrow: 21,
    },
    contentHeader: {
      width: "100%",
      padding: "0px 10px 40px 10px",
    },
    paper: {
      marginTop: "2rem",
      padding: "0.5rem 2rem 2rem 2rem",
      width: "100%",
    },
    leftContentHeader: {
      width: "50%",
      float: "left",
    },
    rightContentHeader: {
      width: "50%",
      float: "right",
      textAlign: "right",
      paddingRight: "20px",
    },
    contentTable: {
      width: "100%",
      padding: "20px 10px 10px 10px",
      background: "#ffffff",
      margin: "10px",
      boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
    },
    packageLink: {
      color: "#ffffff",
      textDecoration: "none",
    },
    tableContainer: {
      marginTop: "1rem",
      position: "sticky",
      top: "200px",
    },
    option: {
      "&:hover": {
        backgroundColor: "cyan !important",
      },
    },
  })
);
interface Props {
  getPackage: any;
  packageList: any;
  couponList: any;
  getCoupons: any;
  createNewBooking: any;
  getLeads: any;
  leads: any;
  getLeadDetails: any;
  lead_details: any;
  booking_slots: any;
  resetLeadDetails: any;
  createBooking: any;
  getAvailableSlots: any;
  loading: boolean;
  sameNumberBooking: boolean;
  getCities: any;
  cities: any;
  getCenterInfo: any;
  centerInfo: any;
  getImagingTimeslots: any;
  imagingTimeSlots: any;
  createImagingBooking: any;
  createIMGBooking: any;
  getRedCoing: any;
  redCoin: any;
  getSubCenterInfo: any;
  subCenter: any;
  getPinCode: any;
  pin_code: any;
  getMultiplePackage: any;
  multiplePackage: any;
  getDisplayCities: any;
  displayCities: any;
  checkGeoFenceArea: any;
  checkGeoFenceStatus: boolean;
  checkGeoFenchMapMyIndia: any;
  geoFencesMapMyIndia: any;
  getMapMyIndiaAccessToken: any;
  mapmyindiaAccessToken: any;
}

const NewBooking: React.FC<Props> = ({
  packageList,
  getPackage,
  couponList,
  getCoupons,
  getLeads,
  getLeadDetails,
  lead_details,
  resetLeadDetails,
  getImagingTimeslots,
  imagingTimeSlots,
  loading,
  createImagingBooking,
  createIMGBooking,
  getSubCenterInfo,
  subCenter,
  getPinCode,
  getMultiplePackage,
  multiplePackage,
  getDisplayCities,
  displayCities,
  checkGeoFenceArea,
  checkGeoFenceStatus,
  checkGeoFenchMapMyIndia,
  geoFencesMapMyIndia,
  getMapMyIndiaAccessToken,
  mapmyindiaAccessToken,
  pin_code,
}) => {
  const classes = useStyles();
  const history = useHistory();
  let dispatch = useDispatch();
  const [collection_date, setCollection_date] = useState<String>("");
  const [selectedSlot, setSelectedSlot] = useState<Number>(0);
  const [customer_name, setCustomer_name] = useState<String>("");
  const [customer_age, setCustomer_age] = useState<String>("");
  const [customer_gender, setCustomer_gender] = useState<string>("");
  const [customer_designation, setCustomerDesignation] = useState<String>("");
  const [singlePackage, setSinglePackage] = useState<any>({});
  const [isPaneOpen, setPaneOpen] = useState<boolean>(false);
  const [customer_phonenumber, setCustomer_phonenumber] = useState<String>("");
  const [customer_alternatenumber, setCustomer_alternatenumber] =
    useState<String>("");
  const [customer_whatsapppnumber, setCustomer_whatsapppnumber] =
    useState<String>("");
  const [customer_address, setCustomer_address] = useState<String>("");
  const [customer_email, setCustomer_email] = useState<String>("");
  const [customer_landmark, setCustomer_landmark] = useState<String>("");
  const [customer_aadhar, setCustomer_aadhar] = useState<String>("");
  const [passport, setPassport] = useState<String>("");
  const [adharPassport, setAdharPassport] = useState("customer_aadhar");
  const [packageDetails, setPackageDetails] = useState<any>([]);
  const [packages, setPackages] = useState<Number[]>([]);
  const [price, setPrice] = useState<any>(0);
  const [discount, setDiscount] = useState<any>(0);
  const [totalPrice, setTotalPrice] = useState<any>(0);
  const [coupon, setCoupon] = useState<Number>(0);
  const [couponCode, setCouponCode] = useState<string>("");
  const [extraCharge, setExtraCharge] = useState<boolean>(false);
  const [cityId, setCityId] = useState<any>("");
  const [packageKey, setPackageKey] = useState<any>(0);
  const [redDisc, setRedDisc] = useState<any>(0);
  const [mobileValidation, setMobileValidation] = useState({
    customer_alternatenumber: true,
    customer_phonenumber: true,
    customer_whatsapppnumber: true,
  });
  const timer = useRef<any>(0);
  const [defaultEmail, setDefaultEmail] = useState<any>(
    "customer.reports@redcliffelabs.com"
  );
  const [customer_longitude, setCustomerLongitude] = useState<any>("");
  const [customer_latitude, setCustomerLatitude] = useState<any>("");
  const [center_longitude, setCenterLongitude] = useState<any>("");
  const [center_latitude, setCenterLatitude] = useState<any>("");
  const [bookingFor, setBookingFor] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [centerId, setCenterId] = useState<any>("");
  const [subCenterId, setSubCenterId] = useState<any>("");
  const [customer_areapincode, setCustomer_areapincode] = useState<Number>(0);
  const [centerKey, setCenterKey] = useState<any>(0);
  const [customer_city, setCustomer_city] = useState<String>("");
  const [couponMessage, setCouponMessage] = useState<string>("");
  const [tncMessage, setTncMessage] = useState<string>("");
  const [redCashCheck, setRedCashCheck] = useState<boolean>(false);
  const [subCenterData, setSubCenterData] = useState<any>([]);
  const [centerMessage, setCenterMessage] = useState<string>("");
  const [cityName, setCityName] = useState<string>("");
  const [suggestedAddress, setSuggestedAddres] = useState<any>([]);
  const [mapAddressObject, setMapAddressObject] = useState<any>({});
  const [accessToken, setAccessToken] = useState<any>("");

  const [numCheck, setNumCheck] = useState({
    altCheck: false,
    whatsCheck: false,
  });

  useEffect(() => {
    setCustomer_address(lead_details.address as String);
    setCustomer_age(lead_details.patient_age as String);
    setCustomer_name(lead_details.patient_name as String);
    setCustomer_phonenumber(lead_details.content_number as String);
    setCustomer_whatsapppnumber(lead_details.whatsapp_contact as String);
    setCustomer_gender(lead_details.calling_gender as string);
  }, [lead_details]);

  useEffect(() => {
    resetLeadDetails();
    getCoupons(`?org_type=imaging`);
    getDisplayCities();
    getMapMyIndiaAccessToken();
  }, []);

  const handlePackages = () => {
    const min_phlebo_cost =
      packageList &&
      packageList?.results &&
      packageList?.results.length > 0 &&
      packageList?.results[0].min_booking_amount_for_phlebo;
    let accumulatedPrice: any = 0;
    let packIds: any = [];
    packageDetails &&
      packageDetails.length > 0 &&
      packageDetails.map((item: any) => {
        packIds.push(item.id);
        setPackages(packIds);
        setCenterKey(item.id);
        setSubCenterData([]);
      });
    multiplePackage &&
      multiplePackage.results &&
      multiplePackage.results.length > 0 &&
      multiplePackage.results.map((item: any) => {
        if (item.package_center_address_prices === null) {
          accumulatedPrice = accumulatedPrice + item.offer_price;
        } else {
          accumulatedPrice =
            accumulatedPrice + item.package_center_address_prices.offer_price;
        }

        if (accumulatedPrice < min_phlebo_cost) {
          setExtraCharge(false);
        } else {
          setExtraCharge(false);
        }
      });

    setPrice(Math.floor(accumulatedPrice));
    setDiscount(0);
    setTotalPrice(Math.floor(accumulatedPrice));
    setCoupon(0);
    setCouponCode("");
    getPackage(`org_type=imaging`);
  };
  useEffect(() => {
    handlePackages();
  }, [packageDetails, multiplePackage]);

  useEffect(() => {
    if (extraCharge) {
      setTotalPrice(Math.floor(price - discount + 200));
    } else {
      setTotalPrice(Math.floor(price - discount));
    }
  }, [price, discount, extraCharge]);

  useEffect(() => {
    if (totalPrice < 0) {
      setTotalPrice(0);
    }
  }, [totalPrice]);

  const applyCoupon = () => {
    if (couponCode === "") {
      setCouponCode("");
      setCoupon(0);
      setDiscount(0);
      return;
    }
    const appliedCoupon =
      couponList.results.find((coup: any) => coup.id === coupon) ||
      couponList.results.find((coup: any) => coup.code === couponCode);
    if (appliedCoupon && appliedCoupon.minimum_price < totalPrice) {
      setCoupon(appliedCoupon.id);
      if (appliedCoupon.discount_type.toLowerCase() === "percentage") {
        setDiscount(Math.floor((price * appliedCoupon.discount) / 100));
      }
      if (appliedCoupon.discount_type.toLowerCase() === "amount") {
        setDiscount(Math.floor(appliedCoupon.discount));
      }
    } else {
      setCouponCode("");
      setCoupon(0);
      setDiscount(0);
    }
  };
  const verifyMobile = (type: any) => {
    if (type === "mobile") {
      if (
        parseInt(customer_phonenumber && customer_phonenumber[0]) < 6 ||
        (customer_phonenumber && customer_phonenumber.length < 10)
      ) {
        setMobileValidation((prev: any) => ({
          ...prev,
          customer_phonenumber: false,
        }));
      }
    }
    if (type === "alternate") {
      if (
        parseInt(customer_alternatenumber && customer_alternatenumber[0]) < 6 ||
        (customer_alternatenumber && customer_alternatenumber.length < 10)
      ) {
        setMobileValidation((prev: any) => ({
          ...prev,
          customer_alternatenumber: false,
        }));
      }
    }
    if (type === "whatsapp") {
      if (
        parseInt(customer_whatsapppnumber && customer_whatsapppnumber[0]) < 6 ||
        (customer_whatsapppnumber && customer_whatsapppnumber.length < 10)
      ) {
        setMobileValidation((prev: any) => ({
          ...prev,
          customer_whatsapppnumber: false,
        }));
      }
    }
  };
  useEffect(() => {
    if (subCenter) {
      setSubCenterData(subCenter);
    }
  }, [subCenter]);
  const submitForm = async (e: any) => {
    e.preventDefault();
    const data: any = {
      customer_name: customer_name,
      customer_age: customer_age,
      customer_gender: customer_gender,
      packages: packages,
      booking_date: moment(new Date()).format("YYYY-MM-DD"),
      collection_date: collection_date,
      collection_slot: selectedSlot,
      customer_email: customer_email === "" ? defaultEmail : customer_email,
      customer_phonenumber: customer_phonenumber,
      customer_altphonenumber: numCheck.altCheck
        ? customer_phonenumber
        : customer_alternatenumber,
      customer_whatsapppnumber: numCheck.whatsCheck
        ? customer_phonenumber
        : customer_whatsapppnumber,
      customer_address: customer_landmark,
      customer_landmark: customer_landmark,
      customer_areapincode: customer_areapincode,
      customer_longitude: customer_longitude,
      customer_latitude: customer_latitude,
      customer_city: customer_city,
      customer_aadhar: customer_aadhar,
      passport_number: passport,
      coupon_applied: coupon,
      designation: customer_designation,
      display_name: cityName,
      // dob: inputList ? inputList[0]?.dateOfBirth : "",
      book_for: bookingFor || "",
      cityid: cityId,
      center: centerId,
      is_redcash_applied: redCashCheck ? "true" : "false",
      center_address: subCenterId,
    };
    if (data["coupon_applied"] === 0) {
      delete data["coupon_applied"];
    }
    if (data["dob"] === "") {
      delete data["dob"];
    }
    await createImagingBooking(data);
  };
  useEffect(() => {
    if (subCenterId !== "") {
      getImagingTimeslots(
        `?packages=${packages}&collection_date=${collection_date}&center_address=${subCenterId}`
      );
    } else {
      dispatch({ type: ACTIONS.GET_MULTIPLE_PACKAGES, payload: "" });
    }
  }, [subCenterId]);

  useEffect(() => {
    if (createIMGBooking?.pk) {
      history.push(`/dashboard/pha/booking-view/${createIMGBooking.pk}`);
      dispatch({ type: ACTIONS.CREATE_IMAGING_BOOKING, payload: "" });
      dispatch({ type: ACTIONS.GET_MULTIPLE_PACKAGES, payload: "" });
    }
  }, [createIMGBooking]);
  const changeCollectionDate = (e: any) => {
    setSelectedSlot(0);
    setCollection_date(e.target.value as string);
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAdharPassport((event.target as HTMLInputElement).value);
  };
  const handleSubCenter = (val: any) => {
    setCenterId(val.center.id);
    setSubCenterId(val.id);
    setCenterLatitude(val.latitude);
    setCenterLongitude(val.logitude);
    setCustomer_address(val.address);
    setCustomer_areapincode(parseInt(val.location.id));
    setCustomer_city(val.location.city);
  };
  useEffect(() => {
    if (packages.length > 0) {
      getSubCenterInfo(
        `?imaging=true&customer_latitude=${customer_latitude}&customer_longitude=${customer_longitude}&package=${packages}&display_name=${cityName}`
      );
    }
  }, [packages]);

  const handleGender = (value: any) => {
    setCustomerDesignation(value);
    const findGender = GENDER_CHOICE.filter((el: any) => el.db_name == value);
    setCustomer_gender(findGender[0]?.gender);
  };

  useEffect(() => {
    if (subCenterId !== "") {
      getMultiplePackage(
        `?multiple_ids=${packages}&center_address=${subCenterId}&imaging=true`
      );
    }
  }, [subCenterId, packageDetails]);

  useEffect(() => {
    if (packages.length > 0) {
      if (subCenterData.length === undefined) {
        setSubCenterId("");
        setCenterMessage("No Center Available for this package");
      } else {
        setCenterMessage("");
      }
    }
  }, [subCenterData]);

  const handleNumSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNumCheck({ ...numCheck, [event.target.name]: event.target.checked });
  };

  useEffect(() => {
    if (geoFencesMapMyIndia?.latitude && geoFencesMapMyIndia?.longitude) {
      setCustomerLatitude(geoFencesMapMyIndia?.latitude);
      setCustomerLongitude(geoFencesMapMyIndia?.longitude);
    }
  }, [geoFencesMapMyIndia]);

  useEffect(() => {
    if (mapmyindiaAccessToken?.accesstoken) {
      setAccessToken(mapmyindiaAccessToken?.accesstoken);
    }
  }, [mapmyindiaAccessToken]);

  useEffect(() => {
    if (mapAddressObject?.eLoc) {
      getPinCode(mapAddressObject?.addressTokens?.pincode);
      setCustomer_landmark(mapAddressObject?.placeAddress);
      checkGeoFenchMapMyIndia(mapAddressObject?.eLoc, "NA");
    }
  }, [mapAddressObject]);

  useEffect(() => {
    if (pin_code?.results && pin_code?.results.length > 0) {
      setCityId(pin_code?.results[0].cityid);
    }
  }, [pin_code]);

  const locationAutoCompleteSearch = (val: string) => {
    if (val.length > 2) {
      const urlForMap = `https://atlas.mapmyindia.com/api/places/search/json?query=${val}&access_token=${accessToken}&tokenizeAddress=true`;
      return new Promise(function (resolve, reject) {
        const id = "_" + Math.round(10000 * Math.random());
        const callbackName = "jsonp_callback_" + "data" + id;
        window[callbackName] = (data: any) => {
          delete window[callbackName];
          const ele = document.getElementById(id);
          ele?.parentNode?.removeChild(ele);
          const suggestedData = data?.suggestedLocations;
          if (suggestedData.length > 0) {
            setSuggestedAddres(data?.suggestedLocations);
          }
        };
        const src = urlForMap + "&callback=" + callbackName;
        const script = document.createElement("script");
        script.src = src;
        script.id = id;
        script.addEventListener("error", reject);
        (
          document.getElementsByTagName("head")[0] ||
          document.body ||
          document.documentElement
        ).appendChild(script);
      });
    }
  };

  return (
    <div>
      <Paper className={classes.paper} elevation={15}>
        <form onSubmit={submitForm}>
          <Grid container spacing={3} style={{ margin: "1rem auto" }}>
            <Grid item xs={12} md={6}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <p>Booking date</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <input
                    className="input"
                    name="collection_date"
                    type="date"
                    value={`${new Date().getFullYear()}-${
                      new Date().getMonth() + 1 < 10
                        ? `0${new Date().getMonth() + 1}`
                        : new Date().getMonth() + 1
                    }-${
                      new Date().getDate() < 10
                        ? `0${new Date().getDate()}`
                        : new Date().getDate()
                    }`}
                    style={{
                      width: "100%",
                      margin: "0",
                      padding: "18.5px 14px",
                      height: "40px",
                    }}
                    required
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <p>Sample Collection date</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <input
                    className="input"
                    name="collection_date"
                    type="date"
                    value={
                      (collection_date && collection_date.toString()) || ""
                    }
                    // onChange={(e) => setCollection_date(e.target.value as string)}
                    onChange={(e) => changeCollectionDate(e)}
                    style={{
                      width: "100%",
                      margin: "0",
                      padding: "18.5px 14px",
                      height: "40px",
                    }}
                    required
                    min={`${new Date().getFullYear()}-${
                      new Date().getMonth() + 1 < 10
                        ? `0${new Date().getMonth() + 1}`
                        : new Date().getMonth() + 1
                    }-${
                      new Date().getDate() < 10
                        ? `0${new Date().getDate()}`
                        : new Date().getDate()
                    }`}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <p>City Name</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    id="cityname"
                    onChange={(event, newValue) => {
                      let cName: any = "";
                      if (newValue) {
                        let obj = JSON.parse(
                          JSON.stringify(newValue, null, " ")
                        );
                        cName = obj;
                      }
                      setCityName(cName);
                    }}
                    options={displayCities.cities || []}
                    freeSolo
                    blurOnSelect
                    aria-required
                    disabled={collection_date === ""}
                    limitTags={1}
                    getOptionLabel={(option: any) => option}
                    disableClearable
                    disableCloseOnSelect
                    renderInput={(params) => (
                      <TextField
                        className="input"
                        {...params}
                        label="City Name"
                        variant="outlined"
                        style={{ width: "100%", margin: "0", padding: "0" }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <p>Customer Address</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    id="colony-area-sector"
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let obj = JSON.parse(
                          JSON.stringify(newValue, null, " ")
                        );
                        setMapAddressObject(obj);
                      }
                    }}
                    options={suggestedAddress ? suggestedAddress : []}
                    freeSolo
                    blurOnSelect
                    aria-required
                    getOptionLabel={(option: any) =>
                      `${option?.placeName}, ${option?.placeAddress}`
                    }
                    filterOptions={(option: any) => option}
                    onInputChange={(event, newInputValue) => {
                      clearTimeout(timer.current);
                      timer.current = setTimeout(() => {
                        locationAutoCompleteSearch(newInputValue);
                      }, 500);
                    }}
                    renderInput={(params) => (
                      <TextField
                        className="input"
                        {...params}
                        placeholder="Search Address"
                        variant="outlined"
                        style={{ width: "100%", margin: "0", padding: "0" }}
                        disabled={collection_date === ""}
                        required
                      />
                    )}
                  />
                </Grid>
                {customer_latitude !== "" ? (
                  <div style={{ color: "red", fontSize: "12px" }}>
                    {errorMessage}
                  </div>
                ) : (
                  ""
                )}
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <p>Package Name</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    id="package"
                    key={packageKey}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let packs: any = [];
                        newValue.map((val: any) => {
                          let obj = JSON.parse(JSON.stringify(val, null, " "));
                          packs.push(obj);
                        });
                        setPackageDetails(packs);
                      }
                    }}
                    multiple
                    disableClearable
                    options={packageList.results}
                    limitTags={0}
                    disabled={customer_landmark === ""}
                    freeSolo
                    disableCloseOnSelect
                    aria-required
                    onInputChange={(event, newInputValue) => {
                      clearTimeout(timer.current);
                      timer.current = setTimeout(() => {
                        getPackage(`org_type=imaging&search=${newInputValue}`);
                      }, 1000);
                    }}
                    getOptionLabel={(option: any) =>
                      option.name && option.package_city_prices
                        ? option.name.toString() +
                          ", " +
                          option.package_city_prices.tat_time
                        : option.name + ", " + option.tat_time
                    }
                    renderOption={(option, { selected }) => (
                      <React.Fragment>
                        <Checkbox
                          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                          checkedIcon={<CheckBoxIcon fontSize="small" />}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.package_city_prices
                          ? option.name.toString() +
                            ", " +
                            option.package_city_prices.tat_time
                          : option.name.toString() + ", " + option.tat_time}
                      </React.Fragment>
                    )}
                    renderInput={(params) => (
                      <TextField
                        className="input"
                        {...params}
                        placeholder="Package"
                        variant="outlined"
                        style={{ width: "100%", margin: "0", padding: "0" }}
                      />
                    )}
                  />
                  <p style={{ color: "red" }}>{centerMessage}</p>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <div
                className="sampleCollectionTimeImaging"
                style={{ overflow: "auto" }}
              >
                <p style={{ marginBottom: "1rem" }}>Sample Collection Time</p>
                <div className={classes.slotContainer}>
                  {imagingTimeSlots &&
                    imagingTimeSlots?.results &&
                    imagingTimeSlots?.results?.length > 0 &&
                    imagingTimeSlots.results.map((slot: any) => {
                      return (
                        imagingTimeSlots?.results.length > 0 && (
                          <Card
                            key={slot.id}
                            className={
                              slot.id === selectedSlot
                                ? classes.selectedSlot
                                : classes.slot
                            }
                            onClick={() => setSelectedSlot(slot.id as Number)}
                          >
                            <p
                              style={{
                                textAlign: "center",
                                color: "black",
                                fontSize: "14px",
                              }}
                            >
                              {slot.start_time} onwards
                            </p>
                            <p style={{ textAlign: "center", color: "green" }}>
                              {" "}
                              {/* Availabe slots: {slot.available_slots} */}
                            </p>
                          </Card>
                        )
                      );
                    })}
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} md={3}>
              <p>Center Address</p>
            </Grid>
            <Grid item xs={12} md={9}>
              <Autocomplete
                id="sub_center"
                key={centerKey}
                onChange={(event, newValue) => {
                  if (newValue) {
                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                    handleSubCenter(obj as String);
                  }
                }}
                options={subCenterData.length > 0 ? subCenterData : []}
                style={{ width: "100%" }}
                freeSolo
                blurOnSelect
                aria-required
                getOptionLabel={(option: any) =>
                  `${option?.address},Distance : ${Math.trunc(
                    option?.distance
                  )} KM ,Price:  ₹${option?.cost?.offer_price__sum}`
                }
                renderOption={(option: any) => {
                  return (
                    <span
                      style={{
                        backgroundColor:
                          option.center_status === "p1" ? "#ffecb3" : "",
                      }}
                    >
                      {option?.address},Distance :{" "}
                      {Math.trunc(option?.distance)} KM ,<br />
                      Price: ₹{option?.cost?.offer_price__sum}
                    </span>
                  );
                }}
                onInputChange={(event, newInputValue) => {
                  clearTimeout(timer.current);
                  timer.current = setTimeout(() => {
                    getSubCenterInfo(
                      `?imaging=true&name=${newInputValue}&customer_latitude=${customer_latitude}&customer_longitude=${customer_longitude}&package=${packages}&display_name=${cityName}`
                    );
                  }, 1000);
                }}
                renderInput={(params) => (
                  <TextField
                    className="input"
                    {...params}
                    placeholder="Select Center"
                    variant="outlined"
                    style={{ width: "100%", margin: "0", padding: "0" }}
                    required
                  />
                )}
                disabled={packages.length === 0}
              />
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="flex-end"
            spacing={3}
            style={{ margin: "1rem auto", background: "#eff" }}
          >
            <Grid item xs>
              <p>Title</p>
              <Select
                className="input"
                name="customer_designation"
                variant="outlined"
                value={customer_designation}
                style={{ width: "100%", margin: "0", height: "40px" }}
                onChange={(e) => handleGender(e.target.value as String)}
                required
              >
                <MenuItem value="Mr">Mr</MenuItem>
                  <MenuItem value="Ms">Ms (Mrs/Miss)</MenuItem>
                  <MenuItem value="other">Others</MenuItem>
              </Select>
            </Grid>
            <Grid item xs>
              <p>Customer Name</p>
              <TextField
                className="input"
                name="customer_name"
                type="text"
                placeholder="Enter Name"
                value={customer_name || ""}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ width: "100%", margin: "0" }}
                onChange={(e) => setCustomer_name(e.target.value as String)}
                required
              />
            </Grid>
            <Grid item xs>
              <p>Age</p>
              <TextField
                className="input"
                name="customer_age"
                type="number"
                placeholder="Enter Age"
                value={customer_age || ""}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ width: "100%", margin: "0" }}
                onChange={(e) => setCustomer_age(e.target.value as String)}
                required
              />
            </Grid>
            <Grid item xs>
              <p>Gender</p>
              <Select
                className="input"
                name="customer_gender"
                variant="outlined"
                value={customer_gender}
                disabled
                style={{ width: "100%", margin: "0", height: "40px" }}
                // onChange={(e) => setCustomer_gender(e.target.value as String)}
                required
              >
                <MenuItem value={"male"}>Male</MenuItem>
                <MenuItem value={"female"}>Female</MenuItem>
               
              </Select>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
            direction="row"
            justify="center"
            alignItems="center"
            style={{ margin: "1rem auto" }}
          >
            <Grid item xs={12} md={5}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <p>Mobile Number</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    className="input"
                    name="customer_phonenumber"
                    type="number"
                    onWheel={(e: any) => e.target.blur()}
                    onInput={(e: any) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 10);
                    }}
                    value={customer_phonenumber || ""}
                    placeholder="Enter Mobile Number"
                    variant="outlined"
                    style={{ margin: "0", width: "100%" }}
                    onChange={(e) =>
                      setCustomer_phonenumber(e.target.value as String)
                    }
                    onBlur={() => verifyMobile("mobile")}
                    onFocus={() => {
                      setMobileValidation((prev: any) => ({
                        ...prev,
                        customer_phonenumber: true,
                      }));
                    }}
                    helperText={
                      !mobileValidation.customer_phonenumber &&
                      "Incorrect Phone Number"
                    }
                    required
                  />
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={numCheck.altCheck}
                          onChange={handleNumSelect}
                          name="altCheck"
                        />
                      }
                      label="Same Alternate Number"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={numCheck.whatsCheck}
                          onChange={handleNumSelect}
                          name="whatsCheck"
                        />
                      }
                      label="Same Whatsapp Number"
                    />
                  </FormGroup>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <p>Alternate Mobile Number</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    className="input"
                    name="customer_alternatenumber"
                    type="number"
                    onWheel={(e: any) => e.target.blur()}
                    onInput={(e: any) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 10);
                    }}
                    value={
                      numCheck.altCheck
                        ? customer_phonenumber
                        : customer_alternatenumber || ""
                    }
                    placeholder="Enter Alternate Mobile Number"
                    variant="outlined"
                    style={{ margin: "0", width: "100%" }}
                    onChange={(e) =>
                      setCustomer_alternatenumber(e.target.value as String)
                    }
                    onBlur={() => verifyMobile("alternate")}
                    onFocus={() => {
                      setMobileValidation((prev: any) => ({
                        ...prev,
                        customer_alternatenumber: true,
                      }));
                    }}
                    helperText={
                      !mobileValidation.customer_alternatenumber &&
                      "Incorrect Phone Number"
                    }
                    required
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <p>Email</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    className="input"
                    name="customer_email"
                    type="email"
                    placeholder="customer.reports@redcliffelabs.com"
                    value={customer_email}
                    variant="outlined"
                    style={{ margin: "0", width: "100%" }}
                    onChange={(e) =>
                      setCustomer_email(e.target.value as String)
                    }
                    // required
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <p>Whatsapp Number</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    className="input"
                    name="customer_whatsapppnumber"
                    type="number"
                    onWheel={(e: any) => e.target.blur()}
                    onInput={(e: any) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 10);
                    }}
                    placeholder="Enter Whatsapp Number"
                    value={
                      numCheck.whatsCheck
                        ? customer_phonenumber
                        : customer_whatsapppnumber || ""
                    }
                    variant="outlined"
                    // maxLength={12}
                    style={{ margin: "0", width: "100%" }}
                    onChange={(e) =>
                      setCustomer_whatsapppnumber(e.target.value as String)
                    }
                    required
                    onBlur={() => verifyMobile("whatsapp")}
                    onFocus={() => {
                      setMobileValidation((prev: any) => ({
                        ...prev,
                        customer_whatsapppnumber: true,
                      }));
                    }}
                    helperText={
                      !mobileValidation.customer_whatsapppnumber &&
                      "Incorrect Whatsapp Number"
                    }
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Aadhaar/Passport</FormLabel>
                    <RadioGroup
                      aria-label="Choose"
                      name="adharpassport"
                      value={adharPassport}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="customer_aadhar"
                        control={<Radio />}
                        label="Aadhaar Number"
                      />
                      <FormControlLabel
                        value="passport_number"
                        control={<Radio />}
                        label="Passport Number"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    className="input"
                    name="customer_aadhar"
                    type="text"
                    placeholder={
                      adharPassport === "customer_aadhar"
                        ? "Enter Aadhaar Number"
                        : "Enter Passport Number"
                    }
                    value={
                      adharPassport === "customer_aadhar"
                        ? customer_aadhar
                        : passport
                    }
                    variant="outlined"
                    style={{ margin: "0", width: "100%" }}
                    onChange={(e) =>
                      adharPassport === "customer_aadhar"
                        ? setCustomer_aadhar(e.target.value as String)
                        : setPassport(e.target.value as String)
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={7} style={{ padding: "10px" }}>
              <div className="price-details-new-booking">
                <table style={{ width: "100%", margin: "0" }}>
                  <thead>
                    <tr>
                      <td className="cell" align="center">
                        S No.
                      </td>
                      <td className="cell" align="center">
                        Name
                      </td>
                      <td className="cell" align="center">
                        Package
                      </td>
                      <td className="cell" align="center">
                        Price
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="cell" align="center">
                        1
                      </td>
                      <td className="cell" align="left">
                        {customer_designation} {customer_name}
                      </td>
                      <td className="cell" align="left">
                        {multiplePackage &&
                          multiplePackage.results &&
                          multiplePackage.results.length > 0 &&
                          multiplePackage.results.map(
                            (packageItem: any, index: any) => {
                              return (
                                <ul>
                                  <li>
                                    <a
                                      onClick={() => {
                                        setSinglePackage(packageItem);
                                        setPaneOpen(true);
                                      }}
                                      className={classes.packageLink}
                                    >
                                      {packageItem?.name}
                                      <br /> DESCRIPTION :{" "}
                                      {packageItem?.description}
                                      <br />
                                      TaT :{" "}
                                      {packageItem.package_center_address_prices
                                        ? packageItem
                                            .package_center_address_prices
                                            .tat_time
                                        : packageItem.tat_time}
                                      <br />
                                      Price :{" "}
                                      {packageItem.package_center_address_prices
                                        ? packageItem
                                            .package_center_address_prices
                                            .offer_price
                                        : packageItem.offer_price}
                                    </a>
                                  </li>
                                </ul>
                              );
                            }
                          )}
                      </td>
                      <td className="cell" align="center">
                        ₹ {Math.floor(totalPrice)}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={4}>
                        <table style={{ width: "100%" }}>
                          <tr>
                            <td
                              className="cell"
                              align="left"
                              rowSpan={3}
                              colSpan={2}
                              style={{ width: "60%" }}
                              valign="top"
                            >
                              <Grid container spacing={1} direction="row">
                                <Grid item xs={12}>
                                  <p>Enter Coupon</p>
                                </Grid>
                                <Grid item xs={8}>
                                  <Autocomplete
                                    id="coupon"
                                    onChange={(event, newValue) => {
                                      if (newValue) {
                                        let obj = JSON.parse(
                                          JSON.stringify(newValue, null, " ")
                                        );
                                        setCoupon(obj?.id as Number);
                                        setCouponCode(obj?.code as string);
                                      }
                                    }}
                                    inputValue={couponCode}
                                    options={couponList?.results || []}
                                    freeSolo
                                    blurOnSelect
                                    onInputChange={(event, newInputValue) => {
                                      clearTimeout(timer.current);
                                      setCouponCode(newInputValue);
                                      timer.current = setTimeout(() => {
                                        getCoupons(
                                          `?code=${newInputValue}&org_type=imaging`
                                        );
                                      }, 1000);
                                      if (newInputValue === "") {
                                        setCoupon(0);
                                        setDiscount(0);
                                        setTncMessage("");
                                        setCouponMessage("");
                                      }
                                    }}
                                    getOptionLabel={(option: any) =>
                                      option.code
                                    }
                                    getOptionDisabled={(option: any) =>
                                      option?.minimum_price > totalPrice
                                    }
                                    renderInput={(params) => (
                                      <TextField
                                        className="input"
                                        {...params}
                                        placeholder="Enter Coupon"
                                        variant="outlined"
                                        style={{
                                          width: "100%",
                                          margin: "0",
                                          padding: "0",
                                        }}
                                        inputProps={{
                                          ...params.inputProps,
                                          onKeyDown: (e) => {
                                            if (e.key === "Enter") {
                                              e.stopPropagation();
                                            }
                                          },
                                        }}
                                      />
                                    )}
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <Button
                                    variant="contained"
                                    fullWidth
                                    color="primary"
                                    disabled={redCashCheck}
                                    onClick={applyCoupon}
                                  >
                                    Apply
                                  </Button>
                                </Grid>
                                <p
                                  style={{
                                    color: "#8e24aa",
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                    alignItems: "center",
                                  }}
                                >
                                  {couponMessage} <br />
                                  {tncMessage}
                                </p>
                              </Grid>
                            </td>
                            <td
                              className="cell"
                              align="right"
                              style={{ width: "20%" }}
                            >
                              Discount
                            </td>
                            <td
                              className="cell"
                              align="center"
                              style={{ width: "20%" }}
                            >
                              ₹ {Math.floor(discount)}
                            </td>
                          </tr>
                          <tr>
                            <td
                              className="cell"
                              align="right"
                              style={{ width: "20%" }}
                            >
                              RedCash Discount
                            </td>
                            <td
                              className="cell"
                              align="center"
                              style={{ width: "20%" }}
                            >
                              ₹ {redDisc > 0 ? Math.floor(redDisc) : 0}
                            </td>
                          </tr>

                          <tr>
                            <td className="cell" align="right">
                              <h4 className="totalPrice">Total Price</h4>
                            </td>
                            <td className="cell" align="center">
                              <h4 className="totalPrice">
                                ₹{" "}
                                {redDisc > 0
                                  ? Math.floor(totalPrice) - Math.floor(redDisc)
                                  : Math.floor(totalPrice)}
                              </h4>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={3}
            direction="row"
            justify="center"
            alignItems="center"
            style={{ margin: "1rem auto" }}
          >
            <Grid item xs={12}>
              <Grid item xs={12}>
                <h3
                  style={{
                    borderTop: "dotted 1px #cccc",
                    paddingTop: "10px",
                    fontSize: "24px",
                    fontWeight: "bold",
                  }}
                >
                  CENTER ADDRESS
                </h3>
              </Grid>
              <Grid container spacing={3} style={{ margin: "1rem auto" }}>
                <Grid item xs={12} md={3}>
                  <Grid item xs={12} style={{ padding: "0" }}>
                    <p>Center Address</p>
                  </Grid>
                  <Grid item xs={12} style={{ padding: "0" }}>
                    <TextField
                      className="input"
                      name="customer_address"
                      type="text"
                      placeholder="Enter address"
                      value={customer_address}
                      disabled
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Grid item xs={12} style={{ padding: "0" }}>
                    <p>Center Longitude</p>
                  </Grid>
                  <Grid item xs={12} style={{ padding: "0" }}>
                    <TextField
                      className="input"
                      name="customer_longitude"
                      type="text"
                      placeholder="Enter Longitude"
                      value={center_longitude}
                      disabled
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Grid item xs={12} style={{ padding: "0" }}>
                    <p>Center Latitude</p>
                  </Grid>
                  <Grid item xs={12} style={{ padding: "0" }}>
                    <TextField
                      className="input"
                      name="customer_latitude"
                      type="text"
                      placeholder="Enter Latitude"
                      value={center_latitude}
                      disabled
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Grid item xs={12} style={{ padding: "0" }}>
                    <p>Booking For</p>
                  </Grid>
                  <Grid item xs={12} style={{ padding: "0" }}>
                    <Select
                      className="input"
                      name="booking_for"
                      variant="outlined"
                      value={bookingFor}
                      style={{ width: "100%", margin: "0", height: "40px " }}
                      onChange={(e) => setBookingFor(e.target.value as string)}
                    >
                      <MenuItem value="self">Self</MenuItem>
                      <MenuItem value="family">Family</MenuItem>
                      <MenuItem value="friend">Friend</MenuItem>
                      <MenuItem value="other">Other</MenuItem>
                    </Select>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <div className={classes.buttonContainer}>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              style={{ marginLeft: "1rem", marginBottom: "20px" }}
              disabled={loading || !selectedSlot || !packages}
            >
              Proceed for booking
            </Button>
          </div>
        </form>
      </Paper>
    </div>
  );
};
const mapStateToProps = (state: any) => ({
  packageList: state.PhleboAdminReducer.packageList,
  pin_code: state.PhleboAdminReducer.pin_code,
  couponList: state.PhleboAdminReducer.couponList,
  createBooking: state.PhleboAdminReducer.createBooking,
  leads: state.PhleboAdminReducer.leads,
  lead_details: state.PhleboAdminReducer.lead_details,
  booking_slots: state.PhleboAdminReducer.booking_slots,
  sameNumberBooking: state.PhleboAdminReducer.sameNumberBooking,
  loading: state.PhleboAdminReducer.loading,
  cities: state.PhleboAdminReducer.cities,
  centerInfo: state.PhleboAdminReducer.centerInfo,
  imagingTimeSlots: state.PhleboAdminReducer.imagingTimeSlots,
  createIMGBooking: state.PhleboAdminReducer.createIMGBooking,
  redCoin: state.PhleboAdminReducer.redCoin,
  subCenter: state.PhleboAdminReducer.subCenter,
  multiplePackage: state.PhleboAdminReducer.multiplePackage,
  displayCities: state.PhleboAdminReducer.displayCities,
  checkGeoFenceStatus: state.PhleboAdminReducer.checkGeoFenceStatus,
  geoFencesMapMyIndia: state.PhleboAdminReducer.geoFencesMapMyIndia,
  mapmyindiaAccessToken: state.PhleboAdminReducer.mapmyindiaAccessToken,
});

export default connect(mapStateToProps, {
  getPackage,
  getCoupons,
  createNewBooking,
  getLeads,
  getLeadDetails,
  resetLeadDetails,
  getAvailableSlots,
  getCities,
  getCenterInfo,
  getImagingTimeslots,
  createImagingBooking,
  getRedCoing,
  getSubCenterInfo,
  getPinCode,
  getMultiplePackage,
  getDisplayCities,
  checkGeoFenceArea,
  checkGeoFenchMapMyIndia,
  getMapMyIndiaAccessToken,
})(NewBooking);
