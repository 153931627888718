import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";
import { Grid, TextField, Select, MenuItem } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import {
  getPackageManagement,
  getPackageCategory,
} from "../actions/productMangerAction";
import { TableFooter, TablePagination } from "@material-ui/core";
import Button from '@mui/material/Button';
import { generateProductManagementFilter } from "../../helpers/generateUrl";
import { Link } from "react-router-dom";
import ProductManagementModal from "../Components/comments/productManagementModal";
import Autocomplete from "@material-ui/lab/Autocomplete";

const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 700,
    },
    root: {
      flexGrow: 0,
      //   width: "100%",
      //   marginTop: "20px",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
      //   padding: theme.spacing(3),
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
  })
);

interface Props {
  getPackageManagement: any;
  packageManagement: any;
  match: any;
  getPackageCategory: any;
  packagecategory: any;
  loading: boolean;
}

const LeadsTable: React.FC<Props> = ({
  getPackageManagement,
  packageManagement,
  getPackageCategory,
  packagecategory,
  match,
  loading,
}) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const timer = useRef<any>(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [id, setId] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [code, setCode] = useState<String>("");
  const [packagePrice, setPackagePrice] = useState<string>("");
  const [offerPrice, setOfferPrice] = useState<string>("");
  const [addonPrice, setAddonPrice] = useState<string>("");
  const [parameter, setParameter] = useState<string>("");
  const [fasting, setFasting] = useState<string>("none");
  const [status, setStatus] = useState<string>("none");
  const [age, setAge] = useState<string>("");
  const [tatModel, setTatModal] = useState<boolean>(false);
  const [patientsNumber, setPatientsNumber] = useState<string>("");
  const [tat, setTat] = useState<string>("");
  const [category, setCategory] = useState<string>("");
  const [mrp, setMrp] = useState<string>("");
  const [gender, setGender] = useState<string>("none");
  const [open, setOpen] = useState<boolean>(false);
  const [modalData, setModalData] = useState<any>("");
  const [editUser, setEditUser] = useState<any>({});
  const [categories, setCategories] = useState<any>("");
  // const bookingId = match.params.id;

  const history = useHistory();

  const filterLeads = () => {
    const body: any = {
      package_id: id,
      name: name,
      code: code as String,
      parameter: parameter,
      recommended_gender: gender,
      package_price: mrp,
      fasting_time: fasting,
      recommended_age: age,
      addon_price: addonPrice,
      offer_price: offerPrice,
      status,
      tat_time: tat,
      category: categories,
    };
    const url = generateProductManagementFilter(body).substring(2);

    getPackageManagement(`?${url}`);
  };

  useEffect(() => {
    getPackageManagement();
    getPackageCategory();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url = packageManagement.links.next;

      getPackageManagement(`?${url}`.substring(url.indexOf("?") + 1));
    } else if (newPage < page) {
      let url = packageManagement.links.previous;
      getPackageManagement(
        url.indexOf("?") === -1 ? "" : `?${url}`.substring(url.indexOf("?") + 1)
      );
    }
    setPage(newPage);
  };

  const handleUser = () => {
    let body: any = {
      ...editUser,
    };
    // updateUserList(body)
    setEditUser({});
    setTimeout(() => {
      // getUserList()
    }, 1000);
  };
  const handleOpenModel = (data: any) => {
    setOpen(true);
    setModalData(data);
    // setTatModal(id);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  return (
    <>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid
          container
          spacing={3}
          direction="row"
          alignItems="center"
          justify="center"
          style={{ margin: "0" }}
        >
          <Grid item xs={12} sm={6} md={9}>
            <h2>Package Management</h2>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Button
              variant="contained"
              color="primary"
              style={{ marginLeft: "1rem" }}
              onClick={() => {
                history.push(`/dashboard/pm/create/package`);
              }}
            >
              Create Package
            </Button>
          </Grid>
          <Grid container spacing={1} direction="row" style={{ marginTop: 20 }}>
            <Grid item xs={12} sm={4} md={2}>
              <TextField
                name="Package ID"
                type="number"
                value={id}
                className="input"
                variant="outlined"
                label="Package Id"
                onChange={(e) => setId(e.target.value as string)}
                style={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <TextField
                className="input"
                name="name"
                type="text"
                label="Package Name"
                value={name}
                variant="outlined"
                onChange={(e) => setName(e.target.value as string)}
                style={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <TextField
                className="input"
                name="Package Code"
                type="text"
                label="Package Code"
                value={code}
                variant="outlined"
                onChange={(e) => setCode(e.target.value)}
                style={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <TextField
                name="Parameter"
                type="number"
                value={parameter}
                className="input"
                variant="outlined"
                label="Parameter"
                onChange={(e) => setParameter(e.target.value as string)}
                style={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <Select
                className="input"
                name="status"
                variant="outlined"
                label="Fasting"
                value={fasting}
                style={{ width: "100%", height: "40px" }}
                onChange={(e) => setFasting(e.target.value as string)}
              >
                <MenuItem value={"none"} disabled>
                  FASTING
                </MenuItem>
                <MenuItem value={"Fasting"}>FASTING</MenuItem>
                <MenuItem value={"Non Fasting"}>NON FASTING</MenuItem>
                <MenuItem value={"Others"}>OTHERS</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <Select
                className="input"
                name="status"
                variant="outlined"
                label="Status"
                value={status}
                style={{ width: "100%", height: "40px" }}
                onChange={(e) => setStatus(e.target.value as string)}
              >
                <MenuItem value={"none"} disabled>
                  Status
                </MenuItem>
                <MenuItem value={"True"}>Active</MenuItem>
                <MenuItem value={"False"}>In Active</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <TextField
                name="Age"
                value={age}
                className="input"
                variant="outlined"
                label="Age"
                onChange={(e) => setAge(e.target.value as string)}
                style={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <TextField
                name="TAT"
                type="number"
                value={tat}
                className="input"
                variant="outlined"
                label="TAT"
                onChange={(e) => setTat(e.target.value as string)}
                style={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <Autocomplete
                id="categories"
                onChange={(event, newValue) => {
                  let sourceIds: any = [];
                  if (newValue) {
                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                    sourceIds = obj.map((item: any) => item.id);
                  }
                  setCategories(sourceIds);
                }}
                options={packagecategory?.results || []}
                freeSolo
                blurOnSelect
                aria-required
                limitTags={1}
                multiple
                getOptionLabel={(option: any) => option?.name}
                getOptionDisabled={(option: any) => {
                  return categories.includes(option);
                }}
                disableClearable
                disableCloseOnSelect
                onInputChange={(event, newInputValue) => {
                  clearTimeout(timer.current);
                  timer.current = setTimeout(() => {
                    getPackageCategory(`?name=${newInputValue}`);
                  }, 1000);
                  if (newInputValue.length === 0) {
                    setCategories([]);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    className="input"
                    {...params}
                    label="Categories"
                    // required
                    variant="outlined"
                    style={{ width: "100%", margin: "0", padding: "0" }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <Select
                className="input"
                name="status"
                variant="outlined"
                label="Gender"
                value={gender}
                style={{ width: "100%", height: "40px" }}
                onChange={(e) => setGender(e.target.value as string)}
              >
                <MenuItem disabled value={"none"}>
                  Gender
                </MenuItem>
                <MenuItem value={"male"}>Male</MenuItem>
                <MenuItem value={"female"}>Female</MenuItem>
                <MenuItem value={"both"}>Both</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                onClick={filterLeads}
                disabled={loading}
              >
                Filter
              </Button>
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => history.push("/dashboard/pm/packagemanagement")}
              >
                Reset
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <div
          className="data-table"
          style={{ width: "100%", marginTop: "2rem" }}
        >
          <TableContainer
            className={classes.tableContainer}
            component={Paper}
            style={{ maxHeight: "560px" }}
          >
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell align="center">Package ID</StyledTableCell>
                  <StyledTableCell align="center">Package Code</StyledTableCell>
                  <StyledTableCell align="center">Package Name</StyledTableCell>
                  <StyledTableCell align="center">Package Price</StyledTableCell>
                  <StyledTableCell align="center">Offer Price</StyledTableCell>
                  <StyledTableCell align="center">Addon Price</StyledTableCell>
                  <StyledTableCell align="center">Parameter</StyledTableCell>
                  <StyledTableCell align="center">Fasting</StyledTableCell>
                  <StyledTableCell align="center">Age</StyledTableCell>
                  <StyledTableCell align="center">Gender</StyledTableCell>
                  <StyledTableCell align="center">TAT</StyledTableCell>
                  <StyledTableCell align="center">Edit</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {packageManagement && packageManagement?.results && packageManagement?.results?.length>0 ? (
                  packageManagement?.results.map((item: any) => {
                    return (
                      <StyledTableRow key={item.id}>
                        <StyledTableCell align="center">
                          {item.id ? item.id : "NA"}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {item.code ? item.code : "NA"}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {item.name ? item.name : "NA"}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {item.package_price ? item.package_price : "NA"}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {item.offer_price ? item.offer_price : "NA"}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {item.addon_price ? item.addon_price : "NA"}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {item.parameter ? item.parameter : "NA"}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {item.fasting_time ? item.fasting_time : "NA"}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {item.recommended_age ? item.recommended_age : "NA"}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {item.recommended_gender
                            ? item.recommended_gender
                            : "NA"}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <Button
                            // disabled={data.is_sync}
                            variant="contained"
                            color="inherit"
                            onClick={() => handleOpenModel(item)}
                          >
                            TAT
                          </Button>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <Button
                            color="primary"
                            variant="text"
                            onClick={() => {
                              history.push(
                                `/dashboard/pm/bookings-edit/${item.id}/edit`
                              );
                            }}
                          >
                            Edit
                          </Button>
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })
                ) : (
                  <h3 style={{ textAlign: "center" }}>No data found</h3>
                )}
              </TableBody>
              {packageManagement && packageManagement.count > 0 && (
                <TableFooter>
                  <TablePagination
                     colSpan={3}
                    count={packageManagement.count || 0}
                    rowsPerPageOptions={[]}
                    rowsPerPage={rowsPerPage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    page={page}
                    SelectProps={{
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                  />
                </TableFooter>
              )}
            </Table>
          </TableContainer>
        </div>
        <ProductManagementModal
          open={open}
          setOpen={setOpen}
          data={modalData}

          // updateTatPackage={open}
        />
      </main>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  packageManagement: state.ProductManagerReducer.packageManagement,
  packagecategory: state.ProductManagerReducer.packagecategory,
});

export default connect(mapStateToProps, {
  getPackageManagement,
  getPackageCategory,
})(LeadsTable);
