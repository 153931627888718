import React, { useState, useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Chip,
  Paper,
  withStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import moment from "moment";
import Button from '@mui/material/Button';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Loader from "../../components/loader";
import ImageTwoToneIcon from "@material-ui/icons/ImageTwoTone";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import { Grid, Select, MenuItem, TextField } from "@material-ui/core";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import "./index.sass";
import { updateBatchPayment } from "../actions/PhleboAdmin";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      marginTop: "2rem",
      padding: "0.5rem 2rem",
      width: "100%",
    },
    table: {
      maxWidth: "100%",
      margin: "auto",
    },
    conlorChange: {
      background: "red",
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
  })
);
const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);
const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

interface Props {
  loading: Boolean;
  batchPayment: any;
  getBatchPayment: any;
  updateBatchPayment: any;
  setPage: any;
  page: any;
}

const BatchedPaymentTable: React.FC<Props> = ({
  loading,
  batchPayment,
  getBatchPayment,
  updateBatchPayment,
  page,
  setPage,
}) => {
  const classes = useStyles();
  const history = useHistory();
  // const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [confirmPaymentId, setConfirmPaymentId] = useState<any>("");
  const [confirmModal, setConfirmModal] = useState<boolean>(false);
  const [viewImg, setViewImg] = useState<boolean>(false);
  const [image, setImage] = useState<any>("");
  const [remarks, setRemarks] = useState<any>("");
  const [statusPayment, setStatusPayment] = useState<any>("");
  const [isConfirmed, setIsConfirmed] = useState<any>(false);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url = batchPayment.next;
      getBatchPayment(url.substring(url.indexOf("?")));
    } else if (newPage < page) {
      let url = batchPayment.previous;
      getBatchPayment(
        url.indexOf("?") === -1 ? "" : url.substring(url.indexOf("?"))
      );
    }
    setPage(newPage);
  };
  const updateRemarkConfirm = async () => {
    const data = {
      finance_remark: remarks,
      status: statusPayment,
    };
    await updateBatchPayment(confirmPaymentId, data);
    setConfirmModal(false);
    history.push("/dashboard/pha/batched_payment");
  };
  const handleUpdatePayment = (data: any) => {
    setStatusPayment(data?.status);
    if (data?.status === "accepted") {
      setIsConfirmed(true);
    } else {
      setIsConfirmed(false);
    }
    setRemarks(data?.finance_remark);
    setConfirmModal(true);
    setConfirmPaymentId(data?.id);
  };
  const handleClose = () => {
    setConfirmModal(false);
    setConfirmPaymentId("");
  };
  const handleViewImg = (val: any) => {
    setImage(val?.receipt);
    setViewImg(true);
  };
  const handleCloseImg = () => {
    setViewImg(false);
    setImage("");
  };
  return (
    <div style={{ width: "100%" }} className="data-table">
      <TableContainer
        className={classes.tableContainer}
        component={Paper}
        style={{ maxHeight: "500px" }}
      >
        {loading ? (
          <Loader />
        ) : (
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell align="center">S. No.</StyledTableCell>
                <StyledTableCell align="center">Phlebo Name</StyledTableCell>
                <StyledTableCell align="center">Batch No's.</StyledTableCell>
                <StyledTableCell align="center">Processing Lab</StyledTableCell>
                <StyledTableCell align="center">
                  Processing Lab City
                </StyledTableCell>
                <StyledTableCell align="center">Amount</StyledTableCell>
                <StyledTableCell align="center">
                  Amount Deposit Date
                </StyledTableCell>
                <StyledTableCell align="center">Bank Receipt</StyledTableCell>
                <StyledTableCell align="center">Batch Remarks</StyledTableCell>
                <StyledTableCell align="center">Status</StyledTableCell>
                <StyledTableCell align="center">
                  Finance Remarks
                </StyledTableCell>
                {/* <StyledTableCell align="center">Edit</StyledTableCell> */}
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {batchPayment &&
                batchPayment?.results &&
                batchPayment?.results.length > 0 &&
                batchPayment?.results.map((data: any, index: any) => {
                  return (
                    <StyledTableRow key={index}>
                      <StyledTableCell align="center">
                        {index + 1}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.phlebo_name}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {String(data?.batches)}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.procession_lab}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.procession_lab_city}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.amount}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {moment(data?.created_at).format("DD-MM-YYYY, hh:mm A")}
                      </StyledTableCell>

                      <StyledTableCell align="center">
                        {data.receipt ? (
                          <ImageTwoToneIcon
                            onClick={() => handleViewImg(data)}
                          />
                        ) : (
                          ""
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.remark}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.status}
                      </StyledTableCell>

                      <StyledTableCell align="center">
                        {data?.finance_remark}
                      </StyledTableCell>
                      {/* <StyledTableCell align="center">
                        <Button
                          style={{ background: "#2196f3" }}
                          variant="outlined"
                          startIcon={<ModeEditIcon />}
                          onClick={() => handleUpdatePayment(data)}
                        >
                          Edit
                        </Button>
                      </StyledTableCell> */}
                    </StyledTableRow>
                  );
                })}
            </TableBody>
            <TableFooter>
              <StyledTableRow>
                <TablePagination
                  colSpan={2}
                  count={batchPayment?.count || 0}
                  rowsPerPageOptions={[]}
                  rowsPerPage={batchPayment?.page_size || 20}
                  page={page}
                  SelectProps={{
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                />
              </StyledTableRow>
            </TableFooter>
          </Table>
        )}
      </TableContainer>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={confirmModal}
      >
        <DialogTitle id="customized-dialog-title">
          Edit Payment status
        </DialogTitle>
        <DialogContent dividers>
          <TextField
            variant="outlined"
            fullWidth
            value={statusPayment}
            select
            onChange={(e) => setStatusPayment(e.target.value)}
            label="Select status"
          >
            <MenuItem value="rejected">Rejected</MenuItem>
            <MenuItem value="accepted">Accepted</MenuItem>
          </TextField>
          <textarea
            style={{ marginTop: "15px", width: "100%" }}
            name="remarks"
            placeholder="Remarks"
            rows={4}
            cols={50}
            value={remarks}
            onChange={(e) => setRemarks(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            autoFocus
            onClick={() => updateRemarkConfirm()}
            color="primary"
            disabled={statusPayment === "" || isConfirmed}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        onClose={handleCloseImg}
        aria-labelledby="customized-dialog-title"
        open={viewImg}
      >
        <DialogTitle id="customized-dialog-title">Reciept Image</DialogTitle>
        <DialogContent dividers>
          <img src={image} width="400" />
        </DialogContent>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps, {
  updateBatchPayment,
})(BatchedPaymentTable);
