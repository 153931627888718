import React, { useEffect, useState } from 'react'
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { AppBar, Checkbox, Card, Chip, Dialog, Divider, Grid, IconButton, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Toolbar, Typography } from '@material-ui/core';
import Button from '@mui/material/Button';
import { Autocomplete } from "@material-ui/lab";
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  getBookingById,
  getPinCode,
  clickToCallBooking,
  updateBooking,
  resampleBooking,
  getAvailableSlots,
  getPackage,
  updateAdditionalBooking,
  getSmsReportSend,
  getPaymentInfo,
  getReportDownload,
  getReportStatus
} from '../../../actions/BulkUploadMasterAction'
import Loader from '../../../../components/loader';
import moment from 'moment';
import CallIcon from "@material-ui/icons/Call";
import { GridCloseIcon } from '@material-ui/data-grid';
import { useRef } from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import LocalAtmIcon from "@material-ui/icons/LocalAtm";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,

    },
    toolbar: {
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      marginTop: "2rem",
      padding: "0.5rem 2rem",
      width: "100%",
    },
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    slotContainer: {
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "center",
    },
    slot: {
      width: "150px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      margin: "0.5rem"
    },
    selectedSlot: {
      width: "150px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      background: "linear-gradient(60deg, cyan, #00dfff, cyan)",
      cursor: "pointer",
      margin: "0.5rem"
    },
    tableHead: {
      fontWeight: "bold",
      textTransform: "capitalize",
      textAlign: "center",
    },
  })
);

interface Props {
  match: any;
  booking: any;
  getBookingById: any;
  getPinCode: any;
  pin_code: any;
  partnerList: any;
  getPartners: any;
  clickToCallBooking: any;
  updateBooking: any;
  getTubeDetails: any;
  resampleBooking: any;
  getAvailableSlots: any;
  booking_slots: any;
  getPackage: any;
  packageList: any;
  tube_details: any;
  updateAdditionalBooking: any;
  getSmsReportSend: any;
  additional_booking: any;
  loading: Boolean;
  getBookingByBarcode: any;
  bookingByBarcode: any;
  getPaymentInfo: any;
  paymentInformation: any;
  getReportDownload: any;
  reportDownload: any;
  getReportStatus: any;
  reportStatus: any;
}

const PhleboAdminViewBooking: React.FC<Props> = ({
  match,
  booking,
  getBookingById,
  getPinCode,
  pin_code,
  clickToCallBooking,
  updateBooking,
  resampleBooking,
  getAvailableSlots,
  booking_slots,
  tube_details,

  getPackage,
  packageList,
  updateAdditionalBooking,
  additional_booking,
  getSmsReportSend,

  bookingByBarcode,
  getPaymentInfo,
  paymentInformation,
  getReportDownload,
  reportDownload,
  getReportStatus,
  reportStatus,
  loading
}) => {
  const classes = useStyles();

  const timer = useRef<any>(0)
  const [timeSlots, setTimeSlots] = useState<Array<Object>>([])
  const [selectedSlot, setSelectedSlot] = useState<Number>(booking.collection_slot && booking.collection_slot.id)
  const [customer_name, setCustomer_name] = useState<any>("")
  const [customer_age, setCustomer_age] = useState<any>("")
  const [customer_gender, setCustomer_gender] = useState<any>("male")
  const [customer_aadhar, setCustomer_aadhar] = useState<any>("")
  const [packageDetails, setPackageDetails] = useState<any>([])
  const [tubeDetails, setTubeDetails] = useState<any>([])
  const [resamplePackage, setResamplePackage] = useState<any>("")
  const [resampleTube, setResampleTube] = useState<any>([])
  const [packageId, setPackageId] = useState<any>([])
  const [id, setId] = useState(0)
  const [openResapling, setResapling] = React.useState(false);
  const [resamplingMessage, setResamplinigMessage] = useState<string>("");
  const [open, setOpen] = React.useState(false);
  const [raiseComplaint, setRaiseComplaint] = useState(false)
  const [tubeData, setTubeData] = useState<any>({})
  const barcodeId = localStorage.getItem("barcode");
  const [bookingData, setBookingData] = useState({})
  const [paymentId, setPaymentId] = useState<any>(0)
  const [openPaymentModal, setOpenPaymentModal] = useState<any>(false)
  const [personId, setPersoneId] = React.useState('none');
  const [bill_id, set_BillId] = useState<String>("")
  const [partialCheck, setpartialCheck] = useState<any>("")
  const [billId, setBillId] = useState<any>("")
  const [additionalBillId, setAdditionalBillId] = useState<any>("")
  const [openReportModal, setReportModal] = useState(false);


  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
    getSlots()
  };

  const getArea = (pincode: String, colDate: any) => {
    const zone = pin_code.results && pin_code.results.find((code: any) => code.pincode === pincode)
    getAvailableSlots(colDate, zone && zone.zone_data && zone.zone_data.id)
  }

  const getSlots = () => {
    getArea(booking.customer_areapincode && booking.customer_areapincode.pincode, booking.collection_date && booking.collection_date)
  }

  useEffect(() => {
    const slots = booking_slots && booking_slots.results;
    setTimeSlots(slots && slots[0].zone_data && slots[0].zone_data.time_slots)
  }, [booking_slots]);

  const bookingId = match.params.id
  useEffect(() => {
    getBookingById(bookingId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingId, additional_booking])

  useEffect(() => {
    getPinCode(booking.customer_areapincode && `${booking.customer_areapincode.pincode}&show_code=true`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [booking])

  const handleCallClick = (id: number, type: string) => {
    clickToCallBooking(id, type);
  };

  const history = useHistory()
  const cancelHandler = () => {
    // const data: any = {
    //     "booking_status": "cancelled"
    // }
    // updateBooking(data, bookingId)
    history.push(`/dashboard/lp`)
  }

  const resamplingHandler = () => {
    setResapling(true)

  }
  const ProccessResampling = () => {
    const data: any = {
      "booking_status": "resampling",
      "query": resamplingMessage,
      // "resampling_tube":[{packageId:packageId}]
    }
    resampleBooking(data, bookingId)
    history.push(`/dashboard/lp`)
    setResamplinigMessage("")
    setResapling(false);
  };
  const closeResampling = () => {
    setResamplinigMessage("")
    setResapling(false);

  }

  const handleEditMember = (member: any) => {
    setCustomer_aadhar(member.customer_aadhar)
    setCustomer_age(member.customer_age)
    setCustomer_gender(member.customer_gender)
    setCustomer_name(member.customer_name)
    setPackageDetails(member.packages)
    setSelectedSlot(member.collection_slot.id)
    setId(member.id)
    handleOpen()
  }

  const updateFamilyMemberHandler = async () => {
    const body: any = {
      customer_aadhar,
      customer_age,
      customer_gender,
      customer_name,
    }
    await updateAdditionalBooking(id, body)
    setOpen(false)
    setCustomer_aadhar("")
    setCustomer_age("")
    setCustomer_gender("male")
    setCustomer_name("")
    setPackageDetails([])
    setId(0)
  }
  const handleSendReportSms = () => {
    getSmsReportSend(`${booking.pk}/booking`)
    alert("Report SMS Send Successfully")
  }

  const handlePaymentInfo = (id: any) => {
    setPaymentId(id)
    setOpenPaymentModal(true);
    setPaymentId(id);
  };
  useEffect(() => {
    if (paymentId != 0) {
      getPaymentInfo(`?booking=${paymentId}`);
    }

  }, [paymentId])
  //Download Report Api call
  useEffect(() => {
    if (personId !== 'none') {
      if (bookingId == personId) {
        if (billId !== null) {
          getReportDownload(`${bookingId}/`);
        }
        else {
        }
      }
      else if (bookingId != personId) {
        if (additionalBillId !== null) {
          getReportDownload(`${personId}/?additional_id=true`);
        }
        else {
        }
      }
    }
  }, [personId])
  //Download Report Api call
  //Report Status 
  const handleReportStatus = () => {
    if (personId !== 'none') {
      if (bookingId == personId) {
        if (billId !== null) {
          getReportStatus(`?booking_id=${bookingId}`);
          setReportModal(true);
        }
        else {
          alert("Bill Id is Not Genrated")
        }
      }
      else if (bookingId != personId) {
        if (additionalBillId !== null) {
          getReportStatus(`?add_member_booking=${personId}`);
          setReportModal(true);
        }
        else {
          alert("Family Member Bill Id is Not Genrated")
        }

      }
    }
  };
  //Report Status 
  //Report Status 
  //Select Customer Name
  const handleChange = (event: any, data: any) => {
    if (event.target.value == bookingId) {
      setBillId(data?.bill_id)
    }
    else {
      const additionDetails = data?.additional_members.find((bId: any) => bId.id === event.target.value)
      setAdditionalBillId(additionDetails.bill_id)
    }
    setPersoneId(event.target.value);
  };
  //Select Customer Name

  //Download Report
  const handleDownloadReport = (report: any) => {
    if (billId === null && additionalBillId !== null) {
      alert("Bill Id Is Not Enter")
    } {
      if (report.message === "Your report is not ready yet") {
        alert(report.message)
      }
      else {
        var b64 = report.allReportDetails.reportDetails;
        var bin = window.atob(b64);
        var link = document.createElement('a');
        link.download = `${report.allReportDetails.Patient_Name}-${report.allReportDetails.Age}.pdf`;
        link.href = 'data:application/octet-stream;base64,' + b64;
        link.click();
      }
    }

  };
  //Download Report
  return (
    <>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <div className={classes.buttonContainer}>
         

        </div>

        <Dialog open={openResapling} onClose={closeResampling} aria-labelledby="form-dialog-title">

          <DialogContent style={{ width: "600px", height: "auto" }}>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Message:"
              value={resamplingMessage}
              onChange={(e) => setResamplinigMessage(e.target.value as string)}
              type="text"
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={closeResampling} color="primary">
              Cancel
            </Button>
            <Button onClick={ProccessResampling} color="primary"
              disabled={

                resamplingMessage === ""
              }>
              Proceed
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog fullScreen open={open} onClose={handleClose}>
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <GridCloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                Add Family Member
              </Typography>
              <Button autoFocus color="inherit" onClick={updateFamilyMemberHandler}>
                Edit
              </Button>
            </Toolbar>
          </AppBar>
          <Grid container spacing={3} style={{ margin: "1rem auto", padding: "0 1rem" }}>
            <Grid item xs={12} md={6}>
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={12} md={4}>
                  <p>Customer Name</p>
                </Grid>
                <Grid item xs={12} md={8}>
                  <input
                    className="input"
                    name="collection_date"
                    type="name"
                    placeholder="Customer Name"
                    value={customer_name}
                    onChange={(e) => setCustomer_name(e.target.value)}
                    style={{ width: "100%", margin: "0", padding: "18.5px 14px" }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={12} md={4}>
                  <p>Customer Aadhar</p>
                </Grid>
                <Grid item xs={12} md={8}>
                  <input
                    className="input"
                    name="collection_date"
                    type="number"
                    placeholder="Customer Aadhaar"
                    value={customer_aadhar}
                    onChange={(e) => setCustomer_aadhar(e.target.value)}
                    style={{ width: "100%", margin: "0", padding: "18.5px 14px" }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={12} md={4}>
                  <p>Packages</p>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Autocomplete
                    id="package"
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let packs: any = []
                        newValue.map((val: any) => {
                          let obj = JSON.parse(JSON.stringify(val, null, " "));
                          packs.push(obj)
                        })
                        setPackageDetails(packs)
                      }
                    }}
                    multiple
                    options={packageList.results}
                    value={packageDetails}
                    disableClearable
                    limitTags={0}
                    getOptionLabel={(option: any) => option.name && option.name.toString()}
                    getOptionDisabled={(option: any) => {
                      const packID = packageDetails.map((pack: any) => pack.id)
                      return packID.includes(option.id)
                    }}
                    onInputChange={(event, newInputValue) => {
                      clearTimeout(timer.current)
                      timer.current = setTimeout(() => {
                        getPackage(newInputValue)
                      }, 1000)
                    }}
                    freeSolo
                    disableCloseOnSelect
                    renderTags={(value: string[], getTagProps) =>
                      value.map((option: any, index: number) => (
                        <Chip
                          variant="default"
                          color="primary"
                          label={option.name && option.name.toString()}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Package"
                        variant="outlined"
                        style={{ width: "100%", margin: "0", padding: "0" }}
                        className="input"
                      />
                    )}
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={12} md={4}>
                  <p>Customer Age</p>
                </Grid>
                <Grid item xs={12} md={8}>
                  <input
                    className="input"
                    name="collection_date"
                    type="number"
                    placeholder="Customer Age"
                    value={customer_age}
                    onChange={(e) => setCustomer_age(e.target.value)}
                    style={{ width: "100%", margin: "0", padding: "18.5px 14px" }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={12} md={4}>
                  <p>Customer Gender</p>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Select
                    className="input"
                    name="customer_gender"
                    variant="outlined"
                    value={customer_gender}
                    style={{ width: "100%", margin: "0", padding: "0" }}
                    onChange={(e) => setCustomer_gender(e.target.value)}
                  >
                    <MenuItem selected hidden value={"none"}>Please Select</MenuItem>
                    <MenuItem value={"male"}>Male</MenuItem>
                    <MenuItem value={"female"}>Female</MenuItem>
                   
                  </Select>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card className="sampleCollectionTimeFamily" style={{ height: "450px !important", overflow: "auto" }}>
                <p style={{ marginBottom: "1rem" }}>Sample Collection Time</p>
                <div className={classes.slotContainer}>
                  {timeSlots && timeSlots.length > 0 && timeSlots.map((slot: any) => {
                    return (
                      slot.available_slots > 0 && (
                        <Card key={slot.id} className={slot.id === selectedSlot ? classes.selectedSlot : classes.slot}>
                          <p style={{ textAlign: "center", color: "black", fontSize: "14px" }}>{slot.start_time}-{slot.end_time}</p>

                          {/* <p style={{ textAlign: "center", color: "black" }}>{moment(`${slot.start_time}`, ["HH.mm.ss"]).format("hh:mm A")} - {moment(`${slot.end_time}`, ["HH.mm.ss"]).format("hh:mm A")}</p> */}
                          <p style={{ textAlign: "center", color: "green" }}> Available slots: {slot.available_slots}</p>
                        </Card>
                      )
                    )
                  })}
                </div>
              </Card>
            </Grid>
          </Grid>
        </Dialog>

        <Paper className={classes.paper} elevation={15}>
          <h3 style={{ textAlign: "center" }}>View Booking Details</h3>
          {loading ? <Loader /> : (
            <>
              <Grid container direction="row" alignItems="flex-start" justify="space-between" spacing={3} style={{ margin: "1rem auto" }}>
                <Grid item xs={6} md={3}>
                  <h6>Collection Date</h6>
                  <p style={{ fontWeight: "normal" }}>{booking?.collection_date}</p>
                </Grid>
                <Grid item xs={6} md={3}>
                  <h6>Pincode</h6>
                  <p style={{ fontWeight: "normal" }}>{booking?.customer_areapincode && booking?.customer_areapincode?.pincode}</p>
                </Grid>
                <Grid item xs={6} md={3}>
                  <h6>Collection Time</h6>
                  <p style={{ fontWeight: "normal" }}>
                    {booking?.collection_slot && moment(`${booking?.collection_slot.slot.split("-")[0]}`, ["HH.mm.ss"]).format("hh:mm A")}
                    {" - "}
                    {booking?.collection_slot && moment(`${booking?.collection_slot.slot.split("-")[1]}`, ["HH.mm.ss"]).format("hh:mm A")}
                  </p>
                </Grid>
              </Grid>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableHead}>
                        S No.
                      </TableCell>
                      <TableCell className={classes.tableHead}>
                        Customer Name
                      </TableCell>
                      <TableCell className={classes.tableHead}>Age</TableCell>
                      <TableCell className={classes.tableHead}>
                        Gender
                      </TableCell>
                      <TableCell className={classes.tableHead}>
                        Package Name
                      </TableCell>
                      <TableCell className={classes.tableHead}>
                        Partner OrderId
                      </TableCell>
                      <TableCell className={classes.tableHead}>
                        Aadhaar  No
                      </TableCell>

                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow key={1}>
                      <TableCell align="center">1.</TableCell>
                      <TableCell align="center">{`${booking?.designation || ""
                        } ${booking?.customer_name}`}</TableCell>
                      <TableCell align="center">
                        {booking?.customer_age}
                      </TableCell>
                      <TableCell align="center">
                        {booking?.customer_gender}
                      </TableCell>
                      <TableCell align="center">
                        <ul>
                          {booking?.packages &&
                            booking?.packages.map((pack: any, index: any) => {
                              return (
                                <li
                                  key={index}
                                  style={{
                                    margin: "1rem 0",
                                    textAlign: "left",
                                  }}
                                >
                                  {/* {pack.name}, {pack.tat_time} */}
                                  {
                                    pack?.name && pack?.package_city_prices
                                      ? pack.name.toString() +
                                      ", " +
                                      pack?.package_city_prices?.tat_time
                                      : pack?.name + ", " + pack?.tat_time
                                  }
                                </li>
                              );
                            })}
                        </ul>
                      </TableCell>
                      <TableCell align="center">
                        {booking?.partner && booking?.partner.id}
                      </TableCell>
                      <TableCell align="center">
                        {booking?.customer_aadhar || ""}
                      </TableCell>
                      <TableCell align="center">

                      </TableCell>
                    </TableRow>
                    {booking?.additional_members &&
                      booking?.additional_members?.length > 0 &&
                      booking?.additional_members.map(
                        (member: any, index: number) => {
                          return (
                            <TableRow key={index + 1}>
                              <TableCell align="center">
                                {index + 2}.
                              </TableCell>
                              <TableCell align="center">
                                {`${member?.designation || ""} ${member?.customer_name
                                  }`}
                              </TableCell>
                              <TableCell align="center">
                                {member?.customer_age}
                              </TableCell>
                              <TableCell align="center">
                                {member?.customer_gender}
                              </TableCell>
                              <TableCell align="center">
                                <ul>
                                  {member?.packages &&
                                    member?.packages.map(
                                      (pack: any, index: any) => {
                                        return (
                                          <li
                                            key={index}
                                            style={{
                                              margin: "0 0 1rem 0",
                                              textAlign: "left",
                                            }}
                                          >
                                            {/* {pack.name}, {pack.price} */}
                                            {
                                              pack?.name && pack?.package_city_prices
                                                ? pack.name.toString() +
                                                ", " +
                                                pack?.package_city_prices?.tat_time
                                                : pack?.name + ", " + pack?.tat_time
                                            }
                                          </li>
                                        );
                                      }
                                    )}
                                </ul>
                              </TableCell>
                              <TableCell align="center">
                              </TableCell>
                              <TableCell align="center">
                                {member?.customer_aadhar}
                              </TableCell>
                              <TableCell align="center">

                              </TableCell>
                            </TableRow>
                          );
                        }
                      )}
                    <TableRow>
                    </TableRow>

                    <TableRow>
                      <TableCell colSpan={12} >
                        <h6
                          style={{
                            textAlign: "right",
                            padding: "0rem",
                            fontSize: "14px",
                            fontWeight: "bold",
                          }}
                        >
                          Price.{" "}
                          {booking.discounted_price &&
                            booking.discounted_price?.total_price_package &&
                            Math.floor(
                              booking.discounted_price?.total_price_package
                            )}/-
                        </h6>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={12}>
                        <h6
                          style={{
                            textAlign: "right",
                            padding: "0rem",
                            fontSize: "14px",
                            fontWeight: "bold",
                          }}
                        >
                          Phlebo Cost.{" "}
                          {booking?.discounted_price &&
                            booking?.discounted_price?.phlebo_cost &&
                            Math.floor(booking?.discounted_price?.phlebo_cost)}
                          /-
                        </h6>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={12}>
                        <h6
                          style={{
                            textAlign: "right",
                            padding: "0rem",
                            fontSize: "14px",
                            fontWeight: "bold",
                          }}
                        >
                          Discount.{" "}
                          {booking.discounted_price &&
                            booking?.discounted_price?.counpon_discount &&
                            Math.floor(
                              booking?.discounted_price?.counpon_discount
                            )}
                          /-
                        </h6>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={12}>
                        <h6
                          style={{
                            textAlign: "right",
                            padding: "0rem",
                            fontSize: "14px",
                            fontWeight: "bold",
                          }}
                        >
                          RedCash Discount.{" "}
                          {booking?.redcash_discounted_amount}
                          /-
                        </h6>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={12}>
                        <h6
                          style={{
                            textAlign: "right",
                            padding: "0rem",
                            fontSize: "14px",
                            fontWeight: "bold",
                          }}
                        >
                          Discounted Price.{" "}
                          {booking?.discounted_price &&
                            booking?.discounted_price?.final_total_price &&
                            Math.floor(
                              booking?.discounted_price?.final_total_price
                            ) - Math.floor(booking?.redcash_discounted_amount)}
                        </h6>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={12}>
                        <h6
                          style={{
                            textAlign: "right",
                            padding: "1rem",
                            fontSize: "20px",
                            fontWeight: "bold",
                          }}
                        >
                          Total Amount: Rs.{" "}
                          {booking?.discounted_price &&
                            booking?.discounted_price?.final_total_price &&
                            Math.floor(
                              booking?.discounted_price?.final_total_price
                            ) - Math?.floor(booking?.redcash_discounted_amount)}
                          /-
                        </h6>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <Grid container spacing={4} direction="row" justify="flex-start" alignItems="center" style={{ margin: "1rem auto" }}>
                <Grid direction="row" justify="space-around" alignItems="center" container item xs={12} md={6} spacing={3}>
                  <Grid item xs={12} md={5}>
                    <p>AgentName</p>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      name="agentName"
                      type="text"
                      value={(booking?.agent && booking?.agent.name) || ""}
                      disabled
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
                <Grid direction="row" justify="space-around" alignItems="center" container item xs={12} md={6} spacing={3}>
                  <Grid item xs={12} md={5}>
                    <p>Phlebo</p>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      name="phlebo"
                      type="text"
                      value={(booking.phlebo && booking.phlebo.name) || ""}
                      disabled
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
                <Grid direction="row" justify="space-around" alignItems="center" container item xs={12} md={6} spacing={3}>
                  <Grid item xs={12} md={5}>
                    <p>
                      Mobile Number
                      {/* <CallIcon
                                                onClick={() => handleCallClick(bookingId, "call")}
                                            /> */}
                    </p>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      name="customer_phonenumber"
                      type="text"
                      value={booking?.customer_phonenumber || ""}
                      disabled
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
                <Grid direction="row" justify="space-around" alignItems="center" container item xs={12} md={6} spacing={3}>
                  <Grid item xs={12} md={5}>
                    <p>
                      Alternate Mobile Number
                      {/* <CallIcon
                                                onClick={() => handleCallClick(bookingId, "contact")}
                                            /> */}
                    </p>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      name="customer_alternatenumber"
                      type="text"
                      value={booking?.customer_altphonenumber || ""}
                      disabled
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
                <Grid direction="row" justify="space-around" alignItems="center" container item xs={12} md={6} spacing={3}>
                  <Grid item xs={12} md={5}>
                    <p>Email</p>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      name="customer_email"
                      type="email"
                      disabled
                      value={booking.customer_email || ""}
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
                <Grid direction="row" justify="space-around" alignItems="center" container item xs={12} md={6} spacing={3}>
                  <Grid item xs={12} md={5}>
                    <p>
                      Whatsapp Number
                      {/* <CallIcon
                                                onClick={() => handleCallClick(bookingId, "whatsapp")}
                                            /> */}
                    </p>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      name="customer_whatsapppnumber"
                      type="text"
                      disabled
                      value={booking?.customer_whatsapppnumber || ""}
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
                <Grid direction="row" justify="space-around" alignItems="center" container item xs={12} md={6} spacing={3}>
                  <Grid item xs={12} md={5}>
                    <p>Aadhaar Number</p>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      name="customer_aadhar"
                      type="text"
                      disabled
                      value={booking?.customer_aadhar || ""}
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
                <Grid direction="row" justify="space-around" alignItems="center" container item xs={12} md={6} spacing={3}>
                  <Grid item xs={12} md={5}>
                    <p>Passport Number</p>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      name="Passport"
                      type="text"
                      disabled
                      value={booking?.passport_number || ""}
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
                {/* <Grid direction="row" justify="space-around" alignItems="center" container item xs={12} md={6} spacing={3}>
                                    <Grid item xs={12} md={5}>
                                        <p>Booking Source</p>
                                    </Grid>
                                    <Grid item xs={12} md={7}>
                                        <TextField
                                            name="agentName"
                                            type="text"
                                            value={(booking.partner && booking.partner.partner_name) || ""}
                                            disabled
                                            className="input"
                                            variant="outlined"
                                            style={{ margin: "0", width: "100%" }}
                                        />
                                    </Grid>
                                </Grid> */}
                <Grid direction="row" justify="space-around" alignItems="center" container item xs={12} md={6} spacing={3}>
                  <Grid item xs={12} md={5}>
                    <p>Finance Amount</p>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      name="financeamount"
                      type="text"
                      value={booking?.finance_amount || ""}
                      disabled
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
                <Grid direction="row" justify="space-around" alignItems="center" container item xs={12} md={6} spacing={3}>
                  <Grid item xs={12} md={5}>
                    <p>Finance Status</p>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      name="financestatus"
                      type="text"
                      value={booking?.finance_status || ""}
                      disabled
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
                <Grid direction="row" justify="space-around" alignItems="center" container item xs={12} md={6} spacing={3}>
                  <Grid item xs={12} md={5}>
                    <p>Finance Remarks</p>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      name="financeremark"
                      type="text"
                      value={booking?.finance_remarks || ""}
                      disabled
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
                <Grid direction="row" justify="space-around" alignItems="center" container item xs={12} md={6} spacing={3}>
                  <Grid item xs={12} md={5}>
                    <p>Bill Id</p>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      name="billid"
                      type="text"
                      value={(booking?.bill_id && booking?.bill_id) || "NA"}
                      disabled
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
                <Grid direction="row" justify="space-around" alignItems="center" container item xs={12} md={6} spacing={3}>
                  <Grid item xs={12} md={5}>
                    <p>Patient Id</p>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      name="patientid"
                      type="text"
                      value={(booking.patientId && booking.patientId) || "NA"}
                      disabled
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
                <Grid direction="row" justify="space-around" alignItems="center" container item xs={12} md={6} spacing={3}>
                  <Grid item xs={12} md={5}>
                    <p>Report Status</p>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      name="patientid"
                      type="text"
                      value={(booking.report_status && booking.report_status)}
                      disabled
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={4}
                direction="row"
                justify="flex-start"
                alignItems="center"
                style={{ margin: "1rem auto" }}
              >
                <Grid item xs={12}>
                  <h3
                    style={{
                      borderTop: "dotted 1px #cccc",
                      paddingTop: "10px",
                      fontSize: "24px",
                      fontWeight: "bold",
                    }}
                  >
                    Address
                  </h3>
                </Grid>

                <Grid
                  direction="row"
                  justify="space-around"
                  alignItems="center"
                  container
                  item
                  xs={12}
                  md={6}
                  spacing={3}
                >
                  <Grid item xs={12} md={5}>
                    <p>House No./Plot No./Flat No./Door No./Shop/ c/o</p>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      name="customer_address"
                      type="text"
                      value={
                        (booking?.customer_address &&
                          booking?.customer_address) ||
                        ""
                      }
                      disabled
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  direction="row"
                  justify="space-around"
                  alignItems="center"
                  container
                  item
                  xs={12}
                  md={6}
                  spacing={3}
                >
                  <Grid item xs={12} md={5}>
                    <p>Apartment/Building /Colony/ Block/Sector/ Street/Gali/Road/Chawl</p>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      name="customer_address"
                      type="text"
                      value={
                        (booking?.address_line2 &&
                          booking?.address_line2) ||
                        ""
                      }
                      disabled
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  direction="row"
                  justify="space-around"
                  alignItems="center"
                  container
                  item
                  xs={12}
                  md={6}
                  spacing={3}
                >
                  <Grid item xs={12} md={5}>
                    <p>Landmark/Sublocality</p>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      name="customer_address"
                      type="text"
                      value={
                        (booking?.landmark &&
                          booking?.landmark) ||
                        ""
                      }
                      disabled
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  direction="row"
                  justify="space-around"
                  alignItems="center"
                  container
                  item
                  xs={12}
                  md={6}
                  spacing={3}
                >
                  <Grid item xs={12} md={5}>
                    <p>Map Landmark</p>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      name="customer_landmark"
                      type="text"
                      value={booking?.customer_landmark || ""}
                      disabled
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  direction="row"
                  justify="space-around"
                  alignItems="center"
                  container
                  item
                  xs={12}
                  md={6}
                  spacing={3}
                >
                  <Grid item xs={12} md={5}>
                    <p>City</p>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      name="city"
                      type="text"
                      value={
                        (pin_code.results &&
                          pin_code?.results?.length > 0 &&
                          pin_code?.results[0]?.city) ||
                        ""
                      }
                      disabled
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  direction="row"
                  justify="space-around"
                  alignItems="center"
                  container
                  item
                  xs={12}
                  md={6}
                  spacing={3}
                >
                  <Grid item xs={12} md={5}>
                    <p>Area</p>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      name="areaName"
                      type="text"
                      disabled
                      value={
                        (pin_code?.results &&
                          pin_code?.results?.length > 0 &&
                          pin_code?.results[0]?.city) ||
                        ""
                      }
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  direction="row"
                  justify="space-around"
                  alignItems="center"
                  container
                  item
                  xs={12}
                  md={6}
                  spacing={3}
                >
                  <Grid item xs={12} md={5}>
                    <p>Pincode</p>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      name="pincode"
                      type="text"
                      disabled
                      value={
                        (booking?.customer_areapincode &&
                          booking?.customer_areapincode?.pincode) ||
                        ""
                      }
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {booking?.additional_members && booking?.additional_members?.length > 0 && (
                <Grid container spacing={4} direction="row" justify="flex-start" alignItems="center" style={{ margin: "1rem auto" }}>
                  <Grid item xs={12}>
                    <Divider />
                    <h3 style={{ textAlign: "center", marginTop: "1rem" }}>Additional Members Details</h3>
                  </Grid>
                  {booking?.additional_members?.map((member: any, index: any) => {
                    return (
                      <React.Fragment key={index}>

                        <Grid direction="row" justify="space-around" alignItems="center" container item xs={12} md={6} spacing={3}>
                          <Grid item xs={12} md={5}>
                            <p>Collection Slot</p>
                          </Grid>
                          <Grid item xs={12} md={7}>
                            <TextField
                              name="houseNumber"
                              type="text"
                              value={
                                `${member?.collection_slot && moment(`${member?.collection_slot?.slot.split("-")[0]}`, ["HH.mm.ss"]).format("hh:mm A")}`
                                + " - " +
                                `${member?.collection_slot && moment(`${member?.collection_slot?.slot.split("-")[1]}`, ["HH.mm.ss"]).format("hh:mm A")}`
                              }
                              disabled
                              className="input"
                              variant="outlined"
                              style={{ margin: "0", width: "100%" }}
                            />
                          </Grid>
                        </Grid>
                        <Grid direction="row" justify="space-around" alignItems="center" container item xs={12} md={6} spacing={3}>
                          <Grid item xs={12} md={5}>
                            <p>Customer Name</p>
                          </Grid>
                          <Grid item xs={12} md={7}>
                            <TextField
                              name="houseNumber"
                              type="text"
                              value={member?.customer_name}
                              disabled
                              className="input"
                              variant="outlined"
                              style={{ margin: "0", width: "100%" }}
                            />
                          </Grid>
                        </Grid>
                        <Grid direction="row" justify="space-around" alignItems="center" container item xs={12} md={6} spacing={3}>
                          <Grid item xs={12} md={5}>
                            <p>Customer Age</p>
                          </Grid>
                          <Grid item xs={12} md={7}>
                            <TextField
                              name="houseNumber"
                              type="text"
                              value={member?.customer_age}
                              disabled
                              className="input"
                              variant="outlined"
                              style={{ margin: "0", width: "100%" }}
                            />
                          </Grid>
                        </Grid>
                        <Grid direction="row" justify="space-around" alignItems="center" container item xs={12} md={6} spacing={3}>
                          <Grid item xs={12} md={5}>
                            <p>Customer Gender</p>
                          </Grid>
                          <Grid item xs={12} md={7}>
                            <TextField
                              name="houseNumber"
                              type="text"
                              value={member?.customer_gender}
                              disabled
                              className="input"
                              variant="outlined"
                              style={{ margin: "0", width: "100%" }}
                            />
                          </Grid>
                        </Grid>
                        <Grid direction="row" justify="space-around" alignItems="center" container item xs={12} md={6} spacing={3}>
                          <Grid item xs={12} md={5}>
                            <p>Customer Aadhaar</p>
                          </Grid>
                          <Grid item xs={12} md={7}>
                            <TextField
                              name="houseNumber"
                              type="text"
                              value={member?.customer_aadhar}
                              disabled
                              className="input"
                              variant="outlined"
                              style={{ margin: "0", width: "100%" }}
                            />
                          </Grid>
                        </Grid>
                        <Grid direction="row" justify="space-around" alignItems="flex-start" container item xs={12} spacing={3}>
                          <Grid item xs={6} md={3}>
                            <p>Packages</p>
                          </Grid>
                          <Grid item xs={6} md={9}>
                            <div>
                              <ul>
                                {member?.packages && member?.packages.map((pack: any, index: any) => {
                                  return (
                                    <li key={index} style={{ margin: "0 0 1rem 0", textAlign: "left" }}>{pack?.name}, {pack?.price}</li>
                                  )
                                })}
                              </ul>
                            </div>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                      </React.Fragment>
                    )
                  })}
                </Grid>
              )}
              <Grid container spacing={4} direction="row" justify="flex-start" alignItems="center" style={{ margin: "1rem auto" }}>
                {booking?.payment_image && booking?.payment_image?.map((image: any) => {
                  return (
                    <Grid key={image?.id} item xs={12} sm={6} md={4} lg={3} spacing={3}>

                      <img src={image?.image} alt="payment" style={{ width: "100%", aspectRatio: "1/1" }} />
                    </Grid>
                  )
                })}
              </Grid>
            </>
          )}
        </Paper>



      </main>
    </>
  )
}

const mapStateToProps = (state: any) => ({
  booking: state.BulkUploadMasterReducer.booking,
  pin_code: state.BulkUploadMasterReducer.pin_code,
  booking_slots: state.BulkUploadMasterReducer.booking_slots,
  packageList: state.BulkUploadMasterReducer.packageList,
  tube_details: state.BulkUploadMasterReducer.tube_details,
  additional_booking: state.BulkUploadMasterReducer.additional_booking,
  bookingByBarcode: state.BulkUploadMasterReducer.bookingByBarcode,
  paymentInformation: state.BulkUploadMasterReducer.paymentInformation,
  reportStatus: state.BulkUploadMasterReducer.reportStatus,
  reportDownload: state.BulkUploadMasterReducer.reportDownload,
  loading: state.BulkUploadMasterReducer.loading,
})

export default connect(mapStateToProps, {
  getBookingById,
  getPinCode,
  clickToCallBooking,
  updateBooking,
  resampleBooking,

  getAvailableSlots,
  getPackage,
  updateAdditionalBooking,
  getSmsReportSend,

  getPaymentInfo,
  getReportDownload,
  getReportStatus
})(PhleboAdminViewBooking);
