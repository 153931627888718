import {
  ACTIONS,
  PhleboAdminTypes,
} from "../interfaces/actionTypes/PhleboAdmin";

type InitialState = {
  pin_code: Array<any>;
  cities: Array<any>;
  collectionSlot: Array<any>;
  areaList: Array<any>;
  agentsList: Array<any>;
  phleboList: Array<any>;
  packageList: Array<any>;
  partnerList: Array<any>;
  sampleJourneyComments: Object;
  couponList: Array<any>;
  additionMember: Array<any>;
  signature: Array<any>;
  createBooking: Object;
  createPhlebo: Object;
  additional_booking: Object;
  booking: Object;
  todays_positive_calls: Object;
  lead_details: Object;
  leadById: Object;
  leads: Array<any>;
  bookingList: Array<any>;
  comment: any;
  mapBooking: Array<any>;
  data: any;
  users: any;
  bookingsComment: any;
  phleboComment: any;
  communicationWhatsapp: Array<any>;
  zoneComment: any;
  phlebosComment: any;
  lead_id: number;
  read_call_details: object;
  all_recordings: object;
  whatsappComments: object;
  whatsappCommentsByLead: object;
  callStats: object;
  complaint: object;
  complaintNew: Object;
  complaints: object;
  lead_source: any;
  booking_slots: Array<any>;
  search_results: object;
  call_status: object;
  addRemove: Object;
  all_lead: object;
  call_transfer: object;
  routesData: Array<any>;
  bookingIdLoader: boolean;
  user_panel: object;
  agentBYUserGroup: object;
  allBookings: object;
  loading: boolean;
  zoneList: Object;
  zone: Object;
  timeslotsList: Object;
  attendenceList: Object;
  disposition: Array<any>;
  currentBooking: Object;
  cityWisePhlebos: Object;
  riderList: Array<any>;
  sameNumberBooking: boolean;
  area: Object;
  updatePhlebos: Object;
  phleboPhone: Object;
  verificationBookingDetails: Array<any>;
  paymentSendLink: Array<any>;
  paymentReSendLink: Array<any>;
  paymentCancelLink: Array<any>;
  centerInfo: Array<any>;
  reportDownload: Array<any>;
  paymentInformation: Array<any>;
  firebaseNotification: Array<any>;
  sampleHandOverData: Array<any>;
  notificationMessage: Array<any>;
  reportStatus: Array<any>;
  smsReportSend: Array<any>;
  userDetails: Array<any>;
  areaComments: Array<any>;
  unmaskedNumber: Array<any>;
  labDetails: Array<any>;
  qrcode: Array<any>;
  phleboBatchTube: Array<any>;
  bookingJourney: Array<any>;
  checkGeoFenceStatus: boolean;
  autoAssignStatus: boolean;
  verifiedbylist: Array<any>;
  redCoin: Array<any>;
  mapmyindiaAccessToken: Object;
  createPilotBooking: Object;
  subCenter: Array<any>;
  phleboCurrentLocation: Array<any>;
  imagingTimeSlots: Array<any>;
  createIMGBooking: object;
  multiplePackage: Array<any>;
  bookingoverview: Array<any>;
  displayCities: Array<any>;
  geoFencesMapMyIndia: Array<any>;
  addressType: Array<any>;
  packageALlTube: Array<any>;
  unScannedTube: Array<any>;
  ccpackageList: Array<any>;
  phlebo_rider_due: Array<any>;
  ceCenterForCC: Array<any>;
  ticketData: Array<any>;
  ticketCategories: Array<any>;
  ticketSubCategories: Array<any>;
  redtechcomment: Array<any>;
  batchbookingamount: Array<any>;
  batchPayment: Object;
  hivpackageList: Array<any>;
  biomarkerScreening: Array<any>;
  biomarkerUltasound: Array<any>;
  markerAdditionMember: Array<any>;
  labCredential: Object;
  onlineBookingList: Object;
  geoLoading: boolean;
  geoCheckError: string;
  phleboridermap: Array<any>;
  bookingComplaints: Array<any>;
  ticketComments: Array<any>;
  bookingListLoader: boolean;
  phleboLeaveList: Array<any>;
  phleboUnderRm: Array<any>;
  phleboAssignUnassignv2List: Object;
  locationVerified: Array<any>;
  phleboAutoAssign: Array<any>;
  sendLocationOnWhatsapp: Array<any>;
  addressDetailsLatLong: Array<any>;
  geoFencesMapMyIndiaForCustomer: Array<any>;
  geoLoadingforcustomer: boolean;
  geoCheckErrorForCustomer: string;
  crmLeadAddress: Array<any>;
  leadAddressLoading: boolean;
  crmPostLeadAddress: Array<any>;
  whatsappMessageStatus: Array<any>;
  referralCurrentBalance: Array<any>;
  couponReferralValidation: Array<any>;
  leadSubscriptionsData: Array<any>;
  routeManagers: Array<any>;
  zoneDetails: Array<any>;
  whatsappTemplate: Array<any>;
  newLeadDetails: Array<any>;
  customerSummaryUsingUHID: Array<any>;
  customerDetailsUsingUHID: Array<any>;
  latestLeadAddress: Array<any>;
  loadingSummary: boolean;
  dispositionList: Array<any>;
  bookinSubDisposition: Array<any>;
  loadingDetails: boolean;
  leadStatus: Array<any>;
  salesLeadList: Array<any>;
  agentsListnewdata: Array<any>;
  all_lead_tatataly: object;
  teamLeadList: Array<any>;
  ltvLead: Object;
  prescription: Array<any>;
  leadLoading: boolean;
  language: Array<any>;
  phleboLeaveLog: Array<any>;
};

const initialState: InitialState = {
  pin_code: [],
  cities: [],
  batchbookingamount: [],
  collectionSlot: [],
  areaList: [],
  agentsList: [],
  phleboList: [],
  packageList: [],
  partnerList: [],
  couponList: [],
  signature: [],
  createBooking: {},
  createPhlebo: {},
  additional_booking: {},
  booking: {},
  lead_details: {},
  leadById: {},
  todays_positive_calls: {},
  leads: [],
  bookingList: [],
  sampleJourneyComments: {},
  additionMember: [],
  comment: {},
  data: {},
  users: {},
  loading: false,
  read_call_details: {},
  all_recordings: {},
  whatsappComments: {},
  whatsappCommentsByLead: {},
  callStats: {},
  area: {},
  updatePhlebos: {},
  complaint: {},
  complaintNew: {},
  ceCenterForCC: [],
  complaints: {},
  bookingsComment: {},
  phleboComment: {},
  phlebosComment: {},
  zoneComment: {},
  lead_id: 0,
  lead_source: {},
  booking_slots: [],
  search_results: {},
  call_status: {},
  all_lead: {},
  call_transfer: {},
  agentBYUserGroup: {},
  sameNumberBooking: false,
  allBookings: {},
  user_panel: {},
  zoneList: {},
  zone: {},
  phleboBatchTube: [],
  phleboPhone: {},
  timeslotsList: {},
  attendenceList: {},
  currentBooking: {},
  cityWisePhlebos: {},
  verificationBookingDetails: [],
  paymentSendLink: [],
  paymentReSendLink: [],
  routesData: [],
  paymentCancelLink: [],
  sampleHandOverData: [],
  centerInfo: [],
  reportDownload: [],
  paymentInformation: [],
  addRemove: {},
  firebaseNotification: [],
  notificationMessage: [],
  reportStatus: [],
  userDetails: [],
  createPilotBooking: {},
  smsReportSend: [],
  areaComments: [],
  unmaskedNumber: [],
  labDetails: [],
  qrcode: [],
  bookingJourney: [],
  checkGeoFenceStatus: false,
  autoAssignStatus: false,
  verifiedbylist: [],
  redCoin: [],
  mapmyindiaAccessToken: {},
  subCenter: [],
  whatsappTemplate: [],
  riderList: [],
  mapBooking: [],
  phleboCurrentLocation: [],
  imagingTimeSlots: [],
  createIMGBooking: {},
  multiplePackage: [],
  displayCities: [],
  communicationWhatsapp: [],
  bookingoverview: [],
  phleboAutoAssign: [],
  geoFencesMapMyIndia: [],
  addressType: [],
  packageALlTube: [],
  unScannedTube: [],
  ccpackageList: [],
  phlebo_rider_due: [],
  ticketData: [],
  ticketCategories: [],
  ticketSubCategories: [],
  redtechcomment: [],
  hivpackageList: [],
  biomarkerScreening: [],
  biomarkerUltasound: [],
  markerAdditionMember: [],
  batchPayment: [],
  labCredential: {},
  onlineBookingList: {},
  geoLoading: false,
  phleboridermap: [],
  geoCheckError: "",
  bookingComplaints: [],
  customerSummaryUsingUHID: [],
  customerDetailsUsingUHID: [],
  ticketComments: [],
  disposition: [],
  phleboLeaveList: [],
  bookingListLoader: false,
  phleboUnderRm: [],
  phleboAssignUnassignv2List: {},
  locationVerified: [],
  sendLocationOnWhatsapp: [],
  addressDetailsLatLong: [],
  geoFencesMapMyIndiaForCustomer: [],
  geoLoadingforcustomer: false,
  geoCheckErrorForCustomer: "",
  crmLeadAddress: [],
  leadAddressLoading: false,
  crmPostLeadAddress: [],
  whatsappMessageStatus: [],
  referralCurrentBalance: [],
  couponReferralValidation: [],
  bookingIdLoader: false,
  leadSubscriptionsData: [],
  routeManagers: [],
  zoneDetails: [],
  newLeadDetails: [],
  loadingSummary: false,
  loadingDetails: false,
  dispositionList: [],
  bookinSubDisposition: [],
  latestLeadAddress: [],
  leadStatus: [],
  salesLeadList: [],
  agentsListnewdata: [],
  all_lead_tatataly: {},
  teamLeadList: [],
  ltvLead: {},
  prescription: [],
  leadLoading: false,
  language: [],
  phleboLeaveLog: [],
};

const CustomerLeadReducer = (
  state = initialState,
  action: PhleboAdminTypes
) => {
  switch (action.type) {
    case ACTIONS.GET_PIN_CODE:
      return {
        ...state,
        pin_code: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BATCH_BOOKING_AMOUNT:
      return {
        ...state,
        batchbookingamount: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ASSIGNED_UNASSIGNED_BOOKING:
      return {
        ...state,
        allBookings: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PHLEBO_CURRENT_LOCATION:
      return {
        ...state,
        phleboCurrentLocation: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PHLEBOS_LIST:
      return {
        ...state,
        phleboList: action.payload,
        loading: false,
      };
    case ACTIONS.CREATE_AREA:
      return {
        ...state,
        area: action.payload,
        loading: false,
      };
    case ACTIONS.UPLOAD_FILE:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case ACTIONS.UPDATE_AREA:
      return {
        ...state,
        area: action.payload,
        loading: false,
      };
    case ACTIONS.UPDATE_PHLEBOS:
      return {
        ...state,
        updatePhlebos: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CITIES:
      return {
        ...state,
        cities: action.payload,
        loading: false,
      };
    case ACTIONS.GET_COLLECTION_SLOT:
      return {
        ...state,
        collectionSlot: action.payload,
        loading: false,
      };
    case ACTIONS.GET_AREA:
      return {
        ...state,
        areaList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_POSTIVE_CALLS_COMMENTS:
      return {
        ...state,
        todays_positive_calls: action.payload,
        loading: false,
      };
    case ACTIONS.SET_COMMENTS:
      return {
        ...state,
        comment: action.payload,
        loading: false,
      };
    case ACTIONS.ADD_POSTIVE_CALLS_COMMENT:
      return {
        ...state,
        todays_positive_calls: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LEAD_DETAILS:
      return {
        ...state,
        lead_details: action.payload,
        loading: false,
      };
    case ACTIONS.POST_ADD_REMOVE_TEST:
      return {
        ...state,
        addRemove: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LEADS:
      return {
        ...state,
        leads: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PHLEBO_AUTO_ASSIGN:
      return {
        ...state,
        phleboAutoAssign: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LEAD_BY_ID:
      return {
        ...state,
        leadById: action.payload,
        loading: false,
      };

    case ACTIONS.GET_BOOKING_OVERVIEW:
      return {
        ...state,
        bookingoverview: action.payload,
        loading: false,
      };
    case ACTIONS.LEAD_DETAILS_RESET:
      return {
        ...state,
        leadById: {},
        lead_details: {},
        communicationWhatsapp: [],
        loading: false,
        leads: [],
        crmPostLeadAddress: [],
      };
    case ACTIONS.GET_CE_CENTER_FOR_CC:
      return {
        ...state,
        ceCenterForCC: action.payload,
        loading: false,
      };
    case ACTIONS.GET_SAMPLE_HANDOVER_DATA:
      return {
        ...state,
        sampleHandOverData: action.payload,
        loading: false,
      };
    case ACTIONS.GET_AGENTS:
      return {
        ...state,
        agentsList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ROUTES:
      return {
        ...state,
        routesData: action.payload,
        loading: false,
      };
    case ACTIONS.GET_AGENTS_BY_USERGROUP:
      return {
        ...state,
        agentBYUserGroup: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PACKAGES:
      return {
        ...state,
        packageList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PARTNERS:
      return {
        ...state,
        partnerList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_COUPONS:
      return {
        ...state,
        couponList: action.payload,
        loading: false,
      };
    case ACTIONS.CREATE_BOOKING:
      return {
        ...state,
        createBooking: action.payload,
        loading: false,
      };
    case ACTIONS.CREATE_NEW_PHLEBO:
      return {
        ...state,
        createPhlebo: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BOOKING_LIST:
      return {
        ...state,
        bookingList: action.payload,
        bookingListLoader: false,
      };
    case ACTIONS.GET_BOOKING_BY_ID:
      return {
        ...state,
        booking: action.payload,
        bookingIdLoader: false,
      };
    case ACTIONS.GET_MAP_BOOKING_LIST:
      return {
        ...state,
        mapBooking: action.payload,
        loading: false,
      };
    case ACTIONS.GET_RIDER_LIST:
      return {
        ...state,
        riderList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CC_PACKAGES:
      return {
        ...state,
        ccpackageList: action.payload,
        loading: false,
      };
    case ACTIONS.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case ACTIONS.CREATE_CALL:
      return {
        ...state,
        loading: false,
        lead_id: action.payload,
      };
    case ACTIONS.GET_RECORDINGS:
      return {
        ...state,
        all_recordings: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LEAD_SOURCE:
      return {
        ...state,
        lead_source: action.payload,
        loading: false,
      };
    case ACTIONS.SEARCH_CALL:
      return {
        ...state,
        search_results: action.payload,
        loading: false,
      };
    case ACTIONS.GET_RED_TECH_COMMENT:
      return {
        ...state,
        redtechcomment: action.payload,
        loading: false,
      };
    case ACTIONS.ADD_COMMENT:
      return {
        ...state,
        comments: action.payload,
        loading: false,
      };
    case ACTIONS.BOOKING_RESET:
      return {
        ...state,
        createBooking: {},
        bookingList: [],
        batchbookingamount: [],
        todays_positive_calls: {},
        loading: false,
        redCoin: [],
        booking: {},
        booking_slots: {},
        lead_details: {},
        referralCurrentBalance: [],
        bookingIdLoader: false,
      };
    case ACTIONS.GET_SAMPLE_JOURNEY_COMMENTS:
      return {
        ...state,
        sampleJourneyComments: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BOOKINGS_COMMENTS:
      return {
        ...state,
        bookingsComment: action.payload,
        loading: false,
      };
    case ACTIONS.ADD_BOOKINGS_COMMENT:
      return {
        ...state,
        bookingsComment: action.payload,
        loading: false,
      };
    case ACTIONS.GET_WHATSAPP_COMMENTS:
      return {
        ...state,
        whatsappComments: action.payload,
        loading: false,
      };
    case ACTIONS.GET_WHATSAPP_COMMENTS_BY_LEAD:
      return {
        ...state,
        whatsappCommentsByLead: action.payload,
        loading: false,
      };
    case ACTIONS.SEND_WHATSAPP_COMMENTS:
      return {
        ...state,
        whatsappComments: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CALL_STATS:
      return {
        ...state,
        callStats: action.payload,
        loading: false,
      };
    case ACTIONS.RAISE_COMPLAINT:
      return {
        ...state,
        complaint: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ADDRESS_TYPE_SEATING:
      return {
        ...state,
        addressType: action.payload,
        loading: false,
      };
    case ACTIONS.GET_COMPLAINTS:
      return {
        ...state,
        complaints: action.payload,
        loading: false,
      };
    case ACTIONS.ASSIGN_DOCTOR:
      return {
        ...state,
        success: true,
        loading: false,
      };
    case ACTIONS.SEND_FEEDBACK:
      return {
        ...state,
        success: true,
        loading: false,
      };
    case ACTIONS.CREATE_PILOT_BOOKING:
      return {
        ...state,
        createPilotBooking: action.payload,
        loading: false,
      };
    case ACTIONS.GET_AVAILABLE_SLOTS:
      return {
        ...state,
        booking_slots: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ALL_LEADS:
      return {
        ...state,
        all_lead: action.payload,
        leadLoading: false,
      };
    case ACTIONS.GET_PANEL_USERS:
      return {
        ...state,
        user_panel: action.payload,
        loading: false,
      };
    case ACTIONS.LEAD_TRANSFER_BY_COMMA:
      return {
        ...state,
        all_lead: action.payload,
        loading: false,
      };
    case ACTIONS.CREATE_ADDITIONAL_BOOKING:
      return {
        ...state,
        additional_booking: action.payload,
        loading: false,
      };
    case ACTIONS.UPDATE_ADDITIONAL_BOOKING:
      return {
        ...state,
        additional_booking: action.payload,
        loading: false,
      };
    case ACTIONS.CREATE_ZONE:
      return {
        ...state,
        zone: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PHLENO_BATCH_TUBE:
      return {
        ...state,
        phleboBatchTube: action.payload,
        loading: false,
      };
    case ACTIONS.UPDATE_ZONE:
      return {
        ...state,
        zone: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ZONE:
      return {
        ...state,
        zoneList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_TIMESLOTS:
      return {
        ...state,
        timeslotsList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ATTENDENCE:
      return {
        ...state,
        attendenceList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PHLEBO_COMMENTS:
      return {
        ...state,
        phleboComment: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ZONE_COMMENTS:
      return {
        ...state,
        zoneComment: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CURRENT_DAY_BOOKING:
      return {
        ...state,
        currentBooking: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CITY_WISE_PHLEBOS:
      return {
        ...state,
        cityWisePhlebos: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BOOKING_JOURNEY_DETAILS:
      return {
        ...state,
        bookingJourney: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PHLEBO_BY_PHONE:
      return {
        ...state,
        phleboPhone: action.payload,
        loading: false,
      };
    case ACTIONS.GET_VERIFICATION_BOOKING_DETAILS:
      return {
        ...state,
        verificationBookingDetails: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PAYMENT_SEND_LINK:
      return {
        ...state,
        paymentSendLink: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PAYMENT_RESEND_LINK:
      return {
        ...state,
        paymentReSendLink: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PAYMENT_CANCEL_LINK:
      return {
        ...state,
        paymentCancelLink: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CENTER_INFORMATION:
      return {
        ...state,
        centerInfo: action.payload,
        loading: false,
      };
    case ACTIONS.GET_REPORT_DOWNLOAD:
      return {
        ...state,
        reportDownload: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PAYMENT_INFORMATION:
      return {
        ...state,
        paymentInformation: action.payload,
        loading: false,
      };
    case ACTIONS.GET_FIREBASE_NOTIFICATION:
      return {
        ...state,
        firebaseNotification: action.payload,
        loading: false,
      };
    case ACTIONS.GET_NOTIFICATION_MESSAGE:
      return {
        ...state,
        notificationMessage: action.payload,
        loading: false,
      };
    case ACTIONS.GET_REPORT_STATUS:
      return {
        ...state,
        reportStatus: action.payload,
        loading: false,
      };
    case ACTIONS.GET_USER_DETAILS:
      return {
        ...state,
        userDetails: action.payload,
        loading: false,
      };
    case ACTIONS.GET_SEND_LOCATION_REQUEST:
      return {
        ...state,
        communicationWhatsapp: action.payload,
        loading: false,
      };
    case ACTIONS.GET_SMS_REPORT_SEND:
      return {
        ...state,
        smsReportSend: action.payload,
        loading: false,
      };
    case ACTIONS.GET_AREA_COMMENTS:
      return {
        ...state,
        areaComments: action.payload,
        loading: false,
      };
    case ACTIONS.SAME_NUMBER_BOOKING:
      return {
        ...state,
        sameNumberBooking: action.payload,
        loading: false,
      };
    case ACTIONS.GET_UNMASKED_NUMBER:
      return {
        ...state,
        unmaskedNumber: action.payload,
        loading: false,
      };
    case ACTIONS.CHECK_GEOFENCE_AREA:
      return {
        ...state,
        checkGeoFenceStatus: action.payload,
        loading: false,
      };
    case ACTIONS.AUTO_ASSIGN_BOOKING:
      return {
        ...state,
        autoAssignStatus: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LAB_DETAILS:
      return {
        ...state,
        labDetails: action.payload,
        loading: false,
      };
    case ACTIONS.GET_QRCODE_DATA:
      return {
        ...state,
        qrcode: action.payload,
        loading: false,
      };
    case ACTIONS.GET_VERIFIED_BY_LIST:
      return {
        ...state,
        verifiedbyList: action.payload,
      };

    case ACTIONS.GET_ADDITIONAL_BOOKING:
      return {
        ...state,
        additionMember: action.payload,
        loading: false,
      };
    case ACTIONS.GET_SIGNATURE:
      return {
        ...state,
        signature: action.payload,
        loading: false,
      };
    case ACTIONS.GET_RED_COIN:
      return {
        ...state,
        redCoin: action.payload,
        loading: false,
      };
    case ACTIONS.GET_SUB_CENTER_INFORMATION:
      return {
        ...state,
        subCenter: action.payload,
        loading: false,
      };
    case ACTIONS.GET_MAMPMYINDIA_ACCESS_TOKEN:
      return {
        ...state,
        mapmyindiaAccessToken: action.payload,
        loading: false,
      };
    case ACTIONS.GET_IMAGING_TIMESLOTS:
      return {
        ...state,
        imagingTimeSlots: action.payload,
        loading: false,
      };
    case ACTIONS.CREATE_IMAGING_BOOKING:
      return {
        ...state,
        createIMGBooking: action.payload,
        loading: false,
      };
    case ACTIONS.GET_MULTIPLE_PACKAGES:
      return {
        ...state,
        multiplePackage: action.payload,
        loading: false,
      };
    case ACTIONS.GET_DISPLAY_CITIES:
      return {
        ...state,
        displayCities: action.payload,
        loading: false,
      };
    case ACTIONS.CHECK_GEOFENCE_AREA_MAMPMYINDIA:
      return {
        ...state,
        geoFencesMapMyIndia: action.payload,
        // loading: false,
        geoLoading: false,
        geoCheckError: "",
      };
    case ACTIONS.GET_PACKAGE_ALL_TUBE:
      return {
        ...state,
        unScannedTube: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PHLEBO_RIDER_DUE_AMOUNT:
      return {
        ...state,
        phlebo_rider_due: action.payload,
        loading: false,
      };
    case ACTIONS.GET_TICKET_DATA:
      return {
        ...state,
        ticketData: action.payload,
        loading: false,
      };
    case ACTIONS.GET_TICKET_CATEGORIES:
      return {
        ...state,
        ticketCategories: action.payload,
        loading: false,
      };

    case ACTIONS.GET_TICEKT_SUB_CATEGORIES:
      return {
        ...state,
        ticketSubCategories: action.payload,
        loading: false,
      };
    case ACTIONS.GET_HIV_PACKAGES:
      return {
        ...state,
        hivpackageList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BIOMARKER_SCREENING:
      return {
        ...state,
        biomarkerScreening: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BIOMARKER_ULTASOUND:
      return {
        ...state,
        biomarkerUltasound: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ADDITIONAL_MEMBER:
      return {
        ...state,
        markerAdditionMember: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BATCH_PAYMENT:
      return {
        ...state,
        batchPayment: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ONLINE_BOOKING_LIST:
      return {
        ...state,
        onlineBookingList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LAB_CREDENTIAL:
      return {
        ...state,
        labCredential: action.payload,
        loading: false,
      };
    case ACTIONS.SET_GEO_LOADING:
      return {
        ...state,
        geoLoading: action.payload,
      };
    case ACTIONS.SET_GEO_CHECK_ERROR:
      return {
        ...state,
        geoCheckError: action.payload,
      };
    case ACTIONS.GET_PHLEBO_RIDER:
      return {
        ...state,
        phleboridermap: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BOOKING_COMPLAINTS:
      return {
        ...state,
        bookingComplaints: action.payload,
        loading: false,
      };
    case ACTIONS.GET_TICKET_COMMENTS:
      return {
        ...state,
        ticketComments: action.payload,
        loading: false,
      };
    case ACTIONS.UPDATE_DISPOSITION:
      return {
        ...state,
        disposition: action.payload,
        loading: false,
      };
    case ACTIONS.SET_ALL_BOOKING_LOADING:
      return {
        ...state,
        bookingListLoader: action.payload,
      };
    case ACTIONS.BIOMARKER_RESET:
      return {
        ...state,
        biomarkerScreening: {},
      };
    case ACTIONS.RAISE_NEW_RAISE_TICEKT_DISPOSITION:
      return {
        ...state,
        complaintNew: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PHLEBO_LEAVE_LIST:
      return {
        ...state,
        phleboLeaveList: action.payload,
      };
    case ACTIONS.GET_PHLEBO_LIST_UNDER_ROUTE_MANAGER:
      return {
        ...state,
        phleboUnderRm: action.payload,
      };
    case ACTIONS.TABLE_RESET:
      return {
        ...state,
        phleboLeaveList: [],
        loading: false,
      };
    case ACTIONS.GET_ASSIGNED_UNASSIGNED_BOOKING_PILOT_V2:
      return {
        ...state,
        phleboAssignUnassignv2List: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ADDRESS_DETAILS_LAT_LONG:
      return {
        ...state,
        addressDetailsLatLong: action.payload,
        loading: false,
      };
    case ACTIONS.SEND_LOCATION_REQUEST:
      return {
        ...state,
        sendLocationOnWhatsapp: action.payload,
        loading: false,
      };
    case ACTIONS.LOCATION_VERIFIED:
      return {
        ...state,
        locationVerified: action.payload,
        loading: false,
      };
    case ACTIONS.CHECK_GEOFENCE_AREA_MAMPMYINDIA_FOR_CUSTOMER_ADDRESS:
      return {
        ...state,
        geoFencesMapMyIndiaForCustomer: action.payload,
        // loading: false,
        geoLoadingforcustomer: false,
        geoCheckErrorForCustomer: "",
      };
    case ACTIONS.SET_GEO_LOADING_FOR_CUSTOMER:
      return {
        ...state,
        geoLoadingforcustomer: action.payload,
      };
    case ACTIONS.SET_GEO_ERROR_FOR_CUSTOMER:
      return {
        ...state,
        geoCheckErrorForCustomer: action.payload,
      };
    case ACTIONS.GET_CRM_LEAD_ADDRESS:
      return {
        ...state,
        crmLeadAddress: action.payload,
        loading: false,
      };
    case ACTIONS.SET_LEAD_ADDRESS_LOADER:
      return {
        ...state,
        leadAddressLoading: action.payload,
      };
    case ACTIONS.POST_CRM_LEAD_ADDRESS:
      return {
        ...state,
        crmPostLeadAddress: action.payload,
        leadAddressLoading: false,
      };
    case ACTIONS.GET_WHATSAPP_MESSAGE_STATUS:
      return {
        ...state,
        whatsappMessageStatus: action.payload,
        // loading: false,
      };
    case ACTIONS.SET_BOOKING_ID_LOADER:
      return {
        ...state,
        bookingIdLoader: action.payload,
      };
    case ACTIONS.GET_REFERRAL_CURRENT_BALANCE:
      return {
        ...state,
        referralCurrentBalance: action.payload,
        loading: false,
      };
    case ACTIONS.GET_COUPON_REFERRAL_VALIDATION:
      return {
        ...state,
        couponReferralValidation: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LEAD_SUBSCRIPTIONS_DATA:
      return {
        ...state,
        leadSubscriptionsData: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ROUTE_MANAGERS:
      return {
        ...state,
        routeManagers: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ZONE_DETAILS:
      return {
        ...state,
        zoneDetails: action.payload,
        loading: false,
      };
    case ACTIONS.GET_NEW_LEAD_DETAILS:
      return {
        ...state,
        newLeadDetails: action.payload,
      };
    case ACTIONS.LATEST_LEAD_ADDRESS:
      return {
        ...state,
        latestLeadAddress: action.payload,
      };
    case ACTIONS.CUSTOMER_SUMMARY_USING_UHID:
      return {
        ...state,
        customerSummaryUsingUHID: action.payload,
        loadingSummary: false,
      };
    case ACTIONS.CUSTOMER_DETAILS_USING_UHID:
      return {
        ...state,
        customerDetailsUsingUHID: action.payload,
        loadingDetails: false,
      };
    case ACTIONS.SET_LOADING_SUMMARY:
      return {
        ...state,
        loadingSummary: action.payload,
      };
    case ACTIONS.SET_LOADING_DETAILS:
      return {
        ...state,
        loadingDetails: action.payload,
      };
    case ACTIONS.GET_LEAD_DISPOSITION_LIST:
      return {
        ...state,
        dispositionList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BOOKING_SUB_DISPOSITION:
      return {
        ...state,
        bookinSubDisposition: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LEAD_STATUS:
      return {
        ...state,
        leadStatus: action.payload,
        loading: false,
      };
    case ACTIONS.GET_WHATSAPP_TEMPLATE:
      return {
        ...state,
        whatsappTemplate: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PANEL_USER_LIST_DATA_SALES_LEAD:
      return {
        ...state,
        salesLeadList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_AGENTS_NEW_DATA:
      return {
        ...state,
        agentsListnewdata: action.payload,
        loading: false,
      };
    case ACTIONS.LEAD_TRANSFER_TO_TATATALE:
      return {
        ...state,
        all_lead_tatataly: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PANEL_USER_LIST_DATA_TEAM_LEAD:
      return {
        ...state,
        teamLeadList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LTV_LEAD:
      return {
        ...state,
        ltvLead: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PRESCRIPTION_DATA:
      return {
        ...state,
        prescription: action.payload,
        loading: false,
      };
      case ACTIONS.SET_LEAD_LOADING:
        return {
          ...state,
          leadLoading: action.payload,
        };
      case ACTIONS.GET_LANGUAGE:
        return {
          ...state,
          language: action.payload,
          loading: false,
        };
      case ACTIONS.GET_PHLEBO_LEAVE_LOG:
        return {
          ...state,
          phleboLeaveLog: action.payload,
          loading: false,
        };
    default:
      return state;
  }
};

export default CustomerLeadReducer;
