import React, { useState, useEffect } from "react";
import clsx from "clsx";
import Snackbar from "@material-ui/core/Snackbar";
import { Link } from "react-router-dom";
import {
  createStyles,
  makeStyles,
  useTheme,
  Theme,
  fade,
} from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import BookIcon from "@mui/icons-material/Book";
import EqualizerRoundedIcon from "@material-ui/icons/EqualizerRounded";
import List from "@material-ui/core/List";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import TextsmsIcon from "@material-ui/icons/Textsms";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import {
  logout,
  panelUserLogout,
  getFirebaseNotification,
} from "../../actions/loginActions";
import { useDispatch } from "react-redux";
import HomeIcon from "@material-ui/icons/Home";
import {
  PhonelinkLock,
  Book,
  AddBoxTwoTone,
  CloudUploadRounded,
  EditOutlined,
  SettingsApplicationsRounded,
} from "@material-ui/icons";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { Button } from "@material-ui/core";
import {
  changePassword,
  getUserDetails,
  getNotificationMessage,
} from "../actions/EditPackageMasterAction";
import { ConfirmationNumberRounded } from "@material-ui/icons";
import HourglassFullIcon from "@material-ui/icons/HourglassFull";
import HistoryIcon from "@material-ui/icons/History";
import AssignmentLateIcon from "@material-ui/icons/AssignmentLate";
import DeckIcon from "@material-ui/icons/Deck";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import DialpadIcon from "@material-ui/icons/Dialpad";
import WorkIcon from "@material-ui/icons/Work";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import CollectionsBookmarkIcon from "@material-ui/icons/CollectionsBookmark";
import CameraRearIcon from "@material-ui/icons/CameraRear";
import BusinessIcon from "@material-ui/icons/Business";
import FilterCenterFocusIcon from "@material-ui/icons/FilterCenterFocus";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import Popover from "@material-ui/core/Popover";
import { PhoneAndroidRounded, WhatsApp } from "@material-ui/icons";
import AllInboxIcon from "@material-ui/icons/AllInbox";
import { useHistory } from "react-router";
import FastfoodIcon from "@material-ui/icons/Fastfood";
import TransferWithinAStationIcon from "@material-ui/icons/TransferWithinAStation";
// import { messaging } from "../../firebase";
import Fade from "@material-ui/core/Fade";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { connect } from "react-redux";
import InvertColorsIcon from "@material-ui/icons/InvertColors";
import { getToken, onMessageListener } from "../../firebase";
import DeveloperModeIcon from "@material-ui/icons/DeveloperMode";
import ThumbsUpDownIcon from "@material-ui/icons/ThumbsUpDown";
import QuizIcon from "@mui/icons-material/Quiz";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import AssessmentIcon from "@material-ui/icons/Assessment";
const drawerWidth = 240;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    title: {
      flexGrow: 1,
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "block",
      },
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: "none",
    },
    drawer: {
      color: "#fff",
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9) + 1,
      },
    },
    toolbar: {
      display: "flex",
      alignItems: "left",
      justifyContent: "flex-start",
      backgroundColor: "#924A91",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    dashbord: {
      color: "#fff",
      fontSize: "18px",
      fontWeight: "normal",
      padding: "20px 0px 10px 10px",
    },
    content: {
      width: "80%",
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    search: {
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      "&:hover": {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginLeft: 0,
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "auto",
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    inputRoot: {
      color: "inherit",
    },
    popover: {
      pointerEvents: "none",
    },
    paper: {
      padding: theme.spacing(1),
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: "12ch",
        "&:focus": {
          width: "20ch",
        },
      },
    },
  })
);

interface Props {
  getUserDetails: any;
  userDetails: any;
  getNotificationMessage: any;
  notificationMessage: any;
  passwordStatus: boolean;
}
const PhleboAdmin: React.FC<Props> = ({
  getUserDetails,
  getNotificationMessage,
  userDetails,
  notificationMessage,
  passwordStatus,
}) => {
  let dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [changePasswordModal, setchangePasswordModal] = React.useState(false);
  const [oldPassword, setOldPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [heights, setheights] = useState(window.innerHeight);
  const openPopover = Boolean(anchorEl);
  const [openRightMenu, setOpenRightMenu] = React.useState<boolean>(false);
  const audio = new Audio(
    "https://static.redcliffelabs.com/media/mixkit-modern-classic-door-bell-sound-113.mp3"
  );
  const [isTokenFound, setTokenFound] = useState(false);
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });
  const [openSnack, setOpenSnack] = useState<boolean>(false);
  const [anchorElUpdate, setAnchorElUpdate] = React.useState(null);
  const id = open ? "transitions-popper" : undefined;
  useEffect(() => {
    let token;

    async function tokenFunc() {
      token = await getToken(setTokenFound);
      if (token) {
        const body = {
          token,
        };
        dispatch(getFirebaseNotification(body));
      }
      return token;
    }

    tokenFunc();
  }, [setTokenFound]);

  onMessageListener()
    .then((payload) => {
      setShow(true);
      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
      });
    })
    .catch((err) => console.log("failed: ", err));

  const handleClick = (event: any) => {
    setOpenRightMenu(true);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setOpenRightMenu(false);
  };
  useEffect(() => {
    getUserDetails();
  }, []);
  useEffect(() => {
    if (!passwordStatus) {
      setchangePasswordModal(true);
    }
  }, [passwordStatus]);

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
    getNotificationMessage(`?is_read=false`);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const updatePassword = async () => {
    const body: any = {
      old_password: oldPassword,
      new_password: newPassword,
      confirm_password: confirmPassword,
    };
    await dispatch(changePassword(body));
    setchangePasswordModal(false);
    dispatch(logout());
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleNotification = () => {
    history.push("/dashboard/pm/inbox");
  };
  const handleLogout = () => {
    dispatch(panelUserLogout());
    dispatch(logout());
  };
  useEffect(() => {
    if (notification.body !== "") {
      setOpenSnack(true);
      audio.play();
    }
  }, [notification]);
  const handleSnackClose = () => {
    setOpenSnack(false);
  };
  const handleUpdateDetails = (event: any) => {
    setAnchorElUpdate(anchorElUpdate ? null : event.currentTarget);
  };
  return (
    <div className={classes.root}>
      <Snackbar
        // anchorOrigin={{ vertical, horizontal }}
        open={openSnack}
        onClose={handleSnackClose}
        message={notification.body}
      />
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar style={{ backgroundColor: "#924A91" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography className={classes.title} variant="h6" noWrap>
            Redcliffelabs
          </Typography>
          <div>
            <Button
              variant="text"
              onClick={(e) => handleClick(e)}
              size="medium"
              endIcon={<KeyboardArrowDownIcon />}
              style={{ color: "#fff" }}
            >
              {userDetails &&
                userDetails.profile &&
                userDetails.profile.username}
            </Button>
            <Menu
              id="fade-menu"
              open={openRightMenu}
              onClose={handleClose}
              TransitionComponent={Fade}
              anchorEl={anchorEl}
            >
              <MenuItem onClick={() => setchangePasswordModal(true)}>
                Change Password
              </MenuItem>
              <MenuItem onClick={() => handleLogout()}>Logout</MenuItem>
            </Menu>
          </div>
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-owns={openPopover ? "mouse-over-popover" : undefined}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            onClick={handleNotification}
            color="inherit"
          >
            <NotificationsActiveIcon />
          </IconButton>
          <IconButton>
            <DeveloperModeIcon
              aria-describedby={id}
              onClick={handleUpdateDetails}
            />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <Dialog
          open={changePasswordModal}
          onClose={() => setchangePasswordModal(false)}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Change Password</DialogTitle>
          <DialogContent>
            <TextField
              id="name"
              placeholder="Old Password"
              type="password"
              fullWidth
              style={{
                width: "100%",
                margin: "0.3rem auto",
                padding: "14px !important",
              }}
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
            />
            <TextField
              id="name"
              placeholder="New Password"
              type="password"
              fullWidth
              style={{
                width: "100%",
                margin: "0.3rem auto",
                padding: "14px !important",
              }}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <TextField
              id="name"
              placeholder="Confirm Password"
              type="password"
              fullWidth
              style={{
                width: "100%",
                margin: "0.3rem auto",
                padding: "14px !important",
              }}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => updatePassword()} color="primary">
              Update
            </Button>
            <Button
              onClick={() => setchangePasswordModal(false)}
              color="primary"
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <div className={classes.toolbar}>
          <span className={classes.dashbord}>Dashboard</span>
          <IconButton onClick={handleDrawerClose} style={{ color: "#fff" }}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
       
        <List>
          <Link to="/dashboard/epm/packagemanagement">
            <ListItem button>
              <Tooltip title="Package Management" arrow>
                <ListItemIcon>
                  <ManageAccountsIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Package Management" />
            </ListItem>
          </Link>
        </List>
        <Divider />
        <List>
          <ListItem button onClick={() => handleLogout()}>
            <Tooltip title="Logout" arrow>
              <ListItemIcon>
                <ExitToAppIcon style={{ color: "#924A91" }} />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </Drawer>
      {notificationMessage &&
      notificationMessage?.results &&
      notificationMessage?.results?.length > 0
        ? notificationMessage?.results.map((data: any, index: any) => {
            return (
              <Popover
                id="mouse-over-popover"
                className={classes.popover}
                classes={{
                  paper: classes.paper,
                }}
                open={openPopover}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
              >
                <Typography>{index + 1 + "." + data?.notification}</Typography>
              </Popover>
            );
          })
        : null}
    </div>
  );
};
const mapStateToProps = (state: any) => ({
  userDetails: state.EditPackageMasterReducer.userDetails,
  notificationMessage: state.EditPackageMasterReducer.notificationMessage,
  passwordStatus: state.loginReducer.passwordStatus,
});

export default connect(mapStateToProps, {
  getUserDetails,
  getNotificationMessage,
})(PhleboAdmin);
