import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from '@mui/material/Modal';
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { TableContainer, Table, Chip, TableHead, TableBody, TableRow, TableCell, Button, Paper, TextField, Grid, Select, MenuItem } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import {getSyncData } from '../../actions/productMangerAction'
let width = window.innerWidth;
let isMobile = width <= 500;


const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        marginTop: "2rem",
        padding: "0.5rem 2rem",
        width: "90%",
    },
    head: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "2rem",
    },
}));
interface Props {
    getSyncData: any;
}
type ModalProps = {
    setSyncOpenModal: Function;
    openSyncModal: boolean;
    syncId: any;
    getSyncData:any;
    bookingData:any;
    setSyncButton:any;
    getAllBookings:any;
    pageNumber:any;


};

const CreatePhlebo: React.FC<ModalProps> = ({
    setSyncOpenModal,
    openSyncModal,
    syncId,
    getSyncData,
    bookingData,
    setSyncButton,
    getAllBookings,
    pageNumber,

}) => {
    const classes = useStyles();
    const timer = useRef<any>(0)
    const [passportNumber, setPassportNumber] = useState<any>("")
    const [adharNumber, setAdharNumber] = useState<any>("")
    const [srfNumber, setSrfNumber] = useState<any>("")
    const [barcode, setBarcode] = useState<any>("")
    const [syncSubmit,setSyncSubmit]=useState<boolean>(false)
    const handleClose = () => {
        setSyncOpenModal(false);
    };

    const submitForm = async (e: any) => {
        e.preventDefault()
        const body = {
            customer_aadhar: adharNumber,
            passport_number: passportNumber,
            srf_number: srfNumber,
            barcode: barcode
        }
        setSyncSubmit(true)
        await getSyncData(`${syncId}/`,body)
        setTimeout(()=>{
            getAllBookings(pageNumber)
            setSyncSubmit(false)
        },1000)
        
        setSyncButton(true)
        setSyncOpenModal(false)
        

    }
    useEffect(()=>{
            setAdharNumber(bookingData.customer_aadhar)
            setPassportNumber(bookingData.passport_number)
       
    },[openSyncModal])
    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openSyncModal}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openSyncModal}>
                    <div className={classes.paper}>
                        <Paper elevation={5} className={classes.paper}>
                            <div className={classes.head}>

                                <h4
                                    style={{
                                        marginBottom: "1rem",
                                    }}
                                    id="transition-modal-title"
                                >
                                    SYNC {bookingData?.customer_name} BOOKING ({syncId})
                                </h4>
                                <CloseIcon onClick={() => setSyncOpenModal(false)} />
                            </div>
                            <form onSubmit={submitForm}>

                                <Grid container spacing={3} direction="row" alignItems="center" justify="center">
                                    <Grid item xs={12} sm={6} md={3}>
                                        <TextField
                                            className="input"
                                            name="adhar_number"
                                            type="text"
                                            label="ADHAR NUMBER"
                                            value={adharNumber}
                                            variant="outlined"
                                            onChange={(e) => setAdharNumber(e.target.value)}
                                            style={{ width: "100%" }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <TextField
                                            className="input"
                                            name="passport_number"
                                            type="text"
                                            label="PASSPORT NUMBER"
                                            value={passportNumber}
                                            variant="outlined"
                                            onChange={(e) => setPassportNumber(e.target.value)}
                                            style={{ width: "100%" }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <TextField
                                            className="input"
                                            name="Srf_Number"
                                            type="text"
                                            label="SRF NUMBER"
                                            value={srfNumber}
                                            variant="outlined"
                                            onChange={(e) => setSrfNumber(e.target.value)}
                                            style={{ width: "100%" }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <TextField
                                            className="input"
                                            name="barcode"
                                            type="text"
                                            label="BARCODE"
                                            value={barcode}
                                            variant="outlined"
                                            onChange={(e) => setBarcode(e.target.value)}
                                            style={{ width: "100%" }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <Button
                                            color="primary"
                                            type="submit"
                                            variant="contained"
                                            disabled={syncSubmit}
                                        >
                                            SYNC BOOKING
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </Paper>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
};

const mapStateToProps = (state: any) => ({

});

export default connect(mapStateToProps, {
    getSyncData,
})(CreatePhlebo);
