import React, { useState, useEffect, useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Grid,
  TextField,
  withStyles,
  Select,
  MenuItem,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from '@mui/material/Button';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Loader from "../components/loader/index";
import MessageIcon from "@material-ui/icons/Message";
import { useHistory } from "react-router";
import SystemUpdateAltIcon from "@material-ui/icons/SystemUpdateAlt";
import SampleJourneyComment from "../components/Comments/sampleJourney";
import {
  getSampleHandOver,
  updateRiderToLabStatus,
  getRiderList,
  getPhlebos,
  getPhleboRiderDueAmountData,
  getCities,
} from "../actions/RouteManagerAction";
import { genratePhleboRiderCashDueFilter } from "../../helpers/generateUrl";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      marginTop: "2rem",
      width: "100%",
    },
    table: {
      maxWidth: "100%",
    },
    tableHead: {
      background: "rgb(146, 74, 145)!important",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    tableHeadCell: {
      color: "#fff",
      fontWeight: "bold",
      textAlign: "center",
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
    input: {
      padding: "1px",
    },
  })
);
const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

interface SampleHandOverProps {
  getSampleHandOver: any;
  sampleHandOverData: any;
  updateRiderToLabStatus: any;
  getRiderList: any;
  riderList: any;
  getPhlebos: any;
  phleboList: any;
  loading: boolean;
  getPhleboRiderDueAmountData: any;
  phlebo_rider_due: any;
  getCities: any;
  cities: any;
}

const BookingsTable: React.FC<SampleHandOverProps> = ({
  getSampleHandOver,
  sampleHandOverData,
  updateRiderToLabStatus,
  getRiderList,
  riderList,
  getPhlebos,
  phleboList,
  getPhleboRiderDueAmountData,
  phlebo_rider_due,
  getCities,
  cities,
  loading,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const timer = useRef<any>(0);
  const [bookingId, setBookingId] = useState<any>("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [open, setOpen] = useState<boolean>(false);
  const [sampleId, setSampleId] = useState<number>(0);
  const [start_date, setStart_date] = useState("");
  const [end_date, setEnd_date] = useState("");
  const [createdAt, setCreatedAt] = useState<string>("");
  const [rider, setRider] = useState<any>("");
  const [phlebo, setPhlebo] = useState<any>("");
  const [phoneNumber, setPhoneNumber] = useState<any>();
  const [userStatus, setUsertStaus] = useState<any>("none");
  const [city, setCity] = useState<any>([]);
  const [userGroup, setUserGroup] = useState<any>("none");
  useEffect(() => {
    getSampleHandOver();
    getPhlebos();
    getRiderList(`?usergroup=Rider`);
    getPhleboRiderDueAmountData();
  }, []);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url =
        phlebo_rider_due.links && phlebo_rider_due.links.next.split("?")[1];
      getPhleboRiderDueAmountData(url ? `?${url}` : "");
    } else if (newPage < page) {
      let url =
        phlebo_rider_due.links && phlebo_rider_due.links.previous.split("?")[1];
      getPhleboRiderDueAmountData(url ? `?${url}` : "");
    }
    setPage(newPage as number);
  };
  const handleClick = (id: number) => {
    setOpen(true);
    setSampleId(id);
  };
  const handleRiderToLabUpdate = async (id: any) => {
    const body = {
      lab_received: "confirmed",
    };
    await updateRiderToLabStatus(body, id);
    history.push("/dashboard/rm/cash-due-on-pha-rider");
  };
  const onKeyDown = (e: any) => {
    if (e.key === "Enter") {
      filterSampleHandover(e);
    }
  };
  const filterSampleHandover = (e: any) => {
    const body: any = {
      start_date,
      end_date,
      created_date: createdAt,
      phlebo: phlebo,
      rider,
      booking: bookingId,
      phonenumber: phoneNumber,
      user_status: userStatus,
      usergroup: userGroup,
      city: city,
    };
    const url = genratePhleboRiderCashDueFilter(body);
    getPhleboRiderDueAmountData(`${url}`);
  };
  return (
    <div style={{ width: "100%" }}>
      <div className={classes.toolbar} />
      <h2>Cash Due On Phlebo And Rider</h2>
      <Grid container spacing={1} direction="row">
        <Grid item xs={12} sm={4} md={2}>
          <TextField
            className="input"
            name="number"
            type="number"
            label="Phone Number"
            value={phoneNumber}
            variant="outlined"
            onChange={(e) => setPhoneNumber(e.target.value)}
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Select
            className="input"
            name="useStatus"
            variant="outlined"
            value={userStatus}
            style={{ width: "100%", height: "40px", padding: "0" }}
            onChange={(e) => setUsertStaus(e.target.value as string)}
            required
          >
            <MenuItem disabled value={"none"}>
              User Status
            </MenuItem>
            <MenuItem value={"true"}>Active</MenuItem>
            <MenuItem value={"false"}>In Active</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Select
            className="input"
            name="useStatus"
            variant="outlined"
            value={userGroup}
            style={{ width: "100%", height: "40px", padding: "0" }}
            onChange={(e) => setUserGroup(e.target.value as string)}
            required
          >
            <MenuItem disabled value={"none"}>
              User Group
            </MenuItem>
            <MenuItem value={"phlebos"}>Phlebos</MenuItem>
            <MenuItem value={"rider"}>Rider</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Autocomplete
            id="rider"
            className="input"
            onChange={(event, newValue) => {
              if (newValue) {
                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                setRider(obj.id);
              }
            }}
            options={riderList.results || []}
            freeSolo
            blurOnSelect
            aria-required
            getOptionLabel={(option: any) => option.user && option.user.name}
            onInputChange={(event, newInputValue) => {
              clearTimeout(timer.current);
              timer.current = setTimeout(() => {
                getRiderList(`?usergroup=Rider&code=${newInputValue}`);
              }, 1000);
              if (newInputValue.length === 0) {
                setRider("");
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Rider Name"
                variant="outlined"
                style={{ width: "100%", margin: "0", padding: "0" }}
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Autocomplete
            id="phleboName"
            className="input"
            onChange={(event, newValue) => {
              if (newValue) {
                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                setPhlebo(obj.id);
              }
            }}
            options={
              phleboList.results
                ? [
                    ...phleboList.results,
                    { id: 0, user: { username: "NoPhlebo" } },
                  ]
                : []
            }
            freeSolo
            blurOnSelect
            aria-required
            getOptionLabel={(option: any) =>
              option.user && option.user.username
            }
            onInputChange={(event, newInputValue) => {
              clearTimeout(timer.current);
              timer.current = setTimeout(() => {
                getPhlebos(`?code=${newInputValue}`);
              }, 1000);
              if (newInputValue.length === 0) {
                setPhlebo("");
              }
            }}
            renderInput={(params) => (
              <TextField
                className={classes.input}
                {...params}
                placeholder="Phlebo Name"
                variant="outlined"
                style={{ width: "100%", margin: "0", padding: "0" }}
                required
              />
            )}
          />
        </Grid>
        {/* <Grid item xs={12} sm={4} md={2}>
                    <TextField
                        className="input"
                        name="Created At"
                        type="date"
                        label="Created Date"
                        value={createdAt}
                        variant="outlined"
                        onChange={(e) => setCreatedAt(e.target.value as string)}
                        style={{ width: "100%" }}
                    />
                </Grid> */}
        <Grid item xs={12} sm={4} md={2}>
          <Autocomplete
            id="city"
            className="input"
            onChange={(event, newValue) => {
              let sourceIds: any = [];
              if (newValue) {
                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                sourceIds = obj.map((item: any) => item.id);
              }
              setCity(sourceIds);
            }}
            options={cities || []}
            freeSolo
            blurOnSelect
            aria-required
            limitTags={1}
            multiple
            onKeyDown={onKeyDown}
            getOptionLabel={(option: any) => option.name}
            getOptionDisabled={(option: any) => {
              return city.includes(option.id);
            }}
            disableClearable
            disableCloseOnSelect
            onInputChange={(event, newInputValue) => {
              clearTimeout(timer.current);
              timer.current = setTimeout(() => {
                getCities(newInputValue);
              }, 1000);
              if (newInputValue.length === 0) {
                setCity([]);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="City"
                variant="outlined"
                style={{ width: "100%", margin: "0", padding: "0" }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={filterSampleHandover}
            disabled={loading}
          >
            Filter
          </Button>
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => history.push("/dashboard/rm/cash-due-on-pha-rider")}
          >
            Reset
          </Button>
        </Grid>
      </Grid>

      <div className="data-table">
        <TableContainer
          className={classes.tableContainer}
          component={Paper}
          style={{ maxHeight: "540px" }}
        >
          <Table stickyHeader aria-label="simple table">
            <TableHead className={classes.tableHead}>
              <StyledTableRow>
                <StyledTableCell className={classes.tableHeadCell}>
                  USER GROUP
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  USER NAME
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  NAME
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  USER STATUS
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  PHONE NUMBER
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  CASH DUE
                </StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {phlebo_rider_due &&
                phlebo_rider_due.results &&
                phlebo_rider_due.results.length > 0 &&
                phlebo_rider_due.results.map((data: any, index: any) => {
                  return (
                    <StyledTableRow key={index}>
                      <StyledTableCell align="center">
                        {data?.usergroup}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.username}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.fullname}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.user_status ? "Active" : "In Active"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.phonenumber}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.cash_due}
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
            </TableBody>
            <TableFooter>
              <StyledTableRow>
                <TablePagination
                   colSpan={3}
                  count={phlebo_rider_due?.count || 0}
                  rowsPerPageOptions={[]}
                  rowsPerPage={phlebo_rider_due?.page_size || 20}
                  page={page}
                  SelectProps={{
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                />
              </StyledTableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
      <SampleJourneyComment open={open} setOpen={setOpen} sampleId={sampleId} />

      {/* {bookingList.results && bookingList.results.length === 0 && (
        <h6 style={{ textAlign: "center" }}>No Data Found</h6>
      )} */}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  sampleHandOverData: state.RouteManagerReducer.sampleHandOverData,
  riderList: state.RouteManagerReducer.riderList,
  phleboList: state.RouteManagerReducer.phleboList,
  phlebo_rider_due: state.RouteManagerReducer.phlebo_rider_due,
  loading: state.RouteManagerReducer.loading,
  cities: state.RouteManagerReducer.cities,
});
export default connect(mapStateToProps, {
  getSampleHandOver,
  updateRiderToLabStatus,
  getRiderList,
  getPhlebos,
  getPhleboRiderDueAmountData,
  getCities,
})(BookingsTable);
