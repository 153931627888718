import React, { useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { useEffect } from "react";
import PPTModal from "../../components/Learning/PPTModule/pptModule"
import { getCreateModule } from "../../actions/loginActions"

let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        padding: theme.spacing(2, 4, 3),
        width: isMobile ? "400px" : "1350px",
        // height: "100vh",
    },
    head: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "2rem"
    }
}));

type ModalProps = {
    getCreateModule: any;
    createModuleData: any;
};

const LearningModal: React.FC<ModalProps> = ({
    getCreateModule,
    createModuleData,
}) => {
    const classes = useStyles();

    const [fileURLModule, setFileURLModule] = useState<any>("")
    const [timeInMinModule, setTimeInMinModule] = useState<any>(0)
    const [timeInHrModule, setTimeInHrModule] = useState<any>(0)
    // const [questionModalOpen, setQuestionModalOpen] = useState(false);
    const [openPPT, setOpenPPT] = useState(false);
    const [minimumScore, setMinimumScore] = useState<any>(0)
    const [moduleQuestion, setModuleQuestion] = useState<any>([])


    useEffect( () => {
        getCreateModule(`category=OPERATIONS`);
    }, [])

    return (
        <>
            <div className="bodyLearnShow">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ display: "flex", justifyContent: "flex-start" }}>
                        <h2 style={{ fontSize: "24px", padding: "25px 0 0 15px" }}>My Learning Modules</h2>
                    </div>
                </div>
                <div className="startPageDiv">
                    {
                        createModuleData && createModuleData?.count > 0 && createModuleData?.results?.map( (row: any, index: any) => (
                            <div style={{ height: "200px", display: "flex", flexDirection: "column", justifyContent: "space-between", marginTop: "20px", marginRight: "5%", marginBottom: "20px" }}>
                                <div style={{ textAlign: "center" }}>
                                    <div  style={{ textAlign: "center" }}>
                                        <span style={{ color: "#827d7d", padding: "0px 10px 0px 10px" }}>{row?.name !== null ? row?.name : "NO NAME"}</span>
                                        <p style={{ color: "#827d7d", padding: "0px 10px 0px 10px" }}>{`${(row?.completion_hours * 60) + row?.completion_minutes} min`}</p>
                                    </div>
                                </div>
                                <div style={{ textAlign: "center", display: "flex", flexDirection: "column" }}>
                                    <Button style={{
                                        backgroundColor: "#c70e0ef7",
                                        color: "white",
                                        padding: "6px 16px",
                                        borderRadius: "5px",
                                        textTransform: "none",
                                    }}
                                    onClick={() => {
                                        setFileURLModule(row?.image);
                                        setTimeInMinModule(row?.completion_minutes);
                                        setTimeInHrModule(row?.completion_hours);
                                        setModuleQuestion(row?.assignment_question_module);
                                        setMinimumScore(row?.minimum_score);
                                        setOpenPPT(true);
                                    }}
                                    size="small"
                                    >
                                        <p style={{ margin: "0", fontSize: "12px" }}>View Module</p>
                                    </Button>
                                </div>
                            </div>
                        ) )
                    }
                </div>
            </div>
            {   openPPT && 
                <PPTModal
                    open={openPPT}
                    setOpen={setOpenPPT}
                    fileUrl={`${fileURLModule}`}
                    timeInMinModule={timeInMinModule}
                    timeInHrModule={timeInHrModule}
                    viewOrAttempt={true}
                    moduleId={0}
                    moduleQuestion={moduleQuestion}
                    //   setModuleQuestion={setModuleQuestion}
                    minimumScore={minimumScore}
                    setTimeInMinModule={setTimeInMinModule}
                    setTimeInHrModule={setTimeInHrModule}
                    setModuleId={"setModuleId"}
                />
            }
        </>
    );
};

const mapStateToProps = (state: any) => ({
    createModuleData: state.loginReducer.createModuleData,
});

export default connect(mapStateToProps, {
    getCreateModule,
})(LearningModal);