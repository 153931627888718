import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@mui/material/Modal";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import Pagination from "@mui/material/Pagination";
import Fade from "@material-ui/core/Fade";
import { Button } from "@material-ui/core";
import AccessAlarmRoundedIcon from "@mui/icons-material/AccessAlarmRounded";
import QuestionModal from "../questionModal";
import AnswerSheetModal from "../AnswerSheet/answersheet";
import "./pdf.sass";

import { Document, Page, pdfjs } from "react-pdf";
import Loader from "../../loader";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: isMobile ? "400px" : "1200px",
    height: "90vh",
  },
  head: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "1rem",
  },
  timer: {
    backgroundColor: "rgba(255, 255, 255, 0.1)",
    display: "inline-block",
    textAlign: "center",
  },
  col4: {
    width: "25%",
    float: "left",
  },
  box: {
    borderRight: "solid 1px rgba(255, 255, 255, 0.2)",
    fontWeight: "300",
    padding: "10px",
  },
}));

const SECOND = 1000;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;

const PPTModal = ({
  open,
  setOpen,
  fileUrl,
  timeInMinModule,
  timeInHrModule,
  viewOrAttempt,
  moduleId,
  moduleQuestion,
  minimumScore,
  setTimeInHrModule,
  setModuleId,
  setTimeInMinModule,
}) => {
  const classes = useStyles();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const [timer, setTimer] = useState("00:00:01");

  const [zoom, setZoom] = useState(1);
  const [questionModalOpen, setQuestionModalOpen] = useState(false);
  const [answerSheetModalOpen, setAnswerSheetModalOpen] = useState(false);

  useEffect(() => {
    if (timeInHrModule !== 0 && questionModalOpen === true) {
      setTimeInHrModule(0);
      setQuestionModalOpen(false);
    }
  });

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };

  const startTimer = (e) => {
    let { total, hours, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      setTimer(
        (hours > 9 ? hours : "0" + hours) +
          ":" +
          (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
    }
  };

  const clearTimer = (e) => {
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    return () => clearInterval(id);
  };

  const getDeadTime = () => {
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + 0);
    deadline.setMinutes(deadline.getMinutes() + timeInMinModule);
    deadline.setHours(deadline.getHours() + timeInHrModule);
    return deadline;
  };

  useEffect(() => {
    clearTimer(getDeadTime());
  }, []);
  const handleChange = (event, value) => {
    setPageNumber(value);
  };

  console.log(timer, "timer")

  return (
    <div id="pdfShow">
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        closeAfterTransition
      >
        <Fade in={open}>
          <div
            className={classes.paper}
            style={{ display: questionModalOpen === true ? "none" : "block" }}
          >
            {viewOrAttempt === "TrueAndAnswers" ? (
              ""
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <AccessAlarmRoundedIcon
                  style={{ fontSize: "60px", paddingBottom: "10px" }}
                />
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <p style={{ margin: "0" }}>{timer}</p>
                  <p style={{ color: "red" }}>Time Pending</p>
                </div>
              </div>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                variant="contained"
                size="small"
                color="primary"
                startIcon={<ZoomOutIcon />}
                disabled={zoom === 1}
                onClick={() => setZoom(zoom - 0.2)}
              ></Button>

              <Button
                variant="contained"
                size="small"
                color="primary"
                disabled={zoom > 2}
                startIcon={<ZoomInIcon />}
                onClick={() => setZoom(zoom + 0.2)}
              ></Button>
            </div>
            <div
              style={{
                width: "100%",
                overflow: "scroll",
                height: "65vh",
                background: "rgba(0,0,0,0.2)",
                border: "1px solid grey",
              }}
            >
              <Document
                file={fileUrl}
                onLoadSuccess={onDocumentLoadSuccess}
                loading={
                  <div>
                    <Loader />
                  </div>
                }
              >
                <Page pageNumber={pageNumber} scale={zoom} />
              </Document>
            </div>
            <div style={{ width: "100%", paddingTop: 5 }}>
              <Pagination
                count={numPages}
                pageNumber={pageNumber}
                variant="outlined"
                shape="rounded"
                onChange={handleChange}
              />
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              {viewOrAttempt === "TrueAndAnswers" ? (
                <div>
                  <Button
                    style={{
                      backgroundColor: "#c70e0ef7",
                      color: "white",
                      borderRadius: "8px",
                      width: "300px",
                      height: "40px",
                      margin: "25px 15px 0 0px",
                      border: "1px solid",
                    }}
                    size="small"
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    <p style={{ margin: "0", fontSize: "15px" }}>GO BACK</p>
                  </Button>
                  <Button
                    style={{
                      backgroundColor: "#c70e0ef7",
                      color: "white",
                      borderRadius: "8px",
                      width: "300px",
                      height: "40px",
                      margin: "25px 15px 0 0px",
                      border: "1px solid",
                    }}
                    size="small"
                    onClick={() => {
                      setAnswerSheetModalOpen(true);
                    }}
                  >
                    <p style={{ margin: "0", fontSize: "15px" }}>
                      VIEW QUESTIONS AND ANSWERS
                    </p>
                  </Button>
                </div>
              ) : viewOrAttempt === true ? (
                <Button
                  style={{
                    backgroundColor: "#c70e0ef7",
                    color: "white",
                    borderRadius: "8px",
                    width: "300px",
                    height: "40px",
                    margin: "25px 15px 0 0px",
                    border: "1px solid",
                  }}
                  size="small"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <p style={{ margin: "0", fontSize: "15px" }}>GO BACK</p>
                </Button>
              ) : (
                <Button
                  style={{
                    backgroundColor:
                      timer !== "00:00:00" ? "#beb8b89e" : "#c70e0ef7",
                    color: timer !== "00:00:00" ? "black" : "white",
                    borderRadius: "8px",
                    width: "300px",
                    height: "40px",
                    margin: "25px 15px 0 0px",
                    border: timer !== "00:00:00" ? "" : "1px solid",
                  }}
                  size="small"
                  onClick={() => {
                    setQuestionModalOpen(true);
                  }}
                  disabled={timer !== "00:00:00"}
                >
                  <p style={{ margin: "0", fontSize: "15px" }}>TAKE THE QUIZ</p>
                </Button>
              )}
            </div>
          </div>
        </Fade>
      </Modal>
      {questionModalOpen && (
        <QuestionModal
          questionModalOpen={questionModalOpen}
          setQuestionModalOpen={setQuestionModalOpen}
          moduleId={moduleId}
          moduleQuestion={moduleQuestion}
          minimumScore={minimumScore}
          fileUrl={fileUrl}
          setTimeInHrModule={setTimeInHrModule}
          setModuleId={setModuleId}
          setTimeInMinModule={setTimeInMinModule}
          // setOpen={setOpen}
        />
      )}
      {answerSheetModalOpen && (
        <AnswerSheetModal
          open={answerSheetModalOpen}
          setOpen={setAnswerSheetModalOpen}
          moduleQuestion={moduleQuestion}
          minimumScore={minimumScore}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(PPTModal);