import React, { useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Loader from "../loader";
import { connect } from "react-redux";
import { Button, Paper, TextField, Grid, Select, MenuItem, Slider, Chip, withStyles } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
// import { Editor, OriginalTools } from 'react-bootstrap-editor';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { createPackage, getCityPrice, getPackage, getPackageTag, updatePackage, getPackageCatrgory, getPackageDetails, getPackageByName } from '../../actions/productMangerAction'
import { useEffect } from "react";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useRef } from "react";
import { useHistory } from "react-router";
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 0,
        },
        toolbar: {
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        },
        content: {
            width: "80%",
            flexGrow: 21,
        },
        paper: {
            padding: "1rem",
            width: "100%",
            borderRadius: "10px"
        },
        head: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "1rem",
            textAlign: "center"
        },
        heading: {
            fontWeight: "normal",
            fontSize: "1.2rem",
            margin: "0"
        },
        table: {
            maxHeight: "400px",
            margin: "auto",
            overflow: "auto"
        },
        image: {
            width: "100px",
            height: "auto",
        },
        imageSM: {
            width: "50px",
            height: "auto",
        },
        imgContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexWrap: "wrap"
        }
    })
);

const PrettoSlider:any = withStyles({
    root: {
        color: 'rgb(146, 74, 145)',
        height: 8,
    },
    thumb: {
        height: 24,
        width: 24,
        backgroundColor: 'violet',
        border: '2px solid purple',
        marginTop: -8,
        marginLeft: -12,
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 4px)',
    },
    track: {
        height: 8,
        borderRadius: 4,
    },
    rail: {
        height: 8,
        borderRadius: 4,
    },
})(Slider);

interface Props {
    createPackage: any;
    cityPriceList: any;
    getCityPrice: any;
    packageList: any;
    getPackage: any;
    getPackageTag: any;
    getPackageCatrgory: any;
    packageCategory: any
    packageTags: any;
    getPackageDetails: any;
    match: any;
    packageDetails: any;
    getPackageByName: any;
    packageListByName: any;
    loading: any;
    updatePackage: any;
}

const CreatePackagePage: React.FC<Props> = ({
    createPackage,
    cityPriceList,
    getCityPrice,
    packageList,
    getPackage,
    getPackageTag,
    packageTags,
    getPackageCatrgory,
    packageCategory,
    getPackageDetails,
    packageDetails,
    getPackageByName,
    packageListByName,
    updatePackage,
    match,
    loading,
}) => {
    const classes = useStyles();
    const history = useHistory();
    const [name, setName] = useState<string>("")
    const [description, setDescription] = useState<string>("")
    const [details, setDetails] = useState<string>("")
    const [faq, setFaq] = useState<string>("")
    const [topFaq, setTopFaq] = useState<string>("")
    const [packagePrice, setPackagePrice] = useState<number>(0)
    const [cityPrice, setCityPrice] = useState<any>([])
    const [testPackage, setTestPackage] = useState<any>([])
    const [relatedTestPackage, setRelatedTestPackage] = useState<any>([])
    const [categoryForWeb, setCategoryForWeb] = useState<any>([])
    const [discount, setDiscount] = useState<number>(0)
    const [offerPrice, setOfferPrice] = useState<number>(0)
    const [addOnPrice, setAddOnPrice] = useState<number>(0)
    const [parameter, setParameter] = useState<string>("")
    const [isAddon, setIsAddon] = useState<boolean | null>(null)
    const [bestPackageApp, setBestPackageApp] = useState<boolean | null>(null)
    const [recommendedAge, setRecommendedAge] = useState<any>([10, 20])
    const [recommendedGender, setRecommendedGender] = useState<string>("any")
    const [testCategory, settestCategory] = useState<string>("any")
    const [homeCollectionCheck, setHomeCollectionCheck] = useState<string>("any")
    const [bestPackageAppCheck, setBestPackageAppCheck] = useState<string>("any")
    const [ptype, setPtype] = useState<string>("any")
    const [isPremium, setIsPremium] = useState<string>("any")
    const [isPopular, setIsPopular] = useState<string>("any")
    const [type, setType] = useState<string>("any")
    const [metaTitle, setMetaTitle] = useState("")
    const [metaKeyword, setMetaKeyword] = useState("")
    const [metaDescription, setMetaDescription] = useState("")
    const [code, setCode] = useState("")
    const [slug, setSlug] = useState("")
    const [lpSlug, setLpSlug] = useState("")
    const [ogTag, setOgTag] = useState("")
    const [tags, setTags] = useState<any>("")
    const [tatTime, setTatTime] = useState("")
    const [twitterCard, setTwitterCard] = useState("")
    const [specimenInstructions, setSpecimenInstructions] = useState("")
    const [sample, setSample] = useState("")
    const [fastingTime, setFastingTime] = useState("")
    const [isActiveCheck, setIsActiveCheck] = useState(false);
    const [packageId, setPackageId] = useState<any>("");
    const [relatedPackageId, setRelatedPackageId] = useState<any>("");

    const [packageName, setPackageName] = useState<any>("")
   
    const [dd, setDD] = useState("");
    const [hh, setHH] = useState("");
    const [mm, setMM] = useState("");
    const [ss, setSS] = useState("");
    const [packageImage, setPackageImage] = useState<any>([])
    const [file, setFile] = React.useState<any>([]);
    const timer = useRef<any>(0)
    const Dday = () => {
        return <Select className="input"
            name="validOnce"
            variant="outlined"
            value={dd}
            style={{ width: "100%" }}
            onChange={(e: any) => setDD(e.target.value as string)}
        >
            <MenuItem disabled value={dd}>{dd}</MenuItem>
            {Array.from(Array(61), (e, i) => {
                return <MenuItem key={i} value={i}>{i}</MenuItem>
            })}</Select>
    }
    const Hhours = () => {
        return <Select className="input"
            name="validOnce"
            variant="outlined"
            value={hh}
            style={{ width: "100%" }}
            onChange={(e: any) => setHH(e.target.value as string)}
        >
            <MenuItem disabled key={211} value={hh}>{hh}</MenuItem>
            {Array.from(Array(25), (e, i) => {
            return <MenuItem key={i} value={i < 10 ? "0" + i : i}>{i < 10 ? "0" + i : i}</MenuItem>
        })}</Select>
    }
    const Mminute = () => {
        return <Select className="input"
            name="validOnce"
            variant="outlined"
            value={mm}
            style={{ width: "100%" }}
            onChange={(e: any) => setMM(e.target.value as string)}
        >
            <MenuItem disabled value={mm}>{mm}</MenuItem>
            {Array.from(Array(61), (e, i) => {
            return <MenuItem key={i} value={i < 10 ? "0" + i : i}>{i < 10 ? "0" + i : i}</MenuItem>
        })}</Select>
    }
    const Ssecond = () => {
        return <Select className="input"
            name="validOnce"
            variant="outlined"
            value={ss}
            style={{ width: "100%" }}
            onChange={(e: any) => setSS(e.target.value as string)}
        >
            <MenuItem disabled value={ss}>{ss}</MenuItem>
            {Array.from(Array(61), (e, i) => {
            return <MenuItem key={i} value={i < 10 ? "0" + i : i}>{i < 10 ? "0" + i : i}</MenuItem>
        })}</Select>
    }
    useEffect(() => {
        getCityPrice()
        getPackageTag()
        getPackageCatrgory()
        getPackageDetails()
        getPackageByName(`${match.params.slug}/`)
    }, [])

   

    const submitHandler = async () => {
        const formData = new FormData();
       
        formData.append("package_price", packagePrice as any)
      
        if (file.length > 0) {
            for (let i = 0; i < file.length; i++) {
                formData.append("package_image", file[i])
            }
        }

        await updatePackage(slug,formData)
        history.push("/dashboard/pm/packages")
    }
    const fileSelectedHandler = (e: React.ChangeEvent<{ files: any }>) => {
        setFile(e.target.files)
    }
    
    return (
        <>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <Paper elevation={5} className={classes.paper}>
                    <h3 style={{ textAlign: "center", fontWeight: "bold" }}>EDIT PACKAGE</h3>
                    {loading ? <Loader /> : (
                        <Grid container spacing={3} style={{ margin: "1rem auto" }}>
                             
                            <Grid container item xs={12} md={3} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <p>Related Package</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <Select className="input"
                                        name=" related package"
                                        variant="outlined"
                                        value={relatedPackageId}
                                        style={{ width: "100%", margin: "0" }}
                                        onChange={(e) => setRelatedPackageId(e.target.value as String)}
                                    >
                                        <MenuItem disabled value={packageId}>{packageName}</MenuItem>
                                        {packageList && packageList.results && packageList.results.length > 0 && packageList.results.map((data: any) => {
                                            return (
                                                <MenuItem value={data.id}>{data.name}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </Grid>
                            </Grid>
                           
                            <Grid container item xs={12} md={3} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <p>Tags</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <Autocomplete
                                        id="tags"
                                        onChange={(event, newValue) => {
                                            if (newValue) {
                                                let tagId: any = []
                                                newValue.map((val: any) => {
                                                    let obj = JSON.parse(JSON.stringify(val, null, " "));
                                                    tagId.push(obj.id)
                                                })
                                                setTags(tagId)
                                            }
                                        }}
                                        onInputChange={(event, newInputValue) => {
                                            clearTimeout(timer.current)
                                            timer.current = setTimeout(() => {
                                                getPackageTag(newInputValue)
                                            }, 1000)
                                        }}
                                        multiple
                                        disableClearable
                                        limitTags={2}
                                        options={packageTags.results}
                                        freeSolo
                                        blurOnSelect
                                        getOptionLabel={(option: any) => option.tags}
                                        renderTags={(value: string[], getTagProps) =>
                                            value.map((option: any, index: number) => (
                                                <Chip
                                                    key={index}
                                                    variant="default"
                                                    color="primary"
                                                    label={option.tags}
                                                    {...getTagProps({ index })}
                                                />
                                            ))
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                placeholder="Tags"
                                                variant="outlined"
                                                style={{ width: "100%", margin: "0", padding: "0" }}
                                                className="input"
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                            
                          

                           
                           
                          
                          
                          


                           
                          

                           


                            <Grid container item xs={12} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <Button
                                        color="secondary"
                                        variant="contained"
                                        fullWidth
                                        onClick={submitHandler}
                                    >
                                        Update Package
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </Paper>
            </main>
        </>
    );
};


const mapStateToProps = (state: any) => ({
    cityPriceList: state.ProductManagerReducer.cityPriceList,
    packageList: state.ProductManagerReducer.packageList,
    packageCategory: state.ProductManagerReducer.packageCategory,
    packageTags: state.ProductManagerReducer.packageTags,
    packageDetails: state.ProductManagerReducer.packageDetails,
    packageListByName: state.ProductManagerReducer.packageListByName,
    loading: state.ProductManagerReducer.loading,
})

export default connect(mapStateToProps, {
    createPackage,
    getCityPrice,
    getPackage,
    getPackageTag,
    getPackageCatrgory,
    getPackageDetails,
    getPackageByName,
    updatePackage,
})(CreatePackagePage);