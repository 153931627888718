import React, { useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@mui/material/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Button, MenuItem, Select, TextField, Grid } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import QuestionModal from "../questionModal";
import PPTModal from "../PPTModule/pptModule";
import AttemptLearningComponent from "../../../components/Learning/attemptLearningModule"
import { getCreateModule } from "../../../actions/loginActions"
import SecureStorage from "../../../config/SecureStorage";
var jwt = require('jsonwebtoken');

let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: isMobile ? "400px" : "1200px",
  },
  head: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "1rem",
  },
}));

type ModalProps = {
  open: boolean;
  setOpen: Function;
  moduleQuestion: any;
  answerCheck: any;
  scoreArray: any;
  minimumScore: any;
  fileUrl: any;
  setQuestionIndex: any;
  setScoreArray: any;
  setTimeInHrModule: any;
  setModuleId: any;
  setTimeInMinModule: any;
  getCreateModule: any;
  createModuleData: any;
};

const ScoreModal: React.FC<ModalProps> = ({
  open,
  setOpen,
  moduleQuestion,
  answerCheck,
  scoreArray,
  minimumScore,
  fileUrl,
  setQuestionIndex,
  setScoreArray,
  setTimeInHrModule,
  setModuleId,
  setTimeInMinModule,
  getCreateModule,
  createModuleData,
}) => {
  const classes = useStyles();

  const history = useHistory();

  const [query, setQuery] = useState<string>("");
  const [disposition, setDisposition] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [status, setStatus] = useState<string>("pending");
  const [reciveRemark, setReciveRemark] = React.useState<any>("");
  const [errorMessage, setErrorMessage] = useState<any>("");
  const [checkedWay, setCheckedWay] = useState<any>("");
  const [checkedState, setCheckedState] = useState(
    new Array(4).fill(false)
  );
  const [scoreModalOpen, setScoreModalOpen] = useState(false);
  const [questionModalOpen, setQuestionModalOpen] = useState(false);
  const [openPPT, setOpenPPT] = useState(false);
  const [openAttemptLearningComponent, setOpenAttemptLearningComponent] = React.useState(false);
  const [closeAll, setCloseAll] = React.useState(false);

  const handleOnChange = (position: string | number) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );
    setCheckedState(updatedCheckedState);
  };

  const handleClose = () => {
    setOpen(false);
  }

  useEffect( () => {
    setQuestionIndex(0);
    getCreateModule();
  }, [])

  const sumInDecimals = scoreArray.reduce((partialSum: any, a: any) => partialSum + a, 0);

  // function round(num: string | number, decimalPlaces: number) {
  //   num = Math.round(num + "e" + decimalPlaces);
  //   return Number(num + "e" + -decimalPlaces);
  // }

  useEffect( () => {
    if (createModuleData && createModuleData?.count > 0 && createModuleData?.results[createModuleData?.count - 1]?.score_by_user === "Pass") {
      localStorage.setItem("tokeninlocal", "delete");
    }
  }, [createModuleData])

  function round(num: number, decimalPlaces = 0) {
    var p = Math.pow(10, decimalPlaces);
    var n = (num * p) * (1 + Number.EPSILON);
    return Math.round(n) / p;
  }

  const sum = round(sumInDecimals, 1)

  const token = SecureStorage.getItem("token");
  const decoded = jwt.decode(token);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div className={classes.head}>
              <img
                className="invoiceImage"
                src="https://cdn3.iconfinder.com/data/icons/business-marketing-advertising-blue-and-red/64/Marketing_target-1024.png"
                style={{ fontFamily: "sans-serif", height: 60 }}
              />
              <h4
                style={{
                  fontSize: "36px",
                  paddingTop: "12px",
                  paddingLeft: "16px",
                }}
                id="transition-modal-title"
              >
                You've Scored
              </h4>
            </div>
            <div style={{ display: "flex", justifyContent: "center", margin: "25px 0px" }}>
              <div
                className="result" 
                style={{ 
                  borderRadius: "50%",
                  width: "200px",
                  height: "200px",
                  backgroundColor: "#eae6e67d",
                  fontSize: "50px",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "space-around",
                  flexDirection: "column",
                  }}
              >
                {`${sum}/100`}
              </div>
            </div>
            { sum >= minimumScore ?
              <div style={{ display: "flex", justifyContent: "space-between", width: "40%", marginLeft: "30%" }}>
                  <Button style={{
                      backgroundColor: "white",
                      color: "rgb(12 12 12 / 93%)",
                      borderRadius: "8px",
                      width: "200px",
                      height: "40px",
                      margin: "25px 15px 0 0px",
                      border: "1px solid rgb(12 12 12 / 93%)",
                      textTransform: "none",
                  }}
                  size="small"
                  onClick={() => {
                    setOpenAttemptLearningComponent(false);
                    setCloseAll(true);
                    if (decoded.user_group === "PhleboAdmin") {
                      history.push(`/dashboard/pha`);
                    } else if (decoded.user_group === "RouteManager") {
                      history.push(`/dashboard/rm`);
                    }
                  }}
                  disabled={createModuleData?.results[createModuleData?.count - 1]?.score_by_user === "Fail"}
                  >
                      <p style={{ margin: "0", fontSize: "15px" }}>Back To Dashboard</p>
                  </Button>
                  <Button style={{
                      backgroundColor: "#c70e0ef7",
                      color: "white",
                      borderRadius: "8px",
                      width: "200px",
                      height: "40px",
                      margin: "25px 15px 0 0px",
                      textTransform: "none",
                  }}
                  size="small"
                  onClick={() => {
                    // setOpenAttemptLearningComponent(true);
                    setOpen(false);
                    if (decoded.user_group === "PhleboAdmin") {
                      history.push(`/dashboard/pha`);
                    } else if (decoded.user_group === "RouteManager") {
                      history.push(`/dashboard/rm`);
                    }
                  }}
                  >
                      <p style={{ margin: "0", fontSize: "15px" }}>Go To Next Module</p>
                  </Button>
              </div>
              :
              <div style={{ display: "flex", justifyContent: "space-between", width: "40%", marginLeft: "30%" }}>
                  <Button style={{
                      backgroundColor: "white",
                      color: "rgb(12 12 12 / 93%)",
                      borderRadius: "8px",
                      width: "200px",
                      height: "40px",
                      margin: "25px 15px 0 0px",
                      border: "1px solid rgb(12 12 12 / 93%)",
                      textTransform: "none",
                  }}
                  size="small"
                  onClick={() => {
                    setOpen(false)
                    setOpenPPT(true);
                    setScoreArray([])
                    setTimeInHrModule(-1)
                    // setQuestionModalOpen(false);
                  }}
                  >
                      <p style={{ margin: "0", fontSize: "15px" }}>Read Module Again</p>
                  </Button>
                  <Button style={{
                      backgroundColor: "white",
                      color: "rgb(12 12 12 / 93%)",
                      borderRadius: "8px",
                      width: "200px",
                      height: "40px",
                      margin: "25px 15px 0 0px",
                      border: "1px solid rgb(12 12 12 / 93%)",
                      textTransform: "none",
                  }}
                  size="small"
                  onClick={() => {
                    setOpen(false)
                    setQuestionModalOpen(true);
                    setScoreArray([])
                  }}
                  >
                      <p style={{ margin: "0", fontSize: "15px" }}>Take The Test Again</p>
                  </Button>
              </div>
            }
          </div>
        </Fade>
      </Modal>
      {   openPPT && 
          <PPTModal
              open={openPPT}
              setOpen={setOpenPPT}
              fileUrl={`${fileUrl}`}
              timeInMinModule={0}
              timeInHrModule={0}
              viewOrAttempt={false}
              moduleId={0}
              moduleQuestion={moduleQuestion}
              minimumScore={minimumScore}
              setTimeInMinModule={setTimeInMinModule}
              setTimeInHrModule={setTimeInHrModule}
              setModuleId={setModuleId}
          />
      }
      { questionModalOpen &&
        <QuestionModal
            questionModalOpen={questionModalOpen}
            setQuestionModalOpen={setQuestionModalOpen}
            moduleId={0}
            moduleQuestion={moduleQuestion}
            minimumScore={minimumScore}
            fileUrl={`${fileUrl}`}
            setTimeInHrModule={setTimeInHrModule}
            setModuleId={setModuleId}
            setTimeInMinModule={setTimeInMinModule}
            // setOpen={setOpenPPT}
        />
      }
      {  (openAttemptLearningComponent || closeAll) && 
          <AttemptLearningComponent 
            open={openAttemptLearningComponent}
            setOpen={setOpenAttemptLearningComponent}
          />
      }
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  createModuleData: state.loginReducer.createModuleData,
});

export default connect(mapStateToProps, {
  getCreateModule,
})(ScoreModal);