import React, { useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from '@mui/material/Modal';
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import { 
    Avatar, 
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
    MenuItem,
    Select,
    Button,
   } from "@material-ui/core";
import Tooltip from "@mui/material/Tooltip";
import Slider, { SliderThumb } from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import CloseIcon from '@material-ui/icons/Close';
import { useEffect } from "react";
import QuestionModal from "./questionModal"
import PPTModal from "./PPTModule/pptModule"
import EditModules from "./EditModules/editModules"
import { getCreateModule } from "../../actions/loginActions"
import "./index.css"

let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: isMobile ? "400px" : "1200px",
    },
    head: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "2rem"
    }
}));

const PrettoSlider = styled(Slider)({
    color: '#52af77',
    height: 4,
    padding: 0,
    paddingTop: "15px",
    width: "80%",
    '& .MuiSlider-track': {
      border: 'none',
    },
    '& .MuiSlider-thumb': {
      height: 0,
      width: 0,
      backgroundColor: '#fff',
      border: '2px solid currentColor',
      '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
        boxShadow: 'inherit',
      },
      '&:before': {
        display: 'none',
      },
    },
    '& .MuiSlider-valueLabel': {
      lineHeight: 1.2,
      fontSize: 12,
      background: 'unset',
      padding: 0,
      width: 0,
      height: 0,
      borderRadius: '50% 50% 50% 0',
      backgroundColor: '#52af77',
      transformOrigin: 'bottom left',
      transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
      '&:before': { display: 'none' },
      '&.MuiSlider-valueLabelOpen': {
        transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
      },
      '& > *': {
        transform: 'rotate(45deg)',
      },
    },
  });

type ModalProps = {
    open: boolean;
    setOpen: Function;
    getCreateModule: any;
    createModuleData: any;
};

const LearningModal: React.FC<ModalProps> = ({
    open,
    setOpen,
    getCreateModule,
    createModuleData,
}) => {
    const classes = useStyles();

    const [query, setQuery] = useState("")
    const [description, setDescription] = useState("")
    const [status, setStatus] = useState("none")
    const [disposition, setDisposition] = useState<string>("none")
    const [createBookingId, setCreateBookingId] = useState<any>("")
    const [createAmount, setCreateAmount] = useState<any>("")
    const [bookingType, setBookingType] = useState<any>()
    const [fileURLModule, setFileURLModule] = useState<any>("")
    const [timeInMinModule, setTimeInMinModule] = useState<any>(0)
    const [timeInHrModule, setTimeInHrModule] = useState<any>(0)
    const [minimumScore, setMinimumScore] = useState<any>(0)
    const [moduleId, setModuleId] = useState<any>(0)
    const [moduleQuestion, setModuleQuestion] = useState<any>([])
    const [questionModalOpen, setQuestionModalOpen] = useState(false);
    const [viewOrAttempt, setViewOrAttempt] = useState(false);
    const [openPPT, setOpenPPT] = useState(false);
    const [editModulesOpen, setEditModulesOpen] = useState(false);
    const [key, setKey] = useState<any>(false);
    const blockCharInPhoneField = (e: React.KeyboardEvent<HTMLDivElement>) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();

    const handleClose = () => {
        if (createModuleData?.results[createModuleData?.count - 1]?.score_by_user === "Pass") {
            setOpen(false);
        }
    };

    useEffect( () => {
        getCreateModule();
    }, [])

    const [newA, setNewA] = useState<any>();

    const [newB, setNewB] = useState<any>();

    useEffect( () => {
        if (createModuleData && createModuleData?.count > 0 && newA?.length > 0) {
            createModuleData?.results?.map( (row: any, index: any) => (
                (row?.score_by_user === "Pass")
                ? (newA[index] = false, newB[index] = false)
                : (newA[index] = true, newB[index] = true)
            ))
            newB[newA.indexOf(true)] = false;
            setKey(!key)
        }
    }, [newA])

    useEffect( () => {
        if (createModuleData && createModuleData?.count > 0) {
            setNewA(new Array(createModuleData?.count).fill(true));
            setNewB(new Array(createModuleData?.count).fill(true));
        }
    }, [createModuleData, open])

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <div>
                            <div className="header" style={{ display: "flex", justifyContent: "space-between", paddingTop: "10px" }}>
                            <div>
                                {  createModuleData && createModuleData?.count > 0 && createModuleData?.results[createModuleData?.count - 1]?.score_by_user === "Pass" ?
                                    <CloseIcon style={{ color: "black", margin: "10px" }} onClick={() => handleClose()} />
                                    : ""
                                }
                            </div>
                            <IconButton>
                                <Tooltip title="Logo" arrow>
                                    <SchoolOutlinedIcon
                                      style={{ color: "black", margin: "10px" }}
                                    />
                                </Tooltip>
                            </IconButton>
                            </div>
                            <div className="body" style={{ overflow: "scroll" }}>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <h2 style={{ fontSize: "16px", padding: "25px 0 0 15px" }}>My Learning Modules</h2>
                                    {/* <Button style={{
                                        backgroundColor: "white",
                                        color: "rgb(12 12 12 / 93%)",
                                        borderRadius: "8px",
                                        textTransform: "none",
                                        width: "140px",
                                        height: "40px",
                                        margin: "25px 15px 0 0px",
                                        border: "1px solid rgb(12 12 12 / 93%)",
                                    }}
                                    onClick={() => {
                                        setEditModulesOpen(true);
                                    }}
                                    size="small"
                                    >
                                        <p style={{ margin: "0", fontSize: "15px" }}>Edit Modules</p>
                                    </Button> */}
                                </div>
                                { (newB?.length > 0 && newB[0] === false) ?
                                <div className="startPageDiv">
                                    {
                                        createModuleData && createModuleData?.count && createModuleData?.results?.map( (row: any, index: any) => (
                                            <div 
                                            style={{ 
                                                height: "180px", 
                                                display: "flex", 
                                                flexDirection: "column", 
                                                justifyContent: "space-between", 
                                                marginTop: "20px", 
                                                marginRight: "5%", 
                                                marginBottom: "20px",
                                                backgroundColor: (newB[index] === false) ? "white" : "#bebcbc47"
                                             }}>
                                                {
                                                    (newB[index] === false) ? 
                                                    <div className="slide" style={{ display: "flex" }}>
                                                        <PrettoSlider
                                                            valueLabelDisplay="auto"
                                                            aria-label="pretto slider"
                                                            defaultValue={(newB[index] === false && newA[index] === false) ? 100 : 0}
                                                            disabled={false}
                                                        />
                                                        <h6 style={{ marginLeft: "10px", fontSize: "13px" }}>{(newB[index] === false && newA[index] === false) ? "100%" : "0%"}</h6>
                                                    </div>
                                                    : ``
                                                }
                                                <div style={{ textAlign: "center" }}>
                                                    <span style={{ color: "#827d7d", padding: "0px 10px 0px 10px" }}>{row?.name !== null ? row?.name : "NO NAME"}</span>
                                                    <p style={{ color: "#827d7d", padding: "0px 10px 0px 10px" }}>{`${(row?.completion_hours * 60) + row?.completion_minutes} mins`}</p>
                                                </div>
                                                <div style={{ textAlign: "center" }}>
                                                    <Button style={{
                                                        backgroundColor: (newB[index] === false) ? "#c70e0ef7" : "white",
                                                        color: (newB[index] === false) ? "white" : "rgb(48 45 45 / 52%)",
                                                        padding: "6px 16px",
                                                        borderRadius: "5px",
                                                        textTransform: "none",
                                                    }}
                                                    onClick={() => {
                                                        setFileURLModule(row?.image);
                                                        (newA[index] === true) ? setTimeInMinModule(row?.completion_minutes) : setTimeInMinModule(0);
                                                        (newA[index] === true) ? setTimeInHrModule(row?.completion_hours) : setTimeInHrModule(0);
                                                        setOpenPPT(true);
                                                        setModuleId(row?.id);
                                                        setModuleQuestion(row?.assignment_question_module);
                                                        setMinimumScore(row?.minimum_score);
                                                        ( newB[index] === false && newA[index] === true ) ? setViewOrAttempt(false) : setViewOrAttempt(true);
                                                    }}
                                                    disabled={newB[index] === true}
                                                    size="small"
                                                    >
                                                        {
                                                            (newA[index] === true) ? 
                                                            <p style={{ margin: "0", fontSize: "12px" }}>Start Module</p> :
                                                            <p style={{ margin: "0", fontSize: "12px" }}>View Module</p>
                                                        }
                                                    </Button>
                                                </div>
                                            </div>
                                        ) )
                                    }
                                </div>
                                : ""
                                }
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>
            {/* <QuestionModal
                questionModalOpen={questionModalOpen}
                setQuestionModalOpen={setQuestionModalOpen}
                moduleId={moduleId}
                moduleQuestion={moduleQuestion}
                minimumScore={minimumScore}
                fileUrl={fileUrl}
            /> */}
            {   openPPT && 
                <PPTModal
                    open={openPPT}
                    setOpen={setOpenPPT}
                    fileUrl={`${fileURLModule}`}
                    timeInMinModule={timeInMinModule}
                    timeInHrModule={timeInHrModule}
                    viewOrAttempt={viewOrAttempt}
                    moduleId={moduleId}
                    moduleQuestion={moduleQuestion}
                    minimumScore={minimumScore}
                    setTimeInHrModule={setTimeInHrModule}
                    setModuleId={setModuleId}
                    setTimeInMinModule={setTimeInMinModule}
                />
            }
        </div>
    );
};

const mapStateToProps = (state: any) => ({
    createModuleData: state.loginReducer.createModuleData,
});

export default connect(mapStateToProps, {
    getCreateModule,
})(LearningModal);