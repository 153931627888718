import React, { useState, useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
    Chip,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    Grid,
    TextField,
    withStyles,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from "@material-ui/core";
import Button from '@mui/material/Button';
import { useRef } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import { MessageRounded } from "@material-ui/icons";

import Autocomplete from "@material-ui/lab/Autocomplete";
import { useHistory } from "react-router";
import { TablePagination } from "@material-ui/core";
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        paper: {
            marginTop: "2rem",
            padding: "0.5rem 2rem",
            width: "100%",
        },
        table: {
            maxWidth: "100%",
            margin: "auto",
        },
        toolbar: {
            alignItems: "center",
            justifyContent: "flex-end",
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        },
        listStyle: {
            marginLeft: "20px",
        },
        tableContainer: {
            marginTop: "2rem",
            position: "sticky",
            top: "200px",
        },
    })
);

const StyledTableCell: any = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: "#924A91",
            color: theme.palette.common.white,
            fontSize: "14px",
        },
        body: {
            fontSize: 14,
        },
    })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    })
)(TableRow);

interface Props {
    loading: any;
    batchTransferData: any;
    UpdateBatchAmountReceipt: any;
    getBatchTransferData: any;
    filterBatchPayment?: any;
    page: any;
    setPage: any;
}

const CenterTable: React.FC<Props> = ({
    loading,
    batchTransferData,
    UpdateBatchAmountReceipt,
    getBatchTransferData,
    filterBatchPayment,
    page,
    setPage,
}) => {
    const classes = useStyles();
    const history = useHistory();
    const timer = useRef<any>(0);
    const [image, setImage] = useState<any>("");
    const [viewImg, setViewImg] = useState<boolean>(false);
    const [receiptUpdateView, setReceiptView] = useState<boolean>(false);
    const [receiptNumber, setReceiptNumber] = useState<any>("")
    const [file1, setFile1] = React.useState<any>("");
    const [imgSrc, setImgSrc] = useState<any>("")
    const [id, setId] = useState<any>("")
    const handleViewImg = (val: any) => {
        setImage(val?.receipt);
        setViewImg(true);
    };
    const handleCloseImg = () => {
        setViewImg(false);
        setImage("");
    };
    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        if (newPage > page) {
            let url = batchTransferData && batchTransferData?.next.split("?")[1];
            getBatchTransferData(`${url ? `${url}` : ""}`);
        } else if (newPage < page) {
            let url = batchTransferData && batchTransferData?.previous.split("?")[1];
            getBatchTransferData(`${url ? `${url}` : ""}`);
        }
        setPage(newPage as number);
    };
    const handleUpdateReceipt = (id: any) => {
        setReceiptView(true)
        setImgSrc("")
        setReceiptNumber("")
        setFile1("")
        setId(id)
    }
    const handleCloseReceipt = () => {
        setReceiptView(false)
    }
    const fileSelectedHandler1 = async (e: React.ChangeEvent<{ files: any }>) => {
        setImgSrc(URL.createObjectURL(e.target.files[0]))
        const base64 = await convertBase64(e.target.files[0])
        setFile1(base64);
    };
    const convertBase64 = (file: any) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }
    const handleSubmit = async (e: any) => {
        e.preventDefault();
        const body = {
            receipt: file1,
            receipt_number: receiptNumber
        }
        await UpdateBatchAmountReceipt(id, body)
        setImgSrc("")
        setReceiptNumber("")
        setFile1("")
        setReceiptView(false)
        if (filterBatchPayment)
            filterBatchPayment()
    }
    return (
        <div style={{ width: "100%" }}>
            <div className="data-table">
                <TableContainer
                    className={classes.tableContainer}
                    component={Paper}
                    style={{ maxHeight: "740px" }}
                >
                    <Table stickyHeader aria-label="simple table">
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell align="center">Batch</StyledTableCell>
                                <StyledTableCell align="center">finance Remark</StyledTableCell>
                                <StyledTableCell align="center">Remark</StyledTableCell>
                                <StyledTableCell align="center">Amount</StyledTableCell>
                                <StyledTableCell align="center">Deposit Amount</StyledTableCell>
                                <StyledTableCell align="center">Receipt Number</StyledTableCell>
                                <StyledTableCell align="center">Receipt Image</StyledTableCell>
                                <StyledTableCell align="center">Update Receipt</StyledTableCell>
                                <StyledTableCell align="center">Status</StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {batchTransferData && batchTransferData?.results && batchTransferData?.results.length > 0 && batchTransferData?.results.map((data: any) => {
                                return (
                                    <StyledTableRow>
                                        <StyledTableCell style={{ height: "50px" }} align="center"
                                        >
                                            {data?.batches.map((batch: any) => {
                                                let len = data?.batches?.length;
                                                if (len > 0 && data?.batches[len - 1] === batch) return batch;
                                                return batch + ", "
                                            })}
                                        </StyledTableCell>
                                        <StyledTableCell style={{ height: "50px" }} align="center"
                                        >
                                            {data?.finance_remark}
                                        </StyledTableCell>
                                        <StyledTableCell style={{ height: "50px" }} align="center"
                                        >
                                            {data?.remark}
                                        </StyledTableCell>
                                        <StyledTableCell style={{ height: "50px" }} align="center"
                                        >
                                            {data?.amount}
                                        </StyledTableCell>
                                        <StyledTableCell style={{ height: "50px" }} align="center"
                                        >
                                            {data?.deposit_amount}
                                        </StyledTableCell>
                                        <StyledTableCell style={{ height: "50px" }} align="center"
                                        >
                                            {data?.receipt_number}
                                        </StyledTableCell>
                                        <StyledTableCell style={{ height: "50px" }} align="center"
                                        >
                                            {data.receipt ? (
                                                <InsertPhotoIcon
                                                    onClick={() => handleViewImg(data)}
                                                />
                                            ) : (
                                                ""
                                            )}
                                        </StyledTableCell>
                                        <StyledTableCell style={{ height: "50px" }} align="center"
                                        >{data.status !== "accepted" ?
                                            <BrowserUpdatedIcon
                                                onClick={() => handleUpdateReceipt(data?.id)}
                                            />
                                            : ""}

                                        </StyledTableCell>
                                        <StyledTableCell style={{ height: "50px" }} align="center"
                                        >
                                            {data?.status}
                                        </StyledTableCell>

                                    </StyledTableRow>

                                )
                            })}

                        </TableBody>
                        <TableFooter>
                            <StyledTableRow>
                                <TablePagination
                                    rowsPerPageOptions={[]}
                                    colSpan={3}
                                    count={batchTransferData.count || 0}
                                    rowsPerPage={batchTransferData.page_size}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    SelectProps={{
                                        native: true,
                                    }}
                                />
                            </StyledTableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
                <Dialog
                    onClose={handleCloseImg}
                    aria-labelledby="customized-dialog-title"
                    open={viewImg}
                >
                    <DialogTitle id="customized-dialog-title">Reciept Image</DialogTitle>
                    <DialogContent dividers>
                        <img src={image} width="400" />
                    </DialogContent>
                </Dialog>
                <Dialog
                    onClose={handleCloseReceipt}
                    aria-labelledby="customized-dialog-title"
                    open={receiptUpdateView}
                >
                    <DialogTitle id="customized-dialog-title">Update Reciept</DialogTitle>
                    <DialogContent dividers>
                        <form onSubmit={handleSubmit}>
                            <Grid
                                container
                                spacing={1}
                                direction="row"
                                style={{ alignItems: "left" }}
                            >
                                <Grid item xs={6} sm={6} md={6}>
                                    <TextField
                                        className="input"
                                        name="receipt"
                                        type="text"
                                        required
                                        label="Receipt Number"
                                        value={receiptNumber}
                                        variant="outlined"
                                        onChange={(e) => setReceiptNumber(e.target.value)}
                                        style={{ width: "100%", height: "30px" }}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={6} md={6}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        fullWidth
                                        startIcon={<BrowserUpdatedIcon />}
                                    >
                                        Update Receipt
                                    </Button>
                                </Grid>

                                <Grid item xs={6} sm={6} md={6}>
                                    <sup
                                        style={{
                                            fontSize: "11px",
                                            marginLeft: "15px",
                                            marginTop: "15px",
                                            background: "#fff",
                                            color: "rgba(0, 0, 0, 0.54)",
                                            letterSpacing: "0.00938em",
                                        }}
                                    >
                                        Upload Receipt
                                    </sup>
                                    <input
                                        className="input"
                                        required
                                        style={{
                                            width: "100%",
                                            marginTop: "-15px",
                                            marginBottom: "10px",
                                            padding: "18.5px 14px",
                                            border: "1px solid rgba(0, 0, 0, 0.23)",
                                            borderRadius: "5px",
                                        }}
                                        type="file"
                                        name="file"
                                        id="file"
                                        multiple
                                        onChange={fileSelectedHandler1}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={6} md={6}>
                                    {imgSrc === "" ? "" : (
                                        <img src={imgSrc ? imgSrc : ""} style={{ width: "200px", height: "200px" }} />
                                    )}

                                </Grid>
                            </Grid>
                        </form>
                    </DialogContent>
                </Dialog>
            </div>
        </div>
    );
};

const mapStateToProps = (state: any) => ({

});

export default connect(mapStateToProps, {

})(CenterTable);
