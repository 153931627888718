import React, { useState, useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { getRiderList, getRiderAttendance, getLabDetails, updateMeter, postMeter, getUserDetails } from '../../actions/LogisticsPanelAction';
import {
    Grid,
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TableFooter,
    TablePagination,
    Container,
    Box,
    TextField,
    withStyles,
    Dialog,
} from '@material-ui/core';
import { generateAttendenceFilterUrl } from "../../../helpers/generateUrl";
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@mui/material/Button';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { useEffect } from "react";
import Loader from "../loader";
import { DeleteForever, Edit } from "@material-ui/icons";
import { useHistory } from "react-router";
import Modal from '@mui/material/Modal';
import moment from "moment";
import { fontWeight } from "@mui/system";
// import Bs from "../Comments/editMeter";
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 0,
        },
        toolbar: {
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        },
        content: {
            width: "90%",
            flexGrow: 21,
            //   padding: theme.spacing(3),
        },
        paper: {
            padding: "1rem",
            width: "100%",
            borderRadius: "10px"
        },
        head: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "1rem",
            textAlign: "center"
        },
        heading: {
            fontWeight: "normal",
            fontSize: "1.2rem",
            margin: "0"
        },
        table: {
            margin: "auto",
            overflow: "auto"
        },
        image: {
            width: "100px",
            height: "auto",
        },
        imageSM: {
            width: "60px",
            height: "60px",
            border: "2px solid #000 ",
            borderRadius: "50%"
        },
        imgContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexWrap: "wrap"
        },
        imagePaper: {
            position: 'absolute',
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #000',
            boxShadow: theme.shadows[1],
        },
        tableContainer: {
            marginTop: "2rem",
            position: "sticky",
            top: "200px",
        },
    })
);
function rand() {
    return Math.round(Math.random() * 20) - 10;
}
function getModalStyle() {
    const top = 50 + rand();
    const left = 50 + rand();

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}
const StyledTableCell: any = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: "#924A91",
            color: theme.palette.common.white,
            fontSize: "14px",
        },
        body: {
            fontSize: 14,
        },
    })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    })
)(TableRow);

interface Props {
    getAttenList: any;
    riderAttendance: any;
    getRiderAttendance: any
    riderList: any;
    loading: any;
    getRiderList: any
    getLabDetails: any;
    labDetails: any;
    updateMeter: any;
    postMeter: any;
    getUserDetails: any;
    userDetails: any;
}

const AttendancePage: React.FC<Props> = ({
    getAttenList,
    riderAttendance,
    riderList,
    getRiderAttendance,
    loading,
    getRiderList,
    getLabDetails,
    labDetails,
    updateMeter,
    postMeter,
    getUserDetails,
    userDetails,
}) => {
    const classes = useStyles();
    const [page, setPage] = useState(0)
    const [start_date, setStart_date] = useState("")
    const [end_date, setEnd_date] = useState("")
    const [created_date, setCreated_date] = useState("")
    const [rider, setRider] = useState<any>([])
    const timer = useRef<any>(0)
    const [openImage, setImageOpen] = React.useState(false);
    const [imageUrl, setImageUrl] = useState("")
    const [labId, setlabId] = useState<any>("")
    const [modalStyle] = React.useState(getModalStyle);
    const [openDataModal, setOpenDataModal] = useState<any>(false);
    const [isEdit, setIsEdit] = useState<any>(false);
    const [editId, setEditId] = useState<any>("");
    const [meterStart, setMeterStart] = React.useState<any>("");
    const [meterEnd, setMeterEnd] = React.useState<any>("");
    const [phoneNumber, setPhoneNumber] = useState<any>("");
    const history = useHistory()
    const [disableDownloadBtn, setDisableDownloadBtn] = useState<boolean>(false);

    useEffect(() => {
        getRiderList(`?usergroup=Rider`);
        getRiderAttendance(`?rider=true`)
        getLabDetails()
        getUserDetails()
        // getAttenList()
    }, [])

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        if (newPage > page) {
            let url = riderAttendance?.links && riderAttendance?.links?.next.split("?")[1];
            getRiderAttendance(`?${url}`);
        } else if (newPage < page) {
            let url = riderAttendance?.links && riderAttendance?.links?.previous.split("?")[1];
            getRiderAttendance(`?${url}`);
        }
        setPage(newPage as number);
    };
    const handleReset = () => { };

    const handleSave = async (e: any) => {
        e.preventDefault();
        const data = {
            meter_start_reading: meterStart,
            meter_end_reading: meterEnd,
        };
        if (isEdit) {
            if (Number(meterStart) < Number(meterEnd)) {
                await updateMeter(data, editId);
                history.push("/dashboard/lp/attendance")
            } else {
                alert("Meter End Reading should be greater than Meter Start Reading.")
            }
        } else {
            await postMeter(data);
        }

        handleReset();
        getAttenList();
    };
    const handleEdit = (val: any) => {
        setIsEdit(true);
        setEditId(val.id);
        setMeterStart(val?.meter_start_reading);
        setMeterEnd(val?.meter_end_reading);
    };
    const filterAttendence = () => {
        const body: any = {
            start_date,
            end_date,
            created_date,
            rider: rider.join(","),
            lab: labId,
            phonenumber: phoneNumber,
        }
        const url = generateAttendenceFilterUrl(body).substring(2)
        getRiderAttendance(`?rider=true&${url}`)
        setPage(0)
    }
    const handleDownload = () => {
        setDisableDownloadBtn(true);
        getRiderAttendance(`?rider=true&download=true`)
    }

    const handleStartModal = (e: any) => {
        setImageUrl(e)
        setImageOpen(true)

    }
    const handleEndModal = (e: any) => {
        setImageUrl(e)
        setImageOpen(true)
    }

    // const handleEditMeter = () => {
    // }

    const handleImageClose = () => {
        setImageOpen(false)
    }

    const handleClose = () => {
        setOpenDataModal(false)
        getRiderAttendance()
    }

    const body = (
        <div style={modalStyle} className={classes.imagePaper}>
            <img src={imageUrl} alt="popup" style={{ width: "750px", height: "670px" }} />
            {/* <SimpleModal /> */}
        </div>
    );

    useEffect(() => {
        if (riderAttendance.success) {
            setOpenDataModal(true)
        }
    }, [riderAttendance])

    useEffect(() => {
        if (disableDownloadBtn) {
            setTimeout(() => {
                setDisableDownloadBtn(false);
            }, 10000)
        }
    }, [disableDownloadBtn])

    return (
        <>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <Grid item xs={12}>
                    <h4 style={{ fontWeight: "bold" }}>ATTENDANCE</h4>
                </Grid>
                <Grid
                    container
                    spacing={1}
                    direction="row"
                >
                    <Grid item xs={12} sm={4} md={2}>
                        <Autocomplete
                            id="lab"
                            onChange={(event, newValue) => {
                                if (newValue) {
                                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                    setlabId(obj.id)
                                }

                            }}
                            freeSolo
                            blurOnSelect
                            aria-required
                            options={labDetails?.results || []}
                            disableClearable
                            disableCloseOnSelect
                            getOptionLabel={(option: any) => String(option?.name)}
                            onInputChange={(event, newInputValue) => {
                                clearTimeout(timer.current)
                                timer.current = setTimeout(() => {
                                    getLabDetails(`?name=${newInputValue}`)
                                }, 1000)
                                if (newInputValue.length === 0) {
                                    setlabId("")
                                }
                            }}
                            renderInput={(params) => (
                                <TextField className="input"
                                    {...params}
                                    placeholder="Lab Name"
                                    variant="outlined"
                                    style={{ width: "100%", margin: "0", padding: "0" }}
                                    inputProps={{
                                        ...params.inputProps,
                                        onKeyDown: (e) => {
                                            if (e.key === 'Enter') {
                                                e.stopPropagation();
                                            }
                                        },
                                    }}
                                    required
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Autocomplete
                            id="rider"
                            onChange={(event, newValue) => {
                                let sourceIds: any = []
                                if (newValue) {
                                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                    sourceIds = obj.map((item: any) => item.id)
                                }
                                setRider(sourceIds)
                            }}
                            freeSolo
                            blurOnSelect
                            aria-required
                            limitTags={1}
                            multiple
                            options={riderList.results || []}
                            getOptionDisabled={(option: any) => {
                                return rider.includes(option.id)
                            }}
                            disableClearable
                            disableCloseOnSelect
                            getOptionLabel={(option: any) => option.user && option.user.username}
                            onInputChange={(event, newInputValue) => {
                                clearTimeout(timer.current)
                                timer.current = setTimeout(() => {
                                    getRiderList(`?usergroup=Rider&code=${newInputValue}`)
                                }, 1000)
                                if (newInputValue.length === 0) {
                                    setRider([])
                                }
                            }}
                            renderInput={(params) => (
                                <TextField className="input"
                                    {...params}
                                    placeholder="Rider Name"
                                    variant="outlined"
                                    style={{ width: "100%", margin: "0", padding: "0" }}
                                    required
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <TextField
                            name="end_date"
                            type="number"
                            className="input"
                            label="PhoneNumber"
                            variant="outlined"
                            onChange={(e) => setPhoneNumber(e.target.value as string)}
                            style={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <TextField
                            className="input"
                            name="start_date"
                            type="date"
                            label="Start Date"
                            value={start_date}
                            variant="outlined"
                            disabled={created_date !== ""}
                            onChange={(e) => setStart_date(e.target.value as string)}
                            style={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <TextField
                            name="end_date"
                            type="date"
                            value={end_date}
                            className="input"
                            label="End Date"
                            variant="outlined"
                            disabled={created_date !== ""}
                            onChange={(e) => setEnd_date(e.target.value as string)}
                            style={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <TextField
                            name="created_date"
                            type="date"
                            value={start_date === "" || end_date === "" ? created_date : ""}
                            className="input"
                            label="Created Date"
                            variant="outlined"
                            disabled={start_date !== "" || end_date !== ""}
                            onChange={(e) => setCreated_date(e.target.value as string)}
                            style={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Button
                            variant="contained"
                            color="secondary"
                            disabled={loading}
                            fullWidth
                            onClick={filterAttendence}
                        >
                            Filter
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Button
                            variant="contained"
                            color="primary"

                            fullWidth
                            onClick={() => history.push("/dashboard/lp/attendance")}
                        >
                            Reset
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Button
                            variant="contained"
                            color="secondary"

                            fullWidth
                            onClick={handleDownload}
                            disabled={disableDownloadBtn}
                        >
                            Download Data
                        </Button>
                    </Grid>
                </Grid>
                <form style={{ width: "100%", paddingTop: "40px" }} onSubmit={handleSave}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={2}>
                            <TextField
                                label="Meter Start Reading"
                                variant="outlined"
                                className="input"
                                fullWidth
                                type="number"
                                required
                                value={meterStart}
                                disabled={!isEdit}
                                onChange={(e) => setMeterStart(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                            <TextField
                                label="Meter End Reading"
                                variant="outlined"
                                className="input"
                                type="number"
                                fullWidth
                                required
                                value={meterEnd}
                                disabled={!isEdit}
                                onChange={(e) => setMeterEnd(e.target.value)}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={2}>
                            <Button
                                color="primary"
                                variant="contained"
                                fullWidth
                                type="submit"
                                style={{ height: "100%" }}
                                disabled={!isEdit}
                            >
                                {isEdit ? "update" : "save"}
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                            <Button
                                color="secondary"
                                variant="contained"
                                fullWidth
                                onClick={() => history.push("/dashboard/lp/attendance")}
                                style={{ height: "100%" }}
                            >
                                reset
                            </Button>
                        </Grid>
                    </Grid>
                </form>

                {/* <Paper elevation={5} className={classes.paper}> */}
                {loading ? <Loader /> : (
                    <TableContainer className={classes.tableContainer} component={Paper} style={{ maxHeight: "560px" }}>
                        <Table stickyHeader aria-label="simple table">
                            <TableHead>
                                <StyledTableRow>
                                    <StyledTableCell align="center">Date</StyledTableCell>
                                    <StyledTableCell align="center">Rider Name</StyledTableCell>
                                    <StyledTableCell align="center">Rider Phone Number</StyledTableCell> 
                                    <StyledTableCell align="center">Rider OTP</StyledTableCell>
                                    <StyledTableCell align="center">Punch In Time</StyledTableCell>
                                    <StyledTableCell align="center">Punch Out Time</StyledTableCell>
                                    <StyledTableCell align="center">Meter Start</StyledTableCell>
                                    <StyledTableCell align="center">Meter End</StyledTableCell>
                                    <StyledTableCell align="center">Meter Total Reading</StyledTableCell>
                                    <StyledTableCell align="center">Meter Reading Start Image</StyledTableCell>
                                    <StyledTableCell align="center">Meter Reading End Image</StyledTableCell>
                                    <StyledTableCell align="center">Meter Edit Options</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {riderAttendance?.results && riderAttendance?.results.length > 0 && riderAttendance?.results.map((attend: any) => {
                                    return (
                                        <StyledTableRow key={attend?.id}>
                                            <StyledTableCell align="center">{attend?.attendance_date}</StyledTableCell>
                                            <StyledTableCell align="center">{attend?.rider?.fullname}</StyledTableCell>
                                            <StyledTableCell align="center">{attend?.rider?.phonenumber}</StyledTableCell> 
                                            <StyledTableCell align="center">{attend?.otp}</StyledTableCell>
                                            <StyledTableCell align="center">{attend?.start_time}</StyledTableCell>
                                            <StyledTableCell align="center">{attend?.end_time}</StyledTableCell>
                                            <StyledTableCell align="center">{attend?.meter_start_reading}</StyledTableCell>
                                            <StyledTableCell align="center">{attend?.meter_end_reading}</StyledTableCell>
                                            <StyledTableCell align="center">{attend?.total_reading}</StyledTableCell>
                                            {Object.keys(attend?.start_attendance_image).length === 0 ?
                                                <StyledTableCell align="center">NA</StyledTableCell> :
                                                <StyledTableCell align="center" onClick={() => handleStartModal(attend?.start_attendance_image.image)}><img key={attend?.start_attendance_image.id} src={attend?.start_attendance_image.image} alt="startimage" className={classes.imageSM} /></StyledTableCell>
                                            }
                                            {Object.keys(attend?.end_attendance_image).length === 0 ?
                                                <StyledTableCell align="center">NA</StyledTableCell> :
                                                <StyledTableCell align="center" onClick={() => handleEndModal(attend?.end_attendance_image.image)}><img key={attend?.end_attendance_image.id} src={attend?.end_attendance_image.image} alt="startimage" className={classes.imageSM} /></StyledTableCell>
                                            }
                                            <StyledTableCell align="center">
                                                <Button
                                                    color="primary"
                                                    variant="contained"
                                                    onClick={() => handleEdit(attend)}
                                                    disabled={userDetails?.profile?.executive_type !== "Admin"}
                                                >
                                                    edit
                                                </Button>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    )
                                })}
                            </TableBody>
                            <TableFooter>
                                <StyledTableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[]}
                                        colSpan={3}
                                        count={(riderAttendance && riderAttendance?.count) || 0}
                                        rowsPerPage={riderAttendance && riderAttendance?.page_size}
                                        page={page}
                                        onPageChange={handleChangePage}
                                    />
                                </StyledTableRow>
                            </TableFooter>

                        </Table>
                    </TableContainer>
                )}
                {/* </Paper> */}
                <Dialog
                    open={openDataModal}
                    onClose={handleClose}
                >
                    <h5 style={{
                        margin: "0px",
                        padding: "15px",
                        background: "#7CB342",
                        color: "white",
                        fontWeight: "bold"
                    }}>Your request has been submitted. System will send on email.</h5>
                </Dialog>
                <Modal
                    open={openImage}
                    onClose={handleImageClose}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    {body}
                </Modal>
            </main>
        </>
    );
};


const mapStateToProps = (state: any) => ({
    riderAttendance: state.LogisticsReducer.riderAttendance,
    getAttenList: state.LogisticsReducer.getAttenList,
    riderList: state.LogisticsReducer.riderList,
    labDetails: state.LogisticsReducer.labDetails,
    userDetails: state.LogisticsReducer.userDetails,
    loading: state.LogisticsReducer.loading,
})

export default connect(mapStateToProps, {
    getRiderAttendance,
    getRiderList,
    getLabDetails,
    updateMeter,
    postMeter,
    getUserDetails,
})(AttendancePage);