import {
  ACTIONS,
  BookingTypes,
} from "../interfaces/actionTypes/productManagerType";

type InitialState = {
  users: Object;
  areaList: Object;
  area: Object;
  zoneList: Object;
  zone: Object;
  timeslotsList: Object;
  blogList: Object;
  mapmyindiaAccessToken: Array<any>;
  blog: Object;
  blogBySlug: Object;
  blogCategories: Object;
  blogTags: Object;
  blogImages: Object;
  coupon: Object;
  pack: Object;
  cityPriceList: Object;
  loading: boolean;
  pin_code: Array<any>;
  cities: Array<any>;
  agentsList: Array<any>;
  phleboList: Array<any>;
  packageList: Array<any>;
  partnerList: Array<any>;
  couponList: Array<any>;
  createBooking: Object;
  booking: Object;
  todays_positive_calls: Object;
  lead_details: Object;
  leadById: Object;
  leads: Array<any>;
  bookingList: Array<any>;
  comment: any;
  bookingComplaints: Array<any>;
  additional_booking: object;
  data: any;
  bookingsComment: any;
  lead_id: number;
  read_call_details: object;
  all_recordings: object;
  whatsappComments: object;
  whatsappCommentsByLead: object;
  callStats: object;
  complaint: object;
  complaints: object;
  lead_source: any;
  booking_slots: object;
  incorrectNumberLeads: Array<any>;
  search_results: object;
  call_status: object;
  all_lead: object;
  call_transfer: object;
  user_panel: object;
  agentBYUserGroup: object;
  labCredential: Array<any>;
  disposition: Array<any>;
  updateLabCredential: Array<any>;
  dispositions: object;
  dispositionData: Array<any>;
  zoneComment: any;
  userDetails: Array<any>;
  sameNumberBooking: boolean;
  paymentSendLink: Array<any>;
  paymentReSendLink: Array<any>;
  geoFencesMapMyIndia: Array<any>;
  paymentCancelLink: Array<any>;
  smsReportSend: Array<any>;
  breakData: Array<any>;
  assigneUserList: Array<any>;
  verificationBookingDetails: Array<any>;
  todayCallDetails: object;
  topRecord: object;
  smsDetails: Array<any>;
  emailDetails: Array<any>;
  reportStatus: Array<any>;
  collectionSlot: Array<any>;
  centerInfo: Array<any>;
  labDetails: Array<any>;
  syncData: Object;
  bookingoverview: Array<any>;
  sendSMSLink: Array<any>;
  reportDownload: Array<any>;
  paymentInformation: Array<any>;
  notificationMessage: Array<any>;
  unmaskedNumber: Array<any>;
  packageDetails: Array<any>;
  packageCategory: Array<any>;
  packageTags: Array<any>;
  packageComments: Array<any>;
  checkGeoFenceStatus: boolean;
  packageListByName: Array<any>;
  qrcode: Array<any>;
  ticketData: Array<any>;
  ticketCategories: Array<any>;
  ticketSubCategories: Array<any>;
  redtechcomment: Array<any>;
  packageManagement: Array<any>;
  packagecreate: Array<any>;
  updatePackageManagement: Array<any>;
  citywisepackage: Array<any>;
  tatpackageupdated: Array<any>;
  tat: Object;
  tagslist: Array<any>;
  packagecategory: Array<any>;
  testsCrm: Array<any>;
  ticketComments: Array<any>;
  bookingListLoader: boolean;
};

const initialState: InitialState = {
  syncData: [],
  sendSMSLink: [],
  users: {},
  areaList: {},
  area: {},
  zoneList: {},
  zone: {},
  timeslotsList: {},
  geoFencesMapMyIndia: [],
  blogList: {},
  mapmyindiaAccessToken: [],
  blog: {},
  blogBySlug: {},
  blogCategories: {},
  blogTags: {},
  blogImages: {},
  coupon: {},
  pack: {},
  cityPriceList: {},
  additional_booking: {},
  loading: false,
  pin_code: [],
  cities: [],
  agentsList: [],
  phleboList: [],
  packageList: [],
  partnerList: [],
  couponList: [],
  createBooking: {},
  booking: {},
  lead_details: {},
  leadById: {},
  todays_positive_calls: {},
  leads: [],
  bookingList: [],
  comment: {},
  data: {},
  read_call_details: {},
  all_recordings: {},
  whatsappComments: {},
  whatsappCommentsByLead: {},
  callStats: {},
  complaint: {},
  complaints: {},
  bookingsComment: {},
  lead_id: 0,
  lead_source: {},
  booking_slots: {},
  search_results: {},
  call_status: {},
  all_lead: {},
  call_transfer: {},
  agentBYUserGroup: {},
  user_panel: {},
  userDetails: [],
  labCredential: [],
  updateLabCredential: [],
  dispositions: {},
  disposition: [],
  zoneComment: {},
  incorrectNumberLeads: [],
  sameNumberBooking: false,
  dispositionData: [],
  paymentSendLink: [],
  paymentReSendLink: [],
  paymentCancelLink: [],
  smsReportSend: [],
  breakData: [],
  assigneUserList: [],
  todayCallDetails: {},
  topRecord: {},
  verificationBookingDetails: [],
  smsDetails: [],
  emailDetails: [],
  reportStatus: [],
  centerInfo: [],
  labDetails: [],
  reportDownload: [],
  paymentInformation: [],
  notificationMessage: [],
  unmaskedNumber: [],
  bookingoverview: [],
  packageDetails: [],
  packageCategory: [],
  packageTags: [],
  collectionSlot: [],
  packageComments: [],
  packageListByName: [],
  qrcode: [],
  checkGeoFenceStatus: false,
  ticketData: [],
  ticketCategories: [],
  ticketSubCategories: [],
  redtechcomment: [],
  packageManagement: [],
  packagecreate: [],
  updatePackageManagement: [],
  citywisepackage: [],
  tatpackageupdated: [],
  tat: {},
  tagslist: [],
  packagecategory: [],
  testsCrm: [],
  ticketComments: [],
  bookingComplaints: [],
  bookingListLoader: false,
};

const ProductManagerReducer = (state = initialState, action: BookingTypes) => {
  switch (action.type) {
    case ACTIONS.GET_USERS_LIST:
      return {
        ...state,
        users: action.payload,
        loading: false,
      };
    case ACTIONS.GET_TESTS_CRM:
      return {
        ...state,
        testsCrm: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CATEGORY:
      return {
        ...state,
        packagecategory: action.payload,
        loading: false,
      };
    case ACTIONS.GET_SYNC_DATA:
      return {
        ...state,
        syncData: action.payload,
        loading: false,
      };
    case ACTIONS.GET_SMS_LINK:
      return {
        ...state,
        sendSMSLink: action.payload,
        loading: false,
      };
    case ACTIONS.UPDATE_USERS_LIST:
      return {
        ...state,
        users: action.payload,
        loading: false,
      };
    case ACTIONS.CREATE_AREA:
      return {
        ...state,
        area: action.payload,
        loading: false,
      };
    case ACTIONS.UPDATE_AREA:
      return {
        ...state,
        area: action.payload,
        loading: false,
      };
    case ACTIONS.CHECK_GEOFENCE_AREA_MAMPMYINDIA:
      return {
        ...state,
        geoFencesMapMyIndia: action.payload,
        loading: false,
      };
    case ACTIONS.GET_AREA:
      return {
        ...state,
        areaList: action.payload,
        loading: false,
      };
    case ACTIONS.CREATE_ZONE:
      return {
        ...state,
        zone: action.payload,
        loading: false,
      };
    case ACTIONS.UPDATE_ZONE:
      return {
        ...state,
        zone: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ZONE:
      return {
        ...state,
        zoneList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_TIMESLOTS:
      return {
        ...state,
        timeslotsList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BLOG_CATEGORY:
      return {
        ...state,
        blogCategories: action.payload,
        loading: false,
      };
    case ACTIONS.GET_MAMPMYINDIA_ACCESS_TOKEN:
      return {
        ...state,
        mapmyindiaAccessToken: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BLOG_TAGS:
      return {
        ...state,
        blogTags: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BLOG_IMAGES:
      return {
        ...state,
        blogImages: action.payload,
        loading: false,
      };
    case ACTIONS.CREATE_BLOGS:
      return {
        ...state,
        blog: action.payload,
        loading: false,
      };
    case ACTIONS.UPDATE_BLOGS:
      return {
        ...state,
        blog: action.payload,
        loading: false,
      };
    case ACTIONS.DELETE_BLOG:
      return {
        ...state,
        blog: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BLOGS:
      return {
        ...state,
        blogList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BLOG_BY_SLUG:
      return {
        ...state,
        blogBySlug: action.payload,
        loading: false,
      };
    case ACTIONS.CREATE_COUPON:
      return {
        ...state,
        coupon: action.payload,
        loading: false,
      };
    case ACTIONS.UPDATE_COUPON:
      return {
        ...state,
        coupon: action.payload,
        loading: false,
      };
    case ACTIONS.CREATE_PACKAGE:
      return {
        ...state,
        pack: action.payload,
        loading: false,
      };
    case ACTIONS.GET_COLLECTION_SLOT:
      return {
        ...state,
        collectionSlot: action.payload,
        loading: false,
      };
    case ACTIONS.CREATE_ADDITIONAL_BOOKING:
      return {
        ...state,
        additional_booking: action.payload,
        loading: false,
      };
    case ACTIONS.UPDATE_ADDITIONAL_BOOKING:
      return {
        ...state,
        additional_booking: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PACKAGE:
      return {
        ...state,
        pack: action.payload,
        loading: false,
      };
    case ACTIONS.UPDATE_PACKAGE:
      return {
        ...state,
        pack: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CITY_PRICES:
      return {
        ...state,
        cityPriceList: action.payload,
        loading: false,
      };
    case ACTIONS.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case ACTIONS.GET_PIN_CODE:
      return {
        ...state,
        pin_code: action.payload,
        loading: false,
      };
    case ACTIONS.UPLOAD_FILE:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PHLEBOS:
      return {
        ...state,
        phleboList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CITIES:
      return {
        ...state,
        cities: action.payload,
        loading: false,
      };
    case ACTIONS.GET_POSTIVE_CALLS_COMMENTS:
      return {
        ...state,
        todays_positive_calls: action.payload,
        loading: false,
      };
    case ACTIONS.SET_COMMENTS:
      return {
        ...state,
        comment: action.payload,
        loading: false,
      };
    case ACTIONS.ADD_POSTIVE_CALLS_COMMENT:
      return {
        ...state,
        todays_positive_calls: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LEAD_DETAILS:
      return {
        ...state,
        lead_details: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LEADS:
      return {
        ...state,
        leads: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LEAD_BY_ID:
      return {
        ...state,
        leadById: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BOOKING_OVERVIEW:
      return {
        ...state,
        bookingoverview: action.payload,
        loading: false,
      };
    case ACTIONS.LEAD_DETAILS_RESET:
      return {
        ...state,
        leadById: {},
        lead_details: {},
        loading: false,
      };
    case ACTIONS.GET_AGENTS:
      return {
        ...state,
        agentsList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_AGENTS_BY_USERGROUP:
      return {
        ...state,
        agentBYUserGroup: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PACKAGES:
      return {
        ...state,
        packageList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PARTNERS:
      return {
        ...state,
        partnerList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_COUPONS:
      return {
        ...state,
        couponList: action.payload,
        loading: false,
      };
    case ACTIONS.CREATE_BOOKING:
      return {
        ...state,
        createBooking: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BOOKING_LIST:
      return {
        ...state,
        bookingList: action.payload,
        bookingListLoader: false,
      };
    case ACTIONS.GET_BOOKING_BY_ID:
      return {
        ...state,
        booking: action.payload,
        loading: false,
      };
    case ACTIONS.CREATE_CALL:
      return {
        ...state,
        loading: false,
        lead_id: action.payload,
      };
    case ACTIONS.GET_RECORDINGS:
      return {
        ...state,
        all_recordings: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LEAD_SOURCE:
      return {
        ...state,
        lead_source: action.payload,
        loading: false,
      };
    case ACTIONS.SEARCH_CALL:
      return {
        ...state,
        search_results: action.payload,
        loading: false,
      };
    case ACTIONS.ADD_COMMENT:
      return {
        ...state,
        comments: action.payload,
        loading: false,
      };
    case ACTIONS.BOOKING_RESET:
      return {
        ...state,
        createBooking: {},
        bookingList: [],
        loading: false,
      };
    case ACTIONS.GET_USER_DETAILS:
      return {
        ...state,
        userDetails: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BOOKINGS_COMMENTS:
      return {
        ...state,
        bookingsComment: action.payload,
        loading: false,
      };
    case ACTIONS.ADD_BOOKINGS_COMMENT:
      return {
        ...state,
        bookingsComment: action.payload,
        loading: false,
      };
    case ACTIONS.GET_WHATSAPP_COMMENTS:
      return {
        ...state,
        whatsappComments: action.payload,
        loading: false,
      };
    case ACTIONS.GET_WHATSAPP_COMMENTS_BY_LEAD:
      return {
        ...state,
        whatsappCommentsByLead: action.payload,
        loading: false,
      };
    case ACTIONS.SEND_WHATSAPP_COMMENTS:
      return {
        ...state,
        whatsappComments: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CALL_STATS:
      return {
        ...state,
        callStats: action.payload,
        loading: false,
      };
    case ACTIONS.RAISE_COMPLAINT:
      return {
        ...state,
        complaint: action.payload,
        loading: false,
      };
    case ACTIONS.GET_COMPLAINTS:
      return {
        ...state,
        complaints: action.payload,
        loading: false,
      };
    case ACTIONS.ASSIGN_DOCTOR:
      return {
        ...state,
        success: true,
        loading: false,
      };
    case ACTIONS.SEND_FEEDBACK:
      return {
        ...state,
        success: true,
        loading: false,
      };
    case ACTIONS.GET_AVAILABLE_SLOTS:
      return {
        ...state,
        booking_slots: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ALL_LEADS:
      return {
        ...state,
        all_lead: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PANEL_USERS:
      return {
        ...state,
        user_panel: action.payload,
        loading: false,
      };
    case ACTIONS.LEAD_TRANSFER_BY_COMMA:
      return {
        ...state,
        all_lead: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LAB_CREDENTIAL_DETAILS:
      return {
        ...state,
        labCredential: action.payload,
        loading: false,
      };
    case ACTIONS.UPDATE_LAB_CREDENTIALS:
      return {
        ...state,
        updateLabCredential: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ZONE_COMMENTS:
      return {
        ...state,
        zoneComment: action.payload,
        loading: false,
      };
    case ACTIONS.GET_DISPOSITIONS:
      return {
        ...state,
        dispositions: action.payload,
        loading: false,
      };
    case ACTIONS.GET_DISPOSITION_DATA:
      return {
        ...state,
        dispositionData: action.payload,
        loading: false,
      };
    case ACTIONS.GET_INCORRECT_NUMBER_LEAD:
      return {
        ...state,
        incorrectNumberLeads: action.payload,
        loading: false,
      };
    case ACTIONS.SAME_NUMBER_BOOKING:
      return {
        ...state,
        sameNumberBooking: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PAYMENT_SEND_LINK:
      return {
        ...state,
        paymentSendLink: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PAYMENT_RESEND_LINK:
      return {
        ...state,
        paymentReSendLink: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PAYMENT_CANCEL_LINK:
      return {
        ...state,
        paymentCancelLink: action.payload,
        loading: false,
      };
    case ACTIONS.GET_SMS_REPORT_SEND:
      return {
        ...state,
        smsReportSend: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BREAK_DATA:
      return {
        ...state,
        breakData: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ASSIGNED_USERGROUP:
      return {
        ...state,
        assigneUserList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_TODAYS_CALL_DETAILS:
      return {
        ...state,
        todayCallDetails: action.payload,
        loading: false,
      };
    case ACTIONS.GET_TOP_RECORDS:
      return {
        ...state,
        topRecord: action.payload,
        loading: false,
      };
    case ACTIONS.GET_VERIFICATION_BOOKING_DETAILS:
      return {
        ...state,
        verificationBookingDetails: action.payload,
        loading: false,
      };
    case ACTIONS.GET_SMS_DETAILS:
      return {
        ...state,
        smsDetails: action.payload,
        loading: false,
      };
    case ACTIONS.GET_EMAIL_DETAILS:
      return {
        ...state,
        emailDetails: action.payload,
        loading: false,
      };
    case ACTIONS.GET_REPORT_STATUS:
      return {
        ...state,
        reportStatus: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CENTER_INFORMATION:
      return {
        ...state,
        centerInfo: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LAB_DETAILS:
      return {
        ...state,
        labDetails: action.payload,
        loading: false,
      };
    case ACTIONS.GET_REPORT_DOWNLOAD:
      return {
        ...state,
        reportDownload: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PAYMENT_INFORMATION:
      return {
        ...state,
        paymentInformation: action.payload,
        loading: false,
      };
    case ACTIONS.GET_NOTIFICATION_MESSAGE:
      return {
        ...state,
        notificationMessage: action.payload,
        loading: false,
      };
    case ACTIONS.GET_UNMASKED_NUMBER:
      return {
        ...state,
        unmaskedNumber: action.payload,
        loading: false,
      };
    case ACTIONS.CHECK_GEOFENCE_AREA:
      return {
        ...state,
        checkGeoFenceStatus: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PACKAGE_DETAILS:
      return {
        ...state,
        packageDetails: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PACKAGE_CATEGORY:
      return {
        ...state,
        packageCategory: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PACKAGE_TAGS:
      return {
        ...state,
        packageTags: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PACKAGE_COMMENTS:
      return {
        ...state,
        packageComments: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PACKAGE_BY_NAME:
      return {
        ...state,
        packageListByName: action.payload,
        loading: false,
      };
    case ACTIONS.GET_QRCODE_DATA:
      return {
        ...state,
        qrcode: action.payload,
        loading: false,
      };
    case ACTIONS.GET_TICKET_DATA:
      return {
        ...state,
        ticketData: action.payload,
        loading: false,
      };
    case ACTIONS.GET_TICKET_CATEGORIES:
      return {
        ...state,
        ticketCategories: action.payload,
        loading: false,
      };

    case ACTIONS.GET_TICEKT_SUB_CATEGORIES:
      return {
        ...state,
        ticketSubCategories: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PACKAGE_MANAGEMENT:
      return {
        ...state,
        packageManagement: action.payload,
        loading: false,
      };
    case ACTIONS.CREATE_PACKAGE_MANAGEMENT:
      return {
        ...state,
        packagecreate: action.payload,
        loading: false,
      };
    case ACTIONS.UPDATE_PACKAGE_MANAGEMENT:
      return {
        ...state,
        updatePackageManagement: action.payload,
        loading: false,
      };
    case ACTIONS.GET_USERS_LIST:
      return {
        ...state,
        users: action.payload,
        loading: false,
      };
    case ACTIONS.GET_SYNC_DATA:
      return {
        ...state,
        syncData: action.payload,
        loading: false,
      };
    case ACTIONS.GET_SMS_LINK:
      return {
        ...state,
        sendSMSLink: action.payload,
        loading: false,
      };
    case ACTIONS.UPDATE_USERS_LIST:
      return {
        ...state,
        users: action.payload,
        loading: false,
      };
    case ACTIONS.CREATE_AREA:
      return {
        ...state,
        area: action.payload,
        loading: false,
      };
    case ACTIONS.UPDATE_AREA:
      return {
        ...state,
        area: action.payload,
        loading: false,
      };
    case ACTIONS.CHECK_GEOFENCE_AREA_MAMPMYINDIA:
      return {
        ...state,
        geoFencesMapMyIndia: action.payload,
        loading: false,
      };
    case ACTIONS.GET_AREA:
      return {
        ...state,
        areaList: action.payload,
        loading: false,
      };
    case ACTIONS.CREATE_ZONE:
      return {
        ...state,
        zone: action.payload,
        loading: false,
      };
    case ACTIONS.UPDATE_ZONE:
      return {
        ...state,
        zone: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ZONE:
      return {
        ...state,
        zoneList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_TIMESLOTS:
      return {
        ...state,
        timeslotsList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BLOG_CATEGORY:
      return {
        ...state,
        blogCategories: action.payload,
        loading: false,
      };
    case ACTIONS.GET_MAMPMYINDIA_ACCESS_TOKEN:
      return {
        ...state,
        mapmyindiaAccessToken: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BLOG_TAGS:
      return {
        ...state,
        blogTags: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BLOG_IMAGES:
      return {
        ...state,
        blogImages: action.payload,
        loading: false,
      };
    case ACTIONS.CREATE_BLOGS:
      return {
        ...state,
        blog: action.payload,
        loading: false,
      };
    case ACTIONS.UPDATE_BLOGS:
      return {
        ...state,
        blog: action.payload,
        loading: false,
      };
    case ACTIONS.DELETE_BLOG:
      return {
        ...state,
        blog: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BLOGS:
      return {
        ...state,
        blogList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BLOG_BY_SLUG:
      return {
        ...state,
        blogBySlug: action.payload,
        loading: false,
      };
    case ACTIONS.GET_RED_TECH_COMMENT:
      return {
        ...state,
        redtechcomment: action.payload,
        loading: false,
      };
    case ACTIONS.CREATE_COUPON:
      return {
        ...state,
        coupon: action.payload,
        loading: false,
      };
    case ACTIONS.UPDATE_COUPON:
      return {
        ...state,
        coupon: action.payload,
        loading: false,
      };
    case ACTIONS.CREATE_PACKAGE:
      return {
        ...state,
        pack: action.payload,
        loading: false,
      };
    case ACTIONS.GET_COLLECTION_SLOT:
      return {
        ...state,
        collectionSlot: action.payload,
        loading: false,
      };
    case ACTIONS.CREATE_ADDITIONAL_BOOKING:
      return {
        ...state,
        additional_booking: action.payload,
        loading: false,
      };
    case ACTIONS.UPDATE_ADDITIONAL_BOOKING:
      return {
        ...state,
        additional_booking: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PACKAGE:
      return {
        ...state,
        pack: action.payload,
        loading: false,
      };
    case ACTIONS.UPDATE_PACKAGE:
      return {
        ...state,
        pack: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CITY_PRICES:
      return {
        ...state,
        cityPriceList: action.payload,
        loading: false,
      };
    case ACTIONS.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case ACTIONS.GET_PIN_CODE:
      return {
        ...state,
        pin_code: action.payload,
        loading: false,
      };
    case ACTIONS.UPLOAD_FILE:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PHLEBOS:
      return {
        ...state,
        phleboList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CITIES:
      return {
        ...state,
        cities: action.payload,
        loading: false,
      };
    case ACTIONS.GET_POSTIVE_CALLS_COMMENTS:
      return {
        ...state,
        todays_positive_calls: action.payload,
        loading: false,
      };
    case ACTIONS.SET_COMMENTS:
      return {
        ...state,
        comment: action.payload,
        loading: false,
      };
    case ACTIONS.ADD_POSTIVE_CALLS_COMMENT:
      return {
        ...state,
        todays_positive_calls: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LEAD_DETAILS:
      return {
        ...state,
        lead_details: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LEADS:
      return {
        ...state,
        leads: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LEAD_BY_ID:
      return {
        ...state,
        leadById: action.payload,
        loading: false,
      };
    case ACTIONS.LEAD_DETAILS_RESET:
      return {
        ...state,
        leadById: {},
        lead_details: {},
        loading: false,
      };
    case ACTIONS.GET_AGENTS:
      return {
        ...state,
        agentsList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_AGENTS_BY_USERGROUP:
      return {
        ...state,
        agentBYUserGroup: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PACKAGES:
      return {
        ...state,
        packageList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PARTNERS:
      return {
        ...state,
        partnerList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_COUPONS:
      return {
        ...state,
        couponList: action.payload,
        loading: false,
      };
    case ACTIONS.CREATE_BOOKING:
      return {
        ...state,
        createBooking: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BOOKING_LIST:
      return {
        ...state,
        bookingList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BOOKING_BY_ID:
      return {
        ...state,
        booking: action.payload,
        loading: false,
      };
    case ACTIONS.CREATE_CALL:
      return {
        ...state,
        loading: false,
        lead_id: action.payload,
      };
    case ACTIONS.GET_RECORDINGS:
      return {
        ...state,
        all_recordings: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LEAD_SOURCE:
      return {
        ...state,
        lead_source: action.payload,
        loading: false,
      };
    case ACTIONS.SEARCH_CALL:
      return {
        ...state,
        search_results: action.payload,
        loading: false,
      };
    case ACTIONS.ADD_COMMENT:
      return {
        ...state,
        comments: action.payload,
        loading: false,
      };
    case ACTIONS.BOOKING_RESET:
      return {
        ...state,
        createBooking: {},
        bookingList: [],
        loading: false,
      };
    case ACTIONS.GET_USER_DETAILS:
      return {
        ...state,
        userDetails: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BOOKINGS_COMMENTS:
      return {
        ...state,
        bookingsComment: action.payload,
        loading: false,
      };
    case ACTIONS.ADD_BOOKINGS_COMMENT:
      return {
        ...state,
        bookingsComment: action.payload,
        loading: false,
      };
    case ACTIONS.GET_WHATSAPP_COMMENTS:
      return {
        ...state,
        whatsappComments: action.payload,
        loading: false,
      };
    case ACTIONS.GET_WHATSAPP_COMMENTS_BY_LEAD:
      return {
        ...state,
        whatsappCommentsByLead: action.payload,
        loading: false,
      };
    case ACTIONS.SEND_WHATSAPP_COMMENTS:
      return {
        ...state,
        whatsappComments: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CALL_STATS:
      return {
        ...state,
        callStats: action.payload,
        loading: false,
      };
    case ACTIONS.RAISE_COMPLAINT:
      return {
        ...state,
        complaint: action.payload,
        loading: false,
      };
    case ACTIONS.GET_COMPLAINTS:
      return {
        ...state,
        complaints: action.payload,
        loading: false,
      };
    case ACTIONS.ASSIGN_DOCTOR:
      return {
        ...state,
        success: true,
        loading: false,
      };
    case ACTIONS.SEND_FEEDBACK:
      return {
        ...state,
        success: true,
        loading: false,
      };
    case ACTIONS.GET_AVAILABLE_SLOTS:
      return {
        ...state,
        booking_slots: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ALL_LEADS:
      return {
        ...state,
        all_lead: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PANEL_USERS:
      return {
        ...state,
        user_panel: action.payload,
        loading: false,
      };
    case ACTIONS.LEAD_TRANSFER_BY_COMMA:
      return {
        ...state,
        all_lead: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LAB_CREDENTIAL_DETAILS:
      return {
        ...state,
        labCredential: action.payload,
        loading: false,
      };
    case ACTIONS.UPDATE_LAB_CREDENTIALS:
      return {
        ...state,
        updateLabCredential: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ZONE_COMMENTS:
      return {
        ...state,
        zoneComment: action.payload,
        loading: false,
      };
    case ACTIONS.GET_DISPOSITIONS:
      return {
        ...state,
        dispositions: action.payload,
        loading: false,
      };
    case ACTIONS.GET_DISPOSITION_DATA:
      return {
        ...state,
        dispositionData: action.payload,
        loading: false,
      };
    case ACTIONS.GET_INCORRECT_NUMBER_LEAD:
      return {
        ...state,
        incorrectNumberLeads: action.payload,
        loading: false,
      };
    case ACTIONS.SAME_NUMBER_BOOKING:
      return {
        ...state,
        sameNumberBooking: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PAYMENT_SEND_LINK:
      return {
        ...state,
        paymentSendLink: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PAYMENT_RESEND_LINK:
      return {
        ...state,
        paymentReSendLink: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PAYMENT_CANCEL_LINK:
      return {
        ...state,
        paymentCancelLink: action.payload,
        loading: false,
      };
    case ACTIONS.GET_SMS_REPORT_SEND:
      return {
        ...state,
        smsReportSend: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BREAK_DATA:
      return {
        ...state,
        breakData: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ASSIGNED_USERGROUP:
      return {
        ...state,
        assigneUserList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_TODAYS_CALL_DETAILS:
      return {
        ...state,
        todayCallDetails: action.payload,
        loading: false,
      };
    case ACTIONS.GET_TOP_RECORDS:
      return {
        ...state,
        topRecord: action.payload,
        loading: false,
      };
    case ACTIONS.GET_VERIFICATION_BOOKING_DETAILS:
      return {
        ...state,
        verificationBookingDetails: action.payload,
        loading: false,
      };
    case ACTIONS.GET_SMS_DETAILS:
      return {
        ...state,
        smsDetails: action.payload,
        loading: false,
      };
    case ACTIONS.GET_EMAIL_DETAILS:
      return {
        ...state,
        emailDetails: action.payload,
        loading: false,
      };
    case ACTIONS.GET_REPORT_STATUS:
      return {
        ...state,
        reportStatus: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CENTER_INFORMATION:
      return {
        ...state,
        centerInfo: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LAB_DETAILS:
      return {
        ...state,
        labDetails: action.payload,
        loading: false,
      };
    case ACTIONS.GET_REPORT_DOWNLOAD:
      return {
        ...state,
        reportDownload: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PAYMENT_INFORMATION:
      return {
        ...state,
        paymentInformation: action.payload,
        loading: false,
      };
    case ACTIONS.GET_NOTIFICATION_MESSAGE:
      return {
        ...state,
        notificationMessage: action.payload,
        loading: false,
      };
    case ACTIONS.GET_UNMASKED_NUMBER:
      return {
        ...state,
        unmaskedNumber: action.payload,
        loading: false,
      };
    case ACTIONS.CHECK_GEOFENCE_AREA:
      return {
        ...state,
        checkGeoFenceStatus: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PACKAGE_DETAILS:
      return {
        ...state,
        packageDetails: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PACKAGE_CATEGORY:
      return {
        ...state,
        packageCategory: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PACKAGE_TAGS:
      return {
        ...state,
        packageTags: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PACKAGE_COMMENTS:
      return {
        ...state,
        packageComments: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PACKAGE_BY_NAME:
      return {
        ...state,
        packageListByName: action.payload,
        loading: false,
      };
    case ACTIONS.GET_QRCODE_DATA:
      return {
        ...state,
        qrcode: action.payload,
        loading: false,
      };
    case ACTIONS.GET_TICKET_DATA:
      return {
        ...state,
        ticketData: action.payload,
        loading: false,
      };
    case ACTIONS.GET_TICKET_CATEGORIES:
      return {
        ...state,
        ticketCategories: action.payload,
        loading: false,
      };

    case ACTIONS.GET_TICEKT_SUB_CATEGORIES:
      return {
        ...state,
        ticketSubCategories: action.payload,
        loading: false,
      };

    case ACTIONS.GET_CITY_WISE_PACKAGE:
      return {
        ...state,
        citywisepackage: action.payload,
        loading: false,
      };
    case ACTIONS.UPDATE_TAT_PACKAGE:
      return {
        ...state,
        tatpackageupdated: action.payload,
        loading: false,
      };
    case ACTIONS.CREATE_TAT:
      return {
        ...state,
        tat: action.payload,
      };
    case ACTIONS.GET_TAGS_LIST:
      return {
        ...state,
        tagslist: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BOOKING_COMPLAINTS:
      return {
        ...state,
        bookingComplaints: action.payload,
        loading: false,
      };
    case ACTIONS.GET_TICKET_COMMENTS:
      return {
        ...state,
        ticketComments: action.payload,
        loading: false,
      };
    case ACTIONS.UPDATE_DISPOSITION:
      return {
        ...state,
        disposition: action.payload,
        loading: false,
      };
    case ACTIONS.SET_ALL_BOOKING_LOADING:
      return {
        ...state,
        bookingListLoader: action.payload,
      };
    default:
      return state;
  }
};

export default ProductManagerReducer;
