import React, { useEffect, useState } from "react";
import { Theme, createStyles } from "@material-ui/core/styles";
import { makeStyles } from '@material-ui/core/styles';
import {Button } from '@material-ui/core';
import { connect } from "react-redux";
import LiveCallModal from './liveCallTable'
import { getTodaysCallDetails } from '../../actions/productMangerAction'
import MissedCallTable from './missedCall'
import CallTable from './callsTable'
import CallStatsTable from './callStatsTable'
import BreakDetails from './breakDetails'
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        spacebtm: {
            marginBottom: 15,
        },
        root: {
            flexGrow: 1,
        }, table: {
            minWidth: 650,
        },
        card: {
            padding: 15,
            width: "100%",
        },
        content: {
            width: "100%",
            flexGrow: 21,
            margin: "50px auto"
        },
        toolbar: {
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        },
        buttonContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            marginBottom: "1rem",
            flexWrap: "wrap"
        },
        paper: {
            padding: theme.spacing(3),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },
    })
);

interface Props {
    getTodaysCallDetails: any;
    todayCallDetails: any;
    loading:any;
}

const SampleTypeForm: React.FC<Props> = ({
    getTodaysCallDetails,
    todayCallDetails,
    loading,
}) => {
    const classes = useStyles();
    const [connectCall, setConnectCall] = useState<any>([])
    const [connectCallOutgoing, setConnectCallOutgoing] = useState<any>([])
    const [xnumber, setXNumber] = useState<number>(0);
    const [callType, setCallType] = useState<any>("")
    const [tabState, setTabState] = useState<any>(0)
    const [timer, setTimer] = useState<any>(0)
    const [isActive, setIsActive] = useState(false);
    const [isPaused, setIsPaused] = useState(true);
    const [time, setTime] = useState(0);
    const WS_URL = process.env.REACT_APP_WEBSOCKET_URL || "";

      return (
        <main className={classes.content}>
            <div className={classes.buttonContainer}>
                <Button variant="contained" color="default" style={{ marginLeft: "0.5rem", backgroundColor: "#4287f5" }} onClick={() => setTabState(0)} >Home</Button>
                {/* <Button variant="contained" color="default" style={{ marginLeft: "0.5rem", backgroundColor: "#f25cb6" }} onClick={() => setTabState(1)} >Missed Calls</Button> */}
                <Button variant="contained" color="default" style={{ marginLeft: "0.5rem", backgroundColor: "#42cbf5" }} onClick={() => setTabState(2)} >All Calls</Button>
                <Button variant="contained" color="default" style={{ marginLeft: "0.5rem", backgroundColor: "#bd49cc" }} onClick={() => setTabState(3)} >Call Stats</Button>
                <Button variant="contained" color="default" style={{ marginLeft: "0.5rem", backgroundColor: "#42f5c8" }} onClick={() => setTabState(4)} >Break Details</Button>
            </div>
            {tabState === 0 ? (<LiveCallModal/>) : tabState === 1 ? (<CallTable />):tabState===3? (<CallStatsTable />):(<BreakDetails loading={loading}/>) }

        </main>

    );
};

const mapStateToProps = (state: any) => ({
    todayCallDetails: state.ProductManagerReducer.todayCallDetails,
    loading: state.ProductManagerReducer.loading,
});

export default connect(mapStateToProps, { getTodaysCallDetails })(SampleTypeForm);