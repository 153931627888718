export const generateCallStatusUrl = (body) => {
  const {
    lead_status,
    panel_user,
    provider,
    lead,
    qc_agent,
    start_date,
    end_date,
    direction,
    phonenumber,
  } = body;
  const lead_status_part = isSelectedNum(lead_status)
    ? `&lead_status=${lead_status}`
    : "";
  const panel_user_part = isSelectedNum(panel_user)
    ? `&panel_user=${panel_user}`
    : "";
  const provider_part = isSelectedNum(provider) ? `&provider=${provider}` : "";
  const lead_part = isSelectedNum(lead) ? `&lead=${lead}` : "";
  const phonenumber_part = isSelectedNum(phonenumber)
    ? `&phonenumber=${phonenumber}`
    : "";
  const qc_agent_part = isSelectedNum(qc_agent)
    ? `&quality_user=${qc_agent}`
    : "";
  const start_date_part = isSelected(start_date)
    ? `&start_date=${start_date}`
    : "";
  const end_date_part = isSelected(end_date) ? `&end_date=${end_date}` : "";
  const direction_part = isSelectedNum(direction)
    ? `&direction=${direction}`
    : "";

  const url = `?${lead_status_part}${panel_user_part}${provider_part}${lead_part}${qc_agent_part}${start_date_part}${end_date_part}${direction_part}${phonenumber_part}`;

  return url;
};

const isSelected = (field) => {
  if (
    field === "all" ||
    field === "" ||
    field === undefined ||
    field === null ||
    field === "none"
  ) {
    return false;
  } else {
    return true;
  }
};

const isSelectedNum = (field) => {
  if (
    field === 0 ||
    field === "" ||
    field === undefined ||
    field === null ||
    field === "none"
  ) {
    return false;
  } else {
    return true;
  }
};

export const generateCallsUrl = (body) => {
  const {
    city,
    lead_source,
    treatment,
    panel_user,
    priority,
    language,
    followup_date_start,
    followup_date_end,
    status,
    day,
  } = body;
  const city_part = isSelectedNum(city) ? `&city=${city}` : "";
  const source_part = isSelected(lead_source)
    ? `&lead_source=${lead_source}`
    : "";
  const treatment_part = isSelectedNum(treatment)
    ? `&treatment=${treatment}`
    : "";
  const panel_user_part = isSelectedNum(panel_user)
    ? `&panel_user=${panel_user}`
    : "";
  const priority_part = isSelected(priority) ? `&priority=${priority}` : "";
  const language_part = isSelected(language) ? `&language=${language}` : "";
  const followup_date_start_part = isSelected(followup_date_start)
    ? `&followup_date_start=${followup_date_start}`
    : "";
  const followup_date_end_part = isSelected(followup_date_end)
    ? `&followup_date_end=${followup_date_end}`
    : "";
  const status_part = isSelected(status) ? `&status=${status}` : "";
  const url = `?day=${day}${city_part}${source_part}${treatment_part}${panel_user_part}${priority_part}${language_part}${followup_date_start_part}${followup_date_end_part}${status_part}`;

  return url;
};

export const generateAppointmentsUrl = (body) => {
  const {
    appt_status,
    center,
    feedbackstatus,
    doctor,
    treatment,
    panel_user,
    priority,
    appt_date_start,
    appt_date_end,
    day,
    source,
    treatment_id,
    lead_id,
    feedback,
  } = body;
  const appt_status_part = isSelectedNum(appt_status)
    ? `&appt_status=${appt_status}`
    : "";
  const feedbackstatus_part = isSelectedNum(feedbackstatus)
    ? `&feedbackstatus=${feedbackstatus}`
    : "";
  const feedback_part = isSelectedNum(feedback) ? `&feedback=${feedback}` : "";
  const center_part = isSelectedNum(center) ? `&center=${center}` : "";
  const doctor_part = isSelectedNum(doctor) ? `&doctor=${doctor}` : "";
  const treatment_part = isSelectedNum(treatment)
    ? `&treatment=${treatment}`
    : "";
  const panel_user_part = isSelectedNum(panel_user)
    ? `&panel_user=${panel_user}`
    : "";
  const source_part = isSelectedNum(source) ? `&source=${source}` : "";
  const priority_part = isSelected(priority) ? `&priority=${priority}` : "";
  const appt_date_start_part = isSelected(appt_date_start)
    ? `&appt_date_start=${appt_date_start}`
    : "";
  const appt_date_end_part = isSelected(appt_date_end)
    ? `&appt_date_end=${appt_date_end}`
    : "";
  const treatment_id_part = isSelected(treatment_id)
    ? `&treatment_id=${treatment_id}`
    : "";
  const lead_id_part = isSelected(lead_id) ? `&lead_id=${lead_id}` : "";
  const day_part = isSelected(day) ? `day=${day}` : "";

  const url = `?${day_part}${appt_status_part}${center_part}${doctor_part}${treatment_part}${panel_user_part}${priority_part}${appt_date_start_part}${appt_date_end_part}${treatment_id_part}${lead_id_part}${feedback_part}${source_part}${feedbackstatus_part}`;

  return url;
};

export const generatePositiveCallsUrl = (body) => {
  const {
    city,
    lead_source,
    treatment,
    panel_user,

    followup_date_start,
    followup_date_end,

    day,
  } = body;
  const city_part = isSelectedNum(city) ? `&city=${city}` : "";
  const source_part = isSelected(lead_source)
    ? `&lead_source=${lead_source}`
    : "";
  const treatment_part = isSelectedNum(treatment)
    ? `&treatment=${treatment}`
    : "";
  const panel_user_part = isSelectedNum(panel_user)
    ? `&panel_user=${panel_user}`
    : "";

  const followup_date_start_part = isSelected(followup_date_start)
    ? `&followup_date_start=${followup_date_start}`
    : "";
  const followup_date_end_part = isSelected(followup_date_end)
    ? `&followup_date_end=${followup_date_end}`
    : "";
  const day_part = isSelected(day) ? `day=${day}` : "";

  const url = `?${day_part}${city_part}${source_part}${treatment_part}${panel_user_part}${followup_date_start_part}${followup_date_end_part}`;

  return url;
};
export const genrateLabDetailsFilter = (body) => {
  const {
      zone,
  } = body;
  const lab_zone = isSelectedNum(zone) ? `&name=${zone}` : "";
  const url = `?${lab_zone}`;

  return url;
};
export const generateHealthDataFilterUrl = (body) => {
  const {
    booking,
    add_booking,
    customer_phonenumber,
    customer_name,
    test_parameter,
    start_date,
    end_date,
    package_id,
    normal,
  } = body;
  const health_booking = isSelectedNum(booking) ? `&booking=${booking}` : "";
  const health_add_booking = isSelectedNum(add_booking)
    ? `&add_booking=${add_booking}`
    : "";
  const health_normal = isSelectedNum(normal) ? `&normal=${normal}` : "";
  const health_customer_phonenumber = isSelectedNum(customer_phonenumber)
    ? `&customer_phonenumber=${customer_phonenumber}`
    : "";
  const health_customer_name = isSelectedNum(customer_name)
    ? `&customer_name=${customer_name}`
    : "";
  const health_test_parameter = isSelectedNum(test_parameter)
    ? `&test_parameter=${test_parameter}`
    : "";
  const health_start_date = isSelectedNum(start_date)
    ? `&start_date=${start_date}`
    : "";
  const health_end_date = isSelectedNum(end_date)
    ? `&end_date=${end_date}`
    : "";
  const health_package = isSelectedNum(package_id)
    ? `&package_id=${package_id}`
    : "";
  const url = `?${health_normal}${health_booking}${health_add_booking}${health_customer_phonenumber}${health_customer_name}${health_test_parameter}${health_start_date}${health_end_date}${health_package}`;

  return url;
};
export const genrateCenterInformationFilter = (body) => {
  const { city, area } = body;
  const center_city = isSelectedNum(city) ? `&city=${city}` : "";
  const center_area = isSelectedNum(area) ? `&area=${area}` : "";
  const url = `?${center_city}${center_area}`;

  return url;
};
export const genrateCarrerFilter = (body) => {
  const { email, name, job_profile, experience } = body;
  const carrer_email = isSelectedNum(email) ? `&email=${email}` : "";
  const carrer_name = isSelectedNum(name) ? `&name=${name}` : "";
  const carrer_jobProfile = isSelectedNum(job_profile)
    ? `&job_profile=${job_profile}`
    : "";
  const carrer_exprience = isSelectedNum(experience)
    ? `&experience=${experience}`
    : "";
  const url = `?${carrer_email}${carrer_name}${carrer_jobProfile}${carrer_exprience}`;
  return url;
};
export const genratePanelUserFiltr = (body) => {
  const {
    usergroup,
    is_active,
    tl,
    username,
    on_call,
    break_button,
    inbound_mode,
    is_imaging_lead,
    is_homedx_lead,
  } = body;
  const panel_usergroup = isSelectedNum(usergroup)
    ? `&usergroup=${usergroup}`
    : "";
  const panel_userStatus = isSelectedNum(is_active)
    ? `&is_active=${is_active}`
    : "";
  const panel_Break = isSelectedNum(break_button)
    ? `&break_button=${break_button}`
    : "";
  const panel_OnCall = isSelectedNum(on_call) ? `&on_call=${on_call}` : "";
  const panel_panelUser = isSelectedNum(tl) ? `&tl=${tl}` : "";
  const panel_username = isSelectedNum(username) ? `&username=${username}` : "";
  const inboundMode = isSelectedNum(inbound_mode)
    ? `&inbound_mode=${inbound_mode}`
    : "";
  const imagingLead = isSelectedNum(is_imaging_lead)
    ? `&is_imaging_lead=${is_imaging_lead}`
    : "";
  const homedxLead = isSelectedNum(is_homedx_lead)
    ? `&is_homedx_lead=${is_homedx_lead}`
    : "";
  const url = `?${panel_Break}${panel_OnCall}${panel_username}${panel_usergroup}${panel_userStatus}${panel_panelUser}${inboundMode}${imagingLead}${homedxLead}`;
  return url;
};
export const genrateUnregisterdBookingFilter = (body) => {
  const { multiple_booking } = body;
  const unregisterd_bookingId = isSelectedNum(multiple_booking)
    ? `&multiple_booking=${multiple_booking}`
    : "";
  const url = `?${unregisterd_bookingId}`;

  return url;
};

export const generateRecordingUrl = (body) => {
  const {
    lead_status,
    panel_user,
    usergroup,
    provider,
    lead,
    booking_id,
    source,
    qc_agent,
    start_date,
    end_date,
    direction,
    phonenumber,
    status,
    callToNumber,
    category,
    city,
    lead_source,
    hangup_clause
  } = body;
  const lead_status_part = isSelectedNum(lead_status)
    ? `&lead_status=${lead_status}`
    : "";
  const booking_source = isSelectedNum(source)
    ? `&multiple_source=${source}`
    : "";
  const booking_city = isSelectedNum(city) ? `&multiple_city=${city}` : "";
  const booking_call_to = isSelectedNum(callToNumber)
    ? `&call_to=${callToNumber}`
    : "";
  const lead_hangupClause = isSelectedNum(hangup_clause) ?
    `&hangup_clause=${hangup_clause}` :
    "";
  const lead_lead_source = isSelectedNum(lead_source)
    ? `&lead_source=${lead_source}`
    : "";
  const panel_user_part = isSelectedNum(panel_user)
    ? `&panel_user=${panel_user}`
    : "";
  const provider_part = isSelectedNum(provider) ? `&provider=${provider}` : "";
  const recording_status = isSelectedNum(status)
    ? `&call_picked=${status}`
    : "";
  const recording_category = isSelectedNum(category)
    ? `&category=${category}`
    : "";
  const usergroup_category = isSelectedNum(usergroup)
    ? `&usergroup=${usergroup}`
    : "";
  const lead_part = isSelectedNum(lead) ? `&lead=${lead}` : "";
  const booking_part = isSelectedNum(booking_id)
    ? `&booking_id=${booking_id}`
    : "";
  const phonenumber_part = isSelectedNum(phonenumber)
    ? `&phonenumber=${phonenumber}`
    : "";
  const qc_agent_part = isSelectedNum(qc_agent)
    ? `&quality_user=${qc_agent}`
    : "";
  const start_date_part = isSelected(start_date)
    ? `&start_date=${start_date}`
    : "";
  const end_date_part = isSelected(end_date) ? `&end_date=${end_date}` : "";
  const direction_part = isSelectedNum(direction)
    ? `&direction=${direction}`
    : "";

  const url = `?${lead_hangupClause}${booking_city}${booking_call_to}${booking_source}${lead_lead_source}${lead_status_part}${booking_part}${recording_category}${recording_status}${panel_user_part}${usergroup_category}${provider_part}${lead_part}${qc_agent_part}${start_date_part}${end_date_part}${direction_part}${phonenumber_part}`;

  return url;
};

export const generateBookingFilterUrl = (body) => {
  const {
    status,
    financeStatus,
    category,
    verificationStatus,
    source,
    agent,
    phlebo,
    city,
    start_date,
    end_date,
    booking_date,
    collection_date,
    name,
    lead,
    phone,
    pickupAmount,
    pickup_status,
    pickup_done,
    bookingId,
    partner,
    user_group,
    slot,
    sample_registered,
    report_status,
    is_pickup_receive_amount,
    booking_type,
    due_date,
    due_start_date,
    due_end_date,
    assigned_status,
    // lead_status,
    lead_call_status_id,
    route_manager,
    verified_by,
    created_date,
    journey,
    tat_status,
    client_refid,
    org_types,
    tl,
    collection_start_date,
    collection_end_date,
    barcode,
    bookingIdCC,
    zoneId,
    rm_fullname,
    cityId,
    rider,
    sample_pickup_interval,
  } = body;
  const booking_id_cc = isSelectedNum(bookingIdCC) ? `&id=${bookingIdCC}` : "";
  const riderFilter = isSelectedNum(rider) ? `&rider=${rider}` : "";
  const booking_rm = isSelectedNum(rm_fullname) ? `&rm_fullname=${rm_fullname}` : "";
  const booking_category =
    isSelectedNum(category) && category !== "0"
      ? `&multiple_disposition=${category}`
      : "";
  const booking_status = isSelectedNum(status)
    ? `&multiple_booking_status=${status}`
    : "";
  const booking_category_status = isSelectedNum(lead_call_status_id)
    ? `&lead_call_status_id=${lead_call_status_id}`
    : "";
  const booking_id = isSelectedNum(bookingId) ? `&booking_id=${bookingId}` : "";
  const booking_zoneId = isSelectedNum(zoneId) ? `&zone=${zoneId}` : "";
  const booking_verified_by = isSelectedNum(verified_by)
    ? `&verified_by=${verified_by}`
    : "";
  const booking_routemanager = isSelectedNum(route_manager)
    ? `&route_manager=${route_manager}`
    : "";
  const booking_tatStatus = isSelectedNum(tat_status)
    ? `&tat_status=${tat_status}`
    : "";
  const booking_clientRefId = isSelectedNum(client_refid)
    ? `&client_refid=${client_refid}`
    : "";
  const booking_lead_id = isSelectedNum(lead) ? `&lead=${lead}` : "";
  const booking_phlebojourney = isSelectedNum(journey)
    ? `&journey=${journey}`
    : "";
  const booking_partner = isSelectedNum(partner)
    ? `&multiple_partner=${partner}`
    : "";
  const booking_user_group = isSelectedNum(user_group)
    ? `&multiple_usergroup=${user_group}`
    : "";
  const verification_status = isSelectedNum(verificationStatus)
    ? `&multiple_verification_status=${verificationStatus}`
    : "";
  const booking_source = isSelectedNum(source)
    ? `&multiple_source=${source}`
    : "";
  const booking_agent = isSelectedNum(agent) ? `&multiple_agent=${agent}` : "";
  const booking_phlebo = isSelectedNum(phlebo)
    ? `&multiple_phlebo=${phlebo}`
    : "";

  const booking_city = isSelectedNum(city) ? `&multiple_city=${city}` : "";
  const rider_city = isSelectedNum(cityId) ? `&city=${cityId}` : "";
  const booking_zone = isSelectedNum(zoneId) ? `&zone=${zoneId}` : "";
  const booking_collection_slots = isSelectedNum(slot)
    ? `&multiple_collection_slots=${slot}`
    : "";
  const booking_start_date = isSelected(start_date)
    ? `&start_date=${start_date}`
    : "";
  const booking_booking_date = isSelected(booking_date)
    ? `&booking_date=${booking_date}`
    : "";
  const booking_tl = isSelected(tl) ? `&tl=${tl}` : "";
  const booking_end_date = isSelected(end_date) ? `&end_date=${end_date}` : "";
  const booking_due_date = isSelected(due_date) ? `&due_date=${due_date}` : "";
  const booking_due_start_date = isSelected(due_start_date)
    ? `&due_start_date=${due_start_date}`
    : "";
  const booking_due_end_date = isSelected(due_end_date)
    ? `&due_end_date=${due_end_date}`
    : "";
  const booking_collection_date = isSelected(collection_date)
    ? `&collection_date=${collection_date}`
    : "";
  const booking_created_date = isSelected(created_date)
    ? `&created_date=${created_date}`
    : "";
  const booking_barcode = isSelected(barcode) ? `&barcode=${barcode}` : "";
  const booking_name = isSelectedNum(name) ? `&name=${name}` : "";
  const booking_phone = isSelectedNum(phone) ? `&phone=${phone}` : "";
  const booking_pickup_status = isSelectedNum(pickup_status)
    ? `&multiple_pickup_status=${pickup_status}`
    : "";
  const booking_pickup_done = isSelectedNum(pickup_done)
    ? `&pickup_done=${pickup_done}`
    : "";
  const booking_pickup_amount = isSelectedNum(pickupAmount)
    ? `&pickup_amount=${pickupAmount}`
    : "";
  const booking_finance_status = isSelectedNum(financeStatus)
    ? `&multiple_finance_status=${financeStatus}`
    : "";
  const booking_org_type = isSelectedNum(org_types)
    ? `&org_types=${org_types}`
    : "";
  const booking_booking_type = isSelectedNum(booking_type)
    ? `&booking_type=${booking_type}`
    : "";
  const booking_sample_registered = isSelectedNum(sample_registered)
    ? `&sample_registered=${sample_registered}`
    : "";
  const booking_report_status = isSelectedNum(report_status)
    ? `&multiple_report_status=${report_status}`
    : "";
  const booking_is_pickup_receive_amount = isSelectedNum(
    is_pickup_receive_amount
  )
    ? `&is_pickup_receive_amount=${is_pickup_receive_amount}`
    : "";
  const assignedStatus = isSelected(assigned_status)
    ? `&assigned_status=${assigned_status}`
    : "";
  const booking_collection_start_date = isSelected(collection_start_date)
    ? `&collection_start_date=${collection_start_date}`
    : "";
  const booking_collection_end_date = isSelected(collection_end_date)
    ? `&collection_end_date=${collection_end_date}`
    : "";
  const booking_specialpackage = isSelected(sample_pickup_interval) ? `&sample_pickup_interval=${sample_pickup_interval}` : "";
  const url = `?${booking_collection_start_date}${riderFilter}${rider_city}${booking_collection_end_date}${booking_clientRefId}${booking_tatStatus}${booking_phlebojourney}${booking_verified_by}${booking_routemanager}${booking_category_status}${booking_tl}${booking_due_date}${booking_due_start_date}${booking_due_end_date}${booking_booking_type}${booking_is_pickup_receive_amount}${booking_report_status}${booking_sample_registered}${booking_user_group}${booking_collection_slots}${booking_finance_status}${booking_category}${booking_id}${booking_id_cc}${booking_pickup_done}${booking_partner}${booking_pickup_status}${booking_pickup_amount}${booking_agent}${booking_name}${booking_phone}${booking_phlebo}${booking_city}${booking_source}${booking_start_date}${booking_end_date}${booking_collection_date}${booking_status}${booking_booking_date}${verification_status}${booking_lead_id}${assignedStatus}${booking_org_type}${booking_barcode}${booking_created_date}${booking_zone}${booking_rm}${booking_specialpackage}`;
  // const url = `?${booking_routemanager}${booking_category_status}${booking_client_billid}${booking_tl}${booking_due_date}${booking_due_start_date}${booking_due_end_date}${booking_created_date}${booking_booking_type}${booking_is_pickup_receive_amount}${booking_report_status}${booking_sample_registered}${booking_user_group}${booking_collection_slots}${booking_finance_status}${booking_category}${booking_id}${booking_pickup_done}${booking_partner}${booking_pickup_status}${booking_pickup_amount}${booking_agent}${booking_name}${booking_phone}${booking_phlebo}${booking_city}${booking_source}${booking_start_date}${booking_end_date}${booking_collection_date}${booking_status}${booking_booking_date}${verification_status}${booking_lead_id}${assignedStatus}`;
  return url;
};
export const generateTransferdTicketFilterUrl = (body) => {
  const { booking, agent } = body;

  const ticket_booking_id = isSelectedNum(booking) ? `&booking=${booking}` : "";
  const ticket_booking_agent = isSelectedNum(agent) ? `&agent=${agent}` : "";

  const url = `?${ticket_booking_id}${ticket_booking_agent}`;
  return url;
};
export const genrateCollectionCentrLedgerFilter = (body) => {
  const { status, center, start_date, end_date, ledger_type } = body;
  const collection_center = isSelectedNum(center)
    ? `&multiple_center=${center}`
    : "";
  const collection_start_date = isSelected(start_date)
    ? `&start_date=${start_date}`
    : "";
  const collection_end_date = isSelected(end_date)
    ? `&end_date=${end_date}`
    : "";
  const collection_status = isSelectedNum(status) ? `&status=${status}` : "";
  const collection_ledger_type = isSelectedNum(ledger_type)
    ? `&ledger_type=${ledger_type}`
    : "";
  const url = `?${collection_start_date}${collection_end_date}${collection_status}${collection_ledger_type}${collection_center}`;
  return url;
};
export const genratePhleboLeaveFilter = (body) => {
  const { start_date, end_date, phlebo, zone_name } = body;
  const leave_start_date = isSelected(start_date)
    ? `&date__gte=${start_date}`
    : "";
  const leave_end_date = isSelected(end_date)
    ? `&date__lte=${end_date}`
    : "";
  const leave_phlebo = isSelected(phlebo)
    ? `&name=${phlebo}`
    : "";
  const leave_zone_name = isSelected(zone_name)
    ? `&zone_name=${zone_name}`
    : "";
  const url = `?${leave_start_date}${leave_end_date}${leave_phlebo}${leave_zone_name}`;
  return url;
};
export const genrateRepeatedListFilter = (body) => {
  const { lead, start_date, end_date, created_at } = body;
  const repeated_start_date = isSelected(start_date)
    ? `&start_date=${start_date}`
    : "";
  const repeated_end_date = isSelected(end_date) ? `&end_date=${end_date}` : "";
  const repeated_lead = isSelectedNum(lead) ? `&lead=${lead}` : "";
  const repeated_created_at = isSelected(created_at)
    ? `&created_date=${created_at}`
    : "";
  const url = `?${repeated_start_date}${repeated_end_date}${repeated_lead}${repeated_created_at}`;
  return url;
};
export const genrateRemarksSmsSendUrl = (body) => {
  const {
    lead_id,
    template_id,
    sender_id,
    lead_start_date,
    lead_end_date,
    booking_start_date,
    booking_end_date,
    booking_status,
    lead_call_status,
    package_ids,
    number_of_chunks,
    interval_in_hours,
    scheduled_at,
  } = body;

  const remarks_sms_leadId = isSelected(lead_id) ? `&lead_id=${lead_id}` : "";
  const remarks_sms_template = isSelected(template_id)
    ? `&template_id=${template_id}`
    : "";
  const remarks_sms_senderId = isSelected(sender_id)
    ? `&sender_id=${sender_id}`
    : "";
  const remarks_sms_leadStartDate = isSelected(lead_start_date)
    ? `&lead_start_date=${lead_start_date}`
    : "";
  const remarks_sms_leadEndDate = isSelected(lead_end_date)
    ? `&lead_end_date=${lead_end_date}`
    : "";
  const remarks_sms_BookingStartDate = isSelected(booking_start_date)
    ? `&booking_start_date=${booking_start_date}`
    : "";
  const remarks_sms_BookingEndDate = isSelected(booking_end_date)
    ? `&booking_end_date=${booking_end_date}`
    : "";
  const remarks_sms_BookingStatus = isSelected(booking_status)
    ? `&booking_status=${booking_status}`
    : "";
  const remarks_sms_LeadStatus = isSelected(lead_call_status)
    ? `&lead_call_status=${lead_call_status}`
    : "";
  const remarks_sms_PackageId = isSelected(package_ids)
    ? `&package_ids=${package_ids}`
    : "";
  const remarks_number_of_chunks = isSelected(number_of_chunks)
    ? `&number_of_chunks=${number_of_chunks}`
    : "";
  const remarks_interval_in_hours = isSelected(interval_in_hours)
    ? `&interval_in_hours=${interval_in_hours}`
    : "";
  const remarks_scheduled_at = isSelected(scheduled_at)
    ? `&scheduled_at=${scheduled_at}`
    : "";
  const url = `?${remarks_sms_leadId}${remarks_sms_template}${remarks_sms_senderId}${remarks_sms_leadStartDate}${remarks_sms_leadEndDate}${remarks_sms_BookingStartDate}${remarks_sms_BookingEndDate}${remarks_sms_BookingStatus}${remarks_sms_LeadStatus}${remarks_sms_PackageId}${remarks_number_of_chunks}${remarks_interval_in_hours}${remarks_scheduled_at}`;
  return url;
};
export const genrateSampleHandoverFilter = (body) => {
  const {
    start_date,
    end_date,
    created_date,
    phlebo,
    booking,
    rider,
    id,
    barcode,
    batchStatus,
    lab,
  } = body;
  const sampleHandover_start_date = isSelected(start_date)
    ? `&start_date=${start_date}`
    : "";
  const sampleHandover_barcode = isSelected(barcode)
    ? `&barcode=${barcode}`
    : "";
  const sampleHandover_end_date = isSelected(end_date)
    ? `&end_date=${end_date}`
    : "";
  const sampleHandover_created_at = isSelected(created_date)
    ? `&created_date=${created_date}`
    : "";
  const sampleHandover_phlebo = isSelectedNum(phlebo)
    ? `&phlebo=${phlebo}`
    : "";
  const sampleHandover_rider = isSelectedNum(rider) ? `&rider=${rider}` : "";
  const sampleHandover_rider_id = isSelectedNum(id) ? `&id=${id}` : "";
  const sampleHandover_booking_id = isSelectedNum(booking)
    ? `&booking=${booking}`
    : "";
  const batch_status = isSelectedNum(batchStatus)
    ? `&batch_status=${batchStatus}`
    : "";
  const batch_lab = isSelectedNum(lab) ? `&lab=${lab}` : "";
  const url = `?${batch_lab}${sampleHandover_barcode}${sampleHandover_rider_id}${sampleHandover_booking_id}${sampleHandover_start_date}${sampleHandover_end_date}${sampleHandover_created_at}${sampleHandover_phlebo}${sampleHandover_rider}${batch_status}`;
  return url;
};
export const genratePhleboRiderCashDueFilter = (body) => {
  const {
    start_date,
    end_date,
    created_date,
    phlebo,
    booking,
    rider,
    id,
    barcode,
    lab,
    phonenumber,
    username,
    user_status,
    city,
    usergroup,
  } = body;
  const phleboridercashdue_start_date = isSelected(start_date)
    ? `&start_date=${start_date}`
    : "";
  const booking_city = isSelected(city) ? `&city=${city}` : "";
  const phleboridercashdue_barcode = isSelected(barcode)
    ? `&barcode=${barcode}`
    : "";
  const phleboridercashdue_end_date = isSelected(end_date)
    ? `&end_date=${end_date}`
    : "";
  const phleboridercashdue_created_at = isSelected(created_date)
    ? `&created_date=${created_date}`
    : "";
  const phleboridercashdue_phlebo = isSelected(phlebo)
    ? `&phlebo=${phlebo}`
    : "";
  const phleboridercashdue_rider = isSelected(rider) ? `&rider=${rider}` : "";
  const phleboridercashdue_rider_id = isSelected(id) ? `&id=${id}` : "";
  const phleboridercashdue_booking_id = isSelected(booking)
    ? `&booking=${booking}`
    : "";
  const phleboridercashdue_lab = isSelected(lab) ? `&lab=${lab}` : "";
  const phleboridercashdue_phonenumber = isSelected(phonenumber)
    ? `&phonenumber=${phonenumber}`
    : "";
  const phleboridercashdue_username = isSelected(username)
    ? `&username=${username}`
    : "";
  const phleboridercashdue_user_status = isSelected(user_status)
    ? `&user_status=${user_status}`
    : "";
  const phleboridercashdue_usergroup = isSelected(usergroup)
    ? `&usergroup=${usergroup}`
    : "";
  const url = `?${phleboridercashdue_usergroup}${phleboridercashdue_user_status}${phleboridercashdue_username}${phleboridercashdue_phonenumber}${phleboridercashdue_lab}${phleboridercashdue_barcode}${phleboridercashdue_rider_id}${phleboridercashdue_booking_id}${phleboridercashdue_start_date}${phleboridercashdue_end_date}${phleboridercashdue_created_at}${booking_city}${phleboridercashdue_phlebo}${phleboridercashdue_rider}`;
  return url;
};

export const genrateUnUploadedBookingFilter = (body) => {
  const { start_date, end_date, created_date } = body;
  const sampleHandover_start_date = isSelected(start_date)
    ? `&start_date=${start_date}`
    : "";
  const sampleHandover_end_date = isSelected(end_date)
    ? `&end_date=${end_date}`
    : "";
  const sampleHandover_created_at = isSelected(created_date)
    ? `&created_date=${created_date}`
    : "";
  const url = `?${sampleHandover_start_date}${sampleHandover_end_date}${sampleHandover_created_at}`;
  return url;
};
export const genrateCenterAddressPriceFilter = (body) => {
  const { center_address, package_id } = body;
  const centeraddress_center_address = isSelected(center_address)
    ? `&center_address=${center_address}`
    : "";
  const centeraddress_package_id = isSelected(package_id)
    ? `&package_id=${package_id}`
    : "";
  const url = `?${centeraddress_center_address}${centeraddress_package_id}`;
  return url;
};
export const generateVerifiedBookingFilterUrl = (body) => {
  const {
    status,
    financeStatus,
    category,
    verificationStatus,
    source,
    agent,
    phlebo,
    city,
    start_date,
    end_date,
    booking_date,
    collection_date,
    name,
    phone,
    pickupAmount,
    pickup_status,
    pickup_done,
    bookingId,
    partner,
    user_group,
    slot,
    sample_registered,
    report_status,
    is_pickup_receive_amount,
    type,
  } = body;
  const booking_category =
    isSelectedNum(category) && category !== "0"
      ? `&multiple_disposition=${category}`
      : "";
  const booking_status = isSelectedNum(status)
    ? `&multiple_booking_status=${status}`
    : "";
  const booking_id = isSelectedNum(bookingId) ? `&booking=${bookingId}` : "";
  const booking_partner = isSelectedNum(partner)
    ? `&multiple_partner=${partner}`
    : "";
  const booking_user_group = isSelectedNum(user_group)
    ? `&multiple_usergroup=${user_group}`
    : "";
  const verification_status = isSelectedNum(verificationStatus)
    ? `&multiple_verification_status=${verificationStatus}`
    : "";
  const booking_source = isSelectedNum(source)
    ? `&multiple_source=${source}`
    : "";
  const booking_agent = isSelectedNum(agent) ? `&multiple_agent=${agent}` : "";
  const booking_phlebo = isSelectedNum(phlebo)
    ? `&multiple_phlebo=${phlebo}`
    : "";

  const booking_city = isSelectedNum(city) ? `&multiple_city=${city}` : "";
  const booking_collection_slots = isSelectedNum(slot)
    ? `&multiple_collection_slots=${slot}`
    : "";
  const booking_start_date = isSelected(start_date)
    ? `&start_date=${start_date}`
    : "";
  const booking_booking_date = isSelected(booking_date)
    ? `&booking_date=${booking_date}`
    : "";
  const booking_end_date = isSelected(end_date) ? `&end_date=${end_date}` : "";
  const booking_collection_date = isSelected(collection_date)
    ? `&collection_date=${collection_date}`
    : "";
  const booking_name = isSelectedNum(name) ? `&name=${name}` : "";
  const booking_type = isSelectedNum(type) ? `&type=${type}` : "";
  const booking_phone = isSelectedNum(phone) ? `&phone=${phone}` : "";
  const booking_pickup_status = isSelectedNum(pickup_status)
    ? `&multiple_pickup_status=${pickup_status}`
    : "";
  const booking_pickup_done = isSelectedNum(pickup_done)
    ? `&pickup_done=${pickup_done}`
    : "";
  const booking_pickup_amount = isSelectedNum(pickupAmount)
    ? `&pickup_amount=${pickupAmount}`
    : "";
  const booking_finance_status = isSelectedNum(financeStatus)
    ? `&multiple_finance_status=${financeStatus}`
    : "";
  const booking_sample_registered = isSelectedNum(sample_registered)
    ? `&sample_registered=${sample_registered}`
    : "";
  const booking_report_status = isSelectedNum(report_status)
    ? `&multiple_report_status=${report_status}`
    : "";
  const booking_is_pickup_receive_amount = isSelectedNum(
    is_pickup_receive_amount
  )
    ? `&is_pickup_receive_amount=${is_pickup_receive_amount}`
    : "";
  const url = `?${booking_type}${booking_is_pickup_receive_amount}${booking_report_status}${booking_sample_registered}${booking_user_group}${booking_collection_slots}${booking_finance_status}${booking_category}${booking_id}${booking_pickup_done}${booking_partner}${booking_pickup_status}${booking_pickup_amount}${booking_agent}${booking_name}${booking_phone}${booking_phlebo}${booking_city}${booking_source}${booking_start_date}${booking_end_date}${booking_collection_date}${booking_status}${booking_booking_date}${verification_status}`;
  return url;
};
export const generateLeadsUrl = (body) => {
  const {
      lead_call_status_id,
      disposition,
      sub_disposition,
      start_date,
      created_date,
      end_date,
      followUpDate,
      leadSource,
      priority,
      city,
      agent,
      inbound_mode,
      phone,
      id,
      tl,
      lead_form_call,
      lead,
      source_type,
      lead_for,
      multiple_phlebo,
      language,
      usergroup,
  } = body;
  const leads_panelUser = isSelectedNum(tl) ? `&tl=${tl}` : "";
  const inboundMode = isSelectedNum(inbound_mode) ? `&inbound_mode=${inbound_mode}` : "";
  const leads_lead_id = isSelectedNum(lead) ? `&lead=${lead}` : "";
  const leads_lead_form_call = isSelectedNum(lead_form_call) ? `&lead_form_call=${lead_form_call}` : "";
  const leads_created_date = isSelected(created_date) ? `&created_date=${created_date}` : "";
  const leads_start_date = isSelected(start_date) ? `&start_date=${start_date}` : "";
  const leads_end_date = isSelected(end_date) ? `&end_date=${end_date}` : "";
  const leads_followUpDate = isSelected(followUpDate) ? `&followup_date=${followUpDate}` : "";
  const leads_phone = isSelected(phone) ? `&phone=${phone}` : "";
  const leads_lead_source = isSelected(leadSource) ? `&multiple_lead_source=${leadSource}` : "";
  const leads_priority = isSelected(priority) ? `&multiple_priority=${priority}` : "";
  const leads_id = isSelected(id) ? `&id=${id}` : "";
  const leads_category = (isSelectedNum(lead_call_status_id) && lead_call_status_id !== "0") ? `&multiple_category_id=${lead_call_status_id}` : "";
  const leads_disposition = (isSelectedNum(disposition) && disposition !== "0") ? `&disposition=${disposition}` : "";
  const leads_sub_disposition = (isSelectedNum(sub_disposition) && sub_disposition !== "0") ? `&sub_disposition=${sub_disposition}` : "";
  const leads_city = (isSelectedNum(city)) ? `&multiple_city=${city}` : "";
  const leads_agent = (isSelectedNum(agent)) ? `&agent=${agent}` : "";
  const leads_source_type = (isSelected(source_type)) ? `&source_type=${source_type}` : "";
  const leads_lead_for = (isSelectedNum(lead_for)) ? `&lead_for=${lead_for}` : "";
  const leads_phlebo = isSelectedNum(multiple_phlebo) ? `&multiple_phlebo=${multiple_phlebo}`: "";
  const leads_language = isSelectedNum(language) ? `&language=${language}`: "";
  const leads_usergroup = isSelectedNum(usergroup) ? `&usergroup=${usergroup}`: "";
  
  const url = `?${leads_lead_form_call}${leads_panelUser}${leads_phone}${leads_category}${leads_agent}${leads_start_date}${leads_end_date}${leads_city}${leads_id}${leads_created_date}${leads_followUpDate}${leads_lead_source}${leads_priority}${leads_lead_id}${inboundMode}${leads_source_type}${leads_lead_for}${leads_disposition}${leads_sub_disposition}${leads_phlebo}${leads_language}${leads_usergroup}`;

  return url;
};
export const generateRCFreshsUrl = (body) => {
  const {
    category,
    start_date,
    end_date,
    createdAt,
    followUpDate,
    leadSource,
    priority,
    city,
    agent,
    phone,
    id,
  } = body;
  const leads_created_date = isSelected(createdAt)
    ? `&created_date=${createdAt}`
    : "";
  const leads_start_date = isSelected(start_date)
    ? `&start_date=${start_date}`
    : "";
  const leads_end_date = isSelected(end_date) ? `&end_date=${end_date}` : "";
  const leads_followUpDate = isSelected(followUpDate)
    ? `&followup_date=${followUpDate}`
    : "";
  const leads_phone = isSelected(phone) ? `&phone=${phone}` : "";
  const leads_lead_source = isSelected(leadSource)
    ? `&multiple_lead_source=${leadSource}`
    : "";
  const leads_priority = isSelected(priority)
    ? `&multiple_priority=${priority}`
    : "";
  const leads_id = isSelected(id) ? `&lead_id=${id}` : "";
  const leads_category =
    isSelectedNum(category) && category !== "0"
      ? `&multiple_category=${category}`
      : "";
  const leads_city = isSelectedNum(city) ? `&multiple_city=${city}` : "";
  const leads_agent = isSelectedNum(agent) ? `&multiple_agent=${agent}` : "";

  const url = `?${leads_phone}${leads_category}${leads_agent}${leads_start_date}${leads_end_date}${leads_city}${leads_id}${leads_created_date}${leads_followUpDate}${leads_lead_source}${leads_priority}`;

  return url;
};

export const generateCallRecordingUrl = (body) => {
  const { status, lead, created_date, direction } = body;
  const call_status = isSelectedNum(status) ? `&call_picked=${status}` : "";
  const call_lead = isSelectedNum(lead) ? `&lead=${lead}` : "";
  const call_created_date = isSelected(created_date)
    ? `&created_date=${created_date}`
    : "";
  const direction_part = isSelectedNum(direction)
    ? `&direction=${direction}`
    : "";
  const url = `?${call_status}${call_lead}${call_created_date}${direction_part}`;
  return url;
};

export const generateWhatsappMessageUrl = (body) => {
  const { start_date, lead, created_date, end_date, panel_user, type } = body;
  const whatsapp_start_date = isSelectedNum(start_date)
    ? `&start_date=${start_date}`
    : "";
  const whatsapp_lead = isSelectedNum(lead) ? `&lead=${lead}` : "";
  const whatsapp_created_date = isSelected(created_date)
    ? `&created_date=${created_date}`
    : "";
  const whatsapp_end_date = isSelectedNum(end_date)
    ? `&end_date=${end_date}`
    : "";
  const whatsapp_panel_user = isSelectedNum(panel_user)
    ? `&panel_user=${panel_user}`
    : "";
  const whatsapp_type = isSelectedNum(type) ? `&type=${type}` : "";
  const url = `?${whatsapp_type}${whatsapp_panel_user}${whatsapp_start_date}${whatsapp_lead}${whatsapp_created_date}${whatsapp_end_date}`;
  return url;
};

export const generateDispositionUrl = (body) => {
  const { category, city, agent, start_date, end_date, created_date } = body;

  const leads_category =
    isSelectedNum(category) && category !== "0"
      ? `&multiple_disposition=${category}`
      : "";
  const leads_city = isSelectedNum(city) ? `&multiple_city=${city}` : "";
  const leads_agent = isSelectedNum(agent) ? `&multiple_agent=${agent}` : "";
  const leads_created_date = isSelected(created_date)
    ? `&created_date=${created_date}`
    : "";
  const leads_start_date = isSelected(start_date)
    ? `&start_date=${start_date}`
    : "";
  const leads_end_date = isSelected(end_date) ? `&end_date=${end_date}` : "";

  const url = `?${leads_category}${leads_agent}${leads_city}${leads_created_date}${leads_start_date}${leads_end_date}`;

  return url;
};
export const getCenterFilter = (body) => {
  const { centre_type_id, name } = body;
  const center_center = isSelectedNum(centre_type_id)
    ? `&centre_type_id=${centre_type_id}`
    : "";
  const center_center_type = isSelectedNum(name) ? `&name=${name}` : "";

  const url = `?${center_center}${center_center_type}`;

  return url;
};
export const generateBookingDispositionUrl = (body) => {
  const {
    category,
    city,
    agent,
    start_date,
    end_date,
    created_date,
    userGroup,
  } = body;

  const leads_category =
    isSelectedNum(category) && category !== "0"
      ? `&multiple_disposition=${category}`
      : "";
  const leads_city = isSelectedNum(city) ? `&multiple_city=${city}` : "";
  const leads_agent = isSelectedNum(agent) ? `&multiple_agent=${agent}` : "";
  const leads_created_date = isSelected(created_date)
    ? `&created_date=${created_date}`
    : "";
  const leads_start_date = isSelected(start_date)
    ? `&start_date=${start_date}`
    : "";
  const leads_end_date = isSelected(end_date) ? `&end_date=${end_date}` : "";
  const leads_usergroup = isSelected(userGroup)
    ? `&usergroup=${userGroup}`
    : "";

  const url = `?${leads_usergroup}${leads_category}${leads_agent}${leads_city}${leads_created_date}${leads_start_date}${leads_end_date}`;

  return url;
};
export const generateAttendenceFilterUrl = (body) => {
  const {
    phlebo,
    rider,
    start_date,
    end_date,
    created_date,
    lab,
    phonenumber
  } = body;
  const attendence_phlebo = isSelectedNum(phlebo)
    ? `&multiple_phlebo=${phlebo}`
    : "";
  const attendence_rider = isSelectedNum(rider)
    ? `&multiple_rider=${rider}`
    : "";
  const attendence_start_date = isSelected(start_date)
    ? `&start_date=${start_date}`
    : "";
  const attendence_created_date = isSelected(created_date)
    ? `&created_date=${created_date}`
    : "";
  const attendence_end_date = isSelected(end_date)
    ? `&end_date=${end_date}`
    : "";
  const attendence_lab = isSelected(lab) ? `&lab=${lab}` : "";
  const Phone_Number = isSelected(phonenumber) ? `&phonenumber=${phonenumber}` : "";

  const url = `?${attendence_lab}${Phone_Number}${attendence_rider}${attendence_phlebo}${attendence_start_date}${attendence_end_date}${attendence_created_date}`;

  return url;
};
export const generateCxFilterUrl = (body) => {
  const {
    status,
    verificationStatus,
    phlebo,
    city,
    start_date,
    end_date,
    booking_date,
    collection_date,
    name,
    phone,
    pickup_status,
    username,
    id,
    phonenumber,
  } = body;
  const PhoneNumber = isSelected(phonenumber) ? `&phonenumber=${phonenumber}` : "";
  const userId = isSelected(id) ? `&id=${id}` : "";
  const booking_status = isSelectedNum(status)
    ? `&multiple_booking_status=${status}`
    : "";
  const userName = isSelectedNum(username)
    ? `&username=${username}`
    : "";
  const booking_phlebo = isSelectedNum(phlebo)
    ? `&multiple_phlebo=${phlebo}`
    : "";
  const booking_city = isSelectedNum(city) ? `&multiple_city=${city}` : "";
  const booking_start_date = isSelected(start_date)
    ? `&start_date=${start_date}`
    : "";
  const booking_booking_date = isSelected(booking_date)
    ? `&booking_date=${booking_date}`
    : "";
  const booking_end_date = isSelected(end_date) ? `&end_date=${end_date}` : "";
  const booking_collection_date = isSelected(collection_date)
    ? `&collection_date=${collection_date}`
    : "";
  const booking_name = isSelectedNum(name) ? `&name=${name}` : "";
  const booking_phone = isSelectedNum(phone) ? `&phone=${phone}` : "";
  const booking_pickup_status = isSelectedNum(pickup_status)
    ? `&multiple_pickup_status=${pickup_status}`
    : "";
  const verification_status = isSelectedNum(verificationStatus)
    ? `&multiple_verification_status=${verificationStatus}`
    : "";

  const url = `?${booking_pickup_status}${PhoneNumber}${userId}${userName}${booking_name}${booking_phone}${booking_phlebo}${booking_city}${booking_start_date}${booking_end_date}${booking_collection_date}${booking_status}${verification_status}${booking_booking_date}`;

  return url;
};
export const generateQueryTicketBookingFilterUrl = (body) => {
  const {
    bookingId,
    ticket_Status,
    query_type,
    created_date,
    start_date,
    end_date,
    lead,
    follow_up_start_date,
    follow_up_end_date,
    ticket_type,
    created_start_date,
    created_end_date,
    ticket_number,
    sub_ticket_type,
    agent,
  } = body;
  const booking_ticket_Status = isSelectedNum(ticket_Status)
    ? `&ticket_status=${ticket_Status}`
    : "";
  const booking_id = isSelectedNum(bookingId) ? `&booking_id=${bookingId}` : "";
  const booking_ticket_number = isSelectedNum(ticket_number)
    ? `&ticket_number=${ticket_number}`
    : "";
  const booking_agent = isSelectedNum(agent) ? `&agent=${agent}` : "";
  const bookig_lead_id = isSelectedNum(lead) ? `&lead=${lead}` : "";
  const booking_query_type = isSelectedNum(query_type)
    ? `&multiple_querytype=${query_type}`
    : "";
  const booking_ticket_type = isSelectedNum(ticket_type)
    ? `&ticket_type=${ticket_type}`
    : "";
  const booking_start_date = isSelected(start_date)
    ? `&start_date=${start_date}`
    : "";
  const booking_end_date = isSelected(end_date) ? `&end_date=${end_date}` : "";
  const booking_created_date = isSelected(created_date)
    ? `&created_date=${created_date}`
    : "";
  const booking_follow_up_start_date = isSelected(follow_up_start_date)
    ? `&follow_up_start_date=${follow_up_start_date}`
    : "";
  const booking_follow_up_end_date = isSelected(follow_up_end_date)
    ? `&follow_up_end_date=${follow_up_end_date}`
    : "";
  const booking_created_start_date = isSelected(created_start_date)
    ? `&created_start_date=${created_start_date}`
    : "";
  const booking_created_end_date = isSelected(created_end_date)
    ? `&created_end_date=${created_end_date}`
    : "";
    const booking_sub_ticket_type= isSelected(sub_ticket_type) ?
    `&sub_ticket_type=${sub_ticket_type}` :
    "";

  const url = `?${bookig_lead_id}${booking_query_type}${booking_ticket_Status}${booking_id}${booking_start_date}${booking_end_date}${booking_created_date}${booking_follow_up_start_date}${booking_follow_up_end_date}${booking_ticket_type}${booking_created_start_date}${booking_created_end_date}${booking_ticket_number}${booking_agent}${booking_sub_ticket_type}`;

  return url;
};
export const generateCurrentDayBookingFilterUrl = (body) => {
  const { city, booking_date, collection_date } = body;
  const booking_city = isSelectedNum(city) ? `&multiple_city=${city}` : "";
  const booking_booking_date = isSelected(booking_date)
    ? `&booking_date=${booking_date}`
    : "";
  const booking_collection_date = isSelected(collection_date)
    ? `&collection_date=${collection_date}`
    : "";
  const url = `?${booking_city}${booking_collection_date}${booking_booking_date}`;

  return url;
};
export const generateCityWisePhlebosFilterUrl = (body) => {
  const { city } = body;
  const booking_city = isSelectedNum(city) ? `&multiple_city=${city}` : "";

  const url = `?${booking_city}`;

  return url;
};
export const generateSmsFilterUrl = (body) => {
  const { booking, phonenumber } = body;
  const booking_id = isSelectedNum(booking) ? `&booking=${booking}` : "";
  const booking_phone = isSelectedNum(phonenumber)
    ? `&phonenumber=${phonenumber}`
    : "";
  const url = `?${booking_id}${booking_phone}`;

  return url;
};
export const generateEmailFilterUrl = (body) => {
  const { booking, email } = body;
  const booking_id = isSelectedNum(booking) ? `&booking=${booking}` : "";
  const booking_email = isSelectedNum(email) ? `&email=${email}` : "";
  const url = `?${booking_id}${booking_email}`;

  return url;
};
export const generatePincodeFilterUrl = (body) => {
  const { city, pincode } = body;
  const booking_city = isSelectedNum(city) ? `&multiple_city=${city}` : "";
  const booking_pincode = isSelected(pincode) ? `&search=${pincode}` : "";
  const url = `?${booking_city}${booking_pincode}`;

  return url;
};
export const generateBreakUrl = (body) => {
  const { agent, start_date, end_date, created_date } = body;

  const leads_agent = isSelectedNum(agent) ? `&panel_user=${agent}` : "";
  const leads_created_date = isSelected(created_date)
    ? `&created_date=${created_date}`
    : "";
  const leads_start_date = isSelected(start_date)
    ? `&start_date=${start_date}`
    : "";
  const leads_end_date = isSelected(end_date) ? `&end_date=${end_date}` : "";

  const url = `?${leads_agent}${leads_created_date}${leads_start_date}${leads_end_date}`;

  return url;
};

export const generateCityPriceFilter = (body) => {
  const { city, package_id } = body;
  const cityPrice_city = isSelectedNum(city) ? `&city=${city}` : "";
  const cityPrice_package = isSelectedNum(package_id)
    ? `&package_id=${package_id}`
    : "";

  const url = `?${cityPrice_city}${cityPrice_package}`;

  return url;
};

export const generateBlockedCardFilter = (body) => {
  const { package_id, Zone } = body;
  const blockedCard_package = isSelectedNum(package_id)
    ? `&package_id=${package_id}`
    : "";
  const blockedCard_Zone = isSelectedNum(Zone) ? `&Zone=${Zone}` : "";

  const url = `?${blockedCard_Zone}${blockedCard_package}`;

  return url;
};

export const generateProductManagementFilter = (body) => {
  const {
    package_id,
    code,
    parameter,
    category,
    recommended_gender,
    name,
    package_price,
    fasting_time,
    recommended_age,
    addon_price,
    offer_price,
    status,
    tat_time,
  } = body;
  const management_package = isSelectedNum(package_id)
    ? `& package_id=${package_id}`
    : "";
  const management_code = isSelectedNum(code) ? `& code=${code}` : "";
  const package_status = isSelectedNum(status) ? `& status=${status}` : "";
  const tat = isSelectedNum(tat_time) ? `& tat_time=${tat_time}` : "";
  const management_parameter = isSelectedNum(parameter)
    ? `& parameter=${parameter}`
    : "";
  const management_category = isSelectedNum(category)
    ? `& category=${category}`
    : "";
  const addon_Price = isSelectedNum(addon_price)
    ? `& addon_Price=${addon_price}`
    : "";
  const offer_Price = isSelectedNum(offer_price)
    ? `& offer_Price=${offer_price}`
    : "";
  const management_recommended_gender = isSelectedNum(recommended_gender)
    ? `& recommended_gender=${recommended_gender}`
    : "";
  const management_package_price = isSelectedNum(package_price)
    ? `& package_price=${package_price}`
    : "";
  const management_name = isSelectedNum(name) ? `& name=${name}` : "";
  const recommended_age1 = isSelectedNum(recommended_age)
    ? `& recommended_age=${recommended_age}`
    : "";
  const management_fasting = isSelectedNum(fasting_time)
    ? `& fasting_time=${fasting_time}`
    : "";
  const url = `${management_package}${tat}${package_status}${offer_Price}${addon_Price}${recommended_age1}${management_code}${management_parameter}${management_category}${management_recommended_gender}${management_name}${management_package_price}${management_fasting}`;

  return url;
};
export const genrateTatTableFilter = (body) => {
  const {
    customer_name,
    id,
    lead,
    mobile_number,
    collection_date_start,
    collection_date_end,
    collection_date,
    barcode,
    batch_id,
  } = body;

  const tatTable_customer_name = isSelected(customer_name)
    ? `&customer_name=${customer_name}`
    : "";
  const tatTable_booking_id = isSelectedNum(id) ? `&id=${id}` : "";
  const tatTable_barcode_id = isSelectedNum(barcode)
    ? `&barcode=${barcode}`
    : "";
  const tatTable_batch_id = isSelectedNum(batch_id)
    ? `&batch_id=${batch_id}`
    : "";
  const tatTable_lead_id = isSelectedNum(lead) ? `&lead=${lead}` : "";
  const tatTable_mobile_number = isSelected(mobile_number)
    ? `&mobile_number=${mobile_number}`
    : "";
  const tatTable_collection_date_start = isSelected(collection_date_start)
    ? `&collection_date_start=${collection_date_start}`
    : "";
  const tatTable_collection_date_end = isSelected(collection_date_end)
    ? `&collection_date_end=${collection_date_end}`
    : "";
  const tatTable_collection_date = isSelected(collection_date)
    ? `&collection_date=${collection_date}`
    : "";

  const url = `?${tatTable_customer_name}${tatTable_booking_id}${tatTable_mobile_number}${tatTable_lead_id}${tatTable_collection_date_start}${tatTable_collection_date_end}${tatTable_barcode_id}${tatTable_batch_id}${tatTable_collection_date}`;
  return url;
};

export const generateStopsFilter = (body) => {
  const { name, rider, phlebo, cc, center, city, } = body;

  const stops_name = isSelected(name) ? `&name=${name}` : "";
  const centerId = isSelected(center) ? `&center=${center}` : "";
  const phlebo_name = isSelected(phlebo) ? `&phlebo=${phlebo}` : "";
  const center_name = isSelected(cc) ? `&cc=${cc}` : "";
  const rider_name = isSelected(rider) ? `&rider=${rider}` : "";
  const stops_city = isSelectedNum(city) ? `&city=${city}` : "";


  const url = `?${stops_name}${stops_city}${rider_name}${phlebo_name}${center_name}${centerId}`;
  return url;
};

export const generateQueryTicketLeadFilterUrl = (body) => {
  const { ticket_Status, start_date, end_date, id } = body;
  const lead_ticket_Status = isSelectedNum(ticket_Status)
    ? `&multiple_ticket_status=${ticket_Status}`
    : "";

  const lead_lead_id = isSelectedNum(id) ? `&id=${id}` : "";
  const lead_start_date = isSelected(start_date)
    ? `&start_date=${start_date}`
    : "";
  const lead_end_date = isSelected(end_date) ? `&end_date=${end_date}` : "";

  const url = `?${lead_lead_id}${lead_ticket_Status}${lead_start_date}${lead_end_date}`;

  return url;
};

export const batchPaymentFilter = (body) => {
  const { bookingId, batchNumber, lab, depositDate, phlebo, created_end_date, created_start_date } = body;

  const booking = isSelectedNum(bookingId) ? `&booking=${bookingId}` : "";
  const batch = isSelectedNum(batchNumber) ? `&batch=${batchNumber}` : "";
  const labs = isSelectedNum(lab) ? `&lab=${lab}` : "";
  const phlebos = isSelectedNum(phlebo) ? `&panel_user=${phlebo}` : "";
  const date = isSelectedNum(depositDate) ? `&created_at=${depositDate}` : "";
  const Batchcreatedstartdate = isSelectedNum(created_start_date) ? `&created_start_date=${created_start_date}` : "";
  const Batchcreatedenddate = isSelectedNum(created_end_date) ? `&created_end_date=${created_end_date}` : "";
  const url = `?${booking}${batch}${labs}${phlebos}${date}${Batchcreatedstartdate}${Batchcreatedenddate}`;
  return url;
};
export const batchPaymentFilter1 = (body) => {
  const {
    status,
    batch,
    createdAtGreater,
    createdAtLesser,
    createdAt,
    panel_user,
    booking,
    lab,
    created_start_date,
    created_end_date,
  } = body;
  const payment_status = isSelected(status) ? `&status=${status}` : "";
  const payment_batch = isSelectedNum(batch) ? `&batch=${batch}` : "";
  const payment_panel_user = isSelectedNum(panel_user)
    ? `&panel_user=${panel_user}`
    : "";
  const payment_booking = isSelectedNum(booking) ? `&booking=${booking}` : "";
  const payment_lab = isSelectedNum(lab) ? `&lab=${lab}` : "";
  const payment_createdAtGreater = isSelectedNum(createdAtGreater)
    ? `&created_end_date=${createdAtGreater}`
    : "";
  const payment_createdAtLesser = isSelectedNum(createdAtLesser)
    ? `&created_start_date=${createdAtLesser}`
    : "";
  const payment_createdAt = isSelectedNum(createdAt)
    ? `&created_at=${createdAt}`
    : "";
  const payment_created_start_date = isSelectedNum(created_start_date)
    ? `&created_start_date=${created_start_date}`
    : "";
  const payment_created_end_date = isSelectedNum(created_end_date)
    ? `&created_end_date=${created_end_date}`
    : "";

  const url = `?${payment_status}${payment_createdAtGreater}${payment_createdAtLesser}${payment_createdAt}${payment_batch}${payment_panel_user}${payment_booking}${payment_lab}${payment_created_start_date}${payment_created_end_date}`;
  return url;
};

export const genrateManagePhlebos = (body) => {
  const { phlebo, zone, base_city } = body;
  const managePhlebos_city = isSelected(base_city)
    ? `&base_city=${base_city}`
    : "";
  const managePhlebos_zone = isSelectedNum(zone) ? `&zone=${zone}` : "";
  const managePhlebos_phlebo = isSelected(phlebo) ? `&search=${phlebo}` : "";
  const url = `?${managePhlebos_city}${managePhlebos_zone}${managePhlebos_phlebo}`;
  return url;
};



export const genrateDcCampSampleListFilter = (body) => {
  const {
    id,
    created_start_date,
    created_end_date,
    received_start_date,
    received_end_date,
    center,
    received_from
  } = body;
  const dcCampList_id = isSelected(id)
  ? `&id=${id}`
  : "";
  const dcCampList_start_date = isSelected(created_start_date)
    ? `&created_start_date=${created_start_date}`
    : "";
  const dcCampList_end_date = isSelected(created_end_date)
    ? `&created_end_date=${created_end_date}`
    : "";
  const dcCampList_center = isSelected(center)
    ? `&center=${center}`
    : "";
  const dcCampList_receivedFrom = isSelected(received_from)
    ? `&received_from=${received_from}`
    : "";
    const dcCampList_received_start_date = isSelected(received_start_date)
    ? `&created_start_date=${received_start_date}`
    : "";
  const dcCampList_start_end_date = isSelected(received_end_date)
    ? `&created_end_date=${received_end_date}`
    : "";
  const url = `?${dcCampList_id}${dcCampList_start_date}${dcCampList_end_date}${dcCampList_center}${dcCampList_receivedFrom}${dcCampList_received_start_date}${dcCampList_start_end_date}`;
  return url;
};
export const genrateRiderCountFilter = (body) => {
  const {
    rider,
    start_date,
    end_date,
    rider_action,
    lab,
  } = body;

  const rider_ridercount = isSelectedNum(rider) ? `&rider=${rider}` : "";
  const start_date_ridercount = isSelected(start_date)
  ? `&start_date=${start_date}`
  : "";
const end_date_ridercount = isSelected(end_date) ? `&end_date=${end_date}` : "";
const rider_action_ridercount = isSelected(rider_action) ? `&rider_action=${rider_action}` : "";
const rider_labName = isSelected(lab) ? `&lab=${lab}` : "";

  const url = `?${rider_ridercount}${start_date_ridercount}${end_date_ridercount}${rider_action_ridercount}${rider_labName}`;
  return url;
};