import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Card,
  Chip,
  Dialog,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { Autocomplete } from "@material-ui/lab";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import {
  getBookingById,
  clickToCallBooking,
  updateBooking,
  getAvailableSlots,
  getPackage,
  updateAdditionalBooking,
  getPaymentSendLink,
  getUnmaskedNumber,
  updatePhleboReachedStatus,
  getHivPackage,
  getBiomarkerScreening,
  getNewLeadDetails,
} from "../../../actions/RouteManagerAction";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Loader from "../../../../components/loader";
import moment from "moment";
import CallIcon from "@material-ui/icons/Call";
import { GridCloseIcon } from "@material-ui/data-grid";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Popover from "@material-ui/core/Popover";
import RaiseComplaintModal from "../../Comments/raiseTicketModal";
import { useRef } from "react";
import RemoveTest from "./removePackage";
import AddTest from "./addPackage";
import PhleboModal from "../../Comments/phleboComments";
import { CALL_DISABLE_TIME } from "../../../../CallTimeDisable";
import { ACTIONS } from "../../../interfaces/actionTypes/RouteManagerInterface";
import {  } from "../../../../actions/loginActions"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      marginTop: "2rem",
      padding: "0.5rem 2rem",
      width: "100%",
    },
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    typography: {
      padding: theme.spacing(2),
    },
    slotContainer: {
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "center",
    },
    slot: {
      width: "150px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      margin: "0.5rem",
    },
    selectedSlot: {
      width: "150px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      background: "linear-gradient(60deg, cyan, #00dfff, cyan)",
      cursor: "pointer",
      margin: "0.5rem",
    },
    tableHead: {
      fontWeight: "bold",
      textTransform: "capitalize",
      textAlign: "center",
    },
  })
);

interface Props {
  match: any;
  booking: any;
  getBookingById: any;
  partnerList: any;
  getPartners: any;
  clickToCallBooking: any;
  updateBooking: any;
  getAvailableSlots: any;
  getPaymentSendLink: any;
  booking_slots: any;
  complaints: any;
  getPackage: any;
  packageList: any;
  updateAdditionalBooking: any;
  additional_booking: any;
  getUnmaskedNumber: any;
  unmaskedNumber: any;
  updatePhleboReachedStatus: any;
  loading: Boolean;
  getHivPackage: any;
  hivpackageList: any;
  getBiomarkerScreening: any;
  biomarkerScreening: any;
  getNewLeadDetails: any;
  newLeadDetails: any;
}

const PhleboAdminViewBooking: React.FC<Props> = ({
  match,
  booking,
  getBookingById,
  clickToCallBooking,
  updateBooking,
  getAvailableSlots,
  booking_slots,
  getPackage,
  packageList,
  complaints,
  updateAdditionalBooking,
  getPaymentSendLink,
  additional_booking,
  getUnmaskedNumber,
  unmaskedNumber,
  updatePhleboReachedStatus,
  loading,
  getHivPackage,
  hivpackageList,
  getBiomarkerScreening,
  biomarkerScreening,
  newLeadDetails,
  getNewLeadDetails,
}) => {
  const classes = useStyles();
  let dispatch = useDispatch();
  const timer = useRef<any>(0);
  const [timeSlots, setTimeSlots] = useState<Array<Object>>([]);
  const [selectedSlot, setSelectedSlot] = useState<Number>(
    booking?.collection_slot && booking?.collection_slot?.id
  );
  const [customer_designation, setCustomerDesignation] = useState<String>(" ");
  const [customer_name, setCustomer_name] = useState<any>("");
  const [customer_age, setCustomer_age] = useState<any>("");
  const [customer_gender, setCustomer_gender] = useState<any>("male");
  const [customer_aadhar, setCustomer_aadhar] = useState<any>("");
  const [packageDetails, setPackageDetails] = useState<any>([]);
  const [packageDetailsOld, setPackageDetailsOld] = useState<any>([]);
  const [packageDetailsMainMember, setPackageDetailsMainMember] = useState<any>(booking?.packages);
  const [packageDetailsAdditionalMembers, setPackageDetailsAdditionalMembers] = useState<any>(booking?.additional_members?.filter(
    (mem: any) => (mem?.pickup_status !== "cancelled" && mem?.booking_status !== "cancelled")
  )?.map((p: any) => {
    return p.packages;
  }));
  const [packageDetailsAdditionalMembersNewArray, setPackageDetailsAdditionalMembersNewArray] = useState<any>([]);
  const [id, setId] = useState(0);
  const [booking_status, setBooking_status] = useState<string>("pending");
  const [pickup_status, setPickup_status] = useState("pending");
  const [phlebo_verification_remark, setPhlebo_verification_remark] =
    useState<string>("");
  const [phlebo_incentive, setPhlebo_incentive] = useState("");
  const [phlebo_convenience, setPhlebo_convenience] = useState("");
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [open, setOpen] = React.useState(false);
  const [totalPrice, setTotalPrice] = useState<any>(
    booking?.discounted_price && booking?.discounted_price?.final_total_price
  );
  const [leadId, setLeadId] = useState<number>(0);
  const [edit, setEdit] = useState(false);
  const openAnchor = Boolean(anchorEl);
  const [is_price_hide, setIsPriceHode] = useState<boolean>(
    booking?.partner?.is_price_hide
  );
  const [price, setPrice] = useState<any>(0);
  const [extraCharge, setExtraCharge] = useState<boolean>(
    booking?.discounted_price && booking?.discounted_price?.phlebo_cost !== 0
  );
  const [openAddModal, setOpenAddModal] = useState<any>(false);
  const [openRemoveModal, setOpenRemoveModal] = useState<any>(false);
  const [bId, setBId] = useState<any>("");
  const [aId, setAId] = useState<any>("");
  const [type, setType] = useState<any>("");
  const [personId, setPersoneId] = React.useState("none");
  const [alreadyARedPro, setAlreadyARedPro] = useState<any>(false);
  const [raiseComplaint, setRaiseComplaint] = useState(false);
  const [openModal, setopenModal] = useState<boolean>(false);
  const [redProMembershipDiscount, setRedProMembershipDiscount] = useState<any>(0);
  const [callButton, setCallButton] = useState<boolean>(true);
  const popid = open ? "simple-popover" : undefined;
  const handleClose = () => {
    setOpen(false);
  };

  const handleAnchorClose = () => {
    setAnchorEl(null);
  };

  const handleOpen = () => {
    setOpen(true);
    getSlots();
  };

  const getSlots = () => {
    getAvailableSlots(booking && booking?.collection_date && booking?.collection_date, booking?.customer_zone);
  };

  useEffect(() => {
    const slots = booking_slots && booking_slots.results;
    setTimeSlots(slots && slots[0].zone_data && slots[0].zone_data.time_slots);
  }, [booking_slots]);

  const bookingId = match.params.id;
  useEffect(() => {
    getBookingById(`${bookingId}/`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingId, additional_booking]);

  useEffect(() => {
    if (booking?.pk == bookingId) {
      setSelectedSlot(booking.collection_slot && booking.collection_slot?.id);
      if(booking?.lead!==null&&booking?.lead>0){
        getNewLeadDetails(`${booking?.lead}/?booking_id=${bookingId}`, "false");
      }
     
    }
  }, [booking]);

  useEffect(() => {
    if (booking?.pk == bookingId && booking?.cityid) {
      setPackageDetailsMainMember(booking?.packages);
      setPackageDetailsAdditionalMembers(booking?.additional_members?.filter(
        (mem: any) => (mem?.pickup_status !== "cancelled" && mem?.booking_status !== "cancelled")
      )?.map((p: any) => {
        return p.packages;
      }))
    }
  }, [booking]);

  useEffect(() => {
    if (packageDetailsAdditionalMembers?.length === 1) {
      let list;
      list = packageDetailsAdditionalMembers[0];
      setPackageDetailsAdditionalMembersNewArray(list);
    } else if (packageDetailsAdditionalMembers?.length === 2) {
      let list;
      list = packageDetailsAdditionalMembers[0]?.concat(packageDetailsAdditionalMembers[1]);
      setPackageDetailsAdditionalMembersNewArray(list);
    } else if (packageDetailsAdditionalMembers?.length === 3) {
      let list;
      list = packageDetailsAdditionalMembers[0]?.concat(packageDetailsAdditionalMembers[1]?.concat(packageDetailsAdditionalMembers[2]))
      setPackageDetailsAdditionalMembersNewArray(list);
    } else if (packageDetailsAdditionalMembers?.length === 4) {
      let list;
      list = packageDetailsAdditionalMembers[0]?.concat(packageDetailsAdditionalMembers[1]?.concat(packageDetailsAdditionalMembers[2]?.concat(packageDetailsAdditionalMembers[3])));
      setPackageDetailsAdditionalMembersNewArray(list);
    }
  }, [packageDetailsAdditionalMembers])

  const handleAddTest = (bid: any, aid: any, type: any) => {
    setOpenAddModal(true);
    setBId(bid);
    setType(type);
    setAId(aid);
  };
  const handleRemoveTest = (bid: any, aid: any, type: any) => {
    setOpenRemoveModal(true);
    setBId(bid);
    setType(type);
    setAId(aid);
  };
  const handleCloseAddModal = () => {
    // history.push(`/dashboard/rm/booking-view/${booking?.pk}`);
    setOpenRemoveModal(false);
    setOpenAddModal(false);
    getBookingById(`${bookingId}/`);
  };
  useEffect(() => {
    if (newLeadDetails && newLeadDetails?.subscriptions?.length > 0 && newLeadDetails?.subscriptions[0]?.discount > 0) {
      setAlreadyARedPro(true)
      setRedProMembershipDiscount(Math?.floor((booking?.discounted_price?.total_price_package / 100) * newLeadDetails?.subscriptions[0]?.discount));
    }
  }, [newLeadDetails])

  const history = useHistory();
  const cancelHandler = () => {
    // const data: any = {
    //     "booking_status": "cancelled"
    // }
    // updateBooking(data, bookingId)
    history.push(`/dashboard/rm/booking/tickets`);
  };

  const handleEditMember = (member: any) => {
    setCustomerDesignation(member?.designation);
    setCustomer_aadhar(member?.customer_aadhar);
    setCustomer_age(member?.customer_age);
    setCustomer_gender(member?.customer_gender);
    setCustomer_name(member?.customer_name);
    setPackageDetails(member?.packages);
    setPackageDetailsOld(member?.packages);
    setSelectedSlot(member?.collection_slot?.id);
    setBooking_status(member?.booking_status);
    setPickup_status(member?.pickup_status);
    setPhlebo_convenience(member?.phlebo_convenience);
    setPhlebo_incentive(member?.phlebo_incentive);
    setPhlebo_verification_remark(member?.phlebo_verification_remark);
    setId(member?.id);
    handleOpen();
  };

  const updateFamilyMemberHandler = async () => {

    let packagesOld = packageDetailsOld?.map((pack: any) => {
      return pack?.package_city_prices ? pack?.package_city_prices?.offer_price : pack?.offer_price;
    });
    let packagesOldPrice = packagesOld.reduce((packSum: any, a: any) => packSum + a, 0);

    let packagesNew = packageDetails?.map((pack: any) => {
      return pack?.package_city_prices ? pack?.package_city_prices?.offer_price : pack?.offer_price;
    });
    let packagesNewPrice = packagesNew.reduce((packSum: any, a: any) => packSum + a, 0);

    let packagesMainMember = packageDetailsMainMember?.map((pack: any) => {
      return pack?.package_city_prices ? pack?.package_city_prices?.offer_price : pack?.offer_price;
    });
    let packagesMainMemberPrice = packagesMainMember.reduce((packSum: any, a: any) => packSum + a, 0);

    let packagesAdditionalMembers = packageDetailsAdditionalMembersNewArray?.map((pack: any) => {
      return pack?.package_city_prices ? pack?.package_city_prices?.offer_price : pack?.offer_price;
    });
    let packagesAdditionalMembersPrice = packagesAdditionalMembers.reduce((packSum: any, a: any) => packSum + a, 0);

    let packagesOldPriceFinal = packagesAdditionalMembersPrice + packagesMainMemberPrice;
    let packagesNewPriceFinal = packagesNewPrice + packagesAdditionalMembersPrice + packagesMainMemberPrice - packagesOldPrice;

    const body: any = {
      pickup_status,
      phlebo_convenience,
      phlebo_incentive,
      phlebo_verification_remark,
      remove_coupon: "true",
      packages: packageDetails?.map((pack: any) => {
        return pack?.id;
      }),
    };

    if (booking?.coupon_applied?.discount_type === "percentage") {
      if (packagesOldPriceFinal <= packagesNewPriceFinal) {
        delete body["remove_coupon"];
      } else {
        if ((packagesOldPriceFinal < 1000 && packagesNewPriceFinal < 1000) || (packagesOldPriceFinal > 4999 && packagesNewPriceFinal > 4999) || ((packagesOldPriceFinal > 999 && packagesNewPriceFinal > 999) && (packagesOldPriceFinal < 2000 && packagesNewPriceFinal < 2000)) || ((packagesOldPriceFinal > 1999 && packagesNewPriceFinal > 1999) && (packagesOldPriceFinal < 5000 && packagesNewPriceFinal < 5000))) {
          delete body["remove_coupon"];
        }
      }
    } else {
      delete body["remove_coupon"];
    }
    if (booking?.booking_type === "b2b") {
      delete body["remove_coupon"];
    }
    await updateAdditionalBooking(id, body);
    setOpen(false);
    setCustomer_aadhar("");
    setCustomer_age("");
    setCustomer_gender("male");
    setCustomer_name("");
    setPackageDetails([]);
    setBooking_status("pending");
    setPickup_status("pending");
    setPhlebo_convenience("");
    setPhlebo_incentive("");
    setPhlebo_verification_remark("");
    setCustomerDesignation(" ");
    setId(0);
  };
  const handleRefreshPaymentSend = () => {
    getPaymentSendLink(booking?.pk);
    alert("Fresh Payment Link Send Successfully");
    setTimeout(() => {
      getBookingById(`${bookingId}/`);
    }, 2000);
  };
  const handlePaymentSend = () => {
    getPaymentSendLink(booking?.pk);
    alert("Payment Link Send Successfully");
    setTimeout(() => {
      getBookingById(`${bookingId}/`);
    }, 2000);
  };

  const handleUnmaskedNumber = (e: any, id: any, source: any, type: any) => {
    setAnchorEl(e.currentTarget);
    getUnmaskedNumber(id, source, type);
  };
  const handlePhleboReached = () => {
    setopenModal(true);
    // const body = {
    //   booking: booking?.pk,
    //   current_status: "punched",
    // };
    // updatePhleboReachedStatus(body);
    // history.push(`/dashboard/rm/booking-view/${booking?.pk}`);
  };
  // const handlePhleboReached = () => {
  //   const body = {
  //     booking: booking?.pk,
  //     current_status: "punched",
  //   };
  //   updatePhleboReachedStatus(body);
  //   history.push(`/dashboard/rm/booking-view/${booking?.pk}`);
  // };
  const handleCallClick = (id: number, type: string) => {
    setLeadId(id);
    setCallButton(false);
    clickToCallBooking(id, type);
    setTimeout(() => {
      setCallButton(true);
    }, CALL_DISABLE_TIME);
  };

  useEffect(() => {
    if (bookingId !== 0 || bookingId !== undefined) {
      if (Number(bookingId)) {
        getHivPackage(`?booking=${Number(bookingId)}`);
      }
    }
  }, [booking]);

  useEffect(() => {
    if (bookingId !== 0 || bookingId !== undefined) {
      if (Number(bookingId)) {
        getBiomarkerScreening(`booking_id=${Number(bookingId)}`);
      }
    }
  }, [bookingId]);

  useEffect(() => {
    if (personId !== "none" && String(personId) === String(bookingId)) {
      if (Number(personId)) getBiomarkerScreening(`booking_id=${personId}`);
    } else if (personId !== "none" && String(personId) !== String(bookingId)) {
      if (Number(personId))
        getBiomarkerScreening(`add_member_booking=${personId}`);
    }
  }, [personId]);
 

  return (
    <>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <div className={classes.buttonContainer}>

         

          <Button
            disabled={booking?.plink_id === null}
            onClick={() => handleRefreshPaymentSend()}
            variant="contained"
            style={{ marginRight: "1rem", backgroundColor: "#009688" }}
          >
            Fresh Payment Link
          </Button>

          <Button
            disabled={
              booking?.receivable_amount === 0 ||
              booking?.plink_id !== null ||
              booking?.pickup_receive_amount !== null
            }
            onClick={() => handlePaymentSend()}
            variant="contained"
            style={{
              marginRight: "1rem",
              backgroundColor: "#62f57a",
            }}
          >
            Send Payment Link
          </Button>
          {booking?.report_status === "consolidate" || booking?.booking_status === "cancelled" ?
            "" : (
              booking?.booking_status === "oldresampling" ? "" : (
                <Link
                  to={`/dashboard/rm/${booking?.org_type == "homedx" ? "booking-edit" : "booking-imaging-edit"}/${booking?.pk}/edit`}
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    style={{ marginLeft: "1rem" }}
                    onClick={() => dispatch({ type: ACTIONS.GET_BOOKING_BY_ID, payload: {} })}
                  >
                    Edit Booking
                  </Button>
                </Link>
              )
            )}
          {booking?.booking_status === "pending" &&
            booking?.booking_status !== "cancelled" && (
              <Button
                variant="contained"
                color="secondary"
                style={{ marginLeft: "1rem" }}
                onClick={() => cancelHandler()}
              >
                Cancel
              </Button>
            )}

          {booking && booking?.booking_type !== "b2b" ? (
            <Button
              variant={!raiseComplaint ? "contained" : "outlined"}
              color="primary"
              style={{ marginLeft: "1rem", marginBottom: "1rem" }}
              onClick={() => setRaiseComplaint(!raiseComplaint)}
            >
              Raise a Ticket
            </Button>
          ) : ""}

          <Button
            disabled={
              booking?.pickup_status === "confirmed" || booking?.phlebo === null
            }
            variant="contained"
            style={{ marginLeft: "1rem", backgroundColor: "#ffab00" }}
            onClick={() => handlePhleboReached()}
          >
            Phlebo Reached
          </Button>
          {booking?.pickup_status === "confirmed" && (
            <Link
              to={`/dashboard/rm/download/${booking?.pk}/download`}
              style={{ textDecoration: "none" }}
            >
              <Button
                variant="contained"
                color="secondary"
                style={{ marginLeft: "1rem", marginBottom: "1rem" }}
              >
                TRF
              </Button>
            </Link>
          )}
          {hivpackageList && hivpackageList?.length !== 0 ? (
            <Link
              to={`/dashboard/rm/routemanager-hiv/${booking?.pk}`}
              style={{ textDecoration: "none" }}
            >
              <Button
                variant="contained"
                disabled={hivpackageList?.length === 0}
                style={{
                  background: "#1976d2",
                  marginLeft: "1rem",
                  marginBottom: "1rem",
                }}
              >
                Consent Form
              </Button>
            </Link>
          ) : (
            ""
          )}

          {biomarkerScreening &&
            biomarkerScreening?.results &&
            biomarkerScreening?.results?.length !== 0 ? (
            <Link
              to={`/dashboard/rm/routemanager-BiomarkerScreeningreport/${booking?.pk}`}
              style={{ textDecoration: "none" }}
            >
              <Button
                variant="contained"
                style={{
                  background: "#1976d2",
                  marginLeft: "1rem",
                  marginBottom: "1rem",
                }}
              >
                Double Marker Form
              </Button>
            </Link>
          ) : (
            ""
          )}
        </div>
        <Dialog open={openAddModal}>
          <Grid container md={12} style={{ padding: "15px 20px 0 20px" }}>
            <Grid item xs={6} style={{ textAlign: "left" }}>
              <h4>Test Addon</h4>
            </Grid>
            <Grid item xs={6} style={{ textAlign: "right" }}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleCloseAddModal}
              >
                Close
              </Button>
            </Grid>
          </Grid>

          <AddTest id={bId} aid={aId} type={type} />
        </Dialog>
        <Dialog open={openRemoveModal}>
          <Grid container md={12} style={{ padding: "15px 20px 0 20px" }}>
            <Grid item xs={6} style={{ textAlign: "left" }}>
              <h4>Remove Test</h4>
            </Grid>
            <Grid item xs={6} style={{ textAlign: "right" }}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleCloseAddModal}
              >
                Close
              </Button>
            </Grid>
          </Grid>

          <RemoveTest id={bId} aid={aId} type={type} />
        </Dialog>
        {booking?.center?.center_type == "corporate" ? (
          <Dialog fullScreen open={open} onClose={handleClose}>
            <AppBar className={classes.appBar}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <GridCloseIcon />
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                  Edit Family Member
                </Typography>
                {edit ? (
                  <Button
                    autoFocus
                    color="inherit"
                    onClick={updateFamilyMemberHandler}
                    disabled={packageDetails?.length == 0}
                  >
                    Save
                  </Button>
                ) : (
                  <Button
                    autoFocus
                    color="inherit"
                    onClick={updateFamilyMemberHandler}
                  >
                    Save
                  </Button>
                )}
              </Toolbar>
            </AppBar>
            <Grid
              container
              spacing={3}
              style={{ margin: "1rem auto", padding: "0 1rem" }}
            >
              <Grid item xs={12} md={6}>
                <Grid container spacing={3} alignItems="center">
                  <Grid item xs={12} md={4}>
                    <p>Salutation*</p>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Select
                      className="input"
                      name="customer_designation"
                      variant="outlined"
                      value={customer_designation}
                      style={{ width: "100%", margin: "0" }}
                      onChange={(e) =>
                        setCustomerDesignation(e.target.value as String)
                      }
                      required
                    >
                      <MenuItem value="Mr">Mr</MenuItem>
                      <MenuItem value="Ms">Ms (Mrs/Miss)</MenuItem>
                    </Select>
                  </Grid>
                </Grid>
                <Grid container spacing={3} alignItems="center">
                  <Grid item xs={12} md={4}>
                    <p>Customer Name</p>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <input
                      className="input"
                      name="collection_date"
                      type="name"
                      placeholder="Customer Name"
                      value={customer_name}
                      onChange={(e) => setCustomer_name(e.target.value)}
                      style={{
                        width: "100%",
                        margin: "0",
                        padding: "18.5px 14px",
                      }}
                      required
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3} alignItems="center">
                  <Grid item xs={12} md={4}>
                    <p>Customer Aadhar</p>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <input
                      className="input"
                      name="collection_date"
                      type="number"
                      placeholder="Customer Aadhar"
                      value={customer_aadhar}
                      onChange={(e) => setCustomer_aadhar(e.target.value)}
                      style={{
                        width: "100%",
                        margin: "0",
                        padding: "18.5px 14px",
                      }}
                      required
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={3} alignItems="center">
                  <Grid item xs={12} md={4}>
                    <p>Customer Age</p>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <input
                      className="input"
                      name="collection_date"
                      type="number"
                      placeholder="Customer Age"
                      value={customer_age}
                      onChange={(e) => setCustomer_age(e.target.value)}
                      style={{
                        width: "100%",
                        margin: "0",
                        padding: "18.5px 14px",
                      }}
                      required
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3} alignItems="center">
                  <Grid item xs={12} md={4}>
                    <p>Customer Gender</p>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Select
                      className="input"
                      name="customer_gender"
                      variant="outlined"
                      value={customer_gender}
                      style={{ width: "100%", margin: "0", padding: "0" }}
                      onChange={(e) => setCustomer_gender(e.target.value)}
                      required
                    >
                      <MenuItem selected hidden value={"none"}>
                        Please Select
                      </MenuItem>
                      <MenuItem value={"male"}>Male</MenuItem>
                      <MenuItem value={"female"}>Female</MenuItem>

                    </Select>
                  </Grid>
                </Grid>
                <Grid container spacing={3} alignItems="center">
                  <Grid item xs>
                    <p style={{ textAlign: "center", fontWeight: "bold" }}>
                      Add Test
                    </p>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ height: 54 }}
                      fullWidth
                      onClick={() =>
                        handleAddTest(
                          booking?.id === undefined ? booking?.pk : booking?.id,
                          id,
                          "add_member"
                        )
                      }
                    >
                      Test Addon
                    </Button>
                  </Grid>
                  <Grid item xs>
                    <p style={{ textAlign: "center", fontWeight: "bold" }}>
                      Remove Test
                    </p>
                    <Button
                      variant="contained"
                      color="secondary"
                      style={{ height: 54 }}
                      fullWidth
                      onClick={() =>
                        handleRemoveTest(
                          booking?.id === undefined ? booking?.pk : booking?.id,
                          id,
                          "add_member"
                        )
                      }
                    >
                      Test Remove
                    </Button>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} md={6}>
                <Card
                  className="sampleCollectionTimeFamily"
                  style={{
                    height: "428px !important",
                    overflow: "auto",
                    backgroundColor: "#33bfff",
                  }}
                >
                  <h3
                    style={{
                      marginBottom: "1rem",
                      backgroundColor: "#00b0ff",
                      fontWeight: "bold",
                      height: "60px",
                      color: "white",
                      textAlign: "center",
                    }}
                  >
                    Sample Collection Time
                  </h3>
                  <div className={classes.slotContainer}>
                    {timeSlots &&
                      timeSlots?.length > 0 &&
                      timeSlots?.map((slot: any) => {
                        return (
                          slot?.available_slots > 0 && (
                            <Card
                              key={slot?.id}
                              className={
                                slot?.id === selectedSlot
                                  ? classes.selectedSlot
                                  : classes.slot
                              }
                              style={{ cursor: "crosshair" }}
                            >
                              <p
                                style={{
                                  textAlign: "center",
                                  color: "black",
                                  fontSize: "14px",
                                  fontWeight: "bold",
                                }}
                              >
                                {slot?.start_time}-{slot?.end_time}
                              </p>

                              <p
                                style={{
                                  textAlign: "center",
                                  color: "green",
                                  fontWeight: "bold",
                                  fontSize: "17px",
                                }}
                              >
                                {" "}
                                Available slots: {slot?.available_slots}
                              </p>
                            </Card>
                          )
                        );
                      })}
                  </div>
                </Card>
              </Grid>
              <Grid
                container
                spacing={3}
                direction="row"
                justify="center"
                alignItems="center"
                style={{ margin: "1rem auto" }}
              >
                <Grid item xs={12}>
                  <Grid container spacing={3} style={{ margin: "1rem auto" }}>
                    <Grid
                      container
                      item
                      xs={12}
                      md={3}
                      spacing={3}
                      style={{
                        margin: "1rem 0",
                        width: "100%",
                        padding: "1rem",
                      }}
                    >
                      <Grid item xs={12} style={{ padding: "0" }}>
                        <p>Pickup Status</p>
                      </Grid>
                      <Select
                        className="input"
                        name="verification_status"
                        variant="outlined"
                        value={pickup_status || "pending"}
                        style={{ width: "100%", margin: "0", padding: "0" }}
                        onChange={(e) =>
                          setPickup_status(e.target.value as string)
                        }
                        required
                      >
                        <MenuItem value={"pending"}>Pending</MenuItem>
                        <MenuItem value={"confirmed"}>Confirmed</MenuItem>
                        <MenuItem value={"cancelled"}>Cancelled</MenuItem>
                        <MenuItem value={"hold"}>Hold</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container spacing={3} style={{ margin: "1rem auto" }}>
                <Grid
                  item
                  xs={12}
                  md={6}
                  style={{ padding: "1rem", justifyContent: "right" }}
                >
                  <div className="priceDetails" style={{ padding: "1rem" }}>
                    <table style={{ width: "100%", margin: "0" }}>
                      <thead>
                        <tr>
                          <td className="cell">Customer Name</td>
                          <td className="cell">Packages</td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="cell">{customer_name}</td>
                          <td className="cell">
                            <ul>
                              {packageDetails?.map((pack: any, index: any) => {
                                return (
                                  <li key={index}>
                                    {pack?.name}
                                    <br />
                                    DESCRIPTION : {pack?.description}
                                    <br />
                                    Report time:{" "}
                                    {pack?.package_city_prices
                                      ? pack?.package_city_prices?.tat_time
                                      : pack?.tat}
                                    <br />
                                    Smart Report Available:{" "}{pack?.smart_report_available === false ? "No" : "Yes"}
                                    <br />
                                    Price:{" "}
                                    {pack?.package_city_prices
                                      ? pack?.package_city_prices?.offer_price
                                      : pack?.price}
                                  </li>
                                );
                              })}
                            </ul>
                          </td>
                        </tr>
                        {booking?.additional_members &&
                          booking?.additional_members?.map((val: any) => {
                            if (val.id !== id) {
                              return (
                                <tr>
                                  <td className="cell">{val.customer_name}</td>
                                  <td className="cell">
                                    <ul>
                                      {val?.ccpackages?.map(
                                        (pack: any, index: any) => {
                                          return (
                                            <li key={index}>
                                              {pack?.name}
                                              <br />
                                              DESCRIPTION : {pack?.description}
                                              <br />
                                              Report time: {pack?.tat}
                                              <br />
                                              Price: {pack?.price}
                                            </li>
                                          );
                                        }
                                      )}
                                    </ul>
                                  </td>
                                </tr>
                              );
                            }
                          })}

                        <tr>
                          <td className="cell">{booking?.customer_name}</td>
                          <td className="cell">
                            <ul>
                              {booking?.packages &&
                                booking?.packages?.map(
                                  (pack: any, index: any) => {
                                    return (
                                      <li key={index}>
                                        {pack?.name}
                                        <br />
                                        DESCRIPTION : {pack?.description}
                                        <br />
                                        Report time:{" "}
                                        {pack?.package_city_prices
                                          ? pack?.package_city_prices?.tat_time
                                          : pack?.tat}
                                        <br />
                                        Smart Report Available:{" "}{pack?.smart_report_available === false ? "No" : "Yes"}
                                        <br />
                                        Price:{" "}
                                        {is_price_hide
                                          ? "XXX"
                                          : pack?.package_city_prices
                                            ? pack?.package_city_prices?.offer_price
                                            : pack?.price}
                                      </li>
                                    );
                                  }
                                )}
                            </ul>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  style={{ padding: "1rem", justifyContent: "right" }}
                >
                  <div
                    className="priceDetails"
                    style={{ padding: "1rem 1rem 0 1rem" }}
                  >
                    <h6 className="totalPrice">
                      Price: ₹ {is_price_hide ? "XXX" : Math?.floor(price)}{" "}
                      {is_price_hide ? "XXX" : extraCharge ? "+ 200" : ""}
                    </h6>
                    <h6 className="totalPrice">
                      Total Amount: ₹{" "}
                      {is_price_hide === true ? "XXX" : totalPrice}
                    </h6>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Dialog>
        ) : (
          <Dialog fullScreen open={open} onClose={handleClose}>
            <AppBar className={classes.appBar}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <GridCloseIcon />
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                  Add Family Member
                </Typography>
                <Button
                  autoFocus
                  color="inherit"
                  onClick={updateFamilyMemberHandler}
                >
                  Save
                </Button>
              </Toolbar>
            </AppBar>
            <Grid
              container
              spacing={3}
              style={{ margin: "1rem auto", padding: "0 1rem" }}
            >
              <Grid item xs={12} md={6}>
                <Grid container spacing={3} alignItems="center">
                  <Grid item xs={12} md={4}>
                    <p>Customer Name</p>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <input
                      className="input"
                      name="collection_date"
                      type="name"
                      placeholder="Customer Name"
                      value={customer_name}
                      onChange={(e) => setCustomer_name(e.target.value)}
                      style={{
                        width: "100%",
                        margin: "0",
                        padding: "18.5px 14px",
                      }}
                      disabled
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3} alignItems="center">
                  <Grid item xs={12} md={4}>
                    <p>Customer Aadhaar</p>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <input
                      className="input"
                      name="collection_date"
                      type="number"
                      placeholder="Customer Aadhaar "
                      value={customer_aadhar}
                      onChange={(e) => setCustomer_aadhar(e.target.value)}
                      style={{
                        width: "100%",
                        margin: "0",
                        padding: "18.5px 14px",
                      }}
                      disabled
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3} alignItems="center">
                  <Grid item xs={12} md={4}>
                    <p>Packages</p>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Autocomplete
                      id="package"
                      onChange={(event, newValue) => {
                        if (newValue) {
                          let packs: any = [];
                          newValue?.map((val: any) => {
                            let obj = JSON.parse(
                              JSON.stringify(val, null, " ")
                            );
                            packs.push(obj);
                          });
                          setPackageDetails(packs);
                        }
                      }}
                      multiple
                      options={packageList?.results}
                      value={packageDetails}
                      disableClearable
                      limitTags={0}
                      getOptionLabel={(option: any) =>
                        option?.name && option?.name.toString()
                      }
                      getOptionDisabled={(option: any) => {
                        const packID = packageDetails?.map(
                          (pack: any) => pack?.id
                        );
                        return packID.includes(option?.id);
                      }}
                      onInputChange={(event, newInputValue) => {
                        clearTimeout(timer.current);
                        timer.current = setTimeout(() => {
                          getPackage(newInputValue);
                        }, 1000);
                      }}
                      freeSolo
                      disableCloseOnSelect
                      renderTags={(value: string[], getTagProps) =>
                        value?.map((option: any, index: number) => (
                          <Chip
                            variant="default"
                            color="primary"
                            label={option?.name && option?.name.toString()}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Package"
                          variant="outlined"
                          style={{ width: "100%", margin: "0", padding: "0" }}
                          className="input"
                        />
                      )}
                      disabled
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3} alignItems="center">
                  <Grid item xs={12} md={4}>
                    <p>Customer Age</p>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <input
                      className="input"
                      name="collection_date"
                      type="number"
                      placeholder="Customer Age"
                      value={customer_age}
                      onChange={(e) => setCustomer_age(e.target.value)}
                      style={{
                        width: "100%",
                        margin: "0",
                        padding: "18.5px 14px",
                      }}
                      disabled
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3} alignItems="center">
                  <Grid item xs={12} md={4}>
                    <p>Customer Gender</p>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Select
                      className="input"
                      name="customer_gender"
                      variant="outlined"
                      value={customer_gender}
                      style={{ width: "100%", margin: "0", padding: "0" }}
                      onChange={(e) => setCustomer_gender(e.target.value)}
                      disabled
                    >
                      <MenuItem selected hidden value={"none"}>
                        Please Select
                      </MenuItem>
                      <MenuItem value={"male"}>Male</MenuItem>
                      <MenuItem value={"female"}>Female</MenuItem>

                    </Select>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card
                  className="sampleCollectionTimeFamily"
                  style={{ height: "450px !important", overflow: "auto" }}
                >
                  <p style={{ marginBottom: "1rem" }}>Sample Collection Time</p>
                  <div className={classes.slotContainer}>
                    {timeSlots &&
                      timeSlots?.length > 0 &&
                      timeSlots?.map((slot: any) => {
                        return (
                          slot?.available_slots > 0 && (
                            <Card
                              key={slot?.id}
                              className={
                                slot?.id === selectedSlot
                                  ? classes.selectedSlot
                                  : classes.slot
                              }
                            >
                              <p
                                style={{
                                  textAlign: "center",
                                  color: "black",
                                  fontSize: "14px",
                                }}
                              >
                                {slot?.start_time}-{slot?.end_time}
                              </p>

                              {/* <p style={{ textAlign: "center", color: "black" }}>{moment(`${slot?.start_time}`, ["HH.mm.ss"]).format("hh:mm A")} - {moment(`${slot?.end_time}`, ["HH.mm.ss"]).format("hh:mm A")}</p> */}
                              <p
                                style={{ textAlign: "center", color: "green" }}
                              >
                                {" "}
                                Available slots: {slot?.available_slots}
                              </p>
                            </Card>
                          )
                        );
                      })}
                  </div>
                </Card>
              </Grid>
              <Grid
                container
                spacing={3}
                direction="row"
                justify="center"
                alignItems="center"
                style={{ margin: "1rem auto" }}
              >
                <Grid item xs={12}>
                  <Grid container spacing={3} style={{ margin: "1rem auto" }}>
                    <Grid
                      container
                      item
                      xs={12}
                      md={3}
                      spacing={3}
                      style={{
                        margin: "1rem 0",
                        width: "100%",
                        padding: "1rem",
                      }}
                    >
                      <Grid item xs={12} style={{ padding: "0" }}>
                        <p>Booking Status</p>
                      </Grid>
                      <Grid item xs={12} style={{ padding: "0" }}>
                        <Select
                          className="input"
                          name="booking_status"
                          variant="outlined"
                          value={booking_status || "pending"}
                          style={{ width: "100%", margin: "0", padding: "0" }}
                          onChange={(e) =>
                            setBooking_status(e.target.value as string)
                          }
                          required
                          disabled
                        >
                          <MenuItem disabled value={booking_status}>
                            {booking_status}
                          </MenuItem>
                          <MenuItem
                            value={
                              "Sample Not Received Due To Payment Information"
                            }
                          >
                            Sample Not Received Due To Payment Information
                          </MenuItem>
                          <MenuItem value={"sample received"}>
                            Sample Received
                          </MenuItem>
                          <MenuItem value={"partial received"}>
                            Partial Received
                          </MenuItem>
                        </Select>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      md={3}
                      spacing={3}
                      style={{
                        margin: "1rem 0",
                        width: "100%",
                        padding: "1rem",
                      }}
                    >
                      <Grid item xs={12} style={{ padding: "0" }}>
                        <p>Pickup Status</p>
                      </Grid>
                      <Grid item xs={12} style={{ padding: "0" }}>
                        <Select
                          className="input"
                          name="verification_status"
                          variant="outlined"
                          value={pickup_status || "pending"}
                          style={{ width: "100%", margin: "0", padding: "0" }}
                          onChange={(e) =>
                            setPickup_status(e.target.value as string)
                          }
                          required
                        >
                          <MenuItem value={"pending"}>Pending</MenuItem>
                          <MenuItem value={"confirmed"}>Confirmed</MenuItem>
                          <MenuItem value={"cancelled"}>Cancelled</MenuItem>
                          <MenuItem value={"hold"}>Hold</MenuItem>
                        </Select>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      md={3}
                      spacing={3}
                      style={{
                        margin: "1rem 0",
                        width: "100%",
                        padding: "1rem",
                      }}
                    >
                      <Grid item xs={12} style={{ padding: "0" }}>
                        <p>Phlebo Incentive</p>
                      </Grid>
                      <Grid item xs={12} style={{ padding: "0" }}>
                        <TextField
                          name="phlebo_verification_remark"
                          placeholder="Enter phlebo Incentive"
                          type="number"
                          value={phlebo_incentive}
                          className="input"
                          variant="outlined"
                          style={{ margin: "0", width: "100%" }}
                          onChange={(e) => setPhlebo_incentive(e.target.value)}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      md={3}
                      spacing={3}
                      style={{
                        margin: "1rem 0",
                        width: "100%",
                        padding: "1rem",
                      }}
                    >
                      <Grid item xs={12} style={{ padding: "0" }}>
                        <p>Phlebo Convenience</p>
                      </Grid>
                      <Grid item xs={12} style={{ padding: "0" }}>
                        <TextField
                          name="phlebo_verification_remark"
                          placeholder="Enter phlebo Convenience"
                          type="number"
                          value={phlebo_convenience}
                          className="input"
                          variant="outlined"
                          style={{ margin: "0", width: "100%" }}
                          onChange={(e) =>
                            setPhlebo_convenience(e.target.value as string)
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      md={3}
                      spacing={3}
                      style={{
                        margin: "1rem 0",
                        width: "100%",
                        padding: "1rem",
                      }}
                    >
                      <Grid item xs={12} style={{ padding: "0" }}>
                        <p>Phlebo Verification Remark</p>
                      </Grid>
                      <Grid item xs={12} style={{ padding: "0" }}>
                        <TextField
                          name="phlebo_verification_remark"
                          placeholder="Enter phlebo Remark"
                          type="text"
                          value={phlebo_verification_remark}
                          className="input"
                          variant="outlined"
                          style={{ margin: "0", width: "100%" }}
                          onChange={(e) =>
                            setPhlebo_verification_remark(
                              e.target.value as string
                            )
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Dialog>
        )}
      
        <Paper className={classes.paper} elevation={15}>
          {loading && !raiseComplaint ? (
            <Loader />
          ) : (
            <>
              <Grid
                container
                direction="row"
                alignItems="flex-start"
                justify="space-between"
                spacing={3}
                style={{ margin: "1rem auto" }}
              >
                <Grid item xs={12}>
                  <Grid
                    container
                    direction="row"
                    alignItems="flex-start"
                    justify="space-between"
                    spacing={3}
                    style={{ margin: "1rem auto" }}
                  >
                    <Grid item xs={6} sm={4} md={4}>
                      <h3
                        style={{
                          paddingTop: "10px",
                          fontSize: "24px",
                          fontWeight: "bold",
                        }}
                      >
                        {
                          booking?.org_type === "homedx"
                            ? "HOME-DX BOOKING DETAILS"
                            : "IMAGING BOOKING DETAILS"
                        }
                        {alreadyARedPro ? <p style={{ color: "red" }}>VIP MEMBER</p> : ""}
                        { (newLeadDetails && newLeadDetails?.subscriptions?.length > 0 && newLeadDetails?.subscriptions[0]?.created_at?.slice(0,10) > booking?.booking_date) ?
                            ((alreadyARedPro && booking?.discounted_price?.subscription_discount_amount === 0) ? <p style={{ color: "#a70e0e", fontSize: "14px", margin: "0px", width: "900px" }}>VIP membership was purchased after this booking was created, hence VIP discount is not applicable on this booking?.</p> : <></>)
                            : ""
                        }
                      </h3>
                    </Grid>
                    <Grid item xs={6} sm={4} md={4}>
                      <h3
                        style={{
                          paddingTop: "10px",
                          fontSize: "24px",
                          fontWeight: "bold",
                        }}
                      >
                        {booking?.centre_address?.name
                          ? "CENTRE : " +
                          String(
                            booking?.centre_address?.name
                          ).toUpperCase()
                          : ""}
                      </h3>
                    </Grid>
                    <Grid item xs={6} sm={4} md={4}>
                      <h3
                        style={{
                          paddingTop: "10px",
                          fontSize: "24px",
                          fontWeight: "bold",
                          textAlign: "right",
                        }}
                      >
                        BOOKING (ID: {bookingId})
                      </h3>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6} md={4} style={{ textAlign: "start", paddingLeft: "32px" }}>
                  <h6>Collection Date</h6>
                  <p style={{ fontWeight: "normal" }}>
                    {booking?.collection_date}
                  </p>
                </Grid>
                <Grid item xs={6} md={4} style={{ textAlign: "end", paddingRight: "32px" }}>
                  <h6>Collection Time</h6>
                  <p style={{ fontWeight: "normal" }}>
                    {booking?.collection_slot &&
                      moment(
                        `${booking?.collection_slot?.slot?.split("-")[0]}`,
                        ["HH.mm.ss"]
                      ).format("hh:mm A")}
                    {" - "}
                    {booking?.org_type == "homedx"
                      ? booking?.collection_slot &&
                      moment(
                        `${booking?.collection_slot?.slot?.split("-")[1]}`,
                        ["HH.mm.ss"]
                      ).format("hh:mm A")
                      : "Onwards"}
                  </p>
                </Grid>

                {
                    booking?.delivery_date !== null ?
                    <>
                      <Grid item xs={6} md={6} style={{ textAlign: "start", paddingLeft: "32px" }}>
                        <h6>Container Delivery Date</h6>
                        <p style={{ fontWeight: "normal" }}>
                          {booking?.delivery_date}
                        </p>
                      </Grid>
                      <Grid item xs={6} md={6} style={{ textAlign: "end", paddingRight: "32px" }}>
                        <h6>Container Delivery Slot</h6>
                        <p style={{ fontWeight: "normal" }}>
                          {booking?.delivery_collection_slot && booking?.delivery_collection_slot?.length > 0 && booking?.delivery_collection_slot[0]?.slot}
                        </p>
                      </Grid>
                    </> :
                    <></>
                  }
              </Grid>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableHead}>
                        S No.
                      </TableCell>
                      <TableCell className={classes.tableHead}>
                        Customer Name
                      </TableCell>
                      <TableCell className={classes.tableHead}>Age</TableCell>
                      <TableCell className={classes.tableHead}>
                        Gender
                      </TableCell>
                      <TableCell className={classes.tableHead}>
                        {booking?.org_type == "homedx"
                          ? " Package Name"
                          : "Test Name"}
                      </TableCell>
                      <TableCell className={classes.tableHead}>
                        Partner OrderId
                      </TableCell>
                      <TableCell className={classes.tableHead}>
                        Aadhaar No
                      </TableCell>
                      <TableCell className={classes.tableHead}>
                        Edit
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow key={1}>
                      <TableCell align="center">1.</TableCell>
                      <TableCell align="center">{`${booking?.designation || ""
                        } ${booking?.customer_name}`}</TableCell>
                      <TableCell align="center">
                        {booking?.customer_age}
                      </TableCell>
                      <TableCell align="center">
                        {booking?.customer_gender}
                      </TableCell>
                      <TableCell align="center">
                        <ul>
                          {booking?.packages &&
                            booking?.packages?.map((pack: any, index: any) => {
                              return (
                                <li
                                  key={index}
                                  style={{
                                    margin: "1rem 0",
                                    textAlign: "left",
                                  }}
                                >
                                  {/* {pack?.name}, {pack?.tat_time} */}
                                  {pack?.name && pack?.package_city_prices
                                    ? pack?.name.toString() +
                                    ", " +
                                    pack?.package_city_prices?.tat_time
                                    : pack?.name +
                                    (pack?.tat_time
                                      ? ", " + pack?.tat_time
                                      : "")}
                                </li>
                              );
                            })}
                        </ul>
                      </TableCell>
                      <TableCell align="center">
                        {booking?.partner && booking?.partner.id}
                      </TableCell>
                      <TableCell align="center">
                        {booking?.customer_aadhar || ""}
                      </TableCell>
                      <TableCell align="center">
                        {booking?.booking_status === "cancelled" || booking?.pickup_status === "cancelled" ? <span style={{ color: "red" }}>Booking Cancelled You Can't edit</span> : (
                          (booking && booking?.pk > 0) ?
                            <>
                              <Link
                                to={`/dashboard/rm/${booking?.org_type == "homedx"
                                  ? "booking-edit"
                                  : "booking-imaging-edit"
                                  }/${booking?.pk}/edit`}
                                style={{ textDecoration: "none" }}
                              >
                                <Button color="primary" variant="text" onClick={() => dispatch({ type: ACTIONS.GET_BOOKING_BY_ID, payload: {} })}>
                                  Edit
                                </Button>
                              </Link>
                            </>
                            : ""
                        )}
                      </TableCell>
                    </TableRow>
                    {booking?.additional_members &&
                      booking?.additional_members?.length > 0 &&
                      booking?.additional_members?.map(
                        (member: any, index: number) => {
                          return (
                            <TableRow key={index + 1}>
                              <TableCell align="center">
                                {index + 2}.
                              </TableCell>
                              <TableCell align="center">
                                {`${member?.designation || ""} ${member?.customer_name
                                  }`}
                              </TableCell>
                              <TableCell align="center">
                                {member?.customer_age}
                              </TableCell>
                              <TableCell align="center">
                                {member?.customer_gender}
                              </TableCell>
                              <TableCell align="center">
                                <ul>
                                  {member?.packages &&
                                    member?.packages?.map(
                                      (pack: any, index: any) => {
                                        return (
                                          <li
                                            key={index}
                                            style={{
                                              margin: "0 0 1rem 0",
                                              textAlign: "left",
                                            }}
                                          >
                                            {/* {pack?.name}, {pack?.price} */}
                                            {pack?.name &&
                                              pack?.package_city_prices
                                              ? pack?.name.toString() +
                                              ", " +
                                              pack?.package_city_prices
                                                ?.tat_time
                                              : pack?.name +
                                              ", " +
                                              pack?.tat_time}
                                          </li>
                                        );
                                      }
                                    )}
                                </ul>
                              </TableCell>
                              <TableCell align="center"></TableCell>
                              <TableCell align="center">
                                {member?.customer_aadhar}
                              </TableCell>
                              <TableCell align="center">
                                {member?.booking_status !== "cancelled" && member?.pickup_status !== "cancelled" ? (
                                  <>
                                    <Button
                                      color="primary"
                                      variant="text"
                                      onClick={() => handleEditMember(member)}
                                      disabled={member && member?.customer_name === undefined}
                                    >
                                      Edit
                                    </Button>
                                  </>
                                ) : <span style={{ color: "red" }}>Booking Cancelled You Can't edit</span>}
                              </TableCell>
                            </TableRow>
                          );
                        }
                      )}
                    <TableRow></TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <Grid
                container
                spacing={3}
                style={{ margin: "1rem auto" }}
                component={Paper}
              >
                <Grid item xs={12} md={6}>
                  <Grid
                    container
                    spacing={0}
                    style={{
                      padding: "10px",
                      marginLeft: "-10px",
                    }}
                  >
                    <Grid item xs={12} md={12}>
                      <p style={{ fontWeight: "bold", fontSize: "20px" }}>
                        Customer Address{" "}
                      </p>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <p>Locality </p>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <p>
                        :{" "}
                        <strong>
                          {booking?.customer_landmark
                            ? booking?.customer_landmark
                            : booking?.booking_full_address}
                        </strong>
                        &nbsp;{" "}
                      </p>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <p>House / Flat / Shop Number/-</p>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <p>
                        :{" "}
                        <strong>
                          {booking?.customer_address &&
                            booking?.customer_address}
                        </strong>
                      </p>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <p style={{ fontSize: "14px" }}>
                        Apartment / Building / Street Number
                      </p>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <p>
                        :{" "}
                        <strong>
                          {booking?.address_line2 && booking?.address_line2}
                        </strong>
                      </p>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <p>Landmark</p>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <p>
                        :{" "}
                        <strong>
                          {booking?.landmark && booking?.landmark}
                        </strong>
                      </p>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6} style={{ textAlign: "right" }}>
                  <Grid
                    container
                    spacing={0}
                    style={{
                      padding: "10px",
                      marginLeft: "-10px",
                    }}
                  >
                    <Grid item xs={12} md={6}>
                      <p> Package Price </p>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <p>
                        :{" "}
                        <strong>
                          {" "}
                          {booking?.discounted_price &&
                            booking?.discounted_price?.total_price_package &&
                            Math?.floor(
                              booking?.discounted_price?.total_price_package
                            )}
                          /-
                        </strong>
                      </p>
                    </Grid>
                    {booking?.org_type === "homedx" ? (
                      <>
                        <Grid item xs={12} md={6}>
                          <p> Phlebo Cost</p>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <p>
                            :{" "}
                            <strong>
                              {" "}
                              {booking?.discounted_price &&
                                booking?.discounted_price?.phlebo_cost &&
                                Math?.floor(
                                  booking?.discounted_price?.phlebo_cost
                                )}
                              /-
                            </strong>
                          </p>
                        </Grid>
                      </>
                    ) : (
                      ""
                    )}

                    <Grid item xs={12} md={6}>
                      <p style={{ fontSize: "14px" }}>Discount</p>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <p>
                        :{" "}
                        <strong>
                          {" "}
                          {booking?.discounted_price &&
                            booking?.discounted_price?.counpon_discount &&
                            Math?.floor(
                              booking?.discounted_price?.counpon_discount
                            )}
                          /-
                        </strong>
                      </p>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <p> RedCash Discount</p>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <p>
                        :{" "}
                        <strong>
                          {booking?.redcash_discounted_amount}/-
                        </strong>
                      </p>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      {booking?.discounted_price?.coupon_type === "referral" ? <p>Referral Coupon Discount</p> : (booking?.discounted_price?.coupon_type === "redeem points" ? <p>Referral Points Discount</p> : <p>Referral Discount</p>)}
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <p>
                        : <strong>{booking?.discounted_price?.referral_coupon_discount}/-</strong>
                      </p>
                    </Grid>
                    {/* <Grid item xs={12} md={6}>
                        <p>  Smart Package Discount</p>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <p>
                          : <strong>{booking?.smart_discounted_amount}/-</strong>
                        </p>
                      </Grid> */}
                    <Grid item xs={12} md={6}>
                      <p> GiftCard Discount</p>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <p>
                        :{" "}
                        <strong>
                          {booking?.giftcard_discounted_amount}/-
                        </strong>
                      </p>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <p> VIP Membership Discount</p>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <p>
                        : <strong>{Math?.floor(booking?.discounted_price?.subscription_discount_amount)}/-</strong>
                      </p>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <p> VIP Membership Price</p>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <p>
                        : <strong>{Math?.floor(booking?.discounted_price?.subscription_amount)}/-</strong>
                      </p>
                    </Grid>
                    {booking?.discounted_price?.hard_copy_cost !== 0 ? (
                      <>
                        <Grid item xs={12} md={6}>
                          <p>Hard Copy Cost</p>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <p>
                            :{" "}
                            <strong>
                              {booking?.discounted_price?.hard_copy_cost}/-
                            </strong>
                          </p>
                        </Grid>
                      </>
                    ) : ""}
                    <Grid item xs={12} md={6}>
                      <p style={{ fontWeight: "bold" }}> Total Amount</p>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <p>
                        :{" "}
                        <strong>
                          {" "}
                          {booking?.discounted_price &&
                            booking?.discounted_price?.final_total_price &&
                            Math?.floor(
                              booking?.discounted_price?.final_total_price
                            ) -
                            Math?.floor(booking?.redcash_discounted_amount) - Math?.floor(booking?.giftcard_discounted_amount)}
                          /-
                        </strong>
                      </p>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={4}
                direction="row"
                justify="flex-start"
                alignItems="center"
                style={{ margin: "1rem auto" }}
              >
                <Grid
                  direction="row"
                  justify="space-around"
                  alignItems="center"
                  container
                  item
                  xs={12}
                  md={6}
                  spacing={3}
                >
                  <Grid item xs={12} md={5}>
                    <p>AgentName</p>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      name="agentName"
                      type="text"
                      value={(booking?.agent && booking?.agent.name) || ""}
                      disabled
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
                {booking?.org_type === "homedx" &&
                  booking?.center?.center_type !== "collection_center" ? (
                  <>
                    <Grid
                      direction="row"
                      justify="space-around"
                      alignItems="center"
                      container
                      item
                      xs={12}
                      md={6}
                      spacing={3}
                    >
                      <Grid item xs={12} md={5}>
                        <p>Phlebo</p>
                      </Grid>
                      <Grid item xs={12} md={7}>
                        <TextField
                          name="phlebo"
                          type="text"
                          value={
                            (booking?.phlebo && booking?.phlebo.name) || ""
                          }
                          disabled
                          className="input"
                          variant="outlined"
                          style={{ margin: "0", width: "100%" }}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      direction="row"
                      justify="space-around"
                      alignItems="center"
                      container
                      item
                      xs={12}
                      md={6}
                      spacing={3}
                    >
                      <Grid item xs={12} md={5}>
                        <p>
                          Phlebo
                          {booking?.phlebo && booking?.phlebo.name ? (
                            <Button
                              disabled={!callButton}
                              className="addDeck"
                              variant="contained"
                              color="primary"
                              onClick={() => handleCallClick(bookingId, "phlebo")}
                              startIcon={<CallIcon />}


                            ></Button>
                          ) : (
                            ""
                          )}
                        </p>
                      </Grid>
                      <Grid item xs={12} md={7}>
                        <TextField
                          name="phlebo Number"
                          type="text"
                          value={(booking?.phlebo && booking?.phlebo.phonenumber) || ""}
                          disabled
                          className="input"
                          variant="outlined"
                          style={{ margin: "0", width: "100%" }}
                        />
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  ""
                )}
                <Grid
                  direction="row"
                  justify="space-around"
                  alignItems="center"
                  container
                  item
                  xs={12}
                  md={6}
                  spacing={3}
                >
                  <Grid item xs={12} md={5}>
                    <p>
                      Mobile Number

                      <VisibilityIcon
                        onClick={(e: any) =>
                          handleUnmaskedNumber(
                            e,
                            bookingId,
                            "booking",
                            "contact"
                          )
                        }
                        style={{ marginLeft: "2rem" }}
                      />
                      <Button
                        disabled={!callButton}
                        className="addDeck"
                        variant="contained"
                        color="primary"
                        onClick={() => handleCallClick(bookingId, "call")}
                        startIcon={<CallIcon />}
                      ></Button>
                    </p>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      name="customer_phonenumber"
                      type="text"
                      value={booking?.customer_phonenumber || ""}
                      disabled
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  direction="row"
                  justify="space-around"
                  alignItems="center"
                  container
                  item
                  xs={12}
                  md={6}
                  spacing={3}
                >
                  <Grid item xs={12} md={5}>
                    <p>
                      Alternate Mobile Number
                      <VisibilityIcon
                        onClick={(e: any) =>
                          handleUnmaskedNumber(
                            e,
                            bookingId,
                            "booking",
                            "altphonenumber"
                          )
                        }
                        style={{ marginLeft: "2rem" }}
                      />
                      <Button
                        disabled={!callButton}
                        className="addDeck"
                        variant="contained"
                        color="primary"
                        onClick={() => handleCallClick(bookingId, "contact")}
                        startIcon={<CallIcon />}


                      ></Button>

                    </p>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      name="customer_alternatenumber"
                      type="text"
                      value={booking?.customer_altphonenumber || ""}
                      disabled
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  direction="row"
                  justify="space-around"
                  alignItems="center"
                  container
                  item
                  xs={12}
                  md={6}
                  spacing={3}
                >
                  <Grid item xs={12} md={5}>
                    <p>Email</p>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      name="customer_email"
                      type="email"
                      disabled
                      value={booking?.customer_email || ""}
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  direction="row"
                  justify="space-around"
                  alignItems="center"
                  container
                  item
                  xs={12}
                  md={6}
                  spacing={3}
                >
                  <Grid item xs={12} md={5}>
                    <p>
                      Whatsapp Number
                      <VisibilityIcon
                        onClick={(e: any) =>
                          handleUnmaskedNumber(
                            e,
                            bookingId,
                            "booking",
                            "whatsapp"
                          )
                        }
                        style={{ marginLeft: "2rem" }}
                      />
                      <Button
                        disabled={!callButton}
                        className="addDeck"
                        variant="contained"
                        color="primary"
                        onClick={() => handleCallClick(bookingId, "whatsapp")}
                        startIcon={<CallIcon />}
                      ></Button>
                    </p>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      name="customer_whatsapppnumber"
                      type="text"
                      disabled
                      value={booking?.customer_whatsapppnumber || ""}
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  direction="row"
                  justify="space-around"
                  alignItems="center"
                  container
                  item
                  xs={12}
                  md={6}
                  spacing={3}
                >
                  <Grid item xs={12} md={5}>
                    <p>Passport Number</p>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      name="Passport"
                      type="text"
                      disabled
                      value={booking?.passport_number || ""}
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  direction="row"
                  justify="space-around"
                  alignItems="center"
                  container
                  item
                  xs={12}
                  md={6}
                  spacing={3}
                >
                  <Grid item xs={12} md={5}>
                    <p>Finance Amount</p>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      name="financeamount"
                      type="text"
                      value={booking?.finance_amount || ""}
                      disabled
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  direction="row"
                  justify="space-around"
                  alignItems="center"
                  container
                  item
                  xs={12}
                  md={6}
                  spacing={3}
                >
                  <Grid item xs={12} md={5}>
                    <p>Finance Status</p>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      name="financestatus"
                      type="text"
                      value={booking?.finance_status || ""}
                      disabled
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  direction="row"
                  justify="space-around"
                  alignItems="center"
                  container
                  item
                  xs={12}
                  md={6}
                  spacing={3}
                >
                  <Grid item xs={12} md={5}>
                    <p>Finance Remarks</p>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      name="financeremark"
                      type="text"
                      value={booking?.finance_remarks || ""}
                      disabled
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  direction="row"
                  justify="space-around"
                  alignItems="center"
                  container
                  item
                  xs={12}
                  md={6}
                  spacing={3}
                >
                  <Grid item xs={12} md={5}>
                    <p>Book for</p>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      name="book_for"
                      type="text"
                      value={booking?.book_for || ""}
                      disabled={true}
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  direction="row"
                  justify="space-around"
                  alignItems="center"
                  container
                  item
                  xs={12}
                  md={6}
                  spacing={3}
                >
                  <Grid item xs={12} md={5}>
                    <p>Phlebo Remarks</p>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <textarea
                      name="phlebo_remarks"
                      value={booking?.phlebo_remarks || ""}
                      disabled
                      className="input"
                      style={{
                        margin: "0",
                        width: "100%",
                        fontWeight: "bold",
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={4}
                direction="row"
                justify="flex-start"
                alignItems="center"
                style={{ margin: "1rem auto" }}
              >
                {booking?.org_type === "homedx" ? (
                  ""
                ) : (
                  <>
                    <Grid
                      direction="row"
                      justify="space-around"
                      alignItems="center"
                      container
                      item
                      xs={12}
                      md={6}
                      spacing={3}
                    >
                      <Grid item xs={12} md={5}>
                        <p>Customer Address</p>
                      </Grid>
                      <Grid item xs={12} md={7}>
                        <TextareaAutosize
                          maxRows={6}
                          style={{ width: "100%" }}
                          aria-label="maximum height"
                          placeholder="Maximum 4 rows"
                          disabled
                          defaultValue={
                            (booking?.customer_landmark &&
                              booking?.customer_landmark) ||
                            ""
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      direction="row"
                      justify="space-around"
                      alignItems="center"
                      container
                      item
                      xs={12}
                      md={6}
                      spacing={3}
                    >
                      <Grid item xs={12} md={5}>
                        <p>Center Address</p>
                      </Grid>
                      <Grid item xs={12} md={7}>
                        <TextareaAutosize
                          maxRows={6}
                          style={{ width: "100%" }}
                          aria-label="maximum height"
                          placeholder="Maximum 4 rows"
                          disabled
                          defaultValue={
                            (booking?.center_address &&
                              booking?.center_address?.address) ||
                            ""
                          }
                        />
                      </Grid>
                    </Grid>
                  </>
                )}
              </Grid>
              <Grid
                container
                spacing={4}
                direction="row"
                justify="flex-start"
                alignItems="center"
                style={{ margin: "1rem auto" }}
              >
                {booking?.center?.center_type === "collection_center" ? (
                  <Grid item xs={12}>
                    <h3
                      style={{
                        borderTop: "dotted 1px #cccc",
                        paddingTop: "10px",
                        fontSize: "24px",
                        fontWeight: "bold",
                      }}
                    >
                      Centre Address
                    </h3>

                    <b>
                      {booking?.centre_address
                        ? booking?.centre_address?.address +
                        ", " +
                        booking?.centre_address?.area +
                        ", " +
                        booking?.centre_address?.city +
                        " - " +
                        booking?.centre_address?.pincode +
                        ", " +
                        booking?.centre_address?.state
                        : ""}
                    </b>
                    <br />
                    <b>
                      Phone No. :{" "}
                      {booking?.centre_address
                        ? booking?.centre_address?.mobile
                        : ""}
                    </b>
                  </Grid>
                ) : (
                  ""
                )}
              </Grid>
              <Grid
                container
                spacing={4}
                direction="row"
                justify="flex-start"
                alignItems="center"
                style={{ margin: "1rem auto" }}
              >
                {booking?.payment_image &&
                  booking?.payment_image?.map((image: any) => {
                    return (
                      <Grid
                        key={image.id}
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={3}
                        spacing={3}
                      >
                        <img
                          src={image.image}
                          alt="payment"
                          style={{ width: "100%", aspectRatio: "1/1" }}
                        />
                      </Grid>
                    );
                  })}
              </Grid>
            </>
          )}
        </Paper>
        <Popover
          id={popid}
          open={openAnchor}
          anchorEl={anchorEl}
          onClose={handleAnchorClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Typography className={classes.typography}>
            {unmaskedNumber.number}
          </Typography>
        </Popover>
        <RaiseComplaintModal
          open={raiseComplaint}
          setOpen={setRaiseComplaint}
          bookingId={Number(bookingId)}
          complaints={complaints}
          leadId={0}
          booking={booking}
        />
        {openModal && (
          <PhleboModal
            openModal={openModal}
            setopenModal={setopenModal}
            bookingId={bookingId}
          />
        )}
      </main>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  booking: state.RouteManagerReducer.booking,
  booking_slots: state.RouteManagerReducer.booking_slots,
  packageList: state.RouteManagerReducer.packageList,
  additional_booking: state.RouteManagerReducer.additional_booking,
  unmaskedNumber: state.RouteManagerReducer.unmaskedNumber,
  complaints: state.RouteManagerReducer.complaints,
  loading: state.RouteManagerReducer.loading,
  hivpackageList: state.RouteManagerReducer.hivpackageList,
  biomarkerScreening: state.RouteManagerReducer.biomarkerScreening,
  newLeadDetails: state.RouteManagerReducer.newLeadDetails,
});

export default connect(mapStateToProps, {
  getBookingById,
  clickToCallBooking,
  updateBooking,
  getAvailableSlots,
  getPaymentSendLink,
  getPackage,
  updateAdditionalBooking,
  getUnmaskedNumber,
  updatePhleboReachedStatus,
  getHivPackage,
  getBiomarkerScreening,
  getNewLeadDetails,
})(PhleboAdminViewBooking);
