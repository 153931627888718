import React from "react";
import RouteManagerDashboardLeft from "../../RouteManager-Panel/dashboard-left";
import RouteManagerDashboardRight from "../../RouteManager-Panel/dashboard-right";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  container:{
    display:'flex',
    padding: "20px"
  }
});

interface Props {
  children: any;
}

const PhleboAdminDash: React.FC<Props> = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <RouteManagerDashboardLeft />
      <RouteManagerDashboardRight>{children && children}</RouteManagerDashboardRight>
    </div>
  );
};

export default PhleboAdminDash;
