import React, { useEffect, useRef, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Grid,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  TablePagination,
  TableFooter,
  withStyles,
  Switch,
} from "@material-ui/core";
import CancelIcon from "@mui/icons-material/Cancel";
import Button from "@mui/material/Button";
import { Chip, Select, MenuItem } from "@material-ui/core";
import "./index.sass";
import {
  CheckCircleOutlineRounded,
  Edit,
  NetworkCell,
} from "@material-ui/icons";
import { connect } from "react-redux";
import {
  getZone,
  updateZone,
  getTimeslots,
  getCities,
  getAgentList,
  getPhlebos,
  getZoneComments,
} from "../../actions/PhleboAdmin";
import Loader from "../loader";
import { useHistory } from "react-router";
import moment from "moment";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { MessageRounded } from "@material-ui/icons";
import BookingCommentsModal from "../Comments/zonesComments";
import CreateZoneModal from "./createZoneModal";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
      //   width: "100%",
      //   marginTop: "20px",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
    },
    paper: {
      padding: "1rem",
      width: "100%",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    table: {
      maxWidth: "100%",
      margin: "auto",
    },
    heading: {
      fontWeight: "normal",
      fontSize: "1.2rem",
    },
    actionButton: {
      margin: "0 0.5rem",
      cursor: "pointer",
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
  })
);
const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const DefaultSwitch: any = withStyles({
  switchBase: {
    color: "red",
    "&$checked": {
      color: "green",
    },
    "&$checked + $track": {
      backgroundColor: "green",
    },
  },
  checked: {},
  track: {
    backgroundColor: "red",
  },
})(Switch);

interface Props {
  getZone: any;
  updateZone: any;
  zoneList: any;
  getCities: any;
  cities: any;
  getZoneComments: any;
  getPhlebos: any;
  phleboList: any;
  getTimeslots: any;
  timeslotsList: any;
  getAgentList: any;
  agentBYUserGroup: any;
  loading: Boolean;
  bookingListLoader: boolean;
}

const ZonePage: React.FC<Props> = ({
  getZone,
  updateZone,
  zoneList,
  getCities,
  cities,
  getPhlebos,
  getZoneComments,
  phleboList,
  getTimeslots,
  timeslotsList,
  getAgentList,
  agentBYUserGroup,
  loading,
  bookingListLoader,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [search, setSearch] = useState<String>("");

  const [editZone, seteditZone] = useState("");
  const [openModal, setopenModal] = useState(false);
  const [timeslots, setTimeslots] = useState<any>([]);
  const [zoneName, setZoneName] = useState<any>([]);
  const [zoneNameSearch, setZoneNameSearch] = useState<any>("");
  const [timeslotsIds, setTimeslotsIds] = useState<any>([]);
  const [phlebos, setPhlebos] = useState<any>([]);
  const [phlebosId, setPhlebosIds] = useState<any>([]);
  const [city, setCity] = useState<number | null>(0);
  const [cityName, setCityName] = useState("");
  const [open, setOpen] = useState(false);
  const [key, setKey] = useState(false);
  const [zoneId, setZoneId] = useState<number>(0);
  const [maxSlot, setMaxSlot] = useState("");
  const [routeManager, setRouteManager] = useState<any>([]);
  const [routeManagerId, setRouteManagerId] = useState<any>("");
  const [isActive, setIsActive] = useState(false);
  const [isGeoFence, setIsGeoFence] = useState(false);
  const timer = useRef<any>(0);
  const [removedIds, setRemovedIds] = useState<number[]>([]);

  const [page, setPage] = useState(0);

  const handleSearch = () => {
    // setSearch(e.target.value as string)
    getZone(`${search ? search : zoneNameSearch}`);
  };

  const handleReset = () => {
    getZone();
  };

  useEffect(() => {
    getZone();
    // getCities()
    // getPhlebos()
    // getTimeslots()
    // getAgentList("", "RouteManager")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // getZone()
    // if (open === true ) {
    getCities();
    getPhlebos();
    getTimeslots();
    getAgentList("", "RouteManager");
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url = zoneList.links && zoneList.links.next.split("?")[1];
      getZone(`&${url}`);
    } else if (newPage < page) {
      let url = zoneList.links && zoneList.links.previous.split("?")[1];
      getZone(`&${url}`);
    }
    setPage(newPage as number);
  };

  const handleEditZone = (zone: any) => {
    seteditZone(zone.id);
    setCityName(zone.city.name);
    setCity(zone.city.id);
    setPhlebos(zone.phlebos);
    setPhlebosIds(zone.phlebos.map((item: any) => item.id));
    setTimeslotsIds(zone.time_slots.map((item: any) => item.id));
    setTimeslots(zone.time_slots);
    setMaxSlot(zone.max_booking_phlebs_slot);
    setRouteManagerId(zone?.rm === null ? null : zone?.rm.id);
    setKey(true);
    setIsGeoFence(zone.is_geofence);
    setIsActive(zone.is_active);
  };

  const handleSubmit = async (zone: any) => {
    seteditZone("");
    const body: any = {
      city,
      time_slots: timeslotsIds,
      phlebos: phlebosId,
      remove_phlebos: removedIds,
      rm: routeManagerId,
      max_booking_phlebs_slot: maxSlot,
      is_active: isActive,
      is_geofence: isGeoFence,
    };
    await updateZone(zone.id, body);
    getZone();
    setRemovedIds([]);
  };

  
  const disableZone = async (zone: any) => {
    const body: any = {
      is_active: !zone.is_active,
      max_booking_phlebs_slot: maxSlot,
    };
    await updateZone(zone.id, body);
    getZone();
  };
  const handleClick = (id: number) => {
    setopenModal(true);
    getZoneComments(id);
  };
  const handleCancel = async () => {
    seteditZone("");
    setRemovedIds([]);
  };

  return (
    <>
      <main className={classes.content}>
        <div style={{ width: "100%" }} className="data-table">
          <div className={classes.toolbar} />
          <Grid container spacing={1} direction="row" style={{ margin: "0" }}>
            <Grid item xs={12} sm={6} md={9}>
              <h2>ZONES</h2>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Button
                variant="contained"
                color="primary"
                style={{ marginLeft: "140px" }}
                onClick={() => setOpen(true)}
              >
                Add Zone
              </Button>
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <Autocomplete
                id="zonename"
                onChange={(event, newValue) => {
                  if (newValue) {
                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                    setZoneName(obj?.id);
                  }
                }}
                options={zoneList?.results || []}
                freeSolo
                blurOnSelect
                aria-required
                getOptionLabel={(option: any) => option?.city?.name}
                onInputChange={(event, newInputValue) => {
                  clearTimeout(timer.current);
                  timer.current = setTimeout(() => {
                    setZoneNameSearch(newInputValue);
                    getZone(`${newInputValue}`);
                  }, 1000);
                  if (newInputValue.length === 0) {
                    setZoneName(0);
                  }
                }}
                // disabled={search !== "" ? true : false}
                renderInput={(params) => (
                  <TextField
                    className="input"
                    {...params}
                    label="Zone"
                    variant="outlined"
                    style={{ width: "100%", margin: "0", padding: "0" }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <TextField
                id="search"
                type="search"
                value={search}
                // disabled={zoneName !== "" ? true : false}
                className="input"
                variant="outlined"
                label="Search City"
                onChange={(e) => {
                  setSearch(e.target.value as string);
                  // handleSearch(e)
                }}
                style={{ margin: "0" }}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => handleSearch()}
              >
                Search
              </Button>
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                // onClick={() => handleReset()}
                onClick={() => history.push("/dashboard/pha/zones")}
              >
                Reset
              </Button>
            </Grid>
          </Grid>
          {/* <Paper elevation={15} className={classes.paper}> */}
          {bookingListLoader ? (
            <Loader />
          ) : (
            <TableContainer
              className={classes.tableContainer}
              component={Paper}
              style={{ maxHeight: "560px" }}
            >
              <Table stickyHeader aria-label="simple table">
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell align="center">City</StyledTableCell>
                    <StyledTableCell align="center">Zone Name</StyledTableCell>
                    <StyledTableCell align="center">Comment</StyledTableCell>
                    <StyledTableCell align="center">Time Slots</StyledTableCell>
                    <StyledTableCell align="center">Phlebos</StyledTableCell>
                    <StyledTableCell align="center">
                      Route Manager
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Max Booking Phlebo Slot
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Zone Active/InActive
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      MapMyIndia Id
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Geofence Active/InActive
                    </StyledTableCell>
                    {/* <TableCell align="center">Active</TableCell> */}
                    <StyledTableCell align="center">Edit</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {zoneList &&
                    zoneList?.results &&
                    zoneList.results.length > 0 &&
                    zoneList.results.map((code: any, index: any) => {
                      return (
                        <StyledTableRow key={index}>
                          <StyledTableCell align="center">
                            {editZone === code.id ? (
                              <Grid item xs={12} sm={6} md={4}>
                                <Autocomplete
                                  id="city"
                                  onChange={(event, newValue) => {
                                    if (newValue) {
                                      let obj = JSON.parse(
                                        JSON.stringify(newValue, null, " ")
                                      );
                                      setCity(obj.id);
                                      setCityName(obj.name);
                                    }
                                  }}
                                  options={cities || []}
                                  freeSolo
                                  blurOnSelect
                                  aria-required
                                  inputValue={cityName}
                                  getOptionLabel={(option: any) => option.name}
                                  disabled
                                  renderInput={(params) => (
                                    <TextField
                                      className="input"
                                      {...params}
                                      placeholder="City"
                                      variant="outlined"
                                      style={{
                                        width: "100%",
                                        padding: "10px",
                                        minWidth: "200px",
                                      }}
                                    />
                                  )}
                                />
                              </Grid>
                            ) : (
                              code?.city?.name
                            )}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {code?.name}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <div>
                              <MessageRounded
                                onClick={() => handleClick(code.id)}
                              />
                            </div>
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {editZone === code.id ? (
                              <Autocomplete
                                id="timeslots"
                                onChange={(event, newValue) => {
                                  if (newValue) {
                                    let timeslotsArr: any = [];
                                    let timeslotId: any = [];
                                    newValue.map((val: any) => {
                                      let obj = JSON.parse(
                                        JSON.stringify(val, null, " ")
                                      );
                                      timeslotsArr.push(obj);
                                      timeslotId.push(obj.id);
                                    });
                                    setTimeslots(timeslotsArr);
                                    setTimeslotsIds(timeslotId);
                                  }
                                }}
                                value={timeslots}
                                multiple
                                disableClearable
                                limitTags={1}
                                options={timeslotsList?.results || []}
                                freeSolo
                                blurOnSelect
                                getOptionDisabled={(option: any) => {
                                  return timeslotsIds.includes(option.id);
                                }}
                                getOptionLabel={(option: any) => {
                                  return `${option.start_time}-${option.end_time}`;
                                }}
                                renderTags={(value: string[], getTagProps) =>
                                  value.map((option: any, index: number) => (
                                    <Chip
                                      key={index}
                                      variant="default"
                                      color="primary"
                                      label={`${option.start_time}-${option.end_time}`}
                                      {...getTagProps({ index })}
                                    />
                                  ))
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    placeholder="Time Slots"
                                    variant="outlined"
                                    style={{
                                      width: "100%",
                                      padding: "10px",
                                      minWidth: "200px",
                                    }}
                                    className="input"
                                  />
                                )}
                              />
                            ) : (
                              <React.Fragment>
                                {code.time_slots.map((slot: any) => {
                                  return (
                                    <p
                                      key={slot.id}
                                      style={{ margin: "0.5rem auto" }}
                                    >
                                      {moment(`${slot.start_time}`, [
                                        "HH.mm.ss",
                                      ]).format("hh:mm A")}{" "}
                                      -{" "}
                                      {moment(`${slot.end_time}`, [
                                        "HH.mm.ss",
                                      ]).format("hh:mm A")}
                                    </p>
                                  );
                                })}
                              </React.Fragment>
                            )}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {editZone === code.id ? (
                              <Autocomplete
                                id="phlebo"
                                onChange={(event, newValue, reason, detail) => {
                                  if (newValue) {
                                    let timeslotsArr: any = [];
                                    let phleboId: any = [];
                                    newValue.map((val: any) => {
                                      let obj = JSON.parse(
                                        JSON.stringify(val, null, " ")
                                      );
                                      timeslotsArr.push(obj);
                                      phleboId.push(obj.id);
                                    });
                                    setPhlebos(timeslotsArr);
                                    setPhlebosIds(phleboId);

                                    if (reason === "select-option") {
                                      const selectedId = detail?.option.id;

                                      if (removedIds.includes(selectedId)) {
                                        setRemovedIds((previousState) =>
                                          previousState.filter(
                                            (id) => id !== selectedId
                                          )
                                        );
                                      }
                                    }

                                    if (reason === "remove-option") {
                                      setPhlebosIds((previousState: any[]) =>
                                        previousState.filter(
                                          (currentId: any) =>
                                            currentId !== detail?.option.id
                                        )
                                      );
                                      setRemovedIds((previousState) => [
                                        ...previousState,
                                        detail?.option?.id,
                                      ]);
                                    }
                                  }
                                }}
                                value={phlebos}
                                onInputChange={(event, newInputValue) => {
                                  clearTimeout(timer.current);
                                  timer.current = setTimeout(() => {
                                    getPhlebos(`?code=${newInputValue}`);
                                  }, 1000);
                                }}
                                multiple
                                disableClearable
                                disableCloseOnSelect
                                limitTags={1}
                                options={
                                  phleboList?.results
                                    ? [
                                        ...phleboList?.results,
                                        {
                                          id: 0,
                                          user: { username: "No Phlebo" },
                                        },
                                      ]
                                    : []
                                }
                                freeSolo
                                blurOnSelect
                                getOptionDisabled={(option: any) => {
                                  return phlebosId.includes(option.id);
                                }}
                                getOptionLabel={(option: any) =>
                                  option?.user && option?.user?.username
                                }
                                renderTags={(value: string[], getTagProps) =>
                                  value.map((option: any, index: number) => (
                                    <Chip
                                      key={index}
                                      variant="default"
                                      color="primary"
                                      label={
                                        option?.user && option.user?.username
                                      }
                                      {...getTagProps({ index })}
                                    />
                                  ))
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    placeholder="Phlebo"
                                    variant="outlined"
                                    style={{
                                      width: "100%",
                                      padding: "10px",
                                      minWidth: "200px",
                                    }}
                                    className="input"
                                  />
                                )}
                              />
                            ) : (
                              <React.Fragment>
                                {code.phlebos.map((user: any) => {
                                  return (
                                    <p
                                      key={user.id}
                                      style={{ margin: "0.5rem auto" }}
                                    >
                                      {user?.user?.username}
                                    </p>
                                  );
                                })}
                              </React.Fragment>
                            )}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {editZone === code.id ? (
                              <Autocomplete
                                id="rm"
                                onChange={(event, newValue) => {
                                  let routeManager: any = [];
                                  let routeId: any = "";
                                  if (newValue) {
                                    let obj = JSON.parse(
                                      JSON.stringify(newValue, null, " ")
                                    );
                                    routeId = obj.id;
                                    routeManager = obj;
                                  }
                                  setRouteManager(routeManager);
                                  setRouteManagerId(routeId);
                                }}
                                value={routeManager}
                                onInputChange={(event, newInputValue) => {
                                  clearTimeout(timer.current);
                                  timer.current = setTimeout(() => {
                                    getAgentList(
                                      "",
                                      `RouteManager&code=${newInputValue}`
                                    );
                                  }, 1000);
                                }}
                                disableClearable
                                disableCloseOnSelect
                                limitTags={1}
                                options={agentBYUserGroup?.results || []}
                                freeSolo
                                blurOnSelect
                                getOptionLabel={(option: any) =>
                                  option?.user && option?.user?.username
                                }
                                renderTags={(value: string[], getTagProps) =>
                                  value.map((option: any, index: number) => (
                                    <Chip
                                      key={index}
                                      variant="default"
                                      color="primary"
                                      label={
                                        option?.user && option.user?.username
                                      }
                                      {...getTagProps({ index })}
                                    />
                                  ))
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    placeholder="Route Manager"
                                    variant="outlined"
                                    style={{
                                      width: "100%",
                                      padding: "10px",
                                      minWidth: "200px",
                                    }}
                                    className="input"
                                  />
                                )}
                              />
                            ) : (
                              <React.Fragment>
                                {code.rm !== null ? (
                                  <p
                                    key={code?.rm?.id}
                                    style={{ margin: "0.5rem auto" }}
                                  >
                                    {code?.rm?.name}
                                  </p>
                                ) : (
                                  ""
                                )}
                              </React.Fragment>
                            )}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {editZone === code.id ? (
                              <TextField
                                name="area"
                                type="text"
                                defaultValue={code.max_booking_phlebs_slot}
                                value={maxSlot}
                                className="input"
                                variant="outlined"
                                placeholder="Max Booking Slot"
                                onChange={(e) => {
                                  setMaxSlot(e.target.value as string);
                                }}
                                style={{
                                  width: "100%",
                                  padding: "10px",
                                  minWidth: "200px",
                                }}
                              />
                            ) : (
                              code.max_booking_phlebs_slot
                            )}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {editZone === code.id ? (
                              <Switch
                                defaultChecked={code?.is_active}
                                checked={isActive}
                                onChange={(e) => setIsActive(e.target.checked)}
                                name="isActive"
                                color="primary"
                                inputProps={{
                                  "aria-label": "secondary checkbox",
                                }}
                              />
                            ) : (
                              <DefaultSwitch
                                checked={code.is_active}
                                name="checkedA"
                                inputProps={{
                                  "aria-label": "secondary checkbox",
                                }}
                                disableRipple

                                //  onClick={() => disableZone(code)}
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {code?.mapmyindiaid}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {editZone === code.id ? (
                              <Switch
                                defaultChecked={code?.is_geofence}
                                checked={isGeoFence}
                                onChange={(e) =>
                                  setIsGeoFence(e.target.checked)
                                }
                                name="checkedA"
                                color="primary"
                                inputProps={{
                                  "aria-label": "secondary checkbox",
                                }}
                              />
                            ) : (
                              <DefaultSwitch
                                checked={code?.is_geofence}
                                name="checkedA"
                                inputProps={{
                                  "aria-label": "secondary checkbox",
                                }}
                                disableRipple
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {editZone === code.id ? (
                              <>
                                <Button
                                  color="primary"
                                  variant="contained"
                                  onClick={() => handleSubmit(code)}
                                >
                                  <CheckCircleOutlineRounded />
                                </Button>
                                <Button
                                  color="secondary"
                                  variant="contained"
                                  style={{ marginTop: "4px" }}
                                  onClick={() => handleCancel()}
                                >
                                  <CancelIcon />
                                </Button>
                              </>
                            ) : (
                              <Button
                                color="primary"
                                variant="contained"
                                onClick={() => handleEditZone(code)}
                              >
                                <Edit />
                              </Button>
                            )}
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                </TableBody>
                <BookingCommentsModal
                  openModal={openModal}
                  setopenModal={setopenModal}
                  zoneId={zoneId}
                />

                <TableFooter>
                  <StyledTableRow>
                    <TablePagination
                      rowsPerPageOptions={[]}
                      colSpan={3}
                      count={(zoneList && zoneList?.count) || 0}
                      rowsPerPage={(zoneList && zoneList?.page_size) || 20}
                      page={page}
                      onPageChange={handleChangePage}
                    />
                  </StyledTableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          )}
          {zoneList && zoneList.length === 0 && (
            <h3 className={classes.heading} style={{ textAlign: "center" }}>
              No Data Found
            </h3>
          )}
          {/* </Paper> */}
          <CreateZoneModal open={open} setOpen={setOpen} />
        </div>
      </main>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  timeslotsList: state.PhleboAdminReducer.timeslotsList,
  zoneList: state.PhleboAdminReducer.zoneList,
  phleboList: state.PhleboAdminReducer.phleboList,
  cities: state.PhleboAdminReducer.cities,
  agentBYUserGroup: state.PhleboAdminReducer.agentBYUserGroup,
  loading: state.PhleboAdminReducer.loading,
});

export default connect(mapStateToProps, {
  getZone,
  getCities,
  updateZone,
  getTimeslots,
  getPhlebos,
  getZoneComments,
  getAgentList,
})(ZonePage);
