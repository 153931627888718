import React, { useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from '@mui/material/Modal';
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import { 
    Avatar, 
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
    MenuItem,
    Select,
    Button,
   } from "@material-ui/core";
import Tooltip from "@mui/material/Tooltip";
import Slider, { SliderThumb } from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import CloseIcon from '@material-ui/icons/Close';

let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: isMobile ? "400px" : "400px",
    },
    head: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "2rem"
    }
}));

const PrettoSlider = styled(Slider)({
    color: '#52af77',
    height: 4,
    padding: 0,
    paddingTop: "15px",
    width: "80%",
    '& .MuiSlider-track': {
      border: 'none',
    },
    '& .MuiSlider-thumb': {
      height: 0,
      width: 0,
      backgroundColor: '#fff',
      border: '2px solid currentColor',
      '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
        boxShadow: 'inherit',
      },
      '&:before': {
        display: 'none',
      },
    },
    '& .MuiSlider-valueLabel': {
      lineHeight: 1.2,
      fontSize: 12,
      background: 'unset',
      padding: 0,
      width: 0,
      height: 0,
      borderRadius: '50% 50% 50% 0',
      backgroundColor: '#52af77',
      transformOrigin: 'bottom left',
      transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
      '&:before': { display: 'none' },
      '&.MuiSlider-valueLabelOpen': {
        transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
      },
      '& > *': {
        transform: 'rotate(45deg)',
      },
    },
  });

type ModalProps = {
    open: boolean;
    setOpen: Function;
    moduleQuestion: any;
    minimumScore: any;
};

const AnswerSheetModal: React.FC<ModalProps> = ({
    open,
    setOpen,
    moduleQuestion,
    minimumScore,
}) => {
    const classes = useStyles();
    const blockCharInPhoneField = (e: React.KeyboardEvent<HTMLDivElement>) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();

    const handleClose = () => {
    };

    
    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper} style={{ width: "100vh" }}>
                        <div>
                            <div className="header" style={{ display: "flex", justifyContent: "space-between" }}>
                                <IconButton>
                                    <CloseIcon 
                                        style={{ color: "black", margin: "10px" }}
                                        onClick={() => {
                                            setOpen(false);
                                        }}
                                    />
                                </IconButton>
                                <IconButton>
                                    <Tooltip title="Logo" arrow>
                                        <SchoolOutlinedIcon
                                            style={{ color: "black", margin: "10px" }}
                                        />
                                    </Tooltip>
                                </IconButton>
                            </div>
                            <div className="body" style={{ overflow: "scroll" }}>
                                <div className="answerSheetDiv">
                                    <h6 style={{ padding: "0px 10px 0px 10px" }}>Minimum score to pass in this module is <span style={{ color: "red" }}>{minimumScore}</span>.</h6>
                                    {
                                        moduleQuestion && moduleQuestion?.length > 0 && moduleQuestion?.map( (row: any, index: any) => (
                                            <div style={{  }}>
                                                <div style={{ textAlign: "left" }}>
                                                    <span style={{ color: "#827d7d", padding: "0px 10px 0px 10px" }}>{`Question: ${index + 1} - ${row?.name !== null ? row?.name : "No Text in Queston."}`}</span>
                                                    <p style={{ color: "#827d7d", padding: "0px 10px 0px 10px" }}>
                                                        {`Answers: ${row?.assignment_answer_question?.filter( (x: any) => x?.assignment_user % 2 === 1 ).map( (ans: any) => {
                                                            return ans?.name;
                                                        })}`}
                                                    </p>
                                                </div>
                                            </div>
                                        ) )
                                    }
                                    {
                                        moduleQuestion && moduleQuestion?.length === 0 && <h2>No Questions Available for this module.</h2>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
};

const mapStateToProps = (state: any) => ({
});

export default connect(mapStateToProps, {
})(AnswerSheetModal);