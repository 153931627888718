import React, { useState, useEffect, useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Grid,
  TextField,
  withStyles,
  Select,
  MenuItem,
  FormControl,
} from "@material-ui/core";
// import "./index.sass";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  getDcCampSampleHandover,
  updateRiderToLabStatus,
  getCenterInfo,
  getBatchTubeInfo,
} from "../../actions/LogisticsPanelAction";
// import DcCampTubeInfoModal from "../../components/Comments/dcCampTubeModal";
import { genrateDcCampSampleListFilter } from "../../../helpers/generateUrl";
import moment from "moment";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      marginTop: "2rem",
      width: "100%",
    },
    table: {
      maxWidth: "100%",
    },
    tableHead: {
      background: "rgb(146, 74, 145)!important",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    tableHeadCell: {
      color: "#fff",
      fontWeight: "bold",
      textAlign: "center",
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
      width: "98%",
    },
    input: {
      padding: "1px",
    },
    imagePaper: {
      position: "absolute",
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[1],
    },
    dialog: {
      zIndex: 1,
    },
  })
);
const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

function rand() {
  return Math.round(Math.random() * 20) - 10;
}
function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

interface SampleHandOverProps {
  getDcCampSampleHandover: any;
  dcCampList: any;
  updateRiderToLabStatus: any;
  loading: boolean;
  getCenterInfo: any;
  centerInfo: any;
  getBatchTubeInfo: any;
  setTubeId: any;
  setOpenTubeModal: any;
  tubeInfoData: any;
}

const BookingsTable: React.FC<SampleHandOverProps> = ({
  getDcCampSampleHandover,
  dcCampList,
  updateRiderToLabStatus,
  loading,
  getCenterInfo,
  centerInfo,
  getBatchTubeInfo,
  setTubeId,
  setOpenTubeModal,
  tubeInfoData,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const timer = useRef<any>(0);
  const [bookingId, setBookingId] = useState<any>("");
  const [tableId, setTableId] = useState<any>("");
  const [receivedFrom, setReceivedFrom] = useState<any>("none");
  const [center, setCentre] = useState<any>("");
  const [page, setPage] = React.useState(0);
  const [receivedStartDate, setReceivedStartDate] = useState("");
  const [receivedEndDate, setReceivedEndDate] = useState("");
  const [createdStartDate, setCreatedStartDate] = useState("");
  const [createdEndDate, setCreatedEndDate] = useState("");
  const [rider, setRider] = useState<any>([]);
  const [barcode, setBarcode] = useState<any>("");
  const [batchStatus, setBatchStatus] = useState<any>("none");
  const [batchType, setBatchType] = useState<any>("");
  const [openDialerModal, setOpenDialerModal] = useState(false);
  const [editDialerDetails, setEditDialerDetails] = useState<any>({});

  useEffect(() => {
    getDcCampSampleHandover();
    getCenterInfo();
  }, []);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url = dcCampList.links && dcCampList.links.next.split("?")[1];
      getDcCampSampleHandover(url ? `${url}` : "");
    } else if (newPage < page) {
      let url = dcCampList.links && dcCampList.links.previous.split("?")[1];
      getDcCampSampleHandover(url ? `${url}` : "");
    }
    setPage(newPage as number);
  };

  const filterSampleHandover = async (e: any) => {
    e.preventDefault();
    const body: any = {
      id: tableId,
      center: center,
      received_start_date: receivedStartDate,
      received_end_date: receivedEndDate,
      received_from: receivedFrom,
      created_start_date: createdStartDate,
      created_end_date: createdEndDate,
    };
    const url = genrateDcCampSampleListFilter(body).substring(2);
    getDcCampSampleHandover(`${url}${batchType === "" ? "" : `&${batchType}`}`);
    setPage(0);
  };

  const handleEditUser = (data: any) => {
    setOpenDialerModal(true);
    setEditDialerDetails(data);
    getBatchTubeInfo(`?tube_info=${data.id}`);
  };

  document.querySelectorAll("#noTyping").forEach((el) => {
    el.addEventListener("keydown", function (e) {
      e.preventDefault();
    });
  });

  return (
    <div style={{ width: "95%" }}>
      <div className={classes.toolbar} />
      <h2>DC and Camp Sample Receiving List</h2>
      <Grid container spacing={1} direction="row">
        <Grid item xs={12} sm={4} md={2}>
          <TextField
            className="input"
            name="Id"
            type="number"
            label="Id"
            value={tableId}
            variant="outlined"
            onChange={(e) => setTableId(e.target.value)}
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Autocomplete
            id="center"
            onChange={(event, newValue) => {
              if (newValue) {
                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                setCentre(obj.id);
              }
            }}
            freeSolo
            blurOnSelect
            options={centerInfo?.results || []}
            disableCloseOnSelect
            getOptionLabel={(option: any) =>
              (option?.display_name === null
                ? option?.name
                : option?.display_name) +
              "(" +
              option?.center_code +
              ")"
            }
            loading={loading}
            onInputChange={(event, newInputValue) => {
              clearTimeout(timer.current);
              timer.current = setTimeout(() => {
                getCenterInfo(`?name_or_code=${newInputValue}`);
              }, 1000);
            }}
            renderInput={(params) => (
              <TextField
                className="input"
                {...params}
                label="Centre"
                variant="outlined"
                style={{ width: "100%"}}
                inputProps={{
                  ...params.inputProps,
                  onKeyDown: (e) => {
                    if (e.key === "Enter") {
                      e.stopPropagation();
                    }
                  },
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <FormControl style={{ width: "100%" }} className="input">
            <Select
              className="input"
              name="status"
              variant="outlined"
              value={receivedFrom}
              style={{ width: "100%", height: "40px" }}
              onChange={(e) => setReceivedFrom(e.target.value as string)}
            >
              <MenuItem value={"none"}>Received From</MenuItem>
              <MenuItem value={"dc"}>Direct Client</MenuItem>
              <MenuItem value={"camp"}>Camp</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {/* <Grid item xs={12} sm={4} md={2}>
          <TextField
            className="input"
            name="barcode"
            type="text"
            label="Barcode"
            value={barcode}
            variant="outlined"
            onChange={(e) => setBarcode(e.target.value)}
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <TextField
            className="input"
            name="name"
            type="number"
            label="Booking Id"
            value={bookingId}
            variant="outlined"
            onChange={(e) => setBookingId(e.target.value)}
            style={{ width: "100%" }}
          />
        </Grid> */}
        <Grid item xs={12} sm={4} md={2}>
          <TextField
            className="input"
            name="start_date"
            type="date"
            id="noTyping"
            label="Received Start Date"
            value={receivedStartDate}
            variant="outlined"
            onChange={(e) => setReceivedStartDate(e.target.value as string)}
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <TextField
            name="end_date"
            type="date"
            id="noTyping"
            value={receivedEndDate}
            className="input"
            label="Received End Date"
            variant="outlined"
            onChange={(e) => setReceivedEndDate(e.target.value as string)}
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <TextField
            className="input"
            name="Created At"
            type="date"
            id="noTyping"
            label="Created Start Date"
            value={createdStartDate}
            variant="outlined"
            onChange={(e) => setCreatedStartDate(e.target.value as string)}
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <TextField
            className="input"
            name="Created At"
            id="noTyping"
            type="date"
            label="Created End Date"
            value={createdEndDate}
            variant="outlined"
            onChange={(e) => setCreatedEndDate(e.target.value as string)}
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={filterSampleHandover}
          >
            Filter
          </Button>
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => history.push("/dashboard/lp/dc_camp_recivingList")}
          >
            Reset
          </Button>
        </Grid>
      </Grid>
      <div style={{ width: "100%" }} className="data-table">
        <TableContainer
          className={classes.tableContainer}
          component={Paper}
          style={{ maxHeight: "540px" }}
        >
          <Table stickyHeader aria-label="simple table">
            <TableHead className={classes.tableHead}>
              <StyledTableRow>
                {/* <StyledTableCell align="center">View</StyledTableCell> */}
                {/* <StyledTableCell align="center">Edit</StyledTableCell> */}
                <StyledTableCell align="center">ID</StyledTableCell>
                <StyledTableCell align="center">Comment</StyledTableCell>
                <StyledTableCell align="center">DC/Camp Name</StyledTableCell>
                <StyledTableCell align="center">Received From</StyledTableCell>
                <StyledTableCell align="center">Received By</StyledTableCell>
                <StyledTableCell align="center">Received Date</StyledTableCell>
                <StyledTableCell align="center">No of Tubes</StyledTableCell>
                <StyledTableCell align="center">
                  Lab Received At
                </StyledTableCell>
                <StyledTableCell align="center">
                  Lab Received By
                </StyledTableCell>
                <StyledTableCell align="center">Status</StyledTableCell>
                <StyledTableCell align="center">Created At</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {dcCampList &&
                dcCampList?.results &&
                dcCampList.results.map((data: any) => {
                  return (
                    <StyledTableRow key={data}>
                      {/* <StyledTableCell align="center">
                        <Button
                          color="primary"
                          variant="text"
                          onClick={() => handleEditUser(data)}
                        >
                          View
                        </Button>
                      </StyledTableCell> */}
                      {/* <StyledTableCell align="center">
                        {data?.id}
                      </StyledTableCell> */}
                      <StyledTableCell align="center">
                        {data?.id}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.comment}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.dc_or_camp_name_name}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.received_from}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.received_by_name}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.receiving_date}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.no_of_tubes}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.lab_received_at}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.lab_panel_user}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.status}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {moment(data?.created_at).format("DD-MM-YYYY")}
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
            </TableBody>
            <TableFooter>
              <StyledTableRow>
                <TablePagination
                  count={dcCampList?.count || 0}
                  rowsPerPageOptions={[]}
                  colSpan={3}
                  rowsPerPage={dcCampList?.page_size}
                  page={page}
                  SelectProps={{
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                />
              </StyledTableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
      {/* <DcCampTubeInfoModal
        editDialerDetails={editDialerDetails}
        setOpenDialerModal={setOpenDialerModal}
        openDialerModal={openDialerModal} loading={undefined}       
             /> */}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  dcCampList: state.LogisticsReducer.dcCampList,
  centerInfo: state.LogisticsReducer.centerInfo,
  loading: state.LogisticsReducer.loading,
  tubeInfoData: state.LogisticsReducer.tubeInfoData,
});

export default connect(mapStateToProps, {
  getDcCampSampleHandover,
  updateRiderToLabStatus,
  getCenterInfo,
  getBatchTubeInfo,
})(BookingsTable);
