import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from '@mui/material/Modal';
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { TableContainer, Table, Chip, TableHead, TableBody, TableRow, TableCell, Button, Paper, TextField, Grid, Select, MenuItem } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import { updateTubeStatus } from '../../actions/PhleboAdmin'
import EditTube from './editTubeModal'
import AddTube from './addBarcode'
import EditIcon from '@material-ui/icons/Edit';
let width = window.innerWidth;
let isMobile = width <= 500;


const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center', position: 'absolute',
        top: '10%',
        left: '10%',
        overflow: 'scroll',
        height: '100%',
    },
    paper: {
        marginTop: "2rem",
        padding: "0.5rem 2rem",
        width: "100%",
    },
    table: {
        maxWidth: "100%",
        margin: "auto"
    },
    head: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "2rem",
    },
}));
interface Props {
    updateTubeStatus: any;
}
type ModalProps = {
    openTubeModal: boolean;
    setOpenTubeModal: Function;
    qrcode: any;
    getQRCodeData: any;
    updateTubeStatus: any;
    setUpdateTube: any
    setTubeId: any;
    tubeId: any;
    unScannedTube: any;
    bookingIdentifier: any;
    memberCheck: any;
};

const CreatePhlebo: React.FC<ModalProps> = ({
    setOpenTubeModal,
    openTubeModal,
    qrcode,
    getQRCodeData,
    updateTubeStatus,
    setUpdateTube,
    setTubeId,
    tubeId,
    unScannedTube,
    bookingIdentifier,
    memberCheck
}) => {
    const classes = useStyles();
    const timer = useRef<any>(0)
    const [id, setId] = useState(0)
    const [phleboSource, setPhleboSource] = useState<string>("none");
    const [zoneName, setZoneName] = useState<string>("none");
    const [tubeName, setTubeName] = useState<any>([])
    const [open, setOpen] = useState<any>(false)
    const [openAddTube, setOpenAddTube] = useState<any>(false)
    const [codeData, setCodeData] = useState<any>("")
    const [selectedTubeId, setSelectedTubeId] = useState<any>("")
    const [packId, setPackId] = useState<any>("")
    const handleClose = () => {
        setOpenTubeModal(false);
    };
    const handleConfirmStatus = async (id: any) => {
        const tubeData = {
            tube_status: "confirmed"
        }
        await updateTubeStatus(id, tubeData)
        getQRCodeData(tubeId)

    }
    const handleEditTube = (code: any, id: any) => {
        setSelectedTubeId(id)
        setCodeData(code)
        setOpen(true)
        setOpenTubeModal(false)
    }

    const handleAddTube = (id: any, packId: any) => {
        setSelectedTubeId(id)
        setPackId(packId)
        setOpenAddTube(true)
        setOpenTubeModal(false)
    }

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openTubeModal}
                // onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openTubeModal}>
                    <div className={classes.paper}>
                        <Paper elevation={15} className={classes.paper}>
                            <div className={classes.head}>

                                <h4
                                    style={{
                                        marginBottom: "1rem",
                                    }}
                                    id="transition-modal-title"
                                >
                                    TUBE DETAILS
                                </h4>
                                <CloseIcon onClick={() => setOpenTubeModal(false)} />
                            </div>

                            <TableContainer className={classes.table} component={Paper}>
                                <h4 style={{ fontWeight: "bold" }}>Scanned Tube</h4>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">Tube Id</TableCell>
                                            <TableCell align="center">Tube name</TableCell>
                                            <TableCell align="center">Code</TableCell>
                                            <TableCell align="center">Tube Volume</TableCell>
                                            <TableCell align="center" >Tube Status</TableCell>
                                            <TableCell align="center">Phlebo Sample Status</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {qrcode && qrcode.results && qrcode.results.length > 0 ? qrcode.results.map((data: any, index: any) => {

                                            return (
                                                <TableRow key={index}>
                                                    <TableCell align="center">{data?.id}</TableCell>
                                                    <TableCell align="center">{data?.tube.tube.name || "N/A"}</TableCell>
                                                    <TableCell style={{ cursor: "pointer" }} align="center" onClick={() => handleEditTube(data?.code, data?.id)}>{data?.code || "N/A"}<EditIcon /></TableCell>
                                                    <TableCell align="center">{data?.tube.tube.volume || "N/A"}</TableCell>
                                                    <TableCell align="center">{data?.tube_status || "N/A"}</TableCell>
                                                    <TableCell align="center">{data?.is_snr === false ? "Received Sample" : "Not Received"}</TableCell>

                                                </TableRow>
                                            )
                                        }) : ""}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TableContainer className={classes.table} component={Paper}>
                                <h4 style={{ fontWeight: "bold" }}>Not Scanned Tube</h4>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">Tube name</TableCell>
                                            <TableCell align="center">Code</TableCell>
                                            <TableCell align="center">Tube Volume</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {unScannedTube && unScannedTube.length > 0 ? unScannedTube.map((data: any, index: any) =>{
                                              return (
                                                <TableRow key={index}>
                                                    <TableCell align="center">{data?.tube?.tube.name || "N/A"}</TableCell>
                                                    <TableCell style={{ cursor: "pointer" }} align="center"><EditIcon onClick={() => handleAddTube(data?.tube?.id, data?.package.id)} /></TableCell>
                                                    <TableCell align="center">{data?.tube?.tube.volume || "N/A"}</TableCell>
                                                </TableRow>
                                            )
                                        }):""}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </div>
                </Fade>

            </Modal>
            <EditTube setOpen={setOpen} open={open} codeData={codeData} setCodeData={setCodeData} id={selectedTubeId} setOpenTubeModal={setOpenTubeModal} tubeId={tubeId} setTubeId={setTubeId} setUpdateTube={setUpdateTube} />
            <AddTube setOpenAddTube={setOpenAddTube} openAddTube={openAddTube} codeData={codeData} setCodeData={setCodeData} id={selectedTubeId} setOpenTubeModal={setOpenTubeModal} tubeId={tubeId} setTubeId={setTubeId} setUpdateTube={setUpdateTube} packId={packId} bookingIdentifier={bookingIdentifier} memberCheck={memberCheck} />
        </div>
    );
};

const mapStateToProps = (state: any) => ({

});

export default connect(mapStateToProps, {
    updateTubeStatus,
})(CreatePhlebo);
